import {Modal} from "antd";
import React from "react";
import '../styles/modals.css';
import popupImage from '../../../../platformImages/popups/woman_sitting_popupImage.png';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {howToUsePlatformForTeacher} from "../../../../utils/common";

const PopupCongratulationForTeacher = (props) => (
  <Modal
    title={null}
    centered
    style={{margin: 0, padding: 0}}
    visible={props.congratulationTeacherPopupVisible}
    onCancel={() => props.setCongratulationTeacherPopupVisible(false)}
    width={505}
    wrapClassName={'modal-common popup-for-congratulation-for-teacher'}
    footer={null}
  >
    <div className={'head-form'}>
      <img src={popupImage}/>
      <h3>Поздравляем с регистрацией на платформе Ивнвентик!</h3>
      <p>
        Чтобы узнать о всех возможностях платформы, рекомендуем ознакомиться с
        <br/><a onClick={howToUsePlatformForTeacher}>инструкцией по использованию</a>
      </p>
    </div>
  </Modal>
)



const mapStateToProps = state => ({
  congratulationTeacherPopupVisible: state.commonReducer.congratulationTeacherPopupVisible,
});
const mapDispatchToProps = (dispatch, {match}) => ({
  setCongratulationTeacherPopupVisible: (visible) => dispatch(actions.setCongratulationTeacherPopupVisible(visible))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopupCongratulationForTeacher);
