import React, {useEffect, useState} from "react";
import {Spin, Button, Input} from "antd";
import {CheckOutlined, CloseCircleOutlined} from '@ant-design/icons';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {layout2} from "../../../../utils/styles";
import {ymTeacherClickSendToStudentGoal} from "../../../../metriks/ym_metriks";
import PopupForHomeworkCopyLink from "../../layout2/components/PopupForHomeworkCopyLink";
import PopupVideoInstruction from "../../layout2/components/PopupVideoInstruction";
import { serverBaseUrl } from "../../../../utils/serverBaseUrl";
// import {descriptionUrl, serverMediaUrl} from "./api";


const HwMenu = (props) => {
  const {selectedHomework, setSelectedHomeworkId, loading} = props;
  const [hwTitle, setHwTitle] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [videoPopupVisible, setVideoPopupVisible] = useState(false);

  useEffect(() => {
    setEditMode(false);
  }, [selectedHomework.id]);

  const saveHwTitle = async () => {
    await props.updateHomework({hwId: selectedHomework.id, title:hwTitle})
    setEditMode(false);
  }

  const closeEditMode = () => setEditMode(false);

  const removeHomework = async () => {
    const resp = await props.removeHomework(selectedHomework.id);
    setSelectedHomeworkId(resp?.homeworks?.[0]?.id);
  }

  return (
    <>
      <div className={'homework__menu'}>
        <div>
          <div className={'hw__title'}>
            {
              editMode ? (
                <div style={{display: 'flex'}}>
                  <Button style={{flexShrink: 0}} icon={<CloseCircleOutlined />} type={'danger'}  onClick={() => closeEditMode()}/>
                  <Input
                    value={hwTitle}
                    onChange={(e) => { setHwTitle(e.target.value) }}
                  />
                  <Button style={{flexShrink: 0}} disabled={!hwTitle} icon={<CheckOutlined />} type={'primary'} onClick={() => saveHwTitle()}/>
                </div>
              ) : (
                <h2>{selectedHomework.title}</h2>
              )
            }
          </div>
          {
            !editMode && (
              <Button
                onClick={() => {
                  setEditMode(true);
                  setHwTitle(selectedHomework.title);
                }}
                className={'hw__renameBtn'}
                type={'link'}
              >
                Rename homework
              </Button>
            )
          }

          <Button
            onClick={() => removeHomework()}
            className={'hw__removeBtn'} type={'link'}
          >
            Delete homework
          </Button>

          <Spin spinning={loading}/>
        </div>

      </div>
      <div className={'copyLinkBtn'}>
        {
          selectedHomework.tasks?.length > 0 ? (
            <>
              <Button
                type={'primary'} size={'large'} style={{backgroundColor: '#2C79F6', height: '50px', padding: '0 20px', marginRight: '10px'}}
                onClick={() => {
                  ymTeacherClickSendToStudentGoal(selectedHomework.code, selectedHomework.tasks.length);
                  props.setHomeworkCopyLinkPopupVisible(true);
                }}
              >
                Share with students
              </Button>

              {/*<Button*/}
              {/*  style={{*/}
              {/*    backgroundColor: '#E1EDFF', color:'#2C79F6', margin: '10px 10px 10px 0',*/}
              {/*    height: '50px', fontSize: '16px'*/}
              {/*  }}*/}
              {/*  onClick={() => setVideoPopupVisible(true)}*/}
              {/*>*/}
              {/*  <span style={{*/}
              {/*    borderRadius: '50%',*/}
              {/*    backgroundColor: 'rgb(44, 121, 246)',*/}
              {/*    color: 'white',*/}
              {/*    width: '25px',*/}
              {/*    height: '25px',*/}
              {/*    display: 'inline-flex',*/}
              {/*    alignItems: 'center',*/}
              {/*    justifyContent: 'center',*/}
              {/*    marginRight: '5px',*/}
              {/*    lineHeight: 1*/}
              {/*  }}>?</span> Как это работает*/}
              {/*</Button>*/}

              <Button
                style={{
                  backgroundColor: '#E1EDFF', color:'#2C79F6', margin: '10px 10px 10px 0',
                  height: '50px', fontSize: '16px'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  //todo: дополнить правильной ссылкой
                  window.location.href=`${serverBaseUrl}container_test/new_container/`+selectedHomework.id;
                  // window.location.href='${serverMediaUrl}/api/container_test/new_container/'+selectedHomework.id;
                  }
                }
              >
                + Add new task
              </Button>

            </>
          ) : null
        }
      </div>
      <PopupForHomeworkCopyLink
        selectedHomework={selectedHomework}
        link={`${window.location.origin}/homework-auth/${selectedHomework.code}`}
      />
      <PopupVideoInstruction
        visible={videoPopupVisible}
        setVisible={setVideoPopupVisible}
      />
    </>
  )
}




const mapStateToProps = (state, {match}) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHomework: () => dispatch(actions.fetchHomework(true)),
    updateHomework: ({hwId, title, taskId}) => dispatch(actions.updateHomework({hwId, title, taskId})),
    removeHomework: (hwId) => dispatch(actions.removeHomework(hwId)),
    setHomeworkCopyLinkPopupVisible: (visible) => dispatch(actions.setHomeworkCopyLinkPopupVisible(visible))
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HwMenu);
