import studentMainPageImg from "../../../../platformImages/layout2/studentMainPage.png";
import Achievements from "./Achievements";
import UpgradeSkill from "./UpgradeSkill";
import React, {useState} from "react";


const StudentContent = () => {
  return (
    <div>
      <img src={studentMainPageImg} className={'student-content-bg-img'}/>

      <Achievements />
      <UpgradeSkill />
    </div>
  )
}

export default StudentContent;
