import Ski_failure_src from '../../../images/skiBond/Ski_failure.mp4';
import Ski_success_src from '../../../images/skiBond/Ski_success.mp4';
import Skiing_start_src from '../../../images/skiBond/Skiing_start.mp4';

const defaultPatchData = {
  title: '',

  videoSrc: undefined,
  onVideoEnd: (comp) => {},

  theoryVisible: false,
  onClickSkipTheory: (comp) => {},

  nextBtnVisible: false,
  selectSki: false,
  lastStep: false,
}


export const scenario = [
  {
    key: 'initial animation',
    patchData: {
      ...defaultPatchData,
      videoSrc: Skiing_start_src,
      onVideoEnd: (comp) => {
        comp.scenarioManager.next()
      },
    }
  },
  {
    key: 'initial step',
    patchData: {
      ...defaultPatchData,
      title: 'Cпецагенту необходимо покинуть лабораторию, спустившись на лыжах.\nИзвестно, что снег выдерживает максимальное давление 2500 Па',
      nextBtnVisible: true,
    },
    switch_at: 10,
  },
  {
    key: 'select ski',
    patchData: {
      ...defaultPatchData,
      title: 'Выбери лыжи, которые позволят спецагенту не провалиться в снег.\nВысота (h) и ширина(a) лыж также известны',
      selectSki: true,
    }
  },

  {
    key: 'fail animation',
    patchData: {
      ...defaultPatchData,

      videoSrc: Ski_failure_src,
      onVideoEnd: (comp) => {
        if (comp.data.errorCounter >= 2) {
          comp.scenarioManager.selectStepByKey('theory');
          return
        }
        comp.scenarioManager.selectStepByKey('select ski');
      },
    },
  },{
    key: 'success animation',
    patchData: {
      ...defaultPatchData,

      videoSrc: Ski_success_src,
      onVideoEnd: (comp) => {
        comp.scenarioManager.selectStepByKey('success');
      },
    },
  },




  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,

      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        if (comp.data.errorCounter >= 2) {
          comp.data.errorCounter = 0;
          comp.scenarioManager.selectStepByKey('select ski');
          return
        }
        comp.scenarioManager.selectStepByKey('success');
      }
    }
  },

  {
    key: 'success',
    patchData: {
      ...defaultPatchData,

      lastStep: true,
    }
  }
]