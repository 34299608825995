import React from "react";
import {Group, Image, Text} from "react-konva";
import CanvasButton from "../../../canvas/components/CanvasButton";
import useImage from "use-image";
import answerWindowImg from "../../../../images/space/spaceship_take_off/answerWindow.png";



const DarkAnswerWindow = (props) => {
  const [answerWindow] = useImage(answerWindowImg);

  return (
    <Group
      x={props.x}
      y={props.y}
      visible={props.visible}
    >
      <Image image={answerWindow}/>

      {props.children}

      <CanvasButton
        text={'Назад'}
        onClick={props.onCLickBack}
        fontSize={18}
        btnCornerRadius={0}
        textStroke={'transparent'}
        textFill={'#1E2531'}
        btnFill={'#EBFAFD'}
        btnStrokeWidth={1}
        width={100}
        height={35}
        x={80} y={320}
        visible={props.backBtnVisible}
      />
      <CanvasButton
        text={'Далее'}
        onClick={props.onCLickNext}
        fontSize={18}
        btnCornerRadius={0}
        textStroke={'transparent'}
        textFill={'#1E2531'}
        btnFill={'#EBFAFD'}
        btnStrokeWidth={1}
        width={100}
        height={35}
        x={500} y={320}
        visible={props.nextBtnVisible}
      />
    </Group>
  )
};

export default DarkAnswerWindow;