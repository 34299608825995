import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getTimeDeltaSec, hasIntersection, inputVal, showFailOrSuccessPopup} from "../../../../utils/common";
import {defaultPatchData, scenario} from "../scenaries/SacalesWithBowlsScenario";
import weight5Img from '../../../../images/scales/weight5.png';
import weight10Img from '../../../../images/scales/weight10.png';
import weight20Img from '../../../../images/scales/weight20.png';
import weight50Img from '../../../../images/scales/weight50.png';
import weight100Img from '../../../../images/scales/weight100.png';
import weightQ1Img from '../../../../images/scales/weightQ1.png';
import weightQ2Img from '../../../../images/scales/weightQ2.png';
import {mainColor} from "../../../../utils/styles";
import CanvasInput from "../../../canvas/components/CanvasInput";
import CanvasButton from "../../../canvas/components/CanvasButton";
import StepsIndicator from "../../../canvas/components/StepsIndicator";
import CanvasSuccessCard from "../../../canvas/components/CanvasSuccessCard";
import * as actions from "../../../../store/actions";
import CanvasScaleWithBowls from "../../../canvas/components/scales/CanvasScaleWithBowls";



class ScalesWithBowls extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',

      'defaultWeightQPlace',
      'draggableWeightQNode',

      'dropWeightQPlace',
      'dropWeightPlace',

      'leftPlatform',
      'rightPlatform',
      'leverLine',
    ]

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      dragging: false,

      addedWeightQPlace: false,
      rightWeightPlaceStack: [],
      rightPlatformWeight: 0,

      rightWeightItems: [
        {id: 1, weight: 100,  width: 74, height: 111, x: 10},
        {id: 2, weight: 50,  width: 74, height: 77, x: 90},
        {id: 3, weight: 20,  width: 33, height: 49, x: 170},
        {id: 4, weight: 10,  width: 21, height: 31, x: 208},
        {id: 5, weight: 10,  width: 21, height: 31, x: 235},
        {id: 6, weight: 5,  width: 15, height: 21, x: 265},
        {id: 7, weight: 5,  width: 15, height: 21, x: 285}
      ],

      platformOffsetX: 0,
      leftPlatformOffsetY: 0,
      rightPlatformOffsetY: 0,
      leverLineRotation: 0,

      ...defaultPatchData
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  get leverLineRotation() {
    const data = this.data;
    const {maxAngle} = this.staticData;

    const minWeight = data.addedWeightQPlace ? data.weightQMin : 0;
    const maxWeight = data.addedWeightQPlace ? data.weightQMax : 0;
    let res = data.leverLineRotation;
    if (data.rightPlatformWeight < minWeight) {
      const newRes = res - .2;
      res = newRes <= -maxAngle ? -maxAngle : newRes;
    }
    if (data.rightPlatformWeight > maxWeight) {
      const newRes = res + .2;
      res = newRes >= maxAngle ? maxAngle : newRes;
    }
    if (data.rightPlatformWeight >= minWeight && data.rightPlatformWeight <= maxWeight) {
      res = res > 0.1 ? res-.1 : res < -0.1 ? res+.1 : 0;
    }
    return res;
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  _movingCallbacks = {};
  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  showFailOrSuccessPopup = (status) => showFailOrSuccessPopup(this, status);

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);

    data.rightPlatformWeight = data.rightWeightPlaceStack.reduce((accum, weightId) => {
      const item = data.rightWeightItems.find((el) => el.id === weightId)
      return accum + item.weight
    }, 0);


    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    const currWeightNode = this._getNode('draggableWeightQNode');
    const currWeightRectNode = this._getNode('draggableWeightQRectNode');
    if (data.addedWeightQPlace) {
      const dropPlaceNode = this._getNode('leftDropPlace');
      const pos = dropPlaceNode.getAbsolutePosition();
      currWeightNode.setAbsolutePosition({x: pos.x+85, y: pos.y+(dropPlaceNode.height()-currWeightRectNode.height())});
    } else if (!data.dragging) {
      const defaultPlaceNode = this._getNode('defaultWeightQPlace');
      currWeightNode.setAbsolutePosition(defaultPlaceNode.getAbsolutePosition());
    }

    this.data.rightWeightItems.forEach((el) => {
      const id = el.id;
      const currWeight = this.data.rightWeightItems.find(el => el.id === id);
      const weightNode = this._getNode('draggableWeight'+id);
      if (data.rightWeightPlaceStack.includes(id)) {
        const dropWeightPlace = this._getNode('rightDropPlace');
        const placePos = dropWeightPlace.getAbsolutePosition();
        const dropPlaceHeight = dropWeightPlace.height();
        weightNode.setAbsolutePosition({
          x: placePos.x + currWeight.x,
          y: placePos.y + (dropPlaceHeight - currWeight.height)
        });
      } else if (!data.dragging) {
        const defaultWeightPlace = this._getNode('defaultWeightPlace' + id);
        const placePos = defaultWeightPlace.getAbsolutePosition();
        weightNode.setAbsolutePosition(placePos);
      }
    })

    n['stage'].draw();
  }

  Env = () => {
    const data = this.data;

    const [weightQ] = useImage(data.weightQImgStg.img);

    return (
      <React.Fragment>
        <Text x={50} y={40} text={data.title} fontSize={30} fill={'#4A6C97'} stroke={'#4A6C97'} strokeWidth={.5}/>

        <Image image={weightQ} {...data.weightQImgStg.pos}/>

        <Group x={50} y={100} visible={data.formVisible}>
          <Text text={'m = '} fontSize={25} fill={'#4A6C97'} y={3}/>
          <CanvasInput
            x={50} y={0}
            width={100}
            height={30}
            stage={this._getNode('stage')}
            textColor={mainColor}
            onInput={(val) => {
              data.inputVal = inputVal(val);
            }}
            value={data.inputVal}
          />
          <CanvasButton
            x={160} y={0}
            text={'Проверить'}
            fontStyle={'normal'}
            strokeWidth={.5}
            fontSize={17}
            height={30}
            onClick={() => data.checkToSuccess(this)}
          />
        </Group>
      </React.Fragment>
    )
  };

  Weights = (props) => {
    const data = this.data;
    const [weight5] = useImage(weight5Img);
    const [weight10] = useImage(weight10Img);
    const [weight20] = useImage(weight20Img);
    const [weight50] = useImage(weight50Img);
    const [weight100] = useImage(weight100Img);

    const [weightQ] = useImage(data.weightQImgStg.img || weightQ2Img);

    const weightItemsImgs = {
      1: weight100,
      2: weight50,
      3: weight20,
      4: weight10,
      5: weight10,
      6: weight5,
      7: weight5,
    }
    const removeAddedItem = (weightId) => {
      this.data.rightWeightPlaceStack = this.data.rightWeightPlaceStack.filter(itm => {
        return itm !== weightId;
      })
    }
    return (
      <Group {...props}>
        <Group>
          <Rect width={140} height={120} fill={'black'} opacity={0} ref={this._ref('defaultWeightQPlace')}/>
          <Group
            ref={this._ref('draggableWeightQNode')}
            draggable={true}
            onDragStart={() => {
              data.addedWeightQPlace = false;
              data.dragging = true;
            }}
            onDragEnd={(e) => {
              const currentNode = this._getNode('draggableWeightQRectNode');
              const placeNode = this._getNode('leftDropPlace');
              data.addedWeightQPlace = hasIntersection(placeNode, currentNode, this);
              data.dragging = false;
            }}
          >
            <Rect ref={this._ref('draggableWeightQRectNode')} width={140} height={120} opacity={0}/>
            <Image image={weightQ}/>
          </Group>
        </Group>

        <Group x={330}>
          {
            this.data.rightWeightItems.map((el, i) => {
              const img = weightItemsImgs[el.id];
              return (
                <Group key={'weight-'+el.id} x={el.x} y={120-el.height}>
                  <Rect width={el.width} height={el.height} fill={i?'red':'yellow'} opacity={0} ref={this._ref('defaultWeightPlace'+el.id)}/>
                  <Group
                    ref={this._ref('draggableWeight'+el.id)}
                    draggable={true}
                    onDragStart={() => {
                      this.data.dragging = true;
                      removeAddedItem(el.id);
                    }}
                    onDragEnd={(e) => {
                      const currentNode = this._getNode('draggableWeightRect'+el.id);
                      const placeNode = this._getNode('rightDropPlace');
                      const inters = hasIntersection(placeNode, currentNode, this)
                      if (inters) {
                        this.data.rightWeightPlaceStack.push(el.id);
                      } else {
                        removeAddedItem(el.id);
                      }
                      this.data.dragging = false;
                    }}
                  >
                    <Rect ref={this._ref('draggableWeightRect'+el.id)} width={el.width} height={el.height} fill={120} opacity={0} />
                    <Image image={img}/>
                  </Group>
                </Group>
              )
            })
          }
        </Group>
      </Group>
    )
  }

  render() {
    const data = this.data;
    const stepIndex = this.scenarioManager.currentStep;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this._ref('stage')}
          lessonCode={this.props.code}
        >
          <Layer preventDefault={false}>
            <this.Env/>
            <StepsIndicator
              x={770} y={40}
              items={Array(2).fill(1)}
              activeVal={stepIndex+1}
            />

            <CanvasScaleWithBowls
              x={480} y={220}
              _ref={this._ref}
              _getNode={this._getNode}
              checkRightWeight={() => {
                const minWeight = this.data.addedWeightQPlace ? this.data.weightQMin : 0;
                return this.data.rightPlatformWeight < minWeight;
              }}
              checkLeftWeight={() => {
                const maxWeight = this.data.addedWeightQPlace ? this.data.weightQMax : 0;
                return this.data.rightPlatformWeight > maxWeight
              }}
              getMovingCallback={this.getMovingCallback}
            />
            <this.Weights x={200} y={420}/>

            <CanvasSuccessCard
              visible={data.endPopUpVisible}
              goToTheoryVisible={data.goToTheoryVisible}
              onClickReset={() => this.onClickReset()}
              btnTopBg={'#0083D2'}
              btnTopTxtColor={'white'}
              btnBottomBg={'#0083D2'}
              btnBottomTxtColor={'white'}
            />
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScalesWithBowls);

const styles = {
  mainContainer: {
    background: '#EEEBE5'
  }
};
