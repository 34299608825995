import mainChartForError1Img from "../../../../images/pathandspeed/mainCharForError1.png";
import mainCharStairwayImg from "../../../../images/pathandspeed/mainCharStairway.png";
import mainChartForError2Img from "../../../../images/pathandspeed/mainCharForError2.png";
import mainChartDescr1Img from "../../../../images/pathandspeed/mainChartDescr1.png";
import mainChartDescr2Img from "../../../../images/pathandspeed/mainChartDescr2.png";

import PAS6mainChart1Img from "../../../../images/pathandspeed/pathAndSpeed6/mainChart1.png";
import PAS6mainChart2Img from "../../../../images/pathandspeed/pathAndSpeed6/mainChart2.png";
import PAS6mainChart2Descr1Img from "../../../../images/pathandspeed/pathAndSpeed6/mainChart2Descr1.png";
import PAS6mainChart2Descr2Img from "../../../../images/pathandspeed/pathAndSpeed6/mainChart2Descr2.png";
import PAS6mainChart1ForError1Img from "../../../../images/pathandspeed/pathAndSpeed6/mainChart1ForError1.png";
import PAS6mainChart1ForError2Img from "../../../../images/pathandspeed/pathAndSpeed6/mainChart1ForError2.png";
import PAS6mainChart1ForError3Img from "../../../../images/pathandspeed/pathAndSpeed6/mainChart1ForError3.png";
import PAS6mainChart1ForError4Img from "../../../../images/pathandspeed/pathAndSpeed6/mainChart1ForError4.png";
import {getChartImgSetting} from "./utils";
import cloneDeep from "lodash.clonedeep";

const defaultPathData = {
  inputVal: undefined
}

export const pathAndSpeedScenario = [
  // --------- STEP 1 ----------
  {
    key: 'step1',
    text: { value: 'График зависимости пути от \nвремени показывает путь \nпройденный объектом \nза определенное время'},
    patchData: {
      ...defaultPathData,
      roadVisible: true,
      sidePageSide: 'right',

      bikerTimeDeltaSec: 0,
    },
    next_success: 'step1.2',
    switch_at: 10,
  },
  {
    key: 'step1.2',
    text: { value: 'Предположим, что мотоциклист \nдвижется с постоянной скоростью' },
    patchData: {
      ...defaultPathData,
      roadVisible: true,
      sidePageSide: 'right',

      bikerTimeDeltaSec: 0,
    },
    previous: 'step1',
    next_success: 'step1.3',
    switch_at: 6,
  },
  {
    key: 'step1.3',
    text: {
      value: 'Предположим, что мотоциклист \nдвижется с постоянной скоростью' +
        '\n\n' +
        'Тогда график зависимости \nпройденного пути от времени \nбудет прямой линией',
    },
    patchData: {
      ...defaultPathData,
      roadVisible: true,
      roadMoving: true,
      bikerVisible: true,
      sidePageSide: 'right',
    },
    previous: 'step1',
    next_success: 'step1.4',
    switch_at: 8,
  },
  {
    key: 'step1.4',
    text: { value: 'Из графика видно, что \nза первую секунду, \nмотоциклист проехал 20 метров' },
    patchData: {
      ...defaultPathData,
      roadVisible: true,
      roadMoving: true,
      sidePageSide: 'right',

      mainChartImg: mainChartDescr1Img,
    },
    previous: 'step1',
    next_success: 'step1.5',
    switch_at: 8,
  },
  {
    key: 'step1.5',
    text: { value: 'За первые две секунды, \nмотоциклист проехал 40 метров' },
    patchData: {
      ...defaultPathData,
      roadVisible: true,
      roadMoving: true,
      sidePageSide: 'right',

      mainChartImg: mainChartDescr2Img,
    },
    previous: 'step1',
    next_success: 'step2',
    switch_at: 8,
  },

  //
  //
  // --------- STEP 2 ----------
  {
    key: 'step2',
    text: { value: 'Каков путь, пройденный \nмотоциклистом за 5 секунд?' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Путь, м:',

      inputSuccess: 100,

      bikerTimeDeltaSec: 16.6,
      bikerSpeed: 20,
    },
    next_btn_hidden: true,
    previous: 'step1',
    next_success: 'step2.2',
    next_failure: 'step2.1',
  },
  {
    key: 'step2.1',
    text: { value: 'Определи по графику, какой путь \nсоответствует времени в 5 секунд' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Путь, м:',
      errorVisible: true,

      mainChartImg: mainChartForError1Img,
      inputSuccess: 100,

      bikerTimeDeltaSec: 16.6,
      bikerSpeed: 20,
    },
    next_btn_hidden: true,
    previous: 'step2',
    next_success: 'step2.2',
    next_failure: 'step2.1',
  },
  {
    key: 'step2.2',
    text: {
      value: 'Определи по графику, какой путь \nсоответствует времени в 5 секунд',
    },
    patchData: {
      ...defaultPathData,
      successVisible: true,

      bikerTimeDeltaSec: 16.6,
      bikerSpeed: 20,
    },
    previous: 'step2',
    next_success: 'step3',
    switch_at: 5,
  },
  //
  //
  // --------- STEP 3 ----------
  {
    key: 'step3',
    text: {
      value: 'Из графика зависимости пути \nот времени легко можно \nопределить скорость объекта',
    },
    patchData: {
      ...defaultPathData,
      bikerTimeDeltaSec: 16.6,
      bikerSpeed: 20,
    },
    previous: 'step2',
    next_success: 'step3.1',
    // next_failure: 'step3',
    switch_at: 5,
  },
  {
    key: 'step3.1',
    text: {
      value: 'Из графика зависимости пути \nот времени легко можно \nопределить скорость объекта' +
        '\n\n' +
        'Обрати внимание, каждую \nновую секунду \nмотоцикл проезжал \nновые 20 метров',
    },
    patchData: {
      ...defaultPathData,
      mainChartImg: mainCharStairwayImg,
    },
    previous: 'step3',
    next_success: 'step3.2',
    // next_failure: 'step3',
    switch_at: 5,
  },
  {
    key: 'step3.2',
    text: {
      value: 'Из графика зависимости пути \nот времени легко можно \nопределить скорость объекта\n' +
        '\nОбрати внимание, каждую \nновую секунду \nмотоцикл проезжал \nновые 20 метров\n' +
        '\nСкорость мотоциклиста \n- 20 метров в секунду',
    },
    patchData: {
      ...defaultPathData,
      mainChartImg: mainCharStairwayImg,
    },
    previous: 'step3',
    next_success: 'step4',
    // next_failure: 'step3',
    switch_at: 5,
  },
  //
  //
  // --------- STEP 4 ----------
  {
    key: 'step4',
    text: {
      value: 'Определи скорость \nдругого мотоциклиста \nиз графика',
    },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Скорость, м/с:',

      inputSuccess: 40,

      bikerTimeDeltaSec: 16.6/2,
      bikerSpeed: 40,
    },
    next_btn_hidden: true,
    previous: 'step3',
    next_success: 'step4.2',
    next_failure: 'step4.1',
  },
  {
    key: 'step4.1',
    text: {
      value: 'Обрати внимание, какой путь \nпреодолевает мотоциклист за \nодну секунду и рассчитай \nего скорость',
    },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      errorVisible: true,
      questionCardTxt: 'Скорость, м/с:',

      mainChartImg: mainChartForError2Img,

      inputSuccess: 40,

      bikerTimeDeltaSec: 16.6/2,
      bikerSpeed: 40,
    },
    next_btn_hidden: true,
    previous: 'step4',
    next_success: 'step4.2',
    next_failure: 'step4.1',
  },
  {
    key: 'step4.2',
    text: {
      value: 'Определи среднюю скорость \nдругого мотоциклиста \nиз графика',
    },
    patchData: {
      ...defaultPathData,
      successVisible: true,

      bikerTimeDeltaSec: 16.6/2,
      bikerSpeed: 40,
    },
    previous: 'step4',
    next_success: 'step5',
    switch_at: 5,
  },
  //
  //
  // --------- STEP 5 ----------
  {
    key: 'step5',
    text: {
      value: 'На основе графика пути \nможно построить график скорости',
    },
    patchData: {
      ...defaultPathData,
      rightChartStart: true,
      rightChartVisible: true,
      rightChartLineVisible: true,

      bikerTimeDeltaSec: 16.6/2,
      bikerSpeed: 40,
    },
    previous: 'step4',
    next_success: 'step5.1',
  },
  {
    key: 'step5.1',
    text: {
      value: 'Т.к. скорость была постоянной \n(40 м/с), график скорости - \nгоризонтальная линия',
    },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      rightChartLineVisible: true,

      rightChartTimeDeltaSec: 16.6,
      bikerTimeDeltaSec: 16.6/2,
      bikerSpeed: 40,
    },
    previous: 'step5',
    next_success: 'step6',
    switch_at: 18,
  },
  //
  //
  // --------- STEP 6 ----------
  {
    key: 'step6',
    text: {
      value: 'Построй график скорости \nна основе графика пути',
    },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      chartWithDragAndDropVisible: true,

      selectedDataSuccess: 20,

      bikerTimeDeltaSec: 16.6,
      bikerSpeed: 20,
    },
    previous: 'step5',
    next_success: 'success',
    next_failure: 'step6.1',
    next_btn_hidden: true,
  },
  {
    key: 'step6.1',
    text: {
      value: 'Построй график скорости \nна основе графика пути',
    },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      chartWithDragAndDropVisible: true,
      errorVisible: true,

      selectedDataSuccess: 20,

      bikerTimeDeltaSec: 16.6,
      bikerSpeed: 20,
      isLineDragged: true,
    },
    previous: 'step6',
    next_success: 'success',
    next_failure: 'step6.1',
    next_btn_hidden: true,
  },
  {
    key: 'success',
    text: {
      value: 'Построй график скорости \nна основе графика пути',
    },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      chartWithDragAndDropVisible: true,

      taskCompleteVisible: true,

      bikerTimeDeltaSec: 16.6,
      bikerSpeed: 20,
    },
    previous: 'step6',
    next_btn_hidden: true,
  },
];

export const pathAndSpeedScenario2 = [
  // --------- STEP 1 ----------
  {
    key: 'step1',
    text: { value: 'Предположим, что \nмотоциклист первые 4 секунды \nехал с постоянной скоростью. \nА после этого - \nс дважды большей скоростью'},
    patchData: {
      ...defaultPathData,
      roadVisible: true,
      sidePageSide: 'right',

      bikerSpeed: 20,
      bikerSpeed1: 20,
      bikerSpeed2: 40,
      bikerSpeedChangeTime: 6.6,
      bikerTimeDeltaSec: 0,
      maxTimeDeltaSec: 11.6
    },
    next_success: 'step1.1',
    switch_at: 8,
  },

  {
    key: 'step1.1',
    text: { value: 'Предположим, что \nмотоциклист первые 4 секунды \nехал с постоянной скоростью. \nА после этого - \nс дважды большей скоростью'},
    patchData: {
      ...defaultPathData,
      bikerVisible: true,
      roadVisible: true,
      roadMoving: true,
      sidePageSide: 'right',

      bikerSpeed: 20,
      bikerSpeed1: 20,
      bikerSpeed2: 40,
      bikerSpeedChangeTime: 6.6,
      bikerTimeDeltaSec: 0,
      maxTimeDeltaSec: 11.6
    },
    next_success: 'step2',
    switch_at: 10,
  },

  // --------- STEP 2 ----------

  {
    key: 'step2',
    text: { value: 'Какой путь преодолел \nмотоциклист в первые 4 секунды?' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Путь, м:',

      inputSuccess: 80,

      mainChartImg: PAS6mainChart1Img,
    },
    next_btn_hidden: true,
    previous: 'step1',
    next_success: 'step2.2',
    next_failure: 'step2.1',
  },
  {
    key: 'step2.1',
    text: {
      value: 'Определи по графику, \nкакой путь соответствует \nмоменту времени в 4 секунды'},
    patchData: {
      ...defaultPathData,
      errorVisible: true,
      questionCardVisible: true,
      questionCardTxt: 'Путь, м:',

      inputSuccess: 80,

      mainChartImg: PAS6mainChart1ForError1Img,
    },
    next_btn_hidden: true,
    previous: 'step2',
    next_success: 'step2.2',
    next_failure: 'step2.1',
  },
  {
    key: 'step2.2',
    text: { value: 'Какой путь преодолел \nмотоциклист в первые 4 секунды?' },
    patchData: {
      ...defaultPathData,
      successVisible: true,

      mainChartImg: PAS6mainChart1Img,
    },
    previous: 'step2',
    next_success: 'step3',
    switch_at: 5,
  },

  // --------- STEP 3 ----------

  {
    key: 'step3',
    text: { value: 'Какова была скорость \nмотоциклиста в первые \n4 секунды?' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Скорость, м/с:',

      inputSuccess: 20,

      mainChartImg: PAS6mainChart1Img,
    },
    next_btn_hidden: true,
    previous: 'step2',
    next_success: 'step3.2',
    next_failure: 'step3.1',
  },
  {
    key: 'step3.1',
    text: { value: 'Обрати внимание, что за 4 \nсекунды мотоциклист проехал \n80 метров. \nКакова была его скорость?' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Скорость, м/с:',

      inputSuccess: 20,
      errorVisible: true,

      mainChartImg: PAS6mainChart1ForError2Img,
    },
    next_btn_hidden: true,
    previous: 'step3',
    next_success: 'step3.2',
    next_failure: 'step3.1',
  },
  {
    key: 'step3.2',
    text: { value: 'Какова была скорость \nмотоциклиста в первые \n4 секунды?' },
    patchData: {
      ...defaultPathData,
      successVisible: true,

      mainChartImg: PAS6mainChart1Img,
    },
    previous: 'step3',
    next_success: 'step4',
    switch_at: 5,
  },

  // --------- STEP 4 ----------

  {
    key: 'step4',
    text: { value: 'Какой путь преодолел \nмотоциклист с 4 по 7 секунду?'},
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Путь, м:',

      inputSuccess: 120,
      mainChartImg: PAS6mainChart1Img,
    },
    next_btn_hidden: true,
    previous: 'step3',
    next_success: 'step4.2',
    next_failure: 'step4.1',
  },
  {
    key: 'step4.1',
    text: { value: 'Обрати внимание, на сколько \nувеличился пройденный путь \nс 4 по 7 секунду' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Путь, м:',

      inputSuccess: 120,
      errorVisible: true,

      mainChartImg: PAS6mainChart1ForError3Img,
    },
    next_btn_hidden: true,
    previous: 'step4',
    next_success: 'step4.2',
    next_failure: 'step4.1',
  },
  {
    key: 'step4.2',
    text: { value: 'Какой путь преодолел \nмотоциклист с 4 по 7 секунду?' },
    patchData: {
      ...defaultPathData,
      successVisible: true,

      mainChartImg: PAS6mainChart1Img,
    },
    previous: 'step4',
    next_success: 'step5',
    switch_at: 5,
  },

  // --------- STEP 5 ----------

  {
    key: 'step5',
    text: { value: 'Какова была скорость \nмотоциклиста с 4 по 7 секунду?'},
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Скорость, м/с:',

      inputSuccess: 40,
      mainChartImg: PAS6mainChart1Img,
    },
    next_btn_hidden: true,
    previous: 'step4',
    next_success: 'step5.2',
    next_failure: 'step5.1',
  },
  {
    key: 'step5.1',
    text: { value: 'Обрати внимание, на сколько \nувеличился пройденный путь \nс 4 по 7 секунду' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Скорость, м/с:',

      inputSuccess: 40,
      errorVisible: true,

      mainChartImg: PAS6mainChart1ForError4Img,
    },
    next_btn_hidden: true,
    previous: 'step5',
    next_success: 'step5.2',
    next_failure: 'step5.1',
  },
  {
    key: 'step5.2',
    text: { value: 'Какова была скорость \nмотоциклиста с 4 по 7 секунду?' },
    patchData: {
      ...defaultPathData,
      successVisible: true,

      mainChartImg: PAS6mainChart1Img,
    },
    previous: 'step5',
    next_success: 'step6',
    switch_at: 5,
  },

  // --------- STEP 6 ----------

  {
    key: 'step6',
    text: { value: 'На основе графика пути \nможно построить график \nскорости' },
    patchData: {
      ...defaultPathData,
      rightChartStart: true,
      rightChartVisible: true,
      rightChartLineVisible: true,

      mainChartImg: PAS6mainChart1Img,
    },
    previous: 'step5',
    next_success: 'step7',
  },

  // --------- STEP 7 ----------

  {
    key: 'step7',
    text: { value: 'Каков график скорости \nв первые 4 секунды?' },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      chartWithDragAndDropVisible: true,
      secondSpeedStubVisible: true,

      mainChartImg: PAS6mainChart1Img,


      itemsColumnArr: Array(4).fill(1),
      selectedData: Array(4).fill(1).reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),

      chartImgSetting: getChartImgSetting('pathandspeed6', Array(5).fill(1), 'step7'),

      selectedDataSuccess: 20
    },
    next_btn_hidden: true,
    previous: 'step6',
    next_success: 'step7.2',
    next_failure: 'step7.1',
  },
  {
    key: 'step7.1',
    text: { value: 'Каков график скорости в первые \n4 секунды, если за это время \nбыл пройден путь 80 м?' },
    patchData: {
      ...defaultPathData,
      errorVisible: true,
      rightChartVisible: true,
      chartWithDragAndDropVisible: true,
      secondSpeedStubVisible: true,


      itemsColumnArr: Array(4).fill(1),
      selectedData: Array(4).fill(1).reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),

      chartImgSetting: getChartImgSetting('pathandspeed6', Array(5).fill(1), 'step7.1'),

      selectedDataSuccess: 20,

      mainChartImg: PAS6mainChart1Img,
    },
    next_btn_hidden: true,
    previous: 'step7',
    next_success: 'step7.2',
    next_failure: 'step7.1',
  },
  {
    key: 'step7.2',
    text: { value: 'Каков график скорости \nв первые 4 секунды?' },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      successVisible: true,
      secondSpeedStubVisible: true,

      mainChartImg: PAS6mainChart1Img,
    },
    previous: 'step7',
    next_success: 'step8',
    switch_at: 5
  },

  // --------- STEP 8 ----------

  {
    key: 'step8',
    text: { value: 'Каков график скорости \nc 4 по 7 секунду?' },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      chartWithDragAndDropVisible: true,
      firstSpeedStubVisible: true,

      itemsColumnArr: Array(3).fill(1),
      selectedData: Array(3).fill(1).reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),

      chartImgSetting: getChartImgSetting('pathandspeed6', Array(5).fill(1), 'step8'),

      selectedDataSuccess: 40,


      mainChartImg: PAS6mainChart1Img,
    },
    next_btn_hidden: true,
    previous: 'step7',
    next_success: 'step8.2',
    next_failure: 'step8.1',
  },
  {
    key: 'step8.1',
    text: { value: 'Каков график скорости \nc 4 по 7 секунду, если за это \nвремя был пройден путь 120 м?' },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      chartWithDragAndDropVisible: true,
      firstSpeedStubVisible: true,

      itemsColumnArr: Array(3).fill(1),
      selectedData: Array(3).fill(1).reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),

      chartImgSetting: getChartImgSetting('pathandspeed6', Array(5).fill(1), 'step8.1'),

      selectedDataSuccess: 40,

      mainChartImg: PAS6mainChart1Img,
    },
    next_btn_hidden: true,
    previous: 'step7',
    next_success: 'step8.2',
    next_failure: 'step8.1',
  },
  {
    key: 'step8.2',
    text: { value: 'Каков график скорости \nc 4 по 7 секунду?' },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      successVisible: true,
      firstSpeedStubVisible: true,

      mainChartImg: PAS6mainChart1Img,
    },
    previous: 'step8',
    next_success: 'step9',
    switch_at: 5
  },

  // --------- STEP 9 ----------

  {
    key: 'step9',
    text: { value: 'Итоговый график скорости \nбудет выглядеть \nследующим образом:' },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      rightChartLineResultVisible: true,

      mainChartImg: PAS6mainChart1Img,
    },
    previous: 'step8',
    next_success: 'step10',
  },

  // --------- STEP 10 ----------

  {
    key: 'step10',
    text: { value: 'Построй график скорости \nна основе графика пути:' },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      chartWithDragAndDropVisible: true,


      itemsColumnArr: Array(7).fill(1),
      selectedData: Array(7).fill(1).reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),
      chartImgSetting: getChartImgSetting('pathandspeed6', Array(5).fill(1), 'step10'),
      selectedDataSuccessMultipleVal: Array(7).fill(1).map((el, i) => i < 3 ? 40 : 20),


      mainChartImg: PAS6mainChart2Img,
    },
    next_btn_hidden: true,
    previous: 'step9',
    next_success: '13.1',
    next_failure: 'step11',
  },
  // {
  //   //заключительная галочка
  //   key: 'step10.1',
  //   text: { value: 'Построй график скорости \nна основе графика пути:' },
  //   patchData: { ...defaultPathData,
  //     rightChartVisible: true,
  //     taskCompleteVisible: true,
  //
  //     mainChartImg: PAS6mainChart2Img,
  //   },
  //   next_btn_hidden: true,
  //   previous: 'step10',
  // },

  // --------- STEP 11 ----------

  {
    key: 'step11',
    text: { value: 'За первые 3 секунды было \nпройдено 120 метров. Какова \nбыла скорость?' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Скорость, м/с:',


      inputSuccess: 40,

      mainChartImg: PAS6mainChart2Descr1Img,
    },
    next_btn_hidden: true,
    previous: 'step10',
    next_success: 'step11.2',
    next_failure: 'step11.1',
  },

  {
    key: 'step11.1',
    text: { value: 'За первые 3 секунды было \nпройдено 120 метров. Какова \nбыла скорость?' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Скорость, м/с:',
      errorVisible: true,


      inputSuccess: 40,

      mainChartImg: PAS6mainChart2Descr1Img,
    },
    previous: 'step11',
    next_success: 'step11.2',
    next_failure: 'step11.1',
    next_btn_hidden: true,
  },

  {
    //галочка
    key: 'step11.2',
    text: { value: 'За первые 3 секунды было \nпройдено 120 метров. Какова \nбыла скорость?' },
    patchData: {
      ...defaultPathData,
      successVisible: true,

      mainChartImg: PAS6mainChart2Descr1Img,
    },
    previous: 'step11',
    next_success: 'step12',
    switch_at: 5,
  },

  // --------- STEP 12 ----------

  {
    key: 'step12',
    text: { value: 'С 3 по 7 секунду было \nпройдено 80 метров. Какова \nбыла скорость?' },
    patchData: {
      ...defaultPathData,
      questionCardVisible: true,
      questionCardTxt: 'Скорость, м/с:',


      inputSuccess: 20,

      mainChartImg: PAS6mainChart2Descr2Img,
    },
    next_btn_hidden: true,
    previous: 'step11',
    next_success: 'step12.2',
    next_failure: 'step12.1',
  },

  {
    key: 'step12.1',
    text: { value: 'С 3 по 7 секунду было \nпройдено 80 метров. Какова \nбыла скорость?' },
    patchData: {
      ...defaultPathData,
      errorVisible: true,
      questionCardVisible: true,
      questionCardTxt: 'Скорость, м/с:',


      inputSuccess: 20,

      mainChartImg: PAS6mainChart2Descr2Img,
    },
    previous: 'step12',
    next_success: 'step12.2',
    next_failure: 'step12.1',
    next_btn_hidden: true,
  },
  {
    //галочка
    key: 'step12.2',
    text: { value: 'С 3 по 7 секунду было \nпройдено 80 метров. Какова \nбыла скорость?' },
    patchData: {
      ...defaultPathData,
      successVisible: true,

      mainChartImg: PAS6mainChart2Descr2Img,
    },
    previous: 'step12',
    next_success: 'step13',
    switch_at: 5,
  },

// --------- STEP 13 ----------

  {
    key: 'step13',
    text: { value: 'Построй график скорости \nна основе графика пути:' },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      chartWithDragAndDropVisible: true,

      itemsColumnArr: Array(7).fill(1),
      selectedData: Array(7).fill(1).reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),
      chartImgSetting: getChartImgSetting('pathandspeed6', Array(5).fill(1), 'step13'),
      selectedDataSuccessMultipleVal: Array(7).fill(1).map((el, i) => i < 3 ? 40 : 20),

      mainChartImg: PAS6mainChart2Img,
    },
    next_btn_hidden: true,
    previous: 'step12',
    next_success: '13.1',
    next_failure: 'step13',
  },

  {
    //заключительная галочка
    key: '13.1',
    text: { value: 'Построй график скорости \nна основе графика пути:' },
    patchData: {
      ...defaultPathData,
      rightChartVisible: true,
      taskCompleteVisible: true,

      mainChartImg: PAS6mainChart2Img,
    },
    previous: 'step13',
    next_btn_hidden: true,
  },

];



const replaceMotorbikeToSpaceship = (title) => {
  let newTitle = title;
  const replArr = [
    {value: 'мотоциклистом', newWord: 'кораблем'},
    {value: 'мотоциклиста', newWord: 'корабля'},
    {value: 'мотоциклист', newWord: 'корабль'},
    {value: 'мотоцикла', newWord: 'корабля'},
    {value: 'мотоциклы', newWord: 'корабли'},
    {value: 'мотоциклом', newWord: 'кораблем'},
    {value: 'мотоциклов', newWord: 'кораблей'},
    {value: 'мотоцикл', newWord: 'корабль'},
    {value: 'проехал', newWord: 'преодолел путь'},
    {value: 'проезжал', newWord: 'преодолевал'},
    {value: 'ехал', newWord: 'двигался'},
  ]

  replArr.forEach((item) => {
    const re = new RegExp(item.value, 'gi');
    newTitle = newTitle.replace(re, item.newWord);
  })
  return newTitle;
}
const updateStepTitle = (step) => {
  let title = step.text.value;
  if (title) {
    step.text.value = replaceMotorbikeToSpaceship(title);
  }
  return step;
}
export const pathAndSpeedWithSpaceshipScenario = cloneDeep(pathAndSpeedScenario).map((step) => {
  return updateStepTitle(step);
});
export const pathAndSpeedWithSpaceship2Scenario = cloneDeep(pathAndSpeedScenario2).map((step) => {
  return updateStepTitle(step);
});
