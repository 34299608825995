import initialMp4 from "../../../../images/space/loss_satellite/Antenna_start.mp4";
import {showFailOrSuccessPopup} from "../../../../utils/common";


export const lossOfSatelliteDishDefaultPatchData = {
  title: '',

  animationSrc: undefined,
  cyberpunkCardVisible: false,
  arrowsVisible: false,
  failTextVisible: false,
  theoryVisible: false,
  lsnComplete: false,
}

const step2Default = {
  ...lossOfSatelliteDishDefaultPatchData,
  title: 'Определи, на каком расстоянии от ровера находится антенна. \n' +
    'Известно, что антенна двигалась вместе с ветром со скоростью \n' +
    '20 м/с, а ровер двигался в противоположном \nнаправлении со скоростью 3,5 м/с. \n' +
    'Время движения ровера и антенны после отрыва составило 8с.',

  arrowsVisible: true,
  cyberpunkCardVisible: true,
  cyberpunkBtnClick: (comp) => {
    const success = Number(comp.data.inputDistanceToSatelliteDish) === 188;
    showFailOrSuccessPopup(comp, success);
    if (success || comp.data.errCounter >= 1) {
      comp.scenarioManager.selectStepByKey('theory');
    } else {
      comp.data.errCounter++
      comp.scenarioManager.selectStepByKey('step 2 fail');
    }
  }
}

export const lossOfSatelliteDishScenario = [

  {
    key: 'initial',
    patchData: {
      ...lossOfSatelliteDishDefaultPatchData,
      animationSrc: initialMp4
    },
    next_success: 'step 2',
    // switch_at: 1
  },

  {
    key: 'step 2',
    patchData: {
      ...step2Default
    },
    next_success: 'theory',
  },{
    key: 'step 2 fail',
    patchData: {
      ...step2Default,
      failTextVisible: true
    },
    next_success: 'theory',
  },

  {
    key: 'theory',
    patchData: {
      ...lossOfSatelliteDishDefaultPatchData,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        comp.data.inputDistanceToSatelliteDish = undefined;
        if (comp.data.errCounter >= 1) {
          comp.data.errCounter = 0;
          return comp.scenarioManager.selectStepByKey('step 2');
        }
        return comp.scenarioManager.success();
      }
    },
    next_success: 'success'
  },

  {
    key: 'success',
    patchData: {
      ...lossOfSatelliteDishDefaultPatchData,
      theoryVisible: true,
      lsnComplete: true,
    },
  }
]