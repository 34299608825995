import React from 'react';
import {Group, Text} from 'react-konva';
import {_t} from "../../../../utils/lang/common";


const Distance = ({amount, style, reffer}) => {
  const {styles} = Distance;

  return (
    <Group x={700} y={50}>
      <Text
        ref={reffer}
        text={_t('balloon.distance', {amount: amount})}
        strokeWidth={1}
        fontSize={styles.Amount.fontSize}
        stroke={styles.This.color}
        fill={styles.This.color}
      />
    </Group>
  );
};

Distance.styles = {
  This: {
    color: '#457b9d',
  },
  Amount: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'inherit',
  },
  Value: {
    color: 'inherit',
  },
};

export default Distance;
