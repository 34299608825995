import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getCorrectLang} from "../../../../utils/lang/common";
import * as actions from "../../../../store/actions";
import {TaskListWrapper} from "../components/Wrappers";
import LessonItem from "../components/LessonItem";
import '../styles/layout.css';
import {GroupTitle} from "../components/GroupTitle";
import {SubscriptionModal} from "../components/SubscriptionModal";
import {ChapterTitle} from "../components/ChapterTitle";
import MainContainer from "../components/MainContainer";
import {filterListData, getLessonListData, userRoles} from "../../../../utils/common";
import {Divider, Spin} from "antd";
import {layout2} from "../../../../utils/styles";
import LayoutSideMenu from "../components/LayoutSideMenu";
import TeacherCatalogItem from "../components/TeacherCatalogItem";
import InfiniteScroll from "react-infinite-scroll-component";


const Layout = (props) => {
  const {
    user,
    setLanguage,
    fetchHwTeacherNotifications,

    // catalog
    selectedGradeNumber,
    selectedParagraph,
    selectedChapter,
    selectedLesson,

    setCatalogGrade,
    setCatalogChapter,
    setCatalogParagraph,
    setCatalogLesson,
    setShowAllLessons,
    showAllLessons
  } = props;

  const [loading, setLoading] = useState(false);

  const [showPremium, setShowPremium] = useState(false);

  const defCatalogState = {lessons: [], chapters: [], lessonsParagraphs: []};
  const [{ lessons, chapters, lessonsParagraphs }, setCatalogState] = useState(defCatalogState);
  const [paginationState, setPaginationState] = useState({nextUrl: '', maxLessonsCount: 0});

  useEffect(() => {
    const correctLang = getCorrectLang();
    setLanguage(correctLang);
    (async function () {
      setLoading(true);

      /* Фетчим классы, главы,  параграфы */
      const lessonsParagraphsResp = await props.fetchLessonsParagraphsForCatalog();
      /* Фетчим задачи (с пагинацией) */
      const newLessons = await fetchLessons();

      if (lessonsParagraphsResp) {
        /* Записываем параграфы и главы */
        setCatalogState(prev => ({
          ...prev,
          lessons: newLessons,
          chapters: lessonsParagraphsResp.chapters || [],
          lessonsParagraphs: lessonsParagraphsResp.lessonsGroups || [],
        }));
      }
      await fetchHwTeacherNotifications();
      await props.fetchHomework();
      setLoading(false);
    })()
  }, []);


  useEffect(() => {
      fetchLessons(true);
  }, [selectedParagraph, selectedChapter, selectedGradeNumber])

  const fetchLessons = async (resetNextUrl) => {

    /* Сбрасываем url с номером стр. при выборе нового параграфа */
    const nextUrl = resetNextUrl ? '' : paginationState.nextUrl
    const lessonsResp = await props.fetchLessonsCatalog({
      isTeacherCardCatalog: props.teacherCardsCatalog,
      selectedParagraphId: selectedParagraph?.id,
      selectedChapterId: selectedChapter?.id,
      selectedGradeNumber,
      nextUrl
    });

    /* Записываем данные пагинации */
    setPaginationState({
      nextUrl: lessonsResp?.links?.next,
      maxLessonsCount: lessonsResp?.count
    });

    /* Записываем данные заданий
    (если выбрали новый параграф, то переписываем весь список заданий,
     иначе добавляем к существующему) */
    const newLessons = lessonsResp?.results || [];
    setCatalogState(prev => {
      let lessons = prev.lessons.concat(newLessons);
      if (resetNextUrl) {
        lessons = newLessons;
      }
      return (
          {...prev, lessons: lessons})
      }
    );
    return newLessons
  }

  const {combinedLessons, sortedChapters, grades } = getLessonListData(chapters, lessons, lessonsParagraphs);

  /** Фильтрация каталога */
  const {
    filteredCatalogGrades, filteredCatalogParagraphs, filteredCatalogChapters
  } = filterListData(grades, lessonsParagraphs, sortedChapters, selectedParagraph, combinedLessons);


  const newCardStyle = !props.teacherCardsCatalog;

  const isTeacher = user && user.role === userRoles.teacher;
  return (
    <div className={`layout2 ${newCardStyle && 'lessons--new-grid'}`} id={'layout2'}>
      <MainContainer
        withDescription={!props.teacherCardsCatalog}
        pageKey={props.teacherCardsCatalog ? 'teacher-card' : 'tasks'}
        menuChildren={
          <LayoutSideMenu
            grades={grades}
            selectedGradeNumber={selectedGradeNumber}
            setCatalogGradeNumber={(val) => {
              setShowAllLessons(!val && !selectedChapter);
              setCatalogChapter(undefined);
              setCatalogParagraph(undefined);
              setCatalogGrade(val);
              window.scrollTo(0, 0);
            }}

            selectedChapter={selectedChapter}
            setCatalogChapter={(val) => {
              setShowAllLessons(false);
              setCatalogParagraph(undefined);
              setCatalogChapter(val ? val.id : val);
              window.scrollTo(0, 0);
            }}

            lessonsParagraphs={lessonsParagraphs}
            selectedParagraph={selectedParagraph}
            setCatalogParagraph={(val) => {
              setShowAllLessons(false);
              setCatalogParagraph(val ? val.id : val);
              window.scrollTo(0, 0);
            }}

            resetCatalogSelectedItems={props.resetCatalogSelectedItems}

            showAllLessons={showAllLessons}
            setShowAllLessons={setShowAllLessons}
          />
        }
      >
        <TaskListWrapper loading={loading}>
          <InfiniteScroll
            dataLength={lessons.length}
            next={() => fetchLessons()}
            hasMore={paginationState.maxLessonsCount > lessons?.length}
            // scrollableTarget="app__main-container"
            loader={
              <div style={{margin: '20px auto', display: 'flex', justifyContent: 'center'}}>
                <Spin spinning={true}/>
              </div>
            }
            endMessage={
              <Divider style={{margin: '20px 0'}} />
            }
          >
          {
            Object.keys(filteredCatalogGrades).length ?
              Object.keys(filteredCatalogGrades).map(gradeNum => {
                const chaptersOfGrade = filteredCatalogGrades[gradeNum];
                const corrChapters = filteredCatalogChapters.filter(ch => Number(gradeNum) === ch.grade);
                return (
                  <React.Fragment key={`grade-${gradeNum}`}>
                    <div id={`grade-${gradeNum}`} style={{marginTop: '77px'}}>
                      <ChapterTitle chapter={{title: gradeNum+' класс'}} fontFamily={'Roboto-Black'} />
                      {
                        corrChapters.map((ch, i) => {
                          const paragraphsToDisplay = filteredCatalogParagraphs.filter(p => p.show_on_platform && p.chapter === ch.id);
                          return (
                            <div id={`chapter-${ ch.id }`} key={ `chapter-${ ch.id }` } style={{marginTop: i===0 ? '0' : '60px'}}>
                              <ChapterTitle chapter={ ch }/>
                              {
                                paragraphsToDisplay?.map(p => {
                                  const paragraphId = p.id;
                                  const lessonsItems = combinedLessons[paragraphId];
                                  const lessonsEmpty = !lessonsItems || !lessonsItems.length;
                                  return (
                                    <div id={`paragraph-${ paragraphId }`} style={{marginBottom: '35px'}} key={ `selectedParagraphs-${ paragraphId }` }>
                                      <GroupTitle lessonsEmpty={lessonsEmpty} title={ `${ lessonsParagraphs.find(g => g.id === parseInt(paragraphId))?.title }` }/>
                                      <div className={'lessonsContainer'}>
                                        {
                                          !lessonsEmpty ?
                                            lessonsItems?.map((l, i) => (
                                              <React.Fragment key={ `lessonItem-${ i }` }>
                                                {
                                                  props.teacherCardsCatalog ? (
                                                    <TeacherCatalogItem
                                                      lessonItem={ l }
                                                    />
                                                  ) : (
                                                    <LessonItem
                                                      newCardStyle={newCardStyle}
                                                      lessonItem={ l }
                                                      selectedLesson={ selectedLesson }
                                                      setSelectedLesson={ setCatalogLesson }
                                                      setShowPremium={ setShowPremium }
                                                      user={ user }
                                                      teacherPage={isTeacher}
                                                    />
                                                  )
                                                }
                                              </React.Fragment>
                                            ))
                                            :
                                            <span style={{color: 'gray'}}>--- Нет заданий этой категории ---</span>
                                        }
                                      </div>
                                    </div>)
                                })
                              }
                            </div>
                          )}
                        )
                      }
                    </div>
                 </React.Fragment>
                )
              })
              : null
          }
          </InfiniteScroll>
        </TaskListWrapper>
      </MainContainer>
      <SubscriptionModal showPremium={showPremium} setShowPremium={setShowPremium}/>
    </div>
  )
};


const mapStateToProps = (state, {match}) => {
  const chapters = state.lessonsReducer.chapters;
  const lessonsParagraphs = state.lessonsReducer.lessonsGroups;
  return {
    lessons: state.lessonsReducer.lessons,
    chapters: chapters,
    lessonsParagraphs: lessonsParagraphs,
    user: state.lessonsReducer.user,

    selectedGradeNumber: state.catalogReducer.selectedGrade,
    selectedChapter: chapters.find(el => el.id === state.catalogReducer.selectedChapterId),
    selectedParagraph: lessonsParagraphs.find(el => el.id === state.catalogReducer.selectedParagraphId),
    selectedLesson: state.catalogReducer.selectedLesson,
    showAllLessons: state.catalogReducer.showAllLessons,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHomework: () => dispatch(actions.fetchHomework(true)),
    setLanguage: (lang) => dispatch(actions.setLanguage(lang)),
    logoutAction: () => dispatch(actions.logoutAction()),
    fetchHwTeacherNotifications: () => dispatch(actions.fetchHwTeacherNotifications()),
    fetchLessonsCatalog: (props) => dispatch(actions.fetchLessonsCatalog(props)),
    fetchLessonsParagraphsForCatalog: () => dispatch(actions.fetchLessonsParagraphsForCatalog()),

    resetCatalogSelectedItems: () => dispatch(actions.resetCatalogSelectedItems()),
    setCatalogGrade: (grade) => dispatch(actions.setCatalogGrade(grade)),
    setCatalogChapter: (chapterId) => dispatch(actions.setCatalogChapter(chapterId)),
    setCatalogParagraph: (paragraphId) => dispatch(actions.setCatalogParagraph(paragraphId)),
    setCatalogLesson: (lesson) => dispatch(actions.setCatalogLesson(lesson)),
    setShowAllLessons: (status) => dispatch(actions.setShowAllLessons(status)),
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);
