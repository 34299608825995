import SuccessScreen from "../../successScreen/containers/SuccessScreen";
import {CloseCircleOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";


const ProgressModal = (props) => {
  const {
    successScreenVisible,
    setSuccessScreenVisible
  } = props;

  const [points, setPoints] = useState(0);
  const [initialPoints, setInitialPoints] = useState(undefined);


  useEffect(() => {
    const pointsNeedToBeUpdate = (props.points !== points) && (props.points !== props.initialPoints);
    if (pointsNeedToBeUpdate) {
      setSuccessScreenVisible(Boolean(props.points));
      setTimeout(() => {
        setPoints(props.points);
      }, 200);
    }
  }, [props.points]);

  useEffect(() => {
    /** set initial points */
    setTimeout(() => setInitialPoints(props.initialPoints), 100);
  }, [props.initialPoints])

  /**
   * initialPoints !== undefined - для того, чтобы очки не показывались при инициализации с 0
   * ``` переделывать не стал, опасаясь сломать ```
   */
  return (
    <>
      {
          initialPoints !== undefined ? (
            <div
              className={'course-map__modal course-map__success-screen'}
              style={{zIndex: successScreenVisible ? 100 : -1, opacity: Number(successScreenVisible)}}
            >
              <SuccessScreen points={points} initialPoints={initialPoints} setVisible={setSuccessScreenVisible}/>
              <div
                className={'course-map__modal-close'}
                style={{opacity: Number(successScreenVisible)}}
                onClick={() => setSuccessScreenVisible(false)}
              >
                <CloseCircleOutlined/>
              </div>
            </div>
          ) : null
      }
    </>
  )
}


const mapStateToProps = state => {
  return ({
    initialPoints: state.mapReducer.initialPoints,
    points: state.mapReducer.points,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgressModal);
