import React, { Component } from "react";
import Konva from "konva";
import { Transformer } from "react-konva";
import rotateArrow from '../../../images/bondGas/rotateArrow.png'
import {radToDeg} from "../../../utils/common";

const smallRectStg = {
  stroke:"rgba(0,0,0,0)",
  fill:"rgba(0,0,0,0)",
  x:5, y: 0,
  width:35,
  height:70,
};

class CanvasTransformer extends Component {
  constructor(props) {
    super(props);

    this.checkNode = this.checkNode.bind(this);
  }

  componentDidMount() {
    this.checkNode();
    this.transformer.find(".back").sceneFunc(ctx => {
      const tr = this.transformer;
      const shape = this.transformer.find(".back")[0];
      const padding = tr.getPadding();
      ctx.beginPath();
      ctx.moveTo(shape.width() / 2, shape.height() + padding);
      if (tr.rotateEnabled()) {
        // after enable
        tr.find("Rect").setAttrs(smallRectStg);
      }
      ctx.fillStrokeShape(shape);
    });

    this.props.getSetTransformRotation(this.setRotation)
  }

  componentDidUpdate() {
    this.checkNode();
  }

  setRotation = (angl) => {
    const stage = this.transformer.getStage();
    const { selectedShapeName } = this.props;
    const selectedNode = stage.findOne("." + selectedShapeName);
    selectedNode.rotation(angl);
    this.transformer.rotation(angl);
    this.transformer.attachTo(selectedNode);
    this.transformer.getLayer().batchDraw();
  }

  checkNode() {
    const stage = this.transformer.getStage();
    const { selectedShapeName } = this.props;

    const selectedNode = stage.findOne("." + selectedShapeName);
    if (selectedNode === this.transformer.node()) {
      return;
    }
    if (selectedNode) {
      this.transformer.attachTo(selectedNode);
    } else {
      this.transformer.detach();
    }
    this.transformer.getLayer().batchDraw();
  }

  transformBoundBoxFunc = (oldBox, newBox) => {
    const newBoxRotation = -radToDeg(newBox.rotation);
    if (newBoxRotation <= 0 || newBoxRotation >= 2800) {
      return oldBox
    }
    return newBox;
  }

  render() {
    return (
      <Transformer
        ref={node => {
          this.transformer = node;
        }}
        keepRatio={false}
        resizeEnabled={false}
        rotateAnchorOffset={0}
        onTransform={(e) => {
          const currTarget = e.currentTarget;
          if (this.props.onRotation) {
            this.props.onRotation(currTarget.rotation());
          }
        }}
        boundBoxFunc={this.transformBoundBoxFunc}
      />
    );
  }
}

export default CanvasTransformer;
