import React, {useEffect, useRef, useState} from "react";
import {Group, Image, Rect} from "react-konva";

import defaultSwitchBtnImg from '../../../images/crane/switchBtn.png';
import defaultSwitchTrackActiveImg from '../../../images/crane/switchTrackGreen.png';
import defaultSwitchTrackImg from '../../../images/crane/switchTrackPurple.png';
import useImage from "use-image";


/**
 *  PROPS
 *
 *  x, y: numbers
 *  switchFieldName: string
 *  externalData: obj -- for getting dynamic active switch parameter
 *  switchTrackImg, switchTrackActiveImg, switchBtnImg: string -- images for component
 *  onClick: () => void -- click method
 *
 */

const RadioSwitch = (props) => {
  const {
    x, y,

    externalData,

    onClick = () => {},

    switchFieldName,

    switchTrackImg,
    switchTrackActiveImg,
    switchBtnImg,
  } = props;

  const [state, setState] = useState(0);

  const [switchBtn] = useImage(switchBtnImg || defaultSwitchBtnImg);
  const [switchTrackPurple] = useImage(switchTrackImg || defaultSwitchTrackImg);
  const [switchTrackGreen] = useImage(switchTrackActiveImg || defaultSwitchTrackActiveImg);


  const handleClick = () => {
    onClick();
    setState(state+1);
  }

  const isActive = externalData[switchFieldName];

  const trackOpacity = Number(!isActive);
  const trackActiveOpacity = Number(isActive);
  const btnOffsetX = isActive ? -24 : 0;

  return (
    <Group x={x} y={y} onTap={handleClick} onClick={handleClick}>
      <Image image={switchTrackGreen} opacity={trackActiveOpacity}/>
      <Image image={switchTrackPurple} opacity={trackOpacity}/>
      <Image image={switchBtn} x={3} y={2} offsetX={btnOffsetX}/>
    </Group>
  )
}

export default RadioSwitch;
