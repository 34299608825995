import React from "react";
import CanvasContainer from "../../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";

import illustrationBlockImg from "../../../../../images/ilustrations/illustration_block.png";
import illustrationVorotImg from "../../../../../images/ilustrations/illustration_vorot.png";
import * as actions from "../../../../../store/actions";
import ScenarioManager from "../../../../../utils/ScenarioManager";
import {sendSuccessForScenario} from "../../../../../utils/common";



class Illustration extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',
    ]

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 1000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['stage'].draw();
  }


  Canvas = () => {

    let correctImg = '';
    if (this.props.code === 'illustration_block1') {
      correctImg = illustrationBlockImg;
    }
    if (this.props.code === 'illustration_vorot1') {
      correctImg = illustrationVorotImg;
    }

    const [background] = useImage(correctImg);

    return (
      <React.Fragment>
        <Image image={background}/>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Illustration);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
