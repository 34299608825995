import React from "react";
import cloneDeep from "lodash.clonedeep";
import throttle from "lodash.throttle";
import useImage from "use-image";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Circle, Group, Image, Layer, Line, Rect, Text} from "react-konva";
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {spaceshipTakeOffElementsUI, spaceshipTakeOffScenario} from "../utils/spaceshipTakeOffScenario";

import spaceshipImg from '../../../../images/space/spaceship_take_off/spaceship.png';

import launchFacilityImg from '../../../../images/space/spaceship_take_off/launchFacility.png';
import launchGroundImg from '../../../../images/space/spaceship_take_off/launchGround.png';
import launchServiceFarmImg from '../../../../images/space/spaceship_take_off/launchServiceFarm.png';

import spshipTrajectoryImg from '../../../../images/space/spaceship_take_off/spshipTraectory.png';
import answerWindowImg from '../../../../images/space/spaceship_take_off/answerWindow.png';

import fuelIndicatorBorderImg from '../../../../images/space/spaceship_take_off/fuelIndicatorBorder.png';

import btnStartImg from '../../../../images/space/spaceship_take_off/btnStart.png';
import btnFuelImg from '../../../../images/space/spaceship_take_off/btnFuel.png';

import btnLeftImg from '../../../../images/space/spaceship_take_off/btnLeft.png';
import btnRightImg from '../../../../images/space/spaceship_take_off/btnRight.png';
import CanvasButton from "../../../canvas/components/CanvasButton";
import {GIF} from "../../../canvas/components/GIF";
import TheoryCanvas from "../components/TheoryCanvas";
import Video from "../../../canvas/components/Video";
import Inertia_theory_q1Mp4 from "../../../../images/space/spaceship_take_off/theory/Inertia_theory_q1.mp4";
import Inertia_theory_q2Mp4 from "../../../../images/space/spaceship_take_off/theory/Inertia_theory_q2.mp4";
import Inertia_theory_q3Mp4 from "../../../../images/space/spaceship_take_off/theory/Inertia_theory_q3.mp4";
import Inertia_theory_q4Mp4 from "../../../../images/space/spaceship_take_off/theory/Inertia_theory_q4.mp4";
import {defaultImgOrVideoStyle} from "../utils/theoryGenerator";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import { getTimeDeltaSec } from "../../../../utils/common";
import flameGif from "../../../../images/space/spaceship_take_off/rocket_flame2.gif";
import smokeGif from "../../../../images/space/spaceship_take_off/Smoke.gif";
import explosionGif from "../../../../images/space/spaceship_take_off/explosion.gif";
import spacebreakGif from "../../../../images/space/spaceship_landing/spacebreak.gif";
import {layout2} from "../../../../utils/styles";
import * as actions from "../../../../store/actions";



class SpaceshipTakeOff extends React.Component {
  constructor(props) {
    super(props);
    this.managedComponents = [
      'stage',

      'title',

      'launchServiceFarm',

      'heightText',
      'speedText',

      'atmosphereY',

      'spaceship',

      'fuelIndicator',
    ];

    this.staticData = {
      fuelMaxWidth: 245,
      correctFuelPercent: 80, // минимальный подходящий процент топлива
      maxHeight: 70,
    };
    this.elementsUI = spaceshipTakeOffElementsUI;
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      atmosphereY: 0, // y для передвижения сферы при полете
      atmosphereX: 0,

      launchServiceFarmRotation: 0,
      spaceshipRotation: 0,
      spaceshipRotationSpeed: 0,

      fuelPercent: 0,

      spaceshipY: 0,

      refuelingTank: false,

      ...this.elementsUI,
    }
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(spaceshipTakeOffScenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
    document.addEventListener("keydown", (e) => this.handleKeyAction(e,));
    document.addEventListener("keyup", (e) => this.handleKeyAction(e,));
  }

  handleKeyAction = (event) => {
    // console.log('debug on event key code', event);
    switch (event.code) {
      case 'ArrowLeft':
        if (event.type === 'keydown') {
          this.data.btnRotateAction = 'left';
        } else {
          this.data.btnRotateAction = undefined;
        }
        break;
      case 'ArrowRight':
        if (event.type === 'keydown') {
          this.data.btnRotateAction = 'right';
        } else {
          this.data.btnRotateAction = undefined;
        }
        break;
    }
    this.forceUpdate();
  };

  get spaceshipRotation() {
    const data = this.data;
    if (data.btnRotateAction) {
      data.controllerAdditionAction(this); // переход на следующий шаг при нажатии
      switch (data.btnRotateAction) { // поворот корабля в полете
        case 'left':
          data.spaceshipRotationSpeed = data.spaceshipRotationSpeed - data.timedeltaSec * 30;
          break;
        case 'right':
          data.spaceshipRotationSpeed = data.spaceshipRotationSpeed + data.timedeltaSec * 30;
          break;
      }
    }

    if (Math.abs(data.spaceshipRotation) < 5) {
        // throttle(() => data.spaceshipRotation = (Math.random() - 0.5) * 30, 1000);
        // console.log('debug on before debounce');
        this.randomNoiseOnDirection()
    } else {
      // data.spaceshipRotationSpeed += data.spaceshipRotation % 180 * data.timedeltaSec * 0.5
    }


    data.spaceshipRotation += data.spaceshipRotationSpeed * data.timedeltaSec;

    return data.spaceshipRotation;
  }

  fuelPercentToPx = (fuelPercent) => {
    const fuelMaxWidth = this.staticData.fuelMaxWidth;
    const val = (fuelMaxWidth / 100) * fuelPercent;
    return val >= 0 ? val : 0;
  }

  onClickRestart = () => {
    this.data = cloneDeep(this.initialData);
    this.data.fuelPercent = 100;
    this.updateStage();
    this.scenarioManager.selectStepByKey('spaceship flying');
  };
  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  get fuelPercent() {
    const data = this.data;
    if (data.refuelingTank) {
      return data.fuelPercent >= 100 ? 100 : data.fuelPercent + .2;
    } else {
      return data.fuelPercent;
    }
  }

  randomNoiseOnDirection = throttle(
    () => {
      const data = this.data;
      data.spaceshipRotationSpeed += (Math.random() - 1) * 5
    }
    ,2000
  );

  move = (time) => {
    const staticData = this.staticData;
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = getTimeDeltaSec(timedelta);
    data.timedeltaSec = timedeltaSec;


    if (data.rocketReadyStart) {
      const maxRotation = 30;
      data.launchServiceFarmRotation = data.launchServiceFarmRotation + .1; // поворот платформы при взлете
      if (data.launchServiceFarmRotation >= maxRotation) {
        data.launchServiceFarmRotation = maxRotation;
        this.scenarioManager.next();
      }
    }

    if (data.rocketTakeOff) {
      data.spaceshipY += (.1+(data.spaceshipY/2)*timedeltaSec);

      if (data.spaceshipY >= 500) {
        this.scenarioManager.next();
      }
    }
    if (data.controllerVisible) {
      data.spaceshipRotation = this.spaceshipRotation;
    }

    if (data.spaceshipFlying) {
      data.atmosphereY += 600 * timedeltaSec * Math.cos(data.spaceshipRotation * Math.PI / 180);
      data.atmosphereX += 600 * timedeltaSec * Math.sin(data.spaceshipRotation * Math.PI / 180);

      if ( Math.abs(data.atmosphereX) > 4000 ) {
        data.onFailure(this, 'border limit');
      }

      let heightVal = data.atmosphereY;
      if ( heightVal <= 0 ) {
        data.onFailure(this, 'fell');
      }
    }

    if (
      (data.fuelPercent <= 0) &&
      data.spaceshipFlying
    ) {
      data.onFuelEnd(this);
    }

    if (data.fuelConsumptionIsActive) {
      data.fuelPercent = data.fuelPercent <= 0 ? 0 : data.fuelPercent - 5 * data.timedeltaSec; // расход топлива
    }

    data.fuelPercent = this.fuelPercent; // наполнение бака

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['launchServiceFarm'].rotation(-data.launchServiceFarmRotation);

    const fuelPxWidth = this.fuelPercentToPx(data.fuelPercent);
    n['fuelIndicator'].width(fuelPxWidth);


    let heightVal = (data.atmosphereY / 150).toFixed(1);
    n['heightText'].text(heightVal+" км");

    n['atmosphereY'].y(data.atmosphereY);
    n['atmosphereY'].x(-data.atmosphereX);


    n['spaceship'].y(-data.spaceshipY);
    n['spaceship'].rotation(data.spaceshipRotation);

    n['stage'].draw();
  }


  onClickStart = () => {
    const fuelCorr = this.data.fuelPercent >= this.staticData.correctFuelPercent;
    if (fuelCorr) {
      this.scenarioManager.next();
    }
  }
  onClickController = (type) => {
    this.data.btnRotateAction = type;
  }
  onPressFuelBtn = () => {
    this.data.refuelingTank = true;
  }
  onReleaseFuelBtn = () => {
    this.data.refuelingTank = false;
  }

  Spaceship = (props) => {
    const data = this.data;
    const [spaceship] = useImage(spaceshipImg);

    return (
      <Group {...props}>
        <Line visible={data.selectTrajectory} offsetY={data.spaceshipY} points={[0,0,0,400]} dash={[6, 2]} stroke={'white'}/>
        <Group rotation={data.selectTrajectory ? 20 : 0} ref={this._ref('spaceship')}>
          <Line visible={data.selectTrajectory} y={0} points={[0,0,0,-400]} dash={[6, 2]} stroke={'white'}/>
          <Image offsetY={386/2} offsetX={78/2} image={spaceship} scale={data.spaceshipScale}/>

          {
            data.explosionVisible && (
              <GIF src={explosionGif} y={-90} x={130} rotation={90} scale={.75}/>
            )
          }
          <GIF visible={data.explosionVisible} src={spacebreakGif} y={-110} x={200} rotation={90} scale={.75} loop={true}/>

          {
            (data.fuelPercent > 0 && data.spaceshipFlying) && (
              <>
                <GIF src={flameGif} y={38} x={-.5} scale={.25} loop={true} opacity={.75}/>
                <GIF src={flameGif} y={38} x={-6} scale={.25} loop={true} opacity={.75}/>
              </>
            )
          }
          {
            (data.rocketTakeOff) && (
              <>
                <GIF src={flameGif} y={170} x={0} scale={1} loop={true} opacity={.75}/>
                <GIF src={flameGif} y={170} x={-27} scale={1} loop={true} opacity={.75}/>
              </>
            )
          }
        </Group>
      </Group>
    )
  };

  Env = () => {
    const data = this.data;

    const [spshipTrajectory] = useImage(spshipTrajectoryImg);
    const [answerWindow] = useImage(answerWindowImg);

    const [fuelIndicatorBorder] = useImage(fuelIndicatorBorderImg);
    const [btnStart] = useImage(btnStartImg);
    const [btnFuel] = useImage(btnFuelImg);

    const [btnLeft] = useImage(btnLeftImg);
    const [btnRight] = useImage(btnRightImg);

    const [launchFacility] = useImage(launchFacilityImg);
    const [launchGround] = useImage(launchGroundImg);
    const [launchServiceFarm] = useImage(launchServiceFarmImg);

    const btnControllerSize = 90;
    return (
      <React.Fragment>

        <Text
          x={70} y={50}
          text={data.title}
          lineHeight={1.2}
          ref={this._ref('title')}
          fill={'white'} fontSize={30}
        />
        <Text
          x={503} y={86}
          text={data.secondTitle}
          lineHeight={1.2}
          fill={'white'} fontSize={30}
          fontStyle={'bold'}
        />


        {data.rocketTakeOff && <Group visible={data.rocketTakeOff}>
          <Group x={210} y={330} rotation={0}>
            <GIF src={smokeGif} loop={false}/>
          </Group>
        </Group>}

        <Group visible={data.launchStationVisible}>
          <Group x={502} y={443} rotation={0} height={280} ref={this._ref('launchServiceFarm')}>
            <Image offsetX={55} offsetY={255} image={launchServiceFarm}/>
          </Group>

          <Image x={465} y={472} image={launchFacility}/>
          <Image y={457} image={launchGround}/>
        </Group>

        <Group y={200}>

          <CanvasButton
            visible={data.fuelBtnVisible}
            x={130} y={0}
            onTouchStart={() => this.onPressFuelBtn()}
            onMouseDown={() => this.onPressFuelBtn()}
            onTouchEnd={() => this.onReleaseFuelBtn()}
            onMouseUp={() => this.onReleaseFuelBtn()}
            onMouseLeave={() => this.onReleaseFuelBtn()}
          >
            <Image scale={{x: .8, y: .8}} image={btnFuel}/>
          </CanvasButton>


          <CanvasButton
            visible={data.startBtnVisible}
            x={730} y={0}
            onClick={() => this.onClickStart()}
          >
            <Image scale={{x: .8, y: .8}} image={btnStart}/>
          </CanvasButton>


          <Group x={70} y={190} visible={data.indicatorVisible}>
            <Text text={'Топливо:'} y={-30} fill={'white'} fontSize={20}/>
            <Group x={5} y={5}>
              <Rect width={245} height={55} cornerRadius={10} fill={'lightgray'}/>
              <Rect ref={this._ref('fuelIndicator')} width={35} height={55} cornerRadius={5} fill={'#E36E31'}/>
            </Group>
            <Image image={fuelIndicatorBorder}/>
          </Group>
        </Group>



        <Group x={70} y={250} visible={data.infoDataVisible}>
          <Group>
            <Text text={'Высота:'} fill={'white'} fontSize={20}/>
            <Text ref={this._ref('heightText')} x={110} y={0} text={'0 км'} fill={'white'} fontSize={20} fontStyle={'bold'}/>
          </Group>
        </Group>


        <Group x={780} y={420} visible={data.controllerVisible}>
          <CanvasButton
            opacity={data.btnRotateAction === 'left' ? 0.8 : undefined}
            x={-btnControllerSize/1.5} y={0}
            onTouchStart={() => this.onClickController('left')}
            onMouseDown={() => this.onClickController('left')}
            onTouchEnd={() => this.onClickController(undefined)}
            onMouseUp={() => this.onClickController(undefined)}
            onMouseLeave={() => this.onClickController(undefined)}
          >
            <Image width={btnControllerSize} height={btnControllerSize} image={btnLeft}/>
          </CanvasButton>
          <CanvasButton
            opacity={data.btnRotateAction === 'right' ? 0.8 : undefined}
            x={btnControllerSize/1.5} y={0}
            onTouchStart={() => this.onClickController('right')}
            onMouseDown={() => this.onClickController('right')}
            onTouchEnd={() => this.onClickController(undefined)}
            onMouseUp={() => this.onClickController(undefined)}
            onMouseLeave={() => this.onClickController(undefined)}
          >
            <Image width={btnControllerSize} height={btnControllerSize} image={btnRight}/>
          </CanvasButton>
        </Group>

        <Group visible={data.selectTrajectory}>
          <Group x={570} y={200}>
            <CanvasButton
              text={'Траектория 1'}
              onClick={() => {data.onClickTrajectoryBtn(this, true)}}
              fontSize={23}
              strokeWidth={.2}
              btnCornerRadius={0}
              btnFill={'transparent'}
              btnStroke={'white'}
              btnStrokeWidth={1}
              width={190}
              height={45}
              x={0} y={-50}
            />
            <CanvasButton
              text={'Траектория 2'}
              onClick={() => {data.onClickTrajectoryBtn(this, false)}}
              fontSize={23}
              strokeWidth={.2}
              btnCornerRadius={0}
              btnFill={'transparent'}
              btnStroke={'white'}
              btnStrokeWidth={1}
              width={190}
              height={45}
              x={-30} y={150}
            />
          </Group>

          <Text x={800} y={500} text={'ПАУЗА'} fontSize={40} stroke={'white'} fill={'white'} opacity={.4}/>
        </Group>


        <Group x={150} y={60} visible={data.answerWindow}>
          <Image image={answerWindow}/>
          <Image x={450} y={65} image={spshipTrajectory}/>

          <Group x={90} y={80}>
            <Text x={0} y={0} width={490} fontStyle={'bold'} opacity={.5} align={'center'} text={data.answerWindowTitle} fill={'white'} fontSize={30}/>
            <Text x={0} y={50} opacity={.5} text={data.answerWindowText} fill={'white'} fontSize={20}/>
          </Group>

          <CanvasButton
            text={'Далее'}
            onClick={() => {this.scenarioManager.success()}}
            fontSize={18}
            btnCornerRadius={0}
            textStroke={'transparent'}
            textFill={'#1E2531'}
            btnFill={'#6F7D94'}
            btnStrokeWidth={1}
            width={100}
            height={35}
            x={500} y={320}
          />
        </Group>



      </React.Fragment>
    )
  };

  Atmosphere = (props) => {

    const sizeAirRect = 100;
    const airRectCount = 100;
    const airRectHorizontalCount = 10;
    return (
      <Group {...props} ref={this._ref('atmosphereY')}>
        <Group y={-sizeAirRect * airRectCount}>
          {
            Array(airRectCount).fill(1).map((el, i) => {
              return (
                <Rect key={'airRect-'+i} width={ 10000 } height={ sizeAirRect } fill={ `#008CC3` } y={ i * sizeAirRect } x={-5000}
                      opacity={ i / airRectCount }/>
              )
            })
          }
        </Group>

        <Rect x={-5000} width={10000} height={500} fill={'#5CAC32'}/>
      </Group>
    )
  }

  render() {
    const data = this.data;

    const questionImages = {
      TheoryQ1: (props) => <Video {...defaultImgOrVideoStyle} src={Inertia_theory_q1Mp4} y={140}/>,
      TheoryQ2: (props) => <Video {...defaultImgOrVideoStyle} src={Inertia_theory_q2Mp4} y={140}/>,
      TheoryQ3: (props) => <Video {...defaultImgOrVideoStyle} src={Inertia_theory_q3Mp4} y={140}/>,
      TheoryQ4: (props) => <Video {...defaultImgOrVideoStyle} src={Inertia_theory_q4Mp4} y={140}/>,
    }

    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer>
            <this.Atmosphere
              offsetY={-500} // дефолтный сдвиг чтобы скрыть часть земли
              y={data.atmosphereY}
            />

            <this.Spaceship x={510} y={300} visible={this.data.spaceshipVisible}/>

            <this.Env/>

            <TheoryCanvas
              data={this.data}
              containerComp={this}
              visible={data.theoryVisible}
              questionImages={questionImages}
            />

            <CanvasButton
              visible={data.restartFlyingBtnVisible}
              text={'Повторить попытку'}
              btnFill={layout2.orange}
              x={650} y={510}
              height={30} width={180}
              fontSize={15}
              fontStyle={'normal'}
              onClick={() => this.onClickRestart()}
            />
            <CanvasResetBtn
              y={510}
              onClick={() => this.onClickReset()}
            />
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpaceshipTakeOff);

const styles = {
  mainContainer: {
    background: '#003347'
  }
};
