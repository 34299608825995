import {getHeight, width, widthPhaser} from "../canvas/containers/CanvasContainer";


export const getScaleRatioAndExtraStyles = (match, isPhaser, fullscreen) => {
  const {code, id} = match.params;

  let scaleRatio;
  let extraStyles = {
    container: {
      width: 'fit-content',
      transformOrigin: `0px 0px`,
      boxShadow: '0px 0px 12px 1px rgba(76,111,154,0.50)',
    },
  };

  const windowHeight = document.documentElement.clientHeight;
  const windowWidth = document.documentElement.clientWidth;
  const canvasWidth = isPhaser ? widthPhaser : width;
  const canvasHeight = getHeight(`${code}${id}`, isPhaser);

  // Full screen desktop
  const ifDesktopFullscreen = fullscreen && windowWidth > 600;

  if (windowWidth / windowHeight >= canvasWidth / canvasHeight) {
    scaleRatio = windowHeight / canvasHeight;

    let translateRight = (scaleRatio > 1 && !ifDesktopFullscreen)
      ? windowWidth / 2 - width / 2
      : (windowWidth - scaleRatio * width) / 2;

    extraStyles.container.transform = (scaleRatio > 1 && !ifDesktopFullscreen)
      ? `translate(${translateRight}px, ${0}px)`
      : `translate(${translateRight}px, ${0}px) scale(${scaleRatio}, ${scaleRatio})`
  } else {

    scaleRatio = windowWidth / canvasWidth;

    let translateTop = (windowHeight - scaleRatio * canvasHeight) / 2;
    let translateRight = windowWidth / 2 - canvasWidth / 2;

    extraStyles.container.transform = (scaleRatio > 1 && !ifDesktopFullscreen)
      ? `translate(${translateRight}px, ${0}px)`
      : `translate(${0}px, ${translateTop}px) scale(${scaleRatio}, ${scaleRatio})`
  }
  return {scaleRatio, extraStyles};
};

export const getSchoolTaskScaleRatioStyles = () => {
  let scaleRatio;
  let extraStyles = {
    container: {
      width: 'fit-content',
      transformOrigin: `0px 0px`,
      boxShadow: '0px 0px 12px 1px rgba(76,111,154,0.50)',
    },
  };

  const windowHeight = document.documentElement.clientHeight;
  const windowWidth = document.documentElement.clientWidth;
  const initialWidth = 1920;
  const initialHeight = 1080;

  // Full screen desktop

  if (windowWidth / windowHeight >= initialWidth / initialHeight) {
    scaleRatio = windowHeight / initialHeight;

    let translateRight = (scaleRatio > 1)
      ? windowWidth / 2 - initialWidth / 2
      : (windowWidth - scaleRatio * initialWidth) / 2;

    extraStyles.container.transform = (scaleRatio > 1)
      ? `translate(${translateRight}px, ${0}px)`
      : `translate(${translateRight}px, ${0}px) scaleX(${scaleRatio}, ${scaleRatio/2})`
  } else {

    scaleRatio = windowWidth / initialWidth;

    let translateTop = (windowHeight - scaleRatio * initialHeight) / 2;
    let translateRight = windowWidth / 2 - initialWidth / 2;

    extraStyles.container.transform = (scaleRatio > 1)
      ? `translate(${translateRight}px, ${0}px)`
      : `translate(${0}px, ${translateTop}px) scale(${scaleRatio}, ${scaleRatio})`
  }

  return {scaleRatio, extraStyles};
}
