import React from "react";
import {useHistory} from "react-router-dom";
import {getCodeUrlByTaskCode} from "../../layout2/utils";
import completeImg from "../images/completeIcon.png";
import greeceBookImg from "../images/greeceBook.png";
import {openNotificationWithIcon} from "../../../../utils/common";
import { getCorrectLang } from "../../../../utils/lang/common";


const MapElement = (props) => {
  const prevTaskDontComplete = props.prevItem ? props.prevItem.complete : true;
  const currentTaskNeedPayment = (Boolean(props.prevItem?.paymentRedirect) && !props.coursePaid) || false;
  const isTeacherVideo = props.isTeacher && props.teacher_video;
  const isStudentVideo = !props.isTeacher && props.teacher_video;
  const isStudentPresentation = !props.isTeacher && props.presentation;
  const disabled = !props.isOpen && (
    (props.disabled || !prevTaskDontComplete) || currentTaskNeedPayment || isStudentVideo || isStudentPresentation
  ) && !props.isTeacher;
  const {pos, itemImage} = props.getConfigByTaskCode(props.lessonCode, props.isTeacher);

  const history = useHistory();

  const taskCode = getCodeUrlByTaskCode(props.lessonCode);
  let status = props.complete ? 'success' : 'active';
  if (disabled) {
    status = 'disabled';
  }

  const isPresentation = props.presentation;
  let extraStyles = {}
  if (isPresentation) {
    extraStyles = {animation: 'none', background: 'none', border: '0', transform: 'scale(.7)'}
  }

  const lang = getCorrectLang();

  return (
    <div
      id={props.lessonCode}
      aria-disabled={disabled}
      className={`
        farm-map-item farm-map-item--${status}
        ${props.isTeacher ? ' farm-map-item--teacher' : ''}
        ${props.withRound && props.isTeacher ? ' farm-map-item--rounded' : ''}
      `}
      onClick={async (e) => {
        if (!disabled) {
          if (!props.isTeacher) {
            let {available: isAvailable} = await props.preOpen();
            if (!isAvailable) {
              openNotificationWithIcon('info',  [
                'Slow down...',
                'Too fast!',
                'Relax for a second',
                'Take a breath.',
                'Wait a bit'
              ][Math.floor(Math.random() * 5)]);
              return
            }
          }
          const pathName = history.location.pathname.replaceAll('/', '');
          if (props.link) {
            window.location.pathname = props.link;
          } else {
            history.push(`/${pathName}/${lang}/${taskCode}`);
          }
          props.setTaskModalVisible(true);
        }
      }}
      style={{width: '180px', height: '180px', ...pos, ...extraStyles}}
    >
      {
        (isTeacherVideo || !props.teacher_video) ? (
          <>
            {
              (itemImage && !isStudentPresentation) ? (
                <img src={itemImage} alt="" style={{position: 'relative', width: '100%'}}/>
              ): null
            }
            {
              props.isTeacher ? (
                <>
                  <p className={'farm-map-item--number'}>
                    <b>{props.index + 1}</b> { props.teacher_video ? '(Video)' : '' }
                  </p>

                  {
                    isPresentation ? (
                      <div style={{
                        position: 'relative',
                        left: itemImage ? '0' : '60px', top: itemImage ? '0' :'30px',
                        zIndex: 2, display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column'
                      }}>
                        {
                          !itemImage ? (
                            <img src={greeceBookImg} alt=""/>
                          ) : null
                        }
                        <div style={{
                          padding: '10px', color: 'white',
                          fontFamily: 'Roboto-Bold',
                          background: '#37588DB5',
                          borderRadius: '100px', textAlign: 'center',
                          fontSize: '25px', marginTop: '10px'
                        }}>Slides</div>
                      </div>
                    ):null
                  }
                </>
              ) : null
            }
          </>
        ):null
      }
      {
        props.complete && (!isStudentVideo && !isStudentPresentation) ? (
          <img className={'farm-map-item--success-img'} style={{zIndex: 3, right: '-2em', top: '0em'}} src={completeImg} alt=""/>
        ) : null
      }
    </div>
  )
}

export default MapElement;
