import React, {useState} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import * as actions from '../../../../store/actions';
import underwaterBaseImg from '../../../../images/bondSubmarine/underwaterBase.png';
import pattern1Img from '../../../../images/bondSubmarine/patter1.png';
import pattern2Img from '../../../../images/bondSubmarine/pattern2.png';
import submarinePathGif from '../../../../images/bondSubmarine/submarine_path.gif';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import BtnsController from "../../../canvas/components/KeyController/BtnsController";
import {defaultPatchData, scenario} from "../scenario";
import CanvasButton from "../../../canvas/components/CanvasButton";
import airBgImg from '../../../../images/bondSubmarine/airBg.png';
import {getTimeDeltaSec} from "../../../../utils/common";
import CanvasSuccessCard from "../../../canvas/components/CanvasSuccessCard";
import CheckMarkGif from "../../../canvas/components/CheckMarkGif";
import fireGif from "../../../../images/fireSmall.gif";
import {GIF} from "../../../canvas/components/GIF";
import btnLeftImage from "../../../../images/space/spaceship_landing/btn-left.png";
import btnRightImage from "../../../../images/space/spaceship_landing/btn-right.png";
import Controller from "../components/Controller";




class BondSubmarine extends React.Component {
  constructor(props) {
    super(props);
    const {code} = this.props;


    // --------- REFS ---------
    this.managedComponents = [
      'stage',
      'waterLevel',
      'submarine',
      'submarineWater',
      'background',
      'horizon',
      'submarineRect',
      'submarineRect2',
      'opacityWaterRect',
      'underwaterBaseBox',
      'submarinePathGif',
      'submarineScaleHelper',

      'controllerLeftBox',
      'controllerRightBox',

      'leftWaterSubmarineBox',
      'rightWaterSubmarineBox',

      'text',
      'text2',
      'text3',
    ]

    this.staticData = {
      minX: -1000,
      maxX: 6000,
      scale: 10,
      scalePx: 50,
      maxDepthPx: 485.4
    };

    this.state = {
      textPoint: 0,
      textImagePoint: 0,
    };

    this.initialData = {
      prevTime: null,
      startTime: null,
      timedeltaSecAll: 0,

      start: false,

      depth: 0,
      posX: 150,
      horizontalSpeed: 0,
      verticalSpeed: 0,
      waterVolume: 0,
      textColor: '#447b9c',

      moveRight: false,
      moveLeft: false,
      moveUp: false,
      moveDown: false,

      submarineDies: false,
      exploreButtonPoint: 0,
      exploreCheckButton: 0,

      submarineTimer: 0,

      rightBoxWaterIsFull: false,
      leftBoxWaterIsFull: false,

      rightBoxWaterHeight: 0,
      leftBoxWaterHeight: 0,
      submRightBoxWaterHeight: 0,
      submLeftBoxWaterHeight: 0,

      submarineDirectionLeft: false,

      fishStngs: undefined,

      ...defaultPatchData
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  get realDepth () {
    return this.data.depth + 270;
  }

  get pressure () {
    return this.realDepth / 75 * 10 * 1000 * 10;
  }

  get waterLevel () { return this.data.waterVolume}

  get mass () { return 10500 + this.data.waterVolume * 1000}

  get archForce () {
    const depth = this.data.depth;
    const maxArchForce = 13000 * 10;
    if (depth < - 270) {
      return 0
    }
    if (depth >= -270 && depth <= -240) {
      return maxArchForce * (depth - (-270)) / 30
    }
    return maxArchForce;
  }

  get submarineDies() {
    // const nodes = this.getAllNodes();
    // const bumped1 = this.hasIntersection(nodes.barrier1Node, nodes.submarineRectNode) || this.hasIntersection(nodes.barrier1Node, nodes.submarineRect2Node);
    // const bumped2 = this.hasIntersection(nodes.barrier2Node, nodes.submarineRectNode) || this.hasIntersection(nodes.barrier2Node, nodes.submarineRect2Node);
    // const bumped3 = this.hasIntersection(nodes.barrier3Node, nodes.submarineRectNode) || this.hasIntersection(nodes.barrier3Node, nodes.submarineRect2Node);
    // return bumped1 || bumped2 || bumped3;
    return false;
  }

  get success() {
    return this.data.posX > 800;
  }

  getCorrectHeight = (dataKey, condition, maxHeight, delta) => {
    const data = this.data;
    if (condition) {
      return data[dataKey] >= maxHeight ? maxHeight : data[dataKey]+delta;
    } else {
      return data[dataKey] <= 0.2 ? 0 : data[dataKey]-delta;
    }
  }
  _ref = (key) => this[`${key}Ref`] = React.createRef();

  _getNode = (key) => this[`${key}Ref`]?.current;
  pxToDepthM = (px) => {
    const {scale, scalePx} = this.staticData;
    return px / scalePx * scale;
  };
  depthToPx = (depth) => {
    const {scale, scalePx} = this.staticData;
    return depth / scale * scalePx;
  };

  handleReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    document.addEventListener("keydown", this.handleKey);
    document.addEventListener("keyup", this.handleKey);
    this.setFishPos();
  }

  setFishPos = () => {
    this.data.fishStngs = Array(23*3).fill(1).reduce((accum, el, i) => {
      const indx = Math.floor(Math.random() * 22)+1;
      const randX = Math.floor(Math.random() * 5000)+1;
      const randY = Math.floor(Math.random() * this.depthToPx(600));

      const scaleXRand = Math.floor(Math.random() * 2);
      const imgKey = `fish-${indx}`;
      accum['fish-'+(i+1)] = {
        imgKey: imgKey,
        x: randX,
        y: randY,
        scaleX: scaleXRand ? 1 : -1
      }
      return accum;
    }, {})
  }

  handleCLickOrTouchButton = (actionKey, val) => {
    this.data = {
      ...this.data,
      moveRight: false,
      moveLeft: false,
      moveUp: false,
      moveDown: false,
      [actionKey]: val
    };
  };

  handleKey = (event) => {
    const isKeydown = event.type === 'keydown';
    if (this.data.start && !this.data.stopMoving) {
      switch (event.keyCode) {
        case 37: this.data.moveLeft = isKeydown; break;
        case 39: this.data.moveRight = isKeydown; break;
        // case 38: this.data.moveUp = isKeydown; break;
        // case 40: this.data.moveDown = isKeydown; break;
      }
      this.scenarioManager.success('step 3');
    }
  };

  handlerCLickBox = (direction) => {
    this.scenarioManager.success('step 3');
    this.data[direction+'BoxWaterIsFull'] = !this.data[direction+'BoxWaterIsFull'];
    if (this.data[direction+'BoxWaterIsFull']) {
      this.data.waterVolume += 2.5;
    } else {
      this.data.waterVolume -= 2.5;
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKey);
    document.removeEventListener("keyup", this.handleKey);
  }

  enslow (value, delta, timedeltaSec) {
    let absValue = Math.abs(value);
    let signValue = Math.sign(value);

    absValue -= delta * timedeltaSec;
    if (absValue < 0) absValue = 0;

    return signValue * absValue
  }

  move = (time) => {
    const {} = this.staticData;
    const { moveUp, moveDown } = this.data;
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);


    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.data.submarineDies = this.submarineDies;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);
    data.timedeltaSecAll += data.timedeltaSec;

    const realDepth = this.realDepth;

    let force = (this.mass * 10 - this.archForce) * 20;


    if (data.waterVolume >= 5) data.waterVolume = 5;
    if (data.waterVolume <= 0) data.waterVolume = 0;


    let horizontalSpeedDelta = 40;
    let enslowHorDelta = Math.abs(data.horizontalSpeed) * .5;
    let enslowDelta = Math.abs(data.verticalSpeed) * 1.3;

    if (data.stopMoving) {
      enslowDelta = Math.abs(data.verticalSpeed) * 50;
      enslowHorDelta = Math.abs(data.horizontalSpeed) * 3;
    }

    data.verticalSpeed += force / this.mass*3 * data.timedeltaSec;
    data.verticalSpeed = this.enslow(data.verticalSpeed,  enslowDelta, data.timedeltaSec);
    data.horizontalSpeed = this.enslow(data.horizontalSpeed, enslowHorDelta, data.timedeltaSec)

    data.depth += data.verticalSpeed * data.timedeltaSec;
    if (data.moveLeft) {
      data.submarineDirectionLeft = true;
      if (data.posX < this.staticData.minX) {
        horizontalSpeedDelta = 0 }
      data.horizontalSpeed -= horizontalSpeedDelta * data.timedeltaSec;
    }
    if (data.moveRight) {
      data.submarineDirectionLeft = false;
      if (data.posX > this.staticData.maxX) {
        horizontalSpeedDelta = 0 }
      data.horizontalSpeed += horizontalSpeedDelta * data.timedeltaSec;
    }


    const maxDepthPx = this.depthToPx(this.staticData.maxDepthPx);
    if (realDepth >= maxDepthPx) {
      data.submarineTimer += data.timedeltaSec;
      if (data.submarineTimer >= 5) {
          this.scenarioManager.failure('step 5');
      }
      if (data.submarineTimer >= 7) {
        this.scenarioManager.failure('broken submarine');
      }
    } else {
      data.submarineTimer = 0;
    }



    data.leftBoxWaterHeight = this.getCorrectHeight('leftBoxWaterHeight', data.leftBoxWaterIsFull, 70, 1);
    data.rightBoxWaterHeight = this.getCorrectHeight('rightBoxWaterHeight', data.rightBoxWaterIsFull, 70, 1);
    data.submLeftBoxWaterHeight = this.getCorrectHeight('submLeftBoxWaterHeight', data.leftBoxWaterIsFull, 15, 0.2);
    data.submRightBoxWaterHeight = this.getCorrectHeight('submRightBoxWaterHeight', data.rightBoxWaterIsFull, 15, 0.2);


    data.posX += data.horizontalSpeed * data.timedeltaSec;

    let text = '';
    let textHeight = 180;

    this.updateStage();
  };

  hasIntersection(r1, r2) {
    if (!r1 || !r2) return undefined;
    const r1abs = r1.getAbsolutePosition(this.stageRef?.current);
    const r2abs = r2.getAbsolutePosition(this.stageRef?.current);
    return !(
      r2abs.x > r1abs.x + r1.width() ||
      r2abs.x + r2.width() < r1abs.x ||
      r2abs.y > r1abs.y + r1.height() ||
      r2abs.y + r2.height() < r1abs.y
    );
  }

  updateStage() {
    const {code} = this.props;
    const {depth, startTime, prevTime, waterVolume, posX} = this.data;
    const timedelta = prevTime ? startTime - prevTime : 0;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    n['submarineWater'].height(-waterVolume * 3.8);

    n['background'].setAttrs({y: -depth, x: -posX});

    n['opacityWaterRect'].y(-depth);
    n['horizon'].setAttrs({y: depth, height: -depth+170});

    n['submarine'].offsetX(-this.data.horizontalSpeed);
    n['submarineScaleHelper'].setAttrs({
      scaleX: this.data.submarineDirectionLeft ? -1: 1,
      x:  this.data.submarineDirectionLeft ? 250: 170,
    })
    if (n['submarinePathGif']) {
      const realDepth = this.realDepth;
      n['submarinePathGif'].visible((this.data.moveLeft || this.data.moveRight) && realDepth>40);
    }


    n['controllerLeftBox'].height(this.data.leftBoxWaterHeight);
    n['controllerRightBox'].height(this.data.rightBoxWaterHeight);

    n['leftWaterSubmarineBox'].height(this.data.submLeftBoxWaterHeight);
    n['rightWaterSubmarineBox'].height(this.data.submRightBoxWaterHeight);


    const foundBase = this.hasIntersection(n['underwaterBaseBox'], n['submarineRect']);
    if (foundBase) {
      this.scenarioManager.success('step 5');
    }

    // dev info
    // const realDepth = this.realDepth;
    // n['text'].text(`x: ${Math.round(posX)}`);
    // n['text2'].text(`time: ${Math.round(this.data.timedeltaSecAll)}`);
    // n['text3'].text(`depth: ${Math.round(this.pxToDepthM(realDepth))}`);

    const fluidDensity = 1030; //кг/м³
    const g = 9.8;
    let waterColumnHeight = (this.realDepth - 23.5)/5;
    waterColumnHeight = waterColumnHeight < 0 ? 0 : waterColumnHeight;
    const waterPressureAbove = Math.round((waterColumnHeight * fluidDensity * g)/1000 / 100);
    const waterMassAbove = Math.round(waterColumnHeight * fluidDensity * 5 * 35 / 1000); // submarine height ang weight

    stageNode.draw();
  }

  CanvasLever = () => {
    const {code} = this.props;
    const {backgroundPosY, posX} = this.data;

    const [submarine1] = useImage(this.data.submarineImg);
    const [underwaterBase] = useImage(underwaterBaseImg);
    const [airBg] = useImage(airBgImg);

    const [pattern1] = useImage(pattern1Img);
    const [pattern2] = useImage(pattern2Img);


    const fishImgs = {};
    Array(23).fill(1).forEach((num, i) => {
      const imgPath = require(`../../../../images/bondSubmarine/fish-${i+1}.png`);
      const [fish] = useImage(imgPath);
      fishImgs[`fish-${i+1}`] = fish;
    });

    const bgWidth = 13000;
    const bgHeight = 15000;
    const fishItems = Array(23*5).fill(1);

    const baseY = this.depthToPx(420);
    const groundY = this.depthToPx(600);
    const patternList = Array(7).fill(1);
    return (
      <React.Fragment>
        <Layer preventDefault={false}>
          <Group ref={this._ref('background')} height={1000}>
            <Group x={-2000}>
              <Group ref={this._ref('horizon')}>
                {
                  Array(10).fill(1).map((el, i) => {
                    return (
                      <Image key={'air-'+i} image={airBg} preventDefault={false} x={1000 * i}/>
                    )
                  })
                }
              </Group>
              <Rect y={170} fill={'#0083D2'} height={bgHeight} width={bgWidth} preventDefault={false}/>
            </Group>




            <Group x={-1000} y={200}>
              {
                patternList.map((el, i2) => (patternList.map((el, i1) => (
                  <Image key={`pattern1-${i1}`} image={pattern1} width={390} height={480} x={650*i1} y={550*i2} opacity={.4}/>
                ))))
              }
              {
                patternList.map((el, i2) => (patternList.map((el, i1) =>(
                  <Image key={`pattern2-${i1}`} image={pattern2} width={920} height={600} x={1250*i1} y={900*i2} opacity={.4}/>
                ))))
              }
            </Group>


            <Group y={200}>
              {
                Object.keys(this.data.fishStngs||[]).map((k, i) => {
                  const fishStgs = this.data.fishStngs[k];
                  const image = fishImgs[fishStgs.imgKey];
                  return (
                    <Image key={'fish-'+i} image={image} x={fishStgs.x} y={fishStgs.y} scaleX={fishStgs.scaleX}/>
                  )
                })
              }
            </Group>

            <Group x={5000} y={baseY + 170}>
              <Rect x={-200} ref={this._ref('underwaterBaseBox')} width={1200} height={300} opacity={0} fill={'red'}/>
              <Image image={underwaterBase}/>
            </Group>

            <Group x={0} y={groundY + 170}>
              <Rect width={bgWidth} height={1000} fill={'#ACB0A8'}/>
            </Group>
          </Group>

          <Text x={30} y={30} text={this.data.title} fill={'#00253C'} fontSize={35} lineHeight={1.2} {...this.data.titleStgs}/>

          <Group ref={this._ref('submarine')} x={50} y={420} height={40} offsetY={30}>
            <Group ref={this._ref('submarineScaleHelper')}>
              <Group x={10} y={5} ref={this._ref('submarinePathGif')}>
                <GIF
                  src={submarinePathGif}
                  scaleX={-.05} scaleY={.05} opacity={.7} loop/>
                <GIF
                  y={10}
                  src={submarinePathGif}
                  scaleX={-.05} scaleY={.05} opacity={.7} loop/>
              </Group>
              <Rect ref={this._ref('submarineWater')} x={67} y={38} width={70} fill={'#0CB2D4'} opacity={0}/>
              <Image image={submarine1} y={-50}/>
              <Rect ref={this._ref('submarineRect')} x={5} y={-18} width={100} height={50} fill={'red'} opacity={0}/>

              <Group x={25} y={20}>
                {
                  ['left', 'right'].map((k, i) => {
                    return (
                      <Group x={30*i} key={'box-'+i}>
                        <Rect width={26} height={15} stroke={'black'} fill={'gray'} cornerRadius={2} strokeWidth={1.5}/>
                        <Rect ref={this._ref(k+'WaterSubmarineBox')} x={26} y={15} width={26} height={15} fill={'#0083D2'} cornerRadius={2} rotation={180}/>
                      </Group>
                    )
                  })
                }
              </Group>
            </Group>
          </Group>

          <Group ref={this._ref('opacityWaterRect')}>
            <Group y={170}>
              <Rect width={bgWidth} height={bgHeight} fill={'#0083D2'} opacity={.3}/>
              {
                Array(150).fill(1).map((el, i) => {
                  const correctIndex = i+1;
                  const isFive = correctIndex % 5 === 0;
                  const lineWidth = isFive ? 40 : 20;
                  return (
                    <Group y={correctIndex*this.staticData.scalePx} key={`scale-${i}`}>
                      <Line points={[0, 0, lineWidth, 0]} stroke={'white'} strokeWidth={2}/>
                      <Text text={correctIndex * this.staticData.scale} fill={'white'} fontSize={isFive ? 20 : 13} y={isFive ? -10 : -5} x={lineWidth+10}/>
                    </Group>
                  )
                })
              }
            </Group>
          </Group>

          <CanvasButton
            text={'Рассчитал, готов к погружению'}
            x={600} y={480}
            onClick={() => {
              this.scenarioManager.selectStepByKey('step 3')
            }}
            fontSize={20}
            height={40}
            width={350}
            textFill={'#0083D2'}
            btnFill={'white'}
            strokeWidth={.2}
            btnCornerRadius={0}
            visible={this.data.startBtnVisible}
          />

          <CanvasSuccessCard
            title={this.data.finallyPopupText}
            visible={this.data.finallyStepVisible}
            success={this.data.successFinallyPopup}
            {...this.data.finallyPopupTextStgs}
            goToTheory={() => this.scenarioManager.selectStepByKey('theory')}
            onClickReset={() => this.handleReset()}
          />


          {
            this.data.successStepPopupVisible && (
              <Group x={270} y={100}>
                <Rect width={450} height={330} cornerRadius={10} fill={'white'}/>
                <Text y={50} width={450} align={'center'} text={'Лаборатория найдена!'} fill={'#000253'} fontSize={30}/>
                <CheckMarkGif x={187} y={120} withImageBg scale={{x: 1.5, y:1.5}} loop={false}/>
                <CanvasButton
                  text={'Далее'}
                  x={155} y={240}
                  onClick={() => {
                    this.scenarioManager.selectStepByKey('animation 2')
                  }}
                  fontSize={20}
                  height={40}
                  width={150}
                  textFill={'white'}
                  btnFill={'#0083D2'}
                  strokeWidth={.2}
                  btnCornerRadius={0}
                />
              </Group>
            )
          }

          <Group x={540} y={535} visible={this.data.directionsArrows}>
            {
              [{text: 'запад', arrowLen: -50}, {text: 'восток', arrowLen: 50}].map((el, i) => {
                return (
                  <Group x={i*130}>
                    <Text x={!i ? 10 : -53} y={-7} text={el.text} fill={'white'} fontSize={15}/>
                    <Arrow points={[0,0,el.arrowLen, 0]} stroke={'white'} fill={'white'} scale={{x: .7, y:.7}}/>
                  </Group>
                )
              })
            }
          </Group>

          <Controller
            x={500} y={370}
            handleKeyAction={this.handleCLickOrTouchButton}
            handlerCLickBox={this.handlerCLickBox}
            hintVisible={this.data.controllerHintVisible}
            leftBoxRef={this._ref('controllerLeftBox')}
            rightBoxRef={this._ref('controllerRightBox')}
            visible={this.data.controllerVisible}
          />

          <CanvasSuccessCard
            visible={this.data.complete}
            goToTheory={() => this.scenarioManager.selectStepByKey('theory')}
            onClickReset={() => this.handleReset()}
          />

          {/* // dev info */}
          {/*<Group x={50} y={250}>*/}
          {/*  <Rect fill={'white'} width={150} height={80} cornerRadius={[10,10,0,10]}/>*/}
          {/*  <Text x={10} y={10} text={'212'} ref={this._ref('text')} fill={'black'} fontSize={20}/>*/}
          {/*  <Text x={10} y={30} text={'212'} ref={this._ref('text2')} fill={'black'} fontSize={20}/>*/}
          {/*  <Text x={10} y={50} text={'212'} ref={this._ref('text3')} fill={'black'} fontSize={20}/>*/}
          {/*</Group>*/}
        </Layer>
      </React.Fragment>
    )
  };

  keyboardEvents = (event) =>{
    event.persist();
    console.log(event.key); // this will return string of key name like 'Enter'
  };

  render() {
    const props = this.props;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this._ref('stage')}
          lessonCode={props.code}
          component={this}
          withVideo={true}
          videoVisible={this.data.videoSrc}
          theoryVisible={this.data.theoryVisible}
          additionalTheoryStgs={{
            skipInitialStep: true,
            hideResetBtn: true,
            bgColor: 'white',
          }}
        >
          <this.CanvasLever/>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BondSubmarine);

const styles = {
  mainContainer: {
    background: 'black',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    margin: '3px',
    background: '#3e4258',
    borderRadius: '3px',
    color: 'white',
    zIndex: 10,
    fontSize: '2vmin',
    padding: '1vmin 1.5vmin',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  fieldsBlock: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    bottom: '10%',
    right: '30%'
  }
};
