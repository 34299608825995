import { Arrow, Layer, Stage, Text, Group } from "react-konva";
import React from "react";

export const CanvasVelocity = ({boatSpeedV, resultV}) => {
  return (
      <Group>
          <Arrow
            points={[0, 0, boatSpeedV.x, boatSpeedV.y]}
            stroke={"black"}
            fill={"black"}
          />
          <Arrow
            points={[boatSpeedV.x, boatSpeedV.y, resultV.x, resultV.y]}
            stroke={"black"}
            fill={"black"}
            dash={[8, 8]}
          />
          <Arrow
            points={[0, 0, resultV.x, resultV.y]}
            stroke={"green"}
            fill={"green"}
          />
      </Group>
  )
};
