import React from "react";
import '../styles/healthBar.css';
import img from '../images/heart.png';


const HealthBar = (props) => {

  return (
    <div className={'health-bar'}>
      {
        Array(props.defaultHealth).fill(1).map((el, i) => (
          <img key={i} className={`health-bar__item ${(props.healthCount-1) < i ? 'health-bar__item--hidden' : ''}`} src={img}/>
        ))
      }
    </div>
  )
}
export default HealthBar;
