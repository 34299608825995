import React from "react";


/** Initialize array with radius of n and queue values ahead of it */
export function pointRadiusLast(radius, length, initialArray) {
  var result = initialArray || [ radius ];
  while (result.length < length) result.unshift(0);
  return result;
}

export const getBoolByLsnCode = (lsnCode) => ({
  idealGas1: lsnCode === 'idealGas1',
  idealGas2: lsnCode === 'idealGas2',
  idealGas3: lsnCode === 'idealGas3',
  idealGas4: lsnCode === 'idealGas4',
  idealGas5: lsnCode === 'idealGas5',
  idealGas6: lsnCode === 'idealGas6',
  idealGas7: lsnCode === 'idealGas7',
  idealGas8: lsnCode === 'idealGas8',
  idealGas9: lsnCode === 'idealGas9',
  idealGas10: lsnCode === 'idealGas10',
  idealGas11: lsnCode === 'idealGas11',
});

export const getChartInitialData = ({dataLabel}) => ({
  labels: [],
  datasets: [
    {
      label: dataLabel,
      align: 'right',
      data: [],
      borderColor: ['#2972CC'],
      pointBorderColor: '#2972CC',
      borderWidth: 2,
      backgroundColor: ['rgba(200,0,0,0.0)'],
      pointBackgroundColor: '#2972CC',
      pointStyle: 'circle',
    }
  ]
});

export const getChartInitialOptions = ({
    title,
    titleFontSize,
    xMax,
    xStep,
    xLabel,
    yMax,
    yStep,
    yLabel,
  }) => ({
  title: {
    display: true,
    text: title,
    fontSize: titleFontSize || 18,
    fontColor: '#111111',
    lineHeight: 1.1,
    padding: 5
  },
  elements: {
    point: {
      radius: 0,
      backgroundColor: 'rgba(0,0,0,0)'
    }
  },
  legend: {
    align: 'end',
    labels: {
      padding: 5,
      usePointStyle: true
    }
  },
  animation: { duration: 10, },
  scales: {
    xAxes: [
      {
        ticks: {
          min: 0,
          max: xMax,
          stepSize: xStep
        },
        type: 'linear',
        scaleLabel: {
          display: true,
          labelString: xLabel
        },
      }
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          max: yMax,
          stepSize: yStep
        },
        type: 'linear',
        scaleLabel: {
          display: true,
          labelString: yLabel
        }
      },
    ]
  }
});