import React from "react";
import alfa1Mp4 from '../../../../images/space/jumping_on_planets/videos/alfa_1.mp4';
import alfa2Mp4 from '../../../../images/space/jumping_on_planets/videos/alfa_2.mp4';
import alfa3Mp4 from '../../../../images/space/jumping_on_planets/videos/alfa_3.mp4';
import alfa4Mp4 from '../../../../images/space/jumping_on_planets/videos/alfa_4.mp4';
import initial from '../../../../images/space/jumping_on_planets/videos/initial.mp4';
import {logger} from "redux-logger/src";


export const planetsDefaultPatchData = {
  title: '',
  animationSrc: undefined,
  planetEnvVisible: false,
  planetsStgs: {},
  resetBtnStg: {y: 513},
  theoryVisible: false,
  onClickSkipTheory: (comp) => {}
}


const planetsStgs = {
  'planet1': {x: -20, y: -30, circleStg: {radius: 0}, textStg: {x: -20, y: 120}, formula: {x: -20, y: 160}},
  'planet2': {x: 200, y: 0, circleStg: {radius: 70, x: 64, y: 65}, textStg: {x: 25, y: 150}, formula: {x: 0, y: 190}},
  'planet3': {
    x: 400,
    y: 35,
    circleStg: {radius: 53, x: 48, y: 48},
    textStg: {x: 10, y: 115},
    formula: {x: -10, y: 155}
  },
  'planet4': {x: 570, y: 65, circleStg: {radius: 36, x: 59, y: 30}, textStg: {x: 20, y: 85}, formula: {x: 0, y: 125}},
  'planet5': {
    x: 770,
    y: 85,
    circleStg: {radius: 26, x: 22.5, y: 22.5},
    textStg: {x: -15, y: 65},
    formula: {x: -35, y: 105}
  },
}

// навигация по видео и переход к теории
const selectNexVideoStep = (comp) => {
  const currKey = comp.scenarioManager.getStepData().key;
  const selectedPlanets = comp.data.selectedPlanets;
  const currIndx = selectedPlanets.findIndex((k) => k === currKey);
  const newKey = selectedPlanets[currIndx+1];


  if (newKey) {
    comp.scenarioManager.selectStepByKey(newKey);
  } else {
    comp.data.selectedPlanets = [];
    if (comp.data.selectCorrectPlanets) {
      comp.scenarioManager.selectStepByKey('success');
    } else {
      comp.scenarioManager.selectStepByKey('planets');
    }
    // Скрыта теория по предложению Паши 20.07.21
    // if (comp.data.errAnswerCounter === 1) {
    //   comp.scenarioManager.selectStepByKey('planets');
    // } else {
    //   comp.scenarioManager.selectStepByKey('theory');
    // }
  }
}

export const planetsScenario = [

  {
    key: 'initial animation',
    patchData: {
      ...planetsDefaultPatchData,
      animationSrc: initial,
      skipVideo: (comp) => comp.scenarioManager.next(),
    },
    next_success: 'planets',
    // switch_at: 1,
  },

  {
    key: 'planets',
    patchData: {
      ...planetsDefaultPatchData,
      title: 'Выбери планеты, на которых пилот прыгнет выше, \n' +
        'чем 1 м 8 см (высота прыжка обратно пропорциональна g)',
      planetsStgs: planetsStgs,
      planetEnvVisible: true,
    },
    next_success: 'start spaceship',
    // switch_at: 1,
  },

  {
    key: 'planet2',
    patchData: {
      ...planetsDefaultPatchData,
      animationSrc: alfa1Mp4,
      planetEnvVisible: true,
      onVideoEnd: (comp) => selectNexVideoStep(comp),
      skipVideo: (comp) => selectNexVideoStep(comp),
    },
  },{
    key: 'planet3',
    patchData: {
      ...planetsDefaultPatchData,
      animationSrc: alfa2Mp4,
      planetEnvVisible: true,
      onVideoEnd: (comp) => selectNexVideoStep(comp),
      skipVideo: (comp) => selectNexVideoStep(comp),
    },
  },{
    key: 'planet4',
    patchData: {
      ...planetsDefaultPatchData,
      animationSrc: alfa3Mp4,
      planetEnvVisible: true,
      onVideoEnd: (comp) => selectNexVideoStep(comp),
      skipVideo: (comp) => selectNexVideoStep(comp),
    },
  },{
    key: 'planet5',
    patchData: {
      ...planetsDefaultPatchData,
      animationSrc: alfa4Mp4,
      planetEnvVisible: true,
      onVideoEnd: (comp) => selectNexVideoStep(comp),
      skipVideo: (comp) => selectNexVideoStep(comp),
    },
  },


  {
    key: 'theory',
    patchData: {
      ...planetsDefaultPatchData,

      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        if (comp.data.errAnswerCounter >= 2) {
          comp.data.errAnswerCounter = 0;
          comp.scenarioManager.selectStepByKey('planets');
          return;
        }
        comp.scenarioManager.selectStepByKey('success');
      }
    },
    // switch_at: 1
  },



  {
    key: 'success',
    patchData: {
      ...planetsDefaultPatchData,
      title: 'Задача пройдена!',
      resetBtnStg: {
        x: 450,
        y: 430,
        width: 200,
        height: 50,
      },
      selectedPlanets: [],
      planetsStgs: {
        'planet1': {
          x: 0 , y: 180,
          circleStg: {visible: false}, textStg: {visible: false}, formula: {visible: false}},
        'planet2': {
          x: 80, y: -60,
          circleStg: {visible: false}, textStg: {visible: false}, formula: {visible: false}},
        'planet3': {
          x: 280, y: 35,
          circleStg: {visible: false}, textStg: {visible: false}, formula: {visible: false}},
        'planet4': {
          x: 730, y: -155,
          circleStg: {visible: false}, textStg: {visible: false}, formula: {visible: false}},
        'planet5': {
          x: 670, y: 150,
          circleStg: {visible: false}, textStg: {visible: false}, formula: {visible: false}},
      }
    },
  },
]
