import axios from "axios";
import {USER_ACHIEVEMENTS, HEADER_BANNER, SKILLS, HOMEWORK_ACHIEVEMENTS} from "../urls";
import {openNotificationWithIcon} from "../../utils/common";
import moment from "moment";


export const fetchHeaderBanner = () => async (dispatch) => {
  let resp;
  try {
    resp = await axios.get(HEADER_BANNER);
  } catch (error) {
    console.log('debug error', error);
    openNotificationWithIcon('error', 'Произошла ошибка при отправке обратной связи');
  }
  if (resp?.status) {
    let banner_visible = true;
    const exprDate_str = localStorage.getItem('header-banner');
    if (exprDate_str) {
      const exprDate_moment = moment(exprDate_str, 'DD.MM.YYYY HH:mm');
      const nowUtc = moment();
      banner_visible = nowUtc.isSameOrAfter(exprDate_moment, 'minute');
    }
    dispatch(setHeaderBanners({visible: banner_visible, items: resp.banners}));
  }
  return resp;
}

export const fetchSkills = () => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(SKILLS);
  } catch (error) {
    console.log('debug error', error);
  }
  return resp;
}
export const fetchUserAchievements = () => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(USER_ACHIEVEMENTS);
  } catch (error) {
    console.log('debug error', error);
  }
  return resp;
}

export const fetchHomeworkAchievements = (homeworkId) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${HOMEWORK_ACHIEVEMENTS}?homeworkId=${homeworkId}`);
  } catch (error) {
    console.log('debug error', error);
  }
  return resp;
}







export const SET_DARK_MODE = 'SET_DARK_MODE';
export const setDarkMode = (status) => {
  return {
    type: SET_DARK_MODE,
    payload: status,
  }
};
export const SET_HEADER_BANNERS = 'SET_HEADER_BANNERS';
export const setHeaderBanners = (bannerData) => {
  return {
    type: SET_HEADER_BANNERS,
    payload: bannerData,
  }
};

export const SET_CONGRATULATION_TEACHER_POPUP_VISIBLE = 'SET_CONGRATULATION_TEACHER_POPUP_VISIBLE';
export const setCongratulationTeacherPopupVisible = (visible) => {
  return {
    type: SET_CONGRATULATION_TEACHER_POPUP_VISIBLE,
    payload: visible,
  }
};

export const SET_HOMEWORK_COPY_LINK_POPUP_VISIBLE = 'SET_HOMEWORK_COPY_LINK_POPUP_VISIBLE';
export const setHomeworkCopyLinkPopupVisible = (visible) => {
  return {
    type: SET_HOMEWORK_COPY_LINK_POPUP_VISIBLE,
    payload: visible,
  }
};

export const SET_ANONYMOUS_POPUP_VISIBLE = 'SET_ANONYMOUS_POPUP_VISIBLE';
export const setAnonymousPopupVisible = (visible) => {
  return {
    type: SET_ANONYMOUS_POPUP_VISIBLE,
    payload: visible,
  }
};

export const SET_FEEDBACK_POPUP_VISIBLE = 'SET_FEEDBACK_POPUP_VISIBLE';
export const setFeedbackPopupVisible = (visible) => {
  return {
    type: SET_FEEDBACK_POPUP_VISIBLE,
    payload: visible,
  }
};


export const CHANGE_VK_SHARE_MODAL_VISIBLE = 'CHANGE_VK_SHARE_MODAL_VISIBLE';
export const changeVKShareVisible = (visible) => {
  return {
    type: CHANGE_VK_SHARE_MODAL_VISIBLE,
    payload: visible,
  }
};

export const CHANGE_PAYMENT_MODAL_VISIBLE = 'CHANGE_PAYMENT_MODAL_VISIBLE';
export const changeChangePaymentVisible = (visible) => {
  return {
    type: CHANGE_PAYMENT_MODAL_VISIBLE,
    payload: visible,
  }
};

export const CHANGE_SUCCESS_MODAL_VISIBLE = 'CHANGE_SUCCESS_MODAL_VISIBLE';
export const changeSuccessVisible = (visible) => {
  return {
    type: CHANGE_SUCCESS_MODAL_VISIBLE,
    payload: visible,
  }
};

export const CHANGE_FAILURE_MODAL_VISIBLE = 'CHANGE_FAILURE_MODAL_VISIBLE';
export const changeFailureVisible = (visible) => {
  return {
    type: CHANGE_FAILURE_MODAL_VISIBLE,
    payload: visible,
  }
};


export const CHANGE_PRESENTATION_MODAL_VISIBLE = 'CHANGE_PRESENTATION_MODAL_VISIBLE';
export const changePresentationModalVisible = (visible) => {
  return {
    type: CHANGE_PRESENTATION_MODAL_VISIBLE,
    payload: visible,
  }
};

export const CHANGE_PRESENTATION_FORM_MODAL_VISIBLE = 'CHANGE_PRESENTATION_FORM_MODAL_VISIBLE';
export const changePresentationFormModalVisible = (visible) => {
  return {
    type: CHANGE_PRESENTATION_FORM_MODAL_VISIBLE,
    payload: visible,
  }
};


export const SET_SIDEBAR_MENU_VISIBLE = 'SET_SIDEBAR_MENU_VISIBLE';
export const setSidebarMenuVisible = (visible) => {
  return {
    type: SET_SIDEBAR_MENU_VISIBLE,
    payload: visible,
  }
};
