import React, {useState} from "react";
import useImage from "use-image";
import {Group, Image, Rect} from "react-konva";


const AsteroidPointItem = (props) => {
  const defaultOpacity = .7;
  const [opacity, setOpacity] = useState(defaultOpacity);
  const [pointItem] = useImage(props.pointItemImg);
  return(
    <Group
      {...props}
      onMouseOver={() => setOpacity(1)}
      onMouseLeave={() => setOpacity(defaultOpacity)}
      opacity={opacity}
    >
      <Rect x={10} y={10} width={20} height={20} fill={'red'} opacity={0}/>
      {
        props.children ? (
          <>{props.children}</>
        ) : (
          <Image image={pointItem} scale={{x: props.scale, y: props.scale}}/>
        )
      }
    </Group>
  )
}

export default AsteroidPointItem;