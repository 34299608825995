import {Modal} from "antd";
import React from "react";
import LoginForm from "../../../auth/components/LoginForm";
import '../styles/modals.css';
import popupImage from '../../../../platformImages/popups/woman_sitting_popupImage.png';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const PopupForAnonymous = (props) => (
  <Modal
    title={null}
    centered
    style={{margin: 0, padding: 0}}
    visible={props.anonymousPopupVisible}
    onCancel={() => props.setAnonymousPopupVisible(false)}
    width={505}
    wrapClassName={'modal-common popup-for-anonymous'}
    footer={null}
  >
    <div className={'head-form'}>
      <img src={popupImage}/>
      <p style={{textAlign: 'center'}}>Войдите, чтобы воспользоваться полным функционалом платформы</p>
    </div>
    <LoginForm popupLogin={true} registrationMode={'tcr'}/>
  </Modal>
)



const mapStateToProps = state => ({
  anonymousPopupVisible: state.commonReducer.anonymousPopupVisible,
});
const mapDispatchToProps = (dispatch, {match}) => ({
  setAnonymousPopupVisible: (visible) => dispatch(actions.setAnonymousPopupVisible(visible))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopupForAnonymous);
