
export const convertTheoryQuestionToCorrectFormat = (questions) => {
  return questions?.reduce((accum, qst, i) => {
    const foundQuestion = accum.find((itm) => (itm.id === qst.id) || (itm.id === qst.next_step_for));
    const qstCorrFormat = {
      id: qst.id,
      title: qst.title,
      steps: [ {...qst} ]
    }
    if (foundQuestion) {
      const alreadyExist = foundQuestion.steps.find((step) => {
        return step.id === qst.id;
      });
      if (!alreadyExist) {
        foundQuestion.steps.push({...qst});
      }
    } else {
      accum.push(qstCorrFormat);
    }
    return accum
  }, [])
}