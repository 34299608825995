import { Image } from "react-konva";
import React from "react";
import {pxPerMetre} from "../settings";

export const CanvasEngine = (
  {
    engineImage,
    rotation,
    width,
    height
  }) => {

  return(
    <Image
      image={engineImage}
      width={width * pxPerMetre}
      height={height * pxPerMetre}
      offsetX={width * pxPerMetre/2}
      offsetY={height * pxPerMetre/2}
      rotation={rotation}
    />
  )
};
