import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import dragImg11 from "../images/arrangeByColumns/LogicLever3_1_1.png";
import dragImg12 from "../images/arrangeByColumns/LogicLever3_1_2.png";
import dragImg13 from "../images/arrangeByColumns/LogicLever3_1_3.png";

import dragImg21 from "../images/arrangeByColumns/LogicLever3_2_1.png";
import dragImg22 from "../images/arrangeByColumns/LogicLever3_2_2.png";
import dragImg23 from "../images/arrangeByColumns/LogicLever3_2_3.png";
import ArrangeByColumns from "./ArrangeByColumns";
import bgNature from '../images/bg.png';


const LogicLever3 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'All titles are false. Move the pictures',
      columnsItems: [
        {id: 1, successAnswerId: 2, title: "Has no wheels"},
        {id: 2, successAnswerId: 3, title: "Not a pulley"},
        {id: 3, successAnswerId: 1, title: "Not a lever"},
      ],
      dragItems: [
        {id: 1, image: dragImg12},
        {id: 2, image: dragImg11},
        {id: 3, image: dragImg13},
      ]
    },
    {
      title: 'A machine with a fulcrum is the first. A machine with a wheel is the last',
      columnsItems: [
        {id: 1, successAnswerId: 3, title: "The first"},
        {id: 2, successAnswerId: 1, title: "The second"},
        {id: 3, successAnswerId: 2, title: "The third"},
      ],
      dragItems: [
        {id: 1, image: dragImg22},
        {id: 2, image: dragImg23},
        {id: 3, image: dragImg21},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <ArrangeByColumns
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    columns={item.columnsItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default LogicLever3;
