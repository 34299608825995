import React, {useCallback} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Layer} from "react-konva";
import CanvasSubmarineScale from '../components/CanvasSubmarineScale'
import {connect} from "react-redux";



class SubmarineScale extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();

    this.state = {
      moveUp: false,
      moveDown: false,
    };
  }
  _resetCallback = () => {console.log('Debug reset callback')};
  getResetCallback = (callback) => {this._resetCallback = callback};

  componentDidMount() {
    const stageNode = this.stageRef?.current;
    if (stageNode) {
      this.setState({moveUp: false, moveDown: false});
    }
  }

  render() {

    const stageNode = this.stageRef?.current;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <CanvasSubmarineScale
              getResetCallback={this.getResetCallback}
              stageNode={stageNode}
              moveUp={this.state.moveUp}
              moveDown={this.state.moveDown}
            />
          </Layer>
        </CanvasContainer>

        <div style={styles.fieldsBlock}>
          <button
            style={styles.btn}
            onTouchStart={() => this.setState({moveDown: true, moveUp: false})}
            onTouchEnd={() => this.setState({moveDown: false, moveUp: false})}
            onMouseDown={() => this.setState({moveDown: true, moveUp: false})}
            onMouseUp={() => this.setState({moveDown: false, moveUp: false})}
          >Погружение</button>
          <button
            style={styles.btn}
            onTouchStart={() => this.setState({moveUp: true, moveDown: false})}
            onTouchEnd={() => this.setState({moveUp: false, moveDown: false})}
            onMouseDown={() => this.setState({moveUp: true, moveDown: false})}
            onMouseUp={() => this.setState({moveUp: false, moveDown: false})}
          >Подъем</button>
          <button
            style={styles.btn}
            onClick={() => this.setState({moveUp: false, moveDown: false}, () => this._resetCallback())}
          >Сбросить</button>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmarineScale);

const styles = {
  mainContainer: {
    background: '#EDF2FB',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    margin: '0 5px',
    background: '#9caaad',
    borderRadius: '7px',
    color: 'white',
    zIndex: 10,
    fontSize: '2vmin',
    padding: '1vmin 1.5vmin',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  fieldsBlock: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: '10%',
    right: '10%'
  }
};
