import React from "react";
import '../styles/lessonTag.css';

const LessonTag = ({align='left', tagItem, indx, itemsCount}) => {
  const size = 17;
  const background = tagItem.background || 'gray';
  const isAlignLeft = align === 'left';

  const containerTagStyle = {
    display: 'flex',
    [isAlignLeft ? 'marginRight' : 'marginLeft']: '-20px',
  }
  const tagStyle = {
    height: size,
    padding: '0 15px',
    background: background,
    color: tagItem.color || 'white',
    borderRadius: indx !== 0 ? '0 3px' : '3px 3px 0 0',
  }
  const triangleStgStyle = {
    position: 'relative',
    width: 0,
    height: 0,
    borderTopLeftRadius: '3px',
    borderBottomRightRadius: '3px',
  }
  const prefixTriangleStyle = {
    ...triangleStgStyle,
    left: 1,
    [isAlignLeft ? 'borderTop' : 'borderBottom']: `${size}px solid ${background}`,
    [isAlignLeft ? 'borderLeft' : 'borderRight']: `${size}px solid transparent`,
    transform: `rotate(${isAlignLeft ? 0 : -90}deg)`,
  }
  const suffixTriangleStyle = {
    ...triangleStgStyle,
    right: 1.5,
    [isAlignLeft ? 'borderBottom' : 'borderTop']: `${size}px solid ${background}`,
    [isAlignLeft ? 'borderRight' : 'borderLeft']: `${size}px solid transparent`,
    transform: `rotate(${isAlignLeft ? 0 : -90}deg)`,
  }
  const pStyle = {
    lineHeight: `${size}px`,
    fontWeight: 'bold',
    fontSize: 12
  }

  return (
    <div style={containerTagStyle}>
      {
        (
          isAlignLeft && indx !== 0 || !isAlignLeft
        ) && (
          <div style={prefixTriangleStyle} />
        )
      }

      <div className={'lesson-tag__content'} style={tagStyle}>
        <p className={'lesson-tag__title'} style={pStyle}>{tagItem.title}</p>
      </div>

      {
        (
          (!isAlignLeft && indx !== itemsCount-1) || isAlignLeft
        ) && (
          <div style={suffixTriangleStyle}/>
        )
      }
    </div>
  )
}

export default LessonTag;
