import {_t} from "../../../../utils/lang/common";


export const getCorrectTitle = (step) => {
  let text = _t('train_task.Task_text2')
  if (step < 1){
    text = _t('train_task.Task_text1')
  }
  return text;
}
