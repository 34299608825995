import cloneDeep from 'lodash.clonedeep';

export const pressureStatic = (gasAmount, temperatureKelvin, volume) => {
  const R = 8.31;
  return gasAmount * R * temperatureKelvin / volume;
};

export const volumeStatic = (gasAmount, temperatureKelvin, pressure) => {
  const R = 8.31;
  return gasAmount * R * temperatureKelvin / pressure;
};


export const temperatureStatic = (gasAmount, volume, pressure) => {
  const R = 8.31;
  return pressure * volume / (gasAmount * R);
};

/*
 Нужно процесс представить адиабатическим, p * V ^ k = const
 k = 4/3 для трехатомного газа

 при изменении объема
 T2 = (V2/V1) ^ (1-k) * T1
 p2 = (V2/V1) ^ (-k) * p1
*/

const kAdiabatic = 4/3;

export const gasAmount = ({petrol, air, exhaust}) => {
  return petrol * 0.01 + air + exhaust
};

export const meterPerPixel = 0.01;

export const pistonSize = 0.01;
export const volume = (pistonOffsetPx) => {
  return 0.01 + pistonOffsetPx * pistonSize * meterPerPixel;
};

const atmospherePressure = 100000;

const valveSizeCoef = (valveOffsetPx) => {
  const valveSize = 0.0004;
  const coef = 15;
  return valveOffsetPx * meterPerPixel * valveSize * coef;
};

export const valveIntakePerSec = (valveOffsetPx, pressure) => {
  return valveSizeCoef(valveOffsetPx) * (atmospherePressure - pressure);
};

export let gasPushed = false;
export const pushGas = (status=false) => {
  gasPushed = status;
};

export const inputValveWork = (inputValveOffsetPerSec, data, timedeltaSec, output=false) => {
  let consistency = data.internal.consistency;
  let intake = valveIntakePerSec(inputValveOffsetPerSec, data.internal.pressure) * timedeltaSec;
  const total = consistency.petrol + consistency.exhaust + consistency.air;
  if (Math.sign(intake) > 0) {
    data.internal.temperatureKelvin = (data.internal.temperatureKelvin * total  + 293 * intake) / (total + intake);
    if (output) {
      consistency.air += intake * 0.9;
      consistency.exhaust += intake * 0.1;
    } else {
      consistency.petrol += gasPushed ? intake * 0.5 : intake * 0.2;
      consistency.air += gasPushed ? intake * 0.5 : intake * 0.8;
    }
  } else {
    if  (total + intake < 0) {
      consistency = {petrol: 0, exhaust: 0, air: 0}
    } else {
      consistency.petrol += intake * consistency.petrol/total;
      consistency.air += intake * consistency.air/total;
      consistency.exhaust += intake * consistency.exhaust/total;
    }
  }

  // при близких давлениях происходит небольшое перемешивание газов
  if (Math.abs(atmospherePressure - data.internal.pressure) < 2000) {
    const mixSpeed = valveSizeCoef(inputValveOffsetPerSec) * 100;
    data.internal.temperatureKelvin = (data.internal.temperatureKelvin * (total - mixSpeed)  + 293 * mixSpeed) / total;
    if (output) {
      consistency.petrol += mixSpeed * ( -consistency.petrol/total );
      consistency.air += mixSpeed * (0.9 - consistency.air/total) ;
      consistency.exhaust += mixSpeed * (0.1 - consistency.exhaust/total);
    } else {
      consistency.petrol += mixSpeed * (0.5 - consistency.petrol/total);
      consistency.air += mixSpeed * (0.5 - consistency.air/total);
      consistency.exhaust += mixSpeed * ( -consistency.exhaust/total );
    }
  }
};

// ICE initial data
export const initialData = () => {
  let initInternal = {
    consistency: {
      petrol: 0.0001,
      air: 0.0001,
      exhaust: 0
    },
    temperatureKelvin: 293,
    pressure: undefined,
  };

  let initExternal = {
    pistonOffsetPx: 0,
  };

  let pressure = pressureStatic(gasAmount(initInternal.consistency), initInternal.temperatureKelvin, volume(initExternal.pistonOffsetPx));

  return {
    internal: {...initInternal, pressure},
    external: initExternal,
  }
};

// === PROCESSES ===

export const adiabaticVolumeChangeSimple = ({pressure, volume, temperature}, newVolume) => {
  let T1 = temperature;
  let p1 = pressure;
  let V1 = volume;
  let V2 = newVolume;

  let T2 = Math.pow(V2/V1, 1-kAdiabatic) * T1;
  let p2 = Math.pow(V2/V1, -kAdiabatic) * p1;

  return {
    pressure: p2,
    volume: V2,
    temperature: T2,
  };
};

export const adiabaticVolumeChange = (data, V1, V2) => {
  let T1 = data.internal.temperatureKelvin;
  let p1 = data.internal.pressure;

  let T2 = Math.pow(V2/V1, 1-kAdiabatic) * T1;
  let p2 = Math.pow(V2/V1, -kAdiabatic) * p1;

  data.internal.temperatureKelvin = T2;
  data.internal.pressure = p2;

  return data;
};

const petrolToTemperatureCoef = 2000;

export const burn = (data) => {
  let consistency = data.internal.consistency;
  // todo сделать модель, в которой сгорает не всё
  data.internal.temperatureKelvin += consistency.petrol * petrolToTemperatureCoef;
  if (consistency.petrol > 0 && consistency.air > 0) {
    consistency.exhaust = consistency.petrol + consistency.air;
    consistency.petrol = 0;
    consistency.air = 0;
  }
};
