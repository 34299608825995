import React from "react";
import {useQuery} from "../../../utils/custom-hooks/useQuery";
import {useHistory} from "react-router-dom";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";


const GoBack = (props) => {
  const {
    left, top,
    lessonData, setLessonVariant,
  } = props;

  const history = useHistory();
  const withback = useQuery().get('withback');
  const backUrl = useQuery().get('back');

  // lessonData
  return (
    <>
      {
        withback ? (
          <div
            style={{...styles.goToTasks, left: left || 0, top: top || 0}}
            onClick={() => {
              const id = lessonData?.id;

              // if (lessonData.grade && lessonData.chapter && lessonData.group) {
              //   props.setShowAllLessons(false);
              //   props.setCatalogGrade(`${lessonData.grade}`);
              //   props.setCatalogChapter(lessonData.chapter);
              //   props.setCatalogParagraph(lessonData.group);
              // }
              //
              // if (setLessonVariant) {
              //   setLessonVariant(undefined);
              // }

              let key = '/';
              // if (id) {
              //   key = `${key}#lsn-${id}`;
              // }
              //
              // if (backUrl) {
              //   key = `/${backUrl}/#lsn-${id}`;
              // }

              if (backUrl) {
                key = `/${backUrl}`;
              }

              history.push(key)
            }}
          >
            {"< Back to tasks"}
          </div>
        ) : null
      }
    </>
  )
}

const styles = {
  goToTasks: {
    position: 'absolute', top: '0px', left: '0px', padding: '0 5px', borderRadius: '0 0 3px 0',fontWeight: 500,
    zIndex: 201, color: '#4C6F9A', cursor: 'pointer', textDecoration: 'underline',
    backgroundColor: 'rgba(255,255,255, 0.6)'
  },
}


const mapStateToProps = (state, props) => {
  const chapters = state.lessonsReducer.chapters;
  const lessonsGroups = state.lessonsReducer.lessonsGroups;
  let currentChapters = {};
  let currentLessonsParagraphs = {};
  if (chapters.length) {
    currentChapters = chapters.find(ch => ch.id === props.lessonData.chapter);
  }
  if (lessonsGroups.length) {
    currentLessonsParagraphs = lessonsGroups.find(gp => gp.id === props.lessonData.group);
  }
  return {
    currentChapters,
    currentLessonsParagraphs,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setCatalogGrade: (grade) => dispatch(actions.setCatalogGrade(grade)),
  setCatalogChapter: (chapterId) => dispatch(actions.setCatalogChapter(chapterId)),
  setCatalogParagraph: (paragraphId) => dispatch(actions.setCatalogParagraph(paragraphId)),
  setShowAllLessons: (status) => dispatch(actions.setShowAllLessons(status)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoBack);