import {getChartInitialData} from "../utils/chart";
import Chart from "../../../components/chart/Chart";
import {_t} from "../../../../utils/lang/common";
import React from "react";

const ChartsContainer = (props) => {
  const externalData = props.externalData;

  return (
    <>
      {
        props.lsnCode === 'pathandspeed1' && (
          <div style={style.container}>
            <div style={style.contentBody}>
              <Chart
                customInitialData={() => getChartInitialData(externalData(), props.lsnCode)}
                currentLessonCode={props.lsnCode}
                start={() => externalData().start}
                getResetCallback={props.getResetCallback}
                title={`График зависимости пути от времени, м`}

                dataLabel={`Мотоциклист, A`}
                xLabel={`Время, мин`}
                yLabel={`Путь, м`}
                xMax={4}
                yMax={2400}
                yStep={600}
                xStep={1}
                height={330}
                externalData={() => externalData()}
                pointsValues={() => externalData().dataset}
              />
            </div>
          </div>
        )
      }{
        ['pathandspeed3', 'pathandspeed4'].includes(props.lsnCode) && (
          <div style={{...style.container, ...style.spaceChartContainer}}>
            <Chart
              customInitialData={() => getChartInitialData(externalData(), props.lsnCode)}
              currentLessonCode={props.lsnCode}
              start={() => externalData().start}
              getResetCallback={props.getResetCallback}
              title={`График зависимости пути от времени, м`}

              xLabel={`Время, сек`}
              yLabel={`Путь, км`}
              xMax={25}
              yMax={500}
              yStep={100}
              xStep={5}
              height={330}
              externalData={() => externalData()}
              pointsValues={() => externalData().dataset}
            />
          </div>
        )
      }
    </>
  )
};

export default ChartsContainer;

const style = {
  container: {
    padding: '25px',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EFEBE4',
    boxShadow: '0px 0px 3px 5PX rgba(0,0,0,0.1)',
  },
  contentBody: {
    background: 'white',
    width: '100%',
    padding: '0 20px 0 10px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  spaceChartContainer: {
    padding: '15px',
    width: '320px',
    height: '320px',
    left: 100,
    top: 230,
    background: 'white',
  }
}