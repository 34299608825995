import React from "react";
import {CanvasContainer} from "../../../canvas";
import useImage from "use-image";
import {Layer, Group, Text, Image, Line, Arc, Arrow, Circle} from "react-konva";
import cloneDeep from "lodash.clonedeep";
import {
    circlDragBoundFunc,
} from "../utils";
import yImg from '../../../../images/tgCircle/y.png';
import xImg from '../../../../images/tgCircle/x.png';
import oImg from '../../../../images/tgCircle/o.png';
import oneImg from '../../../../images/tgCircle/one.png';
// import arrowImg from '../../../../images/tgCircle/arrow.png';
// import formulasImg from '../../../../images/tgCircle/formulas.png';
import crclImg from '../../../../images/tgCircle/crcl.png';
import correctImg from '../../../../images/tgCircle/correct.png';
import dragcirclImg from '../../../../images/tgCircle/dragcircl.png';
import dragcirclOrangeImg from '../../../../images/tgCircle/dragcirclOrange.png';
import arrow_orangeImg from '../../../../images/tgCircle/arrow_orange.png';
import {getBoolByLsnCode} from "../utils";

import Victor from 'victor';


class tgCircle extends React.Component{
    constructor(props) {
        super(props);

        // --------- REFS ---------
        this.stageRef = React.createRef();

        this.dragcirclOrangeRef = React.createRef();
        this.arrowRef = React.createRef();
        this.orangeArrowRef = React.createRef();
        this.textAndArrowRef = React.createRef();
        this.degreesRef = React.createRef();
        this.setAngleRef = React.createRef();
        this.angleInPiRef = React.createRef();
        this.firstArcRef = React.createRef();
        this.circlBackgroundRef = React.createRef();
        this.correctRef = React.createRef();
        this.firstTextRef = React.createRef();
        this.firsCirclRef = React.createRef();
        this.secondTextRef = React.createRef();
        this.correctTaskRef = React.createRef();


        this.staticData = {
            prozrach: 0,
            strela: 0,
            endStrela: 0,
            correctSymbolAfterFirstMove: 0,
            sbrosFirstText: 0,
            zapuskTimera: 1,
        };

        this.state = {

        };

        this.initialData = {

            startTime: undefined,
            prevTime: undefined,
            start: false,
            circlenumber: 0,
            startThreeSecTimer: 1,
            secondTextappeared: 0,
            zapuskTaskTimera: 0,
        };


        this.data = cloneDeep(this.initialData);


    }

    componentDidMount() {
        window.requestAnimationFrame(this.move);
        this.setState({count: 0});
        this.setState({arrowThreeSec: 0});
        this.setState({hidenText: 0});
        this.setState({correctAnswerTaskTime: 0});
        this.doIntervalChange();
    }

    answerIsCorrect = () => {
        this.Interval = setInterval(() => {
            this.setState(prevState => ({
                correctAnswerTaskTime: prevState.correctAnswerTaskTime + 1
            }))
            if (this.state.correctAnswerTaskTime === 12) {
                clearInterval(this.Interval);
                this.state.correctAnswerTaskTime = 0;
            }
        }, 250)}


    //таймер
    doIntervalChange = () => {
        this.Interval = setInterval(() => {
            this.setState(prevState => ({
                count: prevState.count + 1
            }))
            if (this.state.count === 20) {
                clearInterval(this.Interval);
            }
        }, 25)}

    arrowThreeSecondsIntervalChange = () => {
        this.Interval = setInterval(() => {
            this.setState(prevState => ({
                arrowThreeSec: prevState.arrowThreeSec + 1
            }))
            if (this.state.arrowThreeSec === 30) {
                clearInterval(this.Interval);
            }
        }, 100)}

    hidenTextAndCorrectSymbolInterval = () => {
        this.Interval = setInterval(() => {
            this.setState(prevState => ({
                hidenText: prevState.hidenText + 1
            }))
            if (this.state.hidenText === 60) {
                clearInterval(this.Interval);
            }
        }, 50)}




    move = (time) => {
        const data = this.data;
        this.requestId = window.requestAnimationFrame(this.move);

        const stageNode = this.stageRef?.current;
        if (!stageNode) return;

        data.startTime = data.startTime || time;
        // const timedelta = data.prevTime ? time - data.prevTime : 0;
        // data.prevTime = time;
        // const timedeltaSec = timedelta / 1000;

        this.updateStage();
    };

    onDragStart = () => {
        this.data.prozrach = 1;
        this.data.endStrela = 0;
    };

    onDragMove = (e) => {
        this.data.prozrach = 1;
        this.data.strela = 1;
        const pos = e.target.position();
        const posV = new Victor(pos.x, pos.y);

        const pointA = Victor(170, -20);
        const pointB = Victor(180, -20);

        // console.log('debug on drag move', this.data.prevPosV, posV);
        if (this.data.prevPosV !== undefined) {
            if (posV.x > pointA.x && posV.x < pointB.x && posV.y >= pointA.y && this.data.prevPosV.y < pointA.y) {
                this.data.circlenumber =  this.data.circlenumber > -3 ? this.data.circlenumber - 1 : 0;
            }
            if (posV.x > pointA.x && posV.x < pointB.x && posV.y <= pointA.y && this.data.prevPosV.y > pointA.y) {
                this.data.circlenumber =  this.data.circlenumber < 3 ? this.data.circlenumber + 1 : 0;
            }
        }
        this.data.prevPosV = posV.clone();
    };
    onDragEnd = () => {
        this.data.prozrach = 0;
        this.data.endStrela = 1;
        this.data.correctSymbolAfterFirstMove = 1;
        this.data.sbrosFirstText = 1;
    };

    updateStage = () => {
        const {
            isTgCircle1,
            isTgCircle2,
            isTgCircle3,
            isTgCircle4,
            isTgCircle5,
        } = getBoolByLsnCode(this.props.code);
        const data = this.data;
        const dragcirclOrangeNode = this.dragcirclOrangeRef?.current;
        const arrowNode = this.arrowRef?.current;
        const orangeArrowNode = this.orangeArrowRef?.current;
        const stageNode = this.stageRef?.current;

        const textAndArrowNode = this.textAndArrowRef?.current;

        const degreesNode = this.degreesRef?.current;
        const setAngleNode = this.setAngleRef?.current;
        const angleInPiNode = this.angleInPiRef?.current;
        const firstArcNode = this.firstArcRef?.current;
        const circlBackgroundNode = this.circlBackgroundRef?.current;
        const correctNode = this.correctRef?.current;
        const firstTextNode = this.firstTextRef?.current;
        const firsCirclNode = this.firsCirclRef?.current;
        const secondTextNode = this.secondTextRef?.current;
        const correctTaskNode = this.correctTaskRef?.current;
        if (!stageNode) return;


        arrowNode.points([0, 6, (dragcirclOrangeNode.x() + 25) * 1.3, (dragcirclOrangeNode.y() + 25) * 1.3]);


        if (this.data.startThreeSecTimer === 1) {
            textAndArrowNode.opacity(this.state.count / 20);
        }

        if (this.data.correctSymbolAfterFirstMove === 1 && this.data.startThreeSecTimer === 1) {
            if (this.data.zapuskTimera === 1) {
                this.arrowThreeSecondsIntervalChange();
                this.data.zapuskTimera = 0;
            }
            correctNode.opacity(1 - this.state.arrowThreeSec / 30);
        }
        //запуск таймера для исчезновение галочки и текста через 3 секунды
        if (this.data.sbrosFirstText === 1 && this.data.startThreeSecTimer === 1) {
            this.hidenTextAndCorrectSymbolInterval();
            this.data.startThreeSecTimer = 0;
        }
        //исчезновение галочки и текста через 3 секунды
        if (this.state.hidenText >= 30) {
            correctNode.opacity((30 - (this.state.hidenText - 30)) / 30);
            firstTextNode.opacity((30 - (this.state.hidenText - 30)) / 30);
            secondTextNode.opacity(((this.state.hidenText - 30)) / 30);
        }
        //после обучения (начинается выполнение задания)
        if (secondTextNode.opacity() === 1 && this.data.secondTextappeared === 0) {

            if (degreesNode.text() === '45' + '°' && this.data.endStrela === 1) {
                setAngleNode.text(isTgCircle1 ? 'Set a 90°angle' : 'Set a π/2 angle');
                if (this.data.zapuskTaskTimera === 0 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 1;
                }
            }
            if (degreesNode.text() === '90' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a 90°angle') {
                setAngleNode.text('Set a 135°angle');
                if (this.data.zapuskTaskTimera === 1 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 0;
                }
            }
            if (degreesNode.text() === '135' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a 135°angle') {
                setAngleNode.text('Set a 180°angle');
                if (this.data.zapuskTaskTimera === 0 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 1;
                }
            }

            if (degreesNode.text() === '180' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a 180°angle') {
                setAngleNode.text('Set a 270°angle');
                if (this.data.zapuskTaskTimera === 1 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 0;
                }
            }
            //для π/2
            if (degreesNode.text() === '90' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a π/2 angle') {
                setAngleNode.text('Set a π angle');
                if (this.data.zapuskTaskTimera === 0 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 1;
                }
            }
            //для π
            if (degreesNode.text() === '180' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a π angle') {
                setAngleNode.text('Set a 2π angle');
                if (this.data.zapuskTaskTimera === 1 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 0;
                }
            }
            //для 2π
            if (degreesNode.text() === '360' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a 2π angle') {
                setAngleNode.text('Set a 3π angle');
                if (this.data.zapuskTaskTimera === 0 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 1;
                }
            }
            //для 3π
            if (degreesNode.text() === '540' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a 3π angle') {
                setAngleNode.text('Set a π/4 angle');
                if (this.data.zapuskTaskTimera === 1 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 0;
                }
            }
            //для π/4
            if (degreesNode.text() === '45' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a π/4 angle') {
                setAngleNode.text('Set a -3π/4 angle');
                if (this.data.zapuskTaskTimera === 0 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 1;
                }
            }
            //для -3π/4
            if (degreesNode.text() === '-135' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a -3π/4 angle') {
                setAngleNode.text(' ');
                if (this.data.zapuskTaskTimera === 1 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 0;
                }
            }

            if (degreesNode.text() === '270' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a 270°angle') {
                setAngleNode.text('Set a 360°angle');
                if (this.data.zapuskTaskTimera === 0 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 1;
                }
            }

            if (degreesNode.text() === '360' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a 360°angle') {
                setAngleNode.text('Set a 540°angle');
                if (this.data.zapuskTaskTimera === 1 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 0;
                }
            }

            if (degreesNode.text() === '540' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a 540°angle') {
                setAngleNode.text('Set a 720°angle');
                if (this.data.zapuskTaskTimera === 0 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 1;
                }
            }

            if (degreesNode.text() === '720' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a 720°angle') {
                setAngleNode.text('Set a -30°angle');
                if (this.data.zapuskTaskTimera === 1 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 0;
                }
            }

            if (degreesNode.text() === '-30' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a -30°angle') {
                setAngleNode.text('Set a -180°angle');
                if (this.data.zapuskTaskTimera === 0 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 1;
                }
            }

            if (degreesNode.text() === '-180' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a -180°angle') {
                setAngleNode.text('Set a -360°angle');
                if (this.data.zapuskTaskTimera === 1 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 0;
                }
            }

            if (degreesNode.text() === '-360' + '°' && this.data.endStrela === 1 && setAngleNode.text() === 'Set a -360°angle') {
                setAngleNode.text(' ');
                if (this.data.zapuskTaskTimera === 0 && this.state.correctAnswerTaskTime === 0) {
                    this.answerIsCorrect();
                    this.data.zapuskTaskTimera = 1;
                }
            }

        }
        // console.log(this.state.correctAnswerTaskTime);

        if (this.state.correctAnswerTaskTime !== 0) {
            correctTaskNode.opacity(1);
        } else {
            correctTaskNode.opacity(0);
        }

        //0,1,2,3,-1 = 0,-2 = 1, -3 = 2
        let CircleNumberSpecificationsArc = ['#cedceb', '#A0BBD8', '#739BC6', '#497CB2', '#cedceb', '#A0BBD8', '#739BC6']; // firstArcNode.fill
        let CircleNumberSpecificationsBack = ['#f0efec', '#cedceb', '#A0BBD8', '#739BC6', '#f0efec', '#cedceb', '#A0BBD8']; // circlBackgroundNode.fill
        let CircleNumberSpecificationsClock = [true, true, true, true, false, false, false]; //firstArcNode.clockwise
        let CircleNumberSpecificationsY = [280, 280, 280, 280, 360, 360, 360]; //degreesNode.y

        firstArcNode.fill(CircleNumberSpecificationsArc[data.circlenumber >= 0 ? data.circlenumber : Math.abs(data.circlenumber) + 3]);
        circlBackgroundNode.fill(CircleNumberSpecificationsBack[data.circlenumber >= 0 ? data.circlenumber : Math.abs(data.circlenumber) + 3]);
        firstArcNode.clockwise(CircleNumberSpecificationsClock[data.circlenumber >= 0 ? data.circlenumber : Math.abs(data.circlenumber) + 3]);
        degreesNode.y(CircleNumberSpecificationsY[data.circlenumber >= 0 ? data.circlenumber : Math.abs(data.circlenumber) + 3]);


        //ToDo нужно придумать условие при ктором комп будет понимать какой у нас (0, 1, 2, 3 или -1,-2,-3 ) оборот
        // this.data.circlenumber === 0, 1, 2, 3 или -1,-2,-3

        //условия для 0, 1, 2 и 3его круга
        //ToDO мне надо добавить тут для каждого круга pi/4 штуки
        if (dragcirclOrangeNode.y() + 20 <= 0 && this.data.circlenumber === 0) {

            degreesNode.text(Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°');


            firstArcNode.angle(-(Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text(Math.trunc(Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) / 45) + '/4 π');

        }
        if (dragcirclOrangeNode.y() + 20 > 0 && this.data.circlenumber === 0) {
            degreesNode.text(360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°');
            firstArcNode.angle(-(360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text(Math.trunc((360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }

        if (dragcirclOrangeNode.y() + 20 <= 0 && this.data.circlenumber === 1) {
            degreesNode.text(360 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°');
            firstArcNode.angle(-(Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text(Math.trunc((360 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 > 0 && this.data.circlenumber === 1) {
            degreesNode.text(720 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°');
            firstArcNode.angle(-(360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text(Math.trunc((720 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 <= 0 && this.data.circlenumber === 2) {
            degreesNode.text(720 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°');
            firstArcNode.angle(-(Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text(Math.trunc((720 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 > 0 && this.data.circlenumber === 2) {
            degreesNode.text(1080 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°');
            firstArcNode.angle(-(360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text(Math.trunc((1080 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 <= 0 && this.data.circlenumber === 3) {
            degreesNode.text(1080 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°');
            firstArcNode.angle(-(Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text(Math.trunc((1080 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 > 0 && this.data.circlenumber === 3) {
            degreesNode.text(1440 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°');
            firstArcNode.angle(-(360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text(Math.trunc((1440 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }

        //условия для -1, -2 и -3его круга
        if (dragcirclOrangeNode.y() + 20 <= 0 && this.data.circlenumber === -1) {
            degreesNode.text('-' + (360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°'));
            firstArcNode.angle((360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text('-' + Math.trunc((360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 > 0 && this.data.circlenumber === -1) {
            degreesNode.text('-' + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°');
            firstArcNode.angle((Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text('-' + Math.trunc(Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 <= 0 && this.data.circlenumber === -2) {
            degreesNode.text('-' + (720 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°'));
            firstArcNode.angle((360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text('-' + Math.trunc((720 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 > 0 && this.data.circlenumber === -2) {
            degreesNode.text('-' + (360 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°'));
            firstArcNode.angle((Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text('-' + Math.trunc((360 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 <= 0 && this.data.circlenumber === -3) {
            degreesNode.text('-' + (1080 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°'));
            firstArcNode.angle((360 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text('-' + Math.trunc((1080 - Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }
        if (dragcirclOrangeNode.y() + 20 > 0 && this.data.circlenumber === -3) {
            degreesNode.text('-' + (720 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI) + '°'));
            firstArcNode.angle((Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)));
            angleInPiNode.text('-' + Math.trunc((720 + Math.round(Math.acos((dragcirclOrangeNode.x() + 25) / 200) * 180 / Math.PI)) / 45) + '/4 π');
        }


        if (this.data.endStrela === 1) {
            if (degreesNode.text() === ('0' + '°') || degreesNode.text() === ('-0' + '°') || degreesNode.text() === ('360' + '°') || degreesNode.text() === ('-360' + '°') || degreesNode.text() === ('720' + '°') || degreesNode.text() === ('1080' + '°') || degreesNode.text() === ('-1080' + '°') || degreesNode.text() === ('1440' + '°') || degreesNode.text() === ('-1440' + '°')) {
                dragcirclOrangeNode.x(175);
                dragcirclOrangeNode.y(-20);
            }
            if (degreesNode.text() === ('90' + '°') || degreesNode.text() === ('810' + '°') || degreesNode.text() === ('450' + '°') || degreesNode.text() === ('1170' + '°') || degreesNode.text() === ('-270' + '°') || degreesNode.text() === ('-630' + '°') || degreesNode.text() === ('-990' + '°') || degreesNode.text() === ('-1350' + '°')) {
                dragcirclOrangeNode.x(-25);
                dragcirclOrangeNode.y(-220);
            }
            if (degreesNode.text() === ('-90' + '°') || degreesNode.text() === ('-810' + '°') || degreesNode.text() === ('-450' + '°') || degreesNode.text() === ('-1170' + '°') || degreesNode.text() === ('270' + '°') || degreesNode.text() === ('630' + '°') || degreesNode.text() === ('990' + '°') || degreesNode.text() === ('1350' + '°')) {
                dragcirclOrangeNode.x(-25);
                dragcirclOrangeNode.y(180);
            }
            if (degreesNode.text() === ('180' + '°') || degreesNode.text() === ('-180' + '°') || degreesNode.text() === ('540' + '°') || degreesNode.text() === ('-540' + '°') || degreesNode.text() === ('900' + '°') || degreesNode.text() === ('-900' + '°') || degreesNode.text() === ('1260' + '°') || degreesNode.text() === ('-1260' + '°')) {
                dragcirclOrangeNode.x(-225);
                dragcirclOrangeNode.y(-20);
            }
        }


        if (this.data.strela === 1) {
            orangeArrowNode.width(0);
        }
        if (this.data.prozrach === 0) {
            dragcirclOrangeNode.opacity(0.5);
        }
        if (this.data.prozrach === 1) {
            dragcirclOrangeNode.opacity(1);
        }

        stageNode.draw();
    };



    Canvas = () => {

        const [yIm] = useImage(yImg);
        const [xIm] = useImage(xImg);
        const [oIm] = useImage(oImg);
        const [one] = useImage(oneImg);
        // const [arrow] = useImage(arrowImg);
        const [crcl] = useImage(crclImg);
        const [dragcircl] = useImage(dragcirclImg);
        const [dragcirclOrange] = useImage(dragcirclOrangeImg);
        const [correct] = useImage(correctImg);
        const [arrowOrange] = useImage(arrow_orangeImg);
        // const [formulas] = useImage(formulasImg);

        const {
            isTgCircle1,
            isTgCircle2,
            isTgCircle3,
            isTgCircle4,
            isTgCircle5,
        } = getBoolByLsnCode(this.props.code);




        return (
         <React.Fragment>
            <Group x={500} y={150}>
                <Image image={crcl} width={400} height={400}/>

                <Circle x={200} y={200} radius={201} fill={'#f0efec'} ref={this.circlBackgroundRef}/>
                <Arc x={200} y={200} angle={0} clockwise={true} outerRadius={201} innerRadius={0} fill={'#cedceb'} ref={this.firstArcRef}/>

                <Image image={one} x={185} y={5} width={10} height={18}/>
                <Image image={yIm} x={185} y={-75} width={7} height={18}/>
                <Image image={one} x={405} y={205} width={10} height={18}/>
                <Image image={xIm} x={465} y={205} width={7} height={18}/>
                <Image image={oIm} x={185} y={205} width={10} height={18}/>
                <Line points={[200,-75,200,475]} stroke={'#d1c4b3'} strokeWidth={1.9}/>
                <Line points={[-75,201,475,201]} stroke={'#d1c4b3'} strokeWidth={1.9}/>
            </Group>
             <Text
                 x={755} y={110}
                 text={'1/4 π'}
                 stroke={isTgCircle2 ? '#597eab' : '#e6e2df'}
                 fill={isTgCircle2 ? '#597eab' : '#e6e2df'}
                 lineHeight={1.4}
                 strokeWidth={.8}
                 fontSize={40}
                 ref={this.angleInPiRef}
             />

            <Group x={700} y={345}>
                <Arrow ref={this.arrowRef} points={[0,6,255,5]} stroke={'#416b98'} fill={'#416b98'}/>
                <Image image={dragcirclOrange} x={isTgCircle1 ? 175 : 116} y={isTgCircle1 ? -20 : -160} width={50} height={50} draggable={true}
                       dragBoundFunc={(pos) => circlDragBoundFunc(this, pos)}
                       onDragStart={(e) => {this.onDragStart(e)}}
                       onDragMove={(e) => {this.onDragMove(e)}}
                       onDragEnd={(e) => {this.onDragEnd(e)}}
                       opacity={0.5} ref={this.dragcirclOrangeRef}/>
            </Group>

             <Group ref={this.textAndArrowRef}>

               <Group  ref={this.firstTextRef}>
                   {isTgCircle1 ? <Text
                     x={50} y={100}
                     text={'This is a unit circle.\nMove a terminal side\nof an angle in a\ncounterclockwise\ndirection in order to\nhave a positive angle.\n(Press      and drag)'}
                     stroke={'#597eab'}
                     fill={'#597eab'}
                     lineHeight={1.4}
                     strokeWidth={.8}
                     fontSize={40}
                    />: <Text
                       x={50} y={80}
                       text={'Angle can be measured\nnot only in degrees, but\nin radians too.\nYou can see the value\nin radians just over\nthe'}
                       stroke={'#597eab'}
                       fill={'#597eab'}
                       lineHeight={1.4}
                       strokeWidth={.8}
                       fontSize={40}
                   />}
                   <Image image={dragcircl} x={isTgCircle1 ? 175 : 130} y={isTgCircle1 ? 440 : 360} width={50} height={50} />
               </Group>


               <Group opacity={0}  ref={this.secondTextRef}>
                    <Text
                        x={50} y={250}
                        text={isTgCircle1 ? 'Set a 45° angle' : 'Set a π/2 angle'}
                        stroke={'#597eab'}
                        fill={'#597eab'}
                        lineHeight={1.4}
                        strokeWidth={.8}
                        fontSize={40}
                        ref={this.setAngleRef}
                    />
               </Group>
                <Text
                    x={735} y={280}
                    text={'0'}
                    stroke={'#597eab'}
                    fill={'#597eab'}
                    lineHeight={1.4}
                    strokeWidth={.8}
                    fontSize={40}
                    ref={this.degreesRef}
                />
                 {isTgCircle1 ?  <Image image={arrowOrange} x={910} y={275} width={30} height={154} ref={this.orangeArrowRef}/>:
                 <Image image={arrowOrange} x={860} y={140} width={30} height={104} scaleX={-1} rotation={-150} ref={this.orangeArrowRef}/>}

            </Group>
             <Image image={correct} x={150} y={500} width={isTgCircle1 ? 200 : 0} opacity={0} ref={this.correctRef}/>
             <Image image={correct} x={150} y={500} opacity={0} ref={this.correctTaskRef}/>
         </React.Fragment>
        )
    };


    render() {
        return (
            <div style={styles.mainContainer}>
                <CanvasContainer stageRef={this.stageRef}>
                    <Layer>
                        <this.Canvas/>
                    </Layer>
                </CanvasContainer>
            </div>
        )
    }
}

export default tgCircle;

const styles = {
    mainContainer: {
        background: '#e6e2df',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
};
