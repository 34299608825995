import React, {useEffect, useRef} from "react";
import {Group, Text, Rect} from "react-konva";


/**
 * PROPS
 * data: { progressRefCode: string, title: string, value: number, maxValue: number }
 * maxProgressWidth: number
 * textWidth: number
 * charWidth: number
 * progressColor: string
 * indicatorName: string
 */

const exampleData = [
  {progressRefCode: 'exmpl1', title: 'exmpl1', char: 'G', value: 200, maxValue: 200},
  {progressRefCode: 'exmpl2', title: 'exmpl2', char: 'F', value: 33, maxValue: 120},
];

class Indicator extends React.Component {
  constructor(props) {
    super(props);


    this.props.data().forEach((item) => {
      this[`${item.progressRefCode}Ref`] = React.createRef();
    })

    this.indicatorStageRef = React.createRef();

  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  move = (time) => {
    const {maxProgressWidth = 100,} = this.props;

    window.requestAnimationFrame(this.move);

    const indicatorStageNode = this.indicatorStageRef?.current;
    if (!indicatorStageNode) return;

    const data = this.props.data();

    data.forEach((item, i) => {
      const itemNode = this[`${item.progressRefCode}Ref`]?.current;
      const valPercent = item.value() / (item.maxValue / 100);
      const correctProgressWidth = (maxProgressWidth / 100) * valPercent;

      itemNode.width(Math.abs(correctProgressWidth));
    })

    indicatorStageNode.draw();
  }

  render() {
    const {
      data = () => [],
      maxProgressWidth = 100,
      progressColor = '#4378d0',
      indicatorName = 'indicatorName',
      textWidth = 200,
      charWidth = 50
    } = this.props;

    return (
      <Group ref={this.indicatorStageRef}>
        {
          data().map((item, i) => {
            const valPercent = item.value() / (item.maxValue / 100);
            const correctProgressWidth = (maxProgressWidth / 100) * valPercent;

            return (
              <Group y={40*i} key={`${indicatorName}-progress-${i}`}>
                <Text y={3} width={textWidth} text={item.title} fontSize={17} align={'right'}/>
                <Text x={textWidth} width={charWidth} text={item.char} fontSize={23} align={'center'}/>

                <Rect ref={this[`${item.progressRefCode}Ref`]} x={textWidth + charWidth} width={correctProgressWidth} height={20} fill={progressColor}/>
              </Group>
            )
          })
        }
      </Group>
    )
  }
}

export default Indicator;
