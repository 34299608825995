import {
  RESET_AUTH_REDUCER,
  UPDATE_CLASS_OF_STUDENT,
  UPDATE_EMAIL,
  UPDATE_IS_TEACHER,
  UPDATE_LAST_NAME,
  UPDATE_NAME,
  UPDATE_PASSWORD,
  UPDATE_PHONE,
  UPDATE_REPEAT_PASSWORD,
} from "../actions/authActions";


const initialState = {
  classOfStudent: 0,
  name: '',
  lastName: '',
  isTeacher: undefined,
  email: '',
  phone: '',
  password: '',
  repeatPassword: '',
}

export const authReducer = (state = initialState, {type, payload}) => {

  switch (type) {

    case UPDATE_NAME:
      return {
        ...state,
        name: payload
      }
    case UPDATE_LAST_NAME:
      return {
        ...state,
        lastName: payload
      }
    case UPDATE_CLASS_OF_STUDENT:
      return {
        ...state,
        classOfStudent: payload
      }
    case UPDATE_IS_TEACHER:
      return {
        ...state,
        isTeacher: payload
      }
    case UPDATE_EMAIL:
      return {
        ...state,
        email: payload
      }
    case UPDATE_PHONE:
      return {
        ...state,
        phone: payload
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        password: payload
      }
    case UPDATE_REPEAT_PASSWORD:
      return {
        ...state,
        repeatPassword: payload
      }

    case RESET_AUTH_REDUCER:
      return {
        ...initialState
      }
    default:
      return state;
  }
}
