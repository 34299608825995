import {Button, Form, Input} from "antd";
import {_t} from "../../../../utils/lang/common";
import React, {useState} from "react";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {ymStudentCompleteRegistrationGoal} from "../../../../metriks/ym_metriks";


const FormCompleteUser = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const sendForm = () => {
    ymStudentCompleteRegistrationGoal();
    props.completeStudentRegistration(email, password)
  }

  const requiredFieldErrTxt = `Обязательно для заполнения`;
  return (
    <Form
      onFinish={sendForm}
      method={'GET'}
      style={{
        maxWidth: '260px',
        marginInline: 'auto',
        display: "flex",
        flexDirection: "column",
        width: '100%'
      }}
    >
      <Form.Item rules={[{ required: true, message: requiredFieldErrTxt }]} initialValue={email} name={'email'}>
        <Input
          id={'updateUserEmailForm'}
          name={'email'}
          type="email"
          value={email}
          placeholder={'Email'}
          style={styles.input}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>
      <Form.Item rules={[{ required: true, message: requiredFieldErrTxt }]} initialValue={password} name={'password'}>
        <Input.Password
          id={'updateUserPasswordForm'}
          name={'password'}
          value={password}
          placeholder={_t("placeholder_password")}
          style={styles.input}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>
      <Button
        size={'large'}
        type="primary"
        htmlType="submit"
        style={{margin: '10px auto 0', width: "150px", borderRadius: '5px'}}
      >
        Отправить
      </Button>
    </Form>
  )
}


const mapStateToProps = (state, {match}) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    completeStudentRegistration: (email, password) => dispatch(actions.completeStudentRegistration(email, password)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormCompleteUser);


const styles = {
  input: {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  }
};
