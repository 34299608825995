import React, {useEffect, useState} from "react";
import LoginForm from "../components/LoginForm";
import RegistrationForm from "../components/RegistrationForm";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {blueColor} from "../../../utils/styles";
import {_t} from "../../../utils/lang/common";
import LangSelect from "../components/LangSelect";
import {Form, Input, Button, Card} from "antd";

const LessonSessionAuth = (props) => {
  const {match} = props;
  const {teacherCode} = match.params;

  const [isLessonClosed, setLessonClosed] = useState(false);
  const [state, setState] = useState({firstName: '', lastName: ''});

  useEffect(() => {
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    setState({firstName, lastName});
  }, []);

  const onChangeInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setState((prev) => {
      prev[name] = value;
      localStorage.setItem(name, value);
      return {...prev}
    })
  };

  const onClickMoveToLesson = async (e) => {
    e.preventDefault();
    const res = await props.lessonSessionAuth(teacherCode);
    if (res && !res.status)
      setLessonClosed(true)
  };

  return (
    <div style={styles.container}>
      <LangSelect />
      <Card style={styles.form} align={'center'}>
        <form onSubmit={onClickMoveToLesson}>
          <div style={{width: '100%'}}>
            <Form.Item
              label={<p style={{lineHeight: '1.7'}}><b>{_t('label_first_name')}</b></p>}
            >
              <Input
                name={'firstName'}
                required={true}
                style={styles.input}
                value={state.firstName}
                onChange={onChangeInput}
              />
            </Form.Item>
            <Form.Item
              label={<p style={{lineHeight: '1.7'}}><b>{_t('label_last_name')}</b></p>}
            >
              <Input
                required={true}
                style={styles.input}
                name='lastName'
                value={state.lastName}
                onChange={onChangeInput}
              />
            </Form.Item>
          </div>
          {
            isLessonClosed && (
              <p className={'ant-typography ant-typography-danger'} style={{margin: '0 0 10px'}}><b>Урок закрыт</b></p>
            )
          }
          <Button
            type={'primary'}
            htmlType={'submit'}
            onClick={onClickMoveToLesson}
          >{_t("button_go_to_lesson")}</Button>
        </form>
      </Card>
    </div>
  )
};


const mapStateToProps = (state) => ({
  lang: state.lessonsReducer.lang,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: async (language) => dispatch(actions.setLanguage(language)),
  lessonSessionAuth: async (teacherCode) => dispatch(actions.lessonSessionAuth(teacherCode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LessonSessionAuth);


const styles = {
  container: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    padding: '20px',
    width: '300px',
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: '7px',
    boxShadow: "0px 1px 2px 1px rgba(208, 216, 243, 0.3)",
    marginTop: '-100px',
  },
  label: {
    width: '100%',
    marginBottom: '15px'
  },
  button: {
    width: '200px',
    margin: '0 20px 0px',
    borderRadius: '7px',
    padding: '10px 10px',
    fontWeight: 'bold',
    border: 0,
    background: blueColor,
    color: 'white',
    fontSize: '1.6em'
  }
};