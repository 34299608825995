import Victor from "victor";
import {hourPerSecond, pxPerKm} from "./settings";


export const getLsnBoolByCode = (code) => ({
  pirates1: code === 'pirates1',
  pirates2: code === 'pirates2',
  pirates3: code === 'pirates3',
  pirates4: code === 'pirates4',
})

export const msToKh = (Vkm) => Vkm.clone().multiply(Victor(pxPerKm, pxPerKm)); // result is vector in pixels
export const scaleToPx = (Vkm) => Vkm.clone().multiply(Victor(pxPerKm, pxPerKm)); // result is vector in pixels
export const scaleToKm = (Vpx) => Vpx.clone().divide(Victor(pxPerKm, pxPerKm));
export const scaleTime = (Ts) => Ts * hourPerSecond;
export const scale = (Vkm) => Vkm.multiply(Victor(pxPerKm, pxPerKm)); // result is vector in pixels


export const getInitialDataByCode = (code) =>
{
  const { pirates1, pirates2, pirates3, pirates4} = getLsnBoolByCode(code);
  let initialState = {
    startTime: undefined,
    prevTime: undefined,

    timer: 0,

    shipStartTime: undefined,
    ballStartTime: undefined,
    shipTimeDelta: 0,
    ballTimeDelta: 0,

    waterOffset: 0,

    fail: false,

    delay: undefined,


    start: false,
    explosion: false,

    shipPos: Victor(0, 0),
    ballPos: Victor(0, 0),
    cannonPos: Victor(0, 0),

    cannonRotation: 0,
    shipRotation: 0,
  };

  if (pirates1 || pirates4)
  {
    initialState = {
      ...initialState,
      cannonSpeed: 1, // km/h
      distance: 10, // km
      ballSpeed: 80, // km/h
      boatSpeed: undefined, // km/h
      flowRate: -5, // km/h
      waterPatternOffsetScaled: new Victor(10, 0),

      // END POS
      endShipPos: Victor(13, 0), // km
      endCannonPos: Victor(13, 0), // km
      endBallPos: Victor(0, 10), // km
      shotPos: Victor(3, 0), // km

      // CENTER POS
      shotCenter: Victor(400, 150),
      cannonCenter: Victor(500, 460),
      shipCenter: Victor(500, 150),
      ballCenter: Victor(500, 150),

      shipRotation: 180,
    }
    if (pirates4) {
      initialState = {
        ...initialState,
        flowRate: .5, // km/h
      }
    }
  }
  else if (pirates2)
  {
    initialState = {
      ...initialState,
      distance: 120, // km
      ballSpeed: 80, // km/h
      boatSpeed: 45, // km/h
      flowRate: -20, // km/h
      waterPatternOffsetScaled: new Victor(0, 0),

      // END POS
      endShipPos: Victor(22, 0), // km
      endBallPos: Victor(0, 10), // km

      // CENTER POS
      cannonCenter: Victor(500, 460),
      shipCenter: Victor(200, 150),
      shotCenter: Victor(200, 150),
      ballCenter: Victor(500, 460),

      cannonRotation: 90,
    }
  }
  else if (pirates3)
  {
    initialState = {
      ...initialState,
      distance: 10, // km
      ballSpeed: 80, // km/h
      boatSpeed: 50, // km/h
      flowRate: -25, // km/h
      waterPatternOffsetScaled: new Victor(10, 0),

      // END POS
      endShipPos: Victor(22, 0), // km
      endBallPos: Victor(0, 10), // km

      // CENTER POS
      cannonCenter: Victor(500, 460),
      // shipCenter: Victor(500, 150),
      shipCenter: Victor(200, 150),
      ballCenter: Victor(200, 150),

      shipRotation: 0,
      cannonRotation: 90,
    }
  }
  return initialState;
};


export const getTitle = (code) => {
  const { pirates1, pirates2, pirates3, pirates4 } = getLsnBoolByCode(code);
  let text = '';
  if (pirates1 || pirates4) {
    text = 'Определи скорость корабля относительно воды, \nчтобы корабль попал в пушку. Обрати внимание на направление течения';
  }
  if (pirates2) {
    text = 'Определи время до выстрела, \nчтобы попасть в пиратский корабль';
  }
  if (pirates3) {
    text = 'Определи время до выстрела, \nчтобы пиратский корабль попал в пушку';
  }
  return text;
}