import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import { _t } from "../../../../utils/lang/common";

import dragImg11 from "../images/matchItems/LogicWheel6_1_row_1.png";
import dragImg12 from "../images/matchItems/LogicWheel6_1_row_2.png";
import dragImg13 from "../images/matchItems/LogicWheel6_1_row_3.png";

import dropImg11 from "../images/matchItems/LogicWheel6_1_drag_1.png";
import dropImg12 from "../images/matchItems/LogicWheel6_1_drag_2.png";
import dropImg13 from "../images/matchItems/LogicWheel6_1_drag_3.png";

import continueImg11 from "../images/matchItems/LogicWheel6_1_continue_1.png";
import continueImg12 from "../images/matchItems/LogicWheel6_1_continue_2.png";
import continueImg13 from "../images/matchItems/LogicWheel6_1_continue_3.png";


import dragImg21 from "../images/matchItems/LogicWheel6_2_drag_1.png";
import dragImg22 from "../images/matchItems/LogicWheel6_2_drag_2.png";
import dragImg23 from "../images/matchItems/LogicWheel6_2_drag_3.png";
import bgNature from '../images/bgNature.png';
import MatchItems from "./MatchItems";


const LogicWheel6 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: _t("logic_wheel.match_the_items"),
      dropItems: [
        {id: 1, successId: 3, completeImg: continueImg11, img: dropImg11},
        {id: 2, successId: 1, completeImg: continueImg12, img: dropImg12},
        {id: 3, successId: 2, completeImg: continueImg13, img: dropImg13},
      ],
      dragItems: [
        {id: 1, img: dragImg12},
        {id: 2, img: dragImg13},
        {id: 3, img: dragImg11},
      ]
    },
    {
      title: _t("logic_wheel.match_the_items"),
      dropItems: [
        {
          id: 1, successId: 3,
          completeText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            {_t("logic_wheel.a_wheel_with")}
            <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}> {_t("logic_wheel.an_axle")} </span>
            {_t("logic_wheel.is_a_simple_machine")}</span>,
          mainText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            {_t("logic_wheel.a_wheel_with") + " ________ " + _t("logic_wheel.is_a_simple_machine")}
          </span>
        },
        {
          id: 2, successId: 1,
          completeText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            {_t("logic_wheel.to")}
            <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}> {_t("logic_wheel.roll")} </span>
            {_t("logic_wheel.smth_on_wheel")}
          </span>,
          mainText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            {_t("logic_wheel.to") + " ________ " + _t("logic_wheel.smth_on_wheel")}
          </span>
        },
        {
          id: 3, successId: 2,
          completeText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}> {_t("logic_wheel.a_wheel")} </span>
            {_t("logic_wheel.helps_to_decrease")}
          </span>,
          mainText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            {" ________ " + _t("logic_wheel.helps_to_decrease")}
          </span>
        },
      ],
      dragItems: [
        {id: 1, img: dragImg23},
        {id: 2, img: dragImg21},
        {id: 3, img: dragImg22},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i + 1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <MatchItems
                    bgImg={bgNature}
                    title={item.title}
                    dropItems={item.dropItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default LogicWheel6;
