import React, {useCallback} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Layer, Group, Circle, Rect, Line, Text, Image} from "react-konva";
import CanvasBalloonScale from '../components/CanvasBalloonScale'
import {connect} from "react-redux";
import cloneDeep from "lodash.clonedeep";
import redBallImg from "../../../../images/balloon/redBall.png";
import redBall2Img from "../../../../images/balloon/redBall2.png";
import noBalloonImg from "../../../../images/balloon/noBaloon.png";
import useImage from "use-image";


class HeliumBalloonScale extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();
    this.heightScaleRef = React.createRef();
    this.airDensityIndicatorRef = React.createRef();

    this.staticData = {};
    this.state = {
      start: false,
    };
    this.initialData = {
      startTime: null,
      prevTime: null,

      airTemperature: 20,

      heliumScaleVal: 0,
      airScaleVal: 100,

      heightScaleOffsetY: 0,
      verticalSpeed: 0,
    };
    this.data = cloneDeep(this.initialData);
  }

  _resetCallback = () => {console.log('Debug reset callback')};
  getResetCallback = (callback) => {this._resetCallback = callback};

  handleReset = () => {
    this.setState({start: false});
    this._resetCallback();
    this.data = cloneDeep(this.initialData);
  };

  componentDidMount() {
    const stageNode = this.stageRef?.current;
    if (stageNode) {
      this.handleReset()
    }
    window.requestAnimationFrame(this.move);
  }

  getAllNodes = () => ({
    heightScaleNode: this.heightScaleRef?.current,
    airDensityIndicatorNode: this.airDensityIndicatorRef?.current,
  });

  get heightScaleOffsetY() {
    const isStart = this.state.start;
    return isStart ? this.data.heightScaleOffsetY + 2 : 0;
  }

  get airDensity() {
    return Math.exp(-this.data.heightScaleOffsetY * 0.003);
  }

  enslow (value, delta, timedeltaSec) {
    let absValue = Math.abs(value);
    let signValue = Math.sign(value);

    absValue -= delta * timedeltaSec;
    if (absValue < 0) absValue = 0;

    return signValue * absValue
  }

  get balloonMass() {
    return 0.5;
  }

  get archimedesForce() {
    return this.airDensity;
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    if (this.state.start) {
      const acceleration = (this.archimedesForce - this.balloonMass) * 100;

      data.verticalSpeed += acceleration * timedeltaSec;
      data.heightScaleOffsetY += data.verticalSpeed * timedeltaSec;

      data.verticalSpeed = this.enslow(data.verticalSpeed, Math.abs(data.verticalSpeed)/3, timedeltaSec);

      data.airDensity = this.airDensity;

      data.heliumScaleVal = this.heightScaleOffsetY / 3;
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const timedelta = data.prevTime ? data.startTime - data.prevTime : 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const nodes = this.getAllNodes();


    nodes.heightScaleNode.offsetY(-data.heightScaleOffsetY);

    nodes.airDensityIndicatorNode.fill(`rgba(81,185,236,${0.5 - 0.04 * (data.heightScaleOffsetY/50)})`);

    stageNode.draw();
  }

  CanvasComp = () => {
    const stageNode = this.stageRef?.current;

    const [redBall] = useImage(redBallImg);
    const [redBall2] = useImage(redBall2Img);
    const [noBalloon] = useImage(noBalloonImg);

    return (
      <>
        <Group x={0} y={400} ref={this.heightScaleRef}>
          {
            Array(16).fill(1).map((el, i) => {
              const correctIndex = i;
              const isFive = correctIndex % 5 === 0;
              const lineWidth = isFive ? 40 : 20;
              return (
                <Group y={-correctIndex*50} key={`scale-${i}`}>
                  <Rect width={520} height={50} fill={`rgba(81,185,236,${0.5 - 0.035 * i})`}/>
                  <Line points={[0, 0, lineWidth, 0]} stroke={'black'} strokeWidth={2}/>
                  <Text text={`${correctIndex ? correctIndex+'0' : 0}`} fill={'black'} fontSize={isFive ? 20 : 13} y={isFive ? -10 : -5} x={lineWidth+10}/>
                </Group>
              )
            })
          }
        </Group>

        {/* redBall2 */}
        <Image image={redBall} x={220} y={350} width={24} height={60}/>


        <Group scale={{x: .8, y: .8}} x={350} y={50}>
          <CanvasBalloonScale
            getResetCallback={this.getResetCallback}
            stageNode={stageNode}
            start={this.state.start}
            data={() => ({leftVal: this.balloonMass*2, rightVal: this.archimedesForce*2})}
            leftWeightNode={
              <Group offset={{x: 45, y: 100}}>
                <Image
                  image={redBall2}
                  width={90}
                  height={130}
                />
              </Group>
            }
            rightWeightNode={
              <Group x={-50} y={-110}>
                <Rect
                  ref={this.airDensityIndicatorRef}
                  x={2} y={2}
                  width={98}
                  height={136}
                  fill={'rgba(81,185,236,0.5)'}
                />
                <Image image={noBalloon} width={100} height={140}/>
              </Group>
            }
          />
        </Group>
      </>
    )
  };

  render() {

    const stageNode = this.stageRef?.current;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasComp />
          </Layer>
        </CanvasContainer>

        <div style={styles.fieldsBlock}>
          <button
            style={styles.btn}
            onClick={() => this.setState({start: true})}
          >Отпустить шарик</button>
          <button
            style={styles.btn}
            onClick={this.handleReset}
          >Сбросить</button>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeliumBalloonScale);

const styles = {
  mainContainer: {
    // background: '#EDF2FB',
    background: 'rgba(0,0,0,0.05)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    margin: '0 5px',
    background: '#9caaad',
    borderRadius: '7px',
    color: 'white',
    zIndex: 10,
    fontSize: '2vmin',
    padding: '1vmin 1.5vmin',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  fieldsBlock: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: '10%',
    right: '10%'
  }
};
