import React from "react";
import ContinueRowTask from "../cotainers/ContinueRowTask";
import ChooseExtraItemTask from "../cotainers/ChooseExtraItemTask";
import ArrangeByColumns from "../cotainers/ArrangeByColumns";
import MatchItems from "../cotainers/MatchItems";
import Numeric_task from "../cotainers/Numeric_task";
import {serverMediaUrl} from "../../../../utils/api";
import imgx from '../images/ximg.png';
import imgv from '../images/vimg.png';

export const schoolTaskTypes = {
  chooseExtraItem: {key: 'choose_extra_item', component: ChooseExtraItemTask},
  trueFalse: {key: 'true_false', component: ChooseExtraItemTask},
  arrangeByColumns: {key: 'arrange_by_columns', component: ArrangeByColumns},
  numeric_task: {key: 'numeric', component: Numeric_task},
  matchItems: {key: 'match_items', component: MatchItems},
  continueRow: {key: 'continue_row', component: ContinueRowTask},
}

export const getCorrectTaskComponentByType = (taskType) => {
  const correctType = Object.values(schoolTaskTypes).find(stt => stt.key === taskType);
  return correctType?.component || <></>
}

export const getCorrectFormatOptions = (options, type) => {
  let items = options.map(item => {
    item.success = item.option_success;
    item.image = item.option_img ? serverMediaUrl + item.option_img : null;
    item.mainText = item.option_text;
    item.text = item.option_title;
    item.bool = item.option_bool;
    item.float_value = item.option_float;
    return item;
  })
  if (type === schoolTaskTypes.chooseExtraItem.key) {
    return {items}
  }
  if (type === schoolTaskTypes.numeric_task.key) {
    return {items}
  }
  if (type === schoolTaskTypes.trueFalse.key) {
    items.forEach(el => el.bool ? (el.text = 'True', el.image = imgv) : (el.text = 'False', el.image = imgx));
    return {items}
  }
  if (type === schoolTaskTypes.arrangeByColumns.key) {
    /**
     * Получение правильного ответа
     * ***
     *  С столбцами вышел казус, выбор правильного столбца удобен в админке,
     *  но не удобен в плане выбора правильной карточки при распределении по столбцам
     * ***
     * Решение ниже:
     *  * Находим элемент, через сравнение column.id(текущего элемента) и success_column.id(искомого)
     *  это и будет наш верный элемент, после записываем в successAnswerId
     */
    let columns = options.map(columnItem => {
      const successOptionForColumn = options.find(item => {
        return columnItem.column?.id === item.success_column?.id
      });

      return ({
        id: columnItem.column?.id,
        title: columnItem.column?.name,
        successAnswerId: successOptionForColumn?.id
      })
    })
    return {columns, dragItems: items}
  }
};
