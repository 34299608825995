import React, {useEffect} from "react";
import '../styles/farm-map.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import MapContainer from "./MapContainer";
import {checkIsTeacherMap, maps} from "../utils";
import FarmMapElement2 from "../components/FarmMapElement2";
import GreeceBlocksMapElement from "../components/GreeceBlocksMapElement";
import bg from '../images/greece-blocks-mp-bg.png';


const GreeceBlocksMap = (props) => {
  const isTeacher = checkIsTeacherMap();

  useEffect(() => {
    if (isTeacher) {
      props.courseMapCreateStatistic();
    }
    // todo remove, test use of action
    // props.getTaskAvailabilityStatus('greece_blocks', 'lift_load1').then(res => console.log('debug on test action result', res));
    // props.patchTaskRangeAvailability('greece_blocks', "2", true).then(res => {
    //   console.log('debug on test action result 3', res);
    //   props.getTaskRanges('greece_blocks').then(res => console.log('debug on test action result 2', res))
    // })
  }, []);

  const Content = ({tasksItems, isOpen, taskModalVisible, currentLessonCode, successScreenVisible, setTaskModalVisible}) => {

    useEffect(() => {
      // Initial redirect if already complete task
      const taskForRedirect = tasksItems.find(task => task.paymentRedirect);
      if (taskForRedirect?.complete && taskForRedirect?.paymentRedirect && !props.coursePaid && !isOpen) {
        window.location.href = taskForRedirect.paymentRedirect;
      }
    }, [tasksItems.length])

    useEffect(() => {
      if (!successScreenVisible) {
        const currentTask = tasksItems.find(task => task.lessonCode === currentLessonCode);
        if (currentTask?.complete && currentTask?.paymentRedirect && !props.coursePaid && !isOpen) {
          window.location.href = currentTask.paymentRedirect;
        }
      }
    }, [tasksItems, successScreenVisible]);

    return (
      <div className={'farm-map-background'}
           style={{backgroundImage: `url(${bg})`}}
      >
        {
          tasksItems.map((item, i) => {
            const prevItem = tasksItems.find(el => el.lessonCode === item.prevKey);
            return (
              <GreeceBlocksMapElement
                key={item.lessonCode}
                isOpen={isOpen}
                isTeacher={isTeacher}
                prevItem={prevItem}
                index={i}
                setTaskModalVisible={setTaskModalVisible} coursePaid={props.coursePaid} {...item}
                preOpen={() => props.getTaskAvailabilityStatus(maps.greeceBlocks.key, item.lessonCode)}
              />
            )
          })
        }
      </div>
    )
  }
  return (
    <MapContainer {...props} lang={'en'} withoutFog={true} withoutSuccessScreen={true} mapKey={maps.greeceBlocks.key}>
      <Content />
    </MapContainer>
  )
}

const mapStateToProps = state => {
  return ({
    coursePaid: state.mapReducer.coursePaid,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({
  getOrCreateMapProgress: (initial) => dispatch(actions.getOrCreateMapProgress(initial, maps.greeceBlocks.key)),
  courseMapAuth: () => dispatch(actions.courseMapAuth()),

  courseMapCreateStatistic: () => dispatch(actions.courseMapCreateStatistic(maps.greeceBlocks.key)),
  getTaskAvailabilityStatus: (mapKey, lessonCode) => dispatch(actions.getTaskAvailabilityStatus(mapKey, lessonCode)),

  getTaskRanges: () => dispatch(actions.getTaskRanges(maps.greeceBlocks.key)),
  patchTaskRangeAvailability: (mapKey, rangeName, status) => dispatch(actions.patchTaskRangeAvailability(mapKey, rangeName, status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GreeceBlocksMap);
