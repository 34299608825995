import React, {useEffect, useRef, useState} from "react";
import {Group, Image, Rect, Text} from "react-konva";

/**
 *  PROPS
 *
 *  x?: numbers
 *  y?: numbers
 *
 *  onClick: () => void -- click method
 *  text: string
 *
 *  textFill?: string
 *  textStroke?: string
 *  width?: number
 *  height?: number
 *  fontSize?: number
 *  strokeWidth?: number
 *  btnFill?: string
 *  btnCornerRadius?: number
 *
 *  textRef?: reference
 *  btnRef?: reference
 *
 */

const CanvasButton = (props) => {
  const {
    x, y,

    onClick = () => {},
    onTouchStart= () => {},
    onTouchEnd= () => {},
    onMouseDown= () => {},
    onMouseUp= () => {},
    onMouseLeave= () => {},

    backgroundImg,

    text,
    textFill,
    textStroke,

    prefixImg,
    prefixX,
    prefixY,
    prefixWidth,
    prefixHeight,
    prefixRotation,
    prefixScale,

    suffixImg,
    suffixX,
    suffixY,
    suffixWidth,
    suffixHeight,
    suffixRotation,

    width=120,
    height=50,
    textWidth=width,
    textHeight=height,
    textX,
    fontSize,
    fontStyle="bold",
    strokeWidth,

    btnFill='#3d405b',
    btnStroke='#3d405b',
    btnStrokeWidth=0,
    btnCornerRadius=0,

    boxShadowColor,

    opacity,

    textRef,
    btnRef,

    children,

    visible,

    align='center',

    disabled=false,
    scale
  } = props;
  const [mouseDown, setMouseDown] = useState(null);

  const correctOpacity = mouseDown ? .8 : opacity ?? 1;
  return (
    <Group
      x={x || 0} y={y || 0}
      onClick={!disabled ? onClick : undefined}
      onTap={!disabled ? onClick : undefined}
      onTouchStart={() => { setMouseDown(true); onTouchStart(); }}
      onTouchEnd={() => { setMouseDown(false); onTouchEnd(); }}
      onMouseDown={() => { setMouseDown(true); onMouseDown(); }}
      onMouseUp={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";
        setMouseDown(false); onMouseUp();
      }}
      onMouseEnter={e => {
        const container = e.target.getStage().container();
        container.style.cursor = "pointer";
      }}
      onMouseLeave={e => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";
        setMouseDown(false);
        onMouseLeave()
      }}
      ref={btnRef}
      opacity={disabled ? .5 : correctOpacity}
      visible={visible}
    >
      {
        children ? (
          children
        ) : (
          <>
            {
              backgroundImg ? (
                <Image
                  image={backgroundImg}
                />
              ) : (
                <Rect
                  id={'btnRect'}
                  width={width} height={height}
                  fill={btnFill}
                  cornerRadius={btnCornerRadius}
                  stroke={btnStroke}
                  strokeWidth={btnStrokeWidth}
                  shadowColor={boxShadowColor || 'rgba(0,0,0,0.5)'}
                  shadowBlur={10}
                  shadowOffset={{x: 2, y:2}}
                />
              )
            }

            {
              prefixImg && (
                <Image
                  image={prefixImg}
                  offsetX={prefixX}
                  x={width/12}
                  offsetY={prefixY}
                  y={height/2 - 9}
                  width={prefixWidth}
                  height={prefixHeight}
                  rotation={prefixRotation}
                  scale={prefixScale}
                />
              )
            }
            <Text
              ref={textRef}
              text={text}
              x={textX}
              stroke={textStroke || 'white'}
              fill={textFill || 'white'}
              width={textWidth}
              height={textHeight}
              fontSize={fontSize || 20}
              fontStyle={fontStyle}
              verticalAlign={'middle'}
              align={align}
              strokeWidth={strokeWidth || 1}
            />
            {
              suffixImg && (
                <Image
                  image={suffixImg}
                  offsetX={suffixX}
                  x={width - suffixWidth}
                  offsetY={prefixY}
                  y={height/2 - 9}
                  width={suffixWidth}
                  height={suffixHeight}
                  rotation={suffixRotation}
                />
              )
            }
          </>
        )
      }

    </Group>
  )
}

export default CanvasButton;