import {getLsnNameByCode} from "./common";


export const getTitleByCode = (code, step) => {
  const { pathandspeed3, pathandspeed4 } = getLsnNameByCode(code);
  let title = '';
  if (pathandspeed3) {
    if (step === 0) {
      title = '' +
        'Определи скорость космического корабля, \n' +
        'используя данные о пройденном пути, \n' +
        'поступающие каждые 5 секунд';

    } else if (step >= 1) {
      title = 'Построй график скорости космического корабля:';
    }
  } else if (pathandspeed4) {
    title = '' +
      'Построй график скорости космического корабля, \n' +
      'используя данные о пройденном пути, \n' +
      'поступающие каждые 5 секунд';
  }
  return title
}

export const getStaticDataByCode = (code) => {
  const { pathandspeed3, pathandspeed10 } = getLsnNameByCode(code);
  let staticData = {};
  if (pathandspeed3 || pathandspeed10) {
    staticData = {
      pointsChats: 6,
      maxStep: 1,
      stepsCount: 2,
      maxShipPath: 500,
      maxShipSec: 25,
    };
  } else {
    staticData = {
      pointsChats: 6,
      maxStep: 1,
      stepsCount: 2,
      maxShipPath: 700,
      maxShipSec: 25,
    };
  }

  return staticData;
}