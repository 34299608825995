import TaskContainerWithCanvasSize from "./TaskContainerWithCanvasSize";
import React, {useEffect} from "react";
import Presentation from "./Presentaiton";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";


const PresentationContainer = (props) => {

  useEffect(() => {
    (async function () {
      await props.addLessonResult(props.lessonCode, false, {}, true);

      setTimeout(() => {
        props.addLessonResult(props.lessonCode, true, {}, false);
      }, 5000)
    })()
  }, [])


  return (
    <TaskContainerWithCanvasSize
      {...props.taskContainerProps}
      taskContent={
        <Presentation width={props.width} presentation={props.presentation} />
      }
    />
  )
}


const mapStateToProps = (state, {match}) => ({});
const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PresentationContainer);
