import Chart from "./Chart";
import React from "react";
import {getBoolByLsnCode} from "../utils/common";
import {_t} from '../../../../utils/lang/common';
import {capitalizeFirstLetter as _c} from "../../../../utils/common";


const getChartInitialData = ({dataLabel}) => ({
  labels: [],
  datasets: [
    {
      label: dataLabel+1,
      align: 'right',
      data: [],
      borderColor: ['#2972CC'],
      pointBorderColor: '#2972CC',
      borderWidth: 2,
      backgroundColor: ['rgba(200,0,0,0.0)'],
      pointBackgroundColor: '#2972CC',
      pointStyle: 'circle',
    },
    {
      label: dataLabel+2,
      align: 'right',
      data: [],
      borderColor: ['#cc6a29'],
      pointBorderColor: '#cc6a29',
      borderWidth: 2,
      backgroundColor: ['rgba(200,0,0,0.0)'],
      pointBackgroundColor: '#cc6a29',
      pointStyle: 'circle',
    }
  ]
})


const getTripleChartPropsByCode = (externalData, lsnCode, chartNumber) => {
  const {
    idealGas4, idealGas5, idealGas6,
    idealGas7, idealGas8, idealGas9,
    idealGas10, idealGas11,
  } = getBoolByLsnCode(lsnCode);


  let resProps = {
    title: '',
    dataLabel: '',
    xLabel: '',
    yLabel: '',
    pointValueX: '',
    pointValueY: '',
  };
  const isTimeCharts = idealGas4 || idealGas6 || idealGas8 || idealGas10;
  const isValuesCharts = idealGas5 || idealGas7 || idealGas9 || idealGas11;

  const volumeAxes = {max: 1, step: .2};
  const pressureAxes = {max: 20000, step: 4000};
  const timeAxes = {max: 20, step: 5};
  const temperatureAxes = {max: 600, step: 120};

  switch (chartNumber) {
    case 1: {
      if (isTimeCharts) {
        resProps = {
          title: _c(`${_t('ideal_gas.pressure')}/${_t('ideal_gas.time')}`),
          dataLabel: `P, ${_t('ideal_gas.pa')}`,
          xLabel: `t, ${_t('ideal_gas.sec')}`,
          yLabel: `P, ${_t('ideal_gas.pa')}`,
          pointValueX:() => externalData().currentTimeSec,
          pointValueY:() => externalData().pressure,
          yMax: pressureAxes.max,
          yStep: pressureAxes.step,
          xMax: timeAxes.max,
          xStep: timeAxes.step,
        }
      } else if (isValuesCharts) {
        resProps = {
          title: _c(`${_t('ideal_gas.pressure')}/${_t('ideal_gas.temperature')}`),
          dataLabel: `P, ${_t('ideal_gas.pa')}`,
          xLabel: `T, ${_t('ideal_gas.kelvin')}`,
          yLabel: `P, ${_t('ideal_gas.pa')}`,
          pointValueX:() => externalData().temperature,
          pointValueY:() => externalData().pressure,
          yMax: pressureAxes.max,
          yStep: pressureAxes.step,
          xMax: temperatureAxes.max,
          xStep: temperatureAxes.step,
        }
        if (idealGas5) {
          resProps = {
            ...resProps,
            pointValueX:() => externalData().temperature1,
            pointValueY:() => externalData().pressure1,
            pointValueX2: () => externalData().temperature2,
            pointValueY2: () => externalData().pressure2,
            chartInitialData: getChartInitialData({dataLabel: `Газ `}),
          }
        }
      }
      break;
    }
    case 2: {
      if (isTimeCharts) {
        resProps = {
          title: _c(`${_t('ideal_gas.volume')}/${_t('ideal_gas.time')}`),
          dataLabel: `V, ${_t('ideal_gas.m3')}`,
          xLabel: `t, ${_t('ideal_gas.sec')}`,
          yLabel: `V, ${_t('ideal_gas.m3')}`,
          pointValueX:() => externalData().currentTimeSec,
          pointValueY:() => externalData().volume,
          yMax: volumeAxes.max,
          yStep: volumeAxes.step,
          xMax: timeAxes.max,
          xStep: timeAxes.step,
        }
      } else if (isValuesCharts) {
        resProps = {
          title: _c(`${_t('ideal_gas.volume')}/${_t('ideal_gas.temperature')}`),
          dataLabel: `V, ${_t('ideal_gas.m3')}`,
          xLabel: `V, ${_t('ideal_gas.m3')}`,
          yLabel: `T, ${_t('ideal_gas.kelvin')}`,
          pointValueX:() => externalData().volume,
          pointValueY:() => externalData().temperature,
          xMax: volumeAxes.max,
          xStep: volumeAxes.step,
          yMax: temperatureAxes.max,
          yStep: temperatureAxes.step,
        }
        if (idealGas5) {
          resProps = {
            ...resProps,
            pointValueX:() => externalData().volume,
            pointValueY:() => externalData().temperature1,
            pointValueX2:() => externalData().volume,
            pointValueY2:() => externalData().temperature2,
            chartInitialData: getChartInitialData({dataLabel: `Газ `}),
          }
        }
      }
      break;
    }
    case 3: {
      if (isTimeCharts) {
        resProps = {
          title: _c(`${_t('ideal_gas.temperature')}/${_t('ideal_gas.time')}`),
          dataLabel: `T, ${_t('ideal_gas.kelvin')}`,
          xLabel: `t, ${_t('ideal_gas.sec')}`,
          yLabel: `T, ${_t('ideal_gas.kelvin')}`,
          pointValueX:() => externalData().currentTimeSec,
          pointValueY:() => externalData().temperature,
          yMax: temperatureAxes.max,
          yStep: temperatureAxes.step,
          xMax: timeAxes.max,
          xStep: timeAxes.step,
        }
      } else if (isValuesCharts) {
        resProps = {
          title: _c(`${_t('ideal_gas.pressure')}/${_t('ideal_gas.volume')}`),
          dataLabel: `P, ${_t('ideal_gas.pa')}`,
          xLabel: `V, ${_t('ideal_gas.m3')}`,
          yLabel: `P, ${_t('ideal_gas.pa')}`,
          pointValueX:() => externalData().volume,
          pointValueY:() => externalData().pressure,
          yMax: pressureAxes.max,
          yStep: pressureAxes.step,
          xMax: volumeAxes.max,
          xStep: volumeAxes.step,
        }
        if (idealGas5) {
          resProps = {
            ...resProps,
            pointValueX: () => externalData().volume,
            pointValueY: () => externalData().pressure1,
            pointValueX2: () => externalData().volume,
            pointValueY2: () => externalData().pressure2,
            chartInitialData: getChartInitialData({dataLabel: `Газ `}),
          }
        }
      }
      break;
    }
  }


  return resProps;
};



const ChartsContainer = (props) => {
  const {
    idealGas1, idealGas2, idealGas3,
    idealGas4, idealGas5, idealGas6,
    idealGas7, idealGas8, idealGas9,
    idealGas10, idealGas11,
  } = getBoolByLsnCode(props.lsnCode);

  const externalData = props.externalData;

  let yMax = 200;
  let yStep = 40;
  if (idealGas2) {
    yMax = 0.5;
    yStep = 0.1;
  } else if (idealGas3) {
    yMax = 20000;
    yStep = 4000;
  }

  return (
    <div style={{
      width: '100%', height: '19%', position: 'absolute', top: '18%', left: '0', zIndex: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'
    }}>
      {
        (idealGas1 || idealGas2 || idealGas3) ? (
          <div style={{width: '92%'}} key={1}>
            <Chart
              currentLessonCode={props.lsnCode}
              start={() => externalData().start}
              getResetCallback={props.getResetCallback1}
              title={`
                График зависимости ${
                  idealGas1 ? `температуры от времени` 
                  : idealGas2 ? `объема от времени`
                  : idealGas3 ? `давления от времени`
                  : '-'
                }`
              }

              dataLabel={`${
                idealGas1 ? `T, ${_t('ideal_gas.kelvin')}` 
                : idealGas2 ? `V, ${_t('ideal_gas.m3')}`
                : idealGas3 ? `P, ${_t('ideal_gas.pa')}`
                : '-'
              }`}
              xLabel={`t, ${_t('ideal_gas.sec')}`}
              yLabel={`${
                idealGas1 ? `T, ${_t('ideal_gas.kelvin')}`
                : idealGas2 ? `V, ${_t('ideal_gas.m3')}`
                : idealGas3 ? `P, ${_t('ideal_gas.pa')}`
                : '-'
              }`}
              xMax={60}
              yMax={yMax}
              yStep={yStep}
              xStep={3}
              height={95}
              externalData={() => externalData()}

              pointValueX={() => externalData().currentTimeSec}
              pointValueY={() => {
                const data = externalData();
                if (idealGas1) {
                  // todo add kelvin-celsium switcher
                  return data.temperature - 273;
                } else if (idealGas2) {
                  return data.volume;
                } else if (idealGas3) {
                  return data.pressure;
                }
              }}
            />
          </div>
        ) : (idealGas4 || idealGas5 || idealGas6 || idealGas7 || idealGas8 || idealGas9 || idealGas10 || idealGas11) ? (
          <div style={{
            width: '96%', display: 'flex', justifyContent: 'space-between'
          }} key={2}>
            <div style={{
              width: '31%',
              position: 'absolute',
              bottom: '-55.5%',
              right: '37.5%',
            }} key={`1-${props.lsnCode}`}>
              <Chart
                currentLessonCode={props.lsnCode}
                start={() => externalData().start}
                getResetCallback={props.getResetCallback3Left}

                xMax={1}
                yMax={yMax}
                yStep={yStep}
                width={300}
                height={300}
                externalData={() => externalData()}

                {...getTripleChartPropsByCode(externalData, props.lsnCode, 1)}

              />
            </div>
            <div style={{
              width: '31%',
              position: 'absolute',
              top: '173%',
              right: '3.5%',
            }} key={`2-${props.lsnCode}`}>
              <Chart
                currentLessonCode={props.lsnCode}
                start={() => externalData().start}
                getResetCallback={props.getResetCallback3Center}

                xMax={1}
                yMax={yMax}
                yStep={yStep}
                width={300}
                height={300}
                externalData={() => externalData()}

                {...getTripleChartPropsByCode(externalData, props.lsnCode, 2)}

              />
            </div>
            <div style={{
              width: '31%',
              position: 'absolute',
              bottom: '-55.5%',
              right: '3.5%',
            }} key={`3-${props.lsnCode}`}>
              <Chart
                currentLessonCode={props.lsnCode}
                start={() => externalData().start}
                getResetCallback={props.getResetCallback3Right}

                xMax={1}
                yMax={yMax}
                yStep={yStep}
                width={300}
                height={300}
                externalData={() => externalData()}

                {...getTripleChartPropsByCode(externalData, props.lsnCode, 3)}

              />
            </div>
          </div>
        ) : (
          <></>
        )
      }
    </div>
  )
};

export default ChartsContainer;
