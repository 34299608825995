import React from "react";
import {CanvasContainer} from "../../../canvas";
import useImage from "use-image";
import backgroundImg from "../../../../images/oscillation/background.png";
import {Layer, Group, Rect, Text, Image, Line, Circle, Arrow} from "react-konva";
import cloneDeep from "lodash.clonedeep";
import {_t} from "../../../../utils/lang/common";
import RadioSwitch from "../../../canvas/components/RadioSwitch";
import spaceImg from "../../../../images/space.png";
import {getBoolByLsnCode} from "../utils";
import Card from '../../../canvas/components/Card';
import CanvasInput from '../../../canvas/components/CanvasInput';
import * as actions from "../../../../store/actions";
import anime from "animejs";
import {connect} from "react-redux";
const animation = anime.timeline({autoplay: false});

const finishAnimationOptions = {
  targets: '.oscillation',
  easing: 'linear',
  translateX: 0,
  translateY: 0,
  duration: 1000,
  delay: 100,
  rotate: 90,
};


class Spring extends React.Component{
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();

    this.elasticityValRef = React.createRef();
    this.weightValRef = React.createRef();
    this.springRef = React.createRef();
    this.ballRef = React.createRef();
    this.ballBallRef = React.createRef();
    this.startBtnRef = React.createRef();
    this.pauseBtnRef = React.createRef();
    this.gravityBtnRef = React.createRef();
    this.forceOfGravityArrowRef = React.createRef();
    this.springArrowRef = React.createRef();
    this.switcherCardRef = React.createRef();
    this.spaceRef = React.createRef();
    this.timeTextRef = React.createRef();
    this.oscillationTextRef = React.createRef();


    this.staticData = {
      minWeight: 0.2,
      maxWeight: 4,

      minElasticity: 2,
      maxElasticity: 10,

      lengthOfString: 115,

    };

    this.state = {
      count: 0,
      oscil: 0,
    };

    this.initialData = {

      startTime: undefined,
      prevTime: undefined,
      start: false,
      pause: false,
      switchActive: false,
      weight: 1,
      elasticity: 4,
      ballOffsetY: 0,
      ballOffsetV: 0,
      gravityG: 9.8,
      elasticityQuestion: null,
    };

    this.data = cloneDeep(this.initialData);
  }

  openModal = () => this.props.changeSuccessVisible(true);

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  doIntervalChange = () => {
    if (this.Interval) {
      clearInterval(this.Interval);
    }
    this.Interval = setInterval(() => {
      this.setState(prevState => ({
        count: prevState.count + 1
      }))
    }, 1000)}


  move = (time,e) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    if (data.switchActive) {
      this.data.gravityG = 0;
    } else {
      this.data.gravityG = 9.8;
    }


    if (this.data.start) {
      this.setState({oscil: Math.floor(this.state.count  /  Math.PI)});
    }




    if (this.data.start) {

      let sumF = this.data.gravityG * this.data.weight - this.data.elasticity * this.data.ballOffsetY
      let ballOffsetA = sumF / this.data.weight;
      this.data.ballOffsetV = this.data.ballOffsetV + ballOffsetA * timedeltaSec;
      this.data.ballOffsetY = this.data.ballOffsetY + this.data.ballOffsetV * timedeltaSec + ballOffsetA * timedeltaSec * timedeltaSec / 2;

      }

    this.updateStage();
  };

  updateStage = (e) => {
    const data = this.data;
    const startBtnTextNode = this.startBtnRef?.current;
    const pauseBtnTextNode = this.pauseBtnRef?.current;
    const gravityBtnTextNode = this.gravityBtnRef?.current;
    const springNode = this.springRef?.current;
    const ballNode = this.ballRef?.current;
    const ballBallNode = this.ballBallRef?.current;
    const elasticityValNode = this.elasticityValRef?.current;
    const weightValNode = this.weightValRef?.current;
    const forceOfGravityArrowNode = this.forceOfGravityArrowRef?.current;
    const springArrowNode = this.springArrowRef?.current;
    const ballLeftNode = this.ballLeftRef?.current;
    const stageNode = this.stageRef?.current;
    const spaceNode = this.spaceRef?.current;
    const timeTextNode = this.timeTextRef?.current;
    const oscillationTextNode = this.oscillationTextRef?.current;
    if (!stageNode) return;

    spaceNode.opacity(Number(data.switchActive));

    elasticityValNode.text(data.elasticity);
    weightValNode.text(data.weight);

    ballBallNode.radius(Math.pow(data.weight, 1/3)*20);
    const correctBallOffsetY = data.ballOffsetY;
    ballNode.y(correctBallOffsetY);
    if (correctBallOffsetY < - 80) this.data.ballOffsetV = 5;
    ballNode.x(0);


    forceOfGravityArrowNode.points([200, 220, 200, 220 + this.data.weight * this.data.gravityG]);
    springArrowNode.points([200, 220, 200,220 - this.data.elasticity * data.ballOffsetY/2]);


    startBtnTextNode.text(data.start ? _t("reset") : _t("start") );
    pauseBtnTextNode.text(_t("pause"));
    gravityBtnTextNode.text(data.gravityG ? _t("on_earth") : _t("in_space"));

    timeTextNode.text(this.state.count);
    oscillationTextNode.text(this.state.oscil);


    const correctSpring = data.ballOffsetY;


    let stepOfString = (this.staticData.lengthOfString+correctSpring)/13;
    let springPoints = [];
    const springStartPoints = [0,0,0,20];
    for (let i = 0; i < 12; i++){
      springPoints.push(10*Math.pow(-1, i), 25+stepOfString);
      stepOfString += (this.staticData.lengthOfString+correctSpring)/13;
    }
    const springEndPoints = [0,25 + stepOfString, 0,160 + correctSpring];


    springNode.points([
      ...springStartPoints,
      ...springPoints,
      ...springEndPoints
    ]);

    stageNode.draw();
  };


    onDragStart = (e) => {
        const data = this.data;
        this.data.ballOffsetA = 0;
        this.data.ballOffsetV = 0;
    };

    onDragMove = (e) => {

        const data = this.data;
        this.data.ballOffsetY = e.target.y();
        this.data.ballOffsetA = 0;
        this.data.ballOffsetV = 0;

    };

    onDragEnd = (e) => {
        const data = this.data;
        this.data.ballOffsetA = 0;
        this.data.ballOffsetV = 0;
    };


  startResetOnClick = () => {
    if (this.data.start) {
      this.data.start = false;
      this.data = cloneDeep(this.initialData);
      clearInterval(this.Interval);
      this.setState({count: 0, oscil: 0});
      // this.state.count = 0;
      // this.state.oscil = 0;
      this.forceUpdate();
    } else {
      this.data.start = true;
      this.doIntervalChange();
    }
  };

  pauseOnClick = () => {
    if (this.data.pause) {
      this.data.start = true;
    } else {
      this.data.start = false;
      clearInterval(this.Interval);
    }
  }

  checkOnClick = () => {
    if (this.data.elasticityQuestion > 3.5 && this.data.elasticityQuestion < 4.5){
      animation.add(finishAnimationOptions);
      animation.play();
      this.openModal();
    }
    else {
      !this.props.failureModalVisible && this.props.changeFailureVisible(true);
    }
  }


  onChangeWeight = (val) => {
    let res = val;
    if (val > this.staticData.maxWeight) res = this.staticData.maxWeight;
    if (val < this.staticData.minWeight) res = this.staticData.minWeight;
    this.data.weight = res;
  };
  onChangeElasticity = (val) => {
    let res = val;
    if (val > this.staticData.maxElasticity) res = this.staticData.maxElasticity;
    if (val < this.staticData.minElasticity) res = this.staticData.minElasticity;
    this.data.elasticity = res;
  };

  CanvasSpring = () => {

    const [background] = useImage(backgroundImg);
    const [space] = useImage(spaceImg);
    return (
      <Group>
        <Image image={background} preventDefault={false}/>
        <Image width={1000} height={900} image={space} ref={this.spaceRef}/>


            <Line x={200} y={48} ref={this.springRef} points={[0,0,0, 20]} strokeWidth={2} stroke={'rgba(0,0,0,0.8)'}/>

        <Group
            ref={this.ballRef}
            draggable={true}
            onDragMove={(e) => {this.onDragMove(e)}}
            onDragStart={(e) => {this.onDragStart(e)}}
            onDragEnd={(e) => {this.onDragEnd(e)}}>

            <Circle x={200} y={220} ref={this.ballBallRef} radius={40} fill={'#5F9EA0'}/>

            <Arrow
              ref={this.forceOfGravityArrowRef}
              points={[200, 250, 200, 300]}
              strokeWidth={3}
              pointerWidth={4}
              pointerLength={4}
              stroke={"red"}
              fill={"red"}
            />


            <Arrow
              ref={this.springArrowRef}
              points={[200, 250, 200, 0]}
              strokeWidth={3}
              pointerWidth={4}
              pointerLength={4}
              stroke={"blue"}
              fill={"blue"}
            />
            </Group>



      </Group>
      )
  };

  CanvasButtons = () => {

    const {
      isOscillation1,
      isOscillation2,

    } = getBoolByLsnCode(this.props.code);

    const buttonsSize = {
      width: 60,
      height: 60
    };
    return (
      <Group x={800} y={280}>

        <Group x={-200} opacity={Number(isOscillation1)}>
          <Text y={0} x={-220} text={_t("spring_constant")} stroke={'#457B9E'} fill={'#457B9E'} fontSize={27} strokeWidth={1} width={190} align={'center'}/>

          <Group
            x={250}
            onClick={() => this.onChangeElasticity(this.data.elasticity + 1)}
          >
            <Rect {...buttonsSize} fill={'#E97D62'} cornerRadius={10} />
            <Text text={'>'} stroke={'white'} fill={'white'} strokeWidth={2} fontSize={24} align={'center'} verticalAlign={'middle'} {...buttonsSize}/>
          </Group>

          <Text y={13} x={60} text={'0'} ref={this.elasticityValRef} stroke={'#457B9E'} fill={'#457B9E'} fontSize={35} strokeWidth={1} width={200} align={'center'}/>

          <Group
            onClick={() => this.onChangeElasticity(this.data.elasticity - 1)}
          >
            <Rect {...buttonsSize} fill={'#E97D62'} cornerRadius={10} />
            <Text text={'<'} stroke={'white'} fill={'white'} strokeWidth={2} fontSize={24} align={'center'} verticalAlign={'middle'} {...buttonsSize}/>
          </Group>
        </Group>



        <Group y={90} x={-200} opacity={Number(isOscillation1)}>
          <Text y={13} x={-220} text={_t("weight")} stroke={'#457B9E'} fill={'#457B9E'} fontSize={27} strokeWidth={1} width={190} align={'right'}/>

          <Group
            x={250}
            onClick={() => this.onChangeWeight(Math.floor((this.data.weight + 0.11)*10)/10)}
          >
            <Rect {...buttonsSize} fill={'#E97D62'} cornerRadius={10} />
            <Text text={'>'} stroke={'white'} fill={'white'} strokeWidth={2} fontSize={24} align={'center'} verticalAlign={'middle'} {...buttonsSize}/>
          </Group>

          <Text y={13} x={60} text={'0'} ref={this.weightValRef} stroke={'#457B9E'} fill={'#457B9E'} fontSize={35} strokeWidth={1} width={200} align={'center'}/>

          <Group
            onClick={() => this.onChangeWeight(Math.floor((this.data.weight - 0.1)*10)/10)}
          >
            <Rect {...buttonsSize} fill={'#E97D62'} cornerRadius={10} />
            <Text text={'<'} stroke={'white'} fill={'white'} strokeWidth={2} fontSize={24} align={'center'} verticalAlign={'middle'} {...buttonsSize}/>
          </Group>
        </Group>



        <Group

          x={-200} y={180}
          onClick={() => this.startResetOnClick()}
        >
          <Rect width={310} height={60} fill={'#E97D62'} cornerRadius={10} />
          <Text ref={this.startBtnRef} text={'Запуск'} stroke={'white'} fill={'white'} strokeWidth={1} fontSize={24} align={'center'} verticalAlign={'middle'} width={310} height={60}/>
        </Group>
        <Group
            x={-200} y={110}
            onClick={() => this.pauseOnClick()}
            opacity={Number(isOscillation2)}
            >
          <Card height={isOscillation2 ? 200 : null} width={isOscillation2 ? 310 : null} y={-150} x={-350}>
            <Text y={5} text={`масса : 1 кг`}  x={150} fill={'#E97D62'} fontSize={24} />
            <Text y={50} text={`время : `}  x={148} fill={'#E97D62'} fontSize={24} />
            <Text y={50} text={`100`} x={240} fill={'#E97D62'} fontSize={24} ref={this.timeTextRef}/>
            <Text y={95} text={`кол-во колебаний : `} x={21} fill={'#E97D62'} fontSize={24} />
            <Text y={95} text={`101`}  x={240} fill={'#E97D62'} fontSize={24} ref={this.oscillationTextRef}/>
            <Text y={135} text={`введите коэфф.`}  x={50} fill={'#E97D62'} fontSize={24}/>
            <Text y={160} text={`упругости : `}  x={108} fill={'#E97D62'} fontSize={24}/>
            <CanvasInput
                y={160}
                x={240}
                width={50}
                height={30}
                stage={this.stageRef?.current}
                textColor={'#E97D62'}
                onInput={(val) => {console.log('debug on elastic input'); this.data.elasticityQuestion = val}}
                value={this.data.elasticityQuestion}
            />
          </Card>
            {isOscillation2 ? <Group onClick={() => this.checkOnClick()}>
              <Rect width={310} height={60} fill={'#E97D62'} cornerRadius={10}  x={0} y={-8}/>
              <Text  x={0} y={-8} text={'Проверить ответ'} stroke={'white'} fill={'white'} strokeWidth={1} fontSize={24} align={'center'} verticalAlign={'middle'} width={310} height={60}/>
            </Group> : null}
          <Rect width={310} height={60} fill={'#E97D62'} cornerRadius={10}  x={-350} y={70}/>
          <Text ref={this.pauseBtnRef} x={-350} y={70} text={'ПАУЗА'} stroke={'white'} fill={'white'} strokeWidth={1} fontSize={24} align={'center'} verticalAlign={'middle'} width={310} height={60}/>
        </Group>


        <Group
          x={-200} y={250}
        >
          <Rect width={240} height={60} fill={'#457B9E'} cornerRadius={0} x={70}/>
          <Text ref={this.gravityBtnRef} text={'ГРАВИТАЦИЯ'} stroke={'white'} fill={'white'} strokeWidth={1} fontSize={24} align={'center'} verticalAlign={'middle'} width={310} height={60} x={35}/>
          <RadioSwitch
              x={0} y={15}
              stageNode={this.stageRef?.current}
              switchFieldName={'switchActive'}
              externalData={this.data}
              onClick={() => this.data.switchActive = !this.data.switchActive}
          />
        </Group>
      </Group>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasSpring/>
            <this.CanvasButtons/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  failureModalVisible: state.commonReducer.failureModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Spring);

const styles = {
  mainContainer: {
    background: '#F1FAEE',
    zIndex: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
};
