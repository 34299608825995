import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ChooseExtraItemTask from "./ChooseExtraItemTask";
import bgNature from '../images/greece-flowers-bg.png';

import img1 from "../images/сhooseExtraItemTask/GreeceBlocks_1_1_1.png";
import img2 from "../images/сhooseExtraItemTask/GreeceBlocks_1_1_2.png";
import img3 from "../images/сhooseExtraItemTask/GreeceBlocks_1_1_3.png";
import img4 from "../images/сhooseExtraItemTask/GreeceBlocks_1_1_4.png";

import img21 from "../images/сhooseExtraItemTask/GreeceBlocks_1_2_1.png";
import img22 from "../images/сhooseExtraItemTask/GreeceBlocks_1_2_2.png";
import img23 from "../images/сhooseExtraItemTask/GreeceBlocks_1_2_3.png";
import img24 from "../images/сhooseExtraItemTask/GreeceBlocks_1_2_4.png";

import img31 from "../images/сhooseExtraItemTask/GreeceBlocks_1_3_1.png";
import img32 from "../images/сhooseExtraItemTask/GreeceBlocks_1_3_2.png";
import img33 from "../images/сhooseExtraItemTask/GreeceBlocks_1_3_3.png";
import img34 from "../images/сhooseExtraItemTask/GreeceBlocks_1_3_4.png";


const GreeceBlocks1 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'Choose an extra item',
      items: [
        {id: 1, image: img1},
        {id: 2, image: img2},
        {id: 3, image: img3, success: true},
        {id: 4, image: img4},
      ]
    },
    {
      title: 'Choose an extra item',
      items: [
        {id: 1, image: img21},
        {id: 2, image: img22, success: true},
        {id: 3, image: img23},
        {id: 4, image: img24},
      ]
    },
    {
      title: 'Choose a machine which doesn’t change the effort',
      items: [
        {id: 1, image: img31},
        {id: 2, image: img32},
        {id: 3, image: img33},
        {id: 4, image: img34, success: true},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >

      {
        chooseTasks.map((item, i) => (
          <React.Fragment key={`step${i}`}>
            {
              ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                <ChooseExtraItemTask
                  lang={'en'}
                  bgImg={bgNature}
                  title={item.title}
                  items={item.items}
                  taskComplete={() => {
                    setCompletedTaskCount(prevState => prevState + 1);
                    if ((i + 1) === chooseTasks.length) {
                      setAllComplete(true);
                    }
                  }}
                />
              ) : null
            }
          </React.Fragment>
        ))
      }
    </TaskMainContainer>
  )
}
export default GreeceBlocks1;
