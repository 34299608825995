import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import MainPage from "../mainPage/containers/MainPage";
import AuthPage from "../auth/containers/AuthPage";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {getCorrectLang} from "../../utils/lang/common";
import LessonSessionAuth from "../auth/containers/LessonSessionAuth";
import IFrame from "../mainPage/containers/IFrame";
import {Layout} from "antd";
import {YMInitializer} from 'react-yandex-metrika';
import TasksListScreen from "../whitelabel/containers/TasksListScreen";
import SimpleTaskScreen from "../whitelabel/containers/SimpleTaskScreen";
import Layout2 from "../pages/layout2/containers/Layout";
import NewsFeed from "../pages/newsFeed/containers/NewsFeed";
import {HomeworkAuth, StudentHomework, TeacherHomework} from "../pages/homework";
import TextProblemTasks from "../testProblems/containers/TextProblemTasksList";
import TextProblemTasksContainer from "../testProblems/containers/TextProblemTasksContainer";
import Grades from "../pages/grades";
import Statistics from "../pages/statistic";
import {updateFavicon} from "../../utils/favicons";
import {PopupExample} from "../Tilda/containers/PopupExample";
import PopupForAnonymous from "../pages/layout2/components/PopupForAnonymous";
import PopupFeedback from "../pages/layout2/components/PopupFeedback";
import PopupCongratulationForTeacher from "../pages/layout2/components/PopupCongratulationForTeacher";
import SuccessScreen from "../pages/successScreen/containers/SuccessScreen";

import CatMap from "../pages/courseMap/containers/CatMap";
import CatFinalTestMap from "../pages/courseMap/containers/CatFinalTestMap";
import CatMapPayment from "../pages/courseMap/containers/CatMapPayment";
import FarmMap from "../pages/courseMap/containers/FarmMap";
import ChooseExtraItemTask from "../pages/schoolTasks/cotainers/ChooseExtraItemTask";
import ContinueRowTask from "../pages/schoolTasks/cotainers/ContinueRowTask";
import ArrangeByColumns from "../pages/schoolTasks/cotainers/ArrangeByColumns";
import MatchItems from "../pages/schoolTasks/cotainers/MatchItems";
import FarmMapAuth from "../pages/courseMap/containers/FarmMapAuth";
import FarmMap2 from "../pages/courseMap/containers/FarmMap2";
import GreeceBlocksMap from "../pages/courseMap/containers/GreeceBlocksMap";
import ChooseSomeItemsTask from "../pages/schoolTasks/cotainers/ChooseSomeItemsTask";
import FarmMap4 from "../pages/courseMap/containers/FarmMap4";
import FarmMap5 from "../pages/courseMap/containers/FarmMap5";
import TeacherLessons from "../pages/layout2/containers/TeacherLessons";

function App(props) {

  useEffect(() => {
    if (!props.user) {
      props.fetchCurrentUser();
    }
    props.fetchHeaderBanner();
    const correctLang = getCorrectLang();
    props.setLanguage(correctLang);
    updateFavicon();

    const consoleLogStyles = 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)';
    // проверка инициализации
    console.log('%cHello world', consoleLogStyles);
  }, []);

  // Отправка запроса через интервалы
  // useSetInterval(props.sendLessonActivityLog, 1000, Boolean(props.lessonSession.token));


  const url = new URL(window.location.href);
  const isEshalaWhitelabel = url.hostname === 'enshaala.inventic.tech';
  const isTildaInventicTech = url.hostname === 'inventic.tech';
  // const isTildaInventicTech = url.hostname === 'localhost';

  if (isEshalaWhitelabel) {
    document.title = 'eShaala demo';
  }
  const __DEV__ = window.location.hostname === 'localhost';

  let routes = <></>;
  switch (true) {
    case isEshalaWhitelabel:
      routes = [
        <Route path={"/"}>
          <TasksListScreen/>
          <Route key={'task1'} path={"/task/:lang/:code/:id/:withDescr"} component={SimpleTaskScreen}/>
          <Route key={'task2'} path={"/task/:lang/:code/:id"} component={SimpleTaskScreen}/>
        </Route>
      ];
      break;
    case isTildaInventicTech:
      routes = [
        <Route path={"/"} component={PopupExample}/>
      ]
      break;
    default:
      routes = [
        <Route key={'frame2'} path="/frame/:lang/:code/:id/:withDescr" component={IFrame}/>,
        <Route key={'frame1'} path="/frame/:lang/:code/:id" component={IFrame}/>,

        <Route key={'newsFeed'} path={"/(news|)"} component={NewsFeed}/>,
        // <Route key={'layout2'} path="/(layout2|)" component={RequireAuth(Layout2)} />,
        <Route key={'layout2'} path={["/tasks", "/layout2"]} component={Layout2}/>,
        <Route key={'teacher_lessons'} path={["/teacher-lessons"]} component={TeacherLessons}/>,
        <Route key={'teacher_cards'} path={"/teacher-cards"}>
          <Layout2 teacherCardsCatalog={true}/>
        </Route>,


        // <Route key={'teacher_homework'} path={["/teacher-homework", "/homework"]} component={RequireAuth(TeacherHomework)} />,
        <Route key={'teacher_homework'} path={["/teacher-homework", "/homework"]} component={TeacherHomework}/>,
        // <Route key={'student_homework'} path="/student-homework" component={RequireAuth(StudentHomework)} />,
        <Route key={'student_homework'} path="/student-homework" component={StudentHomework}/>,


        <Route key={'statistic'} path="/statistic" component={Statistics}/>,
        <Route key={'grades'} path="/grades" component={Grades}/>,


        // <Route key={'homework-auth'} path={["/homework-auth/:code", "/homework-auth"]} component={HomeworkAuth}/>,
        <Route key={'homework-auth'} path={["/homework-auth/:code", "/homework-auth"]}
               component={props => <AuthPage formType={'homework-auth'} {...props}/>}/>,

        /** --------------- TASKS -------------------- */
        <Route
          key={'task_for_homework'}
          path={"/task/:lang/:code/:id/:withDescr/:homeworkId"}
          component={SimpleTaskScreen}
        />,
        <Route
          key={'task_with_description'} /* Для некоторых задач про космос */
          path={"/task/:lang/:code/:id/:withDescr"}
          component={SimpleTaskScreen}
        />,
        <Route
          key={'task_default'}
          path={"/task/:lang/:code/:id"}
          component={SimpleTaskScreen}
        />,
        /** --------------------------- */


        <React.Fragment key={'routes'}>
          <Route key={'main'} path={["/main/:id", "/main"]}>
            <MainPage/>
          </Route>

          {/** --------- AUTH --------- */}
          <Route key={'login'} path="/(login)/" component={props => <AuthPage formType={'login'} {...props}/>}/>
          <Route key={'registration'} path="/registration"
                 component={props => <AuthPage formType={'registr'} {...props}/>}/>
          <Route
            key={'forgotPassword'} path={["/forgot-password", "/forgotPassword"]}
            component={(props => <AuthPage formType={'forgotPassword'} {...props}/>)}
          />
          <Route
            key={'changePassword'}
            path={["/change-password/:code", "/changePassword/:code"]}
            component={(props => <AuthPage formType={'changePassword'} {...props}/>)}
          />
          {/** --------------------------- */}


          <Route
            key={'test'}
            path={"/test/:lsnCode"}
            component={(props => <TextProblemTasksContainer {...props}/>)}
          />
          <Route
            key={'tests-list'}
            path={"/tests-list/:lsnCode"}
            component={(props => <TextProblemTasks {...props}/>)}
          />


          <Route key={'demo'} path={["/demo/:id", "/demo"]}>
            <MainPage isDemo={true}/>
          </Route>
          <Route
            path="/lesson-session/:teacherCode"
            component={LessonSessionAuth}
          />

          <Route
            path="/success_screen/"
            component={SuccessScreen}
          />

          <Route
            key={'cat_map'}
            path={["/course_map/:lang/:code/:id/", "/course_map/"]}
            component={CatMap}
          />

          <Route
            key={'cat_map_open'}
            path={["/open_cat_map/:lang/:code/:id/", "/open_cat_map/"]}
            component={CatMap}
          />
          <Route
            key={'cat_map_payment'}
            path={["/cat_map_payment/"]}
            component={CatMapPayment}
          />

          <Route
            key={'cat_final_test'}
            path={["/cat_final_test/:lang/:code/:id/", "/cat_final_test/"]}
            component={CatFinalTestMap}
          />

          <Route
            key={'farm_map'}
            path={[
              "/teacher_farm_map/:lang/:code/:id/",
              "/teacher_farm_map/",
              "/farm_map/:lang/:code/:id/",
              "/farm_map/"
            ]}
            component={FarmMap}
          />
          <Route
            key={'farm_map2'}
            path={[
              "/teacher_school_map2/:lang/:code/:id/",
              "/teacher_school_map2/",
              "/school_map2/:lang/:code/:id/",
              "/school_map2/"
            ]}
            component={FarmMap2}
          />
          <Route
            key={'greece_blocks'}
            path={[
              "/teacher_greece_blocks/:lang/:code/:id/",
              "/teacher_greece_blocks/",
              "/greece_blocks/:lang/:code/:id/",
              "/greece_blocks/"
            ]}
            component={GreeceBlocksMap}
          />
          <Route
            key={'farm_4_map'}
            path={[
              "/teacher_farm_4_map/:lang/:code/:id/",
              "/teacher_farm_4_map/",
              "/farm_4_map/:lang/:code/:id/",
              "/farm_4_map/"
            ]}
            component={FarmMap4}
          />
          <Route
            key={'farm_5_map'}
            path={[
              "/teacher_farm_5_map/:lang/:code/:id/",
              "/teacher_farm_5_map/",
              "/farm_5_map/:lang/:code/:id/",
              "/farm_5_map/"
            ]}
            component={FarmMap5}
          />
          <Route
            key={'farm_map_auth'}
            path={["/farm_map_auth/", "/map_auth/"]}
            component={FarmMapAuth}
          />

          <Route
            key={'choose_extra'}
            path={["/choose_extra/"]}
            component={ChooseExtraItemTask}
          />
          <Route
            key={'choose_some_items'}
            path={["/choose_some_items/"]}
            component={ChooseSomeItemsTask}
          />
          <Route
            key={'continue_row'}
            path={["/continue_row/"]}
            component={ContinueRowTask}
          />
          <Route
            key={'arrange_by_columns'}
            path={["/arrange_by_columns/"]}
            component={ArrangeByColumns}
          />
          <Route
            key={'match_items'}
            path={["/match_items/"]}
            component={MatchItems}
          />

        </React.Fragment>]
      break;
  }

  return (
    <div>
      <Router>
        <Layout
          id={`app__main-container`}
          // style={{...styles.mainContainer, overflow: isEshalaWhitelabel ? 'hidden' : 'auto'}}
          className={`app__main-container ${props.isDarkMode ? 'app__main-container--dark-mode' : ''}`}
        >
          <Switch>
            {routes}
          </Switch>
        </Layout>
      </Router>

      <PopupForAnonymous/>
      <PopupCongratulationForTeacher/>
      <PopupFeedback/>

      {!__DEV__ && <YMInitializer accounts={[71741128]} options={{webvisor: true, childIframe:true}} version="2"/>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  lang: state.lessonsReducer.lang,
  lessons: state.lessonsReducer.lessons,
  lessonsGroups: state.lessonsReducer.lessonsGroups,
  lessonsAccess: state.lessonsReducer.lessonsAccess,
  lessonSession: state.lessonsReducer.lessonSession,
  user: state.lessonsReducer.user,
  activeLesson: state.lessonsReducer.activeLesson,
  paymentModalVisible: state.commonReducer.paymentModalVisible,
  isDarkMode: state.commonReducer.isDarkMode,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(actions.fetchCurrentUser()),
  setLanguage: (lang) => dispatch(actions.setLanguage(lang)),
  sendLessonActivityLog: () => dispatch(actions.sendLessonActivityLog()),
  fetchHeaderBanner: () => dispatch(actions.fetchHeaderBanner()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

const styles = {
  mainContainer: {height: '100vh'},
};
