import React from "react";
import { Group, Line } from "react-konva";
import Victor from 'victor';

class Track extends React.Component {
  constructor(props) {
    super(props);
    this.startPoint = Victor(0,0);

    this.trackPoints = [];
    this.trackVectors = [];
    this.generateTrackPoints();
  }

  generateTrackPoints () {
    const {config} = this.props;
    for (let part of config) {
      let v = Victor(part.length, 0).rotateDeg(-part.slope);
      this.trackVectors.push(v);
    }
    this.trackPoints.push(this.startPoint.x);
    this.trackPoints.push(this.startPoint.y);
    for (let vector of this.trackVectors) {
      this.startPoint.add(vector);
      this.trackPoints.push(this.startPoint.x);
      this.trackPoints.push(this.startPoint.y);
    }
  }

  findCorrespondingVictor(x) {
    let sum = this.props.x ?? 0;

    for (let victor of this.trackVectors) {
      sum += victor.x;
      if (this.props.x <= x && x <= sum ) return victor;
    }
    return undefined;
  }

  getSlope(x) {
    const found = this.findCorrespondingVictor(x);
    return found ? found.angleDeg() : undefined;
  };

  getPerp(x) {
    const found = this.findCorrespondingVictor(x);
    return found ? Victor(found.y, -found.x).norm() : undefined;
  };

  render() {
    const {x, y, config} = this.props;
    return (
      <Group x={x} y={y}>
        <Line
          points={this.trackPoints}
          stroke={'brown'}
          strokeWidth={3}
        />
      </Group>
    )

  }
}

export default Track
