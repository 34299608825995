import React from "react";
import cloneDeep from "lodash.clonedeep";
import useImage from "use-image";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {GIF} from "../../../canvas/components/GIF";
import {Layer, Group, Image, Rect, Text, Circle, Line} from "react-konva";
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {spaceshipLandingElementsUI, spaceshipLandingScenario} from "../utils/spaceshipLandingScenario";
import theoryBackgroundImg from '../../../../images/space/theory/background.png';

import spaceshipImg from '../../../../images/space/spaceship_landing/spaceship.png';
import engineFireImg from '../../../../images/space/spaceship_landing/engine-fire.png';

import btnLeftImg from '../../../../images/space/spaceship_landing/btn-left.png';
import btnRightImg from '../../../../images/space/spaceship_landing/btn-right.png';
import btnTopImg from '../../../../images/space/spaceship_landing/btn-top.png';

import windGif from '../../../../images/space/spaceship_landing/wind.gif';
import spaceshipBreakGif from '../../../../images/space/spaceship_landing/spacebreak.gif';

import landingPlaceImg from '../../../../images/space/spaceship_landing/landing-place.png';
import livingModuleImg from '../../../../images/space/spaceship_landing/living-module.png';
import stantion1Img from '../../../../images/space/spaceship_landing/stantion-1.png';
import stantion2Img from '../../../../images/space/spaceship_landing/stantion-2.png';

// Theory
import dialogArrowImg from '../../../../images/space/theory/dialogArrow.png';
import theoryQ1Img from '../../../../images/space/theory/theoryQ1.png';
import theoryQ2_1Mp4 from '../../../../images/space/theory/theoryQ2_1.mp4';
import theoryQ2_2Mp4 from '../../../../images/space/theory/theoryQ2_2.mp4';
import theoryQ3Mp4 from '../../../../images/space/theory/theoryQ3.mp4';
// -------

import {getTimeDeltaSec} from "../../../../utils/common";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CyberpunkCard from "../../../canvas/components/CyberpunkCard";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {layout2} from "../../../../utils/styles";
import Card from "../../../canvas/components/Card";
import dragGIF from "../../../../images/gearRatios/personDrag.gif";
import Video from "../../../canvas/components/Video";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import TheoryCanvas from "../components/TheoryCanvas";
import {defaultImgOrVideoStyle} from "../utils/theoryGenerator";
import VideoBlock from "../../../canvas/components/CanvasVideoBlock";
import * as actions from "../../../../store/actions";



class SpaceshipLanding extends React.Component {
  constructor(props) {
    super(props);
    this.managedComponents = [
      'stage',
      'title',

      'shipContainer',
      'shipEngine',

      'centerBtn',
      'centerBtnText',

      'cyberpunkCard',
      'cyberpunkCardBtn',

      'alertText',
    ];

    this.elementsUI = spaceshipLandingElementsUI;
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timeDeltaSec: undefined,
      shipPos: {x: 0, y: 0},
      shipSpeed: {x: 0, y: 0},

      inputSinkRateVal: undefined,

      ...this.elementsUI,
    }
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(spaceshipLandingScenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  handleKeyAction = (event) => {
    const value = event.type === 'keydown';
    switch (event.code) {
      case 'ArrowLeft':
        this.data.moveLeft = value;
        break;
      case 'ArrowRight':
        this.data.moveRight = value;
        break;
      case 'ArrowUp':
        this.data.moveUp = value;
        this.data.moveUp = value;
        break;
      case 'ArrowDown':
        this.data.moveDown = value;
        break;
    }
    this.forceUpdate();
  };

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
    document.addEventListener("keydown", (e) => this.handleKeyAction(e, 'down'));
    document.addEventListener("keyup", (e) => this.handleKeyAction(e, 'up'));
    this.failureSecondTime = false;
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.selectStepByKey('initial animation')
  };

  manualControlPhysics = () => {
    const data = this.data;
     data.shipEngineVisible = false;
      if (data.shipSpeed.x > - 20) {
        // ветер слегка разгоняет корабль
        data.shipSpeed.x -= 5 * data.timeDeltaSec;
      }

      data.shipSpeed.y += 9.8 * data.timeDeltaSec * 2;
      if (data.moveUp) {
        data.shipEngineVisible = true;
        data.shipSpeed.x += 30 * data.timeDeltaSec * 2 * Math.sin(data.shipRotation * Math.PI / 180);
        data.shipSpeed.y -= 30 * data.timeDeltaSec * 2 * Math.cos(data.shipRotation * Math.PI / 180);
      }
      if (data.moveRight) {
        data.shipRotation += 10 * data.timeDeltaSec;
      }
      if (data.moveLeft) {
        data.shipRotation -= 10 * data.timeDeltaSec;
      }

      data.shipPos.x += data.shipSpeed.x * data.timeDeltaSec;
      data.shipPos.y += data.shipSpeed.y * data.timeDeltaSec;
  };

  autopilotPhysics = () => {
    const data = this.data;
    if (!data.autopilotMotionStarted || !data.inputSinkRateVal) {
      return;
    }
    // console.log('debug on motion started', data.autopilotMotionStarted, data.timeDeltaSec, data.shipSpeed.x);
    data.shipSpeed = {x: -20, y: data.inputSinkRateVal * 2};
    data.shipPos.x += data.shipSpeed.x * data.timeDeltaSec;
    data.shipPos.y += data.shipSpeed.y * data.timeDeltaSec;
    // по x - 350
    //  175

  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario) / 1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timeDeltaSec = getTimeDeltaSec(timedelta);
    if (this.scenarioManager.getStepData().key === "ship landing") {
      this.manualControlPhysics();
      if (data.shipPos.y > 550) {
        const posCorrect = data.shipPos.x > 460 && data.shipPos.x < 485;
        const angleCorrect = Math.abs(data.shipRotation) < 15;
        if (posCorrect && angleCorrect) {
          this.scenarioManager.success("ship landing");
        }

        if (posCorrect && !angleCorrect) {
          this.scenarioManager.selectStepByKey("ship landing angle failure");
        }
        if (!posCorrect) {
          this.scenarioManager.selectStepByKey("ship landing failure");
        }
      }
    }
    if (["autopilot 2", "autopilot 2 second attempt", 'autopilot start motion', 'autopilot start motion 2'].includes(this.scenarioManager.getStepData().key)) {
      this.autopilotPhysics();
      if (data.shipPos.y > 550) {
        if (data.shipPos.x > 465 && data.shipPos.x < 483 && Math.abs(data.shipRotation) < 15) {
          this.scenarioManager.success();
        } else {
          this.scenarioManager.failure();
        }
      }
    }
    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['title'].text(data.title);
    n['centerBtnText'].text(data.centerBtnText);
    n['alertText'].text(data.alertIndicatorText);

    n['centerBtn'].visible(data.centerBtnVisible);
    n['shipContainer'].visible(data.shipVisible);
    n['shipEngine'].visible(data.shipEngineVisible);
    n['cyberpunkCardBtn'].visible(data.cyberpunkCardBtnVisible);
    n['cyberpunkCard'].visible(data.cyberpunkCardVisible);

    n['shipContainer'].x(data.shipPos.x);
    n['shipContainer'].y(data.shipPos.y);
    n['shipContainer'].rotation(data.shipRotation);

    n['stage'].draw();
  }

  Base = (props) => {
    const [landingPlace] = useImage(landingPlaceImg);
    const [livingModule] = useImage(livingModuleImg);
    const [stantion1] = useImage(stantion1Img);
    const [stantion2] = useImage(stantion2Img);

    return (
      <Group {...props}>
        <Image x={110} y={35} width={150} height={15} image={landingPlace}/>
        <Image x={55} y={22} width={43} height={27} image={livingModule}/>
        <Image x={-17} width={30} height={50} image={stantion1}/>
        <Image x={17} width={30} height={50} image={stantion2}/>
      </Group>
    )
  }

  Controller = (props) => {
    const data = this.data;
    const [btnLeft] = useImage(btnLeftImg);
    const [btnRight] = useImage(btnRightImg);
    const [btnTop] = useImage(btnTopImg);
    const size = props.size || 50;
    return (
      <Group {...props} visible={data.controllerVisible}>
        <CanvasButton
          x={0} y={0}
          opacity={data.moveUp ? 0.8 : undefined}
          onMouseDown={() => this.handleKeyAction({code: 'ArrowUp', type: 'keydown'})}
          onMouseUp={() => this.handleKeyAction({code: 'ArrowUp', type: 'keyup'})}
          onMouseLeave={() => this.handleKeyAction({code: 'ArrowUp', type: 'keyup'})}
        >
          <Image width={size} height={size} image={btnTop}/>
        </CanvasButton>
        <CanvasButton
          x={-size} y={size}
          opacity={data.moveLeft ? 0.8 : undefined}
          onMouseDown={() => this.handleKeyAction({code: 'ArrowLeft', type: 'keydown'})}
          onMouseUp={() => this.handleKeyAction({code: 'ArrowLeft', type: 'keyup'})}
          onMouseLeave={() => this.handleKeyAction({code: 'ArrowLeft', type: 'keyup'})}
        >
          <Image width={size} height={size} image={btnLeft}/>
        </CanvasButton>
        <CanvasButton
          x={size} y={size}
          opacity={data.moveRight ? 0.8 : undefined}
          onMouseDown={() => this.handleKeyAction({code: 'ArrowRight', type: 'keydown'})}
          onMouseUp={() => this.handleKeyAction({code: 'ArrowRight', type: 'keyup'})}
          onMouseLeave={() => this.handleKeyAction({code: 'ArrowRight', type: 'keyup'})}
        >
          <Image width={size} height={size} image={btnRight}/>
        </CanvasButton>
      </Group>
    )
  }

  Environment = () => {
    const data = this.data;
    const textStg = {
      text: '',
      fill: 'white',
      x: 60,
      y: 40,
      fontSize: 30,
      lineHeight: 1.2,
    }
    const labelStyle = {
      fill: 'black',
      fontSize: 20
    }
    const valueStyle = {
      fontSize: 30,
      fill: 'black',
      y: 28
    }
    return (
      <Group>
        <Text {...textStg} ref={this._ref('title')}/>
        <Text {...textStg} text={data.secondTitle} x={60} y={115} fontStyle={'bold'}/>
        <Rect x={0} y={550} height={70} width={3000} fill={'#E4663F'}/>

        <Card width={150} height={270} x={60} y={200} visible={data.infoCardVisible}>
          <Group x={20} y={30}>
            <Group>
              <Text text={'h, м'} {...labelStyle}/>
              <Text text={'500'} {...valueStyle}/>
            </Group>

            <Group y={80}>
              <Text text={'a, м'} {...labelStyle}/>
              <Text text={'1000'} {...valueStyle}/>
            </Group>

            <Group y={160}>
              <Text text={'V     '} {...labelStyle} fontStyle={'italic'}/>
              <Text x={33} text={', км/ч'} {...labelStyle}/>
              <Text x={15} y={10} text={'гор'} {...labelStyle} fontSize={12}/>
              <Text text={'10'} {...valueStyle}/>
            </Group>
          </Group>
        </Card>


        <CyberpunkCard
          {...data.cyberpunkCardPos}
          cardRef={this._ref('cyberpunkCard')}
          btnRef={this._ref('cyberpunkCardBtn')}
          btnText={'Установить скорость'}
          visible={data.cyberpunkCardVisible}
          onClickBtn={() => data.cyberpunkBtnClick(this)}
        >
          <Group x={340} y={78} visible={data.cyberpunkCardLineVisible}>
            <Line points={[0,0, 80, 0]} stroke={'white'}/>
            <Circle x={80} radius={3} fill={'white'}/>
          </Group>

          <Group x={65} y={50}>
            <Text
              fontStyle={'bold'} fontSize={18}
              fill={'white'}
              text={'Скорость снижения, км/ч'}
            />
            {
              data.cyberpunkCardInputDisabled ? (
                <Group x={0} y={30}>
                  <Rect width={150} height={50} stroke={'rgba(0,0,0,0.1)'} fill={'white'}/>
                  <Text
                    text={this.data.inputSinkRateVal}
                    width={150} height={50}
                    fontSize={35}
                    fill={'black'} fontStyle={'bold'}
                    verticalAlign={'middle'}
                    align={'center'}
                  />
                </Group>
              ) : (
                <CanvasInput
                  withoutBorder={true}
                  id={'1'}
                  x={0} y={30}
                  width={150} height={50}
                  stage={this._getNode('stage')}
                  textColor={'black'}
                  onInput={(val) => {
                    this.data.inputSinkRateVal = val;
                  }}
                  value={this.data.inputSinkRateVal}
                />
              )
            }
          </Group>
        </CyberpunkCard>


        <CanvasButton
          {...data.centerBtnPos}
          visible={data.centerBtnVisible}
          width={250}
          btnRef={this._ref('centerBtn')}
          textRef={this._ref('centerBtnText')}
          text={''}
          textFill={'black'}
          fontStyle={'bold'}
          btnFill={'white'}
          onClick={() => data.centerBtnClick(this)}
        />


        <Group x={820} y={540} visible={data.shipPathLines}>
          <Circle x={-350} radius={5} fill={'white'}/>
          <Line points={[0,0,-350,0]} stroke={'white'} dash={[6,3]}/>
          <Text x={-180} y={-30} text={'a'} fontSize={25} fontStyle={'bold'} fill={'rgba(0,0,0,0.7)'}/>

          <Circle radius={5} fill={'white'}/>
          <Line points={[0,0,0,-150]} stroke={'white'} dash={[6,3]}/>
          <Text x={20} y={-80} text={'h'} fontSize={25} fontStyle={'bold'} fill={'rgba(0,0,0,0.7)'}/>
        </Group>


        <Group x={50} y={520} visible={data.alertIndicatorVisible}>
          <Circle radius={12} fill={'#DC493E'}/>
          <Text x={20} y={-7} ref={this._ref('alertText')} text={''} fontSize={20} fill={'#DC493E'}/>
        </Group>
      </Group>
    )
  };

  Ship = () => {
    const data = this.data;
    const [spaceship] = useImage(spaceshipImg);
    const [engineFire] = useImage(engineFireImg);

    return (
      <Group ref={this._ref('shipContainer')}>
        <Image ref={this._ref('shipEngine')} x={-6} y={-2} width={12} height={30} image={engineFire}/>
        <Image width={40} height={70} offsetX={20} offsetY={70} image={spaceship}/>
        <GIF visible={data.shipBreak} src={spaceshipBreakGif} x={-60} y={-80} scale={.3} loop={true}/>
      </Group>
    )
  };

  AnimationBlock = (props) => {
    const data = this.data;
    return (
      <Group {...props}>
        {
          data.animationStg.src ? (
            <VideoBlock
              src={data.animationStg.src}
              onVideoEnd={() => { this.scenarioManager.next() }}
              scenarioManager={this.scenarioManager}
            />
          ) : (
            <Card width={400} height={60} x={330} y={200} visible={data.animationBlock}>
              <Text x={30} y={20} text={'Анимация'} fill={'black'} fontSize={25}/>
            </Card>
          )
        }
      </Group>
    )
  }


  render() {
    const data = this.data;

    const questionImages = {
      TheoryQ1: (props) => {
        const [theoryQ1] = useImage(theoryQ1Img);
        return <Image {...defaultImgOrVideoStyle} image={theoryQ1}/>
      },
      TheoryQ2: (props) => <Video {...defaultImgOrVideoStyle} src={theoryQ2_1Mp4} y={140}/>,
      TheoryQ2_1: (props) => <Video {...defaultImgOrVideoStyle} src={theoryQ2_2Mp4} y={140}/>,
      TheoryQ3: (props) => <Video {...defaultImgOrVideoStyle} src={theoryQ3Mp4} y={140}/>,
    }
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer>
            <GIF src={windGif} opacity={0.3} loop={true}/>
            <this.Ship/>
            <this.Environment/>
            <this.Base x={380} y={527} scale={{x: .5, y: .5}}/>
            <this.Controller x={790} y={350} size={60}/>


            {/* -------- */}
            <TheoryCanvas
              data={this.data}
              containerComp={this}
              visible={data.theoryVisible}
              questionImages={questionImages}
            />

            <CanvasButton
              text={'Обнулить скорость'}
              onClick={() => this.data.resetSpeed(this)}
                // this.scenarioManager.selectStepByKey('autopilot 2')}
              fontSize={15}
              strokeWidth={.1}
              btnCornerRadius={0}
              btnFill={layout2.orange}
              visible={this.data.resetSpeedBtnVisible}
              width={160}
              height={30}
              x={680}
              y={510}
            />

            <CanvasResetBtn
              y={510}
              onClick={() => this.onClickReset()}
            />


            {/* -------- */}
            <this.AnimationBlock visible={data.animationBlock}/>

          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpaceshipLanding);

const styles = {
  mainContainer: {
    background: '#E9AB7C'
  }
};
