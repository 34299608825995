import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {CloseOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import {useQuery} from "../../../../utils/custom-hooks/useQuery";
import {serverMediaUrl} from "../../../../utils/api";
import lvlComplete from "../../../.././images/lvlComplete.png";
import socialArrow from "../../../.././images/socialArrow.png";
import '../VkSharePopup/vkShare.css';


const VkSharePopup = (props) => {
  const {
    lesson
  } = props;


  const vkShare = useQuery().get('vk_share');
  const vkShareTitle = useQuery().get('title');
  const {pathname, origin} = window.location;
  useEffect(() => {
    const vk_share_button = document.getElementById('vk_share_button');
    if (vkShare && vk_share_button) {

      let imageUrl = lesson?.thumbnail
        ? `${serverMediaUrl}${lesson?.thumbnail}`
        :  lesson?.thumbnail_small
          ? `${serverMediaUrl}${lesson?.thumbnail_small}`
          : null;

      const correctTitle = (
        (vkShareTitle && vkShareTitle.replace(/\"/g, '')) ||
        lesson?.title ||
        'А ты сможешь пройти?'
      );

      vk_share_button.innerHTML =
        window.VK.Share.button({
          url: `${origin}${pathname}?vk_share=1${vkShareTitle ? `&title=${vkShareTitle}` : ''}`,
          title: correctTitle,
          image: imageUrl,
        }, {
          type: 'button',
          text: 'Поделиться'
        });
    }
  }, [props.VKShareModalVisible]);
  return (
    <div style={{...styles.windowContainer, ...(!(props.VKShareModalVisible && vkShare) ? styles.hidden : styles.visible)}}>
      <div
        onClick={() => props.changeVKShareVisible(false)}
        style={{position: 'absolute', width: '100%', height: '100%', zIndex: 12, background: 'rgba(0,0,0,0.4)', cursor: 'pointer'}}
      />
      <div style={{...styles.vkShareContainer}}>
        <CloseOutlined
          style={{position: 'absolute', right: '15px', top: '15px', zIndex:1, fontSize: '18px'}}
          onClick={() => props.changeVKShareVisible(false)}
        />
        <div style={styles.vkShareBody}>
          <div>
            <img src={lvlComplete} width={'100%'} height={'100%'} style={{position: 'absolute', left: 0, top: 0}}/>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: '0', right: '0',
            bottom: '45px',
          }}>
            <img src={socialArrow} className={'vkShare__arrow vkShare__arrow--animation'} width={'50px'} height={'20px'}/>
            <div id={'vk_share_button'} className={'vk_share_button'} />
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state, {match}) => ({
  VKShareModalVisible: state.commonReducer.VKShareModalVisible,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeVKShareVisible: (visible) => dispatch(actions.changeVKShareVisible(visible))
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VkSharePopup);

let styles = {
  hidden: {
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 1s, opacity 0.5s linear',
    zIndex: '1000',
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: '0',
    top: '0',
  },
  visible: {
    visibility: 'visible',
    transition: 'visibility 1s, opacity 0.5s linear',
    opacity: '1',
    zIndex: '1000',
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: '0',
    top: '0',
  },
  windowContainer: {
    width: '100%',
    height: '100%',
  },
  vkShareContainer: {
    position: 'absolute',
    zIndex: 100,
    top: 0, bottom: 0,
    right: 0, left: 0,
    margin: 'auto',
    padding: '25px',
    width: 400,
    height: 350,
    // background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px'
  },
  vkShareBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  }
}
