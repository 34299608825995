import i18next from "i18next";

export const getCorrectLang = () => {
  const correctLang = localStorage.getItem('lang');
  return correctLang && correctLang !== 'undefined' ? correctLang : 'en';
};

export const isEng = () => getCorrectLang() === 'en';

export const _t = (msg, options) => i18next.t(msg, options);

export const setDefaultLang = () => {
  const correctLang = getCorrectLang();
  i18next.init({
    lng: correctLang,
    resources: require(`./${correctLang}.json`)
  });
};
