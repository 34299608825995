

export const updateFavicon = () => {
  const favKeys = [
    'apple-touch-icon.png',
    'favicon-32x32.png',
    'favicon-16x16.png',
    'safari-pinned-tab.svg',
    'site.webmanifest',
  ]

  const isEdEra = window.location.host.toLowerCase().includes('edera');

  let dirName = 'inventicFavIcons';
  if (isEdEra) {
    dirName = 'ederaFavIcons';
  }

  favKeys.forEach((k) => {
    const favEl = document.getElementById(k);
    if (favEl) {
      favEl.href = `${dirName}/${k}`;
    }
  })
}