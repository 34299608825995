import React from "react";
import Shield from "./Shield";
import '../styles/levelAchievement.css';


const LevelAchievement = ({levelData}) => {
  return (
    <div className={'level-achievement'}>
      <img src={levelData.img} className={'level-achievement__img'}/>
      <div className={'level-achievement__info'}>
        <p style={{backgroundColor: levelData.color}}>Уровень</p>
        <Shield level={levelData.level} color={levelData.color}/>
      </div>
    </div>
  )
}

export default LevelAchievement;
