import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ChooseExtraItemTask from "./ChooseExtraItemTask";
import bgNature from '../images/bgNature.png';
import {_t} from "../../../../utils/lang/common";

import img1 from "../images/сhooseExtraItemTask/LogicWheel1_1_1.png";
import img2 from "../images/сhooseExtraItemTask/LogicWheel1_1_2.png";
import img3 from "../images/сhooseExtraItemTask/LogicWheel1_1_3.png";
import img4 from "../images/сhooseExtraItemTask/LogicWheel1_1_4.png";

import imgTask21 from "../images/сhooseExtraItemTask/LogicWheel1_2_1.png";
import imgTask22 from "../images/сhooseExtraItemTask/LogicWheel1_2_2.png";
import imgTask23 from "../images/сhooseExtraItemTask/LogicWheel1_2_3.png";
import imgTask24 from "../images/сhooseExtraItemTask/LogicWheel1_2_4.png";

import imgTask31 from "../images/сhooseExtraItemTask/LogicWheel1_3_1.png";
import imgTask32 from "../images/сhooseExtraItemTask/LogicWheel1_3_2.png";
import imgTask33 from "../images/сhooseExtraItemTask/LogicWheel1_3_3.png";
import imgTask34 from "../images/сhooseExtraItemTask/LogicWheel1_3_4.png";

import imgTask41 from "../images/сhooseExtraItemTask/LogicWheel1_4_1.png";
import imgTask42 from "../images/сhooseExtraItemTask/LogicWheel1_4_2.png";
import imgTask43 from "../images/сhooseExtraItemTask/LogicWheel1_4_3.png";
import imgTask44 from "../images/сhooseExtraItemTask/LogicWheel1_4_4.png";

import imgTask51 from "../images/сhooseExtraItemTask/LogicWheel1_5_1.png";
import imgTask52 from "../images/сhooseExtraItemTask/LogicWheel1_5_2.png";
import imgTask53 from "../images/сhooseExtraItemTask/LogicWheel1_5_3.png";
import imgTask54 from "../images/сhooseExtraItemTask/LogicWheel1_5_4.png";


const LogicWheel1 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: _t("logic_wheel.choose_extra"),
      items: [
        {id: 1, image: img1, success: true},
        {id: 2, image: img2},
        {id: 3, image: img3},
        {id: 4, image: img4},
      ]
    },
    {
      title: _t("logic_wheel.choose_extra"),
      items: [
        {id: 1, image: imgTask21},
        {id: 2, image: imgTask22, success: true},
        {id: 3, image: imgTask23},
        {id: 4, image: imgTask24},
      ]
    },
    {
      title: _t("logic_wheel.choose_extra"),
      items: [
        {id: 1, image: imgTask31},
        {id: 2, image: imgTask32},
        {id: 3, image: imgTask33, success: true},
        {id: 4, image: imgTask34},
      ]
    },
    {
      title: _t("logic_wheel.choose_extra"),
      items: [
        {id: 1, image: imgTask41},
        {id: 2, image: imgTask42},
        {id: 3, image: imgTask43, success: true},
        {id: 4, image: imgTask44},
      ]
    },
    {
      title: _t("logic_wheel.choose_not_round"),
      items: [
        {id: 1, image: imgTask51},
        {id: 2, image: imgTask52},
        {id: 3, image: imgTask53, success: true},
        {id: 4, image: imgTask54},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >

      {
        chooseTasks.map((item, i) => (
          <React.Fragment key={`step${i}`}>
            {
              ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                <ChooseExtraItemTask
                  lang={'en'}
                  bgImg={bgNature}
                  title={item.title}
                  items={item.items}
                  taskComplete={() => {
                    setCompletedTaskCount(prevState => prevState + 1);
                    if ((i + 1) === chooseTasks.length) {
                      setAllComplete(true);
                    }
                  }}
                />
              ) : null
            }
          </React.Fragment>
        ))
      }
    </TaskMainContainer>
  )
}
export default LogicWheel1;
