import React from "react";
import {MenuChapterTitle, MenuGradeTitle} from "./MenuChapterTitle";
import {LessonParagraphItem} from "./LessonParagraphItem";


const LayoutSideMenu = (props) => {
  const {
    grades,
    selectedGradeNumber,
    setCatalogGradeNumber,

    selectedChapter,
    setCatalogChapter,

    lessonsParagraphs,
    selectedParagraph,
    setCatalogParagraph,

    showAllLessons,
    setShowAllLessons,
  } = props;


  return (
    <>
      <div
        className={`side-bar__menu-item ${showAllLessons ? 'side-bar__show-all--selected' : ''}`}
        style={{marginBottom: '10px'}}
        onClick={() => {
          setShowAllLessons(true);
          props.resetCatalogSelectedItems();
        }}>
        <h3 className={`side-bar__show-all-title`}>Все задачи</h3>
      </div>
      {
        Object.keys(grades).map(gradeNum => {
          const chaptersOfGrade = grades[gradeNum];
          return (
            <React.Fragment key={`lessonGrade-${gradeNum}`}>
              <MenuGradeTitle
                gradeNumber={gradeNum}
                selectedChapter={selectedChapter}
                selectedGradeNumber={selectedGradeNumber}
                setCatalogGradeNumber={setCatalogGradeNumber}
              />
              {
                chaptersOfGrade.map(ch => {
                  return (
                    <React.Fragment key={`lessonCh-${ch.id}`}>
                      <div style={{display: Number(selectedGradeNumber) === ch.grade ? "block" : "none"}}>
                        <MenuChapterTitle chapter={ch} selectedChapter={selectedChapter} setSelectedChapter={setCatalogChapter} />
                        {lessonsParagraphs.filter(paragraph => paragraph.show_on_platform && paragraph.chapter === ch.id && paragraph.chapter === selectedChapter?.id).map((item, i) => (
                          <React.Fragment key={`lessonGroupItem-${i}`}>
                            <LessonParagraphItem paragraphItem={item} selectedParagraph={selectedParagraph} setSelectedParagraph={setCatalogParagraph}/>
                          </React.Fragment>))
                        }
                      </div>
                    </React.Fragment>
                  )
                })
              }
            </React.Fragment>
          )
        })
      }
    </>
  )
}

export default LayoutSideMenu;
