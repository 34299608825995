import React, {useEffect, useState} from "react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {serverMediaUrl} from "../../../utils/api";
import {Button, Col, Modal, Row, Select, Space} from "antd";
import PresentationForm from "../components/PresentationForm";
import {MinusOutlined} from "@ant-design/icons";


const PresentationFormModal = (props) => {
  const {
    presentation,
    presentationFormModalVisible,
    changePresentationFormModalVisible
  } = props;
  const defaultPresentationId = presentation.allPresentations[0] ? presentation.allPresentations[0].id : null;

  const [isAddNew, setIsAddNew] = useState(false);
  const [presentationSelectedId, setPresentationSelectedId] = useState(null);

  useEffect(() => {
    setPresentationSelectedId(defaultPresentationId);
    if (!presentation.allPresentations.length && !isAddNew) {
      setIsAddNew(true);
    }
  }, [defaultPresentationId, presentation.allPresentations]);

  const onCancel = () => changePresentationFormModalVisible(false);
  const onChangePresentation = (val) => setPresentationSelectedId(val);
  const onClickAddNewPresentation = () => setIsAddNew(true);
  const editSelectedPresentation = () => {
    setIsAddNew(false);
  };


  const allImgs = presentation.imgs;
  const correctImgs = allImgs.filter(item => item.presentationId === presentationSelectedId);

  return (
    <Modal
      style={{ top: 100, borderRadius: 5, overflow:'hidden', padding: 0 }}
      bodyStyle={{height: '70vh', overflow: 'auto'}}
      // title={'Создание / Редактирование перзентации'}
      title={'Создание перзентации'}
      visible={presentationFormModalVisible}
      onCancel={onCancel}
      width={1000}
      footer={<></>}
    >
      <Space size={10}>
        <Select
          style={{minWidth: 100, maxWidth: 200}}
          size={'large'}
          value={presentationSelectedId}
          onChange={(val) => onChangePresentation(val)}
          notFoundContent={<> - </>}
        >
          {
            presentation.allPresentations.length ? (
              presentation.allPresentations.map((presentation) => (
                <Select.Option key={presentation.id} value={presentation.id}>{presentation.title}</Select.Option>
              ))
            ) : (
              <Select.Option key={'empty-1'} value={0}><MinusOutlined /></Select.Option>
            )
          }
        </Select>
        {/*<Button*/}
        {/*  size={'large'}*/}
        {/*  onClick={editSelectedPresentation}*/}
        {/*>Редактировать выбранную</Button>*/}
        <Button
          size={'large'}
          type={'primary'}
          onClick={onClickAddNewPresentation}
        >Добавить новую +</Button>
      </Space>
      <div style={{marginTop: 20}}>
        {
          isAddNew ? (
            <Row gutter={[10, 15]}>
              <Col md={24}>
                <h3 align={'center'} style={{marginTop: '15px'}}>Создать презентацию</h3>
                <PresentationForm
                  setIsAddNew={setIsAddNew}
                  setPresentationSelectedId={setPresentationSelectedId}
                />
              </Col>
            </Row>
          ) : (
            <Row gutter={[10, 15]}>
              {
                correctImgs.map(item => (
                    <Col md={4} key={`${presentationSelectedId}-${item.slideId}`}>
                      <img src={`${serverMediaUrl}${item.img}`} style={styles.img}/>
                    </Col>
                  )
                )
              }
            </Row>
          )
        }
      </div>
    </Modal>
  )
};


const mapStateToProps = (state) => ({
  user: state.lessonsReducer.user,
  presentation: state.lessonsReducer.presentation,
  presentationFormModalVisible: state.commonReducer.presentationFormModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changePresentationFormModalVisible: (visible) => dispatch(actions.changePresentationFormModalVisible(visible)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PresentationFormModal);

const styles = {
  img: {
    width: '100%',
    height: '110px',
    objectFit: 'contain',
    borderRadius: '5px',
    boxShadow: '0 10px 6px -6px rgba(119, 119, 119, .4)'
  }
};
