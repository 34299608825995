import useImage from "use-image";
import btnLeftImage from "../../../../images/space/spaceship_landing/btn-left.png";
import btnRightImage from "../../../../images/space/spaceship_landing/btn-right.png";
import btnTopImage from "../../../../images/space/spaceship_landing/btn-top.png";
import {Group, Image, Rect, Text} from "react-konva";
import React, {useEffect, useState} from "react";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CurvedArrow from "../../../canvas/components/CurvedArrow";


const Controller = (props) => {
  const [btnLeft, setBtnLeft] = useState(false);
  const [btnRight, setBtnRight] = useState(false);
  const [btnTop, setBtnTop] = useState(false);
  const [btnBottom, setBtnBottom] = useState(false);

  const [btnLeftImg] = useImage(btnLeftImage);
  const [btnRightImg] = useImage(btnRightImage);
  const [btnTopImg] = useImage(btnTopImage);


  const handleKey = (event) => {
    const isKeydown = event.type === 'keydown';
    switch (event.keyCode) {
      case 37: setBtnLeft(isKeydown); break;
      case 39: setBtnRight(isKeydown); break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKey);
    document.addEventListener("keyup", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
      document.removeEventListener("keyup", handleKey);
    }
  }, []);





  const size = 70;
  return (
    <Group x={props.x} y={props.y} visible={props.visible}>

      <CanvasButton
        opacity={btnLeft  ? .7 : 1}
        x={0} y={size}
        onMouseDown={() => props.handleKeyAction('moveLeft', true)}
        onMouseUp={() => props.handleKeyAction('moveLeft', false)}
        onMouseLeave={() => props.handleKeyAction('moveLeft', false)}
      >
        <Rect width={size} height={size} fill={'green'} cornerRadius={10} visible={btnLeft}/>
        <Image width={size} height={size} image={btnLeftImg}/>
      </CanvasButton>

      <Group x={size+20} y={70}>
        <Group x={80} y={-100} visible={props.hintVisible}>
          <CurvedArrow x={-10} y={20} scale={{x:.8, y:.8}}/>
          <Text x={70} text={'Нажми, чтобы \nнаполнить \nцистерну:'} fill={'black'} fontSize={18} lineHeight={1.2}/>
        </Group>

        {
          ['left', 'right'].map((k, i) => {
            return (
              <Group
                key={'box-'+i}
                x={(size*1.7+20)*i}
                onClick={() => {props.handlerCLickBox(k)}}
                onTap={() => {props.handlerCLickBox(k)}}
              >
                <Rect width={size*1.7} height={size} stroke={'black'} fill={'gray'} cornerRadius={7} strokeWidth={3}/>
                <Rect ref={props[k+'BoxRef']} x={(size-1)*1.7} y={size} width={(size-2)*1.7} height={size} fill={'#0083D2'} cornerRadius={7} rotation={180}/>
              </Group>
            )
          })
        }
      </Group>

      <CanvasButton
        opacity={btnRight ? .7 : 1}
        x={370} y={size}
        onMouseDown={() => props.handleKeyAction('moveRight', true)}
        onMouseUp={() => props.handleKeyAction('moveRight', false)}
        onMouseLeave={() => props.handleKeyAction('moveRight', false)}
      >
        <Rect width={size} height={size} fill={'green'} cornerRadius={10} visible={btnRight}/>
        <Image width={size} height={size} image={btnRightImg}/>
      </CanvasButton>
    </Group>
  )
}

export default Controller;