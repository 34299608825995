import React, {useEffect, useRef} from 'react';
import {Group, Image, Rect} from 'react-konva';
import {GIF} from '../../../canvas/components/GIF';
import useImage from 'use-image';
import useForceUpdate from '../../../../utils/custom-hooks/useForceUpdate';

import {height as canvasHeight} from '../../../canvas/containers/CanvasContainer';
import Rectangle from './figures/Rectangle';
import AirBalloonPhysics from './AirBalloonPhysics';
import BallastPhysics from './BallastPhysics';

import balloonImage from '../img/balloon.png';
import ballastImage from '../img/ballast.png';
import fireGif from '../../../../images/fireSmall.gif';

class AirBalloon {
  constructor({areaHeight = canvasHeight} = {}) {
    this.areaHeight = areaHeight;
  }

  physics = new AirBalloonPhysics();
  figure = new Rectangle({
    x: 300,
    y: this.physics.height,
    width: AirBalloon.balloonSizes.basketWidth,
    height: AirBalloon.balloonSizes.basketHeight,
  });

  outerDensity = {
    min: this.physics.calcOuterDensity(this.physics.maxHeight),
    max: this.physics.calcOuterDensity(0),
  };
  temperature = {
    min: 0,
    max: 2000,
  };
  innerDensity = {
    max: this.outerDensity.max - this.outerDensity.min,
    min: 0,
  };
  fuel = {
    min: 0,
    current: 200,
    max: 200,
    speed: -0.1,
  };

  move = ({timeDelta, extraTemperatureVelocity}) => {
    const {balloonSizes: {ballastHeight}} = AirBalloon;
    let updated = false;

    this.physics.addTime({timeDelta, extraTemperatureVelocity: this.fuel.current <= 0 ? 0 : extraTemperatureVelocity});
    this.figure.y = this.physics.height;

    this.fallingBallastList.forEach((ballast, index, arr) => {
      ballast.addTime({timeDelta});
      if (ballast.height < -ballastHeight) {
        arr.splice(index, 1);
        updated = true;
      }
    });

    if (extraTemperatureVelocity !== 0 && this.fuel.current > 0) this.fuel.current += this.fuel.speed * timeDelta;

    if (updated) this.forceUpdate();
    return this;
  };

  dropBallast = ({amount: requestedAmount = 1} = {}) => {
    if (this.physics.ballastAmount <= 0) return false;
    const amount = requestedAmount > this.physics.ballastAmount ? this.physics.ballastAmount : requestedAmount;

    const {balloonSizes: {extraBallastYSpace, coef}} = AirBalloon;

    for (let i = 0; i < amount; i++) {
      const ballast = new BallastPhysics({
        index: this.physics.ballastAmount - 1 - i,
        height: this.physics.height + extraBallastYSpace * coef,
        velocity: this.physics.velocity,
      });
      this.fallingBallastList.push(ballast);
    }
    this.physics.dropBallast({amount});

    this.forceUpdate();
    return true;
  };

  #forceUpdate;
  forceUpdate = () => {
    if (typeof this.#forceUpdate === 'function') this.#forceUpdate();
  };

  Component = ({reffer, fireRef, __DEV__rectRef}) => {
    const {__DEV__rectColor} = AirBalloon;
    const [balloonImageNode] = useImage(balloonImage);
    const [ballastImageNode] = useImage(ballastImage);

    this.#forceUpdate = useForceUpdate();

    return (
      <>
        <Group
          key={'group'}
          ref={reffer}
          x={this.x}
          y={this.y}
        >
          <Image
            image={balloonImageNode}
            scale={this.scale}
          />
          <GIF
            reffer={fireRef}
            src={fireGif}
            x={this.fireX}
            y={this.fireY}
            scale={this.fireScale}
            visible={false}
            loop
          />
          {new Array(this.physics.ballastAmount).fill(null).map((_, index) => (
            <Image
              key={'' + index}
              image={ballastImageNode}
              scale={this.scale}
              x={this.calcBallastX(index)}
              y={this.ballastY}
            />
          ))}

          {__DEV__rectRef ? (
            <Rect
              ref={__DEV__rectRef}
              x={this.__DEV__rectX}
              y={this.__DEV__rectY}
              width={this.figure.width}
              height={this.figure.height}
              fill={__DEV__rectColor}
            />
          ) : null}
        </Group>

        {this.fallingBallastList.map((ballastPhysics) => (
            <this.FallingBallast
              key={ballastPhysics.key}
              physics={ballastPhysics}
              getRef={(ref) => (ballastPhysics.ref = ref)}
            />
        ))}
      </>
    );
  };

  fallingBallastList = [];

  FallingBallast = ({getRef, physics}) => {
    const [ballastImageNode] = useImage(ballastImage);

    const ref = useRef();
    useEffect(() => {
      if (typeof getRef === 'function') getRef(ref);
    }, [ref.current]);

    return (
      <Image
        ref={ref}
        image={ballastImageNode}
        x={this.calcFallingBallastX(physics)}
        y={this.calcFallingBallastY(physics)}
        scale={this.scale}
      />
    );
  };

  get x() {return this.figure.x + AirBalloon.balloonSizes.deltaX;}
  get y() {return this.areaHeight - (this.figure.y + this.figure.height) + AirBalloon.balloonSizes.deltaY;}
  get __DEV__rectX() {return this.figure.x - this.x;}
  get __DEV__rectY() {return - AirBalloon.balloonSizes.deltaY;}
  get scale() {return {x: AirBalloon.balloonSizes.coef, y: AirBalloon.balloonSizes.coef};}
  get fireScale() {return AirBalloon.balloonSizes.fireCoef;}
  get fireX() {return AirBalloon.balloonSizes.fireDeltaX;}
  get fireY() {return AirBalloon.balloonSizes.fireDeltaY;}
  get ballastY() {return - AirBalloon.balloonSizes.ballastDeltaY;}
  get ballastX() {return - AirBalloon.balloonSizes.ballastDeltaX;}
  calcBallastX = (index) => this.ballastX + AirBalloon.balloonSizes.calcBallastOffsetX(index);
  calcFallingBallastX = ({index}) => this.x + this.calcBallastX(index);
  calcFallingBallastY = ({height}) => this.areaHeight - height - AirBalloon.balloonSizes.ballastHeight;

  static balloonSizes = {
    _fullImage: {
      width: 346,
      height: 576,
    },
    _basket: {
      width: 105,
      height: 64,
    },
    _fire: {
      width: 30,
      height: 47,
    },
    _ballast: {
      width: 19,
      height: 30,
      offsetX: {
        0: 13,
        1: 42,
        2: 71,
      },
    },
    extraYSpace: 2,
    targetWidth: 80,
    get coef() {return this.targetWidth / this._fullImage.width;},
    get targetHeight() {return this._fullImage.height * this.coef;},
    get basketWidth() {return this._basket.width * this.coef;},
    get basketHeight() {return this._basket.height * this.coef;},
    get deltaX() {return this.coef * (this._basket.width - this._fullImage.width) / 2;},
    get deltaY() {return this.coef * (this._basket.height - this._fullImage.height + this.extraYSpace);},

    extraFireSpace: 8,
    targetFireWidth: 7,
    get fireCoef() {return this.targetFireWidth / this._fire.width;},
    get targetFireHeight() {return this._fire.height * this.fireCoef;},
    get fireDeltaX() {return (this.targetWidth - this.targetFireWidth) / 2},
    get fireDeltaY() {return (this.targetHeight - this.targetFireHeight) * 2 / 3 + this.extraFireSpace},

    extraBallastYSpace: 14,
    get ballastHeight() {return this._ballast.height * this.coef;},
    get ballastDeltaY() {return this.deltaY + this.extraBallastYSpace * this.coef - this.ballastHeight;},
    get ballastDeltaX() {return this.deltaX;},
    calcBallastOffsetX: (index) => this.balloonSizes.coef * this.balloonSizes._ballast.offsetX[index],
  };

  static __DEV__rectColor = '#ff00ff';
}

export default AirBalloon;
