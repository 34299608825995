import Key from "../../../../utils/Key";

class BallastPhysics {
  get acceleration() {return this.#accelerationOfGravity;}
  addTime = ({timeDelta}) => {
    this.#height += this.velocity * timeDelta + (this.acceleration * Math.pow(timeDelta, 2)) / 2;
    this.#velocity += this.acceleration * timeDelta;
  };

  constructor({
    key = new Key(),
    index = 0,
    height = 0,
    velocity = 0,
  } = {}) {
    this.key = key;
    this.index = index;
    this.#height = height;
    this.#velocity = velocity;
  }

  ref;

  get height() {return this.#height;}
  get velocity() {return this.#velocity;}
  #height;
  #velocity;
  #accelerationOfGravity = -0.25;
}

export default BallastPhysics;
