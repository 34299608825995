import React, {useRef} from "react";
import {Group, Rect, Text} from "react-konva";
import PropTypes, {number, any, func, string, object} from 'prop-types';
import {mainColor} from "../../../utils/styles";

/**
 * mode: 'number' | 'text'
 */
const CanvasInput = (props) => {
  const {
    ref,
    mode='number',
    x, y,
    width,
    height,
    rectStyles,
    background='white',
    containerRef,
    getStage, // function
    stage,
    textColor=mainColor,
    fontSize,
    fontStyle,
    value,
    inputHide,
    inputHideRef = {current: null},
    inputRef = {current: null},
    withoutBorder,
    onInput = (value) => console.log(value),
    visible,
    textAlign='normal',
    disabled
  } = props;

  const textRef = useRef(null);

  const textNode = textRef?.current;
  const input = document.getElementById('inputId');

  // ------- CLEAR VALUES --------
  if (input && input.value && (!value && Number(value) !== 0)) {
    input.value = '';
  }
  if (textNode && textNode.text() && (!value && Number(value) !== 0)) {
    textNode.text('');
    textNode.draw();
  }
  if ((inputHide || inputHideRef.current) && input) {
    input.parentNode.removeChild(input);
  }

  // update html input value when value was changed from the outside
  if (input && input.value && input.value !== value) {
    input.value = value;
  }

  function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  const handleClick = () => {
    const corrStage = stage || getStage?.();
    if (corrStage && !disabled) {
      const textNode = textRef?.current;
      const prevValue = textNode.text();
      textNode.hide();

      const ancestorLayer = textNode.findAncestors('Layer')[0];
      ancestorLayer.draw();

      const textPosition = textNode.absolutePosition();

      const frameContainer = document.getElementById('frame-canvas-task-container');

      const areaPosition = {
        x: textPosition.x,
        y: textPosition.y,
      };

      // create textarea and style it
      const textInput = document.createElement('input');
      frameContainer.appendChild(textInput);

      const isNumberMode = mode === 'number';

      // apply many styles to match text on canvas as close as possible
      // remember that text rendering on canvas and on the textarea can be different
      // and sometimes it is hard to make it 100% the same. But we will try...

      textInput.id = 'inputId';
      textInput.value = textNode.text();
      textInput.autocomplete = "off";
      textInput.style.position = 'absolute';
      textInput.style.top = areaPosition.y + 'px';
      textInput.style.left = areaPosition.x + 'px';
      textInput.style.width = textNode.width() + 'px';
      textInput.style.height =
        textNode.height() - textNode.padding() + 'px';
      textInput.style.fontSize = textNode.fontSize() + 'px';
      textInput.style.zIndex = 1001;

      textInput.style.border = withoutBorder ? '0px solid rgba(0,0,0,0)' : '1px solid rgba(0,0,0,0.3)';
      textInput.style.borderRadius = '3px';
      textInput.style.padding = '0px';
      textInput.style.margin = '0px';
      textInput.style.overflow = 'hidden';
      textInput.style.background = background;
      textInput.style.outline = 'none';
      textInput.style.resize = 'none';
      textInput.style.lineHeight = textNode.lineHeight();
      textInput.style.fontFamily = textNode.fontFamily();
      textInput.style.transformOrigin = 'left top';
      textInput.style.textAlign = textNode.align();
      textInput.style.verticalAlign = textNode.align();
      textInput.style.color = textNode.fill();
      textInput.oninput = (e) => {
        const val = e.target.value.replace(/\,/g, '.');
        const corrVal = isNumberMode ? Number(val) : val;
        onInput(corrVal);
      };
      inputRef.current = textInput;

      textInput.addEventListener('keydown', function (e) {
        // hide on enter
        // but don't hide on shift + enter
        if (e.keyCode === 13 && !e.shiftKey) {
          if (textInput.value === '') { textInput.value = prevValue; }
          //textNode.text(textInput.value);
          removeTextarea();
        }
        // on esc do not set value back to node
        if (e.keyCode === 27) {
          removeTextarea();
        }

        if (isNumberMode) {
          const key = e.key.replace(',', '.');
          const keyIsCorr = /\.|\d/.test(key);
          const val = textInput.value.replace(/\,/g, '.');
          const dotCount = ((val+key).match(/\./g) || []).length;
          if (
            (!keyIsCorr && key.length < 2) || dotCount > 1
          ) {
            e.preventDefault();
          }
          textInput.value = val;
        }
      });

      function handleOutsideClick(e) {
        if (e.target !== textInput) {
          if (textInput.value === '') { textInput.value = ''; }
          removeTextarea();
        }
      }

      // todo refactor this code  ------------ ------------ ------------
      const clientHeight = document.documentElement.clientHeight;
      const clientWidth = document.documentElement.clientWidth;
      const minSizeScreen = Math.min(clientWidth, clientHeight);
      function setCorPos() {
        const inputNode = document.querySelector('#inputId');
        if (inputNode) {
          const containerWithScale = document.getElementById('canvas-task-container');
          const containerScale = Number(containerWithScale.dataset.scale);
          const scale = containerScale && containerScale < 1 ? Number(containerWithScale.dataset.scale) : 1;
          const areaPosition = {
            x: textPosition.x,
            y: textPosition.y,
          };
          const newClientHeight = document.documentElement.clientHeight;
          const inputHeight = parseInt(inputNode.style.height);
          inputNode.style.top = (areaPosition.y - ((clientHeight - newClientHeight))) + inputHeight + 'px';
        }
      }
      function handleResize(e) {
        setTimeout(() => {
          setCorPos();
        }, 0);
        if (minSizeScreen < 1000) {
          return;
        }
        handleOutsideClick(e, 'resize');
      }
      // todo refactor this code ------------ ------------ ------------

      function removeTextarea() {
        if (textInput.parentNode) {
          textInput.parentNode.removeChild(textInput);
          window.removeEventListener('click', handleOutsideClick);
          window.removeEventListener('touchstart', handleOutsideClick);
          window.removeEventListener('resize', handleOutsideClick);
          if (window.screen.orientation) {
            window.screen.orientation.removeEventListener('change', handleOutsideClick);
          }
          textNode.text(textInput.value);
          textNode.show();
          ancestorLayer.draw();
        }
      }

      setTimeout(() => {
        window.addEventListener('click', handleOutsideClick);
        window.addEventListener('touchstart', handleOutsideClick);
        window.addEventListener('resize', handleResize);
        if (window.screen.orientation) {
          window.screen.orientation.addEventListener('change', handleOutsideClick);
        }
      });

      textNode.draw();

      textInput.focus();
    }
  }

  return (
    <Group
      visible={visible}
      ref={containerRef}
      x={x} y={y}
      width={width}
      height={height}
      onClick={() => handleClick()}
      onTap={() => handleClick()}
    >

      <Rect
        stroke={'rgba(0,0,0,.3)'}
        strokeWidth={1}
        fill={background}
        width={width}
        height={height}
        cornerRadius={3}
        {...rectStyles}
      />
      <Text
        offsetX={-1}
        offsetY={-1.5}
        text={value}
        ref={textRef}
        stroke={textColor}
        fill={textColor}
        strokeWidth={.1}
        fontSize={fontSize ? fontSize : height}
        fontStyle={fontStyle}

        width={width} height={height}
        verticalAlign={'middle'}
        align={textAlign}
      />
    </Group>
  )
}

CanvasInput.propTypes = {
  x: number,
  y: number,
  width: number.isRequired,
  height: number.isRequired,
  stage: any.isRequired,
  onInput: func.isRequired,
  value: number||string,
  textColor: string,
  rectStyles: object
}

export default CanvasInput;
