import React from "react";
import {Button} from "antd";
import {UndoOutlined} from '@ant-design/icons';
import './ResetBtn.css';
import {_t} from '../../../utils/lang/common';


const ResetBtn = (props) => {
  return (
    <Button className={'theory-reset-btn'} type={'primary'} {...props}><UndoOutlined style={{marginTop: '2px'}}/>{_t('Restart_button')}</Button>
  )
}

export default ResetBtn;
