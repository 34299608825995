import React, {useEffect, useState} from "react";
import '../styles/schoolTaskDropColumn.css';
import SchoolTaskCard from "./SchoolTaskCard";
import {DropTarget} from "react-drag-drop-container";


/**
 * PROPS
 *   id: number | string,
 *   title: string,
 *   dragItem: obj,
 *   dragging: bool,
 *   success: bool,
 *   fail: bool,
 *   onDragStart: fn,
 *   onDragEnd: fn,
 */
const SchoolTaskDropColumn = (props) => {
  const [enter, setEnter] = useState(false);

  return (
    <div className={`school-task-drop-column ${enter && props.dragStart ? 'school-task-drop-column--enter' :''}`}>
    {/*<div className={`school-task-drop-column ${enter ? 'school-task-drop-column--enter' :''}`}>*/}
      <div className={'school-task-drop-column__head'} style={{height: props.heightTitle}}>
        <p className={'school-task-drop-column__title'} style={{fontSize: props.titleFontSize}}>{props.title}</p>
      </div>
      <div className={`school-task-drop-column__body`}>
        <SchoolTaskCard
          draggable={props.dragging}
          index={props.index}
          id={props.dragItem?.id}
          key={props.dragItem?.id}
          img={props.dragItem?.image}
          mainText={props.dragItem?.mainText}
          text={props.dragItem?.text}
          success={props.success}
          fail={props.fail}
          onDragStart={(e) => {
            props.onDragStart(e);
          }}
          onDrag={(e) => {
            props.onDrag(e);
          }}
          onDragEnd={(e) => {
            setEnter(false);
            props.onDragEnd(e);
          }}
          onDrop={(e) => {
            setEnter(false);
            props.onDrop(e);
          }}
        />
      </div>
      <DropTarget
        targetKey="targetKey"
        dropData={{columnId: props.id}}
        onDragEnter={(e) => setEnter(true)}
        onDragLeave={(e) => setEnter(false)}
      >
        <div
          className={`school-task-drop-column__drop-place ${props.dragStart ? 'school-task-drop-column__drop-place--visible' : ''}`}/>
      </DropTarget>
    </div>
  )
}
export default SchoolTaskDropColumn;
