import React, {useEffect, useState} from "react";
import LangSelect from '../../auth/components/LangSelect'
import {blueColor} from '../../../utils/styles';
import {userRoles} from "../../../utils/common";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {_t} from "../../../utils/lang/common";
import {Button, Col, Row, Space} from 'antd';
import ModalCreateLesson from "./ModalCreateLesson";
import useMedia from "../../../utils/custom-hooks/useMedia";


const Header = (props) => {
  const {
    isDemo,
    lessonSession,
    user,
    changePresentationModalVisible,
    logoutAction,
    lessons,
    changeLessonSession
  } = props;

  const [visibleGenerateLinkModal, setVisibleGenerateLinkModal] = useState(false);

  const isTeacher = user && user.role === userRoles.teacher;
  const isMDView = useMedia(['(max-width: 768px)'],[true], false);
  const isLessonCreated = Boolean(lessonSession.link);


  useEffect(() => {
    setEmptyLessonCheckbox();
  }, [lessons]);

  const setEmptyLessonCheckbox = () => {
    const lessonsObj = lessons.reduce((obj,lesson) => (obj[lesson.code]=false, obj),{});
    changeLessonSession({selectedLessons: lessonsObj});
  };

  const logout = async () => {
    await logoutAction();
  };

  const onCLickCloseCreateLesson = () => {
    setVisibleGenerateLinkModal(false);
  };
  const onCLickCreateLesson = () => setVisibleGenerateLinkModal(true);
  const onCLickOpenPresentation = () => changePresentationModalVisible(true);


  const correctLogoMobileStyle = isTeacher || isDemo ? styles.logoContainerSmallTeacher : styles.logoContainerSmallStudent;
  const mobileLogoStyle = isMDView ? correctLogoMobileStyle : {};
  const headerMobileHeight = isTeacher || isDemo ? 'auto' : '50px';
  return (
    <div style={{...styles.headerWrapper, ...{height: `${props.collapsed ? '0' : `${isMDView ? headerMobileHeight : 'auto'}`}`}}}>
      <Row style={{padding: `${!isMDView ? '10px 0 0 0' : '0'}`}}>
        <Col style={{...styles.logoContainer, ...mobileLogoStyle}}>
          <h3 style={styles.logo}>Физматика</h3>
        </Col>
        <Col flex={'5 1 auto'}>
          {
            !isDemo ? (
              <Row gutter={[10]} style={{padding: `${isMDView ? '15px 10px 10px' : '25px 20px 20px'}`}}>
                <Col flex={'3 1 auto'} >
                  {
                    isTeacher && (
                      <Space size={10}>
                        <Button
                          size={isMDView ? 'small' : 'default'}
                          type={'primary'}
                          onClick={onCLickOpenPresentation}
                        >{_t("button_open_presentation")}</Button>
                        <Button
                          size={isMDView ? 'small' : 'default'}
                          type={Boolean(lessonSession.link) ? 'warning' :'primary'}
                          onClick={onCLickCreateLesson}
                        >{isLessonCreated ? _t("button_edit_lesson_session") : _t("button_create_lesson_session")}</Button>

                        <ModalCreateLesson
                          onCancel={onCLickCloseCreateLesson}
                          visible={visibleGenerateLinkModal}
                        />
                      </Space>
                      )
                  }
                </Col>
                <Col flex={'1 1 auto'} align={'end'}>
                  <Space size={10}>
                    <LangSelect
                      size={isMDView ? 'small' : 'default'}
                      lang={props.lang}
                      styles={{position: 'relative', top: 0, right: 0}}/>
                    <Button
                      size={isMDView ? 'small' : 'default'}
                      type="ghost"
                      onClick={() => logout()}
                    >{_t("button_logout")}</Button>
                  </Space>
                </Col>
              </Row>
            ) : (
              <Row gutter={[30]} style={{
                padding: `${isMDView ? '5px 10px 10px' : '15px 20px 10px'}`,
                justifyContent: 'flex-start'
              }}>
                <Col>
                  <p><b>Запись на вебинар для преподавателей - </b></p>
                  <a href="https://forms.gle/EdgFFYV1gkvPJ5W97">https://forms.gle/EdgFFYV1gkvPJ5W97</a>
                </Col>
                <Col>
                  <p><b>Оставить идею новой задачи - </b></p>
                  <a href="https://forms.gle/YSPsXuMWbFTFt6Gy7">https://forms.gle/YSPsXuMWbFTFt6Gy7</a>
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
      {
        isTeacher || (user && user.is_superuser) && (
          <div style={{position: 'absolute', top: '5px', right: '20px'}}>
            <p><b>{user && user.username}</b></p>
          </div>
        )
      }
    </div>
  );
};


const mapStateToProps = (state) => ({
  lessonSession: state.lessonsReducer.lessonSession,
  lang: state.lessonsReducer.lang,
  lessons: state.lessonsReducer.lessons,
  lessonsGroups: state.lessonsReducer.lessonsGroups,
  lessonsAccess: state.lessonsReducer.lessonsAccess,
  user: state.lessonsReducer.user,
  activeLesson: state.lessonsReducer.activeLesson,
  paymentModalVisible: state.commonReducer.paymentModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  logoutAction: () => dispatch(actions.logoutAction()),
  checkLogin: () => dispatch(actions.checkLogin()),
  setActiveLesson: (lesson) => dispatch(actions.setActiveLesson(lesson)),
  changeChangePaymentVisible: (status) => dispatch(actions.changeChangePaymentVisible(status)),
  changePresentationModalVisible: (status) => dispatch(actions.changePresentationModalVisible(status)),
  setLanguage: (language) => dispatch(actions.setLanguage(language)),
  generateLessonLink: (tasksCodes) => dispatch(actions.generateLessonLink(tasksCodes)),
  changeLessonSession: () => dispatch(actions.changeLessonSession()),
  closeLesson: () => dispatch(actions.closeLesson()),
  resetLessonSession: () => dispatch(actions.resetLessonSession()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

const styles = {
  // LOGO
  logo: {
    fontSize: '2.5em',
    color: blueColor,
    fontWeight: 'bolder',
    lineHeight: 1,
    margin: '0'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '250px',
    minWidth: '250px',
    padding: '10px'
  },
  logoContainerSmallStudent: {
    maxWidth: '155px',
    minWidth: 'auto',
    width: '100%',
    fontSize: '.6em'
  },
  logoContainerSmallTeacher: {
    maxWidth: '100%',
    width: '100%',
    fontSize: '.6em'
  },

  headerWrapper: {
   transition: 'height .25s',
    overflow: 'hidden',
  },
  closeIcon: {
    cursor: 'pointer',
    width: '30px',
    position: 'absolute',
    top: '5px',
    right: '5px'
  },
  leftBlock: {
    flexShrink: 0,
    width: '250px',
  },
  lessonInfoWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    lineHeight: 1.2,
    height: '40px',
    fontSize: '1.1em',
    width: '100%'
  },
  smallText: {
    color: blueColor,
    fontWeight: 'bold'
  },
  dateTime: {
    color: blueColor,
    fontWeight: 'bold',
    fontSize: '3em',
    lineHeight: .9
  },
  dateTimePiker: {
    fontSize: '12px',
    margin: '0 0 0px 10px',
    display: 'flex',
  },
  goToLessonBtn: {
    width: 'auto',
    margin: '0 0 0 20px',
    borderRadius: '7px',
    padding: '7px 15px',
    fontWeight: 'bold',
    border: 0,
    background: blueColor,
    color: 'white',
    fontSize: '1.4em'
  },
  logoutButton: {
    margin: '0px 20px 0px',
    height: '40px',
    padding: '0 15px',
    fontWeight: 'bold',
  },
  studenLink: {
    border: 0,
    boxShadow: 'none',
    background: 'transparent',
    cursor: 'pointer',
    fontSize: '1.8em',
    margin: '5px 0px 0px 15px',
  },
  langSwitcher: {
    width: '50px',
    padding: '0',
    cursor: 'pointer'
  },
  input: {
    fontSize: '1.2em',
    width: '170px',
    flexShrink: 0,
  },
};
