import IframeContainer from "./IframeContainer";
import React, {useEffect, useState} from "react";
import {Spin} from "antd";


const ConstructContainer = (props) => {
  const {
    lessonData,
    constructCode,
  } = props;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let openRequest = indexedDB.open('construct');

    openRequest.onupgradeneeded = function() {
      let db = openRequest.result;
      if (!db.objectStoreNames.contains('taskCode')) {
        db.createObjectStore('taskCode', {keyPath: 'id'});
      }
    }

    openRequest.onsuccess = function(e) {
      let db = openRequest.result;

      let transaction = db.transaction("taskCode", "readwrite");

      // получить хранилище объектов для работы с ним
      let taskCode = transaction.objectStore("taskCode");

      let newTaskCode = {
        id: 'code',
        code: lessonData.code
      };

      let request = taskCode.openCursor();
      request.onsuccess = function (e) {
        let cursor = e.target.result;
        let res;
        if (cursor) {
          res = cursor.update(newTaskCode);
        } else {
          res = taskCode.add(newTaskCode)
        }

        res.onsuccess = () => {
          setVisible(true);
        }
      };
    };
  }, []);


  let tasksUrl = "co-lessons/"+constructCode+"?code="+lessonData.code;
  if (window.location.hostname.includes('localhost')) {
    tasksUrl = constructCode+"?code="+lessonData.code;
  }
  return (
    <>
      {
        visible ? (
          <IframeContainer urlCode={tasksUrl} lessonData={lessonData}/>
        ) : (
          <Spin size="large">
            <div style={{width: '1000px', height: '500px'}}/>
          </Spin>
        )
      }
    </>
  )
}

export default ConstructContainer;
