import React, {useEffect, useState} from "react";
import GoBack from "./GoBack";
import TaskAdditionalSettings from "../../pages/layout2/components/TaskAdditionalSettings";
import VkSharePopup from "./VkSharePopup/VkSharePopup";
import FullscreenBtn from "./FullscreenBtn";
import {getScaleRatioAndExtraStyles} from "../utils";
import TheoryFolder from "../../components/TheoryFolder/TheoryFolder";


const TaskContainerWithCanvasSize = (
  {
    match,
    styles,
    isPhaser,
    withback,
    taskContent,
    lessonData,
    setLessonVariant,
    hideFullscreenBtn,
    externalFullscreen = false,
  }) => {
  const [fullscreen, setFullscreen] = useState(externalFullscreen);
  const [windowChanged, setWindowChanged] = useState(0);
  const [theoryVisible, setTheoryVisible] = useState(false);

  const resizeListener = () => {
    setWindowChanged(windowChanged + 1)
  };

  /** Opens a theory from an iframe problem */
  const receiveMessage = (e) => {
    switch (e.data) {
      case 'openTextTheory':
        setTheoryVisible(true);
        break;
      case 'taskInit':
        break
      case 'taskFailure':
        break
      case 'taskSuccess':
        break
    }
    if (window != window.parent) {
      window.parent.postMessage(e.data, '*');
    }
  }

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener)
      window.removeEventListener("message", receiveMessage);
    };
  });

  const video = lessonData?.video;
  // Изменение размера окна
  const {scaleRatio, extraStyles} = getScaleRatioAndExtraStyles(match, isPhaser, fullscreen);

  return (
    <div id={'canvas-task-container'} style={{...extraStyles.container, ...(isPhaser ? {height: '543px'} : {})}}
         data-scale={scaleRatio}>
      <GoBack
        lessonData={lessonData}
        setLessonVariant={setLessonVariant}
      />
      {
        withback ?
          <>
            <TaskAdditionalSettings lessonData={lessonData}/>
            <VkSharePopup lesson={lessonData}/>
          </>
          : null
      }

      {taskContent}

      {
        !hideFullscreenBtn ? (
          <FullscreenBtn styles={styles} externalFullscreen={externalFullscreen} visible={!video} setFullscreen={setFullscreen}/>
        ) : null
      }

      <TheoryFolder data={lessonData?.theory_folder} visible={Boolean(lessonData?.theory_folder) && theoryVisible}/>
    </div>
  )
}

export default TaskContainerWithCanvasSize;
