import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Line } from "react-konva";
import Victor from 'victor';
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {GIF} from "../../../canvas/components/GIF";
import {degToRad, lessonStatus, radToDeg, sendSuccessForScenario} from '../../../../utils/common';
import * as actions from '../../../../store/actions';
import boatFormImg from '../../../../images/submarine/step2/boat-form.png';
import submarineImg from '../../../../images/submarine/step2/submarine.png';
import backgroundImg from '../../../../images/submarine/step2/background2.png';
import tubeImg from '../../../../images/submarine/step2/tube-2.png';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import CanvasButton from "../../../canvas/components/CanvasButton";



class SubmarineWaterDisplacement extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();
    this.submarineRef = React.createRef();
    this.submarineWaterRef = React.createRef();
    this.waterInBalloonBackRef = React.createRef();
    this.waterInBalloonFrontRef = React.createRef();
    this.waterInTubeRef = React.createRef();

    this.staticData = {};
    this.state = {
      moveUp: false,
      moveDown: false,
    };
    this.initialData = {
      submarineOffsetY: 0,
      waterOffsetY: 0,
      waterInBalloonOffsetY: 0,
      waterTubePoints: [0,0,0,0],
      waterTubeOpacity: 0
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 8000)
  }

  componentWillUnmount() {
    window.requestAnimationFrame(this.move);
    clearTimeout(this.timerId);
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  getAllNodes = (stageNode) => ({
    submarineNode: this.submarineRef?.current,
    submarineWaterNode: this.submarineWaterRef?.current,
    waterInTubeNode: this.waterInTubeRef?.current,
    waterInBalloonBackNode: this.waterInBalloonBackRef?.current,
    waterInBalloonFrontNode: this.waterInBalloonFrontRef?.current,
  });

  getWaterInTubePoints(timedeltaSec) {
    const data = this.data;
    let x2 = 0, y2 = 0, x3 = 0, y3 = 0;
    if (data.waterOffsetY > 0) {
      x2 = data.waterTubePoints[2]+30 * timedeltaSec*55;
      y2 = data.waterTubePoints[3]+13 * timedeltaSec*55;
      y3 = y2 + data.waterTubePoints[5] * timedeltaSec*55;

      if (x2 >= 313) { x2 = 313; x3 = x2; }
      if (y2 >= 135) { y2 = 135; }
      if (y3 >= 188) { y3 = 188; }
      if (x2 < 313) { y3 = 0 }
    }
    return [0,0, x2, y2, x3, y3];
  }
  get waterInBalloonOffsetY() {
    const data = this.data;
    if (!this.state.moveDown && data.waterInBalloonOffsetY > 0 && data.submarineOffsetY > -80) {
      return data.waterInBalloonOffsetY - 0.5;
    }
    if (this.state.moveDown && Math.abs(data.submarineOffsetY-5) >= 35 && data.waterInBalloonOffsetY < 10 ) {
      return data.waterInBalloonOffsetY + 0.5;
    }
    return data.waterInBalloonOffsetY;
  }
  get waterOffsetY() {
    const data = this.data;
    return Math.abs(data.submarineOffsetY) >= 35 && Math.abs(data.submarineOffsetY) < 100 ? Math.abs(data.submarineOffsetY) - 35 : data.waterOffsetY;
  }

  move = (time) => {
    const {} = this.staticData;
    const {
      moveUp, moveDown
    } = this.state;
    const nodes = this.getAllNodes();
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    data.waterTubeOpacity = data.submarineOffsetY <= -80 ? 1 : 0;
    if (moveDown) {
      data.submarineOffsetY -= 10 * timedeltaSec;
      data.waterTubeOpacity = 1;
    }
    if (data.submarineOffsetY < -100)
      data.submarineOffsetY = -100;


    data.waterOffsetY = this.waterOffsetY;
    data.waterInBalloonOffsetY = this.waterInBalloonOffsetY;
    data.waterTubePoints = this.getWaterInTubePoints(timedeltaSec);

    // if (moveUp) {
    //   data.submarineOffsetY += 10 * timedeltaSec;
    // }


    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const timedelta = data.prevTime ? data.startTime - data.prevTime : 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const nodes = this.getAllNodes();

    nodes.submarineNode.offsetY(data.submarineOffsetY);
    nodes.submarineWaterNode.offsetY(Math.abs(data.waterOffsetY));

    nodes.waterInBalloonBackNode.offsetY(data.waterInBalloonOffsetY);
    nodes.waterInBalloonFrontNode.offsetY(data.waterInBalloonOffsetY);

    const defaultBalloonHeight = 403;
    nodes.waterInBalloonBackNode.height(defaultBalloonHeight+data.waterInBalloonOffsetY);
    nodes.waterInBalloonFrontNode.height(defaultBalloonHeight+data.waterInBalloonOffsetY);

    nodes.waterInTubeNode.points(data.waterTubePoints);
    nodes.waterInTubeNode.opacity(data.waterTubeOpacity);


    stageNode.draw();
  }

  CanvasSubmarineWaterDisplacement = () => {
    const {} = this.state;
    const {backgroundPosY} = this.data;

    const [submarine] = useImage(submarineImg);
    const [background] = useImage(backgroundImg);
    const [boatForm] = useImage(boatFormImg);
    const [tube] = useImage(tubeImg);

    return (
      <React.Fragment>
        <Layer preventDefault={false}>
          <Group y={-145}>
          <Image image={background} preventDefault={false}/>

          <Rect ref={this.waterInBalloonBackRef} x={101} y={251} width={378} height={403} fill={'#AAC4FF'} cornerRadius={[0,0, 8.5, 8.5]} preventDefault={false}/>

          <Group ref={this.submarineRef} x={130} y={-180}>
            <Image image={submarine} preventDefault={false}/>
          </Group>

          <Rect ref={this.waterInBalloonFrontRef} x={101} y={251} width={378} height={403} fill={'#AAC4FF'} opacity={.5} cornerRadius={[0,0, 8.5, 8.5]} preventDefault={false}/>

          <Group x={548} y={302}>
            <Rect ref={this.submarineWaterRef} width={310} height={100} fill={'#AAC4FF'} x={40} y={132}/>
            <Image image={boatForm} preventDefault={false}/>
          </Group>

          <Group x={478} y={240}>
            <Image image={tube} preventDefault={false}/>
          </Group>

          <Line ref={this.waterInTubeRef} points={[0,0,30,14]} x={478} y={244} stroke={'#AAC4FF'} strokeWidth={6}/>
          </Group>
        </Layer>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}
           onKeyPress={(e) => {console.log(e)}}
      >
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
          <this.CanvasSubmarineWaterDisplacement/>

          <Layer>
            <Group x={590} y={450}>
              <CanvasButton
                text={'Погружение'}
                onTouchStart={() => this.setState({moveDown: true, moveUp: false})}
                onTouchEnd={() => this.setState({moveDown: false, moveUp: false})}
                onMouseDown={() => this.setState({moveDown: true, moveUp: false})}
                onMouseUp={() => this.setState({moveDown: false, moveUp: false})}
                fontSize={20}
                strokeWidth={.2}
                btnCornerRadius={5}
                width={150}
                height={45}
                btnFill={'#9caaad'}
                btnStroke={'#808080'}
                btnStrokeWidth={1}
                x={0}
              />
              <CanvasButton
                text={'Сбросить'}
                onClick={() => this.setState({moveUp: false, moveDown: false}, () => this.data = cloneDeep(this.initialData))}
                fontSize={20}
                strokeWidth={.2}
                btnCornerRadius={5}
                width={150}
                height={45}
                btnFill={'#9caaad'}
                btnStroke={'#808080'}
                btnStrokeWidth={1}
                x={170}
              />
            </Group>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmarineWaterDisplacement);

const styles = {
  mainContainer: {
    background: '#EDF2FB',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
};
