import React from "react";
import CanvasContainer from "../../../../canvas/containers/CanvasContainer";
import { Image, Layer, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import backgroundImg from '../../../../../images/timeDependenceOfWork/background.png';
import hookImg from '../../../../../images/timeDependenceOfWork/hook.png';
import cargoImg from '../../../../../images/timeDependenceOfWork/cargo.png';
import {layout2} from "../../../../../utils/styles";
import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import MovingArrow from "../../../../canvas/components/MovingArrow";
import {Player} from "../../../../canvas/components/Player/Player";
import * as actions from "../../../../../store/actions";
import ScenarioManager from "../../../../../utils/ScenarioManager";
import {sendSuccessForScenario} from "../../../../../utils/common";


class TimeDependenceOfWork extends React.Component {
  constructor(props) {
    super(props);
    this.cargoPos = ['left', 'right'];
    this.managedComponents = [
      'stage',

      'playHintArrow',

      'leftCargo',
      'leftCargoCable',
      'leftChartPoint',
      'leftChartLine',

      'rightCargo',
      'rightChartPoint',
      'rightChartLine',
      'rightCargoCableFirst',
      'rightCargoCableSecond',

      'nextBtn',
      'prevBtn',
      'playBtn',
      'stopBtn',
    ]

    this.staticData = {
      endY: 235,
      maxTime: 10,

      leftTimeEnd: 10,
      rightTimeEnd: 5,
      leftWorkEnd: 10,
      rightWorkEnd: 10,
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      timeFromPlaySec: 0,


      leftEnginePower: 1,
      rightEnginePower: 2,

      rightChartPoint: {x: 0, y: 0},
      rightChartLine: [0,0,0,0],
      rightCargoY: 0,

      leftChartPoint: {x: 0, y: 0},
      leftChartLine: [0,0,0,0],
      leftCargoCableY: 0,
      leftCargoY: 0,

      start: false,
      play: false,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  _movingCallbacks = {};
  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };

  mToPx = (meters) => {
    const staticData = this.staticData;
    return meters * (staticData.endY / staticData.maxTime)
  };

  getCorrChartXAndY = (pos) => {
    const data = this.data;
    const staticData = this.staticData;
    const newY = data.timeFromPlaySec * data[`${pos}EnginePower`];
    let x = data.timeFromPlaySec < staticData[`${pos}TimeEnd`] ? data.timeFromPlaySec : staticData[`${pos}TimeEnd`];
    let y = newY < staticData[`${pos}WorkEnd`] ? newY : staticData[`${pos}WorkEnd`];

    return {x: this.mToPx(x), y: -this.mToPx(y)};
  }

  getChartPointPos = (pos) => this.getCorrChartXAndY(pos);
  getChartLinePoints = (pos) => {
    const {x, y} = this.getCorrChartXAndY(pos);
    return [0,0,x, y];
  }
  getCargoY = (pos) => {
    const data = this.data;
    const staticData = this.staticData;

    if (data[`${pos}CargoY`] < 0) {
      return 0
    }
    const newCargoY = this.mToPx(data.timeFromPlaySec * data[`${pos}EnginePower`]);
    return newCargoY >= staticData.endY
      ? staticData.endY
      : newCargoY;
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;

    if (timedeltaSec > 0.5) {
      timedeltaSec = 0.1;
    }

    if (data.play) {
      data.timeFromPlaySec += timedeltaSec;
      this.updateCargoData();
    }

    this.updateStage();
  };

  updateCargoData = () => {
    const data = this.data;
    this.cargoPos.forEach((k) => {
      data[`${k}ChartPoint`] = this.getChartPointPos(k);
      data[`${k}ChartLine`] = this.getChartLinePoints(k);
      data[`${k}CargoY`] = this.getCargoY(k);
    })
  }

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['prevBtn'].visible(data.start);
    n['playBtn'].visible(!data.play);
    n['stopBtn'].visible(data.play);
    n['nextBtn'].visible(data.start);
    n['playHintArrow'].visible(!data.start);

    this.cargoPos.forEach((k) => {
      n[`${k}Cargo`].y(-data[`${k}CargoY`]);
      n[`${k}ChartPoint`].position(data[`${k}ChartPoint`]);
      n[`${k}ChartLine`].points(data[`${k}ChartLine`]);


      if (n[`${k}CargoCableFirst`]) {
        n[`${k}CargoCableFirst`].points([0, 0, 0, 250-data[`${k}CargoY`]])
      }
      if (n[`${k}CargoCableSecond`]) {
        n[`${k}CargoCableSecond`].points([0, 0, 0, 250-data[`${k}CargoY`]])
      }

      if (n[`${k}CargoCable`]) {
        n[`${k}CargoCable`].points([0, 0, 0, 240-data[`${k}CargoY`]]);
      }
    });


    n['stage'].draw();
  }


  onClickPrevBtn = () => {
    const newTime = Math.floor(this.data.timeFromPlaySec)-1;
    this.data.timeFromPlaySec = newTime <= 0 ? 0 : newTime;
    this.onClickStopBtn();
  }
  onClickPlayBtn = () => {
    this.data.play = true;
    this.data.start = true;
  }
  onClickStopBtn = () => {
    this.data.play = false;
    this.updateCargoData();
  }
  onClickNextBtn = () => {
    const newTime = Math.floor(this.data.timeFromPlaySec)+1;
    this.data.timeFromPlaySec = newTime >= this.staticData.maxTime ? this.staticData.maxTime : newTime;
    this.onClickStopBtn();
  }


  Canvas = () => {

    const [background] = useImage(backgroundImg);
    const [hook] = useImage(hookImg);
    const [cargo] = useImage(cargoImg);

    return (
      <React.Fragment>
        <Group>
          <Image image={background}/>

          <Player
            x={250} y={50}

            prevBtnRef={this._ref('prevBtn')}
            playBtnRef={this._ref('playBtn')}
            stopBtnRef={this._ref('stopBtn')}
            nextBtnRef={this._ref('nextBtn')}

            onClickPrevBtn={this.onClickPrevBtn}
            onClickPlayBtn={this.onClickPlayBtn}
            onClickStopBtn={this.onClickStopBtn}
            onClickNextBtn={this.onClickNextBtn}
          />

          <Group x={175} y={493}>
            <Group>
              <Circle radius={3} fill={layout2.green} ref={this._ref('leftChartPoint')}/>
              <Line points={[0,0,100,-40]} stroke={layout2.green} ref={this._ref('leftChartLine')}/>
            </Group>

            <Group>
              <Circle radius={3} fill={layout2.orange} ref={this._ref('rightChartPoint')}/>
              <Line points={[0,0,100,-40]} stroke={layout2.orange} ref={this._ref('rightChartLine')}/>
            </Group>
          </Group>


          <Group x={620}>
            <Group>
              <Line x={50} y={200} points={[0,0,0,240]} stroke={'black'} strokeWidth={1} ref={this._ref('leftCargoCable')}/>

              <Group y={435}>
                <Group ref={this._ref('leftCargo')}>
                  <Image x={-2.5} image={cargo}/>
                  <Line
                    x={80} y={58}
                    points={[0,0,-530,0]}
                    dash={[7, 3]}
                    stroke={layout2.green}
                  />
                </Group>
              </Group>
            </Group>

            <Group x={220}>
              <Group x={47} y={165}>
                <Line points={[0,0,0,250]} stroke={'black'} strokeWidth={1} ref={this._ref('rightCargoCableFirst')}/>
                <Line x={11} points={[0,0,0,250]} stroke={'black'} strokeWidth={1} ref={this._ref('rightCargoCableSecond')}/>
              </Group>

              <Group y={435}>
                <Group ref={this._ref('rightCargo')}>
                  <Image y={-20} x={39} image={hook}/>
                  <Image image={cargo}/>
                  <Line
                    x={82} y={58}
                    points={[0,0,-750,0]}
                    dash={[7, 3]}
                    stroke={layout2.orange}
                  />
                </Group>
              </Group>
            </Group>
          </Group>


          <CanvasResetBtn
            ref={this._ref('resetBtn')}
            onClick={() => this.onClickReset()}
          />

          <MovingArrow
            id={'startHint'}
            ref={this._ref('playHintArrow')}
            stageNode={this._getNode('stage')}
            length={40}
            arrowsStep={40}
            x={300} y={150}
            arrowColor={'black'}
            text={' '}
            rotation={180}
            getMovingCallback={this.getMovingCallback}
          />
        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimeDependenceOfWork);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
