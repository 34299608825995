import React, {useEffect, useState} from "react";
import '../styles/farm-map-auth.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import {maps} from "../utils";
import {Button, Input} from "antd";
import {useQuery} from "../../../../utils/custom-hooks/useQuery";
import farmMapAuthBg from '../images/farm-map-bg.png';
import schoolMap2AuthBg from '../images/school_task_auth_bg.png';
import greeceBlocksMapAuthBg from '../images/greece-blocks-mp-bg.png';
import farm4MapAuthBg from '../images/farm_4_auth_bg.png';
import farm5MapAuthBg from '../images/farm_5_auth_bg.png';

const getCorrBg = (mapKey) => {
  switch (mapKey) {
    case maps.farmMap.key:
      return farmMapAuthBg;
    case maps.schoolMap2.key:
      return schoolMap2AuthBg;
    case maps.greeceBlocks.key:
      return greeceBlocksMapAuthBg;
    case maps.farm_4_map.key:
      return farm4MapAuthBg;
    case maps.farm_5_map.key:
      return farm5MapAuthBg;

    default:
      return farmMapAuthBg;
  }
}

const FarmMapAuth = (props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const mapKey = useQuery().get('map_key') || 'farm_map';
  const correctMapConfig = Object.values(maps).find(item => item.key === mapKey);

  useEffect(() => {
    props.logoutAction();
  }, [])

  const onClick = async () => {
    setLoading(true);
    await props.courseMapAuth(name, correctMapConfig.key);
    await props.getOrCreateMapProgress(true, correctMapConfig.key);
    props.history.push(correctMapConfig.locationKey);
    setLoading(false);
  }
  const correctBg = getCorrBg(mapKey);
  return (
    <div className={'farm-map-auth'}>
      <div className={'farm-map-auth__bg'} style={{background: `url(${correctBg}) no-repeat center`}}/>

      <div className={'farm-map-auth__modal'}>
        <h2>Введите ваше имя</h2>
        <Input placeholder={'Ваше имя'} type={'text'} value={name} onChange={(e) => {
          setName(e.target.value);
        }}/>
        <Button type={'primary'} size={'large'} loading={loading} disabled={!name} onClick={onClick}>
          Войти
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return ({
    coursePaid: state.mapReducer.coursePaid,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({
  getOrCreateMapProgress: (initial, mapKey) => dispatch(actions.getOrCreateMapProgress(initial, mapKey)),
  courseMapAuth: (name, mapKey) => dispatch(actions.courseMapAuth(name, mapKey)),
  logoutAction: () => dispatch(actions.logoutAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FarmMapAuth);
