import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import {TaskListWrapper} from "../components/Wrappers";
import LessonItem from "../components/LessonItem";
import '../styles/layout.css';
import {SubscriptionModal} from "../components/SubscriptionModal";
import MainContainer from "../components/MainContainer";
import {userRoles} from "../../../../utils/common";
import {layout2} from "../../../../utils/styles";
import TeacherCatalogItem from "../components/TeacherCatalogItem";


const TeacherLessons = (props) => {
  const {
    user,
    selectedLesson,
    setCatalogLesson,
  } = props;

  const [loading, setLoading] = useState(false);

  const [showPremium, setShowPremium] = useState(false);

  const defCatalogState = {lessons: [], chapters: [], lessonsParagraphs: []};
  const [{ lessons, chapters, lessonsParagraphs }, setCatalogState] = useState(defCatalogState);


  useEffect(() => {
      fetchLessons(true);
  }, []);


  const fetchLessons = async () => {
    const lessonsResp = await props.fetchTeacherLessons();
    const lessons = lessonsResp?.results || [];
    setCatalogState(prev => ({...prev, lessons: lessons}));
    return lessons
  };


  const newCardStyle = !props.teacherCardsCatalog;

  const isTeacher = user && user.role === userRoles.teacher;
  const lessonsEmpty = !lessons || !lessons.length;
  return (
    <div className={`layout2 ${newCardStyle && 'lessons--new-grid'}`} id={'layout2'}>
      <MainContainer
        withDescription={!props.teacherCardsCatalog}
        pageKey={props.teacherCardsCatalog ? 'teacher-card' : 'tasks'}
      >
        <TaskListWrapper loading={loading}>
          <div className={ 'lessonsContainer' }>
            {
              !lessonsEmpty ?
                lessons?.map((l, i) => (
                  <React.Fragment key={ `lessonItem-${ i }` }>
                    {
                      props.teacherCardsCatalog ? (
                        <TeacherCatalogItem
                          lessonItem={ l }
                        />
                      ) : (
                        <LessonItem
                          newCardStyle={ newCardStyle }
                          lessonItem={ l }
                          selectedLesson={ selectedLesson }
                          setSelectedLesson={ setCatalogLesson }
                          setShowPremium={ setShowPremium }
                          user={ user }
                          teacherPage={ isTeacher }
                        />
                      )
                    }
                  </React.Fragment>
                ))
                :
                <span style={ { color: 'gray' } }>--- Нет заданий этой категории ---</span>
            }
          </div>
        </TaskListWrapper>
      </MainContainer>
      <SubscriptionModal showPremium={showPremium} setShowPremium={setShowPremium}/>
    </div>
  )
};


const mapStateToProps = (state, {match}) => {
  return {
    lessons: state.lessonsReducer.lessons,
    user: state.lessonsReducer.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHomework: () => dispatch(actions.fetchHomework(true)),
    setLanguage: (lang) => dispatch(actions.setLanguage(lang)),
    logoutAction: () => dispatch(actions.logoutAction()),
    fetchHwTeacherNotifications: () => dispatch(actions.fetchHwTeacherNotifications()),
    fetchTeacherLessons: (props) => dispatch(actions.fetchTeacherLessons(props)),
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeacherLessons);
