import React, { useEffect } from "react";
import {Image} from "react-konva";
import "gifler";
import {height} from "../containers/CanvasContainer";


export const GIF = ({ src, width, height, x, y, loop = false, scale, scaleX, scaleY, visible, reffer, opacity, rotation}) => {
  const imageRef = React.useRef(null);
  const canvas = React.useMemo(() => {
    const node = document.createElement("canvas");
    return node;
  }, []);

  React.useEffect(() => {
    // save animation instance to stop it on unmount
    let finish;
    let anim;
    window.gifler(src).get(a => {
      anim = a;
      anim.animateInCanvas(canvas);
      anim.onDrawFrame = (ctx, frame, frameIndex) => {
        if (!finish)
          if (imageRef.current) {
            ctx.drawImage(frame.buffer, frame.x, frame.y);
            imageRef.current.getLayer().draw();
          }
        else
          anim.stop();

        if ((anim._frames.length <= frameIndex + 1) && !loop)
          finish = true
      };

    });
    if (anim)
      return () => anim.stop();
  }, [src, canvas]);

  useEffect(() => {
    if (reffer !== undefined) reffer.current = imageRef.current;
  }, [imageRef.current, reffer]);

  return (
    <Image
      image={canvas}
      x={x}
      y={y}
      ref={imageRef}
      rotation={rotation}
      scaleX={scale || scaleX}
      scaleY={scale || scaleY}
      visible={visible}
      opacity={opacity}
      width={width}
      height={height}
    />
  );
};
