import React, {useEffect, useState} from "react";
import SchoolTaskContainer from "../components/SchoolTaskContainer";
import bgImg from '../images/bg.png';
import SchoolTaskCard from "../components/SchoolTaskCard";
import img1 from '../images/сhooseExtraItemTask/1.png';
import img2 from '../images/сhooseExtraItemTask/2.png';
import img3 from '../images/сhooseExtraItemTask/3.png';
import img4 from '../images/сhooseExtraItemTask/4.png';
import '../styles/chooseExtraItemTask.css';


const defaultItems = [
  {id: 1, image: img1},
  {id: 2, image: img2},
  {id: 3, image: img3, success: true},
  {id: 4, image: img4},
]


const ChooseExtraItemTask = (props) => {
  const [answer, setAnswer] = useState(undefined);

  const items = props.items || defaultItems;

  const taskComplete = () => {
    props.taskComplete && props.taskComplete();
  }

  const answerFail = answer !== undefined && !(answer.success || answer.option_success);
  const answerSuccess = answer !== undefined && (answer.success || answer.option_success);

  return (
    <SchoolTaskContainer
      failMessage={answerFail}
      lang={props.lang}
      bgImg={props.bgImg || bgImg}
      title={props.title || ''}
      titleFontSize={props.titleFontSize}
      tryAgain={answerFail}
      success={answerSuccess}
      continueClassName={'choose-item-task__btn-continue'}
      onClickRestart={() => {
        setAnswer(undefined);
      }}
      onClickContinue={taskComplete}
    >
      <div className={'choose-item-task__items'}>
        {
          items.map((el, i) => {
            const success = answer?.id === el.id && (answer.success || answer.option_success); // обратная совместимость с хардкодом, с сервера приходит option_success
            const fail = answer !== undefined && answer?.id === el.id && !(answer.success || answer.option_success);
            return (
              <SchoolTaskCard
                index={i}
                key={el.id}
                img={el.image}
                mainText={el.mainText}
                text={el.text || el.option_text}
                success={success}
                fail={fail}
                className={`choose-item-task__item ${!answer ? 'choose-item-task__item--hover':''}`}
                onClick={() => {
                  if (!answer) {
                    setAnswer(el);
                  }
                }}
              />
            )
          })
        }
      </div>
    </SchoolTaskContainer>
  )
}
export default ChooseExtraItemTask;
