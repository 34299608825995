import axios from 'axios';

let serverBaseUrl = 'http://localhost:8000/api/';
try {
  const configModule =  require('./serverBaseUrl');
  serverBaseUrl = configModule.serverBaseUrl;
} catch (e) {
  console.warn('No config file', e)
}

export const serverMediaUrl = `${serverBaseUrl}media/`;

export const descriptionUrl = (id) => `https://app.inventic.tech/static-descriptions/${id}/${id}.html`;

export const redirectToLogin = (nextPathname) => {
  const next = nextPathname || window.location.pathname.replace('/', '');
  window.location = `/login${next ? '?next='+next : ''}`;
}

export const generateGetParamsByObj = (paramsObj, withoutQuestionMark) => {
  let params = '';
  Object
    .keys(paramsObj)
    .filter(k => paramsObj[k])
    .map((k, i) => {
      params += `${!i && !withoutQuestionMark ? '?' : '&'}${k}=${paramsObj[k]}`;
    })
  return params;
}

export const redirectToRegistration = ({mode}) => {
  const next = window.location.pathname.replace('/', '');
  let params = '';
  if (mode) {
    params+=`mode=${mode}`;
  }
  if (next) {
    params+=`${mode ? '&' : ''}next=${next}`;
  }
  window.location = `/registration${params ? '?'+ params : ''}`;
}

export const setAxiosSettings = (store) => {
  axios.defaults.baseURL = serverBaseUrl;
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  axios.defaults.withCredentials = true;
  axios.defaults.headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-type': 'application/json; charset=UTF-8',
  };

  // logger to debug API in console
  axios.interceptors.request.use(
    function (config) {
      console.log(
        'REQUEST WILL SEND TO : ',
        config.url,
        config.baseURL,
        '. Method: ',
        config.method,
        '. Params: ',
        config.data
      );
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      console.log(
        'RESPONSE SUCCESS! URL: ',
        response.request.responseURL,
        '. Status: ',
        response.status,
        '. StatusText: ',
        response.statusText,
        '. Data: ',
        response.data
      );

      return response.data; // return data because actual response is contained in data field
    },
    function (error) {
      if (error && error.request && error.request.responseURL) {
        console.log(
          'Response error URL: ',
          error.request.responseURL,
          '\nFull error: ',
          error
        );

        if (error.request.status === 401 && !['/', '/layout2'].includes(window.location.pathname)) {
          // store.dispatch(setLoggedUser(false)); // for HOC functionality
          redirectToLogin();
        }


        return Promise.reject(error);
      }
    }
  );
};
