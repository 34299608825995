import {Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";
import React from "react";


const VariantHelper = ({variantsCount, setLessonVariant, activeLessonVariant}) => {
  const menu = (
    <Menu selectedKeys={[`select-variant-${activeLessonVariant}`]}>
      {
        Array(variantsCount).fill(1).map((el, i) => {
          const variant = i+1;
          return (
            <Menu.Item key={'select-variant-'+variant} onClick={() => {
              setLessonVariant(variant)
            }}>
              Вариант {variant}
            </Menu.Item>
          )
        })
      }
    </Menu>
  )
  return (
    <div
      style={{
        position: 'absolute', zIndex: 10, left: '3.3%', top: '60px', width: '100%',
      }}>
      <div style={{width: '200px'}}>
        <Dropdown overlay={menu} trigger={'click'}>
          <a style={{cursor: 'pointer', fontSize: '20px'}} href={'#'} onClick={(e) => e.preventDefault()}>
            Выбор варианта <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
  )
}

export default VariantHelper;