import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import backgroundImg from '../../../../images/pathandspeed/train/background.png';
import timerImg from '../../../../images/pathandspeed/train/timer.png';
import trainImg from '../../../../images/pathandspeed/train/train.png';
import train2Img from '../../../../images/pathandspeed/train/train2.png';
import trainSpeedImg from '../../../../images/pathandspeed/train/trainSpeed.png';
import arrowGreyImg from '../../../../images/pathandspeed/train/arrowGrey.png';
import {layout2, lsnLabelTxtStyle, lsnTitleTxtStyle, mainColor} from "../../../../utils/styles";
import {getCorrectTitle} from "../utils/trainUtils2";
import Card from "../../../canvas/components/Card";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {
  generateArray,
  getOpacityForNode, sendSuccessForScenario,
  showFailOrSuccessPopup,
  toFixed,
  toggleVisibleEl
} from "../../../../utils/common";
import * as actions from "../../../../store/actions";
import ScenarioManager from "../../../../utils/ScenarioManager";


class Train2 extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();

    this.inputCardRef = React.createRef();
    this.trainRef = React.createRef();

    this.titleStep0Ref = React.createRef();
    this.titleStep1Ref = React.createRef();

    this.timerRef = React.createRef();
    this.timerBtnRef = React.createRef();
    this.speedRef = React.createRef();
    this.arrowsGreyRef = React.createRef();
    this.btnStartRef = React.createRef();

    this.staticData = {
      stepsCount: 2,
      trainCount: 15,
      maxTrainOffset: 1900
    };
    this.state = {};

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      step: 0,

      inputTrainSpeed: undefined,

      trainStart: false,
      timerStart: false,

      trainOffset: 0,
      trainSize: 0,

      speed: undefined,

      timerStartTime: null,
      timer: 0,

      trainPoint: 0,
      coordinateArrowGrey: 310,
      changeCoordinateArrowGrey: 1,
      opacityOfArrows: 1,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  get trainOffset() {
    const {maxTrainOffset} = this.staticData;
    const data = this.data;
    if (!data.trainStart) { return -200 }

    return data.trainOffset < maxTrainOffset ? data.trainOffset+=.75 : maxTrainOffset;
  }

  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
    this.setState({reset: (this.state.reset+1)||0})
  };

  getNodes = (stageNode) => {
    const titles = generateArray(this.staticData.stepsCount).reduce((accum, el, i) => {
      accum[`titleStep${i}Node`] = this[`titleStep${i}Ref`]?.current;
      return accum
    }, {})
    return ({
      ...titles,
      inputCardNode: this.inputCardRef?.current,
      trainNode: this.trainRef?.current,
      timerBtnNode: this.timerBtnRef?.current,
      timerNode: this.timerRef?.current,
      speedNode: this.speedRef?.current,
      arrowsGreyNode: this.arrowsGreyRef?.current,
      btnStartNode: this.btnStartRef?.current,
    });
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;


    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);


    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;

    data.trainOffset = this.trainOffset;
    // console.log(this.trainOffset);
    if(this.trainOffset >= 15 && this.data.trainPoint === 0){
      this.timerController()
      this.data.trainPoint = 1;
    }
    if(this.trainOffset >= 660 && this.data.trainPoint === 1){
      this.timerController()
      this.data.trainPoint = 2;
    }
    if(this.data.coordinateArrowGrey <= 320 && this.data.changeCoordinateArrowGrey === 1){
      this.data.coordinateArrowGrey = this.data.coordinateArrowGrey + 0.5;
      if(this.data.coordinateArrowGrey === 320) {
        this.data.changeCoordinateArrowGrey = 0;
      }
    }
    if(this.data.coordinateArrowGrey >= 300 && this.data.changeCoordinateArrowGrey === 0){
      this.data.coordinateArrowGrey = this.data.coordinateArrowGrey - 0.5;
      if(this.data.coordinateArrowGrey === 300) {
        this.data.changeCoordinateArrowGrey = 1;
      }
    }


    if (data.timerStart) {
      data.timer = Math.abs(toFixed((data.timerStartTime - data.prevTime)/1000, 0));
    }

    this.checkTrainStep();
    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const {
      inputCardNode,
      trainNode,
      timerNode,
      timerBtnNode,
      speedNode,
      titles
    } = this.getNodes(stageNode);
    const nodes = this.getNodes(stageNode);

    const titleItems = generateArray(this.staticData.stepsCount);
    titleItems.forEach((el, i) => {
      const node = nodes[`titleStep${i}Node`];
      const title = getCorrectTitle(i);
      node.text(title);
      toggleVisibleEl(node, data.step !== i);
    })

    toggleVisibleEl(inputCardNode, data.step < 1);

    timerNode && timerNode.text(data.timer);
    trainNode && trainNode.offsetX(-data.trainOffset);

    nodes.arrowsGreyNode.y(this.data.coordinateArrowGrey);
    nodes.arrowsGreyNode.opacity(this.data.opacityOfArrows);
    nodes.btnStartNode.opacity(this.data.opacityOfArrows);
    if(this.data.trainStart === true){this.data.opacityOfArrows = 0;}


    stageNode.draw();
  }

  onChangeInput = (val) => {
    this.data.inputTrainSpeed = toFixed(Number(val), 0);
  }
  chekResult = () => {
    const success = Number(this.data.inputTrainSpeed) === Math.round(400 / this.data.timer) || Number(this.data.inputTrainSpeed) === Math.ceil(400 / this.data.timer);
    showFailOrSuccessPopup(this, success);
    if (success) {
      sendSuccessForScenario(this);
    }
  }

  checkTrainStep = () => {
    const data = this.data;
    const cardVisible = !data.timerStart && data.timerStartTime && data.trainStart;
    if (cardVisible) {
      this.data.step = 1;
    }
    if (this.data.trainOffset >= this.staticData.maxTrainOffset) {
      this.data.trainOffset = -200;
      this.data.trainStart = false;
    }
  }
  startForTrain = () => {
    this.data.trainStart = true;
  }
  timerController = () => {
    if (!this.data.timerStart && this.data.timerStartTime !== null) {
      this.resetTimer();
    } else if (this.data.timerStart) {
      this.stopTimer();
    } else {
      this.startForTimer();
    }
  }
  startForTimer = () => {
    this.data.timerStart = true;
    this.data.timerStartTime = this.data.prevTime;
  }
  stopTimer = () => {
    this.data.timerStart = false;
  }
  resetTimer = () => {
    this.data.timerStart = false;
    this.data.timerStartTime = null;
    this.data.timer = 0;
  }

  Canvas = () => {

    const [background] = useImage(backgroundImg);
    const [timer] = useImage(timerImg);
    const [train] = useImage(trainImg);
    const [train2] = useImage(train2Img);
    const [arrowGrey] = useImage(arrowGreyImg);
    const [trainSpeed] = useImage(trainSpeedImg);


    const titleItems = generateArray(this.staticData.stepsCount);
    const trainItems = generateArray(this.staticData.trainCount);
    return (
      <React.Fragment>

        <Image image={background} />
        {
          titleItems.map((el, i) => (
            <Text
              key={el} ref={this[`titleStep${i}Ref`]}
              text={''} {...lsnTitleTxtStyle}
              opacity={Number(this.data.step === i)}
            />
          ))
        }

        <Group x={600} y={180}>
          <Group key={'1'} x={-250} y={60}>
            <Image image={timer} />
            <Text
              ref={this.timerRef}
              width={145}
              height={70}
              x={17} y={15}
              text={'0'}
              align={'center'}
              verticalAlign={'middle'}
              {...lsnLabelTxtStyle}
              fontStyle={'bold'}
              fontSize={45}
            />
            <Group
                y={310}
                width={74}
                ref={this.arrowsGreyRef}
            >
              <Image image={arrowGrey} x={-210} y={-330} />
              <Image image={arrowGrey} x={-250} y={-330} />

            </Group>
          </Group>
          <Group key={'2'} x={200}>
            <Group ref={this.btnStartRef}>
            <CanvasButton
              text={'Запустить \nпоезд'}
              onClick={() => this.startForTrain()}
              fontSize={20}
              strokeWidth={.2}
              btnCornerRadius={0}
              width={150}
              height={60}
              y={90}
              x={-750}
            />
            </Group>
          </Group>
        </Group>


        <Group ref={this.inputCardRef} x={580} y={180} opacity={0}>
          {/*<Card width={450} height={70} x={-50} y={40}>*/}
            <Group  x={0} y={40}>
              {/*<Text*/}
              {/*  text={'Скорость поезда = '}*/}
              {/*  {...lsnLabelTxtStyle}*/}
              {/*  fontSize={30}*/}
              {/*  x={10}*/}
              {/*/>*/}

              {/*<Text*/}
              {/*  x={370}*/}
              {/*  text={'(м/с)'}*/}
              {/*  {...lsnLabelTxtStyle}*/}
              {/*  fontSize={30}*/}
              {/*/>*/}
              <Image image={trainSpeed}/>
              <CanvasInput
                  id={'1'}
                  x={223}
                  y={27}
                  width={60}
                  height={40}
                  fontSize={30}
                  stage={this.stageRef?.current}
                  onInput={(val) => {
                    this.onChangeInput(val);
                  }}
                  value={this.data.inputTrainSpeed}
              />
            </Group>
          {/*</Card>*/}
          <CanvasButton
            text={'Проверить'}
            onClick={() => this.chekResult()}
            fontSize={20}
            strokeWidth={.2}
            btnCornerRadius={0}
            width={150}
            height={35}
            y={130} x={115}
          />
        </Group>

        <Group ref={this.trainRef} x={40} y={430}>
          {/*{*/}
          {/*  trainItems.map((el, i) => {*/}
          {/*    const firstItem = !i;*/}
          {/*    const correctImg = firstItem ? train : train2;*/}
          {/*    return (*/}
          {/*      <Image key={el} image={correctImg} x={-60*i}/>*/}
          {/*    )*/}
          {/*  })*/}
          {/*}*/}
          <Image image={train}/>
        </Group>

        <CanvasResetBtn
          x={853}
          onClick={() => this.onClickReset()}
        />
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Train2);

const styles = {
  mainContainer: {
    background: 'white'
  }
};
