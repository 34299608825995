import React from "react";
import CanvasContainer, {height} from "../../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, RegularPolygon, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import {layout2, mainColor} from "../../../../../utils/styles";
import roadImg from "../../../../../images/brakingDistances/road.png";
import item1Img from "../../../../../images/brakingDistances/1.png";
import item2Img from "../../../../../images/brakingDistances/2.png";
import item3Img from "../../../../../images/brakingDistances/3.png";
import autoImg from "../../../../../images/brakingDistances/auto.png";
import cargoImg from "../../../../../images/brakingDistances/cargo.png";
import backgroundImg from "../../../../../images/brakingDistances/background.png";

import formulasImg from "../../../../../images/brakingDistances/formulas.png";
import btnUpImg from "../../../../../images/brakingDistances/btnUp.png";
import btnDownImg from "../../../../../images/brakingDistances/btnDown.png";
import wheelImg from "../../../../../images/brakingDistances/wheel.png";

import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import {getTimeDeltaSec, hasIntersection, sendSuccessForScenario} from "../../../../../utils/common";
import CanvasButton from "../../../../canvas/components/CanvasButton";
import * as actions from "../../../../../store/actions";
import ScenarioManager from "../../../../../utils/ScenarioManager";



class BrakingDistances extends React.Component {
  constructor(props) {
    super(props);

    this.wheelis = Array(5).fill(1).map((el, i) => i+1);
    this.roadItems = Array(40).fill(1).map((el, i) => i+1);
    this.mainLsnItems = Array(3).fill(1).map((el, i) => i+1);
    this.cargoItems = Array(5).fill(1).map((el, i) => i+1);

    this.managedComponents = [
      'stage',
      'stopSignal',
      'startBtn',
      'checkPoint',
      'brakePressedPoint',
    ];

    this.mainLsnItems.forEach((id, i) => {
      this.managedComponents.push(`road-${id}`);
      this.managedComponents.push(`roadContainer-${id}`);
      this.managedComponents.push(`car-${id}`);

      this.managedComponents.push(`car-${id}-mass`);
      this.managedComponents.push(`car-${id}-speed`);

      this.managedComponents.push(`car-${id}-box`);
      this.managedComponents.push(`car-${id}-brakingDistances`);

      this.managedComponents.push(`car-${id}-vectorSpeed`);
      this.managedComponents.push(`car-${id}-vectorSpeedText`);
      this.managedComponents.push(`car-${id}-controllers`);

      this.wheelis.forEach((wheelId) => {
        this.managedComponents.push(`car-${id}-wheel-${wheelId}`);
      })

      this.cargoItems.map((cargoId, i) => {
        this.managedComponents.push(`car-${id}-cargo-${cargoId}`)
      })
    });

    this.staticData = {
      maxCargoItems: 5,
      maxCarSpeed: 60,
      speedStep: 20,
      cargoWeight: 4, // т
      carWeight: 4, // т
    }

    this.carModel = {
      cargoItems: 1,
      speedTxt: 20,
      speed: 20,
      offsetRoadX: 0,
      carX: 0,
      brakePressed: false,
      brakingDistances: 0,
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timeDeltaSec: 0,

      startOffset: false,
      start: false,

      car1: {...this.carModel},
      car2: {...this.carModel},
      car3: {...this.carModel},
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 8000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };
  onClickStart = () => {
    this.data.start = true;
  };

  mToPx = (meters) => meters * 5;
  tToKg = (ton) => ton * 1000;

  getCarX = (id) => {
    const data = this.data;
    const car = data[`car${id}`];
    return car.carX+(data.timeDeltaSec * car.speed);
  };

  getCarSpeed = (id) => {
    const data = this.data;
    const car = data[`car${id}`];
    let speed = car.speed;
    if (car.brakePressed) {
      // рассчет с учетом уменьшения кинетической энергии
      const mass = (car.cargoItems * this.staticData.cargoWeight) + this.staticData.carWeight;
      const energy = mass * Math.pow(speed, 2) / 2;

      let updEnergy = energy - 70 * data.timeDeltaSec * speed; // тормоза поедают какое-то количество энергии в секунду
      if (updEnergy < 0) {
        speed = 0
      } else {
        speed = Math.sqrt(updEnergy * 2 / mass);
      }
    }
    if (car.brakePressed && speed <= 0) {
      speed = 0;
    }
    return speed;
  }

  getRoadOffsetX = (id) => {
    const data = this.data;
    const car = data[`car${id}`];
    return car.offsetRoadX < 16 ? car.offsetRoadX+(data.timeDeltaSec * car.speed) : 0;
  }

  onClickMassBtn = (direction, itemId) => {
    const staticData = this.staticData;
    const data = this.data;
    const corrCar = data[`car${itemId}`];

    if (direction === 'up') {
      const cargoItemsCount = corrCar.cargoItems;
      corrCar.cargoItems = cargoItemsCount >= staticData.maxCargoItems ? staticData.maxCargoItems : cargoItemsCount+1;
    }
    if (direction === 'down') {
      const cargoItemsCount = corrCar.cargoItems;
      corrCar.cargoItems = cargoItemsCount <= 0 ? 0 : cargoItemsCount-1;
    }
  };

  onClickSpeedBtn = (direction, itemId) => {
    const data = this.data;
    const staticData = this.staticData;
    const corrCar = data[`car${itemId}`];

    if (direction === 'up') {
      corrCar.speed = corrCar.speed >= staticData.maxCarSpeed ? staticData.maxCarSpeed : corrCar.speed + staticData.speedStep;
    }
    if (direction === 'down') {
      corrCar.speed = corrCar.speed <= 0 ? 0 : corrCar.speed - staticData.speedStep;
    }

    corrCar.speedTxt = corrCar.speed;
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this._getNode('stage');
    if (!stageNode) return;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timeDeltaSec = getTimeDeltaSec(timedelta);

    if (data.start) {
      let intersectionBox = false;
      this.mainLsnItems.forEach((id, i) => {
        const car = data[`car${id}`];
        const stopLineIntersect = hasIntersection(n[`car-${id}-box`], n['brakePressedPoint'], this);
        intersectionBox += hasIntersection(n[`car-${id}-box`], n['checkPoint'], this);
        car.offsetRoadX = this.getRoadOffsetX(id);

        car.speed = this.getCarSpeed(id);
        car.carX = this.getCarX(id);

        if (stopLineIntersect || data[`car${id}`].brakePressed) {
          car.brakePressed = true;
          car.brakingDistances += data.timeDeltaSec * car.speed;
        }
      });

      if (intersectionBox) {
        data.startOffset = true;
      }
    }

    this.updateStage();
  };

  updateStage() {
    const staticData = this.staticData;
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    n[`stopSignal`].visible(Boolean(data.startOffset));
    n[`brakePressedPoint`].visible(Boolean(data.startOffset));
    n[`startBtn`].visible(!data.start);

    this.mainLsnItems.forEach((id, i) => {
      const carData = data[`car${id}`];
      const carKey = `car-${id}`;

      n[`roadContainer-${id}`].offsetX(data.startOffset ? 1000 : 0);

      // n[`road-${id}`].offsetX(this.mToPx(carData.offsetRoadX));
      n[carKey].x(this.mToPx(carData.carX));

      const mass = (carData.cargoItems*staticData.cargoWeight) + staticData.carWeight;
      n[`${carKey}-mass`].text(this.tToKg(mass));
      n[`${carKey}-speed`].text(Math.round(carData.speedTxt));
      n[`${carKey}-brakingDistances`].text(`${Math.round(carData.brakingDistances)} м`);
      n[`${carKey}-brakingDistances`].visible(Boolean(carData.brakePressed));

      n[`${carKey}-vectorSpeed`].points([0,0,carData.speed*2,0]);
      n[`${carKey}-vectorSpeedText`].x(carData.speed*.8);

      n[`${carKey}-vectorSpeedText`].visible(Boolean(carData.speed));
      n[`${carKey}-controllers`].visible(!data.start);

      if (data.start && !carData.brakePressed) {
        this.wheelis.forEach((wheelId) => {
          n[`${carKey}-wheel-${wheelId}`] && n[`${carKey}-wheel-${wheelId}`].rotate(carData.speed);
        })
      }

      this.cargoItems.map((cargoId, i) => {
        n[`${carKey}-cargo-${cargoId}`].visible(i < carData.cargoItems);
      })
    })


    n['stage'].draw();
  }

  Canvas = () => {

    const [road] = useImage(roadImg);
    const [item1] = useImage(item1Img);
    const [item2] = useImage(item2Img);
    const [item3] = useImage(item3Img);
    const [auto] = useImage(autoImg);
    const [cargo] = useImage(cargoImg);
    const [background] = useImage(backgroundImg);

    const [formulas] = useImage(formulasImg);
    const [btnUp] = useImage(btnUpImg);
    const [btnDown] = useImage(btnDownImg);
    const [wheel] = useImage(wheelImg);

    const itemsImgs = [item1, item2, item3];

    return (
      <React.Fragment>
        <Group>
          <Image image={background}/>

          <Image x={50} y={20} image={formulas}/>

          <Group ref={this._ref('startBtn')}>
            <CanvasButton
              text={'Проверить \nтормозной путь'}
              onClick={() => {this.onClickStart()}}
              fontSize={23}
              strokeWidth={.2}
              btnCornerRadius={0}
              btnFill={layout2.orange}
              width={220}
              height={80}
              x={380} y={270}
            />

            <Text
              y={360}
              x={150}
              width={680} align={'center'} text={'12'}
              fontSize={16} fill={'white'}
              text={"Считаем, что водители нажимают на педаль тормоза одинаково, и грузовики не теряют сцепления с дорогой, то есть сила торможения для всех грузовиков одинакова."}
            />
          </Group>

          <Group x={50} y={360} ref={this._ref('stopSignal')}>
            <Line y={10} points={[0,0,0,40]} stroke={'white'}/>
            <Group rotation={22}>
              <RegularPolygon sides={8} fill={'white'} radius={17}/>
              <RegularPolygon sides={8} fill={'red'} radius={15}/>
            </Group>
            <Text
              x={-12} y={-3.5}
              text={'STOP'}
              fontSize={9}
              fontStyle={'bold'}
              fill={'white'}
            />
          </Group>


          <Group y={400}>
            {
              this.mainLsnItems.map((id, i) => {
                const btnSize = 25;
                return (
                  <Group key={`roadItem-${id}`}>
                    <Group x={150} y={-287}>
                      <Group x={i*303}>
                        <Group>
                          <Group x={0} y={0}>
                            <Text
                              x={-5}
                              ref={this._ref(`car-${id}-mass`)}
                              width={80} align={'center'} text={'12'}
                              fontSize={23} fill={mainColor}/>
                          </Group>
                          <Group x={0} y={90}>
                            <Text
                              ref={this._ref(`car-${id}-speed`)}
                              width={30} align={'center'} text={'12'}
                              fontSize={22} fill={mainColor}/>
                          </Group>
                        </Group>
                      </Group>
                    </Group>
                    <Group x={270} y={-305} ref={this._ref(`car-${id}-controllers`)}>
                      <Group x={i*305}>
                        <Group x={0} y={0}>
                          <Image
                            onClick={() => this.onClickMassBtn('up', id)}
                            onTap={() => this.onClickMassBtn('up', id)}
                            width={btnSize} height={btnSize}
                            image={btnUp}/>
                          <Image
                            onClick={() => this.onClickMassBtn('down', id)}
                            onTap={() => this.onClickMassBtn('down', id)}
                            width={btnSize} height={btnSize}
                            y={btnSize+5} image={btnDown}/>
                        </Group>
                        <Group x={0} y={90}>
                          <Image
                            onClick={() => this.onClickSpeedBtn('up', id)}
                            onTap={() => this.onClickSpeedBtn('up', id)}
                            width={btnSize} height={btnSize}
                            image={btnUp}/>
                          <Image
                            onClick={() => this.onClickSpeedBtn('down', id)}
                            onTap={() => this.onClickSpeedBtn('down', id)}
                            width={btnSize} height={btnSize}
                            y={btnSize+5} image={btnDown}/>
                        </Group>
                      </Group>
                    </Group>

                    <Group y={i*50} ref={this._ref(`roadContainer-${id}`)}>
                      <Group ref={this._ref(`road-${id}`)}>
                        {
                          this.roadItems.map((el, i) => (
                            <Image key={`road-${i}`} x={140*i} image={road}/>
                          ))
                        }
                      </Group>

                      <Group x={20} ref={this._ref(`car-${id}`)}>
                        <Rect ref={this._ref(`car-${id}-box`)} x={90} width={50} height={30} fill={'purple'} opacity={0}/>
                        <Image image={auto}/>

                        <Group x={83}>
                          {
                            this.cargoItems.map((cargoId, i) => (
                              <Image x={-i*21} ref={this._ref(`car-${id}-cargo-${cargoId}`)} image={cargo}/>
                            ))
                          }
                        </Group>

                        <Group x={14} y={23}>
                          {
                            this.wheelis.map((wheelId, i) => {
                              let correctX = 0;
                              if (i < 3) { correctX = i*8 }
                              if (i === 3) { correctX = 72 }
                              if (i === 4) { correctX = 104 }
                              return (
                                <Image
                                  x={correctX+4} y={4}
                                  offsetX={4}
                                  offsetY={4}
                                  ref={this._ref(`car-${id}-wheel-${wheelId}`)}
                                  width={8} height={8} image={wheel}
                                />
                              )
                            })
                          }
                        </Group>

                        <Group x={130} y={15}>
                          <Arrow
                            ref={this._ref(`car-${id}-vectorSpeed`)}
                            points={[0,0,40,0]}
                            fill={'white'}
                            stroke={'white'}
                            strokeWidth={2}
                          />
                          <Group
                            x={50} y={-20}
                            ref={this._ref(`car-${id}-vectorSpeedText`)}
                          >
                            <Arrow points={[0,0,70,0]} scale={{x: .3, y: .3}} fill={'white'} stroke={'white'}/>
                            <Text y={3} text={'V'} fill={'white'} fontStyle={'italic'} fontSize={16}/>
                          </Group>
                        </Group>

                      </Group>
                    </Group>

                    <Image x={2} y={i*50+13} width={15} height={17} image={itemsImgs[i]}/>
                    <Text ref={this._ref(`car-${id}-brakingDistances`)} fontStyle={'bold'} text={`${0} м`} x={50} y={i*50+9} fontSize={25} fill={'white'}/>
                  </Group>
                )
              })
            }
          </Group>

          <Rect
            ref={this._ref('checkPoint')}
            x={750} y={370}
            width={40} height={200}
            fill={'red'}
            opacity={0}
          />
          <Rect
            ref={this._ref('brakePressedPoint')}
            x={10} y={370}
            width={40} height={200}
            fill={'green'}
            opacity={0}
            visible={false}
          />

        </Group>

        <CanvasResetBtn y={530} onClick={() => this.onClickReset()}/>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrakingDistances);

const styles = {
  mainContainer: {
    background: '#EDEBE5'
  }
};
