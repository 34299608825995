import {Group, Text} from "react-konva";
import {MiniSpaceShip} from "./MiniSpaceShip";
import {_t} from "../../../../utils/lang/common";
import {MiniSpaceStation} from "./MiniSpaceStation";
import React from "react";


const TopLsnInfo = (props) => {
  const {
    state,
    shipSpeedX,
    shipSpeedY,
    miniShipImage,
    miniStationImage,
    stroke
  } = props;
  return (
    <>
      {
        (state.shipSpeed) && (
          <Group x={550} y={50}>
            <MiniSpaceShip
              shipImage={miniShipImage}
            />
            <Text text={"ox: "} x={40} y={-13} fontSize={25} fill={stroke}/>
            <Text text={shipSpeedX} x={80} y={-25} fontSize={40} fill={stroke}/>
            <Text text={_t("space.meter_per_second")} x={170} y={-13} fontSize={25} fill={stroke}/>
            <Text text={"oy:"} x={240} y={-13} fontSize={25} fill={stroke}/>
            <Text text={shipSpeedY} x={280} y={-25} fontSize={40} fill={stroke}/>
            <Text text={_t("space.meter_per_second")} x={370} y={-13} fontSize={25} fill={stroke}/>
          </Group>
        )
      }{
      (state.stationSpeed) && (
        <Group x={550} y={100}>
          <MiniSpaceStation
            stationImage={miniStationImage}
          />
          <Text text={"ox: "} x={40} y={-13} fontSize={25} fill={stroke}/>
          <Text text={Number(state.stationSpeed.x).toFixed(2)} x={80} y={-25} fontSize={40} fill={stroke}/>
          <Text text={_t("space.meter_per_second")} x={170} y={-13} fontSize={25} fill={stroke}/>
          <Text text={"oy:"} x={240} y={-13} fontSize={25} fill={stroke}/>
          <Text text={Number(state.stationSpeed.y).toFixed(2)} x={280} y={-25} fontSize={40} fill={stroke}/>
          <Text text={_t("space.meter_per_second")} x={370} y={-13} fontSize={25} fill={stroke}/>
        </Group>
      )
    }
    </>
  )
}

export default TopLsnInfo;