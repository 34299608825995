import React from "react";
import {Group, Text} from "react-konva";



const TimerComponent = ({taskName, timeRef}) => {

  return (
    <Group x={500} y={20}>
      <Text text={taskName} fontSize={18} width={200} align={'right'} fontStyle={'bold'}/>
      <Text text={'Время:'} fontSize={18} x={220} />
      <Text ref={timeRef} text={'0'} fontSize={18} x={285}/>
    </Group>
  )
};
export default TimerComponent;