import {
  pathAndSpeedScenario,
  pathAndSpeedScenario2,
  pathAndSpeedWithSpaceship2Scenario,
  pathAndSpeedWithSpaceshipScenario
} from "./scenaries";
import {getLsnNameByCode} from "./common";




export const getScenarioConfig = (code) => {
  const {pathandspeed5, pathandspeed6, pathandspeed12, pathandspeed13} = getLsnNameByCode(code);
  let correctScenario;
  if (pathandspeed5) {
    correctScenario = pathAndSpeedScenario;
  }
  if (pathandspeed6) {
    correctScenario = pathAndSpeedScenario2;
  }
  if (pathandspeed12) {
    correctScenario = pathAndSpeedWithSpaceshipScenario;
  }
  if (pathandspeed13) {
    correctScenario = pathAndSpeedWithSpaceship2Scenario;
  }
  return correctScenario;
}