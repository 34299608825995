import React from "react";
import { Group, Circle } from "react-konva";
import Victor from 'victor';


class Bubble extends React.Component {
  constructor(props) {
    super(props);
    this.circleRef = React.createRef();
    this.groupRef = React.createRef();
    this.requestId = null;
    this.framesToSkip = Math.floor(Math.random()*150);
    this.radius = props.radius ? props.radius : 5;

    this.initialData = {
      prevTime: undefined,
      bubblePosition: Victor( this.radius + Math.floor((3511) * Math.random() % (props.width-this.radius*2)),props.height-this.radius),
      bubbleVelocity: Victor(0, -90), // px per sec
    };

    this.data = {...this.initialData};
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  move = (time) => {
    const {boiling, width, height} = this.props;
    let data = this.data;

    this.requestId = window.requestAnimationFrame(this.move);

    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    if (this.framesToSkip > 0) {
      this.framesToSkip -= 1;
      return;
    }

    if (data.bubblePosition.y < 0) {
      data.bubblePosition.y = height-5;
      data.bubblePosition.x = this.radius + Math.floor((3511) * Math.random() % (width-this.radius*2));
      this.framesToSkip = Math.floor(Math.random()*50);
    }

    if (!boiling) {
      window.cancelAnimationFrame(this.requestId);
    } else {
      data.bubblePosition = data.bubblePosition
        .add(Victor(data.bubbleVelocity.x, data.bubbleVelocity.y).multiply(Victor(timedeltaSec, timedeltaSec)));
    }

    this.updateStage();
  };

  updateStage() {
    const {bubblePosition} = this.data;

    if (!this.circleRef) return;
    let circleNode = this.circleRef.current;
    if (!circleNode) return;

    const layerToDraw = circleNode.getLayer();

    circleNode.x(bubblePosition.x);
    circleNode.y(bubblePosition.y);
    layerToDraw.draw();
  }

  render() {
    const {boiling, x, y} = this.props;
    const {bubblePosition} = this.data;
    if (!boiling) return null;
    return (
      <Group x={x} y={y} ref={this.groupRef}>
        <Circle ref={this.circleRef} radius={this.radius} x={bubblePosition.x} y={bubblePosition.y} fill={'white'} opacity={.4}/>
      </Group>
    )
  }
}

export default Bubble;
