import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import rowImg11 from "../images/continueRow/LogicWheel2_1_row_1.png";
import rowImg12 from "../images/continueRow/LogicWheel2_1_row_2.png";
import rowImg13 from "../images/continueRow/LogicWheel2_1_row_3.png";
import rowImg14 from "../images/continueRow/LogicWheel2_1_row_4.png";

import dragImg11 from "../images/continueRow/LogicWheel2_1_drag_1.png";
import dragImg12 from "../images/continueRow/LogicWheel2_1_drag_2.png";
import dragImg13 from "../images/continueRow/LogicWheel2_1_drag_3.png";
import dragImg14 from "../images/continueRow/LogicWheel2_1_drag_4.png";



import rowImg21 from "../images/continueRow/LogicWheel2_2_row_1.png";
import rowImg22 from "../images/continueRow/LogicWheel2_2_row_2.png";
import rowImg23 from "../images/continueRow/LogicWheel2_2_row_3.png";

import dragImg21 from "../images/continueRow/LogicWheel2_2_drag_1.png";
import dragImg22 from "../images/continueRow/LogicWheel2_2_drag_2.png";
import dragImg23 from "../images/continueRow/LogicWheel2_2_drag_3.png";
import dragImg24 from "../images/continueRow/LogicWheel2_2_drag_4.png";

import bgNature from '../images/bgNature.png';

import ContinueRowTask from "./ContinueRowTask";
import { _t } from "../../../../utils/lang/common";


const LogicWheel2 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: _t("logic_wheel.continue_the_row"),
      rowItems: [
        {id: 1, image: rowImg11},
        {id: 2, image: rowImg12},
        {id: 3, image: rowImg13},
        {id: 4, image: rowImg14},
      ],
      dragItems: [
        {id: 1, image: dragImg11},
        {id: 2, image: dragImg12},
        {id: 3, image: dragImg13},
        {id: 4, image: dragImg14, success: true},
      ]
    },
    {
      title: _t("logic_wheel.continue_the_row"),
      rowItems: [
        {id: 1, image: rowImg21},
        {id: 2, image: rowImg22},
        {id: 3, image: rowImg23},
      ],
      dragItems: [
        {id: 1, image: dragImg21},
        {id: 2, image: dragImg22, success: true},
        {id: 3, image: dragImg23},
        {id: 4, image: dragImg24},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <ContinueRowTask
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    rowItems={item.rowItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default LogicWheel2;
