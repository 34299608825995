import React, {} from "react";
import {Card} from 'antd';


const AuthFormWrapper = (props) => {
  return (
    <div style={{...styles.container, ...props.style}} className={props.className}>
      <Card
        align={'center'}
        title={<h2 style={{fontWeight: 'bold', fontSize: props.titleFontSize}}>{props.title}</h2>}
        bordered={true}
        style={{...styles.card, ...props.cardStyle}}
        bodyStyle={{padding: '15px 24px'}}
      >
        {props.children}
      </Card>
    </div>
  )
};

export default AuthFormWrapper;


const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  card: {
    flexShrink: 0,
    width: '100%',
    maxWidth: '300px',
    marginTop: '-150px',
    borderRadius: '7px',
    boxShadow: "0px 1px 2px 1px rgba(208, 216, 243, 0.3)",
  },
};
