import {Group, Image, Line, Rect, Text} from "react-konva";
import React from "react";
import {mainColor} from "../../../../../utils/styles";
import MovingArrow from "../../../../canvas/components/MovingArrow";


export const DragLineComponent = (props) => {
  const data = props.data;
  const _ref = props._ref;
  const _getNode = props._getNode;

  return (
    <>
      <Group x={640} y={431} visible={data.triangleGrayVisible}>
        <Image image={props.triangleGray} />
      </Group>
      <Group visible={props.isLiftingMethodSelected}>
        <Group x={1000} y={473} visible={data.dragLineVisible}>
          <Line ref={_ref('dragLine')} x={data.dragLineX} y={data.dragLineY}
                points={props.getDragLinePoints}
                stroke={'black'}
                strokeWidth={1.3}
          />
          <Rect ref={_ref('dragRect')}
                stroke={'black'}
                fill={'black'}
                x={data.dragLineX} y={data.dragLineY}
                width={-430} height={40}
                offsetY={20}
                opacity={0}
                draggable={data.dragLineActive}
                onDragMove={(e) => props.dragLineMove(e)}
          />
        </Group>

        {/*connectorLine*/}
        <Line x={663} y={465} points={[-175,-272,0,0]} stroke={'black'} strokeWidth={1.3} visible={data.connectorLineVisible}/>

        <Line
          ref={_ref('cargoLine')}
          x={data.cargoLineX} y={195} stroke={'black'}
          visible={data.cargoLineVisible}
          strokeWidth={1.3}
        />
        <Line
          ref={_ref('cargoLineSecond')}
          x={452} y={202} stroke={'black'}
          visible={data.cargoLineSecondVisible}
          strokeWidth={1.3}
        />


        <Group x={790} y={455}>
          <Group  x={20} y={30} ref={_ref('ropeText')}>
            <Text
              visible={data.withCalc}
              x={-70}
              text={'max'}
              fontSize={30} fontStyle={'bold'}
              fill={mainColor}
            />
            <Text
              text={`${!data.withCalc ? 'F = ' : '      '}${data.ropeForce} Н`}
              fontSize={30}                    fill={mainColor}
              visible={data.ropeForceVisible}
              x={-50}
            />
          </Group>


          <MovingArrow
            id={'dragHint'}
            ref={_ref('dragCableArrowHint')}
            stageNode={_getNode('stage')}
            length={100}
            arrowsStep={10}
            arrowsCount={1}
            textX={30} textY={-10}
            textColor={'black'}
            arrowColor={'black'}
            text={'Тяни'}
            rotation={-90}
            visible={data.dragHintVisible && data.draggable}
            getMovingCallback={props.getMovingCallback}
          />
        </Group>

      </Group>

      <Group x={640} y={431} visible={data.triangleGrayVisible}>
        <Image x={32} y={32} offsetY={12} offsetX={12} image={props.wheel} ref={_ref('wheel3')}/>
      </Group>
    </>
  )
}