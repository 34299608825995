import React, {useState, useEffect} from "react";
import * as actions from '../../../store/actions';
import {connect} from "react-redux";
import {_t} from "../../../utils/lang/common";
import {Form, Button, Input} from 'antd';
import AuthFormWrapper from './AuthFormWrapper';
import {useQuery} from "../../../utils/custom-hooks/useQuery";
import {useHistory} from "react-router-dom";
import {openNotificationWithIcon} from "../../../utils/common";
import {SyncOutlined} from "@ant-design/icons";


const ForgotPasswordForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(undefined);

  useEffect(() => {

  }, []);

  const onChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    setLoading(true);
    const resp = await props.forgotPassword(email);
    if (!resp) {
      openNotificationWithIcon('error', 'Произошла ошибка при отправке сообщения');
      return setLoading(false);
    }

    if (resp.error) {
      openNotificationWithIcon('error', resp.error);
    }
    if (resp.info) {
      openNotificationWithIcon('info', resp.info);
    }
    setLoading(false);
  };

  return (
    <AuthFormWrapper title={'Восстановление пароля'} titleFontSize={18}>
      <form
        method={'POST'}
        onSubmit={sendEmail}
      >
        <Form.Item>
          <Input
            id={'emailLoginForm'}
            name={'email'}
            required={true}
            type="email"
            placeholder={'Email'}
            style={styles.input}
            value={email}
            onChange={(e) => onChange(e)}
          />
        </Form.Item>
        <Button
          icon={loading ? <SyncOutlined spin={loading} style={{fontSize: '14px', marginTop: '4px'}} /> : undefined}
          type="primary" htmlType="submit" style={{margin: '10px auto'}}
        >
          Отправить email
        </Button>
        <br/>
        <Button
          style={{...styles.link, fontSize: '13px'}}
          type={'link'}
          href={`/login`}
        >
          Вернуться к форме входа
        </Button>
      </form>
    </AuthFormWrapper>
  )
};

const mapStateToProps = (state) => ({
  lang: state.lessonsReducer.lang,
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => dispatch(actions.forgotPassword(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordForm);


const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  card: {
    width: '300px',
    marginTop: '-50px'
  },
  input: {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  link: {
    fontSize: '18px'
  }
};