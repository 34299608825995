import Rectangle from '../Rectangle';

const intersection = (f1, f2) => {
  if (f1 instanceof Rectangle && f2 instanceof Rectangle) return rectangleIntersection(f1, f2);
  return undefined;
};

export const rectangleIntersection = (f1, f2) => !(f1.x > f2.endX || f1.endX < f2.x || f1.y > f2.endY || f1.endY < f2.y);

export default intersection;
