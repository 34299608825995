import { Image } from "react-konva";
import React from "react";
import {animated, Keyframes} from "react-spring/renderprops-konva";


export const CanvasBoat = ({boatImage, wavesImage, angle, isRunning}) => {
  const width = 30;
  const height = 73;
  return (
    <>
      <Image
        image={boatImage}
        width={width}
        height={height}
        offsetX={width/2}
        offsetY={height/2}
        rotation={angle}
        preventDefault={false}
      />
      {
        isRunning && (
          <MoveAnimation wavesImage={wavesImage} angle={angle} />
        )
      }
    </>
  )
};

const SpringKeyframes = Keyframes.Spring( async next => {
  while (true) {
    await next({
      native: true,
      config: {duration: 400},
      from: {offsetY: -15},
      to: {offsetY: -12},
      reset: true,

    });
  }
});

const MoveAnimation = (props) => {
  const { wavesImage, angle } = props;
  return (
    <SpringKeyframes key={1}>
      {props => (
        <animated.Image
          {...props}
          image={wavesImage}
          width={30}
          height={30}
          offsetX={15}
          scaleY={1.5}
          rotation={angle}
        />
      )}
    </SpringKeyframes>
  )
};