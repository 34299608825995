import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import '../styles/map-container.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import MapElement, { getConfigByTaskCode } from "../components/CatMapElement";
import {checkIsOpen, checkIsTeacherMap, getCorrectMapCodeByLocation} from "../utils";
import TaskModal from "../components/TaskModal";
import ProgressModal from "../components/ProgressModal";
import { useQuery } from "../../../../utils/custom-hooks/useQuery";
import StatisticModal from "../components/StatisticModal";
import {Button} from "antd";
import {ReloadOutlined, TableOutlined} from '@ant-design/icons';
import LangSelect from "../../../auth/components/LangSelect";
import {_t} from "../../../../utils/lang/common";


let fogOfWar = (taskItems) => {
  let prevItem = undefined;
  const STEP = 50;
  for (let item of taskItems) {
    item.clickable = prevItem === undefined || !!prevItem?.complete;
    prevItem = item;
  }
  let enabledItems = taskItems
    .filter(i => i.clickable === true)
    .map(i => ({...getConfigByTaskCode(i.lessonCode), lessonCode: i.lessonCode}));

  let arr = new Array(Math.ceil(1920 / STEP)).fill(1);
  for (let i in arr) {
    arr[i] = new Array(Math.ceil(1080 / STEP)).fill(1);
  };

  for (let item of enabledItems) {
    const basePoint = {x: Math.floor(item.pos.left / STEP), y: Math.floor(item.pos.top / STEP)};
    const epsilon = ['hypothesis1'].includes(item.lessonCode) ? [-1, 0, 1, 2, 3, 4] : [-1, 0, 1, 2];
    for (let dx of epsilon) {
      for (let dy of epsilon) {
        if (arr[basePoint.x + dx] && arr[basePoint.x + dx][basePoint.y + dy])
          arr[basePoint.x + dx][basePoint.y + dy] = 0
      }
    }

  }

  // console.log('debug on arr', arr);

  let result = [];
  for (let x in arr) {
    for (let y in arr[x]) {
      if (arr[x][y] !== 0) {
        result.push({left: x * STEP , top: y * STEP});
      }
    }
  }
  return result
};

const RenderFog = (props) => {
  console.log('debug on render fog', props);
  const {tasksItems} = props;
  return (
    fogOfWar(tasksItems).map((item, number) => {
      const size = (0.8 + Math.random() * 0.4) * 80;
      return (
        <div key={number} style={{
          height: size,
          width: size,
          borderRadius: '30%',
          backgroundColor: 'rgba(255,255,255, 0.99)',
          zIndex: 5,
          position: 'absolute',
          filter: 'blur(15px)',
          ...item,
        }}> </div>
      )
    })
  )
};

const defaultFog = RenderFog({tasksItems: []});

/**
 * props:
 * - sizeAuto: bool (adaptive view)
 * - withoutSuccessScreen: bool
 * - setLoading: fn(status: bool)
 */
const MapContainer = (props) => {
  const sizeAuto = props.sizeAuto;
  const history = useHistory();
  const isOpen = checkIsOpen();

  const [tasksItems, setTasksItems] = useState([]);

  const [successScreenVisible, setSuccessScreenVisible] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [statisticModalVisible, setStatisticModalVisible] = useState(false);

  const [currentLessonCode, setCurrentLessonCode] = useState('');
  const [taskFailureVisible, setTaskFailureVisible] = useState(false);
  const [taskSuccessVisible, setTaskSuccessVisible] = useState(false);
  const [initialWidth, setInitialWidth] = useState(1920);
  const [initialHeight, setInitialHeight] = useState(1080);
  const [windowChanged, setWindowChanged] = useState(0);
  const [loading, setLoading] = useState(false);

  const [fog, setFog] = useState(defaultFog);

  const centeringToCorrectTask = () => {
    const correctItem = tasksItems.find((item, i) => {
      const disabled = item.disabled && !props.coursePaid;
      const complete = item.complete;
      const teacherVideo = item.teacher_video;
      const prevItem = tasksItems.find(el => el.lessonCode === item.prevKey);
      return !complete && !teacherVideo && !disabled && (!prevItem || prevItem.complete)
    })
    if (!correctItem) {
      return
    }
    const domEl = document.querySelector('#'+correctItem.lessonCode);
    if (domEl) {
      setTimeout(() => {
        domEl.scrollIntoView({block: "center", inline: "center", behavior: "smooth"});
      }, 100);
    }
  }

  const checkIndexDBComplete = async () => {
    const checkExisting = (await window.indexedDB.databases()).map(db => db.name).includes('construct');
    if (checkExisting) {
      let openRequest = indexedDB.open('construct');
      openRequest.onsuccess = function (e) {
        let db = openRequest.result;
        const taskCompleteSuccess = db.objectStoreNames.contains("taskCompleteSuccess");
        const taskCompleteFailure = db.objectStoreNames.contains("taskCompleteFailure");
        // console.log({taskCompleteSuccess, taskCompleteFailure});
        setTaskSuccessVisible(taskCompleteSuccess);
        setTaskFailureVisible(taskCompleteFailure);
      }
    }
  }

  const updateTasksItems = () => setTasksItems(props.tasksItems);

  useEffect(() => {
    if (!sizeAuto)
      setTimeout(centeringToCorrectTask, 300)
  }, [tasksItems])

  useEffect(() => {
    /**
     *  Set elements:
     *  initial OR always if without success screen
     */
    (!tasksItems.length || props.withoutSuccessScreen) && updateTasksItems();
  }, [props.tasksItems]);

  useEffect(() => {
    /** Refresh items when the visibility of the success screen */
    !successScreenVisible && updateTasksItems();
  }, [successScreenVisible]);

  useEffect(() => {
    window.setMapTaskFailureVisible = setTaskFailureVisible;
    window.setMapTaskSuccessVisible = setTaskSuccessVisible;


    // setInterval(checkIndexDBComplete, 3000);


    (async function () {
      props.setLoading && props.setLoading(true);
      await props.courseMapAuth();
      await props.getOrCreateMapProgress(true);
      props.setLoading && props.setLoading(false);
    })()

    /** Opens a success task modal */
    const receiveMessage = (e) => {
      switch (e.data) {
        case 'taskFailure':
          setTaskFailureVisible(true);
          break
        case 'taskSuccess':
          setTaskSuccessVisible(true);
          break
      }
    };
    const resizeListener = (e) => setWindowChanged(prev => prev + 1);

    window.addEventListener("message", receiveMessage, false);
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener("message", receiveMessage);
    }
  }, []);

  useEffect(() => {
    const pathname = getCorrectMapCodeByLocation(history.location.pathname);
    /** Show/hide task window */
    setTaskModalVisible(history.location.pathname.replaceAll('/', '') !== pathname.replaceAll('/', ''));
  }, [history.location.pathname])

  useEffect(() => {
    if (taskModalVisible) {
      const pathname = getCorrectMapCodeByLocation(history.location.pathname);
      const historyPathname = history.location.pathname;
      const taskCode = historyPathname.replace(pathname+'ru/', '').replaceAll('/', '');
      setCurrentLessonCode(taskCode);
    }
  }, [taskModalVisible])



  // useEffect(() => {
  //   if (props.tasksItems.length > 0) {
  //     setFog(RenderFog({tasksItems: props.tasksItems}));
  //   }
  // }, [props.tasksItems]);

  const noFog = useQuery().get('no_fog');
  const allOpened = useQuery().get('all_opened');


  const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        tasksItems, isOpen,
        taskModalVisible,
        currentLessonCode,
        successScreenVisible,
        setTaskModalVisible
      });
    }
    return child;
  });

  const isTeacher = checkIsTeacherMap();
  // console.log('isTeacher', isTeacher);
  return (
    <div
      className={'course-map'}
      style={{
        // transform: 'scale(.9)',
        width: sizeAuto ? '100%' : initialWidth,
        minHeight: sizeAuto ? '100vh' : initialHeight,
        ...(!sizeAuto ? {height: '100vh'} : {})
      }}
    >
      {/* tasks elemets */}
      {childrenWithProps}

      {/*{!noFog && !props.withoutFog && !isOpen ? fog : null}*/}

      {
        !props.withoutSuccessScreen ? (
          <ProgressModal
            successScreenVisible={successScreenVisible}
            setSuccessScreenVisible={setSuccessScreenVisible}
          />
        ) : null
      }

      <TaskModal
        match={props.match}
        taskModalVisible={taskModalVisible}
        taskSuccessVisible={taskSuccessVisible}
        taskFailureVisible={taskFailureVisible}
        getOrCreateMapProgress={props.getOrCreateMapProgress}
        setTaskModalVisible={setTaskModalVisible}
        setTaskSuccessVisible={setTaskSuccessVisible}
        setTaskFailureVisible={setTaskFailureVisible}
      />

      <div className={'course-map__change-lang-widget'}>
        <LangSelect />
      </div>

      {
        isTeacher ? (
          <>
            <Button
              type={'primary'}
              size={'large'}
              className={'course-map__statistic-btn'}
              onClick={() => setStatisticModalVisible(true)}
            >
              {_t('map.Open_stats')}
              <TableOutlined style={{fontSize: '20px', position: 'relative', top: '2px'}} />
            </Button>
            <StatisticModal
              mapKey={props.mapKey}
              tasksItems={tasksItems}
              statisticModalVisible={statisticModalVisible}
              setStatisticModalVisible={setStatisticModalVisible}
            />
          </>
        ) : (
          <Button
            type={'primary'}
            size={'large'}
            className={'course-map__statistic-btn'}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await props.getOrCreateMapProgress(false);
              setLoading(false);
            }}
          >
            {_t('map.Open_stats')}
            <ReloadOutlined style={{fontSize: '20px', position: 'relative', top: '2px'}}/>
          </Button>
        )
      }
    </div>
  )
}


const mapStateToProps = state => {
  return ({
    tasksItems: Object.values(state.mapReducer.lessons || {}),
    points: state.mapReducer.points,
    initialPoints: state.mapReducer.initialPoints,
    coursePaid: state.mapReducer.coursePaid,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapContainer);
