import React from "react";
import cloneDeep from "lodash.clonedeep";
import {degToRad} from "../../../../utils/common";
import {CanvasContainer} from "../../../canvas";
import {Circle, Group, Image, Layer, Line, Rect, Text} from "react-konva";
import useImage from "use-image";
import mountainImg from "../../../../images/cityLighting/Mountain.png";
import backgroundNightImg from "../../../../images/cityLighting/backgroundNight.png";
import backgroundDayImg from "../../../../images/cityLighting/backgroundDay.png";
import buildingsImg from "../../../../images/cityLighting/buildings.png";
import buildingsLightImg from "../../../../images/cityLighting/buildingsLight.png";
import transformerImg from "../../../../images/cityLighting/transformer.png";
import stationImg from "../../../../images/cityLighting/station.png";
import waterImg from "../../../../images/cityLighting/water.png";
import powerCable1Img from "../../../../images/cityLighting/powerCable1.png";
import powerCable2Img from "../../../../images/cityLighting/powerCable2.png";




class CityLighting extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();

    // times of day refs
    this.dayRef = React.createRef();
    this.nightRef = React.createRef();

    this.lightRef = React.createRef();

    // water refs
    this.containerWaterRef = React.createRef();
    this.waterRef = React.createRef();

    // text refs
    this.powerTextRef = React.createRef();
    this.timeTextRef = React.createRef();
    this.volumeTextRef = React.createRef();


    this.requestId = null;

    this.staticData = {
      waterMaxHeight: 120,
      waterContainerMaxHeight: 120
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidMount() {
    this.requestId = window.requestAnimationFrame(this.move);
  }

  componentWillUnmount() {
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;




    this.updateStage();
  };

  updateStage = () => {
    const data = this.data;

    const dayNode = this.dayRef?.current;
    const nightNode = this.nightRef?.current;
    const lightNode = this.lightRef?.current;
    const containerWaterNode = this.containerWaterRef?.current;
    const waterNode = this.waterRef?.current;
    const powerTextNode = this.powerTextRef?.current;
    const timeTextNode = this.timeTextRef?.current;
    const volumeTextNode = this.volumeTextRef?.current;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;




    stageNode.draw();
  };


  CanvasCity = () => {

    const [powerCable1] = useImage(powerCable1Img);
    const [powerCable2] = useImage(powerCable2Img);
    const [water] = useImage(waterImg);
    const [station] = useImage(stationImg);
    const [transformer] = useImage(transformerImg);
    const [buildingsLight] = useImage(buildingsLightImg);
    const [buildings] = useImage(buildingsImg);
    const [mountain] = useImage(mountainImg);

    const [backgroundNight] = useImage(backgroundNightImg);
    const [backgroundDay] = useImage(backgroundDayImg);

    const textSettings = {
      align: 'center', verticalAlign: 'middle', stroke: 'white', fill: 'white', strokeWidth: 1.2, fontSize: 18,
    };
    return (
      <Group>
        {/* Times of Day */}
        <Image width={1000} height={700} image={backgroundDay} preventDefault={false} ref={this.dayRef}/>
        <Image width={1000} height={700} image={backgroundNight} preventDefault={false} ref={this.nightRef}/>



        <Rect x={880} y={480} height={120} width={30} fill={'#5BB2DC'} ref={this.waterRef} rotation={180} offsetX={25}/>


        <Image x={670} y={270} image={mountain} preventDefault={false}/>
        <Image y={500} x={0} width={1000} height={200} image={water} preventDefault={false}/>
        <Image x={865} y={478} image={station} preventDefault={false}/>

        {/* Water container */}
        <Group x={850}>
          <Rect y={250} height={120} width={200} fill={'white'} cornerRadius={5} stroke={'gray'}/>
          <Rect x={200} y={370} height={60} width={200} fill={'#5BB2DC'} ref={this.containerWaterRef} rotation={180} cornerRadius={5}/>
          <Rect y={250} height={120} width={200} fill={'transparent'} cornerRadius={5} stroke={'gray'}/>
        </Group>

        <Group y={328}>
          <Image x={480} y={75} image={powerCable1} preventDefault={false}/>

          <Image x={30} y={10} image={buildingsLight} preventDefault={false} ref={this.lightRef}/>
          <Image x={0} image={buildings} preventDefault={false}/>

          <Image x={640} y={75} image={powerCable2} preventDefault={false}/>
          <Image y={75} x={590} image={transformer} preventDefault={false}/>
        </Group>

        <Rect width={1000} height={13} x={0} y={500} fill={'#4f4f4f'} />

        <Group y={515} x={845}>
          <Rect width={90} height={40} fill={'white'} cornerRadius={5}/>
          <Text {...textSettings} text={'50 кВт'} stroke={'black'} fill={'black'} width={90} height={40} ref={this.powerTextRef}/>
        </Group>


        <Text {...textSettings} text={'Время:'} width={250} x={500} y={100} align={'right'}/>
        <Text {...textSettings} text={'00:00'} ref={this.timeTextRef} width={120} x={750} y={100} fontSize={20}/>

        <Text {...textSettings} text={'Объем запасенной воды, м3:'} width={250} x={500} y={150} align={'right'}/>
        <Text {...textSettings} text={'1500'} ref={this.volumeTextRef} width={120} x={750} y={150} fontSize={20}/>
      </Group>
    )
  };

  CanvasButtons = () => {

    return (
      <Group>

      </Group>
    )
  };


  render() {
    return (
      <div>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasCity/>
            <this.CanvasButtons/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}

export default CityLighting;

const styles = {};