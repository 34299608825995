import React, {useEffect, useRef, useState} from "react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import useMedia from "../../../utils/custom-hooks/useMedia";
import StudentsList from "./StudentsList";
import LessonSessionInfoBlock from "./LessonSessionInfoBlock";
import LessonsSwitcher from "./LessonsSwitcher";
import {CoffeeOutlined, DoubleLeftOutlined, DoubleRightOutlined} from "@ant-design/icons";
import SuccessModal from "./SuccessModal";
import FailureModal from "./FailureModal";
import {_t} from "../../../utils/lang/common";
import {userRoles} from "../../../utils/common";
import {withRouter} from "react-router-dom";

import {Button, Carousel, Collapse, Spin, Tabs, Tooltip} from "antd";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {serverMediaUrl} from "../../../utils/api";

const {Panel} = Collapse;
const {TabPane} = Tabs;

function getBrowserFullscreenElementProp() {
  if (typeof document.fullscreenElement !== "undefined") {
    return "fullscreenElement";
  } else if (typeof document.mozFullScreenElement !== "undefined") {
    return "mozFullScreenElement";
  } else if (typeof document.msFullscreenElement !== "undefined") {
    return "msFullscreenElement";
  } else if (typeof document.webkitFullscreenElement !== "undefined") {
    return "webkitFullscreenElement";
  } else {
    throw new Error("fullscreenElement is not supported by this browser");
  }
}

const Body = (props) => {
  const {
    isDemo,
    isTeacher,
    lessonSessionToken,
    activeLessonId,
    activeLessonCode,
    sidebarCollapse,
    lessonDescription,

    presentationSlides,

    video
  } = props;

  console.log(props);

  const [copiedLink, setCopiedLink] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (copiedLink) setCopiedLink(false);
    }, 2000)
  }, [copiedLink]);

  const styles = getStyles(sidebarCollapse);

  const descrMedia = useMedia(['(max-width: 1500px)'],[true], false);

  const descrContainerSmallView = descrMedia ? styles.containerSmall : {};
  const isLessonSession = Boolean(lessonSessionToken);


  let correctLink = window.location.href;
  if (!correctLink.includes(activeLessonId)) {
    correctLink += `/${activeLessonId}`;
  }

  const lessonTypes = [Boolean(presentationSlides.length), Boolean(video), Boolean(activeLessonCode)];
  const isLsnTypesMoreOne = lessonTypes.filter((type) => type).length > 1;

  return (
    <div
      style={styles.bodyRightBlockContainer}
    >
      {
        !isDemo && (
          <>
            {
              (isTeacher && lessonSessionToken) && (
                <StudentsList />
              )
            } {
            lessonSessionToken && (
              <LessonSessionInfoBlock />
            )
          }
          </>
        )
      }
      <div style={{display: 'flex', flexDirection: 'column', alignItems: `${sidebarCollapse ? 'center' : 'flex-start'}`}}>
        <Tooltip
          title={_t("copy_success")}
          color={'#73d13d'}
          trigger={'click'}
          placement="right"
          visible={copiedLink}
        >
          <CopyToClipboard
            text={correctLink}
            onCopy={() => setCopiedLink(true)}
          >
            <Button style={{margin: '0 0 10px'}} type={'primary'}>
              {_t("button_copy_lesson_link")}
            </Button>
          </CopyToClipboard>
        </Tooltip>
        {
          lessonDescription && (
            <Collapse defaultActiveKey={['1']} style={styles.descriptionCollapse}>
              <Panel header={<h3 style={styles.descriptionTitle}>{_t("title_task_description")}</h3>} key={1}>
                <p style={{...styles.descriptionText}}>{lessonDescription}</p>
              </Panel>
            </Collapse>
          )
        }{
          isLsnTypesMoreOne ? (
            <Tabs defaultActiveKey="1" onChange={() => {}} style={{width: '100%', maxWidth: '1000px'}}>
              {
                activeLessonCode && (
                  <TabPane tab="Задачка" key="1"> <LessonComp {...props} styles={styles}/> </TabPane>
                )
              } {
                video && (
                  <TabPane tab="Видео" key="2"> <VideoComp video={video} /> </TabPane>
                )
              } {
                presentationSlides.length && (
                  <TabPane tab="Презентация" key="3"> <PresentationComp presentationSlides={presentationSlides} /> </TabPane>
                )
              }
            </Tabs>
          ) : (
            <>
              {
                activeLessonCode ? (
                  <LessonComp {...props} styles={styles}/>
                ): video ? (
                  <VideoComp video={video} />
                ): presentationSlides.length ? (
                  <PresentationComp
                    presentationSlides={presentationSlides}
                  />
                ): (
                  <></>
                )
              }
            </>
          )
        }
      </div>
    </div>
  )
};

const LessonComp = ({
      activeLessonCode,
      isDemo,
      isTeacher,
      isLessonSession,
      userCode,
      successModalVisible,
      failureModalVisible,
      styles
  }) => {
  return (
      activeLessonCode && (
        <>
        <div id={'konva-wrapper'} style={styles.lessonWrapper}>
          {
            activeLessonCode ? (
              <LessonsSwitcher
                lessonCode={activeLessonCode}
              />
            ) : (
              <div style={styles.spinner}>
                <Spin size="large" style={{width: '100px', height: '100px'}}/>
              </div>
            )
          }{
          (!isDemo && (!isTeacher && !isLessonSession)) && (
            <div style={styles.blur}>
              {
                userCode ? (
                  <>
                    <span style={{fontSize: '4em', margin: '0 10px'}}><CoffeeOutlined /></span>
                    <h2 style={{margin: '0'}}>Занятия закончились!</h2>
                  </>
                ) : (<></>)
              }
            </div>
          )
        }{
          successModalVisible && (
            <SuccessModal/>
          )
        }{
          failureModalVisible && (
            <FailureModal/>
          )
        }
                <button
         style={{position: 'absolute', zIndex: 100, bottom: 10, right: 10, paddingHorizontal: 4}}
          onClick={
            () => {
              let element = document.getElementById('konva-wrapper');
              if (!document.fullscreenElement) {
                if (element.requestFullscreen) { // W3C API
                    element.requestFullscreen();
                } else if (element.mozRequestFullScreen) { // Mozilla current API
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullScreen) { // Webkit current API
                    element.webkitRequestFullScreen();
                } else if (element.webkitRequestFullscreen) { // Webkit current API
                    element.webkitRequestFullscreen();
                }
                // Maybe other prefixed APIs?
                else {alert('Fullscreen is not supported');}
              } else {
                if (document.exitFullscreen) {
                  document.exitFullscreen();
                }
              }
            }
          }
        >
          &#x2921;
        </button>
        </div>

    </>
      )
  );
};

const VideoComp = ({
   video
 }) => (<>{
  video && (
    <div className="video">
      <iframe width="420" height="315" src={video.link} frameBorder="0" allowFullScreen />
    </div>
  )}</>
);

const PresentationComp = ({
    presentationSlides
  }) => {
  const carouselRef = useRef(null);

  const onClickNext = () => {
    const node = carouselRef?.current;
    node.next();
  };
  const onClickPrev = () => {
    const node = carouselRef?.current;
    node.prev();
  };
  return (<>{
      presentationSlides.length && (
        <div className={'lessonCarousel'} style={{maxWidth: '1000px', width: '100%', margin: '30px 0 0', position: 'relative', maxHeight: '750px'}}>
          <Carousel ref={carouselRef} afterChange={() => {}} dotPosition={'top'}>
            {
              presentationSlides.map((slide) => (
                <img
                  key={`slide-${slide.id}`}
                  src={`${serverMediaUrl}${slide.img}`}
                />
              ))
            }
          </Carousel>
          <div className={'icon leftIcon'} onClick={() => onClickPrev()}>
            <DoubleLeftOutlined />
          </div>
          <div className={'icon rightIcon'} onClick={() => onClickNext()}>
            <DoubleRightOutlined />
          </div>
        </div>
      )}</>
  );
}

const mapStateToProps = (state, {match}) => {
  const activeLessonId = match.params.id;
  const allVideos = state.lessonsReducer.videos;
  const user = state.lessonsReducer.user;
  const isTeacher = user && user.role === userRoles.teacher;
  const activeLesson = state.lessonsReducer.activeLesson;

  const description = activeLesson && activeLesson.description && Object.keys(activeLesson.description).length ? activeLesson.description.text : '';

  const lessonPresentation = state.lessonsReducer.presentation.allPresentations.find((presentation) => activeLesson && presentation.id === activeLesson.presentation);
  const slides = state.lessonsReducer.presentation.imgs.filter((img) => {
    if (lessonPresentation && img.presentationId && lessonPresentation.id)
      return img.presentationId === lessonPresentation.id
  });

  const currentVideo = allVideos && allVideos.find((video) => activeLesson && video.id === activeLesson.video);
  return {
    lang: state.lessonsReducer.lang,
    lessonSessionToken: state.lessonsReducer.lessonSession.token,
    isPresentationActive: state.lessonsReducer.presentation.active,
    isTeacher: isTeacher,
    userCode: user && user.code ,
    activeLessonId: activeLesson ? activeLesson.id : activeLessonId,
    activeLessonCode: activeLesson ? activeLesson.code : '',
    lessonDescription: description,
    paymentModalVisible: state.commonReducer.paymentModalVisible,
    successModalVisible: state.commonReducer.successModalVisible,
    failureModalVisible: state.commonReducer.failureModalVisible,
    presentationModalVisible: state.commonReducer.presentationModalVisible,
    presentationFormModalVisible: state.commonReducer.presentationFormModalVisible,

    presentation: lessonPresentation,
    presentationSlides: slides,

    video: currentVideo
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutAction: () => dispatch(actions.logoutAction()),
  openLesson: (lesson) => dispatch(actions.openLesson(lesson)),
  changeChangePaymentVisible: (status) => dispatch(actions.changeChangePaymentVisible(status)),
  getLessonActivityLog: () => dispatch(actions.getLessonActivityLog()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Body));


const getStyles = (sidebarCollapse) => ({
  bodyRightBlockContainer: {
    width: '100%',
    padding: '7px 20px',
    height: '100%',
  },
  lessonWrapper: {
    maxHeight: '700px',
    height: '100%',
    maxWidth: '1000px',
    width: '100%',
    border: `7px solid rgba(0,0,0,0.3)`,
    position: 'relative',
    overflow: 'hidden',
    flexShrink: 0
  },
  blur: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 100,
    background: 'rgba(255, 255, 255, 0.85)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },



  descriptionContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  },
  descriptionCollapse: {
    maxWidth: '1000px',
    width: '100%',
    margin: '20px 0'
  },
  descriptionTitle: {
    fontWeight: 'bold',
    margin: 0
  },
  descriptionText: {
    maxWidth: '800px',
  },


  // Media
  containerSmall: {
    flexDirection: 'column',
  },
  descriptionTextSmall: {
    maxWidth: '1000px',
  },
  spinner: {
    position: 'absolute', width: '100%', height: '100%', zIndex: 101, display: 'flex', alignItems: "center", justifyContent: 'center'
  }
})
