import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import completeImage from "../../../../images/pathandspeed/complete.png";
import backgroundImg from '../../../../images/pathandspeed/space/background.png';
import engineImg from '../../../../images/pathandspeed/space/engin.png';
import shipImg from '../../../../images/pathandspeed/space/ship2.png';
import {layout2, lsnLabelTxtStyle, lsnTitleTxtStyle, mainColor} from "../../../../utils/styles";
import {getCorrectTitle} from "../utils/trainUtils";
import Card from "../../../canvas/components/Card";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {
  generateArray,
  sendSuccessForScenario,
  showFailOrSuccessPopup,
  toFixed,
  toggleVisibleEl
} from "../../../../utils/common";
import * as actions from "../../../../store/actions";
import {getStaticDataByCode, getTitleByCode} from "../utils/spaceUtils";
import {getLsnNameByCode} from "../utils/common";
import {getChartImgSetting} from "../utils/utils";
import ChartsContainer from "../components/ChartContainer";
import ArrowHint from "../../../canvas/components/ArrowHint";
import CanvasChartImg from "../../../canvas/components/CanvasChartImg";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {
  defaultPatchData,
  space10Scenario,
  space11Scenario
} from "../scenaries/spaceScenario";
import Video from "../../../canvas/components/Video";
import VideoBlock from "../../../canvas/components/CanvasVideoBlock";
import Theory from "../../../components/theory/Theory";


class SpaceWithScenario extends React.Component {
  constructor(props) {
    super(props);

    const {pathandspeed10, pathandspeed11} = getLsnNameByCode(this.props.code);


    this.staticData = getStaticDataByCode(this.props.code);
    this.pointsItems = generateArray(this.staticData.pointsChats);
    this.titleItems = generateArray(this.staticData.stepsCount);

    this.prevStepKey = '';

    this.managedComponents = [
      'stage',

      'chartImgs',
      'startBtn',
      'lineStart',
      'lineProgress',
      'lineEnd',
      'progressText',
      'arrowHint',
      'startArrowHint',
      'cardWithInput',
      'ship',
      'engine',
      'chartImg1',
      'chartImg2',
    ];

    this.pointsItems.forEach((el, i) => {
      this.managedComponents.push(`point${i}`);
    });
    this.titleItems.forEach((el, i) => {
      this.managedComponents.push(`titleStep${i}`);
    })

    this.gridChatsItems = pathandspeed10 ? {x: 6, y: 6} : {x: 6, y: 8};
    this.pointsChatsItems = this.pointsItems.map((el, i) => {
      const sec = i*5;
      let res = {x: 0, y: 0};
      if (pathandspeed10) {
        res = {x: i*54.5, y: -i*38.5};
      }
      if (pathandspeed11) {
        res = {x: i*54.7, y: -i*28.7};
        if (sec > 10 && sec < 20) {
          res = {x: i*54.5, y: -i*31.3};
        }
        if (sec === 20) {
          res = {x: i*54.5, y: -i*35.9};
        }
        if (sec > 20) {
          res = {x: i*54.5, y: -i*40.2};
        }
      }
      return res;
    });


    this.state = {};
    this.itemsRowArr = Array(5).fill(1);
    this.itemsColumnArr = Array(5).fill(1);

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timedeltaSec: undefined,

      shipStartTime: undefined,
      shipPrevTime: undefined,
      shipTimedeltaSec: undefined,

      shipChartLabel: 'Корабль',

      start: false,
      isLineDragged: false,

      step: 0,

      inputAverageSpeed: undefined,// км/ч

      shipSpeed: 20,// км/с
      shipPath: 0,

      errorCount: 0,

      itemsRowArr: this.itemsRowArr,
      itemsColumnArr: this.itemsColumnArr,

      lineProgress: [0,0,0,0],

      selectedData: this.itemsColumnArr.reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),

      chartImgSetting: getChartImgSetting(this.props.code, this.itemsRowArr),
      ...defaultPatchData
    };
    this.data = cloneDeep(this.initialData);

    this.data.itemsColumnArr.forEach((el, columnI) => {
      this.managedComponents.push(`dragLine${columnI}`);
      this.managedComponents.push(`dragLineContainer${columnI}`);

      this.data.itemsRowArr.forEach((el, rowI) => {
        this.managedComponents.push(`actionBox${rowI}${columnI}`);
      });
    })
    const scenario = pathandspeed10 ? space10Scenario : space11Scenario;
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  get shipSpeed() {
    const { pathandspeed11 } = getLsnNameByCode(this.props.code);
    const data = this.data;
    let correctShipSpeed = data.shipSpeed;
    if (pathandspeed11) {
      if (data.shipTimedeltaSec >= 10 && !data.shipTimedeltaSec < 20) {
        correctShipSpeed = this.getCorrectSpeed(40, .03);
      }

      if (data.shipTimedeltaSec >= 20) {
        correctShipSpeed = 40;
      }
    }
    return correctShipSpeed;
  }

  get shipPath() {
    const { pathandspeed11 } = getLsnNameByCode(this.props.code);
    const data = this.data;
    const {maxShipPath} = this.staticData;
    let res = data.shipPath;
    if (data.shipTimedeltaSec && (data.shipPath <= maxShipPath)) {
      res = data.shipSpeed * data.shipTimedeltaSec

      if (pathandspeed11 && data.shipTimedeltaSec >= 20) {
        res -= 293;
      }
    }
    return res;
  }

  get shipTimedeltaSec() {
    const { maxShipSec } = this.staticData;
    const data = this.data;
    if (!data.shipTimedeltaSec || data.shipTimedeltaSec < maxShipSec ) {
      return Math.abs((data.shipStartTime - data.shipPrevTime) / 300);
    }
    return maxShipSec;
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();

  _getNode = (key) => this[`${key}Ref`]?.current;

  _resetCallback = () => {}

  getResetCallback = (callback) => {
    this._resetCallback = callback
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.setState(() => ({
      code: this.props.code
    }))
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
    this.setState(() => ({ code: this.props.code }))
    this._resetCallback();
    this.scenarioManager.resetScenario();
    this.prevStepKey = '';
  };

  onClickStart = () => {
    this.scenarioManager.next();
  };

  onCheckResult = () => {
    const data = this.data;
    const {pathandspeed10, pathandspeed11} = getLsnNameByCode(this.props.code);

    let success = false;
    if (pathandspeed10) {
      if (data.step === 0) {
        success = this.data.inputAverageSpeed === 72000;
      } else if (data.step === 1) {
        success = Object.keys(this.data.selectedData).every((k) => this.data.selectedData[k] === 72);

        if (success) {
          sendSuccessForScenario(this);
        }
      }
    }
    if (pathandspeed11) {
      if (data.step === 1) {
        success = (
          ['1','2'].every((k) => this.data.selectedData[`line${k}`] === 20) &&
          ['3'].every((k) => this.data.selectedData[`line${k}`] === 30) &&
          ['4','5'].every((k) => this.data.selectedData[`line${k}`] === 40)
        );
        if (success) {
          sendSuccessForScenario(this);
        }
      }
    }

    if (success) {
      this.data.errorCount = 0;
      if (this.data.step < this.staticData.maxStep) {
        this.data.step += 1;
        this.scenarioManager.success();
      }
    } else {
      this.data.errorCount+=1;
      if (this.data.errorCount >= 2) {
        this.prevStepKey = this.scenarioManager.getStepData()?.key;
        this.scenarioManager.selectStepByKey('theory');
        return
      }
    }
    showFailOrSuccessPopup(this, success);
  };

  onChangeInput = (val) => {
    this.data.inputAverageSpeed = toFixed(val);
  };

  getCorrectSpeed = (newSpeed, koef) => {
    const data = this.data;
    return data.shipSpeed >= newSpeed ? data.shipSpeed : data.shipSpeed+koef;
  }

  getNodes = (stageNode) => {
    // const titles = this.titleItems.reduce((accum, el, i) => {
    //   accum[`titleStep${i}Node`] = this[`titleStep${i}Ref`]?.current;
    //   return accum
    // }, {});
    const dragLineNodes = {};
    const actionBoxNodes = {};
    this.data.itemsColumnArr.forEach((el, cellI) => {
      dragLineNodes[`dragLine${cellI}Node`] = this[`dragLine${cellI}Ref`]?.current;
      dragLineNodes[`dragLineContainer${cellI}Node`] = this[`dragLineContainer${cellI}Ref`]?.current;

      this.data.itemsRowArr.forEach((el, rowI) => {
        actionBoxNodes[`actionBox${rowI}${cellI}Node`] = this[`actionBox${rowI}${cellI}Ref`]?.current;
      });
    })
    const points = {};
    this.pointsItems.forEach((el, i) => {
      points[`point${i}Node`] = this[`point${i}Ref`]?.current;
    });
    return ({
      ...dragLineNodes,
      ...actionBoxNodes,
      // ...titles,
      ...points,
      chartImgsNode: this.chartImgsRef?.current,
      chartImg1Node: this.chartImg1Ref?.current,
      chartImg2Node: this.chartImg2Ref?.current,
      startBtnNode: this.startBtnRef?.current,
      arrowHintNode: this.arrowHintRef?.current,
      startArrowHintNode: this.startArrowHintRef?.current,
      cardWithInputNode: this.cardWithInputRef?.current,
      shipNode: this.shipRef?.current,
      engineNode: this.engineRef?.current,

      lineStartNode: this.lineStartRef?.current,
      lineProgressNode: this.lineProgressRef?.current,
      lineEndNode: this.lineEndRef?.current,
      progressTextNode: this.progressTextRef?.current,
    });
  }

  move = (time) => {
    const data = this.data;
    const {maxShipPath} = this.staticData;
    this.requestId = window.requestAnimationFrame(this.move);
    const { pathandspeed10, pathandspeed11 } = getLsnNameByCode(this.props.code);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    if (data.start) {
      data.shipStartTime = data.shipStartTime || time;
      data.shipPrevTime = time;

      data.shipPath = this.shipPath;
      data.shipTimedeltaSec = this.shipTimedeltaSec;
      if (pathandspeed11) {
        data.shipSpeed = this.shipSpeed;

        if (data.shipPath >= maxShipPath) {
          this.data.step = 1;
          this.scenarioManager.success('step 2');
        }
      }
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const {maxShipPath} = this.staticData;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const { pathandspeed10, pathandspeed11 } = getLsnNameByCode(this.props.code);
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    toggleVisibleEl(n['startArrowHint'], data.start);
    toggleVisibleEl(n['startBtn'], data.start);

    const shipmentOffset = pathandspeed10 ? -data.shipPath*2.2 : -data.shipPath*1.6
    n['ship'].offsetX(shipmentOffset);
    let engineIsActive = data.start && (data.shipPath < maxShipPath);
    if (pathandspeed11) {
      engineIsActive = data.shipTimedeltaSec >= 10 && data.shipTimedeltaSec < 20;
    }
    n['engine'].opacity(Number(engineIsActive));

    if (
      pathandspeed10 &&
      (data.shipTimedeltaSec == null ||
      (data.start && data.shipTimedeltaSec >= 1))
    ) {
      toggleVisibleEl(n['cardWithInput'], !data.start || this.data.step > 0);
    }

    toggleVisibleEl(n['chartImg1'], this.data.step !== 1);
    toggleVisibleEl(n['arrowHint'], this.data.isLineDragged);

    this.pointsItems.forEach((el, i) => {
      toggleVisibleEl(n[`point${i}`], !data.start || data.shipTimedeltaSec <= (i*5)-1);
    })

    if (pathandspeed11) {
      const maxProgress = 110;
      toggleVisibleEl(n['lineStart'], !data.shipTimedeltaSec || data.shipTimedeltaSec <= 10);
      toggleVisibleEl(n['progressText'], !data.shipTimedeltaSec || data.shipTimedeltaSec <= 10);
      toggleVisibleEl(n['lineProgress'], !data.shipTimedeltaSec || data.shipTimedeltaSec <= 10);
      toggleVisibleEl(n['lineEnd'], !data.shipTimedeltaSec || data.shipTimedeltaSec <= 20);
      if (data.shipTimedeltaSec && data.shipTimedeltaSec >= 10) {
        const points = n['lineProgress'].points();
        points[2] = points[2] < maxProgress ? points[2]+.58 : maxProgress;
        n['lineProgress'] && n['lineProgress'].points(points);
      }
    }

    n['stage'].draw();
  }

  BackgroundEnv = (props) => {
    const [background] = useImage(backgroundImg);

    return (
      <Image image={background} />
    )
  }
  Env = (props) => {
    const data = this.data;

    const [chart] = useImage(data.chartWithDragAndDrop);
    const [engine] = useImage(engineImg);
    const [ship] = useImage(shipImg);
    const [chartForPoints] = useImage(data.chartForPoints);
    const [complete] = useImage(completeImage);

    return (
      <Group {...props}>

        <Text
          text={data.title}
          {...lsnTitleTxtStyle}
          fill={'white'}
          fontSize={25}
          x={40} y={30}
        />

        <Group x={70} y={230} ref={this._ref('chartImgs')}>
          <Image image={chartForPoints} />
          <Group x={53} y={252}>
            {
              this.pointsChatsItems.map((el, i) => (
                <Circle key={'point-'+i} ref={this._ref(`point${i}`)} radius={3} fill={'rgba(0,0,0,0.9)'} strokeWidth={1} opacity={0} {...el}/>
              ))
            }
          </Group>
          <Group x={53} y={252}>
            <Line x={109} y={-20} ref={this._ref('lineStart')} points={[0,0,0,-38]} stroke={'purple'} strokeWidth={.5} dash={[7, 3]} opacity={0}/>
            <Line x={109} y={-27} ref={this._ref('lineProgress')} points={data.lineProgress} strokeWidth={15} stroke={'#9ea4d8'} opacity={0}/>
            <Line x={219} y={-20} ref={this._ref('lineEnd')} points={[0,0,0,-123]} stroke={'purple'} strokeWidth={.5} dash={[7, 3]} opacity={0}/>

            <Text
              ref={this._ref('progressText')}
              x={110} y={-15}
              text={'Двигатели работали'}
              fill={'#7a84e2'}
              stroke={'#7a84e2'}
              strokeWidth={.3}
              opacity={0}
            />
          </Group>
        </Group>

        <Group x={-80} y={160} ref={this._ref('ship')}>
          <Image ref={this._ref('engine')} image={engine} x={-30} y={15}/>
          <Image image={ship} width={40} height={80} rotation={90} offsetY={80}/>
        </Group>


        <Group x={650} y={350}>
          <Group ref={this._ref('startArrowHint')}>
            <ArrowHint
              x={-150} y={-110}
              textY={50}
              fontStyle={'bold'}
              arrowX={120} arrowY={120}
              text={'Нажми на "Старт", чтобы \nзапустить корабль'}
              color={'white'}
              rotation={180}
            />
          </Group>

          <CanvasButton
            btnRef={this._ref('startBtn')}
            text={'Старт'}
            onClick={() => this.onClickStart()}
            fontSize={22}
            strokeWidth={.2}
            btnStroke={'white'}
            btnStrokeWidth={.7}
            width={150}
            height={40}
          />
        </Group>


        <Group ref={this._ref('cardWithInput')} x={630} y={250} opacity={0} visible={false}>
          <Card height={120} width={180}>
            <Text text={'Скорость, км/ч:'} {...lsnLabelTxtStyle} fontStyle={'bold'} x={20} y={20}/>
            <CanvasInput
              id={'1'}
              x={20} y={50}
              width={100}
              height={35}
              fontSize={30}
              stage={this.stageRef?.current}
              onInput={(val) => {
                this.onChangeInput(val);
              }}
              value={this.data.inputAverageSpeed}
            />
          </Card>
          <CanvasButton
            text={'Проверить'}
            onClick={() => this.onCheckResult()}
            fontSize={25}
            strokeWidth={.2}
            btnStroke={'white'}
            btnStrokeWidth={.7}
            width={200}
            height={50}
            x={-10} y={140}
          />
        </Group>




        {/* -------- CHARTS ----------- */}
        <Group x={470} y={205} ref={this._ref('chartImg1')} opacity={0}>
          <CanvasButton
            text={'Проверить'}
            onClick={() => this.onCheckResult()}
            fontSize={25}
            strokeWidth={.2}
            btnStroke={'white'}
            btnStrokeWidth={.7}
            width={200}
            height={50}
            x={80} y={-50}
          />
          <Group>
            <Image image={chart} width={370} height={370}/>
            <CanvasChartImg
              $this={this}
              x={81}
              y={225}
              getResetCallback={this.getResetCallback}
              onDragStart={() => this.data.isLineDragged = true}
            />
          </Group>
        </Group>

        {/*<Group ref={this.completeRef} opacity={0}>*/}
        {/*  <Image x={450} y={208} width={410} height={300} image={complete}/>*/}
        {/*</Group>*/}
      </Group>
    )
  };




  render() {
    const data = this.data;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>


            <this.BackgroundEnv />


            <this.Env visible={!data.videoSrc && !data.hiddenAll}/>

            <CanvasResetBtn
              x={853}
              onClick={() => this.onClickReset()}
            />



            <VideoBlock
              src={data.videoSrc}
              onVideoEnd={data.onVideoEnd ? () => data.onVideoEnd(this) : undefined}
              scenarioManager={this.scenarioManager}
            />
          </Layer>
        </CanvasContainer>


        {
          this.data.theoryVisible && (
            <Theory
              data={this.props.lessonData?.theory}
              onClickSkipTheory={() => this.data.onClickSkipTheory?.(this)}
              lessonComplete={this.data.lessonComplete}
              onClickReset={this.onClickReset}
            />
          )
        }
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpaceWithScenario);

const styles = {
  mainContainer: {
    background: 'black'
  }
};
