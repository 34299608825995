import React, {useState} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Group, Image, Layer, Rect, Text} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getTimeDeltaSec} from "../../../../utils/common";
import gasAnimationGif from '../../../../images/bondGas/gasAnimation.gif';
import wheelHandleImg from '../../../../images/bondGas/wheelHandle2.png';
import wheelRectImg from '../../../../images/bondGas/wheelRect.png';
import rotateArrowImg from '../../../../images/bondGas/rotateArrow.png';
import flaskImg from '../../../../images/bondGas/flask.png';
import flaskHotImg from '../../../../images/bondGas/flaskHot.png';
import flaskColdImg from '../../../../images/bondGas/flaskCold.png';
import baseImg from '../../../../images/bondGas/base.png';
import gasBoxImg from '../../../../images/bondGas/gasBox.png';
import valveImg from '../../../../images/bondGas/piton.png';
import stickImg from '../../../../images/bondGas/stick.png';
import tempBoxImg from '../../../../images/bondGas/tempBox.png';
import tempSlideImg from '../../../../images/bondGas/tempSlide.png';
import Card from "../../../canvas/components/Card";
import CanvasTransformer from "../../../canvas/components/CanvasTransformer";
import CanvasSuccessCard from "../../../canvas/components/CanvasSuccessCard";
import CanvasButton from "../../../canvas/components/CanvasButton";
import MovingArrow from "../../../canvas/components/MovingArrow";
import {defaultPatchData, getScenario} from "../scenario";
import CheckMarkGif from "../../../canvas/components/CheckMarkGif";
import failImg from '../../../../images/failIcon.png';
import chipImg from '../../../../images/bondGas/Chip.png';
import {pressureStatic} from "../../ICE/utils/icePhysics";
import {GIF} from "../../../canvas/components/GIF";
import Victor from "victor";
import Ball from "../../idealGas/components/Ball";
import greyBallImage from "../../../../images/bondGas/dark_green_ball.png";
import Wall from "../../idealGas/components/Wall";
import * as actions from "../../../../store/actions";

const MAX_AMOUNT = 400;
const MIN_AMOUNT = 10;

class BondGas extends React.Component {
  _movingCallbacks = {};

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  constructor(props) {
    super(props);

    this.temperatureEnum = {
      COLD: -1,
      MEDIUM: 0,
      HOT: 1,
    }
    this.managedComponents = [
      'stage',

      'flaskHot',
      'flaskCold',
      'stick',
      'gas',

      'wheel',
      'wheelTransformer',

      'movingArrow',
      'gasInputGif',
      'gasOutputGif',

      'volumeText',
      'pressureText',
      'internalEnergyText',
      'moleculesCountText',
      'temperText',

      'tempSlideController',

      'timerText',

      'wallGroup',
      'ballGroup',
      'dynamicLayer',
    ]

    this.images = {
      pistoncase: {
        pos: Victor(110, 505),
        width: 220,
        height: 265,
        image: null
      },
      piston: {
        pos: Victor(110, 245),
        width: 220,
        height: 20,
        image: null
      },
    };

    this.entities = {
      balls: [],
      walls: [],
      //piston: null,
      redBalls: 0,
    };

    this.staticData = {
      maxTemper: 900,
      minTemper: -260,
      maxWheelAngle: 2800,
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      temperatureSlider: this.temperatureEnum.MEDIUM,

      wheelRotationDeg: 0,
      flaskHotOpacity: 0,
      flaskColdOpacity: 0,

      timerValue: 10,

      volume: 0,
      pressure: 0,
      temperature: 0,
      internalEnergy: 0,
      gasAmount: 45.5, // количество молей газа в 1 кубометре при нормальных условиях

      intakeGas: false,
      releaseGas: false,


      stickY: 0,
      temperSlideControllerX: 0,

      ...defaultPatchData,
    };
    this.data = cloneDeep(this.initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};


  _setTransformRotation = () => {}
  getSetTransformRotation = (callback) => this._setTransformRotation = callback;

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.scenarioManager.resetScenario();
    this.addWalls();
  }

  getNameTemperNameByValue = (corrTemp) => {
    return Object.keys(this.temperatureEnum).find(k => this.temperatureEnum[k]===corrTemp);
  }

  onClickGasLeftBtn = (isBtnActive) => {
    this.scenarioManager.success('block 2-1');
    this.data.intakeGas = isBtnActive;
  }

  onClickGasRightBtn = (isBtnActive) => {
    this.data.releaseGas = isBtnActive;
  }

  transformRotation = (rotationVal) => {
    this.data.wheelRotationDeg = rotationVal;
    if (Math.abs(rotationVal) > 0) {
      this.scenarioManager.success('block 3-1');
    }

    if (this.props.code === 'course7_gas2' && this.data.pressure < 50) {
      this.scenarioManager.success('step 3');
    }
  }

  get flaskHotOpacity() { return this.getCorrectOpacity(this.data.flaskHotOpacity, this.temperatureEnum.HOT) }
  get flaskColdOpacity() { return this.getCorrectOpacity(this.data.flaskColdOpacity, this.temperatureEnum.COLD) }

  get volume() {
    const data = this.data;
    const fullVolume = 200;
    const volume = fullVolume - Math.abs(data.wheelRotationDeg/20);
    return volume / fullVolume
  }

  get pressure() {
    const data = this.data;
    return pressureStatic(data.gasAmount, data.temperature + 273, data.volume);
  }

  get temperature() {
    const temperatureEnum = this.temperatureEnum;
    const sd = this.staticData;
    const data = this.data;
    const coeff = 1;
    let res = data.temperature;
    if (data.temperatureSlider === temperatureEnum.HOT) {
      res = res >= sd.maxTemper ? sd.maxTemper : res + coeff;
    }
    if (data.temperatureSlider === temperatureEnum.COLD) {
      res = res <= sd.minTemper ? sd.minTemper : res - coeff;
    }

    return res;
  }

  get internalEnergy() {
    return (this.temperature + 273) * 2.5;
  }

  checkCorrectPressureForChip = () => {
    if (this.data.pressure <= 5000000) {
      this.scenarioManager.success('step 3');
    } else {
      this.scenarioManager.failure('step 3');
    }
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this._setTransformRotation(0);
    this.scenarioManager.resetScenario();
  };

  getCorrectOpacity = (dataOpacity, enumVal) => {
    let opacity = dataOpacity;
    if (this.data.temperatureSlider === enumVal) {
      opacity = opacity >= 1 ? 1 : opacity + .005;
    } else {
      opacity = opacity <= 0.05 ? 0 :  opacity - .01;
    }
    return opacity;
  }

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  addWalls() {
    const pistoncasepos = this.images.pistoncase.pos;
    const pistoncasew = this.images.pistoncase.width;
    const pistoncaseh = this.images.pistoncase.height;


    let wallGroup = this.wallGroupRef?.current;
    const wallVectors = [
      [Victor(pistoncasepos.x, pistoncasepos.y), Victor(pistoncasepos.x, pistoncasepos.y - pistoncaseh), 'left'],
      [Victor(pistoncasepos.x, pistoncasepos.y), Victor(pistoncasepos.x + pistoncasew, pistoncasepos.y), 'bottom'],
      [Victor(pistoncasepos.x + pistoncasew, pistoncasepos.y), Victor(pistoncasepos.x + pistoncasew, pistoncasepos.y - pistoncaseh), 'right'],
    ];


    const pistonpos = this.images.pistoncase.pos;
    const pistonw = this.images.pistoncase.width;
    const pistonh = this.images.pistoncase.height;

    const pistonVector = [
      Victor(pistonpos.x, pistonpos.y - pistonh),
      Victor(pistonpos.x + pistonw, pistonpos.y - pistonh),
      'top'
    ];

    for (let i = 0; i < wallVectors.length; i++) {
      let props = {
        id: `wall-${this.entities.walls.length}`,
        index: this.entities.walls.length,
        start: wallVectors[i][0],
        end: wallVectors[i][1],
        strokeWidth: 2,
        color: 'black',
        draggable: true,
        orientation: wallVectors[i][2]
      }

      let wall = new Wall(props);
      this.entities.walls.push(wall);
      // wallGroup.add(wall.getLine());
    }

    let props = {
        id: `piston-${this.entities.walls.length}`,
        index: this.entities.walls.length,
        start: pistonVector[0],
        end: pistonVector[1],
        strokeWidth: 2,
        draggable: false,
        orientation: pistonVector[2]
    }

    let piston = new Wall(props);
    this.entities.piston = piston;
    wallGroup.add(piston.getLine());
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);

    data.flaskHotOpacity = this.flaskHotOpacity;
    data.flaskColdOpacity = this.flaskColdOpacity;

    data.volume = this.volume;

    data.pressure = this.pressure;
    data.temperature = this.temperature;
    data.internalEnergy = this.internalEnergy;

    if (data.intakeGas) {
      data.gasAmount = data.gasAmount < MAX_AMOUNT ? data.gasAmount + 25 * data.timedeltaSec : data.gasAmount;
    }

    if (data.releaseGas) {
      data.gasAmount = data.gasAmount > MIN_AMOUNT ? data.gasAmount  - 25 * data.timedeltaSec : data.gasAmount;
    }

    if (data.timerStart) {
      data.timerValue = data.timerValue <= 0 ? 0 : data.timerValue - data.timedeltaSec;
    }

    if (this.props.code === 'course7_gas2' && data.timerValue === 0) {
      this.checkCorrectPressureForChip();
    }

    if (data.temperatureSlider === this.temperatureEnum.HOT) {
      this.scenarioManager.success('block 4-1');
    }
    if (data.setWheelRotationDeg) {
      data.setWheelRotationDeg(this);
    }

    data.stickY = Math.abs(data.wheelRotationDeg)/20;

    this.entities.piston.var.prevy = this.entities.piston.var.y;
    this.entities.piston.var.y = this.images.piston.pos.y + data.stickY;
    this.entities.piston.var.v = this.entities.piston.var.y - this.entities.piston.var.prevy;

    if ((Math.round(data.pressure) >= 5000000) && this.props.code === 'course7_gas2') {
      this.scenarioManager.success('step 1');
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    const corrStickY = data.stickY >= 140 ? 140 : data.stickY <= 0 ? 0 : data.stickY;

    // Particles
    const timeDelta = data.timedeltaSec;
    const pistoncase = this.images.pistoncase;
    const defaultRedBallsPos = Victor(pistoncase.pos.x + pistoncase.width , pistoncase.pos.y - 95);

    if (this.entities.redBalls > data.gasAmount) {
      let ball = this.entities.balls.pop();
      ball.getImage().destroy();
      this.entities.redBalls -= 1;
    }

    let combinedSpeed = 0;
    for (let i = 0; i < this.entities.balls.length; i++) {
      let ball = this.entities.balls[i];

      ball.var.pos.add(ball.var.V.clone().multiply(Victor(timeDelta/16.7, timeDelta/16.7)));

      for (let k = 0; k < this.entities.walls.length; k++) {
        this.entities.walls[k].checkCollision(ball);
      }
      for (let j = 0; j < this.entities.balls.length; j++) {
        if (j === i) {continue;}
        this.entities.balls[j].checkCollision(ball);
      }
      this.entities.piston.checkCollision(ball);

      let prevV = ball.var.V;
      ball.var.V.add(ball.var.F);
      let dif = ball.var.V.length() - prevV.length();
      ball.var.V.multiply(Victor(1 - Math.abs(dif/2), 1-Math.abs(dif/2)));
      ball.var.F = Victor(0, 0);

      let ballImage = ball.getImage();
      ballImage.position({x: ball.var.pos.x, y: ball.var.pos.y});

      combinedSpeed += ball.var.V.length();
    }

    const avgSpeed = combinedSpeed/this.entities.redBalls || 2;
    const tempK = data.temperature + 273;
    for (let i = 0; i < this.entities.balls.length; i++) {
      let ball = this.entities.balls[i];
      ball.var.V.multiply(new Victor(tempK/(avgSpeed * 250), tempK/(avgSpeed * 250)));
    }

    if (this.entities.redBalls < data.gasAmount) {
      let ballGroup = this.ballGroupRef?.current;
      let newBallId = this.entities.balls.length;
      let ball = new Ball({id: `ball-${newBallId}`, pos: defaultRedBallsPos, speed: new Victor(avgSpeed * 3 * (Math.random()-.5), avgSpeed * 3 * (Math.random()-.5)), image: greyBallImage});
      this.entities.balls.push(ball);
      this.entities.redBalls += 1;
      ballGroup.add(ball.getImage());
      let ballImage = ball.getImage();
      ballImage.opacity(0.5);
    }

    n['stick'].y(corrStickY);

    n['flaskHot'].opacity(data.flaskHotOpacity);
    n['flaskCold'].opacity(data.flaskColdOpacity);

    n['volumeText'].text(data.volume.toFixed(2));
    n['pressureText'].text((data.pressure/1000).toFixed(2));
    n['temperText'].text(Math.round(data.temperature));
    n['internalEnergyText'].text(Math.round(data.internalEnergy));
    n['moleculesCountText'].text(`${(data.gasAmount * 6).toFixed(0)}`);

    n['tempSlideController'].x(data.temperSlideControllerX);
    n['timerText'].text(Math.round(data.timerValue));

    n['gasInputGif'].visible(data.intakeGas && data.gasAmount < MAX_AMOUNT - 5);
    n['gasOutputGif'].visible(data.releaseGas && data.gasAmount > MIN_AMOUNT + 2);


    const r = 100 + data.temperature > 0 ? (data.temperature) * 155 / 400 : 0;
    const b = 100 + data.temperature < 0 ? ( -data.temperature ) * 155 / 300 : 0;
    n['gas'].fill(`rgb(${r}, 0, ${b})`);

    n['gas'].opacity((data.pressure * 0.5 + data.gasAmount * 8000)/16000000);
    n['gas'].red(0);

    n['gas'].height(270 - corrStickY);

    n['stage'].draw();
  }

  Env = () => {
    const data = this.data;
    const [base] = useImage(baseImg);

    const BtnItem = (props) => {
      const resultDefaultValue = props.correctBtn && data.pressureAnswered;
      const [result, setResult] = useState( resultDefaultValue ? resultDefaultValue : undefined);
      const [hover, setHover] = useState(false);
      let bgColor = hover ? '#BCDDE0' : '#DAEEFB';
      let btnStroke = '#BCDDE0';
      let textFill = '#315259';

      if (result === true) {
        btnStroke = '#6BB562';
        bgColor = '#6BB562';
        textFill = 'white';
      }
      if (result === false) {
        btnStroke = '#E48A81';
        bgColor = '#E48A81';
        textFill = 'white';
      }

      const onClick = () => {
        if (data.pressureAnswered) {
          return;
        }

        setResult(props.correctBtn);

        if (!props.correctBtn) {
          setTimeout(() => {
            setResult(undefined)
          }, 2000)
        } else {
          setTimeout(() => {
            props.onClick();
          }, 0)
        }
      }
      const onHover = val => !data.pressureAnswered && setHover(val);
      return (
        <Group
          onMouseEnter={() => onHover(true)}
          onMouseLeave={() => onHover(false)}
        >
          <CanvasButton
            x={props.x} y={props.y}
            text={props.text}
            textStroke={textFill}
            strokeWidth={.2}
            textFill={textFill}
            textWidth={280}
            fontSize={25}
            height={55}
            width={280}
            btnStroke={btnStroke}
            btnStrokeWidth={2}
            btnFill={bgColor}
            onClick={onClick}
            boxShadowColor={'rgba(0,0,0,0)'}
          />
        </Group>
      )
    }

    const textColor = '#315259';
    const course7Gas3 = this.props.code === 'course7_gas3';
    return (
      <>
        <Image x={45} y={515} image={base}/>

        <Group x={325} y={380}>
          <Group ref={this._ref('gasInputGif')}>
            <GIF x={-80} width={120} height={60} src={gasAnimationGif} loop={true}/>
          </Group>
          <Group ref={this._ref('gasOutputGif')}>
            <GIF scaleX={-1} x={390} width={120} height={60} src={gasAnimationGif} loop={true}/>
          </Group>
        </Group>

        <Group x={50} y={50} visible={data.timerVisible}>
          <Text ref={this._ref('timerText')} text={'0'} fontStyle={'bold'} fontSize={23} fill={'#F2857D'}/>
        </Group>

        <Text x={300} y={20} text={data.title} fill={textColor} lineHeight={1.3} fontSize={30}/>

        <Card x={720} y={150} width={260} height={course7Gas3 ? 160 : 300} fill={'#BCDDE0'}>
          <Group x={20} y={20}>
            <Group visible={!course7Gas3}>
              <Text text={'Объем, м'} fill={textColor} fontSize={20}/>
              <Text x={90} text={'3'} fill={textColor} fontSize={13}/>
              <Text ref={this._ref('volumeText')} y={25} text={'0.98'} fill={textColor} fontSize={30} fontStyle={'bold'}/>
            </Group>
            <Group y={70} visible={!course7Gas3}>
              <Text text={'Число молекул'} fill={textColor} fontSize={20}/>
              <Text ref={this._ref('moleculesCountText')} y={25} text={'50'} fill={textColor} fontSize={30} fontStyle={'bold'}/>
              <Text x={70} y={25} text={'х10'} fill={textColor} fontSize={30} fontStyle={'bold'}/>
              <Text x={125} y={25} text={'23'} fill={textColor} fontSize={15} fontStyle={'bold'}/>
            </Group>
            <Group y={course7Gas3 ? 0 : 140}>
              <Text text={'Температура, °C'} fill={textColor} fontSize={20}/>
              <Text ref={this._ref('temperText')} y={25} text={'260'} fill={textColor} fontSize={30} fontStyle={'bold'}/>
            </Group>
            <Group y={70} visible={course7Gas3}>
              <Text text={'Внутренняя энергия, Дж'} fill={textColor} fontSize={20}/>
              <Text ref={this._ref('internalEnergyText')} y={25} text={'260'} fill={textColor} fontSize={30} fontStyle={'bold'}/>
            </Group>
            <Group y={210} visible={!course7Gas3}>
              <Text text={'Давление, кПа'} fill={textColor} fontSize={20}/>
              <Text ref={this._ref('pressureText')} y={25} text={'50'} fill={textColor} fontSize={30} fontStyle={'bold'}/>
            </Group>
          </Group>
        </Card>

        <Group x={370} y={130} visible={data.pressureAnswerBtnsVisible}>
          <BtnItem
            key={'BtnItem-'+1}
            x={0} y={30}
            text={'Давление вырастет'}
            correctBtn={data.pressureAnswerCorrect === 'rise'}
            onClick={() => {
              if (data.pressureAnswerCorrect === 'rise') {
                this.scenarioManager.next()
              }
            }}
          />
          <BtnItem
            key={'BtnItem-'+2}
            x={0} y={110}
            text={'Давление упадет'}
            correctBtn={data.pressureAnswerCorrect === 'drop'}
            onClick={() => {
              if (data.pressureAnswerCorrect === 'drop') {
                this.scenarioManager.next()
              }
            }}
          />
        </Group>

        <CanvasButton
          x={790} y={480}
          // text={}
          textStroke={textColor}
          strokeWidth={.2}
          textFill={textColor}
          textWidth={150}
          fontSize={25}
          height={45}
          width={150}
          btnStroke={'#BCDDE0'}
          btnStrokeWidth={2}
          btnFill={'#BCDDE0'}
          onClick={() => this.scenarioManager.next()}
          boxShadowColor={'rgba(0,0,0,0)'}
          visible={data.nextBtnVisible}
          {...data.nextBtnStg}
        />
      </>
    )
  };

  PressureMechanism = (props) => {
    const data = this.data;
    // const [wheel] = useImage(wheelImg);
    const [wheelHandle] = useImage(wheelHandleImg);
    const [wheelRect] = useImage(wheelRectImg);
    const [valve] = useImage(valveImg);
    const [stick] = useImage(stickImg);
    const [rotateArrow] = useImage(rotateArrowImg);
    return (
      <Group {...props}>
        <Group ref={this._ref('stick')} y={data.stickY}>
          <Group y={-50}>
            {
              Array(3).fill(1).map((el, i) => (
                <Image key={'stick'+i} x={100} y={-275*i} image={stick}/>
              ))
            }
          </Group>
          <Image y={220} image={valve}/>
          <Rect ref={this._ref('gas')} y={245} x={2} width={220} height={270} opacity={0.5}/>
        </Group>

        <Group x={60} y={60} visible={!data.hideVolumeHandle}>
          <Image
            x={-15} y={16}
            image={rotateArrow}
            visible={data.rotateArrowVisible}
          />
          <Image x={33} y={25} image={wheelRect}/>
          <Image
            ref={this._ref('wheel')}
            name={'wheel'} x={49} y={47} image={wheelHandle} width={35} height={190} offset={{x: 35/2, y: 190/2}}/>
          <CanvasTransformer
            selectedShapeName={this.data.rotateElementName}
            onRotation={this.transformRotation}
            getSetTransformRotation={this.getSetTransformRotation}
          />
        </Group>
      </Group>
    )
  }

  Flask = (props) => {
    const [flask] = useImage(flaskImg);
    const [flaskHot] = useImage(flaskHotImg);
    const [flaskCold] = useImage(flaskColdImg);
    const [chip] = useImage(chipImg);
    return (
      <Group {...props}>
        <Image image={flask}/>
        <Image x={-.5} ref={this._ref('flaskHot')} image={flaskHot} opacity={0}/>
        <Image ref={this._ref('flaskCold')} image={flaskCold} opacity={0}/>

        <Image x={90} y={230} image={chip} width={50} height={50} visible={this.data.flaskChipVisible}/>
      </Group>
    )
  }

  ActionBoxes = (props) => {
    const data = this.data;
    const [gasBox] = useImage(gasBoxImg);
    const [tempBox] = useImage(tempBoxImg);
    const [tempSlide] = useImage(tempSlideImg);

    const GasBtn = (props) => {
      const [mouseDown, setMouseDown] = useState(false);
      const press = () => {
        props.onMouseDown();
        setMouseDown(true);
      }
      const release = () => {
        props.onMouseUp();
        setMouseDown(false);
      }
      return (
        <Group
          x={props.x} y={props.y}
          onTouchStart={press}
          onMouseDown={press}

          onTouchEnd={release}
          onMouseLeave={release}
          onMouseUp={release}
        >
          <Rect x={-13} y={-10} width={50} height={35} fill={'red'} opacity={0}/>
          <Rect
            y={mouseDown ? 3 : 0}
            cornerRadius={[10,10,0,0]}
            width={25} height={5} fill={'#F1C402'}
          />
          <Rect y={5} width={25} height={5} fill={'#315259'} opacity={1}/>
        </Group>
      )
    }

    const updateTemp = () => {
      const temperatureEnum = this.temperatureEnum;
      let corrTemp = temperatureEnum.MEDIUM;
      const x = data.temperSlideControllerX;
      if (x > 25) {
        corrTemp = temperatureEnum.HOT;
      } else if (x < -25) {
        corrTemp = temperatureEnum.COLD;
      }
      data.temperatureSlider = corrTemp;
      if (corrTemp === temperatureEnum.HOT) {
        this.scenarioManager.success('block 4-1');
      }
    }

    return (
      <Group {...props}>
        <Group y={375} visible={!data.hideGasController}>
          <Image image={gasBox}/>

          <Group x={123} y={-5}>
            <GasBtn
              onMouseUp={() => this.onClickGasLeftBtn(false)}
              onMouseDown={() => this.onClickGasLeftBtn(true)}
            />
            <GasBtn
              x={88}
              onMouseUp={() => this.onClickGasRightBtn(false)}
              onMouseDown={() => this.onClickGasRightBtn(true)}
            />
          </Group>
        </Group>
        <Group y={450}>
          <Image image={tempBox}/>
          <Group
            ref={this._ref('tempSlideController')}
            draggable={true}
            onDragMove={(e) => {
              this.data.temperSlideControllerX = e.target.x();
              updateTemp(e);
            }}
            onDragEnd={(e) => {
              let x = e.target.x();
              if (x > 25) { x = 60;
              } else if (x < -25) {
                x = -60;
              } else {
                x = 0;
              }
              data.temperSlideControllerX = x;
              updateTemp(e)
            }}
            dragBoundFunc={(pos) => {
              const stageNode = this.stageRef?.current;
              const stageWidth = stageNode.width();
              const stageHeight = stageNode.height();

              const vertical = stageHeight * .800;
              const leftBorderX = stageWidth * .265;
              const rightBorderX = stageWidth * .383;
              const newX = pos.x >= rightBorderX ? rightBorderX : ( pos.x <= leftBorderX ? leftBorderX : pos.x );
              const newY = pos.y <= vertical ? vertical : ( pos.y >= vertical ? vertical : pos.y );
              return {y: newY, x: newX};
            }}
          >
            <Image x={165} y={35} image={tempSlide}/>
          </Group>
        </Group>
      </Group>
    )
  }

  HintsAndIcons = () => {
    const data = this.data;
    const [fail] = useImage(failImg);
    return (
      <>
        <MovingArrow
          id={'movingArrow'}
          ref={this._ref('movingArrow')}
          stageNode={this._getNode('stage')}
          length={30}
          arrowsStep={10}
          arrowsCount={1}
          textColor={'black'}
          arrowColor={'black'}
          text={''}
          getMovingCallback={this.getMovingCallback}
          {...this.data.movingArrowSettings}
        />

        {
          data.checkMarkStgs.visible && (
            <CheckMarkGif {...data.checkMarkStgs}/>
          )
        }
        {
          data.warningMarkStgs.visible && (
            <Image image={fail} width={50} height={63} {...data.warningMarkStgs}/>
          )
        }

      </>
    )
  }

  render() {
    const data = this.data;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this._ref('stage')}
          lessonCode={this.props.code}
          component={this}
          withVideo={true}
          videoVisible={data.videoSrc}
          theoryVisible={data.theoryVisible}
          additionalTheoryStgs={{
            skipInitialStep: true,
            hideResetBtn: true,
            bgColor: 'white',
          }}
        >
          {/*Чтобы частицы были под чипом*/}
          <Layer key={'dynamic'} ref={this._ref('dynamicLayer')}>
            <Group
              id={'ballGroup'}
              ref={this._ref('ballGroup')}
            />

            <Group
              id={'wallGroup'}
              ref={this._ref('wallGroup')}
            />

          </Layer>
          <Layer preventDefault={false}>
            <this.Env/>

            <this.Flask x={100} y={207}/>
            <this.PressureMechanism x={108}/>
            <this.ActionBoxes x={325}/>


            <this.HintsAndIcons />

            <CanvasSuccessCard
              title={data.finallyPopupText}
              visible={data.finallyStepVisible}
              success={data.successFinallyPopup}
              goToTheory={() => {this.scenarioManager.selectStepByKey('theory')}}
              onClickReset={() => this.data.onClickReset(this)}
            />
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BondGas);

const styles = {
  mainContainer: {
    background: '#DAEEFB'
  }
};
