import React from "react";
import '../styles/clock.css';
import {Spin} from "antd";
import MapTimer from "./MapTimer";


const Clock = (props) => {
  return (
    <div className={'map-clock'}>
      <div className={'map-clock__time-wrapper'}>
        <Spin size={'large'} spinning={props.loading}>
          <span  className={'map-clock__time'}>
            <MapTimer
              format={'mm:ss'}
              initialTimeStr={'30:00'}
              {...props}
            />
          </span>
        </Spin>
      </div>
    </div>
  )
}

export default Clock;
