import {Group, Rect} from "react-konva";
import Video from "./Video";
import React from "react";
import CanvasButton from "./CanvasButton";


const VideoBlock = (props) => {
  const {src, onVideoEnd, scenarioManager, skipVideo} = props;
  return (
    <Group {...props}>
      {
        src && (
          <>

            <Video
              src={src}
              width={1000} height={562}
              onEnded={() => {
                if (onVideoEnd) {
                  onVideoEnd();
                } else {
                  scenarioManager.next()
                }
              }}
            />
            <CanvasButton
              text={'Пропустить'}
              onClick={() => skipVideo ? skipVideo () : scenarioManager.next()}
              fontSize={18}
              btnCornerRadius={0}
              textStroke={'transparent'}
              // textFill={'white'}
              textFill={'white'}
              btnFill={'gray'}
              // btnStroke={'white'}
              btnStroke={'dimgray'}
              btnStrokeWidth={1}
              width={130}
              height={40}
              x={830} y={512}
            />
          </>
        )
      }
    </Group>
  )
}

export default VideoBlock;