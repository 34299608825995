import {height as canvasHeight} from '../../../canvas/containers/CanvasContainer';

class AirBalloonPhysics {
  get mass() {return this.#mass + this.#ballastAmount * this.#ballastMass;}

  calcKelvin = (temperature) => temperature + AirBalloonPhysics.#kelvinDelta;
  get kelvin () {return this.calcKelvin(this.temperature);}

  get temperatureVelocity() {return this.#maxTemperatureVelocity * Math.max(0, Math.min(1, this.height / this.maxHeight));}
  get velocity() {return this.#velocity / this.#drag;}

  calcOuterDensity = (height) => this.#baseDensity * (1 - height / this.maxHeight);
  calcInnerDensity = (temperature) => this.#baseDensity * AirBalloonPhysics.#innerDensityCoef / this.calcKelvin(temperature);
  get outerDensity() {return this.calcOuterDensity(this.height)}
  get innerDensity() {return this.calcInnerDensity(this.temperature)}

  get force() {return (this.outerDensity - this.innerDensity) - this.mass * 1.5;}
  get acceleration() {return this.force / this.mass;}

  addTime = ({timeDelta, extraTemperatureVelocity}) => {
    this.#height += this.velocity * timeDelta + (this.acceleration * Math.pow(timeDelta, 2)) / 2;
    this.#velocity += this.acceleration * timeDelta;
    this.#temperature += (this.temperatureVelocity + extraTemperatureVelocity) * timeDelta;
    return this;
  };

  dropBallast = ({amount = 1} = {}) => {
    this.#ballastAmount = Math.max(0, this.#ballastAmount - amount);
    return this;
  };

  constructor({
    mass = AirBalloonPhysics.#defaultMass, // mass
    ballastMass = AirBalloonPhysics.#defaultBallastMass, // ballast mass, const
    ballastAmount = AirBalloonPhysics.#defaultBallastAmount, // start ballast's amount
    height = AirBalloonPhysics.#defaultHeight, // start position
    maxHeight = AirBalloonPhysics.#defaultMaxHeight, // max height, const
    temperature = AirBalloonPhysics.#defaultTemperature, // start temperature
    maxTemperatureVelocity = AirBalloonPhysics.#defaultMaxTemperatureVelocity, // max passive temperature leak, const
    baseDensity = AirBalloonPhysics.#defaultBaseDensity, // default air density
    drag = AirBalloonPhysics.#defaultDrag, // default air resistance
  } = {}) {
    this.#mass = mass;
    this.#ballastMass = ballastMass;
    this.#ballastAmount = ballastAmount;
    this.#height = height;
    this.#maxHeight = maxHeight;
    this.#temperature = temperature;
    this.#maxTemperatureVelocity = maxTemperatureVelocity;
    this.#baseDensity = baseDensity;
    this.#drag = drag;
  }

  get height() {return this.#height;}
  get maxHeight() {return this.#maxHeight;}
  get temperature() {return this.#temperature;}
  get ballastAmount() {return this.#ballastAmount;}
  #mass;
  #ballastMass;
  #ballastAmount;
  #height;
  #maxHeight;
  #temperature;
  #velocity = AirBalloonPhysics.#startVelocity;
  #baseDensity;
  #drag;
  #maxTemperatureVelocity;

  static #defaultMass = 70;
  static #defaultBallastMass = 30;
  static #defaultBallastAmount = 3;
  static #defaultHeight = canvasHeight / 3;
  static #defaultMaxHeight = canvasHeight;
  static #defaultTemperature = 35;
  static #defaultMaxTemperatureVelocity = -1;
  static #defaultBaseDensity = 1000;
  static #defaultDrag = 100;

  static #startVelocity = 0;

  static #kelvinDelta = 273.15;
  static #innerDensityCoef = 200;
}

export default AirBalloonPhysics;
