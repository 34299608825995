import React from "react";
import LoginForm from "../components/LoginForm";
import RegistrationForm from "../components/RegistrationForm";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import ChangePasswordForm from "../components/ChangePasswordForm";
import HomeworkAuth from "../../pages/homework/containers/HomeworkAuth";


const AuthPage = (props) => {
  const {formType} = props;

  return (
    <div style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      {
        formType === 'login' ?
          <LoginForm {...props} />
      : formType === 'registr' ?
          <RegistrationForm {...props} />
      : formType === 'forgotPassword' ?
          <ForgotPasswordForm {...props} />
      : formType === 'changePassword' ?
          <ChangePasswordForm {...props} />
      : formType === 'homework-auth' ?
          <HomeworkAuth {...props} />
      :
          null
      }
    </div>
  )
};


const mapStateToProps = (state) => ({
  lang: state.lessonsReducer.lang,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: async (language) => dispatch(actions.setLanguage(language)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthPage);


const styles = {
  container: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
};
