

export const work1JScenario = [
  // --------- STEP 1 ----------
  {
    key: 'step1',
    patchData: {
      horizontalStep: true,
      horizontalArrowHintVisible: true,

      limitCupPath: 500,
      forceTxt: undefined,
      pathTxt: undefined,
      workTxt: undefined,
      dragCupPath: 0,
      dragging: false,
    },
    next_success: 'step1.2',
    next_btn_hidden: true,
  },
  {
    key: 'step1.2',
    patchData: {
      horizontalStep: true,
      arrowSuccessVisible: true,

    },
    previous: 'step1',
    next_success: 'step1.3',
    next_btn_hidden: true,
    switch_at: 1,
  },
  {
    key: 'step1.3',
    patchData: {
      horizontalStep: true,

    },
    previous: 'step1',
    next_success: 'step2',
  },
  {
    key: 'step2',
    patchData: {
      verticalStep: true,
      verticalArrowHintVisible: true,

      limitCupPath: 800,
      forceTxt: undefined,
      pathTxt: undefined,
      workTxt: undefined,
      dragCupPath: 0,
      dragging: false,
    },
    previous: 'step1',
    next_success: 'step2.1',
    next_btn_hidden: true,
  },
  {
    key: 'step2.1',
    patchData: {
      verticalStep: true,
      arrowSuccessVisible: true,
      arrowSuccessX: 500,
      arrowSuccessY: 250,

      cupDefPosX: 570,
      cupDefPosY: 0,
    },
    previous: 'step2',
    next_success: 'step2.1',
    next_btn_hidden: true,
  },
];