import Victor from 'victor';

export const pxPerMetre = 17.5;
export const asteroidFlightTime = 1.0; // s
export const asteroidScale = Victor(1, 3); // times from with & height in CanvasAsteroid.js

export const massInKilos = ["training2", "training4", "training5"];

export const showItems = {
  "training1": {
    "ship": ["mass", "speedX", "impulse"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "training2": {
    "ship": ["mass", "speedX", "impulse"],
  },
  "training3": {
    "ship": ["mass", "speedX", "impulse"],
  },
  "training4": {
    "ship": ["mass", "speedX", "impulse"],
  },
  "training5": {
    "ship": ["mass", "speedX", "impulse"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "training6": {
    "ship": ["mass", "speedX", "impulse"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "training7": {
    "ship": ["mass", "speedX", "impulse"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },

  "space1": {
    "ship": ["mass", "speedX", "impulse"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "space2": {
    "ship": ["mass", "speedX", "impulse"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "space3": {
    "ship": ["mass", "speedX", "impulse"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "space4": {
    "ship": ["mass", "speedX", "impulse", "engineTimeX"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "space5": {
    "ship": ["mass", "speedX", "impulse", "engineTimeX", "engineTimeY"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "space6": {
    "ship": ["mass", "speedX", "impulse", "engineTimeX"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "space7": {
    "ship": ["mass", "speedX", "impulse", "engineTimeX", "engineTimeY"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
  "space8": {
    "ship": ["mass", "speedX", "impulse", "engineTimeY"],
    "station": ["mass", "speedX", "impulse"],
    "system": ["mass", "speedX", "impulse"]
  },
}
