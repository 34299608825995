import * as React from 'react';
import {useHistory} from "react-router-dom";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {userRoles} from "../../../../utils/common";


const Notifications = (props) => {

  const isAnonym = !props.user;

  const history = useHistory();


  const createNewHw = async () => {
    if (isAnonym) {
      return props.setAnonymousPopupVisible(true);
    }
    const {newHomeworkId} = await props.createHomework();
    if (newHomeworkId) {
      props.setSelectedHomeworkId(newHomeworkId);
    }
    history.push('/homework');
  }


  const selectHw = async (homeworkId) => {
    props.setSelectedHomeworkId(homeworkId);
    history.push('/homework');
  }
  const NotificationItem = (props) => {
    return (
      <div className={'notifications__item'} onClick={() => props.selectHw(props.homeworkId)}>
        <p className={'notifications__lsn-classroom'}>
          {props.homeworkTitle}
        </p>
        {
          props.isTeacher && (
            <span className={'notifications__circle'}/>
          )
        }
      </div>
    )
  };

  return (
    <div>
      <div className={'notifications'}>
        {
          (props.isTeacher || isAnonym) ? (
            <div
              className={'notifications__item notifications__item--create-new'}
              onClick={createNewHw}
            >
              <p className={'title'}>Создать задание с автопроверкой</p>
              <span className={'plus-icon'}>+</span>
            </div>
          ) : null
        }

        {
          props.notificationsList.map((el, i) => (
            <NotificationItem key={'notification-'+i} selectHw={selectHw} {...el}/>
          ))
        }
      </div>
    </div>
  );
};


const mapStateToProps = state => {
  const user = state.lessonsReducer.user;
  const isTeacher = user?.role === userRoles.teacher;
  // const lastHomework =
  return ({
    isTeacher: isTeacher,
    user: user,
    notificationsList: state.lessonsReducer.homeworkNotifications,
  });
}

const mapDispatchToProps = (dispatch, {match}) => ({
  createHomework: (title) => dispatch(actions.createHomework(title)),
  setSelectedHomeworkId: (homeworkId) => dispatch(actions.setSelectedHomeworkId(homeworkId)),
  setAnonymousPopupVisible: (visible) => dispatch(actions.setAnonymousPopupVisible(visible))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
