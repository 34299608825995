import React from "react";
import '../styles/healthModal.css';
import heartImg from '../images/heart.png';
import heartEmptyImg from '../images/heartEmpty.png';
import CustomButton from "./CustomButton";
import {Col, Row} from "antd";


const HealthModal = (props) => {

  return (
    <div className={`health-modal__backdrop ${props.visible ? 'health-modal__backdrop--visible' : ''}`}>
      <div className={'health-modal'}>
        <Row>
          <Col span={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {
              props.restart ? (
                <p className={'health-modal__text'}>0</p>
              ) : (
                <p className={'health-modal__text'}>-1</p>
              )
            }
          </Col>
          <Col span={12}>
            {
              props.restart ? (
                <img src={heartEmptyImg} alt=""/>
              ) : (
                <img src={heartImg} alt=""/>
              )
            }
          </Col>
        </Row>
        {
          props.restart ? (
            <CustomButton
              className={'health-modal__button'}
              onClick={props.onClickReset}
              btnText={props.lang === 'ru' ? 'Пройти заново' : 'Restart'}
            />
          ) : null
        }
      </div>
    </div>
  )
}
export default HealthModal;
