import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import Victor from 'victor';
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {GIF} from "../../../canvas/components/GIF";
import {degToRad, lessonStatus, radToDeg} from '../../../../utils/common';
import * as actions from '../../../../store/actions';

import stickImg from '../../../../images/gearRatios/scaleStick.png';
import {connect} from "react-redux";
import {Input} from 'antd';


class Step1 extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();

    this.staticData = {};
    this.state = {};
    this.initialData = {};
    this.data = cloneDeep(this.initialData);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    this.requestId = window.requestAnimationFrame(this.move);
  }

  componentWillUnmount() {
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  getAllNodes = () => ({
    stickNode: this.stickRef?.current,
  });

  move = (time) => {
    const {maxAngle, minAngle} = this.staticData;
    const {

    } = this.state;
    const nodes = this.getAllNodes();
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;


    this.updateStage();
  };

  updateStage() {
    const {

    } = this.state;
    const {rotationAngle, bridgeSize, startTime, prevTime} = this.data;
    const timedelta = prevTime ? startTime - prevTime : 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const nodes = this.getAllNodes();


    stageNode.draw();
  }

  onChangeInput = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    const min = Number(e.target.min);
    const max = Number(e.target.max);
    let correctVal = val;
    if (Number(val) < min)
      correctVal = min;
    if (Number(val) > max)
      correctVal = max;
    this.setState({[name]: correctVal, [`${name}Input`]: val})
  };

  CanvasICE = () => {
    const {} = this.state;

    const [stick] = useImage(stickImg);

    return (
      <React.Fragment>
        <Layer>

        </Layer>
      </React.Fragment>
    )
  };

  render() {
    const {} = this.state;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.CanvasICE/>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step1);

const styles = {
  mainContainer: {
    background: '#97C1D9',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    position: 'absolute',
    background: '#E76F52',
    borderRadius: '12px',
    color: 'white',
    fontSize: '1.3em',
    zIndex: 10,
    padding: '7px 14px',
    fontWeight: 'bold'
  },
  fieldsBlock: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    width: 400,
    top: 550
  }
};
