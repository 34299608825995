import animationStartMp4 from "../../../../images/space/theory/Animation_start.mp4";
import {createTheoryStepsList, theoryDefaultData} from "./theoryGenerator";
import {Text} from "react-konva";
import React from "react";

export const spaceshipTakeOffElementsUI = {
  title: '',
  secondTitle: '',
  answerWindowTitle: '',
  answerWindowText: '',

  btnRotateAction: undefined,

  spaceshipY: 0,
  // spaceshipRotation: 0,

  spaceshipVisible: false,
  spaceshipScale: {x: .9, y: .9},

  controllerVisible: false,

  launchStationVisible: false,
  fuelBtnVisible: false,
  startBtnVisible: false,
  indicatorVisible: false,
  rocketTakeOff: false,

  infoDataVisible: false,
  rocketReadyStart: false,
  spaceshipFlying: false,

  spaceshipFell: false,
  selectTrajectory: false,
  fuelConsumptionIsActive: false,
  explosionVisible: false,
  restartFlyingBtnVisible: false,

  answerWindow: false,
  onFuelEnd: (component) => {},
  controllerAdditionAction: (component) => {},
  onFailure: (component) => {},

  //  THEORY
  ...theoryDefaultData,
}
const questionTextStyles = {
  fill: '#4f558c', fontSize: 20,
  lineHeight: 1.2,
  y: -20,
  width: 535,
}

export const spaceshipTakeOffScenario = [

  {
    key: 'initial spaceship',
    patchData: {
      title: 'Произведи заправку и нажми "Пуск"',

      launchStationVisible: true,
      fuelBtnVisible: true,
      startBtnVisible: true,
      indicatorVisible: true,
      spaceshipVisible: true,
    },
    next_success: 'start spaceship',
    // switch_at: 1,
  },


  // Взлет корабля
  {
    key: 'start spaceship',
    patchData: {
      title: '',

      launchStationVisible: true,
      rocketReadyStart: true,
      indicatorVisible: true,
      spaceshipVisible: true,
    },
    next_success: 'spaceship take off',
  }, {
    key: 'spaceship take off',
    patchData: {
      title: '',

      launchStationVisible: true,
      rocketTakeOff: true,
      indicatorVisible: true,
      spaceshipVisible: true,
    },
    next_success: 'spaceship flying',
  },


  // Полет корабля
  {
    key: 'spaceship flying',
    patchData: {
      title: 'Старайся удержать вертикальное направление \nдвижения корабля клавишами',
      secondTitle: 'на клавиатуре / на экране.',

      atmosphereY: 1000,
      spaceshipRotation: 0,
      spaceshipRotationSpeed: 0,

      spaceshipScale: {x: .2, y: .2},

      spaceshipFlying: true,

      fuelConsumptionIsActive: true,
      infoDataVisible: true,
      controllerVisible: true,
      indicatorVisible: true,
      spaceshipVisible: true,
      onFuelEnd: (component) => {
        let height = component.data.atmosphereY / 150;
        if (height >= component.staticData.maxHeight) {
          component.scenarioManager.selectStepByKey('spaceship fuel end');
        } else {
          component.scenarioManager.selectStepByKey('spaceship fuel end fail');
        }
      },
      controllerAdditionAction: (component) => {
        component.scenarioManager.selectStepByKey('spaceship flying max height');
      },
      onFailure: (component, key) => {
        if (key === 'border limit') {
          component.scenarioManager.selectStepByKey('spaceship border limit');
        }
        if (key === 'fell') {
          component.scenarioManager.selectStepByKey('spaceship fell');
        }
      },
    },
    next_success: 'spaceship flying max height',
    next_failure: 'spaceship fell',
  },

  {
    key: 'spaceship flying max height',
    patchData: {
      title: 'Поднимись выше 70 км',

      spaceshipScale: {x: .2, y: .2},

      fuelConsumptionIsActive: true,
      spaceshipFlying: true,
      infoDataVisible: true,
      controllerVisible: true,
      indicatorVisible: true,
      spaceshipVisible: true,
      onFuelEnd: (component) => {
        let height = component.data.atmosphereY / 150;
        if (height >= component.staticData.maxHeight) {
          component.scenarioManager.selectStepByKey('spaceship fuel end');
        } else {
          component.scenarioManager.selectStepByKey('spaceship fuel end fail');
        }
      },
      onFailure: (component, key) => {
        if (key === 'border limit') {
          component.scenarioManager.selectStepByKey('spaceship border limit');
        }
        if (key === 'fell') {
          component.scenarioManager.selectStepByKey('spaceship fell');
        }
      },
    },
    next_failure: 'spaceship fell',
  },

  // Корабль упал
  {
    key: 'spaceship fell',
    patchData: {
      title: 'Корабль потерпел крушение. Попробуй начать с начала.',

      spaceshipScale: {x: .2, y: .2},


      spaceshipRotation: -90,
      atmosphereY: -200,
      explosionVisible: true,
      spaceshipFell: true,
      spaceshipVisible: true,
      restartFlyingBtnVisible: true,
    },
  },{
    key: 'spaceship border limit',
    patchData: {
      title: 'Корабль вышел за пределы допустимой траектории. \nСтарайся обеспечить более вертикальный взлет. \nПопробуй снова.',

      spaceshipScale: {x: .2, y: .2},

      spaceshipFell: true,
      spaceshipVisible: true,
      restartFlyingBtnVisible: true,
    },
  },{
    key: 'spaceship fuel end fail',
    patchData: {
      title: 'Корабль не набрал необходимую высоту 70 км \nи не смог покинуть планету Земля. Попробуй снова.',

      spaceshipScale: {x: .2, y: .2},

      spaceshipFell: true,
      infoDataVisible: true,
      spaceshipVisible: true,
      restartFlyingBtnVisible: true,
    },
  },



  // Кончилось топливо
  {
    key: 'spaceship fuel end',
    patchData: {
      title: 'Запас топлива исчерпан. По какой траектории \nкорабль будет продолжать движение?',

      spaceshipScale: {x: .2, y: .2},
      // spaceshipRotation: 20,
      spaceshipRotationSpeed: 0,

      infoDataVisible: true,
      indicatorVisible: true,
      selectTrajectory: true,
      spaceshipVisible: true,
      onClickTrajectoryBtn: (component, success) => {
        const corrKey = `spaceship trajectory ${success ? "success" : 'fail'}`;
        component.data.questionSuccess = success;
        component.scenarioManager.selectStepByKey(corrKey);
      }
    },
    next_success: 'spaceship flying max height',
  },




  {
    key: 'spaceship trajectory success',
    patchData: {
      title: 'Запас топлива исчерпан. По какой траектории \nкорабль будет продолжать движение?',

      spaceshipScale: {x: .2, y: .2},

      infoDataVisible: true,
      spaceshipVisible: true,
      answerWindow: true,
      answerWindowTitle: 'Правильно!',
      answerWindowText: 'Ракета будет продолжать двигаться по инерции.',
    },
    next_success: 'initial theory'
  },{
    key: 'spaceship trajectory fail',
    patchData: {
      title: 'Запас топлива исчерпан. По какой траектории \nкорабль будет продолжать движение?',

      spaceshipScale: {x: .2, y: .2},

      infoDataVisible: true,
      spaceshipVisible: true,
      answerWindow: true,
      answerWindowTitle: 'Будь внимательнее!',
      answerWindowText: 'Ракета будет продолжать двигаться по инерции.',
    },
    next_success: 'initial theory'
  },



  ...createTheoryStepsList(
    {
      theoryVisible: true,
      projectorVisible: true,
      questionsListVisible: true,
      theoryQuestions: [
        {id: 1,stepY: 0, text: 'Как будет двигаться космический корабль вдали от звёзд и планет?', scenarioKey: 'theory Qst. 1'},
        {id: 2,stepY: 70, text: 'Что такое движение по инерции?', scenarioKey: 'theory Qst. 2'},
        {id: 3,stepY: 140, text: 'Как меняется скорость тела, если оно движется по инерции?', scenarioKey: 'theory Qst. 3'},
        {id: 4,stepY: 240, text: 'Как долго корабль может двигаться без топлива?', scenarioKey: 'theory Qst. 4'},
      ],
      skipTheoryOnClick: (component) => {
        if (component.data.questionSuccess) {
          component.scenarioManager.selectStepByKey('success');
        } else {
          component.data.atmosphereY = 0;
          component.data.atmosphereX = 0;
          component.data.launchServiceFarmRotation = 0;
          component.data.spaceshipRotation = 0;
          component.data.spaceshipRotationSpeed = 0;
          component.data.spaceshipY = 0;
          component.data.fuelPercent = 100;
          component.scenarioManager.selectStepByKey('spaceship flying');
        }
      }
    },
    [
      {
        key: 'theory Qst. 1',
        patchData: {
          theoryVisible: true,
          projectorVisible: true,
          projectorContent: (images) => (
            <>
              <Text text={
                'Космический корабль будет двигаться по инерции.'
              } {...questionTextStyles}/>
              <images.TheoryQ1/>
            </>
          ),
        },
        next_success: 'theory questions',
        next_failure: 'theory questions',
      },

      {
        key: 'theory Qst. 2',
        patchData: {
          theoryVisible: true,
          prevQstStepDisabled: true,
          projectorVisible: true,
          projectorContent: (images) => (
            <>
              <Text text={'Движение по инерции – это движение, при котором на тело не действуют другие тела.'} {...questionTextStyles} y={0}/>
              <images.TheoryQ2/>
            </>
          ),
        },
        next_success: 'theory questions',
        next_failure: 'theory questions',
      },

      {
        key: 'theory Qst. 3',
        patchData: {
          theoryVisible: true,
          projectorVisible: true,
          projectorContent: (images) => (
            <>
              <Text text={
                'Если тело движется по инерции, скорость тела остаётся неизменной. Это называют принципом инерции Галилея.'
              } {...questionTextStyles}/>
              <images.TheoryQ3/>
            </>
          ),
        },
        next_success: 'theory questions',
        next_failure: 'theory questions',
      },

      {
        key: 'theory Qst. 4',
        patchData: {
          theoryVisible: true,
          projectorVisible: true,
          projectorContent: (images) => (
            <>
              <Text text={'Корабль может двигаться бесконечно долго, если он не будет остановлен другим телом. \n' +
              'Например, автомобиль с неработающим двигателем останавливается, ' +
              'потому что на него действует покрытие дороги.'} {...questionTextStyles}/>
              <images.TheoryQ4/>
            </>
          ),
        },
        next_success: 'theory questions',
        next_failure: 'theory questions',
      },
    ]
  )
]
