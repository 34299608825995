import {Group, Image, Line, Rect, Text} from "react-konva";
import React from "react";
import useImage from "use-image";
import indicatorImg from "../../../../images/ICE/indicator.png";
import cloneDeep from "lodash.clonedeep";
import {_t} from '../../../../utils/lang/common';


/**
 * PROPS
 *
 * externalData: f() => {
 *   fuel: int
 *   gas: int
 *   temperature: int
 *   pressure: int
 * }
 *
 */
class Indicator extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();
    this.temperatureRef = React.createRef();
    this.pressureRef = React.createRef();
    this.petrolRef = React.createRef();
    this.airRef = React.createRef();
    this.exhaustRef = React.createRef();

    this.staticData = {};

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      temperatureAngle: 0,
      pressureAngle: 0,
    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  get temperatureAngle() {
    const t = this.props.externalData().temperature;
    const maxAngle = 240;
    const maxVal = 100;
    if ( t <= maxVal && t >= 0) return t/maxVal * maxAngle;
    if (t > maxVal) return maxAngle;
  }
  get pressureAngle() {
    const p = this.props.externalData().pressure / 100000;
    const maxAngle = 240;
    const maxVal = 20;
    if ( p <= maxVal && p >= 0) return p/maxVal * maxAngle;
    if (p > maxVal) return maxAngle;
  }
  get petrolWidth() {
    const consistency = this.props.externalData().consistency;
    const correctPetrol = consistency?.petrol < 1 ? consistency?.petrol : 1;
    return correctPetrol * 100 || 0;
  }
  get airWidth() {
    const consistency = this.props.externalData().consistency;
    const correctAir = consistency?.air < 1 ? consistency?.air : 1;
    return correctAir * 100 || 0;
  }
  get exhaustWidth() {
    const consistency = this.props.externalData().consistency;
    const correctExhaust = consistency?.exhaust < 1 ? consistency?.exhaust : 1;
    return correctExhaust * 100 || 0;
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);
    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta/1000;


    data.temperatureAngle = this.temperatureAngle;
    data.pressureAngle = this.pressureAngle;

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const staticData = this.staticData;
    const temperatureNode = this.temperatureRef?.current;
    const pressureNode = this.pressureRef?.current;
    const petrolNode = this.petrolRef?.current;
    const airNode = this.airRef?.current;
    const exhaustNode = this.exhaustRef?.current;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    temperatureNode.rotation(data.temperatureAngle);
    pressureNode.rotation(data.pressureAngle);

    petrolNode.width(this.petrolWidth);
    airNode.width(this.airWidth);
    exhaustNode.width(this.exhaustWidth);

    stageNode.draw();
  }

  IndicatorCanvas = (props) => {
    const [indicator] = useImage(indicatorImg);
    const staticData = {
      maxItems: 9
    };

    return (
      <Group ref={this.stageRef}>
        <Rect x={300} y={220} width={200} height={10} fill={'#AEB5B9'}/>

        <Group x={500} y={130}>

          <Image image={indicator} />

          {/* PROGRESS LINES */}
          <Group x={70} y={28}>
            <Group y={0}>
              <Text text={_t('ice.indicator.fuel')} x={-55} y={2} align={'right'} width={50}/>
              <Rect ref={this.petrolRef} width={10} height={15} fill={'#e2d462'}/>
            </Group>
            <Group y={36}>
              <Text text={_t('ice.indicator.air')} x={-55} y={2} align={'right'} width={50}/>
              <Rect ref={this.airRef} width={10} name={'air'} height={15} fill={'#bdfff7'}/>
            </Group>
            <Group y={72}>
              <Text text={_t('ice.indicator.exhaust')} x={-55} y={2} align={'right'} width={50}/>
              <Rect ref={this.exhaustRef} width={10} name={'exhaust'} height={15} fill={'#7b7b7b'}/>
            </Group>
          </Group>

          {/* ARROWS */}
          <Group x={43} y={183}>
            <Line ref={this.temperatureRef} points={[0,0,-15, 8]} stroke={'red'} rotation={0}/>
            <Line ref={this.pressureRef} points={[0,0, -15, 8]} y={75} stroke={'red'} rotation={0}/>
          </Group>

          <Group x={-100}>
            <Text x={5} y={10} text={_t('ice.indicator.gas_consistency')} stroke={'#71A1BD'} strokeWidth={.5} fontSize={15} align={'right'}/>
            <Text y={160} text={_t('ice.indicator.temperature')} stroke={'#71A1BD'} strokeWidth={.5} fontSize={15} align={'right'}/>
            <Text x={5} y={230} text={_t('ice.indicator.pressure')} stroke={'#71A1BD'} strokeWidth={.5} fontSize={15} align={'right'}/>
          </Group>
        </Group>
      </Group>
    )
  };

  render() {
    return ( <this.IndicatorCanvas /> )
  };
}


export default Indicator;
