import React from "react";
import Konva from "konva";
import { Layer, Line, Rect, Circle, Image as KonvaImage, Group, Text, RegularPolygon, } from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";

import redBallImage from "../../../../images/IdealGas/red_ball.png";
import blueBallImage from "../../../../images/IdealGas/blue_ball.png";
import Ball from "../components/Ball";
import Wall from "../components/Wall";
import SceneImage from "../../../../images/brownianMotion/scene.png";


import Victor from 'victor';
import useImage from "use-image";
import ArrowHint from "../../../canvas/components/ArrowHint";
import { layout2, lsnTitleTxtStyle, mainColor } from "../../../../utils/styles";
import Card from "../../../canvas/components/Card";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {sendSuccessForScenario, showFailOrSuccessPopup, toggleVisibleEl} from "../../../../utils/common";
import cloneDeep from "lodash.clonedeep";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import { getBoolLessonByCode, getTitles } from "../utils/brownianUtils";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";


class Diffusion extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();
    this.backgroundLayerRef = React.createRef();
    this.wallsLayerRef = React.createRef();
    this.ballsLayerRef = React.createRef();
    this.UILayerRef = React.createRef();

    this.entities = {
      walls: [],
      balls: {
        red: [],
        blue: [],
      },
    }

    this.const = {
      redBallsNumber: 100,
      blueBallsNumber: 100,

      xmin: 245,
      xmax: 382,
      ymin: 145,
      ymax: 540,
    }
    this.const.yborder = (this.const.ymax + this.const.ymin) / 2;

    this.images = {
      blueBallImage: new Image(),
      redBallImage: new Image(),
    }
    this.images.blueBallImage.src = blueBallImage;
    this.images.redBallImage.src = redBallImage;
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidMount() {

    const { xmin, xmax, ymin, ymax, yborder } = this.const;

    const wallsLayer = this.wallsLayerRef?.current;
    const ballsLayer = this.ballsLayerRef?.current;

    //Add walls
    const wallVectors = [
      [Victor(xmin, ymin), Victor(xmin, ymax), 'left'],
      [Victor(xmin, ymin), Victor(xmax, ymin), 'top'],
      [Victor(xmin, ymax), Victor(xmax, ymax), 'bottom'],
      [Victor(xmax, ymin), Victor(xmax, ymax), 'right'],
    ]
    for (let i = 0; i < wallVectors.length; i++) {
      let props = {
        id: `wall-${i + 1}`,
        index: i,
        start: wallVectors[i][0],
        end: wallVectors[i][1],
        strokeWidth: 2,
        color: 'blue',
        draggable: true,
        orientation: wallVectors[i][2],
      }

      let wall = new Wall(props);
      this.entities.walls.push(wall);
      wall.getLine().on('dragmove', function () { console.log('position: ', this.position()) });
      wallsLayer.add(wall.getLine());
    }


    //Add border
    const borderVector = [[Victor(xmin - 5, yborder), Victor(xmax + 5, yborder)]];
    for (let i = 0; i < borderVector.length; i++) {
      let props = {
        id: `border`,
        index: 5,
        start: borderVector[i][0],
        end: borderVector[i][1],
        strokeWidth: 4,
        color: 'red',
        draggable: true,
        orientation: 'bottom',
      }

      let border = new Wall(props);
      this.entities.walls.push(border);
      border.getLine().on('dragmove', function () { console.log('position: ', this.position()) });
      wallsLayer.add(border.getLine());
      border.const.orientation = 'border';
      // console.log('border: ', border, border.getLine());
      this.border = border.getLine();
    }

    wallsLayer.draw();


    //Add balls
    const redBallVectors = [];
    for (let i = 0; i < this.const.redBallsNumber; i++) {
      let x = Math.random() * (this.const.xmax - this.const.xmin - 10) + this.const.xmin + 5;
      let y = Math.random() * (this.const.yborder - this.const.ymin - 10) + this.const.ymin + 5;
      let vector = Victor(x, y);
      redBallVectors.push(vector);
    }

    const blueBallVectors = [];
    for (let i = 0; i < this.const.blueBallsNumber; i++) {
      let x = Math.random() * (this.const.xmax - this.const.xmin - 10) + this.const.xmin + 5;
      let y = Math.random() * (this.const.ymax - this.const.yborder - 10) + this.const.yborder + 5;
      let vector = Victor(x, y);
      blueBallVectors.push(vector);
    }

    for (let i = 0; i < redBallVectors.length; i++) {
      const ball = new Ball({
        id: `redball-${i + 1}`,
        index: i,
        pos: redBallVectors[i],
        image: redBallImage,
        speed: (Victor(Math.random() - 0.5, Math.random() - 0.5)).multiply(Victor(0.1, 0.1)),
      })

      this.entities.balls.red.push(ball);
      ballsLayer.add(ball.getImage());
    }

    for (let i = 0; i < blueBallVectors.length; i++) {
      const ball = new Ball({
        id: `blueball-${i + 1}`,
        index: i + this.const.redBallsNumber,
        pos: blueBallVectors[i],
        image: blueBallImage,
        speed: (Victor(Math.random() - 0.5, Math.random() - 0.5)).multiply(Victor(0.1, 0.1)),
      })

      this.entities.balls.blue.push(ball);
      ballsLayer.add(ball.getImage());
    }
    ballsLayer.draw();

    this.RAF = window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  move = (time) => {
    if (!this.prevTime) {
      this.prevTime = time;
    }
    let timeDelta = time - this.prevTime;
    this.prevTime = time;

    const ballsLayer = this.ballsLayerRef?.current;
    const wallsLayer = this.wallsLayerRef?.current;


    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);


    for (let i = 0; i < this.entities.balls.red.length; i++) {
      let ball = this.entities.balls.red[i];
      ball.var.pos.add(ball.var.V.clone().multiply(Victor(timeDelta / 16.7, timeDelta / 16.7)));
      for (let k = 0; k < this.entities.walls.length; k++) {
        if (this.entities.walls[k].const.orientation == 'border') {
          this.entities.walls[k].const.orientation = 'bottom';
          this.entities.walls[k].checkCollision(ball);
          this.entities.walls[k].const.orientation = 'border';
        }
        else {
          this.entities.walls[k].checkCollision(ball);
        }
      }
      for (let j = 0; j < this.entities.balls.red.length; j++) {
        if (j === i) { continue; }
        this.entities.balls.red[j].checkCollision(ball);
      }
      for (let j = 0; j < this.entities.balls.blue.length; j++) {
        this.entities.balls.blue[j].checkCollision(ball);
      }

      let prevV = ball.var.V;
      ball.var.V.add(ball.var.F);
      let dif = ball.var.V.length() - prevV.length();
      ball.var.V.multiply(Victor(1 - Math.abs(dif / 2), 1 - Math.abs(dif / 2)));
      ball.var.F = Victor(0, 0.01);

      let ballImage = ball.getImage();
      ballImage.position({ x: ball.var.pos.x, y: ball.var.pos.y });
    }
    for (let i = 0; i < this.entities.balls.blue.length; i++) {
      let ball = this.entities.balls.blue[i];
      ball.var.pos.add(ball.var.V.clone().multiply(Victor(timeDelta / 16.7, timeDelta / 16.7)));
      for (let k = 0; k < this.entities.walls.length; k++) {
        if (this.entities.walls[k].const.orientation == 'border') {
          this.entities.walls[k].const.orientation = 'top';
          this.entities.walls[k].checkCollision(ball);
          this.entities.walls[k].const.orientation = 'border';
        }
        else {
          this.entities.walls[k].checkCollision(ball);
        }
      }
      for (let j = 0; j < this.entities.balls.red.length; j++) {
        this.entities.balls.red[j].checkCollision(ball);
      }
      for (let j = 0; j < this.entities.balls.blue.length; j++) {
        if (j === i) { continue; }
        this.entities.balls.blue[j].checkCollision(ball);
      }

      let prevV = ball.var.V;
      ball.var.V.add(ball.var.F);
      let dif = ball.var.V.length() - prevV.length();
      ball.var.V.multiply(Victor(1 - Math.abs(dif / 2), 1 - Math.abs(dif / 2)));
      ball.var.F = Victor(0, 0.01);

      let ballImage = ball.getImage();
      ballImage.position({ x: ball.var.pos.x, y: ball.var.pos.y });
    }

    ballsLayer.draw();
    wallsLayer.draw();

    this.RAF = window.requestAnimationFrame(this.move);
  }

  borderEvent = (time) => {

    if (!this.prevBorderTime) {
      this.prevBorderTime = time;
    }
    let timeDelta = time - this.prevBorderTime;
    this.prevBorderTime = time;

    if (!this.border) { return; }
    let opacity = this.border.getAttr('opacity');
    let newOpacity = opacity - timeDelta / 2000;
    if (newOpacity <= 0) {
      newOpacity = 0;
      window.cancelAnimationFrame(this.borderRAF);
    }
    else {
      this.borderRAF = window.requestAnimationFrame(this.borderEvent);
    }
    this.border.setAttr('opacity', newOpacity);
    const UILayer = this.UILayerRef?.current;
    UILayer.draw();

  }

  clickEvent = (e) => {
    if (!this.borderEventStart) {
      this.borderEventStart = true;
      for (let i = 0; i < this.entities.walls.length; i++) {
        if (this.entities.walls[i].id == 'border') {
          this.entities.walls[i].const.orientation = 'transparent';
        }
      }
    }
    else {
      return;
    }
    const target = e.target;
    this.borderRAF = window.requestAnimationFrame(this.borderEvent);
  }

  onDragDebug = (e) => {
    const target = e.target;
    console.log('position: ', target.position());
  }





  Scene = () => {
    const { code } = this.props;
    const { brownian1, brownian2, brownian3 } = getBoolLessonByCode(code);
    const [sceneimg] = useImage(SceneImage);

    return (
      <React.Fragment>

        <Layer
          ref={this.backgroundLayerRef}
        // width={window.width}
        // heght={window.height}
        >
          <KonvaImage
            image={sceneimg}
            width={520 * 2}
            height={294 * 2}
          />

        </Layer>

        <Layer
          ref={this.wallsLayerRef}>
        </Layer>
        <Layer
          ref={this.ballsLayerRef}>
        </Layer>
        <Layer
          ref={this.UILayerRef}>
          <Rect
            fill='red'
            x={435}
            y={318}
            width={620 - 435}
            height={386 - 318}
            draggable={true}
            onDragMove={this.onDragDebug}
            opacity={0}
            onClick={this.clickEvent}
            onTap={this.clickEvent}
          />
        </Layer>


      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} tabIndex={1}>
          <this.Scene />
        </CanvasContainer>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Diffusion);

const styles = {
  mainContainer: {
    background: '#eeebe5',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }
}
