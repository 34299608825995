import React from "react";
import Bubble from "./Bubble";

export default ({x, y, width, height, boiling, bubblesNum}) => {
  return (boiling
    ? ( Array(bubblesNum).fill(1)
      .map((e,i) =>
        <Bubble height={height} width={width} key={i} x={x} y={y} boiling={boiling}/>
      )
    ) : null)
}
