import {Group, Rect} from "react-konva";
import {onDragBoundFunc, onDragEnd, onDragMove, onDragStart, setInitialArrowHintPos, setLineRandom} from "./utils";
import ArrowHint from "../ArrowHint";
import React, {useEffect, useState} from "react";
import {getRandomInt} from "../../../../utils/common";
import {setInitialPosLinePos} from "./utils";


/***
 *
 * Для использования компонента, нужны внешние параметры:
 *
 * itemsRowArr - int[] - массивы строк и столбцов
 * itemsColumnArr  - int[] - массивы строк и столбцов
 * chartImgSetting - obj - объект с набором настроек
 * props = {
 *   $this - this компонента задачи для получения доступа к данным и методам
 *   onDragStart,
 *   onDragEnd,
 *   onDragMove,
 * }
 *
 * Пример использования ...src/lessons/pathandspeed/containers/Space
 *
 ***/

const CanvasChartImg = (props) => {
  const {
    $this,
    x=65,
    y=220,
    getResetCallback,
    visible=true,
  } = props;

  const chartStng = $this.data.chartImgSetting || {};
  const {
    startColumnIndex=0,
    withScenario,
    cellWidth=41.5,
    cellHeight=40,
    dropBoxContainerY,
    containerHeight=170,
    rotationDeg=0,
    selectedValues,
    rotationIndexItems=[],
    randomLinePos=false
  } = chartStng;

  const reset = () => setInitialPos();
  getResetCallback(reset);

  useEffect(() => {
    setInitialPos();
  }, [chartStng])

  const setInitialPos = () => {
    if (randomLinePos) {
      setLineRandom($this);
    } else {
      setInitialPosLinePos($this);
      setInitialArrowHintPos($this);
    }
  }

  return (
    <Group x={x} y={y} visible={visible}>
      <Group y={dropBoxContainerY}>
        {
          $this.data.itemsRowArr.map((el, rowI) => {
            return $this.data.itemsColumnArr.map((el, columnI) => {
              const isElemRotation = rotationIndexItems.includes(columnI);
              const corrIndxForX = startColumnIndex + columnI;
              return (
                <Rect
                  ref={
                    withScenario
                      ? $this._ref(`actionBox${rowI}${columnI}`)
                      : $this[`actionBox${rowI}${columnI}Ref`]
                  }
                  key={`box-${rowI}-${columnI}`}
                  x={cellWidth * corrIndxForX}
                  y={-cellHeight * rowI + (isElemRotation ? cellHeight/2 : 0)}
                  height={cellHeight}
                  width={cellWidth}
                  opacity={0.2}
                  visible={false}
                  fill={`#ea${2*columnI}${2*rowI}00`}
                  stroke={'blake'}
                  strokeWidth={.5}
                />
              )
            })
          })
        }
      </Group>
      <Group y={-containerHeight}>
        {
          $this.data.itemsColumnArr.map((el, columnI) => {
            const isElemRotation = rotationIndexItems.includes(columnI);
            const y = (isElemRotation ? cellHeight/2 : 0);
            const corrIndxForX = startColumnIndex + columnI;
            return (
              <React.Fragment key={`item-${columnI}`}>
                <Group
                  ref={
                    withScenario
                      ? $this._ref(`dragLineContainer${columnI}`)
                      : $this[`dragLineContainer${columnI}Ref`]
                  }
                  draggable
                  dragBoundFunc={(pos) => onDragBoundFunc($this, pos, columnI)}

                  onDragStart={(e) => {
                    props.onDragStart && props.onDragStart();
                    onDragStart($this, e, columnI)
                  }}
                  onDragMove={(e) => {
                    props.onDragMove && props.onDragMove();
                    onDragMove($this, e, columnI)
                  }}
                  onDragEnd={(e) => {
                    props.onDragEnd && props.onDragEnd();
                    onDragEnd($this, e, columnI)
                  }}
                  x={cellWidth * corrIndxForX}
                  y={y}
                  key={`dragAndDrop-${columnI}`}
                  visible={!chartStng[`line${columnI}Hidden`]}
                >
                  <Rect y={0} height={cellHeight} opacity={0} width={cellWidth} fill={'black'}/>
                  <Rect
                    ref={
                      withScenario
                        ? $this._ref(`dragLine${columnI}`)
                        : $this[`dragLine${columnI}Ref`]
                    }
                    rotation={isElemRotation ? rotationDeg : 0}
                    x={(isElemRotation ? 42 : cellWidth)/2}
                    offsetX={(isElemRotation ? 60 : cellWidth)/2}
                    offsetY={1.5}
                    y={(cellHeight/2)-1.5 + (isElemRotation ? 1 : 0)}
                    height={3}
                    opacity={.5}
                    width={isElemRotation ? 60 : cellWidth}
                    fill={'orange'}
                  />
                </Group>
                {
                  !columnI && (
                    <Group ref={
                      withScenario
                        ? $this._ref(`arrowHint`)
                        : $this.arrowHintRef
                    }>
                      <ArrowHint
                        imgScaleX={-1}
                        rotation={-60}
                        // x={30 * corrIndxForX + 30}
                        x={30}
                        y={y + 3 + cellHeight/2}
                        fontSize={13}
                        textX={30} textY={20}
                        text={'Перемещай \nотрезки \nграфика'}
                      />
                    </Group>
                  )
                }
              </React.Fragment>
            )
          })
        }
      </Group>
    </Group>
  )
}

export default CanvasChartImg;