import React from "react";
import News from "./News";


const TeacherContent = () => {

  return (
    <News />
  )
}

export default TeacherContent;
