import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ChooseExtraItemTask from "./ChooseExtraItemTask";
import bgNature from '../images/bgNature.png';
import imgTrue from "../images/сhooseExtraItemTask/scarecrow_true.png";
import imgFalse from "../images/сhooseExtraItemTask/scarecrow_false.png";
import { _t } from "../../../../utils/lang/common";


const LogicWheel4 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: _t("logic_wheel.two_scarecrows"),
      titleFontSize: '30px',
      items: [
        {id: 1, image: imgTrue, success: true},
        {id: 2, image: imgFalse},
      ]
    },
    {
      title: _t("logic_wheel.wheel_and_axle"),
      items: [
        {id: 1, image: imgTrue, success: true},
        {id: 2, image: imgFalse},
      ]
    },
    {
      title: _t("logic_wheel.wheel_for_cars"),
      items: [
        {id: 1, image: imgTrue},
        {id: 2, image: imgFalse, success: true},
      ]
    },
    {
      title: _t("logic_wheel.use_wheels"),
      items: [
        {id: 1, image: imgTrue},
        {id: 2, image: imgFalse, success: true},
      ]
    }
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >

      {
        chooseTasks.map((item, i) => (
          <React.Fragment key={`step${i}`}>
            {
              ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                <ChooseExtraItemTask
                  lang={'en'}
                  bgImg={bgNature}
                  title={item.title}
                  items={item.items}
                  titleFontSize={item.titleFontSize}
                  taskComplete={() => {
                    setCompletedTaskCount(prevState => prevState + 1);
                    if ((i + 1) === chooseTasks.length) {
                      setAllComplete(true);
                    }
                  }}
                />
              ) : null
            }
          </React.Fragment>
        ))
      }
    </TaskMainContainer>
  )
}
export default LogicWheel4;
