import useImage from "use-image";
import boat from "../images/Boat.png";
import { Image } from "react-konva";
import React from "react";

export const CanvasIslandEnd = ({islandImage, angle}) => {
  const width = 100;
  const height = 178;

  return <Image image={islandImage} width={width} height={height} offsetX={width/2} offsetY={height/2} rotation={angle} preventDefault={false}/>;
};
