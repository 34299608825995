import React from "react";
import CanvasContainer from "../../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import bgImg from '../../../../../images/liftingLoadMultiple/background.png';
import wheelImg from '../../../../../images/liftingLoadMultiple/wheel.png';
import cargoImg from '../../../../../images/liftingLoadMultiple/cargo.png';
import triangleGrayImg from '../../../../../images/liftingLoadMultiple/triangleGray.png';
import windingArrowImg from '../../../../../images/liftingLoadMultiple/windingArrow.png';
import supportStandImg from '../../../../../images/liftingLoadMultiple/supportStand.png';
import springboardImg from '../../../../../images/liftingLoadMultiple/springboard.png';

import ScenarioManager from "../../../../../utils/ScenarioManager";
import {getMethodsListByCode, getScenario, initialData} from "../scenario";
import MovingArrow from "../../../../canvas/components/MovingArrow";
import ArrowHint from "../../../../canvas/components/ArrowHint";
import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import {layout2, lsnLabelTxtStyle, mainColor} from "../../../../../utils/styles";
import {_t, getCorrectLang} from "../../../../../utils/lang/common";
import {getBoolCode} from "../utils";
import {hasIntersection, inputVal, sendSuccessForScenario, showFailOrSuccessPopup} from "../../../../../utils/common";
import * as actions from "../../../../../store/actions";
import Card from "../../../../canvas/components/Card";
import CanvasInput from "../../../../canvas/components/CanvasInput";
import CanvasButton from "../../../../canvas/components/CanvasButton";
import TaskComplete from "../../../../canvas/components/TaskComplete";
import CurvedArrow from "../../../../canvas/components/CurvedArrow";


class LiftingLoadMultiple extends React.Component {

  constructor(props) {
    super(props);

    this.methodsList = getMethodsListByCode(this.props.code);

    this.managedComponents = [
      'stage',

      'cargo',
      'cargo2',
      'cargo2RotationContainer',
      'cargoLine',
      'cargoLineSecond',
      'cargoLineThird',

      'titleTxt',

      'wheel1',
      'wheel1Container',

      'wheel2',
      'wheel3',
      'wheel4',
      'formula',

      'windingRode1',
      'windingRode2',
      'windingRode3',
      'hintForWindingRope',

      'windingCircle',
      'windingWheel',

      'leverHandle',
      'leverHandleArrow',
      'dragLeverHandleBox',

      'windingRopeLenText',

      'cargo2Text1',
      'cargo2Text2',

      'cargoHeight',

      'cargoText',
      'ropeText',
      'leverHandleText',

      'dragCableArrowHint',
      'selectMethodArrowHint',
      'movingArrowHintSpringboard',

      'dragLine',
      'dragRect',

      'movingArrowHint',

      'triangleActionBlock',
      'cargoActionBlock',

      ...this.methodsList.map((num) => `methodImg${num}`),
    ];

    this.data = cloneDeep(initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  _movingCallbacks = {};
  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    const {peryshkin23, peryshkin24, blocksRopeLen1 } = getBoolCode(this.props.code);
    const props = this.props;
    this.timerId = setTimeout(() => {
      if (!peryshkin23 && !peryshkin24 && !blocksRopeLen1) {
        sendSuccessForScenario(this);
      }
      if (blocksRopeLen1) {
        props.addLessonResult(props.code, true, {}, false);
      }
    }, 5000);
    this.scenarioManager.resetScenario();
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onClickReset = () => {
    this.data = cloneDeep(initialData);
    this.updateStage();
    if (this.data.resetBtnPrev) {
      this.scenarioManager.back();
    } else {
      this.scenarioManager.resetScenario();
    }
  };

  checkSuccess = () => {
    const data = this.data;
    const res = data.inputVal >= data.successRange.start && data.inputVal <= data.successRange.end;

    showFailOrSuccessPopup(this, res);
    if (res) {
      let corrKey = data.cargoName;
      if (data.cargoName === 'piano') {
        corrKey = 'bricks';
      }
      if (data.cargoName === 'bricks') {
        corrKey = 'bigCargo';
      }
      if (data.cargoName === 'bigCargo') {
        corrKey = 'piano';
      }
      this.scenarioManager.selectStepByKey(`${corrKey} initial`);
    }
  };

  getCargoLinePoints = () => {
    const data =  this.data;
    return [0, data.cargoLineLength - data.draggedOutDelta, 0, 0];
  };

  getDragLinePoints = () => {
    const data =  this.data;
    return [0, 0, -330 - data.dragLineX*data.ropeCoeff, 0];
  };

  getCargoY = () => {
    const data =  this.data;
    return data.cargoY - data.draggedOutDelta;
  };

  get windingRopePercent() {
    const data = this.data;
    const corrDraggedOutDelta = data.draggedOutDelta * data.windingRopeCoeff;
    return corrDraggedOutDelta / data.maxCargoY * 100;
  }

  getWindingRopeLen(pos) {
    const cargoLinePer = this.windingRopePercent;
    const maxWindingLen = 200;
    const resPx = cargoLinePer*2;
    const ropeSizeHelp = maxWindingLen * (pos-1);
    const res = resPx - ropeSizeHelp < 0 ? 0 : resPx - ropeSizeHelp;
    return res >= maxWindingLen ? maxWindingLen : res;
  }

  dragLineMove = (e) => {
    let data = this.data;

    const newX = data.draggedOutDelta + e.evt.movementX*data.ropeCoeff;

    if (data.draggedOutDelta >= data.maxCargoY){
      if (!data.withCalc) {
        if (data.blocksDragLineSuccessCallback) {
          data.blocksDragLineSuccessCallback(this);
        } else {
          showFailOrSuccessPopup(this, true);
        }
      }
      return;
    }

    let res = data.ropeForce;
    const isSingleBlock = data.liftingSingleBlock;
    const isDualBlock =  data.liftingDualBlock && !data.wheel4Visible;
    const isTripleBlock = data.liftingDualBlock && data.wheel4Visible;
    if (isDualBlock) {
      res = data.ropeForce * 2;
    }
    if (isTripleBlock) {
      res = data.ropeForce * 3;
    }

    if (res >= data.cargoWeight || !data.withCalc) {
      if ( e.evt.movementX > 0) {
        data.draggedOutDelta = newX > data.maxCargoY ? data.maxCargoY : newX;
      }
    }
    data.dragging = true;
  };

  dragMoveLeverHandle = (e) => {
    let data = this.data;
    const position = e.target.getPosition();
    if (data.draggedOutDelta > data.maxCargoY) {
      !data.withCalc && showFailOrSuccessPopup(this, true);
      return
    }

    let newY = position.y;
    if (position.y > data.maxLeverHandleY) {
      newY = data.maxLeverHandleY;
    }
    if (position.y < data.minLeverHandleY) {
      newY = data.minLeverHandleY;
    }

    let draggedOutDelta = 0;

    const resForce = data.leverVal * data.leverForce;
    const isLeverActive = data.leverVal && resForce >= data.cargoWeight;
    if (
      e.evt.movementY > 0 &&
      newY < data.maxLeverHandleY &&
      newY > data.minLeverHandleY &&
      (isLeverActive || !data.withCalc)
    ) {
      draggedOutDelta = e.evt.movementY/2;
    }

    data.leverHandleRotation = -newY;
    data.dragLeverHandleY = newY;
    data.dragLeverHandleX = -newY;
    data.draggedOutDelta += draggedOutDelta;

    data.dragging = true;
  };

  dragMoveCargo = (e) => {
    const data = this.data;
    const posAbs = e.target.getAbsolutePosition();
    const pos = e.target.getPosition();
    const angle = data.triangleAngle;

    if (pos.x <= -data.maxCargoX) {
      !data.withCalc && showFailOrSuccessPopup(this, true);
      return
    }

    let x = pos.x;
    let y = 8;
    let cargo2Rotation = 0;
    const triangleActionNode = this._getNode('triangleActionBlock');
    const cargoActionBoxNode = this._getNode('cargoActionBlock');
    const rectBoxIntersection = hasIntersection(triangleActionNode, cargoActionBoxNode, this);

    if (rectBoxIntersection) {
      y = pos.x * Math.tan(angle * Math.PI / 180) - angle * data.triangleYOffsetCoeff;
      cargo2Rotation = angle;


      let coeff = data.liftingTriangleTxt.width.value/data.liftingTriangleTxt.height.value;
      let res = coeff * data.liftingDragMomentum;
      if (res <= data.cargoWeight && data.withCalc) {
        return
      }
    }

    if (posAbs.x < triangleActionNode.x()) {
      y = -data.buildHeight;
    }
    if (pos.x > 450) {
      x = 450;
    }

    data.cargo2Rotation = cargo2Rotation;
    data.cargoX = x;
    data.cargoY = y;
    data.dragging = true;
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.scenarioStartTime = this.scenarioStartTime || time
    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;
    this.scenarioManager.passTimestampSec((time - this.scenarioStartTime)/1000);

    const percOfPx = data.draggedOutDelta > 0 ? data.draggedOutDelta / ((data.maxCargoY + 13.5) / 100) : 0;
    // console.log(data.maxCargoY / 100 / data.draggedOutDelta)
    data.cargoHeight = Math.round(percOfPx / data.buildingHeight);

    data.windingRope1Len = this.getWindingRopeLen(1);
    data.windingRope2Len = this.getWindingRopeLen(2);
    data.windingRope3Len = this.getWindingRopeLen(3);

    this.updateStage();
  };

  selectMethod = (num) => {
    let key = `method-${num}`;
    if (this.data.cargoName) {
      key = `${this.data.cargoName}-${key}`;
    }
    this.scenarioManager.selectStepByKey(key);
  };

  calcRotation = (factor= 1) => {
    const data = this.data;
    const wheelRadius = 8;
    // factor - чтобы учитывать подвижные блоки, которые крутятся в 2 раза медленнее (или быстрее?)
    return data.draggedOutDelta / wheelRadius / Math.PI * 180 * factor;
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    const isLiftingMethodSelected = this.methodsList.some((el, i) => data[`method${el}Visible`]);

    const liftingSingleBlock = data.liftingSingleBlock;
    const liftingDualBlock = data.liftingDualBlock;
    const liftingLeverHandle = data.liftingLeverHandle;
    const liftingInclinedSurface = data.liftingInclinedSurface;

    // Wheels rotation
    n['wheel1'].rotation(this.calcRotation(data.wheel1Factor));
    n['wheel2'].rotation(this.calcRotation(data.wheel2Factor));
    n['wheel3'].rotation(-this.calcRotation(data.wheel3Factor));
    n['wheel4'].rotation(this.calcRotation(data.wheel4Factor));

    // Arrows hints
    n['dragCableArrowHint'].visible((liftingDualBlock || liftingSingleBlock) && !data.dragging);
    n['selectMethodArrowHint'].visible(!isLiftingMethodSelected);
    n['movingArrowHintSpringboard'].visible(liftingInclinedSurface && !data.dragging);
    n['leverHandleArrow'].visible(liftingLeverHandle && !data.dragging);

    n['cargoText'].visible((liftingSingleBlock || liftingDualBlock || liftingLeverHandle) && data.dragging);
    n['ropeText'].visible((liftingSingleBlock || liftingDualBlock) && data.dragging);
    n['leverHandleText'].visible(liftingLeverHandle && data.dragging);

    n['cargo2Text1'].visible(liftingInclinedSurface && data.dragging);
    n['cargo2Text2'].visible(liftingInclinedSurface && data.dragging);


    n['dragLine'].x(data.dragLineX*data.ropeCoeff);
    n['dragLine'].y(data.dragLineY);
    n['dragLine'].points(this.getDragLinePoints());
    n['dragRect'].x(data.dragLineX*data.ropeCoeff);
    n['dragRect'].y(data.dragLineY);
    n['dragRect'].width(-330 - data.dragLineX*data.ropeCoeff);

    // Cargo and lines
    n['cargoLine'].points(this.getCargoLinePoints());
    n['cargoLineSecond'].points(this.getCargoLinePoints());
    n['cargoLineThird'].points(this.getCargoLinePoints());

    n['cargo'].y(this.getCargoY());
    n['cargo2'].position({x: data.cargoX, y: data.cargoY});
    n['cargo2RotationContainer'].rotation(data.cargo2Rotation);
    n['wheel1Container'].y(this.getCargoY());

    n['dragLeverHandleBox'].x(data.dragLeverHandleX);
    n['dragLeverHandleBox'].y(data.dragLeverHandleY);
    n['leverHandle'].rotation(data.leverHandleRotation);

    if (data.withWindingRope) {
      n['windingRode1'].points([0, 0, 0, -data.windingRope1Len]);
      n['windingRode2'].points([0, -200, 0, data.windingRope2Len-200]);
      n['windingRode3'].points([0, 0, 0, -data.windingRope3Len]);
      n['hintForWindingRope'].visible(data.dragging);


      const windingRopeLen = Math.round((data.windingRope1Len + data.windingRope2Len + data.windingRope3Len) / 20);
      n['windingRopeLenText'].text(`${windingRopeLen} ${_t('blocks_rope_len1.Meter_text1')}`);
    }

    n['titleTxt'].setAttrs({...n['titleTxt'].attrs, ...data.titleData});

    const radius = 8 + ((data.draggedOutDelta/65) + (1/data.ropeCoeff));
    n['windingCircle'].radius(radius);
    n['windingWheel'].rotation(-this.calcRotation(data.windingWheelFactor || data.wheel1Factor));

    n['cargoHeight'].text(`${data.cargoHeight} ${_t('blocks_rope_len1.Meter_text1')}`);

    const maxLiftingHeight = data.buildingHeight; // м
    let liftingHeight = Math.round(data.draggedOutDelta / (data.maxCargoY / 100) * (maxLiftingHeight/100));
    if (liftingHeight > maxLiftingHeight) {
      liftingHeight = maxLiftingHeight
    }
    n['formula'].text(`A = ${data.ropeForce||0} Н x ${liftingHeight||0} ${_t('blocks_rope_len1.Meter_text1')} = ${(data.ropeForce*liftingHeight)||0} Дж`);



    n['stage'].draw();
  }


  Canvas = () => {
    const data = this.data;

    const [bg] = useImage(bgImg);
    const [cargo] = useImage(data.cargoImg);
    const [wheel] = useImage(wheelImg);
    const [triangleGray] = useImage(triangleGrayImg);
    const [supportStand] = useImage(supportStandImg);
    const [springboard] = useImage(springboardImg);
    const [windingArrow] = useImage(windingArrowImg);

    const methodsImgs = {};

    this.methodsList.forEach((num) => {
      const imgPath = require(`../../../../../images/${data.imgDir}/liftingMethod${num}.png`);
      const [liftingMethod] = useImage(imgPath);
      methodsImgs[`img${num}`] = liftingMethod;
    });

    const isLiftingMethodSelected = this.methodsList.some((el, i) => data[`method${el}Visible`]);


    const triangleLifting = data.liftingInclinedSurface;
    return (
      <React.Fragment>
        <Group y={530}>
          <Rect width={1000} height={40} fill={'#EAA80E'}/>
          <Rect width={1000} height={8} fill={'#A0A3AC'}/>
        </Group>

        <Group scale={data.lsnMainContainerScale} y={data.lsnMainContainerY}>
          <Image y={triangleLifting ? 292 : 0} image={bg} scale={triangleLifting ? {x: .45, y: .45} : {x: 1, y: 1}}/>
          <Group visible={triangleLifting}>
            <Rect x={191} y={393} rotation={0} width={50} height={40} fill={'#F9F0D9'}/>
            <Rect x={185} y={423} rotation={0} width={23} height={100} fill={'#04913F'}/>
            <Rect width={1000} height={40} y={524} fill={'#EAA80E'}/>
          </Group>
          <Rect
            ref={this._ref('triangleActionBlock')}
            {...data.triangleActionBlockStg}
          />

          <Text
            visible={data.formulaVisible}
            x={70} y={100}
            ref={this._ref('formula')}
            text={`A = ${0} Н x ${0} ${_t('blocks_rope_len1.Meter_text1')} = ${0}`}
            fill={layout2.green}
            fontSize={30} fontStyle={'bold'}
          />

          <Group x={184.3} y={411.8}>
            <Image image={springboard} visible={triangleLifting} {...data.liftingTriangleStg}/>

            <Group visible={data.liftingTriangleTxt?.visible}>
              <Text
                {...data.liftingTriangleTxt?.width}
                text={`${data.liftingTriangleTxt?.width.value} ${_t('blocks_rope_len1.Meter_text1')}`}
                rotation={data.triangleAngle}
              />
              <Text
                {...data.liftingTriangleTxt?.height}
                text={`${data.liftingTriangleTxt?.height.value}\n${_t('blocks_rope_len1.Meter_text1')}`}
              />
            </Group>
          </Group>

          <Group>
            <Text
              text={''}
              fill={'dimgray'}
              fontSize={22}
              x={60} y={50}
              ref={this._ref('titleTxt')}
            />
            <Text visible={data.blockNameTxt} x={510} y={160} text={data.blockNameTxt} fill={mainColor} fontSize={15}/>
          </Group>

          <Group x={909} y={523} visible={data.windingBlockVisible}>
            <Image image={triangleGray} rotation={-90}/>

            <Group x={55} y={-60}>
              <Circle radius={15} fill={'lightgray'}/>
              <Circle radius={10} fill={'black'}/>
            </Group>
          </Group>


          <Group>
            <Group x={640} y={431} visible={data.triangleGrayVisible}>
              <Image image={triangleGray} />
            </Group>

            <Group visible={isLiftingMethodSelected}>
              {/*dragLine*/}
              <Group x={1000} y={473} visible={data.dragLineVisible}>
                <Line ref={this._ref('dragLine')} x={data.dragLineX} y={data.dragLineY}
                      points={this.getDragLinePoints()}
                      stroke={'black'}
                      strokeWidth={1.3}
                />
                <Rect ref={this._ref('dragRect')}
                      stroke={'black'}
                      fill={'black'}
                      x={data.dragLineX} y={data.dragLineY}
                      width={-430} height={40}
                      offsetY={20}
                      opacity={0}
                      draggable={data.dragLineActive}
                      onDragMove={(e) => this.dragLineMove(e)}
                />
              </Group>

              {/*connectorLine*/}
              <Line x={663} y={465} points={[-175,-272,0,0]} stroke={'black'} strokeWidth={1.3} visible={data.connectorLineVisible}/>

              <Line
                ref={this._ref('cargoLine')}
                x={data.cargoLineX} y={195} stroke={'black'}
                visible={data.cargoLineVisible}
                strokeWidth={1.3}
              />
              <Line
                ref={this._ref('cargoLineSecond')}
                x={452} y={202} stroke={'black'}
                visible={data.cargoLineSecondVisible}
                strokeWidth={1.3}
              />


              <Group x={790} y={455}>
                <Group  x={20} y={30} ref={this._ref('ropeText')}>
                  <Text
                    visible={data.withCalc}
                    x={-70}
                    text={'max'}
                    fontSize={30} fontStyle={'bold'}
                    fill={mainColor}
                  />
                  <Text
                    text={`${!data.withCalc ? 'F = ' : '      '}${data.ropeForce} Н`}
                    fontSize={30}                    fill={mainColor}
                    visible={data.ropeForceVisible}
                    x={-50}
                  />
                </Group>


                <MovingArrow
                  id={'dragHint'}
                  ref={this._ref('dragCableArrowHint')}
                  stageNode={this._getNode('stage')}
                  length={100}
                  arrowsStep={10}
                  arrowsCount={1}
                  textX={30} textY={-10}
                  textColor={'black'}
                  arrowColor={'black'}
                  text={_t('blocks_rope_len1.Tip_text2')}
                  rotation={-90}
                  visible={data.dragHintVisible}
                  getMovingCallback={this.getMovingCallback}
                />
              </Group>
            </Group>

            <Group x={640} y={431} visible={data.triangleGrayVisible}>
              <Image x={32} y={32} offsetY={12} offsetX={12} image={wheel} ref={this._ref('wheel3')}/>
            </Group>

            <Group visible={data.wheel1ContainerVisible} ref={this._ref('wheel1Container')}>
              <Group x={450} y={470}>
                <Line x={12.5} y={10} points={[0,0,0,20]} stroke={'black'}/>
                <Image image={wheel} x={12} y={10} offsetY={12} offsetX={12} ref={this._ref('wheel1')}/>
              </Group>
            </Group>

            <Line
              ref={this._ref('cargoLineThird')}
              x={462.5} y={198} stroke={'black'}
              visible={data.cargoLineThirdVisible}
              strokeWidth={1.3}
            />
            <Rect
              x={446} y={198}
              width={20} height={12}
              fill={'#F9F0D9'}
              visible={data.wheel4Visible}
            />

            <Group
              x={400} y={178}
              visible={data.supportStandVisible}
            >
              <Group visible={data.leverHandleVisible}>
                <Group
                  ref={this._ref('leverHandle')}
                  x={85} y={20}
                >
                  <Rect
                    rotation={30}
                    offsetX={data.leverWidth}
                    fill={layout2.green}
                    width={data.leverWidth} height={6}
                  />
                </Group>

                <Group x={-100} y={-120}>
                  <Group ref={this._ref('leverHandleArrow')} x={30} y={60} rotation={-60}>
                    <Arrow points={[0,0,30,0,]} fill={'black'} stroke={'black'}/>
                    <Arrow rotation={180} points={[0,0,30,0]} fill={'black'} stroke={'black'}/>
                  </Group>
                  <Group ref={this._ref('leverHandleText')}>
                    <Text
                      visible={data.withCalc}
                      text={`max`} x={-110} y={50}
                      fontStyle={'bold'} fontSize={30} fill={mainColor}
                    />
                    <Text
                      text={`${data.leverForce} Н`} x={-40} y={50}
                      fontStyle={'bold'} fontSize={30} fill={mainColor}
                    />
                  </Group>
                  <Rect
                    ref={this._ref('dragLeverHandleBox')}
                    draggable
                    onDragMove={(e) => this.dragMoveLeverHandle(e)}
                    fill={'black'}
                    opacity={0}
                    width={150} height={200}
                  />
                </Group>
              </Group>
              <Image x={82} y={23} offsetY={12} offsetX={12} image={wheel} ref={this._ref('wheel2')}/>
              <Image image={supportStand} />
            </Group>

            <Group x={457} y={210} visible={data.wheel4Visible}>
              <Image scale={{x: .52, y: .52}} offsetY={12} offsetX={12} image={wheel} ref={this._ref('wheel4')}/>
              <Line points={[0,0,0,-10]} stroke={'black'} strokeWidth={1.3}/>
            </Group>

          </Group>

          <Group x={405} y={475}>
            <ArrowHint
              visible={!isLiftingMethodSelected}
              rotation={190}
              imgScaleX={-1}
              x={90} y={20}
              textX={40} textY={-70}
              fontStyle={'bold'}
              text={data.cargoInitialHintText}
            />

            <Group>
              <Group
                ref={this._ref('cargo')}
                x={data.cargoX} y={data.cargoY}
                visible={!triangleLifting}
              >
                <Image image={cargo} {...data.cargoImgStg}/>
                <Group x={45} y={35} ref={this._ref('cargoText')}>
                  <Group
                    x={20} y={20}
                    visible={data.cargoWeightLetter}
                  >
                    <Arrow
                      x={1} y={-2}
                      points={[0,0,55,0]} scale={{x: .3, y: .3}} stroke={mainColor} fill={mainColor}/>
                    <Text
                      text={'P'}
                      fontSize={30} fontStyle={'bold'}
                      fill={mainColor}
                    />
                  </Group>
                  <Text visible={!data.cargoWeightLetter && data.cargoGravityTxt } x={20} y={20} text={`${data.cargoWeight} Н`} fontSize={30} fontStyle={'bold'} fill={mainColor}/>
                  <Arrow points={[0,0,0,60]} stroke={'black'} fill={'black'} visible={data.cargoGravityTxt}/>
                </Group>
                <Text ref={this._ref('cargoHeight')} visible={data.cargoHeightVisible} x={75} y={20} text={`${data.cargoHeight} ${_t('blocks_rope_len1.Meter_text1')}`} fontSize={30} fontStyle={'bold'} fill={mainColor}/>
              </Group>

              {/* cargo 2 */}
              <Group x={30} y={20}>
                <Group
                  ref={this._ref('cargo2')}
                  draggable
                  onDragMove={(e) => this.dragMoveCargo(e)}
                  visible={triangleLifting}
                  x={data.cargoX} y={data.cargoY}
                >
                  <Group
                    ref={this._ref('cargo2RotationContainer')}
                    offsetX={30}
                    offsetY={20}
                    rotation={0}
                  >
                    <Rect ref={this._ref('cargoActionBlock')} x={14} y={14} width={33} height={28} fill={'red'} opacity={0}/>

                    <Image
                      {...data.cargoImgStg}
                      image={cargo}
                      scale={triangleLifting ? {x: .7, y: .7} : {x: 1, y: 1}}/>

                    <Group visible={triangleLifting}>
                      <Circle x={23} y={37} radius={4} fill={layout2.green}/>
                      <Circle x={38} y={37} radius={4} fill={layout2.green}/>
                    </Group>

                    <Group x={30} y={25} ref={this._ref('cargo2Text1')}>
                      <Text visible={data.withCalc} x={-175} y={-25} text={`max`} fontSize={25} fill={mainColor} fontStyle={'bold'}/>
                      <Text x={-115} y={-25} text={`${data.liftingDragMomentum} Н`} fontSize={25} fill={mainColor} fontStyle={'bold'}/>
                      <Arrow points={[0,0,-30,0]} stroke={mainColor} fill={mainColor}/>
                    </Group>
                  </Group>

                  <Group x={0} y={4} ref={this._ref('cargo2Text2')}>
                    <Group
                      x={15} y={20}
                      visible={data.cargoWeightLetter}
                    >
                      <Arrow
                        x={1} y={-2}
                        points={[0,0,55,0]} scale={{x: .3, y: .3}} stroke={mainColor} fill={mainColor}/>
                      <Text
                        text={'P'}
                        fontSize={30} fontStyle={'bold'}
                        fill={mainColor}
                      />
                    </Group>
                    <Text visible={!data.cargoWeightLetter} x={15} y={20} text={`${data.cargoWeight} Н`} fontStyle={'bold'} fontSize={30} fill={mainColor}/>
                    <Arrow points={[0,0,0,60]} stroke={mainColor} fill={mainColor}/>
                  </Group>
                </Group>
              </Group>

            </Group>

          </Group>




          {/* winding wheel */}
          <Group
            x={964} y={463}
            visible={data.windingBlockVisible}
          >

            {/* hide drag line */}
            <Rect x={10} y={9} width={20} height={5} fill={'#fcf0d7'}/>
            <Rect x={30} y={9} width={7} height={5} fill={'#DED6C4'}/>
            <Circle x={7} y={7} radius={5} fill={'lightgray'}/>

            <Group x={13} y={0} visible={data.withWindingRope}>
              <Group x={-280} y={-250} ref={this._ref('hintForWindingRope')}>
                <CurvedArrow x={180} y={120} rotation={-90} scale={{x:.8, y:.8}}/>
                <Text x={70} text={_t('blocks_rope_len1.Tip_text3')} fill={'black'} fontSize={16} lineHeight={1.2}/>
              </Group>

              <Text
                x={-110} y={-120}
                ref={this._ref('windingRopeLenText')}
                text={`0 ${_t('blocks_rope_len1.Meter_text1')}`}
                fill={'black'}
                fontSize={20}
                width={150}
                align={'center'}
              />

              <Group x={-2}>
                <Line points={[0,0,0,-200]} stroke={'#C7C7C7'} strokeWidth={1.3}/>
                <Line x={10} points={[0,0,0,-200]} stroke={'#C7C7C7'} strokeWidth={1.3}/>
                <Line x={20} points={[0,0,0,-200]} stroke={'#C7C7C7'} strokeWidth={1.3}/>
                <Line ref={this._ref('windingRode1')} points={[0,0,0,0]} stroke={'black'} strokeWidth={1.3}/>
                <Line ref={this._ref('windingRode2')} x={10} points={[0,0,0,0]} stroke={'black'} strokeWidth={1.3}/>
                <Line ref={this._ref('windingRode3')} x={20} points={[0,0,0,0]} stroke={'black'} strokeWidth={1.3}/>
              </Group>

              <Circle x={13} radius={6} fill={'#C7C7C7'}/>
              <Group x={3} y={-200}>
                <Circle radius={6} fill={'#C7C7C7'}/>
                <Circle x={20} radius={6} fill={'#C7C7C7'}/>
              </Group>
            </Group>

            <Circle radius={10} fill={'black'}/>
            <Circle radius={10} fill={'black'} ref={this._ref('windingCircle')} visible={data.windingCircleVisible}/>
            <Image image={wheel} offsetY={12} offsetX={12} ref={this._ref('windingWheel')}/>
          </Group>


          <Group x={800} y={200} visible={data.questionCardSettings?.visible}>
            <Card width={170} height={130} {...data.questionCardSettings?.card}>
              <Text x={25} y={20} text={'12'} {...lsnLabelTxtStyle} fill={mainColor} {...data.questionCardSettings?.label}/>
              <CanvasInput
                id={'1'}
                y={70}
                x={25}
                width={100}
                height={30}
                stage={this._getNode('stage')}
                textColor={mainColor}
                onInput={(val) => {
                  this.data.inputVal = val;
                }}
                value={this.data.inputVal}
                {...data?.input}
              />
            </Card>
            <CanvasButton
              text={_t('peryshkin23.btn')}
              onClick={() => data.btnCheckSuccessCallback(this)}
              fontSize={17}
              strokeWidth={.2}
              btnCornerRadius={5}
              height={40}
              width={140}
              x={15}
              y={140}
              {...data.questionCardSettings?.btn}
            />

            <Image
              x={150} y={75}
              image={windingArrow}
              visible={data.windingArrowHintVisible}
            />
          </Group>


          <MovingArrow
            id={'method4Springboard'}
            ref={this._ref('movingArrowHintSpringboard')}
            stageNode={this._getNode('stage')}
            length={90}
            arrowsStep={10}
            arrowsCount={1}
            x={data.triangleHintX} y={460}
            textColor={'black'}
            arrowColor={'black'}
            text={''}
            // rotation={105}
            rotation={93 + data.triangleAngle}
            visible={triangleLifting}
            {...data.arrowHintSpringboardStg}
            getMovingCallback={this.getMovingCallback}
          />
        </Group>

        <Group
          x={950} y={0}
          offsetX={this.methodsList.length * data.methodsImgsStep}
          scale={data.methodsContainerScale}
          visible={!data.withoutSelectingMethods}
        >
          {
            this.methodsList.map((num, i) => {
              const refKey = `methodImg${num}`;
              const node = this._getNode(refKey);

              if (!methodsImgs[`img${num}`]) {
                return <Group ref={this._ref(refKey)} key={`methodImg-${num}`}/>}

              return (
                <Image
                  ref={this._ref(refKey)}
                  key={`methodImg-${num}`}
                  onMouseLeave={() => {
                    if (!data[`method${num}Visible`]) {
                      node.scale({x: 1, y: 1})
                      node.offset({x: 0, y: 0});
                    }
                  }}
                  onMouseEnter={() => {
                    if (!data[`method${num}Visible`]) {
                      node.scale({x: 1.1, y: 1.1})
                      node.offset({x: 10, y: 10});
                    }
                  }}
                  x={i * data.methodsImgsStep}
                  width={180} height={180}
                  scale={data[`method${num}Visible`] ? {x: 1.1, y: 1.1} : {x: 1, y: 1}}
                  offset={data[`method${num}Visible`] ? {x: 10, y: 10} : {x: 0, y: 0}}
                  image={methodsImgs[`img${num}`]}
                  opacity={!isLiftingMethodSelected || data[`method${num}Visible`] ? 1 : .4}
                  onClick={() => this.selectMethod(num)}
                  onTap={() => this.selectMethod(num)}
                />
              )
            })
          }
          <MovingArrow
            id={'selectMethod'}
            ref={this._ref('selectMethodArrowHint')}
            stageNode={this._getNode('stage')}
            length={30}
            arrowsStep={10}
            arrowsCount={1}
            x={90} y={200}
            textColor={'black'}
            arrowColor={'black'}
            text={''}
            rotation={180}
            visible={!isLiftingMethodSelected}
            getMovingCallback={this.getMovingCallback}
          />
        </Group>


        <Group x={650} y={200} visible={data.checkCargoWeightVisible}>
          <Text text={'Введи примерный \nвес груза (Р):'} fontStyle={'bold'} fontSize={17} fill={mainColor}/>
          <Card y={45} width={220} height={60}>
            <Group x={15} y={10}>
              <Text y={7} text={'P = '} fontStyle={'bold'} fontSize={30} fill={mainColor}/>
              <CanvasInput
                id={'1'}
                x={55}
                width={90}
                height={40}
                fontSize={30}
                stage={this._getNode('stage')}
                onInput={(val) => {
                  data.inputVal = Number(inputVal(val));
                }}
                value={data.inputVal}
              />
              <Text x={150} y={7} text={' H '} fontStyle={'bold'} fontSize={30} fill={mainColor}/>
            </Group>
          </Card>
          <CanvasButton
            x={40} y={115}
            text={_t('peryshkin23.btn')}
            onClick={this.checkSuccess}
            fontSize={18}
            height={30}
            width={140}
            strokeWidth={.2}
          />
        </Group>

        <CanvasResetBtn y={525} onClick={() => this.onClickReset()}/>


        <TaskComplete
          x={600} y={50}
          visible={data.completeLsnVisible}
          fontSize={20}
          withoutBackground
          text={_t('peryshkin23.complete')}
        />
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiftingLoadMultiple);

const styles = {
  mainContainer: {
    background: '#F9F0D9'
  }
};
