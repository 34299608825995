import { msToKmh } from "../../../../utils/common";
import React from "react";
import {_t} from "../../../../utils/lang/common";
import {Input} from 'antd';

export const InputsBlock = (props) => {
  const {angle, running, flowRate, boatSpeed, onChangeInput} = props;
  return (
    <>
      <div style={styles.fieldsContainer}>
        <label style={styles.fieldsContainerLabel}>
          <span>{_t("ship_angle")}</span>
          <Input
            type="number"
            placeholder={ '' }
            onChange={ (e) => onChangeInput('angle', e.target.value) }
            value={ angle !== undefined ? angle : '' }
            step={.01}
            disabled={ running }
            style={styles.fieldsContainerInput}
            min={ -90 }
            max={ 90 }
          />
        </label>
        <label style={styles.fieldsContainerLabel}>
          <span>{_t("ship_speed_of_the_current")}</span>
          <Input
            type="number"
            placeholder={ '' }
            onChange={ (e) => onChangeInput('flowRate', e.target.value) }
            value={ flowRate }
            disabled={ running }
            style={styles.fieldsContainerInput}
            max={ 100 }
          />
        </label>
        <label style={styles.fieldsContainerLabel}>
          <span>{_t("ship_boat_speed")}</span>
          <Input
            type="number"
            placeholder={ '' }
            onChange={ (e) => onChangeInput('boatSpeed', e.target.value) }
            value={ boatSpeed }
            disabled={ running }
            style={styles.fieldsContainerInput}
            max={ 100 }
          />
        </label>
      </div>
      <div
        className={ 'start-position guide-line' }
        style={{...styles.guideLine, transform: `rotate(${ angle !== undefined ? angle : 0 }deg)`}}
      >
        <div style={styles.guideLineAngle}>
          <div style={styles.guideLineAngleBtn}/>
        </div>
      </div>
    </>
  )
};

const styles = {
  fieldsContainer: {
    position: 'absolute',
    width: '30%',
    maxWidth: '230px',
    marginTop: '-60px!important',
    padding: '10px',
    // transform: 'translateX(-210px)',
    bottom: '10%',
    zIndex: 10,
    backgroundColor: 'rgba(255,255,255,0.7)',
    borderRadius: 10,
    fontSize: '1.5vmin',
    left: '14%'
  },
  fieldsContainerLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fieldsContainerSpan: {
    textAlign: 'end'
  },
  fieldsContainerInput: {
    width: '30%',
    maxWidth: '75px',
    borderRadius: '5px',
    border: '1px solid rgba(0,0,0,0.5)',
    padding: '.8vmin',
    margin: '.4vmin 1vmin',
    fontSize: '1.5vmin',
  },

  guideLine: {
    height: '38%',
    overflow: 'hidden',
    width: '36%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: '9.5%',
    position: 'absolute',
    marginRight: '-13px'
  },
  guideLineAngle: {
    display: 'block',
    position: 'absolute',
    top: '0',
    height: '46%',
    width: '.4vmin',
    background: 'white',
  },
  guideLineAngleBtn: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    top: '0',
    left: '-4px',
    background: 'brown',
    border: '0',
    borderRadius: '50%',
    zIndex: '10',
    cursor: 'pointer',
  },
};
