import React, {useState, useEffect} from "react";
import * as actions from '../../../store/actions';
import {connect} from "react-redux";
import {_t} from "../../../utils/lang/common";
import {Form, Button, Input} from 'antd';
import AuthFormWrapper from './AuthFormWrapper';
import {useQuery} from "../../../utils/custom-hooks/useQuery";
import {useHistory} from "react-router-dom";


const ChangePasswordForm = (props) => {
  const {match} = props;
  const {params} = match;

  const [error, setError] = useState('');
  const [password, setPassword] = useState(undefined);
  const [repeatPassword, setRepeatPassword] = useState(undefined);
  const history = useHistory();

  const isPasswordNotIdentical = password && repeatPassword && (password !== repeatPassword);

  const onChange = (e, isRepeat) => {
    const value = e.target.value;

    if (!isRepeat) {
      setPassword(value);
    } else {
      setRepeatPassword(value);
    }
  };

  const nextPath = useQuery().get('next');

  const sendPassword = async (e) => {
    e.preventDefault();
    if (!isPasswordNotIdentical) {
      const error = await props.changePassword(password, params.code);
      if (!error) {
        return history.push('/');
      }
      setError(error);
    } else {
      setError('Пароли должны быть идентичны');
    }
  };
  const requiredFieldErrTxt = `Поле обязательно для заполнения`;
  return (
    <AuthFormWrapper title={'Изменить пароль'} titleFontSize={18}>
      <form
        method={'POST'}
        onSubmit={sendPassword}
      >
        <Form.Item rules={[{ required: true, message: requiredFieldErrTxt }]} name={'password'}>
          <Input.Password
            id={'passwordRegistrForm'}
            className={'error'}
            name={'password'}
            type="password"
            placeholder={_t("placeholder_password")}
            style={styles.input}
            value={password}
            onChange={(e) => onChange(e, false)}
          />
        </Form.Item>
        <Form.Item rules={[{ required: true, message: requiredFieldErrTxt }]} name={'repeatPassword'}>
          <Input.Password
            id={'repeatPasswordRegistrForm'}
            className={'error'}
            name={'repeatPassword'}
            type="password"
            placeholder={_t("placeholder_repeat_password")}
            style={styles.input}
            value={repeatPassword}
            onChange={(e) => onChange(e, true)}
          />
        </Form.Item>
        <p className={'error-text'}>{error || ''}</p>
        <Button type="primary" htmlType="submit" style={{margin: '10px auto'}}>
          Отправить пароль
        </Button>
      </form>
    </AuthFormWrapper>
  )
};

const mapStateToProps = (state) => ({
  lang: state.lessonsReducer.lang,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (password, code) => dispatch(actions.changePassword(password, code)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordForm);


const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  card: {
    width: '300px',
    marginTop: '-50px'
  },
  input: {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  link: {
    fontSize: '18px'
  }
};
