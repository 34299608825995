



export const RESET_CATALOG_SELECTED_ITEMS = 'RESET_CATALOG_SELECTED_ITEMS';
export const resetCatalogSelectedItems = () => {
  return {
    type: RESET_CATALOG_SELECTED_ITEMS,
    payload: {},
  }
};

export const SET_SHOW_ALL_LESSONS = 'SET_SHOW_ALL_LESSONS';
export const setShowAllLessons = (status) => {
  return {
    type: SET_SHOW_ALL_LESSONS,
    payload: status,
  }
};

export const SET_CATALOG_GRADE = 'SET_CATALOG_GRADE';
export const setCatalogGrade = (grade) => {
  return {
    type: SET_CATALOG_GRADE,
    payload: grade,
  }
};
export const SET_CATALOG_CHAPTER = 'SET_CATALOG_CHAPTER';
export const setCatalogChapter = (chapter) => {
  return {
    type: SET_CATALOG_CHAPTER,
    payload: chapter,
  }
};
export const SET_CATALOG_PARAGRAPH = 'SET_CATALOG_PARAGRAPH';
export const setCatalogParagraph = (paragraph) => {
  return {
    type: SET_CATALOG_PARAGRAPH,
    payload: paragraph,
  }
};
export const SET_CATALOG_LESSON = 'SET_CATALOG_LESSON';
export const setCatalogLesson = (lesson) => {
  return {
    type: SET_CATALOG_LESSON,
    payload: lesson,
  }
};
