import React from "react";
import {CanvasContainer} from "../../../canvas";
import {Layer, Group, Rect, Text, Image, Line, Circle, Arrow} from "react-konva";
import cloneDeep from "lodash.clonedeep";
import Indicator from "../../../canvas/components/Indicator";
import useImage from "use-image";
import backgroundImg from "../../../../images/loop/background.png";
import wheelImg from "../../../../images/loop/wheel.png";
import jeepImg from "../../../../images/loop/jeep.png";
import carImg from "../../../../images/loop/car.png";
import {degToRad, radToDeg} from "../../../../utils/common.js";


class TrackLoop extends React.Component{
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();

    this.carRef = React.createRef();
    this.startBtnRef = React.createRef();


    this.staticData = {
      maxHeight: 50,
      maxHeightPx: 225,
      maxRangeY: 200,
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      start: false,

      // Параметры относящиеся к машинке
      height: 0,
      maxCarHeight: 0,
      length: 0,
      carVelocity: 0,
      carAcceleration: 0,
      carMass: 10,
      carAngle: 63,
      velocityOfFallX: 0,
      velocityOfFallY: 0,

      counter: 0,

      // Параметры относящиеся к треку
      firstPath: 350,
      secondPath: 400,
      thirdPath: 510,
      forthPath: 800,
      fifthPath: 1320,
      sixPath: 1700,

      lengthOfPath: 1700,

      gravity: 9.8,
    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }


  metrToPixels(metr){
    return metr * 10;
  }
  // Задание трека аналитическими функциями
  // В каждой точке трека известна координата и угол поворота машинки
  loop(){
    let trackloop = [];
    let trackX = -0.5;
    let trackY = 0;
    let trackXprev = -0.5;
    let trackYprev = 0;
    let carAngle = 63;
    // +100 чтобы машинке было куда ехать в конце пути
    for (let i = 0; i<this.data.lengthOfPath+100; i++){
      if (i<this.data.firstPath){
        trackX = i/2.05;
        trackY += 1;
      } else if (i > this.data.firstPath && i < this.data.secondPath) {
        trackX = (Math.pow(i-this.data.firstPath, 1.1 ) + this.data.firstPath)/2.05;
        trackY += 1;
      } else if (i > this.data.secondPath && i < this.data.thirdPath){
        trackX += 1;
        trackY = (Math.pow(trackXprev - 206, 2/3) + 195)*2.05;
      } else if (i > this.data.thirdPath && i < this.data.forthPath){
        trackX += 1;
      } else if (i > this.data.forthPath && i < this.data.fifthPath){
        trackX += 1.08 * Math.sin(41.6 + i/80);
        trackY += 1.08 * Math.cos(41.6 + i/80);
      } else if (i > this.data.fifthPath && i < this.data.sixPath){
        trackX += 1;
      }

      // Условия для определения правильного угла машинки
      if ((trackX - trackXprev+0.01) > 0){
        carAngle = radToDeg(Math.atan((trackY - trackYprev) / (trackX - trackXprev+0.01)));
      } else {
        carAngle = radToDeg(Math.atan((trackY - trackYprev) / (trackX - trackXprev+0.01)) - Math.PI);
      }
      trackloop.push([trackX, trackY, carAngle]);
      trackXprev = trackX;
      trackYprev = trackY;
    }
    return trackloop
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    let coord = this.loop();
    // coord[data.counter][0] - x машинки
    // coord[data.counter][1] - y машинки
    // coord[data.counter][2] - угол машинки

    if (data.start) {

      data.carAcceleration = data.gravity * Math.cos(degToRad(90) - degToRad(coord[data.counter][2]));
      data.carAcceleration = this.metrToPixels(data.carAcceleration);
      // Условие отрыва машинки от трека
      if (this.metrToPixels(data.gravity) * Math.cos(degToRad(coord[data.counter][2])) +
      Math.pow(data.carVelocity, 2)/78 < 0 && data.counter > data.forthPath){
        data.carAcceleration = this.metrToPixels(data.gravity);
        data.velocityOfFallY += data.carAcceleration * timedeltaSec;
        data.velocityOfFallX = data.carVelocity * Math.cos(degToRad(coord[data.counter][2]));
        data.height += data.velocityOfFallY * timedeltaSec;
        data.length += data.velocityOfFallX * timedeltaSec;
        // Условие падения на трек
        if (data.height > 466){
          data.start = false;
          alert('Crash!');
        }
      } else {
        data.height = coord[data.counter][1];
        data.length = coord[data.counter][0];
        data.carVelocity += data.carAcceleration * timedeltaSec;
        data.counter += Math.round(data.carVelocity * timedeltaSec);
        data.carAngle = coord[data.counter][2];
      }

      if (Math.abs(coord[data.counter+1][2] - coord[data.counter][2]) < 30 &&
      Math.abs(coord[data.counter+1][2] - coord[data.counter][2]) > 250){
        coord[data.counter+1][2] = coord[data.counter][2];
      }

      // Конец пути
      if (data.counter > data.lengthOfPath){
        data.start = false;
        alert('Finish!');
      }
    }

    this.updateStage();
  };

  updateStage = () => {
    const data = this.data;

    const carNode = this.carRef?.current;
    const startBtnTextNode = this.startBtnRef?.current;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    carNode.y(data.height);
    carNode.x(data.length);
    carNode.rotation(data.carAngle);

    startBtnTextNode.text(data.start ? 'Сбросить' : 'Отпустить');

    stageNode.draw();
  };

  startResetOnClick = () => {
    if (this.data.start) {
      this.data.start = false;
      this.data = cloneDeep(this.initialData);
    } else {
      this.data.start = true;
    }
  };

  dragBoundFunc = (pos) => {
    /**
     *  Function to limit movement
     */
    const stageNode = this.stageRef?.current;
    const stageWidth = stageNode.width();
    const stageHeight = stageNode.height();

    const leftBorderX = stageWidth * .04;
    const rightBorderX = stageWidth * .04;
    const topBorderY = stageHeight * .41;
    const bottomBorderY = stageHeight * .84;

    const newX = pos.x >= rightBorderX ? rightBorderX : ( pos.x <= leftBorderX ? leftBorderX : pos.x );
    const newY = pos.y <= topBorderY ? topBorderY : ( pos.y >= bottomBorderY ? bottomBorderY : pos.y );
    return {y: newY, x: newX};
  };

  onDragMove = (e) => {
    const {maxRangeY, maxHeight} = this.staticData;
    const data = this.data;
    const currNode = e.target;
    const rangeControllerY = currNode.getAbsolutePosition().y - 287;
    const coef = maxHeight / maxRangeY;

    // Начальное положение машинки, изменяющееся ползунком
    let coord = this.loop();
    data.counter = rangeControllerY;
    data.height = coord[data.counter][1];
    data.maxCarHeight = coord[data.counter][1];
    data.length = coord[data.counter][0];
    data.carAcceleration = 0;
    data.carVelocity = 0;


    data.start = false;
  };

  indicatorData = () => {
    const data = this.data;
    const {maxHeightPx} = this.staticData;
    return [
      {progressRefCode: 'potentialEnergy',
      title: 'Потенциальная энергия:',
      char: '',
      // mgh_max/mgh
      value: () => 100 * (445 - data.height) / (445 - data.maxCarHeight),
      maxValue: 100},
      {progressRefCode: 'kineticEnergy',
      title: 'Кинетическая энергия:',
      char: '',
      // mv^2 / (2 * gh_max)
      value: () => 100 * Math.pow(data.carVelocity, 2) / (2 * 20 * data.gravity * (445 - data.maxCarHeight)),
      maxValue: 100},
      {progressRefCode: 'liftingHeight',
      title: 'Высота подъема, м:',
      char: '',
      value: () => 20,
      maxValue: 100},
    ]
  }

  CanvasPendulum = () => {

    const [car] = useImage(carImg);
    const [wheel] = useImage(wheelImg);
    const [jeep] = useImage(jeepImg);
    const [background] = useImage(backgroundImg);
    const color = '#b1b3b2';
    return (
      <Group>
        <Image image={background} preventDefault={false}/>
        {/*<Image image={wheel} x={0} width={10} height={10} preventDefault={false}/>*/}
        {/*<Image image={wheel} x={0} width={10} height={10} preventDefault={false}/>*/}
        {/*<Image image={jeep} x={0} width={100} height={30} preventDefault={false}/>*/}
        <Group x={25} y={130}>
          <Image ref={this.carRef} image={car}
          x={0} width={40} height={15}
          rotation={63}/>
          {/*<Rect ref={this.carRef} width={30} height={20} fill={'#ca365d'} rotation={63}/>*/}
        </Group>


        <Group x={400} y={50}>
          <Indicator
            data={this.indicatorData}
            progressX={220}
            indicatorName={'pendulumIndicator'}
            charWidth={10}
          />
        </Group>
      </Group>
    )
  };

  CanvasButtons = () => {

    const buttonsSize = {
      width: 200,
      height: 60
    };
    const color = '#b1b3b2';
    return (
      <Group x={20} y={420}>

        <Group
          y={200}
          onClick={() => this.startResetOnClick()}
        >
          <Rect {...buttonsSize} fill={color} cornerRadius={10} />
          <Text ref={this.startBtnRef} text={'Отпустить'} stroke={'white'} fill={'white'} strokeWidth={.5} fontSize={24} align={'center'} verticalAlign={'middle'} {...buttonsSize}/>
        </Group>

        <Group y={-130} x={20}>
          <Line points={[0, 0, 0, 300]} stroke={color} strokeWidth={4}/>

          <Circle
            x={0}
            ref={this.rangeControllerRef}
            draggable
            radius={10}
            fill={'#10428e'}
            dragBoundFunc={this.dragBoundFunc}
            onDragMove={this.onDragMove}
          />
        </Group>
      </Group>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasPendulum/>
            <this.CanvasButtons/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}

export default TrackLoop;

const styles = {
  mainContainer: {
    background: '#A8DADC',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
};
