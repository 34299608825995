import CanvasButton from "./CanvasButton";
import React from "react";
import {layout2} from "../../../utils/styles";
import {Group} from "react-konva";
import playImg from '../img/play.png'
import useImage from "use-image";


const CanvasPlayBtn = React.forwardRef((props, ref) => {
  const {
    x=850,
    y=500,
    width=95,
    height=30,
    fontSize=15,
    onClick,
  } = props;
  const [play] = useImage(playImg);
  return (
    <CanvasButton
      btnRef={ref}
      text={'Старт'}
      prefixImg={play}
      prefixY={1}
      prefixWidth={18}
      prefixHeight={18}
      onClick={() => onClick()}
      fontSize={fontSize}
      strokeWidth={.2}
      btnCornerRadius={0}
      btnFill={layout2.orange}
      width={width}
      textWidth={width-20}
      height={height}
      align={'right'}
      x={x}
      y={y}
    />
  )
})

export default CanvasPlayBtn;