import React from "react";
import CanvasContainer from "../../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import backgroundImg from "../../../../../images/power1W/background.png";
import formulaImg from "../../../../../images/power1W/formula.png";
import controllerImg from "../../../../../images/power1W/controller.png";
import wheelImg from "../../../../../images/power1W/wheel.png";
import carImg from "../../../../../images/power1W/car.png";
import timerImg from "../../../../../images/power1W/timer.png";
import hookStartImg from "../../../../../images/power1W/hookStart.png";
import {getTimeDeltaSec, sendSuccessForScenario} from "../../../../../utils/common";
import ArrowHint from "../../../../canvas/components/ArrowHint";
import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import * as actions from "../../../../../store/actions";
import ScenarioManager from "../../../../../utils/ScenarioManager";


class Power1W extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',

      'hookStart',
      'startHookArrowHint',
      'forceArrowHint',

      'workTxt',
      'timerText',
      'timeTxt',
      'resultTxt',
      'stopwatchTxt',

      'car',
      'wheelLeft',
      'wheelRight',
    ]

    this.staticData = {
      hookYLimit: -10,
      hookRotationLimit: 60,
      initialCarSpeed: 1,
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timedeltaSec: 0,
      timer: 0,

      start: false,
      startHookPush: false,

      carSpeed: 1,
      carPath: 0,

      hookY: 0,
      hookRotation: 0,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  onStart = (status) => {
    const data = this.data;
    if (!data.start) {
      data.start = true;
      data.startHookPush = true;
    } else {
      data.startHookPush = !data.start;
    }
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };

  mToPx = (meters) => meters * 300;

  get carPath() {
    const data = this.data;
    return data.carPath + (data.start ? this.mToPx(data.timedeltaSec * data.carSpeed) : 0);
  }
  get carSpeed() {
    const data = this.data;
    if (data.startHookPush) {
      return this.staticData.initialCarSpeed;
    }
    const corrSpeed = data.carSpeed > 0 && !data.startHookPush ? data.carSpeed - .005 : data.carSpeed;
    return corrSpeed < 0 ? 0 : corrSpeed;
  }
  get timer() {
    const data = this.data;
    const time = data.timer + data.timedeltaSec;
    return data.startHookPush ? time : data.timer;
  }
  get hookY() {
    const data = this.data;
    const { hookYLimit } = this.staticData;
    const coeff = 22 * data.timedeltaSec;
    if (data.startHookPush) {
      return hookYLimit < data.hookY ? data.hookY - coeff : hookYLimit;
    }
    return 0;
  }
  get hookRotation() {
    const data = this.data;
    const { hookRotationLimit } = this.staticData;
    const coeff = 120 * data.timedeltaSec;
    if (data.startHookPush) {
      return hookRotationLimit > data.hookRotation ? data.hookRotation + coeff : hookRotationLimit;
    }
    return 0;
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = getTimeDeltaSec(timedelta);

    data.timedeltaSec = timedeltaSec/2;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.hookY = this.hookY;
    data.hookRotation = this.hookRotation;
    data.timer = this.timer;
    data.carPath = this.carPath;
    data.carSpeed = this.carSpeed;

    if (data.start && !data.startHookPush && !data.sendResult) {
      sendSuccessForScenario(this);
      data.sendResult = true;
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    const time = Number(data.timer.toFixed(1));
    n['stopwatchTxt'].text(`${time > 1 ? 1 : time}с`);
    n['workTxt'].text(time);
    n['timeTxt'].text(time);
    n['resultTxt'].text(Number(data.start));
    n['hookStart'].y(data.hookY);
    n['hookStart'].rotation(data.hookRotation);

    n['forceArrowHint'].visible(data.startHookPush);
    n['startHookArrowHint'].visible(!data.start);
    n['car'].x(data.carPath);
    n['wheelLeft'].rotation(data.carPath*data.timedeltaSec*1000);
    n['wheelRight'].rotation(data.carPath*data.timedeltaSec*1000);

    n['stage'].draw();
  }


  Canvas = () => {

    const [background] = useImage(backgroundImg);
    const [formula] = useImage(formulaImg);
    const [controller] = useImage(controllerImg);
    const [wheel] = useImage(wheelImg);
    const [car] = useImage(carImg);
    const [timer] = useImage(timerImg);
    const [hookStart] = useImage(hookStartImg);

    return (
      <React.Fragment>
        <Group>
          <Image image={background}/>

          <Group x={460} y={40}>
            <Image image={formula} width={500} height={160}/>

            <Text
              ref={this._ref('workTxt')}
              x={120} y={40} text={'0'} fontStyle={'bold'} fontSize={30} width={65} align={'center'} fill={'#4D4D4D'}
            />
            <Text
              ref={this._ref('timeTxt')}
              x={120} y={100} text={'0'} fontStyle={'bold'} fontSize={30} width={65} align={'center'} fill={'#4D4D4D'}
            />
            <Text
              ref={this._ref('resultTxt')}
              x={325} y={68} text={'0'} fontStyle={'bold'} fontSize={30} width={65} align={'center'} fill={'#4D4D4D'}
            />
          </Group>


          <Group x={60} y={150}>
            <ArrowHint
              ref={this._ref('startHookArrowHint')}
              x={140} y={-45}
              color={'white'}
              text={'Нажми \nна газ'}
              fontStyle={'bold'}
              fontSize={17}
              imgScaleX={-1.5}
              imgScaleY={1.5}
              arrowX={-20}
              arrowY={140}
              rotation={190}
            />

            <Group x={80} y={69}>
              <Image image={hookStart} ref={this._ref('hookStart')}/>
            </Group>

            <Image image={controller}/>

            <Group
              x={80} y={70}
              onMouseDown={() => this.onStart(true)}
              onMouseUp={() => this.onStart(false)}
              onTouchStart={() => this.onStart(true)}
              onTouchEnd={() => this.onStart(false)}
            >
              <Rect x={-30} y={-3} width={100} height={50} fill={'red'} opacity={0}/>
            </Group>
          </Group>

          <Group x={365} y={250}>
            <Image image={timer}/>
            <Text ref={this._ref('stopwatchTxt')} x={20} y={35} width={40} align={'center'} text={'1c'} fontStyle={'bold'} fontSize={18} fill={'#4D4D4D'}/>
          </Group>

          <Group y={427}  ref={this._ref('car')}>

            <Group x={17} y={29}>
              <Image x={0} image={wheel} ref={this._ref('wheelLeft')} offsetY={11} offsetX={11}/>
              <Image x={65} image={wheel} ref={this._ref('wheelRight')} offsetY={11} offsetX={11}/>
            </Group>
            <Image image={car}/>

            <Group y={30} ref={this._ref('forceArrowHint')}>
              <Group x={110} y={-40}>
                <Text text={'1 H'} fill={'white'} fontSize={35}/>
              </Group>

              <Arrow x={50} points={[0,0,130,0]} fill={'white'} stroke={'white'} strokeWidth={5}/>
            </Group>
          </Group>

          <CanvasResetBtn
            onClick={() => this.onClickReset()}
          />
        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Power1W);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
