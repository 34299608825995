import Victor from "victor";
import {calcCollisionSpeeds, pxVictor} from "../helpers";
import {asteroidFlightTime, asteroidScale, pxPerMetre} from "../settings";

const getAsteroidParams = (shipTime) => {
  const asteroidTime = Math.random() * 9 + 1 + shipTime;
  const scale = Math.random() * (asteroidScale.y - asteroidScale.x) + asteroidScale.x;

  let initialPos, endPos;
  const rand1 = Math.random();
  const rand2 = Math.random();
  // Enumerate sides from left, clockwise
  // Assume that the window size is 700 * 1000 px
  if (rand1 < 0.25) {
    initialPos = new Victor(0, rand1 * 3000);
    endPos = new Victor(1000, rand2 * 600 + 50);
  } else if (rand1 < 0.5) {
    initialPos = new Victor((rand1 - 0.25) * 4000, 0);
    endPos = new Victor(rand2 * 1000, 700);
  } else if (rand1 < 0.75) {
    initialPos = new Victor(1000, (rand1 - 0.5) * 3000);
    endPos = new Victor(0, rand2 * 600 + 50);
  } else {
    initialPos = new Victor((rand1 - 0.75) * 4000, 700);
    endPos = new Victor(rand2 * 1000, 0);
  }

  const asteroidSpeed = new Victor((endPos.x - initialPos.x) / (pxPerMetre * asteroidFlightTime),
    (endPos.y - initialPos.y) / (pxPerMetre * asteroidFlightTime));

  return {
    asteroidTime: asteroidTime,
    asteroidSpeed: asteroidSpeed,
    initialPos: initialPos,
    scale: scale
  }
};
const handleDocking = (moveProps, firstDocking, shipSpeed, stationSpeed=Victor(0, 0), shiftY=0, massCoeff=1) => {
  let state = moveProps.state;
  let code = moveProps.code;
  let newShipSpeed = moveProps.prevState.shipSpeed;
  let newStationSpeed = moveProps.prevState.stationSpeed;
  let newStationPos, newShipPos;
  const time = moveProps.stationTimedeltaSec;

  let endShipPos = moveProps.prevState.endShipPos;
  let endStationPos = moveProps.prevState.endStationPos;
  if (firstDocking) {
    endShipPos = moveProps.prevState.endShipPos.clone().add(Victor(0, shiftY * pxPerMetre));
    endStationPos = moveProps.prevState.stationPos.clone().add(moveProps.prevState.stationCenter);

    const lessonsWithDockingCodes = [
      "training0", "training6", "training7", 'training8', 'training9',
      'training10', 'training11', 'training12', 'inertia1', 'inertia2', 'inertia3'
    ].includes(code);

    const speeds = calcCollisionSpeeds(shipSpeed, state.shipMass / massCoeff,
      state.stationMass / massCoeff,
      moveProps.isSuccess || lessonsWithDockingCodes,stationSpeed);
    newShipSpeed = speeds.shipSpeed;
    newStationSpeed = speeds.stationSpeed;
  }

  newShipPos = new Victor(
    newShipSpeed.x + moveProps.prevState.shipPos.x,
    newShipSpeed.y + moveProps.prevState.shipPos.y
  );
  newStationPos = new Victor(
    newStationSpeed.x + moveProps.prevState.stationPos.x,
    newStationSpeed.y + moveProps.prevState.stationPos.y
  );
  console.log(newShipSpeed)
  // newShipPos = new Victor(
  //   newShipSpeed.x * time * pxPerMetre + endShipPos.x - moveProps.prevState.shipCenter.x,
  //   newShipSpeed.y * time * pxPerMetre + endShipPos.y - moveProps.prevState.shipCenter.y
  // );
  // newStationPos = new Victor(
  //   newStationSpeed.x * time * pxPerMetre,
  //   newStationSpeed.y * time * pxPerMetre + endStationPos.y - moveProps.prevState.stationCenter.y
  // );

  return {
    shipSpeed: newShipSpeed,
    stationSpeed: newStationSpeed,
    shipPos: newShipPos,
    stationPos: newStationPos,
    endShipPos: endShipPos,
    endStationPos: endStationPos
  }
};
const asteroidMoves = (asteroidPos) => {
  return (asteroidPos !== undefined
    && asteroidPos !== null
    && asteroidPos.x > -100
    && asteroidPos.x < 1100
    && asteroidPos.y > -100
    && asteroidPos.y < 800);
};


export const moveSpace1 = ($this, moveProps) => {
  let {
    shipTimedeltaSec,
    stationTimedeltaSec,
    newStartAfterDocking,
    finishFail,
    finishSuccess,
    prevState,
    RequestId,
    isDocking,
    isSuccess,
  } = moveProps;

  let shipSpeed = new Victor(prevState.setShipSpeed, 0);
  let stationSpeed = new Victor(prevState.setStationSpeed || 0, 0);
  // let stationSpeed = prevState.stationSpeed;

  // let newStationPos = prevState.stationPos;
  let newStationPos = new Victor(stationSpeed.x * shipTimedeltaSec,
    stationSpeed.y * shipTimedeltaSec).multiply(pxVictor);
  let newShipPos = new Victor(shipSpeed.x * shipTimedeltaSec,
    shipSpeed.y * shipTimedeltaSec).multiply(pxVictor);

  const correctShipPositionX = newShipPos.x + prevState.shipCenter.x;
  const correctStationPositionX = newStationPos.x + prevState.stationCenter.x - 140; // 140 - Distance from the center of the ship to the station center

  let asteroidTime = prevState.asteroidTime;
  let asteroidSpeed = prevState.asteroidSpeed; // May be undefined
  let asteroidPos = prevState.asteroidPos; // May be undefined
  let asteroidScale = prevState.asteroidScale; // May be undefined


  // ============== BOOL ===================
  // The lesson completion criterion was changed to measure time, not station position
  // This will ensure each lesson is completed in 5 seconds
  const isStationHasFinished = stationTimedeltaSec > 35;
  const isShipDocking = prevState.endShipPos && (isDocking || correctShipPositionX >= correctStationPositionX);

  if (isStationHasFinished)
    window.cancelAnimationFrame(RequestId);

  if (isShipDocking)
  {
    if (!isDocking)
      if (isSuccess) finishSuccess();
      else finishFail();

    const newParams = handleDocking(moveProps, !isDocking, shipSpeed, stationSpeed);
    shipSpeed = newParams.shipSpeed;
    newShipPos = newParams.shipPos;
    stationSpeed = newParams.stationSpeed;
    newStationPos = newParams.stationPos;
    isDocking = true;
  } else {
    newStartAfterDocking = null;
  }

  if (shipTimedeltaSec > asteroidTime) {
    const asteroidParams = getAsteroidParams(shipTimedeltaSec);

    asteroidTime = asteroidParams.asteroidTime;
    asteroidSpeed = asteroidParams.asteroidSpeed;
    asteroidPos = asteroidParams.initialPos;
    asteroidScale = asteroidParams.scale;
  } else {
    if (asteroidMoves(asteroidPos)) {
      const dtime = shipTimedeltaSec - prevState.prevShipTimedelta;
      const increment = new Victor(asteroidSpeed.x * dtime, asteroidSpeed.y * dtime).multiply(pxVictor);
      asteroidPos.add(increment);
    } else {
      if (asteroidPos !== undefined)
        asteroidPos = null;
    }
  }

  return {
    startAfterDocking: newStartAfterDocking,
    docking: isDocking,
    shipPos: newShipPos,
    stationPos: newStationPos,
    stationSpeed: stationSpeed,
    shipSpeed: shipSpeed,
    stopEngine: Victor(Number(shipTimedeltaSec > 0.5), 1),
    useSetSpeed: false,
    prevShipTimedelta: shipTimedeltaSec,
    asteroidSpeed: asteroidSpeed,
    asteroidPos: asteroidPos,
    asteroidTime: asteroidTime,
    asteroidScale: asteroidScale
  };
};
export const moveSpace2 = ($this, moveProps) => {
  let {
    shipTimedeltaSec,
    stationTimedeltaSec,
    newStartAfterDocking,
    finishFail,
    finishSuccess,
    prevState,
    RequestId,
    isDocking,
    isSuccess,
  } = moveProps;

  let shipSpeed = prevState.shipSpeed;
  let stationSpeed = prevState.stationPos;

  const dtime = shipTimedeltaSec - prevState.prevShipTimedelta;
  let newStationPos = prevState.stationPos;
  let newShipPos = prevState.shipPos.add(Victor(dtime, dtime).multiply(pxVictor).multiply(shipSpeed));

  const correctShipPositionX = newShipPos.x + prevState.shipCenter.x;


  // ============== BOOL ===================
  // The lesson completion criterion was changed to measure time, not station position
  // This will ensure each lesson is completed in N seconds
  const isStationHasFinished = stationTimedeltaSec > 35;
  const isShipDocking = isDocking || correctShipPositionX >= prevState.endShipPos.x;
  // const isBoxesNotOverYet = prevState.boxCounter < prevState.boxCount;

  if (isStationHasFinished)
    window.cancelAnimationFrame(RequestId);

  let koef = (prevState.boxMass * prevState.boxCount)/prevState.shipMass;
  let newBoxPos = prevState.boxPos
    .add(Victor(-prevState.boxSpeed, 0).multiply(Victor(dtime, dtime).multiply(Victor(pxPerMetre, pxPerMetre))));
  shipSpeed = Victor(prevState.boxSpeed * koef, 0);


  if (isShipDocking)
  {
    if (!isDocking)
      if (isSuccess) finishSuccess();
      else finishFail();

    const newParams = handleDocking(moveProps, !isDocking, shipSpeed);
    shipSpeed = newParams.shipSpeed;
    newShipPos = newParams.shipPos;
    stationSpeed = newParams.stationSpeed;
    newStationPos = newParams.stationPos;
    isDocking = true;
  } else {
    newStartAfterDocking = null;
  }

  return {
    prevShipTimedelta: shipTimedeltaSec,
    startAfterDocking: newStartAfterDocking,
    shipSpeed: shipSpeed,
    stationSpeed: stationSpeed,
    docking: isDocking,
    shipPos: newShipPos,
    stationPos: newStationPos,
    boxPos: newBoxPos,
  };
};
export const moveSpace3 = ($this, moveProps) => {
  let {
    shipTimedeltaSec,
    stationTimedeltaSec,
    newStartAfterDocking,
    finishFail,
    finishSuccess,
    prevState,
    RequestId,
    isDocking,
    isSuccess,
  } = moveProps;

  // ============== BOOL ===================
  const engineWillWork = shipTimedeltaSec < prevState.engineTimeX;

  const acceleration = prevState.impulseIncreasePerSecond / prevState.shipMass;

  let shipSpeed = isDocking ? prevState.shipSpeed :
    Victor((engineWillWork ? shipTimedeltaSec : prevState.engineTimeX)
      * acceleration, 0);
  let stationSpeed = prevState.stationSpeed;

  let newStationPos = prevState.stationPos;

  const acceleratedPath = Math.min(prevState.engineTimeX, shipTimedeltaSec)**2 * acceleration / 2;
  const permanentSpeedPath = (Math.max(shipTimedeltaSec - prevState.engineTimeX, 0)
    * acceleration * prevState.engineTimeX);
  const shipXCoord = acceleratedPath + permanentSpeedPath;

  // Assume that movement along Y axis is not accelerated
  let newShipPos = new Victor(shipXCoord,  shipSpeed.y * shipTimedeltaSec).multiply(pxVictor);

  const correctShipPositionX = newShipPos.x + prevState.shipCenter.x;


  // ============== BOOL ===================
  // The lesson completion criterion was changed to measure time, not station position
  // This will ensure each lesson is completed in N seconds
  const isStationHasFinished = stationTimedeltaSec > 35;
  // Handle the case of ship moving backwards
  const isShipDocking = isDocking || correctShipPositionX >= prevState.endShipPos.x;


  if (isStationHasFinished)
    window.cancelAnimationFrame(RequestId);

  if (isShipDocking)
  {
    if (!isDocking)
      if (isSuccess) finishSuccess();
      else finishFail();

    const newParams = handleDocking(moveProps, !isDocking, shipSpeed);
    shipSpeed = newParams.shipSpeed;
    newShipPos = newParams.shipPos;
    stationSpeed = newParams.stationSpeed;
    newStationPos = newParams.stationPos;
    isDocking = true;
  } else {
    newStartAfterDocking = null;
  }

  return {
    startAfterDocking: newStartAfterDocking,
    shipSpeed: shipSpeed,
    stationSpeed: stationSpeed,
    docking: isDocking,
    shipPos: newShipPos,
    stationPos: newStationPos,
    stopEngine: Victor(Number(!engineWillWork), 1),
  };
};
export const moveSpace6 = ($this, moveProps) => {
  let {
    shipTimedeltaSec,
    stationTimedeltaSec,
    newStartAfterDocking,
    finishFail,
    finishSuccess,
    prevState,
    RequestId,
    isDocking,
    isSuccess,
    animationShown
  } = moveProps;

  // ============== BOOL ===================
  const engineWillWork = new Victor(Number(shipTimedeltaSec < prevState.engineTimeX),
    Number(shipTimedeltaSec < prevState.engineTimeY));

  const acceleration = new Victor(prevState.impulseIncreaseX / prevState.shipMass,
    prevState.impulseIncreaseY / prevState.shipMass);

  let shipSpeed, stationSpeed;
  let newShipEndPos = prevState.endShipPos;
  let newStationEndPos = prevState.endStationPos;

  if (isDocking) {
    shipSpeed = prevState.shipSpeed;
  } else {
    shipSpeed = (Victor((engineWillWork.x ? shipTimedeltaSec : prevState.engineTimeX),
      (engineWillWork.y ? shipTimedeltaSec : prevState.engineTimeY))
      .multiply(acceleration)
      .add(prevState.initialShipSpeed));
  }
  stationSpeed = prevState.stationSpeed;

  let newStationPos = new Victor(prevState.stationPos.x, stationSpeed.y * shipTimedeltaSec * pxPerMetre);

  const acceleratedPathX = (Math.min(prevState.engineTimeX, shipTimedeltaSec)**2 * acceleration.x / 2
    + Math.min(prevState.engineTimeX, shipTimedeltaSec) * prevState.initialShipSpeed.x);
  const permanentSpeedPathX = (Math.max(shipTimedeltaSec - prevState.engineTimeX, 0)
    * (acceleration.x * prevState.engineTimeX + prevState.initialShipSpeed.x));

  const acceleratedPathY = (Math.min(prevState.engineTimeY, shipTimedeltaSec)**2 * acceleration.y / 2
    + Math.min(prevState.engineTimeY, shipTimedeltaSec) * prevState.initialShipSpeed.y);
  const permanentSpeedPathY = (Math.max(shipTimedeltaSec - prevState.engineTimeY, 0)
    * (acceleration.y * prevState.engineTimeY + prevState.initialShipSpeed.y));

  const shipXCoord = acceleratedPathX + permanentSpeedPathX;
  const shipYCoord = acceleratedPathY + permanentSpeedPathY;

  let newShipPos = new Victor(shipXCoord,  shipYCoord).multiply(pxVictor);

  const correctShipPositionX = newShipPos.x + prevState.shipCenter.x;


  // ============== BOOL ===================
  // The lesson completion criterion was changed to measure time, not station position
  // This will ensure each lesson is completed in N seconds
  const isStationHasFinished = stationTimedeltaSec > 35;
  // Handle the case of ship moving backwards
  const deltaY = Math.abs((newStationPos.y + prevState.stationCenter.y)
    - (newShipPos.y + prevState.shipCenter.y));
  // Близкие координаты => столкновение, далекие => пролет мимо
  const isShipDocking = isDocking || (correctShipPositionX >= prevState.endShipPos.x
    && correctShipPositionX <= prevState.endShipPos.x + 10 / 17.5 * pxPerMetre
    && deltaY < 30 / 17.5 * pxPerMetre);
  const failedButNotCrashed = (
    correctShipPositionX >= prevState.endShipPos.x
    && correctShipPositionX <= prevState.endShipPos.x + 10 / 17.5 * pxPerMetre
    && deltaY >= 30 / 17.5 * pxPerMetre
  )

  if (isStationHasFinished) {
    window.cancelAnimationFrame(RequestId);
    if (isSuccess)
      finishSuccess();
    else
      finishFail();
  }

  if (failedButNotCrashed && !animationShown) {
    finishFail();
  }

  if (isShipDocking) {
    if (!animationShown)
      if (isSuccess) finishSuccess();
      else finishFail();

    const newParams = handleDocking(moveProps, !isDocking, shipSpeed, stationSpeed, acceleratedPathY + permanentSpeedPathY);
    shipSpeed = newParams.shipSpeed;
    newShipPos = newParams.shipPos;
    stationSpeed = newParams.stationSpeed;
    newStationPos = newParams.stationPos;
    newShipEndPos = newParams.endShipPos;
    newStationEndPos = newParams.endStationPos;
    isDocking = true;
  } else {
    newStartAfterDocking = null;
  }

  return {
    startAfterDocking: newStartAfterDocking,
    shipSpeed: shipSpeed,
    stationSpeed: stationSpeed,
    docking: isDocking,
    shipPos: newShipPos,
    endShipPos: newShipEndPos,
    endStationPos: newStationEndPos,
    stationPos: newStationPos,
    stopEngine: Victor(Number(!engineWillWork.x), Number(!engineWillWork.y)),
    animationShown: animationShown || isShipDocking || failedButNotCrashed ||  isStationHasFinished
  };
};

// ------------- Training tasks ---------------
export const doTraining1 = ($this, moveProps) => {
  let {
    shipTimedeltaSec,
    finishFail,
    finishSuccess,
    prevState,
    isSuccess,
    engineIsOn
  } = moveProps;

  // ============== BOOL ===================
  const acceleration = prevState.impulseIncreasePerSecond / prevState.shipMass;
  const dtime = shipTimedeltaSec - prevState.prevShipTimedelta;
  const dx = prevState.shipSpeed.x * dtime * pxPerMetre;

  let shipSpeed = Victor(engineIsOn ? dtime * acceleration : 0, 0).add(prevState.shipSpeed);
  let shipXCoord = prevState.shipPos.x + dx;

  let newShipPos = new Victor(shipXCoord,  shipSpeed.y * shipTimedeltaSec * pxPerMetre);

  if (isSuccess && !prevState.animationShown)
    finishSuccess();
  if (shipXCoord + prevState.shipCenter.x < 0 && !prevState.animationShown)
    finishFail();

  return {
    shipSpeed: shipSpeed,
    shipPos: newShipPos,
    stopEngine: Victor(Number(!engineIsOn), 1),
    animationShown: prevState.animationShown || isSuccess || shipXCoord + prevState.shipCenter.x < 0,
    prevShipTimedelta: shipTimedeltaSec
  };
}
export const doTraining2 = ($this, moveProps) => {
  let {
    finishFail,
    finishSuccess,
    prevState,
    shipTimedeltaSec,
    RequestId
  } = moveProps;

  if (prevState.shipPos.x > 2000)
    window.cancelAnimationFrame(RequestId);

  const shipSpeed = new Victor(prevState.setShipSpeed, 0);

  const newShipPos = new Victor(prevState.shipSpeed.x * shipTimedeltaSec,
    prevState.shipSpeed.y * shipTimedeltaSec).multiply(pxVictor);

  return {
    shipSpeed: shipSpeed,
    shipPos: newShipPos,
    useSetSpeed: false
  }
};
export const doTraining3 = ($this, moveProps) => {
  let {
    finishFail,
    finishSuccess,
    shipTimedeltaSec,
    prevState,
    isSuccess,
    animationShown,
    RequestId
  } = moveProps;

  if (prevState.shipPos.x > 2000)
    window.cancelAnimationFrame(RequestId);

  const shipSpeed = new Victor(prevState.setShipSpeed, 0);

  const newShipPos = new Victor(prevState.shipSpeed.x * shipTimedeltaSec,
    prevState.shipSpeed.y * shipTimedeltaSec).multiply(pxVictor);

  if (shipTimedeltaSec > 1 && !animationShown) {
    if (isSuccess)
      finishSuccess();
    else
      finishFail();
  }

  return {
    shipSpeed: shipSpeed,
    animationShown: shipTimedeltaSec > 1,
    useSetSpeed: false,
    shipPos: newShipPos
  }
};
export const doTraining4 = ($this, moveProps) => {
  let {
    finishFail,
    finishSuccess,
    shipTimedeltaSec,
    prevState,
    isSuccess,
    animationShown,
    RequestId
  } = moveProps;

  if (prevState.shipPos.x > 2000)
    window.cancelAnimationFrame(RequestId);

  const shipSpeed = new Victor(prevState.setShipSpeed, 0);

  const newShipPos = new Victor(prevState.shipSpeed.x * shipTimedeltaSec,
    prevState.shipSpeed.y * shipTimedeltaSec).multiply(pxVictor);

  if (shipTimedeltaSec > 1 && !animationShown) {
    if (isSuccess)
      finishSuccess();
    else
      finishFail();
  }

  return {
    animationShown: shipTimedeltaSec > 1,
    shipSpeed: shipSpeed,
    shipPos: newShipPos,
    useSetSpeed: false
  }
};
export const doTraining5 = ($this, moveProps) => {
  let {
    finishFail,
    finishSuccess,
    shipTimedeltaSec,
    stationTimedeltaSec,
    newStartAfterDocking,
    prevState,
    RequestId,
    isDocking,
    isSuccess,
  } = moveProps;

  let shipSpeed = new Victor(prevState.setShipSpeed, 0);
  let stationSpeed = prevState.stationSpeed;

  let newStationPos = prevState.stationPos;
  let newShipPos = new Victor(shipSpeed.x * shipTimedeltaSec,
    shipSpeed.y * shipTimedeltaSec).multiply(pxVictor);

  const correctShipPositionX = newShipPos.x + prevState.shipCenter.x;


  // ============== BOOL ===================
  // The lesson completion criterion was changed to measure time, not station position
  // This will ensure each lesson is completed in 5 seconds
  const isStationHasFinished = stationTimedeltaSec > 35;
  const isShipDocking = prevState.endShipPos && (isDocking || correctShipPositionX >= prevState.endShipPos.x);


  if (isStationHasFinished)
    window.cancelAnimationFrame(RequestId);

  if (isShipDocking)
  {
    const newParams = handleDocking(moveProps, !isDocking, shipSpeed, Victor(0, 0), 0, 1000);
    shipSpeed = newParams.shipSpeed;
    newShipPos = newParams.shipPos;
    stationSpeed = newParams.stationSpeed;
    newStationPos = newParams.stationPos;
    isDocking = true;
  } else {
    newStartAfterDocking = null;
  }

  return {
    startAfterDocking: newStartAfterDocking,
    docking: isDocking,
    shipPos: newShipPos,
    stationPos: newStationPos,
    stationSpeed: stationSpeed,
    shipSpeed: shipSpeed,
    stopEngine: Victor(Number(shipTimedeltaSec > 0.5), 1),
    useSetSpeed: false
  };
};