import React, {useState, useEffect} from "react";
import * as actions from '../../../store/actions';
import {connect} from "react-redux";
import {_t} from "../../../utils/lang/common";
import {Form, Button, Input} from 'antd';
import AuthFormWrapper from './AuthFormWrapper';
import {useQuery} from "../../../utils/custom-hooks/useQuery";
import {useHistory} from "react-router-dom";
import '../styles/login.css'
import {generateGetParamsByObj} from "../../../utils/api";



const LoginForm = ({ loginAction, setAnonymousPopupVisible, popupLogin, updateEmail, updatePassword, email, password, registrationMode}) => {

  const [error, setError] = useState('')
  const history = useHistory();

  const nextPath = useQuery().get('next');
  const homeworkRegistrationPath = useQuery().get('hw-auth');
  const login = async (e) => {
    e.preventDefault();
    const resp = await loginAction({email, password});
    setAnonymousPopupVisible(false);
    if (resp && !popupLogin) {
      if (resp.status) {
        history.push(`/${nextPath || 'layout2'}`);
      } else {
        setError(resp.error);
      }
    }
  };


  const params = generateGetParamsByObj({
    next: nextPath,
    mode: registrationMode
  });
  let registrationLink = `/registration${params}`;
  if (homeworkRegistrationPath) {
    registrationLink = homeworkRegistrationPath;
  }

  return (
    <AuthFormWrapper title={_t("title_login")}>
      <form
        method={'GET'}
        onSubmit={login}
      >
        <Form.Item initialValue={email}>
          <Input
            id={'emailLoginForm'}
            name={'email'}
            required={true}
            type="email"
            value={email}
            placeholder={'Email'}
            style={styles.input}
            onChange={(e) => updateEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item initialValue={password}>
          <Input.Password
            id={'passwordLoginForm'}
            name={'password'}
            required={true}
            value={password}
            placeholder={_t("placeholder_password")}
            style={styles.input}
            onChange={(e) => updatePassword(e.target.value)}
          />
        </Form.Item>
        <p className={'error-text'}>{error}</p>
        <Button type="primary" htmlType="submit" style={{margin: '10px auto'}}>
          {_t("button_login")}
        </Button>
        <p className={'login__gray-text'}>{_t("or")}</p>
        <Button
          className={'login__sing_up-btn'}
          type={'ghost'}
          href={!history ? registrationLink : undefined}
          onClick={(e) => {
            if (history) {
              history.push(registrationLink)
            }
          }}
        >
          {homeworkRegistrationPath ? 'Вернуться' : `${_t("button_sign_up")}`}
        </Button>
        <br />
        <div className={'login__forgot-password'}>
          <p className={'login__forgot-password-txt login__gray-text'}>Забыли пароль?</p>
          <Button
            className={'login__forgot-password-btn'}
            type={'link'}
            href={`/forgot-password${nextPath ? '?next='+nextPath : ''}`}
          >
            Восстановить пароль
          </Button>
        </div>
      </form>
    </AuthFormWrapper>
  )
};

const mapStateToProps = (state) => ({
  lang: state.lessonsReducer.lang,
  email: state.authReducer.email,
  password: state.authReducer.password,
});

const mapDispatchToProps = (dispatch) => ({
  loginAction: (state) => dispatch(actions.loginAction(state)),
  updateEmail:  (val) => dispatch(actions.updateEmail(val)),
  updatePassword: (val) => dispatch(actions.updatePassword(val)),
  setAnonymousPopupVisible: (visible) => dispatch(actions.setAnonymousPopupVisible(visible))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);


const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  card: {
    width: '300px',
    marginTop: '-50px'
  },
  input: {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  link: {
    fontSize: '18px'
  }
};
