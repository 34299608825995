import React, {useRef} from "react";
import {Layer, Group, Line, Text} from "react-konva";
import { height, width } from "../../../canvas/containers/CanvasContainer";


const CanvasGrid = (props) => {
  const layerRef = useRef(null);
  const heightContainer = height;
  const widthContainer = width;

  const step = props.step ? props.step : 30;
  const stroke = props.stroke ? props.stroke : 'rgba(255,255,255, 0.8)';
  const strokeWidth = props.strokeWidth ? props.strokeWidth : 1;

  const scaleLegend = props.scaleLegend || "масштаб не указан";

  const skeleton = Array(Math.round(widthContainer/step)).fill(1);

  return (
    <Group ref={layerRef} x={props.centerX} y={props.centerY}>
      {
        skeleton.map((el, i) => {
          const stepPoint = i * step * el;
          return (
            <React.Fragment key={i}>
              {/*vertical*/}
              <Line points={[stepPoint, -heightContainer, stepPoint, heightContainer]} stroke={stroke} strokeWidth={strokeWidth} preventDefault={false}/>
              <Line points={[-stepPoint, -heightContainer, -stepPoint, heightContainer]} stroke={stroke} strokeWidth={strokeWidth} preventDefault={false}/>
              {/*horizontal*/}
              <Line points={[-widthContainer, stepPoint, widthContainer, stepPoint]} stroke={stroke} strokeWidth={strokeWidth} preventDefault={false}/>
              <Line points={[-widthContainer, -stepPoint, widthContainer, -stepPoint]} stroke={stroke} strokeWidth={strokeWidth} preventDefault={false}/>
            </React.Fragment>
          )
        })
      }
      <Text text={scaleLegend} x={1*step + 5} y={-15} fontSize={14} fill={stroke}/>
    </Group>
  )
};

export default CanvasGrid;
