import {Group, Image} from "react-konva";
import React from "react";
import useImage from "use-image";
import roadImage from "../../../../images/pathandspeed/road.png";


const Road = React.forwardRef(({id, y, roadWidth, roadItemsCount, children, customImageNode}, ref) => {

  const roadX = roadWidth || 197;

  const [road] = useImage(roadImage);
  const roadItems = Array(roadItemsCount).fill(1);
  return (
    <Group ref={ref}>
      {
        roadItems.map((el, i) => {
          return (
            <Group key={id+'-'+i} y={y} x={roadX*i}>
              {
                customImageNode ?
                  customImageNode
                :
                  <Image image={road}/>
              }
            </Group>
          )
        })
      }
    </Group>
  )
});

export default Road;