export const circlDragBoundFunc  = ($this, pos) => {

    /**
     *  Function to limit movement
     */
    const radius = 200;
    const x = 675;
    const y = 325;
    const scale =  radius / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
    const newX = Math.round((pos.x - x) * scale + x);
    const newY = Math.round((pos.y - y) * scale + y);
    return {y: newY, x: newX};

};

export const getBoolByLsnCode = (code) => ({
    isTgCircle1: code === 'tgcircle1',
    isTgCircle2: code === 'tgcircle2',
    isTgCircle3: code === 'tgcircle3',
    isTgCircle4: code === 'tgcircle4',
    isTgCircle5: code === 'tgcircle5',
})
