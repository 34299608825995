import React from "react";
import '../styles.css';


const TasksIndicator = (props) => {
  return (
    <div className={'tasks-indicator__container'}>
      {
        (props.items || []).map((item, i) => {
          const completed = Boolean(item.answers.length);
          return (
            <div key={'task-'+i} className={`tasks-indicator__item ${completed ? 'tasks-indicator__item--selected' : ''}`}
                 style={props.items.length === 1 ? {borderRadius: '6px'} : {}}
            />
          )
        })
      }
    </div>
  )
}

export default TasksIndicator;