import {_t} from "../../../utils/lang/common";
import React, {useState} from "react";
import moment from "moment";
import {checkLessonStatus} from '../../../utils/common'
import {connect} from "react-redux";


const StudentsList = (props) => {
  const {
    lessons,
    lessonSession,
  } = props;
  const [visibleStudentsList, setVisibleStudentsList] = useState(true);
  const selectedLessons = lessonSession.selectedLessons;
  const lessonsOfSession = lessons.filter((lsn) => selectedLessons[lsn.code]);
  const studentsList = lessonSession.students;

  const toggleVisibleStudentsList = () => setVisibleStudentsList(!visibleStudentsList);

  return (
    <div style={styles.studentsOfSessionLesson}>
      {
        visibleStudentsList && (
          <table style={{...styles.studentsTable, borderCollapse: 'collapse'}} border={'black'}>
            <thead>
              <tr>
                <th style={styles.th} />
                {
                  lessonsOfSession.map((lsn) => (
                    <th key={lsn.id} style={styles.th}>{lsn.title}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                studentsList.length
                  ?
                    studentsList.map((stdnt) => (
                        <tr key={stdnt.id}>
                          <td style={{...styles.td, width: '160px'}}>{stdnt.first_name} {stdnt.last_name}</td>
                          {
                            lessonsOfSession.map((lsn, i) => {

                              const correctAccess = lessonSession.studentsLessonsAccess.filter((access) => {
                                return access.lesson === lsn.id && access.user === stdnt.id
                              })[0];
                              const actionsLog = stdnt.actionsLog;
                              const lsnStatus = correctAccess && checkLessonStatus(correctAccess.status);

                              let style = {fontSize: '12px'};
                              if (lsnStatus) {
                                if (lsnStatus.success)
                                  style = {...style, background: 'rgba(0,200,0,0.2)'};
                                else if (lsnStatus.fail)
                                  style = {...style, background: 'rgba(200,0,0,0.2)'};
                              }
                              if (lsn.id === actionsLog.activeLessonId)
                                style = {...style, border: '2px solid rgb(41, 114, 204)'};

                              const lessonsData = actionsLog.lessonsData;

                              let timeLog = null;
                              if (lessonsData && lessonsData[lsn.id]) {
                                const dataStartUtc = moment.utc(lessonSession.startLessonDatetime).toDate();
                                const dataFinishUtc = moment.utc(lessonsData[lsn.id].finish).toDate();
                                const dataStart = moment(dataStartUtc, "HH:mm:ss");
                                const dataFinish = moment(dataFinishUtc, "HH:mm:ss");

                                const timeDiff = moment.utc(moment(dataFinishUtc).valueOf() - moment(dataStartUtc).valueOf());

                                if (timeDiff.isValid() && dataStart.isBefore(dataFinish))
                                  timeLog = timeDiff.format('HH:mm:ss');
                              }
                              return(
                                <td key={i} style={{...styles.td, ...style}}>
                                  {
                                    timeLog && (
                                      `${_t('time')}: ${timeLog}`
                                    )
                                  }
                                </td>
                              )
                            })
                          }
                        </tr>
                      )
                    )
                  : (
                    <tr>
                      <td colSpan={lessonsOfSession.length+1} style={{textAlign: 'center', padding: '20px'}}>
                        <h6>Еще никто не зашел</h6>
                      </td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        )
      }
      <button
        style={styles.studentsListBtn}
        onClick={toggleVisibleStudentsList}
      >{_t('button_students_of_lesson')}</button>
    </div>
  )
};

const mapStateToProps = (state) => {
  return ({
    lessons: state.lessonsReducer.lessons,
    lessonSession: state.lessonsReducer.lessonSession,
  });
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentsList);


const styles = {
  studentsOfSessionLesson: {
    padding: '5px 10px'
  },
  studentsTable: {
    fontSize: '15px',
    margin: '5px auto'
  },
  studentsListBtn: {
    padding: '10px 20px'
  },
  td: {
    padding: '5px',
  },
  th: {
    width: '130px',
    padding: '5px',
    textAlign: 'center'
  },
  lessonSuccess: {

  },
  lessonFail: {

  }
};