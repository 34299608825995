import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ArrangeByColumns from "./ArrangeByColumns";
import simpleMachinesImg from "../images/arrangeByColumns/simple_machines.png";
import barnImg from "../images/arrangeByColumns/barn.png";
import rampImg from "../images/arrangeByColumns/ramp2.png";
import workImg from "../images/arrangeByColumns/work.png";
import ChooseExtraItemTask from "./ChooseExtraItemTask";
import ContinueRowTask from "./ContinueRowTask";
import task2Img3 from "../images/continueRow/task2Row3.png";
import task2Img2 from "../images/continueRow/task2Row2.png";
import task2Img1 from "../images/continueRow/task2Row1.png";
import task2DragItemImg1 from "../images/continueRow/task2Drag1.png";
import task2DragItemImg2 from "../images/continueRow/task2Drag2.png";
import task2DragItemImg3 from "../images/continueRow/task2Drag3.png";
import task2DragItemImg4 from "../images/continueRow/task2Drag4.png";
import bg from "../images/space-bg-1.png";


const Example8Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'Продолжи ряд',
      rowItems: [
        {id: 1, mainText: 'Земля'},
        {id: 2, mainText: 'Марс'},
        {id: 3, mainText: 'Юпитер'},
      ],
      dragItems: [
        {id: 1, mainText: 'Ганимед'},
        {id: 2, mainText: 'Сатурн', success: true},
        {id: 3, mainText: 'Луна'},
        {id: 4, mainText: 'Плутон'},
      ],
    },
    {
      title: 'Продолжи ряд',
      rowItems: [
        {id: 1, mainText: 'ИО'},
        {id: 2, mainText: 'Каллисто'},
        {id: 3, mainText: 'Меркурий'},
      ],
      dragItems: [
        {id: 1, mainText: 'Европа'},
        {id: 2, mainText: 'Луна'},
        {id: 3, mainText: 'Сатурн'},
        {id: 4, mainText: 'Титан', success: true},
      ],
    },
    {
      title: 'Продолжи ряд',
      rowItems: [
        {id: 1, mainText: 'Нептун'},
        {id: 2, mainText: 'Земля'},
        {id: 3, mainText: 'Венера'},
      ],
      dragItems: [
        {id: 1, mainText: 'Марс', success: true},
        {id: 2, mainText: 'Сатурн'},
        {id: 3, mainText: 'Уран'},
        {id: 4, mainText: 'Меркурий'},
      ],
    },
    {
      title: 'Продолжи ряд',
      rowItems: [
        {id: 1, mainText: 'Марс'},
        {id: 2, mainText: 'Венера'},
        {id: 3, mainText: 'Земля'},
      ],
      dragItems: [
        {id: 1, mainText: 'Меркурий'},
        {id: 2, mainText: 'Нептун', success: true},
        {id: 3, mainText: 'Уран'},
        {id: 4, mainText: 'Луна'},
      ],
    }
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={4}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      {
        chooseTasks.map((item, i) => (
          <React.Fragment key={`step${i}`}>
            {
              (completedTaskCount === (i) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                <ContinueRowTask
                  lang={'ru'}
                  bgImg={bg}
                  title={item.title}
                  rowItems={item.rowItems}
                  dragItems={item.dragItems}
                  taskComplete={() => {
                    setCompletedTaskCount(prevState => prevState + 1);
                    if ((i + 1) === chooseTasks.length) {
                      setAllComplete(true);
                    }
                  }}
                />
              ) : null
            }
          </React.Fragment>
        ))
      }
    </TaskMainContainer>
  )
}
export default Example8Task;
