import React from "react";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Circle, Group, Image, Layer, Rect, Text} from "react-konva";
import cloneDeep from "lodash.clonedeep";
import useImage from "use-image";
import beaker1Img from "../../../../images/beaker/beaker1.png";
import ScenarioManager from "../../../../utils/ScenarioManager";
import CanvasInput from "../../../canvas/components/CanvasInput";
import groundImg from "../../../../images/ground.png";
import {layout2, lsnLabelTxtStyle, lsnTitleTxtStyle} from "../../../../utils/styles";
import Card from "../../../canvas/components/Card";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {inputVal, showFailOrSuccessPopup} from "../../../../utils/common";
import TaskComplete from "../../../canvas/components/TaskComplete";
import StepsIndicator from "../../../canvas/components/StepsIndicator";
import {getCorrectScenario} from "../utils/scenaries";


class Beaker extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();

    this.elementsUI = {
      taskCompleteVisible: false
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      currentTimeSec: 0,

      beakerImg: beaker1Img
    };
    this.data = cloneDeep(this.initialData);


    this.managedComponents = [
      'stage',
      'title',
      'activeCircle1',
      'activeCircle2',

    ];

    for (let el of this.managedComponents) {
      this[`${el}Ref`] = React.createRef();
    }
    const scenario = getCorrectScenario(props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`];
  _getNode = (key) => this[`${key}Ref`]?.current;


  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.scenarioManager.resetScenario();
  }

  resetData = () => {
    this._resetBikerChartCallback();
    this._resetChartLinesCallback();
  }
  resetScenario = () => {
    this.scenarioManager.resetScenario();
    this.resetData();
  }
  back = () => {
    this.resetData();
    this.data.inputVal = '';
    this.scenarioManager.back();
  }
  next = () => {
    this.data.inputVal = '';
    this.scenarioManager.next();
  }

  checkSuccess = () => {
    const data = this.data;
    const key = this.scenarioManager.getStepData().key;

    let success = data.inputSuccess === Number(data.inputVal);
    if (data.radioCalcErr1) {
      success &= data.inputCalcErrSuccess1 === Number(data.inputCalcErr);
    } else {
      success &= data.inputCalcErrSuccess50 === Number(data.inputCalcErr);
    }

    if (success) {
      data.inputVal = undefined;
      data.inputCalcErr = undefined;
      this.scenarioManager.success(key)
    } else {
      this.scenarioManager.failure(key)
    }
    showFailOrSuccessPopup(this, success);
  }

  move = (time) => {
    const data = this.data;

    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    data.startTime = data.startTime || time;
    data.prevTime = time;
    data.currentTimeSec = (data.prevTime - data.startTime)/1000;
    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);




    this.updateStage();
  };

  updateStage = () => {
    const data = this.data;
    const stepData = this.scenarioManager.getStepData();
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['title'].text(stepData?.text?.value);

    /** ---  Блок с выбором цены деления  --- */
    // n['activeCircle1'].visible(data.radioCalcErr50);
    // n['activeCircle2'].visible(data.radioCalcErr1);

    n['stage'].draw();
  };

  Scene = () => {
    const data = this.data;
    const stepData = this.scenarioManager.getStepData();


    const [ground] = useImage(groundImg);
    const [beaker] = useImage(data.beakerImg);


    let textSetting = { stroke: layout2.blue, fill: layout2.blue, strokeWidth: .2, fontSize: 27, lineHeight: 1.3};
    return (
      <React.Fragment>
        <Group>

          <Text ref={this._ref('title')} {...lsnTitleTxtStyle} x={60}/>

          <Group x={250} y={170}>
            <Group x={70}>
              <Card height={80} width={400}>
                <Group x={15} y={25}>
                  <Group>
                    <Text x={10} text={`V =`} {...textSetting}/>
                    <CanvasInput
                      id={'1'}
                      y={1}
                      x={70}
                      width={90}
                      height={30}
                      stage={this.stageRef?.current}
                      textColor={layout2.blue}
                      onInput={(val) => { this.data.inputVal = inputVal(val) }}
                      value={data.inputVal}
                    />
                  </Group>
                  <Group>
                    <Text x={172} text={`±`} {...textSetting} />
                    <CanvasInput
                      id={'1'}
                      y={1}
                      x={200}
                      width={90}
                      height={30}
                      stage={this.stageRef?.current}
                      textColor={layout2.blue}
                      onInput={(val) => { this.data.inputCalcErr = inputVal(val) }}
                      value={data.inputCalcErr}
                    />
                  </Group>
                  <Text x={300} text={`${data.measureTxt}`} {...textSetting} />
                </Group>
              </Card>
              <CanvasButton
                y={100}
                text={'Проверить'}
                onClick={this.checkSuccess}
                fontSize={20}
                height={40}
                width={140}
                strokeWidth={.2}
                btnCornerRadius={0}
              />

            </Group>

            {/** ----  Блок с выбором цены деления ---- */}
            {/*<Card x={530} height={220} width={150}>*/}
            {/*  <Group x={25} y={20}>*/}
            {/*    <Text text={`Считать \nпогрешность \nкак:`} {...lsnLabelTxtStyle} fontStyle={'bold'} />*/}
            {/*    <Group y={40}>*/}
            {/*      <Group*/}
            {/*        x={10} y={30}*/}
            {/*        onClick={() => {*/}
            {/*          this.data.radioCalcErr50 = true;*/}
            {/*          this.data.radioCalcErr1 = false;*/}
            {/*        }}*/}
            {/*        onTap={() => {*/}
            {/*          this.data.radioCalcErr50 = true;*/}
            {/*          this.data.radioCalcErr1 = false;*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <Rect x={-35} y={-10} width={150} height={55} fill={'black'} visible={false}/>*/}
            {/*        <Circle y={10} radius={5} fill={'white'} strokeWidth={.5} stroke={'black'}/>*/}
            {/*        <Circle y={10} radius={3} fill={'black'} ref={this._ref('activeCircle1')}/>*/}
            {/*        <Text x={20} text={`1/2 цены \nделения`} {...lsnLabelTxtStyle} />*/}
            {/*      </Group>*/}

            {/*      <Group*/}
            {/*        x={10} y={90}*/}
            {/*        onClick={() => {*/}
            {/*          this.data.radioCalcErr1 = true;*/}
            {/*          this.data.radioCalcErr50 = false;*/}
            {/*        }}*/}
            {/*        onTap={() => {*/}
            {/*          this.data.radioCalcErr1 = true;*/}
            {/*          this.data.radioCalcErr50 = false;*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <Rect x={-35} y={-10} width={150} height={55} fill={'black'} visible={false}/>*/}
            {/*        <Circle y={10} radius={5} fill={'white'} strokeWidth={.5} stroke={'black'}/>*/}
            {/*        <Circle y={10} radius={3} fill={'black'} ref={this._ref('activeCircle2')}/>*/}
            {/*        <Text x={20} text={`1 цены \nделения`} {...lsnLabelTxtStyle} />*/}
            {/*      </Group>*/}
            {/*    </Group>*/}
            {/*  </Group>*/}
            {/*</Card>*/}
          </Group>

          <Group x={80} y={153}>
            <Rect x={data.waterX} y={345} rotation={180} height={data.waterHeight} width={data.waterWidth} fill={'#BFDEE7'} cornerRadius={[15,15,0,0]}/>
            <Image image={beaker} />
          </Group>


          <Image image={ground} x={40} y={500}/>

          <TaskComplete visible={!!data.taskCompleteVisible}/>

          <StepsIndicator
            x={770} y={40}
            items={Array(4).fill(1)}
            activeVal={Number(stepData.key.replace('step', ''))}
          />
        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer>
            <this.Scene />
          </Layer>
        </CanvasContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Beaker);

const styles = {
  mainContainer: {
    height: "auto",
    background: "#EFEBE4",
  },
};
