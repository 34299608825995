import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import Victor from 'victor';
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {GIF} from "../../../canvas/components/GIF";
import {degToRad, lessonStatus, radToDeg} from '../../../../utils/common';
import {FieldBlock} from '../components/FieldBlock';
import * as actions from '../../../../store/actions';
import weightImg from '../../../../images/gearRatios/weight2.png';
import blockImg from '../../../../images/gearRatios/block.png';
import gearEngine1 from '../../../../images/gearRatios/gearEngine1.png';
import gearEngine2 from '../../../../images/gearRatios/gearEngine2.png';
import gearEngine3 from '../../../../images/gearRatios/gearEngine3.png';
import {connect} from "react-redux";
import SteamEngineCanvas from "../../steamEngine/components/SteamEngineCanvas";
import {Input} from 'antd';
import {_t} from "../../../../utils/lang/common";


class Engine extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();
    this.smallGearRef = React.createRef();
    this.mediumGearRef = React.createRef();
    this.bigGearRef = React.createRef();
    this.weightRef = React.createRef();
    this.ropeRef = React.createRef();

    this.staticData = {};
    this.engineData = {};
    this.state = {
      burning: false,
      activeGear: 'medium', // 'medium', 'small'
      weight: 2,
      weightInput: 2,
    };
    this.initialData = {
      smallGearAngle: 0,
      mediumGearAngle: 0,
      leftGearSpeed: 30,
      bigGearAngle: 0,
      ropePoints: [40, 10, 40, -370],
      weightOffsetY: 330,
      weightTorque: 0
    };
    this.data = cloneDeep(this.initialData);
  }

  _resetCallback = () => {console.log('Debug reset callback')};
  getResetCallback = (callback) => {this._resetCallback = callback};

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  getAllNodes = (stageNode) => ({
    smallGearNode: this.smallGearRef?.current,
    mediumGearNode: this.mediumGearRef?.current,
    bigGearNode: this.bigGearRef?.current,
    weightNode: this.weightRef?.current,
    ropeNode: this.ropeRef?.current,
  });


  move = (time) => {
    const {maxAngle, minAngle} = this.staticData;
    const {
      weight,
      burning,
      activeGear,
    } = this.state;

    const nodes = this.getAllNodes();
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    // === Calc weight position ===

    data.leftGearSpeed = this.engineData.wheelRotationSpeed * 3.4;
    data.smallGearAngle = data.smallGearAngle + data.leftGearSpeed * timedeltaSec;
    data.mediumGearAngle = data.mediumGearAngle + data.leftGearSpeed * timedeltaSec;

    const mediumRatio = .8;
    const smallRatio = .6;

    if (activeGear === 'medium')
      data.bigGearAngle = data.mediumGearAngle * mediumRatio;
    if (activeGear === 'small')
      data.bigGearAngle = data.smallGearAngle * smallRatio;

    data.weightOffsetY = data.bigGearAngle / 10;

    // === Calc torque to engine ===

    const weightBlock1Momentum = 10 * weight * 10; // radius * Force (block2)
    let weightGearsMomentum = weightBlock1Momentum;
    if (activeGear === 'medium')
      weightGearsMomentum = weightBlock1Momentum * mediumRatio;
    if (activeGear === 'small')
      weightGearsMomentum = weightBlock1Momentum * smallRatio;
    data.weightTorque = weightGearsMomentum / .6; // 0.6 ременное передаточное число

    // todo не учитывается инерция груза

    if (data.weightOffsetY >= 340) {
      data.weightTorque = 5000;
      this.engineData.wheelRotationSpeed = 0;
    }

    this.updateStage();
  };

  updateStage() {
    const {
      weight,
      burning,
      activeGear,
    } = this.state;
    const data = this.data;
    const {rotationAngle, startTime, prevTime} = this.data;
    const timedelta = prevTime ? startTime - prevTime : 0;
    const timedeltaSec = timedelta / 1000;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const nodes = this.getAllNodes();

    nodes.smallGearNode.rotation(data.smallGearAngle+10); // +10 изначальный поворот иначе шестеренки не совпадают
    nodes.mediumGearNode.rotation(data.mediumGearAngle-7); // -7 изначальный поворот иначе шестеренки не совпадают
    nodes.bigGearNode.rotation(-data.bigGearAngle);


    // if (data.weightOffsetY <= 330 && data.weightOffsetY >= 0) {
      data.ropePoints = [40, -data.weightOffsetY + 10, 40, -370];
      nodes.weightNode.offsetY(data.weightOffsetY);
      nodes.ropeNode.points(data.ropePoints);
    // }

    stageNode.draw();
  }

  onChangeInput = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    const min = Number(e.target.min);
    const max = Number(e.target.max);
    let correctVal = val;
    if (Number(val) < min)
      correctVal = min;
    if (Number(val) > max)
      correctVal = max;
    this.setState({[name]: correctVal, [`${name}Input`]: val})
  };

  CanvasLever = () => {
    const {
      burning,
      activeGear
    } = this.state;

    const [block] = useImage(blockImg);
    const [weight] = useImage(weightImg);
    const [gear1] = useImage(gearEngine1);
    const [gear2] = useImage(gearEngine2);
    const [gear3] = useImage(gearEngine3);

    return (
      <React.Fragment>
        <Layer preventDefault={false}>


          {/* WEIGHT */}
          <Group x={849} y={450}>
            <Line ref={this.ropeRef} points={this.data.ropePoints} stroke={'white'} strokeWidth={1}/>
            <Group ref={this.weightRef} offsetY={this.data.weightOffsetY}>
              <Image image={weight}/>
              <Text text={this.state.weight} align={'center'} width={85} y={40} verticalAlign={'center'} fill={'white'} fontSize={30}/>
            </Group>
          </Group>

          <Group x={670}>
            {/* GEARS */}
            <Group y={80}>

              <Group x={-12}>
                <Image
                  ref={this.mediumGearRef}
                  image={gear1}
                  width={75}
                  height={75}
                  offset={{x:75/2, y: 75/2}}
                  rotation={-7}
                  opacity={activeGear === 'medium' ? 1 : 0}
                />
                <Image
                  ref={this.smallGearRef}
                  image={gear2}
                  width={67}
                  height={67}
                  offset={{x:67/2, y: 67/2}}
                  rotation={10}
                  opacity={activeGear === 'small' ? 1 : 0}
                />


                {/* LINE TO ENGINE */}
                <Line points={[-110,0,-80,430]} stroke={'white'} strokeWidth={1}/>
                <Line points={[-155,0,-185,430]} stroke={'white'} strokeWidth={1}/>

                {/* LINES TO GEAR */}
                <Line points={[-130,-25, 0, -25]} stroke={'white'} strokeWidth={1}/>
                <Line points={[-130, 24, 0, 24]} stroke={'white'} strokeWidth={1}/>

                <Circle radius={25.5} fill={'#6D6D6B'}/>
                <Circle radius={7} fill={'#ACACAC'}/>
              </Group>

              <Group x={65}>
                <Image ref={this.bigGearRef} image={gear3} width={90} height={90} offset={{x: 90/2, y: 90/2}}/>

                {/* LINES TO GEAR */}
                <Line points={[130,-25, 0, -25]} stroke={'white'} strokeWidth={1}/>
                <Line points={[130, 24, 0, 24]} stroke={'white'} strokeWidth={1}/>

                <Circle radius={25.5} fill={'#6D6D6B'}/>
                <Circle radius={7} fill={'#ACACAC'}/>
              </Group>
            </Group>

            {/* BLOCKS */}
            <Group y={-2}>
              <Image image={block} x={-170} />
              <Image image={block} x={170}  />
            </Group>
          </Group>

        </Layer>
        {/*{console.log('debug on torque', -this.data.weightTorque)}*/}
        <SteamEngineCanvas
          stageNode={this.stageRef?.current}
          getResetCallback={this.getResetCallback}
          externalTorque={() => -this.data.weightTorque}
          // externalTorque={-this.data.weightTorque}
          maxRotationSpeed={250}
          engineData={this.engineData}
          burning={burning}
          autoSwitchValve={true}
          scaleSize={.7}
          y={250}
        />
      </React.Fragment>
    )
  };

  render() {
    const {burning, activeGear} = this.state;

    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.CanvasLever/>
        </CanvasContainer>

        <div style={styles.gearsSwitcher}>
          <div
            style={{
              ...styles.gearBtn,
              background: activeGear==='medium' ? 'rgba(0,230,0,0.3)' : 'rgba(0,0,0,0.1)',
            }}
            onClick={() => {this.setState({activeGear: 'medium'})}}
          >
            <img src={gearEngine1} alt="" width={'100%'} height={'100%'} style={{objectFit: 'contain'}}/>
          </div>
          <div
            style={{
              ...styles.gearBtn,
              background: activeGear==='small' ? 'rgba(0,230,0,0.3)' : 'rgba(0,0,0,0.1)',
            }}
            onClick={() => {this.setState({activeGear: 'small'})}}
          >
            <img src={gearEngine2} alt="" width={'100%'} height={'100%'} style={{objectFit: 'contain'}}/>
          </div>
        </div>

        {/* --------- BUTTONS --------- */}
        <button
          style={{...styles.btn, right: '80%'}}
          onClick={() => this.setState((prevState) => ({burning: !prevState.burning}))}
        >
          {burning ? _t("torch_off") : _t("torch_on")}
        </button>
        <button
          style={{...styles.btn, right: '5%'}}
          onClick={() => this.setState({burning: false}, () => {
            this._resetCallback();
            this.data=cloneDeep(this.initialData)
          })}
        >
          {_t("reset")}
        </button>
        {/* ---------------------------  */}

        <div style={styles.fieldsBlock}>
          <label htmlFor="">
            <p style={{color: 'white', fontSize: '1em'}}>{_t("cargo")}:</p>
            <Input
              type="number"
              name={'weight'}
              style={{fontSize: '1em'}}
              onChange={this.onChangeInput}
              value={this.state.weightInput}
              min={1}
              max={50}
            />
          </label>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Engine);

const styles = {
  mainContainer: {
    background: '#447B9C',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    position: 'absolute',
    background: '#E76F52',
    borderRadius: '7px',
    color: 'white',
    zIndex: 10,
    fontSize: '2vmin',
    padding: '1vmin 1.5vmin',
    fontWeight: 'bold',
    cursor: 'pointer',
    bottom: '4%',
  },
  fieldsBlock: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: '4.5%',
    right: '17%',
    fontSize: '2vmin'
  },
  gearsSwitcher: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    top: '20%',
    right: '25%'
  },
  gearBtn: {
    width: '5vmin',
    height: '5vmin',
    flexShrink: 0,
    cursor: 'pointer',
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    border: '1px solid rgba(0,0,0,0.3)',
    background: 'rgba(0,0,0,0.3)',
    padding: '3px'
  }
};
