import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getTimeDeltaSec, hasIntersection} from "../../../../utils/common";
import {defaultPatchData, getScenario} from "../scenario";
import cardImg from '../../../../images/waterTower/card.png';
import buildingsImg from '../../../../images/waterTower/buildings.png';
import groundImg from '../../../../images/waterTower/ground.png';
import waterTankImg from '../../../../images/waterTower/waterTank.png';
import waterTowerBaseImg from '../../../../images/waterTower/waterTowerBase.png';
import tubeBorderImg from '../../../../images/waterTower/tubeBorder.png';
import failIconImg from '../../../../images/failIcon.png';
import Card from "../../../canvas/components/Card";
import CurvedArrow from "../../../canvas/components/CurvedArrow";
import MovingArrow from "../../../canvas/components/MovingArrow";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CheckMarkGif from "../../../canvas/components/CheckMarkGif";
import {
  checkFailureDoubleSmall, checkFailureSingleSmall,
  checkHoverDropBoxIntersection, correctCityWaterHeight, correctTankWaterCoeffHeight, correctTankWaterHeight,
  dropTowerBase,
  dropWaterTank,
  filterWaterTowers,
  getCorrectPosByDropNode, resetDragAndDrop, setDefaultPos, setDefaultTankPos
} from "../utils";
import * as actions from "../../../../store/actions";

import {_t, getCorrectLang} from '../../../../utils/lang/common';

const blue = '#0083D2';
const orange = '#F7931E';

class WaterTower extends React.Component {
  _movingCallbacks = {};

  constructor(props) {
    super(props);

    this.lang = getCorrectLang();

    this.waterTowerItems = [1,2];
    this.dropBoxItems = [1,2,3];
    this.dropBoxDirection = ['left', 'right'];

    this.managedComponents = [
      'stage',

      'cityWater',
    ]
    this.waterTowerItems.forEach((el,i) => {
      this.managedComponents = [
        ...this.managedComponents,
        `waterTankMain${el}`,
        `waterTank${el}`,
        `waterTankText${el}`,
        `waterTankRect${el}`,


        `towerBaseMain${el}`,
        `towerBaseImg${el}`,
        `towerBaseRect${el}`,

        `tubeForWater${el}`,
        `waterTubeForTank${el}`,
        `waterForTank${el}`,

        `defaultPosTwrBase${el}`,
        `defaultPosWtrTank${el}`,
      ]
    })
    this.dropBoxDirection.forEach((direction) => {
      this.dropBoxItems.forEach((el, i) => {
        this.managedComponents.push(direction+'DropBox'+el);
      })
    })

    this.staticData = {
      angleFortowerBase: -33.3,
      waterTubeStepLen: 120,

      maxWaterTankHeight: 70,

      singleBigTowerWaterTankCoeffHeight: 3.7,
      singleSmallTowerWaterTankCoeffHeight: 5.5,
      dualTowerWaterTankCoeffHeight: 2.2,

      singleBigTowerWaterTankHeight: 35,
      singleSmallTowerWaterTankHeight: 35,
      dualTowerWaterTankHeight: 55,

      singleBigTowerWaterCityHeight: 240,
      singleSmallTowerWaterCityHeight: 160,
      dualTowerWaterCityHeight: 181,
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      waterTubeForTank1Visible: false,
      waterTubeForTank2Visible: false,

      leftWaterTower: [], // стек для башни
      rightWaterTower: [], // стек для башни

      waterTankVolume: 0,

      waterTankHeight: 0,
      waterTubeHeight: 0,
      waterTubeMaxHeight: 0,

      cityWaterHeight: 0,

      waterHeightComplete: 0,

      failureSingleSmall: false,
      failureDoubleSmall: false,

      ...defaultPatchData,
    };
    this.data = cloneDeep(this.initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();

  _getNode = (key) => this[`${key}Ref`]?.current;

  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  get waterTankHeight() {
    let data = this.data;
    let staticData = this.staticData;
    let res = data.waterTankHeight;

    if (data.fillCityWater) {
      const minWaterTankHeight = correctTankWaterHeight(this);
      const cityWaterHeight = correctTankWaterCoeffHeight(this);
      if (res <= minWaterTankHeight) {
        res = minWaterTankHeight;
      } else {
        res -= cityWaterHeight * data.timedeltaSec;
      }
    } else {
      if (res >= staticData.maxWaterTankHeight ) {
        res = staticData.maxWaterTankHeight;
      } else {
        res += 25 * data.timedeltaSec;
      }
    }
    return res;
  }

  get cityWaterHeight() {
    let data = this.data;
    let res = data.cityWaterHeight;
    let maxWaterHeight = correctCityWaterHeight(this);
    let maxHeightComplete = res >= maxWaterHeight;

    if (maxHeightComplete) {
      res = maxWaterHeight;
    } else {
      res += 25 * data.timedeltaSec;
    }
    this.setWaterHeightComplete(maxHeightComplete);
    return res;
  }
  get waterTubeHeight() {
    let data = this.data;
    let res = data.waterTubeHeight;
    if (res >= data.waterTubeMaxHeight) {
      res = data.waterTubeMaxHeight;
    } else {
      res += 85 * data.timedeltaSec;
    }
    return res;
  }

  checkSuccess = () => {
    const data = this.data;
    const leftWaterTowerLen = data.leftWaterTower.length;
    const rightWaterTowerLen = data.rightWaterTower.length;
    const helperArr = [leftWaterTowerLen, rightWaterTowerLen];
    return helperArr.some(len => len > 2);
  }

  setWaterHeightComplete = (complete) => {
    this.data.waterHeightComplete = complete;
  }

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    resetDragAndDrop(this);
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
    this.data.withoutHint = true;
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);


    if (data.fillTanksTubes) {
      data.waterTubeHeight = this.waterTubeHeight;
    }
    if (data.fillTanksWater || data.fillCityWater) {
      data.waterTankHeight = this.waterTankHeight;
    }
    if (data.fillCityWater) {
      data.cityWaterHeight = this.cityWaterHeight;
    }

    const success = this.checkSuccess();
    if (data.waterHeightComplete && data.fillCityWater) {

      data.failureSingleSmall = checkFailureSingleSmall(this);
      data.failureDoubleSmall = checkFailureDoubleSmall(this);

      if (success) {
        this.scenarioManager.success('fill city water');
      } else {
        let correctKey = '';
        if (data.failureSingleSmall) {
          correctKey = 'failure single small';
        }
        if (data.failureDoubleSmall) {
          correctKey = 'failure double small';
        }
        if (correctKey) {
          this.scenarioManager.selectStepByKey(correctKey);
        }
      }
    }
    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    this.dropBoxDirection.forEach((direction) => {

      const waterTower = data[direction+'WaterTower'];
      waterTower.forEach((el, i) => {
        this.scenarioManager.success('drag hint 2');
        const indx = i+1;
        if (n[el.id]) {
          let level = el.level !== indx ? indx : el.level;
          const pos = getCorrectPosByDropNode(n[direction+'DropBox'+level])
          n[el.id].setAbsolutePosition(pos)
          el.level = level;
        } else {
          console.error("%c Ref don't work", 'font-size: 17px; color: red;')
        }

        if (el.isTank) {
          this.scenarioManager.success('add water tower');

          data.waterTubeMaxHeight = this.staticData.waterTubeStepLen * i;
          n['tubeForWater'+el.dragIndex].setAttrs({
            visible: true, height: data.waterTubeMaxHeight,
          });
          n['waterForTank'+el.dragIndex].setAttrs({
            height: data.waterTankHeight, visible: true
          });
          n['waterTubeForTank'+el.dragIndex].setAttrs({
            height: data.waterTubeHeight, visible: true
          });
          if (data.waterTankHeight === this.staticData.maxWaterTankHeight) {
            this.scenarioManager.success('fill tanks water');
          }
          if (data.waterTubeHeight === data.waterTubeMaxHeight) {
            this.scenarioManager.success('fill tubes water');
          }
        }
      });


      // задает дефолтную позицию для бака, если он переместился на землю
      if (waterTower[0]?.isTank) {
        const tankEl = waterTower[0];
        const tankNode = n[tankEl.id];
        const defaultPosNode = n['defaultPosWtrTank'+tankEl.dragIndex];
        filterWaterTowers(tankEl.id, this);
        setDefaultTankPos(defaultPosNode, tankNode, this);
      }
    })


    n['cityWater'].height(data.cityWaterHeight);

    n['stage'].draw();
  }


  Env = () => {
    const data = this.data;
    return (
      <>
        <Text x={30} y={30} text={this.data.title} fill={'#484848'} fontSize={30} lineHeight={1.2}/>

        <Group x={200} y={160}>
          <CanvasButton
            text={_t('repeat')}
            onClick={() => this.onClickReset()}
            btnFill={blue}
            height={40}
            strokeWidth={.3}
            fontSize={17}
            x={120}
            boxShadowColor={'rgba(0,0,0,0)'}
            visible={data.resetBtnVisible}
          />
          {this.lang !== 'en' ? (
            <CanvasButton
              text={_t('viewTheory')}
              onClick={() => this.scenarioManager.selectStepByKey('theory')}
              textFill={blue}
              btnFill={'transparent'}
              btnStroke={blue}
              btnStrokeWidth={1.5}
              height={40} width={250}
              strokeWidth={.3}
              fontSize={17}
              x={260}
              boxShadowColor={'rgba(0,0,0,0)'}
              // visible={data.goToTheoryBtnVisible && (this.props.lang !== 'en')}
              visible={data.goToTheoryBtnVisible}
            />
          ) : null}
          <CanvasButton
            text={_t('fillTank')}
            onClick={() => this.scenarioManager.success('complete water tower')}
            btnFill={blue}
            height={40} width={250}
            strokeWidth={.3}
            fontSize={17}
            x={200}
            boxShadowColor={'rgba(0,0,0,0)'}
            visible={data.fillWithWaterBtnVisible}
          />
        </Group>



        <MovingArrow
          id={'method4Springboard'}
          ref={this._ref('hintForDropPlace')}
          stageNode={this._getNode('stage')}
          length={30}
          arrowsStep={10}
          arrowsCount={1}
          x={660} y={400}
          textX={-10}
          textColor={'black'}
          textWidth={90}
          arrowColor={'black'}
          text={_t('placeHereTxt')}
          arrowsX={33} arrowsY={10}
          rotation={0}
          visible={data.withHintArrow && data.dragging && !data.withoutHint}
          getMovingCallback={this.getMovingCallback}
        />

        <Group x={750} y={240} visible={data.cardHintArrowVisible && !data.withoutHint}>
          <Text text={_t('dragElementsTxt')} fill={'black'} fontSize={17}/>
          <CurvedArrow x={70} y={100} scale={{x:.8, y:.8}} rotation={-90}/>
        </Group>

        {
          data.checkMarkVisible && (
            <CheckMarkGif x={450} y={180} withImageBg={true}/>
          )
        }
      </>
    )
  };

  Card = () => {
    const data = this.data;
    const [waterTank] = useImage(waterTankImg);
    const [waterTowerBase] = useImage(waterTowerBaseImg);
    const [card] = useImage(cardImg);

    const textStg = {
      fill: "white", fontSize: 13, width: 88, height: 78,
      text: _t('tankTxt'), align: 'center', verticalAlign: 'middle'
    }


    const onDragMoveTowerBase = (num, evt) => {
      const currTwrBase = this._getNode('towerBaseRect'+num);
      checkHoverDropBoxIntersection(currTwrBase, 'base', this);
    }

    const onDragMoveTank = (num, evt) => {
      const currTank = this._getNode('waterTankRect'+num);
      checkHoverDropBoxIntersection(currTank, 'tank', this);
    }

    return (
      <Card width={130} height={352} x={860} y={70}>
        <Group x={60} y={50}>
          {/* Начальная позиция */}
          <Rect x={-45} y={-17.5} width={70} height={70} ref={this._ref('defaultPosWtrTank1')} fill={'red'} opacity={0} zIndex={-1}/>
          <Rect x={-25} y={-37.5} width={70} height={70} ref={this._ref('defaultPosWtrTank2')} fill={'red'} opacity={0} zIndex={-1}/>
          {/* ------------ */}
          {
            [{y:20}, {x: 20}].map((el, index) => {
              const i = index+1;
              return (
                <Group x={el.x} y={el.y} key={'water-tank-'+i}>
                  <Group
                    dragElId={`waterTankMain${i}`}
                    dragIndex={i}
                    zIndex={1000}
                    draggable={data.draggable}
                    onDragMove={(evt) => {onDragMoveTank(i, evt)}}
                    onDragStart={(evt) => {
                      this.scenarioManager.success('initial drag hint');

                      const currTarget = evt.currentTarget;
                      const currId = currTarget.attrs.dragElId;
                      filterWaterTowers(currId, this);
                      this.data.dragging = true;
                    }}
                    onDragEnd={(evt) => {
                      const currTarget = evt.currentTarget;
                      this.data.dragging = false;
                      dropWaterTank(currTarget, this)
                    }}
                    ref={this._ref(`waterTankMain${i}`)}
                  >

                    {/* Бак водонапорной башни */}
                    <Group ref={this._ref('waterTank'+i)}>
                      <Image image={waterTank} width={90} height={75} offset={{x: 90/2, y: 75/2}}/>

                      <Group x={-45} y={-37.5}>
                        <Text {...textStg} ref={this._ref('waterTankText'+i)}/>

                        {/* Вода, которая заполняет бак */}
                        <Rect ref={this._ref('waterForTank'+i)} x={45} y={37} width={84} height={0} // 72
                              rotation={180} offset={{x: 42, y:36}} fill={blue} cornerRadius={[3,3, 0,0]}/>
                      </Group>
                    </Group>


                    {/* Труба с вытекающей водой */}
                    <Group x={-2.5} y={37.5}
                           // visible={data['waterTubeForTank'+i+"Visible"]}
                    >
                      <Rect
                        width={5}
                        height={97.5} //195
                        fill={orange} ref={this._ref('tubeForWater'+i)} visible={false}/>
                      <Rect ref={this._ref('waterTubeForTank'+i)} width={5} y={0} height={0} // 195
                            fill={blue}/>
                    </Group>


                    {/* Блок для пересечения */}
                    <Rect ref={this._ref('waterTankRect'+i)} x={-15} y={-17.5} width={30} height={30} opacity={0} fill={'red'}/>
                  </Group>
                </Group>
              )
            })
          }
        </Group>

        <Group x={50} y={180}>
          {
            [{}, {x:20, y:80}].map((el,indx) => {
              const i = indx+1;
              return(
                <Group x={el.x} y={el.y} key={`twrConstr${i}`}>
                  {/* Начальная позиция опоры */}
                  <Rect x={-35} y={-35} width={70} height={70} ref={this._ref('defaultPosTwrBase'+i)} fill={'black'} opacity={0}/>

                  {/* Опора держащая водонапорную башню */}
                  <Group
                    dragElId={`towerBaseMain${i}`}
                    dragIndex={i}
                    draggable={data.draggable}
                    onDragMove={(evt) => onDragMoveTowerBase(i, evt)}
                    onDragStart={(evt) => {
                      this.scenarioManager.success('initial drag hint');

                      const towerImgBase = this._getNode('towerBaseImg'+i);
                      const currTarget = evt.currentTarget;
                      const currId = currTarget.attrs.dragElId;
                      filterWaterTowers(currId, this);

                      towerImgBase.rotation(this.staticData.angleFortowerBase);
                      this.data.dragging = true;
                    }}
                    onDragEnd={(evt) => {
                      const currTarget = evt.currentTarget;
                      this.data.dragging = false;
                      dropTowerBase(currTarget, this);
                    }}
                    ref={this._ref(`towerBaseMain${i}`)}
                  >
                    {/* Хитбокс для пересечения */}
                    <Rect
                      x={-8} y={-15}
                      dragBoxId={'towerBaseRect'+i}
                      width={17}
                      height={30}
                      fill={'red'}
                      opacity={0}
                      ref={this._ref('towerBaseRect'+i)}
                    />
                    <Image
                      width={78}
                      height={88}
                      offset={{x: 39, y: 44}}
                      ref={this._ref('towerBaseImg'+i)}
                      image={waterTowerBase}
                    />
                  </Group>
                </Group>
              )
            })
          }
        </Group>
      </Card>
    )
  }




  Buildings = () => {

    const [tubeBorder] = useImage(tubeBorderImg);
    const [buildings] = useImage(buildingsImg);
    const [ground] = useImage(groundImg);
    const [failIcon] = useImage(failIconImg);

    const doubleSmallTowerFail = [
      {},
      {x: 250, y: -2},
      {x: 405, y: -30},{x: 405, y: -2}
    ];
    const singleSmallTowerFail = [
      ...doubleSmallTowerFail,
      {y: 30},
      {x: 250, y: 25},
      {x: 405, y: 27},
    ];

    return (
      <Group>
        <Group y={200}>
          <Rect y={30} width={900} height={300} fill={orange}/>
          <Rect ref={this._ref('cityWater')} x={450} y={170} offset={{x: 900/2, y: 280/2}} width={900} height={240} fill={blue} rotation={180}/>


          <Image image={buildings} y={3}/>

          <Image image={ground} y={261}/>
          <Group x={687.5} y={261}>
            <Rect width={5} height={34} fill={orange}/>
            <Rect x={110} width={5} height={34} fill={orange}/>
          </Group>

          <Group x={30} y={291}>
            <Image image={tubeBorder}/>
            <Image image={tubeBorder} x={807}/>
          </Group>

          <Group x={55} y={103} visible={this.data.failureSingleSmall}>
            {
              singleSmallTowerFail.map((pos, i) => (
                <Image key={'image-singleSmall'+i} image={failIcon} width={20} height={25} {...pos}/>
              ))
            }
          </Group>
          <Group x={55} y={103} visible={this.data.failureDoubleSmall}>
            {
              doubleSmallTowerFail.map((pos, i) => (
                <Image key={'image-doubleSmall'+i} image={failIcon} width={20} height={25} {...pos}/>
              ))
            }
          </Group>
          <Group x={645} y={180}>
            {
              this.dropBoxDirection.map((direction,directI) => {
                return (
                  <Group key={direction} x={directI*110} y={6}>
                    {
                      this.dropBoxItems.map((el, i) => {
                        return (
                          <Group key={direction+'DropBox'+el} y={-75*i}>
                          {/* Дропбокс для строения */}
                            <Rect
                              dropBoxId={'id-'+direction+'-DropBox-'+el}
                              ref={this._ref(direction+'DropBox'+el)}
                              width={90} height={75}
                              fill={'blue'}
                              opacity={0}
                            />
                          </Group>
                        )
                      })
                    }
                  </Group>
                )
              })
            }
          </Group>
        </Group>
      </Group>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this._ref('stage')}
          lessonCode={this.props.code}

          component={this}
          theoryVisible={this.data.theoryVisible}
          additionalTheoryStgs={{
            skipInitialStep: true,
            hideResetBtn: true,
            bgColor: 'white',
          }}
        >
          <Layer preventDefault={false}>
            <this.Buildings/>
            <this.Card/>

            <this.Env/>

            <Rect x={680} y={506.5} width={140} height={50} fill={'#A67D53'}/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  lang: state.lessonsReducer.lang
});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaterTower);

const styles = {
  mainContainer: {
    background: '#C7F3FE'
  }
};
