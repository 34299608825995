import React from 'react';
import {Group, Rect} from 'react-konva';
import useForceUpdate from '../../../../utils/custom-hooks/useForceUpdate';
import rand from '../../../../utils/rand';
import {height, width} from '../../../canvas/containers/CanvasContainer';

import Rectangle from './figures/Rectangle';
import {rectangleIntersection} from './figures/utils/intersection';

class Track {
  deltaX = 0;
  trackDataArray = [];

  get lastObj() {
    let lastObj = this.trackDataArray[this.trackDataArray.length - 1];
    if (lastObj === undefined) return new Rectangle({x: Track.rectProps.x.first, width: 0, height: 0});
    return lastObj;
  }

  constructor() {
    this.fill();
  }

  fill = () => {
    const {rectProps: {x}} = Track;

    let updated = false;
    for (let delta = this.calcLastDelta(); delta > x.min && delta + rand(0, x.max - x.min) > x.max; delta = this.calcLastDelta()) {
      this.trackDataArray.push(this.createObject());
      updated = true;
    }
    return updated;
  };
  clear = () => {
    let updated = false;
    while (this.trackDataArray[0].endX + this.deltaX < 0) {
      this.trackDataArray.shift();
      updated = true;
    }
    return updated;
  };

  calcLastDelta = () => width - this.lastObj.endX - this.deltaX + Track.rectProps.x.min;

  createObject = () => {
    const {rectProps: {x, y, width, height}} = Track;

    let lastObj = this.lastObj;

    return new Rectangle({
      x: lastObj.endX + rand(x.min, x.max),
      y: rand(y.min, y.max),
      width: rand(width.min, width.max),
      height: rand(height.min, height.max),
    });
  };

  move = (val) => {
    this.deltaX += val;
    if (this.fill() || this.clear()) this.forceUpdate();
  };

  intersection = (rect) => {
    const movedRect = new Rectangle({...rect, x: rect.x - this.deltaX});
    for (let item of this.trackDataArray) {
      if (movedRect.endX < item.x) return false;
      if (rectangleIntersection(movedRect, item)) return true;
    };
    return false;
  };

  Component = ({reffer}) => {
    const {color} = Track;
    this.forceUpdate = useForceUpdate();

    return (
      <Group ref={reffer}>
        {this.trackDataArray.map((item, index) => (
          <Rect
            key={index}
            {...item}
            y={height - (item.height + item.y)}
            fill={color}
          />
        ))}
      </Group>
    );
  };

  static color = '#a8dadc';
  static rectProps = {
    x: {
      min: 15,
      max: 100,
      first: 500,
    },
    y: {
      min: 0,
      max: 0,
    },
    width: {
      min: 30,
      max: 150,
    },
    height: {
      min: 50,
      max: 500,
    },
  };
}

export default Track;
