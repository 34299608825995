import moment from "moment";
import useSetInterval from "../../../utils/custom-hooks/useSetInterval";
import {Space} from "antd";
import React, {useState} from "react";
import {connect} from "react-redux";


const LessonSessionInfoBlock = (props) => {
  const [timeBeforeStart, setTimeBeforeStart] = useState('');
  const lessonSession = props.lessonSession;

  const dateTimeStartLesson = moment(lessonSession.startLessonDatetime, 'DD.MM.YYYY HH:mm');

  useSetInterval(() => {
    const duration = moment.duration(dateTimeStartLesson.diff(moment()));
    const hours = Math.trunc(duration.asHours());
    const min = Math.trunc(duration.asMinutes()) % 60;
    const sec = Math.trunc(duration.seconds());
    // console.log()
    if (duration.asSeconds() > 0)
      return setTimeBeforeStart(`${hours}:${min}:${sec}`);
    else
      return setTimeBeforeStart(`Урок начался!`);
  }, 1000);

  return (
    <Space style={{padding: '10px', fontSize: '1.2em'}} size={30}>
      {/* ToDo complete date and time */}
      {/*<p><b>Дата и время начала урока:</b> {dateTimeStartLesson.format('DD.MM.YYYY HH:mm')}</p>*/}
      {/*<p><b>Время до начала урока:</b> {timeBeforeStart}</p>*/}
      <p><b>Ссылка на zoom:</b> {lessonSession.zoomLink}</p>
    </Space>
  )
};

const mapStateToProps = (state) => ({
  lessonSession: state.lessonsReducer.lessonSession,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LessonSessionInfoBlock);
