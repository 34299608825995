import React, {useState} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Group, Image, Layer, Rect, Text} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import Card from "../../../canvas/components/Card";
import {layout2} from "../../../../utils/styles";
import CanvasButton from "../../../canvas/components/CanvasButton";
import crownImg from '../../../../images/crown/crown.png';
import background1Img from '../../../../images/crown/background1.png';
import background2Img from '../../../../images/crown/background2.png';
import background3Img from '../../../../images/crown/background3.png';
import dynamometrImg from '../../../../images/crown/dinamometr.png';
import hookImg from '../../../../images/crown/hook.png';
import CanvasInput from "../../../canvas/components/CanvasInput";
import {
  dragBoundFunc,
  dynamometrDragBoundFunc,
  dynamometrDragEnd,
  dynamometrDragMove,
  dynamometrDragStart,
  getCorrectStateDataByCode,
  getCrownLessonCodes,
  getTitleByCode,
  onDragEnd,
  onDragMove,
  onDragStart,
  setInitialCrownPos
} from "../utils/CrownUtils";
import {generateArray, sendSuccessForScenario, showFailOrSuccessPopup, toggleVisibleEl} from "../../../../utils/common";
import ArrowHint from "../../../canvas/components/ArrowHint";
import * as actions from "../../../../store/actions";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {defaultPatchData, getScenarioByCode} from "../scenario";
import CanvasSuccessCard from "../../../canvas/components/CanvasSuccessCard";
import CanvasScaleWithBowls from "../../../canvas/components/scales/CanvasScaleWithBowls";
import CrownScale from "../components/CrownScale";


class Crown extends React.Component {
  constructor(props) {
    super(props);

    this.staticData = {};
    this.crownsItems = [];

    this.stageRef = React.createRef();

    this.cardItem1Ref = React.createRef();
    this.cardItem2Ref = React.createRef();
    this.cardsContainerRef = React.createRef();

    this.dynamometrValRef = React.createRef();
    this.densityValRef = React.createRef();
    this.powerValRef = React.createRef();

    this.dynamometrRef = React.createRef();
    this.hookRef = React.createRef();

    this.btnNextLsnRef = React.createRef();
    this.waterRef = React.createRef();

    this.crown1Ref = React.createRef();
    this.crown2Ref = React.createRef();
    this.crown3Ref = React.createRef();
    this.crown4Ref = React.createRef();
    this.crown5Ref = React.createRef();

    this.btnHintRef = React.createRef();

    this.hookActionBoxRef = React.createRef();
    this.crownActionBoxRef = React.createRef();
    this.defaultDynamometrPlaceRef = React.createRef();
    this.defaultCrownPlaceRef = React.createRef();
    this.waterCrownPlaceRef = React.createRef();

    this.topLeftActBoxRef = React.createRef();
    this.topRightActBoxRef = React.createRef();
    this.bottomLeftActBoxRef = React.createRef();
    this.bottomRightActBoxRef = React.createRef();

    this.titleItems = generateArray(3);
    this.titleItems.forEach((el, i) => {
      this[`titleStep${i}Ref`] = React.createRef();
    })

    this.crownsItems.forEach((item) => {
      this[`crown${item}Ref`] = React.createRef();
    })

    this.state = {
      code: '',
      step: 0,
    };

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      starClosingPopupTime: undefined,

      densityVal: 1000,
      powerVal: 10,
      dynamometrVal: 0,

      inputVal: null, // Значение
      inputCalcErr: null, // Погрешность
      relativeCalcErr: null, // Относительная погрешность

      crownWasDragged: false,
      hookActive: false,
      crownInWater: false,

      crownsInWater: [],

      btnVisibleTimer: 0,

      ...defaultPatchData,
    };
    this.data = cloneDeep(this.initialData);
    let scenario = getScenarioByCode(this);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }


  get waterHeight () {
    const { waterHeightItem, minWaterHeight} = this.staticData;
    const data = this.data;

    const height = minWaterHeight + waterHeightItem * data.crownsInWater.length;

    if (data.crownInWater) {
      return data.waterHeight >= height ? height : data.waterHeight + (data.waterHeightCoeff || .5);
    } else {
      return data.waterHeight <= height? height : data.waterHeight - (data.waterHeightCoeff || .5);
    }
  }

  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  updateDefaultData = () => {
    const { staticData, initialData } = getCorrectStateDataByCode(this);
    this.staticData = cloneDeep({...this.staticData, ...staticData});
    this.crownsItems = Array(this.staticData.crownsCount).fill(1).map((el, i) => i+1);
    this.initialData = cloneDeep({...this.initialData, ...initialData});
    this.data = cloneDeep({...this.initialData, ...initialData});
    this.updateStage();
  }

  get dynamometrVal () {
    const { lsnCrown3 } = getCrownLessonCodes(this);
    const {maxDynamometrVal, minDynamometrVal} = this.staticData;
    const data = this.data;

    if (lsnCrown3) {
      if (data.hookActive) {
        return data.dynamometrVal >= maxDynamometrVal ? maxDynamometrVal : data.dynamometrVal + 10;
      } else {
        return data.dynamometrVal <= minDynamometrVal ? minDynamometrVal : data.dynamometrVal - 10;
      }
    } else {
      return 0
    }
  }
  componentDidUpdate() {
    if (this.state.code !== this.props.code) {
      this.updateDefaultData();
      this.setState({code: this.props.code});
    }
  }

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  resetFields = () => {
    this.data = {
      ...this.data,
      inputVal: null,
      inputCalcErr: null,
      relativeCalcErr: null,
    }
  };

  getNodes = () => {
    const titles = this.titleItems.reduce((accum, el, i) => {
      accum[`titleStep${i}Node`] = this[`titleStep${i}Ref`]?.current;
      return accum
    }, {});
    const crownsNodes = this.crownsItems.reduce((accum, id, i) => {
      accum[`crown${id}Node`] = this[`crown${id}Ref`]?.current;
      return accum
    }, {});
    return ({
      ...titles,
      ...crownsNodes,

      stageNode: this.stageRef?.current,

      btnNextLsnNode: this.btnNextLsnRef?.current,

      densityValNode: this.densityValRef?.current,
      powerValNode: this.powerValRef?.current,
      dynamometrValNode: this.dynamometrValRef?.current,

      defaultDynamometrPlaceNode: this.defaultDynamometrPlaceRef?.current,
      dynamometrNode: this.dynamometrRef?.current,
      hookNode: this.hookRef?.current,

      btnHintNode: this.btnHintRef?.current,
      waterNode: this.waterRef?.current,

      hookActionBoxNode: this.hookActionBoxRef?.current,
      crownActionBoxNode: this.crownActionBoxRef?.current,

      topLeftActBoxNode: this.topLeftActBoxRef?.current,
      topRightActBoxNode: this.topRightActBoxRef?.current,
      bottomLeftActBoxNode: this.bottomLeftActBoxRef?.current,
      bottomRightActBoxNode: this.bottomRightActBoxRef?.current,

      defaultCrownPlaceNode: this.defaultCrownPlaceRef?.current,
      waterCrownPlaceNode: this.waterCrownPlaceRef?.current,

      cardItem1Node: this.cardItem1Ref?.current,
      cardItem2Node: this.cardItem2Ref?.current,
      cardsContainerNode: this.cardsContainerRef?.current,
    });
  }

  onClickReset = () => {
    this.crownsItems.forEach((crownId) => {
      setInitialCrownPos(this, crownId);
    })
    this.data = cloneDeep({...this.initialData});
    this.updateStage();
    this.setState(({update: 1}));
    this.scenarioManager.resetScenario();
  };
  get dynamometrInWaterVal () {
    const { lsnCrown3 } = getCrownLessonCodes(this);
    const {maxDynamometrInWaterVal} = this.staticData;
    const data = this.data;

    if (lsnCrown3 && data.hookActive && data.crownInWater) {
      return data.dynamometrVal > maxDynamometrInWaterVal && data.dynamometrVal > 0 ? data.dynamometrVal - 1 : maxDynamometrInWaterVal;
    } else {
      return 0
    }
  }

  componentDidMount() {
    this.updateDefaultData();
    window.requestAnimationFrame(this.commonMove);
    this.setState({code: this.props.code});
    this.scenarioManager.resetScenario();
  }

  move = (time) => {
    const {} = this.state;

    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    data.prevTime = time;

    data.waterHeight = this.waterHeight;
    data.dynamometrVal = data.crownInWater ? this.dynamometrInWaterVal : this.dynamometrVal;

    this.updateStage(time);
  };

  updateStage(time) {
    const props = this.props;
    const data = this.data;
    const staticData = this.staticData;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;
    const {
      lsnCrown1,
      lsnCrown2_0,
      lsnCrown2_1,
      lsnCrown2_2,
      lsnCrown3,
      lsnCrown4,
    } = getCrownLessonCodes(this);
    const {
      waterNode,
      btnHintNode,
      densityValNode,
      dynamometrValNode,
      powerValNode,
      btnNextLsnNode,
      cardItem1Node,
      cardItem2Node,
      cardsContainerNode,
    } = this.getNodes();
    const nodes = this.getNodes();

    if (data.waterHeight) {
      waterNode.height(data.waterHeight);
      waterNode.offsetY(data.waterHeight - staticData.minWaterHeight);
    }
    btnHintNode.opacity(Number(!data.crownWasDragged));

    const timedeltaSec = Math.abs((data.starClosingPopupTime - data.prevTime)/ 1000);

    const conditions = !props.successModalVisible && this.state.step < 2;
    if (conditions) {
      data.starClosingPopupTime = data.starClosingPopupTime || time;
      toggleVisibleEl(btnNextLsnNode, timedeltaSec <= 3);
    }

    toggleVisibleEl(btnNextLsnNode, (!timedeltaSec || timedeltaSec <= 3) && conditions || props.successModalVisible);


    toggleVisibleEl(cardItem1Node, !lsnCrown4 && !lsnCrown1 && !lsnCrown2_0 && !lsnCrown2_1 && !lsnCrown2_2);
    toggleVisibleEl(cardItem2Node, !lsnCrown2_0 && !lsnCrown2_1 && !lsnCrown2_2);
    toggleVisibleEl(btnNextLsnNode, !lsnCrown2_1);
    toggleVisibleEl(cardsContainerNode, lsnCrown2_1);

    if (lsnCrown2_0 || lsnCrown2_1 || lsnCrown2_2) {
        this.titleItems.forEach((el, ix) => {
          const node = nodes[`titleStep${ix}Node`];
          const titleText = getTitleByCode(this, ix);
          toggleVisibleEl(node, this.state.step !== ix);
          node.text(titleText);
        })
    }

    densityValNode    && densityValNode.text(data.densityVal);
    powerValNode      && powerValNode.text(data.powerVal);
    dynamometrValNode && dynamometrValNode.text(data.dynamometrVal);

    stageNode.draw();
  }

  checkResultValue = () => {
    const { lsnCrown1, lsnCrown2_0, lsnCrown2_1, lsnCrown2_2, lsnCrown3, lsnCrown4, lsnCrown5 } = getCrownLessonCodes(this);
    const {inputVal, inputCalcErr, relativeCalcErr} = this.data;
    const {minCorrectVal, maxCorrectVal, correctVal, correctCalcErr, correctRelativeCalcErr} = this.staticData;

    const valWithDot = String(inputVal).replace(',', '.');
    const correctValFormat = Number(`${valWithDot}`.split('.')[0]);

    let success = correctValFormat === correctVal;
    if (lsnCrown1) {
      success &= Number(inputCalcErr) === correctCalcErr;
    }
    if (lsnCrown2_0 || lsnCrown2_1 || lsnCrown2_2) {
      success &= Number(relativeCalcErr) === correctRelativeCalcErr
    }
    if (lsnCrown3) {
      success = (correctValFormat >= minCorrectVal) && (correctValFormat <= maxCorrectVal);
    }

    if ((lsnCrown2_2 || lsnCrown1 || (lsnCrown3) || lsnCrown4) && success) {
      sendSuccessForScenario(this);
    }

    if (lsnCrown2_0 && success) {
      this.setState({step: 1});
    }

    if (!lsnCrown5 || !success) {
      showFailOrSuccessPopup(this, success);
    }
  }

  onClickContinue = () => {
    this.resetFields();
    this.setState(() => ({step: 2}), this.updateDefaultData);
    this.setState(() => ({code: this.props.code}));
  }

  Canvas = (props) => {
    const data = this.data;

    const {
      lsnCrown1,
      lsnCrown2_0,
      lsnCrown2_1,
      lsnCrown2_2,
      lsnCrown3,
      lsnCrown4,
      lsnCrown5,
    } = getCrownLessonCodes(this);

    const [crown] = useImage(crownImg);
    const [background1] = useImage(background1Img);
    const [background2] = useImage(background2Img);
    const [background3] = useImage(background3Img);
    const [dynamometr] = useImage(dynamometrImg);
    const [hook] = useImage(hookImg);

    let text = getTitleByCode(this, 0);
    let textContainerSettings = { x: 100, y: 40 };
    let textSetting = { stroke: layout2.blue, fill: layout2.blue, strokeWidth: .2, fontSize: 27, lineHeight: 1.3};
    let smallTextSetting = { ...textSetting, fontSize: 17 };

    let correctBack = background1;
    let backgroundSettings = { x:70, y:220 };
    let waterSettings = { x: 68, y: 112, width: 312, height: data.waterHeight, };
    let crownSettings = { x: 470, y: 190, width: 135, height: 65, };
    let resetBtnSettings = { width: 150, height: 45, x: 750, y: 440, };
    let continueBtnSettings = { width: 170, height: 45, x: 280, y: 100, };
    let cardContainerSettings = { x: 520, y: 120, };
    let initialCrownPlaceSettings = { x: 470, y: 190, width: 135, height: 65, opacity: 0, fill: 'black' };
    let waterCrownPlaceSettings = { x: 155, y: 210, width: 135, height: 65, opacity: 0, fill: 'black' };

    let topLeft = { x: 140, y: 20, width: 310, height: 200, opacity: 0};
    let bottomLeft = { x: 140, y: 235, width: 310, height: 250, opacity: 0};
    let topRight = { x: 460, y: 20, width: 310, height: 200, opacity: 0};
    let bottomRight = { x: 460, y: 235, width: 310, height: 250, opacity: 0};

    if (lsnCrown2_2) {
      textContainerSettings = {...textContainerSettings, y: 10};
    }
    if (lsnCrown2_0 || lsnCrown2_1 || lsnCrown2_2) {
      waterSettings = { ...waterSettings, y: 127 };
    }
    if (lsnCrown3 && !lsnCrown5) {
      correctBack = background2;
      backgroundSettings = {x:100, y:330};
      waterSettings = { ...waterSettings, x: 95, y: 152, width: 200 };
      crownSettings = { x: 377, y: 205, width: 135, height: 65, };
      resetBtnSettings = { width: 150, height: 45, x: 820, y: 460, };
      cardContainerSettings = { x: 420, y: 120, };
      initialCrownPlaceSettings = { ...initialCrownPlaceSettings, x: 375, y: 200 };
      waterCrownPlaceSettings = { ...waterCrownPlaceSettings, x: 130, y: 220 };

      topLeft = { x: 168, y: 125, width: 195, height: 200, opacity: 0 };
      bottomLeft = { x: 168, y: 340, width: 195, height: 170, opacity: 0 };
      topRight = { x: 375, y: 125, width: 240, height: 200, opacity: 0 };
      bottomRight = { x: 375, y: 340, width: 240, height: 170, opacity: 0 };
    }
    if (lsnCrown5) {
      correctBack = background3;
      waterSettings = {...waterSettings, x: 68, y: 145};

    }
    return (
      <Group {...props}>

        <Image image={correctBack} {...backgroundSettings}/>

        <Group {...textContainerSettings}>
          {
            (lsnCrown1 || lsnCrown4 || lsnCrown3) && (
              <Text text={text} {...textSetting}/>
            )
          }

          {
            (lsnCrown2_2 || lsnCrown2_1 || lsnCrown2_0) && (
              this.titleItems.map((el, i) => (
                <Text key={`titleStep-${i}`} ref={this[`titleStep${i}Ref`]} {...textSetting} opacity={i===0} text={text}/>
              ))
            )
          }
        </Group>

        {/* -------- SECTIONS BOXES -------- */}
        <Rect ref={this.topLeftActBoxRef} {...topLeft} fill={'purple'}/>
        <Rect ref={this.bottomLeftActBoxRef} {...bottomLeft} fill={'red'}/>
        <Rect ref={this.topRightActBoxRef} {...topRight} fill={'pink'}/>
        <Rect ref={this.bottomRightActBoxRef} {...bottomRight} fill={'yellow'}/>

        <Group {...cardContainerSettings}>
          <Group ref={this.cardsContainerRef}>
            {
              !lsnCrown2_1 && (
                <CanvasButton
                  text={'Проверить'}
                  onClick={() => {this.checkResultValue()}}
                  fontSize={23}
                  strokeWidth={.2}
                  btnCornerRadius={0}
                  width={150}
                  height={45}
                  y={(lsnCrown2_0 || lsnCrown2_2) ? 190 : 90}
                />
              )
            }

            <Group ref={this.cardItem1Ref} opacity={Number(!lsnCrown3)}>
              <Card height={80} width={lsnCrown4 ? 270 : 400}>
                <Group x={15} y={25}>
                  <Text x={10} text={`V =`} {...textSetting}/>
                  <CanvasInput
                    id={'1'}
                    y={1}
                    x={70}
                    width={90}
                    height={30}
                    stage={this.stageRef?.current}
                    textColor={layout2.blue}
                    onInput={(val) => {
                      this.data.inputVal = val || null;
                    }}
                    value={data.inputVal}
                  />
                  {
                    !lsnCrown4 && (
                      <>
                        <Text x={172} text={`±`} {...textSetting} />
                        <CanvasInput
                          id={'1'}
                          y={1}
                          x={200}
                          width={90}
                          height={30}
                          stage={this.stageRef?.current}
                          textColor={layout2.blue}
                          onInput={(val) => {
                            this.data.inputCalcErr = val || null;
                          }}
                          value={data.inputCalcErr}
                        />
                      </>
                    )
                  }
                  <Text x={lsnCrown4 ? 170 : 300} text={`(см3)`} {...textSetting} />
                </Group>
              </Card>
            </Group>
            <Group ref={this.cardItem2Ref} opacity={Number(lsnCrown2_0 || lsnCrown2_1 || lsnCrown2_2)}>
              <Card height={80} width={400} y={95}>
                <Group x={15} y={25}>
                  <Text y={-12} x={10} text={`Относительная \nпогрешность`} {...textSetting} fontSize={22}/>
                  <CanvasInput
                    id={'3'}
                    y={1}
                    x={200}
                    width={90}
                    height={30}
                    stage={this.stageRef?.current}
                    textColor={layout2.blue}
                    onInput={(val) => {
                      this.data.relativeCalcErr = val || null;
                    }}
                    value={data.relativeCalcErr}
                  />
                  <Text y={-2} x={300} text={`(%)`} {...textSetting} />
                </Group>
              </Card>
            </Group>
          </Group>

          <CanvasButton
            btnRef={this.btnNextLsnRef}
            text={'Продолжить > '}
            onClick={() => this.onClickContinue()}
            fontSize={23}
            strokeWidth={.2}
            btnCornerRadius={0}
            opacity={0}
            {...continueBtnSettings}
          />


          {
            (lsnCrown3 || lsnCrown5) && (
              <>
                <Card height={80} width={300}>
                  <Group x={15} y={25}>
                    <Text x={10} text={`ρ =`} {...textSetting}/>
                    <CanvasInput
                      id={'1'}
                      y={1}
                      x={70}
                      width={90}
                      height={30}
                      stage={this.stageRef?.current}
                      textColor={layout2.blue}
                      onInput={(val) => {
                        data.inputVal = val || null;
                      }}
                      value={data.inputVal}
                    />
                    <Text x={180} text={`(кг/м3)`} {...textSetting} />
                  </Group>
                </Card>

                <Group visible={!lsnCrown5}>
                  <Card height={180} width={150} x={400}>
                    <Group x={20} y={22}>
                      <Text text={`g`} {...smallTextSetting} fontStyle={'bold'} fontSize={20} />
                      <Text x={12} y={3} text={`, Н/кг`} {...smallTextSetting} />
                      <Text ref={this.powerValRef} y={23} text={`123`} {...textSetting} />
                    </Group>
                    <Group x={20} y={92}>
                      <Text text={`ρ`} {...smallTextSetting} fontStyle={'bold'} fontSize={20} />
                      <Text x={20} y={3} text={`воды, кг/м3`} {...smallTextSetting} />
                      <Text ref={this.densityValRef} y={23} text={`123`} {...textSetting} />
                    </Group>
                  </Card>

                  <Rect
                    ref={this.defaultDynamometrPlaceRef}
                    x={208} y={120}
                    fill={'black'}
                    width={113} height={205}
                    cornerRadius={15}
                    opacity={0}
                  />
                  <Group
                    ref={this.dynamometrRef}
                    draggable
                    x={207} y={120}
                    dragBoundFunc={(pos) => dynamometrDragBoundFunc(this, pos)}
                    onDragStart={(e) => {dynamometrDragStart(this, e)}}
                    onDragMove={(e) => {dynamometrDragMove(this, e)}}
                    onDragEnd={(e) => {dynamometrDragEnd(this, e)}}
                  >
                    <Group x={52} y={140}>
                      <Image ref={this.hookRef} image={hook}/>
                      <Rect
                        ref={this.hookActionBoxRef}
                        x={-13}
                        y={70}
                        width={40} height={40} fill={'purple'}
                        opacity={0}
                      />
                    </Group>
                    <Group>
                      <Image image={dynamometr}/>
                      <Text
                        x={16} y={15}
                        width={80}
                        ref={this.dynamometrValRef}
                        text={`1230`} {...smallTextSetting}
                        fontStyle={'bold'}
                        align={'center'}
                      />
                    </Group>
                  </Group>
                </Group>
              </>
            )
          }

          {/* ------ HINT ------- */}
          <Group ref={this.btnHintRef}>
            {
              lsnCrown1 && (
                <ArrowHint
                  rotation={270}
                  x={150} y={270}
                  textX={40} textY={-40}
                  text={'Корону можно \nперемещать'}
                />
              )
            }
          </Group>
        </Group>

        <Group x={70} y={220}>
          {/* ------ ACTION BOXES ------ */}
          <Rect
            ref={this.defaultCrownPlaceRef}
            {...initialCrownPlaceSettings}
          />
          <Rect
            ref={this.waterCrownPlaceRef}
            {...waterCrownPlaceSettings}
          />



          {/* ------ CROWN ------ */}
          {
            !lsnCrown2_1 && (
              <>
              {
                this.crownsItems.map((id) => (
                  <Group
                    key={'item' + id}
                    ref={this[`crown${id}Ref`]}
                    draggable
                    dragBoundFunc={(pos) => dragBoundFunc(this, pos, id)}
                    onDragStart={(e) => {
                      onDragStart(this, e, id)
                    }}
                    onDragMove={(e) => {
                      onDragMove(this, e, id)
                    }}
                    onDragEnd={(e) => {
                      onDragEnd(this, e, id)
                    }}
                    {...crownSettings}
                  >
                    <Image image={crown}/>
                    <Rect
                      ref={this.crownActionBoxRef}
                      x={25} width={80} height={30} fill={'black'}
                      opacity={0}
                    />
                  </Group>
                ))
              }
              </>
            )
          }

          {/* ------ WATER ------ */}
          <Rect
            ref={this.waterRef}
            cornerRadius={[0,0, 20, 20]}
            fill={'#00bfff'}
            opacity={.3}
            {...waterSettings}
          />
        </Group>
        {
          (lsnCrown2_0 || lsnCrown2_2) && (
            <CanvasButton
              text={'Сбросить'}
              onClick={() => this.onClickReset()}
              fontSize={23}
              strokeWidth={.2}
              btnCornerRadius={0}
              {...resetBtnSettings}
            />
          )
        }



       {/* Scale img */}
        <Group visible={lsnCrown5}>
          <CanvasScaleWithBowls
            id={'imgScale'}
            x={850} y={360}
            scaleNum={.4}
            _ref={()=> {}}
            _getNode={()=> {}}
            checkRightWeight={() => false}
            checkLeftWeight={() => false}
            getMovingCallback={this.getMovingCallback}
          />
          <Image
            x={735} y={397}
            image={crown}
            scale={{x: .4, y: .4}}
          />
          <CanvasButton
            text={'Взвесить'}
            onClick={() => this.scenarioManager.selectStepByKey('scale')}
            fontSize={20}
            strokeWidth={.2}
            btnCornerRadius={0}
            {...resetBtnSettings}
            y={450}
            x={770}
            height={40}
          />
        </Group>
      </Group>
    )
  };

  QuestionStep = (props) => {
    const data = this.data;

    const BtnItem = (props) => {
      const [result, setResult] = useState( undefined);
      const [hover, setHover] = useState(false);
      let bgColor = hover ? '#BCDDE0' : '#eeebe5';
      let btnStroke = '#6C8DC8';
      let textFill = '#6C8DC8';

      if (result === true) {
        btnStroke = '#6BB562';
        bgColor = '#6BB562';
        textFill = 'white';
      }
      if (result === false) {
        btnStroke = '#E48A81';
        bgColor = '#E48A81';
        textFill = 'white';
      }

      const onClick = () => {
        setResult(props.correctBtn);
        setTimeout(() => {
          if (!props.correctBtn) {
            setResult(undefined);
          }
          props.onClick();
        }, 500);
      }
      return (
        <Group
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <CanvasButton
            x={props.x} y={props.y}
            text={props.text}
            textStroke={textFill}
            strokeWidth={.2}
            textFill={textFill}
            textWidth={280}
            fontSize={23}
            height={55}
            width={280}
            btnStroke={btnStroke}
            btnStrokeWidth={2}
            btnFill={bgColor}
            onClick={onClick}
            boxShadowColor={'rgba(0,0,0,0)'}
          />
        </Group>
      )
    }

    return (
      <Group {...props}>
        <Text
          x={70} y={40}
          text={'Можно утверждать, что корона выполнена \nиз чистого золота?'}
          fill={layout2.blue} fontSize={27} lineHeight={1.3}
        />

        <Group x={350} y={230}>
          <BtnItem
            text={'Чистое золото'}
            correctBtn={false}
            onClick={() => {
              this.scenarioManager.selectStepByKey('theory');
            }}
          />
          <BtnItem
            x={0} y={80}
            text={'Другой материал'}
            correctBtn={true}
            onClick={() => {
              this.scenarioManager.selectStepByKey('animation');
            }}
          />
        </Group>
      </Group>
    )
  }


  render() {
    const { lsnCrown5, } = getCrownLessonCodes(this);
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this.stageRef}
          lessonCode={this.props.code}
          component={this}
          withVideo={true}
          videoVisible={this.data.videoSrc}
          theoryVisible={this.data.theoryVisible}
          additionalTheoryStgs={{
            skipInitialStep: true,
            hideResetBtn: true,
            bgColor: 'white',
          }}
        >
          <Layer
            preventDefault={false}
          >
            <this.Canvas visible={this.data.lsnStep}/>
            <this.QuestionStep visible={this.data.questionStep}/>

            <CrownScale
              visible={lsnCrown5 && this.data.crownScaleStep}
              crownWeight={this.data.crownWeight}
              rightWeightItems={this.data.rightWeightItems}
              getMovingCallback={this.getMovingCallback}
              mainComp={this}
            />

            <CanvasSuccessCard
              goToTheoryVisible={this.data.goToTheoryVisible}
              visible={this.data.successPopupVisible}
              goToTheory={() => {this.scenarioManager.selectStepByKey('theory')}}
              onClickReset={() => this.onClickReset()}
            />
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  successModalVisible: state.commonReducer.successModalVisible,
  failureModalVisible: state.commonReducer.failureModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Crown);

const styles = {
  mainContainer: {
    background: '#eeebe5',
    width: '100%',
    height: '100%'
  }
};
