import {
  getFilterStudentsByGrades,
  getFilterMeasure,
  getFilterRows
} from "../../../../utils/tableFilters";


export const getConfigFilter = (gradesArr, setFilterStateFunc) => [
  getFilterStudentsByGrades(gradesArr, false, setFilterStateFunc),
  getFilterRows(setFilterStateFunc),
  getFilterMeasure(setFilterStateFunc),
]