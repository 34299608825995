import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import backgroundImg from '../../../../images/pathandspeed/train/background2.png';
import timerImg from '../../../../images/pathandspeed/train/timer.png';
import timerImgEn from '../../../../images/pathandspeed/train/timerEn.png';
import trainImg from '../../../../images/pathandspeed/train/train.png';
import train2Img from '../../../../images/pathandspeed/train/train2.png';
import {layout2, lsnLabelTxtStyle, lsnTitleTxtStyle, mainColor} from "../../../../utils/styles";
import {getCorrectTitle} from "../utils/trainUtils";
import Card from "../../../canvas/components/Card";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {
  generateArray,
  getOpacityForNode, sendSuccessForScenario,
  showFailOrSuccessPopup,
  toFixed,
  toggleVisibleEl
} from "../../../../utils/common";
import * as actions from "../../../../store/actions";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {_t, getCorrectLang} from "../../../../utils/lang/common";


class Train extends React.Component {
  constructor(props) {
    super(props);

    this.lang = getCorrectLang();

    this.stageRef = React.createRef();

    this.inputCardRef = React.createRef();
    this.trainRef = React.createRef();

    this.titleStep0Ref = React.createRef();
    this.titleStep1Ref = React.createRef();

    this.timerRef = React.createRef();
    this.timerBtnRef = React.createRef();
    this.speedRef = React.createRef();

    this.staticData = {
      stepsCount: 2,
      trainCount: 15,
      maxTrainOffset: 1900
    };
    this.state = {};

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      step: 0,

      trainStart: false,
      timerStart: false,

      trainOffset: 0,
      trainSize: 0,

      speed: 180,

      timerStartTime: null,
      timer: 0,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  get trainOffset() {
    const {maxTrainOffset} = this.staticData;
    const data = this.data;
    if (!data.trainStart) {
      return -200
    }
    return data.trainOffset < maxTrainOffset ? data.trainOffset += (90 * data.timedeltaSec) : maxTrainOffset;
  }

  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
  };

  getNodes = (stageNode) => {
    const titles = generateArray(this.staticData.stepsCount).reduce((accum, el, i) => {
      accum[`titleStep${i}Node`] = this[`titleStep${i}Ref`]?.current;
      return accum
    }, {})
    return ({
      ...titles,
      inputCardNode: this.inputCardRef?.current,
      trainNode: this.trainRef?.current,
      timerBtnNode: this.timerBtnRef?.current,
      timerNode: this.timerRef?.current,
      speedNode: this.speedRef?.current
    });
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario) / 1000);

    let timedeltaSec = 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = timedelta / 1000;

    data.trainOffset = this.trainOffset;

    if (data.timerStart) {
      data.timer = Math.abs(toFixed((data.timerStartTime - data.prevTime) / 1000, 0));
    }

    this.checkTrainStep();
    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const {
      inputCardNode,
      trainNode,
      timerNode,
      timerBtnNode,
      speedNode,
      titles
    } = this.getNodes(stageNode);
    const nodes = this.getNodes(stageNode);

    const titleItems = generateArray(this.staticData.stepsCount);
    titleItems.forEach((el, i) => {
      const node = nodes[`titleStep${i}Node`];
      const title = getCorrectTitle(i);
      node.text(title);
      toggleVisibleEl(node, data.step !== i);
    })

    toggleVisibleEl(inputCardNode, data.step < 1);

    timerNode && timerNode.text(data.timer);
    let timerBtnText = _t('train_task.Count_time_button');
    if (data.timerStart) {
      timerBtnText = _t('train_task.Stop_time_button');
    }
    if (!data.timerStart && data.timerStartTime !== null) {
      timerBtnText = _t('train_task.Reset_time_button');
    }
    timerBtnNode && timerBtnNode.text(timerBtnText);
    trainNode && trainNode.offsetX(-data.trainOffset);

    stageNode.draw();
  }

  onChangeInput = (val) => {
    this.data.trainSize = toFixed(Number(val), 0);
  }
  chekResult = () => {
    const success = Number(this.data.trainSize) >= 450 && Number(this.data.trainSize) <= 550;
    showFailOrSuccessPopup(this, success);
    if (success) {
      sendSuccessForScenario(this);
    }
  }

  checkTrainStep = () => {
    const data = this.data;
    const cardVisible = !data.timerStart && data.timerStartTime && data.trainStart;
    if (cardVisible) {
      this.data.step = 1;
    }
    if (this.data.trainOffset >= this.staticData.maxTrainOffset) {
      this.data.trainOffset = -200;
      this.data.trainStart = false;
    }
  }

  startForTrain = () => {
    this.data.trainStart = true;
  }
  timerController = () => {
    if (!this.data.timerStart && this.data.timerStartTime !== null) {
      this.resetTimer();
    } else if (this.data.timerStart) {
      this.stopTimer();
    } else {
      this.startForTimer();
    }
  }
  startForTimer = () => {
    this.data.timerStart = true;
    this.data.timerStartTime = this.data.prevTime;
  }
  stopTimer = () => {
    this.data.timerStart = false;
  }
  resetTimer = () => {
    this.data.timerStart = false;
    this.data.timerStartTime = null;
    this.data.timer = 0;
  }

  Canvas = () => {
    let correctTimerImg = timerImg;
    if (this.lang === 'en') {
      correctTimerImg = timerImgEn;
    }

    const [background] = useImage(backgroundImg);
    const [timer] = useImage(correctTimerImg);
    const [train] = useImage(trainImg);
    const [train2] = useImage(train2Img);


    const titleItems = generateArray(this.staticData.stepsCount);
    const trainItems = generateArray(this.staticData.trainCount);
    return (
      <React.Fragment>

        <Image image={background}/>
        {
          titleItems.map((el, i) => (
            <Text
              key={el} ref={this[`titleStep${i}Ref`]}
              text={''} {...lsnTitleTxtStyle}
              opacity={Number(this.data.step === i)}
            />
          ))
        }

        <Group x={600} y={180}>
          <Group key={'1'}>
            <Image image={timer}/>
            <Text
              ref={this.timerRef}
              width={145}
              height={70}
              x={17} y={15}
              text={'0'}
              align={'center'}
              verticalAlign={'middle'}
              {...lsnLabelTxtStyle}
              fontStyle={'bold'}
              fontSize={45}
            />
            <CanvasButton
              textRef={this.timerBtnRef}
              text={_t('train_task.Count_time_button')}
              onClick={() => this.timerController()}
              fontSize={15}
              strokeWidth={.2}
              btnCornerRadius={0}
              width={120}
              height={30}
              x={30} y={130}
            />
          </Group>
          <Group key={'2'} x={200}>
            <Card width={150} height={120}>
              <Text
                text={_t('train_task.Train_speed_text')}
                x={20} y={20}
                fontStyle={'bold'}
                {...lsnLabelTxtStyle}
              />
              {/*<Text*/}
              {/*    text={', км/ч'}*/}
              {/*    x={73} y={40}*/}
              {/*    {...lsnLabelTxtStyle}*/}
              {/*/>*/}
              <Text
                ref={this.speedRef}
                text={this.data.speed}
                x={20} y={60}
                {...lsnLabelTxtStyle}
                fontSize={35}

              />
            </Card>
            <CanvasButton
              text={_t('train_task.Start_button')}
              onClick={() => this.startForTrain()}
              fontSize={15}
              strokeWidth={.2}
              btnCornerRadius={0}
              width={150}
              height={30}
              y={130}
            />
          </Group>
        </Group>


        <Group ref={this.inputCardRef} x={60} y={180} opacity={0}>
          <Card width={450} height={100}>
            <Group x={40} y={30}>
              <Text
                text={_t('train_task.Train_length_text') + ' = '}
                {...lsnLabelTxtStyle}
                fontSize={30}
              />
              <CanvasInput
                id={'1'}
                x={230}
                width={80}
                height={40}
                fontSize={30}
                stage={this.stageRef?.current}
                onInput={(val) => {
                  this.onChangeInput(val);
                }}
                value={0}
              />
              <Text
                x={320}
                text={_t('train_task.Dimension1')}
                {...lsnLabelTxtStyle}
                fontSize={30}
              />
            </Group>
          </Card>
          <CanvasButton
            text={_t('train_task.Submit_button')}
            onClick={() => this.chekResult()}
            fontSize={20}
            strokeWidth={.2}
            btnCornerRadius={0}
            width={150}
            height={35}
            y={130} x={140}
          />
        </Group>

        <Group ref={this.trainRef} x={40} y={430}>
          {
            trainItems.map((el, i) => {
              const firstItem = !i;
              const correctImg = firstItem ? train : train2;
              return (
                <Image key={el} image={correctImg} x={-60 * i}/>
              )
            })
          }
        </Group>

        <CanvasResetBtn
          x={853}
          onClick={() => this.onClickReset()}
        />
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Train);

const styles = {
  mainContainer: {
    background: 'white'
  }
};
