import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Group, Image, Layer, Line, Rect, Text} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import Card from '../../../canvas/components/Card';
import CanvasInput from '../../../canvas/components/CanvasInput';
import backgroundImg from '../../../../images/crane/background.png';
import wheelImg from '../../../../images/crane/wheel.png';
import boxImg from '../../../../images/crane/box.png';
import boxesImg from '../../../../images/crane/boxes.png';
import boxesCrane6Img from '../../../../images/crane/boxesCrane6.png';
import boxesCrane7Img from '../../../../images/crane/boxesCrane7.png';
import craneImg from '../../../../images/crane/crane.png';
import crossImg from '../../../../images/crane/cross.png';
import engineImg from '../../../../images/crane/engine.png';
import hookImg from '../../../../images/crane/hook.png';
import infoImg from '../../../../images/crane/info.png';
import * as actions from "../../../../store/actions";
import anime from "animejs";

import {connect} from "react-redux";
import {getBoolByLsnCode, getCorrectKey} from "../utils";
import {mainColor as _mainColor} from "../../../../utils/styles";
import ScenarioManager from "../../../../utils/ScenarioManager";
import MovingArrow from "../../../canvas/components/MovingArrow";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import {showFailOrSuccessPopup} from "../../../../utils/common";
import CanvasButton from "../../../canvas/components/CanvasButton";

const animation = anime.timeline({autoplay: false});

const finishAnimationOptions = {
  targets: '.crane',
  easing: 'linear',
  translateX: 0,
  translateY: 0,
  duration: 1000,
  delay: 100,
  rotate: 90,
};


class Crane extends React.Component {
  constructor(props) {
    super(props);
    const {
      isCrane10,
      isCrane11,
      isCrane12,
    } = getBoolByLsnCode(this.props.code);

    this.stageRef = React.createRef();

    this.startBtn2Ref = React.createRef();
    this.inputContainerRef = React.createRef();
    this.infoCardRef = React.createRef();

    this.inputWeightContainerRef = React.createRef();
    this.inputWeightValueRef = React.createRef();
    this.inputWeightHideRef = React.createRef();
    this.inputHintRef = React.createRef();
    this.inputHintTextRef = React.createRef();

    this.boxRef = React.createRef();
    this.redBoxRef = React.createRef();
    this.boxesOffsetRef = React.createRef();

    this.bigWheelRef = React.createRef();
    this.topLeftWheelRef = React.createRef();
    this.topRightWheelRef = React.createRef();
    this.bigWheelRef = React.createRef();

    this.singleCableRef = React.createRef();
    this.leftDoubleCableRef = React.createRef();
    this.rightDoubleCableRef = React.createRef();

    this.timeTextRef = React.createRef();
    this.weightCargoTextRef = React.createRef();
    this.completedWorkTextRef = React.createRef();
    this.liftingHeightTextRef = React.createRef();
    this.forceGravityTextRef = React.createRef();
    this.loadOnTheEngineTextRef = React.createRef();
    this.timeLiftingUpTextRef = React.createRef();
    this.maximumEngineLoadTextRef = React.createRef();

    this.hookRef = React.createRef();
    this.engineOffsetRef = React.createRef();

    this.leftSlingRef = React.createRef();
    this.rightSlingRef = React.createRef();

    this.startBtnRef = React.createRef();
    this.startBtnContainerRef = React.createRef();
    this.fistTextRef = React.createRef();
    this.fistTextTextRef = React.createRef();
    this.helpRef = React.createRef();
    this.helpTextRef = React.createRef();
    this.toBigPowerRef = React.createRef();

    // vectors
    this.vectorRopePullingForceRef = React.createRef();
    this.vectorRopePullingForceTextRef = React.createRef();

    this.vectorForceGravityRef = React.createRef();
    this.vectorForceGravityTextRef = React.createRef();
    this.numberForceGravityRef = React.createRef();


    this.state = {
      holdup: 17,
      count: isCrane11 ? 0 : 10,
      textPoint: 100,
      // lsnCode: ''
    };
    this.initialData = {
      start: false,
      startTime: 0,
      prevTime: 0,
      enginePower67: isCrane10 || isCrane11 ? 1000 : 10000,
      enginePower: isCrane12 ? undefined : 100,
      // massOfCargo: 100,
      switchActive: false, // for switcher
      completedWorkQuestion: undefined,

      completedWork: 0,
      liftingHeight: 0,
      forceGravity: 490,
      maximumEngineLoad: 120,

      weightCargo: isCrane10 || isCrane11 || isCrane12 ? 100 : undefined,

      time: 10,

      CargoHorizontalV: 0,
      CargoVerticalV: 0,
      wheelVel: 0,
      leftWheelVel: 0,
      rightWheelVel: 0,
      engineOffsetX: 260,
      boxX: 240,
      boxY: 470,
      verevka: 360,
      numberLoadOnTheEngine: 0,
      numberTimeLiftingUp: 0,
      boxesOffsetRefX: 0,
      hookRefY: -55,
      redBoxRefX: 0,
      numberForceGravity: 0,
      count: 15,
      countik: 15,
      help: 0,
      bigPower: 0,
      opacityOfText: 0,
      hightCrane: 19.2,

      sendResult: false
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.code !== this.props.code) {
      this.setState((prev) => ({...prev, lsnCode: this.props.code}), () => this.onClickReset())
    }
  }

  _movingCallbacks = {};
  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  openModal = () => {(this.scenarioManager.config.length - 1 > this.scenarioManager.currentStep) && this.scenarioManager.success();};

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
  }

//таймер
  doIntervalChange = () => {
    const { isCrane11 } = getBoolByLsnCode(this.props.code);
    if (this.Interval) {
      clearInterval(this.Interval);
    }
    this.Interval = setInterval(() => {
      this.setState(prevState => ({
        count: isCrane11 ? prevState.count + 1 : prevState.count - 1
      }))

      if (((this.state.count === 0) && !isCrane11) || (isCrane11 && this.state.count === 10)) {
        clearInterval(this.Interval);
      }
    }, 1100)
  }

  intervalHoldup = () => {
    if (this.Intervall) {
      clearInterval(this.Intervall);
    }
    this.Intervall = setInterval(() => {
      this.setState(prevState => ({
        holdup: prevState.holdup - 1
      }))
      if (this.state.holdup === -1) {
        clearInterval(this.Intervall);
      }
    }, 1000)
  }


  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
    clearInterval(this.Interval);
    clearInterval(this.Intervall);
  };

  helpOnClick = () => {
    if (this.data.help === 1) {
      this.data.help = 0;
    } else {
      this.data.help = 1;
    }
  }

  //нажатие на кнопку старт
  startResetOnClick = (propData) => {
    const {
      isCrane10,
      isCrane11,
      isCrane12,
    } = getBoolByLsnCode(this.props.code);
    const data = this.data;
    const isCrane6OrSame = isCrane10 || isCrane12;

    if (!propData?.reset) {
      const boxComplete = data.boxX > 301 && data.boxY < 216;
      const dontCheckInputBeforeCompleteBox = ((isCrane10 || isCrane11) && !boxComplete)
      if (!dontCheckInputBeforeCompleteBox) {
        const inputVal = data[getCorrectKey(this.props.code)];
        if (inputVal === undefined && !propData?.reset) {
          return data.inputHintTextVisible = true;
        } else {
          data.inputHintTextVisible = false;
        }
      }

      if (isCrane11 || isCrane10) {
        return this.checkSuccess();
      }
    }

    this.doIntervalChange();
    this.intervalHoldup();
    if (this.data.start || propData?.reset) {
      clearInterval(this.Interval);
      clearInterval(this.Intervall);
      this.data.start = false;
      // this.setState({countik: 15});
      this.setState({count: (isCrane6OrSame) ? 11 : 0});
      this.setState({holdup: (isCrane6OrSame) ? 13 : 17});
      this.setState({textPoint: 100});
      this.data = cloneDeep(this.initialData);
      this.forceUpdate();
    } else {
      this.data.start = true;
      this.setState({textPoint: 100});
    }
  };


  getNodes = () => ({
    startBtn2Node: this.startBtn2Ref?.current,

    inputContainerNode: this.inputContainerRef?.current,
    infoCardNode: this.infoCardRef?.current,

    inputWeightContainerNode: this.inputWeightContainerRef?.current,
    inputWeightValueNode: this.inputWeightValueRef?.current,
    inputWeightHideNode: this.inputWeightHideRef?.current,
    inputHintNode: this.inputHintRef?.current,
    inputHintTextNode: this.inputHintTextRef?.current,

    boxNode: this.boxRef?.current,
    redBoxNode: this.redBoxRef?.current,
    boxesOffsetRefNode: this.boxesOffsetRef?.current,
    hookRefNode: this.hookRef?.current,
    topRightWheelNode: this.topRightWheelRef?.current,
    topLeftWheelNode: this.topLeftWheelRef?.current,
    bigWheelNode: this.bigWheelRef?.current,

    singleCableNode: this.singleCableRef?.current,
    leftDoubleCableNode: this.leftDoubleCableRef?.current,
    rightDoubleCableNode: this.rightDoubleCableRef?.current,

    timeTextNode: this.timeTextRef?.current,
    weightCargoTextNode: this.weightCargoTextRef?.current,
    completedWorkTextNode: this.completedWorkTextRef?.current,
    liftingHeightTextNode: this.liftingHeightTextRef?.current,
    forceGravityTextNode: this.forceGravityTextRef?.current,
    loadOnTheEngineTextNode: this.loadOnTheEngineTextRef?.current,
    timeLiftingUpTextNode: this.timeLiftingUpTextRef?.current,
    maximumEngineLoadTextNode: this.maximumEngineLoadTextRef?.current,

    engineOffsetNode: this.engineOffsetRef?.current,
    leftSlingNode: this.leftSlingRef?.current,
    rightSlingNode: this.rightSlingRef?.current,

    vectorRopePullingForceNode: this.vectorRopePullingForceRef?.current,
    vectorRopePullingForceTextNode: this.vectorRopePullingForceTextRef?.current,
    vectorForceGravityNode: this.vectorForceGravityRef?.current,
    vectorForceGravityTextNode: this.vectorForceGravityTextRef?.current,
    numberForceGravityNode: this.numberForceGravityRef?.current,
    fistTextNode: this.fistTextRef?.current,
    fistTextTextNode: this.fistTextTextRef?.current,
    helpNode: this.helpRef?.current,
    helpTextNode: this.helpTextRef?.current,
    toBigPowerNode: this.toBigPowerRef?.current,
    startBtnContainerNode: this.startBtnContainerRef?.current,
    startBtnNode: this.startBtnRef?.current,
  });

  checkSuccess = () => {
    const { isCrane10, isCrane11, isCrane12 } = getBoolByLsnCode(this.props.code);
    const val = this.data[getCorrectKey(this.props.code)] / 1000; // переводим в кило Дж или Вт
    if (val) {
      const success = (
        (isCrane10 && (val === 10)) ||
        (isCrane11 && (val === 10000)) ||
        (isCrane12 && (val === 1))
      );

      if (success) {
        this.openModal();
      }
      showFailOrSuccessPopup(this, success);
      this.data.sendResult = true;
    }
  }

  move = (time) => {
    const {
      isCrane10,
      isCrane11,
      isCrane12,
    } = getBoolByLsnCode(this.props.code);
    const isCrane6OrSame = isCrane10 || isCrane11 || isCrane12;
    const {count} = this.state;
    const {holdup} = this.state;
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    if(isCrane6OrSame && !isCrane12){
      this.data.enginePower = this.data.enginePower67;
    }

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    if(!isCrane6OrSame){data.count = (15 - Math.trunc(((data.prevTime - data.startTime)) / 1000));}
    if(isCrane6OrSame){data.count = (11 - Math.trunc(((data.prevTime - data.startTime)) / 1000));}


    if (!isCrane12) {
      if (data.enginePower >= 10000) {
        data.enginePower = 10000
      }
      if (data.enginePower <= 10) {
        data.enginePower = 10
      }
    }

    let weightCargo = data.weightCargo !== undefined ? data.weightCargo : (isCrane6OrSame ? 3000 : 50);
    const minWeight = 50;
    if (weightCargo <= minWeight) {
      weightCargo = minWeight;
    }

    //натяжка канатов в первую секунду
    if (
      data.start &&
      data.engineOffsetX < 322 &&
      (data.count === 11 && (isCrane6OrSame))
    ) {

      data.startTime = data.startTime || time;
      const timedelta = data.prevTime ? time - data.prevTime : 0;
      data.prevTime = time;
      const timedeltaSec = timedelta / 1000;

      if(isCrane6OrSame){data.hightCrane = 25.4;}
      let t = weightCargo * 9.8 * data.hightCrane;
      let hookVelocity = (weightCargo*25) / t;
      data.verevka = data.verevka - hookVelocity;
      data.hookRefY = data.hookRefY - hookVelocity;
      data.numberForceGravity = data.numberForceGravity + 8;

      if (data.switchActive === false && data.numberLoadOnTheEngine < 490) {
        data.numberLoadOnTheEngine = data.numberLoadOnTheEngine + 3;
      }
      if (data.switchActive === true && data.numberLoadOnTheEngine < 245) {
        data.numberLoadOnTheEngine = data.numberLoadOnTheEngine + 1;
      }
    }
    // движение груза после натяжки канатов
    if (
      data.start &&
      data.engineOffsetX < 322 &&
      data.count <= 14 &&
      data.count > 0
    ) {

      data.startTime = data.startTime || time;
      const timedelta = data.prevTime ? time - data.prevTime : 0;
      data.prevTime = time;
      const timedeltaSec = timedelta / 1000;

      if(isCrane6OrSame){data.hightCrane = 10;}

      let t = weightCargo * 9.8 * data.hightCrane / data.enginePower;
      let hookVelocity = 3.8 / t;
      data.CargoHorizontalV = 6.2 / t;
      if(isCrane6OrSame){
        data.CargoVerticalV = 25.4 / t;
      }
      data.numberForceGravity = 490;
      data.engineOffsetX = data.engineOffsetX + data.CargoHorizontalV * timedelta / 100;
      data.boxX = data.boxX + data.CargoHorizontalV * timedelta / 100;
      data.boxY = data.boxY - data.CargoVerticalV * timedelta / 100;
      data.verevka = data.verevka - hookVelocity;
      data.liftingHeight = Math.trunc(Math.abs(data.boxY - 469) / 2.51) / 10;
      data.completedWork = isCrane12 ? Math.trunc(data.liftingHeight * weightCargo * 9.8) : undefined;
      data.leftWheelVel = -data.boxY;
      data.rightWheelVel = data.boxY * 1;
      data.numberTimeLiftingUp = 14 - data.count;
      if (data.switchActive === false) {
        data.numberLoadOnTheEngine = Math.trunc(9.8 * 50);
      }
      if (data.switchActive === true) {
        data.numberLoadOnTheEngine = Math.trunc(9.8 * 50 / 2);
      }


    }

    if (isCrane12) {
      //движение катера с грузами
      if (this.state.count === 0) {

        data.boxesOffsetRefX = data.boxesOffsetRefX + 1;
        if (data.engineOffsetX < 322 && holdup === 0) {
          !this.props.failureModalVisible && this.props.changeFailureVisible(true);
        }
        if (data.engineOffsetX > 322) {
          data.redBoxRefX = data.redBoxRefX + 1;
        }
      }

      if (data.engineOffsetX > 321) {
        data.numberLoadOnTheEngine = 0;
      }
    }
    if ((data.boxX>301 && data.boxY<216 && !data.sendResult) && isCrane12) {
      this.checkSuccess();
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const {
      isCrane1,
      isCrane2,
      isCrane3,
      isCrane10,
      isCrane11,
      isCrane12,
    } = getBoolByLsnCode(this.props.code);
    const isCrane6OrSame = isCrane10 || isCrane11 || isCrane12;
    const {
      boxNode,
      redBoxNode,
      boxesOffsetRefNode,
      hookRefNode,
      topRightWheelNode,
      topLeftWheelNode,
      bigWheelNode,
      singleCableNode,
      leftDoubleCableNode,
      rightDoubleCableNode,
      timeTextNode,
      weightCargoTextNode,
      completedWorkTextNode,
      liftingHeightTextNode,
      forceGravityTextNode,
      loadOnTheEngineTextNode,
      timeLiftingUpTextNode,
      maximumEngineLoadTextNode,

      engineOffsetNode,
      leftSlingNode,
      rightSlingNode,

      startBtnNode,

      vectorRopePullingForceNode,
      vectorRopePullingForceTextNode,
      vectorForceGravityNode,
      vectorForceGravityTextNode,
      fistTextNode,
      fistTextTextNode,
      helpNode,
      helpTextNode,
      toBigPowerNode,
      inputHintTextNode,
      inputHintNode,
      inputWeightHideNode,
      inputWeightValueNode,
      inputWeightContainerNode,
      inputContainerNode,
      infoCardNode,
      startBtnContainerNode,
      startBtn2Node
    } = this.getNodes();

    let weightCargo = data.weightCargo !== undefined ? data.weightCargo : (isCrane6OrSame ? 3000 : 50);

    engineOffsetNode      && engineOffsetNode.x(this.data.engineOffsetX);
    boxNode               && boxNode.x(this.data.boxX);
    boxNode               && boxNode.y(this.data.boxY);
    topRightWheelNode     && topRightWheelNode.rotation(data.rightWheelVel);

    topLeftWheelNode      && topLeftWheelNode.rotation(this.data.leftWheelVel);
    bigWheelNode          && bigWheelNode.rotation(this.data.wheelVel);

    boxesOffsetRefNode    && boxesOffsetRefNode.x(this.data.boxesOffsetRefX);
    hookRefNode           && hookRefNode.y(this.data.hookRefY);
    redBoxNode            && redBoxNode.x(this.data.redBoxRefX);
    leftSlingNode         && leftSlingNode.points([-60, 27, 0, this.data.hookRefY + 55]);
    rightSlingNode        && rightSlingNode.points([0, this.data.hookRefY + 55, 60, 27]);
    singleCableNode       && singleCableNode.points([0, 0, 0, this.data.boxY - 120]);
    leftSlingNode         && leftSlingNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'grey');
    rightSlingNode        && rightSlingNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'grey');


    vectorRopePullingForceNode       && vectorRopePullingForceNode.points([0, this.data.hookRefY + 52, 0, -25 + (2.3 * this.data.hookRefY + 115)]);
    vectorForceGravityNode           && vectorForceGravityNode.points([0, this.data.hookRefY + 70, 0, weightCargo - (this.data.hookRefY + 60)]);
    vectorRopePullingForceNode       && vectorRopePullingForceNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    vectorForceGravityNode           && vectorForceGravityNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    vectorRopePullingForceNode       && vectorRopePullingForceNode.fill(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    vectorForceGravityNode           && vectorForceGravityNode.fill(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    vectorRopePullingForceTextNode   && vectorRopePullingForceTextNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    vectorForceGravityTextNode       && vectorForceGravityTextNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    vectorRopePullingForceTextNode   && vectorRopePullingForceTextNode.fill(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    vectorForceGravityTextNode       && vectorForceGravityTextNode.fill(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    leftDoubleCableNode              && leftDoubleCableNode.points([0, 0, 0, this.data.boxY - 200]);
    rightDoubleCableNode             && rightDoubleCableNode.points([0, 0, 0, this.data.boxY - 200]);

    startBtn2Node.visible((isCrane10 || isCrane11) && !data.start)

    if (isCrane6OrSame) {
      inputHintTextNode.visible(!!data.inputHintTextVisible);
      if (isCrane12) {
        inputWeightValueNode.visible(data.start);
        inputWeightContainerNode.visible(!data.start);
        inputHintNode.visible(!data.start);
      }
    }


    inputContainerNode.visible(((isCrane10 || isCrane11) && data.boxX>301 && data.boxY<216) || isCrane12);
    startBtnContainerNode.visible(((isCrane10 || isCrane11) && data.boxX>301 && data.boxY<216) || isCrane12);
    let initialHeight = isCrane10 ? 300 : 250;
    let heightWithoutInput = isCrane10 ? 200 : 150;
    if (isCrane10 || isCrane11) {
      infoCardNode.height((data.boxX > 301 && data.boxY < 216) ? initialHeight : heightWithoutInput);
    }

    if (isCrane12) {
      startBtnNode.text(data.start ? 'Сбросить' : 'Проверить');
    }

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    // set text from data
    completedWorkTextNode    && completedWorkTextNode.text(data.completedWork);
    liftingHeightTextNode    && liftingHeightTextNode.text(data.liftingHeight);
    forceGravityTextNode     && forceGravityTextNode.text(data.numberForceGravity);
    loadOnTheEngineTextNode  && loadOnTheEngineTextNode.text(data.numberLoadOnTheEngine);
    timeLiftingUpTextNode    && timeLiftingUpTextNode.text(data.numberTimeLiftingUp);

    if (timeTextNode) {
      timeTextNode.text(this.state.count)
    }

    if (isCrane6OrSame && this.state.textPoint <= 100) {
      fistTextNode &&  fistTextNode.opacity(this.state.textPoint / 100);
    }

    stageNode.draw();
  }

  Canvas = () => {
    const data = this.data;

    const {
      isCrane1,
      isCrane2,
      isCrane3,
      isCrane4,
      isCrane5,
      isCrane6,
      isCrane7,
      isCrane10, isCrane11, isCrane12,
    } = getBoolByLsnCode(this.props.code);
    const isCrane6OrSame = isCrane10 || isCrane11 || isCrane12;

    const [background] = useImage(backgroundImg);
    const [wheel] = useImage(wheelImg);
    const [box] = useImage(boxImg);
    const [boxes] = useImage(boxesImg);
    const [boxesCrane6] = useImage(boxesCrane6Img);
    const [boxesCrane7] = useImage(boxesCrane7Img);
    const [crane] = useImage(craneImg);
    const [cross] = useImage(crossImg);
    const [engine] = useImage(engineImg);
    const [hook] = useImage(hookImg);
    const [info] = useImage(infoImg);

    const mainColor = _mainColor;

    const titleSetting = {
      stroke: mainColor, fill: mainColor, strokeWidth: .2,
      fontSize: 13
    }
    const valueSettings = {
      stroke: mainColor, fill: mainColor, strokeWidth: .2,
      fontSize: 25
    }
    const secondTextColor = '#e2856b';
    return (
        <React.Fragment>
          <Group y={-140} onClick={() => this.data.opacityOfText = 1} onTap={() => this.data.opacityOfText = 1}>
            <Image image={background} width={1000} height={700}
            />

            <Group ref={this.boxesOffsetRef} x={this.data.engineOffsetX}>
              <Image image={boxesCrane6} x={160} y={320} />
            </Group>

            <Group x={70} y={170}>
              <Image image={crane} />
              <Group x={260} ref={this.engineOffsetRef}>
                <Group x={2} y={55}>
                  <Line points={[0, 0, 0, this.data.verevka]} stroke={'gray'} x={40} y={10} strokeWidth={1.5}
                        ref={this.singleCableRef} visible={true}/>
                  <Image x={0} image={engine}/>
                </Group>
                <Group x={0} y={70}>
                  <Image image={wheel} x={35} y={10} offsetX={10} offsetY={10} ref={this.topRightWheelRef}/>
                </Group>
              </Group>

              <Group x={240} y={470} ref={this.boxRef}>
                <Line points={[-60, 27, 0, this.data.hookRefY + 55]} stroke={'grey'} x={63} y={-25} strokeWidth={1.5}
                      ref={this.leftSlingRef}/>
                <Image image={hook} x={55} y={this.hookRefY} ref={this.hookRef}/>
                <Line points={[0, this.data.hookRefY + 55, 60, 27]} stroke={'gray'} x={63} y={-25} strokeWidth={1.5}
                      ref={this.rightSlingRef}/>

                <Image image={box} ref={this.redBoxRef}/>
              </Group>
            </Group>


            <Group x={820} y={isCrane11 ? 250 : 180}>
              <Card height={isCrane11 ? 250 : isCrane12 ? 330 : 290} rectRef={this.infoCardRef}>
                <Group x={20} y={20} visible={isCrane10 || isCrane12}>
                  <Text text={`Масса \nгруза, кг:`} {...titleSetting}/>
                  <Text y={27} text={`100`}{...valueSettings} />
                </Group>
                <Group x={20} y={20} visible={isCrane11}>
                  <Text text={`Вырабатываемая \nмощность, кВт:`} {...titleSetting}/>
                  <Text y={27} text={`1000`}{...valueSettings} />
                </Group>

                <Group x={20} y={80} visible={isCrane10 || isCrane12}>
                  <Text text={`Высота оного \nконтейнера, м:`} {...titleSetting}/>
                  <Text y={27} text={`2.5`}{...valueSettings} />
                </Group>

                <Group x={20} y={isCrane11 ? 85 : 200} visible={isCrane11 || isCrane12}>
                  <Text text={`Время \nподъема, с:`} {...titleSetting}/>
                  <Text y={27} text={`0`}{...valueSettings} ref={this.timeTextRef}/>
                </Group>

                <Group x={20} y={145} visible={isCrane10 || isCrane12}>
                  <Text text={`g, H/кг:`} {...titleSetting}/>
                  <Text y={17} text={`10`}{...valueSettings}/>
                </Group>


                <Group x={20} y={isCrane11 ? 160 : isCrane12 ? 260 : 210} ref={this.inputContainerRef}>
                  <MovingArrow
                    id={'hint1'}
                    ref={this.inputHintRef}
                    textRef={this.inputHintTextRef}
                    stageNode={this.stageRef?.current}
                    length={40}
                    arrowsCount={1}
                    x={-70} y={43}
                    textX={-90} textY={30}
                    fontSize={17}
                    textColor={'gold'}
                    fontStyle={'bold'}
                    text={'Введи значение'}
                    rotation={-90}
                    visible={isCrane6OrSame}
                    getMovingCallback={this.getMovingCallback}
                  />

                  <Text text={
                    `${
                      isCrane10 || isCrane11 ?
                        'Выполненная \nработа, кДж:'
                      : isCrane12 ?
                        'Мощность \nдвигателя, кВт:'  
                      :
                      'Максимальная\nмасса груза, кг:'
                    }`
                  } {...titleSetting}/>
                  <CanvasInput
                    y={28}
                    width={90}
                    height={30}
                    containerRef={this.inputWeightContainerRef}
                    stage={this.stageRef?.current}
                    textColor={valueSettings.fill}
                    inputHideRef={this.inputWeightHideRef}
                    onInput={(val) => {
                      let value = undefined;
                      if (val !== null) {
                        value = val*1000
                      }
                      data[getCorrectKey(this.props.code)] = value;
                    }}
                    value={(data[getCorrectKey(this.props.code)]/1000) || undefined}
                  />
                  <Text
                    ref={this.inputWeightValueRef}
                    y={28}
                    fontSize={30}
                    visible={isCrane12}
                    text={(data[getCorrectKey(this.props.code)]/1000)||''}
                    fill={valueSettings.fill}
                  />

                </Group>
              </Card>
            </Group>

            <Group
                x={820} y={540}
                onClick={() => this.startResetOnClick()}
                onTap={() => this.startResetOnClick()}
                ref={this.startBtnContainerRef}
            >
              <Rect width={150} height={45} fill={'#1b3080'} cornerRadius={5}/>
              <Text ref={this.startBtnRef} text={'Проверить'} stroke={'white'} fill={'white'} strokeWidth={1} fontSize={20}
                    align={'center'} verticalAlign={'middle'} width={150} height={45}/>
            </Group>

            <CanvasButton
              btnRef={this.startBtn2Ref}
              text={'Старт'}
              onClick={() => {
                this.data.start = true;
                this.doIntervalChange();
              }}
              fontSize={20}
              btnFill={'#1b3080'}
              strokeWidth={.2}
              btnCornerRadius={5}
              width={150}
              height={45}
              x={820} y={430}
            />

          </Group>

          {isCrane6OrSame ? <Group ref={this.fistTextRef} x={510} y={10}>
            {/*<Card height={85} width={570} >*/}
              <Text
                  text={
                    `${
                      isCrane10
                        ? 'Определи работу, которую \nсовершил кран по подъему груза. \nЧтобы начать, нажми “Старт”'
                      : isCrane11
                        ? 'Определи работу, которую \nсовершил кран по подъему груза, \nзная вырабатываемую мощность. \nЧтобы начать, нажми “Старт”'
                      : isCrane12
                        ? 'Определи минимальную мощность \nдвигателя крана, которая \nпозволит поднять груз заданной \nмассы за указанное время. \nПосле ввода ответа нажми \n“Проверить”'
                      : 
                          'Определи максимальный груз, \nкоторый может загрузить\nкран данной мощности \nдо отправления корабля'
                    }`
                  }
                  stroke={'#406C9C'}
                  fill={'#406C9C'}
                  lineHeight={1.3}
                  strokeWidth={.4}
                  fontSize={18}
                  x={12}
                  y={20}
                  ref={this.fistTextTextRef}
              />
            {/*</Card>*/}
          </Group> : null}


          <CanvasResetBtn x={30} onClick={() => this.startResetOnClick({reset: true})}/>
        </React.Fragment>
    )
  };

  render() {
    return (
        <div style={styles.mainContainer}>
          <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
            <Layer>
              <this.Canvas/>
            </Layer>
          </CanvasContainer>
        </div>
    )
  }
}


const mapStateToProps = (state) => ({
  failureModalVisible: state.commonReducer.failureModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Crane);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
