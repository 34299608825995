import {hasIntersection} from "../../../../utils/common";
import {element} from "prop-types";
import {getLsnNameByCode} from "../../pathandspeed/utils/spaceUtils";

export const getTitleByCode = ($this, i) => {
  const {
    lsnCrown1,
    lsnCrown2_0,
    lsnCrown2_1,
    lsnCrown2_2,
    lsnCrown3,
    lsnCrown4,
    lsnCrown5,
  } = getCrownLessonCodes($this);

  let title = '';
  if (lsnCrown1 || lsnCrown4 || lsnCrown2_0 || i === 0) {
    title = 'Проведи эксперимент и определи объем (V, см3)\nкороны царя Гиерона';

    if (lsnCrown1 || lsnCrown2_0) {
      title += ' (погрешность измерений - \nполовина цены деления шкалы)';
    }
  }
  if (lsnCrown2_1 || i === 1) {
    title = 'За счет чего можно снизить погрешность измерений?';
  }
  if (lsnCrown2_2 || i === 2) {
    title = 'У царя Гиерона было целых пять корон. Как с их' +
      ' \nиспользованием добиться самой низкой относительной ' +
      ' \nпогрешность измерений объема однойкороны?';
  }
  if (lsnCrown3) {
    title = 'Определить плотность материала короны, используя \nдоступные материалы';
  }
  if (lsnCrown5) {
    title = 'Определить плотность материала короны, используя \nдоступные инструменты';
  }

  return title
}

export const getCrownLessonCodes = ($this) => {
  const lsnCode = $this.props.code, step = $this.state.step;
  return ({
    lsnCrown1: 'crown1' === lsnCode,
    lsnCrown2_0: ('crown2' === lsnCode) && (step === 0),
    lsnCrown2_1: ('crown2' === lsnCode) && (step === 1),
    lsnCrown2_2: ('crown2' === lsnCode) && (step === 2),
    lsnCrown3: ['crown3', 'course7_crown1', 'crown5'].includes(lsnCode),
    course7_crown: 'course7_crown1' === lsnCode,
    lsnCrown4: 'crown4' === lsnCode,
    lsnCrown5: 'crown5' === lsnCode,
  })
}

export const getCorrectStateDataByCode = ($this) => {
  const {
    lsnCrown1,
    lsnCrown2_0,
    lsnCrown2_1,
    lsnCrown2_2,
    lsnCrown3,
    lsnCrown4,
    lsnCrown5,
  } = getCrownLessonCodes($this);

  let staticData = {}, initialData = {};

  if (lsnCrown1 || lsnCrown4 || lsnCrown2_0 || lsnCrown2_1 || lsnCrown2_2)
  {
    staticData = {
      correctVal: 1000,
      correctCalcErr: 250,

      minWaterHeight: 165,

      waterHeightItem: 15.8,

      crownsCount: 1
    };
    initialData = {
      waterHeight: 165,
    }
    if (lsnCrown2_0 || lsnCrown2_1 || lsnCrown2_2) {
      staticData = {
        ...staticData,
        minWaterHeight: 155,
      };
      initialData = {
        waterHeight: 155,
      }
    }

    if (lsnCrown2_0) {
      staticData = {
        ...staticData,
        correctRelativeCalcErr: 25,
      };
    }
    if (lsnCrown2_2) {
      staticData = {
        ...staticData,
        crownsCount: 5,
        correctRelativeCalcErr: 5,
      };
    }
  }
  else if (lsnCrown3 && !lsnCrown5)
  {
    staticData = {
      minCorrectVal: 12250,
      maxCorrectVal: 12500,

      minWaterHeight: 135,

      minDynamometrVal: 0,
      maxDynamometrVal: 25,
      maxDynamometrInWaterVal: 23,

      waterHeightItem: 18,

      crownsCount: 1
    };
    initialData = {
      waterHeight: 135,
    }
  }
  else if (lsnCrown5)
  {
    staticData = {
      minCorrectVal: 11200,
      maxCorrectVal: 11200,

      minWaterHeight: 135,

      waterHeightItem: 18,

      crownsCount: 1
    };
    initialData = {
      waterHeight: 135,
      waterHeightCoeff: .5,
    }
  }
  return { staticData, initialData };
}

const updateCrownsInWaterArr = ($this, waterIntersection, crownId) => {
  const crownsInWater = $this.data.crownsInWater;
  if (waterIntersection) {

    !crownsInWater.includes(crownId) &&
    $this.data.crownsInWater.push(crownId);

  } else {
    $this.data.crownsInWater = crownsInWater.filter((crownInWaterId) => crownInWaterId !== crownId);
  }
}

// -------- SET POSITIONS --------
export const setInitialCrownPos = ($this, crownId= 1) => {
  const nodes = $this.getNodes();
  const scale = nodes.stageNode.getAbsoluteScale();
  const defaultCrownPos = nodes.defaultCrownPlaceNode.getAbsolutePosition();
  const node = nodes[`crown${crownId}Node`];
  node && node.setAbsolutePosition({
    x: defaultCrownPos.x * scale.x,
    y: defaultCrownPos.y * scale.y,
  })
}
export const setWaterCrownPos = ($this, crownId= 1) => {
  const nodes = $this.getNodes();
  const scale = nodes.stageNode.getAbsoluteScale();
  const waterCrownPos = nodes.waterCrownPlaceNode.getAbsolutePosition();
  const crownsInWaterLen = $this.data.crownsInWater.length-1;


  nodes[`crown${crownId}Node`].setAbsolutePosition({
    x: waterCrownPos.x * scale.x,
    y: (waterCrownPos.y - (crownsInWaterLen * 30)) * scale.y,
  });
}
export const setDynamometrPos = ($this) => {
  const { stageNode, dynamometrNode, defaultDynamometrPlaceNode } = $this.getNodes();
  const scale = stageNode.getAbsoluteScale();
  const defaultPos = defaultDynamometrPlaceNode.getAbsolutePosition();
  dynamometrNode.setAbsolutePosition({
    x: defaultPos.x * scale.x,
    y: defaultPos.y * scale.y,
  });
}

const checkCrownIntersection = ($this, crownId) => {
  const nodes = $this.getNodes();
  const { topLeftActBoxNode, topRightActBoxNode, bottomLeftActBoxNode, bottomRightActBoxNode } = $this.getNodes();

  const crownNode = nodes[`crown${crownId}Node`]

  const topLeft = hasIntersection(crownNode, topLeftActBoxNode, $this);
  const topRight = hasIntersection(crownNode, topRightActBoxNode, $this);
  const bottomLeft = hasIntersection(crownNode, bottomLeftActBoxNode, $this);
  const bottomRight = hasIntersection(crownNode, bottomRightActBoxNode, $this);
  return ({
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,

    topRightAndLeft: topLeft && topRight,
    leftTopAndBottom: topLeft && bottomLeft,
    rightTopAndBottom: topRight && bottomRight,
  })
}

// --------- CROWN ------------

export const dragBoundFunc = ($this, pos, crownId=1) => {
  const stageNode = $this.stageRef?.current;
  const stageWidth = stageNode.width();
  const stageHeight = stageNode.height();
  const {
    lsnCrown1,
    lsnCrown2_0,
    lsnCrown2_1,
    lsnCrown2_2,
    lsnCrown3,
    lsnCrown4,
    lsnCrown5,
  } = getCrownLessonCodes($this);

  const {
    topLeft, topRight, bottomLeft, bottomRight,
    topRightAndLeft, leftTopAndBottom, rightTopAndBottom
  } = checkCrownIntersection($this, crownId);

  let leftBorderX = stageWidth * .14;
  let rightBorderX = stageWidth * .55;
  let topBorderY = 0;
  let bottomBorderY = stageHeight * .75;

  // ------- LESSON 1 --------
  if (lsnCrown1 || lsnCrown4 || lsnCrown2_0 || lsnCrown2_2 || lsnCrown5) {
    if (bottomRight) {
      leftBorderX = stageWidth * .455;
    }
    if (topRightAndLeft) {
      bottomBorderY = stageHeight * .28;
    }
    if (leftTopAndBottom || bottomLeft) {
      rightBorderX = stageWidth * .315;
    }
  }

  // ------- LESSON 3 --------
  if (lsnCrown3 && !lsnCrown5) {
    if (bottomRight) {
      leftBorderX = stageWidth * .37;
    }
    if (topLeft) {
      leftBorderX = stageWidth * .17;
    }
    if (topRightAndLeft) {
      bottomBorderY = stageHeight * .48;
    }
    if (bottomLeft || bottomRight) {
      bottomBorderY = stageHeight * .78;
    }
    if (leftTopAndBottom || bottomLeft) {
      rightBorderX = stageWidth * .23;
      leftBorderX = stageWidth * .17;
    }
  }

  const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
  const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
  return {y: newY, x: newX};
};

export const onDragEnd = ($this, e, crownId) => {
  const nodes = $this.getNodes();

  const waterIntersection = hasIntersection(nodes[`crown${crownId}Node`], nodes.waterNode, $this);

  if (waterIntersection) {
    setWaterCrownPos($this, crownId);
  } else {
    setInitialCrownPos($this, crownId);
  }
}
export const onDragMove = ($this, e, crownId) => {
  const nodes = $this.getNodes();
  const crownsInWater = $this.data.crownsInWater;
  const waterIntersection = hasIntersection(nodes[`crown${crownId}Node`], nodes.waterNode, $this);

  updateCrownsInWaterArr($this, waterIntersection, crownId);

  $this.data.crownInWater = waterIntersection;
}
export const onDragStart = ($this, e) => {
  $this.data.crownWasDragged = true;
}


// --------- DYNOMOMETR ------------

export const dynamometrDragBoundFunc = ($this, pos, crownId=1) => {
  const data = $this.data;
  const stageNode = $this.stageRef?.current;
  const stageWidth = stageNode.width();
  const stageHeight = stageNode.height();
  const {
    topLeft, topRight, bottomLeft, bottomRight,
    topRightAndLeft, leftTopAndBottom, rightTopAndBottom
  } = checkCrownIntersection($this, crownId);

  let leftBorderX = stageWidth * .14;
  let rightBorderX = stageWidth * .65;
  let topBorderY = 0;
  let bottomBorderY = stageHeight * .59;

  if (data.hookActive) {
    if (bottomRight) {
      leftBorderX = stageWidth * .378;
    }
    if (topLeft) {
      leftBorderX = stageWidth * .188;
    }
    if (topRightAndLeft) {
      bottomBorderY = stageHeight * .28;
    }
    if (leftTopAndBottom || bottomLeft) {
      rightBorderX = stageWidth * .248;
      leftBorderX = stageWidth * .187;
    }
  }

  const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
  const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
  return {y: newY, x: newX};
}

export const dynamometrDragMove = ($this, e) => {
  const {
    stageNode,
    crown1Node,
    hookNode,
    waterNode,
    hookActionBoxNode,
    crownActionBoxNode,
  } = $this.getNodes();

  const waterIntersection = hasIntersection(waterNode, crown1Node, $this);
  const hookIntersection = hasIntersection(hookActionBoxNode, crownActionBoxNode, $this);

  if (hookIntersection) {
    const hookBoxWidth = hookActionBoxNode.getWidth();
    const hookBoxPos = hookActionBoxNode.getAbsolutePosition();
    const crownWidth = crown1Node.getWidth();

    crown1Node.setAbsolutePosition({
      x: hookBoxPos.x - (crownWidth/2)+(hookBoxWidth/2),
      y: hookBoxPos.y,
    })
  }

  $this.data.hookActive = hookIntersection;
  $this.data.crownInWater = waterIntersection;
  updateCrownsInWaterArr($this, waterIntersection, 1);
}
export const dynamometrDragStart = ($this, e) => {}
export const dynamometrDragEnd = ($this, e) => {
  setDynamometrPos($this);
  if ($this.data.hookActive) {
    setInitialCrownPos($this);
    $this.data.hookActive = false;
  }
}