import React from "react";
import { Layer, Rect, Group } from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import cloneDeep from "lodash.clonedeep";
import Victor from 'victor';
import Track from "../components/Track";

class Rally extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.rectRef = React.createRef();
    this.trackRef = React.createRef();
    this.state = {
      go: false,
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      pos: Victor(0, 0),
      speed: Victor(0,0),
      track: [
        {
          length: 100,
          slope: 0,
        },
        {
          length: 100,
          slope: 10,
        },
      ],
      trackOffset: Victor(0, 0),
    };
    this.data = cloneDeep(this.initialData);
    console.log('debug on new data', this.data)
  };

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  };

  enslow (value, delta, timedeltaSec) {
    let absValue = Math.abs(value);
    let signValue = Math.sign(value);

    absValue -= delta * timedeltaSec;
    if (absValue < 0) absValue = 0;

    return signValue * absValue
  }

  move = (time) => {
    const {go} = this.state;
    let data = this.data;
    let trackNode = this.trackRef?.current;

    const slope = trackNode.getSlope(this.data.pos.x);
    const perp = trackNode.getPerp(this.data.pos.x);
    let trackVector = trackNode.findCorrespondingVictor(this.data.pos.x).clone();


    this.requestId = window.requestAnimationFrame(this.move);

    let stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    // console.log('debug on 1');

    // let speed = data.speed.length();

    if (go) {
      // console.log('debug on 2');
      // speed += 50 * timedeltaSec;
      data.speed.add(trackVector.norm().multiply(Victor(10 * timedeltaSec, 10*timedeltaSec)))
    }

    // speed += Math.sin(slope/180 * Math.PI) * 200 * timedeltaSec;
    if (slope < 0) {
      // speed -= 40 * timedeltaSec
    }

    // console.log('debug on acceleration', speed, slope);
    // console.log('debug on speed',);

    // data.speed = Victor(this.enslow(data.speed.x, 10, timedeltaSec), this.enslow(data.speed.y, 10, timedeltaSec));
    // data.speed = Victor(speed * Math.cos(slope/180 * Math.PI) , speed * Math.sin(slope/180 * Math.PI));

    // console.log('debug on speed', speed, data.speed);
    // data.speed.rotateToDeg(slope);
    data.pos = data.pos
        .add(Victor(data.speed.x * timedeltaSec, data.speed.y * timedeltaSec));

    this.updateStage()
  };

  updateStage() {
    let stageNode = this.stageRef?.current;
    let rectNode = this.rectRef?.current;
    let trackNode = this.trackRef?.current;

    const slope = trackNode.getSlope(this.data.pos.x);
    if (!stageNode || !rectNode || slope === undefined) return;
    // console.log('debug on speed', this.data.speed);

    rectNode.x(this.data.pos.x);
    rectNode.y(this.data.pos.y);
    rectNode.rotation(slope);

    stageNode.draw()

  };

  hasIntersection(r1, r2) {
    return !(
          r2.x() > r1.x() + r1.width() ||
          r2.x() + r2.width() < r1.x() ||
          r2.y() > r1.y() + r1.height() ||
          r2.y() + r2.height() < r1.y()
        );
  }

  Scene = () => {
    const {pos} = this.data;
    return (
      <React.Fragment>
        <Layer preventDefault={false}>
          <Group x={200} y={400}>
            <Rect ref={this.rectRef} id={'rect1'} x={pos.x} y={pos.y} width={10} height={10} fill={'black'}/>
            <Track ref={this.trackRef} x={0} y={0} config={this.data.track}/>
          </Group>
        </Layer>
      </React.Fragment>
    )
  };

  render() {
     return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.Scene/>
        </CanvasContainer>
        <button
          style={{position: 'absolute', right: 150, bottom: 50, zIndex: 10}}
          onTouchStart={() => this.setState({go: true})}
          onTouchEnd={() => this.setState({go: false})}
          onMouseDown={() => this.setState({go: true})}
          onMouseUp={() => this.setState({go: false})}
        >
          Go
        </button>
      </div>
     )
  }
}

export default Rally;

const styles = {
  mainContainer: {
    background: '#457b9d',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }
}
