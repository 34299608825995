import { Image } from "react-konva";
import React from "react";

export const CanvasCannonball = ({ballImage}) => {
  const width = 15;
  const height = 15;

  return(
    <Image
      image={ballImage}
      width={width}
      height={height}
      offsetX={width/2}
      offsetY={height/2}
      rotation={90}
    />
  )
};
