import useImage from "use-image";
import asteroidImg from "../../../../images/space/spaceship_asteroid_1/asteroid.png";
import React, {useState} from "react";
import pointItemImg from "../../../../images/space/spaceship_asteroid_1/pointItem.png";
import {Group, Image, Rect} from "react-konva";




const Asteroid = (props) => {
  const [asteroid] = useImage(asteroidImg);
  return (
    <Group {...props}>
      <Image image={asteroid} scale={{x: props.scale||.5, y: props.scale||.5}}/>
      {props.children}
    </Group>
  )
}

export default Asteroid;

