

export const getScenario = (code) => {
  if (code === 'peryshkin13') {
    return scenarioDam;
  } else {
    return scenarioDam2;
  }
}


export const scenarioDam = [
  {
    key: 'lsn1 step1',
    patchData: {
      text: { value: 'Запусти плотину'},

      cardInfoVisible: true,

      height: 50
    },
    next_success: 'lsn1 step2',
  },
  {
    key: 'lsn1 step2',
    patchData: {
      text: { value: 'Останови плотину через 10-20 секунд'},

      height: 50,

      volumeStep: 20,

      cardInfoVisible: true,
    },
    next_success: 'lsn1 step3',
  },
  {
    key: 'lsn1 step3',
    patchData: {
      text: { value: 'Рассчитай мощность потока воды, прошедшего через водовод электростанции'},

      height: 50,

      inputSuccessBegin: 9500,
      inputSuccessEnd: 10500,

      cardInfoVisible: true,
      cardInputVisible: true,
      cardPowerVisible: true,

      btnsHidden: true,
    },
    next_success: 'success',
  },

  {
    key: 'success',
    patchData: {
      text: { value: ''},

      successVisible: true,

      btnsHidden: true,
    },
    next_success: 'success',
  },
]


export const scenarioDam2 = [
  {
    key: 'lsn2 step1',
    patchData: {
      text: { value: 'Запусти плотину'},

      cardInfoVisible: true,

      height: 100
    },
    next_success: 'lsn2 step2',
  },
  {
    key: 'lsn2 step2',
    patchData: {
      text: { value: 'Останови плотину через 10-20 секунд'},

      height: 100,

      volumeStep: 10,

      cardInfoVisible: true,
    },
    next_success: 'lsn2 step3',
  },
  {
    key: 'lsn2 step3',
    patchData: {
      text: { value: 'Рассчитай мощность потока воды, прошедшего через водовод электростанции'},

      height: 100,

      inputSuccessBegin: 9500,
      inputSuccessEnd: 10500,

      cardInfoVisible: true,
      cardInputVisible: true,
      cardPowerVisible: true,

      btnsHidden: true,
    },
    next_success: 'lsn2 step4',
  },{
    key: 'lsn2 step4',
    patchData: {
      text: { value: 'Рассчитай КПД плотины. Обрати внимание на мощность \nвырабатываемой энергии'},

      height: 100,

      inputSuccessBegin: 13,
      inputSuccessEnd: 17,

      cardInfo2Visible: true,
      cardInputVisible: true,
      cardEfficiencyVisible: true,

      stationPowerVisible: true,
      btnsHidden: true,
    },
    next_success: 'success',
  },

  {
    key: 'success',
    patchData: {
      text: { value: ''},

      successVisible: true,

      btnsHidden: true,
    },
    next_success: 'success',
  },
]