import React from "react";
import {Image} from "react-konva";
import useImage from "use-image";
import curvedArrowImg from '../../../images/curvedArrow.png';


const CurvedArrow = (props) => {
  const [curvedArrow] = useImage(curvedArrowImg);
  return (
    <Image image={curvedArrow} {...props}/>
  )
}

export default CurvedArrow;