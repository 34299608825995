import TaskContainerWithCanvasSize from "./TaskContainerWithCanvasSize";
import React, {useEffect} from "react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";


const VideoContainer = (props) => {

  useEffect(() => {
    (async function () {
      await props.addLessonResult(props.lessonCode, false, {}, true);

      setTimeout(() => {
        props.addLessonResult(props.lessonCode, true, {}, false);
      }, 1000)
    })()
  }, [])


  return (
    <TaskContainerWithCanvasSize
      {...props.taskContainerProps}
      taskContent={
        <iframe width={props.width} height={550} src={props.video} frameBorder="0" allowFullScreen />
      }
    />
  )
}



const mapStateToProps = (state, {match}) => ({});
const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoContainer);
