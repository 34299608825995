import React, {useEffect, useState} from "react";
import IFrame from "../../mainPage/containers/IFrame";
import {width} from "../../canvas/containers/CanvasContainer";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import {getRandomInt, userRoles} from "../../../utils/common";
import TeacherCard from "../components/TeacherCard";
import TextProblemTaskContent from "../components/TextProblemTaskContent";
import TaskContainerWithCanvasSize from "../components/TaskContainerWithCanvasSize";
import {
  ymStudentEnteredToLsnFromHomeworkGoal, ymStudentRegistrationFromLinkGoal,
  ymTeacherEnteredToLsnGoal,
  ymTeacherEnteredToTeacherCardGoal
} from "../../../metriks/ym_metriks";
import ConstructContainer from "../components/ConstructContainer";
import VideoContainer from "../components/VideoContainer";
import PresentationContainer from "../components/PresentationContainer";
import {useQuery} from "../../../utils/custom-hooks/useQuery";
import RecommendationOfCourses from "../components/RecommendationOfCourses";
import '../styles/taskScreen.css';
import SchoolTaskContainer from "../components/ShoolTasksContainer";
import { isEng } from "../../../utils/lang/common";



const SimpleTaskScreen = (props) => {
  const {match, homeworks, user, fetchLesson, setLessonVariant, hideFullscreenBtn} = props;
  const {code, lang, id, homeworkId} = match.params;

  const [showDescription, setShowDescription] = useState(false);
  const [lessonData, setLessonData] = useState({});

  useEffect(() => {
    // Delete DB for construct task
    indexedDB.deleteDatabase('construct');

    // Добавляем id homework из параметров роутера
    if (homeworkId) {
      props.setSelectedHomeworkId(Number(homeworkId));
    }

    (async function(){
      let res = await fetchLesson(`${code}${id}`);
      if (res?.status) {
        const lesson = res.lesson;
        setRandomVariant(lesson);
        setLessonData(lesson);
      }
    })()

    return () => {
      indexedDB.deleteDatabase('construct');
    }
  }, []);

  useEffect(() => {
    if (user && Object.keys(lessonData).length) {
      const isTeacher = user?.role === userRoles.teacher;
      if (!isTeacher) {
        if (homeworkId) {
          (async function () {
            const resp = await props.fetchHomework(isTeacher, homeworkId);
            if (resp?.status) {
              const hw = resp.homework
              ymStudentEnteredToLsnFromHomeworkGoal(hw.code);
            }
          })()
        }
      } else {
        if (teacherCard) {
          ymTeacherEnteredToTeacherCardGoal(lessonData.code, lessonData.chapter, lessonData.group, lessonData.grade);
        } else {
          const lsnTypes = lessonData.tags.map(el => el.title).join(', ');
          ymTeacherEnteredToLsnGoal(lsnTypes, lessonData.code, lessonData.chapter, lessonData.group, lessonData.grade);
        }
      }
    }
  }, [user, lessonData])

  const setRandomVariant = (lesson) => {
    let variant = getRandomInt(lesson.variants_count)+1;
    setLessonVariant(variant)
  }

  // Кол-во вариантов для текстовой задачи
  const variantsCount = lessonData.variants_count;

  // Доп. параметры
  const isTeacher = user?.role === userRoles.teacher;

  // Тип задания
  const video = isEng() ? lessonData?.video_en : lessonData?.video;
  const presentation = lessonData?.presentation;
  const schoolTaskContainer = lessonData?.school_task_container;
  const textProblem = lessonData?.text_problems_container;
  const teacherCard = lessonData?.teacher_card;
  const constructCode = lessonData?.construct_code;
  const phaserCode = lessonData?.phaser_code;

  const withback = useQuery().get('withback');
  const noResult = useQuery().get('noResult');

  const __DEV__ = window.location.hostname.includes('localhost');

  const TaskContentSwitcher = () => {

    const taskContainerProps = { match, hideFullscreenBtn, styles, lessonData, setLessonVariant, withback, externalFullscreen: props.fullscreen }
    const TextProblemTaskProps = {
      styles, isTeacher, lessonData, variantsCount, setLessonVariant, activeLessonVariant: props.activeLessonVariant
    }

    switch (true) {

      /** ЗАДАЧА PHASER */
      case Boolean(phaserCode):
        return (
        <TaskContainerWithCanvasSize
          {...taskContainerProps}
          isPhaser={true}
          taskContent={
            __DEV__ ?
              <iframe
                src={
                  `http://localhost:88/${phaserCode}/?code=${lessonData.code}&lang=${lang || 'ru'}${noResult ? '&noResult=1' : ''}`
                }
                width={'963px'}
                height={'544'}
              />
              :
              <iframe
                src={
                  `https://eshaala.inventic.tech/ph-lessons/${phaserCode}/?code=${lessonData.code}&lang=${lang || 'ru'}${noResult ? '&noResult=1' : ''}`
                }
                width={'963px'}
                height={'544'}
              />
          }
        />)


      /** ЗАДАЧА CONSTRUCT */
      case Boolean(constructCode):
        return (
          <ConstructContainer lessonData={lessonData} constructCode={constructCode}/>
        )

      /** ЗАДАЧА ШКОЛЬНАЯ ЗАДАЧА */
      case Boolean(schoolTaskContainer):
        return (
          <SchoolTaskContainer lessonData={lessonData}/>
        )

      /** ТЕКСТОВАЯ ЗАДАЧА */
      case Boolean(textProblem):
        return <TextProblemTaskContent {...TextProblemTaskProps}/>;


      /** КАРТОЧКА УЧИТЕЛЯ */
      case Boolean(teacherCard):
      return <TeacherCard cardId={teacherCard} lessonData={lessonData} setLessonVariant={setLessonVariant}/>;


      /** ВИДЕО */
      case Boolean(video):
        return (
          <VideoContainer lessonCode={lessonData.code} width={width} video={video} taskContainerProps={taskContainerProps}/>
        );


      /** ПРЕЗЕНТАЦИЯ */
      case Boolean(presentation):
        return (
          <PresentationContainer lessonCode={lessonData.code} width={width} presentation={presentation} taskContainerProps={taskContainerProps}/>
        );


      /** ИНТЕРАКТИВНАЯ ЗАДАЧА */
      default:
        return (
          <TaskContainerWithCanvasSize
            {...taskContainerProps}
            taskContent={
              <IFrame match={match} lessonData={lessonData}/>
            }
          />
        );
    }
  }

  return (
    <div id={'task-container'}>
      <TaskContentSwitcher />

      {
        withback && !isTeacher ? (
          <RecommendationOfCourses />
        ) : null
      }
    </div>
  )
};

const mapStateToProps = (state, {match}) => {
  return {
    VKShareModalVisible: state.commonReducer.VKShareModalVisible,
    user: state.lessonsReducer.user,
    activeLessonVariant: state.lessonsReducer.activeLessonVariant,
    selectedHomeworkId: state.lessonsReducer.selectedHomeworkId,
    homeworks: state.lessonsReducer.homeworks,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeVKShareVisible: (visible) => dispatch(actions.changeVKShareVisible(visible)),
    fetchLesson: (lsnCode) => dispatch(actions.fetchLesson(lsnCode)),
    setLessonVariant: (variant) => dispatch(actions.setLessonVariant(variant)),
    setSelectedHomeworkId: (hwId) => dispatch(actions.setSelectedHomeworkId(hwId)),
    fetchHomework: (isTeacher, hwId) => dispatch(actions.fetchHomework(isTeacher, hwId)),

  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SimpleTaskScreen);



let styles = {
  goToLayout2Container: {bottom: '-60px', width: '100%', position: 'absolute', display: 'flex', justifyContent: 'space-between'},
  goToLayout2: {
    margin: 'auto', padding: '5px',
    zIndex: 201, color: 'white', fontWeight: 500, fontSize: '20px', backgroundColor: '#4C6F9A', cursor: 'pointer', width: 'auto',
    boxShadow: '0px 0px 12px 1px rgba(76,111,154,0.50)',
  },
  fullscreenBtn: {position: 'absolute', zIndex: 100, bottom: 10, right: 10, paddingHorizontal: 10, fontSize: '24px'}
}
