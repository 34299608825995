import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Line, Arrow} from "react-konva";
import Victor from 'victor';
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {GIF} from "../../../canvas/components/GIF";
import {degToRad, lessonStatus, radToDeg, sendSuccessForScenario} from '../../../../utils/common';
import * as actions from '../../../../store/actions';
import submarine1Img from '../../../../images/submarine/boat1.png';
import submarine2Img from '../../../../images/submarine/boat2.png';
import indicatorImg from '../../../../images/submarine/data.png';
import boundariesImg from '../../../../images/submarine/boundaries.png';
import againButtonImg from '../../../../images/submarine/againButton3.png';
import FarchAssetImg from '../../../../images/submarine/FarchAsset.png';
import FtyazhAssetImg from '../../../../images/submarine/FtyazhAsset.png';
import checkButtonImg from '../../../../images/submarine/checkButton.png';
import {connect} from "react-redux";
import CanvasSubmarineScale from "../components/CanvasSubmarineScale";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import intersection from "../../balloon/data/figures/utils/intersection";
import Rectangle from "../../balloon/data/figures/Rectangle";
import Card from "../../../canvas/components/Card";
import RadioSwitch from "../../../canvas/components/RadioSwitch";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {mainColor as _mainColor} from "../../../../utils/styles";
import anime from "animejs";
import ScenarioManager from "../../../../utils/ScenarioManager";
import CanvasButton from "../../../canvas/components/CanvasButton";

const animation = anime.timeline({autoplay: false});

const finishAnimationOptions = {
  targets: '.castle',
  easing: 'linear',
  translateX: 0,
  translateY: 0,
  duration: 1000,
  delay: 100,
  rotate: 90,
};



class Submarine extends React.Component {
  constructor(props) {
    super(props);
    const {code} = this.props;
    const isSubmarine6 = code === 'submarine6';

    // --------- REFS ---------
    this.stageRef = React.createRef();
    this.depthRef = React.createRef();
    this.pressureRef = React.createRef();
    this.waterLevelRef = React.createRef();
    this.realDepthTextRef = React.createRef();
    this.realPressureTextRef = React.createRef();
    this.submarineRef = React.createRef();
    this.submarineWaterRef = React.createRef();
    this.backgroundRef = React.createRef();
    this.horizonRef = React.createRef();
    this.indicatorText1Ref = React.createRef();
    this.indicatorText2Ref = React.createRef();
    this.indicatorText3Ref = React.createRef();
    this.pressureRectRef = React.createRef();
    this.pressureTextRef = React.createRef();
    this.barrier1Ref = React.createRef();
    this.barrier2Ref = React.createRef();
    this.barrier3Ref = React.createRef();
    this.submarineRectRef = React.createRef();
    this.submarineRect2Ref = React.createRef();
    this.volumeTextRef = React.createRef();

    this.submarineInsideTanks = React.createRef();
    this.submarine2Ref = React.createRef();
    this.submarine1Ref = React.createRef();

    this.forceOfArchimedesArrowRef = React.createRef();
    this.forceOfArchimedesTextRef = React.createRef();
    this.forceOfGravityArrowRef = React.createRef();
    this.forceOfGravityTextRef = React.createRef();
    this.firstQuestionTextRef = React.createRef();
    this.imageSubmarine6ScaleRef = React.createRef();

    this.firstQuestionTextWrapperRef = React.createRef();
    this.firstQuestionTextWrapperRectRef = React.createRef();

    this.staticData = {};

    this.state = {
      textPoint: 0,
      textImagePoint: 0,
    };

    this.initialData = {
      prevTime: null,
      startTime: null,

      depth: 0,
      posX: 150,
      horizontalSpeed: 0,
      verticalSpeed: 0,
      waterVolume: isSubmarine6? 5 : 0,
      textColor: '#447b9c',
      moveRight: false,
      moveLeft: false,
      moveUp: false,
      moveDown: false,

      submarineDies: false,
      exploreButtonPoint: 0,
      exploreCheckButton: 0,
      imageTextSubmarine6: 0,
      inputUsed: false,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  handleReset = () => {
    this.data = cloneDeep(this.initialData);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    document.addEventListener("keydown", this.handleKeyPress);
    document.addEventListener("keyup", this.handleKeyUp);
    this.firstTextInterval();
  }

  handleCLickOrTouchButton = (actionKey, val) => {
    this.data = {
      ...this.data,
      moveRight: false,
      moveLeft: false,
      moveUp: false,
      moveDown: false,
      [actionKey]: val
    };
  };

  // exploreButtonClick =() => {
  //   // console.log(this.data.exploreButtonPoint);
  //   // if(this.data.exploreButtonPoint === 1){
  //   //   this.data.exploreButtonPoint = 0;
  //   // }
  //   if(this.data.exploreButtonPoint === 0){
  //     this.data.exploreButtonPoint = 1;
  //   }
  // }


  handleKeyPress = (event) => {
    const {code} = this.props;
    const isSubmarine7 = code === 'submarine7';
    // console.log('debug on event key code', event.keyCode);
    if (isSubmarine7) {
      switch (event.keyCode) {
        case 37:
          this.data.moveLeft = true;
          break;
        case 39:
          this.data.moveRight = true;
          break;
        case 38:
          this.data.moveUp = true;
          break;
        case 40:
          this.data.moveDown = true;
          break;
      }
    }
  };

  handleKeyUp = (event) => {
    const {code} = this.props;
    const isSubmarine7 = code === 'submarine7';
    console.log('debug on event key up', event.keyCode);
    if (isSubmarine7) {
      switch (event.keyCode) {
        case 37:
          this.data.moveLeft = false;
          break;
        case 39:
          this.data.moveRight = false;
          break;
        case 38:
          this.data.moveUp = false;
          break;
        case 40:
          this.data.moveDown = false;
          break;
      }
    }
  }

  firstTextInterval = () => {
    this.Interval = setInterval(() => {
      this.setState(prevState => ({
        textPoint: prevState.textPoint + 1
      }))
      if (this.state.textPoint === 100) {
        clearInterval(this.Interval);
      }
    }, 25)}

  imageTextSubmarine6Interval = () => {
    if(this.data.imageTextSubmarine6 === 0) {
      this.data.imageTextSubmarine6 = 1;
    }
    this.Interval = setInterval(() => {
      this.setState(prevState => ({
        textImagePoint: prevState.textImagePoint + 1
      }))
      if (this.state.textImagePoint === 100) {
        clearInterval(this.Interval);
      }
    }, 25)}

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  get realDepth () {
    return this.data.depth + 270;
  }

  get pressure () {
    return this.realDepth / 75 * 10 * 1000 * 10;
  }

  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
    this.setState({textPoint: 0});
    this.setState({textImagePoint: 0});
    this.firstTextInterval();

  };
  onClickCheckButton = () => {
    const {code} = this.props;
    const isSubmarine6 = code === 'submarine6';
    if((this.data.massOfWaterColumn < 5300 && this.data.massOfWaterColumn > 4790 && !isSubmarine6) || (isSubmarine6 && this.data.waterVolume < 3.01 && this.data.waterVolume > 2.99 )){
      this.data.exploreCheckButton = 1;
      animation.add(finishAnimationOptions);
      animation.play();
      this.props.changeSuccessVisible(true);

       if (isSubmarine6) {
         this.data.waterVolume = 3;
       }
    }
    else{
      animation.add(finishAnimationOptions);
      animation.play();
      this.props.changeFailureVisible(true);
    }
    this.forceUpdate();
  };

  getAllNodes = (stageNode) => ({
    depthNode: this.depthRef?.current,
    pressureNode: this.pressureRef?.current,
    submarineNode: this.submarineRef?.current,
    waterLevelNode: this.waterLevelRef?.current,
    realDepthTextNode: this.realDepthTextRef?.current,
    realPressureTextNode: this.realPressureTextRef?.current,
    submarineWaterNode: this.submarineWaterRef?.current,
    backgroundNode: this.backgroundRef?.current,
    horizonNode: this.horizonRef?.current,
    indicatorText1Node: this.indicatorText1Ref?.current,
    indicatorText2Node: this.indicatorText2Ref?.current,
    indicatorText3Node: this.indicatorText3Ref?.current,
    pressureRectNode: this.pressureRectRef?.current,
    pressureTextNode: this.pressureTextRef?.current,

    submarineInsideTanksNode: this.submarineInsideTanks?.current,
    submarine2Node: this.submarine2Ref?.current,
    submarine1Node: this.submarine1Ref?.current,

    barrier1Node: this.barrier1Ref?.current,
    barrier2Node: this.barrier2Ref?.current,
    barrier3Node: this.barrier3Ref?.current,
    submarineRectNode: this.submarineRectRef?.current,
    submarineRect2Node: this.submarineRect2Ref?.current,

    forceOfArchimedesArrowNode: this.forceOfArchimedesArrowRef?.current,
    forceOfArchimedesTextNode: this.forceOfArchimedesTextRef?.current,
    forceOfGravityArrowNode: this.forceOfGravityArrowRef?.current,
    forceOfGravityTextNode: this.forceOfGravityTextRef?.current,
    firstQuestionTextNode: this.firstQuestionTextRef?.current,
    volumeTextNode: this.volumeTextRef?.current,
    imageSubmarine6ScaleNode: this.imageSubmarine6ScaleRef?.current,

    firstQuestionTextWrapperNode: this.firstQuestionTextWrapperRef?.current,
    firstQuestionTextWrapperRectNode: this.firstQuestionTextWrapperRectRef?.current,
  });

  // get depthAngle () {
  //   const d = this.realDepth;
  //   const maxAngle = 270;
  //   const maxVal = 1000;
  //   if ( d <= maxVal && d >= 0) return d/maxVal * maxAngle;
  //   if (d > maxVal) return maxAngle;
  // };
  // get pressureAngle () {
  //   const p = this.pressure;
  //   const maxAngle = 270;
  //   const maxVal = 20 * 100000;
  //   if ( p <= maxVal && p >= 0) return p/maxVal * maxAngle;
  //   if (p > maxVal) return maxAngle;
  // };
  // get waterLevelAngle () {
  //   const wl = this.waterLevel;
  //   const maxAngle = 270;
  //   const maxVal = 5;
  //   if ( wl <= maxVal && wl >= 0) return wl/maxVal * maxAngle;
  //   if (wl > maxVal) return maxAngle;
  // };

  get waterLevel () {
    return this.data.waterVolume;
  }

  get mass () {
    return 10000 + this.data.waterVolume * 1000;
  }

  get archForce () {
    const depth = this.data.depth;
    const maxArchForce = 13000 * 10;
    if (depth < - 270) {
      return 0
    }
    if (depth >= -270 && depth <= -240) {
      return maxArchForce * (depth - (-270)) / 30
    }
    return maxArchForce;
  }

  enslow (value, delta, timedeltaSec) {
    let absValue = Math.abs(value);
    let signValue = Math.sign(value);

    absValue -= delta * timedeltaSec;
    if (absValue < 0) absValue = 0;

    return signValue * absValue
  }

  move = (time) => {
    const {} = this.staticData;
    const {
      moveUp, moveDown
    } = this.data;
    const nodes = this.getAllNodes();
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const isSubmarine7 = this.props.code === 'submarine7';
    const isSubmarine1 = this.props.code === 'submarine1';
    const isSubmarine5 = this.props.code === 'submarine5';
    const isSubmarine6 = this.props.code === 'submarine6';

    if (isSubmarine7) {
      if (this.submarineDies && this.data.submarineDies) {
        this.props.changeFailureVisible(true);
        this.handleReset();
        return;
      }
      if (this.success) {
        this.props.changeSuccessVisible(true);
        this.handleReset();
        return;
      }
    }
    if((isSubmarine1 || isSubmarine5 || isSubmarine6) && this.data.imageTextSubmarine6 === 0) {
      nodes.firstQuestionTextNode && nodes.firstQuestionTextNode.opacity(this.state.textPoint / 100);
      nodes.firstQuestionTextWrapperNode && nodes.firstQuestionTextWrapperNode.opacity(this.state.textPoint / 100);
      if (isSubmarine6) {
      nodes.imageSubmarine6ScaleNode.opacity(0);
      }
    }
    if((isSubmarine1 || isSubmarine5 || isSubmarine6) && this.data.imageTextSubmarine6 === 1){
      nodes.firstQuestionTextNode && nodes.firstQuestionTextNode.opacity(0);
      nodes.firstQuestionTextWrapperNode && nodes.firstQuestionTextWrapperNode.opacity(0);
      // nodes.imageSubmarine6ScaleNode.opacity(this.state.textImagePoint/100);
    }



    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    this.data.submarineDies = this.submarineDies;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    if(!isSubmarine6) {
      if (moveDown) {
        data.waterVolume += 2 * timedeltaSec;
      }

      if (moveUp) {
        data.waterVolume -= 2 * timedeltaSec;
      }
    }

    if(this.data.exploreButtonPoint === 1 && (Math.floor(this.data.waterVolume * 1000) <= 5000) && (Math.floor(this.data.waterVolume * 1000) >= 0)){
      nodes.volumeTextNode.text(Math.floor(this.data.waterVolume * 1000));
    }


    let force = (this.mass * 10 - this.archForce) * 20;

    data.verticalSpeed += force / this.mass * timedeltaSec;

    const enslowDelta = Math.abs(data.verticalSpeed) * 1.3;
    data.verticalSpeed = this.enslow(data.verticalSpeed,  enslowDelta, timedeltaSec);

    data.depth += data.verticalSpeed * timedeltaSec;

    if (data.waterVolume >= 5) data.waterVolume = 5;
    if (data.waterVolume <= 0) data.waterVolume = 0;


    if (data.depth > 85)
      data.textColor = '#273e53';
    else
      data.textColor = '#447b9c';

    // Hosizontal move
    if (data.moveRight) {
      data.horizontalSpeed += 40 * timedeltaSec;
    }
    if (data.moveLeft) {
      data.horizontalSpeed -= 40 * timedeltaSec;
    }
    if (this.props.code === 'submarine7') {
      data.posX += data.horizontalSpeed * timedeltaSec;
    } else {
      data.horizontalSpeed = 0;
      data.posX = 150;
    }

    let text = '';
    let textHeight = 180;
    if (isSubmarine1) {
      text = 'За счет какого механизма лодка погружается под воду?';
      textHeight = 80
      if (data.exploreButtonPoint) {
        text = 'В подводной лодке расположены емкости для набора воды \nи увеличения массы лодки.' +
          '\nЕсли вода спущена, то лодка всплывает.\nЕсли емкости заполнены, то лодка погружается под воду';
        textHeight = 180
      }
    }
    if (isSubmarine5) {
      text = 'Определи массу столба воды, который давит\nна верхнюю часть лодки на глубине 50 метров,\nесли известно, что площадь лодки в горизонтальном разрезе - 100 м2'
    }
    if (isSubmarine6) {
      text = 'Определи, сколько воды нужно залить в цистерны, чтобы лодка оставалась в равновесии'
      textHeight = 110;
    }

    nodes.firstQuestionTextNode &&
      nodes.firstQuestionTextNode.text(text);
    nodes.firstQuestionTextWrapperRectNode &&
      nodes.firstQuestionTextWrapperRectNode.height(textHeight);

    this.updateStage();
  };

  hasIntersection(r1, r2) {
    if (!r1 || !r2) return undefined;
    const r1abs = r1.getAbsolutePosition(this.stageRef?.current);
    const r2abs = r2.getAbsolutePosition(this.stageRef?.current);
    return !(
          r2abs.x > r1abs.x + r1.width() ||
          r2abs.x + r2.width() < r1abs.x ||
          r2abs.y > r1abs.y + r1.height() ||
          r2abs.y + r2.height() < r1abs.y
        );
  }

  get submarineDies() {
    const nodes = this.getAllNodes();
    const bumped1 = this.hasIntersection(nodes.barrier1Node, nodes.submarineRectNode) || this.hasIntersection(nodes.barrier1Node, nodes.submarineRect2Node);
    const bumped2 = this.hasIntersection(nodes.barrier2Node, nodes.submarineRectNode) || this.hasIntersection(nodes.barrier2Node, nodes.submarineRect2Node);
    const bumped3 = this.hasIntersection(nodes.barrier3Node, nodes.submarineRectNode) || this.hasIntersection(nodes.barrier3Node, nodes.submarineRect2Node);
    return bumped1 || bumped2 || bumped3;
  }

  get success() {
    return this.data.posX > 800;
  }

  updateStage() {
    const {code} = this.props;

    const isSubmarine1 = code === 'submarine1';
    const isSubmarine2 = code === 'submarine2';
    const isSubmarine5 = code === 'submarine5';
    const isSubmarine6 = code === 'submarine6';
    const isSubmarine7 = code === 'submarine7';

    const {depth, startTime, prevTime, waterVolume} = this.data;
    const timedelta = prevTime ? startTime - prevTime : 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    const nodes = this.getAllNodes();

    nodes.submarineWaterNode.height(-waterVolume * 3.8);
    nodes.backgroundNode.y(-depth);
    nodes.horizonNode.y(depth);
    nodes.horizonNode.height(-depth+170);
    if(!isSubmarine6) {
      if (Math.floor(this.data.depth + 250) / 5 > 0) {
        nodes.realDepthTextNode.text(Math.floor(this.data.depth + 250) / 5);
        nodes.realPressureTextNode.text((Math.floor((this.data.depth + 250) * 9.8 / 5)));
      }
      if (Math.floor(this.data.depth + 250) / 5 <= 0) {
        nodes.realDepthTextNode.text(0);
        nodes.realPressureTextNode.text(0);
      }
    }

    if(isSubmarine6){
      //сила тяжести
      nodes.realPressureTextNode.text(Math.floor(this.mass * 10 / 100 ));
    }


    if (isSubmarine7) {
      nodes.submarineNode.x(this.data.posX);
    } else {
      nodes.submarineNode.x(this.initialData.posX);
    }

    if (isSubmarine6) {
      nodes.forceOfArchimedesArrowNode && nodes.forceOfArchimedesArrowNode.points([0, 0, 0, -this.archForce / 1000 +50]);
      nodes.forceOfGravityArrowNode && nodes.forceOfGravityArrowNode.points([0, 0, 0, this.mass * 10 / 1000 -50]);
      nodes.forceOfArchimedesTextNode && nodes.forceOfArchimedesTextNode.y(-this.archForce / 1000 + 40);
      nodes.forceOfGravityTextNode && nodes.forceOfGravityTextNode.y((this.mass * 10 / 1000) - 80);
    }

    const fluidDensity = 1030; //кг/м³
    const g = 9.8;
    let waterColumnHeight = (this.realDepth - 23.5)/5;
    waterColumnHeight = waterColumnHeight < 0 ? 0 : waterColumnHeight;
    const waterPressureAbove = Math.round((waterColumnHeight * fluidDensity * g)/1000 / 100);
    const waterMassAbove = Math.round(waterColumnHeight * fluidDensity * 5 * 35 / 1000); // submarine height ang weight
    if (isSubmarine5) {
      nodes.pressureRectNode.height(-depth-250);
      // nodes.pressureTextNode.height(-depth-250);
      // nodes.pressureTextNode.text(`${waterMassAbove} т, ${waterPressureAbove} атм`);
    }

    nodes.submarineInsideTanksNode.visible(isSubmarine2 || isSubmarine5 || isSubmarine6 || (isSubmarine1 && this.data.exploreButtonPoint === 1));
    nodes.submarine2Node.visible(isSubmarine2 || isSubmarine6 || isSubmarine5 || (isSubmarine1 && this.data.exploreButtonPoint === 1));
    nodes.submarine1Node.visible((isSubmarine1 && this.data.exploreButtonPoint === 0)|| isSubmarine7);


    stageNode.draw();
  }

  onChangeInput = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    const min = Number(e.target.min);
    const max = Number(e.target.max);
    let correctVal = val;
    if (Number(val) < min)
      correctVal = min;
    if (Number(val) > max)
      correctVal = max;
    this.setState({[name]: correctVal, [`${name}Input`]: val})
  };

  CanvasLever = () => {
    const {code} = this.props;
    const {backgroundPosY, posX} = this.data;

    const [submarine1] = useImage(submarine1Img);
    const [submarine2] = useImage(submarine2Img);
    const [indicator] = useImage(indicatorImg);
    const [boundaries] = useImage(boundariesImg);
    const [againButton] = useImage(againButtonImg);
    const [FarchAsset] = useImage(FarchAssetImg);
    const [FtyazhAsset] = useImage(FtyazhAssetImg);
    const [checkButton] = useImage(checkButtonImg);

    const lineProps = {
      x: 35, y: 35,
      points: [0, 0, -17, 15],
      stroke: 'red'
    };
    const titleSetting = {
      stroke: '#e17c63', fill: '#e17c63', strokeWidth: .2,
      fontSize: 13
    }
    const valueSettings = {
      stroke: '#e17c63', fill: '#e17c63', strokeWidth: .2,
      fontSize: 25
    }

    const isSubmarine1 = code === 'submarine1';
    const isSubmarine2 = code === 'submarine2';
    const isSubmarine5 = code === 'submarine5';
    const isSubmarine6 = code === 'submarine6';
    const isSubmarine7 = code === 'submarine7';

    return (
      <React.Fragment>
        <Layer preventDefault={false}>
          <Group ref={this.backgroundRef} height={1000}>
            <Rect ref={this.horizonRef} fill={'#A8DADC'} height={170} width={1000} preventDefault={false}/>
            <Group y={170}>
              <Rect fill={'#447b9c'} height={5000} width={1000} preventDefault={false}/>
              {
                Array(150).fill(1).map((el, i) => {
                  const correctIndex = i+1;
                  const isFive = correctIndex % 5 === 0;
                  const lineWidth = isFive ? 40 : 20;
                  return (
                    <Group y={correctIndex*50} key={`scale-${i}`}>
                      <Line points={[0, 0, lineWidth, 0]} stroke={'white'} strokeWidth={2}/>
                      <Text text={correctIndex * 10} fill={'white'} fontSize={isFive ? 20 : 13} y={isFive ? -10 : -5} x={lineWidth+10}/>
                    </Group>
                  )
                })
              }
            </Group>


            { isSubmarine7
              ? (<Group>
                <Rect ref={this.barrier1Ref} x={400} y={180} width={10} height={300} fill={'gray'}/>
                <Rect ref={this.barrier2Ref} x={280} y={620} width={10} height={200} fill={'gray'}/>
                <Rect ref={this.barrier3Ref} x={670} y={300} width={10} height={900} fill={'gray'}/>
              </Group>)
              : null
            }
          </Group>


          <Group x={65} y={40}>
            <Card height={200} width={900} x={-10} y={-10} opacity={0}
                  reffer={this.firstQuestionTextWrapperRef}
                  rectRef={this.firstQuestionTextWrapperRectRef}
            >
              <Text
                ref={this.firstQuestionTextRef}
                opacity={0}
                x={20} y={20}
                lineHeight={1.2}
                text={''}
                width={850} strokeWidth={.5}
                stroke={'#486a93'}
                fill={'#486a93'}
                fontSize={30}
              />
            </Card>
          </Group>


          <Group ref={this.submarineRef} x={posX} y={420} height={40} offsetY={30}>
            {
              isSubmarine5 && (
                <>
                  <Rect ref={this.pressureRectRef} width={178} height={-200} fill={'#58ACF9'} y={30}/>
                  {/*<Text ref={this.pressureTextRef} text={'21 v'} stroke={'white'} fill={'white'} fontSize={20} y={-30} width={178} height={-200} align={'center'} strokeWidth={1}/>*/}
                </>
              )
            }

            <Image
              ref={this.submarine2Ref}
              image={submarine2}
              visible={isSubmarine2 || isSubmarine5 || isSubmarine6 || (isSubmarine1 && this.data.exploreButtonPoint === 1)}
            />

            <Rect ref={this.submarineWaterRef} x={67} y={38} width={70} fill={'#0CB2D4'}/>

            <Image
              ref={this.submarine1Ref}
              image={submarine1}
              width={178} height={39}
              visible={(isSubmarine1 && this.data.exploreButtonPoint === 0) || isSubmarine7}
            />

            <Rect ref={this.submarineRectRef} x={5} y={18} width={171} height={20} fill={'red'} opacity={0}/>
            <Rect ref={this.submarineRect2Ref} x={105} y={3} width={25} height={15} fill={'pink'} opacity={0}/>


            <Group ref={this.submarineInsideTanks} visible={isSubmarine2 || isSubmarine5 || (isSubmarine1 && this.data.exploreButtonPoint === 1)}>
              <Image image={boundaries} x={67} y={21}/>
              <Rect stroke={'#9caaad'} x={67} y={38} height={-18} width={70}/>
            </Group>

            {/* -------------- VECTORS -------------- */}
            {
              isSubmarine6 || isSubmarine7 && (
                <Group x={100} y={18}>
                  <Group>
                    <Arrow ref={this.forceOfArchimedesArrowRef} points={[0, 0, 0, -100]} stroke={'#273e53'} fill={'#273e53'} strokeWidth={1}/>
                    <Image image={FarchAsset} y={-100} x={20} ref={this.forceOfArchimedesTextRef}/>

                  </Group>

                  <Group y={20}>
                    <Arrow ref={this.forceOfGravityArrowRef} points={[0, 0, 0, 100]} stroke={'#273e53'} fill={'#273e53'} strokeWidth={1}/>
                    <Image image={FtyazhAsset} y={80} x={20} ref={this.forceOfGravityTextRef}/>
                  </Group>
                </Group>
              )
            }
          </Group>
          {
            !isSubmarine7 && (
              <Group x={803} y={495} onClick={() => this.onClickReset()} onTap={() => this.onClickReset()}>
                <Image image={againButton}/>
              </Group>
            )
          }

          {isSubmarine5 || isSubmarine6?
            <Group x={369} y={495}>
              <CanvasButton
                x={0}
                text={'Проверить'}
                onClick={() => this.onClickCheckButton()}
                fontSize={21}
                height={60}
                width={152}
                strokeWidth={.2}
                btnCornerRadius={3}
              />
            </Group> : null}

          {
            isSubmarine6 && (
              <Group x={150} y={50} opacity={0} ref={this.imageSubmarine6ScaleRef}>
                <Rect width={400} height={200} stroke={'rgba(0,0,0,0.1)'} strokeWidth={2} cornerRadius={3} fill={'#EDF2FB'} y={-30}/>
                <Group scale={{x: .4, y: .4}}>
                  <CanvasSubmarineScale
                    stageNode={this.stageRef?.current}
                    moveUp={this.data.moveUp}
                    moveDown={this.data.moveDown}
                    externalData={() => ({...this.data, leftMomentum: ((this.mass -0.057)* 10 )/300, rightMomentum: (this.archForce) / 300 })}
                  />
                </Group>
              </Group>
            )
          }
          <Group x={370} y={isSubmarine5 ?  250 : 300}>

            <Card y={isSubmarine6 ? 50 : 0} height={
              isSubmarine6 ? 130 : (this.data.exploreButtonPoint === 1 && isSubmarine1) || isSubmarine5 ? 240 : 170}>

              <Group x={20} y={20} visible={!isSubmarine6}>
                <Text text={`Глубина\nпогружения, м:`} {...titleSetting}/>
                <Text y={30} text={''} ref={this.realDepthTextRef}{...valueSettings}/>
              </Group>

              <Group x={20} y={75} visible={!isSubmarine6}>
                <Text text={
                  // isSubmarine6
                    // ? `\n\nСила \nтяжести , кН:`
                    `\n\nДавление на\nглубине , кПа:`
                } {...titleSetting}/>
                <Text y={55} text={`15`} ref={this.realPressureTextRef}{...valueSettings}/>
              </Group>


              <Group x={20} y={140}>
                {(this.data.exploreButtonPoint === 1 && (isSubmarine1 || isSubmarine5)) ?<Text text={` \n\nобъем набранной\nводы , л:`} {...titleSetting}/>: null}
                {(this.data.exploreButtonPoint === 1 && (isSubmarine1 || isSubmarine5)) ?<Text y={55} text={``}ref={this.volumeTextRef}{...valueSettings}/>: <Text y={55} text={`????`}ref={this.volumeTextRef}{...valueSettings} opacity={0}/>}
              </Group>

              <Group x={20} y={isSubmarine6 ? 30 :170} visible={isSubmarine6 || isSubmarine5}>
                <Text text={
                  isSubmarine6
                   ? `Объем набранной\nводы, м3:`
                   : `Масса столба\nводы, т`
                } {...titleSetting}/>
                {/*todo добавить другой инпут для столба воды*/}
                <CanvasInput
                    textColor={'#e17c63'}
                    y={27}
                    width={90}
                    height={30}
                    stage={this.stageRef?.current}
                    onInput={(val) => {
                      this.data.waterVolume = parseFloat(val)/10;
                      this.data.inputUsed = true;
                    }}
                    value={this.data.inputUsed ? (this.data.waterVolume * 10).toFixed(1) : ''}
                />
              </Group>

            </Card>
          </Group>

          <Group x={820} y={300} visible={isSubmarine6}>
            <Card height={170} width={120} >
              <Group x={20} y={20}>
                <Text text={`Объем\nлодки, м3:`} stroke={'#3e4258'} fill={'#3e4258'} strokeWidth={.2}
                fontSize={13}/>
                <Text y={30} text={'130'} stroke={'#3e4258'} fill={'#3e4258'} strokeWidth={.2}
                      fontSize={25}/>
              </Group>

              <Group x={20} y={65}>
                <Text text={`\n\nМасса лодки \nс пустыми \nцистернами , т:`} stroke={'#3e4258'} fill={'#3e4258'} strokeWidth={.2}
                      fontSize={13}/>
                <Text y={70} text={`100`} stroke={'#3e4258'} fill={'#3e4258'} strokeWidth={.2}
                      fontSize={25}/>
              </Group>
            </Card>
          </Group>

        </Layer>
      </React.Fragment>
    )
  };

  keyboardEvents = (event) =>{
    event.persist();
    console.log(event.key); // this will return string of key name like 'Enter'
  };

  render() {
    const props = this.props;
    const isSubmarine7 = props.code === 'submarine7';
    const isSubmarine1 = props.code === 'submarine1';
    const isSubmarine5 = props.code === 'submarine5';
    const isSubmarine6 = props.code === 'submarine6';

    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={props.code}>
          <this.CanvasLever/>
        </CanvasContainer>

        <div style={{...styles.fieldsBlock, right: `${isSubmarine7 ? '12%' : '3%'}`}}>
          {
            isSubmarine7 && (
              <button
                style={{...styles.btn, fontSize: '3vmin'}}
                onTouchStart={() => this.handleCLickOrTouchButton('moveLeft', true)}
                onTouchEnd={() => this.handleCLickOrTouchButton('moveLeft', false)}
                onMouseDown={() => this.handleCLickOrTouchButton('moveLeft', true)}
                onMouseUp={() => this.handleCLickOrTouchButton('moveLeft', false)}
              ><ArrowLeftOutlined /></button>
            )
          }
          <div style={isSubmarine7 ? {display: 'flex', flexDirection: 'column'} : {}}>
            {!isSubmarine6 ? (
              <>
                <button
                  style={{...styles.btn, fontSize: `${isSubmarine7 ? '3vmin' : '2vmin'}`}}
                  onTouchStart={() => this.handleCLickOrTouchButton('moveUp', true)}
                  onTouchEnd={() => this.handleCLickOrTouchButton('moveUp', false)}
                  onMouseDown={() => this.handleCLickOrTouchButton('moveUp', true)}
                  onMouseUp={() => this.handleCLickOrTouchButton('moveUp', false)}
                >
                  {isSubmarine7 ? <ArrowUpOutlined /> : 'Подъем'}
                </button>
              </>
              )
              : null
            }
            {!isSubmarine6 &&
              <div style={{ top: '10%'}}>
                 <button
                  style={{...styles.btn, fontSize: `${isSubmarine7 ? '3vmin' : '2vmin'}`}}
                  onTouchStart={() => this.handleCLickOrTouchButton('moveDown', true)}
                  onTouchEnd={() => this.handleCLickOrTouchButton('moveDown', false)}
                  onMouseDown={() => this.handleCLickOrTouchButton('moveDown', true)}
                  onMouseUp={() => this.handleCLickOrTouchButton('moveDown', false)}
                >{isSubmarine7 ? <ArrowDownOutlined /> : 'Погружение'}</button>
              </div>
            }
            { isSubmarine1 && (<div style={{ top: '10%'}}>
              <button
                  style={{ fontSize:'2vmin', margin: '3px', background: '#88c3b0', borderRadius: '3px', color: 'white', zIndex: 10, padding: '1vmin 1.5vmin', fontWeight: 'bold', cursor: 'pointer',}}
                  onClick={() => this.data.exploreButtonPoint = 1}
                  onTouchStart={() => this.data.exploreButtonPoint = 1}
              >{'Узнать >>'}</button>
            </div>
            )
            }
          </div>
          {
            isSubmarine7 && (
              <>
                <button
                  style={{...styles.btn, fontSize: '3vmin'}}
                  onTouchStart={() => this.handleCLickOrTouchButton('moveRight', true)}
                  onTouchEnd={() => this.handleCLickOrTouchButton('moveRight', false)}
                  onMouseDown={() => this.handleCLickOrTouchButton('moveRight', true)}
                  onMouseUp={() => this.handleCLickOrTouchButton('moveRight', false)}
                ><ArrowRightOutlined /></button>
                <button
                  style={{...styles.btn, margin: '3px 15px'}}
                  onClick={() => this.handleReset()}
                  onTouchStart={() => this.handleReset()}
                >Сбросить</button>
              </>
            )
          }
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Submarine);

const styles = {
  mainContainer: {
    background: 'black',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    margin: '3px',
    background: '#3e4258',
    borderRadius: '3px',
    color: 'white',
    zIndex: 10,
    fontSize: '2vmin',
    padding: '1vmin 1.5vmin',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  fieldsBlock: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    bottom: '10%',
    right: '30%'
  }
};
