import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import dragImg11 from "../images/arrangeByColumns/LogicWheel5_1_1.png";
import dragImg12 from "../images/arrangeByColumns/LogicWheel5_1_2.png";
import dragImg13 from "../images/arrangeByColumns/LogicWheel5_1_3.png";
import dragImg14 from "../images/arrangeByColumns/LogicWheel5_1_4.png";

import ArrangeByColumns from "./ArrangeByColumns";
import bgNature from '../images/bgNature.png';
import { _t } from "../../../../utils/lang/common";


const LogicWheel5 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: _t("logic_wheel.active_vocabulary"),
      columnsItems: [
        {id: 1, successAnswerId: 2, title: _t("logic_wheel.wheel")},
        {id: 2, successAnswerId: 1, title: _t("logic_wheel.axle")},
        {id: 3, successAnswerId: 4, title: _t("logic_wheel.wheelbarrow")},
        {id: 4, successAnswerId: 3, title: _t("logic_wheel.to_roll")},
      ],
      dragItems: [
        {id: 1, image: dragImg12},
        {id: 2, image: dragImg11},
        {id: 3, image: dragImg14},
        {id: 4, image: dragImg13},
      ]
    },
    {
      title: _t("logic_wheel.active_vocabulary"),
      columnsItems: [
        {id: 1, successAnswerId: 3, title: _t("logic_wheel.wheel")},
        {id: 2, successAnswerId: 4, title: _t("logic_wheel.axle")},
        {id: 3, successAnswerId: 1, title: _t("logic_wheel.wheelbarrow")},
        {id: 4, successAnswerId: 2, title: _t("logic_wheel.to_roll")},
      ],
      dragItems: [
        {id: 1, mainText: _t("logic_wheel.small_vehicle")},
        {id: 2, mainText: _t("logic_wheel.move_along_surface")},
        {id: 3, mainText: _t("logic_wheel.circle_shaped_device")},
        {id: 4, mainText: _t("logic_wheel.rod_or_shaft")},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <ArrangeByColumns
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    columns={item.columnsItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default LogicWheel5;
