import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Arrow, Group, Image, Layer, Rect, Text} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {GIF} from "../../../canvas/components/GIF";

import chartBg_1_3 from '../../../../images/IdealGas/bg1-3.png';
import chartBg_isotherm from '../../../../images/IdealGas/bg_isotherm.png';
import chartBg_Isobar_isochore from '../../../../images/IdealGas/bg_Isobar_isochore.png';
import chartBg_adiabat from '../../../../images/IdealGas/bg_adiabat.png';
import pistonImage from '../../../../images/steamEngine/piston-task-2.png';
import fireGIF from '../../../../images/fireSmall.gif'

import ChartsContainer from "../components/ChartsContainer";
import {adiabaticVolumeChangeSimple, pressureStatic, volumeStatic} from "../../ICE/utils/icePhysics";
import {getBoolByLsnCode} from "../utils/common";

import {_t} from '../../../../utils/lang/common';
import {capitalizeFirstLetter as _c, sendSuccessForScenario} from "../../../../utils/common";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CanvasInput from "../../../canvas/components/CanvasInput";


let initialData = {
  // ----- TIME DATA ------
  currentTimeSec: 0,
  startTime: undefined,
  prevTime: undefined,

  // ----- ACTIONS ------
  burning: false,
  start: false,
  pushingPiston: false,

  pistonLeft: false,
  pistonRight: false,

  // ----- DATA FIXING VALUES ------
  isFixedTemperature: false,
  isFixedPressure: false,
  isFixedVolume: false,
  isAdiabatic: false,

  temperaturePesSecond: 10,

  temperature1: 293,
  temperature2: 493,
  pressure1: 10000,
  pressure2: 10000,

  temperature: 293,
  gasAmount: 1,
  volume: 0.244, // tmp value fot initialization
  pressure: 10000,
};

initialData.voluime = volumeStatic(initialData.gasAmount, initialData.temperature, initialData.pressure);

const getInitialData = (code) => {
  const {
    idealGas1, idealGas2, idealGas3,
    idealGas4, idealGas5, idealGas6,
    idealGas7, idealGas8, idealGas9,
    idealGas10, idealGas11,
  } = getBoolByLsnCode(code);
  let resInitialData = {...initialData};

  resInitialData = {
    ...resInitialData,
    isFixedVolume: idealGas1 || idealGas3 || idealGas5 || idealGas8 || idealGas9,
    isFixedPressure: idealGas2 || idealGas6 || idealGas7,
    isFixedTemperature: idealGas4,
    isAdiabatic: idealGas10 || idealGas11,
  };
  return resInitialData;
};

class IdealGasChart extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();

    this.fixPosPistonRef = React.createRef();
    this.fireRef = React.createRef();
    this.gasRef = React.createRef();
    this.pistonRef = React.createRef();
    this.gasPistonRef = React.createRef();

    this.temperatureInputRef = React.createRef();
    this.pressureInputRef = React.createRef();

    this.state = {};
    this.initialData = {...initialData};
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _resetCallback1 = () => {};
  _resetCallback2Left = () => {};
  _resetCallback2Right = () => {};
  _resetCallback3Left = () => {};
  _resetCallback3Center = () => {};
  _resetCallback3Right = () => {};
  getResetCallback1 = (callback) => {this._resetCallback1 = callback};
  getResetCallback2Left = (callback) => {this._resetCallback2Left = callback};
  getResetCallback2Right = (callback) => {this._resetCallback2Right = callback};
  getResetCallback3Left = (callback) => {this._resetCallback3Left = callback};
  getResetCallback3Center = (callback) => {this._resetCallback3Center = callback};
  getResetCallback3Right = (callback) => {this._resetCallback3Right = callback};

  handleReset = () => {
    this._resetCallback1();
    this._resetCallback2Left();
    this._resetCallback2Right();
    this._resetCallback3Left();
    this._resetCallback3Center();
    this._resetCallback3Right();

    this.data=cloneDeep(this.initialData);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.code !== prevProps.code) {
      this.initialData = getInitialData(this.props.code);
      this.handleReset();
    }
  }

  componentDidMount() {
    this.initialData = getInitialData(this.props.code);
    this.handleReset();
    window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onChangeInput = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    this.data[name] = Number(val);
  };


  get canBoil () {return this.data.temperature >= 100}

  get pistonOffset () {
    const data = this.data;
    return data.volume / 0.01 * 5;
  }
  get pistonBlockedLeft () {
    const {idealGas1, idealGas2, idealGas3} = getBoolByLsnCode(this.props.code);
    if (!idealGas1 && !idealGas2 && !idealGas3) {
      return this.pistonOffset < 40;
    } else {
      return this.pistonOffset < 70;
    }
  }
  get pistonBlockedRight () {
    const {idealGas1, idealGas2, idealGas3} = getBoolByLsnCode(this.props.code);
    if (!idealGas1 && !idealGas2 && !idealGas3) {
      return this.pistonOffset > 225;
    } else {
      return this.pistonOffset > 335;
    }
  }

  move = (time) => {
    let data = this.data;
    const {idealGas5} = getBoolByLsnCode(this.props.code);
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);


    let timedeltaSec = 0;

    if (this.data.start) {
      data.startTime = data.startTime || time;
      const timedelta = data.prevTime ? time - data.prevTime : 0;
      data.prevTime = time;
      timedeltaSec = timedelta / 1000;
    }

    data.currentTimeSec = data.startTime ? (time - data.startTime)/1000 : 0;


    const cachedVolume = data.volume;
    if (data.pistonRight && !this.pistonBlockedRight) {
      data.volume += 0.05 * timedeltaSec;
    }

    if (data.pistonLeft && !this.pistonBlockedLeft) {
      data.volume -= 0.05 * timedeltaSec;
    }

    // console.log('debug on data', data);

    // Расчет состояния газа
    if (data.isFixedPressure) {
      data.volume = volumeStatic(data.gasAmount, data.temperature, data.pressure);
      if (this.pistonBlockedLeft || this.pistonBlockedRight) {
        data.volume = cachedVolume;
      }
    }

    if (data.isAdiabatic) {
      const {pressure, volume, temperature} = adiabaticVolumeChangeSimple(
        {pressure: data.pressure, volume: cachedVolume, temperature: data.temperature},
        data.volume
      );
      data.pressure = pressure;
      data.volume = volume;
      data.temperature = temperature;
    }

    if (data.burning) {
      data.temperature += timedeltaSec * data.temperaturePesSecond;
    }

    // нагрев - горелкой - не адиабатический процесс, но в задаче с адиабатой мы греем при постоянном объеме для перехода на другую адиабату,
    // поэтому считаем, что нагрев изохорный
    if (data.isFixedVolume || data.isFixedTemperature || data.isAdiabatic) {
      if (idealGas5) {
        data.pressure1 = pressureStatic(data.gasAmount, data.temperature1, data.volume);
        data.pressure2 = pressureStatic(data.gasAmount, data.temperature2, data.volume);
      } else {
        data.pressure = pressureStatic(data.gasAmount, data.temperature, data.volume);
      }
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    let fixPosPistonBtnNode = this.fixPosPistonRef?.current;
    let fireNode = this.fireRef?.current;
    let gasNode = this.gasRef?.current;
    let gasPistonNode = this.gasPistonRef?.current;
    let pistonNode = this.pistonRef?.current;
    let temperatureInputNode = this.temperatureInputRef?.current;
    let pressureInputNode = this.pressureInputRef?.current;

    if (!gasNode || !gasPistonNode || !pistonNode) return;

    gasPistonNode.opacity(0.4);
    gasPistonNode.width(this.pistonOffset);
    pistonNode.x(this.pistonOffset);

    if (fireNode) {
      fireNode.opacity(data.burning ? 1 : 0);
    }
    if (temperatureInputNode) {
      temperatureInputNode.value = data.temperature;
    }
    if (pressureInputNode) {
      pressureInputNode.value = data.pressure;
    }
    if (fixPosPistonBtnNode) {
      fixPosPistonBtnNode.text(`${data.isFixedVolume ? _c(_t('ideal_gas.release')) : _c(_t('ideal_gas.fix'))}`);
    }



    const layerToDraw = gasNode.getLayer();
    layerToDraw.draw();
  }


  CanvasFlask = () => {
    const data = this.data;
    const {
      idealGas1, idealGas2, idealGas3,
      idealGas4, idealGas5, idealGas6,
      idealGas7, idealGas8, idealGas9,
      idealGas10, idealGas11,
    } = getBoolByLsnCode(this.props.code);

    let pistonSettings = {x: 300, y: 402, height: 165};
    let fireSettings = {x: 300, y: 510, scaleX: 1.1, scaleY: 1.1};

    if (!idealGas1 && !idealGas2 && !idealGas3) {
      pistonSettings = {x: 70, y: 420, height: 117};
      fireSettings = {x: 80, y: 530, scaleX: 1, scaleY: 1};
    }


    let correctBackground = chartBg_1_3;

    if (idealGas4 || idealGas5) {
      correctBackground = chartBg_isotherm;
    } else if (idealGas6 || idealGas7 || idealGas8 || idealGas9) {
      correctBackground = chartBg_Isobar_isochore;
    } else if (idealGas10 || idealGas11) {
      correctBackground = chartBg_adiabat;
    }

    const [background] = useImage(correctBackground);
    const [piston] = useImage(pistonImage);


    return (
      <React.Fragment>
        <Layer preventDefault={false}>
          <Image image={background} preventDefault={false}/>

          <Group x={300} y={380}>
            <Rect ref={this.gasRef} fill={'white'}  width={300} height={65} opacity={0}/>
          </Group>

          <Group scale={{x: fireSettings.scaleX, y: fireSettings.scaleY}}>
            <GIF reffer={this.fireRef} src={fireGIF} loop={true} x={fireSettings.x} y={fireSettings.y}/>
          </Group>

          <Group x={pistonSettings.x} y={pistonSettings.y}>
            <Rect ref={this.gasPistonRef} fill={'white'} width={data.pistonOffset} height={pistonSettings.height} opacity={0} cornerRadius={[5, 0, 0, 5]}/>
            <Image ref={this.pistonRef} image={piston} x={data.pistonOffset} preventDefault={false} width={330} height={pistonSettings.height}/>
          </Group>

        </Layer>
      </React.Fragment>
    )
  };

  render() {
    const {burning} = this.state;
    const {
      idealGas1, idealGas2, idealGas3,
      idealGas4, idealGas5, idealGas6,
      idealGas7, idealGas8, idealGas9,
      idealGas10, idealGas11,
    } = getBoolByLsnCode(this.props.code);


    const isTripleCharts = !idealGas1 && !idealGas2 && !idealGas3;

    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.CanvasFlask/>

          <Layer>
            {/** --- ЗАЖЕЧЬ --- */}
            {!(idealGas4 || idealGas5) && (
              <CanvasButton
                text={_c(_t('ideal_gas.torch'))}
                onTouchStart={() => { this.data.burning = true; this.data.start = true }}
                onTouchEnd={() => this.data.burning = false}
                onMouseDown={() => { this.data.burning = true; this.data.start = true }}
                onMouseUp={() => this.data.burning = false}
                fontSize={20}
                strokeWidth={.2}
                btnCornerRadius={3}
                width={120}
                height={45}
                btnFill={'#E76F52'}
                x={isTripleCharts ? 370 : 100} y={515}
              />
            )}

            {/** --- СБРОСИТЬ --- */}
            <CanvasButton
              text={_c(_t('ideal_gas.reset'))}
              onClick={() => this.handleReset()}
              fontSize={20}
              strokeWidth={.2}
              btnCornerRadius={3}
              width={120}
              height={45}
              btnFill={'#E76F52'}
              x={isTripleCharts ? 510 : 820} y={515}
            />

            {/** --- ОТПУСТИТЬ / ЗАФИКСИРОВАТЬ --- */}
            {
              (idealGas2 || idealGas3 || idealGas6 || idealGas7) && (
                <CanvasButton
                  textRef={this.fixPosPistonRef}
                  text={this.data.isFixedVolume ? _c(_t('ideal_gas.fix')) : _c(_t('ideal_gas.release'))}
                  onClick={() => { this.data.isFixedVolume = !this.data.isFixedVolume; this.data.isFixedPressure = !this.data.isFixedPressure; this.data.pressure = 10000 }}
                  fontSize={20}
                  strokeWidth={.2}
                  btnCornerRadius={3}
                  width={170}
                  height={45}
                  btnFill={'#E76F52'}
                  x={isTripleCharts ? 370 : 730} y={400}
                />
              )
            }

            {/** --- СТРЕЛКИ ПЕРЕМЕЩЕНИЯ ПОРШНЯ --- */}
            {
              (idealGas4 || idealGas5 || idealGas10 || idealGas11) && (
                <>
                  <CanvasButton
                    onTouchStart={() => {this.data.start = true; this.data.pistonLeft = true}}
                    onTouchEnd={() => this.data.pistonLeft = false}
                    onMouseDown={() => {this.data.start = true; this.data.pistonLeft = true}}
                    onMouseUp={() => this.data.pistonLeft = false}
                    fontSize={20}
                    strokeWidth={.2}
                    btnCornerRadius={3}
                    width={170}
                    height={45}
                    x={510} y={390}
                  >
                    <Rect fill={'#E76F52'} width={50} height={50}/>
                    <Arrow x={35} y={25} points={[0,0,20,0]} scaleX={-1} stroke={'white'} fill={'white'}/>
                  </CanvasButton>
                  <CanvasButton
                    onTouchStart={() => {this.data.start = true; this.data.pistonRight = true}}
                    onTouchEnd={() => this.data.pistonRight = false}
                    onMouseDown={() => {this.data.start = true; this.data.pistonRight = true}}
                    onMouseUp={() => this.data.pistonRight = false}
                    fontSize={20}
                    strokeWidth={.2}
                    btnCornerRadius={3}
                    width={170}
                    height={45}
                    x={580} y={390}
                  >
                    <Rect fill={'#E76F52'} width={50} height={50}/>
                    <Arrow x={15} y={25} points={[0,0,20,0]} stroke={'white'} fill={'white'}/>
                  </CanvasButton>
                </>
              )
            }

            {
              (idealGas4) && (
                <Group x={170} y={330}>
                  <Text text={`Температура (K):`} fill={'black'} fontSize={18}/>
                  <CanvasInput
                    id={'temperature'}
                    y={25}
                    x={0}
                    width={90}
                    height={30}
                    getStage={() => this.stageRef?.current}
                    stage={this.stageRef?.current}
                    onInput={(val) => {
                      this.data.temperature = val || null;
                    }}
                    value={this.data.temperature}
                  />
                </Group>
              )
            }
            {
              idealGas5 && (
                <Group x={60} y={340}>
                  <Text text={`Температура первого газа (K): ${this.data.temperature1}`} fill={'black'} fontSize={18}/>
                  <Text y={30} text={`Температура второго газа (K): ${this.data.temperature2}`} fill={'black'} fontSize={18}/>
                </Group>
              )
            }
          </Layer>
        </CanvasContainer>


        <ChartsContainer
          lsnCode={this.props.code}
          externalData={() => ({...this.data, ...this.state})}

          getResetCallback1={this.getResetCallback1}
          getResetCallback2Left={this.getResetCallback2Left}
          getResetCallback2Right={this.getResetCallback2Right}
          getResetCallback3Left={this.getResetCallback3Left}
          getResetCallback3Center={this.getResetCallback3Center}
          getResetCallback3Right={this.getResetCallback3Right}
        />
      </div>
    )
  }
}



const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdealGasChart);

const styles = {
  mainContainer: {
    background: '#A8DADC',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
};
