import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import SteamEngineCanvas from "../components/SteamEngineCanvas";
import cloneDeep from 'lodash.clonedeep';
import {_t} from "../../../../utils/lang/common";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {sendSuccessForScenario} from "../../../../utils/common";
import ScenarioManager from "../../../../utils/ScenarioManager";


class SteamEngine extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.state = {
      burning: false,
      switchLeft: true,
    };
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _resetCallback = () => {console.log('Debug reset callback')};
  getResetCallback = (callback) => {this._resetCallback = callback};

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  move = (time) => {

    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);
  }

  render() {
    const {burning, switchLeft} = this.state;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <SteamEngineCanvas
            getResetCallback={this.getResetCallback}
            burning={burning}
            manualSwitchLeft={switchLeft}
            stageNode={this.stageRef?.current}
          />
        </CanvasContainer>
        <button
          style={{...styles.btn, left: '11%', bottom: '25%'}}
          onClick={() => this.setState((prevState) => ({burning: !prevState.burning}))}
        >
          {burning ? _t("torch_off") : _t("torch_on")}
        </button>
        <button
          style={{...styles.btn, right: '4%', bottom: '4%'}}
          onClick={() => this.setState({burning: false, switchLeft: true}, () => this._resetCallback())}
        >
          {_t("reset")}
        </button>
        <button
          style={{...styles.btn, right: '30%', bottom: '55%'}}
          onClick={() => this.setState(prevState => ({switchLeft: !prevState.switchLeft}))}
        >
          {_t("change_position")}
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SteamEngine);

const styles = {
  mainContainer: {
    background: '#457b9d',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  info: {
    position: 'absolute',
    top: '100px',
    right: '100px',
  },
  infoVal: {
    color: 'white',
    fontSize: '4em',
    fontWeight: 'bold'
  },
  infoText: {
    color: 'white',
    fontSize: '2em',

  },
  btn: {
    position: 'absolute',
    background: '#E76F52',
    borderRadius: '7px',
    color: 'white',
    zIndex: 10,
    fontSize: '2vmin',
    padding: '1vmin 1.5vmin',
    fontWeight: 'bold',
  }
};
