import React from "react";
import Victor from "victor";
import Konva from "konva";
import Wall from "../components/Wall";

class Ball extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.id = props.id;

    this.const = {

      R: 3,
      M: 1,
      rotation: 'undefined'

    }

    this.var = {
      id: props.id,
      pos: props.pos,
      V: Victor(0, 0),
      P: Victor(0, 0),
      T: 25,
      collision: 0,
      movement: false,
      F: Victor(0, 0),
    }
    this.image = new Konva.Image({
      id: this.id,
      x: this.var.pos.x,
      y: this.var.pos.y,
      offsetX: this.const.R/2,
      offsetY: this.const.R/2,
      image: null,
      width: this.const.R,
      height: this.const.R,
    });
    const image = new Image();
    image.src = props.image;
    image.onload = () => {
      this.image.image(image);
    }

    if (this.props.speed) {
      this.var.V = this.props.speed;
      this.var.P = Victor(this.var.M * this.var.V.x, this.var.M * this.var.V.y);
    }
    else {
      const Vx = (Math.random()-0.5)*10;
      const Vy = (Math.random()-0.5)*10;
      this.var.V = Victor(Vx, Vy);
       this.var.P = Victor(this.var.M*Vx, this.var.M*Vy);
    }



  }

  componentDidMount() {



  }

  checkCollision1(ball) {
    //console.log('Ball collision checker called');
    if (!(ball instanceof Ball)) {console.log('not a ball'); return;}

    if (this.var.collision > 0) {
      this.var.collision -= 1;
      this.var.pos.add(this.var.V);
      return;
    }

    let [p1, p2] = [this.var.pos, ball.var.pos];
    let [v1, v2] = [this.var.V, ball.var.V];
    let [m1, m2] = [this.const.M, ball.const.M];

    let dist = this.var.pos.distance(ball.var.pos);
    if (dist > this.const.R) {
      return;
    }

    this.var.collision = 0;
    ball.var.collision = 0;

    let vecR = p2.clone().subtract(p1).normalize().multiply(Victor(this.const.R, this.const.R));
    let vecR_n = vecR.clone().normalize();
    let V1projR;
    if (v1.length() == 0) {
      V1projR = Victor(0, 0);
    } else {
      V1projR = vecR.dot(v1)/vecR.length();
      V1projR = vecR_n.clone().multiply(Victor(V1projR, V1projR));
    }
    let V2projR;
    if (v2.length() == 0) {
      V2projR = Victor(0, 0);
    }
    else {
      V2projR = vecR.dot(v2)/vecR.length();
      V2projR = vecR_n.clone().multiply(Victor(V2projR, V2projR));
    }
    let V1perpR = v1.clone().subtract(V1projR);
    let V2perpR = v2.clone().subtract(V2projR);

    //const V1projR_new = ((m1-m2)*V1projR+2*m2*V2projR)/(m1+m2);

    let V1projR_new = Victor(m1-m2, m1-m2).multiply(V1projR).add(Victor(2*m2, 2*m2).multiply(V2projR)).divide(Victor(m1+m2, m1+m2));
    let V2projR_new = Victor(m2-m1, m2-m1).multiply(V2projR).add(Victor(2*m1, 2*m1).multiply(V1projR)).divide(Victor(m1+m2, m1+m2));
    //const V2projR_new = ((m2-m1)*V2projR+2*m1*V1projR)/(m1+m2);

    let v1_new = V1perpR.add(V1projR_new);
    let v2_new = V2perpR.add(V2projR_new);

    this.var.V = v1_new;
    ball.var.V = v2_new;

  }

  checkCollision(ball) {
    if (!(ball instanceof Ball)) { return; }

    let n = this.var.pos.clone().subtract(ball.var.pos);
    let dist = n.length()
    if (dist >= this.const.R) { return; }

    n = n.normalize();
    dist = dist < this.const.R/2 ? this.const.R/2 : dist;
    let Fmod = 0.0001/(dist/this.const.R)**4;
    //console.log('dist, Fmod: ', dist, Fmod);
    this.var.F.add(n.clone().multiply(Victor(Fmod, Fmod)));
    ball.var.F.add(n.clone().multiply(Victor(-Fmod, -Fmod)));
  }

  getImage() {
    return this.image;
  }
}

export default Ball;
