import {Group, Image, Text} from "react-konva";
import CanvasButton from "./CanvasButton";
import Card from "./Card";
import React from "react";
import CheckMarkGif from "./CheckMarkGif";
import useImage from "use-image";
import failImg from '../../../images/failIcon.png';


const CanvasSuccessCard = (props) => {
  const {
    x=250, y=100, visible, goToTheory, goToTheoryVisible=true, onClickReset, success=true, finallyPopupFontSize=40,  finallyPopupColor='#315259',
    btnTopBg='#BCDDE0',
    btnBottomBg='#BCDDE0',
    btnTopTxtColor='#315259',
    btnBottomTxtColor='#315259',
    finallyPopupTextPos,
    mainBg,
    children
  } = props;
  const [fail] = useImage(failImg);

  return (
    <Group x={x} y={y} visible={visible}>
      <Card width={500} height={400} cornerRadius={20} fill={mainBg}>
        <Text
          y={50} width={500} align={'center'}
          text={props.title ? props.title : success ? 'Задача выполнена' : 'Задача не выполнена'}
          fill={finallyPopupColor} fontSize={finallyPopupFontSize}
          {...finallyPopupTextPos}
        />

        <Group x={230} y={130}>
          {
            success ? (
              <CheckMarkGif x={-10} y={-10} scale={{x: 1.3, y:1.3}} withImageBg={true} />
            ) : (
              <Image image={fail} width={50} height={63}/>
            )
          }
        </Group>


        <Group x={130} y={230}>
          {
            children ? (
              children
            ) : (
              <>
                <CanvasButton
                  text={'Посмотреть теорию'}
                  onClick={() => goToTheory()}
                  fontSize={20}
                  strokeWidth={.1}
                  btnCornerRadius={0}
                  btnFill={btnTopBg}
                  textFill={btnTopTxtColor}
                  width={250}
                  height={45}
                  visible={goToTheoryVisible}
                />
                <CanvasButton
                  text={'Начать заново'}
                  onClick={() => onClickReset()}
                  fontSize={20}
                  strokeWidth={.1}
                  btnCornerRadius={0}
                  btnFill={btnBottomBg}
                  textFill={btnBottomTxtColor}
                  width={250}
                  height={45}
                  y={!goToTheoryVisible ? 30 : 70}
                />
              </>
            )
          }
        </Group>
      </Card>
    </Group>
  )
}

export default CanvasSuccessCard;