
export const getLsnNameByCode = (code) => ({
  pathandspeed1: code === 'pathandspeed1',
  pathandspeed2: code === 'pathandspeed2',
  pathandspeed3: code === 'pathandspeed3',
  pathandspeed4: code === 'pathandspeed4',
  pathandspeed5: code === 'pathandspeed5',
  pathandspeed6: code === 'pathandspeed6',
  pathandspeed7: code === 'pathandspeed7',
  pathandspeed8: code === 'pathandspeed8',
  pathandspeed9: code === 'pathandspeed9',
  pathandspeed10: code === 'pathandspeed10',
  pathandspeed11: code === 'pathandspeed11',
  pathandspeed12: code === 'pathandspeed12',
  pathandspeed13: code === 'pathandspeed13',
})