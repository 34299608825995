import {
  UPDATE_INITIAL_POINTS,
  UPDATE_MAP_LESSONS, UPDATE_MAP_STATUS,
  UPDATE_MAP_TIMER,
  UPDATE_POINTS,
  UPDATE_COURSE_PAID, UPDATE_COURSE_TASKS_RANGERS
} from "../actions/mapActions";


const initialState = {
  lessons: {},
  timer: {},
  points: 0,
  initialPoints: undefined,
  coursePaid: false,
  taskRanges: {},
}

export const mapReducer = (state = initialState, {type, payload}) => {

  switch (type) {

    case UPDATE_MAP_STATUS:
      return {
        ...state,
        mapStatus: payload
      }
    case UPDATE_MAP_LESSONS:
      return {
        ...state,
        lessons: {
          ...state.lessons,
          ...payload
        }
      }
    case UPDATE_MAP_TIMER:
      return {
        ...state,
        timer: {
          ...state.timer,
          ...payload
        }
      }
    case UPDATE_POINTS:
      return {
        ...state,
        points: payload
      }
    case UPDATE_INITIAL_POINTS:
      return {
        ...state,
        initialPoints: payload
      }
    case UPDATE_COURSE_PAID:
      return {
        ...state,
        coursePaid: payload
      }

    case UPDATE_COURSE_TASKS_RANGERS:
      return {
        ...state,
        taskRanges: payload
      }

    default:
      return state;
  }
}
