import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import backgroundImg from '../../../../images/space/loss_satellite/background.png'
import {lossOfSatelliteDishDefaultPatchData, lossOfSatelliteDishScenario} from "../utils/LossOfSatelliteDishScenario";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import Video from "../../../canvas/components/Video";
import CyberpunkCard from "../../../canvas/components/CyberpunkCard";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {layout2} from "../../../../utils/styles";
import VideoBlock from "../../../canvas/components/CanvasVideoBlock";
import * as actions from "../../../../store/actions";
import Theory from "../../../components/theory/Theory";


class LossOfSatelliteDish extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',
    ]

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      inputDistanceToSatelliteDish: undefined,
      errCounter: 0,

      ...lossOfSatelliteDishDefaultPatchData
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(lossOfSatelliteDishScenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;



    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    n['stage'].draw();
  }


  Env = () => {
    const data = this.data;
    const [background] = useImage(backgroundImg);

    return (
      <React.Fragment>
        <Image image={background}/>

        <Text x={30} y={40} text={data.title} fontSize={27} lineHeight={1.2} fill={'#4D4D4D'}/>

        <Group y={400} visible={data.arrowsVisible}>
          <Group x={130}>
            <Arrow points={[0,0,-100,0]} stroke={'#4D4D4D'} fill={'#4D4D4D'} strokeWidth={5}/>
            <Text x={-100} y={30} text={'Направление \nветра'} fill={'#4D4D4D'} fontSize={20}/>
          </Group>
          <Group x={830}>
            <Arrow points={[0,0,100,0]} stroke={'#4D4D4D'} fill={'#4D4D4D'}  strokeWidth={5}/>
            <Text y={30} text={'Направление \nдвижения ровера'} fill={'#4D4D4D'} fontSize={20}/>
          </Group>
        </Group>

        <CyberpunkCard
          x={330} y={230}
          btnText={'Проверить'}
          visible={data.cyberpunkCardVisible}
          onClickBtn={() => data.cyberpunkBtnClick(this)}
        >
         <Group x={70} y={50}>
           <Text text={'Расстояние от ровера, м'} fontSize={20} fill={'white'}/>

           <CanvasInput
             withoutBorder={true}
             id={'1'}
             x={0} y={30}
             width={150} height={50}
             stage={this._getNode('stage')}
             textColor={'black'}
             onInput={(val) => {
               this.data.inputDistanceToSatelliteDish = val;
             }}
             value={this.data.inputDistanceToSatelliteDish}
           />
           <Text y={90} text={'Неверно, попробуй снова'} visible={data.failTextVisible} fontSize={16} strokeWidth={.5} stroke={layout2.red} fill={layout2.red}/>
         </Group>
        </CyberpunkCard>

      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>

            <this.Env/>

            <CanvasResetBtn
              onClick={() => this.onClickReset()}
            />

            {
              this.data.animationSrc && (
                <VideoBlock
                  src={this.data.animationSrc}
                  onVideoEnd={() => this.scenarioManager.next()}
                  scenarioManager={this.scenarioManager}
                />
              )
            }

          </Layer>
        </CanvasContainer>

        {
          this.data.theoryVisible && (
            <Theory
              data={this.props.lessonData?.theory}
              onClickSkipTheory={() => this.data.onClickSkipTheory?.(this)}
              lessonComplete={this.data.lsnComplete}
              onClickReset={this.onClickReset}
            />
          )
        }
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LossOfSatelliteDish);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
