

export const getCorrectTitle = (step) => {
  let text = "" +
    "Определи скорость локомотива в метрах в секунду.\n" +
    "Введи ответ в поле и нажми “Проверить”"
  if (step < 1){
    text = "" +
      "Определи скорость локомотива в метрах в секунду.\nНажми “Запустить поезд”, чтобы поезд выехал на мост."
  }
  return text;
}