import {Rect, Text} from "react-konva";
import React from "react";

export const borderColor = 'rgba(0, 0, 0, 0.15)';
export const blueColor = '#2972CC';
export const blueColor2 = '#2C79F6';

export const mainColor = '#3d405b';
export const mainRedColor = '#E17C63';

export const layout2 = {
  blueBg: '#d2e0ed',
  blue: '#5884CB',
  blue2: '#2C79F6',
  lightblue: 'rgba(44, 121, 246, .7)',
  // darkBlue: '#2C79F6',
  darkBlue: '#4d6f9a',
  grayBg: '#d2e0ed',
  lightOrange: '#f6be46',
  red: '#E17C63',
  orange: '#F6692C',
  yellow: '#fcee00',
  green: '#52aa6c',
  gray: '#999999',
};

export const lsnTitleTxtStyle = {
  x: 40, y: 40,
  stroke: layout2.darkBlue,
  fill: layout2.darkBlue,
  strokeWidth: .2,
  fontSize: 27,
  lineHeight: 1.3
};

export const lsnLabelTxtStyle = {
  stroke: layout2.darkBlue,
  fill: layout2.darkBlue,
  strokeWidth: .2,
  fontSize: 15,
  lineHeight: 1.3
};

export const defaultCanvasShadowStyles = {
  shadowColor: 'black',
  shadowBlur: 13,
  shadowOffsetX: 2,
  shadowOffsetY: 4,
  shadowOpacity: .2,
}

export const defaultHtmlShadowStyles = {
  boxShadow: '0px 4px 10px rgba(0,0,0,0.15)'
}