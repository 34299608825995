import React, {useState} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image as KonvaImage, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getRandomInt, getTimeDeltaSec} from "../../../../utils/common";
import skyImg from '../../../../images/bus/sky.png';
import sunImg from '../../../../images/bus/sun.png';
import treeImg from '../../../../images/bus/tree.png';
import tree2Img from '../../../../images/bus/tree2.png';
import busImg from '../../../../images/bus/bus.png';
import busBgImg from '../../../../images/bus/busBg.png';
import wheelImg from '../../../../images/bus/wheel.png';
import human1Img from '../../../../images/bus/human1.png';
import human2Img from '../../../../images/bus/human2.png';
import human3Img from '../../../../images/bus/human3.png';
import human4Img from '../../../../images/bus/human4.png';
import human5Img from '../../../../images/bus/human5.png';
import human6Img from '../../../../images/bus/human6.png';
import human7Img from '../../../../images/bus/human7.png';
import human8Img from '../../../../images/bus/human8.png';
import human9Img from '../../../../images/bus/human9.png';
import indicatorImg from '../../../../images/bus/indicator.png';
import indicatorLineImg from '../../../../images/bus/indicatorLine.png';
import tunnelImg from '../../../../images/bus/tunnel.png';
import smokeGif from '../../../../images/bus/smoke.gif';
import stopImg from '../../../../images/bus/stop.png';
import gasImg from '../../../../images/bus/gas.png';

import darkBusImg from '../../../../images/bus/darkBus.png';
import lightImg from '../../../../images/bus/light.png';
import stopLightImg from '../../../../images/bus/stopLight.png';
import windowLightImg from '../../../../images/bus/windowLight.png';

import dialogGreenImg from '../../../../images/bus/dialogGreen.png';
import dialogRedImg from '../../../../images/bus/dialogRed.png';

import {defaultPatchData, getScenario} from "../scenario";
import CurvedArrow from "../../../canvas/components/CurvedArrow";
import {GIF} from "../../../canvas/components/GIF";
import {enslow} from "../../../../utils/physics";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CanvasSuccessCard from "../../../canvas/components/CanvasSuccessCard";
import * as actions from "../../../../store/actions";
import plusImage from "../../../../images/electrostatic/plus.png";
import minusImage from "../../../../images/electrostatic/minus.png";




class Bus extends React.Component {
  constructor(props) {
    super(props);

    this.houseItemsCount = 100;
    this.treeItemsCount = 100;
    this.houseImgItems = [];
    this.treeImgItems = [];

    this.managedComponents = [
      'stage',

      'human1',
      'human2',
      'human3',
      'human4',
      'human5',
      'human6',
      'human7',
      'human9',
      'human8',

      'wheelSmoke',
      'indicatorLine',

      'bus',
      'wheel1',
      'wheel2',

      'treesLayer',
      'roadLayer',
      'housesLayer',
      'tunnelLayer',

      'dialogBgImage',
      'dialogTxt',
    ]


    this.staticData = {
      maxSpeed: 140,
      maxIndicatorAngle: 260,
    }

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      pedalGas: false,
      pedalStop: false,

      testError: 0,

      busSpeed: 0,
      humanDeg: 0,
      indicatoDeg: 0,

      ...defaultPatchData,
    };
    this.data = cloneDeep(this.initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  get humanDeg() {
    const data = this.data;
    let res = data.humanDeg;
    if (data.pedalStop && data.busSpeed > 5) {
      res = res >= 15 ? 15 : res+(60*data.timedeltaSec);
    }
    if (data.pedalGas) {
      res = res <= -15 ? -15 : res-(60*data.timedeltaSec);
    }
    if (!data.pedalStop && !data.pedalGas) {
      res = enslow(res, 60, data.timedeltaSec);
    }
    return res;
  }

  get indicatoDeg() {
    const data = this.data;
    const maxIndicatorDeg = this.staticData.maxIndicatorAngle;
    const maxSpeed = this.staticData.maxSpeed;
    const speedPerc = data.busSpeed / maxSpeed * 100;
    return maxIndicatorDeg/100 * speedPerc;
  }

  get busSpeed() {
    const data = this.data;
    let res = data.busSpeed;
    if (data.pedalStop && !data.tunnelStep) {
      res = res <= 0 ? 0 : res-(15*data.timedeltaSec);
    }
    if (data.pedalGas && !data.tunnelStep) {
      res = res >= 140 ? 140 : res+(4*data.timedeltaSec);
    }
    // if (!data.pedalGas && !data.pedalStop && !data.tunnelStep) {
    //   res = res <= 0 ? 0 : res-(1*data.timedeltaSec);
    // }
    if (data.tunnelStepInitial) {
      res = data.busSpeed;
    }
    return res;
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();

  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
    this.generateRandomParameters();
  }

  generateRandomParameters = () => {
    Array(this.houseItemsCount).fill(1).map((el, i) => {
      const indx = getRandomInt(13) + 1;
      this.houseImgItems.push(indx);
    });
    Array(this.treeItemsCount).fill(1).map((el, i) => {
      const treeNum = getRandomInt(2)+1;
      this.treeImgItems.push(treeNum);
    });
  }

  onClickReset = () => {
    this._getNode('roadLayer').x(0)
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);


    data.indicatoDeg = this.indicatoDeg;
    data.humanDeg = this.humanDeg;
    data.busSpeed = this.busSpeed;

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    let globalHouseX = 0;
    Array(this.houseItemsCount).fill(1).map((el, i) => {
      const houseNode = this._getNode('house' + (i + 1));
      let y = houseNode ? 110 - houseNode.height() : 0;
      houseNode.position({x: globalHouseX, y: y});
      globalHouseX += houseNode.width()+100;
    });

    n['wheelSmoke'].visible(data.busSpeed >= 10 && data.pedalStop && !data.tunnelStep);

    n['human2'].rotation(data.humanDeg);
    n['human3'].rotation(data.humanDeg);
    n['human4'].rotation(data.humanDeg);
    n['human5'].rotation(data.humanDeg);
    n['human6'].rotation(data.humanDeg);
    n['human7'].rotation(data.humanDeg);
    n['human9'].rotation(data.humanDeg);
    n['human8'].rotation(data.humanDeg);



    const notInert = !data.pedalGas && !data.pedalStop;
    let img = new Image();
    img.src = notInert ? dialogGreenImg : dialogRedImg

    n['dialogBgImage'].image(img);
    n['dialogTxt'].text(notInert ? 'Инерциальная \nсистема отсчета' : 'Неинерциальная \nсистема отсчета');

    n['indicatorLine'].rotation(data.indicatoDeg);
    const wheelRotationSpeed = data.pedalStop && data.busSpeed !== 0 && !data.tunnelStep ? 0 : (data.busSpeed/4);
    n['wheel1'].rotate(wheelRotationSpeed);
    n['wheel2'].rotate(wheelRotationSpeed);

    let newRoadX = n['roadLayer'].x() - (data.busSpeed/4);
    if (newRoadX <= -20000) {
      newRoadX = 0;
    }
    if (data.tunnelStepInitial) {
      n['bus'].x(n['bus'].x()+11);
      if (n['bus'].x() > 1100) {
        this.scenarioManager.success('tunnel step initial');
      }
    } else {
      n['treesLayer'].x(newRoadX);
      n['roadLayer'].x(newRoadX);
      n['housesLayer'].x(newRoadX / 3);
    }

    if (data.tunnelStep) {
      n['bus'].setAttrs({ x: 80, y: 273 });
    }

    n['stage'].draw();
  }


  Env = () => {

    const [sky] = useImage(skyImg);
    const [sun] = useImage(sunImg);

    const maxHouseImgs = 13;
    const houseImgs = {};
    Array(maxHouseImgs).fill(1).forEach((num, i) => {
      const [house] = useImage(require(`../../../../images/bus/house${i+1}.png`));
      houseImgs[`house${i+1}`] = house;
    });
    const width = 25000;
    return (
      <Group>
        <KonvaImage image={sky}/>
        <KonvaImage x={900} y={30} image={sun}/>

        <Text x={50} y={30} text={this.data.title} fontSize={30} lineHeight={1.2} stroke={'#125071'} strokeWidth={.5} fill={'#125071'}/>

        <Group y={300} ref={this._ref('roadLayer')}>

          {/* Газон */}
          <Rect width={width} height={68} fill={'#6E8046'}/>
          {/* Тротуар */}
          <Rect y={186} width={width} height={76} fill={'#EDE1DE'}/>

          {/* Дорога */}
          <Group y={48}>
            <Rect width={width} height={138} fill={'#C9B7BD'}/>
            <Group y={60}>
              {
                Array(200).fill(1).map((el, i) => (
                  <Rect key={'line'+i} x={i*200+50} width={80} height={8} fill={'whitesmoke'} opacity={.7}/>
                ))
              }
            </Group>
          </Group>
        </Group>


        <Group y={245} scale={{x: .6, y: .6}} ref={this._ref('housesLayer')}>
          {
            this.houseImgItems.map((houseImgId, i) => (
              <KonvaImage key={'house'+i} x={i*250} y={0} image={houseImgs[`house${houseImgId}`]} ref={this._ref('house'+(i+1))}/>
            ))
          }
        </Group>
      </Group>
    )
  };
  Bus = (props) => {
    const data = this.data;
    const [bus] = useImage(busImg);
    const [busBg] = useImage(busBgImg);
    const [wheel] = useImage(wheelImg);
    const [human1] = useImage(human1Img);
    const [human2] = useImage(human2Img);
    const [human3] = useImage(human3Img);
    const [human4] = useImage(human4Img);
    const [human5] = useImage(human5Img);
    const [human6] = useImage(human6Img);
    const [human7] = useImage(human7Img);
    const [human8] = useImage(human8Img);
    const [human9] = useImage(human9Img);

    const [darkBus] = useImage(darkBusImg);
    const [light] = useImage(lightImg);
    const [stopLight] = useImage(stopLightImg);
    const [windowLight] = useImage(windowLightImg);

    const [dialogGreen] = useImage(dialogGreenImg);
    const [dialogRed] = useImage(dialogRedImg);

    return (
      <Group x={20} y={280} ref={this._ref('bus')}>
        <Group {...props}>
          <KonvaImage x={20} y={20} image={busBg}/>
          <Group x={20} y={45}>
            <KonvaImage x={450} y={10} image={human1} ref={this._ref('human1')}/>
            <KonvaImage x={358+20} y={45} image={human2} ref={this._ref('human2')} offset={{x: 20, y: 45}}/>
            <KonvaImage x={300+20} y={45} image={human3} ref={this._ref('human3')} offset={{x: 20, y: 45}}/>
            <KonvaImage x={260+20} y={45} image={human4} ref={this._ref('human4')} offset={{x: 20, y: 45}}/>
            <KonvaImage x={195+20} y={45} image={human5} ref={this._ref('human5')} offset={{x: 20, y: 45}}/>
            <KonvaImage x={175+20} y={45} image={human6} ref={this._ref('human6')} offset={{x: 20, y: 45}}/>
            <KonvaImage x={110+20} y={45} image={human7} ref={this._ref('human7')} offset={{x: 20, y: 45}}/>
            <KonvaImage x={40} y={40} image={human9} ref={this._ref('human9')} offset={{x: 20, y: 45}}/>
            <KonvaImage x={60} y={50} image={human8} ref={this._ref('human8')} offset={{x: 20, y: 45}}/>
          </Group>
          <Rect x={20} y={20} width={512} height={77} fill={'#84EDF6'} opacity={.4}/>

          <Group x={400} y={-110} visible={data.withInertHint}>
            <KonvaImage image={dialogGreen} ref={this._ref('dialogBgImage')}/>
            <Text x={30} y={20} text={'Инерциальная \nсистема отсчета'} fill={'white'} fontSize={25}  ref={this._ref('dialogTxt')}/>
          </Group>

          <KonvaImage image={bus}/>

          <Group y={120}>
            <Group x={55} y={87} ref={this._ref('wheelSmoke')}>
              <GIF rotation={-70} src={smokeGif} x={5} scale={.2} loop={true}/>
              <GIF rotation={-70} src={smokeGif} x={295} scale={.2} loop={true}/>
            </Group>
            <KonvaImage x={135} y={35} offset={{x: 34, y: 35}} image={wheel} ref={this._ref('wheel1')}/>
            <KonvaImage x={422} y={35} offset={{x: 34, y: 35}} image={wheel} ref={this._ref('wheel2')}/>
          </Group>
        </Group>
        <Group visible={data.tunnelStep}>
          <KonvaImage image={darkBus} x={-2} y={-1.5}/>
          <KonvaImage image={light} x={838} y={185}/>
          <KonvaImage image={stopLight} x={-77} y={125}/>
          <KonvaImage image={windowLight} x={20} y={-95}/>
        </Group>
      </Group>
    )
  }
  TunnelRock = () => {
    const [tunnel] = useImage(tunnelImg);
    return (
      <Group x={850} visible={this.data.tunnelStepInitial} ref={this._ref('tunnelLayer')}>
        <KonvaImage image={tunnel}/>
      </Group>
    )
  }
  TunnelTest = () => {
    const data = this.data;
    const TestBtn = ({y, btnText, onClick, text, success}) => {
      const [hover, setHover] = useState(false);
      let rectStg = { fill: '#66AEDE' };
      if ((success && data.selectedBtn === btnText) || (success !== undefined && btnText === data.successTestItem)) {
        rectStg = { fill: '#7ade74', }
      }
      if ((success !== undefined) && ((data.selectedBtn === btnText) && !success)) {
        rectStg = { fill: 'white', stroke: 'red' }
      }
      return (
        <Group y={y}>
          <Rect width={30} height={30} {...rectStg}/>
          <Text x={8} y={6} text={btnText} fill={rectStg.stroke || 'white'} fontSize={20}/>
          <Text x={50} y={4} text={text} fill={'white'} fontSize={25}/>
          <Rect
            onClick={onClick}
            onTap={onClick}
            onMouseEnter={() => { setHover(data.btnsTestActive)}}
            onMouseLeave={() => { setHover(false)}}
            x={-10} y={-10} width={550} height={50} fill={hover ? 'white' : 'transparent'} opacity={0.3}/>
        </Group>
      )
    }
    const onClickBtn = (selectedBtn) => {
      const success = selectedBtn === data.successTestItem;
      data.selectedBtn = selectedBtn;
      if (!success && data.btnsTestActive) {
        data.testError += 1;
        if (data.testError >= 2) {
          this.onClickReset();
        }
      }
      ['tunnel test 1', 'tunnel test 2', 'tunnel test 3'].map((k) => {
        if (success) {
          this.scenarioManager.success(k);
        } else {
          this.scenarioManager.failure(k);
        }
      });
    }
    return (
      <Group x={50} visible={data.btnsTestVisible}>
        <Text y={30} text={data.testTitle} fill={'white'} fontSize={27} lineHeight={1.2}/>
        <Group y={20}>
          <TestBtn
            y={60} btnText={'А'}
            onClick={() => {
              onClickBtn('А');
            }}
            success={data.selectedBtn ? data.selectedBtn === data.successTestItem : undefined}
            text={'Ускоряется'}
          />
          <TestBtn
            y={110} btnText={'Б'}
            onClick={() => {
              onClickBtn('Б');
            }}
            success={data.selectedBtn ? data.selectedBtn === data.successTestItem : undefined}
            text={'Тормозит'}
          />
          <TestBtn
            y={160} btnText={'В'}
            onClick={() => {
              onClickBtn('В');
            }}
            success={data.selectedBtn ? data.selectedBtn === data.successTestItem : undefined}
            text={'Едет с постоянной скоростью'}
          />
        </Group>
      </Group>
    )
  }
  TunnelStep = () => {
    const data = this.data;
    return (
      <Group visible={data.tunnelStep}>
        <Rect width={1000} height={562} fill={'rgb(30,30,30)'}/>
        <Text x={50} y={30} text={data.title} fill={'white'} fontSize={30} lineHeight={1.2}/>

        <CanvasButton
          x={410} y={130}
          text={'Начать'}
          onClick={() => {
            this.scenarioManager.selectStepByKey('tunnel test 1');
          }}
          fontSize={20}
          strokeWidth={.1}
          btnCornerRadius={0}
          btnFill={'#EBFAFD'}
          textFill={'rgb(30,30,30)'}
          width={200}
          height={45}
          visible={data.btnStartVisible}
        />
      </Group>
    )
  }
  IndicatorAndPedals = () => {
    const data = this.data;
    const [indicator] = useImage(indicatorImg);
    const [indicatorLine] = useImage(indicatorLineImg);
    const [stop] = useImage(stopImg);
    const [gas] = useImage(gasImg);

    const Pedal = ({pedalImg, x, y, onMouseDown, onMouseUp}) => {
      const [mouseDown, setMouseDown] = useState(false);

      return (
        <Group
          onMouseDown={() => { setMouseDown(true); onMouseDown(); }}
          onMouseUp={() => { setMouseDown(false); onMouseUp(); }}
          onTouchStart={() => { setMouseDown(true); onMouseDown(); }}
          onTouchEnd={() => { setMouseDown(false); onMouseUp(); }}
          opacity={mouseDown ? .85 : 1}
        >
          <KonvaImage x={x} y={y} image={pedalImg}/>
        </Group>
      )
    }

    const mouseDown = (dataKey, dataVal) => {
      if (((dataKey === 'pedalStop') && data.pedalHintStopVisible) || ((dataKey === 'pedalGas') && data.pedalHintGasVisible)) {
        ['step 1', 'step 3'].map((k) => this.scenarioManager.success(k));
      }
      this.data[dataKey] = dataVal;
    }
    return (
      <Group x={30} y={370}>
        <Group>
          <KonvaImage image={indicator}/>
          <Group rotation={-127} x={90} y={85}>
            <KonvaImage rotation={0} image={indicatorLine} width={21} height={78} offset={{x: 10.5, y: 70}} ref={this._ref('indicatorLine')}/>
          </Group>
        </Group>

        <Group x={550} visible={!this.data.tunnelStepInitial}>
          <Pedal
            x={0} y={0}
            pedalImg={stop}
            onMouseDown={() => mouseDown('pedalStop', true)}
            onMouseUp={() => mouseDown('pedalStop', false)}
          />
          <Pedal
            x={150} y={-95}
            pedalImg={gas}
            onMouseDown={() => mouseDown('pedalGas', true)}
            onMouseUp={() => mouseDown('pedalGas', false)}
          />

          <Group>
            <Group x={180} y={0} visible={data.pedalHintGasVisible}>
              <CurvedArrow rotation={-70} x={-10} y={25} scale={{x:.7, y:.7}}/>
              <Text text={'Газ'} y={-45} fontSize={20}/>
            </Group>
            <Group y={30} visible={data.pedalHintStopVisible}>
              <CurvedArrow rotation={-70} x={-10} y={25} scale={{x:.7, y:.7}}/>
              <Text text={'Тормоз'} y={-45} fontSize={20}/>
            </Group>
          </Group>
        </Group>
      </Group>
    )
  }
  Trees = () => {
    const [tree] = useImage(treeImg);
    const [tree2] = useImage(tree2Img);
    const treeImgs = { tree1: tree, tree2: tree2 };
    return (
      <Group y={400} ref={this._ref('treesLayer')}>
        {
          this.treeImgItems.map((treeImgId, i) => {
            const img = treeImgs['tree'+treeImgId];
            return (
              <KonvaImage x={i*750+300} image={img}/>
            )
          })
        }
      </Group>
    )
  }

  render() {
    const data = this.data;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this._ref('stage')}
          lessonCode={this.props.code}
        >
          <Layer preventDefault={false}>
            <this.Env/>
            {
              !data.tunnelStep && (
                <this.Bus/>
              )
            }

            <this.TunnelRock/>
            <this.Trees />
            <this.IndicatorAndPedals/>
            <this.TunnelStep/>
            {
              data.tunnelStep && (
                <this.Bus scale={{x: 1.54, y: 1.54}}/>
              )
            }
            <this.TunnelTest/>

            {
              data.successPopupVisible && (
                <CanvasSuccessCard
                  goToTheoryVisible={false}
                  onClickReset={() => this.onClickReset()}
                />
              )
            }
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Bus);

const styles = {
  mainContainer: {
    background: '#E9F9FD',
    // background: 'red',
  }
};
