import React, {useEffect} from "react";
import TextProblemTasksList from "../../testProblems/containers/TextProblemTasksList";


const AllComplete = (props) => {

  useEffect(() => {
    if (props.textProblems) {
      const allSuccess = props.textProblems.every(task => task.answers.every(item => item.is_correct));
      if (!allSuccess) {
        props.failResult();
      } else {
        props.successResult();
      }
    }
  }, []);

  return (
    <>
      <TextProblemTasksList variantId={props.variantId} textProblems={props.textProblems}/>
      <div style={{margin: '0px 0', display: 'flex', justifyContent: 'center'}}>
        {/*<Button size={'large'} type={'primary'} onClick={() => this.props.repeatTextProblem()}>*/}
        {/*  Пройти заново*/}
        {/*</Button>*/}
      </div>
    </>
  )
}

export default AllComplete;
