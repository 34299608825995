import {Spin, Tooltip} from "antd";
import React from "react";
import '../styles/sectionWrapper.css';
import {connect} from "react-redux";
import {InfoCircleOutlined} from "@ant-design/icons";


const SectionWrapper = (props) => {

  return (
    <div className={`news-section-container ${props.isDarkMode && 'news-section-container--dark-mode'}`}>
      <div className={'news-section-container__head'}>
        <h1 className={'news-section-container__title'}>{props.title}</h1>
        {
          props.infoText ? (
            <Tooltip placement="top" title={props.infoText}>
              <InfoCircleOutlined className={'news-section-container__info-icon'} style={{color: 'white'}}/>
            </Tooltip>
          ) : null
        }
        <Spin spinning={props.loading} style={{margin: '-10px 20px 0'}} />
      </div>

      <div className={props.contentClassName || ''}>
        {props.children}
      </div>
    </div>
  )
}




const mapStateToProps = state => ({
  isDarkMode: state.commonReducer.isDarkMode,
});
const mapDispatchToProps = (dispatch, {match}) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SectionWrapper);
