import { Image } from "react-konva";
import React from "react";

export const CanvasCannon = ({
  cannonImage,
  rotation
}) => {
  const width = 120;
  const height = 60;

  return(
    <Image
      image={cannonImage}
      width={width}
      height={height}
      offsetX={width/2}
      offsetY={height/2}
      rotation={rotation}
    />
  )
};
