import React from "react";
import {useHistory} from "react-router-dom";
import {getCodeUrlByTaskCode} from "../../layout2/utils";
import completeImg from '../images/completeIcon.png';
import notCompleteImg from '../images/notCompleteIcon.png';


const CatFinalTestMapElement = (props) => {
  const history = useHistory();

  const taskCode = getCodeUrlByTaskCode(props.lessonCode);
  const complete = props.complete;
  const failure = props.failure;
  const wasOpened = props.wasOpened;
  const disabled = props.disabled && !props.start;
  const disabledClick = disabled || complete || failure;

  let img = '';
  try {
    img = require(`../images/${props.lessonCode}/${props.lessonCode}.png`);
  } catch (error) {}

  return (
    <div
      id={props.lessonCode}
      aria-disabled={disabledClick}
      className={`cat-final-test__item`}
      onClick={(e) => {
        if (!disabledClick) {
          history.push(`${history.location.pathname}ru/${taskCode}`);
          props.setTaskModalVisible(true);
        }
      }}
    >
      <div style={{position: "relative"}}>
        <img className={'cat-final-test__item-img'} src={img} alt="" />

        {
          complete ? (
            <img className={'cat-final-test__item-img--status'} src={completeImg} alt="" />
          ) : (
            <>
              {
                failure ? (
                  <img className={'cat-final-test__item-img--status'} src={notCompleteImg} alt="" />
                ) : null
              }
            </>
          )
        }
      </div>

      <p className={'cat-final-test__item-title'}>{props.title}</p>

      {
        disabled ? (
          <div className={'cat-final-test__placeholder'} />
        ) : null
      }
    </div>
  )
}

export default CatFinalTestMapElement;
