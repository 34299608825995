import React from "react";
import { Layer, Rect, Image, Group } from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import cloneDeep from "lodash.clonedeep";
import useImage from "use-image";
import ballImage from "../../../../images/electrostatic/ball.png";
import Victor from 'victor';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {sendSuccessForScenario} from "../../../../utils/common";


class Electrostatic1 extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.staticData = {
      ballSize: 30
    };
    this.state = {};
    this.initialData = {
      leftDragMove: false,
      rightDragMove: false,

      startTime: undefined,
      prevTime: undefined,

      prevMoveEvt: null,
      timeMoveStart: 0,
      timeMove: 0,

      fraction: 1,

      leftBallSpeed: Victor(0,0),
      rightBallSpeed: Victor(0,0),

      leftBallPos: Victor(300,300),
      rightBallPos: Victor(600,300),

      leftBallReflected: false,
      rightBallReflected: false,

      K: 1000,
      e: 20,
      m: 1,

    };
    this.data = cloneDeep(this.initialData);

    this.ballLeftRef = React.createRef();
    this.ballRightRef = React.createRef();
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  };

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  enslow (value, delta, timedeltaSec) {
    let absValue = Math.abs(value);
    let signValue = Math.sign(value);

    absValue -= delta * timedeltaSec;
    if (absValue < 0) absValue = 0;

    return signValue * absValue
  }

  calculateRepulsion()
  {
    let lbP = this.data.leftBallPos.clone();
    let rbP = this.data.rightBallPos.clone();
    let {K, m, e} = this.data;
    let r = lbP.subtract(rbP);
    let dist = r.length();
    let way = r.clone().norm();
    let force = K*e**2/dist;
    let a = force/m;

    //console.log("debug repulsion: ", r, way, dist, a);

    return [a, way];
  }

  calculateReflection()
  {
    let canvWidth = this.stageRef.current.attrs.width;
    let canvHeight = this.stageRef.current.attrs.height;

    let data = this.data;

    let [lx, ly] = [data.leftBallPos.x, data.leftBallPos.y];
    let [rx, ry] = [data.rightBallPos.x, data.rightBallPos.y];

    if (!data.leftBallReflected) {
      if (lx <= 0 || lx >= canvWidth) {
        data.leftBallSpeed.x *= -1;
        data.leftBallReflected = true;
      }
      if (ly <= 0 || ly >= canvHeight) {
        data.leftBallSpeed.y *= -1;
        data.leftBallReflected = true;
      }
    }
    else {
      if (lx >= 0 || lx <= canvWidth) {
        data.leftBallReflected = false;
      }
      if (ly >= 0 || ly <= canvHeight) {
        data.leftBallReflected = false;
      }
    }

    if (!data.rightBallReflected) {
      if (rx <= 0 || rx >= canvWidth) {
        data.rightBallSpeed.x *= -1;
        data.rightBallReflected = true;
      }
      if (ry <= 0 || ry >= canvHeight) {
        data.rightBallSpeed.y *= -1;
        data.rightBallReflected = true;
      }
    }
    else {
      if (rx >= 0 || rx <= canvWidth) {
        data.rightBallReflected = false;
      }
      if (ry >= 0 || ry <= canvHeight) {
        data.rightBallReflected = false;
      }
    }
    console.log("Debug reflection: ", data.leftBallReflected, data.rightBallReflected)
    //console.log('Debug on stageRef: ', this.stageRef.current.attrs);
    //console.log('Debug on canv width and height: ', canvWidth, canvHeight);

  }

  move = (time) => {
    let data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let stageNode = this.stageRef?.current;
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    let [a, way] = this.calculateRepulsion();
    let deltaSpeed = way.clone().multiply(Victor(a*timedeltaSec, a*timedeltaSec));

    data.leftBallSpeed.add(deltaSpeed);
    data.rightBallSpeed.add(deltaSpeed.clone().multiply(Victor(-1, -1)));

    // сила трения
    ['x', 'y'].map(coord => {
      ['left', 'right'].map(side => {
        data[`${side}BallSpeed`][coord] = this.enslow(data[`${side}BallSpeed`][coord], 200, timedeltaSec);
      });
    });

    this.calculateReflection();

    data.leftBallPos.add(Victor(data.leftBallSpeed.x*timedeltaSec, data.leftBallSpeed.y*timedeltaSec));
    data.rightBallPos.add(Victor(data.rightBallSpeed.x*timedeltaSec, data.rightBallSpeed.y*timedeltaSec));

    console.log('Debug on speed: ', a, way);

    this.updateStage()
  };

  updateStage() {
    const data = this.data;
    let stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const ballLeftNode = this.ballLeftRef?.current;
    const ballRightNode = this.ballRightRef?.current;

    const posLeft = ballLeftNode.getAbsolutePosition();
    const posRight = ballRightNode.getAbsolutePosition();


    if (this.hasIntersections(posLeft, posRight)) {
      console.log('Intersections!');

      let tmpSpeed1 = data.leftBallSpeed;
      data.leftBallSpeed = data.rightBallSpeed;
      data.rightBallSpeed = tmpSpeed1;
    }


    if (!data.leftDragMove) {
      ballLeftNode.position(data.leftBallPos);
    }
    if (!data.rightDragMove) {
      ballRightNode.position(data.rightBallPos);
    }

    stageNode.draw();
  };

  hasIntersections = (leftBall, rightBall) => {
    const ballSize = this.staticData.ballSize;
    const rLeft = ballSize/2;
    const rRight = ballSize/2;
    let dx = rightBall.x - leftBall.x;
    let dy = rightBall.y - leftBall.y;
    const d = Math.sqrt(dx * dx + dy * dy);
    const x = (d * d - rRight * rRight + rLeft * rLeft) / (2 * d);
    const y = Math.sqrt(rLeft * rLeft - x * x);
    dx /= d;
    dy /= d;
    return Boolean(
  leftBall.x + dx * x - dy * y ||
        leftBall.y + dy * x + dx * y ||
        leftBall.x + dx * x + dy * y ||
        leftBall.y + dy * x - dx * y);
  };

  onDragStart = (e, ballCode) => {
    const data = this.data;
    const isLeftBall = ballCode === 'leftBall';
    const isRightBall = ballCode === 'rightBall';

    if (isLeftBall) { data.leftDragMove = true }
    if (isRightBall) { data.rightDragMove = true }

    data.timeMoveStart = e.evt.timeStamp;
    data.prevMoveEvt = e;
  };
  onDragMove = (e, ballCode) => {
    const data = this.data;
    const currentEvent = e.evt;
    const prevEvent = data.prevMoveEvt.evt;
    const isLeftBall = ballCode === 'leftBall';
    const isRightBall = ballCode === 'rightBall';

    data.timeMove = currentEvent.timeStamp;
    const timedelta = data.timeMove - data.timeMoveStart;
    const timedeltaSec = timedelta/1000;

    if (isLeftBall) { data.leftDragMove = true }
    if (isRightBall) { data.rightDragMove = true }

    const movementX=currentEvent.layerX-prevEvent.layerX;
    const movementY=currentEvent.layerY-prevEvent.layerY;
    const movement=Math.sqrt(Math.abs(movementX*movementX+movementY*movementY));

    const speed=10*movement;//current speed

    data[`${ballCode}Pos`] = Victor(currentEvent.layerX, currentEvent.layerY);
    data[`${ballCode}Speed`] = Victor(10*movementX, 10*movementY);

    data.prevMoveEvt = e;
  };
  onDragEnd = (e, ballCode) => {
    const data = this.data;
    const isLeftBall = ballCode === 'leftBall';
    const isRightBall = ballCode === 'rightBall';

    let shift = this.staticData.ballSize/2;

    if (isLeftBall) {
      data.leftBallPos.add(Victor(-shift, -shift));
    }
    else {
      data.rightBallPos.add(Victor(-shift, -shift));
    }

    if (isLeftBall) { data.leftDragMove = false }
    if (isRightBall) { data.rightDragMove = false }
  };


  Scene = () => {
    const [ball] = useImage(ballImage);

    return (
      <React.Fragment>
        <Layer>
          <Group
            draggable={true}
            onMouseDown={() => this.data.leftDragMove = true}
            onTouchStart={() => this.data.leftDragMove = true}
            onDragStart={(e) => {this.onDragStart(e, 'leftBall')}}
            onDragMove={(e) => {this.onDragMove(e, 'leftBall')}}
            onDragEnd={(e) => {this.onDragEnd(e, 'leftBall')}}
            ref={this.ballLeftRef}
            x={500} y={300}>
            <Image
              image={ball}
              width={this.staticData.ballSize}
              height={this.staticData.ballSize}
            />
          </Group>

          <Group
            draggable={true}
            onMouseDown={() => this.data.rightDragMove = true}
            onTouchStart={() => this.data.rightDragMove = true}
            onDragStart={(e) => {this.onDragStart(e, 'rightBall')}}
            onDragMove={(e) => {this.onDragMove(e, 'rightBall')}}
            onDragEnd={(e) => {this.onDragEnd(e, 'rightBall')}}
            ref={this.ballRightRef}
            x={600} y={300}>
            <Image
              image={ball}
              width={this.staticData.ballSize}
              height={this.staticData.ballSize}
            />
          </Group>
        </Layer>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
          <this.Scene/>
        </CanvasContainer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Electrostatic1);

const styles = {
  mainContainer: {
    background: '#f4f1de',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }
}
