import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import kolbaImg from '../../../../images/ICE/kolba2.png';
import valveImg from '../../../../images/ICE/valve.png';
import valveCamImg from '../../../../images/ICE/valveCam.png';
import springImg from '../../../../images/ICE/spring.png';
import pistonImg from '../../../../images/ICE/piston.png';
import sparkPlugImg from '../../../../images/ICE/sparkPlug.png';
import {connect} from "react-redux";
import fuelImg from "../../../../images/ICE/fuel.png";
import Explosion from "../components/Explosion";
import {
  adiabaticVolumeChange, burn,
  gasAmount,
  initialData,
  inputValveWork, meterPerPixel,
  pistonSize,
  pressureStatic,
  volume
} from "../utils/icePhysics";
import Indicator from "../components/Indicator";
import {getBoolByLsnCode} from "../utils/iceCommon";
import {_t} from '../../../../utils/lang/common';


class Step3 extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();
    this.valveRef = React.createRef();
    this.valveCamRef = React.createRef();
    this.sparkPlugRef = React.createRef();
    this.fuelRef = React.createRef();
    this.gasRef = React.createRef();
    this.pistonRef = React.createRef();
    this.springRef = React.createRef();

    this.staticData = {};
    this.state = {
      pressPiston: false
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      // PISTON
      pistonOffsetY: 0,
      springHeight: 182,

      // VALVE
      valveOffsetY: 0,
      valveCamAngle: 0,

      physics: initialData(),

      // ACTIONS
      fuelInjection: false,
      sparkPlugActive: false,
    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    this.requestId = window.requestAnimationFrame(this.move);
  }

  componentWillUnmount() {
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  getAllNodes = () => ({
    valveNode: this.valveRef?.current,
    valveCamNode: this.valveCamRef?.current,
    sparkPlugNode: this.sparkPlugRef?.current,
    fuelNode: this.fuelRef?.current,
    gasNode: this.gasRef?.current,
    pistonNode: this.pistonRef?.current,
    springNode: this.springRef?.current,
  });

  /**
   * -------------------------- GETTERS --------------------------
   */
  get cylinderContentsHeight() {
    const data = this.data;
    const pistonHeadNode = this.pistonRef?.current;
    const pistonHeight = pistonHeadNode.getHeight();
    const pistonOffsetY = data.pistonOffsetY;
    return pistonOffsetY + pistonHeight/3;
  }
  get springHeight() {
    const data = this.data;
    return 182 - data.pistonOffsetY;
  }

  updatePistonOffsetY = (timedeltaSec) => {
    const data = this.data;

    const pressureForce = data.physics.internal.pressure * pistonSize;

    const stiffness = 5000;
    const springForce = data.pistonOffsetY * meterPerPixel * stiffness;

    if (this.state.pressPiston) {
      data.pistonOffsetY += 20 * timedeltaSec;
    } else {
      data.pistonOffsetY += (pressureForce - springForce)*timedeltaSec;
    }

    if (data.pistonOffsetY > 100) {
      data.pistonOffsetY = 100;
      return;
    }
    if (data.pistonOffsetY < 0) {
      data.pistonOffsetY = 0;
      return;
    }
  };

  rotateValveCam = () => {
    const data = this.data;
    if (data.fuelInjection) {
      data.valveCamAngle += 2;
    }
  }

  updateValveOffsetY = () => {
    const data = this.data;
    const valveCam = Math.abs(Math.round(data.valveCamAngle)) % 360;
    if (valveCam <= 45)
      data.valveOffsetY = valveCam/5;
    if (valveCam > 45 && valveCam <= 90)
      data.valveOffsetY = 18 - valveCam/5;
  }

  get explosion() { return this.data.sparkPlugActive  && (this.data.fuelLevel > 0)}


  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    this.data.physics.internal.volume = volume(data.pistonOffsetY);

    // Расчет состояния газа из внешних предпосылок
    this.data.physics.internal.pressure = pressureStatic(
      gasAmount(this.data.physics.internal.consistency),
      this.data.physics.internal.temperatureKelvin,
      this.data.physics.internal.volume
    );

    // Расчет влияния газа на поршень
    // PISTON
    let cachedVolume = this.data.physics.internal.volume;
    this.updatePistonOffsetY(timedeltaSec);
    this.data.physics.internal.volume = volume(data.pistonOffsetY);
    let newVolume = this.data.physics.internal.volume;

    adiabaticVolumeChange(data.physics, cachedVolume, newVolume);

    data.springHeight = this.springHeight;

    // расчет иных процессов
    // // VALVE
    inputValveWork(data.valveOffsetY, data.physics, timedeltaSec);
    // console.log(
    //   'debug on gas',
    //   this.data.physics.internal.pressure,
    //   this.data.physics.internal.volume,
    //   this.data.physics.internal.consistency,
    // );
    this.rotateValveCam();
    this.updateValveOffsetY();


    if (data.sparkPlugActive) {
       burn(data.physics) // changes inplace
    }

    data.cylinderContentsHeight = this.cylinderContentsHeight;

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const consistency = data.physics.internal.consistency;
    const timedelta = data.prevTime ? data.startTime - data.prevTime : 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const nodes = this.getAllNodes();


    // PISTON
    nodes.pistonNode.offsetY(-data.pistonOffsetY);
    nodes.springNode.height(data.springHeight);
    nodes.springNode.offsetY(data.springHeight);

    // VALVE
    nodes.valveNode.offsetY(-data.valveOffsetY);
    nodes.valveCamNode.rotation(data.valveCamAngle-27);

    // FUEL AND GAS
    nodes.fuelNode.height(data.cylinderContentsHeight);
    nodes.fuelNode.opacity(consistency.petrol/0.6);
    nodes.gasNode.height(data.cylinderContentsHeight);
    nodes.gasNode.opacity(consistency.exhaust);

    // SPARK PLUG
    nodes.sparkPlugNode.opacity(Number(data.sparkPlugActive));

    stageNode.draw();
  }


  CanvasICE = () => {
    const data = this.data;

    const [kolba] = useImage(kolbaImg);
    const [valve] = useImage(valveImg);
    const [valveCam] = useImage(valveCamImg);
    const [spring] = useImage(springImg);
    const [piston] = useImage(pistonImg);
    const [sparkPlug] = useImage(sparkPlugImg);
    const [fuel] = useImage(fuelImg);


    return (
      <React.Fragment>

    {/* --------- INDICATOR --------- */}
          <Indicator
            externalData={() => ({
              temperature: this.data.physics.internal.temperatureKelvin - 273,
              pressure: this.data.physics.internal.pressure,
              consistency: this.data.physics.internal.consistency,
            })}
          />

          <Group y={110}>

    {/* FUEL AND GAS */}
            <Group x={150} y={60}>
              <Rect ref={this.gasRef}  width={150} height={175} fill={'#c3c3c3'} opacity={0}/>
              <Rect ref={this.fuelRef} width={150} height={175} fill={'#FFEABD'} opacity={0}/>
              <Explosion
                getExplosion={() => this.explosion}
                getRectProps={() => ({width: 150, height: data.cylinderContentsHeight})}
              />
            </Group>
            <Image image={fuel}/>

    {/* VALVE */}
            <Group x={141}>
              <Image ref={this.valveRef} image={valve} x={-5} y={-25} rotation={-23}/>
              <Image ref={this.valveCamRef} image={valveCam} x={3} y={-45.5} offsetY={34/2} offsetX={34/2} rotation={-27}/>
            </Group>

            <Group>
    {/* PISTON */}
              <Image ref={this.pistonRef} image={piston} x={166} y={80}/>
              <Image ref={this.springRef} image={spring} x={203} y={320 + this.initialData.springHeight} offsetY={-this.initialData.springHeight}/>

              <Image image={kolba} />
              <Text x={35} y={40} text={_t('ice.fuel_mixture')} stroke={'#71A1BD'} strokeWidth={.8} fontSize={15} align={'right'} fontStyle={'bold'}/>

    {/* SPARK PLUG */}
              <Group x={215}>
                <Image image={sparkPlug}/>
                <Line ref={this.sparkPlugRef} points={[0, 0, 0, 5]} stroke={'blue'} x={13} y={70} opacity={data.sparkPlugActive ? 1 : 0 }/>
              </Group>
            </Group>
          </Group>
      </React.Fragment>
    )
  };

  CanvasButton = () => {

    const btnSettings = {
      fill: '#447B9C',
      cornerRadius: 5,
      width: 200,
      height: 40,
      fontSize: 16
    };
    const btnTextSettings = {
      stroke: 'white',
      strokeWidth: .5,
      fill: 'white',
      fontSize: 17,
      width: btnSettings.width,
      height: btnSettings.height,
      align: 'center',
      verticalAlign: 'middle'
    };
    return (
      <Group>

          <Group
            y={450} x={600}
            onTap={() => this.data.fuelInjection = !this.data.fuelInjection}
            onClick={() => this.data.fuelInjection = !this.data.fuelInjection}>
            <Rect {...btnSettings}/>
            <Text text={_t('ice.rotate_the_shaft')} {...btnTextSettings}/>
          </Group>

          <Group
            y={500} x={600}
            onMouseDown={() => this.setState({pressPiston: true})}
            onMouseUp={() => this.setState({pressPiston: false})}
            onTouchStart={() => this.setState({pressPiston: true})}
            onTouchEnd={() => this.setState({pressPiston: false})}
          >
            <Rect {...btnSettings}/>
            <Text text={_t('ice.push_the_piston')} {...btnTextSettings}/>
          </Group>

          <Group
            x={600} y={550}
            onMouseDown={() => this.data.sparkPlugActive = true}
            onMouseUp={() => this.data.sparkPlugActive = false}
            onTouchStart={() => this.data.sparkPlugActive = true}
            onTouchEnd={() => this.data.sparkPlugActive = false}
          >
            <Rect {...btnSettings}/>
            <Text text={_t('ice.spark_plug')} {...btnTextSettings}/>
          </Group>
      </Group>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasICE/>
            <this.CanvasButton/>
          </Layer>
        </CanvasContainer>


      {/*  <div style={styles.buttons}>*/}
      {/*    <button*/}
      {/*      style={styles.btn}*/}
      {/*      onClick={() => this.data.fuelInjection = !this.data.fuelInjection}*/}
      {/*      // onMouseDown={() => this.data.fuelInjection = true}*/}
      {/*      // onMouseUp={() => this.data.fuelInjection = false}*/}
      {/*    >Вращать вал</button>*/}
      {/*    <button*/}
      {/*      style={styles.btn}*/}
      {/*      onMouseDown={() => this.setState({pressPiston: true})}*/}
      {/*      onMouseUp={() => this.setState({pressPiston: false})}*/}
      {/*    >Надавить на поршень</button>*/}
      {/*    <button*/}
      {/*      style={styles.btn}*/}
      {/*      onMouseDown={() => this.data.sparkPlugActive = true}*/}
      {/*      onMouseUp={() => this.data.sparkPlugActive = false}*/}
      {/*    >Свеча</button>*/}
      {/*  </div>*/}
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step3);

const styles = {
  mainContainer: {
    background: '#97C1D9',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    background: '#447B9C',
    borderRadius: '7px',
    color: 'white',
    fontSize: '1.3em',
    padding: '7px 14px',
    fontWeight: 'bold',
    width: 'auto',
    margin: '10px 0',
    border:'0',
    cursor: 'pointer'
  },
  buttons: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    bottom: 120,
    right: 150,
  }
};
