import initialVideo from "../../../../images/space/spaceship_asteroid_2/Force_start.mp4";
import Victor from "victor";

export const spaceshipAsteroid2ElementsUI = {
  title: '',
  topCenterEngineActive: false,
  topRightEngineActive: false,
  leftEngineActive: false,
  rightEngineActive: false,
  bottomCenterEngineActive: false,
  bottomRightEngineActive: false,

  explosionStationBigVisible: false,
  explosionStationSmall1Visible: false,
  explosionStationSmall2Visible: false,

  forceController: false,

  asteroidVisible: false,

  dudeVisible: false,

  arrowHintAsteroidEngineVisible: true,
  arrowHintForceControllerVisible: false,
  explosionVisible: false,
  videoSrc: undefined,

  theoryVisible: false,
  replayVisible: false,
}

const crashPatchData = {
  title: 'Миссия провалена, станция взорвалась! \nпопробуй еще раз.',
  arrowHintAsteroidEngineVisible: false,
  arrowHintForceControllerVisible: false,
  explosionVisible: true,
  asteroidVisible: false,
  start: false,
  spaceshipWithAsteroidV: new Victor(40, 0),
  spaceshipWithAsteroidRotation: 0,
  spaceshipWithAsteroidRotationSpeed: 4,
  spaceshipWithAsteroidPos: new Victor(250, 270),
}

export const spaceshipAsteroid2Scenario = [


  {
    key: 'initial',
    patchData: {
      videoSrc: initialVideo,
      onVideoEnd: (comp) => {
        comp.scenarioManager.selectStepByKey('step 1');
      }
    },
    next_success: 'step 1',
  },

  {
    key: 'step 1',
    patchData: {
      title: 'Система определила все возможные точки стыковки \n' +
             'с астероидом. Выбери такую траекторию, которая позволит\n' +
             'избежать столкновения астероида с станциями \n',

      asteroidVisible: true,
    },
    next_success: 'step 2',
  },

  {
    key: 'step 2',
    patchData: {
      title: 'Отлично! А теперь выбери числовое значение силы, с которой корабль \nдействует на астероид.',
      forceController: true,
      asteroidVisible: true,
      arrowHintAsteroidEngineVisible: false,
      arrowHintForceControllerVisible: true,
    },
    next_success: 'step 3'
  },


  {
    key: 'step 3',
    patchData: {
      forceController: true,
      arrowHintAsteroidEngineVisible: false,
      arrowHintForceControllerVisible: false,
      asteroidVisible: true,
    },
    next_success: 'success',
  },


  {
    key: 'crash big station',
    patchData: {
      ...crashPatchData,
      explosionStationBigVisible: true,
    },
    switch_at: 1,
    next_success: 'crash-stub',
  },{
    key: 'crash small station 1',
    patchData: {
      ...crashPatchData,
      explosionStationSmall1Visible: true,
    },
    switch_at: 1,
    next_success: 'crash-stub',
  },{
    key: 'crash small station 2',
    patchData: {
      ...crashPatchData,
      explosionStationSmall2Visible: true,
    },
    switch_at: 1,
    next_success: 'crash-stub',
  },

  {
    key: 'crash-stub',
    patchData: {
      title: 'Миссия провалена, станция взорвалась! \nпопробуй еще раз.',
      replayVisible: true
    }
  },

  {
    key: 'theory',
    patchData: {
      ...crashPatchData,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        if (comp.data.errorCount > 1) {
          comp.data.errorCount = 0
          return comp.scenarioManager.selectStepByKey('step 3');
        }
        comp.scenarioManager.selectStepByKey('success');
      }
    },
    next_success: 'success',
  },




  {
    key: 'success',
    patchData: {
      title: '',
      asteroidVisible: false,
      start: false,
      dudeVisible: true,
      arrowHintAsteroidEngineVisible: false,
      arrowHintForceControllerVisible: false,
    },
  }
]