import {
  CHANGE_FAILURE_MODAL_VISIBLE,
  CHANGE_PAYMENT_MODAL_VISIBLE,
  CHANGE_PRESENTATION_FORM_MODAL_VISIBLE,
  CHANGE_PRESENTATION_MODAL_VISIBLE,
  CHANGE_SUCCESS_MODAL_VISIBLE,
  CHANGE_VK_SHARE_MODAL_VISIBLE,
  SET_ANONYMOUS_POPUP_VISIBLE,
  SET_CONGRATULATION_TEACHER_POPUP_VISIBLE,
  SET_DARK_MODE,
  SET_FEEDBACK_POPUP_VISIBLE,
  SET_HEADER_BANNERS,
  SET_HOMEWORK_COPY_LINK_POPUP_VISIBLE,
  SET_SIDEBAR_MENU_VISIBLE
} from "../actions/commonActions";


const initialState = {
  isDarkMode: false,
  headerBanners: {
    visible: false,
    items: []
  },

  congratulationTeacherPopupVisible: false,
  feedbackPopupVisible: false,
  anonymousPopupVisible: false,
  homeworkCopyLinkPopupVisible: false,
  sidebarMenuVisible: false,
  paymentModalVisible: false,
  successModalVisible: false,
  failureModalVisible: false,
  presentationModalVisible: false,
  presentationFormModalVisible: false,
  VKShareModalVisible: false
}

export const commonReducer = (state = initialState, {type, payload}) => {

  switch (type) {

    case SET_DARK_MODE:
      return {
        ...state,
        isDarkMode: payload
      };
    case SET_HEADER_BANNERS:
      return {
        ...state,
        headerBanners: {...state.headerBanners, ...payload}
      };
    case SET_CONGRATULATION_TEACHER_POPUP_VISIBLE:
      return {
        ...state,
        congratulationTeacherPopupVisible: payload
      };
    case SET_SIDEBAR_MENU_VISIBLE:
      return {
        ...state,
        sidebarMenuVisible: payload
      };
    case SET_FEEDBACK_POPUP_VISIBLE:
      return {
        ...state,
        feedbackPopupVisible: payload
      };
    case SET_ANONYMOUS_POPUP_VISIBLE:
      return {
        ...state,
        anonymousPopupVisible: payload
      };

    case SET_HOMEWORK_COPY_LINK_POPUP_VISIBLE:
      return {
        ...state,
        homeworkCopyLinkPopupVisible: payload
      };


    case CHANGE_VK_SHARE_MODAL_VISIBLE:
      return {
        ...state,
        VKShareModalVisible: payload
      };
    case CHANGE_PAYMENT_MODAL_VISIBLE:
      return {
        ...state,
        paymentModalVisible: payload
      };
    case CHANGE_SUCCESS_MODAL_VISIBLE:
      return {
        ...state,
        successModalVisible: payload
      };
    case CHANGE_FAILURE_MODAL_VISIBLE:
      return {
        ...state,
        failureModalVisible: payload
      };
    case CHANGE_PRESENTATION_MODAL_VISIBLE:
      return {
        ...state,
        presentationModalVisible: payload
      };
    case CHANGE_PRESENTATION_FORM_MODAL_VISIBLE:
      return {
        ...state,
        presentationFormModalVisible: payload
      };

    default:
      return state;
  }
}
