import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Group, Image, Layer, Line, Rect, RegularPolygon, Text} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import Card from '../../../canvas/components/Card';
import controllerImg from '../../../../images/crane/withScenario/controller.png';
import backgroundImg from '../../../../images/crane/background.png';
import wheelImg from '../../../../images/crane/wheel.png';
import bigWheelImg from '../../../../images/crane/bigWheel.png';
import boxImg from '../../../../images/crane/box.png';
import boxesImg from '../../../../images/crane/boxes.png';
import boxesCrane6Img from '../../../../images/crane/boxesCrane6.png';
import craneImg from '../../../../images/crane/crane.png';
import engineImg from '../../../../images/crane/engine.png';
import hookImg from '../../../../images/crane/hook.png';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getScenario, initialData} from "../scenaries";
import {layout2, lsnLabelTxtStyle, lsnTitleTxtStyle, mainColor} from "../../../../utils/styles";
import CanvasNextBtn from "../../../canvas/components/CanvasNextBtn";
import CanvasPrevBtn from "../../../canvas/components/CanvasPrevBtn";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import MovingArrow from "../../../canvas/components/MovingArrow";
import {getTimeDeltaSec, hasIntersection} from "../../../../utils/common";
import TaskComplete from "../../../canvas/components/TaskComplete";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {getFormulaWithInput} from "../components/FormulaWithInput";
import ArrowHint from "../../../canvas/components/ArrowHint";


class CraneScenario extends React.Component {
  constructor(props) {
    super(props);
    this.btnItems = ['Top', 'Right', 'Bottom', 'Left'];
    this.managedComponents = [
      'stage',

      'title',

      'completePlaceHintArrow',
      'completePlace',

      'cardData',
      'cardFormula',

      'controllArrowHint',

      'redHitBox',

      'liftingHeightText',
      'liftingWeightText',
      'liftingTimeText',

      'leftSling',
      'rightSling',
      'engine',
      'topRightWheel',
      'topLeftWheel',
      'bigWheel',
      'bigWheelImg',
      'hook',
      'redBox',
      'leftDoubleCable',
      'rightDoubleCable',

      'btnTop',
      'btnRight',
      'btnBottom',
      'btnLeft',


      'completeBlockVisible',
      'commonNodeForCrane',
    ];

    this.data = cloneDeep(initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  _movingCallbacks = {};
  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.scenarioManager.resetScenario();
  }

  heightPxToM = (px) => {
    return px * .051
  }

  get liftingHeight() {
    const data = this.data;
    return Math.abs(Number(this.heightPxToM(data.heightY - data.liftingStartOffset).toFixed(1)));
  }
  get boxY() {
    const data = this.data;
    const topLimit = 240;
    const bottomLimit = 470;
    let coeff = .3;
    let res = data.boxY;
    if (data.autoCompleteLifting) {
      coeff = .4
    }

    if (data.btnTop || data.autoCompleteLifting) {
      res = topLimit < data.boxY ? data.boxY - coeff : res;
    }
    if (data.btnBottom) {
      res = bottomLimit > data.boxY ? data.boxY + coeff : res;
    }
    return res;
  }
  get engineX() {
    const data = this.data;
    const leftLimit = 200;
    const rightLimit = 322;
    let coeff = .3;
    let res = data.engineX;
    if (data.autoCompleteLifting) {
      coeff = .2
    }

    if (data.btnLeft) {
      res = leftLimit < data.engineX ? data.engineX - coeff : res;
    }
    if (data.btnRight || data.autoCompleteLifting) {
      res = rightLimit > data.engineX ? data.engineX + coeff : res;
    }
    return res;
  }

  get heightY() {
    return -(initialData.boxY - this.data.boxY);
  }

  get boxInPlace() {
    const boxNode = this._getNode('redHitBox');
    const placeNode = this._getNode('completePlace');
    return hasIntersection(boxNode, placeNode, this) && this.data.engineX >= 320;
  }
  resetInput = () =>  this.data.inputVal = '';

  onClickReset = () => {
    this.data = cloneDeep(initialData);
    this.resetInput();
    this.scenarioManager.resetScenario();
  };
  onClickPrev = () => {
    this.resetInput();
    this.data = cloneDeep(initialData);
    this.scenarioManager.back();
  };
  onClickNext = () => {
    this.resetInput();
    this.scenarioManager.next();
  };

  checkResultValue = () => {
    const data = this.data;
    const currentKey = this.scenarioManager.getStepData().key;

    let checkInputSteps = [
      'step5', 'step5.1', 'step6', 'step6.1',
      'step7', 'step7.1', 'step8', 'step8.1',
    ];

    if (!data.checkSuccessFn) {
      if (data?.inputSuccess === this.data.inputVal) {
        checkInputSteps.forEach(key => key === currentKey && this.scenarioManager.success(key));
      } else {
        checkInputSteps.forEach(key => key === currentKey && this.scenarioManager.failure(key));
      }
    } else {

      if (data.checkSuccessFn(data)) {
        this.scenarioManager.success(currentKey)
      } else {
        this.scenarioManager.failure(currentKey)
      }

    }
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);


    const stageNode = this._getNode('stage');
    if (!stageNode) return;


    data.count = (15 - Math.trunc(((data.prevTime - data.startTime)) / 1000));
    this.commonStartTime = this.commonStartTime || time;
    data.commonPrevTime = time;

    if (data.start) {

      data.startTime = data.startTime || time;
      const timedelta = data.prevTime ? time - data.prevTime : 0;
      data.prevTime = time;
      const timedeltaSec = getTimeDeltaSec(timedelta);


      let t = data.weightCargo * 9.8 * data.hightCrane;
      let hookVelocity = (data.weightCargo*25) / t;
      data.hookVelocity = hookVelocity;

      if (!data.boxCompletePos) {
        data.liftingTime += timedeltaSec;
        data.verevka = data.verevka - hookVelocity;
        data.boxY = this.boxY;
        data.engineX = this.engineX;
        data.heightY = this.heightY;
        data.leftWheelVel = -data.boxY;
        data.rightWheelVel = data.boxY;
        data.wheelVel = data.boxY;

        data.cargoReadyForLifted = data.heightY < -15;

        data.liftingStartOffset = data.cargoReadyForLifted ? data.liftingStartOffset : data.heightY;
        data.liftingHeight = this.liftingHeight;

        data.boxInPlace = this.boxInPlace;



        if (data.boxInPlace && !data.completeTask) {
          data.boxInPlace = true;
          this.scenarioManager.next();
        }

      }
    }

    this.scenarioManager.passTimestampSec((time - this.commonStartTime)/1000);
    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['title'].text(data.text?.value);
    const txtStg = data.text?.settings;
    txtStg && Object.keys(txtStg).forEach((k) => {
      n['title'][k](txtStg[k]);
    })


    this.btnItems.forEach((k) => {
      n[`btn${k}`].fill(data[`btn${k}`] ? '#5fe537' : 'lightgray');
    })

    if (!data.boxCompletePos || data.autoCompleteLifting) {

      if (!data.cargoReadyForLifted || !data.startTime) {
        n['leftSling'].points([-60, 27, 0, data.heightY]);
        n['rightSling'].points([0, data.heightY, 60, 27]);
        n['hook'].y(data.heightY - 55);
      }

      n['bigWheel'].y(0);
      n['commonNodeForCrane'].y(data.heightY - data.liftingStartOffset);
      n['engine'].x(data.engineX);
      n['leftDoubleCable'].points([0, 0, 0, data.boxY - 200]);
      n['rightDoubleCable'].points([0, 0, 0, data.boxY - 200]);
      n['cardData'].position({x: data.cardDataSettings.x, y: data.cardDataSettings.y});

    }
    if (data.boxCompletePos) {
      n['leftSling'].points([-60, 27, 0,  20 ]);
      n['rightSling'].points([0,  20, 60, 27]);
      n['hook'].y(-120);
      n['bigWheel'].y(-36);
      n['commonNodeForCrane'].y(-196);
      n['engine'].x(322);
      n['leftDoubleCable'].points([0, 0, 0, 30]);
      n['rightDoubleCable'].points([0, 0, 0, 30]);
      n['cardData'].position({x: 103, y: 370});
    }


    n['bigWheelImg'].rotation(data.wheelVel);

    n['topLeftWheel'].rotation(data.leftWheelVel);
    n['topRightWheel'].rotation(data.rightWheelVel);


    n['controllArrowHint'].visible(data.controllerArrowsVisible);
    n['completePlaceHintArrow'].visible(data.arrowsVisible);
    n['completePlaceHintArrow'].opacity(Number(data.arrowsVisible));
    n['cardData'].visible(data.cardDataVisible);


    n['liftingHeightText'].text(data.liftingHeight);
    n['liftingTimeText'].text(Math.round(data.liftingTime));


    n['stage'].draw();
  }


  btnOnMouseDown = (key, val) => {
    this.data[key] = val;
    this.data.start = val;

    if (val) {
      this.data.arrowsVisible = true;
      this.data.controllerArrowsVisible = false;
      this.data.cardDataVisible = true;
    }
  }

  Canvas = () => {
    const data = this.data;
    const stepData = this.scenarioManager.getStepData();

    const [controller] = useImage(controllerImg);
    const [background] = useImage(backgroundImg);
    const [boxes] = useImage(boxesImg);
    const [boxesCrane6] = useImage(boxesCrane6Img);
    const [crane] = useImage(craneImg);
    const [engine] = useImage(engineImg);
    const [wheel] = useImage(wheelImg);
    const [bigWheel] = useImage(bigWheelImg);
    const [hook] = useImage(hookImg);
    const [box] = useImage(boxImg);

    const triangleSettings = {sides: 3, fill: 'lightgray', radius: 10 };
    const formulaSettings = {fill: mainColor, fontSize: 35}
    const cardSettings = data.cardFormulaSettings || {};
    const errorSettings = data.errorSettings || {};

    const cardFormula = getFormulaWithInput(this.data.customFormulaCode, this.data, this);
    return (
      <React.Fragment>
        <Image image={background} width={1000} height={700} />
        <Image image={boxesCrane6} x={160} y={303} />
        <Image image={boxes} x={160} y={175} />

        <Text ref={this._ref('title')} {...lsnTitleTxtStyle} fill={mainColor} x={540} y={70}/>



        <Group x={370} y={366}>
          <MovingArrow
            id={'hint1'}
            ref={this._ref('completePlaceHintArrow')}
            stageNode={this.stageRef?.current}
            length={20}
            arrowsStep={40}
            arrowsCount={2}
            x={40} y={-40}
            textX={-35} textY={-20}
            textColor={'gray'}
            arrowColor={'gray'}
            text={''}
            rotation={0}
            visible={false}
            getMovingCallback={this.getMovingCallback}
          />

          <Group y={-7} x={10}>
            <Rect ref={this._ref('completePlace')} width={150} height={10} fill={'green'} opacity={0}/>
          </Group>

        </Group>


        <Group x={70} y={30}>
          <Image image={crane} />

          <Group x={262} y={55} ref={this._ref('engine')}>
            <Group x={20}>
              <Line points={[0, 0, 0, this.data.verevka - 90]} stroke={'gray'}
                    x={1} y={27} strokeWidth={1.5} ref={this._ref('leftDoubleCable')}/>
              <Line points={[0, 0, 0, this.data.verevka - 90]} stroke={'gray'}
                    x={44} y={27} strokeWidth={1.5} ref={this._ref('rightDoubleCable')}/>

              <Image image={engine}/>
            </Group>

            <Group x={20} y={15}>
              <Image image={wheel} x={10} y={10} offsetX={10} offsetY={10}
                     ref={this._ref('topLeftWheel')}/>
              <Image image={wheel} x={35} y={10} offsetX={10} offsetY={10}
                     ref={this._ref('topRightWheel')}/>
            </Group>

            <Group y={0} ref={this._ref('commonNodeForCrane')}>
              <Group x={-20} y={290}>
                <Group y={0} ref={this._ref('bigWheel')}>
                  <Line points={[0, 0, 0, 75]} stroke={'gray'} x={62} y={0} strokeWidth={1.5}/>
                  <Image image={bigWheel} width={50} height={52}
                         x={62} y={0} offsetX={25} offsetY={26} ref={this._ref('bigWheelImg')}/>
                </Group>

                <Group x={0} y={125}>
                  <Line points={[-60, 27, 0, this.data.hookRefY + 55]} stroke={'grey'}
                        x={63} y={-25} strokeWidth={1.5} ref={this._ref('leftSling')}/>
                  <Image image={hook} x={55} y={this.data.hookRefY}
                         ref={this._ref('hook')}/>
                  <Line points={[0, this.data.hookRefY + 55, 60, 27]} stroke={'gray'}
                        x={63} y={-25} strokeWidth={1.5} ref={this._ref('rightSling')}/>

                  <Group ref={this._ref('redBox')}>
                    <Image image={box}/>
                    <Rect width={127} height={3} opacity={0} y={60} fill={'blue'} ref={this._ref('redHitBox')}/>
                  </Group>
                </Group>
              </Group>

              <Card
                {...data.cardDataSettings}
                reffer={this._ref('cardData')}
              >

                <Group {...data.liftingTimeTxtInCardSettings}>
                  <Text text={`Время \nподъема груза \n(    ), с:`} {...lsnLabelTxtStyle} fill={layout2.orange} fontSize={14}/>
                  <Text text={`t`} {...lsnLabelTxtStyle} x={10} y={38} fill={layout2.orange} fontStyle={'italic'} fontSize={14}/>
                  <Text ref={this._ref('liftingTimeText')} y={55} text={`${data.weightCargo}`} {...lsnLabelTxtStyle} fill={layout2.orange} fontSize={25}/>
                </Group>

                <Group {...data.liftingHeightTxtInCardSettings}>
                  <Text text={`Высота \nподъема (    ), м:`} {...lsnLabelTxtStyle} fill={layout2.orange} fontSize={14}/>
                  <Text text={`h`} {...lsnLabelTxtStyle} x={70} y={20} fill={layout2.orange} fontStyle={'italic'} fontSize={14}/>
                  <Text ref={this._ref('liftingHeightText')} y={40} text={`${data.liftingHeight}`} {...lsnLabelTxtStyle} fill={layout2.orange} fontSize={25}/>
                </Group>

                <Group {...data.liftingWeightTxtInCardSettings}>
                  <Text text={`Вес груза (    ), \nH:`} {...lsnLabelTxtStyle} fill={layout2.orange} fontSize={14}/>
                  <Text text={`P`} {...lsnLabelTxtStyle} x={72} fill={layout2.orange} fontStyle={'italic'} fontSize={14}/>
                  <Text ref={this._ref('liftingWeightText')} y={40} text={`${data.weightCargo}`} {...lsnLabelTxtStyle} fill={layout2.orange} fontSize={25}/>
                </Group>
              </Card>
            </Group>
          </Group>


          <Group x={675} y={230} visible={data.controllerVisible}>
            <Rect x={-15} y={-15} width={245} height={245} fill={'lightgray'}/>
            <Image image={controller} />

            <Group x={107} y={63}>
              {
                this.btnItems.map((k, i) => {
                  const corrKey = `btn${k}`;
                  let x = 0;
                  let y = 0;
                  let rotation = 90 * i;
                  if (k === 'Left') { x = -45; y = 45; }
                  if (k === 'Right') { x = 45; y = 45; }
                  if (k === 'Bottom') { y = 90; }
                  return (
                    <Group
                      key={corrKey+i}
                      onMouseDown={() => this.btnOnMouseDown(corrKey, true)}
                      onMouseUp={() => this.btnOnMouseDown(corrKey, false)}
                      onTouchStart={() => this.btnOnMouseDown(corrKey, true)}
                      onTouchEnd={() => this.btnOnMouseDown(corrKey, false)}
                      onMouseLeave={() => this.btnOnMouseDown(corrKey, false)}
                      x={x} y={y} rotation={rotation}
                    >
                      <Rect opacity={0} x={-30} y={-15} width={60} height={30} fill={'black'}/>
                      <RegularPolygon {...triangleSettings} ref={this._ref(corrKey)} />
                    </Group>
                  )
                })
              }
            </Group>

            <Group x={-155} y={-14} ref={this._ref('controllArrowHint')}>
              <Rect width={140} height={80} fill={'white'}/>
              <ArrowHint
                rotation={170}
                arrowX={170}
                arrowY={70}
                fontSize={15}
                textX={10} textY={15}
                text={'Используй пульт \nдля подъема \nгруза'}
              />
            </Group>

          </Group>

        </Group>

        <Group x={650}>
          <ArrowHint
            x={-155} y={420}
            rotation={170}
            arrowX={150}
            arrowY={95}
            fontSize={15}
            color={'white'}
            fontStyle={'bold'}
            textX={10} textY={15}
            text={'Нажми назад, \nесли пропустил \nпредыдущую часть'}
            visible={data.prevBtnArrowVisible}
          />
          <CanvasPrevBtn x={0}
             onClick={() => this.onClickPrev()}
             visible={!stepData.prev_btn_hidden}
          />
          <CanvasNextBtn x={100}
             visible={!stepData.next_btn_hidden}
             onClick={() => this.onClickNext()}
             ref={this._ref('nextBtn')}
          />
          <CanvasResetBtn x={210}
             onClick={() => this.onClickReset()}
          />
        </Group>


        <Group x={540} y={130}>
          <Card
            height={80} width={300}
            visible={!!data.cardFormulaVisible}
            {...cardSettings.card}
          >

            <Group y={25} visible={data.formulaWithInputVisible || data.customFormulaVisible}>
              {cardFormula}

              <Text visible={data.errorVisible} {...errorSettings}/>
            </Group>

            <Group y={25} visible={data.formulaTextVisible}>
              <Text x={20} text={data.formulaText} {...formulaSettings}/>
            </Group>
          </Card>

          <CanvasButton
            text={'Проверить'}
            onClick={() => {this.checkResultValue()}}
            {...data.checkBtnStg}
            visible={data.formulaWithInputVisible}
          />
        </Group>



        <TaskComplete
          y={data.completeBlockVisible ? 200 : 150}
          withoutBackground
          withoutText={!data.completeTaskVisible && data.completeBlockVisible}
          visible={data.completeBlockVisible||data.completeTaskVisible}
        />
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  failureModalVisible: state.commonReducer.failureModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CraneScenario);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
