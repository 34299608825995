import {hasIntersection} from "../../../utils/common";



export const filterWaterTowers = (currentId, comp) => {
  comp.dropBoxDirection.forEach((direct) => {
    const wtKey = direct+'WaterTower';
    const waterTower = comp.data[wtKey];
    comp.data[wtKey] = waterTower.filter((el) => el.id !== currentId);
  })
  comp.waterTowerItems.forEach(el => {
    const tubeForWater = comp._getNode('tubeForWater'+el);
    tubeForWater.visible(false);
  })
}

export const getCorrectPosByDropNode = (dropBox) => {
  let width = dropBox.width();
  let height = dropBox.height();
  const pos = dropBox.getAbsolutePosition();
  return {x: pos.x + width / 2, y: pos.y + height / 2}
}


export const setDefaultTankPos = (dropBox, dragBox, comp) => {
  const dragIndex = dragBox.attrs.dragIndex;
  const waterTankText = comp._getNode('waterTankText'+dragIndex);
  const waterForTank = comp._getNode('waterForTank'+dragIndex);
  const waterTubeForTank = comp._getNode('waterTubeForTank'+dragIndex);
  const tubeForWater = comp._getNode('tubeForWater'+dragIndex);
  waterTankText.visible(true);
  tubeForWater.visible(false);
  waterTubeForTank.visible(false);
  waterForTank.visible(false);

  setDefaultPos(dropBox, dragBox);
}
export const setDefaultBaseTowerPos = (dropBox, dragBox, comp) => {
  const dragIndex = dragBox.attrs.dragIndex;
  const towerImgBase = comp._getNode('towerBaseImg'+dragIndex);
  towerImgBase.rotation(0);

  setDefaultPos(dropBox, dragBox);
}
export const setDefaultPos = (dropBox, dragBox) => {
  let width = dropBox.width();
  let height = dropBox.height();
  const pos = dropBox.getAbsolutePosition();
  dragBox.setAbsolutePosition({x:pos.x+width/2, y:pos.y+height/2});
}


export const dropNode = (corrDropBoxIndex, dragBoxNode, waterTower, isTank) => {
  // Уровень(level) нужен для определения корректного дропбокса в функции updateStage
  const dragIndex = dragBoxNode.attrs.dragIndex;
  const dragElId = dragBoxNode.attrs.dragElId;
  const value = {id: dragElId, dragIndex: dragIndex, level:corrDropBoxIndex, isTank: isTank};
  waterTower.push(value);
}

export const mapAllDropBox = (component, callback) => {
  component.dropBoxDirection.forEach((direction) => {
    component.dropBoxItems.forEach((dropBoxIndex, i) => {
      callback(direction, dropBoxIndex)
    })
  })
}



export const checkHoverDropBoxIntersection = (currElDrag, constrName, comp) => {

  const checkHoverBox = (dropBoxDirection, dropBoxIndx) => {
    const dropBox = comp._getNode(`${dropBoxDirection}DropBox${dropBoxIndx}`);
    const hasIntr = hasIntersection(dropBox, currElDrag, comp);

    if (hasIntr) {
      if (
        ((constrName==='tank') && dropBoxIndx >= 2 ) ||
        ((constrName==='base') && dropBoxIndx <= 2)
      ) {
        dropBox.setAttrs({fill: 'green', opacity: .3})
      }
    } else {
      dropBox.setAttrs({ opacity: 0 })
    }
  }

  mapAllDropBox( comp, checkHoverBox )
}


export const dropTowerBase = (dragBoxNode, comp) => {
  const dragIndex = dragBoxNode.attrs.dragIndex;
  const dragElId = dragBoxNode.attrs.dragElId;
  const defaultPosNode = comp._getNode('defaultPosTwrBase'+dragIndex);
  const hitboxNode = comp._getNode('towerBaseRect'+dragIndex);

  let dropped = false;

  const checkDropBox = (dropBoxDirection, dropBoxIndx) => {
    const waterTower = comp.data[dropBoxDirection+'WaterTower'];
    const corrDropBoxIndex = !waterTower[0] ? 1 : 2; // берем нужную позиция проверяя на наличие
    const initialDropBox = comp._getNode(dropBoxDirection+'DropBox'+dropBoxIndx);
    initialDropBox.opacity(0);

    let intersect = hasIntersection(initialDropBox, hitboxNode, comp);
    const alreadyAdded = waterTower.find((el) => el.id === dragElId); // проверяем на наличие в стеке

    if (intersect && !alreadyAdded && dropBoxIndx < 3) {
      dropNode(corrDropBoxIndex, dragBoxNode, waterTower, false);
      dropped = true;
    }
  }

  mapAllDropBox( comp, checkDropBox )


  if (!dropped) {
    setDefaultBaseTowerPos(defaultPosNode, dragBoxNode, comp);
  }
}


export const dropWaterTank = (dragBoxNode, comp) => {
  const dragIndex = dragBoxNode.attrs.dragIndex;
  const dragElId = dragBoxNode.attrs.dragElId;
  const defaultPosNode = comp._getNode('defaultPosWtrTank'+dragIndex);
  const hitboxNode = comp._getNode('waterTankRect'+dragIndex);
  const waterTankText = comp._getNode('waterTankText'+dragIndex);

  let dropped = false;

  const checkDropBox = (dropBoxDirection, dropBoxIndx) => {
    const waterTower = comp.data[dropBoxDirection+'WaterTower'];
    const tankAlreadyAdded = waterTower[1]?.isTank;
    const dontAddedTowerBase = !waterTower[0];
    const corrDropBoxIndex = !waterTower[1] ? 2 : 3; // берем нужную позиция проверяя на наличие
    const initialDropBox = comp._getNode(dropBoxDirection+'DropBox'+dropBoxIndx);
    initialDropBox.opacity(0);

    let intersect = hasIntersection(initialDropBox, hitboxNode, comp);
    const alreadyAdded = waterTower.find((el) => el.id === dragElId); // проверяем на наличие в стеке

    if (intersect && !alreadyAdded && !dontAddedTowerBase && !tankAlreadyAdded) {
      dropNode(corrDropBoxIndex, dragBoxNode, waterTower, true);
      waterTankText.visible(false);
      dropped = true;
    }
  }
  mapAllDropBox( comp, checkDropBox )

  if (!dropped) {
    setDefaultTankPos(defaultPosNode, dragBoxNode, comp);
  }
  return dropped;
}

export const resetDragAndDrop = (comp) => {
  comp.waterTowerItems.forEach((dragIndex) => {
    const defaultTankNode = comp._getNode('defaultPosWtrTank'+dragIndex);
    const defaultBaseNode = comp._getNode('defaultPosTwrBase'+dragIndex);
    const waterTankMainNode = comp._getNode('waterTankMain'+dragIndex);
    const towerBaseMainNode = comp._getNode('towerBaseMain'+dragIndex);

    setDefaultTankPos(defaultTankNode, waterTankMainNode, comp);
    setDefaultBaseTowerPos(defaultBaseNode, towerBaseMainNode,comp);
  });
}


export const checkFailureSingleSmall = (comp) => {
  const leftWaterTowerLen = comp.data.leftWaterTower.length;
  const rightWaterTowerLen = comp.data.rightWaterTower.length;
  const helperArr = [leftWaterTowerLen, rightWaterTowerLen];
  return (helperArr.includes(0) || helperArr.includes(1)) && helperArr.includes(2);
}
export const checkFailureDoubleSmall = (comp) => {
  const leftWaterTowerLen = comp.data.leftWaterTower.length;
  const rightWaterTowerLen = comp.data.rightWaterTower.length;
  const helperArr = [leftWaterTowerLen, rightWaterTowerLen];
  return helperArr.every(len => len === 2);
}

export const correctTankWaterCoeffHeight = (comp) => {
  return getCorrectStaticData(comp, 'TankCoeff');
}

export const correctTankWaterHeight = (comp) => {
  return getCorrectStaticData(comp, 'Tank');
}

export const correctCityWaterHeight = (comp) => {
  return getCorrectStaticData(comp, 'City');
}

const getCorrectStaticData = (comp, containerName) => {
  let data = comp.data;
  let staticData = comp.staticData;
  const leftWaterTowerLen = data.leftWaterTower.length;
  const rightWaterTowerLen = data.rightWaterTower.length;
  const helperArr = [leftWaterTowerLen, rightWaterTowerLen];
  let res = 0;
  switch (true) {
    case helperArr.some(len => len > 2):
      res = staticData[`singleBigTowerWater${containerName}Height`];
      break;
    case helperArr.every(len => len === 2):
      res = staticData[`dualTowerWater${containerName}Height`];
      break;
    case (helperArr.includes(0) || helperArr.includes(1)) && helperArr.includes(2):
      res = staticData[`singleSmallTowerWater${containerName}Height`];
      break;
  }
  return res;
}