import React from "react";
import GoBack from "./GoBack";


const IframeContainer = (props) => {

  const __DEV__ = window.location.hostname.includes('localhost');
  return (
    <div style={{width: '100%', height: '100%', position: 'relative'}}>
      <GoBack
        top={'3.3%'} left={'3%'}
        lessonData={props.lessonData}
      />
      {
        __DEV__ ?
          <iframe src={"http://localhost:88/"+props.urlCode} width={'100%'} height={'100%'}/>
          :
          <iframe src={"https://app.inventic.tech/"+props.urlCode} width={'100%'} height={'100%'}/>
      }
    </div>
    )
}

export default IframeContainer;
