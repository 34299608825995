import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import dropImg1 from "../images/matchItems/GreeceBlocks_5_1_drop_1.png";
import dropImg2 from "../images/matchItems/GreeceBlocks_5_1_drop_2.png";
import dropImg3 from "../images/matchItems/GreeceBlocks_5_1_drop_3.png";

import dragImg1 from "../images/matchItems/GreeceBlocks_5_1_drag_1.png";
import dragImg2 from "../images/matchItems/GreeceBlocks_5_1_drag_2.png";
import dragImg3 from "../images/matchItems/GreeceBlocks_5_1_drag_3.png";

import continueImg1 from "../images/matchItems/GreeceBlocks_5_1_continue_1.png";
import continueImg2 from "../images/matchItems/GreeceBlocks_5_1_continue_2.png";
import continueImg3 from "../images/matchItems/GreeceBlocks_5_1_continue_3.png";


import dragImg21 from "../images/matchItems/GreeceBlocks_5_2_drag_1.png";
import dragImg22 from "../images/matchItems/GreeceBlocks_5_2_drag_2.png";
import dragImg23 from "../images/matchItems/GreeceBlocks_5_2_drag_3.png";


import bgNature from '../images/greece-flowers-bg.png';
import MatchItems from "./MatchItems";


const GreeceBlocks6 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const Text = ({txt}) => (
    <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>{txt}</span>
  )
  const RedText = ({txt}) => (
    <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}>{txt}</span>
  )

  const chooseTasks = [
    {
      title: 'Match the items',
      dropItems: [
        {id: 1, successId: 3, completeImg: continueImg1, img: dropImg1},
        {id: 2, successId: 1, completeImg: continueImg2, img: dropImg2},
        {id: 3, successId: 2, completeImg: continueImg3, img: dropImg3},
      ],
      dragItems: [
        {id: 1, img: dragImg3},
        {id: 2, img: dragImg1},
        {id: 3, img: dragImg2},
      ]
    },
    {
      title: 'Match the items',
      dropItems: [
        {
          id: 1, successId: 3,
          completeText: <Text txt={<>A <RedText txt={'pulley'}/> is a simple machine which basically contains a wheel and rope to lift the heavy objects</>}/>,
          mainText: <Text txt={'A _______ is a simple machine which basically contains a wheel and rope to lift the heavy objects'}/>
        },
        {
          id: 2, successId: 1,
          completeText: <Text txt={<>All a fixed pulley does is change the <RedText txt={'direction'}/> of you pull</>}/>,
          mainText: <Text txt={'All a fixed pulley does is change the _________ of you pull'}/>
        },
        {
          id: 3, successId: 2,
          completeText: <Text txt={<>The universal rule for the pulley: if the effort becomes easier, the <RedText txt={'rope'}/> becomes longer</>}/>,
          mainText: <Text txt={'The universal rule for the pulley: if the effort becomes easier, the ____ becomes longer'}/>
        },
      ],
      dragItems: [
        {id: 1, img: dragImg23},
        {id: 2, img: dragImg22},
        {id: 3, img: dragImg21},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i + 1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <MatchItems
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    dropItems={item.dropItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default GreeceBlocks6;
