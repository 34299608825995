import { Image } from "react-konva";
import React from "react";

export const CanvasImage = (props) => {
  const {
    width,
    height,
    imgRef,
  } = props;

  return(
    <Image
      {...props}
      offsetX={width/2}
      offsetY={height/2}
      ref={imgRef}
      preventDefault={false}
    />
  )
};

export default CanvasImage;
