import React, {useEffect, useState} from "react";
import {CloseCircleOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import '../styles/headerBanner.css';
import * as actions from "../../../../store/actions";
import {serverMediaUrl} from "../../../../utils/api";
import moment from 'moment';
import {Carousel} from "antd";


const HeaderBanner = (props) => {
  const { isMobile } = props;

  const [slides, setSlides] = useState([]);

  const items = props.headerBanners.items;
  useEffect(() => {
    let resSlides = items.filter((el) => isMobile ? Boolean(el.thumbnail) : true);
    setSlides(resSlides)
  }, [items, props.headerBanners.visible, isMobile])

  return (
    <Carousel
      autoplay
      pauseOnHover={true}
      autoplaySpeed={5000}
      speed={1000}
      className={`header-banner-carousel ${isMobile ? 'header-banner-carousel--mobile' : ''}`}
    >
      {
        slides.map((banner) => {
          let img = serverMediaUrl+ (isMobile ? banner.thumbnail : banner.image)
          return (
            <div key={banner.id}>
              <a
                target={'_blank'}
                href={banner.link}
                className={`header-banner`}
                style={{background: banner.background}}
              >
                <div className={'close-btn'} onClick={(e) => {
                  e.preventDefault();
                  let exprDate = moment().add(1, 'days').format('DD.MM.YYYY HH:mm');
                  localStorage.setItem('header-banner', exprDate)
                  props.setHeaderBanners({visible: false});
                }}>
                  <CloseCircleOutlined style={{color: 'black'}}/>
                </div>
                {
                  banner.image ? (
                    <img className={'header-banner__img'} src={img} alt=""/>
                  ) : null
                }
              </a>
            </div>
          )
        })
      }
    </Carousel>
  )
}

const mapStateToProps = (state, {match}) => {
  return {
    headerBanners: state.commonReducer.headerBanners
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeaderBanners: (data) => dispatch(actions.setHeaderBanners(data)),
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderBanner);

