import React, {useEffect} from 'react';
import {HistoryOutlined, BarChartOutlined} from '@ant-design/icons';
import {Progress} from "antd";
import '../styles/lessonitemInfo.css'


const LessonItemStatistic = (props) => {

  const {lessonResult, homeworkStats} = props;

  const maxProgressBar = Math.max(lessonResult.detailed?.success_time, lessonResult.scores);
  let resultsInfo = [];

  lessonResult && resultsInfo.push(
    {
      title: 'Мои результаты',
      items: {
        time: {
          title: 'Время выполнения задачи (с)', icon: <HistoryOutlined/>, value: lessonResult.detailed?.success_time.toFixed(1)
        },
        pointsOfCorrect: {
          title: "Баллов за правильное решение", icon: <BarChartOutlined/>, value: lessonResult.scores_for_success.toFixed(0)
        },
        pointsOfTime: {
          title: "Баллов за время выполнения", icon: <BarChartOutlined/>, value: lessonResult.scores_for_time.toFixed(0)
        },
        resultPoints: {
          title: 'Баллов за задачу', icon: <BarChartOutlined/>, value: lessonResult.scores.toFixed(0)
        },
      }
    }
  );
  homeworkStats && resultsInfo.push(
    {
      title: 'Результаты класса',
      items: {
        averageTime: {
          title: 'Среднее время в классе (с)', icon: <HistoryOutlined/>, value: homeworkStats.mean_success_time ? homeworkStats.mean_success_time.toFixed(1) : 0
        },
        averagePoints: {
          title: 'Средний балл класса', icon: <BarChartOutlined/>, value: homeworkStats.mean_scores ? homeworkStats.mean_scores.toFixed(1) : 0
        },
      }
    }
  );

  return (
    <div className={'infoWrapper'}>
      {
        resultsInfo.map((el, i) => {
          return (
            <div className={'blockContainer'} key={'block-statitic-'+props.lsn.id+i}>
              <h3 className={'blockTitle'}>{el.title}</h3>
              <div className={'itemsContainer'}>
                <table style={{width: '100%'}}>
                  <tbody>
                    {
                      Object.keys(el.items).map((k, i) => {
                        const item = el.items[k];
                        const percent = item.value / (maxProgressBar / 100);
                        return (
                          <tr className={'itemRow'} key={'statitic-'+props.lsn.id+'-'+i}>
                            <td>
                              <span className={'infoIcon'}>{item.icon}</span>
                            </td>
                            <td>
                              <p className={'infoTitle'}>{item.title}:</p>
                            </td>
                            <td>
                              <p className={'infoValue'}>{item.value}</p>
                            </td>
                            <td style={{width: '30%'}}>
                              <Progress className={'infoProgress'} percent={percent} showInfo={false} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          )
        })
      }
    </div>
  );
};

export default LessonItemStatistic;
