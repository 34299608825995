

export const getScenario = (code) => {
  if (code === 'bus1') {
    return scenario;
  }
  if (code === 'bus2') {
    return scenario2;
  }
  if (code === 'bus3') {
    return scenario3;
  }
}

export const defaultPatchData = {
  title: '',
  testTitle: '',
  successTestItem: '',

  pedalHintGasVisible: false,
  pedalHintStopVisible: false,
  tunnelStep: false,
  tunnelStepInitial: false,
  btnStartVisible: false,
  btnsTestVisible: false,
  btnsTestActive: false,

  withInertHint: false,
  successPopupVisible: false,
}

export const scenario = [
  {
    key: 'step 1',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми на газ или тормоз и обрати внимание, \nв какую сторону отклоняются пассажиры',
      pedalHintGasVisible: true,
      pedalHintStopVisible: true
    },
    next_success: 'step 2'
  },
  {
    key: 'step 2',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми на газ или тормоз и обрати внимание, \nв какую сторону отклоняются пассажиры',
    }
  }
]
export const scenario3 = [
  {
    key: 'step 1',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми на газ или тормоз и обрати внимание, \nв какую сторону отклоняются пассажиры',
      pedalHintGasVisible: true,
      pedalHintStopVisible: true,
      withInertHint: true,
    },
    next_success: 'step 2'
  },
  {
    key: 'step 2',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми на газ или тормоз и обрати внимание, \nв какую сторону отклоняются пассажиры',
      withInertHint: true,
    }
  }
]

export const scenario2 = [
  {
    key: 'step 1',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми на газ или тормоз и обрати внимание, \nв какую сторону отклоняются пассажиры',
      pedalHintGasVisible: true,
    },
    next_success: 'step 2'
  },
  {
    key: 'step 2',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми на газ или тормоз и обрати внимание, \nв какую сторону отклоняются пассажиры',
    },
    next_success: 'step 3',
    switch_at: 5
  },
  {
    key: 'step 3',
    patchData: {
      ...defaultPatchData,
      title: 'Отлично! \nА теперь нажми на тормоз',
      pedalHintStopVisible: true
    },
  },
  {
    key: 'step 4',
    patchData: {
      ...defaultPatchData,
    },
    next_success: 'tunnel step initial',
    switch_at: 5
  },
  {
    key: 'tunnel step initial',
    patchData: {
      ...defaultPatchData,
      tunnelStepInitial: true,
    },
    next_success: 'tunnel step 1'
  },
  {
    key: 'tunnel step 1',
    patchData: {
      ...defaultPatchData,
      title: 'Определи, автобус едет с постоянной скоростью, \nнабирает скорость или тормозит',
      tunnelStep: true,
      pedalGas: false,
      pedalStop: false,
      busSpeed: 0,
      btnStartVisible: true,
    },
  },



  {
    key: 'tunnel test 1',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Выбери вариант:',
      tunnelStep: true,
      btnsTestVisible: true,
      pedalStop: true,
      busSpeed: 45,
      successTestItem: 'Б',
      selectedBtn: undefined,
      btnsTestActive: true
    },
    next_success: 'tunnel test 1 success',
    next_failure: 'tunnel test 1 failure',
  },{
    key: 'tunnel test 1 success',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Правильно!',
      tunnelStep: true,
      btnsTestVisible: true,
      pedalStop: true,
      busSpeed: 45,
      successTestItem: 'Б',
    },
    next_success: 'tunnel test 2',
    switch_at: 5
  },{
    key: 'tunnel test 1 failure',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Будь внимательнее',
      tunnelStep: true,
      btnsTestVisible: true,
      pedalStop: true,
      busSpeed: 45,
      successTestItem: 'Б',
    },
    next_success: 'tunnel test 2',
    switch_at: 5
  },



  {
    key: 'tunnel test 2',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Выбери вариант:',
      tunnelStep: true,
      btnsTestVisible: true,
      pedalGas: true,
      pedalStop: false,
      successTestItem: 'А',
      busSpeed: 45,
      selectedBtn: undefined,
      btnsTestActive: true
    },
    next_success: 'tunnel test 2 success',
    next_failure: 'tunnel test 2 failure',
  },{
    key: 'tunnel test 2 success',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Правильно!',
      tunnelStep: true,
      btnsTestVisible: true,
      pedalGas: true,
      pedalStop: false,
      busSpeed: 45,
      successTestItem: 'А',
    },
    next_success: 'tunnel test 3',
    switch_at: 5
  },{
    key: 'tunnel test 2 failure',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Будь внимательнее',
      tunnelStep: true,
      btnsTestVisible: true,
      pedalGas: true,
      pedalStop: false,
      busSpeed: 45,
      successTestItem: 'А',
    },
    next_success: 'tunnel test 3',
    switch_at: 5
  },



  {
    key: 'tunnel test 3',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Выбери вариант:',
      tunnelStep: true,
      btnsTestVisible: true,
      tunnelStepInitial: true,

      pedalGas: false,
      pedalStop: false,
      busSpeed: 45,
      successTestItem: 'В',
      selectedBtn: undefined,
      btnsTestActive: true
    },
    next_success: 'tunnel test 3 success',
    next_failure: 'tunnel test 3 failure',
  },{
    key: 'tunnel test 3 success',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Правильно!',
      tunnelStep: true,
      btnsTestVisible: true,
      tunnelStepInitial: true,
      pedalGas: false,
      pedalStop: false,
      busSpeed: 45,
      successTestItem: 'В',

    },
    next_success: 'success',
    switch_at: 5
  },{
    key: 'tunnel test 3 failure',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Будь внимательнее',
      tunnelStep: true,
      btnsTestVisible: true,
      tunnelStepInitial: true,
      pedalGas: false,
      pedalStop: false,
      busSpeed: 45,
      successTestItem: 'В',
    },
    next_success: 'success',
    switch_at: 5
  },




  {
    key: 'success',
    patchData: {
      ...defaultPatchData,
      testTitle: 'Правильно!',
      tunnelStep: true,
      btnsTestVisible: true,
      successPopupVisible: true,
      selectedBtn: undefined,
      busSpeed: 0,
    },
  },
]