import chart1ForPointsImg from "../../../../images/pathandspeed/space/chart1ForPoints.png";
import chart2ForPointsImg from "../../../../images/pathandspeed/space/chart2ForPoints.png";
import chart1Img from '../../../../images/pathandspeed/space/chart1.png';
import chart2Img from '../../../../images/pathandspeed/space/chart2.png';
import initialMp4 from '../../../../images/pathandspeed/space/videos/Speed_start.mp4';


export const defaultPatchData = {
  title: '',
  videoSrc: undefined,
  chartForPoints: undefined,
  chartWithDragAndDrop: undefined,
  hiddenAll: false,
  theoryVisible: false,
  lessonComplete: false
}


export const space10Scenario = [

  {
    key: 'initial',
    patchData: {
      ...defaultPatchData,
      videoSrc: initialMp4
    }
  },


  {
    key: 'step 1',
    patchData: {
      ...defaultPatchData,

      title: 'Помоги экипажу «Инвентик-7» определить скорость полёта, \nиспользуя данные о пройденном пути, поступающие каждые 5 секунд',
      chartForPoints: chart1ForPointsImg,
      chartWithDragAndDrop: chart1Img,
    },
    next_success: 'step 2'
  },{
    key: 'step 2',
    patchData: {
      ...defaultPatchData,

      title: 'Помоги экипажу «Инвентик-7» определить скорость полёта, \nиспользуя данные о пройденном пути, поступающие каждые 5 секунд',
      chartForPoints: chart1ForPointsImg,
      chartWithDragAndDrop: chart1Img,
      start: true,
    },
    next_success: 'step 3'
  },{
    key: 'step 3',
    patchData: {
      ...defaultPatchData,

      title: 'Построй график скорости космического корабля:',
      chartForPoints: chart1ForPointsImg,
      chartWithDragAndDrop: chart1Img,
      lineProgress: [0,0,110,0]
    },
    next_success: 'theory'
  },



  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,
      title: 'Блок теории',
      hiddenAll: true,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        const errorCount = comp.data.errorCount;
        if (errorCount >= 2) {
          comp.data.inputAverageSpeed = undefined;
          comp.scenarioManager.selectStepByKey(comp.prevStepKey || 'step 1');
          comp.data.errorCount = 0;
          return;
        }
        comp.scenarioManager.selectStepByKey('success');
      }
    },
    next_success: 'theory'
  },


  {
    key: 'success',
    patchData: {
      ...defaultPatchData,
      title: 'Задача выполнена',
      theoryVisible: true,
      lessonComplete: true
    }
  },

]





// ----------------  Path and speed 11  --------------------


export const space11Scenario = [

  {
    key: 'step 1',
    patchData: {
      ...defaultPatchData,

      title: 'Построй график скорости космического корабля, \n' +
             'используя данные о пройденном пути, \n' +
             'поступающие каждые 5 секунд',
      chartForPoints: chart2ForPointsImg,
      chartWithDragAndDrop: chart2Img,
    },
    next_success: 'step 2'
  },{
    key: 'step 2',
    patchData: {
      ...defaultPatchData,

      title: 'Построй график скорости космического корабля, \n' +
             'используя данные о пройденном пути, \n' +
             'поступающие каждые 5 секунд',
      chartForPoints: chart2ForPointsImg,
      chartWithDragAndDrop: chart2Img,
      start: true,
    },
    next_success: 'step 3'
  }, {
    key: 'step 3',
    patchData: {
      ...defaultPatchData,

      title: 'Построй график скорости космического корабля:',
      chartForPoints: chart2ForPointsImg,
      chartWithDragAndDrop: chart2Img,
    },
    next_success: 'theory'
  },


  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,
      title: 'Блок теории',
      hiddenAll: true,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        const errorCount = comp.data.errorCount;
        if (errorCount >= 2) {
          comp.data.inputAverageSpeed = undefined;
          comp.scenarioManager.selectStepByKey(comp.prevStepKey || 'step 2');
          comp.data.errorCount = 0;
          return
        }
        comp.scenarioManager.selectStepByKey('success');
      }
    },
    next_success: 'theory'
  },



  {
    key: 'success',
    patchData: {
      ...defaultPatchData,
      title: 'Задача выполнена',
      theoryVisible: true,
      lessonComplete: true
    }
  },

]