import React from "react";
import Konva from "konva";
import { Layer, Line, Rect, Circle, Image as KonvaImage, Group } from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import cloneDeep from "lodash.clonedeep";
import useImage from "use-image";
import electroscopeImage from "../../../../images/electrostatic/electroscope.png";
import plusImage from "../../../../images/electrostatic/plus.png";
import minusImage from "../../../../images/electrostatic/minus.png";
import stickImage from "../../../../images/electrostatic/stick.png";
import Victor from 'victor';
import {sendSuccessForScenario} from "../../../../utils/common";
import ScenarioManager from "../../../../utils/ScenarioManager";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";


class Electrostatic3 extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.connectorRef = React.createRef();
    this.state = {};

    this.electroscope1 = {

      id: 'electroscope1',

      electroscopePosition: Victor(700, 200),
      eW: 282,
      eH: 686,

      centreShiftVector: Victor(140, 320),

      ballShiftVector: Victor(140, 105/2),
      bW: 105,
      bH: 105,

      scale: 1/2,
      netRank: 5,
      rodRank: 5,

      rotationStickAngle: 0,
    }
    this.electroscope1.ballPos = this.electroscope1.electroscopePosition.clone().add(
      this.electroscope1.ballShiftVector.clone().multiply(Victor(this.electroscope1.scale, this.electroscope1.scale)));


    this.electroscope2 = {

      id: 'electroscope2',

      electroscopePosition: Victor(300, 200),
      eW: 282,
      eH: 686,

      centreShiftVector: Victor(140, 320),

      ballShiftVector: Victor(140, 105/2),
      bW: 105,
      bH: 105,

      scale: 1/2,
      netRank: 5,
      rodRank: 5,

      rotationStickAngle: 0,
    }
    this.electroscope2.ballPos = this.electroscope2.electroscopePosition.clone().add(
      this.electroscope2.ballShiftVector.clone().multiply(Victor(this.electroscope2.scale, this.electroscope2.scale)));

    this.const = {
      e: 1,
      K: 1000,
      electricCharges: [0, 1],
    }

    this.charge = {

      plusPos: Victor(350, 300),
      pW: 40,
      pH: 40,

      plusDragMove: false,

      dragStartPos: null,
      dragEndPos: null,

      connected: false,

    };

    this.plusRef = React.createRef();
    this.rotationStickRef1 = React.createRef();
    this.rotationStickRef2 = React.createRef();
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  };

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.plusImg = new Image();
    
    this.minusImg = new Image();

    //console.log(this.plusImg);

    this.plusImg.src = plusImage;
    this.minusImg.src = minusImage;


    let stageRef = this.stageRef?.current;
    this.layer = stageRef.getLayers()[0];
    this.getChargeNets(this.electroscope1, this.layer);
    this.updateChargeNets(this.electroscope1, this.layer);
    this.getChargeNets(this.electroscope2, this.layer);
    this.updateChargeNets(this.electroscope2, this.layer);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  getChargeNets(electroscope, layer) {

    let ballPos = electroscope.ballPos;
    let ballWidth = electroscope.bW;

    let o = ballPos.clone().add(Victor(-3, -2));
    let R = ballWidth/2;
    let r = o.clone().add(Victor(0, -R/2));
    let m = 4;

    let ballNet = [];
    const pi = Math.PI;

    ballNet.push(o.clone());
    for (let i = 0; i < m; i++) {
      let n = i*4;
      for (let k = 0; k < n; k++) {
        let node = o.clone().add(Victor(-R/2.4*i/(m-1), -R/2.4*i/(m-1)).multiply(Victor(Math.cos(2*pi*k/n), Math.sin(2*pi*k/n))));
        ballNet.push(node);
      }
    }
    electroscope.ballNet = ballNet;

    let ballCharges = [];
    for (let i = 0; i < ballNet.length; i++) {
      ballCharges.push(Math.random() > 0.5 ? 1 : 0);
    }
    electroscope.ballCharges = ballCharges;

    //----------------

    let start = o.add(Victor(-3, R/2+3));
    let n = electroscope.rodRank;

    let rodNet = [];
    for (let i = 0; i < 2*n; i++) {
      let node = start.clone().add(Victor(0, i*R/n));
      rodNet.push(node);
    };

    start = start.add(Victor(3, 0));
    for (let i = 2*n; i < 3*n; i++) {
      let node = start.clone().add(Victor(0, i*R/n));
      rodNet.push(node);
    };
    electroscope.rodNet = rodNet;

    let rodCharges = [];
    for (let i = 0; i < rodNet.length; i++) {
      rodCharges.push(Math.random() > 0.5 ? 1 : 0);
    }
    electroscope.rodCharges = rodCharges;

    //-----------------

    start = Victor(0, 0) //this.const.centreShiftVector.clone().multiply(Victor(this.const.scale, this.const.scale));
    
    let stickNet = []
    for (let i = 0; i < 2*n-2; i++) {
      let node = start.clone().add(Victor(0, i*R/n));
      stickNet.push(node);
    };
    electroscope.stickNet = stickNet;

    let stickCharges = [];
    for (let i = 0; i < stickNet.length; i++) {
      stickCharges.push(Math.random() > 0.5 ? 1 : 0);
    }

    electroscope.stickCharges = stickCharges;
  }

  updateChargeNets(electroscope, layer) {

    let ballNet = electroscope.ballNet;
    let ballCharges = electroscope.ballCharges;
    let rodNet = electroscope.rodNet;
    let rodCharges = electroscope.rodCharges;
    let stickNet = electroscope.stickNet;
    let stickCharges = electroscope.stickCharges;
    
    //Checking ball charge
    let qBall = 0;
    for (let i = 0; i < ballCharges.length; i++) {
      qBall += ballCharges[i] ? 1 : 0;
    }
    qBall = qBall/ballCharges.length; 

    //Checking rod charge
    let qRod = 0;
    for (let i = 0; i < rodCharges.length; i++) {
      qRod += rodCharges[i] ? 1 : 0;
      
    }
    qRod = qRod/rodCharges.length;


    //Update conditions
    let rot = electroscope.deg/90;
    let target_q = 1/((rot*5)**3+2);
    let sign = qBall - target_q > 0 ? 1 : 0;
    let change = Math.abs(qBall - target_q)*2;

    //Update charges
    for (let i = 0; i < ballNet.length; i++) {
      if (true) {//Math.random() < change) {
        if (sign == ballCharges[i]) {
          ballCharges[i] = Math.random() < change  ? Math.abs(ballCharges[i] - 1) : ballCharges[i]; 
        }
        if (Math.random() > 0.5) {
          for (let k = 0; k < rodNet.length; k++) {
            if (sign != rodCharges[i]) {
              rodCharges[i] = Math.random() < change  ? Math.abs(rodCharges[i] - 1) : rodCharges[i]; 
              break;
            }
          }
        }
        else {
          for (let k = 0; k < stickNet.length; k++) {
            if (sign != stickCharges[i]) {
              stickCharges[i] = Math.random() < change  ? Math.abs(stickCharges[i] - 1) : stickCharges[i]; 
              break;
            }
          }
        }
      }
    }

    //Draw
    let netGroup = layer.getChildren((node) => {return node.getId() === 'netGroup'+electroscope.id})[0];
    console.log(netGroup);

    if (!netGroup) {
        netGroup = new Konva.Group(
          {id: 'netGroup'+electroscope.id}
        );
        layer.add(netGroup);
      for (let i = 0; i < ballNet.length; i++) {
        let chargeImg = new Konva.Image(
          {
            id: 'net-${i}'+electroscope.id,
            width: 5,
            height: 5,
            x: ballNet[i].x,
            y: ballNet[i].y,
            image: null
          }
        );
        netGroup.add(chargeImg);
        ballCharges[i] ? chargeImg.image(this.plusImg) : chargeImg.image(this.minusImg);
        layer.batchDraw();     
      }
    }


    let rodNetGroup = layer.getChildren((node) => {return node.getId() === 'rodNetGroup'+electroscope.id})[0];
    console.log(rodNetGroup);

    if (!rodNetGroup) {
      rodNetGroup = new Konva.Group(
        {id: 'rodNetGroup'+electroscope.id}
      );
      layer.add(rodNetGroup);
      for (let i = 0; i < rodNet.length; i++) {
        let chargeImg = new Konva.Image(
          {
            id: 'rodnet-${i}'+electroscope.id,
            width: 5,
            height: 5,
            x: rodNet[i].x,
            y: rodNet[i].y,
            image: null
          }
        );
        rodNetGroup.add(chargeImg);
        let img = new Image();
        img.src = rodCharges[i] ? plusImage : minusImage
        img.onload = () => {
          chargeImg.image(img);
          layer.batchDraw();
        }
      }
    }


    let stickNetGroup = layer.getChildren((node) => {return node.getId() === 'stickNetGroup'+electroscope.id})[0];

    if (!stickNetGroup) {
      stickNetGroup = new Konva.Group(
        {
        id: 'stickNetGroup'+electroscope.id,
        x: electroscope.electroscopePosition.x+electroscope.centreShiftVector.x*electroscope.scale,
        y: electroscope.electroscopePosition.y+electroscope.centreShiftVector.y*electroscope.scale,
        offsetX: 3,//1,,
        offsetY: 40//42,
        });
      layer.add(stickNetGroup);
      for (let i = 0; i < stickNet.length; i++) {
        let chargeImg = new Konva.Image(
          {
            id: 'rodnet-${i}',
            width: 5,
            height: 5,
            x: stickNet[i].x,
            y: stickNet[i].y,
            image: null
          }
        );
        stickNetGroup.add(chargeImg);
        let img = new Image();
        img.src = stickCharges[i] ? plusImage : minusImage
        img.onload = () => {
          chargeImg.image(img);
          layer.batchDraw();
        }
      }
    }

    let rodChildren = rodNetGroup.getChildren();
    //console.log('children:', children);
    for (let i = 0; i < rodChildren.length; i++) {
      rodCharges[i] ? rodChildren[i].image(this.plusImg) : rodChildren[i].image(this.minusImg);
    }

    let ballChildren = netGroup.getChildren();
    //console.log('children:', children);
    for (let i = 0; i < ballChildren.length; i++) {
      ballCharges[i] ? ballChildren[i].image(this.plusImg) : ballChildren[i].image(this.minusImg);
    }

    let stickChildren = stickNetGroup.getChildren();
    //console.log('children:', children);
    for (let i = 0; i < stickChildren.length; i++) {
      stickCharges[i] ? stickChildren[i].image(this.plusImg) : stickChildren[i].image(this.minusImg);
    }
    stickNetGroup.rotation(electroscope.deg);

    layer.batchDraw();
  }


  move = (time) => {
    //let data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let stageNode = this.stageRef?.current;
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    this.updateStage()
  };

  updateStage() {
    //const data = this.data;
    let stageNode = this.stageRef?.current;
    if (!stageNode) return;

    // get main layer
    //const layer = stageNode.getLayers()[0];
    //this.updateImgs(this.layer);
    //this.updateBallNet(this.layer);
    stageNode.draw();
  };

  onDragStart = (e) => {
    this.charge.plusDragMove = true;
    this.charge.dragStartPos = this.charge.plusPos;
  };

  onDragMove = (e) => {
    let plus = this.plusRef.current;
    let pos = plus.position();
    let ballPos1 = this.electroscope1.ballPos.clone();
    let ballPos2 = this.electroscope2.ballPos.clone();
    let plusPos = Victor(pos.x+this.charge.pW/2, pos.y+this.charge.pH/2);
    this.charge.plusPos = plusPos;

    function sigmoid(x){
      return 1/(1+Math.exp(-x*250+4))*90;
    }
    
    let dist1 = plusPos.clone().subtract(ballPos1).length();
    let dist2 = plusPos.clone().subtract(ballPos2).length();
    this.electroscope1.dist = dist1;
    this.electroscope2.dist = dist2;
    let field1 = this.const.K*this.const.e/dist1**2;
    let field2 = this.const.K*this.const.e/dist2**2;
    this.electroscope1.field = field1;
    this.electroscope2.field = field2;

    let deg1 = sigmoid(field1);
    let deg2 = sigmoid(field2);
    if (this.charge.connected) {
      if (dist1 > dist2) {deg1 = deg2}
      else {deg2 = deg1}
    }
    this.electroscope1.deg = deg1;
    this.electroscope2.deg = deg2;

    let bar1 = this.rotationStickRef1.current;
    bar1.rotation(deg1);
    let bar2 = this.rotationStickRef2.current;
    bar2.rotation(deg2);

    if (this.charge.plusPos.clone().subtract(this.charge.dragStartPos.clone()).length() >= 2) {
      this.charge.dragStartPos = this.charge.plusPos.clone();
      this.updateChargeNets(this.electroscope1, this.layer);
      this.updateChargeNets(this.electroscope2, this.layer);
    }
    

  };

  onDragEnd = (e) => {
    this.charge.plusDragMove = false;
  };

  changeConnector = (e) => {
    let connector = this.connectorRef.current;
    
    this.charge.connected = !this.charge.connected;
    connector.setAttr('visible', this.charge.connected);
    
    let value = this.charge.connected? 'Разомкнуть' : 'Замкнуть';
    e.target.setAttribute(
      'value',
      value
    )
    //console.log(button.getAttribute('value'));
    console.log(this.charge.connected);

    this.updateChargeNets(this.electroscope1, this.layer);
      this.updateChargeNets(this.electroscope2, this.layer);
  }

  Scene = () => {
    const {code} = this.props;

    const [electroscope] = useImage(electroscopeImage);
    const [plus] = useImage(plusImage);
    const [minus] = useImage(minusImage);
    const [stick] = useImage(stickImage);

    return (
      <React.Fragment>
        <Layer>
          {
            code === 'electrostatic3' && (
              <Group>

                <KonvaImage
                  image={electroscope}
                  width={this.electroscope1.eW*this.electroscope1.scale}
                  height={this.electroscope1.eH*this.electroscope1.scale}
                  x={this.electroscope1.electroscopePosition.x} 
                  y={this.electroscope1.electroscopePosition.y}
                />

                <Line
                  ref={this.rotationStickRef1}
                  x={this.electroscope1.electroscopePosition.x+this.electroscope1.centreShiftVector.x*this.electroscope1.scale}
                  y={this.electroscope1.electroscopePosition.y+this.electroscope1.centreShiftVector.y*this.electroscope1.scale}
                  points={[0, -40, 0,  40]}
                  stroke={'red'}
                  strokeWidth={2}
                />

                <KonvaImage
                  image={electroscope}
                  width={this.electroscope2.eW*this.electroscope2.scale}
                  height={this.electroscope2.eH*this.electroscope2.scale}
                  x={this.electroscope2.electroscopePosition.x} 
                  y={this.electroscope2.electroscopePosition.y}
                />

                <Line
                  ref={this.rotationStickRef2}
                  x={this.electroscope2.electroscopePosition.x+this.electroscope2.centreShiftVector.x*this.electroscope2.scale}
                  y={this.electroscope2.electroscopePosition.y+this.electroscope2.centreShiftVector.y*this.electroscope2.scale}
                  points={[0, -40, 0,  40]}
                  stroke={'red'}
                  strokeWidth={2}
                />

                <KonvaImage
                  draggable={true}
                  onDragStart={(e) => {this.onDragStart(e)}}
                  onDragMove={(e) => {this.onDragMove(e)}}
                  onDragEnd={(e) => {this.onDragEnd(e)}}
                  ref={this.plusRef}
                  x={350} y={300}
                  image={plus}
                  width={40}
                  height={40}
                />

                <Line
                  ref={this.connectorRef}
                  x={(this.electroscope1.ballPos.x+this.electroscope2.ballPos.x)/2}
                  y={(this.electroscope1.ballPos.y+this.electroscope2.ballPos.y)/2}
                  points={[-(this.electroscope1.ballPos.x-this.electroscope2.ballPos.x)/2, 0, (this.electroscope1.ballPos.x-this.electroscope2.ballPos.x)/2, 0]}
                  stroke={'silver'}
                  strokeWidth={2}
                  visible={false}
                />
            
              </Group>
            )   
          }
        </Layer>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.Scene/>
        </CanvasContainer>

        <button
          style={{position: 'absolute', left: '200px', bottom: '100px', zIndex: 10, cursor: 'pointer'}}
          onClick={this.changeConnector}
        >Замкнуть</button>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Electrostatic3);

const styles = {
  mainContainer: {
    background: '#f4f1de',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }
}
