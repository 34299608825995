import React from "react";
import cloneDeep from "lodash.clonedeep";
import Victor from 'victor';
import {
  boxDragBoundFunc,
  dynomomDragBoundFunc,
  onDragStart,
  onDragMove,
  onDragEnd,
  getAllNodes, calcDynamometerReaction, getTitles
} from "../utils";
import {CanvasContainer} from "../../../canvas";
import {Circle, Group, Image, Layer, Line, Rect, Text} from "react-konva";
import useImage from "use-image";
import pointerImg from "../../../../images/dynamometer/pointer.png";
import boxImg from "../../../../images/dynamometer/box.png";
import dynamometerImg from "../../../../images/dynamometer/dynamometer.png";
import dynamometerImgEn from "../../../../images/dynamometer/dynamometerEn.png";
import groundImg from "../../../../images/dynamometer/ground.png";
import hookImg from "../../../../images/dynamometer/hook.png";
import smallHookImg from "../../../../images/dynamometer/smallHook.png";
import MovingArrow from "../../../canvas/components/MovingArrow";
import {sendSuccessForScenario, showFailOrSuccessPopup, toggleVisibleEl} from "../../../../utils/common";
import {element} from "prop-types";
import {layout2, lsnTitleTxtStyle, mainColor} from "../../../../utils/styles";
import CanvasInput from "../../../canvas/components/CanvasInput";
import CanvasButton from "../../../canvas/components/CanvasButton";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import StepsIndicator from "../../../canvas/components/StepsIndicator";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {_t, getCorrectLang} from "../../../../utils/lang/common";


class Dynamometer extends React.Component {
  constructor(props) {
    super(props);

    this.lang = getCorrectLang();

    // --------- REFS ---------
    this.stageRef = React.createRef();

    this.boxLeftRef = React.createRef();
    this.boxRightRef = React.createRef();
    this.boxLeftImgRef = React.createRef();
    this.boxRightImgRef = React.createRef();

    this.hookRef = React.createRef();
    this.hookBindBoxRef = React.createRef();

    this.dynamometerRef = React.createRef();
    this.groundRef = React.createRef();
    this.pointerRef = React.createRef();

    this.fieldsBlockRef = React.createRef();
    this.btnsContainerRef = React.createRef();

    // HINTS
    this.dragDynamometerHintRef = React.createRef();
    this.dragBoxHintRef = React.createRef();
    this.bindBoxHintRef = React.createRef();


    // BIND BOX
    this.boxLeftHorizontalBindBoxRef = React.createRef();
    this.boxLeftTopBindBoxRef = React.createRef();
    this.boxLeftBottomBindBoxRef = React.createRef();

    this.boxRightHorizontalBindBoxRef = React.createRef();
    this.boxRightTopBindBoxRef = React.createRef();
    this.boxRightBottomBindBoxRef = React.createRef();

    this.resultDependBtnRef = React.createRef();
    this.resultDependBtnTxtRef = React.createRef();
    this.resultNotDependBtnRef = React.createRef();
    this.resultNotDependBtnTxtRef = React.createRef();

    this.titleItems = getTitles().allTitles;

    Object.keys(this.titleItems).forEach((k) => {
      this[`title${k}Ref`] = React.createRef();
    })

    this.requestId = null;

    this.state = {
      step: 1
    }
    this.staticData = {
      maxHookLeftOffset: 140,
      boxSize: 100,
      bottomYPos: 400
    };
    this.initialData = {
      titleStep: 1,

      boxBindedToBox: false,
      dynamometerBindedToBox: false,
      dynamometerIsDragged: false,
      boxIsDragged: false,

      startTime: undefined,
      prevTime: undefined,

      // boxLeftPos: null,
      // boxRightPos: null,

      leftBoxBindToRightBoxVertical: false,
      leftBoxBindToRightBoxHorizontal: false,

      rightBoxBindToLeftBoxVertical: false,
      rightBoxBindToLeftBoxHorizontal: false,

      dynamometerExtension: 0,

      connectedStartX: 0,

      rightBoxBindToDynamometer: false,
      leftBoxBindToDynamometer: false,

      movingHookRight: false,
      previousHookX: undefined,
      fractionForce: 0,

      resistanceFInput: '',
      calcErrInput: '',

      // resultBtnVal: 'depend',
      resultBtnVal: '',

      dynamometerIsDragging: false,

      dynamometerPos: Victor(650, 435),
      boxLeftPos: Victor(100, 0),
      boxRightPos: Victor(250, 0),
      boxRightVelocity: Victor(0, 0),
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  setDefaultData = () => {
    this.data = cloneDeep(this.initialData);
  }

  _movingCallbacks = {};
  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  componentDidMount() {
    this.requestId = window.requestAnimationFrame(this.commonMove);
  }

  componentWillUnmount() {
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  updateFractionForce = () => {
    const data = this.data;
    let singleFractionForce = data.rightBoxBindToDynamometer || data.leftBoxBindToDynamometer;
    let doubledFractionForce = data.leftBoxBindToRightBoxHorizontal
      || data.rightBoxBindToLeftBoxHorizontal
      || data.leftBoxBindToRightBoxVertical
      || data.rightBoxBindToLeftBoxVertical;

    if (singleFractionForce) {
      data.fractionForce = 1;
      if (doubledFractionForce) {
        data.fractionForce = 2;
      }
    }
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    const {
      boxLeftNode,
      boxRightNode,
    } = getAllNodes(this);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    this.updateFractionForce();
    const actualFractionForce = data.boxRightVelocity.x < 0.1 ? data.fractionForce * 1.3 : data.fractionForce;

    if (data.rightBoxBindToDynamometer && data.dynamometerIsDragging) {

      if (data.dynamometerExtension < 0) {
        data.rightBoxBindToDynamometer = false;
        data.dynamometerExtension = 0;
      } else {
        data.dynamometerExtension = data.dynamometerPos.x - data.boxRightPos.x - 143;
        const dynamometerForce = data.dynamometerExtension * 0.0175;

        if (dynamometerForce > actualFractionForce) {
          data.boxRightVelocity.x = 15 * (dynamometerForce - actualFractionForce);
          // data.dynamometerExtension -= 5 * (dynamometerForce - data.fractionForce);
          // data.boxRightPos.x = data.dynamometerPos.x - data.dynamometerExtension - 143;
        } else {
          data.boxRightVelocity.x = 0;
        }
      }
    }

    if (!data.dynamometerIsDragging) {
      data.dynamometerExtension = 0;
      data.boxRightVelocity.x = 0;
      if (data.rightBoxBindToDynamometer) {
        data.dynamometerPos.x = data.boxRightPos.x + 143
      }
    }

    data.boxRightPos.x = data.boxRightPos.x + data.boxRightVelocity.x * 1;

    if (data.leftBoxBindToRightBoxHorizontal) {
      data.boxLeftPos = {x: data.boxRightPos.x - this.staticData.boxSize - 10, y: 0};
    }

    if (data.leftBoxBindToRightBoxVertical) {
      data.boxLeftPos.x = data.boxRightPos.x;
    }

    this.updateStage();
  };

  updateStage = () => {
    const data = this.data;
    const state = this.state;

    const nodes = getAllNodes(this);
    const {
      hookNode,
      pointerNode,
      dynamometerNode,
      boxRightNode,
      boxLeftNode,
      dragDynamometerHintNode,
      dragBoxHintNode,
      fieldsBlockNode,

      resultDependBtnNode,
      resultDependBtnTxtNode,
      resultNotDependBtnNode,
      resultNotDependBtnTxtNode,
      btnsContainerNode,
      bindBoxHintNode,
    } = nodes;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    // Движение крючка и указателя на шкале нужно двигать одновременно
    // hookNode
    // pointerNode

    // Drag & drop делается через "bindBox", чтобы их увидеть надо сделать opacity 1 на строчке 183 "bindBoxOpacity"
    hookNode.x(-50 - this.data.dynamometerExtension);
    pointerNode.x(143 - this.data.dynamometerExtension);

    dynamometerNode.position(data.dynamometerPos);
    boxRightNode.position(data.boxRightPos);
    boxLeftNode.position(data.boxLeftPos);



    const timeDeltaSec = (data.prevTime - data.startTime)/1000
    if (timeDeltaSec>=10) {
      toggleVisibleEl(dragDynamometerHintNode, data.dynamometerIsDragged);


      dragBoxHintNode.x(boxRightNode.getAbsolutePosition().x+160);
      toggleVisibleEl(dragBoxHintNode, data.boxIsDragged || data.dynamometerIsDragging);
    }

    Object.keys(this.titleItems).forEach((k) => {
      const titleCode = `step${state.step}Title${data.titleStep}`;
      const subTitleCode = `step${state.step}SubTitle${data.titleStep}`;
      const conditions = titleCode === k || (subTitleCode === k && data.dynamometerBindedToBox)
      toggleVisibleEl(nodes[`title${k}Node`], !conditions);
    })

    toggleVisibleEl(fieldsBlockNode, !data.dynamometerBindedToBox || state.step !== 1);

    resultDependBtnNode.fill(data.resultBtnVal === 'depend' ? mainColor : 'white');
    resultDependBtnTxtNode.fill(!data.resultBtnVal || data.resultBtnVal === 'notDepend' ? mainColor : 'white');
    resultNotDependBtnNode.fill(!data.resultBtnVal || data.resultBtnVal === 'depend' ? 'white' : mainColor);
    resultNotDependBtnTxtNode.fill(data.resultBtnVal && data.resultBtnVal === 'notDepend' ? 'white' : mainColor);


    const btnsVisible = state.step === 2 && (data.dynamometerBindedToBox && data.boxBindedToBox);
    toggleVisibleEl(btnsContainerNode, !btnsVisible);
    toggleVisibleEl(
      bindBoxHintNode,
      state.step !== 2 ||
      (data.boxBindedToBox ||
      data.dynamometerBindedToBox ||
      data.rightBoxIsDragging ||
      data.leftBoxIsDragging)
    );


    stageNode.draw();
  };

  checkResult = () => {
    const state = this.state;
    const data = this.data;

    let success = false;
    if (state.step === 1) {
      const correctCalcErrInput = data.calcErrInput === .25;
      const correctResistance = data.resistanceFInput === 4;
      success = correctCalcErrInput && correctResistance;

      if (success) {
        this.setState(
          {step: 2},
          this.setDefaultData
        );
      }
      if (!correctResistance) {
        data.titleStep = 2;
      }
      if (correctResistance && !correctCalcErrInput) {
        data.titleStep = 3;
      }

    }
    if (state.step === 2) {
      success = this.data.resultBtnVal === 'notDepend';
      if (success) {
        this.setState({step: 3})
        sendSuccessForScenario(this);
      } else {
        data.titleStep = 2;
      }
    }
    showFailOrSuccessPopup(this, success);
  }

  onChangeInput = (val, fieldName) => {
    this.data[fieldName] = Number(val);
  }

  CanvasDynamometer = () => {
    let corrDynamometerImg = dynamometerImg;
    if (this.lang === 'en') {
      corrDynamometerImg = dynamometerImgEn
    }


    const {boxSize} = this.staticData;
    const [pointer] = useImage(pointerImg);
    const [box] = useImage(boxImg);
    const [dynamometer] = useImage(corrDynamometerImg);
    const [ground] = useImage(groundImg);
    const [hook] = useImage(hookImg);
    const [smallHook] = useImage(smallHookImg);

    const boxStngs = { width: boxSize, height: boxSize, image: box, preventDefault: false };

    const bindBoxOpacity = {opacity: 0 };
    const hookBindBox = { fill:'purple',  ...bindBoxOpacity };
    const rightBindBox = { width: 30, height: 30, fill: 'red', x: 87, y: boxSize/3, ...bindBoxOpacity };
    const topBindBox = { width: boxSize - 40, height: 15, fill: 'blue', y: -15, x: 15, ...bindBoxOpacity };
    const bottomBindBox = { width: boxSize - 40, height: 5, fill: 'blue', y: boxSize, x: 15, ...bindBoxOpacity };
    const textSettings = {fontSize: 25, fill: layout2.darkBlue, y: 3}

    const btnTextSetting = {
      strokeWidth: .2,
      lineHeight: 1.3,
      x:0,
      width:100,
      height:40,
      align:'center',
      verticalAlign:'middle',
      fontStyle:'bold',
      fontSize:15,
    };
    return (
      <Group>
        <StepsIndicator
          x={770} y={40}
          items={Array(2).fill(1)}
          activeVal={this.state.step}
        />
        <Group>
          {
            Object.keys(this.titleItems).map((k, i) => (
              <Text
                key={k} ref={this[`title${k}Ref`]}
                text={this.titleItems[k]} {...lsnTitleTxtStyle}
                fontSize={22}
                visible={false} opacity={0}
              />
            ))
          }
        </Group>
        <Group y={500}>
          <Image x={0} width={950} height={150} image={ground} preventDefault={false} ref={this.groundRef}/>

          <Group y={-99}>

            <Group
              ref={this.boxLeftRef}
              x={100}
              draggable
              dragBoundFunc={(pos) => boxDragBoundFunc(this, pos, 'boxLeft')}
              onMouseDown={() => this.data.boxLeftDragMove = true}
              onTouchStart={() => this.data.boxLeftDragMove = true}
              onDragStart={(e) => {onDragStart(this, e, 'boxLeft')}}
              onDragMove={(e) => {onDragMove(this, e, 'boxLeft')}}
              onDragEnd={(e) => {onDragEnd(this, e, 'boxLeft')}}
            >
              <Image {...boxStngs} ref={this.boxLeftImgRef}/>
              <Rect {...rightBindBox} ref={this.boxLeftHorizontalBindBoxRef}/>
              <Rect {...topBindBox} ref={this.boxLeftTopBindBoxRef} />
              <Rect {...bottomBindBox} ref={this.boxLeftBottomBindBoxRef} />
            </Group>

            <Group
              ref={this.boxRightRef}
              x={250}
              draggable
              dragBoundFunc={(pos) => boxDragBoundFunc(this, pos, 'boxRight')}
              onMouseDown={() => this.data.boxRightDragMove = true}
              onTouchStart={() => this.data.boxRightDragMove = true}
              onDragStart={(e) => {onDragStart(this, e, 'boxRight')}}
              onDragMove={(e) => {onDragMove(this, e, 'boxRight')}}
              onDragEnd={(e) => {onDragEnd(this, e, 'boxRight')}}
            >
              <Image image={smallHook} x={-18} y={42}/>
              <Image {...boxStngs}  ref={this.boxRightImgRef}/>
              <Rect {...rightBindBox} ref={this.boxRightHorizontalBindBoxRef} />
              <Rect {...topBindBox} ref={this.boxRightTopBindBoxRef}/>
              <Rect {...bottomBindBox} ref={this.boxRightBottomBindBoxRef}/>
            </Group>
          </Group>
        </Group>

        <Group
          x={650} y={435}
          draggable
          dragBoundFunc={(pos) => dynomomDragBoundFunc(this, pos)}
          onMouseDown={() => this.data.dynamometerDragMove = true}
          onTouchStart={() => this.data.dynamometerDragMove = true}
          onDragStart={(e) => {onDragStart(this, e, 'dynamometer')}}
          onDragMove={(e) => {onDragMove(this, e, 'dynamometer')}}
          onDragEnd={(e) => {onDragEnd(this, e, 'dynamometer')}}
          ref={this.dynamometerRef}
        >

          {/** Rect to expand the area of interaction with the dynamometer */}
          <Rect x={-50} y={-75} width={300} height={200} fill={'red'} opacity={0}/>

          <Group x={-50} y={11} ref={this.hookRef}>
            <Image width={200} height={13} image={hook} preventDefault={false}/>
            <Rect width={20} height={20} {...hookBindBox} ref={this.hookBindBoxRef}/>
          </Group>

          <Image width={220} height={50} image={dynamometer} preventDefault={false} />
          <Text text={this.lang === 'en' ? '' : 'H'} {...textSettings} x={160} y={18} fontSize={12} fill={'rgba(255,255,255,.8)'} fontStyle={'bold'}/>
          <Image x={143} y={10} width={7} height={20} image={pointer} preventDefault={false} ref={this.pointerRef}/>
        </Group>


        <MovingArrow
          id={'hint1'}
          ref={this.dragDynamometerHintRef}
          stageNode={this.stageRef?.current}
          length={40}
          arrowsCount={2}
          x={820} y={365}
          textX={-35} textY={-20}
          textColor={'gray'}
          arrowColor={'gray'}
          text={_t('dynamometer.Tip_text2')}
          rotation={0}
          visible={false}
          getMovingCallback={this.getMovingCallback}
        />

        <MovingArrow
          id={'hint2'}
          ref={this.dragBoxHintRef}
          stageNode={this.stageRef?.current}
          length={40}
          x={415} y={450}
          textX={10} textY={0}
          textColor={'gray'}
          arrowColor={'gray'}
          text={_t('dynamometer.Tip_text1')}
          rotation={90}
          visible={false}
          getMovingCallback={this.getMovingCallback}
        />

        <MovingArrow
          id={'hint3'}
          ref={this.bindBoxHintRef}
          stageNode={this.stageRef?.current}
          length={40}
          x={35} y={300}
          arrowsCount={2}
          arrowsStep={150}
          textX={10} textY={0}
          textColor={'gray'}
          arrowColor={'gray'}
          arrowsX={110} arrowsY={30}
          text={_t('dynamometer.Tip_text3')}
          visible={false}
          getMovingCallback={this.getMovingCallback}
        />



        <Group
          x={60} y={140}
          ref={this.fieldsBlockRef}
          visible={false}
          opacity={0}
        >
          <Text text={_t('dynamometer.Force_symbol')+'   ='} {...textSettings} x={0} />
          <Text text={_t('dynamometer.Force_symbol_small')} {...textSettings} x={15} y={15} fontSize={15}/>
          <CanvasInput
            id={'1'}
            x={60}
            width={60}
            height={30}
            fontSize={17}
            fontStyle={'bold'}
            stage={this.stageRef?.current}
            textColor={mainColor}
            onInput={(val) => this.onChangeInput(val, 'resistanceFInput')}
            value={this.data.resistanceFInput}
          />
          <Text text={'±'} {...textSettings} x={130}/>
          <CanvasInput
            id={'1'}
            x={155}
            width={60}
            height={30}
            fontSize={17}
            fontStyle={'bold'}
            stage={this.stageRef?.current}
            textColor={mainColor}
            onInput={(val) => this.onChangeInput(val, 'calcErrInput')}
            value={this.data.calcErrInput}
          />
          <Text text={_t('dynamometer.Dimension1')} {...textSettings} x={225}/>

          <CanvasButton
            text={_t('dynamometer.Submit_button')}
            x={270} y={0}
            onClick={() => this.checkResult()}
            fontSize={15}
            height={30}
            strokeWidth={.2}
            btnCornerRadius={0}
          />
        </Group>


        <Group
          x={60} y={150}
          ref={this.btnsContainerRef}
          visible={false} opacity={0}
        >
          <Group
            onClick={() => {
              this.data.resultBtnVal = 'depend';
              this.checkResult();
            }}
            onTap={() => {
              this.data.resultBtnVal = 'depend';
              this.checkResult();
            }}
          >
            <Rect
              ref={this.resultDependBtnRef}
              width={100}
              height={40}
              strokeWidth={1.5}
              stroke={mainColor}
            />
            <Text ref={this.resultDependBtnTxtRef} text={_t('dynamometer.Option1_text')} {...btnTextSetting}/>
          </Group>
          <Group
            x={100}
            onClick={() => {
              this.data.resultBtnVal = 'notDepend';
              this.checkResult();
            }}
            onTap={() => {
              this.data.resultBtnVal = 'notDepend';
              this.checkResult();
            }}
          >
            <Rect
              ref={this.resultNotDependBtnRef}
              width={100}
              height={40}
              strokeWidth={1.5}
              stroke={mainColor}
            />
            <Text ref={this.resultNotDependBtnTxtRef} text={_t('dynamometer.Option2_text')} {...btnTextSetting}/>
          </Group>
        </Group>
      </Group>
    )
  };


  render() {
    return (
      <div style={styles.background}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasDynamometer/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dynamometer);

const styles = {
  background: {
    background: '#F4F1DE'
  }
};
