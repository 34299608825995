import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import Konva from "konva";
import {
  Rect,
  Layer,
  Text,
  Group,
  Circle,
  Line,
  Image as KonvaImage,
  Arrow,
} from "react-konva";
import useImage from "use-image";
import { connect } from "react-redux";
import Victor from "victor";
import CanvasInput from "../../../canvas/components/CanvasInput";
import Card from "../../../canvas/components/Card";

import bigCaseImage from "../../../../images/hydraulicPress/big_case.png";
import leftCaseImage from "../../../../images/hydraulicPress/left_case.png";
import rightCaseImage from "../../../../images/hydraulicPress/right_case.png";
import standImage from "../../../../images/hydraulicPress/stand.png";
import barImage from "../../../../images/hydraulicPress/bar.png";
import fakeBarImage from "../../../../images/hydraulicPress/fake_bar.png";
import smallPistonImage from "../../../../images/hydraulicPress/small_piston.png";
import bigPistonImage from "../../../../images/hydraulicPress/big_piston.png";
import cylinderImage from "../../../../images/hydraulicPress/cylinder.png";
import topBarImage from "../../../../images/hydraulicPress/top_bar.png";
import pointerImage from "../../../../images/hydraulicPress/pointer.png";
import largeCylinderImage from "../../../../images/hydraulicPress/large_cylinder.png";

class HydraulicPress extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();
    this.arrowGroupRef = React.createRef();
    this.dynamicLayerRef = React.createRef();
    this.barRef = React.createRef();
    this.fakebarRef = React.createRef();
    this.leftLiquidRef = React.createRef();
    this.rightLiquidRef = React.createRef();
    this.smallPistonRef = React.createRef();
    this.bigPistonRef = React.createRef();
    this.cylinderRef = React.createRef();
    this.topBarRef = React.createRef();
    this.arrowLayerRef = React.createRef();
    this.uiLayerRef = React.createRef();
    this.leftCaseRef = React.createRef();
    this.rightCaseRef = React.createRef();
    this.leftInputRef = React.createRef();
    this.bottomLineRef = React.createRef();
    this.secondLineRef = React.createRef();
    this.firstLineRef = React.createRef();
    this.miniLiquidRef = React.createRef();
    this.successTextRef = React.createRef();
    this.pointerRef = React.createRef();
    this.largeCylinderRef = React.createRef();
    this.heightArrowRef = React.createRef();
    this.heightTextRef = React.createRef();
    this.instructionRef = React.createRef();
    this.F1Ref = React.createRef();
    this.F2Ref = React.createRef();
    this.hRef = React.createRef();

    this.stand = {
      x: 340,
      y: 580,
    };

    this.shell = {
      x: 350,
      y: 350,
    };

    this.smallpiston = {
      x: 354,
      y: 350,
      S: 10,
      F: 0,
      initialX: 354,
      initialY: 350,
      dy: 0,
    };

    this.bigpiston = {
      x: 525,
      y: 350,
      S: 50,
      F: 0,
      initialX: 525,
      initialY: 350,
      dy: 0,
    };

    this.bar = {
      initialX: 300 + 148 / 2,
      initY: 230 + 127 / 2,
      x: 300 + 148 / 2,
      y: 230 + 127 / 2,
      offsetX: 148 / 2,
      offsetY: 127 / 2,
    };

    this.topbar = {
      x: 510,
      y: 300,
    };

    this.cylinder = {
      x: 595,
      y: 326,
      width: 24,
      height: 24,
      F: 0,
      Fthr: 1000,
    };

    this.leftliquid = {
      initialX: this.smallpiston.x,
      initialY: this.smallpiston.y + 27,
      height: 202,
      width: 40,
      initialWidth: 40,
    };

    this.rightliquid = {
      initialX: this.bigpiston.x,
      initialY: this.bigpiston.y + 27,
      height: 202,
      width: 166,
    };

    this.leftcase = {
      x: 353,
      y: 352,
    };

    this.rightcase = {
      x: 373,
      y: 352,
    };

    this.bigcase = {
      x: 521.5,
      y: 350,
    };

    this.largecylinder = {
      x: 681,
      y: 111,
      height: 100,
    };
    this.const = {
      stif: 50000,
      fail: false,
      success: false,
      stop: false,
      start: false,
    };

    this.debug = {
      value: 0,
    };

    this.display = {
      F1: 0,
      F2: 0,
      h: this.cylinder.height / 24 * 10
    }
  }

  componentDidMount() {
    this.RAF = window.requestAnimationFrame(this.move);
    this.getNodes();
    this.updateImages();
    this.introstage = 1;
    this.introRAF = window.requestAnimationFrame(this.intro);
  }

  move = (time) => {
    const stageNode = this.stageRef?.current;
    const arrowLayer = this.arrowLayerRef?.current;

    const arrow = this.arrow;
    const arrowText = this.arrowText;

    const bigArrow = this.bigArrow;
    const bigArrowText = this.bigArrowText;

    const backArrow = this.backArrow;
    const backArrowText = this.backArrowText;

    let extForce = 0;

    //Draw Arrow and Text

    if (arrow && arrowText) {
      this.mousepos = stageNode.getPointerPosition();

      let delta = this.mousepos.y - this.dragStartPos.y;
      delta = delta >= 0 ? delta : 0;

      extForce = delta;

      let points = [];
      delta > 300 ? (points = [0, 0, 0, 300]) : (points = [0, 0, 0, delta]);
      delta <= 0 ? (points = [0, 0, 0, 0.1]) : (points = points);

      this.arrow.setAttr("points", points);

      let x = this.bar.x + 20;
      let y = delta >= 300 ? this.dragStartPos.y + 300 : this.mousepos.y;
      y = y < this.dragStartPos ? this.dragStartPos : y;

      this.arrowText.position({ x: x, y: y });
      this.arrowText.setAttr("text", `F: ${Math.round(points[3])}Н`);
      arrowLayer.draw();
    }

    extForce = extForce > 300 ? 300 : extForce;

    this.display.F1 = extForce;

    let bigForce = extForce * (this.bigpiston.S / this.smallpiston.S);

    let backForce =
      bigForce <= this.cylinder.Fthr ? bigForce : this.cylinder.Fthr;

    this.display.F2 = bigForce;

    let eqForce = bigForce - backForce;

    // if (bigArrow && bigArrowText) {
    //   let points = [0, 0, 0, -Math.abs(Math.round(bigForce/10))];
    //   bigArrow.setAttr('points', points);

    //   let x = bigArrow.position().x + 20;
    //   let y = bigArrow.position().y + points[3];

    //   bigArrowText.position({x: x, y: y});
    //   bigArrowText.setAttr('text', `F: ${Math.round(bigForce)}Н`);
    //   arrowLayer.draw();
    // }

    if (this.backArrow && this.backArrowText) {
      // let point = 0;
      // point = bigForce < this.cylinder.Fthr ? bigForce : this.cylinder.Fthr;
      let points = [0, 0, 0, Math.abs(Math.round(bigForce / 10))];

      backArrow.setAttr("points", points);

      let x = backArrow.position().x + 20;
      let y = backArrow.position().y + points[3];

      backArrowText.position({ x: x, y: y });
      //backArrowText.setAttr("text", `F: ${Math.round(point)}Н`);

      arrowLayer.draw();
    }

    let dH = eqForce / this.const.stif;

    if (dH < 0.05 && dH != 0) {
      dH = 0.05;
    }

    let dh = (dH * this.bigpiston.S) / this.smallpiston.S;

    this.bigpiston.dy += dH;
    //this.cylinder.dy += dH;
    this.smallpiston.dy += dh;
    this.arrowdy += dh;

    this.display.h = Math.round((this.cylinder.height - this.bigpiston.dy) / 24 * 10 * 100) / 100;
    //console.log(this.display.h);

    this.updateStage();
    // const uiLayer = this.uiLayerRef?.current;
    // uiLayer.draw();
    window.requestAnimationFrame(this.move);
  };

  updateImages() {
    if (this.const.stop) {
      return;
    }

    this.smallpiston.node.setAttr(
      "y",
      this.smallpiston.y + this.smallpiston.dy
    );
    this.leftliquid.node.setAttr(
      "y",
      this.leftliquid.initialY + this.smallpiston.dy
    );
    this.leftliquid.node.setAttr(
      "height",
      this.leftliquid.height - this.smallpiston.dy
    );

    this.bigpiston.node.setAttr("y", this.bigpiston.y - this.bigpiston.dy);
    this.rightliquid.node.setAttr(
      "y",
      this.rightliquid.initialY - this.bigpiston.dy
    );
    this.rightliquid.node.setAttr(
      "height",
      this.rightliquid.height + this.bigpiston.dy
    );
    this.cylinder.node.setAttr(
      "height",
      this.cylinder.height - this.bigpiston.dy
    );
    this.cylinder.node.setAttr(
      "width",
      this.cylinder.height + this.bigpiston.dy
    );
    this.cylinder.node.setAttr("x", this.cylinder.x - this.bigpiston.dy / 2);

    this.bar.node.setAttr("y", this.bar.y + this.smallpiston.dy);
    if (this.arrow) {
      this.arrow.setAttr("y", this.arrowStart.y + this.arrowdy);
    }
    // if (this.bigArrow) {
    //   this.bigArrow.setAttr('y', this.cylinder.y +
    //   this.cylinder.node?.getAttr('height'));
    // }
    if (this.backArrow) {
      this.backArrow.setAttr(
        "y",
        this.cylinder.y + this.cylinder.node?.getAttr("height")
      );
    }

    this.leftliquid.node.setAttr("width", this.leftliquid.width);
    this.leftliquid.node.setAttr(
      "x",
      this.leftliquid.initialX +
      this.leftliquid.initialWidth / 2 -
      this.leftliquid.width / 2
    );

    const rightCase = this.rightCaseRef?.current;
    rightCase.setAttr(
      "x",
      this.leftliquid.node.getAttr("x") +
      this.leftliquid.node.getAttr("width") -
      24
    );
    //console.log("width: ", rightCase.getAttr("width"));
    const leftCase = this.leftCaseRef?.current;
    leftCase.setAttr("x", this.leftliquid.node.getAttr("x"));

    this.smallpiston.node.setAttr("width", this.leftliquid.width);
    this.smallpiston.node.setAttr(
      "x",
      this.smallpiston.x +
      this.leftliquid.initialWidth / 2 -
      this.leftliquid.width / 2
    );

    const bottomLine = this.bottomLineRef?.current;
    bottomLine.setAttr("x", this.leftliquid.node.getAttr("x") + 1);
    bottomLine.setAttr("points", [
      0,
      0,
      this.leftliquid.node.getAttr("width") - 1,
      0,
    ]);

    const firstLine = this.firstLineRef?.current;
    firstLine.setAttr(
      "x",
      this.leftliquid.node.getAttr("x") + this.leftliquid.node.getAttr("width")
    );
    firstLine.setAttr("points", [
      0,
      0,
      this.bigcase.x - firstLine.getAttr("x"),
      0,
    ]);

    const secondLine = this.secondLineRef?.current;
    secondLine.setAttr(
      "x",
      this.leftliquid.node.getAttr("x") + this.leftliquid.node.getAttr("width")
    );
    secondLine.setAttr("points", [
      0,
      0,
      this.bigcase.x - secondLine.getAttr("x"),
      0,
    ]);

    const miniLiquid = this.miniLiquidRef?.current;
    miniLiquid.setAttr("x", secondLine.getAttr("x") - 5);
    miniLiquid.setAttr("width", secondLine.getAttr("points")[2] + 10);

    const largeCylinder = this.largeCylinderRef?.current;
    largeCylinder.setAttr(
      "height",
      (this.cylinder.node.getAttr("height") / 24) * 100
    );
    largeCylinder.setAttr(
      "y",
      this.largecylinder.y - largeCylinder.getAttr("height") + 100
    );

    const heightArrow = this.heightArrowRef?.current;
    heightArrow.setAttr("points", [0, 0, 0, -largeCylinder.getAttr("height")]);

    const heightText = this.heightTextRef?.current;
    heightText.setAttr(
      "y",
      this.largecylinder.y +
      this.largecylinder.height -
      largeCylinder.getAttr("height") / 2
    );

    if (this.dragStartPos) {
      this.dragStartPos.y = this.arrowStart.y + this.arrowdy;
    }

    const F1 = this.F1Ref?.current;
    F1.setAttr('text', `${this.display.F1}`);

    const F2 = this.F2Ref?.current;
    F2.setAttr('text', `${this.display.F2}`);

    const h = this.hRef?.current;
    h.setAttr('text', `${this.display.h ? this.display.h : 0}`);
    console.log('UI: ', this.display);

    const dynamicLayer = this.dynamicLayerRef?.current;
    const arrowLayer = this.arrowLayerRef?.current;
    const uiLayer = this.uiLayerRef?.current;

    dynamicLayer.draw();
    arrowLayer.draw();
    uiLayer.draw();
  }

  restartScene = () => {
    this.getNodes();

    this.smallpiston.dy = 0;
    this.bigpiston.dy = 0;
    this.arrowdy = 0;

    this.const.fail = false;
    this.const.success = false;
    this.const.stop = false;

    const fakebar = this.fakebarRef?.current;
    let pos = { x: this.bar.x, y: this.bar.y };
    fakebar.position(pos);

    this.display.F1 = 0;
    this.display.F2 = 0;
    this.display.h = 10.0;

    window.cancelAnimationFrame(this.introRAF);
    this.introstage = 1;
    if (this.introPrevTime) { delete this.introPrevTime; }
    if (this.introStartTime) { delete this.introStartTime; }
    this.introRAF = window.requestAnimationFrame(this.intro);

    this.updateImages();
  };

  getNodes() {
    if (!this.smallpiston.node) {
      this.smallpiston.node = this.smallPistonRef?.current;
    }
    if (!this.bigpiston.node) {
      this.bigpiston.node = this.bigPistonRef?.current;
    }
    if (!this.leftliquid.node) {
      this.leftliquid.node = this.leftLiquidRef?.current;
    }
    if (!this.rightliquid.node) {
      this.rightliquid.node = this.rightLiquidRef?.current;
    }
    if (!this.bar.node) {
      this.bar.node = this.barRef?.current;
    }
    if (!this.cylinder.node) {
      this.cylinder.node = this.cylinderRef?.current;
    }
  }

  updateStage() {
    this.getNodes();

    if (!this.arrow || !this.arrowText) {
      return;
    }
    if (this.cylinder.node?.getAttr("height") <= 12) {
      this.const.success = true;
      this.bigpiston.dy = this.cylinder.height - 12;
      this.smallpiston.dy =
        (this.bigpiston.dy * this.bigpiston.S) / this.smallpiston.S;
    }
    let y = this.smallpiston.node?.getAttr("y");
    if (y >= 529) {
      this.const.fail = true;
      let newy = 529;
      this.smallpiston.dy = newy - this.smallpiston.y;
      this.bigpiston.dy =
        (this.smallpiston.dy * this.smallpiston.S) / this.bigpiston.S;
    }

    //if (this.const.stop) { return; }
    if (this.const.fail) {
      this.finish();
      return;
    }
    if (this.const.success) {
      this.finish();
      return;
    }

    // this.leftInputRef?.current.value(this.smallPistonRef.S);

    this.updateImages();
  }

  finish() {
    window.cancelAnimationFrame(this.RAF);
    this.display.F1 = 0;
    this.display.F2 = 0;
    //const e = this.fakebarRef?.current;
    console.log('finish: ', this.display);
    this.onDragEnd(null);
    this.const.stop = true;
    //this.updateImages();
    // const stage = this.stageRef?.current;
    // stage.draw();

  }

  intro = (time) => {

    const dynamicLayer = this.dynamicLayerRef?.current;
    const instructionText = this.instructionRef?.current;

    if (!this.introPrevTime) {
      this.introPrevTime = time;
      this.introStartTime = time;
      instructionText.setAttr('text', "Чем меньше радиус поршня 1,\nтем с большей силой можно оказывать\nвоздействие на стальной брусок");
      instructionText.setAttr('opacity', 1);
    }
    let dT = time - this.introPrevTime;
    this.introPrevTime = time;
    let dTotal = time - this.introStartTime;

    if (dTotal <= 2000) {
      this.introRAF = window.requestAnimationFrame(this.intro);
      dynamicLayer.draw();
      return;
    }


    let opacity = instructionText.getAttr("opacity");
    console.log('opacity: ', opacity);

    if (this.introstage == 1) {
      opacity -= dT / 1000;
      //console.log('opacity, dT', opacity, dT);

      if (opacity <= 0) {
        opacity = 0;
        this.introstage = 2;
        instructionText.setAttr('text',
          'Определи максимальный радиус поршня 1 (до двух знаков после запятой), который позволит сжать стальной брусок. Брусок можно сжимать произвольное количество раз: просто нажми “Заменить бурсок” чтобы его поменять');
        instructionText.setAttr('width', 600);
      } else {
        this.introRAF = window.requestAnimationFrame(this.intro);
      }

      instructionText.setAttr("opacity", opacity);

    }
    if (this.introstage == 2) {
      opacity += dT / 1000;
      if (opacity >= 1) {
        opacity = 1;
        window.cancelAnimationFrame(this.introRAF);
        delete (this.introPrevTime);
        delete (this.introStartTime);
      } else {
        this.introRAF = window.requestAnimationFrame(this.intro);
      }
      instructionText.setAttr('opacity', opacity);
    }

    dynamicLayer.draw();
  };

  onDragStart = (e) => {

    const stageNode = this.stageRef?.current;
    this.dragStartPos = stageNode.getPointerPosition();
    this.arrowdy = 0;
    this.arrowStart = Object.assign({}, this.dragStartPos);
    this.drag = true;

    if (!this.arrow) {
      let arrow = new Konva.Arrow({
        x: this.bar.x + 1.5,
        y: this.dragStartPos.y, //this.bar.y - this.bar.offsetY+3,
        stroke: "#406C9C",
        fill: "#406C9C",
        strokeWidth: 5,
        opacity: 0.8,
      });

      const arrowGroup = this.arrowGroupRef?.current;
      arrowGroup.add(arrow);
      this.arrow = arrow;
    }

    if (!this.arrowText) {
      let arrowText = new Konva.Text({
        fontFamily: "Arial",
        fontSize: 24,
        fill: "#406C9C",
      });

      this.arrowText = arrowText;
      const arrowGroup = this.arrowGroupRef?.current;
      arrowGroup.add(arrowText);
    }

    // if (!this.bigArrow) {

    //   let bigArrow = new Konva.Arrow({
    //     x: this.cylinder.x + this.cylinder.width/2,
    //     y: this.cylinder.y + this.cylinder.node?.getAttr('height'),//this.bar.y - this.bar.offsetY+3,
    //     stroke: 'orange',
    //     fill: 'orange',
    //     strokeWidth: 15,
    //     opacity: 0.5
    //   })

    //   const arrowGroup = this.arrowGroupRef?.current;
    //   arrowGroup.add(bigArrow);
    //   this.bigArrow = bigArrow;
    // }

    // if (!this.bigArrowText) {

    //   let bigArrowText = new Konva.Text({
    //     fontFamily: 'Arial',
    //     fontSize: 24,
    //     fill: 'orange',
    //     strokeWidth: 0.5,
    //     stroke: 'red'
    //   })

    //   this.bigArrowText = bigArrowText;
    //   const arrowGroup = this.arrowGroupRef?.current;
    //   arrowGroup.add(bigArrowText);
    // }

    if (!this.backArrow) {
      let backArrow = new Konva.Arrow({
        x: this.cylinder.x + this.cylinder.width / 2,
        y: this.cylinder.y + this.cylinder.node?.getAttr("height"), //this.bar.y - this.bar.offsetY+3,
        stroke: "#FF8E78",
        fill: "#FF8E78",
        strokeWidth: 15,
        opacity: 0.8,
      });

      const arrowGroup = this.arrowGroupRef?.current;
      arrowGroup.add(backArrow);
      this.backArrow = backArrow;
    }

    if (!this.backArrowText) {
      let backArrowText = new Konva.Text({
        fontFamily: "Arial",
        fontSize: 24,
        fill: "#FF8E78",
        stroke: "darkred",
        strokeWidth: 0.4,
      });

      this.backArrowText = backArrowText;
      const arrowGroup = this.arrowGroupRef?.current;
      arrowGroup.add(backArrowText);
    }
  };

  onDragMove = (e) => {
    if (e.target.getAttr("id") != "fakebar") {
      return;
    }

    //console.log("big Arrow: ", this.bigArrow);
  };

  onDragEnd = (e) => {
    this.drag = false;

    if (this.arrow) {
      this.arrow.destroy();
      delete this.arrow;
    }

    if (this.arrowText) {
      this.arrowText.destroy();
      delete this.arrowText;
    }

    // this.bigArrow.destroy();
    // delete this.bigArrow;

    // this.bigArrowText.destroy();
    // delete this.bigArrowText;

    if (this.backArrow) {
      this.backArrow.destroy();
      delete this.backArrow;
    }

    if (this.backArrowText) {
      this.backArrowText.destroy();
      delete this.backArrowText;
    }

    let pos = this.bar.node?.position();
    const fakeBar = this.fakebarRef?.current;
    fakeBar.position(pos);
    //e.target.position(pos);

    this.display.F1 = 0;
    this.display.F2 = 0;
    console.log('ODE: ', this.display);
    this.updateImages();

    // const dynamicLayer = this.dynamicLayerRef?.current;
    // dynamicLayer.draw();
    // const arrowLayer = this.arrowLayerRef?.current;
    // arrowLayer.draw();
    // const uiLayer = this.uiLayerRef?.current;
    // uiLayer.draw();
  };

  onDragDebug = (e) => {
    //console.log("pos: ", e.target.position());
  };

  onInputDebug = (value) => {
    const input = this.leftInputRef?.current;
    console.log("debug on input: ", value);

    // if (value < 5) {
    //   value = 5;
    //   //input.setAttr('value', 5);
    // }
    if (value > 25) {
      value = 25;
      //input.setAttr('value', 25);
    }
    this.smallpiston.S = value;
    this.leftliquid.width = value * 4;

    this.restartScene();
    this.forceUpdate();
  };

  Canvas = () => {
    const [stand] = useImage(standImage);
    const [bar] = useImage(barImage);
    const [fakebar] = useImage(fakeBarImage);
    const [smallpiston] = useImage(smallPistonImage);
    const [bigpiston] = useImage(bigPistonImage);
    const [topbar] = useImage(topBarImage);
    const [cylinder] = useImage(cylinderImage);
    const [leftcase] = useImage(leftCaseImage);
    const [rightcase] = useImage(rightCaseImage);
    const [bigcase] = useImage(bigCaseImage);
    const [pointer] = useImage(pointerImage);
    const [largecylinder] = useImage(largeCylinderImage);

    return (
      <React.Fragment>
        <Layer ref={this.backgroundLayerRef}>
          <Group>
            <KonvaImage image={stand} x={this.stand.x} y={this.stand.y} />
          </Group>
        </Layer>
        <Layer ref={this.dynamicLayerRef}>
          <Group>
            <Rect
              ref={this.leftLiquidRef}
              x={this.smallpiston.x - 1}
              y={27 + this.smallpiston.y}
              height={202}
              width={41}
              fill={"#CAE9FF"}
            />
            <Rect
              ref={this.rightLiquidRef}
              x={this.bigpiston.x}
              y={27 + this.bigpiston.y}
              height={202}
              width={166}
              fill={"#CAE9FF"}
            />

            <Line
              ref={this.bottomLineRef}
              x={this.leftliquid.initialX}
              y={this.stand.y - 2}
              stroke={"#4CC9F0"}
              strokeWidth={2.2}
              points={[0, 0, 0, 0]}
            />
            <Line
              x={this.leftliquid.initialX + this.leftliquid.initialWidth}
              ref={this.firstLineRef}
              y={this.stand.y - 35}
              stroke={"#4CC9F0"}
              strokeWidth={2.2}
              points={[
                0,
                0,
                this.bigpiston.x -
                this.leftliquid.initialX -
                this.leftliquid.initialWidth,
                0,
              ]}
            />
            <Line
              x={this.rightcase.x}
              ref={this.secondLineRef}
              y={this.stand.y - 25}
              stroke={"#4CC9F0"}
              strokeWidth={2.2}
              points={[
                0,
                0,
                this.bigpiston.x -
                this.leftliquid.initialX -
                this.leftliquid.initialWidth,
                0,
              ]}
            />

            <KonvaImage
              image={leftcase}
              ref={this.leftCaseRef}
              x={this.leftcase.x}
              y={this.leftcase.y}
              onDragMove={this.onDragDebug}

            />
            <KonvaImage
              image={rightcase}
              ref={this.rightCaseRef}
              x={this.rightcase.x}
              y={this.rightcase.y}
              onDragMove={this.onDragDebug}

            />
            <KonvaImage
              image={bigcase}
              x={this.bigcase.x}
              y={this.bigcase.y}
              onDragMove={this.onDragDebug}

            />
            <Rect
              ref={this.miniLiquidRef}
              x={this.leftliquid.initialX + this.leftliquid.initialWidth}
              y={this.stand.y - 35}
              height={10}
              fill={"#CAE9FF"}
            />
            <KonvaImage
              image={smallpiston}
              ref={this.smallPistonRef}
              //onDragMove={this.onDragDebug}
              //draggable={true}
              x={this.smallpiston.x}
              y={this.smallpiston.y}
            />
            <KonvaImage
              image={bigpiston}
              ref={this.bigPistonRef}
              //onDragMove={this.onDragDebug}
              //draggable={true}
              x={this.bigpiston.x}
              y={this.bigpiston.y}
            />
            <KonvaImage
              image={cylinder}
              ref={this.cylinderRef}
              //onDragMove={this.onDragDebug}
              //draggable={true}
              x={this.cylinder.x}
              y={this.cylinder.y}
            />
            <KonvaImage
              image={pointer}
              ref={this.pointerRef}
              x={626}
              y={213}
              //draggable={true}
              onDragMove={this.onDragDebug}
            />
            <KonvaImage
              image={largecylinder}
              ref={this.largeCylinderRef}
              x={this.largecylinder.x}
              y={this.largecylinder.y}
              draggable={true}
              onDragMove={this.onDragDebug}
            />
            <Arrow
              ref={this.heightArrowRef}
              x={this.largecylinder.x + this.largecylinder.height + 20}
              y={this.largecylinder.y + this.largecylinder.height}
              points={[0, 0, 0, -this.largecylinder.height]}
              fill={"#406C9C"}
              stroke={"#406C9C"}
              strokeWidth={3}
              pointerAtBeginning={true}
            />
            <Text
              ref={this.heightTextRef}
              fontFamily={"Arial"}
              offsetY={12}
              fontSize={24}
              fill={"#406C9C"}
              text={"h"}
              x={this.largecylinder.x + this.largecylinder.height + 30}
              y={this.largecylinder.y + this.largecylinder.height / 2}
            />
            <KonvaImage
              image={topbar}
              ref={this.topBarRef}
              //onDragMove={this.onDragDebug}
              //draggable={true}
              x={this.topbar.x}
              y={this.topbar.y}
            />
            <KonvaImage
              image={bar}
              ref={this.barRef}
              //onDragMove={this.onDragDebug}
              //draggable={true}
              x={this.bar.x}
              y={this.bar.y}
              offsetX={this.bar.offsetX}
              offsetY={this.bar.offsetY}
            />
            <KonvaImage
              ref={this.fakebarRef}
              id={"fakebar"}
              image={fakebar}
              draggable={true}
              x={this.bar.x}
              y={this.bar.y}
              onDragStart={this.onDragStart}
              onDragMove={this.onDragMove}
              onDragEnd={this.onDragEnd}
              offsetX={this.bar.offsetX}
              offsetY={this.bar.offsetY}
              opacity={0.5}
            />

            <Text
              ref={this.instructionRef}
              fontSize={24}
              fill={"#406C9C"}
              text={
                "Чем меньше радиус поршня 1,\nтем с большей силой можно оказывать\nвоздействие на стальной брусок"
              }
              x={60}
              y={60}
              opacity={1}
            />
          </Group>
        </Layer>

        <Layer ref={this.arrowLayerRef}>
          <Group ref={this.arrowGroupRef} />
        </Layer>

        <Layer ref={this.uiLayerRef}>
          <Group x={755} y={255}>
            <Rect
              strokeWidth={1}
              fill={"#406C9C"}
              width={170}
              height={40}
              cornerRadius={3}
            />
            <Text
              text={`Начать заново`}
              fill={'#F0EBE4'}
              fontSize={20}
              align={"center"}
              verticalAlign={"middle"}
              width={170}
              height={40}
              onClick={this.restartScene}
            />
          </Group>

          <Card height={250} width={170} x={110} y={231}>
            <Group x={20} y={20}>
              <Text
                text={`Оказываемая на первый поршень сила (F1), Н:`}
                fill={'#FF8E78'}
                fontSize={16}
                align={"left"}
                width={140}
              />
              <Text
                ref={this.F1Ref}
                y={55}
                offsetX={-1}
                offsetY={-1.5}
                text={`${this.display.F1}`}
                stroke={"#FF8E78"}
                fill={"#FF8E78"}
                strokeWidth={0.1}
                fontSize={30}
                width={90}
                height={30}
                verticalAlign={"middle"}
              // align={'center'}
              />
              <Text
                y={120}
                text={`Радиус поршня 1, см:`}
                fill={'#FF8E78'}
                fontSize={16}
                align={"left"}
                width={120}
              />


              {console.log('debug on rerendered', this.smallpiston.S)}
              <CanvasInput
                ref={this.leftInputRef}
                y={165}
                width={90}
                height={30}
                stage={this.stageRef?.current}
                textColor={"#FF8E78"}
                onInput={this.onInputDebug}
                value={this.smallpiston.S}
              />
            </Group>
          </Card>

          <Card height={310} width={170} x={755} y={300}>
            <Group x={20} y={20}>

              <Text
                text={`Оказываемая на брусок сила (F2), Н:`}
                fill={'#FF8E78'}
                fontSize={16}
                align={"left"}
                width={120}
              />
              <Group y={57}>
                <Rect
                  //stroke={"rgba(0,0,0,.3)"}
                  strokeWidth={1}
                  fill={"transparent"}
                  width={90}
                  height={30}
                  cornerRadius={3}
                />
                <Text
                  ref={this.F2Ref}
                  offsetX={-1}
                  offsetY={-1.5}
                  text={`${this.bigpiston.S}`}
                  stroke={"#FF8E78"}
                  fill={"#FF8E78"}
                  strokeWidth={0.1}
                  fontSize={30}
                  width={90}
                  height={30}
                  verticalAlign={"middle"}
                // align={'center'}
                />
              </Group>
              <Text
                y={110}
                text={`Высота бруска (h), см:`}
                fill={'#FF8E78'}
                fontSize={16}
                align={"left"}
                width={120}
              />
              <Group y={150}>
                <Rect
                  //stroke={"rgba(0,0,0,.3)"}
                  strokeWidth={1}
                  fill={"transparent"}
                  width={90}
                  height={30}
                  cornerRadius={3}
                />
                <Text
                  ref={this.hRef}
                  offsetX={-1}
                  offsetY={-1.5}
                  text={`${this.bigpiston.S}`}
                  stroke={"#FF8E78"}
                  fill={"#FF8E78"}
                  strokeWidth={0.1}
                  fontSize={30}
                  width={90}
                  height={30}
                  verticalAlign={"middle"}
                // align={'center'}
                />
              </Group>
              <Text
                y={200}
                text={`Радиус второго цилиндра, см:`}
                fontSize={16}
                fill={'#FF8E78'}
                width={130}
                align={"left"}
              />
              <Group y={240}>
                <Rect
                  //stroke={"rgba(0,0,0,.3)"}
                  strokeWidth={1}
                  fill={"transparent"}
                  width={90}
                  height={30}
                  cornerRadius={3}
                />
                <Text

                  offsetX={-1}
                  offsetY={-1.5}
                  text={`${this.bigpiston.S}`}
                  stroke={"#FF8E78"}
                  fill={"#FF8E78"}
                  strokeWidth={0.1}
                  fontSize={30}
                  width={90}
                  height={30}
                  verticalAlign={"middle"}
                // align={'center'}
                />
              </Group>
            </Group>
          </Card>

          {/* <Card height={200} width={200}>

            <Group>

            </Group>


          </Card> */}
        </Layer>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.Canvas />
        </CanvasContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HydraulicPress);

const styles = {
  mainContainer: {
    background: "#F0EBE4",
  },
};
