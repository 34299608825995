import React from "react";
import {_t} from "../../../../utils/lang/common";
import button from "../../../../button.jpg"


export const EngineButton = (props) => {
  const {
    running,
    label,
    touchDown,
    touchUp
  } = props;

  return running && label === "training1" && (
      <button
          style={styles.btn}
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
          onMouseDown={touchDown}
          onMouseUp={touchUp}
      >{_t("engine_launch")}</button>
  )
};

const styles = {
  btn: {
    background: 'rgba(255, 255, 255, .9)',
    left: '30%',
    bottom: '7%',
    padding: '10px',
    fontSize: '2.5vmin',
    width: '30%',
    position: 'absolute',
    zIndex: 100
  },
};