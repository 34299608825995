import {Button} from "antd";
import React from "react";
import '../styles/customBtn.css';

/**
 * PROPS
 *  btnColor: purple | orange | green | red
 *  className: string
 *  btnText: string
 *  onClick: fn
*/

const CustomButton = (props) => {
  const color = props.btnColor || 'orange';
  return (
    <Button
      className={`custom-button custom-button--${color} ${props.className}`}
      onClick={() => props.onClick && props.onClick()}
      style={props.style}
    >
      {props.btnText}
    </Button>
  )
}
export default CustomButton;
