import {Modal} from "antd";
import React from "react";
import '../styles/modals.css';

const PopupVideoInstruction = (props) => (
  <Modal
    title={null}
    centered
    style={{margin: 0, padding: 0}}
    visible={props.visible}
    onCancel={() => props.setVisible(false)}
    width={800}
    wrapClassName={'modal-common big-white-cross'}
    footer={null}
  >
    <div style={{paddingBottom: '56.25%'}} />
    {
      props.visible && (
        <iframe
          src={'https://www.youtube.com/embed/-bUS1Gbb1Q0?rel=0&loop=1&playlist=CyC_2Q3USzE'}
          style={{
            position: 'absolute',
            left: 0, top: 0,
            width: '100%',
            height: '100%',
          }}
        />
      )
    }
  </Modal>
)

export default PopupVideoInstruction;
