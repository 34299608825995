import {getCrownLessonCodes} from "./utils/CrownUtils";
import React from "react";
import CrownEndMp4 from '../../../images/crown/Crown_end.mp4';
import weight20Img from "../../../images/crown/crownScale/weight20.png";
import weight100Img from "../../../images/crown/crownScale/weight100.png";
import weight1000Img from "../../../images/crown/crownScale/weight1000.png";


export const defaultPatchData = {
  lsnStep: false,
  questionStep: false,
  crownScaleStep: false,

  successPopupVisible: false,
  goToTheoryVisible: false,

  videoSrc: undefined,
  onVideoEnd: (comp) => {},

  theoryVisible: false,
  onClickSkipTheory: (comp) => {},
}


export const getScenarioByCode = (comp) => {
  const { course7_crown, lsnCrown5 } = getCrownLessonCodes(comp);
  if (course7_crown) {
    return scenario;
  }
  if (lsnCrown5) {
    return scenarioWithScale;
  }
  return [
    {key: 'start', patchData: {...defaultPatchData, lsnStep: true}},
    {key: 'success', patchData: {...defaultPatchData, lsnStep: true}}
  ];
}

export const scenario = [
  {
    key: 'start',
    patchData: {
      ...defaultPatchData,
      lsnStep: true,
    }
  },

  {
    key: 'question',
    patchData: {
      ...defaultPatchData,
      questionStep: true,
    }
  },

  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,

      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        comp.scenarioManager.selectStepByKey('start');
      },
    }
  },

  {
    key: 'animation',
    patchData: {
      ...defaultPatchData,

      videoSrc: CrownEndMp4,
      onVideoEnd: (comp) => {
        comp.scenarioManager.selectStepByKey('success');
      },
    }
  },

  {
    key: 'success',
    patchData: {
      ...defaultPatchData,

      goToTheoryVisible: true,
      successPopupVisible: true,
    }
  },
]

const rightWeightItems = [
  {id: 5, weight: 1000, img: weight1000Img, x: 0, height: 111, width: 75,},
  {id: 6, weight: 1000, img: weight1000Img, x: 85, height: 111, width: 75,},
  {id: 3, weight: 100, img: weight100Img, x: 170, height: 57, width: 54,},
  {id: 4, weight: 100, img: weight100Img, x: 230, height: 57, width: 54,},
  {id: 1, weight: 20, img: weight20Img, x: 290, height: 49, width: 33,},
  {id: 2, weight: 20, img: weight20Img, x: 330, height: 49, width: 33,},
];
export const scenarioWithScale = [
  {
    key: 'start',
    patchData: {
      ...defaultPatchData,
      lsnStep: true,
      rightWeightItems: rightWeightItems,
    },
    next_success: 'success'
  },

  {
    key: 'scale',
    patchData: {
      ...defaultPatchData,

      crownWeight: 1120,

      rightWeightItems: rightWeightItems,

      crownScaleStep: true,
    }
  },

  {
    key: 'success',
    patchData: {
      ...defaultPatchData,

      successPopupVisible: true
    }
  },
]