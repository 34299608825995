import {LSN_STEPS} from "../toricelliExperiment/utils";
import {hasIntersection, radToDeg} from "../../../utils/common";
import Victor from "victor";


export const dragBoundFunc = (pos, $this) => {
  const data = $this.data;
  const stageNode = $this.stageRef?.current;
  const stageWidth = stageNode.width();
  const stageHeight = stageNode.height();

  let leftBorderX = stageWidth;
  let rightBorderX = stageWidth;
  let topBorderY = stageHeight;
  let bottomBorderY = stageHeight;

  if (data.toricelliStep === 'initial') {
    leftBorderX = stageWidth * .2;
    rightBorderX = stageWidth * .9;
    topBorderY = stageHeight * .45;
    bottomBorderY = stageHeight * .45;
  }
  if (data.toricelliStep === 'rotation') {
    leftBorderX = stageWidth * .371;
    rightBorderX = stageWidth * .371;
    topBorderY = stageHeight * .235;
    bottomBorderY = stageHeight * .235;
  }

  const newX = pos.x >= rightBorderX ? rightBorderX : ( pos.x <= leftBorderX ? leftBorderX : pos.x );
  const newY = pos.y <= topBorderY ? topBorderY : ( pos.y >= bottomBorderY ? bottomBorderY : pos.y );
  return {y: newY, x: newX};
}


const getPlaceForColumnPos = ($this) => {
  const data = $this.data;
  const n = Object.fromEntries($this.managedComponents.map(key => [key, $this._getNode(key)]));

  const columnMercuryContainerNode = n['columnMercuryContainer'];
  const placeForColumnNode = n['placeForColumn'];
  const placeForColumnPos = placeForColumnNode.getAbsolutePosition();

  return {
    x: placeForColumnPos.x + columnMercuryContainerNode.offsetX(), y: placeForColumnPos.y + columnMercuryContainerNode.offsetY()
  }
}

const setColumnMercuryContainerStandPos = ($this) => {
  const data = $this.data;
  const columnMercuryContainerNode = $this._getNode('columnMercuryContainer');
  const scale = columnMercuryContainerNode.getAbsoluteScale();

  if (data.toricelliStep === 'rotation') {
    data.flaskRotation = 180;
  }
}

export const onDragMove = (e, $this) => setColumnMercuryContainerStandPos($this);
export const onDragStart = (e, $this) => setColumnMercuryContainerStandPos($this);

export const onDragEnd = (e, $this) => {
  const data = $this.data;
  const n = Object.fromEntries($this.managedComponents.map(key => [key, $this._getNode(key)]));

  const columnMercuryContainerNode = n['columnMercuryContainer'];
  const bindBoxColumnNode = n['bindBoxColumn'];
  const transformerNode = n['transformer'];

  const placeForColumnPos = getPlaceForColumnPos($this);
  const bindBoxPos = bindBoxColumnNode.getAbsolutePosition();

  const isColumnIntersectionWithBindBox = hasIntersection(bindBoxColumnNode, columnMercuryContainerNode, $this);
  if (isColumnIntersectionWithBindBox && data.toricelliStep === 'initial') {
    data.flaskCoords = Victor(placeForColumnPos.x, placeForColumnPos.y);
    $this.scenarioManager.selectStepByKey('experiment rotation');
  }
}

export const transformEnd = (e, $this) => {
  const data = $this.data;
  const n = Object.fromEntries($this.managedComponents.map(key => [key, $this._getNode(key)]));

  const columnMercuryContainerNode = n['columnMercuryContainer'];
  const transformerNode = n['transformer'];
  const targetRotation = e.target.attrs.rotation;
  const scale = columnMercuryContainerNode.getAbsoluteScale();

  if ((targetRotation < -160 || targetRotation > 160) && data.toricelliStep === 'rotation') {
    transformerNode.nodes([]);
    transformerNode.getLayer().batchDraw();
    columnMercuryContainerNode.rotation(0)
    setColumnMercuryContainerStandPos($this);

    $this.scenarioManager.selectStepByKey('experiment animation');
  }
}

export const transformBoundBoxFunc = (oldBox, newBox) => {
  const newBoxRotation = radToDeg(newBox.rotation);
  if (newBoxRotation >= 180 || newBoxRotation <= -180) {
    return oldBox;
  }
  return newBox;
}