import React from "react";
import Konva from "konva";
import {Layer, Line, Rect, Circle, Image as KonvaImage, Group, Text, RegularPolygon,} from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import redBallonImage from "../../../../images/brownianMotion/redGasCylinder.png";
import blueBallonImage from "../../../../images/brownianMotion/blueGasCylinder.png";
import pistonCase from "../../../../images/brownianMotion/pistonCase.png";
import indicatorImg from "../../../../images/brownianMotion/indicator.png";
import piston from "../../../../images/brownianMotion/pistion.png";
import tubeImg from "../../../../images/brownianMotion/tube.png";
import groundImg from "../../../../images/brownianMotion/ground.png";
import redBallIMage from "../../../../images/IdealGas/red_ball.png";
import blueBallImage from "../../../../images/IdealGas/blue_ball.png";
import Ball from "../components/Ball";
import Wall from "../components/Wall";
import SceneImage from "../../../../images/brownianMotion/scene.png";


import Victor from 'victor';
import useImage from "use-image";
import ArrowHint from "../../../canvas/components/ArrowHint";
import {layout2, lsnTitleTxtStyle, mainColor} from "../../../../utils/styles";
import Card from "../../../canvas/components/Card";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {sendSuccessForScenario, showFailOrSuccessPopup, toggleVisibleEl} from "../../../../utils/common";
import cloneDeep from "lodash.clonedeep";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import {getBoolLessonByCode, getTitles} from "../utils/brownianUtils";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";


class Brownian extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.mainLayerRef = React.createRef();
    this.ballGroupRef = React.createRef();
    this.wallGroupRef = React.createRef();
    this.backgroundGroupRef = React.createRef();
    this.lessonsElementsRef = React.createRef();
    this.dynamicLayerRef = React.createRef();
    this.backgroundLayerRef = React.createRef();
    this.pistonRef = React.createRef();

    this.arrowHintPistonRef = React.createRef();
    this.arrowHintBallonRef = React.createRef();
    this.arrowHintTemperatureRef = React.createRef();
    this.arrowHintIndicatorRef = React.createRef();
    this.cardWithFieldsRef = React.createRef();
    this.temperatureTxtRef = React.createRef();

    this.indicatorsGroupRef = React.createRef();

    this.titlesItems = getTitles(this.props.code);
    Object.keys(this.titlesItems.allTitles).forEach((k) => {
      this[`title-${k}Ref`] = React.createRef();
    });

    this.indicatorItems = ['pressure', 'volume'];
    this.indicatorItems.forEach((k) => {
      this[`indicator${k}Ref`] = React.createRef();
      this[`indicator${k}FakeRef`] = React.createRef();
      this[`indicatorValLine${k}Ref`] = React.createRef();
      this[`indicatorValLine${k}FakeRef`] = React.createRef();
    })

    //this.state = {};
    this.entities = {
      balls: [],
      walls: [],
      //piston: null,
      redBalls: 0,
      blueBalls: 0,
    };

    this.images = {
      pistoncase: {
        pos: Victor(200, 150),
        width: 683,
        height: 300,
        image: null
      },
      piston: {
        pos: Victor(100, 150).add(Victor(0, 300/2)),
        width: 10,
        height: 295,
        image: null
      },
      redBallon: {

      },
      blueBallon: {

      }
    }

    this.initialData = {
      indicators: {
        volume: {
          val: 200,
          disabled: false,
        },
        volumeFake: {
          val: 200,
          disabled: false,
        },
        pressure: {
          val: 0,
          disabled: false,
        },
        pressureFake: {
          val: 0,
          disabled: false,
        },
      },


      pistonOffset: 0,
      pistonStartTime: undefined,
      allBallonActivatedStartTime: undefined,

      step: 0,
      pistonMoved: false,
      arrowHintBallonRedActivated: false,
      arrowHintBallonBlueActivated: false,

      arrowHintPistonHidden: true,
      arrowHintTemperatureHidden: true,
      arrowHintIndicatorHidden: false,
      cardWithFieldsHidden: true,
      temperatureTxtHidden: true,
    }

    this.redGas = false;
    this.blueGas = false;
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  };

  setDefaultData = () => {
    let stageRef = this.stageRef?.current;
    stageRef.on('click', (e) => this.onClick(e));

    this.images.pistoncase.image = new Image();
    this.images.pistoncase.image.src = pistonCase;

    this.images.piston.image = new Image();
    this.images.piston.image.src = piston;

    this.images.redBallon.image = new Image();
    this.images.redBallon.image.src = redBallonImage;

    this.images.blueBallon.image = new Image();
    this.images.blueBallon.image.src = blueBallonImage;

    this.images.blueBallon.image.onload = (e) => {
      const backgroundLayer = this.backgroundLayerRef?.current;
      backgroundLayer.draw();
    }


    this.drawPiston();
    this.addWalls();
    //this.addPistons();
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.setDefaultData();

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  getNodes = () => {
    const titlesNodes = {};
    Object.keys(this.titlesItems.allTitles).forEach((k) => {
      titlesNodes[`title-${k}Node`] = this[`title-${k}Ref`]?.current;
    });

    const indicators = {};
    this.indicatorItems.forEach((k) => {
      indicators[`indicator${k}Node`] = this[`indicator${k}Ref`]?.current;
      indicators[`indicator${k}FakeNode`] = this[`indicator${k}FakeRef`]?.current;
      indicators[`indicatorValLine${k}Node`] = this[`indicatorValLine${k}Ref`]?.current;
      indicators[`indicatorValLine${k}FakeNode`] = this[`indicatorValLine${k}FakeRef`]?.current;
    })
    return ({
      ...titlesNodes,
      ...indicators,

      pistonNode: this.pistonRef?.current,

      indicatorsGroupNode: this.indicatorsGroupRef?.current,
      arrowHintPistonNode: this.arrowHintPistonRef?.current,
      arrowHintBallonNode: this.arrowHintBallonRef?.current,

      arrowHintTemperatureNode: this.arrowHintTemperatureRef?.current,
      cardWithFieldsNode: this.cardWithFieldsRef?.current,
      temperatureTxtNode: this.temperatureTxtRef?.current,
      arrowHintIndicatorNode: this.arrowHintIndicatorRef?.current,
    })
  }

  // onClickReset = () => {
  //   this.setDefaultData();
  // }

  drawPiston() {

    const background = this.backgroundGroupRef?.current;

    let pistoncase = new Konva.Image({
      x: this.images.pistoncase.pos.x,
      y: this.images.pistoncase.pos.y,
      width: this.images.pistoncase.width,
      height: this.images.pistoncase.height,
      image: this.images.pistoncase.image
    })

    background.add(pistoncase);

    let piston = new Konva.Image({
      y: this.images.pistoncase.pos.y+this.images.pistoncase.height/2,
      x: this.images.pistoncase.pos.x+265/2-96/2,
      ref: this.pistonRef,
      offsetY: 295/2,
      width: this.images.piston.width,
      height: this.images.piston.height,
      image: this.images.piston.image,
      draggable: true,
      dragBoundFunc: (pos) => this.dragBoundFunction(pos)
    })

    piston.on('dragmove', (e) => this.onDrag(e));

    this.pistonImage = piston;
    background.add(piston);

    const { brownian1, brownian2, brownian3 } = getBoolLessonByCode(this.props.code);

    let redBallon = new Konva.Image({
      x: 80,
      y: 346,
      rotation: -90,
      image: this.images.redBallon.image,
      draggable: false,
      visible: brownian1
    })

    redBallon.on('mousedown touchstart', (e) => {
      this.data.arrowHintBallonRedActivated = true;
      this.redGas = true;
    });
    redBallon.on('mouseup touchend', (e) => {this.redGas = false});

    background.add(redBallon);

    let blueBallon = new Konva.Image({
      x: 80,
      y: brownian2 ? 420 : 280,
      rotation: -90,
      image: this.images.blueBallon.image,
      draggable: false,
      visible: brownian1 || brownian2
    })

    blueBallon.on('mousedown touchstart', (e) => {
      this.data.arrowHintBallonBlueActivated = true;
      this.blueGas = true;
    });
    blueBallon.on('mouseup touchend', (e) => {this.blueGas = false});

    background.add(blueBallon);
  }

  dragBoundFunction(pos) {
    let newx = pos.x;
    if (newx < this.images.pistoncase.pos.x+265/2-96/2) {newx = this.images.pistoncase.pos.x+265/2-96/2;}
    if (newx > this.images.pistoncase.pos.x+1380/2-96/2) {newx = this.images.pistoncase.pos.x+1380/2-96/2;}
    return {
      x: newx,
      y: this.images.pistoncase.pos.y+this.images.pistoncase.height/2
    }
  }

  addWalls() {

    const pistoncasepos = this.images.pistoncase.pos;
    const pistoncasew = this.images.pistoncase.width;
    const pistoncaseh = this.images.pistoncase.height;


    let wallGroup = this.wallGroupRef?.current;
    const wallVectors = [
      [Victor(pistoncasepos.x, pistoncasepos.y), Victor(pistoncasepos.x, pistoncasepos.y + pistoncaseh), 'left'],
      [Victor(pistoncasepos.x, pistoncasepos.y), Victor(pistoncasepos.x + pistoncasew, pistoncasepos.y), 'top'],
      [Victor(pistoncasepos.x, pistoncasepos.y + pistoncaseh), Victor(pistoncasepos.x + pistoncasew, pistoncasepos.y + pistoncaseh), 'bottom'],
    ]
    for (let i = 0; i < wallVectors.length; i++) {
      let props = {
        id: `wall-${this.entities.walls.length}`,
        index: this.entities.walls.length,
        start: wallVectors[i][0],
        end: wallVectors[i][1],
        strokeWidth: 2,
        color: 'black',
        draggable: true,
        orientation: wallVectors[i][2]
      }

      let wall = new Wall(props)
      this.entities.walls.push(wall);
      wallGroup.add(wall.getLine());
    }

    const pistonpos = this.images.piston.pos;
    const pistonw = this.images.piston.width;
    const pistonh = this.images.piston.height;

    const pistonVector = [
      Victor(pistoncasepos.x - 120, pistoncasepos.y),
      Victor(pistoncasepos.x + 683/2 - 96/2, pistoncasepos.y + pistonh),
      'piston'
    ];
    let props = {
        id: `piston-${this.entities.walls.length}`,
        index: this.entities.walls.length,
        start: pistonVector[0],
        end: pistonVector[1],
        strokeWidth: 2,
        draggable: false,
        orientation: pistonVector[2]
    }

    let piston = new Wall(props);
    this.entities.piston = piston;
    wallGroup.add(piston.getLine());
  }

  onDrag(e) {

    let pistonPosition = e.target.position();
    this.entities.piston.var.prevx = this.entities.piston.var.x;
    this.entities.piston.var.x = pistonPosition.x;
    this.entities.piston.var.v = this.entities.piston.var.x - this.entities.piston.var.prevx;
    this.entities.piston.getLine().setAttr('x', pistonPosition.x);


    /// ---------- Task settings ----------
    this.data.pistonOffset = this.entities.piston.var.v;
    this.data.indicators.volume.val += this.data.pistonOffset;
    this.data.indicators.volumeFake.val += this.data.pistonOffset;

    this.data.pistonMoved = true;
    this.data.arrowHintPistonHidden = true;
    /// ----------------------------------------
    return;

  }

  volumeAngle (isFake) {
    const v = this.data.indicators[`volume${isFake ? 'Fake' : ''}`].val;
    const max = 1000;
    const min = 200;

    if ( Math.abs(v) <= min) return 0;
    if ( v <= max) {
      return isFake ? -(v/max*200 - 35) : v/max*200 - 35;
    }
    if (v>max) return 200
  };

  pressureAngle (isFake) {
    return this.data.indicators[`pressure${isFake ? 'Fake' : ''}`].val*50;
  };


  move = (time) => {
    const data = this.data;
    if (!this.prevTime) { this.prevTime = time };
    let timeDelta = time - this.prevTime;
    this.prevTime = time;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    /// ---------- Task settings ----------
    if (data.arrowHintBallonRedActivated && data.arrowHintBallonBlueActivated) {
      data.allBallonActivatedStartTime = data.allBallonActivatedStartTime || time;
    }
    if (data.pistonMoved && data.step === 1) {
      data.pistonStartTime = data.pistonStartTime || time;
    }

    this.data.indicators.pressure.val = (this.entities.blueBalls / (this.data.indicators.volume.val/5));
    this.data.indicators.pressureFake.val = -(this.entities.blueBalls / (this.data.indicators.volumeFake.val/5));
    /// ----------------------------------------


    this.updateStage(timeDelta)

    this.requestId = window.requestAnimationFrame(this.move);
  };

  updateStage(timeDelta) {
    const { brownian1, brownian2, brownian3 } = getBoolLessonByCode(this.props.code);

    const defaultRedBallsPos = Victor(200, 330);
    const defaultBlueBallsPos = Victor(200, brownian2 ? 400 : 260);

    if (this.redGas) {
      if (this.entities.redBalls < 100) {
        let ballGroup = this.ballGroupRef?.current;
        let newBallId = this.entities.balls.length;
        let ball = new Ball({id: `ball-${newBallId}`, pos: defaultRedBallsPos, image: redBallIMage});
        this.entities.balls.push(ball);
        this.entities.redBalls += 1;
        ballGroup.add(ball.getImage());
      }
    }

    if (this.blueGas) {
      if (this.entities.blueBalls < 100) {
        let ballGroup = this.ballGroupRef?.current;
        let newBallId = this.entities.balls.length;
        let ball = new Ball({id: `ball-${newBallId}`, pos: defaultBlueBallsPos, image: blueBallImage});
        this.entities.balls.push(ball);
        this.entities.blueBalls += 1;
        ballGroup.add(ball.getImage());
      }
    }

    for (let i = 0; i < this.entities.balls.length; i++) {
      let ball = this.entities.balls[i];

      ball.var.pos.add(ball.var.V.clone().multiply(Victor(timeDelta/16.7, timeDelta/16.7)));

      for (let k = 0; k < this.entities.walls.length; k++) {
        this.entities.walls[k].checkCollision(ball);
      }
      for (let j = 0; j < this.entities.balls.length; j++) {
        if (j === i) {continue;}
        this.entities.balls[j].checkCollision(ball);
      }
      this.entities.piston.checkCollision(ball);

      let prevV = ball.var.V;
      ball.var.V.add(ball.var.F);
      let dif = ball.var.V.length() - prevV.length();
      ball.var.V.multiply(Victor(1 - Math.abs(dif/2), 1-Math.abs(dif/2)));
      ball.var.F = Victor(0, 0);

      let ballImage = ball.getImage();
      ballImage.position({x: ball.var.pos.x, y: ball.var.pos.y});

    }


    /// ---------- Task settings ----------
    const data = this.data;
    const nodes = this.getNodes();

    if (!nodes.arrowHintPistonNode) {
      return;
    }

    nodes.arrowHintPistonNode.offsetX(-this.data.pistonOffset);
    toggleVisibleEl(nodes.arrowHintPistonNode, data.arrowHintPistonHidden);
    toggleVisibleEl(
      nodes.arrowHintBallonNode,
      (data.arrowHintBallonRedActivated || data.arrowHintBallonBlueActivated) || !brownian1
    );

    toggleVisibleEl(nodes.indicatorsGroupNode, !brownian2);
    toggleVisibleEl(nodes.cardWithFieldsNode, data.cardWithFieldsHidden);

    toggleVisibleEl(nodes.arrowHintIndicatorNode, data.arrowHintIndicatorHidden);
    toggleVisibleEl(nodes.arrowHintTemperatureNode, data.arrowHintTemperatureHidden);


    Object.keys(this.titlesItems.allTitles).forEach((k) => {
      const correctTitleCode = `${this.props.code}${data.step > 0 ? '_1' : ''}`;
      let visible = correctTitleCode === k;
      toggleVisibleEl(nodes[`title-${k}Node`], !visible);
    });

    this.indicatorItems.forEach((k, i) => {
      const indicatorDisabled = data.indicators[k].disabled;
      const indicatorFakeDisabled = data.indicators[`${k}Fake`].disabled;

      nodes[`indicator${k}Node`].opacity(indicatorDisabled ? .5 : 1);
      nodes[`indicator${k}Node`].offsetY(indicatorDisabled ? -40 : 0);
      nodes[`indicator${k}FakeNode`].opacity(indicatorFakeDisabled ? .5 : 1);
      nodes[`indicator${k}FakeNode`].offsetY(indicatorFakeDisabled ? -40 : 0);

      nodes[`indicatorValLine${k}Node`].rotation(this[`${k}Angle`]());
      nodes[`indicatorValLine${k}FakeNode`].rotation(this[`${k}Angle`](true));
    })

    const ballonTimeseltaSec = ((this.prevTime - data.allBallonActivatedStartTime) / 1000);
    const pistonTimeseltaSec = ((this.prevTime - data.pistonStartTime) / 1000);
    const timeDelay = 3;
    if (
      brownian1 &&
      data.step === 0 &&
      (ballonTimeseltaSec >= timeDelay)
    ) {
      showFailOrSuccessPopup(this, true);
      data.step = 1;
      data.arrowHintPistonHidden = false
      data.pistonMoved = false
    }
    if (
      brownian1 &&
      data.pistonMoved &&
      pistonTimeseltaSec &&
      pistonTimeseltaSec >= timeDelay &&
      data.step === 1
    ) {
      showFailOrSuccessPopup(this, true);
      data.step = 2;
    }

    const indicatorsSuccess = Object.keys(data.indicators).reduce(
      (accum, k, i) => {
        accum.push(!k.includes('Fake') ? !data.indicators[k].disabled : data.indicators[k].disabled);
        return accum;
    }, []);

    if (
      brownian2 &&
      data.step === 0 &&
      indicatorsSuccess.every((el) => el===true)
    ) {
      showFailOrSuccessPopup(this, true);
      data.step = 1;
    }

    /// ------------------------------


    const dynamicLayer = this.dynamicLayerRef?.current;
    if (!(dynamicLayer === null)) {
      dynamicLayer.draw();
    }

    const lsnNode = this.lessonsElementsRef?.current;
    lsnNode && lsnNode.draw();
  };

  onClick = (e, entities) => {

    // if (!(e.target === this.stageRef?.current)) { return; }
    // console.log(e.target);

    let mousepos = this.stageRef?.current.getPointerPosition(); //e.target.getPointerPosition();
    let ballGroup = this.ballGroupRef?.current;
    let newBallId = this.entities.balls.length;
    let ball = new Ball({id: `ball-${newBallId}`, pos: Victor(mousepos.x, mousepos.y)});

    this.entities.balls.push(ball);
    ballGroup.add(ball.getImage());
  }

  onClickMinus = () => {

  }
  onClickPlus = () => {

  }

  Scene = () => {
    const {code} = this.props;
    const { brownian1, brownian2, brownian3 } = getBoolLessonByCode(code);
    const [tube] = useImage(tubeImg);
    const [ground] = useImage(groundImg);
    const [indicator] = useImage(indicatorImg);
    const titles = this.titlesItems.allTitles;
    return (
      <React.Fragment>

        <Layer
          key={'layer1'}
          ref={this.mainLayerRef}
          width={window.width}
          heght={window.height}
        />

        <Layer
          key={'layer2'}
          ref={this.lessonsElementsRef}
          width={window.width}
          heght={window.height}
        >
          <Group x={480} y={60} ref={this.indicatorsGroupRef} visible={false} opacity={0}>
            {
              this.indicatorItems.map((k, i) => (
                  <React.Fragment key={k+i}>
                    <Group
                      x={200*i}
                      ref={this[`indicator${k}Ref`]}
                      onClick={() => {
                        this.data.indicators[k].disabled = !this.data.indicators[k].disabled;
                        this.data.arrowHintIndicatorHidden = true;
                      }}
                    >
                      <KonvaImage key={k} image={indicator} />
                      <Line ref={this[`indicatorValLine${k}Ref`]} points={[0,0,0,-20]} stroke={'red'} x={43} y={40}/>
                      <Text
                        text={k==='volume' ? 'Объём' : 'Давление'}
                        fontStyle={'bold'} fill={mainColor} width={87} y={73} align={'center'}
                      />
                    </Group>
                    <Group
                      x={200*i+100}
                      ref={this[`indicator${k}FakeRef`]}
                      onClick={() => {
                        this.data.indicators[`${k}Fake`].disabled = !this.data.indicators[`${k}Fake`].disabled;
                        this.data.arrowHintIndicatorHidden = true;
                      }}
                    >
                      <KonvaImage key={k+'Fake'} image={indicator} />
                      <Line ref={this[`indicatorValLine${k}FakeRef`]} points={[0,0,0,-20]} stroke={'red'} x={43} y={40}/>
                      <Text
                        text={k==='volume' ? 'Объём' : 'Давление'}
                        fontStyle={'bold'} fill={mainColor} width={87} y={73} align={'center'}
                      />
                    </Group>
                  </React.Fragment>
                )
              )
            }

            <ArrowHint
              x={395} y={-70}
              imgScaleX={-1}
              rotation={180}
              arrowX={0}
              arrowY={110}
              ref={this.arrowHintIndicatorRef}
              fontSize={13}
              text={'Нажми, чтобы \nскрыть прибор'}
              visible={false}
            />
          </Group>

          <Group x={200} y={135}>
            <KonvaImage image={ground} x={-80} y={315}/>
            <Rect fill={'#EDEBE5'} y={17} width={680} height={295}/>
            <KonvaImage image={tube} x={80} y={158} />
          </Group>


          {
            Object.keys(titles).map((k) => {
              return (
                <Text
                  key={`title-${k}`}
                  ref={this[`title-${k}Ref`]}
                  text={titles[k]}
                  {...lsnTitleTxtStyle}
                  fontSize={20}
                  x={50}
                  y={30}
                  visible={false}
                  opacity={0}
                />
              )
            })
          }


          <ArrowHint
            x={300} y={190}
            ref={this.arrowHintPistonRef}
            fontSize={13}
            text={'Перемещай перегородку, \nчтобы менять объем, \nдоступный для газа'}
            visible={false}
          />
          <ArrowHint
            x={20} y={130}
            ref={this.arrowHintBallonRef}
            rotation={180}
            arrowX={50}
            arrowY={110}
            fontSize={13}
            text={'Нажми, чтобы \nоткрыть баллон'}
            visible={false}
          />

          <Card
            x={740} y={170}
            reffer={this.cardWithFieldsRef}
            width={183} height={310}
            visible={false}
          >
            <Group x={20} y={25}>
              <Text text={'Температура, °C:'} fill={mainColor} fontStyle={'bold'} fontSize={14}/>
              <Text y={20} text={'-35'} fill={mainColor} fontSize={35} fontStyle={'bold'} ref={this.temperatureTxtRef}/>

              <Group y={80}>
                <CanvasButton
                  btnRef={this.timerBtnRef}
                  onClick={this.onClickMinus}
                >
                  <Rect
                    stroke={mainColor}
                    strokeWidth={1.5}
                    width={40}
                    height={40}
                    cornerRadius={5}
                  />
                  <RegularPolygon
                    x={20}
                    y={20}
                    sides={3}
                    radius={10}
                    fill={mainColor}
                    rotation={30}
                  />
                </CanvasButton>

                <CanvasButton
                  btnRef={this.timerBtnRef}
                  onClick={this.onClickPlus}
                  x={50}
                >
                  <Rect
                    stroke={mainColor}
                    strokeWidth={1.5}
                    width={40}
                    height={40}
                    cornerRadius={5}
                  />
                  <RegularPolygon
                    x={20}
                    y={20}
                    sides={3}
                    radius={-10}
                    fill={mainColor}
                    rotation={30}
                  />
                </CanvasButton>
              </Group>

              <Group y={150}>
                <Text text={'Газ:'} fill={mainColor} fontStyle={'bold'} fontSize={14}/>
                <CanvasButton
                  text={'Молекула воды'}
                  btnRef={this.timerBtnRef}
                  onClick={this.onClickPlus}
                  fontSize={14}
                  fontStyle={'normal'}
                  strokeWidth={.3}
                  height={35} width={145}
                  btnFill={layout2.darkBlue}
                  btnStroke={layout2.darkBlue}
                  boxShadowColor={'rgba(0,0,0,0)'}
                  y={25}
                />
                <CanvasButton
                  text={'Азот'}
                  btnRef={this.timerBtnRef}
                  onClick={this.onClickPlus}
                  fontSize={14}
                  fontStyle={'normal'}
                  strokeWidth={.3}
                  height={35} width={145}
                  btnFill={layout2.blue}
                  btnStroke={layout2.blue}
                  boxShadowColor={'rgba(0,0,0,0)'}
                  y={70}
                />
              </Group>

            </Group>



            <ArrowHint
              x={130} y={-15}
              imgScaleX={-1}
              rotation={180}
              arrowX={0}
              arrowY={140}
              ref={this.arrowHintTemperatureRef}
              fontSize={13}
              text={'Нажми, чтобы \nизменить \nтемпературу \nгаза'}
              visible={false}
            />

          </Card>

          {/*<CanvasResetBtn onClick={this.onClickReset}/>*/}
        </Layer>



        <Layer
          key={'layer3'}
          ref={this.backgroundLayerRef}
          width={window.width}
          heght={window.height}
        >
          <Group ref={this.backgroundGroupRef}/>
        </Layer>


        <Layer key={'layer4'} ref={this.dynamicLayerRef}>
            <Group
              id={'ballGroup'}
              ref={this.ballGroupRef}
            />

            <Group
              id={'wallGroup'}
              ref={this.wallGroupRef}
            />

          </Layer>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} tabIndex={1}>
          <this.Scene/>
        </CanvasContainer>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Brownian);

const styles = {
  mainContainer: {
    background: '#eeebe5',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }
}
