import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../styles/styles.css'
import LevelAchievement from "../components/LevelAchievement";
import {levels} from "../data";
import LevelProgressBar from "../components/LevelProgressBar";
import {Button} from "antd";


class SuccessScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disableBtns: false,

      newPoints: 0,
      currentPoints: 0,

      maxPoints: 0,
      levelNum: 0,
      tempLevel: 0
    }
  }

  componentDidMount() {
    const currentLevel = this.getLevelByPoints(this.props.initialPoints || 100);

    this.setState(() => ({
      currentPoints: this.props.initialPoints,
      levelNum: currentLevel.level,
      maxPoints: currentLevel.maxPoints,
    }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.points !== this.props.points) {
      this.updatePoints(this.props.points);
    }
  }

  getLevelByPoints(points) {
    return levels.find(lvl => (points >= lvl.startPoints) && (points < lvl.maxPoints))
  }
  completePoints = (callback) => {
    this.setState(
      prev => ({ ...prev, currentPoints: prev.currentPoints + prev.newPoints }),
      () => setTimeout(callback, 1500)
    );
  }
  addTempLevel = (callback) => {
    this.setState(
      prev => ({ ...prev, tempLevel: prev.levelNum + 1 }),
      () => setTimeout(callback, 1500)
    );
  }
  addLevel = () => {
    this.setState(
      prev => ({...prev, disableBtns: true}),
      () =>
        this.completePoints(
          () =>
            this.addTempLevel(
              () =>
                this.setState(prev => {
                  const newLevel = levels.find(item => item.level === prev.tempLevel);
                  if (newLevel) {
                    return ({
                      ...prev,
                      levelNum: newLevel.level,
                      maxPoints: newLevel.maxPoints,
                      tempLevel: 0,
                      newPoints: 0,
                      currentPoints: prev.currentPoints,
                      disableBtns: false
                    })
                  } else {
                    return prev
                  }
                },
                  () => setTimeout(() => this.props.setVisible?.(false), 3000)
              )
            )
        )
    )
  }
  addNewPoints = (points, callback) => {
    this.setState(
      prev => ({
        ...prev,
        newPoints: points - (prev.currentPoints)
      }),
      () => {
        if (points >= this.state.maxPoints) {
          this.addLevel();
        } else {
          setTimeout(callback, 1500)
        }
      }
    )
  }
  updateCurrentPoints = (callback) => {
    this.setState(
      prev => {
        const points = prev.newPoints + (prev.currentPoints);
        return ({
          newPoints: 0,
          currentPoints: points,
        })
      },
      () => callback && setTimeout(callback, 3000)
    );
  }
  updatePoints = (points) => {
    this.addNewPoints(
      points,
      () => this.updateCurrentPoints(
        () => this.props.setVisible?.(false)
      )
    );
  }

  render() {
    const levelData = levels.find((item) => item.level === this.state.levelNum);
    const tempLevelData = levels.find((item) => item.level === this.state.tempLevel);
    return (
      <div className={'success-screen'}>
        <div className={'success-screen__bg'}/>
        {
          levelData ? (
            <>
              <div className={'success-screen__content'}>
                <LevelAchievement levelData={levelData}/>
                <LevelProgressBar progressData={this.state}/>
                {
                  tempLevelData ? (
                    <div className={'tempLevel'}>
                      <img src={tempLevelData.img} className={'tempLevel__img'}/>
                    </div>
                  ) : null
                }
              </div>

              {/*<div className={'success-screen__buttons'}>*/}
              {/*  <Button*/}
              {/*    type={'default'}*/}
              {/*    size={'large'}*/}
              {/*    disabled={this.state.disableBtns}*/}
              {/*    onClick={() => this.addLevel()}*/}
              {/*  >Добавить левел</Button>*/}

              {/*  <Button*/}
              {/*    type={'default'}*/}
              {/*    size={'large'}*/}
              {/*    disabled={this.state.disableBtns}*/}
              {/*    onClick={() => this.updatePoints(100)}*/}
              {/*  >*/}
              {/*    +100 баллов*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </>
          ) : (
            <h1 style={{
              position: 'absolute',
              color: 'white',
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              fontSize: '50px'
            }}> Такого уровня еще нет </h1>
          )
        }
      </div>
    );
  }
}


const mapStateToProps = state => ({});
const mapDispatchToProps = (dispatch, {match}) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuccessScreen);
