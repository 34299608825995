import React from "react";
import Konva from "konva";
import { Layer, Line, Rect, Circle, Image as KonvaImage, Group, Text, RegularPolygon, } from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";

import Victor from 'victor';
import useImage from "use-image";
import Particle from '../components/Particle';

import { getBoolLessonByCode, getTitles } from "../utils/brownianUtils";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";

import redBallImage from "../../../../images/IdealGas/red_ball.png";

//mport 'process-stats';

class PhaseState extends React.Component {
	constructor(props) {

		super(props);
		this.pLayerRef = React.createRef();

		this.initialData = {

			particles: 50,
			initialState: 'gas',
			T: 50,

		}

		//this.data = cloneDeep(this.initialData);

		this.entities = [];
		this.gasn = 0;
		this.liquidn = 0;
		this.solidn = 0;

		this.T = 50;

		this.customWalls = {
			wl: 300,
			wr: 500,
			wt: 200,
			wb: 400,
		}


		this.nparticles = 100;
	}

	generateV() {
		let vx = Math.random() - 0.5;
		let vy = Math.random() - 0.5;
		let k = 0.5;
		return Victor(vx, vy).multiply(Victor(k, k));
	}
	generatePos() {
		let x = this.customWalls.wl + 20 + Math.random() * 160;
		let y = this.customWalls.wt + 20 + Math.random() * 160;
		return Victor(x, y);
	}

	generateParticle(pstate) {
		let fill;
		switch (pstate) {
			case "gas":
				fill = 'blue';
				break;
			case "liquid":
				fill = 'red';
				break;
			default:
				break;
		}
		let props = {
			pstate: pstate,
			V: Victor(Math.random() - 0.5, Math.random() - 0.5),
			R: 5,
			// V: this.generateV(),
			pos: this.generatePos(),
			fill: fill
		}
		const particle = new Particle(props);
		return particle;
	}
	componentDidMount() {

		//const procStats = require('process-stats')();

		//console.log(procStats());

		const pLayer = this.pLayerRef?.current;

		for (let i = 0; i < this.nparticles / 2; i++) {
			let particle = this.generateParticle('gas');
			this.gasn += 1;
			this.entities.push(particle);
			pLayer.add(particle.node);
		}
		for (let i = 0; i < this.nparticles / 2; i++) {
			let particle = this.generateParticle('liquid');
			this.liquidn += 1;
			this.entities.push(particle);
			pLayer.add(particle.node);
		}

		pLayer.draw();

		this.RAF = window.requestAnimationFrame(this.move);
	}

	changeState() {
		let [gn, ln, sn] = [this.gasn, this.liquidn, this.solidn];

		let n = gn / this.entities.length * 100;
		let delta = n - this.T;

		if (delta < 0) {
			//Too much liquid; need to evaporate
			for (let i = 0; i < this.entities.length; i++) {
				let particle = this.entities[i];
				if (particle.pstate == 'liquid') {
					let p = Math.exp(Math.abs(delta)*Math.log(2)/100) - 1;
					if (Math.random() < p) {
						particle.pstate = 'gas';
						particle.node.setAttr('fill', 'blue');
						gn += 1;
						ln -= 1;
						n = gn /this.entities.length * 100;
						delta = n - this.T;
					}
				}
			}
		}
		else if (delta > 0) {
			//Too much gas; need to condence
			for (let i = 0; i < this.entities.length; i++) {
				let particle = this.entities[i];
				if (particle.pstate == 'gas') {
					let p = Math.exp(Math.abs(delta)*Math.log(2)/100) - 1;
					if (Math.random() < p) {
						particle.pstate = 'liquid';
						particle.node.setAttr('fill', 'red');
						gn -= 1;
						ln += 1;
						n = gn /this.entities.length * 100;
						delta = Math.abs(n - this.T);
					}
				}
			}
		}

	}



	move = (time) => {

		if (!this.prevTime) {
			this.prevTime = time;
		}
		let dT = time - this.prevTime;
		this.prevTime = time;

		const pLayer = this.pLayerRef?.current;

		for (let i = 0; i < this.entities.length; i++) {

			let particle = this.entities[i];

			if (!(particle instanceof Particle)) {
				console.log("Move function called on object that is not a Particle object");
				return;
			}

			particle.CustomCollide(this.customWalls);

			for (let k = 0; k < this.entities.length; k++) {
				let target = this.entities[k];
				if (i !== k) {
					particle.Collide(target);
				}

			}
		}

		

		for (let i = 0; i < this.entities.length; i++) {
			let particle = this.entities[i];
			if (particle instanceof Particle) {
				particle.Move(dT/16.7);
				particle.F = Victor(0, 0);
			}
		}

		this.changeState();

		pLayer.draw();

		this.RAF = window.requestAnimationFrame(this.move);

	}

	Scene = () => {

		return (
			<React.Fragment>

				<Layer
					ref={this.backgroundLayerRef}
				// width={window.width}
				// heght={window.height}
				>
				</Layer>

				<Layer
					ref={this.wallsLayerRef}>
				</Layer>
				<Layer
					ref={this.pLayerRef}>
				</Layer>

			</React.Fragment>
		)
	};

	render() {
		return (
			<div style={styles.mainContainer}>
				<CanvasContainer stageRef={this.stageRef} tabIndex={1}>
					<this.Scene />
				</CanvasContainer>

			</div>
		)
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
	changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PhaseState);

const styles = {
	mainContainer: {
		background: '#eeebe5',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
	}
}
