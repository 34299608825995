import React from "react";
import '../styles.css';
import TextProblemHtmlContent from "../components/TextProblemHtmlContent";
import AnswerBlock from "../components/AnswerBlock";
import {element} from "prop-types";
import {useQuery} from "../../../utils/custom-hooks/useQuery";

class TextProblemTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: null,
    }
  }

  onChange = (e, multiple=false) => {
    const value = e.target.value;
    const checked = e.target.checked;
    this.setState((prevState) => {
      let newValue;
      const prevAnswers = (prevState.answers || []);
      if (multiple) {
        if (checked) {
          newValue = [...prevAnswers, value];
        } else {
          newValue = prevAnswers.filter((answer) => answer !== value);
        }
      } else {
        newValue = [value];
      }
      return {answers: newValue}
    });
  };

  sendVal = () => {
    const { answers } = this.state;
    const { textProblem, postAnswer } = this.props;
    const isOptions = textProblem.answer_type === 'options';
    if (isOptions) {
      let userAnswersOptions = textProblem.answer_options
        .filter((opt) => answers.includes(opt['id']))
        .map(el => ({option_id: el.id, value_str: el.value_str}))
      postAnswer(textProblem.id, userAnswersOptions);
    } else {
      if (answers.length)
        postAnswer(textProblem.id, answers.map(answer => ({value_str: answer})));
    }
    window.scrollTo(0,0);
  };


  render () {
    const {textProblem, visible} = this.props;

    return (
      <div className={'text-problem-task__container'} style={{display: visible ? 'block' : 'none'}}>
        {
          textProblem && (
            <>
              <h2 className={'text-problem-task__title'}>{textProblem.title}</h2>

              <TextProblemHtmlContent htmlForRender={textProblem.task}/>
              <AnswerBlock
                editMode={true}
                textProblem={textProblem}
                stateAnswers={this.state.answers || []}
                sendVal={this.sendVal}
                onChange={this.onChange}
              />
            </>
         )
        }
      </div>
    )
  }
}

export default TextProblemTask;