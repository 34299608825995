import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import dragImg11 from "../images/matchItems/LogicWedge6_1_drag_1.png";
import dragImg12 from "../images/matchItems/LogicWedge6_1_drag_2.png";
import dragImg13 from "../images/matchItems/LogicWedge6_1_drag_3.png";

import dropImg11 from "../images/matchItems/LogicWedge6_1_row_1.png";
import dropImg12 from "../images/matchItems/LogicWedge6_1_row_2.png";
import dropImg13 from "../images/matchItems/LogicWedge6_1_row_3.png";

import continueImg11 from "../images/matchItems/LogicWedge6_1_continue_1.png";
import continueImg12 from "../images/matchItems/LogicWedge6_1_continue_2.png";
import continueImg13 from "../images/matchItems/LogicWedge6_1_continue_3.png";

import dragImg21 from "../images/matchItems/LogicWedge6_2_drag_1.png";
import dragImg22 from "../images/matchItems/LogicWedge6_2_drag_2.png";
import dragImg23 from "../images/matchItems/LogicWedge6_2_drag_3.png";

import dropImg21 from "../images/matchItems/LogicWedge6_2_row_1.png";
import dropImg22 from "../images/matchItems/LogicWedge6_2_row_2.png";
import dropImg23 from "../images/matchItems/LogicWedge6_2_row_3.png";

import continueImg21 from "../images/matchItems/LogicWedge6_2_continue_1.png";
import continueImg22 from "../images/matchItems/LogicWedge6_2_continue_2.png";
import continueImg23 from "../images/matchItems/LogicWedge6_2_continue_3.png";


import dragImg31 from "../images/matchItems/LogicWedge6_3_drag_1.png";
import dragImg32 from "../images/matchItems/LogicWedge6_3_drag_2.png";
import dragImg33 from "../images/matchItems/LogicWedge6_3_drag_3.png";

import dragImg41 from "../images/matchItems/LogicWedge6_4_drag_1.png";
import dragImg42 from "../images/matchItems/LogicWedge6_4_drag_2.png";
import dragImg43 from "../images/matchItems/LogicWedge6_4_drag_3.png";


import bgNature from '../images/bgNature.png';
import MatchItems from "./MatchItems";


const LogicWedge6 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'Match the items',
      dropItems: [
        {id: 1, successId: 3, completeImg: continueImg11, img: dropImg11},
        {id: 2, successId: 1, completeImg: continueImg12, img: dropImg12},
        {id: 3, successId: 2, completeImg: continueImg13, img: dropImg13},
      ],
      dragItems: [
        {id: 1, img: dragImg12},
        {id: 2, img: dragImg13},
        {id: 3, img: dragImg11},
      ]
    },
    {
      title: 'Match the items',
      dropItems: [
        {id: 1, successId: 3, completeImg: continueImg21, img: dropImg21},
        {id: 2, successId: 1, completeImg: continueImg22, img: dropImg22},
        {id: 3, successId: 2, completeImg: continueImg23, img: dropImg23},
      ],
      dragItems: [
        {id: 1, img: dragImg22},
        {id: 2, img: dragImg23},
        {id: 3, img: dragImg21},
      ]
    },
    {
      title: 'Match the items',
      dropItems: [
        {
          id: 1, successId: 3,
          completeText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            A lever balances on a
            <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}> fulcrum </span></span>,
          mainText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            A lever balances on a _______
          </span>
        },
        {
          id: 2, successId: 1,
          completeText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            A wheel with
            <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}> an axle </span>
            is a simple machine
          </span>,
          mainText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            A wheel with ________ is a simple machine
          </span>
        },
        {
          id: 3, successId: 2,
          completeText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}> A wheel </span>
            helps to decrease ground friction
          </span>,
          mainText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            ________ helps to decrease ground friction
          </span>
        },
      ],
      dragItems: [
        {id: 1, img: dragImg32},
        {id: 2, img: dragImg33},
        {id: 3, img: dragImg31},
      ]
    },
    {
      title: 'Match the items',
      dropItems: [
        {
          id: 1, successId: 3,
          completeText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            A
            <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}> pulley </span>
            is a simple machine which basically contains a wheel and rope to lift the heavy objects
          </span>,
          mainText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            A _______ is a simple machine which basically contains a wheel and rope to lift the heavy objects
          </span>
        },
        {
          id: 2, successId: 1,
          completeText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            A fixed pulley change the
            <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}> direction </span>
            of you pull
          </span>,
          mainText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            A fixed pulley change the _________ of you pull
          </span>
        },
        {
          id: 3, successId: 2,
          completeText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            The universal rule for the pulley: if the effort becomes easier, the
            <span style={{color: "#EA5616", fontFamily: 'Roboto-Bold'}}> rope </span>
            becomes longer
          </span>,
          mainText: <span style={{color: "#27397B", fontFamily: 'Roboto-Bold'}}>
            The universal rule for the pulley: if the effort becomes easier, the ____ becomes longer
          </span>
        },
      ],
      dragItems: [
        {id: 1, img: dragImg42},
        {id: 2, img: dragImg43},
        {id: 3, img: dragImg41},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i + 1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <MatchItems
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    dropItems={item.dropItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default LogicWedge6;
