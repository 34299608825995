import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ContinueRowTask from "./ContinueRowTask";
import img1 from "../images/continueRow/row1.png";
import img2 from "../images/continueRow/row2.png";
import img3 from "../images/continueRow/row3.png";
import dragItemImg1 from "../images/continueRow/dragItem1.png";
import dragItemImg2 from "../images/continueRow/dragItem2.png";
import dragItemImg3 from "../images/continueRow/dragItem3.png";
import dragItemImg4 from "../images/continueRow/dragItem4.png";

import task2Img1 from "../images/continueRow/task2Row1.png";
import task2Img2 from "../images/continueRow/task2Row2.png";
import task2Img3 from "../images/continueRow/task2Row3.png";
import task2DragItemImg1 from "../images/continueRow/task2Drag1.png";
import task2DragItemImg2 from "../images/continueRow/task2Drag2.png";
import task2DragItemImg3 from "../images/continueRow/task2Drag3.png";
import task2DragItemImg4 from "../images/continueRow/task2Drag4.png";


const Example1Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const step1RowItems = [
    {id: 1, image: img1},
    {id: 2, image: img2},
    {id: 3, image: img3},
  ]
  const step1DragItems = [
    {id: 1, image: dragItemImg1},
    {id: 2, image: dragItemImg2},
    {id: 3, image: dragItemImg3},
    {id: 4, image: dragItemImg4, success: true},
  ]


  const step2RowItems = [
    {id: 1, image: task2Img3},
    {id: 2, image: task2Img2},
    {id: 3, image: task2Img1},
  ]
  const step2DragItems = [
    {id: 1, image: task2DragItemImg1, success: true},
    {id: 2, image: task2DragItemImg2},
    {id: 3, image: task2DragItemImg3},
    {id: 4, image: task2DragItemImg4},
  ]


  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={2}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      {
        completedTaskCount < 1 ?
          <ContinueRowTask
            key={'step1'}
            title={'Continue the row: move the correct picture from below'}
            rowItems={step1RowItems}
            dragItems={step1DragItems}
            taskComplete={() => setCompletedTaskCount(prevState => prevState+1)}
          />
        : completedTaskCount >= 1 ?
          <ContinueRowTask
            key={'step2'}
            title={'Continue the row: move the correct picture from below'}
            rowItems={step2RowItems}
            dragItems={step2DragItems}
            taskComplete={() => {
              setAllComplete(true);
              setCompletedTaskCount(prevState => prevState + 1);
            }}
          />
        : null
      }
    </TaskMainContainer>
  )
}
export default Example1Task;
