import React from "react";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Circle, Group, Image, Layer, Line, Rect, Text} from "react-konva";
import {defaultCanvasShadowStyles, layout2, lsnTitleTxtStyle, mainColor} from "../../../../utils/styles";
import CanvasInput from "../../../canvas/components/CanvasInput";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {toggleVisibleEl} from "../../../../utils/common";
import cloneDeep from "lodash.clonedeep";
import Road from "../components/Road";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import useImage from "use-image";
import biker1Image from "../../../../images/pathandspeed/biker1.png";
import mainChartImg from "../../../../images/pathandspeed/mainChart.png";
import rightChartImg from "../../../../images/pathandspeed/rightChart.png";
import successArrowImg from "../../../../images/pathandspeed/successArrow.png";
import cratersImg from "../../../../images/pathandspeed/space/craters.png";
import shipImg from "../../../../images/pathandspeed/space/ship2.png";
import Card from "../../../canvas/components/Card";
import CanvasChartImg from "../../../canvas/components/CanvasChartImg";
import {getChartImgSetting} from "../utils/utils";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getScenarioConfig} from "../utils/scenarioHelper";
import {getLsnNameByCode} from "../utils/common";



class PathandspeedLongLesson extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();

    this.elementsUI = {
      roadVisible: false,
      sidePageSide: 'left',
      roadMoving: false,
      bikerVisible: false,
      firstSpeedStubVisible: false,
      secondSpeedStubVisible: false,
      questionCardVisible: false,
      questionCardTxt: '',
      errorVisible: false,
      mainChartImg: null,
      successVisible: false,
      rightChartStart: false,
      rightChartVisible: false,
      rightChartLineVisible: false,
      chartWithDragAndDropVisible: false,
      rightChartLineResultVisible: false,
      taskCompleteVisible: false,
      isLineDragged: false,
    }
    this.itemsRowArr = Array(5).fill(1);
    this.itemsColumnArr = Array(10).fill(1);
    this.initialData = {
      maxTimeDeltaSec: 16.6,

      stepTimeStart: undefined,

      startTime: undefined,
      prevTime: undefined,
      currentTimeSec: 0,

      rightChartStartTime: 0,
      rightChartLinePointPos: 0,
      rightChartLinePoints: [0,0,0,0],

      bikerStartTime: 0,
      bikerTimeDeltaSec: 0,
      bikerPath: 0,
      bikerSpeed: 20,

      maxRoadOffset: 396,
      roadSpeed: 6,
      roadOffset: 0,

      mainChartLinePointPos: 0,
      mainChartLinePoints: [0,0,0,0],
      mainChartX: 0,

      isLineDragged: false,

      itemsRowArr: this.itemsRowArr,
      itemsColumnArr: this.itemsColumnArr,

      selectedData: this.itemsColumnArr.reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),

      chartImgSetting: getChartImgSetting(this.props.code, this.itemsRowArr),
    };
    this.data = cloneDeep(this.initialData);

    // this.chartImgSetting = getChartImgSetting(this.props.code, this);

    this.chartImgRefKeys = this.data.itemsColumnArr.reduce((accum, el, columnI) => {
      accum.push(`dragLine${columnI}`, `dragLineContainer${columnI}`);
      this.data.itemsRowArr.forEach((el, rowI) => {
        accum.push(`actionBox${rowI}${columnI}`);
      });
      return accum;
    }, [])
    this.managedComponents = [
      'stage',
      'title',

      // road
      'road', 'biker',
      'roadContainer',

      // Main chart (side page)
      'mainChartLine',
      'mainChartContainer', 'mainChartPoint',

      // Right chart line
      'rightChartPoint',
      'rightChartLine',
      'rightChartLine2',
      'checkRightChartBtn',

      // Chart img refs (drag and drop)
      ...this.chartImgRefKeys, 'arrowHint',
    ];

    for (let el of this.managedComponents) {
      this[`${el}Ref`] = React.createRef();
    }

    const scenarioConfig = getScenarioConfig(this.props.code);
    this.scenarioManager = new ScenarioManager(scenarioConfig, this);
  }

  _ref = (key) => this[`${key}Ref`];
  _getNode = (key) => this[`${key}Ref`]?.current;

  _resetBikerChartCallback = () => {}
  getResetBikerChartCallback = (callBack) => this._resetBikerChartCallback = callBack;

  _resetChartLinesCallback = () => {}
  getResetChartLinesCallback = (callBack) => this._resetChartLinesCallback = callBack;


  _updateCurrentData = () => {
    const stepPatchData = this.scenarioManager.getStepData().patchData;
    let correctInitialData = this.initialData;
    if (stepPatchData) {
      correctInitialData = {...correctInitialData, ...stepPatchData};
    }
    this.data = cloneDeep(correctInitialData);
  };

  resetData = () => {
    this._resetBikerChartCallback();
    this._resetChartLinesCallback();
  }
  resetScenario = () => {
    this.scenarioManager.resetScenario();
    this.resetData();
  }
  back = () => {
    this.resetData();
    this.data.inputVal = '';
    this.scenarioManager.back();
  }
  next = () => {
    this.data.inputVal = '';
    this.scenarioManager.next();
  }

  get rightChartLinePointPos () {
    const {pathandspeed6, pathandspeed13} = getLsnNameByCode(this.props.code);
    const rightChartTimeDeltaSec = this.data.rightChartTimeDeltaSec || 0;
    if (pathandspeed6 || pathandspeed13) {
      let pointPos = {x:-rightChartTimeDeltaSec*20, y:0};
      if (rightChartTimeDeltaSec >= 6.6) {
        pointPos = {x:-rightChartTimeDeltaSec*20, y: 33};
      }
      return pointPos;
    }
    return {x: -rightChartTimeDeltaSec * 20, y: 0}
  }

  /**
   * ================= GETTERS ======================
   * */
  get correctBikerSpeed () {
    const data = this.data;
    const {pathandspeed6, pathandspeed13} = getLsnNameByCode(this.props.code);
    let correctSpeed = data.bikerSpeed;

    if ((pathandspeed6 || pathandspeed13) && data.bikerTimeDeltaSec >= data.bikerSpeedChangeTime) {
      correctSpeed = data.bikerSpeed2;
    }
    return correctSpeed;
  }

  get rightChartTimeDeltaSec () {
    const data = this.data;
    let timeDelta = (data.prevTime - data.rightChartStartTime) / 500;
    if (timeDelta+.2 >= data.maxTimeDeltaSec){
      timeDelta = data.maxTimeDeltaSec;
    }
    return timeDelta;
  }

  get rightChartLinePoints () {
    const {pathandspeed6, pathandspeed13} = getLsnNameByCode(this.props.code);
    const rightChartTimeDeltaSec = this.data.rightChartTimeDeltaSec || 0;
    if (rightChartTimeDeltaSec < 6.6) {
      return [0,0, (rightChartTimeDeltaSec*20), 0]
    }
    return this.data.rightChartLinePoints;
  }

  get rightChartLinePoints2 () {
    const {pathandspeed6, pathandspeed13} = getLsnNameByCode(this.props.code);
    const rightChartTimeDeltaSec = this.data.rightChartTimeDeltaSec || 0;
    if (rightChartTimeDeltaSec >= 6.6) {
      let y = 0
      if (pathandspeed6 || pathandspeed13) {
        y = -33;
      }
      return [this.data.rightChartLinePoints[2], y, (rightChartTimeDeltaSec * 20), y]
    }
    return this.data.rightChartLinePoints2;
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.scenarioManager.resetScenario();
  }

  get roadOffset () {
    const {roadSpeed, maxRoadOffset, roadOffset} = this.data;
    const newOffset = roadOffset + roadSpeed;
    return newOffset >= maxRoadOffset ? 0 : newOffset;
  }

  get bikerTimeDeltaSec() {
    const data = this.data;
    let timeDelta = (data.prevTime - data.bikerStartTime) / 500;
    if (timeDelta+.2 >= data.maxTimeDeltaSec){
      timeDelta = data.maxTimeDeltaSec;
    }
    return timeDelta;
  }

  get mainChartLinePointPos() {
    const data = this.data;
    const {pathandspeed6, pathandspeed13} = getLsnNameByCode(this.props.code);
    let mainChartLinePointPos =  {x:-data.bikerTimeDeltaSec*20, y:data.bikerPath};
    if ((pathandspeed6 || pathandspeed13) && data.bikerTimeDeltaSec >= data.bikerSpeedChangeTime) {
      const prevY = data.bikerSpeed1*data.bikerSpeedChangeTime;

      mainChartLinePointPos = {x:-data.bikerTimeDeltaSec*20, y:data.bikerPath};
    }
    return mainChartLinePointPos;
  }

  get mainChartLinePoints() {
    const data = this.data;
    const {pathandspeed6, pathandspeed13} = getLsnNameByCode(this.props.code);
    let mainChartLinePoints = [0,0, data.bikerTimeDeltaSec*20, -data.bikerPath];

    if ((pathandspeed6 || pathandspeed13) && data.bikerTimeDeltaSec >= data.bikerSpeedChangeTime) {
      const prevX = data.bikerSpeedChangeTime*20;
      const prevY = data.bikerSpeed1*data.bikerSpeedChangeTime;
      mainChartLinePoints = [
        0, 0,
        prevX, -prevY,
        data.bikerTimeDeltaSec*20, -data.bikerPath,
      ];
    }
    return mainChartLinePoints;
  }

  get mainChartX() {
    const data = this.data;

    const minX = 0;
    const maxX = 500;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));
    const chartX = n['mainChartContainer'].x();
    if (data.sidePageSide === 'right') {
      return chartX < maxX ? chartX + 12 : maxX;
    }
    if (!data.sidePageSide || data.sidePageSide === 'left') {
      return chartX > minX ?  chartX - 12 : minX;
    }
    return chartX;
  }

  get bikerPath() {
    const data = this.data;
    const {pathandspeed6, pathandspeed13} = getLsnNameByCode(this.props.code);
    let bikerPath = data.bikerSpeed * data.bikerTimeDeltaSec;

    if ((pathandspeed6 || pathandspeed13) && data.bikerTimeDeltaSec >= data.bikerSpeedChangeTime) {
      bikerPath = data.bikerSpeed * data.bikerTimeDeltaSec - data.bikerSpeed1*data.bikerSpeedChangeTime;
    }

    return bikerPath;
  }

  /**
   ========================================================
   **/

  checkSuccess = () => {
    const data = this.data;
    const {pathandspeed5, pathandspeed6, pathandspeed12, pathandspeed13} = getLsnNameByCode(this.props.code);
    const currentKey = this.scenarioManager.getStepData().key;

    let checkInputSteps = [];
    if (pathandspeed5 || pathandspeed12) { checkInputSteps = ['step2', 'step2.1', 'step4', 'step4.1']; }
    if (pathandspeed6 || pathandspeed13) {
      checkInputSteps = [
        'step2', 'step2.1', 'step3', 'step3.1',
        'step4', 'step4.1', 'step5', 'step5.1',
        'step11', 'step11.1', 'step12', 'step12.1'
      ];
    }


    if (data?.inputSuccess === this.data.inputVal) {
      checkInputSteps.forEach(key => key === currentKey && this.scenarioManager.success(key));
    } else {
      checkInputSteps.forEach(key => key === currentKey && this.scenarioManager.failure(key));
    }


    let checkChartDragAndDrop = [];
    if (pathandspeed5 || pathandspeed12) { checkChartDragAndDrop = ['step6', 'step6.1'] }
    if (pathandspeed6 || pathandspeed13) { checkChartDragAndDrop = ['step7', 'step7.1', 'step8', 'step8.1', 'step9', 'step10', 'step13'] }

    const selectedData = Object.keys(data.selectedData).map((k) => data.selectedData[k]);
    const selectedDataSuccessSingle = (
      data?.selectedDataSuccess !== undefined &&
      selectedData.every((el) => el === data.selectedDataSuccess)
    )
    const selectedDataSuccessMultipleVal = JSON.stringify(selectedData) === JSON.stringify(data.selectedDataSuccessMultipleVal);

    if (selectedDataSuccessSingle || selectedDataSuccessMultipleVal) {
      checkChartDragAndDrop.forEach(key => key === currentKey && this.scenarioManager.success(key));
    } else {
      checkChartDragAndDrop.forEach(key => key === currentKey && this.scenarioManager.failure(key));
    }

    this._resetChartLinesCallback();
  }

  move = (time) => {
    const data = this.data;

    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    // setRafTime
    data.stepTimeStart = data.stepTimeStart || time;
    // console.log(stepData);


    data.startTime = data.startTime || time;
    data.prevTime = time;
    data.currentTimeSec = (data.prevTime - data.startTime)/1000;

    data.bikerSpeed = this.correctBikerSpeed;

    if (data.roadMoving) {
      data.bikerStartTime = data.bikerStartTime || data.prevTime;
      data.bikerTimeDeltaSec = this.bikerTimeDeltaSec;
    } else {
      data.bikerStartTime = 0;
    }

    data.dataset = this.dataset;
    data.bikerPath = this.bikerPath;
    data.roadOffset = this.roadOffset;

    data.mainChartLinePointPos = this.mainChartLinePointPos;
    data.mainChartLinePoints = this.mainChartLinePoints;
    data.mainChartX = this.mainChartX;

    if (data.rightChartStart) {
      data.rightChartStartTime = data.rightChartStartTime || data.prevTime;
      data.rightChartTimeDeltaSec = this.rightChartTimeDeltaSec;
    }
    data.rightChartLinePointPos = this.rightChartLinePointPos;
    data.rightChartLinePoints = this.rightChartLinePoints;
    data.rightChartLinePoints2 = this.rightChartLinePoints2;


    this.updateStage();
  };

  updateStage = () => {
    const data = this.data;
    const stepData = this.scenarioManager.getStepData();

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));
    const apply = (key, funcName, param) => n[key]?.[funcName]?.(param);


    n['title'].text(stepData.text.value);

    if (data.roadMoving) {
      n['road'].offsetX(data.roadOffset);
    }
    n['biker'].offsetX(-data.bikerPath*1.7);


    n['mainChartPoint'].offset(data.mainChartLinePointPos);
    n['mainChartLine'].points(data.mainChartLinePoints);

    n['rightChartPoint'].offset(data.rightChartLinePointPos);
    n['rightChartLine'].points(data.rightChartLinePoints);
    n['rightChartLine2'].points(data.rightChartLinePoints2);


    n['mainChartContainer'].x(data.mainChartX);
    n['arrowHint'].visible(!this.data.isLineDragged);
    n['checkRightChartBtn'].visible(this.data.isLineDragged);


    n['stage'].draw();
  };

  Scene = () => {
    const data = this.data;
    const stepData = this.scenarioManager.getStepData();
    const {pathandspeed12, pathandspeed13, pathandspeed6 } = getLsnNameByCode(this.props.code);
    const btnSettings = {
      fontSize:15,
      fontStyle:'bold',
      strokeWidth:.2,
      btnFill: layout2.darkBlue,
      btnStroke: layout2.darkBlue,
      btnCornerRadius:0,
      width:120,
      height:30,
      y:500,
    };
    const labelSetting = { stroke: mainColor, fill: mainColor, strokeWidth: .2, fontSize: 15, lineHeight: 1.3};

    const withSpaceship = pathandspeed12 || pathandspeed13;

    let correctMainChartImg = mainChartImg;
    const actorImg = withSpaceship ? shipImg : biker1Image;
    const [actor] = useImage(actorImg);
    const [successArrow] = useImage(successArrowImg);
    const [rightChart] = useImage(rightChartImg);
    const [mainChart] = useImage(data.mainChartImg || correctMainChartImg);
    const [craters] = useImage(cratersImg);


    const actorStgs = withSpaceship ? {
      rotation: 90,
      width: 40, height: 80,
      offset: {x: 10, y:80}
    } : {};
    const nextBtnHidden = stepData.next_btn_hidden;

    const sidePagePositionRight = data.sidePageSide === 'right';
    const titleXPos = sidePagePositionRight ? 40 : 530;

    const customRoad = withSpaceship ? (
      <Image image={craters} width={220} height={75} opacity={.2}/>
    ) : undefined;
    return (
      <React.Fragment>
        <Group>

          <Text ref={this._ref('title')} {...lsnTitleTxtStyle} x={titleXPos}/>

          <Group y={350} visible={!!data.roadVisible}>
            <Road
              id={1}
              ref={this._ref('road')}
              roadItemsCount={8}
              customImageNode={customRoad}
              roadWidth={0}
            />

            <Group ref={this._ref('biker')}>
              <Image
                visible={!!data.bikerVisible}
                x={-40} y={33}
                image={actor}
                {...actorStgs}
              />
            </Group>
          </Group>


          <Group x={560} y={100}>
            <Group x={100} y={100} visible={!!data.questionCardVisible}>
              <Card height={100} width={160}>
                <Group x={15} y={25}>
                  <Text x={0} y={-10} text={data.questionCardTxt} {...labelSetting}/>
                  <CanvasInput
                    id={'1'}
                    y={15}
                    x={0}
                    width={100}
                    height={30}
                    stage={this.stageRef?.current}
                    textColor={layout2.blue}
                    onInput={(val) => {
                      this.data.inputVal = val || null;
                    }}
                    value={this.data.inputVal || undefined}
                  />
                </Group>
              </Card>

              <CanvasButton
                text={'Проверить'}
                onClick={this.checkSuccess}
                x={20} y={135}
                height={40}
                strokeWidth={.3}
                fontSize={17}
              />

              <Text
                x={10} y={185}
                text={'Ответ неверный'}
                fill={'#999999'}
                fontSize={19}
                visible={!!data.errorVisible}
              />
            </Group>
          </Group>


          <Group x={500} y={130} visible={!!data.rightChartVisible}>
            <Image x={10} y={20} image={rightChart} preventDefault={false}/>
            {/*
              ------------------------------------
                Заменяем описание графика "Мотоциклист" на "Корабль"
              ------------------------------------
            */}
            <Group x={375} y={88} visible={withSpaceship}>
              <Rect width={80} height={15} fill={'white'}/>
              <Text text={'Корабль'} stroke={'rgba(0,0,0,0.5)'} strokeWidth={.5} fontSize={15}/>
            </Group>
            {/* ------------------------- */}


            <Group x={65} y={(pathandspeed6 || pathandspeed13) ? 232 : 199} visible={!!data.rightChartLineVisible}>
              <Circle ref={this._ref('rightChartPoint')} radius={3} fill={'#ff8e78'} />
              <Line ref={this._ref('rightChartLine')} points={[0,0,0,0]} stroke={'#ff8e78'} strokeWidth={1.5}/>
              <Line ref={this._ref('rightChartLine2')} points={[0,0,0,0]} stroke={'#ff8e78'} strokeWidth={1.5}/>
            </Group>

            <Group x={65} y={199} visible={!!data.rightChartLineResultVisible}>
              <Line y={32} points={[0,0,133,0]} stroke={'#ff8e78'} strokeWidth={1.5}/>
              <Line x={133} points={[0,0,98,0]} stroke={'#ff8e78'} strokeWidth={1.5}/>
            </Group>

            <Group visible={!!data.chartWithDragAndDropVisible}>
              <CanvasChartImg
                $this={this}
                y={238}
                getResetCallback={this.getResetChartLinesCallback}
                onDragStart={() => this.data.isLineDragged = true}
              />

              <Rect visible={!!data.firstSpeedStubVisible} x={66} y={100} width={130.5} height={163.5} fill={'white'} opacity={.7}/>
              <Rect visible={!!data.secondSpeedStubVisible} x={199} y={100} width={200} height={163.5} fill={'white'} opacity={.7}/>

              <CanvasButton
                btnRef={this._ref('checkRightChartBtn')}
                text={'Проверить'}
                onClick={this.checkSuccess}
                x={170} y={290}
                height={40}
                strokeWidth={.3}
                fontSize={17}
              />
              <Text
                x={300} y={295}
                text={'Ответ \nневерный'}
                fill={'#999999'}
                fontSize={19}
                visible={!!data.errorVisible}
              />
            </Group>
          </Group>



          <Group x={sidePagePositionRight ? 40 : 530}>
            <CanvasButton
              text={'Назад'}
              onClick={this.back}
              {...btnSettings}
            />
            <CanvasButton
              text={'Вперед'}
              onClick={this.next}
              {...btnSettings}
              x={130}
              visible={!nextBtnHidden}
            />

            <CanvasResetBtn
              onClick={this.resetScenario}
              x={340}
            />
          </Group>

          <Group x={500} ref={this._ref('mainChartContainer')}>
            <Rect width={500} height={600} fill={'#EFEBE4'} {...defaultCanvasShadowStyles}/>
            <Group x={20} y={20}>
              <Rect width={460} height={520} fill={'white'}/>
              <Image image={mainChart} preventDefault={false}/>
              {/*
                ------------------------------------
                  Заменяем описание графика "Мотоциклист" на "Корабль"
                ------------------------------------
              */}
              <Group x={365} y={63} visible={withSpaceship}>
                <Rect width={80} height={12} fill={'white'}/>
                <Text text={'Корабль'} stroke={'rgba(0,0,0,0.5)'} strokeWidth={.5} fontSize={15}/>
              </Group>
              {/* ------------------------- */}

              <Group x={55} y={445} visible={!data.mainChartImg}>
                <Circle ref={this._ref('mainChartPoint')} radius={3} fill={'#ff8e78'} />
                <Line ref={this._ref('mainChartLine')} points={[0,0,20,-20]} stroke={'#ff8e78'} strokeWidth={1.5}/>
              </Group>

              <Rect visible={!!data.firstSpeedStubVisible} x={55} y={100} width={131.5} height={344} fill={'white'} opacity={.7}/>
              <Rect visible={!!data.secondSpeedStubVisible} x={188} y={100} width={210} height={344} fill={'white'} opacity={.7}/>
            </Group>
          </Group>
        </Group>



        <Image visible={!!data.successVisible} x={660} y={180} image={successArrow}/>


        <Group x={250} y={120} visible={!!data.taskCompleteVisible}>
          <Rect width={450} height={330} fill={'white'} {...defaultCanvasShadowStyles} shadowOpacity={.5} cornerRadius={10}/>
          <Group x={140} y={180}>
            <Line points={[0,-50,60,0,170,-120]} strokeWidth={30} stroke={'#8ccbb6'}/>
            <Text width={450} align={'center'} x={-140} y={50} text={'Урок пройден!'} fill={'#8ccbb6'} fontSize={40}/>
          </Group>
        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer>
            <this.Scene />
          </Layer>
        </CanvasContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PathandspeedLongLesson);

const styles = {
  mainContainer: {
    height: "auto",
    background: "#EFEBE4",
  },
};
