import React, {useState} from "react";
import {Form, Input, Button, Upload, Modal, Spin, Space} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}




const PresentationForm = (props) => {
  const initialFormState = {title: props.title || '', fileList: props.imgs || []};
  const initialUploadState = {
    uploading: false,
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
  };

  const [error, setError] = useState(false);
  const [spinVisible, setSpinVisible] = useState(false);
  const [formState, setFormState] = useState(initialFormState);
  const [uploadState, setUploadState] = useState(initialUploadState);

  const handleCancel = () => setUploadState({ previewVisible: false });
  const handleChange = ({ fileList }) => {
    setError(false);
    setFormState((prev) => ({...prev, fileList: fileList }));
  };

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setUploadState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const onChangePresentationTitle = (value) => {
    setError(false);
    setFormState((prev) => ({...prev, title: value }))
  };

  const onSubmit = async () => {
    if (formState.title && formState.fileList.length) {
      setError(false);
      setSpinVisible(true);
      let formdata = new FormData();
      formdata.append('title', formState.title);
      formState.fileList.forEach((file, i) => {
        formdata.append('images[]', file.originFileObj);
      });
      const resp = await props.createPresentation(formdata);

      setSpinVisible(false);
      if (resp && resp.status) {
        props.setIsAddNew(false);
        props.setPresentationSelectedId(resp.currentPresentation.id);
      }
    } else {
      setError(true)
    }
  };

  const layout = {
    labelCol: { span: 8, },
    wrapperCol: { span: 16, },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16},
  };

  const { fileList, title } = formState;
  const { previewVisible, previewImage, previewTitle } = uploadState;
  return (
    <>
      <Form
        method="post"
        style={styles.form}
        encType={'multipart/form-data'}
      >
        <Form.Item
          {...layout}
          label={'Название презентации'}
        >
          <Input
            required
            type={'text'}
            name={'title'}
            value={title}
            onChange={(e) => onChangePresentationTitle(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          {...layout}
          label={'Загрузить изображения презентации'}
        >
          <Upload
            multiple
            className={'presentation-slides-upload'}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            name={'slides'}
          >
            {fileList.length >= 100 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Добавить изображения</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        {
          error ? (
            <Form.Item {...tailLayout}>
              <span className={'ant-typography ant-typography-danger'}>
                  {!formState.title ? 'Вы не добавили название презентации' : ''}
                <br/>
                {!formState.fileList.length ? 'Вы не добавили изображения' : ''}
              </span>
            </Form.Item>
          ) : (<></>)
        }
        <Form.Item {...tailLayout}>
          <Space>
            <Button type={'primary'} onClick={onSubmit} loading={spinVisible}>Сохранить</Button>
            <Button onClick={() => props.setIsAddNew(false)}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createPresentation: (formdata) => dispatch(actions.createPresentation(formdata)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PresentationForm);

const styles = {
  form: {
    width: 850,
    margin: '50px auto'
  }
};
