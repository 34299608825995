import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line, Transformer } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import columnImg from '../../../../images/toricelliExperiment/column.png';
import backgroundGroundImg from '../../../../images/toricelliExperiment/backgroundGround.png';
import backgroundMountainsImg from '../../../../images/toricelliExperiment/backgroundMountains.png';
import scaleImg from '../../../../images/toricelliExperiment/scale.png';
import standImg from '../../../../images/toricelliExperiment/stand.png';
import corkImg from '../../../../images/toricelliExperiment/cork.png';
import Card from "../../../canvas/components/Card";
import ArrowHint from "../../../canvas/components/ArrowHint";
import RadioSwitch from "../../../canvas/components/RadioSwitch";
import {mainColor, mainRedColor} from "../../../../utils/styles";
import {dragBoundFunc, onDragEnd, onDragStart, onDragMove, transformEnd, transformBoundBoxFunc, LSN_STEPS} from "../utils";
import Victor from "victor";
import * as actions from "../../../../store/actions";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {sendSuccessForScenario} from "../../../../utils/common";

const initialState = {
  corkFlew: false,
};

class ToricelliExperiment extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();

    this.backgroundGroundRef = React.createRef();
    this.backgroundMountainsRef = React.createRef();
    this.columnMercuryRef = React.createRef();
    this.columnMercuryContainerRef = React.createRef();
    this.standMercuryRef = React.createRef();

    this.placeForColumnRef = React.createRef();
    this.bindBoxColumnRef = React.createRef();

    this.moveHintRef = React.createRef();
    this.rotationHintRef = React.createRef();

    this.heightMercuryColumnTextRef = React.createRef();
    this.heightAboveSeaTextRef = React.createRef();

    this.textCardRef = React.createRef();
    this.scaleRef = React.createRef();
    this.switcherCardRef = React.createRef();
    this.columnMercuryCardRef = React.createRef();

    this.transformerRef = React.createRef();

    this.backgroundTogetherRef = React.createRef();
    this.corkRef = React.createRef();

    this.staticData = {
      maxHeightColumnMercury: 255,
      maxHeightStandMercury: 42,
      maxHeightAboveSea: 3000,
    };

    this.state = {};

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      step: LSN_STEPS.INITIAL,

      switchActive: false,

      heightStandMercury: 0,
      heightColumnMercury: 309,

      heightAboveSeaVal: 0,
      heightColumnMercuryVal: 0,

      backgroundTogetherRefY: 0,
      columnMercuryContainerY: 160,
      tiktak: 0,

      corkCoords: Victor(-1, 0),
      corkAngle: 0,
      corkSpeedVector: Victor(-100 + Math.random()*220, -130 + -Math.random()*300),
      corkRotationSpeed: 50 + Math.random()*280,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 15000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
  };

  getNodes = () => ({
    backgroundGroundNode: this.backgroundGroundRef?.current,
    backgroundMountainsNode: this.backgroundMountainsRef?.current,
    columnMercuryNode: this.columnMercuryRef?.current,
    columnMercuryContainerNode: this.columnMercuryContainerRef?.current,
    placeForColumnNode: this.placeForColumnRef?.current,
    bindBoxColumnNode: this.bindBoxColumnRef?.current,
    moveHintNode: this.moveHintRef?.current,
    rotationHintNode: this.rotationHintRef?.current,
    heightMercuryColumnTextNode: this.heightMercuryColumnTextRef?.current,
    heightAboveSeaTextNode: this.heightAboveSeaTextRef?.current,
    scaleNode: this.scaleRef?.current,
    textCardNode: this.textCardRef?.current,
    switcherCardNode: this.switcherCardRef?.current,
    columnMercuryCardNode: this.columnMercuryCardRef?.current,
    standMercuryNode: this.standMercuryRef?.current,
    transformerNode: this.transformerRef?.current,
    backgroundTogetherNode: this.backgroundTogetherRef?.current,

    corkNode: this.corkRef?.current,
  });

  get heightColumnMercury() {
    const data = this.data;
    const coeffiz = 20;
    if (data.switchActive) {
      return data.heightColumnMercuryVal > 532 ? data.heightColumnMercuryVal - coeffiz : 532;
    } else {
      return data.heightColumnMercuryVal < 760 ? data.heightColumnMercuryVal + coeffiz : 760;
    }

  }

  get heightAboveSea() {
    const {maxHeightAboveSea} = this.staticData;
    const data = this.data;
    const coeff = 200;
    if (data.switchActive) {
      return data.heightAboveSeaVal < maxHeightAboveSea ? data.heightAboveSeaVal + coeff : maxHeightAboveSea;
    } else {
      return data.heightAboveSeaVal > 0 ? data.heightAboveSeaVal - coeff : 0;
    }
  }

  move = (time) => {
    const data = this.data;
    const {burning, corkFlew, boiling, pushingPistol} = this.state;
    let {corkCoords, corkSpeedVector, corkRotationSpeed, pistonOffset} = data;
    const initStep = data.step === LSN_STEPS.INITIAL;
    const rotationStep = data.step === LSN_STEPS.ROTATION;
    const mountainsStep = data.step === LSN_STEPS.MOUNTAINS;
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    if (((mountainsStep && data.columnMercuryContainerY >= 248 ))) {
      this.setState({corkFlew: true})
    }

    if (corkFlew) {
      data.gasAmount = 0;
      corkCoords = corkCoords
          .add(Victor(corkSpeedVector.x, corkSpeedVector.y).multiply(Victor(timedeltaSec, timedeltaSec)));
      corkSpeedVector = corkSpeedVector
          .add(Victor(0, 300).multiply(Victor(timedeltaSec, timedeltaSec))); // gravity acceleration
      data.corkAngle += data.corkRotationSpeed * timedeltaSec;
    }

    if (data.step === LSN_STEPS.MOUNTAINS && data.columnMercuryContainerY <= 248){
        data.columnMercuryContainerY = data.columnMercuryContainerY + 2;
    }


    data.heightColumnMercuryVal = this.heightColumnMercury;
    data.heightAboveSeaVal = this.heightAboveSea;

    if (data.switchActive && this.data.backgroundTogetherRefY < 695) {
      this.data.backgroundTogetherRefY = this.data.backgroundTogetherRefY + 35;
    }
    if (!data.switchActive && this.data.backgroundTogetherRefY > 0) {
      this.data.backgroundTogetherRefY = this.data.backgroundTogetherRefY - 35;
    }

    if (data.switchActive && data.heightStandMercury < 9.8) {
      data.heightStandMercury = data.heightStandMercury + 0.2;
      data.heightColumnMercury = data.heightColumnMercury - 3.2;
    }
    if (!data.switchActive && data.heightStandMercury > 5.2 && data.tiktak === 25) {
      data.heightStandMercury = data.heightStandMercury - 0.2;
      data.heightColumnMercury = data.heightColumnMercury + 3.2;
    }

    if (data.tiktak < 25 && data.columnMercuryContainerY === 250) {
      data.tiktak = data.tiktak + 1;
      data.heightStandMercury = data.heightStandMercury + 0.2;
      data.heightColumnMercury = data.heightColumnMercury - 3.2;
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const {corkCoords, corkAngle} = this.data;

    const {
      columnMercuryNode,
      standMercuryNode,
      scaleNode,
      textCardNode,
      moveHintNode,
      switcherCardNode,
      rotationHintNode,
      columnMercuryCardNode,
      placeForColumnNode,
      backgroundMountainsNode,
      backgroundGroundNode,
      heightMercuryColumnTextNode,
      heightAboveSeaTextNode,
      backgroundTogetherNode,
      columnMercuryContainerNode,
      corkNode,
    } = this.getNodes();

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const initStep = data.step === LSN_STEPS.INITIAL;
    const rotationStep = data.step === LSN_STEPS.ROTATION;
    const mountainsStep = data.step === LSN_STEPS.MOUNTAINS;

    // INITIAL
    moveHintNode.opacity(Number(initStep));
    columnMercuryCardNode.opacity(Number(initStep));
    placeForColumnNode.opacity(Number(initStep));


    // ROTATION
    rotationHintNode.opacity(Number(rotationStep));

    // MOUNTAINS
    scaleNode.opacity(Number(mountainsStep));
    textCardNode.opacity(Number(mountainsStep));
    switcherCardNode.opacity(Number(mountainsStep));
    // standMercuryNode.opacity(Number(mountainsStep));

    backgroundTogetherNode.y(data.backgroundTogetherRefY);

    // backgroundMountainsNode.opacity(Number(data.switchActive))
    // backgroundGroundNode.opacity(Number(!data.switchActive))

    columnMercuryNode.height(data.heightColumnMercury*.8 + 62);
    standMercuryNode.height(data.heightStandMercury + 3);
    columnMercuryNode.rotation(0);
    columnMercuryNode.y(1);
    columnMercuryNode.x(3);
    scaleNode.y(-data.heightStandMercury - 220);

    heightMercuryColumnTextNode.text(data.heightColumnMercuryVal);
    heightAboveSeaTextNode.text(data.heightAboveSeaVal);
    columnMercuryContainerNode.y(data.columnMercuryContainerY);

    // corkNode.height(rotationStep || (mountainsStep && data.columnMercuryContainerY <= 248 ) ? 8 : 8);
    corkNode.x(corkCoords.x);
    corkNode.y(-corkCoords.y);
    corkNode.rotation(corkAngle);

    // console.log(corkNode.y())


    stageNode.draw();
  }




  Canvas = () => {
    const staticData = this.staticData;
    const data = this.data;

    const [column] = useImage(columnImg);
    const [backgroundGround] = useImage(backgroundGroundImg);
    const [backgroundMountains] = useImage(backgroundMountainsImg);
    const [scale] = useImage(scaleImg);
    const [stand] = useImage(standImg);
    const [cork] = useImage(corkImg);

    return (
      <React.Fragment>
        <Group>
          <Group ref={this.backgroundTogetherRef} y = {0}>
            <Image width={1000} height={700} image={backgroundMountains} ref={this.backgroundMountainsRef} y={-700}/>
            <Image width={1000} height={700} image={backgroundGround} ref={this.backgroundGroundRef} y={0} x = {0.5}/>
          </Group>

          <Group x={250} y={426}>
            <Image image={scale} x={80} y={-222} ref={this.scaleRef} width={25} height={170}/>
            <Image image={stand}/>
            <Rect ref={this.standMercuryRef} width={132} height={42} stroke={'#c0c0c0'} fill={'#c0c0c0'} rotation={180} x={193} y={45}/>
          </Group>

          {/* --------------- HINTS --------------- */}

          <Group ref={this.moveHintRef}>
            {/* Rotate hints */}
            <ArrowHint x={400} y={315} text={'Перемести пробирку сюда'}/>
          </Group>

          <Group x={370} y={80}>
            <Rect x={-27} y={-100} width={60} height={600} stroke={'transparent'} fill={'transparent'} ref={this.bindBoxColumnRef}/>
            <Rect width={8} height={315} fill={'#DCDCDC'} stroke={'#DCDCDC'} ref={this.placeForColumnRef}/>
          </Group>

          <Group ref={this.rotationHintRef}>
            {/* Rotate hints */}
            <ArrowHint x={390} y={60} text={'Переверни пробирку'}/>
            <ArrowHint x={360} y={410} text={' '} rotation={180}/>
          </Group>

          {/* ------------------------------ */}


          <Card width={120} height={500} x={800} y={10} reffer={this.columnMercuryCardRef}>
            <Text text={'Пробирка с ртутью:'} width={70} x={20} y={30} fontSize={14} strokeWidth={.8} stroke={mainColor} fill={mainColor}/>
          </Card>
          <Group
            x={830} y={80}
            draggable={true}
            dragBoundFunc={(pos) => dragBoundFunc(pos, this)}
            onDragEnd={(e) => onDragEnd(e, this)}
            onDragStart={(e) => onDragStart(e, this)}
            onDragMove={(e) => onDragMove(e, this)}
          >
            <Rect width={43} height={600} stroke={'transparent'} fill={'transparent'} x={-17} y={-20}/>
            <Group ref={this.columnMercuryContainerRef} offsetX={4} offsetY={155} y={154} height={313} width={9}>
              <Rect
                ref={this.columnMercuryRef}
                stroke={'#c0c0c0'} fill={'#c0c0c0'}
                width={3} height={280}
                rotation={180}
                x={6} y={310}
              />
              <Image image={column} />
              <Image image={cork} width={11} height={8} x={-1} ref={this.corkRef}
                     x={data.corkCoords.x} y={data.corkCoords.y}
                     rotation={data.corkAngle}/>
            </Group>
          </Group>
          <Transformer
            ref={this.transformerRef}
            resizeEnabled={false}
            // onTransform={(e) => console.log(e)}
            onTransformEnd={(e) => transformEnd(e, this)}
            boundBoxFunc={transformBoundBoxFunc}
          />


          <Card width={150} height={150} x={800} y={40} reffer={this.switcherCardRef}>
            <Text x={30} y={30} text={'Подняться в горы:'} width={100} fontSize={15} strokeWidth={.8} stroke={mainColor} fill={mainColor}/>
            <RadioSwitch
              x={30} y={80}
              stageNode={this.stageRef?.current}
              switchFieldName={'switchActive'}
              externalData={data}
              onClick={() => data.switchActive = !data.switchActive}
            />
          </Card>


          <Card width={150} height={200} x={400} y={220} reffer={this.textCardRef}>
            <Group x={15} y={15}>
              <Text text={'Высота ртутного столба, мм:'} width={120} fontSize={15} strokeWidth={.8} stroke={mainRedColor} fill={mainRedColor}/>
              <Text ref={this.heightMercuryColumnTextRef} y={35} text={'560'} width={120} fontSize={30} strokeWidth={.8} stroke={mainRedColor} fill={mainRedColor}/>
            </Group>

            <Group x={15} y={120}>
              <Text text={'Высота над уровнем моря, м:'} width={125} fontSize={15} strokeWidth={.8} stroke={mainRedColor} fill={mainRedColor}/>
              <Text ref={this.heightAboveSeaTextRef} y={35} text={'3000'} width={120} fontSize={30} strokeWidth={.8} stroke={mainRedColor} fill={mainRedColor}/>
            </Group>
          </Card>

        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code} >
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToricelliExperiment);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
