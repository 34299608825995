import React from "react";
import {Group, Rect} from "react-konva";
import {mergeRefs} from "../../../utils/common";


const Card = React.forwardRef((props, ref) => {
  const {
    x, y,

    reffer,
    rectRef,
    width,
    height,
    fill,
    stroke,
    strokeWidth,
    children,
    visible=true,
    opacity,
    cornerRadius=3
  } = props;
  return (
    <Group visible={visible} opacity={!visible ? 0 : opacity} x={x} y={y} ref={mergeRefs(reffer, ref)}>
      <Rect
        ref={rectRef}
        width={width || 150}
        height={height || 230}
        fill={fill || 'white'}
        stroke={stroke || 'transparent'}
        strokeWidth={strokeWidth || 1}
        shadowColor={'black'}
        shadowBlur={13}
        shadowOffsetX={4}
        shadowOffsetY={4}
        shadowOpacity={.2}
        cornerRadius={cornerRadius}
      />
      { children }
    </Group>
  )
});

export default Card;