import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import backgroundImg from '../../../../images/space/jumping_on_planets/background.png';
import {planetsDefaultPatchData, planetsScenario} from "../utils/planetsScenario";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import planet1Img from "../../../../images/space/jumping_on_planets/planet1.png";
import planet2Img from "../../../../images/space/jumping_on_planets/planet2.png";
import planet3Img from "../../../../images/space/jumping_on_planets/planet3.png";
import planet4Img from "../../../../images/space/jumping_on_planets/planet4.png";
import planet5Img from "../../../../images/space/jumping_on_planets/planet5.png";
import CanvasButton from "../../../canvas/components/CanvasButton";
import VideoBlock from "../../../canvas/components/CanvasVideoBlock";
import * as actions from "../../../../store/actions";
import Theory from "../../../components/theory/Theory";


class JumpingOnPlanets extends React.Component {
  constructor(props) {
    super(props);
    this.planets = [
      'planet1',
      'planet2',
      'planet3',
      'planet4',
      'planet5',
    ];
    this.managedComponents = [
      'stage',

      'checkPlanet',
    ]
    this.planets.forEach((p) => {
      this.managedComponents.push(p+'_circle');
    })
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      selectedPlanets: [],
      errAnswerCounter: 0,
      ...planetsDefaultPatchData,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(planetsScenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  onClickCheck = () => {
    const data = this.data;
    const corrAnswer = ['planet4', 'planet5'];
    const correctlyChosenPlanets = corrAnswer.every((k) => data.selectedPlanets.length === 2 && (data.selectedPlanets.includes(k)));
    if (!correctlyChosenPlanets) {
      this.data.errAnswerCounter++;
      this.data.selectCorrectPlanets = false;
    } else {
      this.data.selectCorrectPlanets = true;
    }
    this.scenarioManager.selectStepByKey(data.selectedPlanets[0]);
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;



    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));



    n['stage'].draw();
  }

  Planets = (props) => {
    const data = this.data;
    const [planet1] = useImage(planet1Img);
    const [planet2] = useImage(planet2Img);
    const [planet3] = useImage(planet3Img);
    const [planet4] = useImage(planet4Img);
    const [planet5] = useImage(planet5Img);
    let planetsData = {
      'planet1': {image: planet1, name: 'Планета ветров', g: 9.5},
      'planet2': {image: planet2, name: 'Альфа-1', g: 15},
      'planet3': {image: planet3, name: 'Альфа-2', g: 8},
      'planet4': {image: planet4, name: 'Альфа-3', g: 5},
      'planet5': {image: planet5, name: 'Альфа-4', g: 3},
    };
    return (
      <Group {...props}>
        {
          this.planets.map((planetKey,i) => {
            const selectedPlanet = data.selectedPlanets.includes(planetKey);
            const planetStg = data.planetsStgs[planetKey] || {visible: false};
            const planet = planetsData[planetKey];
            return (
              <Group key={planetKey} {...planetStg} onClick={() => {
                if (i) { // don't pushing first planet
                  const data = this.data;
                  const selectedPlanets = data.selectedPlanets;
                  if (selectedPlanets.includes(planetKey)) {
                    data.selectedPlanets = selectedPlanets.filter((k) => k!==planetKey);
                  } else {
                    data.selectedPlanets.push(planetKey);
                  }
                  this.forceUpdate(); // update component
                }
              }}>
                <Image image={planet.image}/>
                <Circle ref={this._ref(planetKey+'_circle')} {...planetStg.circleStg} stroke={'white'} strokeWidth={4} visible={selectedPlanet}/>
                <Text {...planetStg.textStg} text={planet.name} fontSize={20} fill={'white'}/>
                <Text {...planetStg.formula} text={'g = '+planet.g+' м/с²'} fill={'white'} fontSize={30}/>
              </Group>
            )
          })
        }
        <Group visible={data.planetEnvVisible}>
          <Text x={200} y={-50} text={'Выбери планеты:'} fontSize={20} fill={'white'}/>
          <Group x={-100} y={-70}>
            <Rect width={280} height={300} stroke={'white'} cornerRadius={[0, 20, 20, 0]}/>
            <Text x={30} y={250} text={'Прыжок пилота: 78 см'} fontSize={20} fill={'white'}/>
          </Group>
        </Group>
      </Group>
    )
  }
  Env = () => {
    const data = this.data;
    const [background] = useImage(backgroundImg);

    return (
      <React.Fragment>
        <Image image={background}/>

        <Text x={40} y={40} text={data.title} fontSize={30} fill={'white'}/>

        <CanvasButton
          visible={Boolean(data.selectedPlanets.length)}
          btnRef={this._ref('checkPlanet')}
          text={'Проверить'}
          onClick={() => {this.onClickCheck()}}
          fontSize={18}
          strokeWidth={.2}
          btnCornerRadius={0}
          btnFill={'white'}
          textFill={'#3d405b'}
          width={150}
          height={40}
          x={570} y={490}
        />
      </React.Fragment>
    )
  };

  render() {
    const data = this.data;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Env/>

            <this.Planets x={100} y={220}/>


            <CanvasResetBtn
              {...data.resetBtnStg}
              onClick={() => this.onClickReset()}
            />

            <VideoBlock
              src={this.data.animationSrc}
              onVideoEnd={() => {
                if (this.data.onVideoEnd) {
                  this.data.onVideoEnd(this);
                } else {
                  this.scenarioManager.next()
                }
              }}
              skipVideo={() => {this.data.skipVideo(this)}}
              visible={data.animationSrc}
              scenarioManager={this.scenarioManager}
            />

          </Layer>
        </CanvasContainer>

        {
          this.data.theoryVisible && (
            <Theory
              data={this.props.lessonData?.theory}
              onClickSkipTheory={() => this.data.onClickSkipTheory?.(this)}
              onClickReset={this.onClickReset}
            />
          )
        }
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JumpingOnPlanets);

const styles = {
  mainContainer: {
    background: '#0b242f'
  }
};
