import React, {useEffect, useState} from "react";
import '../../layout2/styles/layout.css';
import '../styles/homework.css';
import MainContainer from "../../layout2/components/MainContainer";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {layout2} from "../../../../utils/styles";
import {SolutionOutlined} from '@ant-design/icons';
import {Spin, Badge, Divider} from "antd";
import TeacherHwTasks from "../components/TeacherHwTasks";
import StudentHwTasks from "../components/StudenHwTasks";
import {Link, useHistory} from "react-router-dom";
import {userRoles} from "../../../../utils/common";
import useSetInterval from "../../../../utils/custom-hooks/useSetInterval";
import {EmptyComp} from "../components/EmptyComp";
import {ymTeacherClickCreateHomeworkGoal} from "../../../../metriks/ym_metriks";


const Homework = (props) => {
  const {
    user,
    homeworks,
    teacherPage,
    getHomeworkStats,
    selectedHomeworkId,
    setSelectedHomeworkId,

    selectedLesson,
    setCatalogLesson,
  } = props;

  const selectedHomework = homeworks?.find(hw => selectedHomeworkId === hw.id);

  const [loading, setLoading] = useState(false);
  const [resultTableVisible, setResultTableVisible] = useState(false);
  const [classrooms, setClassrooms] = useState([]);

  const history = useHistory();
  const isTeacherRole = user && (user.role === userRoles.teacher);


  useSetInterval(() => {
    fetchHwNotifications();
    props.fetchHomework(teacherPage);
  }, 10000, isTeacherRole && homeworks.length);

  useEffect(() => {
    window.scrollTo({top:0});
    props.setAddNewHomeworkFlag(false);
    (async function () {
      const resp = await props.fetchClassroomsData();
      setClassrooms(resp?.classroomsList || []);
    })();
    (async function () {
      setLoading(true);
      await props.fetchHomework(teacherPage);
      setLoading(false);

    })();
  }, []);

  useEffect(() => {
    /** Выбор первого ДЗ сразу после загрузки */
    if (!selectedHomeworkId) {
      const firstHw = homeworks?.[0];
      firstHw && setSelectedHomeworkId(firstHw.id);
    }
  }, [homeworks.length]);

  useEffect(() => {
    /** Функционал для оповещений
     *   - Фетч данных при загрузке
     *   - При размонтировании отправляется дата просмотра ДЗ учителем и
     *     делается фетч за обновленными данными
     */
    (async function () {
      await fetchHwNotifications();
    })()

    return async () => {
      await updateHwTimeForNotifications();
      await fetchHwNotifications();
    }
  }, [selectedHomeworkId]);

  useEffect(() => {
    if (user && teacherPage && (user.role !== userRoles.teacher)) {
      history.push('/student-homework')
    }
  }, [user?.role]);


  const updateHwTimeForNotifications = async () => {
    /**
     * Обновляет время просмотра ДЗ для функционала оповещений ДЗ
     */
    if (teacherPage && selectedHomeworkId) {
      await props.teacherSeenHomework(selectedHomeworkId);
    } else {
      // todo notifications for students
    }
  };
  const fetchHwNotifications = async () => {
    /**
     * Запрашивает у API данные для оповещений о изменениях в ДЗ
     */
    if (teacherPage && selectedHomeworkId) {
      await props.fetchHwTeacherNotifications();
    } else {
      // todo notifications for students
    }
  }


  const onClickCreateHw = async () => {
    ymTeacherClickCreateHomeworkGoal();
    const {newHomeworkId} = await props.createHomework();
    if (newHomeworkId) {
      setSelectedHomeworkId(newHomeworkId);
    }
  };

  const onClickRemoveTask = async (taskId) => {
    setLoading(true);
    await props.updateHomework({hwId: selectedHomework.id, taskId});
    setLoading(false);
  };


  const sortedLsns = selectedHomework?.tasks || [];
  const pageKey = teacherPage ? 'teacher-hw-page' : 'student-hw-page';

  const Menu = () => (
    <>
      {
        homeworks.map(hw => {
          const isSelected = selectedHomeworkId === hw.id;
          const hw_notify = props.homeworkNotifications.find(el => el.homeworkId === hw.id);
          return (
            <div
              onClick={() => {
                setSelectedHomeworkId(hw.id);
                setResultTableVisible(false);
              }}
              key={`hw-${hw.id}`}
              className={`hwItem`}
            >
              <h3 style={{
                fontFamily: isSelected ? 'Roboto-Bold' : 'Roboto',
                color: isSelected ? layout2.blue2 : layout2.lightblue,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between'
              }}>
                {hw.title}
                {
                  hw_notify ? (
                    <Badge color={'#FF9737'} className={'badge-custom-size'}/>
                  ) : null
                }
              </h3>
            </div>
          )
        })
      }
      {
        teacherPage ? (
          <>
            {
              Boolean(homeworks.length) &&
              <div style={{padding: '0 10px', margin: '15px 0 8px'}}><Divider style={{margin: 0}}/></div>
            }
            <div
              onClick={() => onClickCreateHw()}
              key={`hw-new`}
              className={`hwNewItem`}
              style={{color: layout2.blue2}}
            >
              + Add new homework
            </div>
          </>
        ) : null
      }
    </>
  )

  return (
    <div className={'layout2 homework'}>
      <MainContainer
        withDescription={true}
        pageKey={pageKey}
        menuChildren={<Menu />}
      >
        {
          selectedHomework ? (
            <>
              {
                teacherPage ? (
                  <TeacherHwTasks
                    visible={sortedLsns.length > 0}
                    mainLoading={loading}
                    classrooms={classrooms}
                    corrLessons={sortedLsns}
                    onClickRemoveTask={onClickRemoveTask}
                    selectedLesson={selectedLesson}
                    setSelectedLesson={setCatalogLesson}
                    selectedHomework={selectedHomework}
                    setSelectedHomeworkId={setSelectedHomeworkId}
                    refreshData={async () => {
                      await props.fetchHomework(true);
                    }}
                  />
                ) : (
                  <StudentHwTasks
                    visible={sortedLsns.length > 0}
                    mainLoading={loading}
                    classrooms={classrooms}
                    corrLessons={sortedLsns}
                    selectedLesson={selectedLesson}
                    setSelectedLesson={setCatalogLesson}
                    selectedHwId={selectedHomework.id}
                    refreshData={async () => {
                      await props.fetchHomework(false);
                    }}
                  />
                )
              }
            </>
          ) : (
            <EmptyComp
              icon={(props) => <SolutionOutlined {...props}/>}
              height={400}
              maxWidth={1000}
              top={120}
              text={
                <Spin size={'large'} spinning={loading}>
                  {
                  teacherPage ? (
                    <span className={'createHwSpan'} onClick={() => onClickCreateHw()}>
                      Create new homework
                    </span>
                  ) : (
                    <> No homeworks created yet </>
                  )}
                </Spin>
              }
            />
          )
        }
      </MainContainer>
    </div>
  )
}


const mapStateToProps = (state, {match}) => {
  return {
    user: state.lessonsReducer.user,
    chapters: state.lessonsReducer.chapters,
    homeworks: state.lessonsReducer.homeworks,
    lessonsGroups: state.lessonsReducer.lessonsGroups,
    selectedHomeworkId: state.lessonsReducer.selectedHomeworkId,
    homeworkNotifications: state.lessonsReducer.homeworkNotifications,

    selectedLesson: state.catalogReducer.selectedLesson
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClassroomsData: () => dispatch(actions.fetchClassroomsData()),
    fetchHomework: (isTeacher) => dispatch(actions.fetchHomework(isTeacher)),
    updateHomework: ({hwId, taskId}) => dispatch(actions.updateHomework({hwId, title: undefined, lsnId: taskId, action: 'remove'})),
    createHomework: (title) => dispatch(actions.createHomework(title)),
    getHomeworkStats: (homeworkId) => dispatch(actions.getHomeworkStats(homeworkId)),
    teacherSeenHomework: (hwId) => dispatch(actions.updateHomework({hwId, teacherSeenHw: true, title: undefined, lsnId: undefined, action: undefined})),
    setSelectedHomeworkId: (homeworkId) => dispatch(actions.setSelectedHomeworkId(homeworkId)),
    fetchHwTeacherNotifications: () => dispatch(actions.fetchHwTeacherNotifications()),
    setAddNewHomeworkFlag: (status) => dispatch(actions.setAddNewHomeworkFlag(status)),

    setCatalogLesson: (lesson) => dispatch(actions.setCatalogLesson(lesson)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Homework);
