import playIcon from "../../../images/play.png";
import {checkLessonStatus, userRoles} from "../../../utils/common";
import successIcon from "../../../images/success.png";
import failIcon from "../../../images/redcorss.png";
import React, {useEffect, useState} from "react";
import {withRouter, Link} from "react-router-dom";
import {Menu, Image} from "antd";
import ym from 'react-yandex-metrika';
import {connect} from "react-redux";
import {loginAction} from "../../../store/actions";


const LessonsMenu = (props) => {
  const {
    isTeacher,
    isDemo,
    correctLessons,
    activeLesson,
    lessonsAccess,
    selectLesson,
    lessonsGroups,
    history,
    match: {params}
  } = props;

  const correctLsnGroups = lessonsGroups.filter((group) => correctLessons.find((lsn) => lsn.group === group.id));
  // const isMenuBlocked = !isTeacher && !isDemo;
  const isMenuBlocked = false;

  const onClickMenuItem = (lsn) => {
    selectLesson(lsn);
    const path = isDemo ? 'demo' : 'main';
    history.push(`/${path}/${lsn.id}`);

    const correctCode = isDemo ? 'click_select-lesson_demo' : 'click_select-lesson_main';
    ym('reachGoal', correctCode, {lessonCode: lsn.code, lesson: lsn.title});
  };

  const CustomMenuItem = props => (
    <li
      key={props.lesson.id}
      onClick={() => onClickMenuItem(props.lesson)}
      className={`ant-menu-item ${props.isLessonActive && 'ant-menu-item-selected'}`}
      role="menuitem"
      style={{
        display: props.isCorrectGroup ? 'flex' : 'none',
        paddingLeft: '45px',
        height: 'auto'
      }}
    >
      <div className="ant-image" style={styles.antdImg}>
        <img src={props.correctIcon} width={17} style={styles.menuItemImg}/>
      </div>
      <span style={styles.menuLessonTitle}>{props.lesson.title}</span>
    </li>
  );
  const styles = getStyles({isMenuBlocked});
  const CustomSubMenu = ({...props}) => {
    const {group} = props;
    return (
      <Menu.SubMenu
        {...props}
        title={<p style={styles.menuGroupTitle}>{group.title}</p>}
        className={'submenuTitle'}
      >
        {
          (correctLessons && correctLessons.length) && (
            correctLessons.map((lesson, lessonIndex) =>
              {
                const isCorrectGroup = lesson.group === group.id;
                const isLessonActive = activeLesson && activeLesson.id === lesson.id;
                const lessonAccess = lessonsAccess.find(access => access.lesson === lesson.id);
                let correctIcon = playIcon;
                if (lessonAccess) {
                  const {success, fail} = checkLessonStatus(lessonAccess.status);
                  if (success)
                    correctIcon = successIcon;
                  if (fail)
                    correctIcon = failIcon;
                }
                return (
                  <CustomMenuItem
                    lesson={lesson}
                    isLessonActive={isLessonActive}
                    isCorrectGroup={isCorrectGroup}
                    correctIcon={correctIcon}
                    key={lesson.id}
                    icon={<Image src={correctIcon} width={17} style={styles.menuItemImg}/>}
                    title={lesson.title}
                    style={{display: isCorrectGroup ? 'block' : 'none'}}
                  />
                )
              }
            )
          )
        }
      </Menu.SubMenu>
    );
  };

  let correctGroupKey = correctLsnGroups.length ? correctLsnGroups[0].id : 1;
  if (params.id && activeLesson) {
    correctGroupKey = activeLesson.group;
  }

  return (
    <div style={styles.lessonsGroupStyle}>
      {
        correctLsnGroups.length ? (
          <Menu
            mode="inline"
            defaultOpenKeys={[`sub-${correctGroupKey}`]}
          >
            {
              (correctLsnGroups && correctLsnGroups.length) &&
              correctLsnGroups.map((group, groupIndex) => (
                <CustomSubMenu group={group} key={`sub-${group.id}`}/>
              ))
            }
          </Menu>
        ) : <></>
      }
    </div>
  )
};

const mapStateToProps = (state, {match}) => {
  const user = state.lessonsReducer.user;
  const lessons = state.lessonsReducer.lessons;
  const isTeacher = user && user.role === userRoles.teacher;

  const lessonSession = state.lessonsReducer.lessonSession;

  let correctLessons = lessons;
  if (lessonSession.token) {
    const selectedLessons = lessonSession.selectedLessons;
    correctLessons = lessons.filter((lsn) => selectedLessons[lsn.code]);
  }

  // const activeLsnCode = match.params.code;
  // const activeLesson = correctLessons.find((lsn, i) => {
  //   if (activeLsnCode) {
  //     return lsn.code === activeLsnCode;
  //   } else {
  //     return i === 0;
  //   }
  // });

  return ({
    isTeacher: isTeacher,
    correctLessons: correctLessons,
    selectedLessons: state.lessonsReducer.lessonSession.selectedLessons,
    activeLesson: state.lessonsReducer.activeLesson,
    // activeLesson: activeLesson,
    lessonsGroups: state.lessonsReducer.lessonsGroups,
    lessonsAccess: state.lessonsReducer.lessonsAccess,
  });
};

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(LessonsMenu));


const getStyles = (styleProps) => ({
  lessonsGroupStyle: {
    fontWeight: 'bold',
    position: 'relative',
    width: '100%',
    marginTop: '40px'
  },
  menuItemImg: {
    margin: '0 5px 0 -5px', position: 'relative', top: '0'
  },
  menuLessonTitle: {
    color: styleProps.isMenuBlocked ? 'gray' : 'initial',
    fontSize: '13px',
    lineHeight: '20px',
    whiteSpace: 'normal',
    display: 'flex',
    alignItems: 'center'
  },
  menuGroupTitle: {
    fontSize: '13px',
    whiteSpace: 'normal',
    lineHeight: '20px'
  },
  antdImg: {
    margin: '0px 5px 0px -10px',
    position: 'relative',
    top: '-1px',
    width: '17px'
  }
});
