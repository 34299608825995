import {Text} from "react-konva";
import React from "react";


export const theoryDefaultData = {
  theoryVisible: false,
  dialogVisible: false,
  dialogFontSize: 20,
  dialogStgRect: {},
  dialogText: '',
  projectorVisible: false,
  questionsListVisible: false,
  theoryQuestions: [],
  projectorContent: undefined,
  multipleStepsQuestion: false,
  nextQstStepDisabled: false,
  prevQstStepDisabled: false,
  skipTheoryOnClick: (component) => {console.log('skipTheoryOnClick')}
}

export const defaultImgOrVideoStyle = {
  x: 70,
  y: 160,
  width: 380,
  height: 220,
}


export const createTheoryStepsList = (theoryQuestionsData, questionsSteps) => {
  return ([
    {
      key: 'initial theory',
      patchData: {
        theoryVisible: true,
        dialogVisible: true,
        dialogText: 'Привет! \nВыбери интересующий \nвопрос из списка',
      },
      next_success: 'theory questions',
      next_failure: 'theory questions',
      switch_at: 3
    },{
      key: 'theory questions',
      patchData: {
        ...theoryQuestionsData,
      },
      next_success: 'success',
      next_failure: 'theory questions',
    },

    ...questionsSteps,

    {
      key: 'success',
      patchData: {
        theoryVisible: true,
        dialogVisible: true,

        dialogText: 'Задача \nпройдена!',
        dialogFontSize: 25,
        dialogStgRect: {
          width: 150,
          height: 100
        },
      },
      next_success: 'success',
      next_failure: 'theory questions',
    }
  ]);
}


// ------- Наработка по генерации конфига вопросов -------
// ---  Могут быть формулы подумать как в таком случае делать заголовок и сам контент

// const questions = [
//   {
//     key: 'theory Qst. 1',
//     question: {
//       text: 'Как будет двигаться космический корабль вдали от звёзд и планет?',
//       txtStg: {}
//     },
//     answer: {
//       text: 'Космический корабль будет двигаться по инерции.',
//       txtStg: {},
//       video: {},
//       img: {}
//     },
//     prev_question_key: '',
//     next_question_key: '',
//   },
//   {
//     key: 'theory Qst. 2',
//     question: {
//       text: 'Что такое движение по инерции?',
//       txtStg: {}
//     },
//     answer: {
//       text: 'Движение по инерции – это движение, при котором на тело не действуют другие тела.',
//       txtStg: {},
//       video: {},
//       img: {}
//     },
//     prev_question_key: '',
//     next_question_key: 'theory Qst. 2.1',
//   },
//   {
//     key: 'theory Qst. 2.1',
//     question: {
//       text: 'Что такое движение по инерции?',
//       txtStg: {}
//     },
//     answer: {
//       text: 'Движение по инерции – это движение, при котором на тело не действуют другие тела.',
//       txtStg: {},
//       video: {},
//       img: {}
//     },
//     prev_question_key: 'theory Qst. 2',
//     next_question_key: '',
//   },
//   {
//     key: 'theory Qst. 3',
//     question: {
//       text: 'Как меняется скорость тела, если оно движется по инерции?',
//       txtStg: {}
//     },
//     answer: {
//       text: 'Если тело движется по инерции, скорость тела остаётся неизменной. Это называют принципом инерции Галилея.',
//       txtStg: {},
//       video: {},
//       img: {}
//     },
//     prev_question_key: '',
//     next_question_key: '',
//   },
//   {
//     key: 'theory Qst. 4',
//     question: {
//       text: 'Как долго корабль может двигаться без топлива?',
//       txtStg: {}
//     },
//     answer: {
//       text: 'Корабль может двигаться бесконечно долго, если он не будет остановлен другим телом. Например, автомобиль с неработающим двигателем останавливается, потому что на него действует покрытие дороги.',
//       txtStg: {},
//       video: {},
//       img: {}
//     },
//     prev_question_key: '',
//     next_question_key: '',
//   },
// ];


