import React from "react";
import SimpleTaskScreen from "../../../whitelabel/containers/SimpleTaskScreen";
import {getCorrectMapCodeByLocation} from "../utils";
import closeImg from '../images/closeIcon.png';
import {Button} from "antd";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import SuccessImg from '../images/successArrow.png';
import { isEng } from "../../../../utils/lang/common";


const TaskModal = (props) => {
  const {
    match,
    taskModalVisible,
    getOrCreateMapProgress,
    taskSuccessVisible,
    taskFailureVisible,
    setTaskModalVisible,
    setTaskSuccessVisible,
    setTaskFailureVisible
  } = props;

  const history = useHistory();

  const closeTaskModal = () => {
    getOrCreateMapProgress();
    const pathname = getCorrectMapCodeByLocation(history.location.pathname);
    history.push(pathname);
    setTaskModalVisible(false);
    setTaskSuccessVisible(false);
    setTaskFailureVisible(false);
  }

  return (
    <div
      className={'course-map__modal'}
      style={{zIndex: taskModalVisible ? 100 : -1, opacity: Number(Boolean(taskModalVisible))}}
    >
      {
        match.params.id ? (
          <SimpleTaskScreen
            fullscreen={true}
            hideFullscreenBtn={true}
            match={match}
          />
        ) : null
      }
      {
        taskSuccessVisible ? (
          <div className={'course-map__task-success-modal'}>
            <h1>{isEng() ? "Complete!" : 'Задача выполнена!'}</h1>
            <img src={SuccessImg} />
            <Button onClick={closeTaskModal}>{isEng() ? "Return to the map" : 'Вернуться на карту'}</Button>
          </div>
        ) : null
      }
      {
        taskFailureVisible ? (
          <div className={'course-map__task-success-modal'}>
            <h1>{isEng() ? "Not complete!" : 'Задание не выполнено!'}</h1>
            <Button onClick={closeTaskModal}>{isEng() ? "Return to the map" : 'Вернуться на карту'}</Button>
          </div>
        ) : null
      }

      <div
        className={'course-map__modal-close'}
        style={{opacity: Number(Boolean(taskModalVisible))}}
        onClick={closeTaskModal}
      >
        <img src={closeImg} />
        {/*<CloseCircleOutlined/>*/}
      </div>
    </div>
  )
}


const mapStateToProps = state => ({})
const mapDispatchToProps = (dispatch, {match}) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskModal);
