import {Divider, Spin} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import HtmlContentRender from "../../../components/HtmlContentRender/HtmlContentRender";
import React, {useEffect, useState} from "react";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import SectionWrapper from "../components/SectionWrapper";


const News = (props) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    newsList: [],
    nextLink: undefined,
    paginationState: 0
  });

  useEffect(() => {
    fetchNews();
  }, [])

  const fetchNews = async (link) => {
    setLoading(true);
    const resp = await props.fetchNews(link);
    if (resp) {
      setState(prev => ({
        newsList: prev.newsList.concat(resp?.results),
        nextLink: resp?.links?.next,
        maxLessonsCount: resp?.count,
      }));
    }
    setLoading(false);
  }

  return (
    <SectionWrapper
      loading={loading}
      title={'Лента новостей'}
      contentClassName={'news-feed__news-items'}
    >
      <InfiniteScroll
        style={{padding: '0 20px'}}
        dataLength={state.newsList.length}
        next={() => fetchNews(state.nextLink)}
        hasMore={state.maxLessonsCount > state.newsList?.length}
        loader={
          <div style={{margin: '20px auto', display: 'flex', justifyContent: 'center'}}>
            <Spin spinning={true}/>
          </div>
        }
        endMessage={
          <Divider style={{margin: '20px 0'}} />
        }
      >
        {
          state.newsList.map((el, i) => {
            return (
              <div
                key={'news-item-'+i}
                className={'news-feed__news-item'}
                style={el?.bg_image ? {
                  display: 'block',
                  width: '100%',
                  backgroundImage: `url(${`${el?.bg_image}`})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  // backgroundSize: 'contain',
                  backgroundSize: 'cover',
                } : {}}
              >
                <div>
                  <h3 style={{fontWeight: 'bold'}}>{el.title}</h3>
                  <HtmlContentRender htmlForRender={el.content}/>
                </div>
              </div>
            )
          })
        }
      </InfiniteScroll>
    </SectionWrapper>
  )
}


const mapStateToProps = state => ({
  user: state.lessonsReducer.user,
});
const mapDispatchToProps = (dispatch, {match}) => ({
  fetchNews: (link) => dispatch(actions.fetchNews(link)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(News);

