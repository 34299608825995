import React, {useEffect, useState} from "react";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {getImageUrlByName} from "../../../../utils/common";
import '../styles/homeworkStudentsAchievements.css';
import SectionWrapper from "../../layout2/components/SectionWrapper";
import {Button} from "antd";


const HomeworkStudentsAchievements = (props) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    fetchHomeworkAchievements();
  }, [props.selectedHomeworkId])

  const fetchHomeworkAchievements = async () => {
    setLoading(true);
    const resp = await props.fetchHomeworkAchievements(props.selectedHomeworkId);
    if (resp?.results) {
      setRecords(resp.results);
    }
    setLoading(false);
  }

  return (
    <>
      {
        records.length ? (
          <SectionWrapper
            wrapperClassname={'homework-achievement'}
            loading={loading}
            titleContainerStyle={{margin: '0 0 3%'}}
            title={'Первые, решившие все задачи'}
            // btnAfterTitle={
            //   <Button
            //     type={'link'}
            //     className={'withUnderline'}
            //     onClick={fetchHomeworkAchievements}
            //   > Обновить </Button>
            // }
          >
              <div className={'homework-achievement__card'}>
                {
                  records.map(record => {
                    const img = getImageUrlByName(record.achievement?.image);
                    return (
                      <div className={'homework-achievement__item hw-achievement-item'} key={'achievement-'+record.id}>
                        <img src={img} className={'hw-achievement-item__image'}/>
                        <p className={'hw-achievement-item__username'}>{record.user.first_name} {record.user.last_name}</p>
                      </div>
                    )
                  })
                }
              </div>
            </SectionWrapper>
        ) : null
      }
    </>
  )
}


const mapStateToProps = state => ({
  selectedHomeworkId: state.lessonsReducer.selectedHomeworkId,
});
const mapDispatchToProps = (dispatch, {match}) => ({
  fetchHomeworkAchievements: (homeworkId) => dispatch(actions.fetchHomeworkAchievements(homeworkId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeworkStudentsAchievements);

