import Konva from "konva";
import {Image} from "react-konva";
import React from "react";
import {hasIntersection} from "../../../../utils/common";
import {getLsnNameByCode} from "./common";


export const getChartImgSetting = (code, itemsRowArr, stepKey) => {
  const {
    pathandspeed1, pathandspeed2, pathandspeed3, pathandspeed4, pathandspeed5,
    pathandspeed6, pathandspeed8, pathandspeed9, pathandspeed10, pathandspeed11,
    pathandspeed12, pathandspeed13 } = getLsnNameByCode(code);
  let settings = {};
  if (pathandspeed1) {
    settings = {
      boundFunc: { top: .32, bottom: .67 },
      cellHeight: 44,
      selectedValues: itemsRowArr.map((el, i) => (i+1)*5)
    };
  } else if (pathandspeed2 || pathandspeed4 || pathandspeed8 || pathandspeed9 || pathandspeed11) {
    settings = {
      rotationIndexItems: [2, 3],
      rotationDeg: -49,
      boundFunc: { top: .48, bottom: .8 },
      cellHeight: 46.7,
      dropBoxContainerY: 11,
      containerHeight: 177,
      selectedValues: itemsRowArr.map((el, i) => (i+1)*10),
      randomLinePos: true
    };
    if (pathandspeed11) {
      settings = {...settings, withScenario: true}
    }
  } else if (pathandspeed3 || pathandspeed10) {
    settings = {
      boundFunc: { top: .44, bottom: .8 },
      cellHeight: 46.7,
      dropBoxContainerY: 11,
      containerHeight: 177,
      selectedValues: itemsRowArr.map((el, i) => i+70)
    };
    if (pathandspeed10) {
      settings = {...settings, withScenario: true}
    }
  } else if (pathandspeed5 || pathandspeed12) {
    settings = {
      withScenario: true,
      boundFunc: { top: .45, bottom: .67 },
      cellWidth: 33,
      cellHeight: 33,
      dropBoxContainerY: 11,
      containerHeight: 177,
      selectedValues: itemsRowArr.map((el, i) => (i)*20),
    };
  } else if ( pathandspeed6 || pathandspeed13) {
    settings = {
      withScenario: true,
      boundFunc: { top: .45, bottom: .67 },
      cellWidth: 33,
      cellHeight: 33,

      startColumnIndex: 0,

      dropBoxContainerY: 11,
      containerHeight: 177,
      selectedValues: itemsRowArr.map((el, i) => (i)*20),
    };

    if (['step8', 'step8.1'].includes(stepKey)) {
      settings = {
        ...settings,
        startColumnIndex: 4,
      }
    }
  }

  return settings;
}

const getLineIntersection = ($this, columnI) => {
  const nodes = $this.getNodes();

  let lineIndexActive = undefined;
  $this.data.itemsRowArr.forEach((el, rowI) => {
    const lineNode = nodes[`dragLine${columnI}Node`];
    const boxNode = nodes[`actionBox${rowI}${columnI}Node`];
    if (hasIntersection(boxNode, lineNode, $this)) {
      lineIndexActive = rowI;
    }
  })

  return lineIndexActive
}

const setLinePos = ($this, lineNumber, selectedPos) => {
  const nodes = $this.getNodes();

  const line = nodes[`dragLineContainer${lineNumber}Node`];
  const linePos = line.getAbsolutePosition();

  const box = nodes[`actionBox${selectedPos}${lineNumber}Node`];
  const boxPos = box.getAbsolutePosition();

  line.setAbsolutePosition({
    x: linePos.x, y: boxPos.y
  });
}
export const setInitialPosLinePos = ($this) => {
  const nodes = $this.getNodes();

  const defaultRowI = 4;
  $this.data.itemsColumnArr.forEach((el, columnI) => {
    const line = nodes?.[`dragLineContainer${columnI}Node`];
    const box = nodes?.[`actionBox${defaultRowI}${columnI}Node`];
    if (line && box) {
      const linePos = line.getAbsolutePosition();
      const boxPos = box.getAbsolutePosition();

      line.setAbsolutePosition({
        x: linePos.x, y: boxPos.y
      });
    }
  });
}


export const onDragBoundFunc = ($this, pos, cellI) => {
  const stageNode = $this.stageRef?.current;
  const stageWidth = stageNode.width();
  const stageHeight = stageNode.height();
  const nodes = $this.getNodes();

  const linePos = nodes[`dragLineContainer${cellI}Node`].getAbsolutePosition();

  let leftBorderX = linePos.x;
  let rightBorderX = leftBorderX;
  let topBorderY = stageHeight * .33;
  let bottomBorderY = stageHeight * .66;

  const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
  const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
  return {y: newY, x: newX};
}

export const onDragEnd = ($this, e, cellI) => {
  const selectedLineNumber = getLineIntersection($this, cellI);
  if (selectedLineNumber !== undefined) {
    $this.data.selectedData[`line${cellI+1}`] = (selectedLineNumber+1)*100;
    setLinePos($this, cellI, selectedLineNumber);
  }
}
export const onDragMove = ($this, e, cellI) => {

}
export const onDragStart = ($this, e, cellI) => {
  $this.data.isLineDragged = true;
}


