import React, {useEffect, useState} from "react";
import {Modal} from "antd";
import {getTaskCode} from "../../../utils/common";
import {getHeight, width} from "../../canvas/containers/CanvasContainer";


export const PopupExample = () => {
  const [resizeCount, setResizeCount] = useState(0);
  const [modalTaskCode, setModalTaskCode] = useState(null);

  const onHashChange = () => {
    const hash = window.location.hash;
    const popupCode = '#reactPopup:';
    if (hash.includes(popupCode)) {
      const code = hash.replace(popupCode, '');
      const taskCode = getTaskCode(code);
      setModalTaskCode(taskCode || null);
    }
  }

  const onResize = () => {
    setResizeCount(prev => prev+1);
  }
  useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener("hashchange", onHashChange);
    return () => {
      window.removeEventListener("hashchange", onHashChange);
      window.removeEventListener('resize', onResize);
    }
  }, [])


  let code = modalTaskCode?.replace('/', '');
  const canvasHeight = getHeight(code);
  const windowHeight = document.documentElement.clientHeight;
  const windowWidth = document.documentElement.clientWidth;
  let modalWidth = width;
  let modalHeight = canvasHeight;
  if (canvasHeight >= windowHeight) {
    modalHeight = windowHeight-25; // Отнял 25px от высоты, чтобы убрать скролл
  }
  if (windowWidth < width) {
    modalWidth = windowWidth;
  }
  const modalPercent = modalHeight / modalWidth * 100;
  return (
    <Modal
      title={null}
      centered
      style={{margin: 0, padding: 0}}
      visible={Boolean(modalTaskCode)}
      onCancel={() => {
        window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
        setModalTaskCode(null);
      }}
      width={modalWidth}
      bodyStyle={{paddingBottom: `${modalPercent}%`, position: 'relative'}}
      footer={null}
    >
      <div style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}}>
        <iframe
          src={"https://app.inventic.tech/task/ru/"+modalTaskCode}
          width={'100%'}
          height={'100%'}
        />
      </div>
    </Modal>
  )
}
