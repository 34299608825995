import { Image } from "react-konva";
import React from "react";
import {pxPerMetre} from "../settings";

export const MiniSpaceShip = ({shipImage, rotation}) => {
  const width = 55. / 17.5; // In metres
  const height = 25. / 17.5;

  return(
      <Image
          image={shipImage}
          width={width * pxPerMetre}
          height={height * pxPerMetre}
          offsetX={width * pxPerMetre/2}
          offsetY={height * pxPerMetre/2}
          rotation={rotation}
      />
  )
};
