import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import rowImg1 from "../images/continueRow/GreeceBlocks_2_1_row_1.png";
import rowImg2 from "../images/continueRow/GreeceBlocks_2_1_row_2.png";
import rowImg3 from "../images/continueRow/GreeceBlocks_2_1_row_3.png";

import dragImg1 from "../images/continueRow/GreeceBlocks_2_1_drag_1.png";
import dragImg2 from "../images/continueRow/GreeceBlocks_2_1_drag_2.png";
import dragImg3 from "../images/continueRow/GreeceBlocks_2_1_drag_3.png";
import dragImg4 from "../images/continueRow/GreeceBlocks_2_1_drag_4.png";


import rowImg21 from "../images/continueRow/GreeceBlocks_2_2_row_1.png";
import rowImg22 from "../images/continueRow/GreeceBlocks_2_2_row_2.png";
import rowImg23 from "../images/continueRow/GreeceBlocks_2_2_row_3.png";

import dragImg21 from "../images/continueRow/GreeceBlocks_2_2_drag_1.png";
import dragImg22 from "../images/continueRow/GreeceBlocks_2_2_drag_2.png";
import dragImg23 from "../images/continueRow/GreeceBlocks_2_2_drag_3.png";
import dragImg24 from "../images/continueRow/GreeceBlocks_2_2_drag_4.png";

import bgNature from '../images/greece-flowers-bg.png';

import ContinueRowTask from "./ContinueRowTask";


const GreeceBlocks2 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'Move the missing simple machine',
      rowItems: [
        {id: 1, image: rowImg1},
        {id: 2, image: rowImg2},
        {id: 3, image: rowImg3},
      ],
      dragItems: [
        {id: 1, image: dragImg1},
        {id: 2, image: dragImg2, success: true},
        {id: 3, image: dragImg3},
        {id: 4, image: dragImg4},
      ]
    },
    {
      title: 'Move the missing simple machine',
      rowItems: [
        {id: 1, image: rowImg21},
        {id: 2, image: rowImg22},
        {id: 3, image: rowImg23},
      ],
      dragItems: [
        {id: 1, image: dragImg21},
        {id: 2, image: dragImg22},
        {id: 3, image: dragImg23},
        {id: 4, image: dragImg24, success: true},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <ContinueRowTask
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    rowItems={item.rowItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default GreeceBlocks2;
