import React from "react";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Group, Layer, Rect, Text } from "react-konva";
import {layout2, mainColor} from "../../../../utils/styles";
import CanvasInput from "../../../canvas/components/CanvasInput";
import CanvasButton from "../../../canvas/components/CanvasButton";
import ScenarioManager from "../../../../utils/ScenarioManager";


/*
 Конфиг - это массив шагов, между которыми переключаетсмя задача

 Механизм переключения описан и реализован в класса ScenarioManager (импорт выше)
*/


const scenarioConfig = [
  {
    // ключ должен быть уникальным для каждого шага
    key: 'chart_meaning',
    // В каждом шаге сценария может быть текст. Если несклько текстов, это разные шаги.
    text: { value: 'Step 1: Нажмите на синий прямоугольник или подождите 3 сек' },
    // Это объект, который мёржится с data из задачи при переходе на этот шаг
    // То есть поля, указанные здесь, перезаписывают поля из data
    // Это нужно для
    // - Перемещения элементов (переписать координаты)
    // - Скрытия и показа элементов
    // - задания свойств (opacity)
    // - сброса и отрисовки графиков (например, переходишь назад - данные графика нужно сбросить)
    // Все элементы, которые управляются сценарием, нужно параметризовать здесь.
    patchData: {
      blueRectVisible: true,
      sidePageCoords: {x: 400, y: 0}
    },
    // куда переходит, если шаг выполнен успешно (вызвана функция success). если не указано, то следующий шаг
    next_success: 'calculate_path_1',
    // куда переходит, если шаг выполнен успешно (вызвана функция failure). если не указано, то предыдущий шаг
    next_failure: 'calculate_path_1',
    switch_at: 3, // время, через которое автоматически происходит вызов success текущего шага
  },
  {
    key: 'calculate_path_1',
    text: { value: 'Step 2: Введите в инпут 10' },
    patchData: {
      blueRectVisible: false,
      sidePageCoords: {x: 250, y: 0}
    },
    // явно указывает, куда переходить в случае клика назад
    previous: 'chart_meaning',
    next_success: 'after_value_input',
    next_failure: 'chart_meaning',
  },
  {
    key: 'hidden_step',
    text: { value: 'Step 3.5: Сюда можно попоасть только если нажмешь назад на последнем шаге' },
    patchData: {
      blueRectVisible: false,
      sidePageCoords: {x: 800, y: 0}
    },
  },
  {
    key: 'after_value_input',
    text: { value: 'Step 3: все работает' },
    patchData: {
      blueRectVisible: false,
      sidePageCoords: {x: 600, y: 0}
    },
    previous: 'hidden_step'
  },
];


class ExampleScenarioLongLesson extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();
    this.managedComponents = [
      'stage',
      'scenarioText',
      'sidePage'
    ];

    for (let el of this.managedComponents) {
      this[`${el}Ref`] = React.createRef();
    }

    this.data = {
      inputVal: 0,
      sidePageCoords: {x: 800, y: 0},
      blueRectVisible: true,
    };

    this.scenarioManager = new ScenarioManager(scenarioConfig, this);
  }

  _ref = (key) => this[`${key}Ref`];
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    data.startTime = data.startTime || time;
    const timestampSec = (time - data.startTime)/1000;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    this.updateStage();
  };

  updateStage = () => {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['scenarioText'] && n['scenarioText'].text(this.scenarioManager.getStepData().text.value);

    n['sidePage'] && n['sidePage'].x(data.sidePageCoords.x);
    n['sidePage'] && n['sidePage'].y(data.sidePageCoords.y);

    n['stage'] && n['stage'].draw();
  };

  Scene = () => {
    const data = this.data;

    return (
      <React.Fragment>
        <Group>
          <Rect
            ref={this._ref('sidePage')}
            fill={'grey'}
            width={150}
            height={650}
            x={0}
            y={0}
          />
          <Text
            x={20} y={30}
            ref={this._ref('scenarioText')}
            text={"Hello world!"}
            fontSize={18}
            fill={mainColor}
          />
          <Rect
            onClick={() => this.scenarioManager.success()}
            fill={'blue'}
            width={50}
            height={50}
            y={70}
            x={0}
            visible={data.blueRectVisible}
          />
          <CanvasInput
            id={'1'}
            y={175}
            x={10}
            width={100}
            height={30}
            stage={this._getNode('stage')}
            textColor={layout2.blue}
            onInput={(val) => {
              this.data.inputVal = parseInt(val);
            }}
            value={this.data.inputVal}
          />
          <CanvasButton
            x={10} y={215}
            text={'Проверить инпут'}
            fontStyle={'normal'}
            strokeWidth={.5}
            fontSize={15}
            height={30}
            onClick={() => {
              if (this.data.inputVal === 10) {
                this.scenarioManager.success('calculate_path_1')
              } else {
                this.scenarioManager.failure('calculate_path_1')
              }}
            }
          />
          <CanvasButton
            x={10} y={275}
            text={'Назад'}
            fontStyle={'normal'}
            strokeWidth={.5}
            onClick={this.scenarioManager.back}
            fontSize={15}
            height={30}
          />
          <CanvasButton
            x={10} y={320}
            text={'Сбросить сценарий'}
            fontStyle={'normal'}
            strokeWidth={.5}
            onClick={this.scenarioManager.resetScenario}
            fontSize={15}
            height={30}
          />
        </Group>
      </React.Fragment>
    )

  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer>
            <this.Scene />
          </Layer>
        </CanvasContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExampleScenarioLongLesson);

const styles = {
  mainContainer: {
    height: "auto",
    background: "#EFEBE4",
  },
};
