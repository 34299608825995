import React, {useEffect, useRef, useState} from "react";
import cloneDeep from 'lodash.clonedeep';
import {Line as ChartLine} from 'react-chartjs-2';
import {getChartInitialData, getChartInitialOptions, pointRadiusLast} from './utils/common';


class Chart extends React.Component {
  constructor(props) {
    super(props);

    const customInitialData = props.customInitialData;

    this.chartRef = React.createRef();
    this.state = {};
    this.initialData = customInitialData ? customInitialData() : getChartInitialData(props);
    this.initialOptions = getChartInitialOptions(props);
    this.data = cloneDeep(this.initialData);
    this.options = cloneDeep(this.initialOptions);

    this.startTime = 0;
    this.prevTime = 0;

    this.requestId = null;

  }

  reset = () => {
    const chartNode = this.chartRef?.current;
    if (chartNode) {
      const lineChart = chartNode.chartInstance;
      const xTicks = lineChart.config.options.scales.xAxes[0].ticks;
      const yTicks = lineChart.config.options.scales.yAxes[0].ticks;
      lineChart.config.data.labels = [...this.initialData.labels];
      lineChart.config.data.datasets.forEach((dataset) => {
        dataset.data.splice(0, dataset.data.length);
      })

      // reset axis settings to initial
      xTicks.max = this.props.xMax;
      xTicks.stepSize = this.props.xStep;
      yTicks.max = this.props.yMax;
      yTicks.stepSize = this.props.yStep;

      lineChart.update();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentLessonCode !== this.props.currentLessonCode) {
      const chartNode = this.chartRef?.current;
      if (chartNode) {
        const lineChart = chartNode.chartInstance;
        this.initialData = getChartInitialData(this.props);
        this.initialOptions = getChartInitialOptions(this.props);
        this.data = cloneDeep(this.initialData);
        this.options = cloneDeep(this.initialOptions);

        lineChart.config.data.datasets[0].label = this.data.datasets[0].label;
        lineChart.options = this.options;

        lineChart.update();
      }
    }
  }
  componentDidMount() {
    this.props.getResetCallback(this.reset);
    window.requestAnimationFrame(this.move)
  }

  move = (time) => {
    this.requestId = window.requestAnimationFrame(this.move);
    const data = this.data;

    if (this.props.start()) {
      this.startTime = this.startTime || time;
      this.prevTime = time;

      this.updateChart();
    }
  };

  updateChart = () => {
    const chartNode = this.chartRef?.current;
    if (chartNode) {
      const data = this.data;
      const lineChart = chartNode.chartInstance;
      const dataSets = lineChart.config.data.datasets;

      dataSets.forEach((dataSet, i) => {
        const xData = dataSet.data;

        const pointsValues = this.props.pointsValues()[i];

        const correctValX = Number(pointsValues.x);
        const correctValY = Number(pointsValues.y);

        const xTicks = lineChart.config.options.scales.xAxes[0].ticks;
        const yTicks = lineChart.config.options.scales.yAxes[0].ticks;

        const maxDataX = Math.max(...xData.map(el => el.x ? el.x : 0));
        const maxDataY = Math.max(...xData.map(el => el.y ? el.y : 0));

        // set point radius only to the last point
        dataSet.pointRadius = pointRadiusLast(3, xData.length);

        // update values for x
        if (maxDataX > xTicks.max) {
          xTicks.max *= 2;
          xTicks.stepSize *= 2;
        }
        // update values for y
        if (maxDataY > yTicks.max) {
          yTicks.max *= 2;
          yTicks.stepSize *= 2;
        }
        const timedeltaSec = Math.abs((this.startTime - this.prevTime) / 300);
        //
        // console.log(Math.round(timedeltaSec) % 5);
        // if (Math.round(timedeltaSec) % 5 === 0 || timedeltaSec === 0) {
        //
        // }

        xData.push({x: correctValX, y: correctValY});
        lineChart.update();
      })
    }
  };

  render() {
    const {titleFontSize} = this.props;
    return (
      <>
        <ChartLine
          width={this.props.width}
          height={this.props.height}
          ref={this.chartRef}
          data={this.data}
          options={this.options}
          plugins={[
            {
              /* Adjust axis labelling font size according to chart size */
              beforeUpdate: function(c) {

                const chartHeight = c.chart.height;
                let size = chartHeight * 5 / 100;
                if (size > 18) {
                  size = 18;
                }

                c.titleBlock.options.fontSize = titleFontSize || size;
                c.titleBlock.height = size*2;

                // c.options.legend.labels.boxWidth = size*2-3;
                c.options.legend.labels.boxWidth = 3;
                c.options.legend.labels.fontSize = size-3;

                c.options.scales.xAxes[0].scaleLabel.fontSize = size-3;
                c.options.scales.yAxes[0].scaleLabel.fontSize = size-3;

                c.scales['y-axis-0'].options.ticks.minor.fontSize = size;
                c.scales['x-axis-0'].options.ticks.minor.fontSize = size;
              }
            }]
          }
        />
      </>
    )
  }
};

export default Chart;
