import React, {useState, useEffect, useRef} from "react";
import * as actions from '../../../../store/actions';
import moment from "moment";
import {connect} from "react-redux";
import {
  Group,
  Layer,
  Circle,
  Image,
  Text,
  Line,
  Arrow,
} from "react-konva";
import useImage from 'use-image';
import Victor from 'victor';

import {ObjDescriptionsContainer} from "../components/ObjDescription";
import {CanvasContainer} from "../../../canvas";
import {FieldBlock} from "../components/FieldBlock";
import {EngineButton} from "../components/EngineButton";
import {CanvasSpaceShip} from "../components/CanvasSpaceShip";
import {CanvasEngine} from "../components/CanvasEngine";
import {CanvasSpaceStation} from "../components/CanvasSpaceStation";
import {MiniSpaceShip} from "../components/MiniSpaceShip";
import {MiniSpaceStation} from "../components/MiniSpaceStation";
import {CanvasAsteroid} from "../components/CanvasAsteroid";
import {kmhToMs, lessonStatus, msToKmh, radToDeg} from "../../../../utils/common";
import shipImg from '../images/ship.png'
import boxImg from '../images/Box.png'
import engineImg from '../images/engine.png'
import stationImg from '../images/station.png'
import asteroidImg from '../images/comet.png'
import axisXImg from '../images/axisX.png'

import brokenShipImg from '../images/ship_broken.png'
import brokenStationImg from '../images/station_broken.png'

import stationMiniImg from '../images/stationMini.png'
import shipMiniImg from '../images/shipMini.png'
import {CanvasBox} from "../components/CanvasBox";

import {pxPerMetre, asteroidFlightTime, asteroidScale, massInKilos} from "../settings";
import CanvasGrid from "../../ship/components/CanvasGrid";
import {_t} from "../../../../utils/lang/common";
import {getStateByCode, calcCollisionSpeeds, pxVictor} from "../helpers";
import {Input} from 'antd';
import {checkToSuccess, getCorrectSettings, getNewState} from "../utils/utils";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import TopLsnInfo from "../components/TopLsnInfo";
import Card from "../../../canvas/components/Card";
import {layout2, mainColor} from "../../../../utils/styles";
import CanvasInput from "../../../canvas/components/CanvasInput";

const {
  fround,
  round
} = Math;

let snapshots = [];

const Spaces = (props) => {
  const {
    code,
    isFrame,
    withDescr,
    activeLessonId,
    updateLessonStatus,
    changeSuccessVisible,
    changeFailureVisible,
    updateActiveLesson,
  } = props;

  const initialState = getStateByCode(code);
  const [state, setState] = useState(initialState);
  const [showRecord, setShowRecord] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState(0);
  const containerRef = useRef(null);
  const stageRef = useRef(null);


  useEffect(() => {
    setState(initialState);
    setShowRecord(false);
  }, [code]);

  useEffect(() => {
    if (state.running)
      window.requestAnimationFrame(move);
  },[state.running]);


  // ============= INPUT BLOCK =============
  const { fieldSettings, otherFieldSettings, moreFieldSettings } = getCorrectSettings(code, state);

  const preTimeRef = useRef(0);

  const move = (time) => {
    const RequestId = window.requestAnimationFrame(move);


    const preTime = preTimeRef.current;

    if ((time - preTime) < 20)
      return;

    preTimeRef.current = time;

    setState((prevState) => {

      if (!prevState.running)
        window.cancelAnimationFrame(RequestId);

      let isDocking = prevState.docking;
      const animationShown = prevState.animationShown;
      const isSuccess = checkToSuccess(prevState.shipSpeed.x, code, state);


      // ================= TIME =======================
      let newStartAfterDocking = prevState.startAfterDocking || time;
      const stationTimedelta = time - newStartAfterDocking;
      const stationTimedeltaSec = stationTimedelta / 1000;

      let newStart = prevState.start || time;
      const shipTimedelta = time - newStart;
      const shipTimedeltaSec = shipTimedelta / 1000;

      const engineIsOn = prevState.engineIsOn;

      const moveProps = {
        state,
        code,
        finishFail,
        finishSuccess,
        newStart,
        shipTimedeltaSec,
        stationTimedeltaSec,
        newStartAfterDocking,
        prevState,
        RequestId,
        isSuccess,
        isDocking,
        animationShown,
        engineIsOn
      };

      const newState = getNewState(moveProps, code);

      const resState = {
        ...prevState,
        start: newStart,
        ...newState,
      };

      snapshots.push({
        time: shipTimedeltaSec,
        snapshot: {...resState, running: false},
      });

      return resState ;
    });
  };

  const finishFail = () => {
    setTimeout(() => {
      changeFailureVisible(true);
    }, 1000);
    if (activeLessonId) {
      updateActiveLesson({'finish_time': moment().utc()});
      updateLessonStatus(lessonStatus.FAIL, activeLessonId);
    }
  };
  const finishSuccess = () => {
    setTimeout(() => {
      changeSuccessVisible(true);
    }, 1000);
    if (activeLessonId) {
      updateActiveLesson({'finish_time': moment().utc()});
      updateLessonStatus(lessonStatus.SUCCESS, activeLessonId);
    }
  };

  const startOnCLick = () => {
    setSelectedFrame(0);
    setShowRecord(false);
    snapshots = [];
    setState((prevState) => ({
        ...prevState,
        ballStart: null,
        docking: false,
        start: null,
        startAfterDocking: null,
        running: true,
    }));
  };

  const resetOnCLick = () => {
    setSelectedFrame(0);
    setShowRecord(true);
    setState((prevState) => {
      return({
        ...getStateByCode(code),
        [fieldSettings.name]: prevState[fieldSettings.name],
        [otherFieldSettings.name]: prevState[otherFieldSettings.name],
        [moreFieldSettings.name]: prevState[moreFieldSettings.name],
      })
    });
  };

  const onChangeInput = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    const correctVal = parseFloat(val) || parseFloat(val) === 0 ? Number(val) : '';
    setState((prevState) => ({
      ...prevState,
      [name]: correctVal
    }));
  };

  const onTouchDown = () => {
    setState((prevState) => ({
      ...prevState,
      engineIsOn: true
    }));
  };

  const onTouchUp = () => {
    setState((prevState) => ({
      ...prevState,
      engineIsOn: false
    }));
  };


  const [axisX] = useImage(axisXImg);

  const [shipImage] = useImage(shipImg);
  const [stationImage] = useImage(stationImg);
  const [brokenStationImage] = useImage(brokenStationImg);
  const [brokenShipImage] = useImage(brokenShipImg);

  const [boxImage] = useImage(boxImg);
  const [engineImage] = useImage(engineImg);
  const [miniShipImage] = useImage(shipMiniImg);
  const [miniStationImage] = useImage(stationMiniImg);
  const [asteroidImage] = useImage(asteroidImg);
  const inertiaLsn = ['inertia1', 'inertia2', 'inertia3'].includes(code);

  const CanvasSpace = () => {
    const {
      shipPos,
      stationPos,
      endShipPos,
      shipCenter,
      stationCenter,
      boxPos,
      docking
    } = state;

    const isEngine = !state.boxCount && state.boxCount !== 0;
    const isBroken = false;

    const stroke = 'rgba(255,255,255,1)';
    const layerRef = useRef(null);

    const shipSpeedX = Number(state.shipSpeed.x).toFixed(2);
    const shipSpeedY = Number(state.shipSpeed.y).toFixed(2);

    const isAsteroid = state.asteroidPos !== undefined && state.asteroidPos != null;
    const asteroidRotation = Number((((isAsteroid ? Math.atan2(state.asteroidSpeed.y, state.asteroidSpeed.x) : 0)
                                    / Math.PI) * 180 + 180) % 360);

    let isDescriptionHide = !Boolean(withDescr);
    const textSttng = {
      fontSize: 16,
      stroke: mainColor,
      strokeWidth: .8,
    }
    let textTitleData = {
      stroke: 'white', fill: 'white',
      strokeWidth: .1,
      fontSize: 28,
      x: 50, y: 30,
      lineHeight: 1.3
    };
    if (code === 'inertia1'){
      textTitleData = {
        ...textTitleData,
        text: '' +
          'Определи скорость космического корабля, которая обеспечит ' +
          '\nсовместную скорость  1 м/с после стыковки.',
      }
    }
    if (code === 'inertia2'){
      textTitleData = {
        ...textTitleData,
        text: '' +
          'Станция движется в противоположном кораблю направлении.' +
          '\nОпредели скорость космического корабля, которая обеспечит ' +
          '\nсовместную остановку после стыковки.',
      }
    }
    if (code === 'inertia3'){
      textTitleData = {
        ...textTitleData,
        text: '' +
          'Определи скорость космического корабля, которая ' +
          '\nобеспечит совместную скорость 2 м/с после стыковки',
      }
    }
    return (
      <Layer ref={containerRef}>
        {
          !inertiaLsn && (
            <CanvasGrid
              stroke={'rgba(255,255,255, 0.4)'}
              centerX={150}
              centerY={20 * 17.5}
              step={5 * pxPerMetre} // grid ratio is 1 km
              scaleLegend={_t("space.scale_legend")}
            />
          )
        }

        {
          Boolean(state.boxCount) && !!boxPos && (
              <Group {...shipPos}>
                {
                  Array(state.boxCount).fill().map((el, i) => {
                    return (
                        <Group x={boxPos.x} y={boxPos.y + (-1 * i%2 + 0.5) * i * 3} key={`box-${i}`}>
                          <CanvasBox
                              boxImage={boxImage}
                          />
                        </Group>
                    )
                  })
                }
              </Group>
          )
        }

        {/* ------------- ENGINE ------------- */}
        {
          (state.running && !state.docking && isEngine && !state.stopEngine.x) && (
              <Group {...shipPos}>
                <Group x={shipCenter.x-90} y={shipCenter.y}>
                  <CanvasEngine
                      engineImage={engineImage}
                      width={80 / 17.5}
                      height={20 / 17.5}
                  />
                </Group>
              </Group>
          )
        }{
        (state.running && !state.docking && isEngine && !state.stopEngine.y) && (
            <Group {...shipPos}>
              <Group x={shipCenter.x} y={shipCenter.y-45}>
                <CanvasEngine
                    engineImage={engineImage}
                    rotation={90}
                    width={40 / 17.5}
                    height={15 / 17.5}
                />
              </Group>
            </Group>
        )
      }

      {/* ------------- SHIPS ------------- */}
      {
        (shipPos && shipCenter) && (
            <Group {...shipPos}>
              <Group {...shipCenter}>
                <CanvasSpaceShip
                  height={inertiaLsn ? 130/17.5 : undefined}
                  width={inertiaLsn ? 70/17.5 : undefined}
                  shipImage={shipImage}
                  rotation={90}
                />
                {
                  inertiaLsn && (
                    <Card x={-130} y={50} height={90} width={190}>
                      <Text text={'Масса(кг): '} x={10} y={20} {...textSttng}/>
                      <Text text={state.shipMass*1000 || 0} x={100} y={20} {...textSttng}/>
                      <Text text={'Скорость(м/с):'} x={10} y={50} {...textSttng}/>
                        <CanvasInput
                          id={'1'}
                          y={45}
                          x={130}
                          width={50}
                          height={25}
                          fontSize={17}
                          fontStyle={'bold'}
                          stage={stageRef?.current}
                          textColor={mainColor}
                          rectStyles={state.running ? {stroke: 'transparent'} : undefined}
                          onInput={(val) => {
                            const valueObj = {
                              target: { value: val, name: 'setShipSpeed'}
                            }
                            onChangeInput(valueObj);
                          }}
                          inputHide={state.running}
                          value={state.docking ? state.stationSpeed.x.toFixed(2) : state.setShipSpeed}
                          visible={!state.running}
                        />
                        {
                          state.running && (
                            <Text text={
                              Math.abs(state.docking ? state.stationSpeed.x.toFixed(2) : state.setShipSpeed) || 0
                            } x={130} y={50} {...textSttng}/>
                          )
                        }
                    </Card>
                  )
                }
              </Group>
            </Group>
        )
      }{
        (stationPos && stationCenter) && (
            <Group {...stationPos}>
              <Group {...stationCenter}>
                <CanvasSpaceStation
                  height={inertiaLsn ? 150/17.5 : undefined}
                  width={inertiaLsn ? 150/17.5 : undefined}
                  stationImage={stationImage}
                />
                {
                  inertiaLsn && (
                    <>
                      <Card x={-60} y={80} height={90} width={190}>
                        <Text text={'Масса(кг): '} x={10} y={20} {...textSttng}/>
                        <Text text={state.stationMass*1000 || 0} x={100} y={20} {...textSttng}/>
                        <Text text={'Скорость(м/с):'} x={10} y={50} {...textSttng}/>
                        <Text text={
                          Math.abs(Number(state.stationSpeed.x.toFixed(2))) || 0
                        } x={130} y={50} {...textSttng}/>
                      </Card>
                    </>
                  )
                }
              </Group>
            </Group>
        )
      }

      {/* ------------- ASTEROID ------------- */}
      {
        (isAsteroid && !inertiaLsn) && (
            <Group {...state.asteroidPos}>
              <CanvasAsteroid
                asteroidImage={asteroidImage}
                rotation={asteroidRotation}
                scale={state.asteroidScale}
              />
            </Group>
        )
      }

      {/* ------------- DESCRIPTION ------------- */}
      {
        !inertiaLsn && (
          <>
            <TopLsnInfo
              state={state}
              shipSpeedX={shipSpeedX}
              shipSpeedY={shipSpeedY}
              miniShipImage={miniShipImage}
              miniStationImage={miniStationImage}
              stroke={stroke}
            />
            {
              !isDescriptionHide && (
                <ObjDescriptionsContainer
                  state={state}
                  docking={docking}
                  shipPos={shipPos}
                  isBroken={isBroken}
                  shipCenter={shipCenter}
                  stationCenter={stationCenter}
                  taskCode={code}
                />
              )
            }
          </>
        )
      }
      {
        inertiaLsn && (
          <>
            <Text {...textTitleData} />
            {
              !state.running && (
                <CanvasButton
                  text={'Пуск'}
                  onClick={() => startOnCLick()}
                  fontSize={20}
                  strokeWidth={.2}
                  btnCornerRadius={0}
                  btnFill={'#395577'}
                  height={40}
                  x={220}
                  y={400}
                />
              )
            }
            <ObjDescriptionsContainer
              state={state}
              docking={docking}
              shipPos={shipPos}
              isBroken={isBroken}
              shipCenter={shipCenter}
              stationCenter={stationCenter}
              taskCode={code}
              onlyArrow={true}
            />
          </>
        )
      }
      </Layer>
    )
  };


  let spaceColor = '#000540';
  if (inertiaLsn) {
    spaceColor = '#2a3e57';
  }

  const styles = {
    mainContainer: {
      height: '100%',
      overflow: 'hidden',
      background: spaceColor,
    },
  };

  return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={stageRef}>
          <CanvasSpace />
        </CanvasContainer>

        {
          !inertiaLsn ? (
            <>
              <FieldBlock
                {...fieldSettings}

                secondBlock={otherFieldSettings}
                thirdBlock={moreFieldSettings}

                running={state.running}
                resetOnCLick={resetOnCLick}
                startOnCLick={startOnCLick}
                onChangeInput={onChangeInput}
              />

              <EngineButton
                running={state.running}
                label={code}
                touchDown={onTouchDown}
                touchUp={onTouchUp}
              />)
              {showRecord && <Input
                style={{
                  position: 'absolute',
                  bottom: 50,
                  right: 450,
                  zIndex: 10,
                  width: 300
                }}
                type='range'
                min={0}
                max={snapshots.length-5}
                step={1}
                onChange={(event) => {
                  const val = parseInt(event.target.value);
                  setSelectedFrame(val);
                  setState(snapshots[val].snapshot);
                }}
                value={selectedFrame}
              />}
            </>
          ) : (
           <button
             onClick={() => resetOnCLick()}
             style={{
               background: layout2.orange, color: 'white',
               position: 'absolute', right: '10%', padding: '0 15px',
               fontWeight: 'bold', top: '90%', border: '0',
               display: 'flex'
             }}
           >
             <img src="" alt=""/>
             Заново</button>
          )
        }

      </div>
  )
};

const mapStateToProps = (state) => {
  const activeLesson = state.lessonsReducer.activeLesson;
  return ({
    activeLessonId: activeLesson?.id,
  });
}

const mapDispatchToProps = (dispatch) => ({
  updateLessonStatus: (status, lessonId, method='status') => dispatch(actions.updateLessonStatus(status, lessonId, method)),
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  updateActiveLesson: (data) => dispatch(actions.updateActiveLesson(data)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Spaces);
