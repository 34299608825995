import Header from "./Header";
import React, {useEffect, useState} from "react";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {DescriptionWrapper, MenuWrapper} from "./Wrappers";
import useMedia from "../../../../utils/custom-hooks/useMedia";
import {descriptionUrl} from "../../../../utils/api";
import {checkDescriptionUrl} from "../../../../utils/common";
import HtmlContentRender from "../../../components/HtmlContentRender/HtmlContentRender";
import HeaderBanner from "./HeaderBanner";
import {Spin} from "antd";


const MainContainer = (props) => {
  const {
    user,
    pageKey,
    children,
    menuChildren,
    logoutAction,
    withoutMenuMargin=false,
    menuOnlyMobile=false,
    sidebarMenuVisible,
    containerClassname,
    notificationsCount,
    setSidebarMenuVisible,
    setFeedbackPopupVisible,

    withDescription=false,

    selectedLesson,
    setCatalogLesson
  } = props;


  /* Управление описанием */
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  useEffect(() => {
    if (selectedLesson?.code) {
      const description_html = selectedLesson?.description_html;
      checkDescriptionUrl(selectedLesson, setDescriptionVisible, Boolean(description_html));
    } else {
      setDescriptionVisible(false);
    }
  }, [selectedLesson?.code])

  /* Управление описанием */
  const isMobileView = useMedia(['(max-width: 800px)'],[true], false);
  const isXLView = useMedia(['(max-width: 1200px)'],[true], false);
  useEffect(() => {
    setSidebarMenuVisible(!menuOnlyMobile && !isXLView);
  }, [isXLView]);

  /* Скрывает меню и отступ в режиме десктопа, если установлен флаг "withoutMenuMargin"
     (в мобильном режиме меню присутствует) */
  const menuWrapper = !withoutMenuMargin || (withoutMenuMargin && isXLView);

  return (
    <div className={'mainContentWrapper '+ containerClassname}>
      {
        (props.headerBannerVisible) && (
          <HeaderBanner isMobile={isMobileView}/>
        )
      }

      <Header
        user={user}
        pageKey={pageKey}
        mobileMode={isXLView}
        menuChildrenExist={Boolean(menuChildren)}
        isDarkMode={props.isDarkMode}
        logoutAction={logoutAction}
        descriptionVisible={descriptionVisible}
        notificationsCount={notificationsCount}
        sidebarMenuVisible={sidebarMenuVisible}
        setSidebarMenuVisible={setSidebarMenuVisible}
        setFeedbackPopupVisible={setFeedbackPopupVisible}
      />
      <div className={'contentWrapper'}>
        {
          menuWrapper ? (
            <MenuWrapper
              user={user}
              pageKey={pageKey}
              mobileMode={isXLView}
              backdropVisible={sidebarMenuVisible && isXLView}
              sidebarMenuVisible={sidebarMenuVisible}
              headerBannerVisible={props.headerBannerVisible}
              setSidebarMenuVisible={setSidebarMenuVisible}
            >
              {menuChildren}
            </MenuWrapper>
          ) : null
        }
        {children}
      </div>
      {
        withDescription ? (
          <DescriptionWrapper
            headerBannerVisible={props.headerBannerVisible}
            setSelectedLesson={setCatalogLesson}
            visible={selectedLesson?.code && descriptionVisible}
            code={selectedLesson?.code}
          >
            {
              selectedLesson?.description_html ? (
                <div style={{marginTop: '25px', padding: '25px'}}>
                  <HtmlContentRender htmlForRender={selectedLesson.description_html} />
                </div>
              ) : (
                <>
                  {(selectedLesson?.code && descriptionVisible)
                    ?
                      <iframe
                        id={'description-frame'}
                        style={{border: 0, height: '100%', width: '100%'}}
                        src={descriptionUrl(selectedLesson?.code)}
                      />
                    : null}
                </>
              )
            }
          </DescriptionWrapper>
        ) : null
      }
    </div>
  );
}



const mapStateToProps = (state, {match}) => {
  let notificationsCount = state.lessonsReducer.homeworkNotifications.length;
  return {
    headerBannerVisible: state.commonReducer?.headerBanners?.visible,

    user: state.lessonsReducer.user,
    lessons: state.lessonsReducer.lessons,
    chapters: state.lessonsReducer.chapters,
    lessonsGroups: state.lessonsReducer.lessonsGroups,
    notificationsCount: notificationsCount,
    sidebarMenuVisible: state.commonReducer.sidebarMenuVisible,
    isDarkMode: state.commonReducer.isDarkMode,

    selectedLesson: state.catalogReducer.selectedLesson
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: () => dispatch(actions.checkLogin()),
    setLanguage: (lang) => dispatch(actions.setLanguage(lang)),
    logoutAction: () => dispatch(actions.logoutAction()),
    fetchHomework: () => dispatch(actions.fetchHomework(true)),
    setSidebarMenuVisible: (visible) => dispatch(actions.setSidebarMenuVisible(visible)),
    setFeedbackPopupVisible: (visible) => dispatch(actions.setFeedbackPopupVisible(visible)),

    setCatalogLesson: (lesson) => dispatch(actions.setCatalogLesson(lesson)),
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainContainer);

