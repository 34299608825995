import { Image } from "react-konva";
import React from "react";
import {pxPerMetre} from "../settings";

export const MiniSpaceStation = ({stationImage, rotation}) => {
  const width = 35 / 17.5; // In metres
  const height = 35 / 17.5;

  return(
      <Image
          image={stationImage}
          width={width * pxPerMetre}
          height={height * pxPerMetre}
          offsetX={width * pxPerMetre/2}
          offsetY={height * pxPerMetre/2}
          rotation={rotation}
      />
  )
};
