import React from "react";
import cloneDeep from "lodash.clonedeep";
import {degToRad} from "../../../../utils/common";
import {CanvasContainer} from "../../../canvas";
import {Circle, Group, Image, Layer, Line, Rect, Text} from "react-konva";
import useImage from "use-image";
import backgroundImg from "../../../../images/brickFactory/background.png";
import borderVerticalImg from "../../../../images/brickFactory/borderVertical.png";
import borderHorizontalImg from "../../../../images/brickFactory/borderHorizontal.png";
import brick1Img from "../../../../images/brickFactory/brick1.png";
import brick2Img from "../../../../images/brickFactory/brick2.png";
import kilnImg from "../../../../images/brickFactory/kiln.png";
import dispenserImg from "../../../../images/brickFactory/dispenser.png";
import controll3Img from "../../../../images/brickFactory/controll3.png";




class BrickFactory extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();


    // text refs
    this.powerText1Ref = React.createRef();
    this.powerText2Ref = React.createRef();
    this.powerText3Ref = React.createRef();

    this.linePerformanceTextRef = React.createRef();
    this.summPowerTextRef = React.createRef();

    this.maxLinePerformanceTextRef = React.createRef();
    this.maxSummPowerTextRef = React.createRef();


    this.requestId = null;

    this.staticData = {
      waterMaxHeight: 120,
      waterContainerMaxHeight: 120
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidMount() {
    this.requestId = window.requestAnimationFrame(this.move);
  }

  componentWillUnmount() {
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;




    this.updateStage();
  };

  updateStage = () => {
    const data = this.data;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;




    stageNode.draw();
  };


  CanvasFactory = () => {

    const [borderVertical] = useImage(borderVerticalImg);
    const [borderHorizontal] = useImage(borderHorizontalImg);
    const [brick1] = useImage(brick1Img);
    const [brick2] = useImage(brick2Img);
    const [kiln] = useImage(kilnImg);
    const [dispenser] = useImage(dispenserImg);
    const [controll3] = useImage(controll3Img);

    const [background] = useImage(backgroundImg);

    const textSettings = {
      align: 'center', verticalAlign: 'middle', stroke: 'white', fill: 'white', strokeWidth: .3, fontSize: 18,
    };
    return (
      <Group>
        <Image width={1000} height={700} image={background} preventDefault={false}/>



        <Image x={510} y={430} image={brick2} preventDefault={false}/>

        <Image x={270} y={430} image={brick1} preventDefault={false}/>

        <Image x={420} y={348} image={kiln} preventDefault={false}/>
        <Image x={240} y={250} image={dispenser} preventDefault={false}/>


        <Group x={550}>
          <Image image={borderVertical} preventDefault={false}/>
          <Image x={60} image={borderVertical} preventDefault={false}/>
        </Group>


        <Group x={551}>
          {
            Array(300).fill(0).map((el, i) => {
              return (
                <Image y={i*50} image={borderHorizontal} preventDefault={false}/>
              )
            })
          }
        </Group>


        <Image y={620} x={590} image={controll3} preventDefault={false}/>


        <Group y={418} x={48}>
          <Rect width={90} height={40} fill={'white'} cornerRadius={5}/>
          <Text {...textSettings} text={'50 кВт'} stroke={'black'} fill={'black'} width={90} height={40} ref={this.powerText1Ref}/>
        </Group>
        <Group y={490} x={300}>
          <Rect width={90} height={40} fill={'white'} cornerRadius={5}/>
          <Text {...textSettings} text={'50 кВт'} stroke={'black'} fill={'black'} width={90} height={40} ref={this.powerText2Ref}/>
        </Group>
        <Group y={620} x={693}>
          <Rect width={90} height={40} fill={'white'} cornerRadius={5}/>
          <Text {...textSettings} text={'50 кВт'} stroke={'black'} fill={'black'} width={90} height={40} ref={this.powerText3Ref}/>
        </Group>


        <Group y={100} x={740}>
          <Group >
            <Text {...textSettings} text={'20'}  stroke={'#3D88D0'} fill={'#3D88D0'} fontSize={25} width={90} height={40} ref={this.linePerformanceTextRef}/>
          </Group>
          <Group x={110}>
            <Text {...textSettings} text={'20'} stroke={'black'} fill={'black'} fontSize={25} width={90} height={40} ref={this.maxLinePerformanceTextRef}/>
          </Group>
        </Group>

        <Group y={215} x={740}>
          <Group >
            <Text {...textSettings} text={'20'}  stroke={'#3D88D0'} fill={'#3D88D0'} fontSize={25} width={90} height={40} ref={this.summPowerTextRef}/>
          </Group>
          <Group x={110}>
            <Text {...textSettings} text={'20'} stroke={'black'} fill={'black'} fontSize={25} width={90} height={40} ref={this.maxSummPowerTextRef}/>
          </Group>
        </Group>
      </Group>
    )
  };

  CanvasButtons = () => {

    return (
      <Group>

      </Group>
    )
  };


  render() {
    return (
      <div>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasFactory/>
            <this.CanvasButtons/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}

export default BrickFactory;

const styles = {};