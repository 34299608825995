import SectionWrapper from "./SectionWrapper";
import React, {useEffect, useState} from "react";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import '../styles/achievements.css';
import {getImageUrlByName} from "../../../../utils/common";
import achievementExample from '../../../../platformImages/layout2/achievement_example.png';


const Achievements = (props) => {
  const [loading, setLoading] = useState(false);
  const [achievementsRecords, setAchievements] = useState([]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      const resp = await props.fetchUserAchievements();
      if (resp?.results?.length) {
        setAchievements(resp.results);
      }
      setLoading(false);
    })()
  }, [])

  return (
    <SectionWrapper
      loading={loading}
      title={'Ачивки'}
      contentClassName={'main-page-achievements'}
    >
      {
        achievementsRecords.length ? (
          <>
            {
              achievementsRecords.map(record => {
                const img = getImageUrlByName(record?.achievement?.image);
                return (
                  <div className={'main-page-achievements__item achievement-item'} key={'achievement-'+record.id}>
                    <img src={img} className={'achievement-item__image'}/>
                  </div>
                )
              })
            }
          </>
        ) : (
          <div className={'main-page-achievements__item achievement-item'} key={'achievement-0'}>
            <img src={achievementExample} className={'achievement-item__image'}/>
          </div>
        )
      }
    </SectionWrapper>
  )
}



const mapStateToProps = state => ({});
const mapDispatchToProps = (dispatch, {match}) => ({
  fetchUserAchievements: () => dispatch(actions.fetchUserAchievements()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Achievements);
