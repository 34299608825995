import React, {useRef, useState} from "react";
import {serverMediaUrl} from "../../../../utils/api";
import {Button} from "antd";
import {DownloadOutlined, CloseOutlined, InfoCircleOutlined} from '@ant-design/icons';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";


const TaskAdditionalSettings = (props) => {
  const {
    lessonData
  } = props;

  const containerRef = useRef(null);
  const [visible, setVisibility] = useState(false);


  let imageUrl =  lessonData?.thumbnail_small
    ? `${serverMediaUrl}${lessonData?.thumbnail_small}`
    : lessonData?.thumbnail ? `${serverMediaUrl}${lessonData?.thumbnail}`
      : 'https://yt3.ggpht.com/ytc/AAUvwnhBOh1T3mUPIuFw9aKBhNAo7YbJp947xxOvNbD1=s88-c-k-c0x00ffffff-no-rj';


  function clickOutsidePageIcon(e){
    if (!containerRef?.current.contains(e.target)) {
      close();
    }
  }
  const open = () => {
    setVisibility(true);
    window.addEventListener('click', clickOutsidePageIcon);
  }
  const close = () => {
    setVisibility(false);
    window.removeEventListener('click', clickOutsidePageIcon);
  }

  return (
    <>
      <div
        ref={containerRef}
        style={{
          position: 'absolute',
          right: 0, bottom: visible ? 0 : '-50px',
          zIndex: 1000,
          padding: visible ? '0' : '5px',
          borderRadius: visible ? '5px 5px 0 0' : '0 0 5px 5px',
        }}
      >
        {
          visible && (
            <div style={{backgroundColor: 'white', padding: '25px 20px'}}>
              <h4>Перетащите иконку на рабочий стол, <br/>чтобы не потерять задачу </h4>
              <div style={{
                margin: '20px 0 10px',
              }}>
                <Button
                  style={{position: 'absolute', right: 0, top: 0, width: '100%'}}
                  type="primary"
                  size={'small'}
                  icon={<CloseOutlined />}
                  onClick={close}
                />
                <a href={window.location}
                   style={{
                     display:' flex',
                     flexDirection:' column',
                     alignItems:' center',
                     justifyContent:' center',
                     maxWidth: '300px',
                     margin: '0 auto',
                     padding: '10px',
                     borderRadius: '5px',
                     boxShadow: '0px 1px 6px -2px rgb(0 0 0 / 50%)'
                   }}
                >
                  <div id="icon" style={{
                    backgroundImage:`url(${imageUrl})`,
                    width:'52px', height:'52px',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    margin: '5px 0 7px',
                    borderRadius: '7px'
                  }}/>
                  <div id="title"
                       style={{
                         color: 'black',
                         fontWeight: 'bold'
                       }}
                  >{lessonData?.title}</div>
                </a>
              </div>
            </div>
          )
        }

        <Button
          className={'text-decoration'}
          target={'blank'}
          type="ghost"
          onClick={open}
          shape="round"
          style={{fontSize: '15px', border: '0', boxShadow: 'none'}}
          icon={<DownloadOutlined />}
          hidden={visible}
        >
          Сохранить задачу
        </Button>
      </div>



      <div
        style={{
          position: 'absolute',
          left: 0, bottom: '-50px',
          zIndex: 1000,
          padding: '5px',
          borderRadius: '0 0 5px 5px',
        }}
      >
        <Button
          className={'text-decoration'}
          target={'blank'}
          type="ghost"
          style={{fontSize: '15px', border: '0', boxShadow: 'none'}}
          shape="round"
          icon={<InfoCircleOutlined />}
          onClick={() => props.setFeedbackPopupVisible(true)}
        >
          Нашли ошибку? Напишите нам
        </Button>
      </div>
    </>
  )
}


const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, {match}) => ({
  setFeedbackPopupVisible: (visible) => dispatch(actions.setFeedbackPopupVisible(visible))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAdditionalSettings);
