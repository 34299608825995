import React, {createRef, useEffect, useState} from "react";
import rocketGif from '../../../../src/platformImages/layout2/rocket_with_fire.gif';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {getImageUrlByName} from "../../../utils/common";
import {CloseOutlined} from "@ant-design/icons";
import '../styles/recomendationOfCourses.css';
import useOutsideClick from "../../../utils/custom-hooks/useOutsideClick";
import {ymClickCloseRecommendation, ymClickCourseBanner, ymClickOpenRecommendation} from "../../../metriks/ym_metriks";


const RecommendationOfCourses = (props) => {
  const [visible, setVisible] = useState(false);
  const [courses, setCourses] = useState(false);


  useEffect(() => {
    (async function () {
      const resp = await props.fetchSkills();
      if (resp?.results) {
        setCourses(resp.results);
      }
    })()
  }, [])

  const clickCloseRecommendationsBlock = () => {
    setVisible(false);
    ymClickCloseRecommendation();
  }

  const clickOpenRecommendationsBlock = () => {
    setVisible(true);
    ymClickOpenRecommendation();
  }

  const recommendationBlockRef = createRef(null);
  useOutsideClick(recommendationBlockRef, () => { visible && clickCloseRecommendationsBlock();});

  return (
    <>
      {
        courses.length ? (
          <div className={'recommendation-block'}>
            {
              !visible && (
                <div className={'recommendation-block__btn'} onClick={clickOpenRecommendationsBlock}>
                  <img src={rocketGif}/>
                </div>
              )
            }

            <div
              ref={recommendationBlockRef}
              className={`recommendation-block__content-wrapper ${visible ? 'recommendation-block__content-wrapper--visible' : ''}`}
            >
              <CloseOutlined className={'recommendation-block__close-icon'} onClick={clickCloseRecommendationsBlock}/>

              <div className={`recommendation-block__content-helper ${visible ? 'recommendation-block__content-helper--visible' : ''}`}>
                {
                  courses.map(course => {
                    const img = getImageUrlByName(course.image);
                    return (
                      <a
                        key={'course-'+course.id}
                        href={course.link}
                        target={'_blank'}
                        className={'recommendation-block__course'}
                        onClick={() => ymClickCourseBanner(course.link)}
                      >
                        <img src={img}/>
                      </a>
                    )
                  })
                }
              </div>
            </div>
          </div>
        ) : null
      }
    </>
  )
}


const mapStateToProps = state => ({});
const mapDispatchToProps = (dispatch, {match}) => ({
  fetchSkills: () => dispatch(actions.fetchSkills()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecommendationOfCourses);
