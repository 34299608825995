import React, {useEffect, useState} from "react";
import '../styles/clock.css';
import moment from "moment";


const MapTimer = (props) => {
  const {
    complete = false,
    initialTimeStr = '00:00',
    format = 'mm:ss',
    defaultHours = 0,
    defaultMinutes = 0,
    defaultSeconds = 60,
    completeCallback = () => {}
  } = props;

  const [startTimeStr, setStartTimeStr] = useState('');
  const [timerStr, setTimerStr] = useState(initialTimeStr);

  const humanizeTimeValue = (timeValue, durationSeconds) => {
    const absTimeValue = Math.abs(timeValue);
    let strTimeValue = absTimeValue < 10 ? `0${absTimeValue}` : absTimeValue;

    const completeSeconds = defaultHours * 60**2 + defaultMinutes * 60;
    if (completeSeconds <= durationSeconds) {
      strTimeValue = '00';
    }
    return strTimeValue
  }

  const setCorrectTime = (intervalId) => {
    const now = moment.utc();
    const momentStartTime = moment.utc(props.startTimeStr);
    const duration = moment.duration(now.diff(momentStartTime));
    const durationHours = duration.asHours() << 0;
    const durationMinutes = duration.asMinutes() << 0;
    const durationSeconds = duration.asSeconds() << 0;

    const newHours = (defaultHours - 1) - (durationHours % 60);
    const newMinutes = (defaultMinutes - 1) - (durationMinutes % 60);
    const newSeconds = (defaultSeconds - 1) - (durationSeconds % 60);

    const newStrHours = humanizeTimeValue(newHours, durationSeconds);
    const newStrMinutes = humanizeTimeValue(newMinutes, durationSeconds);
    const newStrSeconds = humanizeTimeValue(newSeconds, durationSeconds);


    const completeSeconds = defaultHours * 60**2 + defaultMinutes * 60;
    if (completeSeconds <= durationSeconds) {
      clearInterval(intervalId);
      completeCallback();
    }

    const newTimerStr = format
      .replace('HH', newStrHours)
      .replace('mm', newStrMinutes)
      .replace('ss', newStrSeconds)

    setTimerStr(newTimerStr);
  }

  useEffect(() => {
    if (complete) {
      setTimerStr(`00:00`);
      return
    }
    if (props.startTimeStr && props.startTimeStr !== startTimeStr) {
      setStartTimeStr(props.startTimeStr);

      // set initial time
      setCorrectTime();

      const timeInterval = setInterval(() => {
        setCorrectTime(timeInterval);
      }, 1000);
      return () => {
        clearInterval(timeInterval);
      }
    }
  }, [props.startTimeStr])

  return timerStr;
}

export default MapTimer;
