import { combineReducers } from 'redux';
import { storeLessonsReducer } from './reducers/lessonReducer';
import { catalogReducer } from './reducers/catalogReducer';
import { commonReducer } from './reducers/commonReducer';
import { authReducer } from './reducers/authReducer';
import {mapReducer} from "./reducers/MapReducer";

const reducer = combineReducers({
  mapReducer: mapReducer,
  lessonsReducer: storeLessonsReducer,
  catalogReducer: catalogReducer,
  commonReducer: commonReducer,
  authReducer: authReducer,
});

export default  reducer;
