export const UPDATE_NAME = 'UPDATE_NAME';
export const updateName = (value) => {
  return {
    type: UPDATE_NAME,
    payload: value,
  }
};
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME';
export const updateLastName = (value) => {
  return {
    type: UPDATE_LAST_NAME,
    payload: value,
  }
};
export const UPDATE_CLASS_OF_STUDENT = 'UPDATE_CLASS_OF_STUDENT';
export const updateClassOfStudent = (value) => {
  return {
    type: UPDATE_CLASS_OF_STUDENT,
    payload: value,
  }
};
export const UPDATE_IS_TEACHER = 'UPDATE_IS_TEACHER';
export const updateIsTeacher = (value) => {
  return {
    type: UPDATE_IS_TEACHER,
    payload: value,
  }
};
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const updateEmail = (value) => {
  return {
    type: UPDATE_EMAIL,
    payload: value,
  }
};
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const updatePassword = (value) => {
  return {
    type: UPDATE_PASSWORD,
    payload: value,
  }
};
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const updatePhone = (value) => {
  return {
    type: UPDATE_PHONE,
    payload: value,
  }
};
export const UPDATE_REPEAT_PASSWORD = 'UPDATE_REPEAT_PASSWORD';
export const updateRepeatPassword = (value) => {
  return {
    type: UPDATE_REPEAT_PASSWORD,
    payload: value,
  }
};
export const RESET_AUTH_REDUCER = 'RESET_AUTH_REDUCER';
export const resetAuthReducer = () => {
  return {
    type: RESET_AUTH_REDUCER,
    payload: null,
  }
};
