import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import Victor from 'victor';
import Boiling from "../components/Boiling";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {GIF} from "../../../canvas/components/GIF";
import {degToRad, sendSuccessForScenario} from '../../../../utils/common';


import flaskBackground from '../../../../images/steamEngine/flask1-background.png';
import flaskBackground2 from '../../../../images/steamEngine/flask2-background.png';

import corkImage from '../../../../images/steamEngine/cork.png';
import pistonImage from '../../../../images/steamEngine/piston-task-2.png';
import fireGIF from '../../../../images/fireSmall.gif'
import ScenarioManager from "../../../../utils/ScenarioManager";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const initialState = {
  burning: false,
  boiling: false,
  corkFlew: false,
  pushingPistol: false,
};

class SteamFlask extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.pressureRef = React.createRef();
    this.temperatureRef = React.createRef();
    this.steamRef = React.createRef();
    this.corkRef = React.createRef();
    this.pistonRef = React.createRef();
    this.steamPistonRef = React.createRef();
    this.tempTextRef = React.createRef();
    this.pressureTextRef = React.createRef();
    this.state = {
      ...initialState,
      withPiston: props.code === 'steam2'
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      waterTemperature: 20,
      gasAmount: 0,
      flaskVolume: 10,
      gasAmountPerSecond: this.state.withPiston ? 2 : 1,
      corkCoords: Victor(16.5, 0),
      corkAngle: 0,
      corkSpeedVector: Victor(-100 + Math.random()*220, -130 + -Math.random()*300),
      corkRotationSpeed: 50 + Math.random()*280,

      pistonOffset: 30,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.code !== prevProps.code)
      this.setState((prev) => {
        return {...prev, withPiston: this.props.code === 'steam2', ...initialState}
      }, () => this.data=cloneDeep(this.initialData));
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  get canBoil () {return this.data.waterTemperature >= 100}

  get pressure () {
    const volume = this.state.withPiston
      ? (this.data.flaskVolume + 15 + (this.data.pistonOffset - this.initialData.pistonOffset)*0.75)
      : this.data.flaskVolume;
      return (this.data.gasAmount/volume);
  }

  get gasEasing () {
    return this.data.pistonOffset > 218 && (this.state.burning && this.pressure > 0.20 || !this.state.burning)
  }

  get pistonBlocked () {
    return this.data.pistonOffset > 225
  }

  get tempText () {
    return `Температура ${ parseInt(this.data.waterTemperature)}`;
  }

  get pressureText () {
    return `Давление ${parseFloat(this.pressure).toFixed(2)}`;
  }

  get tempPoints () {
    return [0, 0, -20, 5];
  }

  get pressurePoints () {
    return [0, 0, -20, 5];
  }

  get termometerAngle () {
    const t = this.data.waterTemperature;
    if (t < 0) return 0;
    if ( t <= 120 && t >= 0) {
    return 2.05 * t
    }
    if (t>120) return 100
  };

  get pressureAngle () {
    const p = this.pressure;
    if (0 < 0) return 0;
    if ( p <= 1 && p >= 0) {
    return 200 * p
    }
    if (p > 1) return 200
  };

  move = (time) => {
    const {burning, corkFlew, boiling, pushingPistol} = this.state;
    let data = this.data;
    let {corkCoords, corkSpeedVector, corkRotationSpeed, pistonOffset} = data;

    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);


    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    if (this.pressure > 0.85) {
      this.setState({corkFlew: true})
    }

    if (this.pressure > 0.5 && !this.gasEasing && !this.pistonBlocked) {
      data.pistonOffset += this.pressure * 20 * timedeltaSec;
    }

    if (pushingPistol && pistonOffset > this.initialData.pistonOffset) {
      data.pistonOffset -= 50 * timedeltaSec;
    }

    if (this.gasEasing) {
      data.gasAmount -= timedeltaSec*data.gasAmountPerSecond * 1.5;
      if (data.gasAmount < 0) data.gasAmount = 0;
    }

    if (burning && !this.canBoil) {
      data.waterTemperature += 30 * timedeltaSec; // degrees per second
    }

    if (this.canBoil && !boiling) {
      this.setState({boiling: true});
    }

    if (!this.canBoil && boiling) {
      this.setState({boiling: false});
    }

    if (!burning && data.waterTemperature > this.initialData.waterTemperature) {
      data.waterTemperature -= (data.waterTemperature - this.initialData.waterTemperature) * 0.05 * timedeltaSec; // остывает
    }

    data.gasAmount -= (data.gasAmount) * 0.01 * timedeltaSec; // чуть травит

    if (burning && !corkFlew && boiling) {
      data.gasAmount += timedeltaSec*data.gasAmountPerSecond;
    }

    if (corkFlew) {
      data.gasAmount = 0;
      corkCoords = corkCoords
        .add(Victor(corkSpeedVector.x, corkSpeedVector.y).multiply(Victor(timedeltaSec, timedeltaSec)));
      corkSpeedVector = corkSpeedVector
        .add(Victor(0, 300).multiply(Victor(timedeltaSec, timedeltaSec))); // gravity acceleration
      data.corkAngle += data.corkRotationSpeed * timedeltaSec;
    }
    this.updateStage();
  };

  updateStage() {
    const {waterTemperature, corkCoords, corkAngle, pistonOffset} = this.data;
    let pressureNode = this.pressureRef?.current;
    let temperatureNode = this.temperatureRef?.current;
    let steamNode = this.steamRef?.current;
    let corkNode = this.corkRef?.current;
    let steamPistonNode = this.steamPistonRef?.current;
    let pistonNode = this.pistonRef?.current;
    // let tempTextNode = this.tempTextRef?.current;
    // let pressureTextNode = this.pressureTextRef?.current;
    if (!steamNode || !corkNode || !temperatureNode || !pressureNode) return;
    if (this.state.withPiston && (!steamPistonNode || !pistonNode)) return;

    let pressure = this.pressure;
    steamNode.opacity(pressure);

    corkNode.x(corkCoords.x);
    corkNode.y(corkCoords.y);
    corkNode.rotation(corkAngle);

    temperatureNode.rotation(this.termometerAngle);
    pressureNode.rotation(this.pressureAngle);

    // tempTextNode.text(this.tempText);
    // pressureTextNode.text(this.pressureText);

    if (this.state.withPiston) {
      steamPistonNode.opacity(pressure);
      steamPistonNode.width(pistonOffset);
      pistonNode.x(pistonOffset);
    }

    // console.log('debug on draw layer');
    const layerToDraw = steamNode.getLayer();
    layerToDraw.draw();
  }


  CanvasFlask = () => {
    const {burning, boiling} = this.state;
    const data = this.data;

    const [background] = useImage(flaskBackground);
    const [background2] = useImage(flaskBackground2);
    const [cork] = useImage(corkImage);
    const [piston] = useImage(pistonImage);


    return (
      <React.Fragment>
        <Layer preventDefault={false}>
          {this.state.withPiston ? <Image image={background2} preventDefault={false}/> : <Image image={background} preventDefault={false}/>}

          <Group x={191} y={225}>
            {/*<Text*/}
            {/*  ref={this.tempTextRef}*/}
            {/*  x={100} y={50}*/}
            {/*  text={this.tempText}*/}
            {/*/>*/}
            {/*<Text*/}
            {/*  ref={this.pressureTextRef}*/}
            {/*  x={100} y={70}*/}
            {/*  text={this.pressureText}*/}
            {/*/>*/}

            <Image ref={this.corkRef} image={cork} x={data.corkCoords.x} y={data.corkCoords.y}
                   rotation={data.corkAngle} offsetX={20} offsetY={10} preventDefault={false}/>
            <Rect ref={this.steamRef} fill={'white'}  width={39} height={65} opacity={0}/>
            <Boiling ref={this.boilingRef} y={68} width={39} height={117} bubblesNum={7} boiling={boiling}/>
          </Group>


          <Group x={175} y={90}>
            <Line
              ref={this.temperatureRef}
              points={this.pressurePoints}
              stroke="red"
              strokeWidth={1}
              rotation={this.termometerAngle}
            />
            <Line
              ref={this.pressureRef}
              y={67}
              points={this.tempPoints}
              stroke="red"
              strokeWidth={1}
              rotation={this.pressureAngle}
            />
          </Group>

          {burning && <GIF src={fireGIF} loop={true} x={195} y={430}/>}
          {this.state.withPiston ? (
            <Group x={455} y={472}>
              <Rect ref={this.steamPistonRef} fill={'white'} width={data.pistonOffset} height={100} opacity={0}/>
              <Image ref={this.pistonRef} image={piston} x={data.pistonOffset} preventDefault={false}/>
            </Group>
          ) : null}
        </Layer>
      </React.Fragment>
    )
  };

  render() {
    const {burning, withPiston} = this.state;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.CanvasFlask/>
        </CanvasContainer>
        <button
          style={{...styles.btn, left: '10%', bottom: '4%', opacity: burning ? .7 : 1}}
          onTouchStart={() => this.setState({burning: true})}
          onTouchEnd={() => this.setState({burning: false})}
          onMouseDown={() => this.setState({burning: true})}
          onMouseUp={() => this.setState({burning: false})}
        >
          Зажечь
        </button>
        <button
          style={{...styles.btn, right: '5%', bottom: '4%'}}
          onClick={() => this.setState({...initialState}, () => this.data=cloneDeep(this.initialData))}
        >
          Сбросить
        </button>
        {withPiston && <button
          style={{...styles.btn, right: '5%', bottom: '17%', background: '#2b71d5'}}
          onTouchStart={() => this.setState({pushingPistol: true})}
          onTouchEnd={() => this.setState({pushingPistol: false})}
          onMouseDown={() => this.setState({pushingPistol: true})}
          onMouseUp={() => this.setState({pushingPistol: false})}
        >
          {"< Надавить"}
        </button>}
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SteamFlask);

const styles = {
  mainContainer: {
    background: '#2b71d5',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    position: 'absolute',
    background: '#E76F52',
    color: 'white',
    zIndex: 10,
    fontSize: '2vmin',
    padding: '1vmin 1.5vmin',
    fontWeight: 'bold'
  }
};
