import React from "react";
import {_t} from "../../../../utils/lang/common";
import {Input} from "antd";


export const FieldBlock = (props) => {
  const {
    running,
    label,
    name,
    value,
    step,
    min,
    max,
    onChangeInput,
    resetOnCLick,
    startOnCLick,
    secondBlock,
    thirdBlock
  } = props;

  return (
    <div style={styles.fieldsContainer}>
      {label !== undefined &&
        <label style={styles.fieldsContainerLabel}>
          <span style={styles.fieldsContainerSpan}>{label}:</span>
          <Input
            type="number"
            step={step}
            name={name}
            onChange={onChangeInput}
            value={value}
            disabled={running}
            style={styles.fieldsContainerInput}
            min={min}
            max={max}
          />
        </label>
      }

      { secondBlock !== '' &&
        <label style={styles.fieldsContainerLabel}>
          <span style={styles.fieldsContainerSpan}>{secondBlock.label}:</span>
          <Input
            type="number"
            step={secondBlock.step}
            name={secondBlock.name}
            onChange={onChangeInput}
            value={secondBlock.value}
            disabled={running}
            style={styles.fieldsContainerInput}
            min={secondBlock.min}
            max={secondBlock.max}
          />
        </label>
      }

      { thirdBlock !== '' &&
      <label style={styles.fieldsContainerLabel}>
        <span style={styles.fieldsContainerSpan}>{thirdBlock.label}:</span>
        <Input
          type="number"
          step={thirdBlock.step}
          name={thirdBlock.name}
          onChange={onChangeInput}
          value={thirdBlock.value}
          disabled={running}
          style={styles.fieldsContainerInput}
          min={thirdBlock.min}
          max={thirdBlock.max}
        />
      </label>
      }

      {props.children}
      {
        !running
          ? (
            <button
              style={styles.startButton}
              onClick={startOnCLick}
            >{_t("button_start")}</button>
          ) : (
            <button
              style={styles.startButton}
              onClick={resetOnCLick}
            >{_t("button_reset")}</button>
          )
      }
    </div>
  )
};

const styles = {
  fieldsContainer: {
    padding: '20px 2%',
    width: 'auto',
    maxWidth: '260px',
    position: 'absolute',
    top: '0',
    left: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    zIndex: 100
  },
  fieldsContainerLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  fieldsContainerSpan: {
    textAlign: 'end',
    color: 'white',
    fontSize: '2vmin'
  },
  fieldsContainerInput: {
    width: 'auto',
    borderRadius: '5px',
    border: '1px solid rgba(0,0,0,0.5)',
    padding: '10px',
    margin: '3px 10px',
    fontSize: '2vmin'
  },
  startButton: {
    background: 'rgba(255, 255, 255, .9)',
    padding: '.3vmin 2vmin',
    fontSize: '2vmin',
    margin: '3% 6%'
  },
};