import React from "react";
import '../styles/shield.css';


const ShieldBig = (props) => (
  <div className={'shield--big'}>
    <svg width="126" height="152" viewBox="0 0 126 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M121 82.8821V17.4039C105.817 10.9104 84.8859 5.31588 64.5183 5.01266C38.2141 4.62106 14.9264 13.4144 5 17.4039V82.8821C5 98.8091 9.25131 105.888 19.5759 115.916C27.8356 123.938 52.9791 139.315 64.5183 146C74.8429 139.315 97.5571 123.938 105.817 115.916C116.141 105.888 121 97.0394 121 82.8821Z"
        fill="white" stroke={props.color || "#C73D22"} strokeWidth="10"/>
    </svg>
  </div>
)
const ShieldSmall = (props) => (
  <div className={'shield--small'}>
    <svg width="96" height="121" viewBox="0 0 96 121" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M96 66.5047V11.0041C83.5 5.5 66.2681 0.757978 49.5 0.500966C27.8443 0.16904 8.6722 7.62247 0.5 11.0041V66.5047C0.5 80.0047 4 86.0047 12.5 94.5047C19.3 101.305 40 114.338 49.5 120.005C58 114.338 76.7 101.305 83.5 94.5047C92 86.0047 96 78.5047 96 66.5047Z"
        fill={props.color || "#C73D22"}/>
    </svg>
  </div>
)


const Shield = (props) => {
  return (
    <div className={'shield'}>
      <ShieldBig color={props.color}/>
      <ShieldSmall color={props.color}/>
      <span className={'shield__text'}>{props.level}</span>
    </div>
  )
}
export default Shield;
