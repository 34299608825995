import React from "react";
import completeIcon from './images/completeIcon.png';
import {layout2} from "../../../utils/styles";
import {serverMediaUrl} from "../../../utils/api";
import {LockFilled, UnlockFilled} from '@ant-design/icons';
import {_t, isEng} from "../../../utils/lang/common";

const sortFn = (a, b, key) => {
  if(a[key] < b[key]) { return -1; }
  if(a[key] > b[key]) { return 1; }
  return 0;
}

export const mapStatColumnsConverter = (tasks, lessons, ranges) => {
  let res = [];
  let student = {
    title: '',
    dataIndex: 'rowName',
    key: 'student',
    width: '100px',
  };

  res.push(student)

  tasks.forEach((task, i) => {
    const corrLsn = lessons.find((lsn) => lsn.code === task.lessonCode);
    let img = corrLsn?.thumbnail_small || corrLsn?.thumbnail || corrLsn?.thumbnail_big;
    const taskRangeNum = task.task_range;
    const available = ranges[taskRangeNum]?.available;
    res.push({
      title: <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        { img ? (
          <img src={serverMediaUrl+img} alt="img" style={{width: '50px', height: '50px'}}/>
        ) : null}
        <p style={{fontFamily: 'Roboto-Medium', padding: '10px 0'}}>
          {(isEng() ? corrLsn?.title_en : corrLsn?.title) || '-'}
          {taskRangeNum ? (
            <span className={`rangeNum rangeNum--${available ? 'unlock' : 'lock'}`} style={{
              position: 'absolute', top: '-3px', right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '2px',
            }}>
              {available ? <UnlockFilled /> : <LockFilled />}

              <b>{_t('map.Stage')} {taskRangeNum} </b>
            </span>
          ) : null}
        </p>
      </div>,
      dataIndex: `lesson-${task.lessonCode}-html`,
      key: `lesson-${task.lessonCode}-html`,
      width: '100px',
    });
  });
  return res;
}
export const mapStatRowsConverter = (rows, tasks) => {
  return rows?.map(rowData => {
    const rowHtml = (
      <div key={rowData.key} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <span style={{color: layout2.blue2}}>{`${rowData.rowName}`}</span>
      </div>
    )
    let resRow = {};

    tasks.forEach(task => {
      rowData[`lesson-${task.lessonCode}-html`] = (
        <React.Fragment key={task.key}>
          {
            rowData[`lesson-${task.lessonCode}`] ? (
              <img src={completeIcon} style={{width: '40px', height: '40px', margin: 'auto', display: 'block'}}/>
            ) : null
          }
          {/* <img src={notCompleteIcon} style={{width: '40px', height: '40px', margin: 'auto', display: 'block'}}/>*/}
        </React.Fragment>
      )
    })

    return {
      ...rowData,
      ...resRow,
      rowText: rowData.rowName,
      rowName: rowHtml
    }
  })
}
