import React, {useEffect, useState} from "react";
import '../styles/cardStyles.css';
import successIcon from '../images/success_icon.png'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

/**
 * PROPS
 *   id: number | string,
 *   img: string,
 *   mainText: string,
 *   index: number,
 *   smallSize: bool,
 *   className: string,
 *   success: bool,
 *   fail: bool,
 *   onClick: fn,
 *   draggable: bool,
 *   onDragStart: fn,
 *   getCardReset: fn,
 */

const getCorrectCardClassName = (success, fail) => {
  const classCrud = 'school-task-card__answer--';
  if (success || fail) {
    return classCrud + (success ? 'success' : '') + (fail ? 'fail' : '')
  }
  return '';
}
const SchoolTaskCard = (props) => {
  const [dragging, setDragging] = useState(false);

  let answerTypeClassNames = getCorrectCardClassName(props.success, props.fail);

  const cardClassNames = [
    'school-task-card',
    `${dragging ? 'school-task-card--dragging' : ''}`,
    `${props.smallSize && !dragging ? 'school-task-card--small-size' : ''}`,
    `${props.draggable ? 'school-task-card--draggable' : ''}`,
    props.className || '',
  ].join(' ');

  const onDrop = (e) => {
    if (props.onDrop) {
      props.onDrop(e);
    }
  }
  const onDrag = (e) => {
    if (props.onDrag) {
      props.onDrag(e);
    }
    setDragging(true);
  }
  const onDragStart = (e) => {
    if (props.onDragStart) {
      props.onDragStart(e);
    }
  }
  const onDragEnd = (e) => {
    if (props.onDragEnd) {
      const isReturn = props.onDragEnd(e);
      if (isReturn) { return }
    }
    setDragging(false);
  }

  const resetDragCard = () => {
    setDragging(false);
  };
  useEffect(() => {
    props.getCardReset && props.getCardReset(resetDragCard);
  }, [])

  const CardContent = () => (
    <div
      className={cardClassNames}
      onClick={props.onClick}
      data-id={props.id}
    >
      {
        props.img ? (
          <img draggable={false} className={'school-task-card__img'} src={props.img} alt=""/>
        )
          : props.mainText ?
        (
          <div className={`school-task-card__main-text-container ${props.smallSize && !dragging ? 'school-task-card__main-text-container--small-size' : ''}`}>
            <p className={'school-task-card__main-text'}>{props.mainText}</p>
          </div>
        ) : null
      }{
        answerTypeClassNames ? (
          <div className={'school-task-card__answer ' + answerTypeClassNames}/>
        ) : null
      }{
        props.success ? (
          <img draggable={false} className={'school-task-card__success-icon'} src={successIcon} alt=""/>
        ) : null
      }
      {
        props.text ? (
          <p className={'school-task-card__text'}>
            {props.text}
          </p>
        ) : null
      }
    </div>
  )

  return (
    <>
      {
        props.draggable ? (
          <DragDropContainer
            targetKey="targetKey"
            onDrop={onDrop}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            dragData={{id: props.id}}
            render={CardContent}
          />
        ) : (
          <CardContent />
        )
      }
    </>
  )
}


export default SchoolTaskCard;
