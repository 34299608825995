import React from "react";
import {Link, useHistory} from "react-router-dom";
import {RightOutlined} from "@ant-design/icons";



const TeacherCatalogItem = (props) => {
  const {
    lessonItem
  } = props;
  const {title, code, description} = lessonItem;
  const history = useHistory();

  const currentPathname = history.location.pathname;
  const corrPathname = currentPathname.replace(/\//g, '');
  const taskId = code.match(/[0-9]*$/)[0];
  const taskCodename = code.slice(0, code.length - taskId.length);
  const taskCode = `${taskCodename}/${taskId}`;
  const lessonLink = `/task/ru/${taskCode}?withback=1${corrPathname ? `&back=${corrPathname}` : ''}`

  return (
    <Link className={'teacher-catalog-item'} to={lessonLink}>
      { title }
      <RightOutlined style={{fontSize: '30px', position: 'absolute', top: '35%', opacity:.6, right: '20px'}} />
    </Link>
  )
}

export default TeacherCatalogItem;