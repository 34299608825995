import React from "react";
import {Group} from "react-konva";



export const defaultPatchData = {
  title: '',
  answerWindowVisible: false,
  answerWindowText: '',
  answerWindowStep1Visible: false,
  answerWindowStep2Visible: false,
  startBtnVisible: false,
  openAnswerWindowBtnVisible: false,
}

export const RepulsionOfBodiesAstronautAndShipScenario = [

  {
    key: 'initial',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми “Оттолкнуться”',
      startBtnVisible: true
    }
  },

  {
    key: 'repulsion',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми “Оттолкнуться”',
      repulsion: true,
    },
    switch_at: 7
  },

  {
    key: 'answerWindow',
    patchData: {
      ...defaultPatchData,
      title: 'Почему скорость космонавта выше скорости корабля?',
      repulsion: true,
      openAnswerWindowBtnVisible: true,
    }
  },

  {
    key: 'answerWindow step 1',
    patchData: {
      ...defaultPatchData,
      title: 'Почему скорость космонавта выше скорости корабля?',
      repulsion: true,
      answerWindowVisible: true,
      answerWindowText: 'Так как корабль обладает большей массой, \n' +
        'то он сильнее препятствует изменению своей скорости. \n' +
        'Это свойство тел называют инертностью. \n' +
        'Корабль более инертен, чем космонавт, поэтому приобретает \n' +
        'меньшую скорость после взаимодействия',

      answerWindowStep1Visible: true
    },
    next_success: 'answerWindow step 2'
  },
  {
    key: 'answerWindow step 2',
    patchData: {
      ...defaultPatchData,
      title: 'Почему скорость космонавта выше скорости корабля?',
      repulsion: true,
      answerWindowVisible: true,
      answerWindowText: 'Это значит, что разогнать корабль сложнее',


      answerWindowStep2Visible: true
    },
    previous: 'answerWindow step 1'
  }


]