import React, {useEffect, useState} from "react";

// Hook
function useSetInterval(callback, interval, condition=true) {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (condition) {
      callback();
      setTimeout(() => {
        setTimer(timer+1)
      }, interval);
    }
  }, [timer, condition]);
}

export default useSetInterval;