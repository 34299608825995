import React from "react";
import CanvasContainer from "../../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow, RegularPolygon} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import backgroundImg from "../../../../../images/peryshkinDam/background.png";
import backgroundHelperImg from "../../../../../images/peryshkinDam/backgroundHelper.png";
import aeroplaneImg from "../../../../../images/peryshkinDam/aeroplane.png";
import wheelContainerImg from "../../../../../images/peryshkinDam/wheelContainer.png";
import mechanismImg from "../../../../../images/peryshkinDam/mechanism.png";
import {layout2, mainColor} from "../../../../../utils/styles";
import {getTimeDeltaSec, inputVal, showFailOrSuccessPopup} from "../../../../../utils/common";
import ScenarioManager from "../../../../../utils/ScenarioManager";
import {getScenario, scenarioDam} from "../scenario";
import Card from "../../../../canvas/components/Card";
import CanvasButton from "../../../../canvas/components/CanvasButton";
import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import CanvasInput from "../../../../canvas/components/CanvasInput";
import TaskComplete from "../../../../canvas/components/TaskComplete";
import * as actions from "../../../../../store/actions";


class Dam extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',

      'airplane',
      'wheelLines',
      'cloud1',
      'cloud2',

      'heightTxt',
      'volumeTxt',
      'timeSecTxt',

      'staticWaterGlare',
      'diagonalWaterGlare',
      'diagonalWaterGlare2',
      'horizontalWaterGlare',

      'startBtn',
      'stopBtn',

      'titleTxt',

      'damLine',
    ]

    this.staticData = {
      airplaneSpeed: 20,
      cloudSpeed: .5,
    }
    this.elementsUI = {
      cardPowerVisible: false,
      btnsHidden: false,
      cardInfoVisible: false,
      cardInfo2Visible: false,
      cardInputVisible: false,
      cardEfficiencyVisible: false,
      successVisible: false,
      stationPowerVisible: false,

      inputVal: undefined,
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timedeltaSec: 0,

      volumeStep: 0,

      wheelOffsetX: 0,
      cloudOffsetX: 0,
      airplaneOffsetX: 0,

      height: 0,
      volume: 0,
      timeSec: 0,

      active: false,
      inputVal: '',

      diagonalWaterGlareOffset: {x: 0, y: 0},
      horizontalWaterGlareOffset: 0,
    };
    this.data = cloneDeep(this.initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    // const correctKey = this.props.code === 'peryshkin13' ? 'lsn1 step1' : 'lsn2 step1';
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    // const correctKey = this.props.code === 'peryshkin13' ? 'lsn1 step1' : 'lsn2 step1';
    // this.scenarioManager.selectStepByKey(correctKey);
    this.scenarioManager.resetScenario();
    this.updateStage();
  };
  onClickStart = () => {
    this.data.active = true;
    this.scenarioManager.next();
  };
  onClickStop = () => {
    this.data.active = false;
    this.scenarioManager.next();
  };
  onCheckSuccess = () => {
    if (this.data.inputVal >= this.data?.inputSuccessBegin && this.data.inputVal <= this.data.inputSuccessEnd) {
      this.scenarioManager.next();
    } else {
      showFailOrSuccessPopup(this, false);
    }
  };


  get diagonalWaterGlareOffset() {
    let x = 0;
    let y = 0;
    x = this.data.diagonalWaterGlareOffset.x < -15 ? 0 : this.data.diagonalWaterGlareOffset.x-.1;
    y = this.data.diagonalWaterGlareOffset.y < -30 ? 0 : this.data.diagonalWaterGlareOffset.y-.2;
    return {x: x, y: y};
  }
  get horizontalWaterGlareOffset() {
    return this.data.horizontalWaterGlareOffset > 90 ? 0 : this.data.horizontalWaterGlareOffset+.2;
  }

  get airplaneOffsetX() {
    return this.data.airplaneOffsetX + this.staticData.airplaneSpeed * this.data.timedeltaSec;
  }
  get cloudOffsetX() {
    return this.data.cloudOffsetX + this.staticData.cloudSpeed * this.data.timedeltaSec;
  }
  get wheelOffsetX() {
    const data = this.data;
    return data.wheelOffsetX < 5 ? data.wheelOffsetX + .3 : 0;
  }
  get volume() {
    const data = this.data;
    return Math.round(data.timeSec) * (data.volumeStep || 0);
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.cloudOffsetX = this.cloudOffsetX;
    data.airplaneOffsetX = this.airplaneOffsetX;


    if (data.active) {
      data.timeSec += data.timedeltaSec;
      data.volume = this.volume;
      data.wheelOffsetX = this.wheelOffsetX;

      data.diagonalWaterGlareOffset = this.diagonalWaterGlareOffset;
      data.horizontalWaterGlareOffset = this.horizontalWaterGlareOffset;
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));




    n['wheelLines'].offsetX(-data.wheelOffsetX);

    n['startBtn'].visible(!data.active);
    n['stopBtn'].visible(data.active);

    n['damLine'].offsetY(data.active ? 45 : 0);


    n['cloud1'].offsetX(data.cloudOffsetX);
    n['cloud2'].offsetX(-data.cloudOffsetX);
    n['airplane'].offsetX(-data.airplaneOffsetX);

    n['heightTxt'].text(data.height);
    n['volumeTxt'].text(data.volume);
    n['timeSecTxt'].text(Math.round(data.timeSec));

    n['titleTxt'].text(data.text?.value);

    n['diagonalWaterGlare'].visible(data.active);
    n['diagonalWaterGlare2'].visible(data.active);
    n['horizontalWaterGlare'].visible(data.active);
    n['staticWaterGlare'].visible(data.active);
    n['diagonalWaterGlare'].offset(data.diagonalWaterGlareOffset);
    n['diagonalWaterGlare2'].offsetX(data.diagonalWaterGlareOffset.x);
    n['horizontalWaterGlare'].offsetX(-data.horizontalWaterGlareOffset);


    n['stage'].draw();
  }


  Canvas = () => {

    const [background] = useImage(backgroundImg);
    const [aeroplane] = useImage(aeroplaneImg);
    const [wheelContainer] = useImage(wheelContainerImg);
    const [backgroundHelper] = useImage(backgroundHelperImg);
    const [mechanism] = useImage(mechanismImg);


    return (
      <React.Fragment>
        <Group>
          <Image image={background}/>


          <Image scale={{x: .7, y: .7}} x={-300} y={20} ref={this._ref('airplane')} image={aeroplane}/>


          <Group y={11}>
            <Rect ref={this._ref('cloud1')} x={830} width={270} height={50} cornerRadius={30} stroke={'white'} fill={'white'}/>
            <Rect ref={this._ref('cloud2')} x={330} y={60} width={200} height={50} cornerRadius={30} stroke={'white'} fill={'white'}/>
          </Group>


          <Group x={150} y={240}>
            <Rect ref={this._ref('damLine')} width={3} height={100} fill={'black'} opacity={.7}/>

            <Group y={180}>
              <Group y={10}>
                <Arrow points={[0,0,0,85]} stroke={'white'} fill={'white'}/>
                <Arrow rotation={180} points={[0,0,0,85]} stroke={'white'} fill={'white'}/>
                <Text x={-30} text={'h'} fill={'white'} fontSize={25} fontStyle={'italic'}/>
              </Group>
              <Line y={100} points={[0,0,92,0]} stroke={'white'} dash={[5, 5]} opacity={.8}/>
            </Group>
          </Group>


          <Group x={300} y={343.5}>
            <Image image={mechanism}/>
            <Group x={14} y={157}>
              <Rect x={-2} y={-1} width={62} height={20} fill={'#e4e2f8'} cornerRadius={5}/>
              <Group x={-10} ref={this._ref('wheelLines')}>
                {
                  Array(24).fill(1).map((el, i) => (
                    <Line key={'line-'+i} y={0} x={3*i} points={[0,0,0,18]} stroke={layout2.gray} strokeWidth={.5} opacity={1}/>
                  ))
                }
              </Group>

              <Rect x={0} y={-1} width={58} height={3} fill={'#e4e2f8'} cornerRadius={15} opacity={.6}/>
              <Rect x={0} y={16} width={59} height={2} fill={'#e4e2f8'} cornerRadius={15} opacity={.6}/>

              <Rect y={-1} x={-21.5} width={20} height={20} fill={'#2A77E7'}/>
              <Image x={59} y={-1.7} image={backgroundHelper}/>
            </Group>
          </Group>



          <Group x={650} y={140} visible={this.data.cardInputVisible}>
            <Card width={280} height={60}>
              <Group x={20} y={20} visible={this.data.cardPowerVisible}>
                <Text text={`N =`} fill={mainColor} fontSize={30}/>
                <CanvasInput
                  id={'1'}
                  y={-1}
                  x={60}
                  width={100}
                  height={30}
                  stage={this._getNode('stage')}
                  textColor={mainColor}
                  onInput={(val) => {
                    this.data.inputVal = inputVal(val);
                  }}
                  value={this.data.inputVal}
                />
                <Text x={165} text={` кВт`} fill={mainColor} fontSize={30}/>
              </Group>
              <Group x={20} y={20} visible={this.data.cardEfficiencyVisible}>
                <Text text={`КПД =`} fill={mainColor} fontSize={30}/>
                <CanvasInput
                  id={'1'}
                  y={-1}
                  x={100}
                  width={60}
                  height={30}
                  stage={this._getNode('stage')}
                  textColor={mainColor}
                  onInput={(val) => {
                    this.data.inputVal = inputVal(val);
                  }}
                  value={this.data.inputVal}
                />
                <Text x={165} text={` %`} fill={mainColor} fontSize={30}/>
              </Group>
            </Card>

            <CanvasButton
              btnRef={this._ref('startBtn')}
              visible={!this.data.start}
              text={'Проверить'}
              onClick={() => this.onCheckSuccess()}
              fontSize={23}
              strokeWidth={.2}
              btnCornerRadius={0}
              width={135}
              height={45}
              x={77} y={70}
            />
          </Group>

          <Group x={450} y={220}>
            <Card width={120} height={225} visible={this.data.cardInfoVisible}>
              <Group x={15} y={10}>
                <Text text={'h'} fill={layout2.orange} fontStyle={'italic'} fontSize={30}/>
                <Text x={15} y={12} text={', м:'} fill={layout2.orange} fontSize={15}/>
                <Text ref={this._ref('heightTxt')} x={0} y={30} text={'0'} fill={layout2.orange} fontSize={25}/>

                <Group y={70}>
                  <Text text={'Объем \nводы, м³'} fill={layout2.orange} fontStyle={'bold'} fontSize={13}/>
                  <Text ref={this._ref('volumeTxt')} x={0} y={30} text={'0'} fill={layout2.orange} fontSize={25}/>
                </Group>

                <Group y={140}>
                  <Text text={'Время, \nсекунд'} fill={layout2.orange} fontStyle={'bold'} fontSize={13}/>
                  <Text ref={this._ref('timeSecTxt')} x={0} y={30} text={'0'} fill={layout2.orange} fontSize={25}/>
                </Group>
              </Group>
            </Card>

            <Card y={70} width={120} height={90} visible={this.data.cardInfo2Visible}>
              <Group x={15} y={10}>
                <Text text={'Мощность \nпотока воды, \nкВт'} fill={layout2.orange} fontStyle={'bold'} fontSize={13}/>
                <Text ref={this._ref('powerWaterTxt')} x={0} y={40} text={'10000'} fill={layout2.orange} fontSize={25}/>
              </Group>
            </Card>

            <Group visible={!this.data.btnsHidden}>
              <CanvasButton
                btnRef={this._ref('startBtn')}
                visible={!this.data.start}
                text={'Пуск'}
                onClick={() => this.onClickStart()}
                fontSize={23}
                strokeWidth={.2}
                btnCornerRadius={0}
                width={120}
                height={45}
                y={240}
              />
              <CanvasButton
                btnRef={this._ref('stopBtn')}
                visible={this.data.start}
                text={'Остановить'}
                onClick={() => this.onClickStop()}
                fontSize={23}
                strokeWidth={.2}
                btnCornerRadius={0}
                width={150}
                height={45}
                y={240}
              />
            </Group>
          </Group>

          <Group x={300} y={230} visible={this.data.stationPowerVisible}>
            <Rect width={120} height={90} fill={'rgba(190,190,190)'} cornerRadius={10}/>
            <Group y={15}>
              <Text text={'1500'} fill={'white'} fontSize={35} width={120} align={'center'}/>
              <Text y={40} text={'кВт'} fill={'white'} fontSize={20} width={120} align={'center'}/>
            </Group>
            <RegularPolygon
              rotation={180}
              sides={3}
              fill={'rgba(190,190,190)'}
              radius={15}
              x={40} y={90}
            />
          </Group>

          <Text ref={this._ref('titleTxt')} x={50} y={30} text={'12'} fontSize={30} fill={mainColor} fontStyle={'bold'}/>
        </Group>

        <Group x={120} y={300} ref={this._ref('staticWaterGlare')}>
          <Rect x={15} y={10} width={20} height={3} fill={'#4f97f8'} cornerRadius={20} rotation={0}/>
          <Rect x={17} y={26} width={20} height={3} fill={'#4f97f8'} cornerRadius={20} rotation={0}/>
          <Rect x={35} y={10} width={20} height={3} fill={'#4f97f8'} cornerRadius={20} rotation={63}/>
          <Rect x={37} y={26} width={20} height={3} fill={'#4f97f8'} cornerRadius={20} rotation={63}/>
        </Group>

        <Group x={150} y={310} ref={this._ref('diagonalWaterGlare')}>
          <Rect x={17} y={23} width={30} height={3} fill={'#4f97f8'} cornerRadius={20} rotation={63}/>
          <Rect x={47} y={82} width={30} height={3} fill={'#4f97f8'} cornerRadius={20} rotation={63}/>
          <Rect x={21} y={50} width={30} height={3} fill={'#4f97f8'} cornerRadius={20} rotation={63}/>
          <Rect x={63} y={120} width={30} height={3} fill={'#4f97f8'} cornerRadius={20} rotation={63}/>
        </Group>
        <Group x={520} y={520} ref={this._ref('diagonalWaterGlare2')}>
          <Rect x={-270} y={-15} width={25} height={3} fill={'#4f97f8'} cornerRadius={20}/>
          <Rect x={-260} y={-9} width={30} height={3} fill={'#4f97f8'} cornerRadius={20}/>
        </Group>
        <Group x={520} y={520} ref={this._ref('horizontalWaterGlare')}>
          {
            Array(3).fill(1).map((el, i) => {
              const x = i%2 === 0 ? 60 : -40
              return <Rect x={x*i} y={10*i} width={50} height={5} fill={'#4f97f8'} cornerRadius={20}/>
            })
          }
        </Group>


        <CanvasResetBtn
          onClick={() => this.onClickReset()}
        />

        <Group visible={this.data.successVisible}>
          <TaskComplete withoutBackground text={'Задача выполнена!'} />
        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dam);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
