import ym from "react-yandex-metrika";


const sendYmGoal = (...args) => {
  try {
    ym(...args);
  } catch (err) {}
}
/**
------------------------------------------------------
                     COMMON
------------------------------------------------------
*/

/* Параметры юзера */
export const ymUserParams = (params) => {
  sendYmGoal('userParams', params);
}

/* Отправка обратная связь */
export const ymClickSendFeedbackGoal = () => {
  sendYmGoal('reachGoal', `click__send_feedback`);
}

/* Зарегистрированы на платформе учителя/ученика */
export const ymRegistrationGoal = (isTeacher) => {
  const code = `${isTeacher ? 'teacher' : 'student'}__click__registration-btn`;
  sendYmGoal('reachGoal',code);
}


/* Открытие блока рекомендаций курсов */
export const ymClickOpenRecommendation = () => {
  sendYmGoal('reachGoal', `click__open-recommendation`);
}

/* Закрытие блока рекомендаций курсов */
export const ymClickCloseRecommendation = () => {
  sendYmGoal('reachGoal', `click__close-recommendation`);
}

/* Переход по баннеру */
export const ymClickCourseBanner = (courseLink) => {
  sendYmGoal('reachGoal', `click__course-banner`, {
    courseLink: courseLink
  });
}


/* Ученик завершил регистрацию */
export const ymStudentCompleteRegistrationGoal = () => {
  const code = 'student__click__complete-registration-btn';
  sendYmGoal('reachGoal',code);
}



/**
------------------------------------------------------
                     TEACHER
------------------------------------------------------
*/
/* Используют платформу для создания домашних работ */
export const ymUseHomeworkFunctionalityGoal = (hwCode, tasksCount) => {
  sendYmGoal('reachGoal',`teacher__click__copy-link-btn`, {
    homeworkCode: hwCode,
    tasksCount: tasksCount || 0
  });
}
/* Используют платформу для ведения урока */
export const ymTeacherEnteredToLsnGoal = (lsnType, lsnCode, chapterId, paragraphId, gradeNum) => {
  sendYmGoal('reachGoal',`teacher__entered-to__lesson`, {
    lessonType: lsnType,
    lessonCode: lsnCode,
    chapterId: chapterId,
    paragraphId: paragraphId,
    gradeNum: gradeNum,
  });
}
/* Используют платформу для подготовки к уроку с использованием карточек */
export const ymTeacherEnteredToTeacherCardGoal = (lsnCode, chapterId, paragraphId, gradeNum) => {
  sendYmGoal('reachGoal',`teacher__entered-to__teacher-card`, {
    lessonCode: lsnCode,
    chapterId: chapterId,
    paragraphId: paragraphId,
    gradeNum: gradeNum,
  });
}

/* Нажали на Создание ДЗ */
export const ymTeacherClickCreateHomeworkGoal = () => {
  sendYmGoal('reachGoal',`teacher__click__create-homework`);
}
/* Нажали на Отправить ученикам */
export const ymTeacherClickSendToStudentGoal = (hwCode, tasksCount) => {
  sendYmGoal('reachGoal',`teacher__click__send-to-student`, {
      homeworkCode: hwCode,
      tasksCount: tasksCount || 0
  });
}



/**
------------------------------------------------------
                     STUDENT
------------------------------------------------------
*/
/* Зарегистрировался по ссылке из Дз (обновленная форма) */
export const ymStudentRegistrationFromLinkGoal = (hwCode) => {
  sendYmGoal('reachGoal',`student__click__registration-btn__from-link`, {homeworkCode: hwCode});
}
/* Перешли по ссылке ДЗ от учителя (обновленная форма) */
export const ymStudentFollowedLinkGoal = () => {
  sendYmGoal('reachGoal',`student__followed-to__homework-link`);
}
/* Выполняют домашнее задание */
export const ymStudentEnteredToLsnFromHomeworkGoal = (hwCode) => {
  sendYmGoal('reachGoal',`student__entered-to__lesson-homework-work`, {homeworkCode: hwCode});
}
