import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import dragImg11 from "../images/arrangeByColumns/LogicLever5_1_1.png";
import dragImg12 from "../images/arrangeByColumns/LogicLever5_1_2.png";
import dragImg13 from "../images/arrangeByColumns/LogicLever5_1_3.png";

import ArrangeByColumns from "./ArrangeByColumns";
import bgNature from '../images/bg.png';


const LogicLever5 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'Active vocabulary',
      columnsItems: [
        {id: 1, successAnswerId: 2, title: "Lever"},
        {id: 2, successAnswerId: 3, title: "Fulcrum"},
        {id: 3, successAnswerId: 1, title: "Seesaw"},
      ],
      dragItems: [
        {id: 1, image: dragImg13},
        {id: 2, image: dragImg11},
        {id: 3, image: dragImg12},
      ]
    },
    {
      title: 'Active vocabulary',
      columnsItems: [
        {id: 1, successAnswerId: 3, title: "Lever"},
        {id: 2, successAnswerId: 1, title: "Fulcrum"},
        {id: 3, successAnswerId: 2, title: "Seesaw"},
      ],
      dragItems: [
        {id: 1, mainText: "A point on which a lever pivots"},
        {id: 2, mainText: "A lever on the children’s playground"},
        {id: 3, mainText: "A movable bar that pivots on a fulcrum attached to a fixed point"},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <ArrangeByColumns
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    columns={item.columnsItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default LogicLever5;
