import React, {useEffect, useRef} from 'react';

import {Image} from 'react-konva';
import Victor from 'victor';

const Water = ({image, vector, canvasWidth: width = 1000, canvasHeight: height = 700, getRef, offset, targetWidth, targetHeight}) => {
    const imageRef = useRef();
    useEffect(() => {
        if(typeof getRef === 'function') {
            getRef(imageRef);
        }
    }, [imageRef.current]);

    const angle = vector instanceof Victor ? vector.multiplyY(new Victor(0, -1)).angleDeg() : 0;

    const scaleCoef = calcCoef({
      width: image?.width,
      height: image?.height,
      targetWidth,
      targetHeight,
    });

    return (
        <Image
            ref={imageRef}
            width={width}
            height={height}
            fillPatternImage={image}
            fillPatternScale={scaleCoef}
            fillPatternRotation={angle}
            fillPatternOffset={offset}
            preventDefault={false}
        />
    );
};

const calcCoef = ({width, height, targetWidth, targetHeight}) => {
  let coef = {x: 1, y: 1};

  if(width === undefined || height === undefined) {
    return coef;
  }

  if (targetWidth !== undefined) {
    coef.x = targetWidth / width;

    if (targetHeight === undefined) {
      coef.y = coef.x;
    }
  }
  if (targetHeight !== undefined) {
    coef.y = targetHeight / height

    if (targetWidth === undefined) {
      coef.x = coef.y;
    }
  }

  return coef;
};

export default Water;
