import { Arrow, Layer, Stage, Text, Group } from "react-konva";
import React from "react";

export const CanvasAxis = () => {
  return (
       <Group>
            <Text
              x={190}
              y={5}
              fontSize={20}
              text={'x'}
            />
            <Arrow
              points={[0, 0, 200, 0]}
              stroke={"white"}
              fill={"white"}
              strokeWidth={2}
            />
            <Text
              x={-20}
              y={-205}
              fontSize={20}
              text={'y'}
            />
            <Arrow
              points={[0, 0, 0, -200]}
              stroke={"white"}
              fill={"white"}
              strokeWidth={2}
            />
        </Group>
  )
};
