import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Circle, Group, Image, Layer, Line, Rect, Text} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";

import indicatorImg from "../../../../images/ICE/indicator.png";
import valveImg from "../../../../images/ICE/valve.png";
import valveCamImg from "../../../../images/ICE/valveCam.png";
import kolba2Img from "../../../../images/ICE/kolba2.png";
import kolba3Img from "../../../../images/ICE/kolba3.png";
import sparkPlugImg from "../../../../images/ICE/sparkPlug.png";
import pistonHeadImg from "../../../../images/ICE/pistonHead.png";
import pistonBodyImg from "../../../../images/ICE/pistonBody.png";
import crankshaftImg from "../../../../images/ICE/crankshaft.png";
import stepsTableImg from "../../../../images/ICE/stepsTable.png";
import exhaustImg from "../../../../images/ICE/wasteGas.png";
import answerWasteGasImg from "../../../../images/ICE/answer1.png";
import answerSparkPlugImg from "../../../../images/ICE/answer2.png";
import answerFuelImg from "../../../../images/ICE/answer3.png";
import fuelImg from "../../../../images/ICE/fuel.png";
import backgroundImg from "../../../../images/ICE/background.png";
import arrowRightImg from "../../../../images/ICE/arrowRight.png";
import arrowLeftImg from "../../../../images/ICE/arrowLeft.png";
import gasImg from "../../../../images/ICE/gas.png";
import ignitionImg from "../../../../images/ICE/ignition.png";
import indicatorLineImg from "../../../../images/ICE/indicatorLine.png";
import indicatorRpmImg from "../../../../images/ICE/indicatorRpm.png";

import Explosion from "../components/Explosion";
import Indicator from "../components/Indicator";

import {_t} from '../../../../utils/lang/common';

import {
  adiabaticVolumeChange,
  atmospherePressure,
  burn,
  gasAmount,
  gasPushed,
  initialData,
  inputValveWork,
  pistonSize,
  pressureStatic,
  pushGas,
  volume
} from "../utils/icePhysics";
import {getBoolByLsnCode} from "../utils/iceCommon";
import {dragBoundFunc, onDragEnd, onDragMove} from "../utils/dragingHelper";
import Konva from "konva";
import Card from "../../../canvas/components/Card";
import * as actions from "../../../../store/actions";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getScenario} from "../utils/scenario";


class Step4 extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------

    this.stageRef = React.createRef();

    this.managedComponents = [
      'stage',
      'indicatorRpmContainer',
      'indicatorRpmLine',
      'engineStrokeTEXT',
      'rpmText',
      'valve',
      'valveCam',
      'valveLeft',
      'valveCamLeft',
      'valveRight',
      'valveCamRight',
      'intake',
      'exhaust',

      'dropEngineStroke1',
      'dropEngineStroke2',
      'dropEngineStroke3',
      'dropEngineStroke4',

      'dropEngineForSparkPlug1',
      'dropEngineForSparkPlug2',
      'dropEngineForSparkPlug3',
      'dropEngineForSparkPlug4',

      'sparkPlug',
      'fuel',
      'gas',
      'crankshaft',
      'pistonBody',
      'pistonHead',
      'piston',

      'startHint',
      'blur',
      'answers',
      'cardHint',
      'cardHintText',
      'btnsContainer',
      'arrowHint',
    ];

    this.state = {};
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      cardHintStg: {},
      blurStg: {},
      btnsContainerStg: {},
      answerStg: {},
      arrowHintStg: {},
      cardHintRectStg: {},
      cardHintText: '',

      // PISTON
      pistonHeadOffsetY: 0,
      pistonBodyOffsetY: 0,
      pistonBodyAngle: 0,
      crankshaftAngle: 0,
      crankshaftSpeed: 0,

      // VALVES
      valveLeftOffsetY: 0,
      valveCamLeftAngle: 0,
      valveRightOffsetY: 0,
      valveCamRightAngle: 0,

      physics: initialData(),

      // ACTIONS
      fuelInjection: false,
      sparkPlugActive: false,


      // ENGINE STROKE
      engineStroke: 1,

      // FUEL AND GAS
      fuelLevel: 0,
      gasLevel: 0,
      cylinderContentsHeight: 0,
      exhaustOpacity: 0,

      // TABLE DATA
      answerWasteGas: 0, // engine stroke
      answerSparkPlug: 0, // engine stroke
      answerFuel: 0, // engine stroke

      //HINTS
      uiElements: {
        startHint: true,
        isCorrect: false,
        showCongratModal: false,
        successShown: false
      },
      hintForButtonsViewed: false
    };
    this.data = cloneDeep(this.initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  /**
   * -------------------------- GETTERS --------------------------
   */
  get cylinderContentsHeight() {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));
    const pistonHeadNode = n['pistonHead'];
    const pistonHeight = pistonHeadNode.getHeight();
    const pistonOffsetY = -data.pistonHeadOffsetY;
    return pistonOffsetY + pistonHeight;
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();

  componentDidUpdate(prevProps, prevState, snapshot) {}

  _getNode = (key) => this[`${key}Ref`]?.current;

  componentWillUnmount() {
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  componentDidMount() {
    this.requestId = window.requestAnimationFrame(this.move);
    this.data.physics.internal.volume = volume(this.pistonOffsetY);
    this.scenarioManager.resetScenario();
  }

  getTableNodes = () => {
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));
    return ({
      dropEngineStroke1Node: n['dropEngineStroke1'],
      dropEngineStroke2Node: n['dropEngineStroke2'],
      dropEngineStroke3Node: n['dropEngineStroke3'],
      dropEngineStroke4Node: n['dropEngineStroke4'],

      dropEngineForSparkPlug1Node: n['dropEngineForSparkPlug1'],
      dropEngineForSparkPlug2Node: n['dropEngineForSparkPlug2'],
      dropEngineForSparkPlug3Node: n['dropEngineForSparkPlug3'],
      dropEngineForSparkPlug4Node: n['dropEngineForSparkPlug4'],
    });
  }

  get pistonHeadOffsetY() {return 50 * Math.cos(Math.PI/180 * this.data.crankshaftAngle)}
  get pistonBodyOffsetY() {return 50 * Math.cos(Math.PI/180 * this.data.crankshaftAngle)}
  get leverage () {return 28 * Math.sin(Math.PI/180 * this.data.crankshaftAngle) }
  get pistonBodyAngle() {return - Math.asin(this.leverage / 105) / Math.PI * 180;}

  get explosion() { return this.data.physics.internal.temperatureKelvin > 650 || (this.data.sparkPlugActive && this.data.physics.internal.temperatureKelvin > 550)}

  get pistonOffsetY(){
    return 50 - this.data.pistonHeadOffsetY;
  }

  get engineStroke() {
    const data = this.data;
    const crankshaftAngle = data.crankshaftAngle % 360;
    const isRightHalf = crankshaftAngle % 360 >= 0 && crankshaftAngle % 360 < 180;
    const isLeftHalf = crankshaftAngle % 360 >= 180 && crankshaftAngle % 360 < 360;
    const isEvenStrokeOfEngine = data.engineStroke % 2 === 0;

    if ((isLeftHalf && !isEvenStrokeOfEngine) || (isRightHalf && isEvenStrokeOfEngine)) {
      return data.engineStroke < 4 ? data.engineStroke + 1 : 1;
    }
    return data.engineStroke;
  }

  updateCrankshaftAngle = (timedeltaSec) => {
    const data = this.data;
    const momentum = this.leverage * (data.physics.internal.pressure || 0) * pistonSize;
    data.crankshaftSpeed = this.enslow(data.crankshaftSpeed,30 * Math.pow(data.crankshaftSpeed / 500, 2) + 10, timedeltaSec);
    data.crankshaftSpeed += momentum * timedeltaSec / 200;
    data.crankshaftAngle += data.crankshaftSpeed * timedeltaSec;
  };

  enslow (value, delta, timedeltaSec) {
    let absValue = Math.abs(value);
    let signValue = Math.sign(value);

    absValue -= delta * timedeltaSec;
    if (absValue < 0) absValue = 0;

    return signValue * absValue
  };

  normalizeAngle = (angle) => {
    if (angle < 0) {
      const backRounds = Math.abs(Math.floor(angle / 360));
      return angle + backRounds * 360;

    }
    else return angle;
  };

  updateValveOffsetY = () => {
    const data = this.data;
    const valveCamLeft = this.normalizeAngle(Math.round(data.valveCamLeftAngle) % 360);
    // LEFT
    if (valveCamLeft <= 45) {
      data.valveLeftOffsetY = valveCamLeft / 5;
    } else if (valveCamLeft > 45 && valveCamLeft <= 90) {
      data.valveLeftOffsetY = 18 - valveCamLeft / 5;
    } else {
      data.valveLeftOffsetY = 0;
    }

    // RIGHT
    const valveCamRight = this.normalizeAngle( Math.round(data.valveCamRightAngle - 116 + 90) % 360); // -116 is a constant offset
    if ( valveCamRight <= 45) {
      data.valveRightOffsetY = valveCamRight / 5;
    } else if (valveCamRight > 45 && valveCamRight <= 90) {
      data.valveRightOffsetY = 18 - valveCamRight / 5;
    } else {
      data.valveRightOffsetY = 0;
    }

  };

  updateValveCamAngles = () => {
    const data = this.data;

    // console.log('debug no fuel', data.answerFuel);
    const { ice4, ice5, ice6, } = getBoolByLsnCode(this.props.code);

    if (ice5) {
      data.answerFuel = 1;
      data.answerWasteGas = 4;
    }

    if (data.answerFuel) {
      data.valveCamLeftAngle = data.crankshaftAngle/2;
      data.valveCamLeftAngle = data.valveCamLeftAngle + (1-data.answerFuel) * 90;
    }

    if (data.answerWasteGas) {
      data.valveCamRightAngle = data.crankshaftAngle/2 + 116;
      data.valveCamRightAngle = data.valveCamRightAngle + (-data.answerWasteGas) * 90;
    }

  };

  rotateValveCam = () => {
    const data = this.data;
    const { ice4, ice5, ice6, } = getBoolByLsnCode(this.props.code);

    if (ice4) {
      if (data.fuelInjection) {
        // LEFT
        data.valveCamLeftAngle += 2;

        // RIGHT
        data.valveCamRightAngle -= 2;
      }
    } else {
      this.updateValveCamAngles();
    }
  };

  activateSparkPlug = () => {
    const data = this.data;
    const { ice4, ice5, ice6, } = getBoolByLsnCode(this.props.code);

    if (ice5) {
      data.answerSparkPlug = 3;
    }

    const normalized = data.crankshaftAngle % 720;

    if (data.answerSparkPlug) {
      let sparkAngle = (data.answerSparkPlug - 1) * 180;
      data.sparkPlugActive = sparkAngle < normalized && normalized < sparkAngle + 15;
    }

  };

  checkCorrect = () => {
    const data = this.data;
    const { changeFailureVisible, changeSuccessVisible, failureModalVisible, successModalVisible} = this.props;
    data.uiElements.isCorrect = (data.answerSparkPlug === 3 && data.answerFuel === 1 && data.answerWasteGas === 4)
      || (data.answerSparkPlug === 1 && data.answerFuel === 3 && data.answerWasteGas === 2);
    if ((Math.floor(data.crankshaftAngle / 360) % 15 === 10) && !failureModalVisible && !successModalVisible && !data.uiElements.successShown) {
       if (data.uiElements.isCorrect) {
         changeSuccessVisible(true);
         data.uiElements.successShown=true;
         this.scenarioManager.success();
       } else {
         this.scenarioManager.selectStepByKey('step5');
         changeFailureVisible(true);
       }
    }
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    let timedeltaSec = timedelta / 1000;

    if (timedeltaSec > 0.5) {
      timedeltaSec = 0.1;
    }

    const { ice4, ice5, ice6, } = getBoolByLsnCode(this.props.code);


    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    // todo проблема this.data.physics.internal.consistency вс элементы становятся NaN, но не знаю где они такими становятся
    console.log(JSON.stringify(this.data.physics.internal.consistency, null, 4));
    // Расчет состояния газа из внешних предпосылок
    this.data.physics.internal.pressure = pressureStatic(
      gasAmount(this.data.physics.internal.consistency)||0,
      this.data.physics.internal.temperatureKelvin||0,
      this.data.physics.internal.volume||0
    );

    // Расчет влияния газа на поршень
    // PISTON
    let cachedVolume = this.data.physics.internal.volume;
    // this.updatePistonOffsetY(timedeltaSec);
    this.data.physics.internal.volume = volume(this.pistonOffsetY);
    let newVolume = this.data.physics.internal.volume;

    adiabaticVolumeChange(data.physics, cachedVolume, newVolume);

    data.springHeight = this.springHeight;

    // расчет иных процессов

    this.updateCrankshaftAngle(timedeltaSec);
    data.pistonHeadOffsetY = this.pistonHeadOffsetY;
    data.pistonBodyOffsetY = this.pistonBodyOffsetY;
    data.pistonBodyAngle = this.pistonBodyAngle;

    // VALVE
    inputValveWork(data.valveLeftOffsetY, data.physics, timedeltaSec);
    inputValveWork(data.valveRightOffsetY, data.physics, timedeltaSec, true);

    this.rotateValveCam();
    this.updateValveOffsetY();
    this.activateSparkPlug();

    if (data.sparkPlugActive) {
      burn(data.physics) // changes inplace
    }

    data.cylinderContentsHeight = this.cylinderContentsHeight;

    if (ice5 || ice6) {
      // ENGINE STROKE
      data.engineStroke = this.engineStroke;
    }

    ice6 && this.checkCorrect();

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const timedelta = data.prevTime ? data.startTime - data.prevTime : 0;
    const consistency = data.physics.internal.consistency;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    const { ice4, ice5, ice6, } = getBoolByLsnCode(this.props.code);


    // VALVE
    n['valveLeft'].offsetY(-data.valveLeftOffsetY || 0);
    n['valveCamLeft'].rotation((data.valveCamLeftAngle || 0)-27);

    // PISTON
    n['piston'].y(70 - (data.pistonBodyOffsetY || 0));
    n['pistonBody'].rotation(data.pistonBodyAngle || 0);
    n['crankshaft'].rotation(data.crankshaftAngle || 0);


    // FUEL AND GAS
    n['fuel'].opacity((consistency.petrol || 0)/0.6);
    n['fuel'].height(data.cylinderContentsHeight || 0);
    n['gas'].opacity(consistency.exhaust || 0);
    n['gas'].height(data.cylinderContentsHeight || 0);

    n['intake'].opacity(gasPushed ? 1: 0.6);

    if ((data.physics.internal.temperatureKelvin > 600) && n['exhaust']) {
      n['exhaust'].cache();
      n['exhaust'].filters([Konva.Filters.RGB]);
      n['exhaust'].red(255);
      n['exhaust'].green(0);
      n['exhaust'].blue(0);
      n['exhaust'].opacity((data.physics.internal.temperatureKelvin || 0 - 273) / (3000 - 273) *  (data.valveRightOffsetY || 0)/8);
    }


    // SPARK PLUG
    n['sparkPlug'].opacity(Number(data.sparkPlugActive || 0));

    if (ice5 || ice6) {
      // VALVES
      n['valveRight'].offsetY(-data.valveRightOffsetY || 0);
      n['valveCamRight'].rotation(data.valveCamRightAngle || 0);
    }

    if (n['engineStrokeTEXT']) {
      // Отображение тактов
      n['engineStrokeTEXT'].text(`Такт ${data.engineStroke}`);
    }

    if (n['rpmText']) {
      const rpmVal = Math.abs(this.crankShaftSpeedRpM()) || 0;
      n['indicatorRpmLine'].rotation((rpmVal < 400 ? rpmVal : 400)/1.6)
    }

    if (n['startHint']) {
      n['startHint'].visible(data.uiElements?.startHint);
      n['indicatorRpmContainer'].zIndex(2);

      n['cardHintText'].text(data.cardHintText);
      n['cardHint'].setAttrs(data.cardHintRectStg || {});
      n['answers'].setAttrs(data.answerStg || {});
      n['startHint'].setAttrs(data.cardHintStg  || {});
      n['blur'].setAttrs(data.blurStg || {});
      n['btnsContainer'].setAttrs(data.btnsContainerStg || {});
      n['arrowHint'].setAttrs(data.arrowHintStg || {});
    }

    stageNode.draw();
  }


  onClickEngineStart = () => {
    this.data.crankshaftSpeed += 100;
    this.checkStepForMove();
  };

  onPressGas = () => {
    pushGas(true);
    this.checkStepForMove();
  };

  onReleaseGas = () => {
    pushGas(false);
  };

  crankShaftSpeedRpM = () => {
    return Math.floor(this.data.crankshaftSpeed / 360 * 60);
  };

  checkStepForMove = () => {
    if (this.data.withBlurSwitch) {
      this.scenarioManager.next();
    }
  };

  CanvasICE = () => {
    const data = this.data;

    const { ice4, ice5, ice6, } = getBoolByLsnCode(this.props.code);

    let kolbaImg = kolba2Img;
    const doubleTubeFlask = ice5 || ice6;

    if (doubleTubeFlask) { kolbaImg = kolba3Img; }

    const [valve] = useImage(valveImg);
    const [kolba] = useImage(kolbaImg);
    const [valveCam] = useImage(valveCamImg);
    const [indicator] = useImage(indicatorImg);
    const [pistonHead] = useImage(pistonHeadImg);
    const [pistonBody] = useImage(pistonBodyImg);
    const [sparkPlug] = useImage(sparkPlugImg);
    const [crankshaft] = useImage(crankshaftImg);
    const [fuel] = useImage(fuelImg);
    const [exhaust] = useImage(exhaustImg);
    const [stepsTable] = useImage(stepsTableImg);
    const [answerWasteGas] = useImage(answerWasteGasImg);
    const [answerSparkPlug] = useImage(answerSparkPlugImg);
    const [answerFuel] = useImage(answerFuelImg);
    const [background] = useImage(backgroundImg);
    const [indicatorLine] = useImage(indicatorLineImg);
    const [indicatorRpm] = useImage(indicatorRpmImg);

    const [arrowRight] = useImage(arrowRightImg);
    const [arrowLeft] = useImage(arrowLeftImg);

    return (
      <React.Fragment>
        <Image image={background} visible={ice6}/>
          <Group>
            {
              (ice4 || ice5) && (
                <Indicator
                  externalData={() => ({
                    temperature: this.data.physics.internal.temperatureKelvin - 273,
                    pressure: this.data.physics.internal.pressure,
                    consistency: this.data.physics.internal.consistency,
                  })}
                />
              )
            }
            <Group y={110}>

              {/* FUEL AND GAS */}
              <Group>
                <Group x={150} y={60}>
                  <Rect ref={this._ref('gas')} width={150} height={175} fill={'#c3c3c3'} opacity={0}/>
                  <Rect ref={this._ref('fuel')} width={150} height={175} fill={'#FFEABD'} opacity={0}/>

                  <Explosion
                    getExplosion={() => this.explosion}
                    getRectProps={() => ({width: 150, height: data.cylinderContentsHeight || 0})}
                  />
                </Group>
                <Image ref={this._ref('intake')} image={fuel}/>
                {
                  doubleTubeFlask && ( <Image ref={this._ref('exhaust')} image={exhaust} x={247} y={-1} opacity={0.4}/> )
                }
              </Group>


              {/* VALVE */}
              <Group>
                <Group x={141}>
                  <Image ref={this._ref('valveLeft')} image={valve} x={-4} y={-26} rotation={-23}/>
                  <Image ref={this._ref('valveCamLeft')} image={valveCam} x={3} y={-45.5} offsetY={34/2} offsetX={34/2} rotation={-27}/>
                </Group>

                {
                  doubleTubeFlask && (
                    <Group x={253}>
                      <Image ref={this._ref('valveRight')} image={valve} x={36} y={-41} rotation={23}/>
                      <Image ref={this._ref('valveCamRight')} image={valveCam} x={57} y={-49.5} offsetY={34/2} offsetX={34/2} rotation={116}/>
                    </Group>
                  )
                }
              </Group>


              {/* KOLBA */}
              <Group>
                <Image image={kolba} />
                <Text x={35} y={40} text={_t('ice.fuel_mixture')} stroke={'#71A1BD'} strokeWidth={.8} fontSize={15} align={'right'} fontStyle={'bold'}/>

                <Group visible={ice6 || ice5}>
                  <Text x={325} y={40} text={'Отработанный \nгаз'} stroke={'#71A1BD'} strokeWidth={.8} fontSize={15} align={'left'} fontStyle={'bold'}/>

                  <Text ref={this._ref('engineStrokeTEXT')} text={`Такт ${1}`} fill={'#447B9C'} stroke={'#447B9C'} fontSize={30} x={185} y={-85}/>

                  <Group visible={false}>
                    <Text text={`Оборотов\nв минуту:`} fill={'#447B9C'} fontSize={15} x={140} y={530}/>
                    <Text ref={this._ref('rpmText')}  text={`${this.crankShaftSpeedRpM()}`} fill={'white'} stroke={'white'} fontSize={40} x={250} y={525}/>
                  </Group>
                </Group>
              </Group>


              {/* SPARK PLUG */}
              <Group x={215}>
                <Image image={sparkPlug}/>
                <Line ref={this._ref('sparkPlug')} points={[0, 0, 5, 5, -5, 5, 0, 0]} stroke={'white'} x={13} y={70} opacity={data.sparkPlugActive ? 1 : 0 }/>
              </Group>


              {/* CRANKSHAFT */}
              <Group x={166} y={60}>
                <Image
                  ref={this._ref('crankshaft')}
                  image={crankshaft}
                  x={60} y={310}
                  offset={{x: 108/2, y: 170/2 }}
                  width={108}
                  height={155}
                />

                {/* PISTON */}
                <Group ref={this._ref('piston')}>
                  <Group y={42}>
                    <Group ref={this._ref('pistonBody')} offset={{x: 52/2}}>
                      <Circle x={87} y={198} radius={23} fill={'#808080'}/>
                      <Image image={pistonBody} x={60} y={0} width={52} height={223}/>
                    </Group>
                  </Group>
                  <Image ref={this._ref('pistonHead')} image={pistonHead} />
                </Group>
              </Group>
            </Group>

            {/* TABLE  */}
            {
              ice6 && (
                <Group x={450} y={50}>
                  <Image image={stepsTable}/>

                  {/* DROPS PLACES */}
                  <Group x={195}>
                    <Rect ref={this._ref('dropEngineStroke1')} y={53} width={150} height={85} fill={'transparent'}/>
                    <Rect ref={this._ref('dropEngineStroke2')} y={143} width={150} height={90} fill={'transparent'}/>
                    <Rect ref={this._ref('dropEngineStroke3')} y={237} width={150} height={90} fill={'transparent'}/>
                    <Rect ref={this._ref('dropEngineStroke4')} y={330} width={150} height={85} fill={'transparent'}/>


                    <Rect ref={this._ref('dropEngineForSparkPlug1')} y={30} width={150} height={40} fill={'transparent'}/>
                    <Rect ref={this._ref('dropEngineForSparkPlug2')} y={120} width={150} height={40} fill={'transparent'}/>
                    <Rect ref={this._ref('dropEngineForSparkPlug3')} y={215} width={150} height={40} fill={'transparent'}/>
                    <Rect ref={this._ref('dropEngineForSparkPlug4')} y={310} width={150} height={40} fill={'transparent'}/>
                  </Group>
                </Group>
              )
            }
          </Group>


        {/* ANSWERS */}
        <Group x={820} y={50} visible={ice6} ref={this._ref('answers')}>
          <Image
            draggable
            y={100} rotation={-2}
            image={answerFuel}
            dragBoundFunc={dragBoundFunc}
            onDragEnd={(e) => {
              onDragEnd(e, 'Fuel', this.data, this.getTableNodes, this);
            }}
            onDragMove={(e) => {
              onDragMove(e, 'Fuel', this.getTableNodes, this);
            }}
          />
          <Image
            draggable
            y={300} rotation={-2}
            image={answerWasteGas}
            dragBoundFunc={dragBoundFunc}
            onDragEnd={(e) => {
              onDragEnd(e, 'WasteGas', this.data, this.getTableNodes, this);
            }}
            onDragMove={(e) => {
              onDragMove(e, 'WasteGas', this.getTableNodes, this);
            }}
          />
          <Image
            draggable
            y={220} rotation={2}
            image={answerSparkPlug}
            dragBoundFunc={dragBoundFunc}
            onDragEnd={(e) => {
              onDragEnd(e, 'SparkPlug', this.data, this.getTableNodes, this);
            }}
            onDragMove={(e) => {
              onDragMove(e, 'SparkPlug', this.getTableNodes, this);
            }}
          />
        </Group>


        <Group
          visible={ice6}
          x={30} y={430} ref={this._ref('indicatorRpmContainer')}
        >
          <Image image={indicatorRpm} />
          <Group rotation={-127} offsetY={97} offsetX={13} x={177} y={40}>
            <Image ref={this._ref('indicatorRpmLine')} image={indicatorLine} rotation={0} offsetY={97} offsetX={13}/>
          </Group>
        </Group>


        <Group ref={this._ref('startHint')} visible={data.uiElements.startHint}>
          <Card visible={ice6} rectRef={this._ref('cardHint')} width={250} height={70} x={280} y={500} stroke={'rgba(0,0,0,0.15)'} cornerRadius={5}>
            <Text ref={this._ref('cardHintText')} x={20} y={15} text={'Теперь жми Зажигание, \nа потом Газ'} fill={'#447B9C'} fontSize={17} lineHeight={1.2} fontStyle={'bold'}/>
            <Image ref={this._ref('arrowHint')} x={160} y={80} image={arrowRight} />
          </Card>
        </Group>

        <Rect visible={ice6} onTap={this.checkStepForMove} onClick={this.checkStepForMove} ref={this._ref('blur')} width={1000} height={1000} fill={'white'} opacity={.8}/>
      </React.Fragment>
    )
  };

  CanvasButton = () => {
    const { ice4, ice5, ice6, } = getBoolByLsnCode(this.props.code);

    const [gas] = useImage(gasImg);
    const [ignition] = useImage(ignitionImg);

    const btnSettings = {
      fill: '#447B9C',
      cornerRadius: 5,
      width: 70,
      height: 40,
      fontSize: 16
    };
    const btnTextSettings = {
      stroke: 'white',
      strokeWidth: 1,
      fill: 'white',
      fontSize: 17,
      height: 40,
      align: 'center',
      verticalAlign: 'middle'
    };
    return (
      <Group ref={this._ref('btnsContainer')}>

        {
          ice4 && (
            <Group
              y={500} x={600}
              onTap={() => this.data.fuelInjection = !this.data.fuelInjection}
              onClick={() => this.data.fuelInjection = !this.data.fuelInjection}
            >
              <Rect {...btnSettings} width={150} />
              <Text text={'Впрыск топлива'} {...btnTextSettings} width={150}/>
            </Group>
          )
        }{
          (ice4) && (
            <Group
              y={550} x={600}
              onMouseDown={() => this.data.sparkPlugActive = true}
              onMouseUp={() => this.data.sparkPlugActive = false}
              onTouchStart={() => this.data.sparkPlugActive = true}
              onTouchEnd={() => this.data.sparkPlugActive = false}
            >
              <Rect {...btnSettings} width={150}/>
              <Text text={_t('ice.spark_plug')} {...btnTextSettings} width={150}/>
            </Group>
          )
        }{
        (ice6 || ice5) && (
          <>
            <Group
              zIndex={1}
              x={540} y={520}
              onMouseDown={() => this.onClickEngineStart()}
              onTouchStart={() => this.onClickEngineStart()}
            >
              <Image image={ignition} />
            </Group>

            <Group
              zIndex={1}
              x={750} y={520}
              onMouseDown={() => this.onPressGas()}
              onTouchStart={() => this.onPressGas()}
              onMouseUp={() => this.onReleaseGas()}
              onTouchEnd={() => this.onReleaseGas()}
            >
              <Image image={gas} />
            </Group>
          </>
          )
        }
      </Group>
    )
  };

  render() {
    const { ice4, ice5, ice6, } = getBoolByLsnCode(this.props.code);
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasICE/>
            <this.CanvasButton/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  failureModalVisible: state.commonReducer.failureModalVisible,
  successModalVisible: state.commonReducer.successModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step4);

const styles = {
  mainContainer: {
    background: '#97C1D9',
    // width: '100%',
    // height: '100%',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // position: 'relative',
  },
  btn: {
    background: '#447B9C',
    borderRadius: '7px',
    color: 'white',
    fontSize: '1.2em',
    padding: '7px 14px',
    fontWeight: 'bold',
    width: 'auto',
    margin: '10px 0',
    border:'0',
    cursor: 'pointer'
  },
  buttons: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    bottom: 120,
    right: 150,
  },
  btnEngine: {
    background: '#447B9C',
    borderRadius: '7px',
    color: 'white',
    fontSize: '1.1em',
    padding: '5px 14px',
    width: 150,
    margin: '10px 0',
    border:'0',
    cursor: 'pointer',
    position: 'absolute',
    top: 570,
    right: 200,
    zIndex: 10
  }
};
