import React, {useState} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow, Transformer} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getRandomInt, getTimeDeltaSec} from "../../../../utils/common";
import {defaultPatchData, scenario} from "../scenario";
import airImg from '../../../../images/mountains/air.png'
import mountainsImg from '../../../../images/mountains/mountains.png'
import bgLabImg from '../../../../images/mountains/bgLab.png'
import tableImg from '../../../../images/mountains/table.png'
import labImg from '../../../../images/mountains/lab.png'
import {layout2, mainColor} from "../../../../utils/styles";
import CanvasButton from "../../../canvas/components/CanvasButton";
import MovingArrow from "../../../canvas/components/MovingArrow";
import ArrowHint from "../../../canvas/components/ArrowHint";
import Card from "../../../canvas/components/Card";
import columnImg from "../../../../images/toricelliExperiment/column.png";
import corkImg from "../../../../images/toricelliExperiment/cork.png";
import Victor from "victor";
import {
  dragBoundFunc,
  onDragEnd,
  onDragMove,
  onDragStart,
  transformBoundBoxFunc,
  transformEnd
} from "../utils";
import standImg from "../../../../images/toricelliExperiment/stand.png";
import CanvasTransformer from "../../../canvas/components/CanvasTransformer";
import {LSN_STEPS} from "../../toricelliExperiment/utils";
import CanvasSuccessCard from "../../../canvas/components/CanvasSuccessCard";
import * as actions from "../../../../store/actions";



class Mountains extends React.Component {
  _movingCallbacks = {};

  constructor(props) {
    super(props);

    this.labsItems = [
      {id: 1, height: 4, heightOfMercury: 308, pos: {x: 430, y: 450}, infoReverse: false},
      {id: 2, height: 5, heightOfMercury: 270, pos: {x: 560, y: 390}, infoReverse: false},
      {id: 3, height: 6, heightOfMercury: 236, pos: {x: 320, y: 350}, infoReverse: false},
      {id: 4, height: 7, heightOfMercury: 206, pos: {x: 935, y: 300}, infoReverse: true},
      {id: 5, height: 8, heightOfMercury: 178, pos: {x: 750, y: 245}, infoReverse: false},
    ]

    this.managedComponents = [
      'stage',

      'columnMercuryContainer',
      'columnMercury',
      'cork',
      'transformer',
      'standMercury',
      'bindBoxColumn',
      'placeForColumn',
    ]

    this.initialExperiment = {
      heightStandMercury: 0,
      heightColumnMercury: 309,

      heightAboveSeaVal: 0,
      heightColumnMercuryVal: 0,
      tiktak: 0,

      flaskCoords: Victor(900, 254),
      flaskRotation: 0,

      corkCoords: Victor(-1, 0),
      corkAngle: 0,
      corkSpeedVector: Victor(-100 + Math.random() * 220, -130 + -Math.random() * 300),
      corkRotationSpeed: 50 + Math.random() * 280,
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      prevStepHelper: '',

      successLabId: undefined,

      ...cloneDeep(this.initialExperiment),
      ...defaultPatchData
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();

  _getNode = (key) => this[`${key}Ref`]?.current;

  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }


  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.scenarioManager.resetScenario();

    this.getRandomSuccessLab();
  }

  getRandomSuccessLab = () => {
    const randLabIndex = getRandomInt(this.labsItems.length);
    const randLab = this.labsItems[randLabIndex];
    if (randLab) {
      this.data.successLabId = randLab.id;
      // console.log('debug random lab', randLabIndex, this.data.successLabId);
    }
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.getRandomSuccessLab();
    this.scenarioManager.selectStepByKey('step 1');
  };
  resetExperiment = () => {
    this.data = {
      ...this.data,
      ...cloneDeep(this.initialExperiment)
  };
    this.updateStage();
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);

    if (data.corkFlew) {
      data.gasAmount = 0;
      let corkCoords = data.corkCoords
        .add(Victor(data.corkSpeedVector.x, data.corkSpeedVector.y)
        .multiply(Victor(data.timedeltaSec, data.timedeltaSec)));
      let corkSpeedVector = data.corkSpeedVector
        .add(Victor(0, 300)
        .multiply(Victor(data.timedeltaSec, data.timedeltaSec))); // gravity acceleration
      data.corkAngle += data.corkRotationSpeed * data.timedeltaSec;
    }


    if (data.toricelliStep === 'animation' && data.flaskCoords.y <= 330){
      data.flaskCoords.y += 2;
    }


    if (data.tiktak < 25 && data.flaskCoords.y >= 330) {
      data.tiktak = data.tiktak + 1;
      data.heightStandMercury = data.heightStandMercury + 1;
      data.heightColumnMercury = data.heightColumnMercury - 3.2;
    }
    if (data.tiktak >= 25) {
      this.scenarioManager.success('experiment animation');
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    if (data.toricelliStep === 'rotation') {
      n['transformer'].nodes([n['columnMercuryContainer']]);
      n['transformer'].getLayer().batchDraw();
    }

    n['columnMercury'].height(data.heightColumnMercury*.8 + 62);
    n['columnMercury'].rotation(0);
    n['standMercury'].height(data.heightStandMercury);

    n['cork'].setAttrs({
      position: {x: data.corkCoords.x, y: -data.corkCoords.y},
      rotation: data.corkAngle
    })

    n['columnMercuryContainer'].position(data.flaskCoords);

    n['stage'].draw();
  }



  selectLab = (labId) => {
    if (this.data.withSelectLab) {
      if (labId === this.data.successLabId) {
        this.scenarioManager.selectStepByKey('success animation');
      } else {
        this.scenarioManager.selectStepByKey('fail');
      }
    }
  }

  TablePopup = (props) => {

    const [table] = useImage(tableImg);
    const onClick = () => {
      this.scenarioManager.selectStepByKey(this.data.prevStepHelper || 'step 1');
    }
    return (
      <Group x={350} y={140} {...props}>
        <Image image={table} width={270} height={421}/>
        <Text
          x={220} y={25}
          text={'×'}
          fontSize={30}
          fill={'black'}
          stroke={'black'}
          onCLick={onClick}
          onTap={onClick}
          onMouseEnter={e => {
            const container = e.target.getStage().container();
            container.style.cursor = "pointer";
          }}
          onMouseLeave={e => {
            const container = e.target.getStage().container();
            container.style.cursor = "default";
          }}
        />
      </Group>
    )
  };
  Background = () => {
    const [air] = useImage(airImg);
    const [mountains] = useImage(mountainsImg);
    return (
      <React.Fragment>
        <Image image={air}/>
        <Image image={mountains} y={265}/>
      </React.Fragment>
    )
  };

  Experiment = (props) => {
    const data = this.data;
    const [bgLab] = useImage(bgLabImg);
    const [column] = useImage(columnImg);
    const [cork] = useImage(corkImg);
    const [stand] = useImage(standImg);

    const Flask = () => {
      return (
        <Group>
          <Group>
              <Group
                offsetX={4} offsetY={155}
                height={313} width={9}
                ref={this._ref('columnMercuryContainer')}
                draggable={data.toricelliStep === 'initial'}
                dragBoundFunc={(pos) => dragBoundFunc(pos, this)}
                onDragEnd={(e) => onDragEnd(e, this)}
                onDragStart={(e) => onDragStart(e, this)}
                onDragMove={(e) => {
                  const pos = e.target.getAbsolutePosition();
                  data.flaskCoords = Victor(pos.x, pos.y);
                }}
                rotation={data.flaskRotation}
              >
                <Rect width={23} height={350} fill={'black'} x={-7} y={-20} opacity={0}/>
                <Rect
                  ref={this._ref('columnMercury')}
                  stroke={'#FA6F52'} fill={'#FA6F52'}
                  width={3} height={280}
                  rotation={180}
                  x={3} y={3}
                />
                <Image image={column} />
                <Image image={cork} width={11} height={8} ref={this._ref('cork')}
                       x={data.corkCoords?.x} y={data.corkCoords?.y}
                       rotation={data.corkAngle}/>
              </Group>
          </Group>
          <Transformer
            ref={this._ref('transformer')}
            resizeEnabled={false}
            // onTransform={(e) => console.log(e)}
            onTransformEnd={(e) => transformEnd(e, this)}
            boundBoxFunc={transformBoundBoxFunc}
          />

        </Group>
      )
    }

    const successLab = this.labsItems.find((el) => el.id === data.successLabId);
    return (
      <Group {...props}>
        <Rect width={1000} height={562} fill={'#B6D2D2'}/>
        <Image image={bgLab} x={380} y={120}/>


        <Group x={-150} y={20}>
          <Group x={370} y={80}>
            <Rect x={-27} y={-100} width={60} height={600} stroke={'transparent'} fill={'transparent'} ref={this._ref('bindBoxColumn')}/>
            <Rect width={8} height={315} fill={'#DCDCDC'} stroke={'#DCDCDC'} ref={this._ref('placeForColumn')} visible={data.placeForFlaskVisible}/>
          </Group>


          <ArrowHint visible={data.dragHintVisible} x={400} y={315} text={'Перемести пробирку сюда'}/>

          <Group visible={data.rotationHintVisible}>
            <ArrowHint x={390} y={60} text={'Переверни пробирку'}/>
            <ArrowHint x={360} y={410} text={' '} rotation={180}/>
          </Group>
        </Group>

        <Group x={100} y={440}>
          <Image image={stand}/>
          <Rect ref={this._ref('standMercury')} width={132} height={42} fill={'#FA6F52'} rotation={180} x={193} y={45}/>
        </Group>


        <Card width={170} height={450} x={810} y={20} visible={data.cardFlaskVisible}>
          <Text text={'Пробирка \nс веществом:'} x={40} y={30} fontSize={14} fill={'black'}/>
        </Card>

        <Flask />


        <Group x={100} y={350} visible={data.heightIndicatorVisible}>
          <Line y={-112} points={[0,0,127,0]} dash={[3, 3]} stroke={'black'} strokeWidth={.5}/>
          <Arrow  points={[0,-20,0,-112]} stroke={'black'} fill={'black'}/>

          <Text x={-7} y={-16} text={'h'} fontSize={30}/>

          <Arrow points={[0,20,0,110]}  stroke={'black'} fill={'black'}/>
          <Line y={110} points={[0,0,127,0]} dash={[3, 3]} stroke={'black'} strokeWidth={.5}/>
        </Group>

        <Card width={170} height={250} x={400} y={250} visible={data.cardInfoVisible}>
          <Group x={25} y={30}>
            <Text text={'h, мм'} fontSize={20} fill={'black'}/>
            <Text y={35} text={successLab?.heightOfMercury || 0} fontSize={40} fill={'black'}/>
          </Group>
          <Group x={25} y={130}>
            <Text text={'ρ,'} fontSize={20} fill={'black'}/>
            <Group x={23}>
              <Text x={2} y={-10} text={'кг'} fontSize={20} fill={'black'}/>
              <Text text={'—'} fontSize={25} fill={'black'}/>
              <Text x={2} y={15} text={'м'} fontSize={20} fill={'black'}/>
              <Text x={20} y={15} text={'3'} fontSize={12} fill={'black'}/>
            </Group>
            <Text y={40} text={'20 000'} fontSize={40} fill={'black'}/>
          </Group>
        </Card>


      </Group>
    )
  };

  Labs = () => {

    const [lab] = useImage(labImg);
    const Lab = (props) => {
      const [hover, setHover] = useState(false);
      const reverse = props.infoReverse;
      let cardStg = {x:hover ? 70 : 30, y:hover ? 35 : 2};
      if (reverse) {
        cardStg = {...cardStg, x:hover ? -130 : -120};
      }
      const onHover = (status) => {
        if (this.data.withSelectLab) {
          setHover(status);
        }
      }
      return (
        <Group
          {...props.pos}
          onMouseEnter={() => onHover(true)}
          onMouseLeave={() => onHover(false)}
          onClick={() => this.selectLab(props.id)}
          onTap={() => this.selectLab(props.id)}
          offset={{x: hover ? 15 : 0, y: hover ? 25 : 0}}
        >
          <Rect x={hover ? -30 : -10} y={-10} width={hover ?  210 : 150} height={hover ? 110 : 50} fill={'red'} opacity={0}/>
          <Circle x={hover ? 23 : 8} y={hover ? 50 : 18} radius={hover ? 39 : 13.5} fill={'white'} stroke={'#FA6F52'} strokeWidth={1}/>
          <Image image={lab} {...hover ? {width: 50,height: 84} : {width: 17,height: 29}}/>

          <Group {...cardStg}>
            <Rect
              width={105}
              height={30}
              fill={'white'}
              shadowBlur={10}
              shadowColor={'rgba(0,0,0,0.5)'}
              shadowOffset={{x: 2, y:2}}
            />
            <Arrow x={reverse ? 65 : 40} y={15} points={[0,0,30,0]} fill={'black'} stroke={'black'} scale={{x: reverse ? 1 : -1, y: 1}}/>
            <Text x={reverse ? 10 : 48} y={3} text={`${props.height} км`} fill={'black'} fontSize={25}/>
          </Group>
        </Group>
      )
    }

    return (
      <Group>
        {
          this.labsItems.map((item, i) => (
            <Lab key={'lab'+i} {...item}/>
          ))
        }
      </Group>
    )
  };


  Env = () => {
    const data = this.data;

    return (
      <React.Fragment>
        <Text x={50} y={30} fontSize={30} lineHeight={1.3} text={data.title} fill={data.titleFill}/>
        <Group x={40} y={440}>
          <Group>
            <MovingArrow
              id={'hint1'}
              stageNode={this.stageRef?.current}
              length={40}
              arrowsCount={1}
              x={120} y={-120}
              arrowColor={'#0083D2'}
              text={''}
              scale={{x: 2, y: 2}}
              visible={data.experimentArrowHintVisible}
              getMovingCallback={this.getMovingCallback}
            />
            <CanvasButton
              text={'Провести эксперимент'}
              onClick={() => {
                this.resetExperiment();
                this.scenarioManager.selectStepByKey('experiment initial')
              }}
              fontSize={18}
              strokeWidth={.1}
              btnCornerRadius={0}
              btnFill={'#0083D2'}
              textFill={'white'}
              visible={data.experimentBtnVisible}
              width={230}
              height={40}
            />
          </Group>
          <Group>
            <MovingArrow
              id={'hint2'}
              stageNode={this.stageRef?.current}
              length={40}
              arrowsCount={1}
              x={320} y={70}
              arrowColor={'#0083D2'}
              text={''}
              rotation={90}
              scale={{x: 2, y: 2}}
              visible={data.tableBtnArrowHintVisible}
              getMovingCallback={this.getMovingCallback}
            />
            <CanvasButton
              text={'Таблица давлений'}
              onClick={() => {
                this.data.prevStepHelper = this.scenarioManager.getStepData().key;
                this.scenarioManager.selectStepByKey('table popup')
              }}
              fontSize={18}
              strokeWidth={.1}
              btnCornerRadius={0}
              btnFill={'#0083D2'}
              textFill={'white'}
              visible={data.tableBtnVisible}
              width={200}
              height={40}
              y={50}
            />
          </Group>

          <Group x={700} y={0}>
            <CanvasButton
              text={'Посмотреть теорию'}
              onClick={() => {
                this.data.prevStepHelper = 'experiment complete';
                this.scenarioManager.selectStepByKey('theory');
              }}
              fontSize={18}
              strokeWidth={.1}
              btnCornerRadius={0}
              btnFill={'#0083D2'}
              textFill={'white'}
              visible={data.checkTheoryBtnVisible}
              width={200}
              height={40}
            />
            <CanvasButton
              text={'Вернуться к карте'}
              onClick={() => {
                this.data.prevStepHelper = 'select lab';
                this.scenarioManager.selectStepByKey('table popup');
              }}
              fontSize={18}
              strokeWidth={.1}
              btnCornerRadius={0}
              btnFill={'#0083D2'}
              textFill={'white'}
              visible={data.returnToMapBtnVisible}
              width={200}
              height={40}
              y={60}
            />
          </Group>
        </Group>

        <CanvasSuccessCard
          title={data.endPopUpTitle}
          visible={data.endPopUpVisible}
          success={data.endPopUpSuccess}
          goToTheory={() => {this.scenarioManager.selectStepByKey('theory')}}
          onClickReset={() => this.onClickReset()}
          btnTopBg={'#0083D2'}
          btnTopTxtColor={'white'}
          btnBottomBg={'#0083D2'}
          btnBottomTxtColor={'white'}
        />
      </React.Fragment>
    )
  };

  render() {
    const data = this.data;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this._ref('stage')}
          lessonCode={this.props.code}

          component={this}
          withVideo={true}
          videoVisible={data.videoSrc}
          theoryVisible={this.data.theoryVisible}
          additionalTheoryStgs={{
            skipInitialStep: true,
            hideResetBtn: true,
            bgColor: 'white',
          }}
        >
          <Layer preventDefault={false}>
            <this.Background/>
            <this.Labs/>
            <this.Experiment visible={data.experiment}/>
            <this.Env/>
            <this.TablePopup visible={data.tablePopupVisible}/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Mountains);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
