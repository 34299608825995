import React from "react";
import Konva from "konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Layer, KonvaImage, Image, Group, Text, Rect} from "react-konva";
import Victor from "victor";
import useImage from "use-image";
import completeImage from "../../../../images/pathandspeed/complete.png";
import chartImage from "../../../../images/pathandspeed/chart.png";
import roadImage from "../../../../images/pathandspeed/road.png";
import biker1Image from "../../../../images/pathandspeed/biker1.png";
import biker2Image from "../../../../images/pathandspeed/biker2.png";
import cloneDeep from "lodash.clonedeep";
import Road from "../components/Road";
import SidePage from "../components/SidePage";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {layout2, mainColor} from "../../../../utils/styles";
import StepsIndicator from "../../../canvas/components/StepsIndicator";
import CanvasInput from "../../../canvas/components/CanvasInput";
import Card from "../../../canvas/components/Card";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {
  onDragBoundFunc,
  onDragEnd,
  onDragMove,
  onDragStart, setInitialPosLinePos
} from "../utils/utils";
import ArrowHint from "../../../canvas/components/ArrowHint";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import {sendSuccessForScenario, showFailOrSuccessPopup, toggleVisibleEl} from "../../../../utils/common";
import CanvasChartImg from "../../../canvas/components/CanvasChartImg";
import {getChartImgSetting} from "../utils/utils";
import ScenarioManager from "../../../../utils/ScenarioManager";



class Pathandspeed extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();

    this.startBtnRef = React.createRef();
    this.arrowHintRef = React.createRef();

    this.road1Ref = React.createRef();
    this.road2Ref = React.createRef();

    this.bikerARef = React.createRef();
    this.bikerBRef = React.createRef();

    this.bikerABtnRef = React.createRef();
    this.bikerBBtnRef = React.createRef();
    this.bikerABtnTxtRef = React.createRef();
    this.bikerBBtnTxtRef = React.createRef();

    this.state = {
      step: 0
    };

    this.staticData = {
      stepsCount: 5,

      bikerASpeedInitial: 600, // м/мин

      bikerBSpeedInitial: 300, // м/мин
      bikerBSpeedSecond: 900, // м/мин

      bikerBPathPointForSpedUp: 600, // м

      endPath: 2400, // м

      maxRoadOffset: 396,
      roadSpeed: 6
    }
    this.itemsRowArr = Array(5).fill(1);
    this.itemsColumnArr = Array(4).fill(1);
    this.initialData = {
      bikerLabelA: 'Мотоциклист А',
      bikerLabelB: 'Мотоциклист Б',

      startTime: undefined,
      prevTime: undefined,

      currentTimeSec: 0,

      start: false,

      road1Offset: 0,
      road2Offset: 0,

      inputVal: undefined,
      bikerBtnVal: undefined,

      bikerAPath: 0, // м
      bikerBPath: 0, // м
      bikerASpeed: this.staticData.bikerASpeedInitial, // м/мин
      bikerBSpeed: this.staticData.bikerBSpeedInitial, // м/мин

      dataset: [{x: 0, y: 0}, {x: 0, y: 0}],

      itemsRowArr: this.itemsRowArr,
      itemsColumnArr: this.itemsColumnArr,

      selectedData: this.itemsColumnArr.reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),

      chartImgSetting: getChartImgSetting(this.props.code, this.itemsRowArr),

      isLineDragged: false,
    };
    this.data = cloneDeep(this.initialData);

    this.data.itemsColumnArr.forEach((el, columnI) => {
      this[`dragLine${columnI}Ref`] = React.createRef();
      this[`dragLineContainer${columnI}Ref`] = React.createRef();

      this.data.itemsRowArr.forEach((el, rowI) => {
        this[`actionBox${rowI}${columnI}Ref`] = React.createRef();
      });
    })
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _resetChartLinesCallback = () => {};
  getResetChartLinesCallback = (callback) => {
    this._resetChartLinesCallback = callback
  };
  _resetBikerChartCallback = () => {};
  getResetBikerChartCallback = (callback) => {
    this._resetBikerChartCallback = callback
  };

  onClickStart = () => {
    this.data.start = true;
  }
  onClickReset = () => {
    const endStep = this.state.step > this.staticData.stepsCount;

    this.data = cloneDeep(this.initialData);
    this.updateStage();

    if ([4,5].includes(this.state.step)) {
      setInitialPosLinePos(this);
      this._resetChartLinesCallback();
    }
    if (!this.state.step || endStep) {
      this._resetBikerChartCallback();
    }
    this.setState((prev) => ({ step: endStep ? 0 : prev.step }))
  }


  get roadOffset() {
    const {roadSpeed, maxRoadOffset} = this.staticData;
    const newOffset = this.data.road1Offset + roadSpeed;
    return newOffset >= maxRoadOffset || !this.data.start ? 0 : newOffset;
  }

  get dataset() {
    const data = this.data;
    const currentTimeSec = data.currentTimeSec;
    const bikerAPath = data.bikerAPath;
    const bikerBPath = data.bikerBPath;
    return [{x: currentTimeSec, y: bikerAPath}, {x: currentTimeSec, y: bikerBPath}];
  }
  get bikerAPath() {
    const data = this.data;
    const bikerASpeed = data.bikerASpeed;
    return bikerASpeed * data.currentTimeSec;
  }
  get bikerBSpeed() {
    const {bikerBSpeedInitial, bikerBSpeedSecond, bikerBPathPointForSpedUp} = this.staticData;
    const data = this.data;
    let correctSpeed = bikerBSpeedInitial
    if (data.bikerBPath > bikerBPathPointForSpedUp) {
      correctSpeed = bikerBSpeedSecond;
    }
    return correctSpeed;
  }
  get bikerBPath() {
    const {bikerBPathPointForSpedUp} = this.staticData;
    const data = this.data;
    let res = data.bikerBSpeed * data.currentTimeSec;
    if (data.bikerBPath > bikerBPathPointForSpedUp) {
      res -= 1200;
    }
    return res;
  }

  getNodes = () => {
    const dragLineNodes = {};
    const actionBoxNodes = {};
    this.data.itemsColumnArr.forEach((el, cellI) => {
      dragLineNodes[`dragLine${cellI}Node`] = this[`dragLine${cellI}Ref`]?.current;
      dragLineNodes[`dragLineContainer${cellI}Node`] = this[`dragLineContainer${cellI}Ref`]?.current;

      this.data.itemsRowArr.forEach((el, rowI) => {
        actionBoxNodes[`actionBox${rowI}${cellI}Node`] = this[`actionBox${rowI}${cellI}Ref`]?.current;
      });
    })
    return ({
      ...dragLineNodes,
      ...actionBoxNodes,

      startBtnNode: this.startBtnRef?.current,

      road1Node: this.road1Ref?.current,
      road2Node: this.road2Ref?.current,

      bikerANode: this.bikerARef?.current,
      bikerBNode: this.bikerBRef?.current,

      bikerABtnNode: this.bikerABtnRef?.current,
      bikerBBtnNode: this.bikerBBtnRef?.current,
      bikerABtnTxtNode: this.bikerABtnTxtRef?.current,
      bikerBBtnTxtNode: this.bikerBBtnTxtRef?.current,

      arrowHintNode: this.arrowHintRef?.current,
    })
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  move = (time) => {

    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    let timedeltaSec = 0;

    if (data.bikerAPath >= this.staticData.endPath) {
      data.start = false;
    }

    if (data.start) {
      data.startTime = data.startTime || time;
      const timedelta = data.prevTime ? time - data.prevTime : 0;
      data.prevTime = time;
      timedeltaSec = timedelta / 1000;

      data.currentTimeSec = data.startTime ? (time - data.startTime)/3000 : 0;

      data.bikerBSpeed = this.bikerBSpeed;

      data.bikerAPath = this.bikerAPath;
      data.bikerBPath = this.bikerBPath;
      data.dataset = this.dataset;

      data.road1Offset = this.roadOffset;
      data.road2Offset = this.roadOffset;
    }

    this.updateStage();

    if ((!this.state.step) && (data.bikerAPath >= this.staticData.endPath)) {
      this.setState({step: 1});
    }
  };

  updateStage() {
    const data = this.data;
    const staticData = this.staticData;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const {
      startBtnNode,
      road1Node,
      road2Node,
      bikerANode,
      bikerBNode,
      bikerABtnNode,
      bikerBBtnNode,
      bikerABtnTxtNode,
      bikerBBtnTxtNode,
      arrowHintNode,
    } = this.getNodes();

    road1Node && road1Node.offsetX(data.road1Offset);
    road2Node && road2Node.offsetX(data.road2Offset);

    const isEnd = data.bikerAPath >= this.staticData.endPath;

    bikerANode && bikerANode.offsetX(-data.bikerAPath/4.8);
    bikerBNode && bikerBNode.offsetX(-data.bikerBPath/4.8);

    toggleVisibleEl(startBtnNode, data.start || isEnd);

    bikerABtnNode && bikerABtnNode.fill(data.bikerBtnVal === 'A' ? mainColor : 'white');
    bikerBBtnNode && bikerBBtnNode.fill(data.bikerBtnVal === 'B' ? mainColor : 'white');
    bikerABtnTxtNode && bikerABtnTxtNode.fill(data.bikerBtnVal === 'A' ? 'white' : layout2.blue);
    bikerBBtnTxtNode && bikerBBtnTxtNode.fill(data.bikerBtnVal === 'B' ? 'white' : layout2.blue);

    toggleVisibleEl(arrowHintNode, data.isLineDragged);

    stageNode.draw();
  }

  stepController = () => {
    const {step} = this.state;
    const data = this.data;
    const {changeSuccessVisible, changeFailureVisible} = this.props;

    let btnVal = data.bikerBtnVal;
    let val = Number(data.inputVal);
    let success = true;

    const selectedData = data.selectedData;
    switch (step) {
      case 1: {
        success = val === 1200;
        break;
      }
      case 2: {
        success = val === 2400;
        break;
      }
      case 3: {
        success = btnVal === 'B';
        break;
      }
      case 4: {
        success = Object.keys(selectedData).every((k) => selectedData[k] === 10);
        break;
      }
      case 5: {
        success = (
          ['1','2'].every((k) => selectedData[`line${k}`] === 5) &&
          ['3','4'].every((k) => selectedData[`line${k}`] === 15)
        );
        if (success) {
          sendSuccessForScenario(this);
        }
        break;
      }
    }


    if (success) {
      if (step <= this.staticData.stepsCount) {
        this.onClickReset();
        this.setState({step: step+1});
      }
    }
    if (!success || (success && (step < this.staticData.stepsCount))) {
      showFailOrSuccessPopup(this, success);
    }
  }


  Scene = () => {
    const data = this.data;
    const {step} = this.state;

    const [complete] = useImage(completeImage);
    const [chartImg] = useImage(chartImage);

    const [biker1] = useImage(biker1Image);
    const [biker2] = useImage(biker2Image);


    const topRoad = 150;
    const bottomRoad = 350;
    const textSetting = {
      fill: '#6e6e6e', stroke: '#6e6e6e',
      strokeWidth: .2, fontSize: 27, lineHeight: 1.3
    };
    const titleSetting = { stroke: layout2.blue, fill: layout2.blue, strokeWidth: .2, fontSize: 27, lineHeight: 1.3};
    const labelSetting = { stroke: layout2.blue, fill: layout2.blue, strokeWidth: .2, fontSize: 15, lineHeight: 1.3};

    const btnTextSetting = {
      ...labelSetting,
      x:0,
      width:150,
      height:100,
      align:'center',
      verticalAlign:'middle',
      fontStyle:'bold',
      fontSize:35,
    };

    let checkSettings = {
      fontSize: 18,
      fontStyle: 'bold',
      strokeWidth: .2,
      btnCornerRadius: 0,
      width: 200,
      x: 160,
      y: 380,
    };

    let questionTxt = '';
    if (step === 1) {
      questionTxt = 'Каков путь, пройденный \nмотоциклистом А за 2 минуты?';
    } else if (step === 2) {
      questionTxt = 'Каков путь, пройденный \nмотоциклистом Б за 4 минуты?';
    } else if (step === 3) {
      questionTxt = 'Кто из мотоциклистов ехал быстрее \nв период с 3 по 4 минуту?';
    } else if (step === 4) {
      checkSettings = {...checkSettings, y: 480}
      questionTxt = 'Отобрази график \nскорости мотоциклиста А';
    } else if (step === 5) {
      checkSettings = {...checkSettings, y: 480}
      questionTxt = 'Отобрази график \nскорости мотоциклиста Б';
    }

    return (
      <React.Fragment>
        <Group>
          <Text x={20} y={topRoad-30} text={this.data.bikerLabelA} {...textSetting} fontStyle={'bold'} fontSize={20} />
          <Group ref={this.road1Ref}>
            <Road
              id={1}
              y={topRoad}
              roadItemsCount={8}
            />
          </Group>
          <Text x={20} y={bottomRoad-30} text={this.data.bikerLabelB} {...textSetting} fontStyle={'bold'} fontSize={20} />
          <Group ref={this.road2Ref}>
            <Road
              id={2}
              y={bottomRoad}
              roadItemsCount={8}
            />
          </Group>
        </Group>


        <Image ref={this.bikerARef} x={0} y={topRoad + 35} image={biker1}/>
        <Image ref={this.bikerBRef} x={0} y={bottomRoad + 35} image={biker2}/>

        <Group ref={this.startBtnRef}>
          <CanvasButton
            text={'Старт'}
            onClick={() => this.onClickStart()}
            fontSize={20}
            strokeWidth={.2}
            btnCornerRadius={0}
            height={40}
            x={200}
            y={500}
          />
        </Group>


        <Group x={step > 0 ? 480 : 550}>
          <Rect
            fill={"#EFEBE4"}
            width={530}
            height={562}
            y={0}
          />
          {
            step > 0 && (
              <Text
                x={60} y={80}
                text={questionTxt}
                {...titleSetting}
              />
            )
          }
          {/* ------- INPUTS -------  */}
          {
            (step === 1 || step === 2) && (
              <Group>

                <Card x={180} y={230} height={100} width={160}>
                  <Group x={15} y={25}>
                    <Text x={0} y={-10} text={`Путь, м:`} {...labelSetting}/>
                    <CanvasInput
                      id={'1'}
                      y={15}
                      x={0}
                      width={100}
                      height={30}
                      stage={this.stageRef?.current}
                      textColor={layout2.blue}
                      onInput={(val) => {
                        this.data.inputVal = val || val === '0' ? val : null;
                      }}
                      value={this.data.inputVal}
                    />
                  </Group>
                </Card>
              </Group>
            )
          }
          {/* ------- BUTTONS -------  */}
          {
            step === 3 && (
              <Group x={100} y={200}>
                <Group
                  onClick={() => this.data.bikerBtnVal = 'A'}
                >
                  <Rect
                    ref={this.bikerABtnRef}
                    width={150}
                    height={100}
                    fill={this.data.bikerBtnVal === 'A' ? 'black' : 'white'}
                    strokeWidth={1.5}
                    stroke={'#3d405b'}
                  />
                  <Text ref={this.bikerABtnTxtRef} text={`А`} {...btnTextSetting}/>
                </Group>
                <Group x={150}
                  onClick={() => this.data.bikerBtnVal = 'B'}
                >
                  <Rect
                    ref={this.bikerBBtnRef}
                    width={150}
                    height={100}
                    fill={this.data.bikerBtnVal === 'B' ? 'black' : 'white'}
                    strokeWidth={1.5}
                    stroke={'#3d405b'}
                  />
                  <Text ref={this.bikerBBtnTxtRef} text={`Б`} {...btnTextSetting}/>
                </Group>
              </Group>
            )
          }
          {/* ------- CHARTS IMGS -------  */}
          {
            (step === 4 || step === 5) && (
              <Group x={80} y={140}>
                <Image image={chartImg}/>

                <CanvasChartImg
                  $this={this}
                  getResetCallback={this.getResetChartLinesCallback}
                  onDragStart={() => this.data.isLineDragged = true}
                />
              </Group>
            )
          }{
            (step > this.staticData.stepsCount) && (
              <Image x={30} y={100} width={480} image={complete}/>
            )
          }{
            (step <= this.staticData.stepsCount) && (
              <CanvasButton
                text={'Проверить'}
                onClick={() => this.stepController()}
                {...checkSettings}
              />
            )
          }
        </Group>

        <CanvasResetBtn
          onClick={() => this.onClickReset()}
          x={step ? 850 : 400}
          y={510}
        />
        {
          (step <= this.staticData.stepsCount) && (
            <>
              <StepsIndicator
                x={770} y={40}
                items={Array(this.staticData.stepsCount).fill(1)}
                activeVal={this.state.step}
              />
            </>
          )
        }
      </React.Fragment>
    );
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
          <Layer>
            <this.Scene />
          </Layer>
        </CanvasContainer>

        <SidePage $this={this} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pathandspeed);

const styles = {
  mainContainer: {
    height: "auto",
    background: "#EFEBE4",
  },
};
