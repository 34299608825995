import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ArrangeByColumns from "./ArrangeByColumns";
import task4Flower1 from "../images/arrangeByColumns/task4Flower1.png";
import task4Flower2 from "../images/arrangeByColumns/task4Flower2.png";
import task4Flower3 from "../images/arrangeByColumns/task4Flower3.png";
import task4Flower4 from "../images/arrangeByColumns/task4Flower4.png";
import ContinueRowTask from "./ContinueRowTask";
import bg from "../images/space-bg-2.png";


const Example8Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'Соедини цветок и страну (регион)',
      columnsItems: [
        {id: 1, successAnswerId: 3, title: "Шотландия"},
        {id: 2, successAnswerId: 4, title: "Англия"},
        {id: 3, successAnswerId: 2, title: "Италия"},
        {id: 4, successAnswerId: 1, title: "Уэльс"},
      ],
      dragItems: [
        {id: 1, mainText: 'Нарцисс'},
        {id: 2, mainText: 'Лилия'},
        {id: 3, mainText: 'Чертополох'},
        {id: 4, mainText: 'Роза'},
      ],
    },{
      title: 'Соедини пары',
      columnsItems: [
        {id: 1, successAnswerId: 4, title: "Тритон"},
        {id: 2, successAnswerId: 3, title: "Равнина снегурочки"},
        {id: 3, successAnswerId: 1, title: "Долина Маринер"},
        {id: 4, successAnswerId: 2, title: "Ганимед"},
      ],
      dragItems: [
        {id: 1, mainText: 'Марс'},
        {id: 2, mainText: 'Юпитер'},
        {id: 3, mainText: 'Венера'},
        {id: 4, mainText: 'Нептун'},
      ],
    },{
      title: 'Соедини пары',
      columnsItems: [
        {id: 1, successAnswerId: 2, title: "Каньон Бабы-Яги"},
        {id: 2, successAnswerId: 1, title: "Европа"},
        {id: 3, successAnswerId: 4, title: "Титан"},
        {id: 4, successAnswerId: 3, title: "Гора Олимп"},
      ],
      dragItems: [
        {id: 1, mainText: 'Юпитер'},
        {id: 2, mainText: 'Венера'},
        {id: 3, mainText: 'Марс'},
        {id: 4, mainText: 'Сатурн'},
      ],
    },
    {
      title: 'Соедини пары',
      columnsItems: [
        {id: 1, successAnswerId: 4, title: "Англия"},
        {id: 2, successAnswerId: 3, title: "Шотландия"},
        {id: 3, successAnswerId: 1, title: "Уэльс"},
        {id: 4, successAnswerId: 2, title: "Северная Ирландия"},
      ],
      dragItems: [
        {id: 1, image: task4Flower3},
        {id: 2, image: task4Flower4},
        {id: 3, image: task4Flower2},
        {id: 4, image: task4Flower1},
      ],
    },
  ];

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={4}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      {
        chooseTasks.map((item, i) => (
          <React.Fragment key={`step${i}`}>
            {
              (completedTaskCount === (i) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                <ArrangeByColumns
                  lang={'ru'}
                  bgImg={bg}
                  title={item.title}
                  columns={item.columnsItems}
                  dragItems={item.dragItems}
                  taskComplete={() => {
                    setCompletedTaskCount(prevState => prevState + 1);
                    if ((i + 1) === chooseTasks.length) {
                      setAllComplete(true);
                    }
                  }}
                />
              ) : null
            }
          </React.Fragment>
        ))
      }
    </TaskMainContainer>
  )
}
export default Example8Task;
