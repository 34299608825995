import React from "react";
import '../styles/healthModal.css';
import heartImg from '../images/heart.png';
import heartEmptyImg from '../images/heartEmpty.png';
import CustomButton from "./CustomButton";
import {Col, Row} from "antd";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../../utils/custom-hooks/useQuery";



const BackToHwModal = (props) => {

    const history = useHistory();
  const withback = useQuery().get('withback');
  const backUrl = useQuery().get('back');

  return (
    <div className={`health-modal__backdrop ${props.visible ? 'health-modal__backdrop--visible' : ''}`}>
      <div className={'health-modal'}>
        {
            // <CustomButton
            //   className={'health-modal__button'}
            //   onClick={(e) => {
            //       //todo: дополнить правильной ссылкой
            //       (props.isTeacher ? window.location.href='http://localhost:3000/homework' : window.location.href='http://localhost:3000/student-homework');}}
            //   btnText={props.lang === 'ru' ? 'Вернуться к заданиям' : 'Back to all tasks'}
            // />

            <CustomButton
              className={'health-modal__button'}
              onClick={() => {
                let key = '/';
                if (backUrl) {
                  key = `/${backUrl}`;
                }
                history.push(key)
              }}
              btnText={props.lang === 'ru' ? 'Вернуться к списку заданий' : 'Back to the list of tasks'}
            />

        }
      </div>
    </div>
  )
}
export default BackToHwModal;
