import React from "react";
import {useHistory} from "react-router-dom";
import {getCodeUrlByTaskCode} from "../../layout2/utils";


export const getConfigByTaskCode = (key) => {
  let res = {pos: {left: 0, top: 0}};

  switch (key) {
    // Комната в центре слева
    case 'initialMapVideo1':
      res = { pos: {left: 1065, top: 500} };
      break
    case 'body_substance1':
      res = { pos: {left: 967, top: 487} };
      break
    case 'phenomena1':
      res = { pos: {left: 1236, top: 487} };
      break
    case 'hypothesis1':
      res = { pos: {left: 226, top: 870} };
      break
    case 'hypothesis2':
      res = { pos: {left: 1109, top: 412} };
      break

    // Комната в центре справа
    case 'test1':
      res = { pos: {left: 1157, top: 498} };
      break

    // Верхняя комната
    case 'phenomena2':
      res = { pos: {left: 1230, top: 157} };
      break

    // Облако
    case 'measure2':
      res = { pos: {left: 239, top: 196} };
      break
    case 'measure1':
      res = { pos: {left: 395, top: 196} };
      break
    case 'units1':
      res = { pos: {left: 550, top: 196} };
      break

    // Нижняя комната
    case 'measure4':
      res = { pos: {left: 1042, top: 743} };
      break
    case 'measurement_duplicate1':
      res = { pos: {left: 1110, top: 848} };
      break
    case 'beaker2':
      res = { pos: {left: 1183, top: 746} };
      break
    case 'measure3':
      res = { pos: {left: 1257, top: 847} };
      break
    case 'lab_volume1':
      res = { pos: {left: 1330, top: 744} };
      break
    case 'laboratory10':
      res = { pos: {left: 1403, top: 849} };
      break
    case 'final_test1':
      res = { pos: {left: 1500, top: 480} };
      break
    case 'finalMapVideo1':
      res = { pos: {left: 1510, top: 370} };
      break
    case 'catMapNextBtn':
      res = { pos: {left: 1615, top: 370} };
      break
  }


  return res
}

const CatMapElement = (props) => {
  const prevTaskDontComplete = props.prevItem ? props.prevItem.complete : true;
  const currentTaskNeedPayment = (Boolean(props.prevItem?.paymentRedirect) && !props.coursePaid) || false;
  const disabled = !props.isOpen && ((props.disabled || !prevTaskDontComplete) || currentTaskNeedPayment);
  const {pos, size} = getConfigByTaskCode(props.lessonCode);

  const history = useHistory();

  const taskCode = getCodeUrlByTaskCode(props.lessonCode);
  let status = props.complete ? 'success' : 'active';
  if (disabled) {
    status = 'disabled';
  }

  let img = '';
  try {
    img = require(`../images/${props.lessonCode}/${props.lessonCode}_${status}.png`);
  } catch (error) {}

  return (
    <div
      id={props.lessonCode}
      aria-disabled={disabled}
      className={'cat-map-item'}
      onClick={(e) => {
        if (!disabled) {
          const pathName = history.location.pathname.replaceAll('/', '');
          if (props.link || props.linkPathname) {
            window.location.pathname = props.link || props.linkPathname;
          } else if (props.linkHref) {
            window.location.href = props.linkHref;
          } else {
            history.push(`/${pathName}/ru/${taskCode}`);
            props.setTaskModalVisible(true);
          }
        }
        // if (!disabled) {
        //   props.setTaskSrc(`/task/ru/${taskCode}`);
        // }
      }}
      style={{...pos}}
    >
      <img src={img} alt="" />
    </div>
  )
}

export default CatMapElement;
