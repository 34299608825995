import React, {useEffect, useState} from "react";
import './style.css';
import theoryBg from "../../../platformImages/theory/background.png";
import leftPaper from "../../../platformImages/theory/leftPaper.png";
import rightPaper from "../../../platformImages/theory/rightPaper.png";
import StringHtmlRender from "../StringHtmlRender/StringHtmlRender";

const TheoryFolder = (props) => {
  const {data} = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(props.visible);
    }, 0)
  }, [data, props.visible])

  return (
    <div className={`theory-v2 ${visible ? 'theory-v2--visible' : ''}`}>
      <img className={'theory-v2__bg'} src={theoryBg} alt=""/>
      <div className={'theory-v2__page-wrapper'}>
        <div className={'theory-v2__page-left'} style={{backgroundImage: `url(${leftPaper})`}}>
          <div className={'theory-v2__content-wrapper'}>
            {
              data ? (
                <StringHtmlRender
                  className={'theory-v2__page-left-content'}
                  htmlForRender={data?.left_page_content || ''}
                />
              ) : null
            }
          </div>
        </div>
        <div className={'theory-v2__page-right'} style={{backgroundImage: `url(${rightPaper})`}}>
          <div className={'theory-v2__content-wrapper'}>
            {
              data ? (
                <StringHtmlRender
                  className={'theory-v2__page-right-content'}
                  htmlForRender={data?.right_page_content || ''}
                />
              ) : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}


export default TheoryFolder;
