import trackImg from "../../../../images/snowboard/track.png";
import trackLImg from "../../../../images/snowboard/trackL.png";
import trackRImg from "../../../../images/snowboard/trackR.png";
import ArrowHint from "../../../canvas/components/ArrowHint";
import React from "react";


export const initialData = {
  startTime: undefined,
  prevTime: undefined,
  timeDeltaSec: 0,

  title: { value: '', stg: {}},

  inputVal: undefined,

  started: false,
  play: false,
  dragging: false,

  hintArrowStg: {},

  passedPath: 0, // параметризация пройденного пути
  speed: 0.5,
  speedSign: 1,
  fullEnergy: 0,
  secondCircleStart: 405,

  speedIndicator: 0,

  withoutInput: false,
  playerVisible: false,
  treeVisible: false,
  energyColorIndicator: false,
  speedIndicatorVisible: false,
  cardInfoVisible: false,
  cardInputHeightVisible: false,
  cardInputSpeedVisible: false,
  heightDragVisible: true,

  heightDragActive: false,

  draggableContainerPos: {x: 0, y: 0},
  maxTrackHeightPx: 140,
  maxTrackHeightM: 5,
  defaultTrackHeightM: 5,
};

export const snowboardScenario = [
  {
    key: 'step1',
    patchData: {
      passedPath: -Math.PI * 225 / 2 + 15, // параметризация пройденного пути
      title: {
        value: '',
        stg: {}
      },

      trackImg: trackImg,

      cardInfoVisible: true,
      energyColorIndicator: true,
      playerVisible: true,
      hintArrowStg: {
        visible: true,
        x: 320,
        y: 375,
        color: 'black',
        text: 'Нажми чтобы \nначать',
        textX: 60,
        fontSize: 15,
        arrowX: 150,
        arrowY: 110,
        rotation: 170,
      },
    },
    next_success: 'success'
  },
  {
    key: 'step2',
    patchData: {
      passedPath: -Math.PI * 225 / 2 + 15, // параметризация пройденного пути
      secondCircleStart: 20000,
      title: {
        value: 'Определи высоту, с которой необходимо начать движение, \nчтобы достичь скорости в 12 м/с',
        stg: {x: 150, y: 20 }
      },

      trackImg: trackLImg,

      cardInputHeightVisible: true,
      speedIndicatorVisible: true,
      // heightDragActive: true,

      hintArrowStg: {
        visible: true,
        x: 660,
        y: 130,
        color: 'black',
        text: 'Заполни \nзначение',
        textX: 60,
        fontSize: 15,
        arrowX: 150,
        arrowY: 110,
        rotation: 170,
      },
      hintArrowStg2: {
        visible: true,
        x: 640,
        y: 210,
        color: 'black',
        text: 'Нажми старт',
        textX: 60,
        fontSize: 15,
        arrowX: 150,
        arrowY: 110,
        rotation: 170,
      },
    },
  },
  {
    key: 'step3',
    patchData: {
      passedPath: -Math.PI * 225 / 2 + 15, // параметризация пройденного пути
      secondCircleStart: 20000,
      title: {
        value: '',
        stg: {}
      },

      trackImg: trackLImg,

      cardInputHeightVisible: true,
      speedIndicatorVisible: true,
      withoutInput: true,
      heightDragActive: true,


      hintArrowStg: {
        visible: false,
      },

    },
  },
  {
    key: 'step4',
    patchData: {
      speed: 100,
      passedPath: 0,
      speedSign: 1,
      title: {
        value: 'Определи минимальную начальную скорость, \nчтобы гарантировать подъем на высоту 14 м',
        stg: {x: 250, y: 50 }
      },

      trackImg: trackRImg,

      cardInputSpeedVisible: true,
      speedIndicatorVisible: true,
      heightDragVisible: false,

      hintArrowStg: {
        visible: true,
        x: 165,
        y: 130,
        color: 'black',
        text: 'Заполни \nзначение',
        textX: 80,
        textY: 50,
        fontSize: 15,
        imgScaleX: -1,
        arrowX: 50,
        arrowY: 110,
        rotation: 200,
      },
      hintArrowStg2: {
        visible: true,
        x: 185,
        y: 220,
        color: 'black',
        text: 'Нажми \nстарт',
        textX: 80,
        textY: 50,
        fontSize: 15,
        imgScaleX: -1,
        arrowX: 50,
        arrowY: 110,
        rotation: 200,
      },
    },
  },
  {
    key: 'success',
  }
]
