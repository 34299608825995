import Lottie from "react-lottie";
import React from "react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import failureAnimationData from "../../../utils/failure.json";


const failureAnimOption = {
  loop: false,
  autoplay: true,
  animationData: failureAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


const FailureModal = (props) => {
  const {
    changeFailureVisible,
    failureModalVisible
  } = props;

  const closeModal = () => {
    changeFailureVisible(false)
  };

  return (
    <div style={!failureModalVisible ? styles.hidden : styles.visible}>
      <div
          style={styles.failureModal}
          onClick={() => closeModal()}
      >
        {
          failureModalVisible && (
            <Lottie options={failureAnimOption} height={100} width={100}/>
          )
        }
      </div>
      <div
          style={styles.modalStub}
          onClick={() => closeModal()}
      />
    </div>
  )
};



const mapStateToProps = (state) => ({
  failureModalVisible: state.commonReducer.failureModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FailureModal);

const styles = {
  hidden: {
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 1s, opacity 0.5s linear',
    zIndex: '1000',
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: '0',
    top: '0',
  },
  visible: {
    visibility: 'visible',
    transition: 'visibility 1s, opacity 0.5s linear',
    opacity: '1',
    zIndex: '1000',
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: '0',
    top: '0',
  },
  failureModal: {
    zIndex: '1001',
    cursor: 'pointer',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto',
    width: '320px',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,.8)',
    borderRadius: '10px',
  },
  modalStub: {
    zIndex: '1000',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
  },
};
