import React from "react";
import {Layer, Rect, Group, Line, Text, Image} from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import cloneDeep from "lodash.clonedeep";
import Victor from 'victor';
import Track from "../components/Track";
import backgroundImg from '../../../../images/rally/background.png';
import planeImg from '../../../../images/rally/plane.png';
import carImg from '../../../../images/rally/car.png';
import useImage from "use-image";


class DragRacing extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.carRef = React.createRef();
    this.trackRef = React.createRef();
    this.bridgeRef = React.createRef();
    this.speedRef = React.createRef();
    this.rpmRef = React.createRef();
    this.timeTextRef = React.createRef();
    this.planeRef = React.createRef();

    this.state = {
      go: false,
      gearNum: 1,
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      currentTime: undefined,

      pos: Victor(0, 0),
      speed: Victor(0,0),

      track: [
        {
          length: 600,
          slope: 0,
        },
        {
          length: 80,
          slope: 9,
        },
      ],
      bridgeTrack: [
        {
          length: 185,
          slope: 0,
        },
      ],
      gearRatios: {
        1: 0.8,
        2: 1,
        3: 1.5,
        4: 2,
        5: 4,
      },
      transmissionRatio: 0.005,
      rpmToForceRatio: 0.5,
      engineRpmLimit: {
        min: 800,
        max: 7500,
      },
      engineRpm: 800,
      powerCoef: 0.1,
    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  };

  speedFromRpm (rpm) {
    return rpm * this.data.gearRatios[this.state.gearNum] * this.data.transmissionRatio
  }

  move = (time) => {
    const { go, gearNum } = this.state;
    let data = this.data;
    let trackNode = this.trackRef?.current;
    let bridgeNode = this.bridgeRef?.current;
    this.requestId = window.requestAnimationFrame(this.move);

    let stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.currentTime = data.startTime ? time - data.startTime : 0;
    const timedeltaSec = timedelta / 1000;

    let trackVector = trackNode.findCorrespondingVictor(this.data.pos.x);
    let bridgeVector = bridgeNode.findCorrespondingVictor(this.data.pos.x);

    if (trackVector) {
      trackVector = trackVector.clone();
      const slope = trackNode.getSlope(this.data.pos.x);

      let engineForce = 0;

      if (go) {

        if (data.engineRpm < data.engineRpmLimit.max) {
          engineForce = data.engineRpm * data.rpmToForceRatio;

          data.engineRpm += engineForce * timedeltaSec / data.gearRatios[gearNum];
        } else {
          data.engineRpm = data.engineRpmLimit.max
        }

      } else {
        if (data.engineRpm > data.engineRpmLimit.min) {
          data.engineRpm -= 14000 * timedeltaSec;
        } else {
          data.engineRpm = data.engineRpmLimit.min;
        }
      }

      let speedScalar = this.speedFromRpm(this.data.engineRpm);

      data.speed = trackVector.norm().multiply(Victor(
         speedScalar,
         speedScalar)
      );

      // console.log('debug on rpm', data.engineRpm);


      data.speed.rotateToDeg(slope);
    } else {
      // console.log('over track', bridgeVector, this.data.pos.y);
      if ( bridgeVector && this.data.pos.y >= 0 && this.data.pos.y <= 4  ) {
        // this.data.pos.y = 0;
        data.speed.rotateToDeg(0);
      } else {
        // GRAVITY
        data.speed.add(Victor(0, 50).multiply(Victor(timedeltaSec, timedeltaSec)))
      }
    }

    data.pos.add(Victor(data.speed.x * timedeltaSec, data.speed.y * timedeltaSec));

    this.updateStage();
  };


  updateStage() {
    let stageNode = this.stageRef?.current;
    let carNode = this.carRef?.current;
    let trackNode = this.trackRef?.current;
    let planeNode = this.planeRef?.current;

    let speedNode = this.speedRef?.current;
    let rpmNode = this.rpmRef?.current;

    let timeTextNode = this.timeTextRef?.current;


    const slope = trackNode.getSlope(this.data.pos.x);

    if (!stageNode || !carNode) return;
    if (!speedNode || !rpmNode || !timeTextNode) return;
    // console.log('debug on speed', this.data.speed);

    planeNode.offsetX(-40*((this.data.startTime - this.data.prevTime)/1000));

    carNode.x(this.data.pos.x);
    carNode.y(this.data.pos.y);
    carNode.rotation(slope);

    speedNode.rotation(this.speedAngle);
    rpmNode.rotation(this.rpmAngle);
    timeTextNode.text(this.timeText);

    stageNode.draw()
  };

  get timeText() {
    return `Время ${parseInt( this.data.currentTime/1000)}`;
  }

  get speedAngle () {
    const s = parseInt(this.data.speed.length());
    const maxAngle = 280;
    const maxSpeed = 340;
    if ( s <= maxSpeed && s >= 0) return s/maxSpeed * maxAngle;
    if (s > maxSpeed) return maxAngle;
  };

  get rpmAngle () {
    const rpm = parseInt(this.data.engineRpm);
    const maxAngle = 205;
    const maxRPM = 8000;
    if ( rpm <= maxRPM && rpm >= 0) return rpm/maxRPM * maxAngle;
    if (rpm > maxRPM) return maxAngle;
  };

  Scene = () => {
    const {pos} = this.data;
    const [background] = useImage(backgroundImg);
    const [plane] = useImage(planeImg);
    const [car] = useImage(carImg);

    return (
      <React.Fragment>
        <Layer preventDefault={false}>

          <Image image={background} preventDefault={false}/>


          <Group opacity={0}>
            <Text
              ref={this.timeTextRef}
              x={100} y={90}
              text={this.timeText}
            />

            <Group x={10} y={400} >
              <Rect ref={this.carRef} id={'rect1'} x={pos.x} y={pos.y} width={10} height={10} fill={'black'} offsetY={10}/>
              <Track ref={this.trackRef} x={0} y={0} config={this.data.track}/>
              <Track ref={this.bridgeRef} x={815} y={0} config={this.data.bridgeTrack}/>
            </Group>
          </Group>

          <Group x={505} y={135}>
            {/* SPEED */}
            <Line x={-140} y={12} points={[0, 0, 25, 18]} stroke={'red'} ref={this.speedRef} rotation={this.speedAngle}/>
            {/* RPM */}
            <Line points={[0, 0, -25, 27]} stroke={'red'} ref={this.rpmRef} rotation={this.rpmAngle}/>

            <Text
              x={103}
              y={-57}
              text={this.state.gearNum}
              fontSize={47}
              fill={'white'}
              stroke={'white'}
              strokeWidth={.5}
            />
          </Group>

          <Image image={plane} x={1050} y={10} ref={this.planeRef}/>

          <Group x={0} y={487}>
            <Image image={car} ref={this.carRef} x={pos.x} y={pos.y} />
          </Group>
        </Layer>
      </React.Fragment>
    )
  };

  onStart = () => {
    this.setState({go: true});
  };
  onReset = () => {

  };

  handleKeyPressStart = (e) => {
    if(e.key === 'Space'){
      this.onStart();
    }
  };

  render() {
     return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.Scene/>
        </CanvasContainer>
        <div style={styles.containerBtns}>
          { [1,2,3,4,5].map(gear => (<button
                key={gear}
                // style={ {...styles.gearBtn, right: 720 - 50 * gear, background: this.state.gearNum === gear ? '#1bae2c' : '#E76F52'} }
                style={ {...styles.gearBtn, background: this.state.gearNum === gear ? '#1bae2c' : '#E76F52'} }
                onClick={ () => {
                    let currentRpm = this.data.speed.length() / (this.data.gearRatios[this.state.gearNum] * this.data.transmissionRatio);
                    let newRpm = currentRpm * this.data.gearRatios[this.state.gearNum] / this.data.gearRatios[gear];
                    if (newRpm > this.data.engineRpmLimit.max) {
                      newRpm = this.data.engineRpmLimit.max;
                    }
                    this.data.engineRpm = newRpm;
                    this.setState({ gearNum: gear });

                }}
              >
                {gear}
              </button>
            ))
          }
          <button
            style={{...styles.btn, background: this.state.go ? '#1bae2c' : '#E76F52' }}
            onClick={() => this.setState({go: true})}
          >
            Старт
          </button>
          <button
            style={{...styles.btn}}
            onClick={() => this.setState({go: false, gearNum: 1}, () => this.data = cloneDeep(this.initialData))}
          >
            Сбросить
          </button>
        </div>
      </div>
     )
  }


}

export default DragRacing;

const styles = {
  mainContainer: {
    background: '#457b9d',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    zIndex: 10,
    padding: '.7vmin 1.2vmin',
    background: '#E76F52',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2vmin',
    margin: '0 .6vmin'
  },
  gearBtn: {
    background: '#E76F52',
    color: 'white',
    fontWeight: 'bold',
    padding: '.7vmin 1.2vmin',
    fontSize: '2vmin',
    margin: '0 .6vmin'
  },
  containerBtns: {
    position: 'absolute',
    top: '35%',
    zIndex: 10,
    display: 'flex',
  }
};
