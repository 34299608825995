import React from "react";
import Konva from "konva";
import { Layer, Line, Rect, Circle, Image as KonvaImage, Group } from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import cloneDeep from "lodash.clonedeep";
import useImage from "use-image";
import electroscopeImage from "../../../../images/electrostatic/electroscope.png";

import ballImage from "../../../../images/electrostatic/silverball.png";
import positiveWireImage from "../../../../images/electrostatic/positive_wire.png";
import negativeWireImage from "../../../../images/electrostatic/negative_wire.png";
import neutralWireImage from "../../../../images/electrostatic/neutral_wire.png";
import electroscopeBallImage from "../../../../images/electrostatic/electroscopeBall.png";
import Victor from 'victor';
import {sendSuccessForScenario} from "../../../../utils/common";
import ScenarioManager from "../../../../utils/ScenarioManager";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";


class Electrostatic2 extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.state = {};

    this.const = {
      e: 1,
      K: 1,

      scale: 1/2,

      colorCoeff: 255,
    }

    this.electroscope = {

      pos: Victor(700, 200),
      imageWidth: 282,
      imageHeight: 686,

      centreShiftVector: Victor(140, 320),

      ballShiftVector: Victor(140, 105/2),
      ballImageWidth: 105,
      ballImageHeight: 105,

      ballCharge: 0,
      rodCharge: 0,
      totalCharge: 0,

      
    }

    this.electroscope.ballR = this.electroscope.ballImageWidth/2*this.const.scale;
    this.electroscope.ballPos = this.electroscope.pos.clone().add(
      this.electroscope.ballShiftVector.clone().multiply(Victor(this.const.scale, this.const.scale))
    );

    this.item = {
      pos: Victor(350, 300),
      imageWidth: 40,
      imageHeight: 40,
      charge: 0,

      touch: false,
    }

    this.item.R = this.item.imageWidth/2;//*this.const.scale;

    this.wires = {
      pwire: {
        width: 200,
        height: 50,
        x: 0,  
        y: 150,
      },
      newire: {
        width: 200,
        height: 50,
        x: 0,  
        y: 325,
      },
      nwire: {
        width: 200,
        height: 50,
        x: 0,  
        y: 500,
      },
      
      xshift: 70,
      yshift: 17/2,
      length: 30,
      height: 17/2

    }

    this.itemRef = React.createRef();
    this.rotationStickRef = React.createRef();
    this.electroscopeBallRef = React.createRef();
    this.rodRef = React.createRef();
    this.positiveWireRef = React.createRef();
    this.negativeWireRef = React.createRef();
    this.neutralWireRef = React.createRef();
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  };

  componentDidMount() {
    window.requestAnimationFrame(this.move);


    let item = this.itemRef?.current;
    //item.on('wheel', (e) => this.onScroll(e));

    let stageRef = this.stageRef?.current;
    //stageRef.on('dragover', (e) => this.dragOver(e));
    this.layer = stageRef.getLayers()[0];

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onScroll = (e) => {

    if (this.item.touch) {return;}

    const deltaQ = 0.1;
    e.evt.deltaY < 0 ? this.item.charge += deltaQ : this.item.charge -= deltaQ;
    
    this.recolorItem(this.item.charge);
    this.updateCharges(false);
  }

  recolorRod(Qr) {
    let rod = this.rodRef?.current;
    rod.cache();
    rod.filters([Konva.Filters.RGB, Konva.Filters.Blur]);

    let stick = this.rotationStickRef?.current;
    stick.cache();
    stick.filters([Konva.Filters.RGB]);

    let blue, green, red = 0;

    if (Qr >= 0) {
      red = 255;
      green = 255-Qr*this.const.colorCoeff;
      blue = green;
    }
    else {
      blue = 255;
      green = 255+Qr*this.const.colorCoeff;
      red = green;
    }

    rod.blue(blue);
    rod.green(green);
    rod.red(red);
    rod.blurRadius(5);

    stick.blue(blue);
    stick.green(green);
    stick.red(red);

    console.log('stick:', stick);
    console.log('rod: ', rod);
  }

  recolorItem(Qi) {
    let item = this.itemRef?.current;
    item.cache();
    item.filters([Konva.Filters.RGB]);

    let blue, green, red = 0;

    if (Qi >= 0) {
      red = 255;
      green = 255-Qi*this.const.colorCoeff;
      blue = green;
    }
    else {
      blue = 255;
      green = 255+Qi*this.const.colorCoeff;
      red = green;
    }

    item.blue(blue);
    item.green(green);
    item.red(red);
  }

  recolorBall(Qb) {
    let ball = this.electroscopeBallRef?.current;
    ball.cache();
    ball.filters([Konva.Filters.RGB]);

    let blue, green, red = 0;

    if (Qb >= 0) {
      red = 255;
      green = 255-Qb*this.const.colorCoeff;
      blue = green;
    }
    else {
      blue = 255;
      green = 255+Qb*this.const.colorCoeff;
      red = green;
    }

    ball.blue(blue);
    ball.green(green);
    ball.red(red);
  }

  updateCharges() {

    if (this.item.touch) { return; }

    let r = this.electroscope.ballPos.clone().subtract(this.item.pos);
    let dipolePos = this.electroscope.ballPos.clone().add(r.clone().normalize().multiply(
      Victor(this.electroscope.centreShiftVector.y, this.electroscope.centreShiftVector.y)));

    let itemDist = dipolePos.clone().subtract(this.item.pos).length();
    let ballDist = dipolePos.clone().subtract(this.electroscope.ballPos).length()*2;

    let itemCharge = this.item.charge;

    this.item.itemDist = itemDist;
    this.electroscope.ballDist = ballDist;

    let itemField = itemCharge /itemDist**2;

    let ballField = -itemField/2;
    let ballCharge = ballDist**2*ballField;

    let rodField = itemField/2;
    let rodCharge = ballDist**2*rodField;

    ballCharge += this.electroscope.totalCharge/2;
    rodCharge += this.electroscope.totalCharge/2;

    this.electroscope.ballCharge = ballCharge;
    this.electroscope.rodCharge = rodCharge;

    const rotationStick = this.rotationStickRef?.current;
    let rotation = Math.abs(rodCharge*45);
    rotation = rotation > 90 ? 90 : rotation
    rotationStick.rotation(rotation);

    this.recolorBall(this.electroscope.ballCharge);
    this.recolorRod(this.electroscope.rodCharge);
    this.recolorItem(this.item.charge);


  }


  move = (time) => {
    this.requestId = window.requestAnimationFrame(this.move);


    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    this.updateStage()
  };

  updateStage() {
    let stageNode = this.stageRef?.current;
    if (!stageNode) return;

    this.recolorBall(this.electroscope.ballCharge);
    this.recolorRod(this.electroscope.rodCharge);
    this.recolorItem(this.item.charge);

    stageNode.draw();
  };

  onDragStart = (e) => {
  };

  onDragMove(e) {
    let pos = e.target.position();
    this.item.pos = Victor(pos.x, pos.y);
    console.log('Pos: ', pos);
    
    //check wire collision
    if (pos.x - this.item.R < this.wires.pwire.x + this.wires.pwire.width
      && pos.x > this.wires.pwire.x + this.wires.xshift) {

      if (pos.y + this.item.R > this.wires.pwire.y + this.wires.yshift
        && pos.y - this.item.R < this.wires.pwire.y + this.wires.yshift + this.wires.height) {
          this.item.charge = 1;
          console.log('Touched pwire');
      }

      if (pos.y + this.item.R > this.wires.newire.y + this.wires.yshift
        && pos.y - this.item.R < this.wires.newire.y + this.wires.yshift + this.wires.height) {
          this.item.charge = 0;
          console.log('Touched newire');
      }

      if (pos.y + this.item.R > this.wires.nwire.y + this.wires.yshift
        && pos.y - this.item.R < this.wires.nwire.y + this.wires.yshift + this.wires.height) {
          this.item.charge = -1;
          console.log('Touched nwire');
      }

    }

    
    
    if (this.item.pos.clone().subtract(this.electroscope.ballPos).length()
        <= this.item.R + this.electroscope.ballR) {
      this.touchEvent();
      return;
    }

    this.item.touch = false;
    this.updateCharges();

    return;
  };

  onDragEnd = (e) => {
  };

  touchEvent() {
    if (this.item.touch) { return; }

    let sumCharge = this.electroscope.totalCharge + this.item.charge;

    let r = this.item.itemDist;
    let b = this.electroscope.ballDist;

    let itemCharge = sumCharge*r**2/(b**2 + r**2);
    let totalCharge = sumCharge - itemCharge;

    this.item.charge = itemCharge;
    this.electroscope.totalCharge = totalCharge;

    this.updateCharges();

    this.item.touch = true;

    return;

  }

  Scene = () => {
    const {code} = this.props;

    const [electroscope] = useImage(electroscopeImage);
    const [ball] = useImage(ballImage);
    const [electroscopeBall] = useImage(electroscopeBallImage);
    const [positiveWire] = useImage(positiveWireImage);
    const [negativeWire] = useImage(negativeWireImage);
    const [neutralWire] = useImage(neutralWireImage);

    return (
      <React.Fragment>
        
        <Layer>
          {
            code === 'electrostatic2' && (
              <Group>

                <KonvaImage
                  image={positiveWire}
                  ref={this.positiveWireRef}
                  id={'pwire'}
                  width={200}
                  height={50}
                  x={0}   
                  y={150}
                />

                <KonvaImage
                  image={negativeWire}
                  ref={this.negativeWireRef}
                  id={'nwire'}
                  width={200}
                  height={50}
                  x={0}   
                  y={500}
                />

                <KonvaImage
                  image={neutralWire}
                  ref={this.neutralWireRef}
                  id={'nwire'}
                  width={200}
                  height={50}
                  x={0}   
                  y={325}
                />

                <KonvaImage
                  image={electroscope}
                  width={this.electroscope.imageWidth*this.const.scale}
                  height={this.electroscope.imageHeight*this.const.scale}
                  x={this.electroscope.pos.x}   
                  y={this.electroscope.pos.y}
                />

                <KonvaImage
                  image={electroscopeBall}
                  ref={this.electroscopeBallRef}
                  width={this.electroscope.ballImageWidth*this.const.scale}
                  height={this.electroscope.ballImageHeight*this.const.scale}
                  x={this.electroscope.ballPos.x-1}
                  y={this.electroscope.ballPos.y}
                  offsetX={this.electroscope.ballImageWidth*this.const.scale/2}
                  offsetY={this.electroscope.ballImageHeight*this.const.scale/2}
                />

                <Line
                  ref={this.rotationStickRef}
                  x={this.electroscope.pos.x+this.electroscope.centreShiftVector.x*this.const.scale}
                  y={this.electroscope.pos.y+this.electroscope.centreShiftVector.y*this.const.scale}
                  points={[0, -40, 0,  40]}
                  stroke={'silver'}
                  strokeWidth={2}
                />

                <Line
                  ref={this.rodRef}
                  x={this.electroscope.pos.x+this.electroscope.centreShiftVector.x*this.const.scale}
                  y={this.electroscope.pos.y+this.electroscope.centreShiftVector.y*this.const.scale}
                  points={([-1, -62, -1, 0, 3, 3, 3, 44])}
                  stroke={'silver'}
                  strokeWidth={3}
                />
                <KonvaImage
                  draggable={true}
                  onDragStart={(e) => {this.onDragStart(e)}}
                  onDragMove={(e) => {this.onDragMove(e)}}
                  onDragEnd={(e) => {this.onDragEnd(e)}}
                  ref={this.itemRef}
                  x={350} y={300}
                  image={ball}
                  width={this.item.imageWidth}
                  height={this.item.imageHeight}
                  offsetX={this.item.imageWidth/2}
                  offsetY={this.item.imageHeight/2}
                />
           
              </Group>
            )
          }
        </Layer>

      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.Scene/>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Electrostatic2);

const styles = {
  mainContainer: {
    background: '#f4f1de',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }
}
