import React from "react";
import {Group, Image, Text} from "react-konva";
import {mergeRefs} from "../../../utils/common";
import Card from "./Card";
import cyberpunkFormImg from '../../../images/space/spaceship_landing/cyberpunk-form.png';
import cyberpunkBtnImg from '../../../images/space/spaceship_landing/cyberpunk-btn.png';
import CanvasButton from "./CanvasButton";
import useImage from "use-image";
import spaceshipImg from "../../../images/space/spaceship_landing/spaceship.png";



const CyberpunkCard = (props) => {
  const {
    x, y,
    children,
    btnText,
    cardRef,
    btnRef,
    visible,
    onClickBtn
  } = props;

  const [cyberpunkForm] = useImage(cyberpunkFormImg);
  const [cyberpunkBtn] = useImage(cyberpunkBtnImg);
  return (
    <Group ref={cardRef} x={x} y={y} visible={visible}>
      <Image
        image={cyberpunkForm}
      />

      <Group>
        {children}
      </Group>

      <CanvasButton
        btnRef={btnRef}
        x={0} y={198}
        text={btnText||''}
        textFill={'black'}
        textWidth={230}
        backgroundImg={cyberpunkBtn}
        onClick={onClickBtn}
      />
    </Group>
  )
};

export default CyberpunkCard;