import React, {useEffect} from "react";
import '../styles/farm-map.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import MapContainer from "./MapContainer";
import {checkIsTeacherMap, maps} from "../utils";
import MapElement from "../components/MapElement";
import { isEng } from "../../../../utils/lang/common";


const getConfigByTaskCode = (key) => {
  let res = {pos: {left: 0, top: 0}};

  switch (key) {
    case 'block_and_logs1':
      res = { pos: {left: 1215, top: 795, width: 160, height: 160} };
      break
    case 'school_map2_video1':
      res = { pos: {left: 1710, top: 550, width: 200, height: 200} };
      break
    case 'cart1':
      res = { pos: {left: 1590, top: 870, width: 200, height: 200} };
      break
    case 'squash1':
      res = { pos: {left: 960, top: 850, width: 180, height: 180} };
      break
    case 'school_map2_video2':
      res = { pos: {left: 580, top: 885, width: 180, height: 180} };
      break
    case 'bicycle2':
      res = { pos: {left: 560, top: 680, width: 170, height: 170} };
      break
    case 'bicycle3':
      res = { pos: {left: 660, top: 630, width: 130, height: 130} };
      break
    case 'logic_wheel_1':
      res = { pos: {left: 260, top: 920, width: 160, height: 160} };
      break
    case 'logic_wheel_2':
      res = { pos: {left: 10, top: 850, width: 180, height: 180} };
      break
    case 'logic_wheel_3':
      res = { pos: {left: 370, top: 610, width: 200, height: 200} };
      break
    case 'logic_wheel_4':
      res = { pos: {left: 1100, top: 660, width: 160, height: 160} };
      break
    case 'logic_wheel_5':
      res = { pos: {left: 870, top: 590, width: 180, height: 180} };
      break
    case 'logic_wheel_6':
      res = { pos: {left: 1400, top: 540, width: 220, height: 220} };
      break
    case 'barrels1':
      res = { pos: {left: 210, top: 730, width: 200, height: 200} };
      break
    case 'bicycle1':
      res = { pos: {left: 780, top: 730, width: 170, height: 170} };
      break
  }


  return res
}

const FarmMap2 = (props) => {
  const isTeacher = checkIsTeacherMap();

  useEffect(() => {
    if (isTeacher) {
      props.courseMapCreateStatistic();
    }
  }, [])

  const Content = ({tasksItems, isOpen, taskModalVisible, currentLessonCode, successScreenVisible, setTaskModalVisible}) => {

    useEffect(() => {
      // Initial redirect if already complete task
      const taskForRedirect = tasksItems.find(task => task.paymentRedirect);
      if (taskForRedirect?.complete && taskForRedirect?.paymentRedirect && !props.coursePaid && !isOpen) {
        window.location.href = taskForRedirect.paymentRedirect;
      }
    }, [tasksItems.length])

    useEffect(() => {
      if (!successScreenVisible) {
        const currentTask = tasksItems.find(task => task.lessonCode === currentLessonCode);
        if (currentTask?.complete && currentTask?.paymentRedirect && !props.coursePaid && !isOpen) {
          window.location.href = currentTask.paymentRedirect;
        }
      }
    }, [tasksItems, successScreenVisible]);

    return (
      <div className={'farm-map2-background'}>
        {
          tasksItems.map((item, i) => {
            const prevItem = tasksItems.find(el => el.lessonCode === item.prevKey);
            return (
              <MapElement
                getConfigByTaskCode={getConfigByTaskCode}
                key={item.lessonCode}
                lessonCode={item.lessonCode}
                isOpen={isOpen}
                isTeacher={isTeacher}
                withRound={true}
                prevItem={prevItem}
                index={i}
                setTaskModalVisible={setTaskModalVisible} coursePaid={props.coursePaid} {...item}
                preOpen={() => props.getTaskAvailabilityStatus(item.lessonCode)}
              />
            )
          })
        }
      </div>
    )
  }
  return (
    <MapContainer {...props} withoutFog={true} withoutSuccessScreen={true} mapKey={maps.schoolMap2.key}>
      <Content />
    </MapContainer>
  )
}

const mapStateToProps = state => {
  return ({
    coursePaid: state.mapReducer.coursePaid,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({
  getOrCreateMapProgress: (initial) => dispatch(actions.getOrCreateMapProgress(initial, maps.schoolMap2.key)),
  courseMapAuth: () => dispatch(actions.courseMapAuth()),

  courseMapCreateStatistic: () => dispatch(actions.courseMapCreateStatistic(maps.schoolMap2.key)),
  getTaskAvailabilityStatus: (lessonCode) => dispatch(actions.getTaskAvailabilityStatus(maps.schoolMap2.key, lessonCode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FarmMap2);
