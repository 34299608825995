import {Arrow, Group, Layer, Rect, Text} from "react-konva";
import React from "react";
import Victor from "victor";
import {showItems, massInKilos} from "../settings";
import {_t} from "../../../../utils/lang/common";
import { capitalizeFirstLetter } from "../../../../utils/common";


const required = (type, item, code) => {
  return showItems[code] && showItems[code][type] ? showItems[code][type].includes(item) : false;
}


export const ObjDescriptionsContainer = (props) => {
  const {
    state,
    docking,
    shipPos,
    // isBroken,
    shipCenter,
    stationCenter,
    taskCode,
    onlyArrow,
    arrowColor = 'white'
  } = props;

  // ------------ SHIP ------------
  const shipMass = (state.shipMass && state.shipMass.toFixed(2)) || 0;
  const shipSpeedX = (state.shipSpeed && Number(state.shipSpeed.x).toFixed(2)) || 0;
  const shipSpeed = (state.setShipSpeed && state.setShipSpeed.toFixed(2)) || shipSpeedX || 0;

  // ------------ STATION ------------
  const stationMass = (state.stationMass && state.stationMass.toFixed(2)) || 0;
  const stationSpeed = (state.stationSpeed && state.stationSpeed.x.toFixed(2)) || 0;

  // ------------ RESULT ------------
  const resMass = (Number(state.stationMass) + Number(state.shipMass)).toFixed(2) || 0;
  const resSpeed = (state.stationSpeed && state.stationSpeed.x.toFixed(2)) || 0;

  const isBroken = state.stationSpeed && Number(state.stationSpeed.x).toFixed(1) !== "0.0"
      && (Number(state.stationSpeed.x).toFixed(1) !== Number(state.shipSpeed.x).toFixed(1)
          || Number(state.stationSpeed.y).toFixed(1) !== Number(state.shipSpeed.y).toFixed(1));

  return (
    <>
      { ((!docking || isBroken) && shipCenter) && (
        <ObjDescription
          index={1}
          formula={`p${1} = m * v${1}`}
          formulaRes={`p${1} = ${((massInKilos.includes(taskCode) ? 1 : 1000) * shipMass).toFixed(0)} * ${shipSpeed}`}
          objPos={shipPos}
          objCenter={shipCenter}
          objMass={shipMass}
          objSpeedX={shipSpeed}
          objSpeedY={state.shipSpeed.y}
          objEngineTimeY={state.engineTimeY}
          objEngineTimeX={state.engineTimeX}
          running={state.running}
          pixelCoef={state.coeff}
          type={"ship"}
          taskCode={taskCode}
          onlyArrow={onlyArrow}
          arrowColor={arrowColor}
        />
      )}{ ((!docking || isBroken) && stationCenter) && (
        <ObjDescription
          index={2}
          formula={`p${2} = m * v${2}`}
          formulaRes={`p${2} = ${((massInKilos.includes(taskCode) ? 1 : 1000) * stationMass).toFixed(0)} * ${stationSpeed}`}
          objPos={state.stationPos}
          objCenter={stationCenter}
          objMass={state.stationMass ? stationMass : shipMass}
          objSpeedX={stationSpeed}
          objSpeedY={state.stationSpeed.y}
          running={state.running}
          pixelCoef={state.coeff}
          type={"station"}
          taskCode={taskCode}
          onlyArrow={onlyArrow}
          arrowColor={arrowColor}
        />
      )}{(docking && !isBroken) && (
        <ObjDescription
          index={3}
          formula={`p${3} = m * v${3}`}
          formulaRes={`p${3} = ${((massInKilos.includes(taskCode) ? 1 : 1000) * resMass).toFixed(0)} * ${resSpeed}`}
          objPos={new Victor((state.stationPos.x + state.shipPos.x) / 2, (state.stationPos.y + state.shipPos.y) / 2)}
          objCenter={new Victor((stationCenter.x + shipCenter.x) / 2, (stationCenter.y + shipCenter.y) / 2)}
          objMass={state.stationMass + state.shipMass}
          objSpeedX={resSpeed}
          objSpeedY={state.stationSpeed.y}
          running={state.running}
          pixelCoef={state.coeff}
          type={"system"}
          taskCode={taskCode}
          onlyArrow={onlyArrow}
          arrowColor={arrowColor}
        />
      )}
    </>
  )
};



export const ObjDescription = (descrProps) => {
  const {
    formula,
    formulaRes,
    objPos,
    objCenter,
    objMass,
    objSpeedX,
    objSpeedY,
    objEngineTimeY,
    objEngineTimeX,
    running,
    pixelCoef,
    type,
    taskCode,
    onlyArrow,
    arrowColor
  } = descrProps;

  const textStyles = {
    stroke: 'white',
    fill: 'white',
    strokeWidth: .1,
    fontSize: 14,
    align: 'left',
  };
  const formulaPosX = 80;
  const formulaPosY = -80;
  const speedX = Number(objSpeedX).toFixed(2);
  const speedY = Number(objSpeedY).toFixed(2);
  const impulseShown = (Number(objSpeedX) * Number((massInKilos.includes(taskCode) ? 1 : 1000) * objMass)).toFixed(0);
  const vectorLength = (Number(impulseShown) * Number(pixelCoef / 1000));
  let item = "";
  if (type === "ship")
    item = _t('space.descriptions.ship_genitive');
  else if (type === "station")
    item = _t('space.descriptions.station_genitive');
  else if (type === "system")
    item = _t('space.descriptions.system_genitive');

  const labels = [];
  let counter = 50;
  if (speedX !== undefined && required(type, "speedX", taskCode)) {
    labels.push(
      <Text
        key={1}
        {...textStyles}
        x={80} y={counter}
        text={capitalizeFirstLetter(_t('space.descriptions.x_velocity', {item, speedX: speedX || 0}))}
      />
    )
    counter += 25;
  } if (speedY !== undefined && required(type, "speedY", taskCode)) {
    labels.push(
      <Text
        key={2}
        {...textStyles}
        x={80} y={counter}
        text={capitalizeFirstLetter(_t('space.descriptions.y_velocity', {item, speedX: speedY || 0}))}
      />
    )
    counter += 25;
  } if (objMass !== undefined && required(type, "mass", taskCode)) {
    labels.push(
      <Text
        key={3}
        {...textStyles}
        x={80} y={counter}
        text={capitalizeFirstLetter(_t('space.descriptions.item_mass', {item, mass: ((massInKilos.includes(taskCode) ? 1 : 1000) * objMass).toFixed(0) || 0}))}
      />
    )
    counter += 25;
  } if (objEngineTimeY !== undefined && required(type, "engineTimeY", taskCode)) {
    labels.push(
      <Text
        key={4}
        {...textStyles}
        x={-28} y={counter}
        text={`Время работы маневрового двигателя: ${objEngineTimeY || 0}`}
      />
    )
    counter += 25;
  } if (objEngineTimeX !== undefined && required(type, "engineTimeX", taskCode)) {
    labels.push(
      <Text
        key={5}
        {...textStyles}
        x={-10} y={counter}
        text={`Время работы основного двигателя: ${objEngineTimeX || 0}`}
      />
    )
    counter += 25;
  } if (impulseShown !== undefined && running && required(type, "impulse", taskCode)) {
    labels.push(
      <Text
        key={6}
        {...textStyles}
        x={80} y={counter}
        text={capitalizeFirstLetter(_t('space.descriptions.item_momentum', {item, momentum: impulseShown || 0}))}
      />
    )
  }


  return (
    <>
      <Group {...objPos}>
        <Group {...objCenter}>
          {
            !onlyArrow ? (
              <>
                {/*FORMULA*/}
                <Arrow
                  points={[formulaPosX, formulaPosY-3, formulaPosX + 12, formulaPosY-3]}
                  strokeWidth={1}
                  pointerWidth={4}
                  pointerLength={4}
                  stroke={"white"}
                  fill={"white"}
                />
                <Arrow
                  points={[formulaPosX + 80, formulaPosY-3, formulaPosX + 92, formulaPosY-3]}
                  strokeWidth={1}
                  pointerWidth={4}
                  pointerLength={4}
                  stroke={"white"}
                  fill={"white"}
                />
                <Text
                  {...textStyles}
                  x={formulaPosX}
                  y={formulaPosY}
                  text={formula}
                  fontSize={20}
                />
                {/* formula result */}
                <Text
                  {...textStyles}
                  x={formulaPosX}
                  y={formulaPosY+30}
                  text={`${formulaRes} = ${impulseShown || 0}`}
                  fontSize={20}
                />

                {labels}
              </>
            ) : null
          }
          {/* VECTOR */}
          <Arrow
            points={[0, 0, vectorLength > 0 ? 50+vectorLength : 0, 0]}
            stroke={arrowColor}
            strokeWidth={4}
            pointerWidth={15}
            pointerLength={15}
            fill={arrowColor}
          />
        </Group>
      </Group>
    </>
  )
};
