
export const getFilterStudentsByGrades = (gradesArr, withoutAllBtn, setFilterStateFunc) => {
  const exampleGrades = [{id: 1, name: '7Б'}, {id: 2, name:'7В'}];
  let items = [];
  if (!withoutAllBtn) {
    items.push({ title: 'All students', value: 'all' });
  }

  (gradesArr || exampleGrades).forEach((item) => {
    items.push({ title: item.name, value: item.id });
  })
  return {
    id: 'students',
    title: 'Students',
    type: 'checkbox',
    stateKey: 'studentsClassroomFilter',
    setStateAction: setFilterStateFunc,
    items: items,
  };
}
export const getFilterRows = (setFilterStateFunc) => {
  return {
    id: 'rows',
    title: 'Rows',
    type: 'radio',
    stateKey: 'rowsFiller',
    setStateAction: setFilterStateFunc,
    items: [
      {
        title: 'By students',
        value: 'by_students',
      },{
        title: 'By groups',
        value: 'by_grade',
      }
    ],
  };
}

export const getFilterMeasure = (setFilterStateFunc) => {
  return {
    id: 'measure',
    title: 'Metrics',
    type: 'radio',
    stateKey: 'measureFilter',
    setStateAction: setFilterStateFunc,
    items: [
      {
        title: '% of completion',
        value: 'by_percent_completion',
      },
      // {
      //   title: 'Количество попыток',
      //   value: 'by_count_trying',
      // },{
      //   title: 'Среднее время решения',
      //   value: 'by_solution_time',
      // }
    ],
  }
};

export const getFilterStudentsByPeriod = (setFilterStateFunc) => {
  return {
    id: 'period',
    title: 'Период',
    type: 'radio',
    withPeriodDatePicker: true,
    stateKey: 'periodFillerFragmentation',
    setStateAction: setFilterStateFunc,
    items: [
      {
        title: 'По неделям',
        value: 'by_weeks',
      },{
        title: 'По месяцам',
        value: 'by_months',
      },{
        title: 'По проверочным работам',
        value: 'by_homeworks',
      }
    ],
  }
};

export const getFilterStudentsByChapter = (setFilterStateFunc, chapters) => {
  const items = chapters.map(chapter => ({title: chapter.title, value: chapter.id}));
  return {
    id: 'chapter',
    title: 'Тема',
    type: 'select',
    stateKey: 'chapterFilter',
    setStateAction: setFilterStateFunc,
    reloadDefaultItems: chapters,
    items: items,
  }
};