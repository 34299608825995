import React, {useEffect, useState} from "react";
import {Badge, Divider} from "antd";
import '../../layout2/styles/header.css';
import '../../../styles/transitions.css';
import {checkPage, userRoles} from "../../../../utils/common";
import {Link, withRouter} from "react-router-dom";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";


const Menu = (props) => {
  const {pageKey, user, mode, visible} = props;

  const [menuItems, setMenuItems] = useState([]);
  const [secondMenuItems, setSecondMenuItems] = useState([]);

  const isTeacher = user && (user.role === userRoles.teacher);
  const isAnonym = !user;

  const { tasks, newsFeed, teacherCard, studentHwPage, teacherHwPage, statisticsPage, gradesPage } = checkPage(pageKey);

  const onClickMenuItem = (e) => {
    // Сбрасываем выбранные элементы при переключении вкладок
    // Для того, чтобы между каталогами не было связи (Каталог задач и карточек учителя)
    props.resetCatalogSelectedItems();

    const container = document.getElementById('app__main-container');
    if (container) {
      container.scrollTo({top:0});
    }
  }

  const clickAnonymousUser = (e) => {
    e.preventDefault();
    return props.setAnonymousPopupVisible(true);
  }

  useEffect(() => {
    let menuItems = [
      {id: 1, title: 'Главная', href: '/', clickAction: (e) => {}, active: newsFeed},
    ];
    let secondMenuItems = [];
    if (isTeacher || isAnonym) {
      menuItems = [
        // ...menuItems,
        // {id: 1, title: 'Главная', href: '/', clickAction: (e) => {}, active: newsFeed},
        // {id: 2, title: 'Проверяемые элементы содержания', href: '/teacher-cards', clickAction: () => {}, active: teacherCard},
        // {id: 3, title: 'Каталог задач', href: '/tasks', clickAction: (e) => {}, active: tasks},
        {id: 4, title: 'My tasks', href: '/homework', clickAction: (e) => {if (isAnonym) { return clickAnonymousUser(e); }},
                active: teacherHwPage, badge: props.addNewHomeworkFlag},
        {id: 5, title: 'Groups', href: '/grades', clickAction: (e) => {if (isAnonym) { clickAnonymousUser(e); }},
                active: gradesPage},
        {id: 6, title: 'Tasks I made', href: `/teacher-lessons`, clickAction: (e) => {if (isAnonym) { clickAnonymousUser(e); }},
                active: gradesPage},
      ]
      // secondMenuItems = [
      //   // {id: 5, title: 'Groups', href: '/grades', clickAction: (e) => {if (isAnonym) { clickAnonymousUser(e); }},
      //   //         active: gradesPage},
      //   // {id: 6, title: 'Stats', href: '/statistic', clickAction: (e) => {if (isAnonym) { clickAnonymousUser(e); }},
      //   //         active: statisticsPage},
      // ]
    } else {
      menuItems = [
        ...menuItems,
        // {id: 3, title: 'Каталог задач', href: '/tasks', clickAction: () => {}, active: tasks},
        {id: 4, title: 'My tasks', href: '/student-homework', clickAction: () => {}, active: studentHwPage},
      ]
    }
    setMenuItems(menuItems);
    setSecondMenuItems(secondMenuItems);
  }, [isTeacher])


  const verticalMode = mode === 'vertical';
  return (
    <div className={`header-menu__wrapper ${props.mobile && 'header-menu__wrapper--adaptive'} ${visible !== undefined && visible && 'header-menu__wrapper--visible'}`}>
      <ul className={`header-menu ${verticalMode ? 'header-menu--vertical' : ''}`}>
        {
          menuItems.map((el, i) => (
              <li className={`header-menu__item ${el.active ? 'header-menu__item--active' : ''}`} key={'menu-item-'+el.id}>
                {
                  el.badge ? (
                    <Badge color={'#FF9737'} className={'badge-custom-size'} offset={[15, 0]}/>
                  ) : null
                }
                <Link
                  to={el.href}
                  onClick={(e) => {
                    el.clickAction(e);
                    onClickMenuItem(e);
                  }}
                >
                    {el.title}
                </Link>
              </li>
          ))
        }
        {
          !props.mobileMode ?
            secondMenuItems.map((el, i) => (
              <li
                style={!i && !props.mobile ? {borderLeft: '1px solid rgba(0,0,0,0.2)', marginLeft: '10px'} : {}}
                key={'menu-item-'+el.id}
                className={`header-menu__item header-menu__item--second-menu ${el.active ? 'header-menu__item--active' : ''}`}
              >
                <Link
                  to={el.href}
                  style={!i && !props.mobile ? {marginLeft: '20px', color: 'gray'} : {color: 'gray'}}
                  onClick={(e) => {
                    el.clickAction(e);
                    onClickMenuItem(e);
                  }}
                >
                  {el.title}
                </Link>
              </li>
            ))
          :
            null
        }
      </ul>
      {
        verticalMode && (
          <Divider style={{margin: '20px 0'}} />
        )
      }
    </div>
  )
}



const mapStateToProps = (state) => ({
  homeworks: state.lessonsReducer.homeworks,
  addNewHomeworkFlag: state.lessonsReducer.addNewHomeworkFlag,
});

const mapDispatchToProps = (dispatch, {match}) => {
  return ({
    checkLogin: () => dispatch(actions.checkLogin()),
    setAnonymousPopupVisible: (visible) => dispatch(actions.setAnonymousPopupVisible(visible)),
    resetCatalogSelectedItems: () => dispatch(actions.resetCatalogSelectedItems()),
    setSelectedHomeworkId: (hwId) => dispatch(actions.setSelectedHomeworkId(hwId)),
  });
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu));
