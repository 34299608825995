

export const getTitles = (code) => {
  const allTitles = {
    idealGas12: 'Смоделируй явление диффузии используя два балона с газом',
    idealGas12_1: 'Проведи эксперимент, подтверждающий, \nчто газ заполняет весь доступный объем',
    idealGas13: 'Часть приборов показывают \nневерные значения. Проведи \nэксперимент и отключи сломанные \nприборы',
    idealGas14: 'Проведи эксперимент и определи температуру перехода воды \nиз жидкого состояния в газообразное',
  }

  return {
    current: allTitles[code],
    allTitles
  }
}

export const getBoolLessonByCode = (code) => ({
  brownian1: code === 'idealGas12',
  brownian2: code === 'idealGas13',
  brownian3: code === 'idealGas14',
})