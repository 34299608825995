import exmplMP4 from '../../../images/exmpl.mp4';
import {hasIntersection} from "../../../utils/common";
import bgImg from '../../../images/relativeMotion/chema/bg.png';
import meteorImg from '../../../images/relativeMotion/chema/meteor.png';
import spaceShipImg from '../../../images/relativeMotion/chema/spaceShip.png';
import rocketImg from '../../../images/relativeMotion/chema/rocket.png';
import planetImg from '../../../images/relativeMotion/chema/planet.png';

import bg2Img from '../../../images/relativeMotion/task/bg.png';
import meteor2Img from '../../../images/relativeMotion/task/asteroid.png';
import spaceShip2Img from '../../../images/relativeMotion/task/ship.png';
import rocket2Img from '../../../images/relativeMotion/task/rocket.png';
import planet2Img from '../../../images/relativeMotion/task/plant.png';
import {Arrow} from "react-konva";
import React from "react";




export const getScenario = (code) => {
  if (code === 'relative_motion1') { return scenario }
  if (code === 'relative_motion2') { return scenario2 }
  if (code === 'relative_motion3') { return scenario3 }
}
export const getImgsForLsn = (code) => {
  if (code === 'relative_motion1') {
    return { bgImg, meteorImg, spaceShipImg, rocketImg, planetImg }
  }
  if (code === 'relative_motion3') {
    return { bgImg, meteorImg, spaceShipImg, rocketImg, planetImg }
  }
  if (code === 'relative_motion2') {
    return { bgImg: bg2Img, meteorImg: meteor2Img, spaceShipImg: spaceShip2Img, rocketImg: rocket2Img, planetImg: planet2Img }
  }
}

export const commonPatchData = {
  title: '',
  titleColor: '#00E2DF',

  start: true,
  shoot: true,

  videoSrc: undefined,
  onVideoEnd: (comp) => {},

  theoryVisible: false,
  onClickSkipTheory: (comp) => {},

  meteorVisible: false,
  meteorVectorVisible: false,
  meteorVectorText: '',

  spaceShipVisible: false,
  spaceShipVectorVisible: false,
  spaceShipVectorText: '',

  rocketVisible: false,
  rocketVectorVisible: false,
  rocketVectorText: '',

  planetVectorVisible: false,
  planetVectorText: '',

  checkBtnVisible: false,
  onClickCheckData: (comp) => {},

  okBtnVisible: false,
  onClickOkBtnData: (comp) => {},

  changePointOfViewBtnVisible: false,
  changePointOfViewBtnText: '',
  onClickChangePointOfViewBtnData: (comp) => {},

  checkStepReset: (comp) => {},

  inputTextTitle: '',
  inputTextSubtitle: '',

  inputCardVisible: false,
  inputDisabled: false,

  finalPopupVisible: false,

  meteorContainerPosX: 0,
  spaceShipContainerPosX: 0,
  rocketContainerPosX: 0,
  meteorScaleX: 1,
  spaceShipScaleX: 1,
  rocketScaleX: 1,

  // ------ 2 -------
  startBtnVisible: false,
  pathInfoVisible: false,
  shootBtnVisible: false,
  explosionVisible: false,
  successStepPopupVisible: false,
  failureStepPopupVisible: false,
  distanceCoeff: 0,
  failureInitialStep: '',
  pathValueText: 0,
}
const resetPositions = {
  meteorPosX: 0,
  rocketPosX: 0,
  spaceShipPosX: 0,
  planetPosX: 0,
}

const defaultPatchData1 = {
  planetImgPos: {x: -640, y: -130},
  titleColor: '#00E2DF',
  bgOpacity: .5,
  planetArrowPos: {x: 181, y: 290},
  meteorArrowPos: {x: 50, y: 40},
  spaceShipArrowPos: {x: 30, y: 25},
  rocketArrowPos: {x: 20, y: 5},
  meteorRectWidth: 120,
}
const defaultPatchData2 = {
  planetImgPos: {x: 0, y: 0},
  titleColor: '#DA9E63',
  bgOpacity: 1,
  meteorArrowPos: {x: 20, y: 40},
  spaceShipArrowPos: {x: 80, y: 22},
  rocketArrowPos: {x: 20, y: 5},
  meteorRectWidth: 80,
}

const commonScenario = {
  videoStep: {
    key: 'animation',
    patchData: {
      ...commonPatchData,
      videoSrc: exmplMP4,
      onVideoEnd: (comp) => comp.scenarioManager.next(),
    }
  },
  theoryStep: {
    key: 'theory',
    patchData: {
      ...commonPatchData,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        comp.scenarioManager.selectStepByKey(comp.data.prevStep || 'success');
        comp.data.errorCount = 0;
      },
    }
  },
}





const block1Def1Data = {
  meteorVisible: true,
  meteorVectorVisible: true,
  meteorVectorText: 'астероида \nотносительно \nпланеты',
  spaceShipVisible: true,
  spaceShipVectorVisible: true,
  spaceShipVectorText: 'корабля \nотносительно \nпланеты',

  inputTextTitle: 'Скорость астероида',
  inputTextSubtitle: 'относительно корабля',

  inputCardVisible: true,

  meteorContainerPosX: 600,
  spaceShipContainerPosX: 100,

  checkStepReset: (comp) => {
    const shipCenterInters = hasIntersection(comp._getNode('spaceShipRect'), comp._getNode('centerRect'), comp);
    const meteorCenterIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('centerRect'), comp);

    if (shipCenterInters || meteorCenterIntersect) {
      comp.data = { ...comp.data, ...resetPositions }
    }
  }
}
const block2Def1Data = {
  meteorVisible: true,
  meteorVectorVisible: true,
  meteorVectorText: 'астероида \nотносительно \nпланеты',
  spaceShipVisible: true,
  spaceShipVectorVisible: true,
  spaceShipVectorText: 'корабля \nотносительно \nпланеты',
  rocketVisible: true,
  rocketVectorVisible: true,
  rocketVectorText: 'ракеты \nотносительно \nкорабля',

  inputTextTitle: 'Скорость ракеты',
  inputTextSubtitle: 'относительно астероида',

  inputCardVisible: true,

  meteorContainerPosX: 600,
  spaceShipContainerPosX: 0,
  rocketContainerPosX: 100,

  checkStepReset: (comp) => {
    const shipCenterInters = hasIntersection(comp._getNode('spaceShipRect'), comp._getNode('centerRect'), comp);
    const meteorCenterIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('centerRect'), comp);
    const meteorRocketIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('rocketRect'), comp);

    if (shipCenterInters || meteorCenterIntersect) {
      comp.data.meteorPosX = 0;
      comp.data.spaceShipPosX = 0;
      comp.data.planetPosX = 0;
      comp.data.rocketPosX = 0;
    }
    if (meteorRocketIntersect) {
      comp.data.rocketPosX = comp.data.spaceShipPosX - 20;
    }
  }
}
const block3Def1Data = {
  meteorVisible: true,
  meteorVectorVisible: true,
  meteorVectorText: 'астероида \nотносительно \nпланеты',
  spaceShipVisible: true,
  spaceShipVectorVisible: true,
  spaceShipVectorText: 'корабля \nотносительно \nпланеты',
  rocketVisible: true,
  rocketVectorVisible: true,
  rocketVectorText: 'ракеты \nотносительно \nкорабля',

  inputTextTitle: 'Скорость ракеты',
  inputTextSubtitle: 'относительно астероида',

  inputCardVisible: true,

  meteorContainerPosX: 200,
  spaceShipContainerPosX: 600,
  rocketContainerPosX: 530,
  meteorScaleX: 1,
  spaceShipScaleX: -1,
  rocketScaleX: -1,

  checkStepReset: (comp) => {
    const shipCenterInters = hasIntersection(comp._getNode('spaceShipRect'), comp._getNode('centerRect'), comp);
    const meteorPlanetIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('planetRect'), comp);
    const meteorRocketIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('rocketRect'), comp);

    if (shipCenterInters || meteorPlanetIntersect) {
      comp.data.meteorPosX = 0;
      comp.data.spaceShipPosX = 0;
      comp.data.planetPosX = 0;
      comp.data.rocketPosX = 0;
    }
    if (meteorRocketIntersect) {
      comp.data.rocketPosX = comp.data.spaceShipPosX + 20;
    }
  }
}

export const scenario = [
  // commonScenario.videoStep,
  {
    key: 'block 1 step 1',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block1Def1Data,
      ...resetPositions,
      title: 'Необходимо откалибровать навигационное оборудование.\n' +
        'Для этого, введи скорость астероида относительно \nкорабля.',
      meteorVectorSpeedValue: -300,
      spaceShipVectorSpeedValue: 100,
      planetVectorSpeedValue: 0,
      checkBtnVisible: true,
      onClickCheckData: (comp) => {
        const data = comp.data;
        if (data.inputVal !== undefined) {
          if (data.inputVal === 400) {
            comp.scenarioManager.selectStepByKey('block 1 step 2');
          } else {
            data.errorCount++;
            if (data.errorCount < 2) {
              comp.showFailPopup();
              comp.scenarioManager.selectStepByKey('block 1 step 1');
            }
          }

          if (data.errorCount >= 2) {
            data.prevStep = 'block 1 step 1';
            data.inputVal = undefined;
            comp.scenarioManager.selectStepByKey('theory');
          }
        }
      },
    }
  },
  {
    key: 'block 1 step 2',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block1Def1Data,
      ...resetPositions,
      title: 'Перейди в систему отсчета корабля, чтобы убедиться \nв правильности расчетов',
      changePointOfViewBtnVisible: true,
      planetVectorSpeedValue: 0,

      changePointOfViewBtnText: 'Перейти в систему отсчета корабля',
      onClickChangePointOfViewBtnData: (comp) => {
        comp.scenarioManager.selectStepByKey('block 1 step 3')
      },
      inputVal: 400,
      inputDisabled: true
    }
  },
  {
    key: 'block 1 step 3',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block1Def1Data,
      ...resetPositions,
      title: 'При переходе в систему отсчета корабля, скорость\n' +
        'планеты будет 100 м/с в противоположном \nнаправлении, скорость астероида - 400 м/с',

      planetVectorVisible: true,
      planetVectorText: 'планеты \nотносительно \nкорабля',
      meteorVectorText: 'астероида \nотносительно \nкорабля',
      spaceShipVectorText: 'корабля \nотносительно \nкорабля',

      meteorVectorSpeedValue: -400,
      spaceShipVectorSpeedValue: 0,
      planetVectorSpeedValue: -100,
      spaceShipPosX: 0,

      okBtnVisible: true,
      onClickOkBtnData: (comp) => {
        comp.data.errorCount = 0;
        comp.data.inputVal = undefined;
        comp.scenarioManager.selectStepByKey('block 2 step 1');
      },

      inputVal: 400,
      inputDisabled: true
    }
  },


  {
    key: 'block 2 step 1',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block2Def1Data,
      ...resetPositions,
      title: 'Теперь откалибруем ракетное оборудование.\nДля этого, введи скорость ракеты относительно \nастероида.',

      meteorVectorSpeedValue: -300,
      spaceShipVectorSpeedValue: 100,
      rocketVectorSpeedValue: 800,
      planetVectorSpeedValue: 0,

      checkBtnVisible: true,
      onClickCheckData: (comp) => {
        const data = comp.data;
        if (data.inputVal !== undefined) {
          if (data.inputVal === 1200) {
            comp.scenarioManager.selectStepByKey('block 2 step 2');
          } else {
            data.errorCount++;
            if (data.errorCount < 2) {
              comp.showFailPopup();
              comp.scenarioManager.selectStepByKey('block 2 step 1');
            }
          }

          if (data.errorCount >= 2) {
            data.prevStep = 'block 2 step 1';
            data.inputVal = undefined;
            comp.scenarioManager.selectStepByKey('theory');
          }
        }
      },
    }
  },
  {
    key: 'block 2 step 2',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block2Def1Data,
      ...resetPositions,
      title: 'Перейди в систему отсчета астероида, чтобы убедиться \nв правильности расчетов',
      changePointOfViewBtnVisible: true,
      planetVectorSpeedValue: 0,

      changePointOfViewBtnText: 'Перейти в систему отсчета астероида',
      onClickChangePointOfViewBtnData: (comp) => {
        comp.scenarioManager.selectStepByKey('block 2 step 3')
      },
      inputVal: 1200,
      inputDisabled: true
    }
  },
  {
    key: 'block 2 step 3',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block2Def1Data,
      ...resetPositions,
      title: 'При переходе в систему отсчета астероида, скорость \nкорабля будет составлять 400 м/с,  скорость \nракеты - 1200 м/с',

      meteorVectorSpeedValue: 0,
      spaceShipVectorSpeedValue: 400,
      rocketVectorSpeedValue: 1200,
      planetVectorSpeedValue: 300,

      planetVectorVisible: true,
      planetVectorText: 'планеты \nотносительно \nастероида',
      meteorVectorText: 'астероида \nотносительно \nастероида',
      spaceShipVectorText: 'корабля \nотносительно \nастероида',
      rocketVectorText: 'ракеты \nотносительно \nастероида',

      okBtnVisible: true,
      onClickOkBtnData: (comp) => {
        comp.data.errorCount = 0;
        comp.data.inputVal = undefined;
        comp.scenarioManager.selectStepByKey('block 3 step 1');
      },

      inputVal: 1200,
      inputDisabled: true
    }
  },

  {
    key: 'block 3 step 1',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block3Def1Data,
      ...resetPositions,
      title: 'Продолжим калибровать ракетное оборудование.\nДля этого, введи скорость ракеты относительно \nастероида.',

      meteorVectorSpeedValue: -300,
      spaceShipVectorSpeedValue: -100,
      rocketVectorSpeedValue: -800,
      planetVectorSpeedValue: 0,
      checkBtnVisible: true,
      onClickCheckData: (comp) => {
        const data = comp.data;
        if (data.inputVal !== undefined) {
          if (data.inputVal === 600) {
            comp.scenarioManager.selectStepByKey('block 3 step 2');
          } else {
            data.errorCount++;
            if (data.errorCount < 2) {
              comp.showFailPopup();
              comp.scenarioManager.selectStepByKey('block 3 step 1');
            }
          }

          if (data.errorCount >= 2) {
            data.prevStep = 'block 3 step 1';
            data.inputVal = undefined;
            comp.scenarioManager.selectStepByKey('theory');
          }
        }
      },
    }
  },
  {
    key: 'block 3 step 2',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block3Def1Data,
      ...resetPositions,
      title: 'Перейди в систему отсчета астероида, чтобы убедиться \nв правильности расчетов',
      changePointOfViewBtnVisible: true,
      planetVectorSpeedValue: 0,

      changePointOfViewBtnText: 'Перейти в систему отсчета астероида',
      onClickChangePointOfViewBtnData: (comp) => {
        comp.scenarioManager.selectStepByKey('block 3 step 3')
      },
      inputVal: 600,
      inputDisabled: true
    }
  },
  {
    key: 'block 3 step 3',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block3Def1Data,
      ...resetPositions,
      title: 'При переходе в систему отсчета астероида, скорость \nкорабля будет составлять 200 м/с, скорость ракеты - \n600 м/с, скорость планеты 300 м/с',

      meteorVectorSpeedValue: 0,
      spaceShipVectorSpeedValue: 200,
      rocketVectorSpeedValue: -600,
      planetVectorSpeedValue: 300,

      planetVectorVisible: true,
      planetVectorText: 'планеты \nотносительно \nастероида',
      meteorVectorText: 'астероида \nотносительно \nастероида',
      spaceShipVectorText: 'корабля \nотносительно \nастероида',
      rocketVectorText: 'ракеты \nотносительно \nастероида',

      okBtnVisible: true,
      onClickOkBtnData: (comp) => {
        comp.data.prevStep = '';
        comp.data.errorCount = 0;
        comp.data.inputVal = undefined;
        comp.scenarioManager.selectStepByKey('theory');
      },

      inputVal: 600,
      inputDisabled: true
    }
  },

  commonScenario.theoryStep,

  {
    key: 'success',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      titleColor: '#00E2DF',

      finalPopupVisible: true,
      meteorVectorSpeedValue: 0,
      spaceShipVectorSpeedValue: 0,
      rocketVectorSpeedValue: 0,
      planetVectorSpeedValue: 0,
    }
  }
]




const block1Def2Data = {
  meteorVisible: true,
  meteorVectorVisible: true,
  meteorVectorText: 'астероида \nотносительно \nпланеты',
  spaceShipVisible: true,
  spaceShipVectorVisible: true,
  spaceShipVectorText: 'корабля \nотносительно \nпланеты',
  rocketVectorText: 'ракеты \nотносительно \nкорабля',

  meteorContainerPosX: 630,
  spaceShipContainerPosX: 30,
  distanceCoeff: 100,
  rocketContainerPosX: 150,
  checkStepReset: (comp) => {
    const shipMeteorInters = hasIntersection(comp._getNode('spaceShipRect'), comp._getNode('meteorRect'), comp);
    const rocketMeteorInters = hasIntersection(comp._getNode('rocketRect'), comp._getNode('meteorRect'), comp);

    const explosion = rocketMeteorInters || Math.round(comp.data.timer) === 10;
    if (explosion && comp.data.start) {
      if (comp.data.shootVal <= 6.1 && comp.data.shootVal >= 5.9) {
        comp.scenarioManager.success('block 1 step 3');
      } else {
        comp.scenarioManager.failure('block 1 step 3');
      }
    }
    if (shipMeteorInters) {
      comp.data = { ...comp.data, ...resetPositions }
    }
  }
}
const block2Def2Data = {
  meteorVisible: true,
  meteorVectorVisible: true,
  meteorVectorText: 'астероида \nотносительно \nпланеты',
  spaceShipVisible: true,
  spaceShipVectorVisible: true,
  spaceShipVectorText: 'корабля \nотносительно \nпланеты',
  rocketVectorText: 'ракеты \nотносительно \nкорабля',

  planetWidth: 110,
  distanceCoeff: 150,
  meteorContainerPosX: 400,
  spaceShipContainerPosX: 600,
  rocketContainerPosX: 500,
  spaceShipScaleX: -1,
  rocketScaleX: -1,

  meteorVectorSpeedValue: -300,
  spaceShipVectorSpeedValue: -100,
  rocketVectorSpeedValue: -800,

  checkStepReset: (comp) => {
    const planetMeteorInters = hasIntersection(comp._getNode('planetRect'), comp._getNode('meteorRect'), comp);
    const rocketMeteorInters = hasIntersection(comp._getNode('rocketRect'), comp._getNode('meteorRect'), comp);

    const explosion = rocketMeteorInters || Math.round(comp.data.timer) === 10;

    if (explosion && comp.data.start && comp.data.shoot) {
      if (comp.data.shootVal <= 3.1 && comp.data.shootVal >= 2.9) {
        comp.scenarioManager.success('block 2 step 3');
      } else {
        comp.scenarioManager.failure('block 2 step 3');
      }
    }
    if (planetMeteorInters) {
      comp.data.meteorPosX = 0;
      comp.data.spaceShipPosX = 0;
      comp.data.planetPosX = 0;
    }
  }
}
const block3Def2Data = {
  meteorVisible: true,
  meteorVectorVisible: true,
  meteorVectorText: 'астероида \nотносительно \nпланеты',
  spaceShipVisible: true,
  spaceShipVectorVisible: true,
  spaceShipVectorText: 'корабля \nотносительно \nпланеты',

  rocketVectorVisible: true,
  rocketVectorText: 'ракеты \nотносительно \nкорабля',

  meteorVectorSpeedValue: -300,
  spaceShipVectorSpeedValue: -100,
  rocketVectorSpeedValue: 800,

  meteorContainerPosX: 600,
  spaceShipContainerPosX: 298,
  rocketContainerPosX: 298,
  meteorScaleX: 1,
  spaceShipScaleX: -1,
  rocketScaleX: 1,

  checkStepReset: (comp) => {
    const meteorShipIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('spaceShipRect'), comp);
    const meteorRocketIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('rocketRect'), comp);

    const explosion = meteorRocketIntersect || Math.round(comp.data.timer) === 10;
    if (explosion) {
      if (comp.data.shootVal <= 5.1 && comp.data.shootVal >= 4.9) {
        comp.scenarioManager.success('block 3 step 3');
      } else {
        comp.scenarioManager.failure('block 3 step 3');
      }
    }

    if (meteorShipIntersect) {
      comp.data = { ...comp.data, ...resetPositions}
    }
  }
}

const getExplosionSteps = (blockNum) => {
  let title = '';
  let correctDefData = {};
  if (blockNum === 1) {
    title = 'В ракету встроен механизм, заставляющий её \nавтоматически взрываться через t = 5 секунд после выстрела.\nНужно сделать выстрел на нужном расстоянии от астероида';
    correctDefData = {...block1Def2Data}
  }
  else if (blockNum === 2) {
    title = 'В ракету встроен механизм, заставляющий её \nавтоматически взрываться через t = 5 секунд после выстрела.\nНужно сделать выстрел на нужном расстоянии от астероида';
    correctDefData = {...block2Def2Data}
  }
  else if (blockNum === 3) {
    title = 'Корабль опередил астероид, выстрел будет совершаться в \nпротивоположном движению корабля направлении.\nРассчитай расстояние, на котором необходимо сделать выстрел';
    correctDefData = {...block3Def2Data}
  }
  const correctData = {
    ...commonPatchData,
    ...defaultPatchData2,
    ...correctDefData,
    title: title, shoot: true,
    explosionVisible: true,
    rocketVectorSpeedValue: 0,
  }
  return [
    {
      key: `block ${blockNum} explosion failure`,
      patchData: {
        ...correctData,
        rocketVisible: false,
      },
      next_success: `block ${blockNum} failure`,
      switch_at: 1
    },
    {
      key: `block ${blockNum} failure`,
      patchData: {
        ...correctData,
        failureStepPopupVisible: true,
        failureInitialStep: `block ${blockNum} step 1`,
        prevStep: `block ${blockNum} failure`,
      },
    },
    {
      key: `block ${blockNum} explosion success`,
      patchData: {
        ...correctData,
        meteorVisible: false,
        rocketVisible: false,
        shootBtnVisible: true,
      },
      next_success: `block ${blockNum} success`,
      switch_at: 1
    },
    {
      key: `block ${blockNum} success`,
      patchData: {
        ...correctData,
        meteorVisible: false,
        successStepPopupVisible: true,
        prevStep: blockNum === 3 ? 'success' : `block ${blockNum} success`,
        rocketVisible: false,
      },
    },
  ]
}

export const scenario2 = [
  // commonScenario.videoStep,
  {
    key: 'block 1 step 1',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,
      ...block1Def2Data,
      ...resetPositions,
      title: 'В ракету встроен механизм, заставляющий её \nавтоматически взрываться через t = 5 секунд после выстрела.\nСкорость ракеты относительно корабля 800 м/с.\nНужно сделать выстрел на нужном расстоянии от астероида',

      start: false,
      startBtnVisible: true,
      timer: 0,

      meteorVectorSpeedValue: -300,
      spaceShipVectorSpeedValue: 100,
      rocketVectorSpeedValue: 800,
    }
  },
  {
    key: 'block 1 step 2',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,
      ...block1Def2Data,
      ...resetPositions,
      title: 'В ракету встроен механизм, заставляющий её \nавтоматически взрываться через t = 5 секунд после выстрела.\nНужно сделать выстрел на нужном расстоянии от астероида',

      rocketVectorVisible: true,

      shoot: false,

      pathInfoVisible: true,
      shootBtnVisible: true,
      pathValueText: 10.0,
    }
  },
  {
    key: 'block 1 step 3',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,
      ...block1Def2Data,
      title: 'В ракету встроен механизм, заставляющий её \nавтоматически взрываться через t = 5 секунд после выстрела.\nНужно сделать выстрел на нужном расстоянии от астероида',
      shoot: true,
      rocketVisible: true,
      rocketVectorVisible: true,
      pathInfoVisible: true,
      shootBtnVisible: true,
    },
    next_failure: 'block 1 explosion failure',
    next_success: 'block 1 explosion success',
  },
  ...getExplosionSteps(1),


  {
    key: 'block 2 step 1',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,
      ...block2Def2Data,
      ...resetPositions,

      title: 'В ракету встроен механизм, заставляющий её \nавтоматически взрываться через t = 5 секунд после выстрела.\nСкорость ракеты относительно корабля 800 м/с.\nНужно сделать выстрел на нужном расстоянии от астероида',
      timer: 0,
      start: false,
      startBtnVisible: true,
      pathValueText: 1.0,
    }
  },
  {
    key: 'block 2 step 2',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,
      ...block2Def2Data,
      ...resetPositions,

      title: 'В ракету встроен механизм, заставляющий её \nавтоматически взрываться через t = 5 секунд после выстрела.\nНужно сделать выстрел на нужном расстоянии от астероида',

      shoot: false,
      pathInfoVisible: true,
      shootBtnVisible: true,
      pathValueText: 1.0,
    }
  },
  {
    key: 'block 2 step 3',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,
      ...block2Def2Data,

      title: 'В ракету встроен механизм, заставляющий её \nавтоматически взрываться через t = 5 секунд после выстрела.\nНужно сделать выстрел на нужном расстоянии от астероида',

      rocketVisible: true,
      rocketVectorVisible: true,
      pathInfoVisible: true,
      shootBtnVisible: true,
    },
    next_failure: 'block 2 explosion failure',
    next_success: 'block 2 explosion success',
  },
  ...getExplosionSteps(2),


  {
    key: 'block 3 step 1',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,
      ...block3Def2Data,
      ...resetPositions,

      title: 'Корабль опередил астероид, выстрел будет совершаться в \nпротивоположном движению корабля направлении.\nРассчитай расстояние, на котором необходимо сделать выстрел',
      timer: 0,
      start: false,
      startBtnVisible: true,
    }
  },
  {
    key: 'block 3 step 2',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,
      ...block3Def2Data,

      title: 'Корабль опередил астероид, выстрел будет совершаться в \nпротивоположном движению корабля направлении.\nРассчитай расстояние, на котором необходимо сделать выстрел',
      shoot: false,
      pathInfoVisible: true,
      shootBtnVisible: true,
      pathValueText: 6.0,
    }
  },
  {
    key: 'block 3 step 3',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,
      ...block3Def2Data,
      title: 'Корабль опередил астероид, выстрел будет совершаться в \nпротивоположном движению корабля направлении.\nРассчитай расстояние, на котором необходимо сделать выстрел',
      rocketVisible: true,
    },
    next_failure: 'block 3 explosion failure',
    next_success: 'block 3 explosion success',
  },
  ...getExplosionSteps(3),

  commonScenario.theoryStep,
  {
    key: 'success',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData2,

      finalPopupVisible: true,
      meteorVectorSpeedValue: 0,
      spaceShipVectorSpeedValue: 0,
      rocketVectorSpeedValue: 0,
      planetVectorSpeedValue: 0,
    }
  }
]


const block1Def3Data = {
  meteorVisible: true,
  meteorVectorVisible: true,
  meteorVectorText: 'астероида \nотносительно \nпланеты',
  spaceShipVisible: true,
  spaceShipVectorVisible: true,
  spaceShipVectorText: 'корабля \nотносительно \nпланеты',

  inputTextTitle: 'Скорость астероида',
  inputTextSubtitle: 'относительно корабля',

  inputCardVisible: true,

  meteorContainerPosX: 600,
  spaceShipContainerPosX: 100,

  checkStepReset: (comp) => {
    const shipCenterInters = hasIntersection(comp._getNode('spaceShipRect'), comp._getNode('centerRect'), comp);
    const meteorCenterIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('centerRect'), comp);

    if (shipCenterInters || meteorCenterIntersect) {
      comp.data = { ...comp.data, ...resetPositions }
    }
  }
}
const block2Def3Data = {
  meteorVisible: true,
  meteorVectorVisible: true,
  meteorVectorText: 'астероида \nотносительно \nпланеты',
  spaceShipVisible: true,
  spaceShipVectorVisible: true,
  spaceShipVectorText: 'корабля \nотносительно \nпланеты',
  rocketVisible: true,
  rocketVectorVisible: true,
  // rocketVectorText: 'ракеты \nотносительно \nкорабля',
  rocketVectorText: 'ракеты \nотносительно \nпланеты',

  inputTextTitle: 'Скорость ракеты',
  inputTextSubtitle: 'относительно астероида',

  inputCardVisible: true,

  meteorContainerPosX: 600,
  spaceShipContainerPosX: 0,
  rocketContainerPosX: 100,

  checkStepReset: (comp) => {
    const shipCenterInters = hasIntersection(comp._getNode('spaceShipRect'), comp._getNode('centerRect'), comp);
    const meteorCenterIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('centerRect'), comp);
    const meteorRocketIntersect = hasIntersection(comp._getNode('meteorRect'), comp._getNode('rocketRect'), comp);

    if (shipCenterInters || meteorCenterIntersect) {
      comp.data.meteorPosX = 0;
      comp.data.spaceShipPosX = 0;
      comp.data.planetPosX = 0;
      comp.data.rocketPosX = 0;
    }
    if (meteorRocketIntersect) {
      comp.data.rocketPosX = comp.data.spaceShipPosX - 20;
    }
  }
}

export const scenario3 = [
  // commonScenario.videoStep,
  {
    key: 'block 1 step 1',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block1Def3Data,
      ...resetPositions,
      title: 'Необходимо откалибровать навигационное оборудование.\n' +
        'Для этого, введи скорость астероида относительно \nкорабля.',
      meteorVectorSpeedValue: -300,
      spaceShipVectorSpeedValue: 100,
      planetVectorSpeedValue: 0,
      checkBtnVisible: true,
      onClickCheckData: (comp) => {
        const data = comp.data;
        if (data.inputVal !== undefined) {
          if (data.inputVal === 400) {
            comp.scenarioManager.selectStepByKey('block 1 step 2');
          } else {
            data.errorCount++;
            if (data.errorCount < 2) {
              comp.showFailPopup();
              comp.scenarioManager.selectStepByKey('block 1 step 1');
            }
          }

          if (data.errorCount >= 2) {
            data.prevStep = 'block 1 step 1';
            data.inputVal = undefined;
            comp.scenarioManager.selectStepByKey('theory');
          }
        }
      },
    }
  },
  {
    key: 'block 1 step 2',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block1Def3Data,
      ...resetPositions,
      title: 'Перейди в систему отсчета корабля, чтобы убедиться \nв правильности расчетов',
      changePointOfViewBtnVisible: true,
      planetVectorSpeedValue: 0,

      changePointOfViewBtnText: 'Перейти в систему отсчета корабля',
      onClickChangePointOfViewBtnData: (comp) => {
        comp.scenarioManager.selectStepByKey('block 1 step 3')
      },
      inputVal: 400,
      inputDisabled: true
    }
  },
  {
    key: 'block 1 step 3',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block1Def3Data,
      ...resetPositions,
      title: 'При переходе в систему отсчета корабля, скорость\n' +
        'планеты будет 100 м/с в противоположном \nнаправлении, скорость астероида - 400 м/с',

      planetVectorVisible: true,
      planetVectorText: 'планеты \nотносительно \nкорабля',
      meteorVectorText: 'астероида \nотносительно \nкорабля',
      spaceShipVectorText: 'корабля \nотносительно \nкорабля',

      meteorVectorSpeedValue: -400,
      spaceShipVectorSpeedValue: 0,
      planetVectorSpeedValue: -100,
      spaceShipPosX: 0,

      okBtnVisible: true,
      onClickOkBtnData: (comp) => {
        comp.data.errorCount = 0;
        comp.data.inputVal = undefined;
        comp.scenarioManager.selectStepByKey('block 2 step 1');
      },

      inputVal: 400,
      inputDisabled: true
    }
  },


  {
    key: 'block 2 step 1',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block2Def3Data,
      ...resetPositions,
      title: 'Теперь откалибруем ракетное оборудование.\nДля этого, введи скорость ракеты относительно \nастероида.',

      meteorVectorSpeedValue: -300,
      spaceShipVectorSpeedValue: 100,
      rocketVectorSpeedValue: 800,
      planetVectorSpeedValue: 0,

      checkBtnVisible: true,
      onClickCheckData: (comp) => {
        const data = comp.data;
        if (data.inputVal !== undefined) {
          if (data.inputVal === 1100) {
            comp.scenarioManager.selectStepByKey('block 2 step 2');
          } else {
            data.errorCount++;
            if (data.errorCount < 2) {
              comp.showFailPopup();
              comp.scenarioManager.selectStepByKey('block 2 step 1');
            }
          }

          if (data.errorCount >= 2) {
            data.prevStep = 'block 2 step 1';
            data.inputVal = undefined;
            comp.scenarioManager.selectStepByKey('theory');
          }
        }
      },
    }
  },
  {
    key: 'block 2 step 2',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block2Def3Data,
      ...resetPositions,
      title: 'Перейди в систему отсчета астероида, чтобы убедиться \nв правильности расчетов',
      changePointOfViewBtnVisible: true,
      planetVectorSpeedValue: 0,

      changePointOfViewBtnText: 'Перейти в систему отсчета астероида',
      onClickChangePointOfViewBtnData: (comp) => {
        comp.scenarioManager.selectStepByKey('block 2 step 3')
      },
      inputVal: 1100,
      inputDisabled: true
    }
  },
  {
    key: 'block 2 step 3',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      ...block2Def3Data,
      ...resetPositions,
      title: 'При переходе в систему отсчета астероида, скорость \nкорабля будет составлять 400 м/с,  скорость \nракеты - 1100 м/с',

      meteorVectorSpeedValue: 0,
      spaceShipVectorSpeedValue: 400,
      rocketVectorSpeedValue: 1100,
      planetVectorSpeedValue: 300,

      planetVectorVisible: true,
      planetVectorText: 'планеты \nотносительно \nастероида',
      meteorVectorText: 'астероида \nотносительно \nастероида',
      spaceShipVectorText: 'корабля \nотносительно \nастероида',
      rocketVectorText: 'ракеты \nотносительно \nастероида',

      okBtnVisible: true,
      onClickOkBtnData: (comp) => {
        comp.data.errorCount = 0;
        comp.data.inputVal = undefined;
        comp.data.prevStep = '';
        comp.scenarioManager.selectStepByKey('theory');
      },

      inputVal: 1100,
      inputDisabled: true
    }
  },

  commonScenario.theoryStep,

  {
    key: 'success',
    patchData: {
      ...commonPatchData,
      ...defaultPatchData1,
      titleColor: '#00E2DF',

      finalPopupVisible: true,
      meteorVectorSpeedValue: 0,
      spaceShipVectorSpeedValue: 0,
      rocketVectorSpeedValue: 0,
      planetVectorSpeedValue: 0,
    }
  }
]
