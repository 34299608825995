import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Arrow, Group, Image, Layer, Line, Rect, Text} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import Card from '../../../canvas/components/Card';
import RadioSwitch from '../../../canvas/components/RadioSwitch';
import CanvasInput from '../../../canvas/components/CanvasInput';
import backgroundImg from '../../../../images/crane/background.png';
import wheelImg from '../../../../images/crane/wheel.png';
import boxImg from '../../../../images/crane/box.png';
import boxesImg from '../../../../images/crane/boxes.png';
import boxesCrane6Img from '../../../../images/crane/boxesCrane6.png';
import boxesCrane7Img from '../../../../images/crane/boxesCrane7.png';
import craneImg from '../../../../images/crane/crane.png';
import crossImg from '../../../../images/crane/cross.png';
import engineImg from '../../../../images/crane/engine.png';
import hookImg from '../../../../images/crane/hook.png';
import infoImg from '../../../../images/crane/info.png';
import * as actions from "../../../../store/actions";
import anime from "animejs";

import {connect} from "react-redux";
import {getBoolByLsnCode} from "../utils";
import {mainColor as _mainColor} from "../../../../utils/styles";
import ScenarioManager from "../../../../utils/ScenarioManager";
import MovingArrow from "../../../canvas/components/MovingArrow";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import {sendSuccessForScenario} from "../../../../utils/common";

const animation = anime.timeline({autoplay: false});

const finishAnimationOptions = {
  targets: '.crane',
  easing: 'linear',
  translateX: 0,
  translateY: 0,
  duration: 1000,
  delay: 100,
  rotate: 90,
};


class Crane extends React.Component {
  constructor(props) {
    super(props);
    const {
      isCrane1,
      isCrane6,
      isCrane7,
    } = getBoolByLsnCode(this.props.code);

    this.stageRef = React.createRef();

    this.inputWeightContainerRef = React.createRef();
    this.inputWeightValueRef = React.createRef();
    this.inputWeightHideRef = React.createRef();
    this.inputHintRef = React.createRef();
    this.inputHintTextRef = React.createRef();

    this.boxRef = React.createRef();
    this.redBoxRef = React.createRef();
    this.boxesOffsetRef = React.createRef();

    this.bigWheelRef = React.createRef();
    this.topLeftWheelRef = React.createRef();
    this.topRightWheelRef = React.createRef();
    this.bigWheelRef = React.createRef();

    this.singleCableRef = React.createRef();
    this.leftDoubleCableRef = React.createRef();
    this.rightDoubleCableRef = React.createRef();

    this.timeTextRef = React.createRef();
    this.weightCargoTextRef = React.createRef();
    this.completedWorkTextRef = React.createRef();
    this.liftingHeightTextRef = React.createRef();
    this.forceGravityTextRef = React.createRef();
    this.loadOnTheEngineTextRef = React.createRef();
    this.timeLiftingUpTextRef = React.createRef();
    this.maximumEngineLoadTextRef = React.createRef();

    this.hookRef = React.createRef();
    this.engineOffsetRef = React.createRef();

    this.leftSlingRef = React.createRef();
    this.rightSlingRef = React.createRef();

    this.startBtnRef = React.createRef();
    this.fistTextRef = React.createRef();
    this.fistTextTextRef = React.createRef();
    this.helpRef = React.createRef();
    this.helpTextRef = React.createRef();
    this.toBigPowerRef = React.createRef();

    // vectors
    this.vectorRopePullingForceRef = React.createRef();
    this.vectorRopePullingForceTextRef = React.createRef();

    this.vectorForceGravityRef = React.createRef();
    this.vectorForceGravityTextRef = React.createRef();
    this.numberForceGravityRef = React.createRef();


    this.state = {
      holdup: isCrane6 || isCrane7 ? 11 : 17,
      count: isCrane6 || isCrane7 ? 11 : 15,
      textPoint: 0,
      // lsnCode: ''
    };

    this.initialData = {
      start: false,
      startTime: 0,
      prevTime: 0,
      enginePower67: isCrane6 ? 1000 : 10000,
      enginePower: isCrane1 ? undefined : 100,
      // massOfCargo: 100,
      switchActive: false, // for switcher
      completedWorkQuestion: '?',

      completedWork: 0,
      liftingHeight: 0,
      forceGravity: 490,
      maximumEngineLoad: 120,

      // weightCargo: isCrane6 || isCrane7 ? 3000 : 50,
      weightCargo: undefined,

      time: isCrane6 || isCrane7 ? 10 : 15,

      CargoHorizontalV: 0,
      CargoVerticalV: 0,
      wheelVel: 0,
      leftWheelVel: 0,
      rightWheelVel: 0,
      engineOffsetX: 260,
      boxX: 240,
      boxY: 470,
      verevka: 360,
      numberLoadOnTheEngine: 0,
      numberTimeLiftingUp: 0,
      boxesOffsetRefX: 0,
      hookRefY: -55,
      redBoxRefX: 0,
      numberForceGravity: 0,
      count: isCrane6 || isCrane7 ? 11 : 15,
      countik: isCrane6 || isCrane7 ? 11 : 15,
      help: 0,
      bigPower: 0,
      opacityOfText: 0,
      hightCrane: 19.2,


    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.code !== this.props.code) {
      this.setState((prev) => ({...prev, lsnCode: this.props.code}), () => this.onClickReset())
    }
  }

  _movingCallbacks = {};
  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  openModal = () => {
    sendSuccessForScenario(this);
    this.props.changeSuccessVisible(true)
  };

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.firstTextInterval();
  }

//таймер
  doIntervalChange = () => {
    if (this.Interval) {
      clearInterval(this.Interval);
    }
    this.Interval = setInterval(() => {
      this.setState(prevState => ({
        count: prevState.count - 1
      }))
      if (this.state.count === 0) {
        clearInterval(this.Interval);
      }
    }, 1000)
  }
  firstTextInterval = () => {
    this.Interval = setInterval(() => {
      this.setState(prevState => ({
        textPoint: prevState.textPoint + 1
      }))
      if (this.state.textPoint === 100) {
        clearInterval(this.Interval);
      }
    }, 25)
  }

  intervalHoldup = () => {
    if (this.Intervall) {
      clearInterval(this.Intervall);
    }
    this.Intervall = setInterval(() => {
      this.setState(prevState => ({
        holdup: prevState.holdup - 1
      }))
      if (this.state.holdup === -1) {
        clearInterval(this.Intervall);
      }
    }, 1000)
  }


  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
    clearInterval(this.Interval);
    clearInterval(this.Intervall);
  };

  helpOnClick = () => {
    if (this.data.help === 1) {
      this.data.help = 0;
    } else {
      this.data.help = 1;
    }
  }

  //нажатие на кнопку старт
  startResetOnClick = (propData) => {
    const {
      isCrane1,
      isCrane2,
      isCrane3,
      isCrane4,
      isCrane5,
      isCrane6,
      isCrane7,
    } = getBoolByLsnCode(this.props.code);
    const data = this.data;

    if (isCrane6 || isCrane7) {
      if (data.weightCargo === undefined && !propData?.reset) {
        return data.inputHintTextVisible = true;
      } else {
        data.inputHintTextVisible = false;
      }
    }

    this.doIntervalChange();
    this.intervalHoldup();
    if (this.data.start || propData?.reset) {
      clearInterval(this.Interval);
      clearInterval(this.Intervall);
      this.data.start = false;
      // this.setState({countik: 15});
      this.setState({count: (isCrane6 || isCrane7) ? 11 : 15});
      this.setState({holdup: (isCrane6 || isCrane7) ? 13 : 17});
      this.setState({textPoint: 100});
      this.data = cloneDeep(this.initialData);
      this.forceUpdate();
    } else {
      this.data.start = true;
      this.setState({textPoint: 100});
    }
  };


  getNodes = () => ({
    inputWeightContainerNode: this.inputWeightContainerRef?.current,
    inputWeightValueNode: this.inputWeightValueRef?.current,
    inputWeightHideNode: this.inputWeightHideRef?.current,
    inputHintNode: this.inputHintRef?.current,
    inputHintTextNode: this.inputHintTextRef?.current,

    boxNode: this.boxRef?.current,
    redBoxNode: this.redBoxRef?.current,
    boxesOffsetRefNode: this.boxesOffsetRef?.current,
    hookRefNode: this.hookRef?.current,
    topRightWheelNode: this.topRightWheelRef?.current,
    topLeftWheelNode: this.topLeftWheelRef?.current,
    bigWheelNode: this.bigWheelRef?.current,

    singleCableNode: this.singleCableRef?.current,
    leftDoubleCableNode: this.leftDoubleCableRef?.current,
    rightDoubleCableNode: this.rightDoubleCableRef?.current,

    timeTextNode: this.timeTextRef?.current,
    weightCargoTextNode: this.weightCargoTextRef?.current,
    completedWorkTextNode: this.completedWorkTextRef?.current,
    liftingHeightTextNode: this.liftingHeightTextRef?.current,
    forceGravityTextNode: this.forceGravityTextRef?.current,
    loadOnTheEngineTextNode: this.loadOnTheEngineTextRef?.current,
    timeLiftingUpTextNode: this.timeLiftingUpTextRef?.current,
    maximumEngineLoadTextNode: this.maximumEngineLoadTextRef?.current,

    engineOffsetNode: this.engineOffsetRef?.current,
    leftSlingNode: this.leftSlingRef?.current,
    rightSlingNode: this.rightSlingRef?.current,

    vectorRopePullingForceNode: this.vectorRopePullingForceRef?.current,
    vectorRopePullingForceTextNode: this.vectorRopePullingForceTextRef?.current,
    vectorForceGravityNode: this.vectorForceGravityRef?.current,
    vectorForceGravityTextNode: this.vectorForceGravityTextRef?.current,
    numberForceGravityNode: this.numberForceGravityRef?.current,
    fistTextNode: this.fistTextRef?.current,
    fistTextTextNode: this.fistTextTextRef?.current,
    helpNode: this.helpRef?.current,
    helpTextNode: this.helpTextRef?.current,
    toBigPowerNode: this.toBigPowerRef?.current,
  });


  move = (time) => {
    const {
      isCrane1,
      isCrane2,
      isCrane3,
      isCrane4,
      isCrane5,
      isCrane6,
      isCrane7,
    } = getBoolByLsnCode(this.props.code);
    const {count} = this.state;
    const {holdup} = this.state;
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    if(isCrane6 || isCrane7){
      this.data.enginePower = this.data.enginePower67;
    }

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    if(!isCrane6 && !isCrane7){data.count = (15 - Math.trunc(((data.prevTime - data.startTime)) / 1000));}
    if(isCrane6 || isCrane7){data.count = (11 - Math.trunc(((data.prevTime - data.startTime)) / 1000));}


    if (data.enginePower >= 10000) {
      data.enginePower = 10000
    }
    if (data.enginePower <= 10) {
      data.enginePower = 10
    }

    let weightCargo = data.weightCargo !== undefined ? data.weightCargo : (isCrane6 || isCrane7 ? 3000 : 50);
    const minWeight = 50;
    if (weightCargo <= minWeight) {
      weightCargo = minWeight;
    }

    //натяжка канатов в первую секунду
    if (
      data.start &&
      data.engineOffsetX < 322 &&
      (
        (data.count === 15 && (!isCrane6 && !isCrane7)) ||
        (data.count === 11 && (isCrane6 || isCrane7))
      )
    ) {

      data.startTime = data.startTime || time;
      const timedelta = data.prevTime ? time - data.prevTime : 0;
      data.prevTime = time;
      const timedeltaSec = timedelta / 1000;

      if(!isCrane6 && !isCrane7) {data.hightCrane = 19.2}
      if(isCrane6){data.hightCrane = 25.4;}
      if(isCrane7){data.hightCrane = 12.2;}
      let t = weightCargo * 9.8 * data.hightCrane;
      let hookVelocity = (weightCargo*25) / t;
      data.verevka = data.verevka - hookVelocity;
      data.hookRefY = data.hookRefY - hookVelocity;
      data.numberForceGravity = data.numberForceGravity + 8;

      if (data.switchActive === false && data.numberLoadOnTheEngine < 490) {
        data.numberLoadOnTheEngine = data.numberLoadOnTheEngine + 3;
      }
      if (data.switchActive === true && data.numberLoadOnTheEngine < 245) {
        data.numberLoadOnTheEngine = data.numberLoadOnTheEngine + 1;
      }
    }
    // движение груза после натяжки канатов
    if (
      data.start &&
      data.engineOffsetX < 322 &&
      data.count <= 14 &&
      data.count > 0
    ) {

      data.startTime = data.startTime || time;
      const timedelta = data.prevTime ? time - data.prevTime : 0;
      data.prevTime = time;
      const timedeltaSec = timedelta / 1000;

      if(!isCrane6 && !isCrane7) {data.hightCrane = 7.5}
      if(isCrane6){data.hightCrane = 10;}
      if(isCrane7){data.hightCrane = 5;}

      let t = weightCargo * 9.8 * data.hightCrane / data.enginePower;
      let hookVelocity = isCrane1 ? (5.5 / t) : (3.8 / t);
      data.CargoHorizontalV = 6.2 / t;
      if(!isCrane6 && !isCrane7){data.CargoVerticalV = 19.1 / t;}
      if(isCrane6){data.CargoVerticalV = 25.4 / t;}
      if(isCrane7){data.CargoVerticalV = 12.2 / t;}
      data.numberForceGravity = 490;
      data.engineOffsetX = data.engineOffsetX + data.CargoHorizontalV * timedelta / 100;
      data.boxX = data.boxX + data.CargoHorizontalV * timedelta / 100;
      data.boxY = data.boxY - data.CargoVerticalV * timedelta / 100;
      data.verevka = data.verevka - hookVelocity;
      data.liftingHeight = Math.trunc(Math.abs(data.boxY - 469) / 2.51) / 10;
      data.completedWork = Math.trunc(data.liftingHeight * weightCargo * 9.8);
      data.leftWheelVel = -data.boxY;
      data.rightWheelVel = data.boxY * 1;
      data.numberTimeLiftingUp = 14 - data.count;
      if (data.switchActive === false) {
        data.numberLoadOnTheEngine = Math.trunc(9.8 * 50);
      }
      if (data.switchActive === true) {
        data.numberLoadOnTheEngine = Math.trunc(9.8 * 50 / 2);
      }


    }
    if (data.completedWorkQuestion > (data.liftingHeight * weightCargo * 9.7) && data.completedWorkQuestion < (data.liftingHeight * weightCargo * 10.1)) {
      this.openModal();
    }
    //движение катера с грузами
    if (this.state.count === 0) {

      data.boxesOffsetRefX = data.boxesOffsetRefX + 1;
      if (data.engineOffsetX < 322 && holdup === 0) {
        !this.props.failureModalVisible && this.props.changeFailureVisible(true);
      }
      if (data.engineOffsetX > 322) {
        data.redBoxRefX = data.redBoxRefX + 1;
      }
    }


    if (!isCrane2 && data.engineOffsetX > 321 && data.engineOffsetX < 322 && data.count <= 2) {
      animation.add(finishAnimationOptions);
      animation.play();
      this.openModal();
    }

    if (data.engineOffsetX > 321) {
      data.numberLoadOnTheEngine = 0;
    }

    if (data.switchActive === true) {
      data.wheelVel = this.data.boxY * 2;
      data.leftWheelVel = 0;
      data.rightWheelVel = data.boxY * 2;
    }

    this.updateStage();
  };

  updateStage() {
    const {} = this.props;
    const data = this.data;
    const startBtnTextNode = this.startBtnRef?.current;
    const {
      isCrane1,
      isCrane2,
      isCrane3,
      isCrane4,
      isCrane5,
      isCrane6,
      isCrane7,
    } = getBoolByLsnCode(this.props.code);

    const {
      boxNode,
      redBoxNode,
      boxesOffsetRefNode,
      hookRefNode,
      topRightWheelNode,
      topLeftWheelNode,
      bigWheelNode,
      singleCableNode,
      leftDoubleCableNode,
      rightDoubleCableNode,
      timeTextNode,
      weightCargoTextNode,
      completedWorkTextNode,
      liftingHeightTextNode,
      forceGravityTextNode,
      loadOnTheEngineTextNode,
      timeLiftingUpTextNode,
      maximumEngineLoadTextNode,

      engineOffsetNode,
      leftSlingNode,
      rightSlingNode,

      vectorRopePullingForceNode,
      vectorRopePullingForceTextNode,
      vectorForceGravityNode,
      vectorForceGravityTextNode,
      fistTextNode,
      fistTextTextNode,
      helpNode,
      helpTextNode,
      toBigPowerNode,
      inputHintTextNode,
      inputHintNode,
      inputWeightHideNode,
      inputWeightValueNode,
      inputWeightContainerNode
    } = this.getNodes();

    let weightCargo = data.weightCargo !== undefined ? data.weightCargo : (isCrane6 || isCrane7 ? 3000 : 50);

    engineOffsetNode.x(this.data.engineOffsetX);
    boxNode.x(this.data.boxX);
    boxNode.y(this.data.boxY);
    topRightWheelNode.rotation(data.rightWheelVel);

    topLeftWheelNode.rotation(this.data.leftWheelVel);
    bigWheelNode.rotation(this.data.wheelVel);

    boxesOffsetRefNode.x(this.data.boxesOffsetRefX);
    hookRefNode.y(this.data.hookRefY);
    redBoxNode.x(this.data.redBoxRefX);
    leftSlingNode.points([-60, 27, 0, this.data.hookRefY + 55]);
    rightSlingNode.points([0, this.data.hookRefY + 55, 60, 27]);
    singleCableNode.points([0, 0, 0, this.data.boxY - 120]);
    leftSlingNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'grey');
    rightSlingNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'grey');

    inputHintTextNode &&
      inputHintTextNode.visible(!!data.inputHintTextVisible);

    if (vectorRopePullingForceNode) {
      vectorRopePullingForceNode.points([0, this.data.hookRefY + 52, 0, -25 + (2.3 * this.data.hookRefY + 115)]);
      vectorRopePullingForceNode.fill(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
      vectorRopePullingForceNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    }
    if (vectorForceGravityNode) {
      vectorForceGravityNode.points([0, this.data.hookRefY + 70, 0, weightCargo - (this.data.hookRefY + 60)]);
      vectorForceGravityNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
      vectorForceGravityNode.fill(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    }
    if (vectorRopePullingForceTextNode) {
      vectorRopePullingForceTextNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
      vectorRopePullingForceTextNode.fill(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    }
    if (vectorForceGravityTextNode) {
      vectorForceGravityTextNode.stroke(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
      vectorForceGravityTextNode.fill(data.engineOffsetX > 322 ? 'transparent' : 'mainColor');
    }
    leftDoubleCableNode &&
      leftDoubleCableNode.points([0, 0, 0, this.data.boxY - 200]);
    rightDoubleCableNode &&
      rightDoubleCableNode.points([0, 0, 0, this.data.boxY - 200]);
    // forceGravityTextNode.text(this.data.numberForceGravity);


    if (isCrane6 || isCrane7) {
      inputWeightValueNode.visible(data.start);
      inputWeightContainerNode.visible(!data.start);
    }


    startBtnTextNode.text(data.start ? 'Сбросить' : 'Проверить');

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    // set text from data
    completedWorkTextNode.text(data.completedWork);
    liftingHeightTextNode.text(data.liftingHeight);
    forceGravityTextNode.text(data.numberForceGravity);
    loadOnTheEngineTextNode.text(data.numberLoadOnTheEngine);
    timeLiftingUpTextNode.text(data.numberTimeLiftingUp);
    if(!isCrane6 && !isCrane7){weightCargoTextNode.text(weightCargo);}

    if(!isCrane6 && !isCrane7){timeTextNode.text(this.state.count);}
    if((isCrane6||isCrane7) && this.state.count < 10){timeTextNode.text(this.state.count);}
    if((isCrane6||isCrane7) && this.state.count >= 10){timeTextNode.text(10);}

    // if (isCrane2) {
    //   fistTextTextNode.text('Определи, зависит ли работа по подъему груза\nна корабль от мощности двигателя?');
    // }
    // if (isCrane3) {
    //   fistTextTextNode.text('Какова может быть роль блока, используемого на кране?\nЧто меняется когда блок включен/выключен?\nМеняется ли работа, совершенная двигателем\nот того, включен блок или выключен?');
    //   fistTextTextNode.x(10);
    // }


    if ((isCrane1 || isCrane2 || isCrane3 || isCrane6 || isCrane7) && this.state.textPoint <= 100) {
      fistTextNode.opacity(this.state.textPoint / 100);
    }
    // if (this.state.textPoint > 200 && (isCrane1 || isCrane2 || isCrane3)) {
    //   fistTextNode.opacity(1 - ((this.state.textPoint - 200) / 100));
    // }
    if (isCrane1 || isCrane2 || isCrane3) {
      helpNode.opacity(this.data.help);
    }

    // if (isCrane2) {
    //   helpTextNode.text('Соверши подъем несколько раз, устанавливая разную мощность двигателя');
    // }
    // if (isCrane3) {
    //   helpTextNode.text('Соверши подъем несколько раз, включая и выключая блок');
    // }

    if ((isCrane1 && data.engineOffsetX > 321 && data.engineOffsetX < 322 && data.count <= 2) || (isCrane1 && data.engineOffsetX < 321)) {
      toBigPowerNode.opacity(0);
    }
    if (isCrane1 && data.engineOffsetX > 321 && data.engineOffsetX < 322 && data.count > 2) {
      toBigPowerNode.opacity(1);
    }


    inputHintNode &&
      inputHintNode.visible(!data.start);

    if(this.data.opacityOfText === 1 && (isCrane1 || isCrane2 ||isCrane3)){
      fistTextNode.opacity(0);
    }


    stageNode.draw();
  }


  Canvas = () => {
    const data = this.data;

    const {
      isCrane1,
      isCrane2,
      isCrane3,
      isCrane4,
      isCrane5,
      isCrane6,
      isCrane7,
    } = getBoolByLsnCode(this.props.code);

    const [background] = useImage(backgroundImg);
    const [wheel] = useImage(wheelImg);
    const [box] = useImage(boxImg);
    const [boxes] = useImage(boxesImg);
    const [boxesCrane6] = useImage(boxesCrane6Img);
    const [boxesCrane7] = useImage(boxesCrane7Img);
    const [crane] = useImage(craneImg);
    const [cross] = useImage(crossImg);
    const [engine] = useImage(engineImg);
    const [hook] = useImage(hookImg);
    const [info] = useImage(infoImg);

    const mainColor = _mainColor;

    const titleSetting = {
      stroke: mainColor, fill: mainColor, strokeWidth: .2,
      fontSize: 13
    }
    const valueSettings = {
      stroke: mainColor, fill: mainColor, strokeWidth: .2,
      fontSize: 25
    }
    const secondTextColor = '#e2856b';
    return (
      <React.Fragment>
        <Group y={-140} onClick={() => this.data.opacityOfText = 1} onTap={() => this.data.opacityOfText = 1}>
          <Image image={background} width={1000} height={700}
          />

          {!isCrane6 && !isCrane7 ? <Group ref={this.boxesOffsetRef} x={this.data.engineOffsetX}>
            <Image image={boxes} x={160} y={320} />
          </Group> : null}
          {isCrane6 ? <Group ref={this.boxesOffsetRef} x={this.data.engineOffsetX}>
            <Image image={boxesCrane6} x={160} y={320} />
          </Group> : null}
          {isCrane7 ? <Group ref={this.boxesOffsetRef} x={this.data.engineOffsetX}>
            <Image image={boxesCrane7} x={160} y={320} />
          </Group> : null}

          <Group x={70} y={170}>
            <Image image={crane} />
            <Group x={260} ref={this.engineOffsetRef}>


              <Group x={2} y={55}>

                {/*  isCrane3 || isCrane5 */}
                <Group opacity={Number(isCrane3 || isCrane5)}>
                  <Line points={[0, 0, 0, this.data.verevka - 90]} stroke={'gray'} x={21} y={10} strokeWidth={1.5}
                        ref={this.leftDoubleCableRef}/>
                  <Line points={[0, 0, 0, this.data.verevka - 90]} stroke={'gray'} x={60} y={10} strokeWidth={1.5}
                        ref={this.rightDoubleCableRef}/>
                </Group>

                {/*  isCrane1 и isCrane2  и isCrane4 */}

                <Line points={[0, 0, 0, this.data.verevka]} stroke={'gray'} x={40} y={10} strokeWidth={1.5}
                      ref={this.singleCableRef} opacity={Number(!isCrane3 && !isCrane5)}/>

                <Image x={isCrane3 || isCrane5 ? 20 : 0} image={engine}/>
              </Group>

              <Group x={isCrane3 || isCrane5 ? 20 : 0} y={70}>

                {/*  isCrane3 || isCrane5 */}
                <Image opacity={Number(isCrane3 || isCrane5)} image={wheel} x={10} y={10} offsetX={10} offsetY={10}
                       ref={this.topLeftWheelRef}/>

                <Image image={wheel} x={35} y={10} offsetX={10} offsetY={10} ref={this.topRightWheelRef}/>
              </Group>
            </Group>

            <Group x={240} y={470} ref={this.boxRef}>
              {/*  isCrane3 || isCrane5*/}
              <Line opacity={Number(isCrane3 || isCrane5)} points={[0, 0, 0, 100]} stroke={'gray'} x={62} y={-140}
                    strokeWidth={1.5}/>
              <Image opacity={Number(isCrane3 || isCrane5)} image={wheel} width={50} height={55} x={62} y={-140}
                     offsetX={25} offsetY={25} ref={this.bigWheelRef}/>


              <Line points={[-60, 27, 0, this.data.hookRefY + 55]} stroke={'grey'} x={63} y={-25} strokeWidth={1.5}
                    ref={this.leftSlingRef}/>
              <Image image={hook} x={55} y={this.hookRefY} ref={this.hookRef}/>
              <Line points={[0, this.data.hookRefY + 55, 60, 27]} stroke={'gray'} x={63} y={-25} strokeWidth={1.5}
                    ref={this.rightSlingRef}/>

              <Image image={box} ref={this.redBoxRef}/>

              {/*  isCrane3 || isCrane2 || isCrane4 || isCrane5 */}
              <Group x={61} y={-50} opacity={Number(isCrane3 || isCrane2 || isCrane4 || isCrane5)}>
                <Group>
                  <Arrow ref={this.vectorRopePullingForceRef} points={[0, 0, 0, -40]} stroke={mainColor}
                         fill={mainColor}/>
                  <Text
                    ref={this.vectorRopePullingForceTextRef}
                    x={-90} y={-45}
                    // text={'Сила натяжения каната'}
                    width={75} strokeWidth={.1}
                    stroke={mainColor}
                    fill={mainColor}
                    align={'right'}
                  />
                </Group>

                <Group y={10}>
                  <Arrow ref={this.vectorForceGravityRef} points={[0, 0, 0, 40]} stroke={mainColor} fill={mainColor}/>
                  <Text
                    ref={this.vectorForceGravityTextRef}
                    x={-90} y={15}
                    // text={'Сила натяжения каната'}
                    width={75} strokeWidth={.1}
                    stroke={mainColor}
                    fill={mainColor}
                    align={'right'}
                  />
                </Group>
              </Group>

              {/*  isCrane2 || isCrane3 || isCrane4 || isCrane5 */}
              <Group x={150} y={-215} opacity={isCrane2 || isCrane3 || isCrane4 || isCrane5}>
                <Card height={270} width={130}>
                  <Group x={15} y={15} opacity={isCrane2 || isCrane3}>
                    <Text text={`Выполненная \nработа, кДж:`} {...titleSetting} stroke={secondTextColor}
                          fill={secondTextColor}/>
                    <Text ref={this.completedWorkTextRef} y={27} text={`0`} {...valueSettings}
                          stroke={secondTextColor} fill={secondTextColor}/>
                  </Group>
                  <Group x={15} y={15} opacity={isCrane4 || isCrane5}>
                    <Text text={`Выполненная \nработа, кДж:`} {...titleSetting} stroke={secondTextColor}
                          fill={secondTextColor}/>
                    <CanvasInput
                      y={27}
                      width={90}
                      height={30}
                      stage={this.stageRef?.current}
                      textColor={secondTextColor}
                      onInput={(val) => data.completedWorkQuestion = val}
                      value={data.completedWorkQuestion}
                    />
                  </Group>
                  <Group x={15} y={80}>
                    <Text text={`Высота \nподъема, м:`} {...titleSetting} stroke={secondTextColor}
                          fill={secondTextColor}/>
                    <Text ref={this.liftingHeightTextRef} y={27} text={`0`} {...valueSettings}
                          stroke={secondTextColor} fill={secondTextColor}/>
                  </Group>

                  <Group x={15} y={145}>
                    {isCrane3 ?
                      <Text text={`Нагрузка на\nдвигатель, кН:`} {...titleSetting} stroke={secondTextColor}
                            fill={secondTextColor}/>
                      :
                      <Text text={`Сила\nтяжести, кН:`} {...titleSetting} stroke={secondTextColor}
                            fill={secondTextColor}/>
                    }
                    <Text ref={this.forceGravityTextRef} y={27} text={`490`} {...valueSettings}
                          stroke={secondTextColor} fill={secondTextColor} opacity={isCrane3 ? 0 : 1}/>
                    <Text ref={this.loadOnTheEngineTextRef} y={27} text={`12`} {...valueSettings}
                          stroke={secondTextColor} fill={secondTextColor} opacity={isCrane3 ? 1 : 0}/>
                  </Group>
                  <Group x={15} y={210}>
                    <Text text={`Время\nподъема, сек:`} {...titleSetting} stroke={secondTextColor}
                          fill={secondTextColor}/>
                    <Text ref={this.timeLiftingUpTextRef} y={27} text={`0`} {...valueSettings}
                          stroke={secondTextColor} fill={secondTextColor}/>
                  </Group>
                </Card>
              </Group>
            </Group>
          </Group>


          <Group x={820} y={180}>

            {!isCrane6 && !isCrane7 ? <Card height={isCrane3 || isCrane5 ? 440 : 350}>

              <Group x={20} y={20}>
                <Text text={`Вес груза, т:`} {...titleSetting}/>
                <Text y={13} text={`50`} {...valueSettings} ref={this.weightCargoTextRef}/>
              </Group>

              <Group x={20} y={75}>
                <Text text={`Время до \nотплытия, сек:`} {...titleSetting}/>
                <Text y={27} text={`15`}{...valueSettings} ref={this.timeTextRef}/>
              </Group>
              <Group x={20} y={150}>
                <Text text={`Высота\nконтейнера, м:`} {...titleSetting}/>
                <Text y={27} text={`2.5`}{...valueSettings} />
              </Group>
              <Group x={20} y={220}>
                <Text text={`Время натяжения\nканата, сек:`} {...titleSetting}/>
                <Text y={27} text={`1`}{...valueSettings} />
              </Group>

              {/*  isCrane3|| isCrane5 */}
              <Group opacity={Number(isCrane3 || isCrane5)}>
                <Group x={20} y={150}>
                  <Text text={`Высота\nконтейнера, м:`} {...titleSetting}/>
                  <Text y={27} text={`2.5`}{...valueSettings} />
                </Group>
                <Group x={20} y={220}>
                  <Text text={`Время натяжения\nканата, сек:`} {...titleSetting}/>
                  <Text y={27} text={`1`}{...valueSettings} />
                </Group>

                <Group y={290}>
                  <Rect width={110} height={2} fill={'darkgray'} x={20}/>

                  <Group y={20}>
                    <Group x={20} y={0}>
                      <Text text={`Включить блок:`} {...titleSetting}/>
                      <RadioSwitch
                        y={15}
                        stageNode={this.stageRef?.current}
                        switchFieldName={'switchActive'}
                        externalData={data}
                        onClick={() => data.switchActive = !data.switchActive}
                      />
                    </Group>
                  </Group>

                </Group>
              </Group>


              <Group
                x={20} y={isCrane3 || isCrane5 ? 370 : 280}
              >
                <Text text={`Мощность \nдвигателя, кВт:`} {...titleSetting}/>
                <CanvasInput
                  y={28}
                  width={90}
                  height={30}
                  stage={this.stageRef?.current}
                  textColor={valueSettings.fill}
                  onInput={(val) => data.enginePower = val}
                  value={data.enginePower}
                />
              </Group>
            </Card> : null}
            {isCrane6 || isCrane7 ? <Card height={290}>

              <Group x={20} y={150}>
                <Text text={`Время до \nотплытия, сек:`} {...titleSetting}/>
                <Text y={27} text={`15`}{...valueSettings} ref={this.timeTextRef}/>
              </Group>
              <Group x={20} y={85}>
                <Text text={`Высота\nконтейнера, м:`} {...titleSetting}/>
                <Text y={27} text={`2.5`}{...valueSettings} />
              </Group>

              <Group
                x={20} y={20}
              >
                <Text text={isCrane6 ? `Мощность \nдвигателя, Вт:` : `Мощность \nдвигателя, кВт:`} {...titleSetting}/>
                <Text y={27} text={isCrane6 ? `1000` : '10'}{...valueSettings} />
              </Group>

              <Group x={20} y={220}>
                <MovingArrow
                  id={'hint1'}
                  ref={this.inputHintRef}
                  textRef={this.inputHintTextRef}
                  stageNode={this.stageRef?.current}
                  length={40}
                  arrowsCount={1}
                  x={-60} y={43}
                  textX={-20} textY={40}
                  textColor={'white'}
                  fontStyle={'bold'}
                  // arrowColor={'gray'}
                  text={'Введи \nзначение'}
                  rotation={-90}
                  visible={isCrane6 || isCrane7}
                  getMovingCallback={this.getMovingCallback}
                />


                <Text text={`Максимальная\nмасса груза, кг:`} {...titleSetting}/>
                <CanvasInput
                  y={28}
                  width={90}
                  height={30}
                  containerRef={this.inputWeightContainerRef}
                  stage={this.stageRef?.current}
                  textColor={valueSettings.fill}
                  inputHideRef={this.inputWeightHideRef}
                  onInput={(val) => data.weightCargo = val || undefined}
                  value={data.weightCargo}
                />
                <Text
                  ref={this.inputWeightValueRef}
                  y={28}
                  fontSize={30}
                  text={`${data.weightCargo}`}
                  fill={valueSettings.fill}
                />

              </Group>
            </Card> : null}
          </Group>

          <Group
            x={850} y={isCrane3 || isCrane5 ? 630 : 540}
            onClick={() => this.startResetOnClick()}
          >
            <Rect width={120} height={50} fill={'#1b3080'} cornerRadius={10}/>
            <Text ref={this.startBtnRef} text={'Проверить'} stroke={'white'} fill={'white'} strokeWidth={1} fontSize={20}
                  align={'center'} verticalAlign={'middle'} width={120} height={50}/>
          </Group>


        </Group>


        {isCrane1 ? <Group ref={this.fistTextRef}>
          <Card height={110} width={470} x={250} y={220}>
            <Text
              text={'Определи минимальную мощность двигателя\nкрана, которая позволит загрузить контейнер\nна корабль до его отправления'}
              stroke={'#406C9C'}
              fill={'#406C9C'}
              lineHeight={1.3}
              strokeWidth={.4}
              fontSize={20}
              x={20}
              y={15}
              ref={this.fistTextTextRef}
            >
            </Text>
          </Card>
        </Group> : null}
        {isCrane2 ? <Group ref={this.fistTextRef}>
          <Card height={80} width={485} x={250} y={220}>
            <Text text={'Определи, зависит ли работа по подъему груза\nна корабль от мощности двигателя?'}
                  stroke={'#406C9C'}
                  fill={'#406C9C'}
                  lineHeight={1.3}
                  strokeWidth={.4}
                  fontSize={20}
                  x={20}
                  y={15}
                  ref={this.fistTextTextRef}
            >
            </Text>
          </Card>
        </Group> : null}
        {isCrane3 ? <Group ref={this.fistTextRef}>
          <Card height={140} width={570} x={220} y={220}>
            <Text
              text={'Какова может быть роль блока, используемого на кране?\nЧто меняется когда блок включен/выключен?\nМеняется ли работа, совершенная двигателем\nот того, включен блок или выключен?'}
              stroke={'#406C9C'}
              fill={'#406C9C'}
              lineHeight={1.3}
              strokeWidth={.4}
              fontSize={20}
              x={20}
              y={20}
              ref={this.fistTextTextRef}
            >
            </Text>
          </Card>
        </Group> : null}
        {isCrane6 || isCrane7 ? <Group ref={this.fistTextRef} x={510} y={10}>
          {/*<Card height={85} width={570} >*/}
          <Text
            text={'Определи максимальный груз, \nкоторый может загрузить\nкран данной мощности \nдо отправления корабля'}
            stroke={'#406C9C'}
            fill={'#406C9C'}
            lineHeight={1.3}
            strokeWidth={.4}
            fontSize={18}
            x={20}
            y={20}
            ref={this.fistTextTextRef}
          />
          {/*</Card>*/}
        </Group> : null}

        {isCrane1 || isCrane2 || isCrane3 ? <Group
          onClick={() => this.helpOnClick()}>
          <Card height={40} width={140} x={25} y={500} fill={'#406C9C'}>
            <Text text={'? Подсказка'}
                  stroke={'#ffffff'}
                  fill={'#ffffff'}
                  lineHeight={1.3}
                  strokeWidth={.4}
                  fontSize={20}
                  x={10}
                  y={8}
            >
            </Text>
          </Card>
        </Group> : null}

        {isCrane1 ? <Group
          ref={this.helpRef}>
          <Card height={80} width={830} x={25} y={20} fill={'#ffffff'}>
            <Text
              text={'Минимальная мощность обеспечит подъем груза за максимально допустимое время.\nНачни с расчета максимального времени подъема'}
              stroke={'#406C9C'}
              fill={'#406C9C'}
              lineHeight={1.3}
              strokeWidth={.4}
              fontSize={20}
              x={20}
              y={15}
              ref={this.helpTextRef}
            >
            </Text>
          </Card>
        </Group> : null}
        {isCrane2 ? <Group
          ref={this.helpRef}>
          <Card height={55} width={750} x={25} y={20} fill={'#ffffff'}>
            <Text
              text={'Соверши подъем несколько раз, устанавливая разную мощность двигателя'}
              stroke={'#406C9C'}
              fill={'#406C9C'}
              lineHeight={1.3}
              strokeWidth={.4}
              fontSize={20}
              x={20}
              y={15}
              ref={this.helpTextRef}
            >
            </Text>
          </Card>
        </Group> : null}
        {isCrane3 ? <Group
          ref={this.helpRef}>
          <Card height={55} width={590} x={25} y={20} fill={'#ffffff'}>
            <Text
              text={'Соверши подъем несколько раз, включая и выключая блок'}
              stroke={'#406C9C'}
              fill={'#406C9C'}
              lineHeight={1.3}
              strokeWidth={.4}
              fontSize={20}
              x={20}
              y={15}
              ref={this.helpTextRef}
            >
            </Text>
          </Card>
        </Group> : null}
        {isCrane1 ? <Group x={-200} y={-200} opacity={0} ref={this.toBigPowerRef}>
          <Card height={120} width={600} x={250} y={220}>
            <Text
              text={'Введена слишком высокая мощность.\nПостарайся рассчитать минимально возможную, достаточную\nдля загрузки контейнера на корабль до его отправления'}
              stroke={'#406C9C'}
              fill={'#406C9C'}
              lineHeight={1.3}
              strokeWidth={.4}
              fontSize={20}
              x={15}
              y={15}
            >
            </Text>
          </Card>
        </Group> : null}


        {/*{*/}
        {/*  this.state.infoOpen ? (*/}
        {/*    <Card height={90} width={635} x={355} y={5}>*/}
        {/*      <Group x={5} y={20}>*/}
        {/*        <Image x={595} image={cross} height={31} width={30} onClick={() => {*/}
        {/*          this.setState({infoOpen: false})*/}
        {/*        }}/>*/}
        {/*        <Text*/}
        {/*          x={10} y={isCrane4 || isCrane5 ? 5 : -25}*/}
        {/*          text={isCrane4 || isCrane5 ? 'Как зависит выполненная работа по подъему \nгруза от мощности двигателя?' : 'Найти минимальную мощность двигателя,\nпри которой будет возможно поднять груз\nдо отхода судна.'}*/}
        {/*          stroke={mainColor}*/}
        {/*          fill={mainColor}*/}
        {/*          lineHeight={1.2}*/}
        {/*          strokeWidth={.8}*/}
        {/*          fontSize={27}*/}
        {/*        />*/}
        {/*      </Group>*/}
        {/*    </Card>*/}
        {/*  ) : (*/}
        {/*    <Image image={info} x={955} y={20} height={31} width={30} onClick={() => {*/}
        {/*      this.setState({infoOpen: true})*/}
        {/*    }}/>*/}
        {/*  )*/}
        {/*}*/}


        <CanvasResetBtn x={25} y={460} onClick={() => this.startResetOnClick({reset: true})}/>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
          <Layer>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  failureModalVisible: state.commonReducer.failureModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Crane);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
