import {hasIntersection, degToRad, radToDeg} from '../../../utils/common'


export const LSN_STEPS = {
  INITIAL: 1,
  ROTATION: 2,
  MOUNTAINS: 3
}


export const dragBoundFunc = (pos, $this) => {
  const data = $this.data;
  const stageNode = $this.stageRef?.current;
  const stageWidth = stageNode.width();
  const stageHeight = stageNode.height();

  let leftBorderX = stageWidth;
  let rightBorderX = stageWidth;
  let topBorderY = stageHeight;
  let bottomBorderY = stageHeight;

  if (data.step === LSN_STEPS.INITIAL) {
    leftBorderX = stageWidth * .3;
    rightBorderX = stageWidth * .85;
    topBorderY = stageHeight * .12;
    bottomBorderY = stageHeight * .12;
  }
  if (data.step === LSN_STEPS.ROTATION) {
    leftBorderX = stageWidth * .371;
    rightBorderX = stageWidth * .371;
    topBorderY = stageHeight * .235;
    bottomBorderY = stageHeight * .235;
  }

  const newX = pos.x >= rightBorderX ? rightBorderX : ( pos.x <= leftBorderX ? leftBorderX : pos.x );
  const newY = pos.y <= topBorderY ? topBorderY : ( pos.y >= bottomBorderY ? bottomBorderY : pos.y );
  return {y: newY, x: newX};
}

const getPlaceForColumnPos = ($this) => {
  const data = $this.data;
  const nodes = $this.getNodes();

  const columnMercuryContainerNode = nodes.columnMercuryContainerNode;
  const placeForColumnNode = nodes.placeForColumnNode;
  const placeForColumnPos = placeForColumnNode.getAbsolutePosition();

  return {
    x: placeForColumnPos.x + columnMercuryContainerNode.offsetX(), y: placeForColumnPos.y + columnMercuryContainerNode.offsetY()
  }
}

const setColumnMercuryContainerStandPos = ($this) => {
  const data = $this.data;
  const nodes = $this.getNodes();
  const columnMercuryContainerNode = nodes.columnMercuryContainerNode;
  const scale = columnMercuryContainerNode.getAbsoluteScale();

  if (data.step === LSN_STEPS.MOUNTAINS) {
    columnMercuryContainerNode.setAbsolutePosition({x: 375 * scale.x, y: 390 * scale.y });
    columnMercuryContainerNode.rotation(180);
  }
}

export const onDragMove = (e, $this) => setColumnMercuryContainerStandPos($this);
export const onDragStart = (e, $this) => setColumnMercuryContainerStandPos($this);

export const onDragEnd = (e, $this) => {
  const data = $this.data;
  const nodes = $this.getNodes();

  const columnMercuryContainerNode = nodes.columnMercuryContainerNode;
  const bindBoxColumnNode = nodes.bindBoxColumnNode;
  const transformerNode = nodes.transformerNode;

  const placeForColumnPos = getPlaceForColumnPos($this);
  const bindBoxPos = bindBoxColumnNode.getAbsolutePosition();

  const isColumnIntersectionWithBindBox = hasIntersection(bindBoxColumnNode, columnMercuryContainerNode, $this);
  if (isColumnIntersectionWithBindBox && data.step === LSN_STEPS.INITIAL) {
    data.step = LSN_STEPS.ROTATION;
    columnMercuryContainerNode.setAbsolutePosition(placeForColumnPos);

    transformerNode.nodes([columnMercuryContainerNode]);
    transformerNode.getLayer().batchDraw();
  }

}

export const transformEnd = (e, $this) => {
  const data = $this.data;
  const nodes = $this.getNodes();

  const columnMercuryContainerNode = nodes.columnMercuryContainerNode;
  const transformerNode = nodes.transformerNode;
  const targetRotation = e.target.attrs.rotation;
  const scale = columnMercuryContainerNode.getAbsoluteScale();

  if (targetRotation < -160 || targetRotation > 160  && data.step === LSN_STEPS.ROTATION) {
    transformerNode.nodes([]);
    transformerNode.getLayer().batchDraw();
    columnMercuryContainerNode.rotation(0)

    data.step = LSN_STEPS.MOUNTAINS;
    setColumnMercuryContainerStandPos($this)
  }
}

export const transformBoundBoxFunc = (oldBox, newBox) => {
  const newBoxRotation = radToDeg(newBox.rotation);
  if (newBoxRotation >= 180 || newBoxRotation <= -180) {
    return oldBox;
  }
  return newBox;
}