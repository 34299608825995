import React, {useEffect} from 'react';
import grayCross from '../../../images/grayCross.png';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import { serverMediaUrl } from "../../../utils/api";

import eshaalaLogo from '../../../images/whitelabel/eShaala_logo.png';


const ListItem = ({taskCode, image, lessons}) => {
  const styles = getStyles();
  const searchCode = taskCode.replace(/\//g, '');
  const lessonData = lessons.find(l => l.code === searchCode);
  console.log('debug on search code', searchCode, lessonData);

  return (
    <Link to={ `/task/en/${taskCode}` }
          style={{
            ...styles.taskItem,
            backgroundImage: `url(${lessonData?.thumbnail ? `${serverMediaUrl}${lessonData?.thumbnail}`: image})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain'
          }}
    >
      {lessonData?.title_en
      && <div
        style={{width: '100%', textAlign: 'center', color: 'black', fontSize: '20px', backgroundColor: 'rgba(255,255,255,0.7)'}}>
        {lessonData.title_en}
      </div>}
    </Link>)
  }
;

const Heading = ({text}) => (
  <h2
    style={{width: '100%', textAlign: 'center', fontWeight: 'bold'}}
  >
    {text}
  </h2>);

const TasksGrid = ({tasks, lessons}) => (
  <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', alignItems: 'center'}}>
    {tasks.map(props => ListItem({...props, lessons}))}
  </div>
);

const Header = () => (
  <div style={{
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '15px 0',
    backgroundColor: "white",
    marginBottom: '15px',
    webkitBoxShadow: '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(145,145,145,0.01)',
    boxShadow: '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(145,145,145,0.01)',
    // fontFamily: 'Arial',
  }}>
    <img style={{width: '15%'}} src={eshaalaLogo} />
    <h1>Interactive tasks demo</h1>
    <div style={{width: '15%'}}/>
  </div>
);

const TasksListScreen = (props) => {
  const {

  } = props;

  console.log('debug on props', props);

  const styles = getStyles();

  const mathTasks = [
    {taskCode: 'tgcircle/1', image: grayCross},
    {taskCode: 'tgcircle/2', image: grayCross},
  ];

  const thermodynamicsTasks = [
    {taskCode: 'idealGas/11', image: grayCross},
    {taskCode: 'idealGas/12', image: grayCross},
    {taskCode: 'ice/5', image: grayCross},
  ];

  const mechanicsTasks = [
    {taskCode: 'balloon/4', image: grayCross},
    {taskCode: 'training/6', image: grayCross},
  ];

  const electricityTasks = [
    {taskCode: 'electrostatic/2', image: grayCross},
  ];

  const chemistryTasks = [
    {taskCode: 'chemistry/1', image: grayCross},
  ];

  return (
    <div style={styles.mainContainer}>
      <Header/>

      <Heading text={'Maths'}/>
      <TasksGrid tasks={mathTasks} lessons={props.lessons}/>

      <Heading text={'Thermodynamics'}/>
      <TasksGrid tasks={thermodynamicsTasks} lessons={props.lessons}/>

      <Heading text={'Mechanics'}/>
      <TasksGrid tasks={mechanicsTasks} lessons={props.lessons}/>

      <Heading text={'Electricity'}/>
      <TasksGrid tasks={electricityTasks} lessons={props.lessons}/>

      <Heading text={'Chemistry'}/>
      <TasksGrid tasks={chemistryTasks} lessons={props.lessons}/>
    </div>
  )
};

const getStyles = (sidebarCollapse) => ({

  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    maxWidth: '950px',
    margin: '0 auto',
    backgroundColor: '#f1e4dd',
  },
  taskItem: {
    width: '300px',
    height: '200px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    margin: '10px 5px',
  },
  image: {
    height: 'auto',
  }

});

const mapStateToProps = (state, {match}) => {
  return {
    lessons: state.lessonsReducer.lessons,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TasksListScreen);


// export default TasksListScreen;
