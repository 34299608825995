import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import Indicator from "../components/Indicator";
import kolbaImg from '../../../../images/ICE/kolba.png';
import valveImg from '../../../../images/ICE/valve.png';
import valveCamImg from '../../../../images/ICE/valveCam.png';
import sparkPlugImg from "../../../../images/ICE/sparkPlug.png";
import fuelImg from "../../../../images/ICE/fuel.png";
import Explosion from '../components/Explosion'
import { burn, gasAmount, initialData, inputValveWork, pressureStatic, volume } from "../utils/icePhysics";
import {getBoolByLsnCode} from "../utils/iceCommon";


class Step2 extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();
    this.valveRef = React.createRef();
    this.valveCamRef = React.createRef();
    this.sparkPlugRef = React.createRef();
    this.fuelRef = React.createRef();
    this.gasRef = React.createRef();
    this.explosionRef = React.createRef();

    this.staticData = {};
    this.state = {};

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      // VALVE
      valveOffsetY: 0,
      valveCamAngle: 0,

      // // INDICATOR
      // temperature: 0,
      // pressure: 0,
      //
      // // FUEL AND GAS
      // fuelLevel: 0,
      // gasLevel: 0,

      physics: initialData(),

      // ACTIONS
      fuelInjection: false,
      sparkPlugActive: false,
    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    this.requestId = window.requestAnimationFrame(this.move);
  }

  componentWillUnmount() {
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  getAllNodes = () => ({
    valveNode: this.valveRef?.current,
    valveCamNode: this.valveCamRef?.current,
    sparkPlugNode: this.sparkPlugRef?.current,
    fuelNode: this.fuelRef?.current,
    gasNode: this.gasRef?.current,
    temperatureNode: this.temperatureRef?.current,
    pressureNode: this.pressureRef?.current,
    explosionNode: this.explosionRef?.current,
  });

  /**
   * -------------------------- GETTERS --------------------------
   */
  rotateValveCam = (timedeltaSec) => {
    const data = this.data;
    if (data.fuelInjection)
      return data.valveCamAngle + 90*timedeltaSec;
    return data.valveCamAngle
  };

  get valveOffsetY() {
    const data = this.data;
    const valveCam = Math.abs(Math.round(data.valveCamAngle)) % 360;
    if (valveCam <= 45)
      return valveCam/5;
    if (valveCam > 45 && valveCam <= 90)
      return 18 - valveCam/5;
    return 0;
  }

  get explosion() { return this.data.sparkPlugActive  && (this.data.fuelLevel > 0)}

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;


    // VALVE
    data.valveCamAngle = this.rotateValveCam(timedeltaSec);
    data.valveOffsetY = this.valveOffsetY;

    inputValveWork(data.valveOffsetY, data.physics, timedeltaSec);
    this.data.physics.internal.pressure = pressureStatic(
      gasAmount(this.data.physics.internal.consistency),
      this.data.physics.internal.temperatureKelvin,
      volume(200), // как будто поршень опущен на 200 пикселей
    );

    if (data.sparkPlugActive) {
       burn(data.physics) // changes inplace
    }

    console.log('debug on consistency',
      data.physics.internal.consistency,
      this.data.physics.internal.pressure,
      this.data.physics.internal.temperatureKelvin
    );

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const consistency = data.physics.internal.consistency;
    const timedelta = data.prevTime ? data.startTime - data.prevTime : 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const nodes = this.getAllNodes();


    // VALVE
    nodes.valveNode.offsetY(-data.valveOffsetY);
    nodes.valveCamNode.rotation(data.valveCamAngle-27);

    // // FUEL AND GAS
    nodes.fuelNode.opacity(consistency.petrol/0.6);
    nodes.gasNode.opacity(consistency.exhaust);
    //
    // // SPARK PLUG
    nodes.sparkPlugNode.opacity(Number(data.sparkPlugActive));

    stageNode.draw();
  }


  CanvasICE = () => {
    const data = this.data;

    const [kolba] = useImage(kolbaImg);
    const [valve] = useImage(valveImg);
    const [valveCam] = useImage(valveCamImg);
    const [sparkPlug] = useImage(sparkPlugImg);
    const [fuel] = useImage(fuelImg);

    return (
      <React.Fragment>

    {/* --------- INDICATOR --------- */}
          <Indicator
            externalData={() => ({
              fuel: this.data.physics.internal.consistency.petrol,
              gas: this.data.physics.internal.consistency.exhaust,
              temperature: this.data.physics.internal.temperatureKelvin - 273,
              pressure: this.data.physics.internal.pressure,
            })}
          />

    {/* --------- ENGINE --------- */}
          <Group y={110}>

      {/* FUEL AND GAS */}
            <Group x={150} y={60}>
              <Rect ref={this.gasRef}  width={150} height={175} fill={'#B8CDEE'} opacity={0}/>
              <Rect ref={this.fuelRef} width={150} height={175} fill={'#FFEABD'} opacity={0}/>

              <Explosion
                getExplosion={() => this.explosion}
                getRectProps={() => ({width: 150, height: 200})}
              />
            </Group>
            <Image image={fuel}/>


      {/* VALVE */}
            <Group x={141}>
              <Image ref={this.valveRef} image={valve} x={-5} y={-25} rotation={-23}/>
              <Image ref={this.valveCamRef} image={valveCam} x={3} y={-45.5} offsetY={34/2} offsetX={34/2} rotation={-27}/>
            </Group>

            <Image image={kolba} />
            <Text x={35} y={40} text={'Топливная\n смесь'} stroke={'#71A1BD'} strokeWidth={.8} fontSize={15} align={'right'} fontStyle={'bold'}/>

      {/* SPARK PLUG */}
            <Group x={215}>
              <Image image={sparkPlug}/>
              <Line ref={this.sparkPlugRef} points={[0, 0, 0, 5]} stroke={'blue'} x={13} y={70} opacity={data.sparkPlugActive ? 1 : 0 }/>
            </Group>
          </Group>
      </React.Fragment>
    )
  };


  CanvasButton = () => {

    const btnSettings = {
      fill: '#447B9C',
      cornerRadius: 5,
      width: 150,
      height: 40,
      fontSize: 16
    };
    const btnTextSettings = {
      stroke: 'white',
      strokeWidth: .5,
      fill: 'white',
      fontSize: 17,
      width: btnSettings.width,
      height: btnSettings.height,
      align: 'center',
      verticalAlign: 'middle'
    };
    return (
      <Group>

        <Group
          y={500} x={600}
          onTap={() => this.data.fuelInjection = !this.data.fuelInjection}
          onClick={() => this.data.fuelInjection = !this.data.fuelInjection}
        >
          <Rect {...btnSettings}/>
          <Text text={'Впрыск топлива'} {...btnTextSettings}/>
        </Group>

        <Group
          x={600} y={550}
          onMouseDown={() => this.data.sparkPlugActive = true}
          onMouseUp={() => this.data.sparkPlugActive = false}
          onTouchStart={() => this.data.sparkPlugActive = true}
          onTouchEnd={() => this.data.sparkPlugActive = false}
        >
          <Rect {...btnSettings}/>
          <Text text={'Свеча'} {...btnTextSettings}/>
        </Group>
      </Group>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasICE/>
            <this.CanvasButton/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step2);

const styles = {
  mainContainer: {
    background: '#97C1D9',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    background: '#447B9C',
    borderRadius: '7px',
    color: 'white',
    fontSize: '1.3em',
    padding: '7px 14px',
    fontWeight: 'bold',
    width: 'auto',
    margin: '10px 0',
    border:'0',
    cursor: 'pointer'
  },
  buttons: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    bottom: 120,
    right: 150,
  }
};
