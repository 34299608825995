import React, {useEffect, useState} from "react";
import grayCross from "../../../images/grayCross.png";
import {Button, Checkbox, Col, Form, Input, Modal, Row, Spin, Tooltip, Space, DatePicker} from "antd";
import FormItem from "antd/es/form/FormItem";
import {_t} from "../../../utils/lang/common";
import {CopyToClipboard} from "react-copy-to-clipboard";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import moment from 'moment';


const ModalCreateLesson = (props) => {
  const {
    lessonSession,
    lessonsGroups,
    lessons
  } = props;

  const [zoomHasError, setZoomHasError] = useState(false);
  const [dateHasError, setDateHasError] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  // Hide a success text after copy link
  useEffect(() => {
    setTimeout(() => {
      if (copiedLink)
        setCopiedLink(false)
    }, 3000)
  }, [copiedLink]);


  const onCLickCancelLesson = () => {
    props.closeLesson();
  };
  const onCLickGenerateLink = async () => {
    {/* ToDo complete date and time */}
    // if (lessonSession.zoomLink && lessonSession.startLessonDatetime) {
    if (lessonSession.zoomLink) {
      return props.generateLessonLink();
    }
    setZoomHasError(true);
    setDateHasError(true);
  };
  const onChangeCheckBox = (lessonCode) => {
    const selectedLessons = lessonSession.selectedLessons;
    selectedLessons[lessonCode] = !selectedLessons[lessonCode];
    props.changeLessonSession({selectedLessons: {...selectedLessons}});
  };

  const onChangeZoomLink = (val) => {
    setZoomHasError(false);
    props.changeLessonSession({zoomLink: val});
  };

  const onChangeDatetime = (val) => {
    setDateHasError(false);
    props.changeLessonSession({
      startLessonDatetime: moment(val).format("DD.MM.YYYY HH:mm")
    });
  };


  const isLessonCreated = Boolean(lessonSession.link);

  let dateTime = null;
  if (lessonSession.startLessonDatetime && moment(lessonSession.startLessonDatetime, "DD.MM.YYYY HH:mm").isValid())
    dateTime = moment(lessonSession.startLessonDatetime, "DD.MM.YYYY HH:mm");

  return(
    <Modal
      style={{ top: 100, borderRadius: 5, overflow:'hidden', padding: 0 }}
      title={`${isLessonCreated ? 'Редактирование' : 'Создание'} урока`}
      visible={props.visible}
      onCancel={props.onCancel}
      width={1000}
      footer={<></>}
    >
      <Row gutter={20}>
        <Col md={16} style={styles.lessonsCkboxWrapper}>
          {
            props.createLessonSpinnerVisible && (
              <div style={styles.checkBoxBlur}> <Spin size="default"/> </div>
            )
          } {
            lessonsGroups.map((group) => (
              <div key={`modal-group-${group.id}`} style={styles.checkboxColumnWrapper}>
                <h4><b>{group.title}</b></h4>
                <div style={styles.checkboxColumn}>
                  {
                    lessons.map((lesson) => {
                      const id = `${group.id}-lesson-${lesson.id}`;
                      if (lesson.group === group.id)
                        return (
                          <FormItem style={{margin:'0'}} key={id}>
                            <Checkbox
                              id={id}
                              type={'checkbox'}
                              className={'pointer'}
                              checked={lessonSession.selectedLessons[lesson.code]}
                              onChange={() => onChangeCheckBox(lesson.code)}
                              disabled={isLessonCreated}
                            >{lesson.title}</Checkbox>
                          </FormItem>
                        );
                      else
                        return (<React.Fragment key={id}/>);
                    })
                  }
                </div>
              </div>
            ))
          }
        </Col>
        <Col md={8} style={{padding: '0 25px'}}>
            <Form.Item
              style={{margin: '0 0 14px'}}
              label={<b>Ссылка на Zoom</b>}
              validateStatus={zoomHasError && !lessonSession.zoomLink ? 'error' : ''}

            >
              <Input
                name={'zoomLink'}
                required={true}
                style={styles.input}
                value={lessonSession.zoomLink || ''}
                onChange={(e) => onChangeZoomLink(e.target.value)}
                disabled={isLessonCreated}
              />
            </Form.Item>
            {/* ToDo complete date and time */}
            {/*<Form.Item*/}
            {/*  style={{margin: '0 0 14px'}}*/}
            {/*  label={<b>Дата и время начала урока</b>}*/}
            {/*  validateStatus={dateHasError && !lessonSession.startLessonDatetime ? 'error' : ''}*/}
            {/*  */}
            {/*>*/}
            {/*  <DatePicker*/}
            {/*    style={{width: '100%'}}*/}
            {/*    value={dateTime}*/}
            {/*    format={"DD.MM.YYYY HH:mm"}*/}
            {/*    showTime={{format: 'HH:mm'}}*/}
            {/*    onChange={onChangeDatetime}*/}
            {/*    disabled={isLessonCreated}*/}
            {/*  />*/}
            {/*</Form.Item>*/}
        </Col>
      </Row>
      {
        isLessonCreated
          ? (
            <Row gutter={[10, 16]}>
              <Col md={24} className="gutter-row">
                <Input value={lessonSession.link} disabled/>
              </Col>
              <Col md={24} className="gutter-row">
                <div style={{display: 'flex'}}>
                  <Button type={'danger'} onClick={onCLickCancelLesson}>
                    {_t("button_cancel_lesson_session")}
                  </Button>

                  <Tooltip
                    title={_t("copy_success")}
                    color={'green'}
                    trigger={'click'}
                    placement="right"
                    visible={copiedLink}
                  >
                    <CopyToClipboard
                      text={lessonSession.link}
                      onCopy={() => setCopiedLink(true)}
                    >
                      <Button style={{margin: '0 10px'}} type={'secondary'} >
                        {_t("button_copy_lesson_link")}
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                </div>
              </Col>
            </Row>
          ) : (
            <Button
              type={'primary'}
              onClick={onCLickGenerateLink}
            >{_t("button_generate_link")}</Button>
          )
      }
    </Modal>
  );
};


const mapStateToProps = (state) => ({
  lessonSession: state.lessonsReducer.lessonSession,
  lessons: state.lessonsReducer.lessons,
  lessonsGroups: state.lessonsReducer.lessonsGroups,
  createLessonSpinnerVisible: state.lessonsReducer.createLessonSpinnerVisible,
});

const mapDispatchToProps = (dispatch) => ({
  generateLessonLink: (tasksCodes) => dispatch(actions.generateLessonLink(tasksCodes)),
  changeLessonSession: (obj) => dispatch(actions.changeLessonSession(obj)),
  resetLessonSession: () => dispatch(actions.resetLessonSession()),
  closeLesson: () => dispatch(actions.closeLesson()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalCreateLesson);

const styles = {
  checkBoxBlur: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0, right: 0,
    top: 0,
    background: 'rgba(255, 255, 255, .8)',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
  lessonsCkboxWrapper: {
    display: 'flex',
    overflow: 'auto',
    marginBottom: '10px',
    position: 'relative',
    width: 650,
    border: '1px solid rgba(0,0,0,0.1)',
    padding: 15,
    borderRadius: '5px',
  },
  checkboxColumn: {overflow: 'auto', maxHeight: '250px', width: '150px', margin: '10px 0 0'},
  checkboxColumnWrapper: {margin: '0 10px 20px 0', flexShrink: '0'}
};