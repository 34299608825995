import CanvasButton from "./CanvasButton";
import React from "react";
import {layout2} from "../../../utils/styles";
import {Group} from "react-konva";
import playImg from '../img/play.png'
import useImage from "use-image";


const CanvasNextBtn = React.forwardRef((props, ref) => {
  const {
    x=850,
    y=500,
    width=95,
    height=30,
    fontSize=15,
    onClick,
    visible,
  } = props;
  const [play] = useImage(playImg);
  return (
    <CanvasButton
      btnRef={ref}
      text={'Вперед'}
      visible={visible}

      suffixImg={play}
      suffixX={5}
      suffixY={15}
      suffixWidth={15}
      suffixHeight={15}

      onClick={() => onClick()}
      fontSize={fontSize}
      strokeWidth={.2}
      btnCornerRadius={0}
      btnFill={layout2.darkBlue}
      width={width}
      textWidth={width-20}
      height={height}
      align={'left'}
      textX={10}
      x={x}
      y={y}
    />
  )
})

export default CanvasNextBtn;