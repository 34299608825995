import React from "react";
import {Button, Input, Radio, Checkbox, Space} from "antd";
import TextProblemHtmlContent from "./TextProblemHtmlContent";



const AnswerBlock = (props) => {
  const {
    textProblem,
    stateAnswers,
    sendVal,
    onChange,
    editMode,
  } = props;
  const userAnswers = textProblem?.answers;
  const correctAnswers = textProblem?.correct_answers;
  const correctAnswersCount = textProblem?.correct_answers_count;
  const isOptions = textProblem?.answer_type === 'options';
  const multipleSelect = correctAnswersCount > 1;

  const changeMethod = (e, multiple) => {
    if (editMode) {
      onChange(e, multiple)
    }
  };
  return (
    <div className={ 'text-problem-task__answer-section' }>
      {
        (isOptions && multipleSelect) ? (
          <CheckboxOptionsBlock
            editMode={editMode}
            textProblem={textProblem}
            correctAnswers={correctAnswers}
            userAnswers={userAnswers}
            stateAnswers={stateAnswers}
            changeMethod={changeMethod}
          />
        ) : (isOptions && !multipleSelect) ? (
            <RadioOptionsBlock
              changeMethod={changeMethod}
              editMode={editMode}
              stateAnswers={stateAnswers}
              userAnswers={userAnswers}
              textProblem={textProblem}
              correctAnswers={correctAnswers}
            />
        ) : (
          <InputStrOrNumberBlock
            correctAnswers={correctAnswers}
            userAnswers={userAnswers}
            textProblem={textProblem}
            editMode={editMode}
            changeMethod={changeMethod}
          />
        )
      }


      {
        editMode && (
          <Button
            size={ 'large' }
            type={ 'primary' }
            className={ 'text-problem-task__submit-btn' }
            onClick={sendVal}
          >
            Ответить
          </Button>
        )
      }
    </div>
  )
}

const RadioOptionsBlock = ({changeMethod, editMode, stateAnswers, userAnswers, textProblem, correctAnswers}) => {
  return (
    <Radio.Group
      className={'text-problem-task__answers-group'}
      onChange={(e) => changeMethod(e, false)}
      value={editMode ? stateAnswers?.[0] : userAnswers?.[0]?.option}
      disabled={!editMode}
    >
      <Space direction="vertical">
        {
          textProblem.answer_options?.map((option) => {
            const userAnswer = userAnswers?.[0];
            const isCurrentOptionCorrect = correctAnswers.map((el) => el.id).includes(option.id);
            const answerChecked = option.id === userAnswer?.option;
            let successClassname = `text-problem-checkbox--${isCurrentOptionCorrect ? 'success' : ''}`;
            let failureClassname = `text-problem-checkbox--${!isCurrentOptionCorrect && answerChecked ? 'fail' : ''}`;
            return (
              <Radio
                key={option.id} value={option.id} style={{display: "flex", alignItems: "center"}}
                className={!editMode ? `${successClassname} ${failureClassname}` : ''}
              >
                {
                  option.value_repr ? (
                      <TextProblemHtmlContent htmlForRender={option.value_repr}/>
                    ) :
                    option.value_str
                }
              </Radio>
            )
          })
        }
      </Space>
    </Radio.Group>
  )
}

const CheckboxOptionsBlock = ({editMode, textProblem, correctAnswers, userAnswers, stateAnswers, changeMethod}) => {
  return (
    <div className={`text-problem-task__answers-group ${!editMode ? 'text-problem-task__answers-group--show-answer' : ''}`}>
      {
        textProblem.answer_options?.map((option) => {
          const isCurrentOptionCorrect = correctAnswers.map((el) => el.id).includes(option.id);
          const answerChecked = !editMode ? userAnswers.map((el) => el.option).includes(option.id) : stateAnswers.includes(option.id);
          let successClassname = `text-problem-checkbox--${isCurrentOptionCorrect ? 'success' : ''}`;
          let failureClassname = `text-problem-checkbox--${!isCurrentOptionCorrect && answerChecked ? 'fail' : ''}`;
          return (
            <Checkbox
              key={option.id}
              disabled={!editMode}
              checked={answerChecked}
              className={!editMode ? `${successClassname} ${failureClassname}` : ''}
              value={option.id}
              onChange={(e) => changeMethod(e, true)}
            >{
              option.value_repr ? (
                <TextProblemHtmlContent htmlForRender={option.value_repr}/>
              ) : option.value_str
            }</Checkbox>
          )
        })
      }
    </div>
  )
}

const InputStrOrNumberBlock = ({correctAnswers,userAnswers, textProblem, editMode, changeMethod}) => {
  return (
    <div className={ 'text-problem-task__answer' }>
      {
        editMode ? (
          <>
            <p>Ответ: </p>
            <Input
              type={textProblem.answer_type === 'number' ? 'number' : 'text'}
              placeholder={ 'Введите значение'}
              onChange={(e) => changeMethod(e, false)}
              value={userAnswers?.[0]}
            />
          </>
        ) : (
          <table>
            <tbody>
            {
              correctAnswers.map((correctAnswer, i) => {
                return (
                  <tr key={correctAnswer.id}>
                    <td><p style={styles.stringAnswerLabel}>Правильный ответ: </p></td>
                    <td>
                      <span className={'render-content'} style={{color: styles.correctAnswerColor, ...styles.answerCommon, margin: '20px 0'}}>
                        {
                          correctAnswer?.value_repr ? (
                            <TextProblemHtmlContent htmlForRender={correctAnswer.value_repr}/>
                          ) : (
                            correctAnswer?.value_str || ''
                          )
                        }
                      </span>
                    </td>
                  </tr>
                )
              })
            }
            <tr>
              <td><p style={styles.stringAnswerLabel}>Ответ пользователя: </p></td>
              <td>
                  <span style={{
                    ...styles.answerCommon,
                    color: userAnswers?.[0]?.is_correct ? styles.correctAnswerColor : styles.wrongAnswerColor}}
                  >
                    {userAnswers?.[0]?.value_str||''}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
        )
      }
    </div>

  )
}

export default AnswerBlock;

const styles = {
  stringAnswerLabel: {width: '150px', textAlign: 'right', margin: '10px'},
  answerCommon: {lineHeight: 1, fontFamily: 'Roboto-Black', fontSize: "24px"},
  correctAnswerColor: '#8DCBB6',
  wrongAnswerColor: '#F1B254',
}
