import React from "react";
import CanvasContainer from "../../../../canvas/containers/CanvasContainer";
import backgroundImg from '../../../../../images/positiveNegativeWork/background.png';
import groundImg from '../../../../../images/positiveNegativeWork/ground.png';
import indicatorImg from '../../../../../images/positiveNegativeWork/indicator.png';
import bolideImg from '../../../../../images/positiveNegativeWork/bolid.png';

import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import CanvasPlayBtn from "../../../../canvas/components/CanvasPlayBtn";
import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import {layout2, mainColor} from "../../../../../utils/styles";
import Victor from "victor";
import * as actions from "../../../../../store/actions";
import ScenarioManager from "../../../../../utils/ScenarioManager";
import {sendSuccessForScenario} from "../../../../../utils/common";


class PositiveNegativeWork extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',

      'gravityForceArrow',
      'frictionForceArrow',
      'gravityForceArrowPoints',
      'frictionForceArrowPoints',
      'indicatorFirstTxt',
      'indicatorSecondTxt',
      'gravityTxt',
      'frictionTxt',
      'playBtn',
      'resetBtn',
      'bolide',
      'bolideImg',
    ];

    this.descentR = 218;
    this.descentFunc = (x) => Math.sqrt(Math.pow(this.descentR, 2) - Math.pow(x-this.descentR, 2)) - 30;

    // на глаз подобранный кусок окружности, по которой должны двигаться сани
    const ys = new Array(218).fill(1).map((el, i) => this.descentFunc(i));
    let points = [];
    for (let x in ys) {
      points.push(parseInt(x));
      points.push(ys[x]);
    }

    this.staticData = {
      descentLimitY: 190,
      descentEndX: 226,
      roadLimitX: 820,
      sandStartX: 350,
      ys: ys,
      points: points,
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timedeltaSec: 0,

      start: false,

      endPath: false,
      endOfDescent: false,
      sandStart: false,

      friction: 0,
      speed: 0,

      gravityWork: 0,
      frictionWork: 0,

      indicator1: undefined,
      indicator2: undefined,

      bolideRotation: 85,
      bolidePosV: Victor(2, this.descentFunc(2)),
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;


  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  openModal = () => sendSuccessForScenario(this);

  mToPx = (meters) => meters * 20;
  pxToM = (pixels) => pixels / 20;

  onClickStart = () => {
    this.data.start = true;
  };
  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };

  bolideSpeed = () => {
    const data = this.data;

    if (data.bolidePosV.x < this.staticData.descentEndX) {
      // движется с ускорением
      data.speed += 400 * data.timedeltaSec;
    }

    // if (data.bolidePosV.x >= this.staticData.descentEndX && data.bolidePosV.x < this.staticData.sandStartX) {
    //   data.speed = data.speed; // скорость не меняется
    // }

    if (data.bolidePosV.x > this.staticData.sandStartX) {
      // отрицательное ускорение за счет песка
      data.speed -= 172 * data.timedeltaSec;
    }

    if (data.speed < 0) {
      data.speed = 0;
    }

  };

  bolidePos = () => {
    const data = this.data;

    if (data.bolidePosV.x < this.staticData.descentEndX) {
      const x = data.bolidePosV.x + data.speed * data.timedeltaSec;
      const y = this.descentFunc(data.bolidePosV.x);
      data.bolidePosV = Victor(x,y);
    } else {
      data.bolidePosV = Victor(data.bolidePosV.x + data.speed * data.timedeltaSec, data.bolidePosV.y);
    }
  };

  bolideRotation = () => {
    const data = this.data;
    const c = 92;
    if (data.bolidePosV.x < this.staticData.descentEndX) {
      data.bolideRotation = - Math.acos((this.descentR - (data.bolidePosV.x))/this.descentR) / Math.PI * 180 + c;
    }
  };

  calcWork = () => {
    let data = this.data;
    data.gravityWork = 160 * 10 * this.pxToM(data.bolidePosV.y - this.initialData.bolidePosV.y);
    // console.log('debug on gravity work', data.gravityWork, this.pxToM(data.speed), data.bolidePosV.y - this.initialData.bolidePosV.y);
    if (data.indicator1 !== undefined) {
      // так как ускорение не рассчитывалось исходя из ситля тяжести, работа силы трения - подгонская
      data.frictionWork = data.gravityWork * (parseInt(this.pxToM(data.speed)) - data.indicator1) / data.indicator1;
    }
  };

  setIndicatorValue = () => {
    let data = this.data;
    data.indicator1 = data.indicator1 === undefined && data.bolidePosV.x >= this.staticData.sandStartX ? parseInt(this.pxToM(data.speed)) : data.indicator1;
    data.indicator2 = data.bolidePosV.x >= this.staticData.sandStartX && data.speed === 0 ? this.pxToM(0) : data.indicator2;
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;
    if (timedeltaSec > 0.5) {
      timedeltaSec = 0.1;
    }
    data.timedeltaSec = timedeltaSec;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    if (data.indicator2 !== undefined && !data.sendResultData) {
      this.openModal();
      data.sendResultData = true;
    }

    if (data.start) {
      this.bolideSpeed();
      this.bolidePos();
      this.bolideRotation();
      this.calcWork();
      this.setIndicatorValue();
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['gravityTxt'].text(`= ${ parseInt(data.gravityWork / 1000) } кДж`); // добавить значение гравитации
    n['frictionTxt'].text(`= ${ parseInt(data.frictionWork / 1000) } кДж`); // добавить значение трения
    n['indicatorFirstTxt'].text(data.indicator1 !== undefined ? data.indicator1 : '');
    n['indicatorSecondTxt'].text(data.indicator2 !== undefined ? data.indicator2 : '');

    n['gravityForceArrow'].visible(data.bolidePosV.x < this.staticData.descentEndX);
    n['frictionForceArrow'].visible(data.bolidePosV.x > this.staticData.sandStartX && data.speed > 0);

    n['bolide'].position(data.bolidePosV);

    // const bolidRotation = n['bolideImg'].rotation();
    n['bolideImg'].rotation(data.bolideRotation);

    n['playBtn'].visible(!data.start);
    n['resetBtn'].visible(data.start);

    n['stage'].draw();
  }


  Canvas = () => {

    const [background] = useImage(backgroundImg);
    const [ground] = useImage(groundImg);
    const [indicator] = useImage(indicatorImg);
    const [bolide] = useImage(bolideImg);

    const titleStg = {fontSize: 60, fill: layout2.darkBlue };

    return (
      <React.Fragment>
        <Group>
          <Image image={background}/>

          <Group x={450} y={40}>
            <Group>
              <Text text={'A'} {...titleStg}/>
              <Text text={'с. тяж.'} {...titleStg} fontSize={30} y={30} x={40}/>
              <Text text={`= 1 кДж`} {...titleStg} x={140} ref={this._ref('gravityTxt')}/>
            </Group>
            <Group x={-40} y={80}>
              <Text text={'A'} {...titleStg}/>
              <Text text={'с. трения'} {...titleStg} fontSize={30} y={30} x={40}/>
              <Text text={`= 0 Дж`} {...titleStg} x={180} ref={this._ref('frictionTxt')}/>
            </Group>
          </Group>


          <Group y={270}>
            <Group y={132}>
              <Group x={330}>
                <Image image={indicator}/>
                <Text text={'0'} {...titleStg} fontStyle={'bold'} fontSize={23} align={'center'} width={60} x={7} y={10} ref={this._ref('indicatorFirstTxt')}/>
              </Group>

              <Group x={840}>
                <Image image={indicator}/>
                <Text text={'0'} {...titleStg} fontStyle={'bold'} fontSize={23} align={'center'} width={60} x={7} y={10} ref={this._ref('indicatorSecondTxt')}/>
              </Group>
            </Group>

            <Image image={ground}/>
            <Rect x={370} y={221} width={530} height={2} fill={'#ffc66a'}/>

            <Group x={6} y={32}>
              <Group ref={this._ref('bolide')}>
                <Image
                  ref={this._ref('bolideImg')}
                  image={bolide}
                  width={40} height={13}
                  rotation={85}
                  offsetY={10} offsetX={30}
                />

                <Group x={-10} ref={this._ref('gravityForceArrow')}>
                  <Group x={25} y={10}>
                    <Text text={'F'} fill={'blue'} fontSize={40} y={20}/>
                    <Text text={'тяж'} fill={'blue'} fontSize={18} y={45} x={20} />
                    <Arrow scale={{x: .3, y: .3}} points={[0,0,80,0]} stroke={'blue'} fill={'blue'} y={15} x={2} />
                  </Group>
                  <Arrow points={[0,0,0,70]} stroke={'blue'} fill={'blue'} ref={this._ref('gravityForceArrowPoints')}/>
                </Group>
                <Group x={-10} ref={this._ref('frictionForceArrow')}>
                  <Group x={-50} y={18}>
                    <Text text={'F'} fill={'blue'} fontSize={40}/>
                    <Text x={20} y={30} text={'тр'} fill={'blue'} fontStyle={13}/>
                    <Arrow y={-5} x={2} scale={{x: .3, y: .3}} points={[0,0,80,0]} stroke={'blue'} fill={'blue'}/>
                  </Group>
                  <Arrow points={[0,0,-70,0]} stroke={'blue'} fill={'blue'} ref={this._ref('frictionForceArrowPoints')}/>
                </Group>
              </Group>
              {/*<Line points={this.staticData.points} stroke={'black'} opacity={0.2}/>*/}
            </Group>
          </Group>


          <Group y={512}>
            <CanvasPlayBtn
              ref={this._ref('playBtn')}
              onClick={() => this.onClickStart()}
              x={20} y={0}
            />
            <CanvasResetBtn
              ref={this._ref('resetBtn')}
              onClick={() => this.onClickReset()}
              x={20} y={0}
            />
          </Group>
        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PositiveNegativeWork);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
