import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ArrangeByColumns from "./ArrangeByColumns";
import ChooseExtraItemTask from "./ChooseExtraItemTask";
import bg from '../images/space-bg-1.png';


const Example6Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const columns1 = [
    {id: 1, successAnswerId: 2, title: "Не планета"},
    {id: 2, successAnswerId: 3, title: "Не земной группы"},
    {id: 3, successAnswerId: 1, title: "Не спутник"},
  ]
  const dragItems1 = [
    {id: 1, mainText: "Меркурий"},
    {id: 2, mainText: "Титан"},
    {id: 3, mainText: "Уран"},
  ]

  const chooseTask = [
    {
      title: "Выбери объект по описанию: твёрдая, не планета, больше по размеру одной из планет",
      items: [
        {id: 1, mainText: "Нептун"},
        {id: 2, mainText: "Ганимед", success: true},
        {id: 3, mainText: "Европа"},
        {id: 4, mainText: "Меркурий"},
      ]
    },{
      title: "Выбери лишнее",
      items: [
        {id: 1, mainText: "Юпитер"},
        {id: 2, mainText: "Луна", success: true},
        {id: 3, mainText: "Марс"},
        {id: 4, mainText: "Земля"},
      ]
    },{
      title: "Выбери лишнее",
      items: [
        {id: 1, mainText: "Ганимед"},
        {id: 2, mainText: "Луна"},
        {id: 3, mainText: "Меркурий", success: true},
        {id: 4, mainText: "Европа"},
      ]
    },{
      title: "Выбери лишнее",
      items: [
        {id: 1, mainText: "ИО"},
        {id: 2, mainText: "Ганимед"},
        {id: 3, mainText: "Европа"},
        {id: 4, mainText: "Тритон", success: true},
      ]
    },{
      title: "Выбери лишнее. Сформулируй, по какому признаку сделан выбор",
      items: [
        {id: 1, mainText: "Нептун"},
        {id: 2, mainText: "Сатурн"},
        {id: 3, mainText: "Уран", success: true},
      ]
    },{
      title: "Выбери лишнее. Сформулируй, по какому признаку сделан выбор",
      items: [
        {id: 1, mainText: "Марс"},
        {id: 2, mainText: "Земля"},
        {id: 3, mainText: "Венера", success: true},
      ]
    },{
      title: "Выбери лишнее. Сформулируй, по какому признаку сделан выбор",
      items: [
        {id: 1, mainText: "Юпитер", success: true},
        {id: 2, mainText: "Венера"},
        {id: 3, mainText: "Меркурий"},
      ]
    },

  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={8}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      {
        completedTaskCount < 1 ? (
          <ArrangeByColumns
            key={'1'}
            bgImg={bg}
            title={'Расставь объекты по заголовкам'}
            lang={'ru'}
            columns={columns1}
            dragItems={dragItems1}
            taskComplete={() => {
              setCompletedTaskCount(prevState => prevState + 1);
            }}
          />
        ) : (
          <>
            {
              chooseTask.map((item, i) => (
                <React.Fragment key={`step${i}`}>
                  {
                    ((completedTaskCount === (i+1)) || ((i+1) === chooseTask.length && completedTaskCount >= chooseTask.length)) ? (
                      <ChooseExtraItemTask
                        lang={'ru'}
                        bgImg={bg}
                        title={item.title}
                        items={item.items}
                        taskComplete={() => {
                          setCompletedTaskCount(prevState => prevState + 1);
                          if ((i+1) === chooseTask.length) {
                            setAllComplete(true);
                          }
                        }}
                      />
                    ) : null
                  }
                </React.Fragment>
              ))
            }
          </>
        )
      }
    </TaskMainContainer>
  )
}
export default Example6Task;
