class Rectangle {
  constructor({x = 0, y = 0, width, height, endX, endY}) {
    this.x = x;
    this.y = y;
    this.width = width ?? endX - x;
    this.height = height ?? endY - y;
  }

  get endX() {
    return this.x + this.width;
  }
  set endX(val) {
    this.width = val - this.x;
  }
  get endY() {
    return this.y + this.height;
  }
  set endY(val) {
    this.height = val - this.y;
  }
}

export default Rectangle;
