import React from "react";
import CanvasContainer, {height} from "../../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, RegularPolygon, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import backgroundImg from "../../../../../images/kineticEnergy/background.png";
import cargoImg from "../../../../../images/kineticEnergy/cargo.png";
import autoImg from "../../../../../images/kineticEnergy/auto.png";
import btnDownImg from "../../../../../images/kineticEnergy/btnDown.png";
import btnUpImg from "../../../../../images/kineticEnergy/btnUp.png";
import roadImg from "../../../../../images/kineticEnergy/road.png";
import wheelImg from "../../../../../images/kineticEnergy/wheel.png";
import formulaMassImg from "../../../../../images/kineticEnergy/formulaMass.png";
import formulaSpeedImg from "../../../../../images/kineticEnergy/formulaSpeed.png";
import formulaKineticEnergyImg from "../../../../../images/kineticEnergy/formulaKineticEnergy.png";

import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import {getTimeDeltaSec, sendSuccessForScenario} from "../../../../../utils/common";
import Card from "../../../../canvas/components/Card";
import {mainColor} from "../../../../../utils/styles";
import * as actions from "../../../../../store/actions";
import ScenarioManager from "../../../../../utils/ScenarioManager";



class KineticEnergy extends React.Component {
  constructor(props) {
    super(props);

    this.roadItems = Array(5).fill(1);
    this.wheelItems = Array(5).fill(1);

    this.staticData = {
      maxCargoItems: 5,
      maxCarSpeed: 60,
      speedStep: 20,
      cargoWeight: 4, // т
      carWeight: 4, // т
    }
    this.cargoItems = Array(this.staticData.maxCargoItems).fill(1);
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timeDeltaSec: 0,

      carSpeed: 20,

      offsetRoadX: 0,

      cargoItemsCount: 0
    };
    this.data = cloneDeep(this.initialData);
    this.managedComponents = [
      'stage',

      'vectorSpeed',
      'vectorSpeedText',

      'roadContainer',

      'massFormulaTxt',
      'carSpeedFormulaTxt',
      'kineticEnergyFormulaTxt',

      ...this.wheelItems.map((el, i) => `wheel${i+1}`),
      ...this.cargoItems.map((el, i) => `cargo-${i+1}`),
    ]
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 8000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  mToPx = (meters) => meters * 25;
  tToKg = (ton) => ton * 1000;

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };

  get roadOffsetX() {
    const data = this.data;
    return data.offsetRoadX < 20 ? data.offsetRoadX+(data.timeDeltaSec * data.carSpeed) : 0;
  }

  plusSpeed = () => {
    const data = this.data;
    const staticData = this.staticData;
    data.carSpeed = data.carSpeed >= staticData.maxCarSpeed ? staticData.maxCarSpeed : data.carSpeed + staticData.speedStep;
  }
  minusSpeed = () => {
    const data = this.data;
    const staticData = this.staticData;
    data.carSpeed = data.carSpeed <= 0 ? 0 : data.carSpeed - staticData.speedStep;
  }
  plusCargo = () => {
    const cargoItemsCount = this.data.cargoItemsCount;
    this.data.cargoItemsCount = cargoItemsCount >= this.staticData.maxCargoItems ? this.staticData.maxCargoItems : cargoItemsCount+1;
  }
  minusCargo = () => {
    const cargoItemsCount = this.data.cargoItemsCount;
    this.data.cargoItemsCount = cargoItemsCount <= 0 ? 0 : cargoItemsCount-1;
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timeDeltaSec = getTimeDeltaSec(timedelta);

    data.offsetRoadX = this.roadOffsetX;

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const staticData = this.staticData;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    this.cargoItems.forEach((el, i) => {
      n[`cargo-${i+1}`].visible(i < data.cargoItemsCount);
    })

    const mass = (data.cargoItemsCount*staticData.cargoWeight) + staticData.carWeight;
    n['massFormulaTxt'].text(this.tToKg(mass));
    n['carSpeedFormulaTxt'].text(data.carSpeed);
    n['kineticEnergyFormulaTxt'].text((mass*data.carSpeed**2)/2);



    n['roadContainer'].offsetX(this.mToPx(data.offsetRoadX));
    n['vectorSpeed'].points([0,0,data.carSpeed*4,0]);
    n['vectorSpeedText'].x(data.carSpeed*1.5);

    this.wheelItems.forEach((el, i) => {
      n[`wheel${i+1}`].rotate(data.carSpeed);
    });



    n['stage'].draw();
  }



  Canvas = () => {
    const data = this.data;
    const staticData = this.staticData;

    const [background] = useImage(backgroundImg);
    const [cargo] = useImage(cargoImg);
    const [auto] = useImage(autoImg);
    const [btnDown] = useImage(btnDownImg);
    const [btnUp] = useImage(btnUpImg);
    const [road] = useImage(roadImg);
    const [wheel] = useImage(wheelImg);

    const [formulaMass] = useImage(formulaMassImg);
    const [formulaSpeed] = useImage(formulaSpeedImg);
    const [formulaKineticEnergy] = useImage(formulaKineticEnergyImg);

    const btnStg = {width: 30, height: 30};
    const formulaSettings = {fontSize: 33, fill: mainColor};
    return (
      <React.Fragment>
        <Group>
          <Image image={background}/>

          <Group x={60} y={60}>
            <Card width={300} height={90}>
              <Group x={0} y={10}>
                <Image y={-5} image={formulaMass} width={220} height={80}/>
                <Rect x={80} fill={'white'} width={100} height={70}/>

                <Text
                  x={80} y={20}
                  width={100} align={'center'}
                  ref={this._ref('massFormulaTxt')}
                  text={`${this.tToKg(data.cargoItemsCount*staticData.cargoWeight)}`}
                  {...formulaSettings}
                />

                <Group x={220} y={0}>
                  <Image image={btnUp} {...btnStg} onClick={this.plusCargo} onTap={this.plusCargo}/>
                  <Image image={btnDown} {...btnStg} y={40} onClick={this.minusCargo} onTap={this.minusCargo}/>
                </Group>
              </Group>
            </Card>

            <Card x={0} y={120} width={300} height={90}>
              <Group x={0} y={10}>
                <Image y={-5} image={formulaSpeed} width={220} height={80}/>
                <Rect x={80} fill={'white'} width={60} height={70}/>

                <Text
                  x={80} y={20}
                  width={50} align={'center'}
                  ref={this._ref('carSpeedFormulaTxt')}
                  text={`${data.carSpeed}`}
                  {...formulaSettings}
                />

                <Group x={220} y={0}>
                  <Image image={btnUp} {...btnStg} onClick={this.plusSpeed} onTap={this.plusSpeed}/>
                  <Image image={btnDown} {...btnStg} y={40} onClick={this.minusSpeed} onTap={this.minusSpeed}/>
                </Group>
              </Group>
            </Card>


            <Card x={400} y={40} width={430} height={130}>
              <Group x={10} y={20}>
                <Image image={formulaKineticEnergy} width={400} height={90}/>
                <Rect x={240} y={20} width={80} height={50} fill={'white'}/>

                <Text ref={this._ref('kineticEnergyFormulaTxt')} x={220} y={32} width={120} align={'center'} text={'1000'} {...formulaSettings}/>
              </Group>
            </Card>
          </Group>

          <Group x={0} y={370}>
            <Group
              ref={this._ref('roadContainer')}
              x={0} y={50}
            >
              {
                this.roadItems.map((el, i) => (
                  <Image key={`road-${i}`} x={500*i} image={road}/>
                ))
              }
            </Group>

            <Group x={20} y={20}>
              <Group x={343} y={-3}>
                {
                  this.cargoItems.map((el, i) => (
                    <Image
                      key={`cargo-${i}`}
                      ref={this._ref(`cargo-${i+1}`)}
                      x={-86 * i}
                      image={cargo}
                    />
                  ))
                }
              </Group>
              <Image image={auto}/>
              <Group x={71} y={106}>
                <Image width={33} height={33} offsetX={16.5} offsetY={16.5} ref={this._ref('wheel1')} image={wheel}/>
                <Image width={33} height={33} offsetX={16.5} offsetY={16.5} ref={this._ref('wheel2')} x={35} image={wheel}/>
                <Image width={33} height={33} offsetX={16.5} offsetY={16.5} ref={this._ref('wheel3')} x={70} image={wheel}/>
                <Image width={33} height={33} offsetX={16.5} offsetY={16.5} ref={this._ref('wheel4')} x={293} image={wheel}/>
                <Image width={33} height={33} offsetX={16.5} offsetY={16.5} ref={this._ref('wheel5')} x={425} image={wheel}/>
              </Group>

              <Group x={500} y={80}>
                <Arrow
                  ref={this._ref('vectorSpeed')}
                  points={[0,0,140,0]}
                  fill={'white'}
                  stroke={'white'}
                  strokeWidth={13}
                />
                <Group
                  x={50} y={20}
                  ref={this._ref('vectorSpeedText')}
                >
                  <Arrow points={[0,0,70,0]} scale={{x: .5, y: .5}} fill={'white'} stroke={'white'}/>
                  <Text y={3} text={'V'} fill={'white'} fontStyle={'italic'} fontSize={40}/>
                </Group>
              </Group>
            </Group>
          </Group>
        </Group>

        <CanvasResetBtn onClick={() => this.onClickReset()}/>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KineticEnergy);

const styles = {
  mainContainer: {
    background: '#EDEBE5'
  }
};
