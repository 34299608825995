import React from "react";
import {Group, Image, Arrow, Text} from "react-konva";
import {mainColor} from "../../../utils/styles";
import hintArrowImg from '../../../images/hintArrow.png';
import whiteHintArrowImg from '../../../images/whiteHintArrow.png';
import useImage from "use-image";

const ArrowHint = React.forwardRef((props, ref) =>{
  const {
    x, y,
    textRef,
    text,
    textX,
    textY,
    arrowX,
    arrowY,
    imgScaleX=1,
    imgScaleY=1,
    textWidth,
    textLineHeight=1.2,
    fontSize,
    strokeWidth,
    fontStyle,
    color,
    children,
    rotation,
    visible=true,
    opacity
  } = props;

  const [hintArrow] = useImage(hintArrowImg);
  const [whiteHintArrow] = useImage(whiteHintArrowImg);

  const correctColorArrow = color === 'white' ? whiteHintArrow : hintArrow;
  return (
    <Group x={x} y={y} ref={ref} visible={visible} opacity={!visible ? 0 : opacity}>
      {/* TODO create custom arrow */}
      {/*<Arrow points={[0,0,20,40,50,20]} lineCap={'round'} stroke={mainColor}/>*/}

      <Image
        x={arrowX} y={arrowY}
        scale={{x: imgScaleX, y: imgScaleY}}
        image={correctColorArrow}
        rotation={rotation}
      />
      <Text
        ref={textRef}
        x={textX ?? 20} y={textY ?? 40}
        text={text || 'пример текста'}
        width={textWidth}
        fontSize={fontSize||15}
        lineHeight={textLineHeight}
        strokeWidth={strokeWidth||.1}
        fontStyle={fontStyle||'italic'}
        stroke={color || mainColor}
        fill={color || mainColor}
      />
      { children }
    </Group>
  )
})

export default ArrowHint;