import React, {useEffect, useState} from "react";
import SchoolTaskContainer from "../components/SchoolTaskContainer";
import bgImg from '../images/bg-field.png';
import SchoolTaskCard from "../components/SchoolTaskCard";
import img1 from '../images/сhooseSomeItemsTask/Find_levers_1.png';
import img2 from '../images/сhooseSomeItemsTask/Find_levers_2.png';
import img3 from '../images/сhooseSomeItemsTask/Find_levers_3.png';
import img4 from '../images/сhooseSomeItemsTask/Find_levers_4.png';
import img5 from '../images/сhooseSomeItemsTask/Find_levers_5.png';
import img6 from '../images/сhooseSomeItemsTask/Find_levers_6.png';
import img7 from '../images/сhooseSomeItemsTask/Find_levers_7.png';
import img8 from '../images/сhooseSomeItemsTask/Find_levers_8.png';
import '../styles/chooseExtraItemTask.css';
import '../styles/chooseSomeItemsTask.css';
import TaskMainContainer from "./TaskMainContainer";


const defaultItems = [
  {id: 1, image: img1, success: true},
  {id: 2, image: img2},
  {id: 3, image: img3, success: true},
  {id: 4, image: img4},
  {id: 5, image: img5},
  {id: 6, image: img6, success: true},
  {id: 7, image: img7},
  {id: 8, image: img8, success: true},
]


const ChooseSomeItemsTask = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);
  const [answers, setAnswers] = useState(undefined);

  const items = props.items || defaultItems;

  const corrAnswers = (answers || []);
  const answerFail = corrAnswers.filter(aswr => !aswr.success).length;
  const correctItemsCount = items.filter(aswr => aswr.success).length;
  const answerSuccess = corrAnswers.filter(aswr => aswr.success).length === correctItemsCount;

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={1}
      health={2}
      completeCount={completedTaskCount}
      setCompletedTaskCount={(val) => {
        setAnswers(undefined);
        setCompletedTaskCount(val);
      }}
      taskComplete={allComplete}
    >
      <SchoolTaskContainer
        failMessage={answerFail}
        lang={props.lang}
        bgImg={props.bgImg || bgImg}
        title={props.title || 'Choose all levers'}
        titleFontSize={props.titleFontSize}
        // tryAgain={answerFail}
        success={answerSuccess}
        continueClassName={'choose-item-task__btn-continue'}
        onClickRestart={() => setAnswers(undefined)}
        onClickContinue={() => {
          setCompletedTaskCount(prevState => prevState + 1);
          setAllComplete(true)
        }}
      >
        <div className={'choose-item-task__items choose-items-task__items'}>
          {
            items.map((el, i) => {
              const answer = corrAnswers.find(aswr => aswr.id === el.id);
              const success = answer?.id === el.id && answer.success;
              const fail = answer !== undefined && answer?.id === el.id && !answer.success;
              return (
                <SchoolTaskCard
                  index={i}
                  key={el.id}
                  img={el.image}
                  mainText={el.mainText}
                  text={el.text}
                  success={success}
                  fail={fail}
                  className={`choose-item-task__item choose-items-task__item ${!answer ? 'choose-item-task__item--hover':''}`}
                  onClick={() => {
                    setAnswers([...corrAnswers, el]);
                  }}
                />
              )
            })
          }
        </div>
      </SchoolTaskContainer>
    </TaskMainContainer>
  )
}
export default ChooseSomeItemsTask;
