import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import Konva from "konva";
import {Rect, Layer, Text, Group, Circle, Line, Image as KonvaImage } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import startSceneImage from "../../../../images/fountain/start_scene.png";
import emptySceneImage from "../../../../images/fountain/empty_scene.png";
import towerImage from "../../../../images/fountain/tower.png";
import Victor from 'victor'
import * as actions from "../../../../store/actions";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {sendSuccessForScenario} from "../../../../utils/common";


class Fountain extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();
    this.startSceneImageRef = React.createRef();
    this.sceneImageRef = React.createRef();
    this.backgroundGroupRef = React.createRef();
    this.towerDropletsGroupRef = React.createRef();
    this.fountainDropletsGroupRef = React.createRef();
    this.dynamicLayerRef = React.createRef();

    this.start = false;
    this.stop = false;

    this.const = {
      g: 0.2,
    }

    this.tower = {
      x: 501,
      y: 395,
      miny: 100,
      drainShiftVector: 
        Victor(529.5-501, 516-395),
      maxdrops: 50,
      dropfloor: 515,
      height: 0,
    }
    this.tower.posVector = Victor(this.tower.x, this.tower.y);
    this.tower.drainVector = this.tower.drainShiftVector.clone().add(Victor(this.tower.x, this.tower.y));

    this.fountain = {
      x: 278.5,
      y: 515,
      maxdrops: 300,
    }

    this.fountain.drainVector = Victor(this.fountain.x, this.fountain.y);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidMount() {
    let stageRef = this.stageRef?.current;
    stageRef.on('click', (e) => this.onClickDebug(e));
    const backgroundGroup = this.backgroundGroupRef?.current;
    backgroundGroup.draw();
    window.requestAnimationFrame(this.move);
    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 8000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  move = (time) => {

    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    if (!this.prevTime) { this.prevTime = time; }
    let timeDelta = time - this.prevTime;
    this.prevTime = time;
    // let dT = Victor(timeDelta/16, timeDelta/16);
    // if (dT.length() > 1.5) { dT = Victor(1, 1); }
    let dT = Victor(1, 1);
    this.tower.drainVector = this.tower.posVector.clone().add(this.tower.drainShiftVector);

    const towerDropletsGroup = this.towerDropletsGroupRef?.current;
    const towerDroplets = towerDropletsGroup.getChildren();

    if (!this.tower.hose) {
      let hose = new Konva.Line({
        stroke: '#29ABE2',
        strokeWidth: 3,
        x: this.tower.drainVector.x + 0.25,
        y: this.tower.dropfloor + 2,
        points: [0, 0, 0, 0],
      })

      this.tower.hose = hose;
      towerDropletsGroup.add(hose);
    }

    this.tower.hose.setAttr('points', [0, 0, 0,
      this.tower.drainVector.y-this.tower.dropfloor - 2]);




    // if (towerDroplets.length < this.tower.maxdrops) {
    //   let towerDroplet = new Konva.Line({
    //     x: this.tower.drainVector.x,
    //     y: this.tower.drainVector.y + (Math.random() - 0.5)*2,
    //     points: [0, 0, 0, 2],
    //     V: Victor(0, 0),
    //     stroke: '#29ABE2',
    //     strokeWidth: 2,
    //     lineCap: 'round'
    //   })

    //   towerDropletsGroup.add(towerDroplet);
    // }

    // for (let i = 0; i < towerDroplets.length; i++) {

    //   let droplet = towerDroplets[i];
    //   let pos = droplet.position();

    //   if (pos.y > this.tower.dropfloor) {
    //     droplet.destroy();
    //     continue;
    //   }

    //   let V = droplet.getAttr('V');
    //   let points = droplet.getAttr('points'); 
    //   V.add(Victor(0, this.const.g));
    //   V.x > 0 ? V.x -= Math.random()*0.3 : V.x += Math.random()*0.3;
    //   points[3] = 2 + Math.abs(V.y/6);

    //   droplet.setAttr('V', V);
    //   droplet.setAttr('points', points);
    //   droplet.position(Victor(pos.x, pos.y).add(V.multiply(dT)));

    // }

    const fountainDropletsGroup = this.fountainDropletsGroupRef?.current;
    const fountainDroplets = fountainDropletsGroup.getChildren();

    if (fountainDroplets.length < this.fountain.maxdrops) {
      let fountainDroplet = new Konva.Line({
        x: this.fountain.drainVector.x,
        y: this.fountain.drainVector.y - 4 - Math.random(),
        points: [0, 0, 0, 0],
        V: Victor((Math.random()-0.5)*0.5, -3 + Math.random()*(-1) + this.tower.height/35),
        stroke: '#29ABE2',
        strokeWidth: 2,
        lineCap: 'round'
      })

      fountainDropletsGroup.add(fountainDroplet);
    }

    for (let i = 0; i < fountainDroplets.length; i++) {

      let droplet = fountainDroplets[i];
      let pos = droplet.position();

      if (pos.y > this.fountain.y - 4) {
        droplet.destroy();
        continue;
      }

      let V = droplet.getAttr('V');
      let points = droplet.getAttr('points'); 
      V.add(Victor(0, this.const.g));
      points[3] = 2 + Math.abs(V.y/6);

      droplet.setAttr('V', V);
      droplet.setAttr('points', points);
      droplet.position(Victor(pos.x, pos.y).add(V.multiply(dT)));

    }
  
    this.updateStage();
  }

  updateStage() {
    const dynamicLayer = this.dynamicLayerRef?.current;
    dynamicLayer.draw();

    // const stageNode = this.stageRef?.current;
    // stageNode.draw();
  }

  fade = (time) => {

    const startScene = this.startSceneImageRef?.current;

    if (this.stop) {
      window.cancelAnimationFrame(this.startRAF);
      startScene.destroy();
      return;
    }

    if (!this.prevFadeTime) {
      this.prevFadeTime = time;
    }
    let timeDelta = time - this.prevFadeTime;
    this.prevFadeTime = time;

    let currentOpacity = startScene.getAttr('opacity');
    let newOpacity = currentOpacity - timeDelta/500;
    if (newOpacity <= 0) {
      newOpacity = 0;
      this.stop = true;
    }

    startScene.setAttr('opacity', newOpacity);

    window.requestAnimationFrame(this.fade);
  }

  dragBound = (pos) => {
    pos.x = this.tower.x;
    pos.y = pos.y > this.tower.y-100 ? this.tower.y-100 : pos.y;
    pos.y = pos.y < this.tower.miny ? this.tower.miny : pos.y;
    return pos;
  }

  onDragStart = (e) => {
    if (this.start) { return; }

    this.start = true;
    this.startRAF = window.requestAnimationFrame(this.fade);
    this.moveRAF = window.requestAnimationFrame(this.move);
  }

  onDragMove = (e) => {
    const target = e.target;
    let pos = target.position();
    this.tower.height = pos.y - this.tower.y;
    this.tower.posVector = Victor(pos.x, pos.y);
  }

  onClickDebug = (e) => {

    // const stageNode = this.stageRef?.current;
    // console.log(stageNode.getPointerPosition());
  }


  Canvas = () => {

    const [startScene] = useImage(startSceneImage);
    const [emptyScene] = useImage(emptySceneImage);
    const [tower] = useImage(towerImage);

    return (
      <React.Fragment>

        <Layer>
          <Rect width={1000} height={150} y={450} fill={'#d7c084'}/>
        </Layer>
        <Layer>
          <Group y={-100}>
            <Group ref={this.backgroundGroupRef}>
              <KonvaImage ref={this.sceneImageRef} image={emptyScene}/>
              <Text
                x={50} y={130}
                ref={this.startSceneImageRef}
                text={'Что произойдет, если поднять водонапорную башню?\nПочему?'}
                lineHeight={1.3}
                fontSize={30}
                fill={'#4a6c97'}
                onClick={this.onStartClick}
                onTap={this.onStartClick}
              />
            </Group>

            <Group>
              <KonvaImage
                ref={this.towerImageRef}
                image={tower}
                dragBoundFunc={this.dragBound}
                draggable={true}
                onDragMove={this.onDragMove}
                onDragStart={this.onDragStart}
                x={this.tower.x}
                y={this.tower.y}
                />
            </Group>
          </Group>
        </Layer>

        <Layer ref={this.dynamicLayerRef}>
          <Group y={-100}>
            <Group>
              <Group
                ref={this.towerDropletsGroupRef}>
              </Group>

              <Group
                ref={this.fountainDropletsGroupRef}>
              </Group>
            </Group>
          </Group>
        </Layer>

      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
          <this.Canvas/>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Fountain);

const styles = {
  mainContainer: {
    background: 'white',
  }
};
