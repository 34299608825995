import {Group, Line, Rect, Text} from "react-konva";
import {defaultCanvasShadowStyles} from "../../../utils/styles";
import React from "react";


const TaskComplete = (props) => {
  let {
    x = 250,
    y = 120,
    height = 330,
    width = 450,
    arrowScale,
    arrowX=120,
    arrowY=180,
    textX=-140,
    textY=50,
    fontSize=40,
    visible,
    text,
    withoutBackground=false,
    withoutText=false
  } = props;
  let corrFontSize = fontSize;
  let corrWidth = width;
  let corrHeight = height;
  let rectX = 0;
  let rectY = 0;
  if (withoutBackground) {
    corrWidth = 300;
    corrHeight = 80;
    rectX = 50;
    rectY = 220;
    textX = -70;
    textY = 63;
    corrFontSize = 30;
  }
  return (
    <Group x={x} y={y} visible={visible}>
      <Rect
        width={corrWidth} x={rectX} y={rectY}
        height={corrHeight} fill={'white'}
        {...defaultCanvasShadowStyles} shadowOpacity={.5}
        cornerRadius={5}
        visible={!withoutText}
      />
      <Group x={arrowX} y={arrowY}>
        <Line points={[0,-50,60,0,170,-120]} strokeWidth={30} stroke={'#8ccbb6'} scale={{x: arrowScale, y: arrowScale}}/>
        <Text
          visible={!withoutText}
          width={corrWidth} align={'center'}
          x={textX} y={textY}
          text={text ? text : 'Урок пройден!'}
          fill={'#8ccbb6'} fontSize={corrFontSize}
        />
      </Group>
    </Group>
  )
}
export default TaskComplete;