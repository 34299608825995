import {Layer, Stage} from "react-konva";
import React, {useEffect, useState} from "react";
import { isWide, isWideScreen } from "../../mainPage/components/LessonsSwitcher";
import VideoBlock from "../components/CanvasVideoBlock";
import Theory from "../../components/theory/Theory";


export const width = 1000;
export const widthPhaser = 963;
export const height = 700;
export const height43 = 700;
export const heightPhaser = 544;
export const height169 = 562;

export const getHeight = (lessonCode, isPhaser) => {
  if (isPhaser) {
    return heightPhaser;
  } else if (isWideScreen(lessonCode)) {
    return height169;
  } else {
    return height43;
  }
};

export const fitStageIntoParentContainer = (stageRef, lessonCode) =>
{
  const konvaContainer = document.querySelector('#konva-wrapper');
  const stageNode = stageRef?.current;

  if (!stageNode || !konvaContainer) return;

  const windowHeight = document.documentElement.clientHeight;
  const windowWidth = document.documentElement.clientWidth;

  const stageWidth = width;
  const stageHeight = getHeight(lessonCode);
  const ratio = stageWidth/stageHeight;

  // --- set correct size lsn container ---
  const indentations = 40;
  let correctWidth, correctHeight;

  if (windowHeight < windowWidth) {
    correctWidth = windowHeight * ratio - indentations;
    correctHeight = windowHeight - indentations;
  }

  if (windowHeight > windowWidth || windowWidth < correctWidth){
    correctWidth = windowWidth - indentations;
    correctHeight = windowWidth / ratio - indentations;
  }

  konvaContainer.style.width = `${correctWidth}px`;
  konvaContainer.style.height = `${correctHeight}px`;
  // --- --- ---


  const containerWidth = konvaContainer.offsetWidth;
  const scale = containerWidth / stageWidth;

  stageNode.width(stageWidth * scale);
  stageNode.height(stageHeight * scale);
  stageNode.scale({ x: scale, y: scale });
  stageNode.draw();
};


const CanvasContainer = (props) => {
  const {
    children, customStyle,
    stageRef, x, y,
    offsetX, offsetY,
    lessonCode='',

    theoryVisible,
    withVideo,
    component,
    additionalVideoStgs,
    additionalTheoryStgs,
  } = props;
  const [isStageRef, setIsStageRef] = useState(false);
  useEffect(() => {
    if (!isStageRef) {
      window.addEventListener('resize', () => fitStageIntoParentContainer(stageRef, lessonCode));
      setIsStageRef(!isStageRef);

      window.dispatchEvent(new Event('resize'));
    }
    return () => {
      window.removeEventListener('resize', () => fitStageIntoParentContainer(stageRef, lessonCode));
    }
  }, [stageRef]);

  return (
    <>
      <Stage
        ref={stageRef}
        width={width}
        height={getHeight(lessonCode)}
        x={x}
        y={y}
        offsetX={offsetX}
        offsetY={offsetY}
        style={{...styles.canvasContainer, ...customStyle}}
      >
        {children}
        {
          (withVideo && component.data.videoSrc) && (
            <Layer visible={component.data.videoSrc}>
              <VideoBlock
                src={component.data.videoSrc}
                onVideoEnd={component.data.onVideoEnd ? () => component.data.onVideoEnd(component) : undefined}
                skipVideo={component.data.onVideoEnd ? () => component.data.onVideoEnd(component) : undefined}
                scenarioManager={component.scenarioManager}
                {...additionalVideoStgs}
              />
            </Layer>
          )
        }
      </Stage>
      {
        theoryVisible && (
          <Theory
            data={component.props.lessonData?.theory}
            onClickSkipTheory={() => component.data.onClickSkipTheory?.(component)}
            lessonComplete={component.data.lessonComplete}
            onClickReset={component.onClickReset}
            {...additionalTheoryStgs}
          />
        )
      }
    </>
  )};

const styles = {
  canvasContainer: {
    zIndex:2,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

export default CanvasContainer;
