import React, {useEffect, useState} from "react";
import '../../layout2/styles/layout.css';
import '../styles/homeworkAuth.css';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {_t} from "../../../../utils/lang/common";
import {Button, Form, Input} from "antd";
import AuthFormWrapper from "../../../auth/components/AuthFormWrapper";
import {ymStudentFollowedLinkGoal, ymStudentRegistrationFromLinkGoal} from "../../../../metriks/ym_metriks";


const HomeworkAuth = (props) => {
  const {user, logoutAction, history} = props;
  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastName] = useState(user?.last_name || '');

  useEffect(() => {
    ymStudentFollowedLinkGoal();

    (async function() {
      let loginRes = await props.checkLogin();
      if (loginRes.status) {
        joinToHomework();
      }
    })();
  }, [])

  useEffect(() => {
    if (user) {
      history.push('/student-homework');
    }
  }, [user])

  const joinToHomework = async (e) => {
    const hwCode = props.match.params.code;
    if (!hwCode) {
      return console.error('homework code not found');
    }

    const res = await props.joinToHomework(firstName, lastName);

    /** Регистрация ученика */
    ymStudentRegistrationFromLinkGoal()

    if (res?.redirect) {
      history.push(res.redirect);
    }
  }

  const requiredFieldErrTxt = `Поле обязательно для заполнения`;
  return (
      <AuthFormWrapper
        cardStyle={{maxWidth: '420px', padding: '20px 10px'}}
        className={'homework-auth'}
        title={'New to the platform?'}
      >
        <Form
          onFinish={joinToHomework}
          method={'GET'}
          style={{maxWidth: '250px'}}
        >
          <Form.Item rules={[{ required: true, message: requiredFieldErrTxt }]} initialValue={firstName} name={'firstName'}>
            <Input
              id={'firstNameHwJoinForm'}
              type="text"
              name={'firstName'}
              value={firstName}
              placeholder={_t("placeholder_name")}
              style={styles.input}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
          <Form.Item rules={[{ required: true, message: requiredFieldErrTxt }]} initialValue={lastName} name={'lastName'}>
            <Input
              id={'lastNameHwJoinForm'}
              type={'text'}
              name={'lastName'}
              value={lastName}
              placeholder={_t("placeholder_last_name")}
              style={styles.input}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
          <Button
            size={'large'}
            type="primary"
            htmlType="submit"
            style={{margin: '10px auto', width: "150px", borderRadius: '5px'}}
          >
            {_t("button_login")}
          </Button>

          <div>
            <Button
              style={{lineHeight: 1.5, color: '#61a0cda3', marginTop: '15px'}}
              className={'login__forgot-password-btn'}
              type={'link'}
              href={`/login?hw-auth=${window.location.pathname}`}
            >
              Есть аккаунт? <br/>
              Войти через почту и пароль
            </Button>
          </div>
        </Form>
      </AuthFormWrapper>
  )
}



const mapStateToProps = (state, {match}) => {
  return {
    homeworks: state.lessonsReducer.homeworks,
    user: state.lessonsReducer.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  const {code} = props.match.params;
  return {
    checkLogin: () => dispatch(actions.checkLogin(undefined, true)),
    joinToHomework: (firstName, lastName) => dispatch(actions.joinToHomework(firstName, lastName, code)),
    fetchHomework: () => dispatch(actions.fetchHomework()),
    updateHomework: ({hwId, taskId}) => dispatch(actions.updateHomework({hwId, title: undefined, lsnId: taskId, action: 'remove'})),
    createHomework: (title) => dispatch(actions.createHomework(title)),
  }
};


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeworkAuth));



const styles = {
  input: {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  }
};
