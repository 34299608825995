import {Group, Line, Text} from "react-konva";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {layout2, mainColor} from "../../../../utils/styles";
import {inputVal} from "../../../../utils/common";
import React from "react";

export const getFormulaWithInput = (code, data, $this) => {
  let corrFormula = defaultCardFormulaWithInput($this, data);
  switch (code) {
    case 'crane9/workFormulaWith2Input1':
      return corrFormula = getCrane9WorkFormulaWith2Input1($this, data);
    case 'crane9/workFormula2':
      return corrFormula = getCrane9WorkFormula2($this, data);
    case 'crane9/powerFormula1':
      return corrFormula = getCrane9PowerFormula($this, data);
    case 'crane9/powerFormula2':
      return corrFormula = getCrane9PowerFormula2($this, data);
    case 'crane9/powerFormula3':
      return corrFormula = getCrane9PowerFormula3($this, data);
    case 'crane9/powerFormula4':
      return corrFormula = getCrane9PowerFormula4($this, data);
    case 'crane9/powerFormula5':
      return corrFormula = getCrane9PowerFormula5($this, data);
  }
  return (
    <>
      {corrFormula}
    </>
  )
}

const defaultCardFormulaWithInput = ($this, data) => {
  const formulaSettings = {fill: mainColor, fontSize: 35}
  const cardSettings = data.cardFormulaSettings || {};
  return (
    <Group>
      <Text x={20} text={'A = '} {...formulaSettings} {...cardSettings.txtFirst}/>

      <CanvasInput
        id={'1'}
        x={100} y={-5}
        width={100}
        height={40}
        stage={$this.stageRef?.current}
        textColor={mainColor}
        onInput={(val) => { data.inputVal = Number(inputVal(val)) }}
        value={data.inputVal}
        {...cardSettings.input}
      />

      <Text x={220} y={0} text={'x  h '} {...formulaSettings} {...cardSettings.txtSecond}/>
    </Group>
  )
}

const getCrane9WorkFormulaWith2Input1 = ($this, data) => {
  const formulaSettings = {fill: mainColor, fontSize: 35}
  const cardSettings = data.cardFormulaSettings || {};
  const stageRef = $this.stageRef?.current;
  return (
    <Group>
      <Text x={20} text={'A = '} {...formulaSettings}/>
      <CanvasInput
        id={'1'}
        x={80} y={-5}
        width={100}
        height={40}
        stage={stageRef}
        textColor={mainColor}
        onInput={(val) => { data.inputWidth = Number(inputVal(val)) }}
        value={data.inputWidth}
        {...cardSettings.input}
      />

      <Text x={175} y={0} text={' H '} {...formulaSettings} fill={layout2.gray}/>
      <Text x={220} y={0} text={' x'} {...formulaSettings}/>

      <CanvasInput
        id={'2'}
        x={260} y={-5}
        width={50}
        height={40}
        stage={stageRef}
        textColor={mainColor}
        onInput={(val) => { data.inputHeight = Number(inputVal(val)) }}
        value={data.inputHeight}
        {...cardSettings.input}
      />

      <Text x={310} y={0} text={' м '} {...formulaSettings} fill={layout2.gray}/>
    </Group>
  )
}
const getCrane9WorkFormula2 = ($this, data) => {
  const formulaSettings = {fill: mainColor, fontSize: 35}
  const cardSettings = data.cardFormulaSettings || {};
  const stageRef = $this.stageRef?.current;
  return (
    <Group>
      <Text x={20} text={'A = 1000'} {...formulaSettings}/>
      <Text x={155} y={0} text={' H '} {...formulaSettings} fill={layout2.gray}/>
      <Text x={190} y={0} text={' x 10 = '} {...formulaSettings}/>

      <CanvasInput
        id={'2'}
        x={310} y={-5}
        width={150}
        height={40}
        stage={stageRef}
        textColor={mainColor}
        onInput={(val) => { data.inputWork = Number(inputVal(val)) }}
        value={data.inputWork}
        {...cardSettings.input}
      />

      <Text x={460} y={0} text={' Дж '} {...formulaSettings} fill={layout2.gray}/>
    </Group>
  )
}

const getCrane9PowerFormula = ($this, data) => {
  const formulaSettings = {fill: mainColor, fontSize: 35}
  const cardSettings = data.cardFormulaSettings || {};
  const stageRef = $this.stageRef?.current;
  return (
    <Group x={5} y={10}>
      <Text x={20} text={'N =  '} {...formulaSettings}/>
      <Line x={90} y={14} points={[0,0,30,0]} stroke={mainColor}/>
      <Text x={83} y={-20} text={' A '} {...formulaSettings}/>
      <Text x={90} y={20} text={' t '} {...formulaSettings}/>
    </Group>
  )
}
const getCrane9PowerFormula2 = ($this, data) => {
  const formulaSettings = {fill: mainColor, fontSize: 35}
  const cardSettings = data.cardFormulaSettings || {};
  const stageRef = $this.stageRef?.current;
  return (
    <Group x={5} y={25}>
      <Text x={20} text={'N =  '} {...formulaSettings}/>

      <CanvasInput
        id={'1'}
        x={80} y={-35}
        width={130}
        height={40}
        stage={stageRef}
        textColor={mainColor}
        onInput={(val) => { data.inputWork = Number(inputVal(val)) }}
        value={data.inputWork}
        {...cardSettings.input}
      />


      <CanvasInput
        id={'1'}
        x={130} y={25}
        width={60}
        height={40}
        stage={stageRef}
        textColor={mainColor}
        onInput={(val) => { data.inputTime = Number(inputVal(val)) }}
        value={data.inputTime}
        {...cardSettings.input}
      />

      <Line x={90} y={14} points={[0,0,190,0]} stroke={mainColor}/>
      <Text x={210} y={-30} text={' Дж '} {...formulaSettings} fill={layout2.gray}/>
      <Text x={190} y={30} text={' с '} {...formulaSettings} fill={layout2.gray}/>
    </Group>
  )
}
const getCrane9PowerFormula3 = ($this, data) => {
  const formulaSettings = {fill: mainColor, fontSize: 35}
  const cardSettings = data.cardFormulaSettings || {};
  const stageRef = $this.stageRef?.current;
  return (
    <Group x={5} y={25}>
      <Text x={20} text={'N =  '} {...formulaSettings}/>

      <Text x={100} y={-30} text={' 10000 '} {...formulaSettings}/>
      <Text width={80} align={'center'} x={120} y={30} text={` ${data.cardFormulaSettings.time} `} {...formulaSettings}/>


      <Line x={90} y={14} points={[0,0,190,0]} stroke={mainColor}/>
      <Text x={200} y={-30} text={' Дж '} {...formulaSettings} fill={layout2.gray}/>
      <Text x={170} y={30} text={' с '} {...formulaSettings} fill={layout2.gray}/>
      <Text x={280} y={0} text={' = '} {...formulaSettings}/>
      <Text x={420} y={0} text={' Вт '} {...formulaSettings} fill={layout2.gray}/>


      <CanvasInput
        id={'1'}
        x={320} y={-5}
        width={100}
        height={40}
        stage={stageRef}
        textColor={mainColor}
        onInput={(val) => { data.inputPower = Number(inputVal(val)) }}
        value={data.inputPower}
        {...cardSettings.input}
      />
    </Group>
  )
}
const getCrane9PowerFormula4 = ($this, data) => {
  const formulaSettings = {fill: mainColor, fontSize: 35}
  const cardSettings = data.cardFormulaSettings || {};
  const stageRef = $this.stageRef?.current;
  return (
    <Group>
      <Text x={20} text={'N = 1000 Вт = '} {...formulaSettings}/>

      <CanvasInput
        id={'1'}
        x={250} y={-5}
        width={60}
        height={40}
        stage={stageRef}
        textColor={mainColor}
        onInput={(val) => { data.inputPower = Number(inputVal(val)) }}
        value={data.inputPower}
        {...cardSettings.input}
      />

      <Text x={310} y={0} text={' кВт '} {...formulaSettings}/>
    </Group>
  )
}
const getCrane9PowerFormula5 = ($this, data) => {
  const formulaSettings = {fill: mainColor, fontSize: 35}
  const cardSettings = data.cardFormulaSettings || {};
  const stageRef = $this.stageRef?.current;
  return (
    <Group y={5}>
      <Text x={20} text={'N =         кВт'} {...formulaSettings}/>

      <CanvasInput
        id={'1'}
        x={90} y={-5}
        width={60}
        height={40}
        stage={stageRef}
        textColor={mainColor}
        onInput={(val) => { data.inputPower = Number(inputVal(val)) }}
        value={data.inputPower}
        {...cardSettings.input}
      />
    </Group>
  )
}