import React from "react";

export const getScenario = (code) => {
  if (code === 'pulley1') {
    return fixedBlockScenario;
  }
}

export const fixedBlockScenario = [
  {
    key: 'fixedBlock',
    patchData: {
      step: 1,
      btnVisible: false,
      centerFixedBLockVisible: true,
      weightPos: {x: 60, y: 225},
      lineDraggablePos: {
        x: 60, y: 0, points: []
      },
      containerPos: { x: 500, y: 200, },
      rope1LeftPos: {x: 0, y: 0},
      rope1RightPos: {x: 60, y: 0, maxHeightLift: 150, lineHeight: 225},

      formulaStg: {
        x: 150,
        width: 130,
        height: 160,
      }
    },
    next_success: 'fixedBlock2',
  },
  {
    key: 'fixedBlock2',
    patchData: {
      step: 2,
      btnVisible: true,
      centerFixedBLockVisible: true,
      weightPos: {x: 60, y: 225},
      lineDraggablePos: {
        x: 60, y: 0, points: []
      },
      containerPos: { x: 500, y: 200, },
      rope1LeftPos: {x: 0, y: 0},
      rope1RightPos: {x: 60, y: 0, maxHeightLift: 150, lineHeight: 225},

      formulaStg: {
        x: 150,
        width: 130,
        height: 160,
      }
    },
    next_success: 'movableBlock',
  },


  {
    key: 'movableBlock',
    patchData: {
      step: 3,
      btnVisible: false,
      movableBLockVisible: true,
      weightPos: {x: 102, y: 315},
      lineDraggablePos: {
        x: 40, y: 0, points: []
      },
      containerPos: { x: 500, y: 120, },

      arrow1Pos: {x: 0, y: 80},
      rope1LeftPos: {x: 72, y: 40},
      rope1RightPos: {x: 72, y: 35, maxHeightLift: 160, lineHeight: 185},
      rope2LeftPos: {x: 193, y: -5, lineHeight: 235},

      formulaStg: {x: 300, y: 50,},

      achievementVisible: false,
    },
  },
  {
    key: 'success',
    patchData: {
      step: 4,
      btnVisible: false,
      movableBLockVisible: true,
      weightPos: {x: 102, y: 315},
      lineDraggablePos: {
        x: 40, y: 0, points: []
      },
      containerPos: { x: 500, y: 120, },

      rope1LeftPos: {x: 72, y: 40},
      rope1RightPos: {x: 72, y: 35, maxHeightLift: 160, lineHeight: 185},
      rope2LeftPos: {x: 193, y: -5, lineHeight: 235},

      formulaStg: {x: 300, y: 50},

      achievementVisible: true
    },
  },
  // {
  //   key: 'success2',
  //   patchData: {
  //     step: 5,
  //     btnVisible: false,
  //     movableBLockVisible: true,
  //     weightPos: {x: 102, y: 315},
  //     lineDraggablePos: {
  //       x: 40, y: 0, points: []
  //     },
  //     containerPos: { x: 500, y: 120, },
  //
  //     rope1LeftPos: {x: 72, y: 40},
  //     rope1RightPos: {x: 72, y: 35, maxHeightLift: 160, lineHeight: 185},
  //     rope2LeftPos: {x: 193, y: -5, lineHeight: 235},
  //
  //     formulaStg: {x: 300, y: 50},
  //
  //     achievementVisible: true
  //   },
  // },
];
