import { Image } from "react-konva";
import React from "react";
import {pxPerMetre} from "../settings";

export const CanvasSpaceStation = (props) => {
  const {
    stationImage, rotation,
    width=200 / 17.5, // In metres
    height=200 / 17.5,
  } = props;

  return(
    <Image
      image={stationImage}
      width={width * pxPerMetre}
      height={height * pxPerMetre}
      offsetX={width * pxPerMetre/2}
      offsetY={height * pxPerMetre/2}
      rotation={rotation}
      preventDefault={false}
    />
  )
};
