import React from "react";
import CanvasContainer from "../../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import bgImg from '../../../../../images/liftingLoadMultiple/background.png';
import wheelImg from '../../../../../images/liftingLoadMultiple/wheel.png';
import triangleGrayImg from '../../../../../images/liftingLoadMultiple/triangleGray.png';
import windingArrowImg from '../../../../../images/liftingLoadMultiple/windingArrow.png';
import supportStandImg from '../../../../../images/liftingLoadMultiple/supportStand.png';
import springboardImg from '../../../../../images/liftingLoadMultiple/springboard.png';
import starImg from '../../../../../images/allLifting/star.png';
import starOpacityImg from '../../../../../images/allLifting/star_opacity.png';
import defaultCargoImg from "../../../../../images/liftingLoadMultiple/cargo.png";
import cargoImg from "../../../../../images/allLifting/cargo.png";
import pianoImg from "../../../../../images/allLifting/piano.png";
import bricksImg from "../../../../../images/allLifting/briks.png";
import concreteMixerImg from "../../../../../images/allLifting/concreteMixer.png";
import bowlImg from "../../../../../images/allLifting/bowl.png";
import ScenarioManager from "../../../../../utils/ScenarioManager";
import {getMethodsListByCode, getScenario, initialData} from "../scenario";
import MovingArrow from "../../../../canvas/components/MovingArrow";
import ArrowHint from "../../../../canvas/components/ArrowHint";
import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import {layout2, lsnLabelTxtStyle, mainColor} from "../../../../../utils/styles";
import {_t} from "../../../../../utils/lang/common";
import {dragLineMove, dragMoveCargo, dragMoveLeverHandle, getBoolCode} from "../utils";
import {
  getTimeDeltaSec,
  hasIntersection,
  inputVal,
  sendSuccessForScenario,
  showFailOrSuccessPopup
} from "../../../../../utils/common";
import * as actions from "../../../../../store/actions";
import Card from "../../../../canvas/components/Card";
import CanvasInput from "../../../../canvas/components/CanvasInput";
import CanvasButton from "../../../../canvas/components/CanvasButton";
import TaskComplete from "../../../../canvas/components/TaskComplete";
import {SelectingMethods} from "../components/SelectingMethods";
import {DragLineComponent} from "../components/common";
import CargosList from "../components/CargosList";

class LiftingWithTime extends React.Component {

  _movingCallbacks = {};

  constructor(props) {
    super(props);

    this.methodsList = getMethodsListByCode(this.props.code);

    this.managedComponents = [
      'stage',

      'cargo',
      'cargo2',
      'cargo2RotationContainer',
      'cargoLine',
      'cargoLineSecond',
      'cargoLineThird',

      'titleTxt',

      'wheel1',
      'wheel1Container',

      'wheel2',
      'wheel3',
      'wheel4',
      'formula',

      'windingCircle',
      'windingWheel',

      'leverHandle',
      'leverHandleArrow',
      'dragLeverHandleBox',

      'cargo2Text1',
      'cargo2Text2',

      'cargoHeight',

      'cargoText',
      'ropeText',
      'leverHandleText',

      'dragCableArrowHint',
      'selectMethodArrowHint',
      'movingArrowHintSpringboard',

      'dragLine',
      'dragRect',

      'movingArrowHint',

      'triangleActionBlock',
      'cargoActionBlock',

      'timeTxt',

      ...this.methodsList.map((num) => `methodImg${num}`),
    ];

    this.timeTxt = 0;
    this.data = cloneDeep(initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();

  _getNode = (key) => this[`${key}Ref`]?.current;

  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.scenarioManager.resetScenario();
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onClickStart = () => {
    this.scenarioManager.selectStepByKey('piano-method-1');
  }
  onClickReset = () => {
    this.data = cloneDeep(initialData);
    this.updateStage();
    this.timeTxt = 0;
    if (this.data.resetBtnPrev) {
      this.scenarioManager.back();
    } else {
      this.scenarioManager.resetScenario();
    }
  };
  nextCargo = () => {
    const data = this.data;
    if (data.start) {
      let key = data.cargoName;
      const indx = data.cargosList.findIndex(c => c.key === key);
      const newKey = data.cargosList[indx + 1]?.key;

      this.timerId = setTimeout(() => {
        if (newKey) {
          this.scenarioManager.selectStepByKey(`${newKey} initial`);
        } else {
          sendSuccessForScenario(this);
        }
      }, 500)
    }
  };
  getCargoLinePoints = () => {
    const data =  this.data;
    return [0, data.cargoLineLength - data.draggedOutDelta, 0, 0];
  };
  getDragLinePoints = () => {
    const data =  this.data;
    return [0, 0, -330 - data.dragLineX*data.ropeCoeff, 0];
  };
  getCargoY = () => {
    const data =  this.data;
    return data.cargoY - data.draggedOutDelta;
  };

  dragLineMove = (e) => {
    const data = this.data;
    const newX = data.draggedOutDelta + e.evt.movementX*data.ropeCoeff;
    if (!data.draggable) {return }
    if (data.draggedOutDelta >= data.maxCargoY) {
      this.nextCargo();
      return;
    }

    let res = data.ropeForce;
    const isSingleBlock = data.liftingSingleBlock;
    const isDualBlock =  data.liftingDualBlock && !data.wheel4Visible;
    const isTripleBlock = data.liftingDualBlock && data.wheel4Visible;
    if (isDualBlock) {
      res = data.ropeForce * 2;
    }
    if (isTripleBlock) {
      res = data.ropeForce * 3;
    }

    if (res >= data.cargoWeight && e.evt.movementX > 0) {
      data.draggedOutDelta = newX > data.maxCargoY ? data.maxCargoY : newX;
    }
    data.dragging = true;
  };
  dragMoveLeverHandle = (e) => {
    const data = this.data;
    const position = e.target.getPosition();
    if (!data.draggable) {return }
    if (data.draggedOutDelta > data.maxCargoY) {
      this.nextCargo();
      return;
    }

    let newY = position.y;
    if (position.y > data.maxLeverHandleY) {
      newY = data.maxLeverHandleY;
    }
    if (position.y < data.minLeverHandleY) {
      newY = data.minLeverHandleY;
    }

    let draggedOutDelta = 0;

    const resForce = data.leverVal * data.leverForce;
    const isLeverActive = data.leverVal && (resForce >= data.cargoWeight);

    if (
      e.evt.movementY > 0 &&
      newY < data.maxLeverHandleY &&
      newY > data.minLeverHandleY &&
      (isLeverActive || !data.withCalc)
    ) {
      draggedOutDelta = e.evt.movementY/2;
    }

    data.leverHandleRotation = -newY;
    data.dragLeverHandleY = newY;
    data.dragLeverHandleX = -newY;
    data.draggedOutDelta += draggedOutDelta;

    data.dragging = true;
  };
  dragMoveCargo = (e) => {
    const data = this.data;
    const posAbs = e.target.getAbsolutePosition();
    const pos = e.target.getPosition();
    const angle = data.triangleAngle;
    const posX = pos.x;

    if (!data.draggable) {return }

    if (data.cargoX <= -data.maxCargoX) {
      this.nextCargo();
      return
    }

    let x = posX;
    let y = 8;
    let cargo2Rotation = 0;
    const triangleActionNode = this._getNode('triangleActionBlock');
    const cargoActionBoxNode = this._getNode('cargoActionBlock');
    const rectBoxIntersection = hasIntersection(triangleActionNode, cargoActionBoxNode, this);
    const triangleActionNodeX = triangleActionNode.x() + triangleActionNode.width();

    if (rectBoxIntersection) {
      y = posX * Math.tan(angle * Math.PI / 180) - angle * data.triangleYOffsetCoeff;
      cargo2Rotation = angle;
    }


    let heavyCargo = false;
    let coeff = data.liftingTriangleTxt.width.value/data.liftingTriangleTxt.height.value;
    if ((coeff * data.liftingDragMomentum) <= data.cargoWeight) {
      if (rectBoxIntersection) { return }
      heavyCargo = true;
    }



    if (posAbs.x < triangleActionNode.x()) {
      y = -data.buildHeight;
    }

    if (posX > 450) {
      x = 450;
    }

    const corrCargoX = !heavyCargo || (triangleActionNodeX < posAbs.x) ? x : data.cargoX;
    const corrCargoY = !heavyCargo || (triangleActionNodeX < posAbs.x) ? y : data.cargoY;
    data.cargo2Rotation = cargo2Rotation;
    data.cargoX = corrCargoX;
    data.cargoY = corrCargoY;
    data.dragging = true;
    this.prevCargoX = x;
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.scenarioStartTime = this.scenarioStartTime || time
    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = getTimeDeltaSec(timedelta);
    this.scenarioManager.passTimestampSec((time - this.scenarioStartTime)/1000);

    const percOfPx = data.draggedOutDelta > 0 ? data.draggedOutDelta / ((data.maxCargoY + 13.5) / 100) : 0;
    data.cargoHeight = Math.round(percOfPx / data.buildingHeight);

    if (data.start) {
      this.timeTxt = (this.timeTxt || 0) + timedeltaSec;
    }

    this.updateStage();
  };

  calcRotation = (factor= 1) => {
    const data = this.data;
    const wheelRadius = 8;
    // factor - чтобы учитывать подвижные блоки, которые крутятся в 2 раза медленнее (или быстрее?)
    return data.draggedOutDelta / wheelRadius / Math.PI * 180 * factor;
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    const isLiftingMethodSelected = this.methodsList.some((el, i) => data[`method${el}Visible`]);

    const liftingSingleBlock = data.liftingSingleBlock;
    const liftingDualBlock = data.liftingDualBlock;
    const liftingLeverHandle = data.liftingLeverHandle;
    const liftingInclinedSurface = data.liftingInclinedSurface;

    // Wheels rotation
    n['wheel1'].rotation(this.calcRotation(data.wheel1Factor));
    n['wheel2'].rotation(this.calcRotation(data.wheel2Factor));
    n['wheel3'].rotation(-this.calcRotation(data.wheel3Factor));
    n['wheel4'].rotation(this.calcRotation(data.wheel4Factor));

    // Arrows hints
    n['dragCableArrowHint'].visible((liftingDualBlock || liftingSingleBlock) && !data.dragging);
    n['selectMethodArrowHint'].visible(!isLiftingMethodSelected);
    n['movingArrowHintSpringboard'].visible(liftingInclinedSurface && !data.dragging);
    n['leverHandleArrow'].visible(liftingLeverHandle && !data.dragging && data.draggable);

    n['cargoText'].visible((liftingSingleBlock || liftingDualBlock || liftingLeverHandle) && data.dragging);
    n['ropeText'].visible((liftingSingleBlock || liftingDualBlock) && data.dragging);
    n['leverHandleText'].visible(liftingLeverHandle && data.dragging);

    n['cargo2Text1'].visible(liftingInclinedSurface && data.dragging);
    n['cargo2Text2'].visible(liftingInclinedSurface && data.dragging);


    n['dragLine'].x(data.dragLineX*data.ropeCoeff);
    n['dragLine'].y(data.dragLineY);
    n['dragLine'].points(this.getDragLinePoints());
    n['dragRect'].x(data.dragLineX*data.ropeCoeff);
    n['dragRect'].y(data.dragLineY);
    n['dragRect'].width(-330 - data.dragLineX*data.ropeCoeff);

    n['timeTxt'].text(`Время, с: ${Math.round(this.timeTxt||0)}`);

    // Cargo and lines
    n['cargoLine'].points(this.getCargoLinePoints());
    n['cargoLineSecond'].points(this.getCargoLinePoints());
    n['cargoLineThird'].points(this.getCargoLinePoints());

    n['cargo'].y(this.getCargoY());
    n['cargo2'].position({x: data.cargoX, y: data.cargoY});
    n['cargo2RotationContainer'].rotation(data.cargo2Rotation);
    n['wheel1Container'].y(this.getCargoY());

    n['dragLeverHandleBox'].x(data.dragLeverHandleX);
    n['dragLeverHandleBox'].y(data.dragLeverHandleY);
    n['leverHandle'].rotation(data.leverHandleRotation);


    n['titleTxt'].setAttrs({...n['titleTxt'].attrs, ...data.titleData});

    const radius = 8 + ((data.draggedOutDelta/65) + (1/data.ropeCoeff));
    n['windingCircle'].radius(radius);
    n['windingWheel'].rotation(-this.calcRotation(data.wheel1Factor));

    n['cargoHeight'].text(`${data.cargoHeight} м`);

    const maxLiftingHeight = data.buildingHeight; // м
    let liftingHeight = Math.round(data.draggedOutDelta / (data.maxCargoY / 100) * (maxLiftingHeight/100));
    if (liftingHeight > maxLiftingHeight) {
      liftingHeight = maxLiftingHeight
    }
    n['formula'].text(`A = ${data.ropeForce||0} Н x ${liftingHeight||0} м = ${(data.ropeForce*liftingHeight)||0} Дж`);



    n['stage'].draw();
  }

  getImg = (imgPath) => {
    const [liftingMethod] = useImage(imgPath);
    return liftingMethod
  }

  Canvas = () => {
    const data = this.data;

    const [bg] = useImage(bgImg);
    const [cargo] = useImage(data.cargoImg);
    const [wheel] = useImage(wheelImg);
    const [triangleGray] = useImage(triangleGrayImg);
    const [supportStand] = useImage(supportStandImg);
    const [springboard] = useImage(springboardImg);
    const [windingArrow] = useImage(windingArrowImg);

    const [piano] = useImage(pianoImg);
    const [bowl] = useImage(bowlImg);
    const [bricks] = useImage(bricksImg);
    const [bigCargo] = useImage(cargoImg);
    const [concreteMixer] = useImage(concreteMixerImg);

    const [star] = useImage(starImg);
    const [starOpacity] = useImage(starOpacityImg);

    const cargosImgs = {
      piano: piano,
      bowl: bowl,
      bricks: bricks,
      bigCargo: bigCargo,
      concreteMixer: concreteMixer,
    };


    const methodsImgs = {};
    this.methodsList.forEach((num) => {
      const imgPath = require(`../../../../../images/${data.imgDir}/liftingMethod${num}.png`);
      methodsImgs[`img${num}`] = this.getImg(imgPath);
    });

    const isLiftingMethodSelected = this.methodsList.some((el, i) => data[`method${el}Visible`]);

    const cargoIndex = data.cargosList.findIndex((c) => c.key === data.cargoName);
    const completedCargosList = data.cargosList.filter((c, i) => i<cargoIndex);
    const notCompletedCargosLis = data.cargosList.filter((c, i) => i>=cargoIndex);

    const triangleLifting = data.liftingInclinedSurface;

    const timeGrade = this.timeTxt < 60 ? 3 : this.timeTxt < 120 ? 2 : 1;
    return (
      <React.Fragment>
        <Group y={530}>
          <Rect width={1000} height={40} fill={'#EAA80E'}/>
          <Rect width={1000} height={8} fill={'#A0A3AC'}/>
        </Group>

        <Group scale={data.lsnMainContainerScale} y={data.lsnMainContainerY}>
          <Image y={triangleLifting ? 292 : 0} image={bg} scale={triangleLifting ? {x: .45, y: .45} : {x: 1, y: 1}}/>
          <Group visible={triangleLifting}>
            <Rect x={191} y={393} rotation={0} width={50} height={40} fill={'#F9F0D9'}/>
            <Rect x={185} y={423} rotation={0} width={23} height={100} fill={'#04913F'}/>
            <Rect width={1000} height={40} y={524} fill={'#EAA80E'}/>
          </Group>
          <Rect
            ref={this._ref('triangleActionBlock')}
            {...data.triangleActionBlockStg}
          />

          <Text
            visible={data.formulaVisible}
            x={70} y={100}
            ref={this._ref('formula')}
            text={`A = ${0} Н x ${0} м = ${0}`}
            fill={layout2.green}
            fontSize={30} fontStyle={'bold'}
          />

          <Group x={184.3} y={411.8}>
            <Image image={springboard} visible={triangleLifting} {...data.liftingTriangleStg}/>

            <Group visible={data.liftingTriangleTxt.visible}>
              <Text
                {...data.liftingTriangleTxt.width}
                text={`${data.liftingTriangleTxt.width.value} м`}
                rotation={data.triangleAngle}
              />
              <Text
                {...data.liftingTriangleTxt.height}
                text={`${data.liftingTriangleTxt.height.value}\nм`}
              />
            </Group>
          </Group>

          <Group>
            <Text
              text={''}
              fill={'dimgray'}
              fontSize={22}
              x={60} y={50}
              ref={this._ref('titleTxt')}
            />
            <Text visible={data.blockNameTxt} x={510} y={160} text={data.blockNameTxt} fill={mainColor} fontSize={15}/>
          </Group>

          <Group x={909} y={523} visible={data.windingBlockVisible}>
            <Image image={triangleGray} rotation={-90}/>

            <Group x={55} y={-60}>
              <Circle radius={15} fill={'lightgray'}/>
              <Circle radius={10} fill={'black'}/>
            </Group>
          </Group>


          <Group>
            <DragLineComponent
              data={this.data}
              getDragLinePoints={this.getDragLinePoints()}
              dragLineMove={this.dragLineMove}
              isLiftingMethodSelected={isLiftingMethodSelected}
              getMovingCallback={this.getMovingCallback}
              _getNode={this._getNode}
              _ref={this._ref}
              triangleGray={triangleGray}
              wheel={wheel}
            />


            <Group visible={data.wheel1ContainerVisible} ref={this._ref('wheel1Container')}>
              <Group x={450} y={470}>
                <Line x={12.5} y={10} points={[0,0,0,20]} stroke={'black'}/>
                <Image image={wheel} x={12} y={10} offsetY={12} offsetX={12} ref={this._ref('wheel1')}/>
              </Group>
            </Group>

            <Line
              ref={this._ref('cargoLineThird')}
              x={462.5} y={198} stroke={'black'}
              visible={data.cargoLineThirdVisible}
              strokeWidth={1.3}
            />
            <Rect
              x={446} y={198}
              width={20} height={12}
              fill={'#F9F0D9'}
              visible={data.wheel4Visible}
            />

            <Group
              x={400} y={178}
              visible={data.supportStandVisible}
            >
              <Group visible={data.leverHandleVisible}>
                <Group
                  ref={this._ref('leverHandle')}
                  x={85} y={20}
                >
                  <Rect
                    rotation={30}
                    offsetX={data.leverWidth}
                    fill={layout2.green}
                    width={data.leverWidth} height={6}
                  />
                </Group>

                <Group x={-100} y={-120}>
                  <Group ref={this._ref('leverHandleArrow')} x={30} y={60} rotation={-60}>
                    <Arrow points={[0,0,30,0,]} fill={'black'} stroke={'black'}/>
                    <Arrow rotation={180} points={[0,0,30,0]} fill={'black'} stroke={'black'}/>
                  </Group>
                  <Group ref={this._ref('leverHandleText')}>
                    <Text
                      visible={data.withCalc}
                      text={`max`} x={-110} y={50}
                      fontStyle={'bold'} fontSize={30} fill={mainColor}
                    />
                    <Text
                      text={`${data.leverForce} Н`} x={-40} y={50}
                      fontStyle={'bold'} fontSize={30} fill={mainColor}
                    />
                  </Group>
                  <Rect
                    ref={this._ref('dragLeverHandleBox')}
                    draggable
                    onDragMove={(e) => this.dragMoveLeverHandle(e)}
                    fill={'black'}
                    opacity={0}
                    width={150} height={200}
                  />
                </Group>
              </Group>
              <Image x={82} y={23} offsetY={12} offsetX={12} image={wheel} ref={this._ref('wheel2')}/>
              <Image image={supportStand} />
            </Group>

            <Group x={457} y={210} visible={data.wheel4Visible}>
              <Image scale={{x: .52, y: .52}} offsetY={12} offsetX={12} image={wheel} ref={this._ref('wheel4')}/>
              <Line points={[0,0,0,-10]} stroke={'black'} strokeWidth={1.3}/>
            </Group>

          </Group>

          <Group x={405} y={475}>

            <Group>
              <Group
                ref={this._ref('cargo')}
                x={data.cargoX} y={data.cargoY}
                visible={!triangleLifting && isLiftingMethodSelected}
              >
                <Image image={cargo} {...data.cargoImgStg}/>
                <Group x={45} y={35} ref={this._ref('cargoText')}>
                  <Group
                    x={20} y={20}
                    visible={data.cargoWeightLetter}
                  >
                    <Arrow
                      x={1} y={-2}
                      points={[0,0,55,0]} scale={{x: .3, y: .3}} stroke={mainColor} fill={mainColor}/>
                    <Text
                      text={'P'}
                      fontSize={30} fontStyle={'bold'}
                      fill={mainColor}
                    />
                  </Group>
                  <Text visible={!data.cargoWeightLetter && data.cargoGravityTxt } x={20} y={20} text={`${data.cargoWeight} Н`} fontSize={30} fontStyle={'bold'} fill={mainColor}/>
                  <Arrow points={[0,0,0,60]} stroke={'black'} fill={'black'} visible={data.cargoGravityTxt}/>
                </Group>
                <Text ref={this._ref('cargoHeight')} visible={data.cargoHeightVisible} x={75} y={20} text={`${data.cargoHeight} м`} fontSize={30} fontStyle={'bold'} fill={mainColor}/>
              </Group>

              {/* cargo 2 */}
              <Group x={30} y={20}>
                <Group
                  ref={this._ref('cargo2')}
                  draggable
                  onDragMove={(e) => this.dragMoveCargo(e)}
                  visible={triangleLifting}
                  x={data.cargoX} y={data.cargoY}
                >
                  <Group
                    ref={this._ref('cargo2RotationContainer')}
                    offsetX={30}
                    offsetY={20}
                    rotation={0}
                  >
                    <Rect ref={this._ref('cargoActionBlock')} x={14} y={5} width={33} height={35} fill={'red'} opacity={0}/>

                    <Image
                      {...data.cargoTrangleStg}
                      image={cargo}/>

                    <Group visible={triangleLifting}>
                      <Circle x={23} y={37} radius={4} fill={layout2.green}/>
                      <Circle x={38} y={37} radius={4} fill={layout2.green}/>
                    </Group>

                    <Group x={30} y={25} ref={this._ref('cargo2Text1')}>
                      <Text visible={data.withCalc} x={-175} y={-25} text={`max`} fontSize={25} fill={mainColor} fontStyle={'bold'}/>
                      <Text x={-115} y={-25} text={`${data.liftingDragMomentum} Н`} fontSize={25} fill={mainColor} fontStyle={'bold'}/>
                      <Arrow points={[0,0,-30,0]} stroke={mainColor} fill={mainColor}/>
                    </Group>
                  </Group>

                  <Group x={0} y={4} ref={this._ref('cargo2Text2')}>
                    <Group
                      x={15} y={20}
                      visible={data.cargoWeightLetter}
                    >
                      <Arrow
                        x={1} y={-2}
                        points={[0,0,55,0]} scale={{x: .3, y: .3}} stroke={mainColor} fill={mainColor}/>
                      <Text
                        text={'P'}
                        fontSize={30} fontStyle={'bold'}
                        fill={mainColor}
                      />
                    </Group>
                    <Text visible={!data.cargoWeightLetter} x={15} y={20} text={`${data.cargoWeight} Н`} fontStyle={'bold'} fontSize={30} fill={mainColor}/>
                    <Arrow points={[0,0,0,60]} stroke={mainColor} fill={mainColor}/>
                  </Group>
                </Group>
              </Group>

            </Group>

          </Group>




          {/* winding wheel */}
          <Group
            x={964} y={463}
            visible={data.windingBlockVisible}>

            {/* hide drag line */}
            <Rect x={10} y={9} width={20} height={5} fill={'#fcf0d7'}/>
            <Rect x={30} y={9} width={7} height={5} fill={'#DED6C4'}/>
            <Circle x={7} y={7} radius={5} fill={'lightgray'}/>


            <Circle radius={10} fill={'black'}/>
            <Circle radius={10} fill={'black'} ref={this._ref('windingCircle')}/>
            <Image image={wheel} offsetY={12} offsetX={12} ref={this._ref('windingWheel')}/>
          </Group>


          <MovingArrow
            id={'method4Springboard'}
            ref={this._ref('movingArrowHintSpringboard')}
            stageNode={this._getNode('stage')}
            length={90}
            arrowsStep={10}
            arrowsCount={1}
            x={data.triangleHintX} y={460}
            textColor={'black'}
            arrowColor={'black'}
            text={''}
            // rotation={105}
            rotation={93 + data.triangleAngle}
            visible={triangleLifting && data.draggable}
            {...data.arrowHintSpringboardStg}
            getMovingCallback={this.getMovingCallback}
          />
        </Group>


        <SelectingMethods
          methodsList={this.methodsList}
          data={this.data}
          methodsImgs={methodsImgs}
          scenarioManager={this.scenarioManager}
          _ref={this._ref}
          _getNode={this._getNode}
          isLiftingMethodSelected={isLiftingMethodSelected}
          getMovingCallback={this.getMovingCallback}
        />


        <Card x={810} y={200} width={150} height={60} visible={!!data.start}>
          <Text
            ref={this._ref('timeTxt')}
            x={20} y={22}
            text={`Время, с: ${0}`}
            fontStyle={'bold'}
            fontSize={16}
            fill={mainColor}/>
        </Card>

        <CanvasResetBtn y={525} onClick={() => this.onClickReset()}/>

        <Group x={435} y={492} visible={!isLiftingMethodSelected} scale={{x: 1.5, y: 1.5}}>
          <Text
            x={-3} y={-22}
            text={`${(data.cargosList[cargoIndex]?.weight || 0) / 10} кг`}
            fontStyle={'bold'}
            fontSize={16}
            fill={mainColor}/>
          <CargosList stepX={40} cargosImgs={cargosImgs} cargosList={notCompletedCargosLis}/>
        </Group>

        <Group
          x={triangleLifting ? 30 : data.liftingLeverHandle ? 150 : 200}
          y={triangleLifting ? 400 : data.liftingLeverHandle ? 280 : 247}
          scale={triangleLifting ? {x: .7, y: .7} : {x: 1, y: 1}}
        >
          <CargosList cargosImgs={cargosImgs} cargosList={completedCargosList}/>
        </Group>

        <Group x={600} y={200} visible={!data.completeLsnVisible}>
          <Group x={80} y={-70}>
            <Text fontSize={18} fill={mainColor} lineHeight={1.2} text={
              (!data.start? 'Используй простые механизмы, \nчтобы поднять все грузы.\n' : '') +
              'Максимальная сила, с которой ты \nможешь тянуть составляет 250 H.'
            }/>
          </Group>
          <CanvasButton
            visible={!data.start}
            text={'Начать'}
            x={150} y={40}
            height={40}
            onClick={() => this.onClickStart()}
          />
        </Group>

        <Group x={300} y={80} visible={data.completeLsnVisible}>
          <TaskComplete
            x={0} y={0}
            arrowX={140}
            arrowY={140}
            height={400}
            arrowScale={.9}
            fontSize={30}
            text={'Задание пройдено!'}
          />

          <Text
            y={230}
            text={`Затраченное временя, с: ${Math.round(this.timeTxt)}`}
            fontSize={16}
            fill={mainColor}
            width={450}
            align={'center'}
          />
          <Group x={70} y={280}>
            {
              Array(3).fill(1).map((el, i) => (
                <Image x={i*110} key={`star-`+i} image={timeGrade >= (i+1) ? star : starOpacity}/>
              ))
            }
          </Group>
        </Group>


      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiftingWithTime);

const styles = {
  mainContainer: {
    background: '#F9F0D9'
  }
};
