import React, {useState} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import Astronaut from "../components/Astronaut";
import backgroundImg from "../../../../images/space/spaceBackground2.png";
import {defaultPatchData, repulsionOfBodiesTwoAstronautScenario} from "../utils/RepulsionOfBodiesTwoAstronautScenario";
import CanvasButton from "../../../canvas/components/CanvasButton";
import DarkAnswerWindow from "../components/DarkAnswerWindow";
import shadowAstronaut1Img from "../../../../images/space/repulsion_of_bodies/shadowAstronaut1.png";
import shadowAstronaut2Img from "../../../../images/space/repulsion_of_bodies/shadowAstronaut2.png";
import fatManImg from "../../../../images/space/repulsion_of_bodies/fatMan.png";
import thinManImg from "../../../../images/space/repulsion_of_bodies/strongMan.png";
import {getTimeDeltaSec, showFailOrSuccessPopup} from "../../../../utils/common";
import VideoBlock from "../../../canvas/components/CanvasVideoBlock";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import * as actions from "../../../../store/actions";
import Theory from "../../../components/theory/Theory";


class RepulsionOfBodiesTwoAstronaut extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',

      'astronautLeft',
      'astronautRight',
    ]

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timedeltaSec: 0,

      astronautRightX: 0,
      astronautRightRotation: 0,
      astronautLeftX: 0,
      astronautLeftRotation: 0,

      leftAstronautSpeed: 0,
      rightAstronautSpeed: 0,

      fatAstronautCorrect: false,
      thinAstronautCorrect: false,

      title: '',

      lastTestStep: '',
      lastAnswerSuccess: undefined,

      selectLeft: false,
      selectRight: false,

      ...defaultPatchData
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(repulsionOfBodiesTwoAstronautScenario, this);
  }
  AstronautComp = React.forwardRef((props, ref) => {
    const {
      x, y,
      withMovableArm,
      getArmPushes,
      getResetArmRotation,
      selected
    } = props;
    return (
      <Group ref={ref}>
        <Group x={x} y={y}>
          <Astronaut
            getArmPushes={getArmPushes}
            getResetArmRotation={getResetArmRotation}
            withMovableArm={withMovableArm}
          />

          <Circle x={80} y={80} radius={120} dash={[6, 3]} fill={'transparent'} strokeWidth={2} stroke={'white'} visible={selected}/>
        </Group>
      </Group>
    )
  })

  _ref = (key) => this[`${key}Ref`] = React.createRef();


  _armPushes = () => {}

  _resetArmRotation = () => {}

  getArmPushes = (cb) => this._armPushes = cb;

  getResetArmRotation = (cb) => this._resetArmRotation = cb;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this._resetArmRotation();
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);


    if (data.repulsion) {
      this._armPushes();

      data.astronautLeftX -= data.leftAstronautSpeed * data.timedeltaSec;
      data.astronautLeftRotation -= .5 * data.timedeltaSec;

      data.astronautRightX += data.rightAstronautSpeed * data.timedeltaSec;
      data.astronautRightRotation += .5 * data.timedeltaSec;


      if ( (Math.abs(data.astronautLeftX) >= 250) || (Math.abs(data.astronautRightX) >= 250) ) {
        ['repulsion step 1', 'repulsion step 2', 'repulsion step 3', 'repulsion step 4'].forEach((k) => {
          this.scenarioManager.success(k);
        })
      }
    }
    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    n['astronautRight'].setAttrs({
      x: data.astronautRightX,
      rotation: data.astronautRightRotation
    });
    n['astronautLeft'].setAttrs({
      x: data.astronautLeftX,
      rotation: data.astronautLeftRotation
    });

    n['stage'].draw();
  }

  _getNode = (key) => this[`${key}Ref`]?.current;

  Background = () => {
    const [background] = useImage(backgroundImg);
    return (
      <Image image={background}/>
    )
  };

  onSelectAstronaut = (manSize) => {
    const data = this.data;
    const isFat = manSize === 'fat';
    const success = (data.fatAstronautCorrect && isFat) || (data.thinAstronautCorrect && !isFat);
    const prevAnswerSuccess = data.lastAnswerSuccess;

    // set new value for last answer
    data.lastAnswerSuccess = success;
    data.lastAnswerKey = this.scenarioManager.getStepData()?.key;

    showFailOrSuccessPopup(this, success);

    if (
      (
        (success && prevAnswerSuccess) ||
        (!success && !prevAnswerSuccess)
      ) &&
      prevAnswerSuccess !== undefined
    ) {
      this.data.theoryFailure = !success;
      return this.scenarioManager.selectStepByKey(success ? 'answerWindow success' : 'answerWindow failure');
    }
    data.selectNextStep(this);
  }

  Env = () => {
    const data = this.data;

    const [shadowAstronaut1] = useImage(shadowAstronaut1Img);
    const [shadowAstronaut2] = useImage(shadowAstronaut2Img);


    return (
      <React.Fragment>

        <Text text={data.title} x={80} y={30} fontSize={25} fill={'white'}/>

        <DarkAnswerWindow
          x={150} y={50}
          visible={data.answerWindowVisible}
          backBtnVisible={false}

          onCLickNext={() => this.scenarioManager.selectStepByKey('theory')}
          nextBtnVisible={true}
        >
          <Group x={80} y={80}>
            <Text x={40} width={480} align={'center'} text={data.answerWindowTitle} fontSize={30} fill={'white'} opacity={.5} lineHeight={1.4}/>
            <Text y={40} text={data.answerWindowText} fontSize={17} fill={'white'} opacity={.5} lineHeight={1.4}/>

            <Group x={230} y={120}>
              <Group visible={data.answerWindowStep2Visible}>
                <Image x={-100} y={10} image={shadowAstronaut1}/>
                <Image x={70} image={shadowAstronaut2}/>
              </Group>
            </Group>
          </Group>
        </DarkAnswerWindow>


        <CanvasButton
          text={'Оттолкнуться'}
          onClick={() => data.onClickRepulsionBtn(this)}
          fontSize={18}
          btnCornerRadius={0}
          textStroke={'transparent'}
          textFill={'#1E2531'}
          btnFill={'#EBFAFD'}
          btnStrokeWidth={1}
          width={160}
          height={35}
          x={420} y={450}
          visible={data.startBtnVisible}
        />

      </React.Fragment>
    )
  };

  SelectionBlock = (props) => {
    const [selected, selectAstronaut] = useState('');

    const [fatMan] = useImage(fatManImg);
    const [thinMan] = useImage(thinManImg);
    return (
      <Group {...props}>
        <Text x={0} y={-60} text={'Выбери'} fill={'white'} fontSize={18}/>

        <Group
          x={-50}
          onMouseEnter={() => selectAstronaut('left')}
          onMouseLeave={() => selectAstronaut('')}
          onClick={() => this.onSelectAstronaut('fat')}
        >
          <Arrow x={30} y={-70} points={[0,0,0,50]} stroke={'white'} fill={'white'} strokeWidth={5}/>
          <Image image={fatMan} scaleX={selected === 'left' ? 1.2 : 1} scaleY={selected === 'left' ? 1.2 : 1} />
        </Group>
        <Group
          x={60}
          onMouseEnter={() => selectAstronaut('right')}
          onMouseLeave={() => selectAstronaut('')}
          onClick={() => this.onSelectAstronaut('thin')}
        >
          <Arrow x={30} y={-70} points={[0,0,0,50]} stroke={'white'} fill={'white'} strokeWidth={5}/>
          <Image image={thinMan} scaleX={selected === 'right' ? 1.2 : 1} scaleY={selected === 'right' ? 1.2 : 1}/>
        </Group>
      </Group>
    )
  }

  render() {
    const data = this.data;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Background />


            <this.SelectionBlock x={450} y={400} visible={data.selectionBlockVisible}/>


            <Group x={350} y={170}>
              <this.AstronautComp
                x={-30}
                withMovableArm={true}
                ref={this._ref('astronautLeft')}
                getArmPushes={this.getArmPushes}
                getResetArmRotation={this.getResetArmRotation}
                selected={data.selectLeft}
              />
              <this.AstronautComp
                x={100} y={-25}
                ref={this._ref('astronautRight')}
                selected={data.selectRight}
              />
            </Group>


            <this.Env />

            <CanvasResetBtn onClick={() => this.onClickReset()}/>

            <VideoBlock
              src={data.videoSrc}
              onVideoEnd={() => { this.scenarioManager.success() }}
              scenarioManager={this.scenarioManager}
            />
          </Layer>
        </CanvasContainer>


        {
          this.data.theoryVisible && (
            <Theory
              data={this.props.lessonData?.theory}
              onClickSkipTheory={() => this.data.onClickSkipTheory?.(this)}
              lessonComplete={this.data.lessonComplete}
              onClickReset={this.onClickReset}
            />
          )
        }
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepulsionOfBodiesTwoAstronaut);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
