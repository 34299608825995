import React, {useEffect} from "react";
import '../styles/farm-map.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import MapContainer from "./MapContainer";
import {checkIsTeacherMap, maps} from "../utils";
import bg from "../images/farm_5_bg.png";
import farm5Video1Img from "../images/farm5map/farm_5_video1.png";
import cutSomeWood1Img from "../images/farm5map/сut_some_wood1.png";
import nailAndScrew1Img from "../images/farm5map/nail_and_screw1.png";
import farm5Video2Img from "../images/farm5map/farm_5_video2.png";
import logicWedge1Img from "../images/farm5map/logic_wedge1.png";
import logicWedge2Img from "../images/farm5map/logic_wedge2.png";
import logicWedge3Img from "../images/farm5map/logic_wedge3.png";
import logicWedge4Img from "../images/farm5map/logic_wedge4.png";
import logicWedge5Img from "../images/farm5map/logic_wedge5.png";
import logicWedge6Img from "../images/farm5map/logic_wedge6.png";
import mechanismsMakerImg from "../images/farm5map/mechanisms_maker1.png";
import MapElement from "../components/MapElement";



export const getConfigByTaskCode = (key, isTeacher) => {
  let res = {pos: {left: 0, top: 0}};

  const stage1 = 150;
  const stage2 = 480;
  const stage3 = 780;
  const stage4 = 1300;
  // const stage5 = 1570;

  const row1 = 230;
  const row2 = 460;
  const row3 = 680;

  switch (key) {
    case 'farm_5_video1':
      res = { pos: {left: stage1, top: row1}, itemImage: farm5Video1Img };
      break
    case 'farm_5_presentation1':
      res = { pos: {left: stage1-50, top: row2-50}};
      break
    case 'cut_some_wood1':
      res = { pos: {left: stage1, top: isTeacher ? row3 : row1}, itemImage: cutSomeWood1Img};
      break

    case 'nail_and_screw1':
      res = { pos: {left: stage2, top: row1}, itemImage: nailAndScrew1Img };
      break
    case 'farm_5_video2':
      res = { pos: {left: stage2, top: row2}, itemImage: farm5Video2Img};
      break
    case 'farm_5_presentation2':
      res = { pos: {left: stage2-50, top: row3-50}};
      break

    case 'logic_wedge1':
      res = { pos: {left: stage3, top: row1}, itemImage: logicWedge1Img};
      break
    case 'logic_wedge2':
      res = { pos: {left: stage3, top: row2}, itemImage: logicWedge2Img};
      break
    case 'logic_wedge3':
      res = { pos: {left: stage3, top: row3}, itemImage: logicWedge3Img};
      break
    case 'logic_wedge4':
      res = { pos: {left: stage3+230, top: row1}, itemImage: logicWedge4Img};
      break
    case 'logic_wedge5':
      res = { pos: {left: stage3+230, top: row2}, itemImage: logicWedge5Img};
      break
    case 'logic_wedge6':
      res = { pos: {left: stage3+230, top: row3}, itemImage: logicWedge6Img};
      break

    case 'mechanisms_maker1':
      res = { pos: {left: stage4, top: row1}, itemImage: mechanismsMakerImg};
      break
  }

  return res
}


const FarmMap4 = (props) => {
  const isTeacher = checkIsTeacherMap();

  useEffect(() => {
    if (isTeacher) {
      props.courseMapCreateStatistic();
    }
  }, [])

  const Content = ({tasksItems, isOpen, taskModalVisible, currentLessonCode, successScreenVisible, setTaskModalVisible}) => {

    useEffect(() => {
      // Initial redirect if already complete task
      const taskForRedirect = tasksItems.find(task => task.paymentRedirect);
      if (taskForRedirect?.complete && taskForRedirect?.paymentRedirect && !props.coursePaid && !isOpen) {
        window.location.href = taskForRedirect.paymentRedirect;
      }
    }, [tasksItems.length])

    useEffect(() => {
      if (!successScreenVisible) {
        const currentTask = tasksItems.find(task => task.lessonCode === currentLessonCode);
        if (currentTask?.complete && currentTask?.paymentRedirect && !props.coursePaid && !isOpen) {
          window.location.href = currentTask.paymentRedirect;
        }
      }
    }, [tasksItems, successScreenVisible]);

    const columns = [
      {id: 1, left: 175, background: '#37588D'},
      {id: 2, left: 510, background: '#8D4E04'},
      {id: 3, left: 920, background: '#8D3737'},
      {id: 4, left: 1325, background: '#4D378D'},
      // {id: 5, left: 1580, background: '#4D378D'}
    ];
    console.log(tasksItems);
    return (
      <div className={'farm-map-background'} style={{backgroundImage: `url(${bg})`}}>
        {
          columns.map((itm) => {
            return (
              <div
                key={itm.id}
                style={{
                position: 'absolute',
                top: '130px',
                padding: '10px 20px',
                borderRadius: '12px',
                color: 'white',
                fontFamily: 'Roboto-Bold',
                fontSize: '25px',
                ...itm
              }}>
                {itm.id} stage
              </div>
            )
          })
        }
        {
          tasksItems.map((item, i) => {
            const prevItem = tasksItems.find(el => el.lessonCode === item.prevKey);
            return (
              <MapElement
                getConfigByTaskCode={getConfigByTaskCode}
                key={item.lessonCode}
                lessonCode={item.lessonCode}
                isOpen={isOpen}
                isTeacher={isTeacher}
                prevItem={prevItem}
                index={i}
                setTaskModalVisible={setTaskModalVisible} coursePaid={props.coursePaid} {...item}
                preOpen={() => props.getTaskAvailabilityStatus(item.lessonCode)}
              />
            )
          })
        }
      </div>
    )
  }
  return (
    <MapContainer {...props} lang={'en'} withoutFog={true} withoutSuccessScreen={true} mapKey={maps.farm_5_map.key}>
      <Content />
    </MapContainer>
  )
}

const mapStateToProps = state => {
  return ({
    coursePaid: state.mapReducer.coursePaid,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({
  getOrCreateMapProgress: (initial) => dispatch(actions.getOrCreateMapProgress(initial, maps.farm_5_map.key)),
  courseMapAuth: () => dispatch(actions.courseMapAuth()),

  getTaskAvailabilityStatus: (lessonCode) => dispatch(actions.getTaskAvailabilityStatus(maps.farm_5_map.key, lessonCode)),
  courseMapCreateStatistic: () => dispatch(actions.courseMapCreateStatistic(maps.farm_5_map.key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FarmMap4);
