import React, {useState} from "react";
import cloneDeep from "lodash.clonedeep";
import useImage from "use-image";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {GIF} from "../../../canvas/components/GIF";
import {Layer, Group, Image, Rect, Text, Circle, Line} from "react-konva";
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";

import spaceBackgroundImg from '../../../../images/space/spaceBackground.png';

import stationBigImg from '../../../../images/space/spaceship_asteroid_2/stantionBig.png';
import stationSMallImg from '../../../../images/space/spaceship_asteroid_2/stantionSMall.png';
import dudeImg from '../../../../images/space/spaceship_asteroid_2/dude.png';
import dialogImg from '../../../../images/space/spaceship_asteroid_2/dialog.png';

import {getTimeDeltaSec, hasIntersection, showFailOrSuccessPopup} from "../../../../utils/common";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import ForceController from "../components/ForceController";
import pointItemImg from "../../../../images/space/spaceship_asteroid_2/spaceship.png";
import engineImg from "../../../../images/space/spaceship_asteroid_1/engine.png";
import Asteroid from "../components/Asteroid";
import AsteroidPointItem from "../components/AsteroidPointItem";
import {spaceshipAsteroid2ElementsUI, spaceshipAsteroid2Scenario} from "../utils/spaceshipAsteroid_2_Scenario";
import Victor from "victor";
import {layout2} from "../../../../utils/styles";
import CanvasButton from "../../../canvas/components/CanvasButton";

import explosionGif from '../../../../images/space/spaceship_asteroid_2/explosion.gif';
import ArrowHint from "../../../canvas/components/ArrowHint";
import VideoBlock from "../../../canvas/components/CanvasVideoBlock";
import * as actions from "../../../../store/actions";
import Theory from "../../../components/theory/Theory";






class SpaceshipAsteroid2 extends React.Component {
  constructor(props) {
    super(props);

    this.points = [
      {keyCode: 49, direct: 'left', x:1, y:32, rotation: 90, numberPos: {x: -23, y: 0}},
      {keyCode: 50, direct: 'bottomCenter', x:30, y:88, rotation: 0, numberPos: {x: 3, y: 10}},
      {keyCode: 51, direct: 'bottomRight', x:55, y:86, rotation: 0, numberPos: {x: 3, y: 10}},
      {keyCode: 52, direct: 'topCenter', x:50, y:1, rotation: 180, numberPos: {x: -13, y: -25}},
      {keyCode: 53, direct: 'topRight', x:75, y:10, rotation: 180, numberPos: {x: -13, y: -25}},
      {keyCode: 54, direct: 'right', x:80, y:50, rotation: -90, numberPos: {x: 13, y: -16}},
    ];

    this.managedComponents = [
      'stage',

      'forceText',

      'explosion',

      'asteroidBox',

      'stationBig',
      'stationSmall1',
      'stationSmall2',

      'spaceshipWithAsteroid',

      'arrowHintForce',
      'arrowHintAsteroidEngine',

      'endRect1',
      'endRect2',
    ];
    this.points.forEach((p) => {
      this.managedComponents.push(p.direct);
    });

    this.staticData = {
      maxForcePx: 300, // px
      maxForce: 5000, // H
      forceStep: 500, // H
    }
    this.elementsUI = spaceshipAsteroid2ElementsUI;
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timeDeltaSec: undefined,

      start: false,
      force: 1000,

      errorCount: 0,

      spaceshipWithAsteroidV: new Victor(40, 0),
      spaceshipWithAsteroidRotation: 0,
      spaceshipWithAsteroidRotationSpeed: 4,
      spaceshipWithAsteroidPos: new Victor(250, 270),


      ...this.elementsUI,
    }
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(spaceshipAsteroid2Scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  handleKey = (event) => {
    const staticData = this.staticData;
    const data = this.data;
    if (this.data.asteroidVisible) {
      let keyFound = false;
      this.points.forEach((point) => {
        if (point.keyCode === event.keyCode) {
          keyFound = true;
          data[point.direct + 'EngineActive'] = event.type === 'keydown';
        }
      })
      if (keyFound) {
        this.scenarioManager.success('step 1');
        data.start = true;
        data.force = data.force >= staticData.maxForce ? staticData.maxForce : data.force;
      }
    }
  };

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    document.addEventListener("keydown", this.handleKey);
    document.addEventListener("keyup", this.handleKey);
    this.scenarioManager.resetScenario();
  }

  get spaceshipWithAsteroidOffset() {
    const data = this.data;
    return {x: 0, y: 0};
  }
  get spaceshipWithAsteroidRotation() {
    const data = this.data;
    // return data.spaceshipWithAsteroidRotation+1;
    return 0;
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKey);
    document.removeEventListener("keyup", this.handleKey);
  }

  resetData = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.selectStepByKey('step 3');
  };

  onClickReset = () => {
    this.resetData();
    this.scenarioManager.resetScenario();
  };

  onClickController = (direction) => {
    const data = this.data;
    const staticData = this.staticData;
    const forceStep = staticData.forceStep;
    let newVal = data.force;

    this.scenarioManager.success('step 2');
    data.arrowHintForceControllerVisible = false;
    switch (direction) {
      case 'top':
        newVal += forceStep;
        break;
      case 'down':
        newVal -= forceStep;
        break;
    }

    if (newVal >= staticData.maxForce) {
      newVal = staticData.maxForce;
    }
    if (newVal <= 500) {
      newVal = 500;
    }

    this.data.force = newVal;
  }

  applyForce = () => {
    const data = this.data;
    const coeff = 0.001;
    const val = data.force * coeff * data.timeDeltaSec;

    let forceVictor = new Victor(0,0);
    let rotationAcceleration = 0;

    if (data.topCenterEngineActive) {
      forceVictor.add(new Victor(0,1));
    }
    if (data.topRightEngineActive) {
      forceVictor.add(new Victor(0,0.5));
      rotationAcceleration += 2;
    }
    if (data.leftEngineActive) {
      forceVictor.add(new Victor(1,0));
    }
    if (data.rightEngineActive) {
      forceVictor.add(new Victor(-1,0));
    }
    if (data.bottomCenterEngineActive) {
      forceVictor.add(new Victor(0,-1));
    }
    if (data.bottomRightEngineActive) {
      forceVictor.add(new Victor(0,-0.5));
      rotationAcceleration -= 2;
    }

    // линейное ускорение
    const direction = forceVictor.rotateDeg(data.spaceshipWithAsteroidRotation);
    data.spaceshipWithAsteroidV = data.spaceshipWithAsteroidV.clone().add(direction.multiply(new Victor(val, val)));

    // вращение
    data.spaceshipWithAsteroidRotationSpeed += rotationAcceleration * val;
  };

  updateCoords = () => {
    const data = this.data;
    data.spaceshipWithAsteroidRotation += data.spaceshipWithAsteroidRotationSpeed * data.timeDeltaSec;
    data.spaceshipWithAsteroidPos = data.spaceshipWithAsteroidPos.clone().add(data.spaceshipWithAsteroidV.clone().multiply(new Victor(data.timeDeltaSec, data.timeDeltaSec)));
  } ;

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;
    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timeDeltaSec = getTimeDeltaSec(timedelta);

    if (data.start) {
      this.applyForce();
      this.updateCoords();
    }
    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['spaceshipWithAsteroid'].setAttrs({
      rotation: data.spaceshipWithAsteroidRotation,
      position: data.spaceshipWithAsteroidPos,
    });

    n['forceText'].text(`F = ${data.force} H`);

    const stBig = n['stationBig'];
    const stSml1 = n['stationSmall1'];
    const stSml2 = n['stationSmall2'];
    const asteroid = n['asteroidBox'];
    const endRect1 = n['endRect1'];
    const endRect2 = n['endRect2'];

    const bigStationIntr = hasIntersection(asteroid,stBig,this);
    const smallStation1Intr = hasIntersection(asteroid,stSml1,this);
    const smallStation2Intr = hasIntersection(asteroid,stSml2,this);

    const endRect1Intr = hasIntersection(asteroid,endRect1,this);
    const endRect2Intr = hasIntersection(asteroid,endRect2,this);

    const crash = bigStationIntr || smallStation1Intr || smallStation2Intr;
    // go to theory
    if (crash && (this.data.errorCount >= 1)) {
      this.data.errorCount = 2;
      this.scenarioManager.selectStepByKey('theory')
    }
    const shipX = data.spaceshipWithAsteroidPos.x;
    const shipY = data.spaceshipWithAsteroidPos.y;
    const offScreenSuccess = (shipX > 1000 || shipX < 0) || (shipY > 560 || shipY < 0);

    if (endRect1Intr || endRect2Intr || offScreenSuccess) {
      showFailOrSuccessPopup(this, true);
      this.scenarioManager.selectStepByKey('theory');
    }

    if (this.data.errorCount === 0) {
      if (bigStationIntr) { this.scenarioManager.selectStepByKey('crash big station') }
      if (smallStation1Intr) { this.scenarioManager.selectStepByKey('crash small station 1') }
      if (smallStation2Intr) { this.scenarioManager.selectStepByKey('crash small station 2') }

      if (smallStation1Intr || smallStation2Intr || bigStationIntr) {
        showFailOrSuccessPopup(this, false);
      }
    }

    n['arrowHintAsteroidEngine'].visible(data.arrowHintAsteroidEngineVisible);
    n['arrowHintForce'].visible(data.arrowHintForceControllerVisible);


    this.points.forEach((p) => {
      n[p.direct].visible(data[p.direct+'EngineActive']);
    });

    n['stage'].draw();
  }

  AsteroidWithPoints = (props) => {
    const data = this.data;
    const [pointItem] = useImage(pointItemImg);
    const [engine] = useImage(engineImg);

    return (
      <Group {...props}>
        <Asteroid scale={.35} offset={{x: 50, y: 45}}>
          {
            this.points.map((item, i) => (
              <Group key={item.direct} x={item.x} y={item.y}>
                <AsteroidPointItem
                  onCLick={() => {}}
                  pointItemImg={pointItemImg}
                  rotation={item.rotation}
                >
                  <Image ref={this._ref(item.direct)} image={engine} x={2} y={60} visible={false} scale={{x: .2, y: .2}} rotation={-90}/>
                  <Image image={pointItem} scale={{x: .07, y: .07}}/>
                </AsteroidPointItem>
                <Text {...item.numberPos} text={i+1} fontSize={18} strokeWidth={.5} stroke={'black'} fill={'black'}/>
              </Group>
              )
            )
          }
        </Asteroid>
        <Rect ref={this._ref('asteroidBox')} x={-20} y={-20} width={30} height={30} fill={'green'} opacity={0}/>
      </Group>
    )
  }

  SpaceStations = (props) => {
    const [stationBig] = useImage(stationBigImg);
    const [stationSMall] = useImage(stationSMallImg);
    return (
      <Group {...props}>
        <Group x={750} y={170}>
          <Rect ref={this._ref('stationBig')} x={-20} y={40} width={200} height={150} fill={'green'} opacity={0}/>
          <Image rotation={20} image={stationBig}/>
          {
            this.data.explosionStationBigVisible && (
              <GIF
                reffer={this._ref('explosion')}
                src={explosionGif}
                x={-80}
                y={-90}
                scale={1}
              />
            )
          }
        </Group>

        <Group x={400} y={30}>
          <Rect ref={this._ref('stationSmall1')} x={0} y={0} width={100} height={100} fill={'green'} opacity={0}/>
          <Image image={stationSMall}/>
          {
            this.data.explosionStationSmall1Visible && (
              <GIF
                reffer={this._ref('explosion')}
                src={explosionGif}
                x={-120}
                y={-160}
                scale={1}
              />
            )
          }
        </Group>

        <Group x={520} y={480}>
          <Rect ref={this._ref('stationSmall2')} x={-45} y={-25} width={100} height={100} fill={'green'} opacity={0}/>
          <Image
            rotation={40}
            offset={{x: 40, y: 40}}
            image={stationSMall}
          />
          {
            this.data.explosionStationSmall2Visible && (
              <GIF
                reffer={this._ref('explosion')}
                src={explosionGif}
                x={-170}
                y={-170}
                scale={1}
              />
            )
          }
        </Group>

      </Group>
    )
  }

  Env = (props) => {
    const [dude] = useImage(dudeImg);
    const [dialog] = useImage(dialogImg);

    return (
      <Group {...props}>


        <ArrowHint
          ref={this._ref('arrowHintAsteroidEngine')}
          imgScaleX={1}
          rotation={180}
          color={'white'}
          x={0}
          y={200}
          fontSize={16}
          textX={-10} textY={-70}
          arrowX={150} arrowY={20}
          text={'Нажимая цифры на клавиатуре, \nуправляй астероидом'}
        />
        <ArrowHint
          ref={this._ref('arrowHintForce')}
          imgScaleX={1}
          rotation={180}
          color={'white'}
          x={30}
          y={380}
          fontSize={16}
          textX={0} textY={-70}
          arrowX={70} arrowY={10}
          text={'Нажми для изменения \nчислового значения силы'}
        />


        <Rect ref={this._ref('endRect1')} x={800} y={-130} width={100} height={300} fill={'red'} opacity={0}/>
        <Rect ref={this._ref('endRect2')} x={800} y={390} width={100} height={300} fill={'red'} opacity={0}/>

        <Text fontSize={27} lineHeight={1.2} text={this.data.title} fill={'white'}/>


        <Group y={295} visible={this.data.dudeVisible}>
          <Image image={dude} x={0} scale={{x: .7, y: .7}}/>
          <Group x={90} y={-83}>
            <Image image={dialog} scaleX={.8}/>
            <Text
              x={40} y={15}
              text={'Отличная работа! \nСтанции продолжают \nфункционировать \nв штатном режиме'}
              fontSize={20}
              lineHeight={1.2}
              fill={'#2a3e57'}
            />
          </Group>
        </Group>
      </Group>
    )
  }

  Background = () => {
    const [spaceBackground] = useImage(spaceBackgroundImg);
    return (
      <Image image={spaceBackground} opacity={.5}/>
    )
  }

  render() {
    const data = this.data;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer>
            <this.Background />

            <this.SpaceStations />

            <Group ref={this._ref('spaceshipWithAsteroid')}>
              <this.AsteroidWithPoints  visible={data.asteroidVisible}/>
            </Group>


            <ForceController
              visible={data.forceController}
              forceTextRef={this._ref('forceText')}
              x={150} y={450}
              onClickController={this.onClickController}
            />

            <this.Env x={30} y={30}/>


            <CanvasButton
              x={660} y={510}
              text={'Обнулить параметры'}
              height={30}
              fontSize={14}
              fontStyle={'bold'}
              btnFill={layout2.orange}
              strokeWidth={.2}
              width={180}
              onClick={() => this.resetData()}
            />
            <CanvasResetBtn
              y={510}
              onClick={() => this.onClickReset()}
            />

            <Group visible={this.data.replayVisible}>
              <Rect width={1000} height={562} fill={'#2A3E57'}/>
              <this.Background />
              <Text x={30} y={30} fontSize={27} lineHeight={1.2} text={this.data.title} fill={'white'}/>
              <Group scale={{x: 1.4, y: 1.4}} x={370} y={300}>
                <CanvasButton
                  x={0} y={0}
                  text={'Попробовать снова'}
                  height={30}
                  fontSize={14}
                  fontStyle={'bold'}
                  btnFill={layout2.orange}
                  strokeWidth={.2}
                  width={180}
                  onClick={() => {
                    this.resetData();
                    this.data.errorCount = 1;
                  }}
                />
              </Group>
            </Group>

            <VideoBlock
              src={data.videoSrc}
              visible={Boolean(data.videoSrc)}
              onVideoEnd={data.onVideoEnd ? () => data.onVideoEnd(this) : undefined}
              scenarioManager={this.scenarioManager}
            />
          </Layer>
        </CanvasContainer>

        {
          this.data.theoryVisible && (
            <Theory
              data={this.props.lessonData?.theory}
              onClickSkipTheory={() => this.data.onClickSkipTheory?.(this)}
              onClickReset={this.onClickReset}
            />
          )
        }
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpaceshipAsteroid2);

const styles = {
  mainContainer: {
    background: '#2A3E57'
  }
};
