import Victor from "victor";

const calcCollisionSpeeds = (shipSpeed, shipMass, stationMass, isSuccess, stationSpeed=Victor(0,0)) =>
{
  if (isSuccess) {
    // Perfectly inelastic collision
    const systemSpeedX = ((shipSpeed.x * shipMass) + (stationSpeed.x * stationMass)) / (shipMass + stationMass);
    const systemSpeedY = ((shipSpeed.y * shipMass) + (stationSpeed.y * stationMass)) / (shipMass + stationMass);

    return {
      shipSpeed: new Victor(systemSpeedX, systemSpeedY),
      stationSpeed: new Victor(systemSpeedX, systemSpeedY)
    }
  } else {
    // Perfectly elastic collision
    const shipSpeedX = (((shipMass - stationMass) * shipSpeed.x + 2 * stationSpeed.y * stationMass)
        / (shipMass + stationMass));
    const stationSpeedX = (((stationMass - shipMass) * stationSpeed.x + 2 * shipSpeed.x * shipMass)
        / (shipMass + stationMass));
    const shipSpeedY = (((shipMass - stationMass) * shipSpeed.y + 2 * stationSpeed.y *  stationMass)
        / (shipMass + stationMass));
    const stationSpeedY = (((stationMass - shipMass) * stationSpeed.y + 2 * shipSpeed.y * shipMass)
        / (shipMass + stationMass));

    return {
      shipSpeed: new Victor(shipSpeedX, shipSpeedY),
      stationSpeed: new Victor(stationSpeedX, stationSpeedY)
    }
  }
}

export default calcCollisionSpeeds;
