import React, {useEffect, useRef, useState} from "react";
import Menu from "./Menu";
import { CloseOutlined, CaretRightOutlined } from '@ant-design/icons';
import {Spin, Button } from 'antd';
import '../styles/sideMenuWrapper.css';


const getTopPositionWithBanner = () => {
  const header = document.getElementById('header');
  return header.offsetTop - window.scrollY;
}

export const MenuWrapper = (props) => {
  const [topPos, setTopPos] = useState(0);

  const updateTopPosition = () => {
    const topPx = getTopPositionWithBanner();
    setTopPos(topPx);
  }

  useEffect(() => {
    updateTopPosition();
  }, [props.headerBannerVisible, props.sidebarMenuVisible])

  useEffect(() => {
    if (props.mobileMode) {
      updateTopPosition();

      document.addEventListener("scroll", updateTopPosition);
      return () => {
        document.removeEventListener("scroll", updateTopPosition);
      }
    }
  }, [])

  return (
    <>
      <div
        className={`layout2__menuWrapper ${props.sidebarMenuVisible ? 'visible' : 'hidden'}`}
        style={props.mobileMode ? {top: `${topPos}px`} : {}}>
        <div className={'layout2__menu'}>
          {/*<div className={'sidebar-mobile-menu'}>*/}
          {/*  {*/}
          {/*    props.user?.id && (*/}
          {/*      <Menu*/}
          {/*        mobileMode={props.mobileMode}*/}
          {/*        user={props.user}*/}
          {/*        pageKey={props.pageKey}*/}
          {/*        mode={'vertical'}*/}
          {/*      />*/}
          {/*    )*/}
          {/*  }*/}
          {/*</div>*/}

          {props.children}
        </div>
      </div>
      {
        props.backdropVisible && (
          <div className={'layout2__menu-backdrop'} onClick={() => props.setSidebarMenuVisible(false)}/>
        )
      }
    </>
  );
}

export const DescriptionWrapper = (props) => {
  const [topPos, setTopPos] = useState(0);

  const updateTopPosition = () => {
    const topPx = getTopPositionWithBanner();
    setTopPos(topPx);
  }

  useEffect(() => {
    updateTopPosition();
  }, [props.headerBannerVisible])

  useEffect(() => {
    updateTopPosition();

    const container = document.getElementById('root');
    const lessons = document.getElementsByClassName('lesson-item-container');
    const handleClick = e => {
      const clickLsn = [...lessons]?.some((el) => el.contains(e.target));
      if (container && !clickLsn && container.contains(e.target)) {
        props.setSelectedLesson(null);
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("scroll", updateTopPosition);
    window.addEventListener("resize", updateTopPosition);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("scroll", updateTopPosition);
      window.removeEventListener("resize", updateTopPosition);
    };
  }, [])

  const code = props.code;
  let lessonLink = '';
  if (code) {
    const taskId = code.match(/[0-9]*$/)[0];
    const taskCodename = code.slice(0, code.length - taskId.length);
    const taskCode = `${taskCodename}/${taskId}`;
    const currentPathname = window.location.pathname;
    const corrPathname = currentPathname.replace(/\//g, '');
    lessonLink = `/task/ru/${taskCode}?withback=1${corrPathname ? `&back=${corrPathname}` : ''}`;
  }

  return (
    <div id={'descriptionWrapper'} className={`descriptionWrapper`} style={{right: props.visible ? '0px' : '-550px', top: `${topPos}px`}}>
      <div
           className={`descriptionShowHideContainer descriptionShowHideContainer--${props.visible ? 'visible' : 'hidden'}`}
      >
        <Button
          size={'large'}
          style={{position: 'absolute', right: 30, top: 15, zIndex: 3, border: 0}}
          icon={<CloseOutlined style={{fontSize: '20px'}} onClick={() => props.setSelectedLesson(null)} />}
        />
        {props.children}
        <div style={{height: '200px'}}/>
      </div>
      <div className={'enterToTaskBackground'}>
        <Button
          className={'enterToTaskBtn'}
          style={{right: props.visible ? '0' : '-550px'}}
          type={'primary'}
          onClick={() => {
            window.location.href = lessonLink
          }}
        >
          Перейти к задаче
          <CaretRightOutlined className={'enterToTaskBtnIcon'} style={{color: '#82C76A', fontSize: '25px', top: '4px', position: 'relative'}}/>
        </Button>
      </div>
    </div>
  );
}

export const TaskListWrapper = (props) => (
  <div id={'taskListWrapper'} className={'taskListWrapper'} style={props.style}>
    {
      props.title ?
        <h3 className={'taskListWrapper__title'}>{props.title}</h3> : null
    }
    <Spin spinning={!!props.loading} size={"large"} style={{width: '100%'}}>
      <div style={{minHeight: 300, maxWidth: 700}}>
        {props.children}
      </div>
    </Spin>
  </div>
);
