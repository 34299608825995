import React, {useState} from "react";
import '../styles/progressBar.css';
import {getProgressBarHeight} from "../utils";

const ProgressBar = (props) => {
  const [items, setItems] = useState(props.completeItemsCount ?? 1);

  const maxItemsCount = props.maxItemsCount || 5;
  const itemHeight = 50;
  const progressBarHeight = getProgressBarHeight(maxItemsCount, itemHeight);

  return (
    <div className={'map-progress-bar__wrapper'} style={{height: progressBarHeight/14 + 'em'}}>
      <div className={'map-progress-bar map-progress-bar--vertical'} style={{
        height: progressBarHeight/14 + 'em',
        justifyContent: props.maxItemsCount === items ? 'center' : 'flex-start'
      }}>
        {
          Array(items).fill(1).map((item, index) => {
            return (
              <div key={index} className={'map-progress-bar__item'} style={{height: itemHeight/14+'em'}}/>
            )
          })
        }
      </div>
      <div className={'map-progress-bar map-progress-bar--horizontal'} style={{
        width: progressBarHeight/14 + 'em', height: '6.5em',
        justifyContent: props.maxItemsCount === items ? 'center' : 'flex-start'
      }}>
        {
          Array(items).fill(1).map((item, index) => {
            return (
              <div key={index} className={'map-progress-bar__item'} style={{width: itemHeight/14+'em', height: '100%'}}/>
            )
          })
        }
      </div>
    </div>
  )
}

export default ProgressBar;
