import React, {useState} from "react";
import SectionWrapper from "../../layout2/components/SectionWrapper";
import {Popconfirm, Button, Divider, Space} from "antd";
import {CloseOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {layout2} from "../../../../utils/styles";
import {RadioGroup} from "../../layout2/components/StatisticTableFilter";


const DistributionByClassItem = ({grades, student, selectedGradeId, removeStudent, updateClassroom}) => {

  let config = {
    id: 'student-'+student.id,
    title: '',
    type: 'radio',
    defaultValue: selectedGradeId,
    items: grades.map((grade) => ({
      title: grade.name,
      value: grade.id,
    }))
  };

  const onChangeRadio = (val) => {
    updateClassroom({clsroomId: val, studentId: student.id})
  }

  const notDistribute = !Boolean(selectedGradeId);
  return (
    <Space style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div style={{display: 'flex'}}>
        <Popconfirm
          title={<p>Are you sure that you want <br/>to delete a student? "{student.title}"？</p>}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onConfirm={() => removeStudent(student.id)}
          cancelText={'Отмена'}
          placement={'topLeft'}
          okText={'Delete'}
          okType={'danger'}
          getPopupContainer={node => node.parentNode}
        >
          <Button danger type={'link'} icon={<CloseOutlined style={{fontSize: '15px'}} />}/>
        </Popconfirm>

        <p style={{color: layout2.blue2, fontSize: '16px', marginRight: '20px', lineHeight: '32px'}}>{student.title}</p>
      </div>

      <RadioGroup
        withoutInitialData={notDistribute}
        config={config}
        onChange={onChangeRadio}
      />
    </Space>
  )
}


const DistributionByClass = (props) => {

  const updateClassroom = (params) => {
    props.updateClassroom(params);
  }

  return (
    <SectionWrapper title={'Allocate students to groups'} loading={props.loading}>
      <div style={{position: 'relative', display: "inline-block"}}>
        {
          (props.notDistibutedStudents?.length || props.distributedStudents?.length) ? (
            <>
              <p style={{fontStyle: 'italic', color: "gray", fontSize: '20px'}}>Choose a group for each student:</p>
              <div style={{overflow: "auto", display: "inline-block", maxHeight: '800px'}}>
                <div style={{margin: '20px 0'}}>
                  {
                    props.notDistibutedStudents.map((student) => {
                      return (
                        <DistributionByClassItem
                          key={'student-'+student.id}
                          student={student}
                          grades={props.allClassroomsList}
                          removeStudent={props.removeStudent}
                          updateClassroom={updateClassroom}
                        />
                      )
                    })
                  }
                </div>

                <Divider style={{margin: '20px 0'}} />

                <div style={{margin: '20px 0'}}>
                  {
                    props.distributedStudents.map((student) => {
                      return (
                        <DistributionByClassItem
                          key={'student-'+student.id}
                          student={student}
                          grades={props.allClassroomsList}
                          selectedGradeId={student.classroomId}
                          removeStudent={props.removeStudent}
                          updateClassroom={updateClassroom}
                        />
                      )
                    })
                  }
                </div>
              </div>
              <div style={{
                position: 'absolute',
                bottom: '5px',
                width: '100%',
                height: '25px',
                background: 'linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0.8533788515406162) 60%, rgba(255,255,255,0) 100%)'
              }} />
            </>
          ) : (
            <h4 style={{color: layout2.gray, fontSize: '18px', fontFamily: 'Roboto-Bold'}}>
              Еще не присоединился ни один ученик
            </h4>
          )
        }
      </div>
    </SectionWrapper>
  )
}

export default DistributionByClass;