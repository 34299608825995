import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import reducer from "./index";
import logger from 'redux-logger';

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  || compose;

export default () => {
  return createStore(reducer, composeEnhances(applyMiddleware(thunk, logger)));
};
