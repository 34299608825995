import React, {useRef, useState, useEffect} from "react";
import Lottie from 'react-lottie'
import islandStart from '../images/islandWithPier.png';
import islandEnd from '../images/pier.png';
import startBtn from '../../../../images/startBtn.png';
import resetBtn from '../../../../images/endBtn.png';
import boat from '../images/Boat.png';
import waves from '../images/waves.png';
import water from '../../../../images/wave.png';
import anime from "animejs";
import useImage from 'use-image';
import Victor from 'victor';
import {
  radToDeg,
  degToRad,
  kmhToMs,
  msToKmh,
  lessonStatus,
} from '../../../../utils/common';
import {
  Stage,
  Layer,
  Text,
  Arrow,
  Image,
  Group,
} from 'react-konva';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {InputsBlock} from "../components/InputsBlock";
import { CanvasAxis } from "../components/CanvasAxis";
import { CanvasVelocity } from "../components/CanvasVelocity";
import { CanvasBoat } from "../components/CanvasBoat";
import { CanvasIslandStart } from "../components/CanvasIslandStart";
import { CanvasIslandEnd } from "../components/CanvasIslandEnd";
import {CanvasContainer} from "../../../canvas";
import CanvasGrid from "../components/CanvasGrid";
import { pxPerKm, hourPerSecond, scaleSpeedVisual } from "../settings";
import {_t} from "../../../../utils/lang/common";
import Water from "../../../canvas/components/Water";
import {Input} from 'antd';


const {sin, cos, asin, atan, fround} = Math;

const animation = anime.timeline({autoplay: false});

const scale = (Vkm) => Vkm.multiply(Victor(pxPerKm, pxPerKm)); // result is vector in pixels
const scaleSpeed = (Vkm) => Vkm.multiply(Victor(scaleSpeedVisual, scaleSpeedVisual)); // result is vector in pixels
const scaleTime = (Ts) => Ts * hourPerSecond;

const finishAnimationOptions = {
    targets: '.ship',
    easing: 'linear',
    translateX: 0,
    translateY: 0,
    duration: 1000,
    delay: 100,
    rotate: 90,
};

let initialData = {
  startTime: undefined,
  shipPos: Victor(0,0), // km
  wavesOffset: new Victor(0,0),
};
let data = {...initialData};


class Ship extends React.Component {

  defaultFlowRate = 5; // km/h
  defaultBoatSpeed = 10; // km/h
  defaultAngle = 0;
  defaultX = 0;
  defaultY = 0;
  distance = 0.350; // km
  failDistance = 0.75; // km

  constructor(props) {
    super(props);

    this.state = {
      running: false,
      flowRate:  this.defaultFlowRate,
      boatSpeed:  this.defaultBoatSpeed,
      angle: this.defaultAngle,
      isFinish: false
    }
  }


  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {running} = this.state;
    const {shipPos} = data;
    if (this.state && running !== prevState.running) {
      if ((shipPos.y > (this.defaultY - this.distance)) && running)
        window.requestAnimationFrame(this.move);
    }
  }


  getSpeedValues = () => {
    const angle = this.state && this.state.angle ? this.state.angle : 0;
    const correctBoatSpeed = this.state ? -this.state.boatSpeed : 0;
    const correctFlowRate = this.state ? -this.state.flowRate : 0;
    const boatSpeedV = Victor(0, correctBoatSpeed).rotateDeg(angle);
    const flowSpeedV = Victor(correctFlowRate, 0);
    const resultV = flowSpeedV.clone().add(boatSpeedV);
    return {
      correctBoatSpeed,
      correctFlowRate,
      boatSpeedV,
      flowSpeedV,
      resultV
    }
  };

  // -------- REFS ----------------
  boatRef = React.createRef();
  stageRef = React.createRef();

  setWaterRef = (ref) => (this.waterRef = ref);

  updateBoat = () => {
    const boatRef = this.boatRef;
    if (boatRef) {
      let boatNode = boatRef.current;
      const shipPositionScaled = scale(data.shipPos);

      boatNode.x(shipPositionScaled.x);
      boatNode.y(shipPositionScaled.y);

      boatNode.draw();
    }

    const waterRef = this.waterRef;
    if(waterRef) {
      const waterNode = waterRef.current;

      const waterPositionScaled = scale(data.wavesOffset);

      waterNode.to({
        duration: 0,
        fillPatternOffsetX: waterPositionScaled.x,
        fillPatternOffsetY: waterPositionScaled.y,
      });
    }
  };
  checkToSuccess = () => {
    const {flowRate, boatSpeed} = this.state;
    const calcAngle = radToDeg(asin(flowRate/boatSpeed));
    const correctCalcAngle = Number(calcAngle.toFixed(0));

    return correctCalcAngle === this.state.angle;
  };


  // main function to calc ship position
  RequestId = null;
  move = (time) => {
    const {resultV, flowSpeedV} = this.getSpeedValues();
    const {running} = this.state;
    const isSuccess = this.checkToSuccess();

    this.RequestId = window.requestAnimationFrame(this.move);

    data.startTime = data.startTime || time;
    const timedelta = time - data.startTime;
    const timedeltaSec = timedelta / 1000;
    const timedeltaHours = scaleTime(timedeltaSec);


    if (!running) {
      window.cancelAnimationFrame(this.RequestId);
    }

    data.shipPos = new Victor( + resultV.x * timedeltaHours,  resultV.y * timedeltaHours);
    data.wavesOffset = flowSpeedV.clone().multiply(new Victor(timedeltaHours, timedeltaHours));

    const isFinishOnPier = (data.shipPos.length() >= this.distance)  && isSuccess && running;
    const isMissed = (data.shipPos.length() >= this.failDistance) || (this.state.angle > 90 || this.state.angle < -90);

    if (isFinishOnPier)
      this.finishSuccess();

    if (isMissed)
      this.finishFail();

    this.updateBoat();
  };

  onChange = (key, value) => this.setState((oldState) => ({
    ...oldState, [key]: value
  }));

  onResetShip = async () => {
    animation.reset();
    this.setState((prevState) => ({
        ...prevState,
        running: false,
        isFinish: false,
      })
    );
    data = {...initialData};
    this.updateBoat();
  };

  onClickStart = async () => {
    this.setState((prevState) => ({
      ...prevState,
      running: true,
      isFinish: false,
    }));
    data = {...initialData};
    this.updateBoat();
  };

  onChangeInput = (key, val) => {
    const correctValue = this.validateValue(key, val);
    this.onChange(key, correctValue);
  };

  validateValue = (key, val) => {
    // add validation here
    return val ? Number(val) : undefined;
  };

  openModal = () => this.props.changeSuccessVisible(true);

  finishSuccess = () => {
    const activeLesson = this.props.activeLesson;
    this.setState((preState)=>({...preState, running: false, isFinish: true}));
    animation.add(finishAnimationOptions);
    animation.play();
    this.openModal();
    window.cancelAnimationFrame(this.RequestId);
    if (activeLesson)
      this.props.updateLessonStatus(lessonStatus.SUCCESS, activeLesson.id)
  };

  finishFail = () => {
    const activeLesson = this.props.activeLesson;
    window.cancelAnimationFrame(this.RequestId);
    this.setState((preState)=>({...preState, running: false, isFinish: true}));
    if (activeLesson)
      this.props.updateLessonStatus(lessonStatus.FAIL, activeLesson.id);
  };


  onChangeAngle = (val) => {
    this.setState((preState) => ({
      ...preState,
      angle: val
    }));
  };

  getAngleMovement = () => {
    const boatSpeed = this.state.boatSpeed;
    const flowRate = this.state.flowRate;
    const angle = this.state.angle ? degToRad(this.state.angle) : 0;
    const res = atan((boatSpeed*sin(angle)-flowRate)/(boatSpeed*cos(angle)));
    return radToDeg(res);
  };

  Water = () => {
    const {flowSpeedV} = this.getSpeedValues();

    const [waterImage] = useImage(water);

    return (
      <Group>
        <Water image={waterImage} vector={flowSpeedV} getRef={this.setWaterRef} targetHeight={700} />
      </Group>
    );
  };

  CanvasShip = () => {
      const {
        boatSpeedV,
        resultV
      } = this.getSpeedValues();

      const visualBoatSpeedV = scaleSpeed(boatSpeedV);
      const visualResultV = scaleSpeed(resultV);


      const {angle} = this.state;
      const center = Victor(500, 500);
      const [boatImage] = useImage(boat);
      const [wavesImage] = useImage(waves);
      const [islandStartImage] = useImage(islandStart);
      const [islandEndImage] = useImage(islandEnd);
      const isStarted = this.state && !this.state.running && !this.state.isFinish;

      return (
        <>
          {
            isStarted && (
              <Group x={center.x} y={center.y}>
                <CanvasAxis/>
              </Group>
            )
          }
          <Group x={500} y={30}>
            <CanvasIslandEnd islandImage={islandEndImage} angle={180}/>
          </Group>
          <Group x={550} y={630}>
            <CanvasIslandStart islandImage={islandStartImage}/>
          </Group>
          {/*managed by data.shipPos*/}
          <Group ref={this.boatRef}>
            <Group x={center.x} y={center.y}>
              {
                isStarted && (
                    <CanvasVelocity
                      boatSpeedV={visualBoatSpeedV}
                      resultV={visualResultV}
                    />
                )
              }
              <CanvasBoat boatImage={boatImage} wavesImage={wavesImage} angle={angle} isRunning={this.state && this.state.running}/>
            </Group>
          </Group>
        </>
      )
  };

  render() {
    const angleMovement = this.getAngleMovement();
    const angleDirection = this.state.angle;
    const isAnglesMatch = angleMovement === angleDirection;

    const state = this.state;
    const isFinish = this.state.isFinish;

    return (
      <div style={styles.shipContainer} id={'main-container'}>

        <div style={styles.islands}>
          <div style={styles.startContainer}>
            { !state.running && !isFinish && <InputsBlock {...state} onChangeInput={this.onChangeInput}/> }


            {/*---------- ANGLE RANGE ----------*/}
            <div style={{...styles.startPosition, ...styles.angleRangeContainer}}>
              <input
                id={'angleRange'}
                style={styles.angleRange}
                disabled={state.running}
                type="range"
                value={state.angle !== undefined ? state.angle + 90 : 90}
                min={0}
                max={180}
                onChange={(e) => this.onChangeAngle(e.target.value - 90)}
              />
            </div>


            {/*---------- BUTTONS ----------*/}
            <div style={{...styles.startPosition, ...styles.startResetButtonContainer}}>
              {
                (state.running || isFinish)
                  ?
                  <button
                    style={styles.startResetButton}
                    onClick={() => this.onResetShip()}
                  >
                    {_t("button_reset")}
                  </button>
                  :
                  <button
                    style={styles.startResetButton}
                    onClick={() => this.onClickStart()}
                  >
                    {_t("button_start")}
                  </button>
              }
            </div>


            {/*---------- SHIP ----------*/}
            <CanvasContainer stageRef={this.stageRef}>
              <Layer preventDefault={false}>
                <this.Water />
                <CanvasGrid
                  centerX={500}
                  centerY={500}
                  step={0.1 * pxPerKm} // grid ratio is 0.1 km
                  scaleLegend={`100м`}
                />
                <this.CanvasShip/>
              </Layer>
            </CanvasContainer>
          </div>
        </div>
      </div>
    )
  }
};

const mapStateToProps = (state) => ({
  activeLesson: state.lessonsReducer.activeLesson,
});

const mapDispatchToProps = (dispatch) => ({
  updateLessonStatus: (status, lessonId, method='status') => dispatch(actions.updateLessonStatus(status, lessonId, method)),
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Ship);


const styles = {
  shipContainer: {
    background: '#00bcd5',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  startResetButtonContainer: {
    // transform: 'translateX(220px)',
    position: 'absolute',
    width: '200px',
    zIndex: '10',
    height: '145px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    top: '-30px!important',
    bottom: '10%',
    left: '45%'
  },
  startResetButton: {
    cursor: 'pointer',
    margin: '0 30px',
    // width: '150px',
    padding: '1vmin 2vmin',
    border: '2px solid white',
    display: 'flex',
    borderRadius: '7px',
    overflow: 'hidden',
    outline: 'none',
    background: '#1CB8D0',
    color: 'white',
    fontSize: '2vmin',
    fontWeight: 'bolder',
  },
  startResetImg: {
    width: '100%'
  },
  islands: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    userSelect: 'none',
  },
  imgIslandEnd: {
    transform: 'rotate(180deg)',
    position: 'relative',
    bottom: '100px',
  },
  imgIslandStart: {
    width: '270px',
    margin: '0 -115px -40px 0px',
  },
  ship: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    zIndex: '1',
  },
  shipImg: {
    width: '30px',
    position: 'relative',
    zIndex: '2',
  },
  startContainer: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  startPosition: {
    position: 'absolute',
    margin: '0 auto',
    left: '0',
    right: '0',
    top: '0',
  },

  /* ---------- ANGEL CONTROL ---------- */
  angleRangeContainer: {
    width: '260px',
    top: 'auto',
    bottom: '28%',
  },
  angleRange: {
    width: '100%',
    position: 'absolute',
    zIndex: '100',
    bottom: 0,
    left: 0,
    opacity: 0,
    cursor: 'pointer'
  }
  /*---------------------------*/
};
