import ExampleScenarioLongLesson from "../views/lessons/examples/containers/ExampleScenarioLongLesson";
import cloneDeep from "lodash.clonedeep";

export default class ScenarioManager {

  // Инициализируется конфигом и ссылкой на this задачи
  // Описание конфига и пример инициализации можно почитать в ExampleScenarioLongLesson (импортирован выше)
  constructor(config, lessonComponent) {
    this.config = config;
    this.lessonComponent = lessonComponent;
    this.timeSec = 0;
    this.resetScenario();

    // отправить на сервер событие, что ученик начал решать задачу
    // для этого в mapDispatchToProps должен быть проброшен этот метод
    this._postNewLessonResult();

    this.transferCallback = () => {
      if (this.getStepData()?.patchData) {
        lessonComponent.data = {
          ...lessonComponent.data,
          ...cloneDeep(lessonComponent.elementsUI), // переименовать в resetStepDate
          ...cloneDeep(this.getStepData()?.patchData)
        };
      }
      // ВАЖНО! ЭТО СЭКОНОМИТ ТВОИ СИЛЫ.
      //
      // после каждого перехода между шагами вызыввается forceUpdate
      // это позволяет не прокидывать рефы в эелементы, которыми управляет сценарий для лаконичночти
      // то есть можно просто завести поле myComponentVisible в data, прокинуть его в render и не писать в updateStage()
      // оно будет работать. Главное - не забыть его прописать в patchData для каждого шага (смотри описание конфига)
      lessonComponent.forceUpdate();
    };
  }

  _postNewLessonResult = () => {
    const lc = this.lessonComponent;
    if (lc.props?.addLessonResult) {
      lc.props.addLessonResult(lc.props?.code, false, {config_log: this.config}, true);
    }
    window.postMessage('taskInit', '*');
  };

  _postLessonResult = (status=false) => {
    // console.log('debug on post success lesson result', status);
    const lc = this.lessonComponent;
    let detailed_data = {config_log: this.config, success_time: status ? this.timeSec : undefined};
    if (lc.props?.addLessonResult) {
      window.postMessage( status ? 'taskSuccess' : 'taskFailure', '*');
      lc.props.addLessonResult(lc.props?.code, status, detailed_data);
    }
  };

  // приватный метод, который корректно выставляет нужный шаг, предварительно выполняя необходимую логику
  _setStepNumber = (stepNumber) => {
    let nextStepData = this.config[stepNumber];
    if (nextStepData?.log !== undefined) {
      if (nextStepData.logArchive === undefined) {
        nextStepData.logArchive = [];
      }
      nextStepData.logArchive.push({...nextStepData.log});
      nextStepData.log = undefined;
    }
    this.currentStep = stepNumber;
  };

  // приватный метод, который обрабатывает переход между шагами
  _consumeStep = (key = undefined, result) => {
    // console.log('debug on consume step time', this.timeSec);
    let currentStep = this.currentStep;

    // Чтобы можно было поставить success на определенный степ и не проходить его, если он не текущий
    if (key !== undefined && key !== this.config[currentStep].key) {
      // console.error('Try to consume not current step', key);
      return;
    }

    if (this.config[currentStep] === undefined) {
      console.error("Can't find current step to consume");
      return;
    }
    this.config[currentStep].result = result;
    const nextKey = result === true ? this.config[this.currentStep]?.next_success : this.config[this.currentStep]?.next_failure;
    let newStep;
    if (! nextKey) {
      newStep = result === true ? currentStep + 1 : currentStep - 1 // при успехе по умолчанию вперед, при неудаче - назад
    } else {
      newStep = this.config.findIndex(item => item.key === nextKey);
      if (newStep < 0 || newStep >= this.config.length) {
        console.error("Can't find next step");
        return;
      }
    }

    // todo изменить эту логику, когда появится понимание, какой шаг считать успехом выполнения задачи
    // по умолчанию - переход на последний шаг сценария засчитывает задачу как успешно выполненную
    if (newStep === this.config.length-1) {
      this._postLessonResult(true);
    }

    this._setStepNumber(newStep);
    this.transferCallback && this.transferCallback();
  };

  // PUBLIC
  // Вернуть данные текущего шага
  getStepData = () => this.config[this.currentStep];

  // В случае успешного действия. Например, пользователь ввел верно инпут
  // В функцию можно передать key, тогда она будет проверять, совпадает ли key с текущим шагом. Если не совпадает, она ничего не сделает.
  // Это сделано для того, чтобы можно было вызывать success нескольких шагов на верный ввод. При этом сработает только текущий.
  // Пример в PathandspeedLongLesson.js метод checkSuccess
  // В нем на верный ввод инпута вызыввается success для всех шагов, которые на это завязаны. При этом срабатывает только один.
  // Можно это сделать if-ами, сравнивая текущий щаг с нужным. Но мне механизм из примера кажется лаконичнее.
  success = (key = undefined) => {
    this._consumeStep(key, true);
  };

  failure = (key = undefined) => {
    this._consumeStep(key, false);
  };

  selectStepByKey = (key) => {
    this.config[this.currentStep].result = undefined;
    const newStep = this.config.findIndex(item => item.key === key);
    this._setStepNumber(newStep);
    this.transferCallback && this.transferCallback();
  };

  next = () => {
    this.success()
  };

  back = () => {
    let currentStep = this.currentStep;
    if (currentStep === 0) return;

    let previousStep = currentStep - 1;

    const data = this.getStepData();
    if (data.previous && this.config.findIndex(item => item.key === data.previous) >= 0) {
      previousStep = this.config.findIndex(item => item.key === data.previous)
    }

    this._setStepNumber(previousStep);
    this.transferCallback && this.transferCallback();
  };

  // Для сброса сценария в изначальное состояние
  resetScenario = () => {
    this.config.map(item => ({ ...item, result: null }));
    this._setStepNumber(0);
    this.lessonComponent.data = {
      ...cloneDeep(this.lessonComponent.initialData ? this.lessonComponent.initialData : {}),
      ...cloneDeep(this.lessonComponent.elementsUI), // переименовать в resetStepDate
      ...cloneDeep(this.getStepData()?.patchData)
    };
    this.transferCallback && this.transferCallback();
  };

  passTimestampSec = (timestampSec) => {
    this.timeSec = timestampSec;
    let stepData = this.getStepData();
    if (stepData.log === undefined) {
      stepData.log = {};
    }

    // log data
    // if ((this.timeSec - Math.floor(this.timeSec)) < 0.1)
    if (!stepData.log.firstSeen) {
      stepData.log.firstSeen = timestampSec;
    }
    stepData.log.lastSeen = timestampSec;

    if (stepData.switch_at && ((timestampSec - stepData.log.firstSeen) > stepData.switch_at)) {
      this.success();
    }
  };
}
