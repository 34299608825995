import React from "react";
import {notification} from 'antd';
import {layout2} from "./styles";
import {descriptionUrl, serverMediaUrl} from "./api";
import axios from "axios";

export const TagsList = {
  'experiment': {color: 'white', background: layout2.orange},
  'computationaltask': {color: 'white', background: layout2.blue},
  'lesson': {color: 'white', background: '#82C76A'},
  'homework': {color: 'white', background: '#7a7a7a'},
}

export const replaceAll = (str,replaceWhat,replaceTo) => {
  const re = new RegExp(replaceWhat, 'g');
  return str.replace(re,replaceTo);
}

export const degToRad = (deg) => (deg * (Math.PI/180));

export const radToDeg = (rad) => (rad * (180/Math.PI));

export const msToKmh = (val) => {
  return Math.floor(val*3.6);
};

export const kmhToMs = (val) => {
  return val/3.6;
};

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const hasIntersection = (r1, r2, $this) => {
  const stageNode = $this.stageRef?.current;
  if (!r1 || !r2 || !stageNode) return undefined;
  const scale = stageNode.getAbsoluteScale();
  const r1abs = r1.getAbsolutePosition();
  const r2abs = r2.getAbsolutePosition();

  const r1X = r1abs.x*scale.x,
    r1Y = r1abs.y*scale.y,
    r2X = r2abs.x*scale.x,
    r2Y = r2abs.y*scale.y;

  return !(
    r2X > r1X + r1.width() ||
    r2X + r2.width() < r1X ||
    r2Y > r1Y + r1.height() ||
    r2Y + r2.height() < r1Y
  );
};


// ---------- STATUSES ----------------


export const lsnTypes = {
  teacherCard: {title: <span>Карточки с планом <br/>урока</span>, key: 'teacherCard'},
  interactive: {title: <span>Интерактивные <br/>материалы</span>, key: 'interactive'},
  textProblem: {title: <span>Текстовые задачи с <br/>вариантами</span>, key: 'textProblem'},
}

export const userRoles = {
  teacher: 'teacher',
  student: 'student'
};

export const lessonStatus = {
  NOT_COMPLETE: 0,
  FAIL: 10,
  SUCCESS: 20,
};

// (FULL_CONTENT, 'Весь контент'),
// (LIMITED_CONTENT, 'Ограниченный контент')
export const lessonAccess = {
  FULL: 0,
  LIMITED: 10,
};

export const checkLessonStatus = (lessonStatus) => ({
  success: Number(lessonStatus) === 20,
  fail: Number(lessonStatus) === 10,
  notComplete: Number(lessonStatus) === 0
});

export const checkLessonAccess = (lessonStatus) => ({
  full: Number(lessonStatus) === 0,
  limited: Number(lessonStatus) === 10,
});


// ----------------- NOTIFICATIONS --------------------------

/**
 *
 * types - 'success', 'info', 'warning', 'error'
 */
export const openNotificationWithIcon = (type, msg, description) => {
  notification[type]({
    message: msg || '',
    description: description || '',
    placement: 'bottomRight',
    duration: 3,
    style: {padding: '20px'}
  });
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};



export const toFixed = (value, toFixedNumber= 2) => {
  return Number(Number(value).toFixed(toFixedNumber));
}

export const generateArray = (itemsCount, startBy= 0) => {
  return Array(itemsCount).fill(1).map((el, i) => i+startBy);
}

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}


// ----------------- CANVAS --------------------------
export const getOpacityForNode = (node, actionHideEl) => {
  if (node) {
    const opacity = node.opacity();
    if (actionHideEl) {
      return opacity <= 0.1 ? 0 : opacity-.04;
    } else {
      return opacity >= 1 ? 1 : opacity+.02;
    }
  }
  return 0;
}

/*** Hide elem if actionHideEl is true otherwise show elem ***/
export const toggleVisibleEl = (node, actionHideEl) => {
  if (node) {
    const opacity = getOpacityForNode(node, actionHideEl);
    node.opacity(opacity);
    if (opacity) {
      return node.show();
    } else {
      return node.hide();
    }
  }
  return 0;
}

export const showFailOrSuccessPopup = ($this, success) => {
  const { changeSuccessVisible, changeFailureVisible } = $this.props;

  if (!changeSuccessVisible || !changeFailureVisible) {
    console.error('%cНе добавлена функция открытия всплывающего окна', 'color: white; font-size: 1.5em');
    return;
  }

  if (success) {
    changeSuccessVisible(true)
  } else {
    changeFailureVisible(true);
  }

  setTimeout(() => {
    changeSuccessVisible(false);
    changeFailureVisible(false);
  }, 1300)
}

export const inputVal = (val) => val;

export const getTimeDeltaSec = (timedelta) => {
  let timedeltaSec = timedelta / 1000;
  if (timedeltaSec > 0.5) {
    timedeltaSec = 0.1;
  }
  return timedeltaSec
}


export const checkPage = (pageKey) => ({
  newsFeed: pageKey === 'news-feed',
  tasks: pageKey === 'tasks',
  teacherCard: pageKey === 'teacher-card',
  studentHwPage: pageKey === 'student-hw-page',
  teacherHwPage: pageKey === 'teacher-hw-page',
  authHwPage: pageKey === 'auth-hw-page',
  statisticsPage: pageKey === 'statistics-page',
  gradesPage: pageKey === 'grades-page',
})

export const sendSuccessForScenario = ($this) => {
  ($this.scenarioManager.config.length - 1 > $this.scenarioManager.currentStep) && $this.scenarioManager.success();
}


export const checkDescriptionUrl = async (selectedLesson, setDescriptionVisible, descriptionHtml) => {
  if (selectedLesson?.code) {
    const url = descriptionUrl(selectedLesson?.code);
    let resp;
    try {
      resp = await axios.get(url);
    } catch (error) {
      setDescriptionVisible(descriptionHtml || false);
      return
    }
    setDescriptionVisible(descriptionHtml || Boolean(resp));
  }
}



export const sortListByField = (list, field) => {
  return list.sort((a, b) => {
    const aItem = a[field];
    const bItem = b[field];
    if (aItem > bItem) { return 1; }
    if (aItem < bItem) { return -1; }
    return 0;
  });
}
export const getSortedChapters = (chapters) => {
  return chapters.sort((a, b) => {
    const aGrade = a.grade;
    const bGrade = b.grade;
    if (aGrade > bGrade) { return 1; }
    if (aGrade < bGrade) { return -1; }
    return 0;
  });
}
export const getSortedGrades = (chapters) => {
  return chapters.reduce((accum, ch, i) => {
    let addedGradesKeys = Object.keys(accum);
    if (!addedGradesKeys.includes(`${ch.grade}`)) {
      accum[ch.grade] = [];
    }
    accum[ch.grade].push(ch);
    return accum
  }, {})
}
export const getCombinedLessons = (lessons, lessonsGroups) => {
  let combinedLessons = {};
  if (lessons.length && lessonsGroups.length) {
    const groupIdsObj = Object.fromEntries(lessonsGroups.map(g => [g.id, []]));
    combinedLessons = lessons.reduce((a, l) => {
      if (a[l.group]){
        a[l.group].push(l);
        return a
      } else {
        return a
      }}, groupIdsObj);
  }
  return combinedLessons;
}

export const filterLessonsByType = (lessons, selectedLsnType) => {
  // Фильтруем задачи по типу
  return lessons.filter((lsn) => {
    let res = true;
    switch (selectedLsnType){
      case 'teacherCard':
        res = !!lsn.teacherCard;
        break;
      case 'interactive':
        res = !lsn.teacherCard && !lsn.textProblem;
        break;
      case 'textProblem':
        res = !!lsn.textProblem;
        break;
    }
    return res;
  });
}

export const getLessonListData = (chapters, lessons, lessonsGroups) => {
  const sortedChapters = getSortedChapters(chapters);
  const grades = getSortedGrades(sortedChapters);
  const combinedLessons = getCombinedLessons(lessons, lessonsGroups);
  return { combinedLessons, sortedChapters, grades }
}

export const filterListData = (grades, lessonsParagraphs, sortedChapters, selectedParagraph, combinedLessons) => {
  /**
   * --- Фильтрация каталога по выбранному параграфу ---
   */
  const paragraphsIds = Object.keys(combinedLessons).filter(k => combinedLessons?.[k]?.length);
  /* Фильтруем группы по урокам */
  let filteredCatalogParagraphs = lessonsParagraphs?.filter((gr, i) => {
    /* Если есть выбранная группа, то оставляем только ее
       (чтобы не была пустая стр., если нет уроков в этом параграфе) */
    return selectedParagraph
      ? selectedParagraph.id === gr.id
      : paragraphsIds.includes(String(gr.id))
  });

  /* Фильтруем главы по параграфам */
  let filteredCatalogChapters = sortedChapters?.filter(ch => (
    filteredCatalogParagraphs.map(gr => gr.chapter).includes(ch.id)
  ));
  /* Фильтруем классы по главам */
  let filteredCatalogGrades = Object.keys(grades).reduce((accum, gradeNum, i) => {
    const correctGrades = filteredCatalogChapters.map(ch => ch.grade);
    if (correctGrades.includes(Number(gradeNum))) {
      accum[gradeNum] = grades[gradeNum];
    }
    return accum;
  }, {});
  return { filteredCatalogGrades, filteredCatalogParagraphs, filteredCatalogChapters }
}


export const getTaskCode = (code) => {
  const taskId = code.match(/[0-9]*$/)[0];
  const taskCodename = code.slice(0, code.length - taskId.length);
  return `${taskCodename}/${taskId}`;
}

export const howToUsePlatformForTeacher = () => {
  window.open('https://inventic.tech/onboarding', '_blank').focus();
}

export const getImageUrlByName = (imgName) => {
  return `${serverMediaUrl}${imgName}`
}
