import React, {useState} from "react";
import SchoolTaskContainer from "../components/SchoolTaskContainer";
import bgImg from '../images/bg.png';
import rabbitHouseImg from '../images/matchItems/rabbit_house.png';
import rabbitRampImg from '../images/matchItems/rabbit_ramp.png';
import rabbitHouseCompleteImg from '../images/matchItems/rabbit_house_complete.png';

import barnImg from '../images/matchItems/barn.png';
import barnRampImg from '../images/matchItems/barn_ramp.png';
import barnCompleteImg from '../images/matchItems/barn_complete.png';

import houseImg from '../images/matchItems/house.png';
import houseRampImg from '../images/matchItems/house_ramp.png';
import houseCompleteImg from '../images/matchItems/house_complete.png';

import '../styles/matchItems.css';
import SchoolTaskCard from "../components/SchoolTaskCard";
import {DropTarget} from "react-drag-drop-container";
import { _t, getCorrectLang } from "../../../../utils/lang/common";

const dropItemsDefault = [
  {id: 1, img: houseImg, completeImg: houseCompleteImg, successId: 3},
  {id: 2, img: rabbitHouseImg, completeImg: rabbitHouseCompleteImg, successId: 1},
  {id: 3, img: barnImg, completeImg: barnCompleteImg, successId: 2},
]
const dragItemsDefault = [
  {id: 1, img: rabbitRampImg},
  {id: 2, img: barnRampImg},
  {id: 3, img: houseRampImg},
]

const MatchItems = (props) => {
  const dropItems = props.dropItems || dropItemsDefault;
  const dragItems = props.dragItems || dragItemsDefault;
  const initialState = {};
  dropItems.forEach(item => {
    initialState[item.id] = {answerStatus: undefined, cardId: undefined}
  });

  const [helperState, setHelperState] = useState(0)
  const [draggingEl, setDraggingEl] = useState(undefined);
  const [dragStart, setDragStart] = useState(false);
  const [state, setState] = useState(initialState);


  const taskComplete = () => {
    props.taskComplete && props.taskComplete();
  }

  const onDragEnter = (e) => {}
  const onDragLeave = (e) => {}
  const onDrop = (data) => {
    const dropId = data.dropData.dropId;
    const dragId = data.dragData.id;
    const dropPlaceObj = dropItems.find(item => item.id === dropId)
    const success = dropPlaceObj.successId === dragId;

    setState(prevState => {
      prevState[dropId] = {answerStatus: success, cardId: dragId};
      return prevState
    });
    if (!success) {
      setTimeout(() => {
        setState(prevState => {
          prevState[dropId] = {answerStatus: undefined, cardId: undefined};
          return prevState
        });
        setHelperState(prevState => prevState+1);
      }, 600);
    }
  }

  const onDragStart = (e) => {
    const currEl = e.target;
    setDragStart(true);
    setDraggingEl(currEl);
  }
  const onDragEnd = (e) => {
    setDragStart(false);
    setDraggingEl(undefined);
  }
  const allAdded = Object.values(state).every(value => value.answerStatus);
  return (
    <SchoolTaskContainer
      lang={getCorrectLang()}
      bgImg={props.bgImg || bgImg}
      title={_t("logic_wheel.match_the_items")}
      success={allAdded}
      continueClassName={'match-items__btn-continue'}
      contentClassName={'match-items__content'}
      onClickContinue={taskComplete}
    >
      <div className={'match-items__drop-items'}>
        {
          dropItems.map((dropItem, i) => {
            const success = state[dropItem.id].answerStatus;
            const fail = (state[dropItem.id].answerStatus !== undefined) && !success;
            let mainText = dropItem.mainText;
            let img = dropItem.img;
            if (success && dropItem.completeImg) {
              img = dropItem.completeImg;
            }
            if (success && dropItem.completeText) {
              mainText = dropItem.completeText;
            }
            return (
              <React.Fragment key={dropItem.id}>
                <DropTarget
                  targetKey="targetKey"
                  dropData={{dropId: dropItem.id}}
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                >
                  <div className={'match-items__drop-item-container'} data-drop-id={dropItem.id}>
                    <SchoolTaskCard
                      index={i}
                      className={'match-items__drop-item'}
                      id={dropItem.id}
                      img={img}
                      mainText={mainText}
                      success={success}
                      fail={fail}
                    />
                  </div>
                </DropTarget>
              </React.Fragment>
            )
          })
        }
      </div>
      {
        !allAdded ? (
          <div className={'match-items__drag-items-wrapper'}>
            <div className={'match-items__drag-items'}>
              {
                dragItems.map((dropItem, i) => {
                  const alreadyAdded = Object.values(state).some(value => value.cardId === dropItem.id);
                  return (
                    <div key={dropItem.id} style={{visibility: alreadyAdded ? 'hidden' : 'visible'}}>
                      <SchoolTaskCard
                        index={i}
                        draggable={true}
                        smallSize={true}
                        className={'match-items__drag-item'}
                        id={dropItem.id}
                        img={dropItem.img}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        onDrop={onDrop}
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
        ): null
      }
    </SchoolTaskContainer>
  )
}
export default MatchItems;

