import Victor from 'victor';
import moment from 'moment';
import React from "react";
import {_t} from '../../../utils/lang/common';

export const getTitles = (code) => {
  const allTitles = {
    step1Title1: _t('dynamometer.Task_text1'),
    step1SubTitle1: _t('dynamometer.Task_text2'),
    step1Title2: _t('dynamometer.Task_text4'),
    step1Title3: _t('dynamometer.Tip_text5'),

    step2Title1: _t('dynamometer.Task_text3'),
    step2Title2: _t('dynamometer.Task_text4'),
  };

  let txt = '';


  return {
    currentTitle: txt,
    allTitles
  }
}

export const getAllNodes = ($this) => {
  let titles = {};
  Object.keys($this.titleItems).forEach((k) => {
    titles[`title${k}Node`] = $this[`title${k}Ref`]?.current;
  })
  return ({
    ...titles,
    boxLeftNode: $this.boxLeftRef?.current,
    boxRightNode: $this.boxRightRef?.current,
    boxLeftImgNode: $this.boxLeftImgRef?.current,
    boxRightImgNode: $this.boxRightImgRef?.current,

    hookNode: $this.hookRef?.current,
    hookBindBoxNode: $this.hookBindBoxRef?.current,

    btnsContainerNode: $this.btnsContainerRef?.current,

    fieldsBlockNode: $this.fieldsBlockRef?.current,

    dragDynamometerHintNode: $this.dragDynamometerHintRef?.current,
    dragBoxHintNode: $this.dragBoxHintRef?.current,
    bindBoxHintNode: $this.bindBoxHintRef?.current,

    dynamometerNode: $this.dynamometerRef?.current,
    groundNode: $this.groundRef?.current,
    pointerNode: $this.pointerRef?.current,

    boxLeftHorizontalBindBoxNode: $this.boxLeftHorizontalBindBoxRef?.current,
    boxLeftTopBindBoxNode: $this.boxLeftTopBindBoxRef?.current,
    boxLeftBottomBindBoxNode: $this.boxLeftBottomBindBoxRef?.current,

    boxRightHorizontalBindBoxNode: $this.boxRightHorizontalBindBoxRef?.current,
    boxRightTopBindBoxNode: $this.boxRightTopBindBoxRef?.current,
    boxRightBottomBindBoxNode: $this.boxRightBottomBindBoxRef?.current,

    resultDependBtnNode: $this.resultDependBtnRef?.current,
    resultDependBtnTxtNode: $this.resultDependBtnTxtRef?.current,
    resultNotDependBtnNode: $this.resultNotDependBtnRef?.current,
    resultNotDependBtnTxtNode: $this.resultNotDependBtnTxtRef?.current,
  });
}



export const onDragStart = ($this, e, elKey) => {
  const isLeftBox = elKey === 'boxLeft';
  const isRightBox =  elKey === 'boxRight';
  const isDynamometer =  elKey === 'dynamometer';

  const data = $this.data;
  if (isDynamometer) {
    data.dynamometerIsDragging = true;
    data.dynamometerIsDragged = true;
    // data.dynamometerExtension = 0;
    // data.connectedStartX = e.target.getAbsolutePosition().x;
  }

  if (isRightBox) {
    data.rightBoxIsDragging = true;
    data.rightBoxBindToDynamometer = false;
  }

  if (isLeftBox) {
    data.leftBoxIsDragging = true;
    data.leftBoxBindToRightBoxHorizontal = false;
    data.leftBoxBindToRightBoxVertical = false;
  }

};

export const onDragMove = ($this, e, elKey) => {
  const data = $this.data;
  const pos = e.target.getPosition();
  data[`${elKey}Pos`] = Victor(pos.x, pos.y);
};


export const onDragEnd = ($this, e, elKey) => {
  const staticData = $this.staticData;
  const data = $this.data;

  const isLeftBox = elKey === 'boxLeft';
  const isRightBox =  elKey === 'boxRight';
  const isDynamometer =  elKey === 'dynamometer';
  const boxSize = staticData.boxSize;

  if (isDynamometer) {
    data.dynamometerIsDragging = false;
  }


  const scale = e.target.getAbsoluteScale();
  const nodes = getAllNodes($this);

  const {
    leftBoxBindToRightBoxVertical,
    rightBoxBindToLeftBoxVertical,

    leftBoxBindToRightBoxHorizontal,
    rightBoxBindToLeftBoxHorizontal,

    rightBoxBindToDynamometer,
    leftBoxBindToDynamometer,
  } = updateIntersection($this);


  const hookBindBoxPos = nodes.hookBindBoxNode.getAbsolutePosition();
  const groundPos = nodes.groundNode.getAbsolutePosition();
  const groundPosY = (groundPos.y - boxSize) * scale.y;

  const boxRightPos = data.boxRightPos;
  const boxLeftPos = data.boxLeftPos;

  let newBoxLeftPos = nodes[`boxLeftNode`].getAbsolutePosition();
  let newBoxRightPos = nodes[`boxRightNode`].getAbsolutePosition();


  if (isLeftBox) {
    if (leftBoxBindToRightBoxVertical)
    {
      newBoxLeftPos = {x: boxRightPos.x * scale.x, y: (groundPos.y - boxSize*2) * scale.y};
      newBoxRightPos = {x: boxRightPos.x * scale.x, y: groundPosY};
    }

    if (leftBoxBindToRightBoxHorizontal)
    {
      newBoxLeftPos = {x: (boxRightPos.x - boxSize) * scale.x, y: groundPosY};
      newBoxRightPos = {x: boxRightPos.x * scale.x, y: groundPosY};
    }

    if (rightBoxBindToLeftBoxHorizontal)
    {
      newBoxLeftPos = {x: boxLeftPos.x * scale.x, y: groundPosY};
      newBoxRightPos = {x: (boxLeftPos.x - boxSize) * scale.x, y: groundPosY};
    }
  }


  if (isRightBox) {
    if (rightBoxBindToLeftBoxVertical)
    {
      newBoxLeftPos = {x: boxLeftPos.x * scale.x, y: groundPosY};
      newBoxRightPos = {x: boxLeftPos.x * scale.x, y: (groundPos.y - boxSize*2) * scale.y};
    }

    if (leftBoxBindToRightBoxHorizontal)
    {
      newBoxLeftPos = {x: (boxRightPos.x - boxSize) * scale.x, y: groundPosY};
      newBoxRightPos = {x: boxRightPos.x * scale.x, y: groundPosY};
    }

    if (rightBoxBindToLeftBoxHorizontal)
    {
      newBoxLeftPos = {x: boxLeftPos.x * scale.x, y: groundPosY};
      newBoxRightPos = {x: (boxLeftPos.x - boxSize) * scale.x, y: groundPosY};
    }
  }


  if (rightBoxBindToDynamometer)
  {

    data.boxIsDragged = true;

    newBoxRightPos = {x: (hookBindBoxPos.x - boxSize+10) * scale.x, y: groundPosY};

    if (leftBoxBindToRightBoxVertical)
    {
      newBoxLeftPos = {x: (hookBindBoxPos.x - boxSize+10) * scale.x, y: boxLeftPos.y*scale.y};
    }
  }

  if (leftBoxBindToDynamometer)
  {
    newBoxLeftPos = {x: (hookBindBoxPos.x - boxSize+10) * scale.x, y: groundPosY};

    if (rightBoxBindToLeftBoxVertical)
    {
      newBoxRightPos = {x: (hookBindBoxPos.x - boxSize+10) * scale.x, y: boxRightPos.y*scale.y};
    }
  }

  // Set bottom 0
  if (
    (isLeftBox || isRightBox) &&
    !leftBoxBindToRightBoxVertical &&
    !rightBoxBindToLeftBoxVertical &&
    !leftBoxBindToRightBoxHorizontal &&
    !rightBoxBindToLeftBoxHorizontal
  ) {
    newBoxLeftPos = {x: data[`boxLeftPos`].x, y: groundPosY};
    newBoxRightPos = {x: data[`boxRightPos`].x, y: groundPosY};
  }

  nodes[`boxLeftNode`].setAbsolutePosition(newBoxLeftPos);
  nodes[`boxRightNode`].setAbsolutePosition(newBoxRightPos);

  if (isDynamometer) {
    nodes.dynamometerNode.setAbsolutePosition({
      x: nodes.dynamometerNode.getAbsolutePosition().x - $this.data.dynamometerExtension,
      y: nodes.dynamometerNode.getAbsolutePosition().y
    });
    $this.data.dynamometerExtension = 0;
    $this.data.connectedStartX = 0;
  }
};

const updateIntersection = ($this) => {
  const staticData = $this.staticData;
  const data = $this.data;
  const nodes = getAllNodes($this);

  data.leftBoxBindToRightBoxVertical = (
    hasIntersection(nodes.boxRightTopBindBoxNode, nodes.boxLeftBottomBindBoxNode, $this) ||
    hasIntersection(nodes.boxRightImgNode, nodes.boxLeftBottomBindBoxNode, $this) ||
    hasIntersection(nodes.boxLeftBottomBindBoxNode, nodes.boxRightBottomBindBoxNode, $this) ||
    hasIntersection(nodes.boxRightHorizontalBindBoxNode, nodes.boxLeftHorizontalBindBoxNode, $this)
  );
  if (data.leftBoxBindToRightBoxVertical) {
    data.boxBindedToBox = true;
  }
  data.rightBoxBindToLeftBoxVertical = false;
  // (
  //   hasIntersection(nodes.boxRightBottomBindBoxNode, nodes.boxLeftTopBindBoxNode, $this) ||
  //   hasIntersection(nodes.boxLeftImgNode, nodes.boxRightBottomBindBoxNode, $this) ||
  //   hasIntersection(nodes.boxLeftBottomBindBoxNode, nodes.boxRightBottomBindBoxNode, $this) ||
  //   hasIntersection(nodes.boxRightHorizontalBindBoxNode, nodes.boxLeftHorizontalBindBoxNode, $this)
  // );


  data.leftBoxBindToRightBoxHorizontal = (
    hasIntersection(nodes.boxRightImgNode, nodes.boxLeftHorizontalBindBoxNode, $this) &&
    (!data.leftBoxBindToRightBoxVertical && !data.rightBoxBindToLeftBoxVertical)
  );
  if (data.leftBoxBindToRightBoxHorizontal) {
    data.boxBindedToBox = true;
  }
  data.rightBoxBindToLeftBoxHorizontal = false;
  // (
  //   hasIntersection(nodes.boxLeftImgNode, nodes.boxRightHorizontalBindBoxNode, $this) &&
  //   (!data.leftBoxBindToRightBoxVertical && !data.rightBoxBindToLeftBoxVertical)
  // );


  data.rightBoxBindToDynamometer = (
    hasIntersection(nodes.hookBindBoxNode, nodes.boxRightHorizontalBindBoxNode, $this) ||
    hasIntersection(nodes.hookBindBoxNode, nodes.boxRightImgNode, $this)
  );
  if (data.rightBoxBindToDynamometer) {
    data.dynamometerBindedToBox = true; // for visible element by data
  }

  data.leftBoxBindToDynamometer = false;
  // (
  //   hasIntersection(nodes.hookBindBoxNode, nodes.boxLeftHorizontalBindBoxNode, $this) ||
  //   hasIntersection(nodes.hookBindBoxNode, nodes.boxLeftImgNode, $this)
  // );

  return ({
    leftBoxBindToRightBoxVertical: data.leftBoxBindToRightBoxVertical,
    rightBoxBindToLeftBoxVertical: data.rightBoxBindToLeftBoxVertical,

    leftBoxBindToRightBoxHorizontal: data.leftBoxBindToRightBoxHorizontal,
    rightBoxBindToLeftBoxHorizontal: data.rightBoxBindToLeftBoxHorizontal,

    rightBoxBindToDynamometer: data.rightBoxBindToDynamometer,
    leftBoxBindToDynamometer: false,// data.leftBoxBindToDynamometer,
  })
};


const hasIntersection = (r1, r2, $this) => {
  const stageNode = $this.stageRef?.current;
  if (!r1 || !r2 || !stageNode) return undefined;
  const scale = stageNode.getAbsoluteScale();
  const r1abs = r1.getAbsolutePosition();
  const r2abs = r2.getAbsolutePosition();

  const r1X = r1abs.x*scale.x,
        r1Y = r1abs.y*scale.y,
        r2X = r2abs.x*scale.x,
        r2Y = r2abs.y*scale.y;

  return !(
    r2X > r1X + r1.width() ||
    r2X + r2.width() < r1X ||
    r2Y > r1Y + r1.height() ||
    r2Y + r2.height() < r1Y
  );
};


export const dynomomDragBoundFunc  = ($this, pos) => {
  /**
   *  Function to limit movement
   */
  const {boxSize} = $this.staticData;
  const {
    leftBoxBindToRightBoxVertical,
    rightBoxBindToLeftBoxVertical,
    rightBoxBindToDynamometer,
    leftBoxBindToDynamometer
  } = $this.data;
  const stageNode = $this.stageRef?.current;

  if (stageNode) {
    const stageWidth = stageNode.width();
    const stageHeight = stageNode.height();
    const scale = stageNode.getAbsoluteScale(); // scale {x, y}

    const rightBoxNode =  $this.boxRightRef?.current;
    const leftBoxNode =  $this.boxLeftRef?.current;

    const rightBoxPosAbs = rightBoxNode.getAbsolutePosition();
    const leftBoxPosAbs = leftBoxNode.getAbsolutePosition();



    let leftBorderX = (rightBoxPosAbs.x + boxSize - 10)* scale.x;

    if (
      (leftBoxBindToRightBoxVertical || rightBoxBindToLeftBoxVertical) &&
      (rightBoxBindToDynamometer || leftBoxBindToDynamometer)
    ) {
      leftBorderX = stageWidth * .1;
    }
    if (rightBoxBindToLeftBoxVertical && !(rightBoxBindToDynamometer || leftBoxBindToDynamometer)) {
      leftBorderX = (leftBoxPosAbs.x + boxSize - 10)* scale.x;
    }


    const rightBorderX = stageWidth * .78;
    const topBorderY = stageHeight * .620;
    const bottomBorderY = stageHeight * .620;

    const newX = pos.x >= rightBorderX ? rightBorderX : ( pos.x <= leftBorderX ? leftBorderX : pos.x );
    const newY = pos.y <= topBorderY ? topBorderY : ( pos.y >= bottomBorderY ? bottomBorderY : pos.y );
    return {y: newY, x: newX};
  }
};

export const boxDragBoundFunc  = ($this, pos, boxKey) => {
  /**
   *  Function to limit movement
   */
  const {boxSize} = $this.staticData;
  const stageNode = $this.stageRef?.current;

  if (stageNode) {
    const stageWidth = stageNode.width();
    const stageHeight = stageNode.height();
    const scale = stageNode.getAbsoluteScale(); // scale {x, y}

    const isLeftBox = boxKey === 'boxLeft';
    const isRightBox =  boxKey === 'boxRight';

    const leftBoxNode = $this.boxLeftRef?.current;
    const rightBoxNode = $this.boxRightRef?.current;
    const hookBoxNode = $this.hookBindBoxRef?.current;

    let leftBorderX = 0;
    let rightBorderX = stageWidth;
    let topBorderY = 0;
    let bottomBorderY = stageHeight;

    if (leftBoxNode && rightBoxNode) {
      const rightBoxPosAbs = rightBoxNode.getAbsolutePosition();
      const leftBoxPosAbs = leftBoxNode.getAbsolutePosition();
      const hookBoxPosAbs = hookBoxNode.getAbsolutePosition();

      if (isLeftBox) {
        leftBorderX = stageWidth * .01;
        // rightBorderX = rightBoxPosAbs.x * scale.x;
        rightBorderX = (hookBoxPosAbs.x - boxSize) * scale.x;
        topBorderY = stageHeight * .415;
        bottomBorderY = stageHeight * .573;
      }
      if (isRightBox) {
        // leftBorderX = leftBoxPosAbs.x * scale.x;
        leftBorderX = stageWidth * .01;
        rightBorderX = (hookBoxPosAbs.x - boxSize) * scale.x;
        topBorderY = stageHeight * .573;
        bottomBorderY = stageHeight * .573;
      }



      const newX = pos.x >= rightBorderX ? rightBorderX : ( pos.x <= leftBorderX ? leftBorderX : pos.x );
      const newY = pos.y <= topBorderY ? topBorderY : ( pos.y >= bottomBorderY ? bottomBorderY : pos.y );
      return {y: newY, x: newX};
    }
  }
};
