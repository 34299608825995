import React from "react";
import {Empty, Spin} from "antd";
import '../styles.css';
import TasksIndicator from "../components/TasksIndicator";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import TextProblemTask from "./TextProblemTask";
import TextProblemTasksList from "./TextProblemTasksList";
import GoBack from "../../whitelabel/components/GoBack";
import VariantHelper from "../../whitelabel/components/VariantHelper";
import AllComplete from "../../whitelabel/components/AllComplete";


class TextProblemTasksContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textProblems: [],
      loading: false,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.activeLessonVariant !== this.props.activeLessonVariant) {
      this.loadData(this.props.activeLessonVariant);
    }
  }

  componentDidMount = async () => {
    const variantId = this.props.activeLessonVariant;
    this.props.addLessonResult(this.props.lsnCode, false, {}, true, variantId);
    this.loadData(variantId);
  };

  loadData = async (variantId) => {
    this.setState({loading: true})
    const result = await this.props.fetchTextProblemsContainer(variantId);
    this.setState({textProblems: result?.data, loading: false});
    return result;
  };

  postAnswer = async (problem_id, value_str, option_id) => {
    const {postTextProblemAnswer, activeLessonVariant, addLessonResult, lsnCode} = this.props;
    await postTextProblemAnswer(problem_id, value_str, option_id, activeLessonVariant);
    await addLessonResult(lsnCode, false, {}, false, activeLessonVariant)
    return await this.loadData(activeLessonVariant);
  };

  // FOR CAT MAP
  successResult = async () => {
    if (this.props.setMapTaskSuccessVisible) {
      this.props.setMapTaskSuccessVisible(true);
    }
  };
  failResult = async () => {
    const {activeLessonVariant, addLessonResult, lsnCode} = this.props;
    await addLessonResult(lsnCode, false, {}, false, activeLessonVariant, true);
    if (this.props.setMapTaskFailureVisible) {
      this.props.setMapTaskFailureVisible(true);
    }
  };


  render () {
    const {lessonData, setLessonVariant, isTeacher, variantsCount, activeLessonVariant} = this.props;
    const {textProblems, loading} = this.state;
    const allComplete = textProblems?.every(textProblem => Boolean(textProblem.answers?.length));
    return (
      <div className={'text-problem-task__container'}>
        <GoBack
          top={'30px'} left={'3.3%'}
          lessonData={lessonData}
          setLessonVariant={setLessonVariant}
        />
        {
          (isTeacher && variantsCount) ? (
            <VariantHelper
              variantsCount={variantsCount}
              setLessonVariant={setLessonVariant}
              activeLessonVariant={activeLessonVariant}
            />
          ): null
        }

        {
          textProblems?.length ? (<TasksIndicator items={textProblems} />) : null
        }

        {
          loading ? (
            <div style={{position: 'absolute', margin: "8% auto", left: '48%'}}>
              <Spin size={'large'}/>
            </div>
          ) : (
            <>
              {
                textProblems?.length ? (
                    <>
                      {
                        !allComplete ? (
                          <>
                            {
                              textProblems.map((textProblem, i) => {
                                const findIndex = textProblems.findIndex((el) => !Boolean(el.answers?.length));
                                const visible = findIndex === i;
                                return (
                                  <TextProblemTask
                                    key={'text-problem-'+textProblem.id}
                                    visible={visible}
                                    variantId={this.props.activeLessonVariant}
                                    postAnswer={this.postAnswer}
                                    textProblem={textProblem} />
                                )
                              })
                            }
                          </>
                        ) : (
                          <AllComplete
                            successResult={this.successResult}
                            failResult={this.failResult}
                            variantId={this.props.activeLessonVariant}
                            textProblems={textProblems}
                          />
                        )
                      }
                    </>
                  ) :
                  <Empty description={'Урок не найден'} />
              }
            </>
          )
        }
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return ({
    selectedHomeworkId: state.lessonsReducer.selectedHomeworkId,
    textProblems: state.lessonsReducer.textProblems,
    activeLessonVariant: state.lessonsReducer.activeLessonVariant,
  });
}

const mapDispatchToProps = (dispatch, props) => {
  let lsnCode = props.lsnCode ? props.lsnCode : props.match.params.lsnCode;
  return ({
    fetchTextProblemsContainer: (variantId) => dispatch(actions.fetchTextProblemsContainer(lsnCode, variantId)),
    postTextProblemAnswer: (problem_id, value_str, option_id, variantId) => dispatch(actions.postTextProblemAnswer(problem_id, value_str, option_id, variantId)),
    setLessonVariant: (variant) => dispatch(actions.setLessonVariant(variant)),
    addLessonResult: (lesson_code, result, detailed, create_new, variant, failure) => (
      dispatch(actions.addLessonResult(lesson_code, result, detailed, create_new, variant, failure))
    ),
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TextProblemTasksContainer);
