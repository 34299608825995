import React from "react";
import CanvasContainer from "../../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import backgroundImg from '../../../../../images/work1J/background.png';
import arrowDownImg from '../../../../../images/work1J/arrowDown.png';
import arrowRightImg from '../../../../../images/work1J/arrowRight.png';
import arrowSuccessImg from '../../../../../images/work1J/arrowSuccess.png';
import arrowWidthImg from '../../../../../images/work1J/arrowWidth.png';
import booksImg from '../../../../../images/work1J/books.png';
import cupImg from '../../../../../images/work1J/cup.png';
import formulaImg from '../../../../../images/work1J/formula.png';
import trenieImg from '../../../../../images/work1J/trenie.png';
import tiazhImg from '../../../../../images/work1J/tiazh.png';
import ArrowHint from "../../../../canvas/components/ArrowHint";
import ScenarioManager from "../../../../../utils/ScenarioManager";
import {work1JScenario} from "../scenario";
import CanvasButton from "../../../../canvas/components/CanvasButton";
import {hasIntersection} from "../../../../../utils/common";
import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import * as actions from "../../../../../store/actions";


class Work1J extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',

      'cup',

      'horizontalHelperBox',
      'verticalHelperBox',
      'cupHelperBox',

      'forceHorizontalArrow',
      'forceVerticalArrow',
      'horizontalArrowHint',
      'verticalArrowHint',

      'forceFormulaTxt',
      'pathFormulaTxt',
      'workFormulaTxt',
    ]

    this.elementsUI = {
      horizontalStep: false,
      verticalStep: false,
      arrowSuccessVisible: false,

    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      forceTxt: undefined,
      pathTxt: undefined,
      workTxt: undefined,

      dragCupPath: 0,

      dragging: false,

      horizontalCupSuccess: false,
      verticalCupSuccess: false,

      horizontalDrag: false,
      verticalDrag: false,

      ...this.elementsUI
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(work1JScenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
  }

  nextStep = (e) => this.scenarioManager.next();

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  get force() {
    return this.data.horizontalStep ? 1 : 5;
  }
  get path() {
    const data = this.data;
    return Number((data.dragCupPath/data.limitCupPath).toFixed(1));
  }
  get work() {
    const data = this.data;

    return Number((data.pathTxt*data.forceTxt).toFixed(1));
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.forceTxt = this.force;
    data.pathTxt = this.path;
    data.workTxt = this.work;

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    let cupSuccessPos = {x: 0, y: 0};
    if (data.horizontalCupSuccess) {
      cupSuccessPos = {x: 510, y: 0};
    }
    if (data.verticalCupSuccess) {
      cupSuccessPos = {x: 180, y: -150};
    }
    if (!data.horizontalDrag && !data.verticalDrag) {
      n['cup'].position(cupSuccessPos);
      n['verticalArrowHint'].position({x: cupSuccessPos.x-150, y: cupSuccessPos.y-20});
    }


    n['forceFormulaTxt'].text((data.dragging ? data.forceTxt : undefined) ?? '');
    n['pathFormulaTxt'].text((data.dragging ? data.pathTxt : undefined) ?? '');
    n['workFormulaTxt'].text((data.dragging ? data.workTxt : undefined) ?? '');

    n['forceHorizontalArrow'].visible(data.horizontalDrag && data.horizontalStep);
    n['forceVerticalArrow'].visible(data.verticalDrag && data.verticalStep);

    n['horizontalArrowHint'].visible(!data.dragging && data.horizontalStep);
    n['verticalArrowHint'].visible(!data.dragging && data.verticalStep);

    n['stage'].draw();
  }

  cupDragBoundFunc = (pos) => {
    const data = this.data;
    let leftBorderX = data.verticalStep ? 570 : 35;
    let rightBorderX = data.verticalStep ? 750 : 550;
    let topBorderY = data.verticalStep ? 200 : 380;
    let bottomBorderY = data.verticalStep ? 390  : 380;

    const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
    const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
    return {y: newY, x: newX};
  }


  cupDragStart = (e) => {
    const data = this.data;
    data.dragging = true;
    data.horizontalDrag = true;
    data.verticalDrag = true;
  }
  cupDragMove = (e) => {
    const cupY = Math.abs(e.target.attrs.y);
    const cupX = Math.abs(e.target.attrs.x);

    this.data.dragCupPath = this.data.horizontalStep ? cupX : cupY;
  }
  cupDragEnd = (e) => {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    data.horizontalDrag = false;
    data.verticalDrag = false;

    const cupNode = n['cup'];
    const cupBoxNode = n['cupHelperBox'];
    const successPlaceNode = data.horizontalStep ? n['horizontalHelperBox'] : n['verticalHelperBox'];

    const success = hasIntersection(cupBoxNode, successPlaceNode, this);


    if (data.horizontalStep) {
      data.horizontalCupSuccess = success;
    } else {
      data.verticalCupSuccess = success;
    }

    if (success) {
      this.nextStep();
    }
  }

  Canvas = () => {
    const data = this.data;
    const stepData = this.scenarioManager.getStepData();

    const [background] = useImage(backgroundImg);
    const [arrowDownForce] = useImage(arrowDownImg);
    const [arrowRightForce] = useImage(arrowRightImg);
    const [arrowSuccess] = useImage(arrowSuccessImg);
    const [arrowWidth] = useImage(arrowWidthImg);
    const [books] = useImage(booksImg);
    const [cup] = useImage(cupImg);
    const [formula] = useImage(formulaImg);

    const [trenie] = useImage(trenieImg);
    const [tiazh] = useImage(tiazhImg);

    return (
      <React.Fragment>
        <Group>
          <Image image={background}/>

          <Image x={50} y={450} image={arrowWidth} visible={data.horizontalStep}/>
          <Group x={660} y={290} visible={data.verticalStep}>
            <Image image={books}/>
          </Group>


          <Group x={540} y={440}>
            <Rect
              ref={this._ref('horizontalHelperBox')}
              visible={data.horizontalStep}
              width={60} height={20} fill={'purple'} opacity={0}/>
            <Rect
              ref={this._ref('verticalHelperBox')}
              visible={data.verticalStep}
              x={160} y={-150}
              width={150} height={20} fill={'purple'} opacity={0}/>
          </Group>


          <Group x={30} y={30}>
            <Image image={formula} />
            <Group x={150} y={55}>
              <Text
                ref={this._ref('forceFormulaTxt')}
                fontSize={40}
                fill={'blue'}
                fontStyle={'bold'}
                width={85} height={50}
                text={'1'} align={'center'}
                verticalAlign={'middle'}
              />
              <Text
                ref={this._ref('pathFormulaTxt')}
                fontSize={40}
                fill={'dimgray'}
                fontStyle={'bold'}
                x={209} width={85}
                height={50} text={'1'} align={'center'}
                verticalAlign={'middle'}
              />
              <Text
                ref={this._ref('workFormulaTxt')}
                fontSize={40}
                fill={'dimgray'}
                fontStyle={'bold'}
                x={425} width={85}
                height={50} text={'1'} align={'center'}
                verticalAlign={'middle'}
              />
            </Group>
          </Group>

          <Group x={data.cupDefPosX || 35} y={data.cupDefPosY || 380}>
            <Group
              ref={this._ref('cup')}
              draggable
              onDragEnd={this.cupDragEnd}
              onDragMove={this.cupDragMove}
              onDragStart={this.cupDragStart}
              dragBoundFunc={this.cupDragBoundFunc}
            >
              <Rect x={-20} y={-20} width={100} height={100} fill={'black'} opacity={0}/>
              <Image image={cup}/>
              <Rect x={10} y={10} width={30} height={60} fill={'red'} opacity={0} ref={this._ref('cupHelperBox')}/>


              <Group ref={this._ref('forceHorizontalArrow')} visible={data.horizontalDrag && data.horizontalStep}>
                <Image
                  x={63} y={-15}
                  image={arrowRightForce}
                  scale={{x: .7, y: .7}}
                />
                <Image
                  x={-70} y={15}
                  image={trenie}
                  scale={{x: .7, y: .7}}
                />
              </Group>
              <Group ref={this._ref('forceVerticalArrow')} visible={data.verticalDrag && data.verticalStep}>
                <Image
                  x={-5} y={-100}
                  image={arrowDownForce}
                  scale={{x: .7, y: .7}}
                />
                <Image
                  x={-27} y={50}
                  image={tiazh}
                  scale={{x: .7, y: .7}}
                />
              </Group>
            </Group>

            <ArrowHint
              ref={this._ref('horizontalArrowHint')}
              imgScaleX={-1}
              rotation={210}
              arrowY={70}
              x={70}
              y={-20}
              fontSize={13}
              textX={30} textY={20}
              fontStyle={'bold'}
              visible={!data.dragging && data.horizontalStep}
              text={'Перемести кружку \nна один метр'}
            />

            <ArrowHint
              ref={this._ref('verticalArrowHint')}
              rotation={150}
              arrowX={140}
              arrowY={70}
              fontSize={13}
              textX={30} textY={20}
              fontStyle={'bold'}
              visible={!data.dragging && data.verticalStep}
              text={'Поставь кружку \nна книги'}
            />

          </Group>

          <CanvasButton
            text={'Далее'}
            onClick={() => {this.nextStep()}}
            fontSize={23}
            strokeWidth={.2}
            btnCornerRadius={0}
            width={150}
            height={45}
            x={700} y={450}
            visible={!stepData.next_btn_hidden}
          />

          <CanvasResetBtn
            onClick={() => this.onClickReset()}
          />
        </Group>

        <Image
          x={data.arrowSuccessX || 700} y={data.arrowSuccessY || 380}
          image={arrowSuccess} visible={data.arrowSuccessVisible}
        />
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Work1J);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
