import GoBack from "./GoBack";
import VariantHelper from "./VariantHelper";
import TextProblemTasksContainer from "../../testProblems/containers/TextProblemTasksContainer";
import React from "react";


const TextProblemTaskContent = (props) => {
  const {
    isTeacher,
    lessonData,
    variantsCount,
    setLessonVariant,
    activeLessonVariant,
  } = props;

  const setMapTaskFailureVisible = window.setMapTaskFailureVisible;
  const setMapTaskSuccessVisible = window.setMapTaskSuccessVisible;

  return (
    <TextProblemTasksContainer
      lsnId={lessonData.id}
      lsnCode={lessonData.code}
      repeatTextProblem={async () => document.location.reload()}

      isTeacher ={isTeacher}
      lessonData={lessonData}
      variantsCount={variantsCount}
      setLessonVariant={setLessonVariant}
      activeLessonVariant={activeLessonVariant}

      setMapTaskFailureVisible={setMapTaskFailureVisible}
      setMapTaskSuccessVisible={setMapTaskSuccessVisible}
    />
  )
}

export default TextProblemTaskContent;
