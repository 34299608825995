import React from "react";
import {Group, Layer, Rect} from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import cloneDeep from "lodash.clonedeep";
import CanvasButton from "../../../canvas/components/CanvasButton";

class Collision extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',
      'rect1',
      'rect2',
    ]

    for (let el of this.managedComponents) {
      this[`${el}Ref`] = React.createRef();
    }

    this.state = {};
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      offset1: 300,
      offset2: 420,
      speed1: 0,
      speed2: 0,
      fraction: 1,

      pushed1: false,
      pushed2: false,

      dragging: false,
    };
    this.data = cloneDeep(this.initialData);
  };

  _ref = (key) => this[`${key}Ref`];
  _getNode = (key) => this[`${key}Ref`]?.current;


  componentDidMount() {
    window.requestAnimationFrame(this.move);
  };

  componentWillUnmount() {
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  enslow (value, delta, timedeltaSec) {
    let absValue = Math.abs(value);
    let signValue = Math.sign(value);

    absValue -= delta * timedeltaSec;
    if (absValue < 0) absValue = 0;

    return signValue * absValue
  }

  move = (time) => {
    let data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    let stageNode = n['stage'];
    if (!stageNode) return;


    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    let timedeltaSec = timedelta / 1000;

    if (timedeltaSec > 0.5) {
      timedeltaSec = 0.1;
    }


    if (data.pushed1) {
      data.speed1 += 100 * timedeltaSec;
    }

    if (data.pushed2) {
      data.speed2 -= 100 * timedeltaSec;
    }

    if (this.hasIntersection(n["rect1"], n["rect2"])) {
      const tmpSpeed1 = data.speed1;
      data.speed1 = data.speed2;
      data.speed2 = tmpSpeed1;
    }

    data.speed1 = this.enslow(data.speed1, data.fraction, timedeltaSec);
    data.speed2 = this.enslow(data.speed2, data.fraction, timedeltaSec);

    if (data.dragging) {
      data.speed1 = 0
      data.speed2 = 0
    }

    data.offset1 += data.speed1 * timedeltaSec;
    data.offset2 += data.speed2 * timedeltaSec;


    this.updateStage()
  };

  updateStage() {
    const {offset1, offset2, dragging} = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    let stageNode = n['stage'];
    if (!stageNode) return;

    if (!dragging) {
      n['rect1'].x(offset1);
      n['rect2'].x(offset2);
    }

    stageNode.draw()
  };

  hasIntersection(r1, r2) {
    return !(
          r2.x() > r1.x() + r1.width() ||
          r2.x() + r2.width() < r1.x() ||
          r2.y() > r1.y() + r1.height() ||
          r2.y() + r2.height() < r1.y()
        );
  }


  clickResBtn = (status, itemNum) => {
    this.data[`pushed${itemNum}`] = status;
  }

  dragBoundFunc = (pos, itemNum) => {
    const stageNode = this._getNode('stage');
    const stageWidth = stageNode.width();
    const stageHeight = stageNode.height();


    const leftBorderX = stageWidth * .1;
    const rightBorderX = stageWidth * .9;
    const topBorderY = stageHeight * .535;
    const bottomBorderY = stageHeight * .535;

    const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
    const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
    return {y: newY, x: newX};
  }

  onDragEnd = (e, itemNum) => {
    const data = this.data;
    data.dragging = false;
    data[`offset${itemNum}`] = this._getNode(`rect${itemNum}`).getAbsolutePosition().x;
  }

  onDragStart = (e, itemNum) => {
    this.data.dragging = true;
  }

  onDragMove = (e, itemNum) => {}

  Scene = () => {
    const {offset1, offset2} = this.data;

    const btnSettings = {
      fontSize: 17,
      strokeWidth: .2,
      btnStrokeWidth: .7,
      width: 100,
      height: 30,
    }
    return (
      <React.Fragment>
        <Layer preventDefault={false}>
          <Rect
            draggable={true}
            dragBoundFunc={(pos) => this.dragBoundFunc(pos, 1)}
            onDragStart={(e) => this.onDragStart(e, 1)}
            onDragEnd={(e) => this.onDragEnd(e, 1)}
            onDragMove={(e) => this.onDragMove(e, 1)}
            ref={this._ref('rect1')}
            x={offset1} y={300}
            width={80} height={80}
            fill={'black'}
          />
          <Rect
            draggable={true}
            dragBoundFunc={(pos) => this.dragBoundFunc(pos, 2)}
            onDragStart={(e) => this.onDragStart(e, 2)}
            onDragEnd={(e) => this.onDragEnd(e, 2)}
            onDragMove={(e) => this.onDragMove(e, 2)}
            ref={this._ref('rect2')}
            x={offset2} y={300}
            width={80} height={80}
            fill={'white'}
          />


          <Group x={700} y={480}>
            <CanvasButton
              text={'Кнопка 1'}
              {...btnSettings}

              onTouchStart={() => this.clickResBtn(true, 1)}
              onTouchEnd={() => this.clickResBtn(false, 1)}
              onMouseDown={() => this.clickResBtn(true, 1)}
              onMouseUp={() => this.clickResBtn(false, 1)}
              onMouseLeave={() => this.clickResBtn(false, 1)}
            />
            <CanvasButton
              x={130}
              text={'Кнопка 2'}
              {...btnSettings}

              onTouchStart={() => this.clickResBtn(true, 2)}
              onTouchEnd={() => this.clickResBtn(false, 2)}
              onMouseDown={() => this.clickResBtn(true, 2)}
              onMouseUp={() => this.clickResBtn(false, 2)}
              onMouseLeave={() => this.clickResBtn(false, 2)}
            />
          </Group>

        </Layer>
      </React.Fragment>
    )
  };

  render() {
     return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <this.Scene/>
        </CanvasContainer>
        {/*<button*/}
        {/*  style={{position: 'absolute', right: 150, bottom: 50, zIndex: 10}}*/}
        {/*  onTouchStart={() => this.clickResBtn(true, 1)}*/}
        {/*  onTouchEnd={() => this.clickResBtn(false, 1)}*/}
        {/*  onMouseDown={() => this.clickResBtn(true, 1)}*/}
        {/*  onMouseUp={() => this.clickResBtn(false, 1)}*/}
        {/*>*/}
        {/*  Кнопка 1*/}
        {/*</button>*/}

        {/*<button*/}
        {/*  style={{position: 'absolute', right: 50, bottom: 50, zIndex: 10}}*/}
        {/*  onTouchStart={() => this.clickResBtn(true, 2)}*/}
        {/*  onTouchEnd={() => this.clickResBtn(false, 2)}*/}
        {/*  onMouseDown={() => this.clickResBtn(true, 2)}*/}
        {/*  onMouseUp={() => this.clickResBtn(false, 2)}*/}
        {/*>*/}
        {/*  Кнопка 2*/}
        {/*</button>*/}
      </div>
     )
  }
}

export default Collision;

const styles = {
  mainContainer: {
    background: '#457b9d',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }
}
