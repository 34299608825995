import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ArrangeByColumns from "./ArrangeByColumns";
import task1Planet4 from "../images/сhooseExtraItemTask/task1Planet1.png";
import task1Planet1 from "../images/сhooseExtraItemTask/task1Planet2.png";
import task1Planet3 from "../images/сhooseExtraItemTask/task1Planet3.png";
import task1Planet2 from "../images/сhooseExtraItemTask/task1Planet4.png";

import task2Planet1 from "../images/сhooseExtraItemTask/task2Planet1.png";
import task2Planet3 from "../images/сhooseExtraItemTask/task2Planet2.png";
import task2Planet2 from "../images/сhooseExtraItemTask/task2Planet3.png";

import task3Planet1 from "../images/сhooseExtraItemTask/task3Planet1.png";
import task3Planet2 from "../images/сhooseExtraItemTask/task3Planet2.png";
import task3Planet3 from "../images/сhooseExtraItemTask/task3Planet3.png";
import task3Planet4 from "../images/сhooseExtraItemTask/task3Planet4.png";

import task4Planet1 from "../images/сhooseExtraItemTask/task4Planet1.png";
import task4Planet2 from "../images/сhooseExtraItemTask/task4Planet2.png";
import task4Planet3 from "../images/сhooseExtraItemTask/task4Planet3.png";
import task4Planet4 from "../images/сhooseExtraItemTask/task4Planet4.png";

import task5Planet1 from "../images/сhooseExtraItemTask/task5Planet1.png";
import task5Planet2 from "../images/сhooseExtraItemTask/task5Planet2.png";
import task5Planet3 from "../images/сhooseExtraItemTask/task5Planet3.png";
import task5Planet4 from "../images/сhooseExtraItemTask/task5Planet4.png";

import task6Planet1 from "../images/сhooseExtraItemTask/task6Planet1.png";
import task6Planet2 from "../images/сhooseExtraItemTask/task6Planet2.png";
import task6Planet3 from "../images/сhooseExtraItemTask/task6Planet3.png";

import task7Planet1 from "../images/сhooseExtraItemTask/task7Planet1.png";
import task7Planet2 from "../images/сhooseExtraItemTask/task7Planet2.png";
import task7Planet3 from "../images/сhooseExtraItemTask/task7Planet3.png";

import task8Planet1 from "../images/сhooseExtraItemTask/task8Planet1.png";
import task8Planet2 from "../images/сhooseExtraItemTask/task8Planet2.png";
import task8Planet3 from "../images/сhooseExtraItemTask/task8Planet3.png";
import task8Planet4 from "../images/сhooseExtraItemTask/task8Planet4.png";

import task9Planet1 from "../images/сhooseExtraItemTask/task9Planet1.png";
import task9Planet2 from "../images/сhooseExtraItemTask/task9Planet2.png";
import task9Planet3 from "../images/сhooseExtraItemTask/task9Planet3.png";
import ChooseExtraItemTask from "./ChooseExtraItemTask";
import bg from "../images/space-bg-2.png";



const Example7Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: "Выбери лишнее по форме",
      items: [
        {id: 1, image: task1Planet1, text: "Луна"},
        {id: 2, image: task1Planet2, text: "Фобос", success: true},
        {id: 3, image: task1Planet3, text: "Ганимед"},
        {id: 4, image: task1Planet4, text: "Титан"},
      ]
    },
    {
      title: "Выбери лишнее в соответствии с количеством спутников",
      items: [
        {id: 1, image: task2Planet1, text: "Венера"},
        {id: 2, image: task2Planet2, text: "Марс", success: true},
        {id: 3, image: task2Planet3, text: "Меркурий"},
      ]
    },
    {
      title: "Выбери планету земной группы, но не самую быструю",
      items: [
        {id: 1, image: task3Planet1, text: "Меркурий"},
        {id: 2, image: task3Planet2, text: "Уран"},
        {id: 3, image: task3Planet3, text: "Ганимед"},
        {id: 4, image: task3Planet4, text: "Венера", success: true},
      ]
    },
    {
      title: "Укажи лишнюю планету",
      items: [
        {id: 1, image: task4Planet1},
        {id: 2, image: task4Planet2, success: true},
        {id: 3, image: task4Planet3},
        {id: 4, image: task4Planet4},
      ]
    },
    {
      title: "Выбери газовый гигант, но не самую далёкую",
      items: [
        {id: 1, image: task5Planet1, text: "Меркурий"},
        {id: 2, image: task5Planet2, text: "Юпитер", success: true},
        {id: 3, image: task5Planet3, text: "Венера"},
        {id: 4, image: task5Planet4, text: "Нептун"},
      ]
    },
    {
      title: "Выбери планету, у которой спутник больше по размеру, чем одна из планет солнечной системы",
      items: [
        {id: 1, image: task6Planet1, text: "Юпитер", success: true},
        {id: 2, image: task6Planet2, text: "Уран"},
        {id: 3, image: task6Planet3, text: "Нептун"},
      ]
    },
    {
      title: "Выбери планету земной группы, но не самую жаркую",
      items: [
        {id: 1, image: task7Planet1, text: "Меркурий", success: true},
        {id: 2, image: task7Planet2, text: "Уран"},
        {id: 3, image: task7Planet3, text: "Венера"},
      ]
    },
    {
      title: "Выбери газовый гигант, но не самую большую",
      items: [
        {id: 1, image: task8Planet1, text: 'Меркурий'},
        {id: 2, image: task8Planet2, text: 'Юпитер'},
        {id: 3, image: task8Planet3, text: 'Венера'},
        {id: 4, image: task8Planet4, text: 'Нептун', success: true},
      ]
    },
    {
      title: "Выбери планету, у которой спутник называется, как одно из земноводных семейства саламандр",
      items: [
        {id: 1, image: task9Planet1, text: "Юпитер"},
        {id: 2, image: task9Planet2, text: "Уран"},
        {id: 3, image: task9Planet3, text: "Нептун", success: true},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={9}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      {
        chooseTasks.map((item, i) => (
          <React.Fragment key={`step${i}`}>
            {
              ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                <ChooseExtraItemTask
                  lang={'ru'}
                  bgImg={bg}
                  title={item.title}
                  items={item.items}
                  taskComplete={() => {
                    setCompletedTaskCount(prevState => prevState + 1);
                    if ((i + 1) === chooseTasks.length) {
                      setAllComplete(true);
                    }
                  }}
                />
              ) : null
            }
          </React.Fragment>
        ))
      }
    </TaskMainContainer>
  )
}
export default Example7Task;
