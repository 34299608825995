import SectionWrapper from "./SectionWrapper";
import React, {useEffect, useState} from "react";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import '../styles/skills.css';
import {Row, Col, Button} from "antd";
import {getImageUrlByName} from "../../../../utils/common";


const UpgradeSkill = (props) => {
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      const resp = await props.fetchSkills();
      if (resp?.results) {
        setSkills(resp.results);
      }
      setLoading(false);
    })()
  }, [])

  return (
    <>
      {skills.length ? (
        <SectionWrapper
          loading={loading}
          title={'Прокачайся'}
          contentClassName={'main-page-sills'}
        >
          <Row gutter={[16, 8]} className={'skills-row'}>
            {
              skills.map(skill => {
                const img = getImageUrlByName(skill.image);
                return (
                  <Col span={24} key={'skill-'+skill.id} md={{span: skills.length > 1 ? 12 : 24}}>
                    <div className={'main-page-sills__item skill-item'}>
                      <img src={img} className={'skill-item__img'}/>
                      <Button className={'skill-item__link'} type={'default'} href={skill.link} target={'_blank'}>Открыть</Button>
                      <div className={'skill-item__text'}>
                        <h4>{skill.title}</h4>
                        <p>{skill.description}</p>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </SectionWrapper>
      ) : null}
    </>
  )
}



const mapStateToProps = state => ({});
const mapDispatchToProps = (dispatch, {match}) => ({
  fetchSkills: () => dispatch(actions.fetchSkills()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpgradeSkill);
