import React, {useState} from "react";
import ResultBar from "../components/ResultBar";
import TaskMainContainer from "./TaskMainContainer";
import ContinueRowTask from "./ContinueRowTask";
import ChooseExtraItemTask from "./ChooseExtraItemTask";
import img1 from "../images/сhooseExtraItemTask/1.png";
import img2 from "../images/сhooseExtraItemTask/2.png";
import img3 from "../images/сhooseExtraItemTask/3.png";
import img4 from "../images/сhooseExtraItemTask/4.png";

import imgTask21 from "../images/сhooseExtraItemTask/house1.png";
import imgTask22 from "../images/сhooseExtraItemTask/house2.png";
import imgTask23 from "../images/сhooseExtraItemTask/house3.png";
import imgTask24 from "../images/сhooseExtraItemTask/house4.png";

import imgTask31 from "../images/сhooseExtraItemTask/ramp.png";
import imgTask32 from "../images/сhooseExtraItemTask/excavator.png";
import imgTask33 from "../images/сhooseExtraItemTask/bicycle.png";
import imgTask34 from "../images/сhooseExtraItemTask/sewing_machine.png";

import imgTask41 from "../images/сhooseExtraItemTask/task41.png";
import imgTask42 from "../images/сhooseExtraItemTask/task42.png";
import imgTask43 from "../images/сhooseExtraItemTask/task43.png";
import imgTask44 from "../images/сhooseExtraItemTask/task44.png";


const Example1Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const steps = [
    {
      title: 'Choose an extra item',
      task: [
        { id: 1, image: img1 },
        { id: 2, image: img2 },
        { id: 3, image: img3, success: true },
        { id: 4, image: img4 },
      ]
    },
    {
      title: 'Choose an extra item',
      task: [
        { id: 1, image: imgTask21 },
        { id: 2, image: imgTask22 },
        { id: 3, image: imgTask23 },
        { id: 4, image: imgTask24, success: true },
      ]
    },
    {
      title: 'Choose a machine without moving parts',
      task: [
        { id: 1, image: imgTask31, success: true },
        { id: 2, image: imgTask32 },
        { id: 3, image: imgTask33 },
        { id: 4, image: imgTask34 },
      ]
    },
    {
      title: 'Choose a ramp which helps to lift a weight to the highest height, but it will be the hardest way',
      task: [
        { id: 1, image: imgTask41 },
        { id: 2, image: imgTask42 },
        { id: 3, image: imgTask43, success: true },
        { id: 4, image: imgTask44 },
      ]
    }
  ];


  // <ContinueRowTask
  //   taskComplete={() => setCompletedTaskCount(prevState => prevState+1)}
  // />
  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={4}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
       <ChooseExtraItemTask
            key={completedTaskCount}
            title={steps[completedTaskCount].title}
            items={steps[completedTaskCount].task}
            taskComplete={() => {
              setCompletedTaskCount(prevState => prevState + 1);
            }}
        />
    </TaskMainContainer>
  )
}
export default Example1Task;
