import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import {Spin} from "antd";
import {maps} from "../utils";


const CatMapPayment = (props) => {
  const [dots, setDots] = useState('...');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      await props.catMapPayment();
      window.location.pathname = maps.catMap.locationKey;
    })()
  }, []);

  return(
    <div style={{minHeight: '100vh', alignItems: 'center', justifyContent: 'center', height: '100%', display: 'flex'}}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
         <Spin size={'large'} spinning={loading} style={{margin: '0 10px'}} />
         <p style={{fontSize: '25px', fontFamily: 'Roboto'}}>Выполняется проверка оплаты<span>{dots}</span></p>
      </div>
    </div>
  )
}



const mapStateToProps = state => {
  return ({});
}
const mapDispatchToProps = (dispatch, {match}) => ({
  catMapPayment: () => dispatch(actions.catMapPayment())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatMapPayment);
