import React from "react";


export const MenuGradeTitle = ({gradeNumber, selectedChapter, selectedGradeNumber, setCatalogGradeNumber}) => {
  const selectedGraded = (gradeNumber === selectedChapter?.grade) || (gradeNumber === selectedGradeNumber);
  return (<>
    <div
      className={`side-bar__menu-item ${selectedGraded && !selectedChapter ? 'side-bar__grade--selected' : ''}`}
      onClick={() => {
        setCatalogGradeNumber(selectedGraded && !selectedChapter ? undefined : gradeNumber);
        const container = document.getElementById('app__main-container');
        if (container) {
          container.scrollTo({top:0, behavior: "smooth"});
        }
      }}
    >
      <div className={'side-bar__grade-title'}>{`${gradeNumber} класс`}</div>
    </div>
  </>)
};

export const MenuChapterTitle = ({chapter: ch, selectedChapter, setSelectedChapter}) => {
  const currSelectedChapter = ch.id === selectedChapter?.id;
  return (<>
    <div
      className={`side-bar__menu-item ${currSelectedChapter ? 'side-bar__chapter--selected' : ''}`}
      onClick={() => setSelectedChapter(currSelectedChapter ? undefined : ch)}
    >
      <div
        className={'side-bar__chapter-title'}
      >{`${ch.title}`}</div>
    </div>
  </>)
};
