import React, {useEffect, useState} from "react";
import LessonItem from "../../layout2/components/LessonItem";
import {TaskListWrapper} from "../../layout2/components/Wrappers";
import {TableOutlined} from "@ant-design/icons";
import {EmptyComp} from "./EmptyComp";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import StatisticTable from "../../layout2/components/StatisticTable";
import SectionWrapper from "../../layout2/components/SectionWrapper";
import {hwColumnsConverter, hwRowsConverter} from "../utils/converters";
import {Button, Form, Input} from "antd";
import HomeworkStudentsAchievements from "./HomeworkStudentsAchievements";
import useMedia from "../../../../utils/custom-hooks/useMedia";
import {_t} from "../../../../utils/lang/common";
import AuthFormWrapper from "../../../auth/components/AuthFormWrapper";
import FormCompleteUser from "./FormCompleteUser";


const StudentHwTasks = (props) => {
  const {
    user,
    classrooms,
    corrLessons,
    mainLoading,
    selectedHwId,
    selectedLesson,
    setSelectedLesson,
    fetchExecutionStatistics,
  } = props;

  const [loading, setLoading] = useState(false);
  // const [homeworkStats, setHomeworkStats] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [stateFilter, setStateFilter] = useState(
    {
      studentsClassroomFilter: ['all'],
      rowsFiller: 'by_students',
      measureFilter: 'by_percent_completion',
    }
  );

  let prevlessonId;
  const lessonHashId = window.location.hash;
  if (lessonHashId) {
    prevlessonId = lessonHashId.replace('#', '');
  }
  useEffect(() => {
    let mainContainer = document.getElementById(`app__main-container`);
    let lsn = document.getElementById(prevlessonId);
    if (lsn) {
      mainContainer.scrollTo({top: lsn.offsetTop-40});
    }
  }, [prevlessonId])

  useEffect(() => {
    reload();
  }, [stateFilter])

  const fetchStatistic = async (withoutLoading) => {
    setLoading(!withoutLoading);
    const resp = await fetchExecutionStatistics(selectedHwId, stateFilter);
    setTableData(resp?.results || [])
    setLoading(false);
  }

  const reload = async () => {
    setLoading(true);
    await props.refreshData();
    await fetchStatistic();
    setLoading(false);
  }

  const isMobileMode = useMedia(['(max-width: 600px)'],[true], false);
  let tableColumns = hwColumnsConverter(corrLessons, stateFilter, isMobileMode);
  let tableRows = hwRowsConverter(tableData);

  return (
    <div className={'teacher-content-wrapper lessons--new-grid'}>
      <TaskListWrapper
        title={'Complete tasks:'}
        loading={mainLoading && !corrLessons?.length}
        style={{marginRight: 0}}
      >
        <div className={'lessonsContainer'}>
          {
            user?.registration_not_completed ? (
              <div className={'lesson-item-container students-complete-registr'}>
                <div className="lsnMainContainer">
                  <h3>Complete registration</h3>
                  <FormCompleteUser />
                </div>
              </div>
            ) : null
          }

            {
              props.visible ? (
                <>
                  {
                    corrLessons.map((l) => (
                      <LessonItem
                        newCardStyle={true}
                        key={'lessonItems-'+l.id}
                        lessonItem={ l }
                        selectedLesson={ selectedLesson }
                        setSelectedLesson={ setSelectedLesson }
                        isTeacherHwPage={false}
                        user={ user }
                        teacherPage={false}
                        selectedHwId={selectedHwId}
                      />
                    ))
                  }
                </>
              ) : (
                <EmptyComp
                  icon={(props) => <TableOutlined {...props}/>}
                  height={500}
                  maxWidth={undefined}
                  top={40}
                  text={"Тут будут располагаться задания"}
                />
              )
            }
        </div>
      </TaskListWrapper>

      <HomeworkStudentsAchievements />

      {
        props.visible ? (
          <SectionWrapper
            wrapperClassname={'sectionWrapper'}
            loading={loading}
            titleContainerStyle={{marginTop: '0'}}
            title={'Статистика по выполнению'}
            btnAfterTitle={
              <Button
                type={'link'}
                className={'withUnderline'}
                onClick={reload}
              > Reload </Button>
            }
          >
            <StatisticTable
              rows={tableRows}
              columns={tableColumns}
              isMobileMode={isMobileMode}
            />
          </SectionWrapper>
        ) : null
      }
    </div>
  )
}


const mapStateToProps = (state, {match}) => {
  return {
    user: state.lessonsReducer.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHomeworkStats: (homeworkId) => dispatch(actions.getHomeworkStats(homeworkId)),
    fetchExecutionStatistics: (selectedHwId, filterState) => dispatch(actions.fetchExecutionStatistics(selectedHwId, filterState))
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentHwTasks);
