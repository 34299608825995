import React, {useEffect, useState} from "react";
import closeImg from '../images/closeIcon.png';
import {connect} from "react-redux";
import '../styles/statistic-modal.css';
import * as actions from "../../../../store/actions";
import {Popconfirm, Button, Table} from "antd";
import {ReloadOutlined, TeamOutlined,  QuestionCircleOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {mapStatColumnsConverter, mapStatRowsConverter} from "../converters";
import {_t} from "../../../../utils/lang/common";


const StatisticModal = (props) => {
  const {
    tasksItems,
    statisticModalVisible,
    setStatisticModalVisible,
  } = props;

  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [tableData, setTableData] = useState([]);

  const updateData = async () => {
    setLoading(true);
    const resp = await props.fetchMapExecutionStatistics(props.mapKey);
    await props.getTaskRanges(props.mapKey);
    setTableData(resp?.results || []);
    setLessons(resp?.lessons || []);
    setLoading(false);
  }

  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {
    let intervalId;
    if (statisticModalVisible) {
      intervalId = setInterval(() => {
        updateData();
      }, 5000);

      return () => {
        clearInterval(intervalId);
      }
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [statisticModalVisible]);


  const rangesNums = Object.keys(props.taskRanges);
  const tableColumns = mapStatColumnsConverter(tasksItems, lessons, props.taskRanges);
  const tableRows = mapStatRowsConverter(tableData, tasksItems);
  const currentRangeNumberStr = rangesNums.find((rangeNum, i) => {
    return props.taskRanges[rangeNum]?.available && !props.taskRanges[`${Number(rangeNum)+1}`]?.available
  })
  return (
    <div
      className={'course-map__modal statistic-modal'}
      style={{zIndex: statisticModalVisible ? 100 : -1, opacity: Number(Boolean(statisticModalVisible))}}
    >
      <div className={'statistic-modal__table'}>
        <div className={'statistic-modal__table-head'}>
          <Popconfirm
            overlayClassName={'statistic-modal__confirm'}
            placement="right"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title={<p>Очистить прогресс учеников?</p>}
            onConfirm={async () => {
              await props.courseMapClearStatistic(props.mapKey);
              updateData();
            }}
            okText="Да"
            cancelText="Нет"
          >
            <Button
              danger={true}
              type={'ghost'}
              loading={loading}
              size={'large'}
              className={'statistic-modal__update-table'}
            >
              {_t('map.Finish_the_lesson')}
              <ExclamationCircleOutlined style={{fontSize: '20px', position: 'relative', top: '2px',}} />
            </Button>
          </Popconfirm>
          <Button
            type={'primary'}
            loading={loading}
            size={'large'}
            className={'statistic-modal__update-table'}
            onClick={updateData}
          >
            {_t('map.Reload')}
            <ReloadOutlined style={{fontSize: '20px', position: 'relative', top: '2px',}} />
          </Button>
        </div>
        <div className={'statistic-modal__table-steps-btns'}>
          {
            rangesNums.map((rangeNumberStr) => {
              const isCurrent = currentRangeNumberStr === rangeNumberStr;
              const prevAvailable = props.taskRanges[`${rangeNumberStr-1}`]?.available;
              const available = props.taskRanges[rangeNumberStr]?.available;
              const disabled = (available || (!prevAvailable && prevAvailable !== undefined)) && !isCurrent;
              return (
                <Button
                  key={`btn-step-${rangeNumberStr}`}
                  className={`available-btn ${isCurrent ? 'available-btn--current' : ''} ${available ? 'available-btn--success' : ''}`}
                  style={{marginRight: '10px'}}
                  type={'primary'}
                  disabled={disabled}
                  onClick={async () => {
                    await props.patchTaskRangeAvailability(props.mapKey, rangeNumberStr, true);
                    updateData();
                  }}
                >
                  <b>{_t('map.Stage')} {rangeNumberStr}</b>
                </Button>
              )
            })
          }
        </div>
        <Table
          bordered
          dataSource={tableRows}
          columns={tableColumns}
          style={{padding: '20px'}}
          scroll={{ x: 1000, y: 500 }}
          pagination={false}
          locale={{
            emptyText: <>
              <TeamOutlined style={{marginTop: '20px', fontSize: '50px'}} />
              <p style={{margin: '10px 0 20px'}}>
                {_t("map.Not_start")}
              </p>
            </>
          }}
        />
      </div>
      <div
        className={'course-map__modal-close'}
        style={{opacity: Number(Boolean(statisticModalVisible))}}
        onClick={() => setStatisticModalVisible(false)}
      >
        <img src={closeImg} style={{width:50, height: 50}}/>
      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  taskRanges: state.mapReducer.taskRanges || {},
})
const mapDispatchToProps = (dispatch, {match}) => ({
  fetchMapExecutionStatistics: (mapKey) => dispatch(actions.fetchMapExecutionStatistics(mapKey)),
  courseMapClearStatistic: (mapKey) => dispatch(actions.courseMapClearStatistic(mapKey)),

  getTaskRanges: (mapKey) => dispatch(actions.getTaskRanges(mapKey)),
  patchTaskRangeAvailability: (mapKey, rangeName, status) => dispatch(actions.patchTaskRangeAvailability(mapKey, rangeName, status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatisticModal);
