import React, {useEffect, useState} from "react";
import '../styles/cat-final-test-map.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import MapContainer from "./MapContainer";
import CatFinalTestMapElement from "../components/CatFinalTestMapElement";
import {maps} from "../utils";
import {Button} from "antd";
import Clock from "../components/Clock";
import ProgressBar from "../components/ProgressBar";
import MapTimer from "../components/MapTimer";


const CatFinalTestMap = (props) => {
  const [workTimeIsOver, setWorkTimeIsOver] = useState(false);
  const [expireDataComplete, setExpireDataComplete] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);


  const mapFailureLongExpireStatus = props.mapStatus === 'failure_long_expire';
  const mapFailureShortExpireStatus = props.mapStatus === 'failure_short_expire';
  const mapStatusContinue = props.mapStatus === 'continue';
  const mapStatusSuccess = props.mapStatus === 'success';
  const mapStatusStart = props.mapStatus === 'start';
  const mapComplete = mapStatusSuccess || mapFailureLongExpireStatus || mapFailureShortExpireStatus;

  const shortExpireData = props.timer['short_expire_date'];
  const shortExpireDataStart = shortExpireData?.start;
  const longExpireData = props.timer['long_expire_date'];
  const longExpireDataStart = longExpireData?.start;

  const expireTime = (
    (shortExpireDataStart && shortExpireData?.['hours']) ||
    (longExpireDataStart && longExpireData?.['hours'])
  );

  const onClickBtnStart = async () => {
    setStartLoading(true);
    await props.updateStatusForMap('start');
    setStartLoading(false);
  };
  const resetMap = async () => {
    await props.updateStatusForMap('reset');
  };

  const onClickResetBtn = async (tasksItems) => {
    if (expireDataComplete) {
      setWorkTimeIsOver(false);
      await resetMap();
      props.textProblemAnswerCleaner(tasksItems.map(task => task.lessonCode));
      await onClickBtnStart();
    }
  };

  const completeMap = async () => {
    await props.updateStatusForMap('complete');
  };
  const continueExecution = async (tasksItems) => {
    props.textProblemAnswerCleaner(tasksItems.filter(task => task.failure).map(task => task.lessonCode));
    await props.updateStatusForMap('continue');
  };


  const Content = ({tasksItems, isOpen, taskModalVisible, setTaskModalVisible}) => {
    const completeItems = tasksItems.filter(item => item.complete);
    // задачи выполнены (вне зависимости верно или нет)
    const allTaskContinueItems = tasksItems.every(item => item.complete || item.failure);

    useEffect(() => {
      if (mapStatusStart && allTaskContinueItems && !mapComplete) {
        completeMap();
      }
    }, [allTaskContinueItems])

    useEffect(() => {
      if (tasksItems.length && (tasksItems.length === completeItems.length) && !mapComplete) {
        completeMap();
      }
    }, [tasksItems]);

    useEffect(() => {
      if (mapStatusStart && !taskModalVisible && workTimeIsOver) {
        completeMap();
      }
    }, [workTimeIsOver]);

    useEffect(() => {
      if (workTimeIsOver && props.mapStatus && !mapStatusStart && !mapComplete) {
        completeMap();
      }
    }, [taskModalVisible])

    useEffect(() => {
      if (mapStatusSuccess) {
        props.catFinalTestSuccess();
      }
    }, [mapStatusSuccess])

    const startExpireDate = shortExpireDataStart || longExpireDataStart;

    const workTime = props.timer?.["work_time"];

    let title = 'Выполни задания контрольной работы';
    if (mapFailureShortExpireStatus) {
      title = 'Через один час у тебя будет возможность завершить задачи';
    }
    if (mapFailureLongExpireStatus) {
      title = 'Подготовься ещё раз и выполни контрольную через 24 часа';
    }
    if (mapStatusSuccess) {
      title = 'Отличный результат!';
    }
    return (
      <div className={'cat-final-test cat-final-test__map-bg'}>
        <div className={'cat-final-test__header'}>
          <h1 className={'cat-final-test__title'}>{title}</h1>
        </div>
        {/* ============== BUTTONS ============== */}
        <div className={'cat-final-test__buttons cat-final-test__content-wrapper'}>

          {
            !workTime?.start ? (

              <Button loading={startLoading} className={'custom-button custom-button--orange'} onClick={onClickBtnStart}>
                Приступить к работе
              </Button>

            ) : (
              <>

                {/*<Button className={`custom-button custom-button--orange`} onClick={() => completeMap()}>*/}
                {/*  Завершить*/}
                {/*</Button>*/}

                {
                  (mapFailureShortExpireStatus && shortExpireDataStart) ? (
                    <Button
                      className={`custom-button custom-button--orange ${shortExpireDataStart && !expireDataComplete ? 'custom-button--disabled' : ''}`}
                      onClick={() => {
                        if (expireDataComplete) {
                          setWorkTimeIsOver(false);
                          continueExecution(tasksItems)
                        }
                      }}
                    >
                      Завершить задачи
                    </Button>
                  ) : null
                }
                {
                  mapFailureLongExpireStatus ? (
                    <Button
                      className={`custom-button custom-button--orange ${longExpireDataStart ? 'custom-button--disabled' : ''}`}
                      onClick={() => onClickResetBtn(tasksItems)}
                    >
                      Сделать заново
                    </Button>
                  ) : null
                }
              </>
            )
          }

          {
            (mapFailureLongExpireStatus || mapFailureShortExpireStatus) ? (
              <>
                <div className={'custom-button timer'}>
                  <span>
                    <MapTimer
                      // complete={startExpireDate}
                      format={'HH:mm:ss'}
                      initialTimeStr={'00:00'}
                      startTimeStr={startExpireDate}
                      defaultHours={expireTime}
                      defaultMinutes={60}
                      completeCallback={() => {
                        setExpireDataComplete(true);
                      }}
                    />
                  </span>
                </div>
              </>
            ) : null
          }
          {
            (mapFailureLongExpireStatus || mapFailureShortExpireStatus || !props.mapStatus) ? (
              <Button
                className={'custom-button custom-button--purple cat-final-test__move-to-cat-map'}
                onClick={() => {window.location.pathname = maps.catMap.locationKey}}
              >
                Вернуться на карту
              </Button>
            ) : null
          }
        </div>

        <div style={{display: "flex", justifyContent: 'center', marginTop: '2em'}}>
          {
            mapStatusSuccess ? (
              <Button
                className={'custom-button custom-button--orange'}
                style={{width: '200px', margin: '0 20px'}}
                onClick={() => {
                  window.location.pathname = maps.catMap.locationKey;
                }}
              >
                Далее
              </Button>
            ) : null
          }
        </div>

        <div className={'cat-final-test__items-wrapper'}>
          <div className={'cat-final-test__items cat-final-test__content-wrapper'}>
            {
              tasksItems.map((item, i) => {
                const prevItem = tasksItems.find(el => el.lessonCode === item.prevKey);
                return (
                  <CatFinalTestMapElement
                    start={workTime?.start}
                    isOpen={isOpen}
                    prevItem={prevItem}
                    setTaskModalVisible={setTaskModalVisible} coursePaid={props.coursePaid} key={item.lessonCode} {...item}
                  />
                )
              })
            }
          </div>
          <div className={'cat-final-test__results'}>
            <ProgressBar
              maxItemsCount={tasksItems.length}
              completeItemsCount={completeItems.length}
            />
            <Clock
              complete={mapComplete}
              startTimeStr={workTime?.start}
              defaultMinutes={Number(workTime?.minutes)}
              loading={mainLoading}
              completeCallback={() => {
                setWorkTimeIsOver(true)
              }}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <MapContainer {...props} sizeAuto={true} withoutSuccessScreen={true} setLoading={setMainLoading} withoutFog={true}>
      <Content />
    </MapContainer>
  )
}

const mapStateToProps = state => {
  return ({
    mapStatus: state.mapReducer.mapStatus,
    timer: state.mapReducer.timer,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({
  getOrCreateMapProgress: (initial) => dispatch(actions.getOrCreateMapProgress(initial, maps.catFinalTestMap.key)),
  updateStatusForMap: (action) => dispatch(actions.updateStatusForMap(maps.catFinalTestMap.key, action)),
  catFinalTestSuccess: () => dispatch(actions.catFinalTestSuccess()),
  courseMapAuth: () => dispatch(actions.courseMapAuth()),
  textProblemAnswerCleaner: (lessonCodesList) => dispatch(actions.textProblemAnswerCleaner(lessonCodesList))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatFinalTestMap);
