import React from "react";


/** Initialize array with radius of n and queue values ahead of it */
export function pointRadiusLast(radius, length, initialArray) {
  var result = initialArray || [ radius ];
  while (result.length < length) result.unshift(0);
  return result;
}

export const getChartInitialData = (props) => ({
  labels: [],
  datasets: [
    {
      label: props.dataLabel,
      align: 'right',
      data: [],
      borderColor: ['#2972CC'],
      pointBorderColor: '#2972CC',
      borderWidth: 2,
      backgroundColor: ['rgba(200,0,0,0.0)'],
      pointBackgroundColor: '#2972CC',
      pointStyle: 'circle',
    }
  ]
});

export const getChartInitialOptions = (props) => ({
  type:'scatter',
  title: {
    display: true,
    text: props.title,
    fontSize: props.titleFontSize || 18,
    fontColor: '#111111',
    lineHeight: 1.1,
    padding: 5
  },
  elements: {
    point: {
      radius: 0,
      backgroundColor: 'rgba(0,0,0,0)'
    }
  },
  legend: {
    align: 'end',
    labels: {
      padding: 5,
      usePointStyle: true
    }
  },
  animation: { duration: 10, },
  scales: {
    xAxes: [
      {
        ticks: {
          min: 0,
          max: props.xMax,
          stepSize: props.xStep
        },
        type: 'linear',
        scaleLabel: {
          display: true,
          labelString: props.xLabel
        },
      }
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          max: props.yMax,
          stepSize: props.yStep
        },
        type: 'linear',
        scaleLabel: {
          display: true,
          labelString: props.yLabel
        }
      },
    ]
  }
});