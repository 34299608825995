import React from "react";
import {Group} from "react-konva";
import initialVideo from '../../../../images/space/repulsion_of_bodies/Mass_start.mp4';
import {trackerInstanceName} from "react-yandex-metrika/src/constants";


export const defaultPatchData = {
  answerWindowTitle: '',
  answerWindowText: '',
  answerWindowVisible: false,

  startBtnVisible: false,
  openAnswerWindowBtnVisible: false,

  selectionBlockVisible: false,
  videoSrc: undefined,
  onClickRepulsionBtn: (comp) => {},

  theoryVisible: false,
  lessonComplete: false,
}

const astronautDefaultPos = {
  astronautRightX: 0,
  astronautRightRotation: 0,
  astronautLeftX: 0,
  astronautLeftRotation: 0,
}

export const repulsionOfBodiesTwoAstronautScenario = [

  {
    key: 'initial animation',
    patchData: {
      ...defaultPatchData,
      videoSrc: initialVideo,
    },
    next_success: 'astronauts step 1'
  },

  {
    key: 'astronauts step 1',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми “Оттолкнуться”',
      startBtnVisible: true,
      repulsion: false,
      selectLeft: false,
      selectRight: false,
      ...astronautDefaultPos,
      onClickRepulsionBtn: (comp) => {
        comp.scenarioManager.selectStepByKey('repulsion step 1');
      },
    }
  },{
    key: 'repulsion step 1',
    patchData: {
      ...defaultPatchData,
      title: 'Кто из космонавтов находился в левом скафандре?',

      selectLeft: true,
      selectRight: false,

      thinAstronautCorrect: true,
      fatAstronautCorrect: false,

      leftAstronautSpeed: 20,
      rightAstronautSpeed: 10,

      lastTestStep: 'repulsion step 1',
      selectionBlockVisible: true,

      ...astronautDefaultPos,
      repulsion: true,
      selectNextStep: (comp) => {
        comp.scenarioManager.selectStepByKey('astronauts step 2');
      }
    },
    next_success: 'select astronaut',
  },

  {
    key: 'astronauts step 2',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми “Оттолкнуться”',
      startBtnVisible: true,
      repulsion: false,
      selectLeft: false,
      selectRight: false,
      ...astronautDefaultPos,
      onClickRepulsionBtn: (comp) => {
        comp.scenarioManager.selectStepByKey('repulsion step 2');
      },
    }
  },{
    key: 'repulsion step 2',
    patchData: {
      ...defaultPatchData,
      title: 'Кто из космонавтов находился в левом скафандре?',

      selectLeft: true,
      selectRight: false,

      fatAstronautCorrect: true,
      thinAstronautCorrect: false,

      leftAstronautSpeed: 15,
      rightAstronautSpeed: 30,

      lastTestStep: 'repulsion step 2',
      selectionBlockVisible: true,

      ...astronautDefaultPos,
      repulsion: true,
      selectNextStep: (comp) => {
        comp.scenarioManager.selectStepByKey('astronauts step 3');
      }
    },
    next_success: 'select astronaut',
  },

  {
    key: 'astronauts step 3',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми “Оттолкнуться”',
      startBtnVisible: true,
      repulsion: false,
      selectLeft: false,
      selectRight: false,
      ...astronautDefaultPos,
      onClickRepulsionBtn: (comp) => {
        comp.scenarioManager.selectStepByKey('repulsion step 3');
      },
    }
  },{
    key: 'repulsion step 3',
    patchData: {
      ...defaultPatchData,
      title: 'Кто из космонавтов находился в правом скафандре?',

      selectLeft: false,
      selectRight: true,

      fatAstronautCorrect: true,
      thinAstronautCorrect: false,

      leftAstronautSpeed: 25,
      rightAstronautSpeed: 12.5,

      lastTestStep: 'repulsion step 3',
      selectionBlockVisible: true,

      ...astronautDefaultPos,
      repulsion: true,
      selectNextStep: (comp) => {
        comp.scenarioManager.selectStepByKey('astronauts step 4');
      }
    },
    next_success: 'select astronaut',
  },

  {
    key: 'astronauts step 4',
    patchData: {
      ...defaultPatchData,
      title: 'Нажми “Оттолкнуться”',
      startBtnVisible: true,
      repulsion: false,
      selectLeft: false,
      selectRight: false,
      ...astronautDefaultPos,
      onClickRepulsionBtn: (comp) => {
        comp.scenarioManager.selectStepByKey('repulsion step 4');
      },
    }
  },{
    key: 'repulsion step 4',
    patchData: {
      ...defaultPatchData,
      title: 'Кто из космонавтов находился в правом скафандре?',

      selectLeft: false,
      selectRight: true,

      thinAstronautCorrect: true,
      fatAstronautCorrect: false,

      leftAstronautSpeed: 10,
      rightAstronautSpeed: 20,

      lastTestStep: 'repulsion step 4',
      selectionBlockVisible: true,

      ...astronautDefaultPos,
      repulsion: true,
      selectNextStep: (comp) => {
        comp.scenarioManager.selectStepByKey('astronauts step 1');
      }
    },
    next_success: 'select astronaut',
  },

  {
    key: 'select astronaut',
    patchData: {
      ...defaultPatchData,

      selectionBlockVisible: true,

      onSelectAstronaut: () => {},

      repulsion: false,
    }
  },



  {
    key: 'answerWindow success',
    patchData: {
      ...defaultPatchData,

      selectLeft: false,
      selectRight: false,

      answerWindowTitle: 'Правильно!',
      answerWindowVisible: true,
      answerWindowText: 'Более тяжелый объект после отталкивания \nприобретает меньшую скорость',
    },
  },
  {
    key: 'answerWindow failure',
    patchData: {
      ...defaultPatchData,

      selectLeft: false,
      selectRight: false,

      answerWindowTitle: 'Будь внимательнее!',
      answerWindowVisible: true,
      answerWindowText: 'Более тяжелый объект после отталкивания \nприобретает меньшую скорость',
    },
  },


  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,
      title: 'Блок с теорией',
      theoryVisible: true,
      repulsion: false,
      ...astronautDefaultPos,

      onClickSkipTheory: (comp) => {
        if (comp.data.theoryFailure) {
          comp.scenarioManager.selectStepByKey(comp.data.lastAnswerKey || 'astronauts');
          comp.data.lastAnswerSuccess = undefined;
          return
        }
        comp.scenarioManager.selectStepByKey('success');
      }
    },
  },


  {
    key: 'success',
    patchData: {
      ...defaultPatchData,
      theoryVisible: true,
      lessonComplete: true,
    },
  }

]