import React from "react";
import useImage from "use-image";
import triangleImg from "../../../../images/submarine/step4/triangle.png";
import {Group, Image, Rect, Line, RegularPolygon, Circle} from "react-konva";
import cloneDeep from "lodash.clonedeep";


/**
 * PROPS
 *
 *  getResetCallback: f(callback)
 *  stageNode: konva node
 *  data: f() {leftVal: number, rightVal: number, start: bool}
 *  leftWeightNode: konva node
 *  rightWeightNode: konva node
 */

class CanvasBalloonScale extends React.Component
{
  constructor(props)
  {
    super(props);

    // --------- REFS ---------
    this.stickRef = React.createRef();
    this.platformLeftRef = React.createRef();
    this.platformRightRef = React.createRef();

    this.staticData = {
      maxAngle: 10
    };
    this.initialData = {
      rotationAngle: 0
    };
    this.data = cloneDeep(this.initialData);
  }

  reset = () => this.data = cloneDeep(this.initialData);

  componentDidMount()
  {
    const {getResetCallback} = this.props;
    if (getResetCallback) {
      getResetCallback(this.reset);
    }
    window.requestAnimationFrame(this.move);
  }

  getAllNodes = () => ({
    stickNode: this.stickRef?.current,
    platformLeftNode: this.platformLeftRef?.current,
    platformRightNode: this.platformRightRef?.current,
  });

  move = (time) => {

    const { maxAngle } = this.staticData;
    const { leftVal, rightVal } = this.props.data();
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    if (!this.props.stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    const leftMomentum = leftVal;
    const rightMomentum = rightVal;

    let angleDelta = 0;
    let correctAngle = 0;
    const s = 100;

    angleDelta = (rightMomentum - leftMomentum) / Math.PI * 180 / 3;


    if (angleDelta > maxAngle) {
      correctAngle = maxAngle;
    } else if (angleDelta < -maxAngle) {
      correctAngle = -maxAngle;
    } else {
      correctAngle = angleDelta;
    }

    // todo add air density illustration


    data.rotationAngle = correctAngle;

    this.updateStage();
  };

  updateStage()
  {
    const { leftVal, rightVal } = this.props.data();
    const data = this.data;
    const timedelta = data.prevTime ? data.startTime - data.prevTime : 0;

    const stageNode = this.props.stageNode;
    if (!stageNode) return;

    const nodes = this.getAllNodes();

    const isNotNodes = Object.keys(nodes).some((key) => !nodes[key]);
    if (!isNotNodes) {
      nodes.stickNode.rotation(data.rotationAngle);

      const left = data.rotationAngle * 3.5;
      const right = -data.rotationAngle * 3.5;

      const offsetX = Math.abs(data.rotationAngle/2);

      nodes.platformLeftNode.offsetY(left);
      nodes.platformLeftNode.offsetX(-offsetX);

      nodes.platformRightNode.offsetY(right);
      nodes.platformRightNode.offsetX(-offsetX);
    }
    stageNode.draw();
  }

  CanvasComp = () => {
    const data = this.data;
    const {leftWeightNode, rightWeightNode} = this.props;

    return (
      <React.Fragment>
        <Group>

          <Group x={500} y={400}>
            <Line ref={this.stickRef} points={[-203, -30, 205, -30]} stroke={'#2d95ef'} strokeWidth={6}/>

            <Group ref={this.platformLeftRef}>
              <Group x={-200} y={-90}>
                {
                  leftWeightNode ? (
                    leftWeightNode
                  ) : (
                    <Circle
                      radius={30}
                      fill={'rgba(200, 0, 0, .3)'}
                      stroke={'rgba(0,0,0,0.3)'}
                    />
                  )
                }
              </Group>
              <Rect
                x={-210} y={-40}
                width={20}
                height={20}
                fill={'#2d95ef'}
              />
              <Line points={[-200, -30, -200, -60]} stroke={'#2d95ef'} strokeWidth={4}/>
              <Line points={[-250, -60, -150, -60]} stroke={'#2d95ef'} strokeWidth={4}/>
              <Line points={[-152, -60, -152, -80]} stroke={'#2d95ef'} strokeWidth={4}/>
              <Line points={[-248, -60, -248, -80]} stroke={'#2d95ef'} strokeWidth={4}/>
            </Group>

            <Group ref={this.platformRightRef}>
              <Group x={200} y={-90}>
                {
                  rightWeightNode ? (
                    rightWeightNode
                  ) : (
                    <Circle
                      radius={30}
                      fill={'rgba(59,183,250,0.3)'}
                      stroke={'rgba(0,0,0,0.3)'}
                    />
                  )
                }
              </Group>
              <Rect
                x={190} y={-40}
                width={20}
                height={20}
                fill={'#2d95ef'}
              />
              <Line points={[200, -30, 200, -60]} stroke={'#2d95ef'} strokeWidth={4}/>
              <Line points={[250, -60, 150, -60]} stroke={'#2d95ef'} strokeWidth={4}/>
              <Line points={[152, -60, 152, -80]} stroke={'#2d95ef'} strokeWidth={4}/>
              <Line points={[248, -60, 248, -80]} stroke={'#2d95ef'} strokeWidth={4}/>
            </Group>

            <RegularPolygon
              sides={3}
              fill={'#2d95ef'}
              radius={30}
            />
            <Circle radius={3} fill={'black'} x={0} y={-27}/>
          </Group>
        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <this.CanvasComp />
    )
  }
}

export default CanvasBalloonScale;
