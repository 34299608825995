import useImage from "use-image";
import wheel from "../../../../images/steamEngine/wheel.png";
import piston from "../../../../images/steamEngine/piston.png";
import stick from "../../../../images/steamEngine/stick.png";
import tank from "../../../../images/steamEngine/water_box.png";
import switchImage from "../../../../images/steamEngine/switch.png";
import steamBox from "../../../../images/steamEngine/steam_box.png";
import steamPipe1Img from "../../../../images/steamEngine/steam_tube_1.png";
import steamPipe2Img from "../../../../images/steamEngine/steam_tube_2.png";
import React from "react";
import {Group, Image, Layer, Rect, Line} from "react-konva";
import Boiling from "./Boiling";
import CanvasImage from "./CanvasImage";
import {GIF} from "../../../canvas/components/GIF";
import fireGIF from "../../../../images/fireSmall.gif";
import cloneDeep from "lodash.clonedeep";
import {render} from "react-dom";

/**
 *  PROPS
 *
 *  externalTorque: () => float
 *  maxRotationSpeed: int - max wheel rotation speed
 *  burning: bool
 *  manualSwitchLeft: bool - switchLeft position
 *  autoSwitchValve: bool
 *  scaleSize: float - scaling
 *  x: float, y: float - coords
 *  engineData: obj - transfers internal data of a engine component to an external object
 */

class SteamEngineCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.wheelRef = React.createRef();
    this.pistolRef = React.createRef();
    this.stickRef = React.createRef();
    this.steamRightRef = React.createRef();
    this.steamLeftRef = React.createRef();
    this.switcherRef = React.createRef();
    this.steamPipeLeftRef = React.createRef();
    this.steamPipeRightRef = React.createRef();
    this.state = {};
    this.requestId = null;
    this.initialData = {
      switchLeft: true,
      startTime: undefined,
      prevTime: undefined,
      pressure: 0,
      pressurePerSecond: 0.2,
      wheelAngle: 10,
      wheelRotationSpeed: 0,
      pistonOffset: - 50 * Math.cos(Math.PI/180 * 10),
      stickRotation: - Math.asin(50*Math.sin(Math.PI/180 * 10 )/130) / Math.PI * 180,
    };
    this.data = cloneDeep(this.initialData);
  }

  reset = () => {this.data = cloneDeep(this.initialData)};

  componentDidUpdate(prevProps) {
    if (!this.props.autoSwitchValve) {
      this.data.switchLeft = this.props.manualSwitchLeft;
    }
  }

  componentDidMount() {
    const {getResetCallback} = this.props;
    if (getResetCallback) {
      getResetCallback(this.reset);
    }

    window.requestAnimationFrame(this.move);
  }

  get leverage () {return 50 * Math.sin(Math.PI/180 * this.data.wheelAngle) }
  get stickRotation () {return - Math.asin(this.leverage / 130) / Math.PI * 180}

  move = (time) => {
    const {burning, stageNode, autoSwitchValve, engineData, maxRotationSpeed, externalTorque} = this.props;
    const pistolNode = this.pistolRef?.current;
    let data = this.data;
    const switchLeft = data.switchLeft;

    this.requestId = window.requestAnimationFrame(this.move);

    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    let absSpeed = Math.abs(data.wheelRotationSpeed);
    let signSpeed = Math.sign(data.wheelRotationSpeed);

    absSpeed -= 20 * timedeltaSec;
    if (maxRotationSpeed && (absSpeed > maxRotationSpeed)) absSpeed = maxRotationSpeed;
    if (absSpeed < 0) absSpeed = 0;

    data.wheelRotationSpeed = signSpeed * absSpeed;

    if (burning) {
      let force = switchLeft ? 1 : -1;
      let torque = (3 * force * this.leverage);

      data.wheelRotationSpeed += torque*timedeltaSec;
    }

    if (externalTorque) {
      const externalTorqueVal = externalTorque();
      // console.log('debug on torque value', externalTorqueVal);
      if (externalTorqueVal)
        data.wheelRotationSpeed += externalTorqueVal * timedeltaSec / 10;
    }

    data.wheelAngle += timedeltaSec*data.wheelRotationSpeed;



    if (autoSwitchValve) {
      let anglePos = data.wheelAngle % 360;
      if (anglePos < 0)
        anglePos += 360;

      if (anglePos > 0 && anglePos < 180)
        data.switchLeft = true;
      if (anglePos > 180 && anglePos < 360)
        data.switchLeft = false;
    }

    data.pistonOffset = - 50 * Math.cos(Math.PI/180 * data.wheelAngle);
    data.stickRotation = this.stickRotation;


    if (engineData) {
      engineData.leverage = this.leverage;
      engineData.force = data.force;
      engineData.torque = data.torque;
      engineData.wheelRotationSpeed = data.wheelRotationSpeed;
      engineData.wheelAngle = data.wheelAngle;
      engineData.pressure = data.pressure;
      engineData.pressurePerSecond = data.pressurePerSecond;
    }

    this.updateStage();
  };

  updateStage() {
    const {burning, autoSwitchValve, stageNode} = this.props;
    const {wheelAngle, pistonOffset, stickRotation, switchLeft} = this.data;

    let wheelNode = this.wheelRef?.current;
    let pistolNode = this.pistolRef?.current;
    let stickNode = this.stickRef?.current;
    let steamLeftNode = this.steamLeftRef?.current;
    let steamRightNode = this.steamRightRef?.current;
    let switcherNode = this.switcherRef?.current;
    let steamPipeLeftNode = this.steamPipeLeftRef?.current;
    let steamPipeRightNode = this.steamPipeRightRef?.current;
    if (!stageNode || !wheelNode || !pistolNode || !stickNode || !steamLeftNode || !steamRightNode) return;


    wheelNode.rotation(wheelAngle);
    pistolNode.x(530 + pistonOffset);
    stickNode.x(620 + pistonOffset);
    stickNode.rotation(stickRotation);

    steamLeftNode.width(95 + pistonOffset);
    steamRightNode.x(105 + pistonOffset);
    steamRightNode.width(87 - pistonOffset);

    // -------------- SWITCHER --------------
    if (burning || !autoSwitchValve) {
      const highPressure = burning ? 0.75 : 0.15;
      const lowPressure = burning ? 0.25 : 0.15;
      const leftOpacity = switchLeft ? highPressure : lowPressure;
      const rightOpacity = !switchLeft ? highPressure : lowPressure;

      switcherNode.x(switchLeft ? 427 : 408);
      steamPipeLeftNode.opacity(leftOpacity);
      steamPipeRightNode.opacity(rightOpacity);
      steamLeftNode.opacity(leftOpacity);
      steamRightNode.opacity(rightOpacity);
    }
    // --------------

    stageNode.draw();
  }

  EngineCanvas = () => {
    const {burning, scaleSize, x, y, autoSwitchValve} = this.props;
    const data = this.data;
    const switchLeft = data.switchLeft;
    const [wheelImg] = useImage(wheel);
    const [pistonImg] = useImage(piston);
    const [stickImg] = useImage(stick);
    const [tankImg] = useImage(tank);
    const [switchImg] = useImage(switchImage);
    const [steamBoxImg] = useImage(steamBox);
    const [steamPipe1] = useImage(steamPipe1Img);
    const [steamPipe2] = useImage(steamPipe2Img);

    const tankWithWaterPos = {x: 179, y: 300, width: 254, height: 150};
    const wheelPos = {x: 750, y: 400, rotation: data.wheelAngle, height: 170, width:170};
    const pistonPos = {x: 530 + data.pistonOffset, y: 400, height:89, width: 192};
    const stickPos = {x: 620 + data.pistonOffset, y: 400, rotation: data.stickRotation, height:26, width: 155};

    const firePos = {x: tankWithWaterPos.x - 100, y: tankWithWaterPos.y + 95};

    // todo move to data, somehow change when water is boiling
    const highPressure = burning ? 0.75 : 0.15;
    const lowPressure = burning ? 0.25 : 0.15;

    const leftOpacity = switchLeft ? highPressure : lowPressure;
    const rightOpacity = !switchLeft ? highPressure : lowPressure;

    const steamColor = 'rgba(255,255,255,0.7)';

    return (
      <React.Fragment>
        <Layer scale={{x: scaleSize, y: scaleSize}} x={x} y={y}>
          <Group>
            <Group {...tankWithWaterPos}>
              <Rect offsetX={tankWithWaterPos.width/2} y={-30} width={tankWithWaterPos.width} height={35} fill={steamColor} cornerRadius={[15,15,0,0]} opacity={highPressure} preventDefault={false}/>
              <Rect offsetX={tankWithWaterPos.width/2} y={5} width={tankWithWaterPos.width} height={80} fill={'#cae9fe'} cornerRadius={[0,0,15,15]}  preventDefault={false}/>
              <Boiling width={tankWithWaterPos.width-10} x={-120} y={9} height={75} bubblesNum={17} boiling={burning}/>
              <Image image={tankImg} y={55} offsetX={262/2} offsetY={183/2} preventDefault={false}/>
            </Group>


            <Rect x={305} y={291} fill={steamColor} opacity={highPressure} width={80} height={4} preventDefault={false}/>
            <Rect x={383} y={271} fill={steamColor} opacity={highPressure} cornerRadius={5} width={110} height={42}  preventDefault={false}/>

            <Image ref={this.steamPipeLeftRef} image={steamPipe1} x={358} y={311} opacity={leftOpacity} width={60} height={45} preventDefault={false}/>
            <Image ref={this.steamPipeRightRef} image={steamPipe2} x={453} y={311} opacity={rightOpacity} width={60} height={45} preventDefault={false}/>


            <Image x={310} y={269} image={steamBoxImg} width={229} height={181} preventDefault={false}/>
            <Group ref={this.switcherRef} x={switchLeft ? 427 : 408} y={295} preventDefault={false}>
              {
                !autoSwitchValve && (
                  <Line points={[30,10,200,10]} stroke={'#603813'} strokeWidth={3} />
                )
              }
              <Image image={switchImg} width={39} height={17}/>
            </Group>


            <Group x={341} y={400}>
              <Rect ref={this.steamLeftRef} fill={steamColor} offsetY={89/2} opacity={leftOpacity} width={95 + data.pistonOffset} height={89} preventDefault={false}/>

              <Rect ref={this.steamRightRef} fill={steamColor} x={105 + data.pistonOffset} offsetY={89/2} opacity={rightOpacity} width={87-data.pistonOffset} height={89} preventDefault={false}/>
            </Group>


            <CanvasImage imgRef={this.wheelRef} {...wheelPos} image={wheelImg}/>
            <CanvasImage imgRef={this.pistolRef} {...pistonPos} image={pistonImg}/>

            <Image ref={this.stickRef} {...stickPos} offsetY={stickPos.height/2} offsetX={10} image={stickImg} preventDefault={false}/>
            {
              burning &&
              <Group >
                <GIF src={fireGIF} loop={true} {...firePos} />
                <GIF src={fireGIF} loop={true} {...firePos} x={firePos.x + 80} />
                <GIF src={fireGIF} loop={true} {...firePos} x={firePos.x + 160} />
              </Group>
            }
          </Group>
        </Layer>
      </React.Fragment>
    );
  };

  render () {
    return (
      <this.EngineCanvas />
    )
  }
}

export default SteamEngineCanvas;
