import {layout2} from "../../../../utils/styles";
import React, {useState} from "react";
import {Button, Menu, Dropdown} from "antd";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";


const DropdownMenu = (props) => {
  const {hwItems, onClickHwItem, taskId} = props;

  const [loading, setLoading] = useState(false);

  const creteNewHomework = async () => {
    if (!loading) {
      setLoading(true);
      await props.createNewHw();
      setLoading(false);
    }
  }

  const selectedKeys = hwItems.reduce((accum, hw) => {
    const isAdded = hw.tasks.find(tsk => tsk.id === taskId);
    if (isAdded) {
      accum.push('homework-' + hw.id);
    }
    return accum
  }, [])
  return (
    <div style={{backgroundColor: 'white', minWidth: '150px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 5px 3px', borderRadius: '5px', overflow: 'hidden'}}>
      <p style={{padding: '10px 10px 7px'}}><b>Добавьте в задание:</b></p>
      <Menu selectedKeys={selectedKeys}>
        {
          hwItems.map((hw) => {
            const key = 'homework-'+hw.id;
            const isAdded = selectedKeys.includes(key);
            return (
              <Menu.Item
                key={key}
                onClick={() => onClickHwItem(hw.id, isAdded)}
              >
                { hw.title }
              </Menu.Item>
            )
          })
        }
      </Menu>
      <Button
        loading={loading}
        size={'small'}
        type={'primary'}
        style={{width: '100%', marginTop: `${hwItems?.length > 0 ? 10 : 0}px`, height: '35px', borderTopLeftRadius: 0, borderTopRightRadius: 0}}
        onClick={creteNewHomework}
      >
        + Создать задание
      </Button>
    </div>
  )
}



const AddHomeworkBtn = (props) => {
  const {taskId, isAnonym, homeworks, teacherHwPage, selectedHwId} = props;

  const isSingleHw = homeworks.length === 1;
  const firstHw = homeworks?.[0];
  const taskAdded = firstHw && firstHw.tasks.find(tsk => tsk.id === taskId)
  const history = useHistory();


  const onClickAddTaskToNewHw = async () => {
    props.setAddNewHomeworkFlag(true);
    const resp = await props.createHomework('', taskId);
    if (resp?.status) {
      props.setSelectedHomeworkId(resp.newHomeworkId);
      history.push('/homework')
    }
  }

  const onClickHwItem = (hwId, isAdded) => {
    if (isAdded) {
      props.updateHomework({hwId, taskId, action: 'remove'});
    } else {
      props.updateHomework({hwId, taskId, action: 'add'});
    }
  }

  return (
    <Dropdown
      overlay={
        <>
          {
            (!teacherHwPage && !isAnonym) ? (
              <DropdownMenu taskId={taskId} hwItems={homeworks} onClickHwItem={onClickHwItem} createNewHw={onClickAddTaskToNewHw}/>
            ) : null
          }
        </>
      }
      trigger={'click'}
      placement="bottomRight"
      arrow={true}
    >
      <div
        onClick={() => {
          if (isAnonym) {
            return props.setAnonymousPopupVisible(true);
          }
          // Удаление задачи из ДЗ на стр. с ДЗ
          if (teacherHwPage) {
            onClickHwItem(selectedHwId, 'true');
          }
        }}
        style={{
          backgroundColor: 'white',
          color: layout2.blue2, display: 'flex',
          height: '45px', width: '45px',
          margin: '0 15px 0 0', border: `1px solid ${teacherHwPage ? layout2.red : layout2.blue2 }`
        }}
        className={`lsnAddTaskToHW ${teacherHwPage ? 'singleAdded' : ''}`}
      >
        {
          (teacherHwPage && !isAnonym) ? (
            <MinusOutlined style={{color: layout2.red, fontSize: '25px', margin: 'auto'}}/>
          ) : (
            <PlusOutlined style={{color: layout2.blue2, fontSize: '25px', margin: 'auto'}}/>
          )
        }
      </div>
    </Dropdown>
  )
}


const mapStateToProps = (state, {match}) => {
  return {
    homeworks: state.lessonsReducer.homeworks,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    createHomework: (title, taskId) => dispatch(actions.createHomework(title, taskId)),
    updateHomework: ({hwId, taskId, action}) => dispatch(actions.updateHomework({hwId, title: undefined, lsnId: taskId, action})),
    setAnonymousPopupVisible: (visible) => dispatch(actions.setAnonymousPopupVisible(visible)),
    setAddNewHomeworkFlag: (status) => dispatch(actions.setAddNewHomeworkFlag(status)),
    setSelectedHomeworkId: (hwId) => dispatch(actions.setSelectedHomeworkId(hwId)),
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddHomeworkBtn);
