import React, {useEffect, useState} from "react";
import * as actions from '../../../store/actions';
import {connect} from "react-redux";
import {_t} from "../../../utils/lang/common";
import {Button, Col, Form, Input, Row, Select, Typography} from "antd";
import AuthFormWrapper from './AuthFormWrapper';
import '../styles/styles.css'
import {useQuery} from "../../../utils/custom-hooks/useQuery";
import {useHistory} from "react-router-dom";
import {ymRegistrationGoal} from "../../../metriks/ym_metriks";
import MaskedInput from "antd-mask-input";


const { Text } = Typography;




const RegistrationForm = (props) => {
  const {
    registrationAction,
    isTeacher,
    name,
    lastName,
    email,
    phone,
    password,
    repeatPassword,
  } = props;

  const formRef = React.createRef(null);
  const [errorText, setErrorText] = useState('');


  const history = useHistory();
  const nextPath = useQuery().get('next');
  const mode = useQuery().get('mode');


  const onFinish = async (e) => {
    ymRegistrationGoal(isTeacher);

    const resp = await registrationAction();
    if (resp) {
      if (resp.status) {
        history.push(`/${nextPath || 'layout2'}`);
      } else {
        if (resp.errorExtraCode === 'email_already_exist') {
          localStorage.setItem('user_email', email);
        }
        setErrorText(resp.error);
      }
    }
  };

  const requiredFieldErrTxt = `Поле обязательно для заполнения`;
  const selectData = {
    teacher: {txt: 'Я учитель', id:1, modeKey: 'tcr'},
    student: {txt: 'Я ученик', id:0, modeKey: 'std'}
  }

  const foundTeacherOrStudentVal = Object.keys(selectData).map((k) => selectData[k]).find((item) => item.modeKey === mode)?.id;

  useEffect(() => {
    props.updateIsTeacher(foundTeacherOrStudentVal);
  }, [mode])
  return (
    <AuthFormWrapper title={_t("title_sign_up")} className={'registration-form'}>
      <Form ref={formRef} name="control-ref" onFinish={onFinish}>
        <Form.Item initialValue={
          mode
            ? foundTeacherOrStudentVal
            : isTeacher === undefined
            ? null
            : isTeacher
        } rules={[{ required: Boolean(foundTeacherOrStudentVal === undefined), message: requiredFieldErrTxt }]} name={'isTeacher'}>
          <Select
            id={'isTeacherSelect'}
            name={'isTeacher'}
            className={'registration__select'}
            placeholder={'Я учитель'}
            onChange={(val) => props.updateIsTeacher(val)}
          >
            {
              Object.keys(selectData).map((k) => {
                const item = selectData[k];
                return (
                  <Select.Option value={item.id} key={`${item.id}`}>{item.txt}</Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item initialValue={name} rules={[{ required: true, message: requiredFieldErrTxt }]} name="name">
          <Input
            id={'nameRegistrForm'}
            name={'name'}
            type="text"
            placeholder={_t("placeholder_name")}
            style={styles.input}
            onChange={(e) => props.updateName(e.target.value)}
          />
        </Form.Item>
        <Form.Item initialValue={lastName} rules={[{ required: true, message: requiredFieldErrTxt }]} name="lastName">
          <Input
            id={'lastNameRegistrForm'}
            name={'lastName'}
            type="text"
            placeholder={!isTeacher ? _t("placeholder_last_name") : _t('placeholder_second_name')}
            style={styles.input}
            onChange={(e) => props.updateLastName(e.target.value)}
          />
        </Form.Item>

        <Form.Item initialValue={email} rules={[{ required: true, message: requiredFieldErrTxt }]} name={'email'}>
          <Input
            id={'emailRegistrForm'}
            name={'email'}
            type="email"
            placeholder={'Email'}
            style={styles.input}
            onChange={(e) => props.updateEmail(e.target.value)}
          />
        </Form.Item>
        {
          isTeacher ? (
            <Form.Item initialValue={phone} rules={[{ required: false, message: requiredFieldErrTxt }]} name={'phone'}>
              <MaskedInput
                id={'phoneRegistrForm'}
                mask="+7 (111) 111-11-11"
                name="phone"
                size="19"
                placeholder={'Телефон'}
                style={styles.input}
                onChange={(e) => props.updatePhone(e.target.value)}
              />
            </Form.Item>
          ) : null
        }
        <Form.Item initialValue={password} rules={[{ required: true, message: requiredFieldErrTxt }]} name={'password'}>
          <Input.Password
            id={'passwordRegistrForm'}
            className={'error'}
            name={'password'}
            type="password"
            placeholder={_t("placeholder_password")}
            style={styles.input}
            onChange={(e) => props.updatePassword(e.target.value)}
          />
        </Form.Item>
        {/*<Form.Item initialValue={repeatPassword} rules={[{ required: true, message: requiredFieldErrTxt }]} name={'repeatPassword'}>*/}
        {/*  <Input.Password*/}
        {/*    id={'repeatPasswordRegistrForm'}*/}
        {/*    className={'error'}*/}
        {/*    name={'repeatPassword'}*/}
        {/*    type="password"*/}
        {/*    placeholder={_t("placeholder_repeat_password")}*/}
        {/*    style={styles.input}*/}
        {/*    onChange={(e) => props.updateRepeatPassword(e.target.value)}*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        {
          errorText &&
            <Text type="danger" style={{margin: '-10px 0 20px', display: 'block', maxWidth: '230px'}}>{errorText}</Text>
        }
        <Form.Item>
          Нажимая зарегистрироваться, я соглашаюсь с условиями <a href={'https://app.inventic.tech/legal-docs/oferta.pdf'}>оферты</a> и <a href={'https://inventic.tech/privacy'}>политикой конфиденциальности</a>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          {_t("button_sign_up")}
        </Button>
        <Row style={{margin: '15px auto 0', alignItems: 'center', justifyContent: 'center'}}>
          <Col> <span>{_t("already_have_account")}? </span> </Col>
          <Col> <Button type={'link'} onClick={() => history.push(`/login${nextPath ? '?next='+nextPath : ''}`)}
          > {_t("button_login")} </Button> </Col>
        </Row>
      </Form>
    </AuthFormWrapper>
  )
};

const mapStateToProps = (state) => ({
  lang: state.lessonsReducer.lang,
  name: state.authReducer.name,
  lastName: state.authReducer.lastName,
  isTeacher: state.authReducer.isTeacher,
  email: state.authReducer.email,
  password: state.authReducer.password,
  repeatPassword: state.authReducer.repeatPassword,
});

const mapDispatchToProps = (dispatch) => ({
  registrationAction: () => dispatch(actions.registrationAction()),

  // updateClassOfStudent: (val) => dispatch(actions.updateClassOfStudent(val)),
  updateName: (val) => dispatch(actions.updateName(val)),
  updateLastName: (val) => dispatch(actions.updateLastName(val)),
  updateIsTeacher: (val) => dispatch(actions.updateIsTeacher(val)),
  updateEmail:  (val) => dispatch(actions.updateEmail(val)),
  updatePassword: (val) => dispatch(actions.updatePassword(val)),
  updatePhone: (val) => dispatch(actions.updatePhone(val)),
  updateRepeatPassword: (val) => dispatch(actions.updateRepeatPassword(val)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationForm);

const styles = {
  container: {
    padding: '30px 10px 10px',
    width: '300px',
    border: '1px solid rgba(0,0,0,0.3)',
    borderRadius: '10px',
    boxShadow: '-1px 3px 5px 0px rgba(0,0,0,0.2)',
    marginTop: '-100px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    marginTop: '20px',
  },
  label: {},
  input: {
    textAlign: 'left',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  button: {
    border: 0,
    background: '#2A73CC',
    color: 'white',
    fontWeight: 'bold',
    marginTop: '30px',
    padding: '13px 0'
  },
};
