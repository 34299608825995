import {Group} from "react-konva";
import React from "react";
import formula1Img from '../../../../images/movableFixedBLock/formula1.png';
import formula2Img from '../../../../images/movableFixedBLock/formula2.png';

export const getScenario = (code) => {
  if (code === 'peryshkin9') {
    return fixedBlockScenario;
  }
  if (code === 'peryshkin10') {
    return movableBlockScenario;
  }
}

export const fixedBlockScenario = [
  {
    key: 'fixedBlock',
    patchData: {
      centerFixedBLockVisible: true,
      weightPos: {x: 60, y: 225},
      lineDraggablePos: {
        x: 60, y: 0, points: []
      },
      containerPos: { x: 500, y: 200, },
      rope1LeftPos: {x: 0, y: 0},
      rope1RightPos: {x: 60, y: 0, maxHeightLift: 150, lineHeight: 225},
      formulaImg: formula1Img,
      formulaStg: {
        x: 150,
        width: 130,
        height: 160,
      }
    },
  },
  {
    key: 'fixedBlock',
    patchData: {
      centerFixedBLockVisible: true,
      weightPos: {x: 60, y: 225},
      lineDraggablePos: {
        x: 60, y: 0, points: []
      },
      containerPos: { x: 500, y: 200, },
      rope1LeftPos: {x: 0, y: 0},
      rope1RightPos: {x: 60, y: 0, maxHeightLift: 150, lineHeight: 225},
      formulaImg: formula1Img,
      formulaStg: {
        x: 150,
        width: 130,
        height: 160,
      }
    },
  },
]
export const movableBlockScenario = [
  {
    key: 'movableBlock',
    patchData: {
      movableBLockVisible: true,
      weightPos: {x: 133, y: 355},
      lineDraggablePos: {
        x: 40, y: 0, points: []
      },
      containerPos: { x: 200, y: 70, },

      rope1LeftPos: {x: 75, y: 0},
      rope1RightPos: {x: 96, y: 0, maxHeightLift: 220, lineHeight: 285},
      rope2LeftPos: {x: 232, y: -69, lineHeight: 330},
      formulaImg: formula2Img,
      formulaStg: {
        x: 300,
        y: 50,

      }
    },
  },
  {
    key: 'success',
    patchData: {
      movableBLockVisible: true,
      weightPos: {x: 133, y: 355},
      lineDraggablePos: {
        x: 40, y: 0, points: []
      },
      containerPos: { x: 200, y: 70, },

      rope1LeftPos: {x: 75, y: 0},
      rope1RightPos: {x: 96, y: 0, maxHeightLift: 220, lineHeight: 285},
      rope2LeftPos: {x: 232, y: -69, lineHeight: 330},
      formulaImg: formula2Img,
      formulaStg: {
        x: 300,
        y: 50,

      }
    },
  },
]