import React from "react";
import {CanvasContainer} from "../../../canvas";
import {Layer, Group, Rect, Text, Image, Line, Circle, Arrow} from "react-konva";
import cloneDeep from "lodash.clonedeep";
import Indicator from "../../../canvas/components/Indicator";
import {degToRad, radToDeg} from "../../../../utils/common.js";
import Victor from 'victor';


class Pendulum extends React.Component{
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();

    this.rangeControllerRef = React.createRef();

    this.centrifugalForceArrowRef = React.createRef();
    this.forceOfGravityArrowRef = React.createRef();
    this.ropeForceArrowRef = React.createRef();

    this.sumForceArrowRef = React.createRef();

    this.pendulumRef = React.createRef();
    this.startBtnRef = React.createRef();

    this.pendulumContainerRef = React.createRef();

    this.state = {
      pendulumSystem: false,
    };


    this.staticData = {
      minPendulumAngle: -30,
      maxPendulumAngle: 30,

      maxRangeX: 400,
    };
    this.vectorScaling = 10;
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      start: false,

      pendulumCounter: 0,
      pendulumRotationAngle: -30,
      pendulumVelocityAngle: 0,
      gravity: 9.8,
      ropeLength: 0.5,
      // gravityForceV:

      gravityForce: new Victor(0, 50),
      centrifugalForce: new Victor(20,20),
      ropeForce: new Victor(0,-50),
    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    // Формула выведена для угловой скорости из закона сохранения энергии
    // mgh_max = mgh + m*w^2*L^2/2
    // h вычисляется как L(1 - cos(phi)) =>
    // w = sqrt(2g*cos(phi) - cos(phi_max)/ropeLength
    // 0.01 чтобы начальная скорость отличалась от 0
    data.pendulumVelocityAngle = radToDeg(Math.sqrt(2*data.gravity*(Math.cos(degToRad(data.pendulumRotationAngle)) -
      Math.cos(degToRad(this.staticData.maxPendulumAngle + 0.01)))/data.ropeLength));

    data.gravityForce.x = 0;
    data.gravityForce.y = data.gravity * this.vectorScaling;
    data.gravityForce.rotateDeg(-data.pendulumRotationAngle);

    data.centrifugalForce.x = 0;
    data.centrifugalForce.y = Math.pow(degToRad(data.pendulumVelocityAngle), 2) * data.ropeLength  * this.vectorScaling;

    data.ropeForce.x = 0;
    data.ropeForce.y = - (data.gravityForce.y + data.centrifugalForce.y);

    if (data.start) {

      const { minPendulumAngle, maxPendulumAngle} = this.staticData;
      const data = this.data;

      let newAngle = data.pendulumRotationAngle;

      if (data.pendulumCounter % 2 === 0){
        newAngle += data.pendulumVelocityAngle * timedeltaSec;
      } else {
        newAngle -= data.pendulumVelocityAngle * timedeltaSec;
      }

      if (newAngle > maxPendulumAngle) {
        newAngle = maxPendulumAngle;
      }

      if (newAngle < minPendulumAngle) {
        newAngle = minPendulumAngle;
      }
      data.pendulumRotationAngle = newAngle;
      if (data.pendulumRotationAngle && Math.abs(data.pendulumRotationAngle % this.staticData.maxPendulumAngle) === 0) {
        data.pendulumCounter += 1;
      }
    }

    this.updateStage();
  };

  updateStage = () => {
    const data = this.data;

    const rangeControllerNode = this.rangeControllerRef?.current;
    const startBtnTextNode = this.startBtnRef?.current;
    const pendulumNode = this.pendulumRef?.current;

    const centrifugalForceArrowNode = this.centrifugalForceArrowRef?.current;
    const forceOfGravityArrowNode = this.forceOfGravityArrowRef?.current;
    const ropeForceArrowNode = this.ropeForceArrowRef?.current;
    const sumForceArrowNode = this.sumForceArrowRef?.current;
    const pendulumContainerNode = this.pendulumContainerRef?.current;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    let sumForceVector = data.gravityForce.clone().add(data.ropeForce);
    if (this.state.pendulumSystem) {
      sumForceVector.add(data.centrifugalForce);
    }
    // const sumForceVector = data.gravityForce.clone().add(data.ropeForce).add(data.centrifugalForce);
    pendulumNode.rotation(data.pendulumRotationAngle);
    centrifugalForceArrowNode.points([0,0,data.centrifugalForce.x,data.centrifugalForce.y]);
    forceOfGravityArrowNode.points([0,0,data.gravityForce.x,data.gravityForce.y]);
    ropeForceArrowNode.points([0,0,data.ropeForce.x,data.ropeForce.y]);
    sumForceArrowNode.points([0,0,sumForceVector.x,sumForceVector.y]);

    startBtnTextNode.text(data.start ? 'Сбросить' : 'Отпустить');

    if (this.state.pendulumSystem){
      stageNode.rotation(-data.pendulumRotationAngle);
    } else {
      stageNode.rotation(0);
    }

    stageNode.draw();
  };

  startResetOnClick = () => {
    if (this.data.start) {
      this.data.start = false;
      this.data = cloneDeep(this.initialData);
    } else {
      this.data.start = true;
    }
  };

  dragBoundFunc = (pos) => {
    /**
     *  Function to limit movement
     */
    const stageNode = this.stageRef?.current;
    const stageWidth = stageNode.width();
    const stageHeight = stageNode.height();

    const leftBorderX = stageWidth * .2;
    const rightBorderX = stageWidth * .6;
    const topBorderY = stageHeight * .815;
    const bottomBorderY = stageHeight * .815;

    const newX = pos.x >= rightBorderX ? rightBorderX : ( pos.x <= leftBorderX ? leftBorderX : pos.x );
    const newY = pos.y <= topBorderY ? topBorderY : ( pos.y >= bottomBorderY ? bottomBorderY : pos.y );
    return {y: newY, x: newX};
  };

  onDragMove = (e) => {
    const {maxRangeX, maxPendulumAngle, minPendulumAngle} = this.staticData;
    const data = this.data;
    const currNode = e.target;
    const rangeControllerX = currNode.getAbsolutePosition().x - 200;
    const maxAngel = Math.abs(maxPendulumAngle) + Math.abs(minPendulumAngle);
    const coef = maxAngel / maxRangeX;


    data.pendulumRotationAngle = -(rangeControllerX * coef - maxPendulumAngle);
    data.start = false;
  };


  indicatorData = () => {
    return [
      {progressRefCode: 'potentialEnergy',
      title: 'Потенциальная энергия',
      char: 'En',
      value: () => // 100 * En / En_max
      100*(1 - Math.cos(degToRad(this.data.pendulumRotationAngle)))/
      (1 - Math.cos(degToRad(this.staticData.maxPendulumAngle))),
      maxValue: 100},
      {progressRefCode: 'kineticEnergy',
      title: 'Кинетическая энергия',
      char: 'Ek',
      value: () => // 100 * Ek / En_max
      100*Math.pow(degToRad(this.data.pendulumVelocityAngle), 2)*this.data.ropeLength/
      (2*this.data.gravity*(1 - Math.cos(degToRad(this.staticData.maxPendulumAngle)))),
      maxValue: 100},
      {progressRefCode: 'workOfGravity',
      title: 'Работа силы тяжести',
      char: 'Af',
      value: () => // 100 * mg(h_max - h) / mgh_max
      100*(Math.cos(degToRad(this.data.pendulumRotationAngle)) - Math.cos(degToRad(this.staticData.maxPendulumAngle)))/
      (1- Math.cos(degToRad(this.staticData.maxPendulumAngle))),
      maxValue: 100},
    ];
  }

  CanvasPendulum = ({x, y, offsetX, offsetY, pendulumContainerRef}) => {

    const color = '#b1b3b2';
    return (
      <Group x={x} y={y} offsetX={offsetX} offsetY={offsetY} ref={pendulumContainerRef}>
        <Rect width={300} height={50} x={250} y={0} stroke={color} fill={color}/>

        <Group ref={this.pendulumRef} x={400} y={50} rotation={0}>
          <Line ref={this.springRef} points={[0,0,0, 350]} strokeWidth={2} stroke={'rgba(0,0,0,0.8)'}/>


          <Group y={350}>
            <Circle ref={this.ballRef} radius={30} fill={color}/>
            <Line points={[-250,0, 250, 0]} strokeWidth={.5} stroke={'rgba(0,0,0,0.3)'}/>

            <Arrow
              ref={this.ropeForceArrowRef}
              points={[0, 0, -50, 0]}
              strokeWidth={2}
              pointerWidth={4}
              pointerLength={4}
              stroke={"black"}
              fill={"black"}
            />
            <Arrow
              ref={this.forceOfGravityArrowRef}
              points={[0, 0, 0, 50]}
              strokeWidth={2}
              pointerWidth={4}
              pointerLength={4}
              stroke={"black"}
              fill={"black"}
            />
            <Arrow
              ref={this.centrifugalForceArrowRef}
              points={[0, 0, 50, 50]}
              strokeWidth={2}
              pointerWidth={4}
              pointerLength={4}
              stroke={"blue"}
              fill={"blue"}
              visible={this.state.pendulumSystem}
            />
            <Arrow
              ref={this.sumForceArrowRef}
              points={[0, 0, 50, 50]}
              strokeWidth={2}
              pointerWidth={4}
              pointerLength={4}
              stroke={"green"}
              fill={"green"}
            />
          </Group>
        </Group>

        <Group x={630} y={450}>
          <Indicator
            data={this.indicatorData}
            progressX={220}
            indicatorName={'pendulumIndicator'}
          />
        </Group>
      </Group>
    )
  };

  CanvasButtons = () => {

    const buttonsSize = {
      width: 200,
      height: 60
    };
    const color = '#b1b3b2';
    return (
      <Group x={300} y={620}>

          <Group
            onClick={() => this.startResetOnClick()}
            onTap={() => this.startResetOnClick()}
          >
            <Rect {...buttonsSize} fill={color} cornerRadius={10} />
            <Text ref={this.startBtnRef} text={'Отпустить'} stroke={'white'} fill={'white'} strokeWidth={.5} fontSize={24} align={'center'} verticalAlign={'middle'} {...buttonsSize}/>
          </Group>

          <Group y={-50} x={100}>
            <Line points={[-200, 0, 200, 0]} stroke={color} strokeWidth={4}/>

            <Circle
              x={200}
              ref={this.rangeControllerRef}
              draggable
              radius={10}
              fill={'#10428e'}
              dragBoundFunc={this.dragBoundFunc}
              onDragMove={this.onDragMove}
            />
          </Group>
      </Group>
    )
  };

  render() {
    const additionalProps = this.state.pendulumSystem ? {
      x: 500, y: 350,
      offsetX: 500, offsetY: 350,
    } : {};
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} {...additionalProps}>
          <Layer >
            <this.CanvasPendulum/>
            {/*<this.CanvasPendulum pendulumContainerRef={this.pendulumContainerRef} x={500} offsetX={500} />*/}
            <this.CanvasButtons/>
          </Layer>
        </CanvasContainer>
        <div style={{position: 'absolute', right: 10, width: 90, zIndex: 100}}>
          <input
            id={'check_id'}
            style={{marginRight: 5}}
            type={'checkbox'}
            onChange={(e) => { console.log('debug on click', e.target.checked, this.state.pendulumSystem); this.setState({pendulumSystem: e.target.checked}); return false;}}
            defaultChecked={this.state.pendulumSystem}
          />
          <label htmlFor={'check_id'}>Перейти в систему отсчета маятника</label>
        </div>
      </div>
    )
  }
}

export default Pendulum;

const styles = {
  mainContainer: {
    background: '#F1FAEE',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
};
