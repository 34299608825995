import React from "react";
import { layout2 } from "../../../../utils/styles";

import "../styles/subscriptionModal.css";

import unlock from "../../../../platformImages/layout2/subscriptionModal/unlock.png"
import check from "../../../../platformImages/layout2/subscriptionModal/check.png"
import buyBlock from "../../../../platformImages/layout2/subscriptionModal/buyBlock.png"

import axios from 'axios';
import { SUBSCRIBE } from "../../../../store/urls";

const styles = {
  premiumModal: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  premiumModalContent: {
    display: 'flex',
    flexDirection: 'column',
    // width: '80%',
    width: '711px',
    zIndex: 101,
    backgroundColor: layout2.blueBg,
    color: layout2.blue,
  },
  buyBlock: {
    backgroundImage: `url(${buyBlock})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '225px',
    width: '711px',
    padding: '100px 0 0 400px'
  },
  button: {
    backgroundColor: layout2.yellow,
    color: 'black',
    border: 'none',
    padding: '10px 15px',
    alignSelf: 'flex-end'
  },
  valuesList: {
    listStyleType: 'none',
  },
  sublist: {
    marginLeft: '30px'
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

  }
};


export const SubscriptionModal = ({showPremium, setShowPremium}) => {

  const subscribe = async () => {
    await axios.post(SUBSCRIBE, {action: 'subscribe'});
    setShowPremium(false);
  };

  return (
    <div style={{...styles.premiumModal, display: showPremium ? 'flex' : 'none'}} onClick={()=>setShowPremium(false)}>
      <div style={styles.premiumModalContent} onClick={(e) => e.stopPropagation()}>
        <div style={styles.titleBlock}>
          <img src={unlock} alt={'Unlock'} style={{margin: 25}}/>
          <h2 style={{color: layout2.blue}}>Преимущества подписки:</h2>
        </div>

        <ul className="valuesList" style={styles.valuesList}>
          <li>
            <img  src={check} />
            <div> Доступ к 100% материалов, включая:
              <ul style={styles.sublist}>
                <li>Вычислительные задачи</li>
                <li>Эксперименты</li>
                <li>Задачи повышенной сложности</li>
              </ul>
            </div>
          </li>
          <li>
            <img src={check} />
            <div>Контроль выполнения задач учениками</div>
          </li>
          <li>
            <img src={check} />
            <div>
              Возможность подключить до 100 учеников, которые так же могут иметь полный доступ к материалам
            </div>
          </li>
        </ul>
        <div style={styles.buyBlock}>
          <div>
            <span style={{fontSize: 24}}>
              <span style={{textDecoration: 'line-through', color: layout2.grayBg}}>2900</span> 300
            </span> руб./месяц
          </div>
          <button onClick={() => subscribe()} style={styles.button}>Оформить подписку</button>
        </div>
        <div style={{margin: 25}}>
          <div>
            Вы сможете отменить подписку в течение месяца с полным возвратом средств, если вас не устроит качество материалов
          </div>
          <div>
            <a href={'https://app.inventic.tech/legal-docs/oferta.pdf'}>полный текст оферты</a>
          </div>
          <div>
            <a href={'https://inventic.tech/privacy'}>политика конфиденциальности</a>
          </div>
        </div>
      </div>
    </div>)
};
