import React from "react";
import CanvasContainer, {height} from "../../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, RegularPolygon, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import {layout2, mainColor} from "../../../../../utils/styles";
import triangle2Img from "../../../../../images/gearRatios/triangle2.png";
import stick2Img from "../../../../../images/gearRatios/scaleStick2.png";
import {element} from "prop-types";
import {hasIntersection, sendSuccessForScenario} from "../../../../../utils/common";
import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import * as actions from "../../../../../store/actions";
import ScenarioManager from "../../../../../utils/ScenarioManager";



class MomentRules extends React.Component {
  constructor(props) {
    super(props);

    this.weights = [
      {weight: 1, height: 40, fill: mainColor},
      {weight: 1, height: 40, fill: mainColor},

      {weight: 2, height: 47, fill: layout2.orange},
      {weight: 2, height: 47, fill: layout2.orange},

      {weight: 3, height: 60, fill: layout2.green},
      {weight: 3, height: 60, fill: layout2.green},

      {weight: 4, height: 75, fill: layout2.gray},
      {weight: 4, height: 75, fill: layout2.gray},

      {weight: 5, height: 93, fill: layout2.lightOrange},
      {weight: 5, height: 93, fill: layout2.lightOrange},
    ];

    this.managedComponents = [
      'stage',

      'stick',
      'points',

      'formula1',
      'formula2',
    ]

    this.pointsCount = 10;
    this.points = Array(this.pointsCount)
      .fill(1)
      .map((el, i) => {
        const id = i+1;
        const side = id > 5 ? 'r' : 'l';
        const refKey = `point${id}`;
        this.managedComponents.push(refKey);
        this.managedComponents.push(`${refKey}Box`);
        return { id: id, side: side, refKey}
      });

    this.weights.forEach((item, i) => {
      const weightId = `${item.weight}${i}`;
      this.managedComponents.push(`weight${weightId}`);
      this.managedComponents.push(`weight${weightId}Box`);
      this.managedComponents.push(`weightForceNum${weightId}`);
      this.managedComponents.push(`weightLeverNum${weightId}`);
      this.managedComponents.push(`weightLeverVal${weightId}`);
      this.managedComponents.push(`weightDescr${weightId}`);
      this.managedComponents.push(`weight${weightId}DefaultPlace`);
    })

    this.staticData = {
      maxAngle: 7,
      minAngle: -7
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      dragging: false,

      rotationAngle: 0,

      leftMomentum: 0,
      rightMomentum: 0,
      formula1BodyArr: [],
      formula2BodyArr: [],

      addedWeights: [] // { itemId: '', weightId: '', weightVal: '', side: '', lever: '',}
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.resetAllWeightPos();
  };

  resetAllWeightPos = () => {
    this.weights.forEach((item, i) => {
      this.setWeightDeFaultPos(`${item.weight}${i}`);
    })
  }
  setWeightDeFaultPos = (weightId) => {
    if (weightId) {
      const currWeight = this._getNode(`weight${weightId}`);
      const weightDefaultPlaceNode = this._getNode(`weight${weightId}DefaultPlace`);
      const weightDescr = this._getNode(`weightDescr${weightId}`);
      const defaultPlacePos = weightDefaultPlaceNode.getAbsolutePosition();
      const defaultPlaceWidth = weightDefaultPlaceNode.width();
      const weightWidth = currWeight.width();

      currWeight.setAbsolutePosition({
        x: defaultPlacePos.x + defaultPlaceWidth/2 - weightWidth/2, y: defaultPlacePos.y
      });
      weightDescr.visible(false);
    }
  }

  /**
   * ------- DRAG -------
   */
  dragBoundFunc = (pos) => {
    const leftBorderX = 20;
    const rightBorderX = 940;
    const topBorderY = 100;
    const bottomBorderY = 480;
    const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
    const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
    return {y: newY, x: newX};
  }
  onDragStart = (e) => {this.data.dragging = true;}
  onDragMove = (e) => {}
  onDragEnd = (e) => {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    data.dragging = false;

    const currWeight = e.target;
    const weightId = currWeight.attrs.weightId;
    const weightVal = currWeight.attrs.weightVal;
    const weightBoxNode = n[`weight${weightId}Box`];

    let anyIntersect = false;

    this.points.forEach((point, i) => {
      const id = i+1;
      const pointNode = n[`point${id}`];
      const pointBoxNode = n[`point${id}Box`];
      const intersect = hasIntersection(weightBoxNode, pointBoxNode, this);
      const side = pointNode.attrs.side;
      const pointLever = pointNode.attrs.pointLever;


      const pointAlreadyTaken = data.addedWeights.find((el) => el.side === side && el.lever === pointLever)

      // check any point intersection
      anyIntersect |= intersect && !pointAlreadyTaken;

      if (intersect && !pointAlreadyTaken) {

        const elData = {
          itemId: data.addedWeights.length+1,
          weightId: weightId,
          weightVal: weightVal,
          side: side,
          lever: pointLever,
        };

        // Update or add weight data
        const foundWeightData = data.addedWeights.find((el) => el.weightId === weightId);
        if (foundWeightData) {
          foundWeightData.side = side;
          foundWeightData.lever = pointLever;
        } else {
          data.addedWeights.push(elData);
        }
      }
    })

    // Remove weight from added data
    if (!anyIntersect) {
      data.addedWeights = data.addedWeights.filter((el) => el.weightId !== weightId);
    }
  }


  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;


    const {leftMomentum, rightMomentum} = this.data;
    const {maxAngle, minAngle} = this.staticData;


    let angleDelta = 0;
    let correctAngle = 0;

    const s = 30;

    if (leftMomentum > rightMomentum)
      angleDelta = (leftMomentum / s) / Math.PI * -180 * timedeltaSec;
    if (leftMomentum < rightMomentum)
      angleDelta = (rightMomentum / s) / Math.PI * 180 * timedeltaSec;

    // fix stick tremor if equal moment
    if (leftMomentum === rightMomentum && data.rotationAngle < 0)
      angleDelta = .1;
    if (leftMomentum === rightMomentum && data.rotationAngle > 0)
      angleDelta = -.1;

    if (data.rotationAngle >= maxAngle && leftMomentum < rightMomentum)
      correctAngle = maxAngle;
    else if (data.rotationAngle <= minAngle && leftMomentum > rightMomentum)
      correctAngle = minAngle;
    else if ((Math.round(data.rotationAngle) === 0) && (leftMomentum === rightMomentum)) {
      correctAngle = 0;
    } else {
      correctAngle = data.rotationAngle + angleDelta;
    }
    data.rotationAngle = correctAngle;




    // Update momentum data
    let newLeftMomentum = 0;
    let newRightMomentum = 0;
    let newFormula1BodyArr = [];
    let newFormula2BodyArr = [];
    this.weights.forEach((item, i) => {
      const weightId = `${item.weight}${i}`;
      const addedWeightData = data.addedWeights.find((el) => el.weightId === weightId);

      if (addedWeightData) {
        if (addedWeightData.side === 'l') {
          newFormula1BodyArr.push(`${addedWeightData.weightVal}*${addedWeightData.lever}`);
          newLeftMomentum += addedWeightData.weightVal*addedWeightData.lever;
        } else {
          newFormula2BodyArr.push(`${addedWeightData.weightVal}*${addedWeightData.lever}`);
          newRightMomentum += addedWeightData.weightVal*addedWeightData.lever;
        }
      }
    });
    data.leftMomentum = newLeftMomentum;
    data.rightMomentum = newRightMomentum;
    data.formula1BodyArr = newFormula1BodyArr;
    data.formula2BodyArr = newFormula2BodyArr;



    // update addedWeights index
    data.addedWeights.forEach((el, i) => {
      el.itemId = i+1;
    })

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    // show hide elem and update descr text
    this.weights.forEach((item, i) => {
      const weightId = `${item.weight}${i}`;
      const weightDescr = n[`weightDescr${weightId}`];
      const currWeight = n[`weight${weightId}`];
      const weightForceNum = n[`weightForceNum${weightId}`];
      const weightLeverNum = n[`weightLeverNum${weightId}`];
      const weightLeverVal = n[`weightLeverVal${weightId}`];
      const addedWeightData = data.addedWeights.find((el) => el.weightId === weightId);

      if (addedWeightData) {
        const isLeftSide = addedWeightData.side === 'l';
        const pointNode = n[`point${isLeftSide ? Math.abs(addedWeightData.lever-6) : addedWeightData.lever+5}`];

        weightForceNum.text(addedWeightData.itemId);
        weightLeverNum.text(addedWeightData.itemId);
        weightLeverVal.text(addedWeightData.lever);


        // set weight to point position if not dragging
        if (!data.dragging) {
          const pointPos = pointNode.getAbsolutePosition();
          const pointHeight = pointNode.height();
          const weightWidth = currWeight.width();

          currWeight.setAbsolutePosition({
            x: pointPos.x - weightWidth/2, y: pointPos.y - pointHeight/2
          });
        }
      } else {

        // set default position if not dragging
        !data.dragging && this.setWeightDeFaultPos(weightId);
      }
      weightDescr.visible(Boolean(addedWeightData));
    });

    n['formula1'].text(`М1 = ${data.formula1BodyArr.join(' + ') || 0} = ${data.leftMomentum}`);
    n['formula2'].text(`М2 = ${data.formula2BodyArr.join(' + ') || 0} = ${data.rightMomentum}`);


    n['stick'].rotation(data.rotationAngle);
    n['stick'].offsetX(+data.rotationAngle/1.5);

    n['stage'].draw();
  }

  CirclesPoints = () => {
    return (
      <>
        {this.points.map((el, i) => {
          const id = el.id;
          let posY = 0;
          let posX = 88.5 * id;
          let correctFill = 'white';
          const isLeftSide = el.side === 'l';
          return (
            <Group
              height={10} width={10}
              ref={this._ref(el.refKey)}
              side={el.side}
              pointLever={Math.abs(isLeftSide ? id-6 : id - 5)}
              key={`${el.side}${id}`}
              x={(isLeftSide ? 0 : 88) + posX} y={posY}
            >
              <Rect
                ref={this._ref(`${el.refKey}Box`)}
                x={-10} y={-10} opacity={0}
                width={20} height={20} fill={'black'}/>
              <Circle
                radius={7}
                fill={correctFill}
              />
            </Group>
          )
        })}
      </>
    )
  };


  Canvas = () => {

    const [stick] = useImage(stick2Img);
    const [basis] = useImage(triangle2Img);

    const peryshkin8 = this.props.code === 'peryshkin8';
    const CirclesPoints = this.CirclesPoints;
    return (
      <React.Fragment>
        <Group x={40} y={80} visible={peryshkin8}>
          <Text
            ref={this._ref('formula1')}
            width={440} align={'center'} text={'М1 = 1*2 + 2*1 + 3*1 + 3*2 + 4*3 + 5*4 = 12'} fontSize={25}/>
          <Text
            ref={this._ref('formula2')}
            x={460} width={440} align={'center'} text={'М2 = 1*2 + 2*1 + 3*1 + 3*2 + 4*3 + 5*4 = 12'} fontSize={25}/>
        </Group>

        <Group x={160} y={180}>
          <Group
            ref={this._ref('stick')}
            offsetY={90/2}
            x={332}
            y={53}
          >
            <Image image={stick} offsetY={90/2} offsetX={900/2} width={900} height={50} rotation={0}/>
            <Group x={-532} y={-2}>
              <CirclesPoints />
            </Group>
          </Group>

          <Image image={basis} y={10} x={300}/>
        </Group>
        <Group x={150} y={520}>
          {
            this.weights.map((item,i) => {
              const imgPath = require(`../../../../../images/momentRules/weight${item.weight}.png`)
              const [img] = useImage(imgPath);
              const txtStg = { y: 5, fontStyle: 'italic', fill: item.fill, fontSize: 20 };
              const arrowStg = { x: 3, points: [0,0,65,0], stroke: item.fill, fill: item.fill, scale: {x: .3, y: .3} };
              const weightId = `${item.weight}${i}`;
              return (
                <Group
                  x={i*70} y={-30-item.height}
                  key={`weight${item.weight}${i}`}
                >
                  <Rect
                    ref={this._ref(`weight${weightId}DefaultPlace`)}
                    width={11} height={11}
                    fill={'black'}
                    x={24} opacity={0}
                  />

                  <Group
                    width={60}
                    ref={this._ref(`weight${weightId}`)}
                    weightId={weightId}
                    weightVal={item.weight}

                    draggable
                    dragBoundFunc={(pos) => this.dragBoundFunc(pos)}
                    onDragStart={(e) => this.onDragStart(e)}
                    onDragMove={(e) => this.onDragMove(e)}
                    onDragEnd={(e) => this.onDragEnd(e)}
                  >
                    <Rect
                      ref={this._ref(`weight${weightId}Box`)}
                      x={15} y={-10} height={30} width={30} fill={'black'} opacity={0}/>
                    <Rect x={-10} y={-10} height={90 + item.height} width={80} fill={'red'} opacity={0}/>
                    <Image
                      height={item.height}
                      image={img}
                    />
                    <Group ref={this._ref(`weightDescr${weightId}`)} visible={false}>
                      <Group y={item.height + 10}>
                        {/*<Arrow {...arrowStg}/>*/}
                        <Text text={`F  = `} {...txtStg}/>
                        <Text
                          ref={this._ref(`weightForceNum${weightId}`)}
                          text={`1`} {...txtStg} x={10} y={15} fontSize={12}/>
                        <Text x={45} text={item.weight} {...txtStg} fontStyle={'normal'}/>
                      </Group>
                      <Group y={item.height + 40}>
                        <Text text={`l  = `} {...txtStg}/>
                        <Text
                          ref={this._ref(`weightLeverNum${weightId}`)}
                          text={`1`} {...txtStg} x={5} y={15} fontSize={12}/>
                        <Text
                          ref={this._ref(`weightLeverVal${weightId}`)}
                          x={40} text={0} {...txtStg} fontStyle={'normal'}/>
                      </Group>
                    </Group>
                  </Group>
                </Group>
              )
            })
          }
        </Group>

        <CanvasResetBtn onClick={() => this.onClickReset()}/>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MomentRules);

const styles = {
  mainContainer: {
    background: '#EDEBE5'
  }
};
