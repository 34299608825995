import React from "react";


export const LessonParagraphItem = ({paragraphItem, selectedParagraph, setSelectedParagraph}) => {
  const {title} = paragraphItem;
  const currParagraphSelected = paragraphItem === selectedParagraph;
  return (
    <div
      className={`side-bar__menu-item ${currParagraphSelected ? 'side-bar__paragraph--selected' : ''}`}
      onClick={() => setSelectedParagraph(currParagraphSelected ? undefined : paragraphItem)}
    >
      <div className={'side-bar__paragraph-title'}>{`§ ${title}`}</div>
    </div>
  )
};
