import React from "react";


export const spaceshipAsteroid1ElementsUI = {
  engineVisible: false,

  spaceshipTop: false,
  spaceshipLeft: false,
  spaceshipRight: false,

  shipStg: {x: -65, y: -10},
}

export const spaceshipAsteroid1Scenario = [

  {
    key: 'spaceship initial',
    patchData: {
      title: '',
      spaceshipLeft: true,
      arrowHintPointVisible: true,
    },
  },

  {
    key: 'spaceship bind left',
    patchData: {
      title: '',
      spaceshipLeft: true,
      shipStg: {x: -65, y: -10},
    },
  },

  {
    key: 'spaceship bind top',
    patchData: {
      title: '',
      spaceshipTop: true,
      shipStg: {x: -40, y: -55},
    },
  },

  {
    key: 'spaceship bind right',
    patchData: {
      title: '',
      spaceshipRight: true,
      shipStg: {x: 45, y: -13},
    },
  },
]
