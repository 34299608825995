import React from "react";
import Victor from "victor";
import Konva from "konva";
import { Layer, Line, Rect, Circle, Image as KonvaImage, Group, Text, RegularPolygon, } from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";

class Particle extends React.Component {

    constructor(props) {
        super(props);

        this.pstate = props.pstate;
        this.pos = props.pos;
        this.R = props.R;
        this.V = props.V;
        this.F = Victor(0, 0);
        this.M = 1;

        this.node = new Konva.Circle({
            x: this.pos.x,
            y: this.pos.y,
            radius: this.R,
            fill: props.fill,
        })

        this.const = {
            e: 1,
            sigma: 3,
            g: Victor(0, 0.03),
        }

        this.bond = [];
    }

    Collide(target) {

        const {e, sigma} = this.const;
        
        if ((!target instanceof Particle)) {
            console.log('Collide function called on object that is not particle');
        }

        if (this.pstate == 'gas') {

            if (target.pstate == 'gas') {

                //For gas-gas interaction we calculate only hard collisions
                this.HardCollision(target);

            }

            if (target.pstate == 'liquid') {

                if (this.HardCollision(target)) {
                    //Try condensing
                    if (Math.random() > 0.99) {
                        this.pstate = 'liquid';
                        this.node.setAttr('fill', 'red');
                    }
                }

            }

        }

        if (this.pstate == 'liquid') {

            if (target.pstate == 'gas') {

                if (this.HardCollision(target)) {
                    //Try condensing
                    if (Math.random() > 0.99) {
                        target.pstate = 'liquid';
                        target.node.setAttr('fill', 'red');
                    }
                }
            }

            if (target.pstate == 'liquid') {


                let r = this.pos.clone().subtract(target.pos);
                let distSq = r.lengthSq();

                this.V.subtract(this.V.clone().multiply(Victor(0.05, 0.05)));
                this.F.add(this.const.g);

                //For liquid-liquid interactions we define the distance of potential interaction
                if (distSq >= this.R**2*36) {
                    return;
                }
                //Then we check for overlap hence hard collision of particles. 
                if (this.HardCollision(target)) {
                    return;
                }
                //If no hard collision detected, we calculate potential interaction (attraction or repulsion)
                let dist = r.length();
                let n = r.clone().normalize();

                let A = 1; let K = -A/(3*this.R); let b = 5/3*A;
                let Fmod = 1*(K*dist + b);

                this.F.add(n.clone().multiply(Victor(Fmod, Fmod)));
                let k = 1*(Math.random() - 0.5);

                this.V.add(n.clone().multiply(Victor(k, k)));

            }


            
            //this.V.subtract(this.V.clone().multiply(Victor(0.1, 0.1)));


        }
        else if (target.pstate == 'solid') {
            return;
        }

        return;

    }

    HardCollision(target) {
        let r = this.pos.clone().subtract(target.pos);
            let distSq = r.lengthSq();

            if (distSq >= 4*this.R**2) {
                return false;
            }

            let dist = r.length();
            let n = r.clone().normalize();
            let D = 2*this.R - dist;

            let v1 = this.V.clone();
            let v2 = target.V.clone();

            let v1proj = v1.clone().dot(n);
            v1proj = n.clone().multiply(Victor(v1proj, v1proj));
            let v2proj = v2.clone().dot(n);
            v2proj = n.clone().multiply(Victor(v2proj, v2proj));

            let v1perp = v1.subtract(v1proj);
            let v2perp = v2.subtract(v2proj);

            let v1projnew = v2proj;
            let v2projnew = v1proj;

            let v1new = v1projnew.add(v1perp);
            let v2new = v2projnew.add(v2perp);

            this.V = v1new;
            target.V = v2new;

            let shift = n.clone().multiply(Victor(D/2, D/2));

            this.pos.add(shift);
            target.pos.add(shift.multiply(Victor(-1, -1)));

            return true;
    }

    CustomCollide(params) {
        const {wl, wr, wt, wb} = params;

        if (this.pos.x < wl) {
            this.pos.x = wl;
            if (this.V.x < 0) {
                this.V.x *= -1;
            }
        }
        if (this.pos.x > wr) {
            this.pos.x = wr;
            if (this.V.x > 0) {
                this.V.x *= -1;
            }
        }
        if (this.pos.y < wt) {
            this.pos.y = wt;
            if (this.V.y < 0) {
                this.V.y *= -1;
            }
        }
        if (this.pos.y > wb) {
            this.pos.y = wb;
            if (this.V.y > 0) {
                this.V.y *= -1;
            }
        }

        return;

    }

    Move(dT) {

        if (!dT) {dT = 1};

        let deltaT = Victor(dT, dT);
        let MVector = Victor(this.M, this.M);
        let dV = this.F.multiply(deltaT).divide(MVector);
        this.V.add(dV);
        this.pos.add(this.V);
        this.node.position({x: this.pos.x, y: this.pos.y});

        return;

    }
}

export default Particle;