import React, {useEffect, useState} from "react";
import StringHtmlRender from "../StringHtmlRender/StringHtmlRender";
import {Button, Col, Row, Space} from "antd";
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons";
import './theory.css';


const TheoryPage = ({selectedQuestion, selectQuestion}) => {
  const [selectedStep, setStep] = useState(null);

  useEffect(() => {
    setStep(selectedQuestion?.steps?.[0]);
  }, [selectedQuestion])

  const stepsList = selectedQuestion?.steps || [];
  const multipleSteps = stepsList.length > 1;
  const stepsLastIndex = stepsList.length-1 || 0;

  const currStepIndex = stepsList.findIndex((el) => el?.id === selectedStep?.id);
  const prevStepIndex = currStepIndex >= 1 ? currStepIndex-1 : 0;
  const nextStepIndex = currStepIndex < stepsLastIndex ? currStepIndex+1 : stepsLastIndex;

  return (
    <div className={'question-page'}>
      <div className={'question-page__body'}>
        <div className={'white-shadow-for-scroll question-page__top-shadow'}/>
        <StringHtmlRender htmlForRender={selectedStep?.content}/>
        <div className={'white-shadow-for-scroll question-page__bottom-shadow'}/>
      </div>
      <div className={'question-page__footer'}>
        <Row>
          <Col md={ multipleSteps ? 12 : 24}>
            <Button
              type={'ghost'}
              className={'theory__btn'}
              onClick={() => selectQuestion(null)}
            >
              К вопросам
            </Button>
          </Col>
          {
            multipleSteps && (
              <Col md={12} align={'end'}>
                <Space size={20}>
                  <Button
                    type={'primary'}
                    className={'theory__btn theory__btn--primary'}
                    onClick={() => setStep(stepsList[prevStepIndex])}
                    disabled={prevStepIndex === currStepIndex}
                  >
                    <CaretLeftOutlined /> Назад
                  </Button>
                  <Button
                    type={'primary'}
                    className={'theory__btn theory__btn--primary'}
                    onClick={() => setStep(stepsList[nextStepIndex])}
                    disabled={nextStepIndex === currStepIndex}
                  >
                    Вперед  <CaretRightOutlined />
                  </Button>
                </Space>
              </Col>
            )
          }
        </Row>
      </div>
    </div>
  )
}

export default TheoryPage;