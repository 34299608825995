import {_t} from '../../../utils/lang/common'

export const defaultPatchData = {
  // title: '',

  theoryVisible: false,
  onClickSkipTheory: (comp) => {},

  resetBtnVisible: false,
  goToTheoryBtnVisible: false,
  fillWithWaterBtnVisible: false,

  withHintArrow: false,
  cardHintArrowVisible: false,

  checkMarkVisible: false,

  fillTanksWater: false,
  fillTanksTubes: false,
  fillCityWater: false,

  draggable: false
}



export const getScenario = (code) => {
  let scenarioData = scenario(defaultPatchData);
  if (code === 'water_tower2') {
    scenarioData[0].patchData.title = _t('water_tower.Task_text1')
  }
  return scenarioData
}


export const scenario = (defaultPatchData) => [
  {
    key: 'initial drag hint',
    patchData: {
      ...defaultPatchData,
      title: 'Задача - построить одну или несколько водонапорных башен, \nкоторые обеспечат подачу воды на каждый этаж',
      cardHintArrowVisible: true,
      draggable: true
    },
    next_success: 'drag hint 2'
  },{
    key: 'drag hint 2',
    patchData: {
      ...defaultPatchData,
      withHintArrow: true,
      draggable: true
    },
    next_success: 'add water tower'
  },

  {
    key: 'add water tower',
    patchData: {
      ...defaultPatchData,
      draggable: true
    },
    next_success: 'complete water tower'
  },{
    key: 'complete water tower',
    patchData: {
      ...defaultPatchData,
      fillWithWaterBtnVisible: true,
      draggable: true
    },
    next_success: 'fill tanks water'
  },


  {
    key: 'fill tanks water',
    patchData: {
      ...defaultPatchData,
      fillTanksWater: true,
    },
    next_success: 'fill tubes water'
  },{
    key: 'fill tubes water',
    patchData: {
      ...defaultPatchData,
      fillTanksTubes: true,
    },
    next_success: 'fill city water'
  },

  {
    key: 'fill city water',
    patchData: {
      ...defaultPatchData,
      fillCityWater: true,
    },
    next_success: 'success',
    // next_failure: 'reset step'
  },

  {
    key: 'failure single small',
    patchData: {
      ...defaultPatchData,
      failureSingleSmall: true,
    },
    next_success: 'reset step',
    switch_at: 2
  },{
    key: 'failure double small',
    patchData: {
      ...defaultPatchData,
      failureDoubleSmall: true,
    },
    next_success: 'reset step',
    switch_at: 2
  },



  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        comp.scenarioManager.selectStepByKey('reset step');
      },
    },
    next_success: 'reset step'
  },

  {
    key: 'success',
    patchData: {
      ...defaultPatchData,
      title: _t('water_tower.Task_text2'),
      checkMarkVisible: true,
    },
    switch_at: 2
  },


  {
    key: 'reset step',
    patchData: {
      ...defaultPatchData,
      resetBtnVisible: true,
      goToTheoryBtnVisible: true,
    },
    next_success: 'reset step'
  },

]





