import React from "react";
import Konva from "konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Layer, Image as KonvaImage, Group, Text, Rect} from "react-konva";
import useImage from "use-image";
import chartPathBigImg from "../../../../images/pathandspeed/cars/chartPathBig.png";
import chartSpeedBigImg from "../../../../images/pathandspeed/cars/chartSpeedBig.png";
import carRedImg from "../../../../images/pathandspeed/cars/carRed.png";
import carGreenImg from "../../../../images/pathandspeed/cars/carGreen.png";
import carBlueImg from "../../../../images/pathandspeed/cars/carBlue.png";
import backgroundImg from "../../../../images/pathandspeed/cars/background.png";
import cloneDeep from "lodash.clonedeep";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {layout2} from "../../../../utils/styles";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import CanvasPlayBtn from "../../../canvas/components/CanvasPlayBtn";
import {capitalizeFirstLetter, sendSuccessForScenario} from "../../../../utils/common";
import ScenarioManager from "../../../../utils/ScenarioManager";


class PathandspeedCars extends React.Component {
  constructor(props) {
    super(props);
    this.carsItems = ['Red', 'Green', 'Blue'];
    this.managedComponents = [
      'stage',
      'sidePage',

      'charPathBtn',
      'charSpeedBtn',

      'chartPath',
      'chartSpeed',

      'carBlue',
      'carGreen',
      'carRed',
      'carBlueBtn',
      'carGreenBtn',
      'carRedBtn',

      'playBtn',
      'resetBtn',

      'scene',
      'roadMarks',
      'chartMarks',
    ];

    this.marks = this.carsItems.reduce((accum, k, i) => {
      accum[`cars${k}Marks`] = {};
      return accum
    }, {});

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      timedeltaSec: 0,
      carStartTime: 0,
      carTimeDeltaSec:0,

      start: false,
      carsEnd: false,

      charPathVisible: true,
      charSpeedVisible: false,

      carBlueVisible: false,
      carGreenVisible: false,
      carRedVisible: true,


      carBlueSpeed: 5, // m/s
      carGreenSpeed: 0, // m/s
      carRedSpeed: 20, // m/s
      carBlueOffsetY: 0,
      carGreenOffsetY: 0,
      carRedOffsetY: 0,

      timeCounter: 0,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  onClickStart = () => {
    this.data.start = true;
  }
  onClickReset = () => {
    this.data = {
      ...cloneDeep(this.initialData),
      charPathVisible: this.data.charPathVisible,
      charSpeedVisible: this.data.charSpeedVisible,
    };
    this.carsItems.forEach((key) => {
      this.marks[`cars${key}Marks`].group.destroy();
      this.marks[`cars${key}Marks`].groupPathChart.destroy();
      this.marks[`cars${key}Marks`].groupSpeedChart.destroy();
    })
  }

  setDefaultData = () => {
    this.carsItems.forEach((k) => {
      const corrKey = k.toLowerCase();
      let carGroupPos = {};
      let img = require(`../../../../images/pathandspeed/cars/${corrKey}Mark.png`)
      if (k === 'Blue') { carGroupPos = {x: 240, y: 555}; }
      if (k === 'Green') { carGroupPos = {x: 275, y: 555}; }
      if (k === 'Red') { carGroupPos = {x: 310, y: 555}; }

      const corrMarks = this.marks[`cars${k}Marks`];

      corrMarks.groupPathChart = new Konva.Group({x: 51, y: 400});
      corrMarks.groupSpeedChart = new Konva.Group({x: 51, y: 400});
      corrMarks.group = new Konva.Group(carGroupPos);
      corrMarks.image = new Image();
      corrMarks.image.src = img;
    })
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);

    this.setDefaultData();
    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  getCarSpeed = (carColor) => {
    const data = this.data;
    const corrColor = capitalizeFirstLetter(carColor);
    const time = Math.round(data.carTimeDeltaSec);
    const carSpeed = data[`car${corrColor}Speed`];

    if (corrColor === 'Red') { return 20 }

    if (corrColor === 'Green') {
      return carSpeed + data.timedeltaSec * 10;
    }

    if (corrColor === 'Blue') {
      // const correctSpeed = time < 3 ? 5 : time < 6 ? 35 : time < 9 ? 15 : 15;
      return time < 3 ? 5 : time < 6 ? 35 : time < 9 ? 15 : 15;
    }
  }
  getCarOffset = (carColor) => {
    const data = this.data;
    const corrColor = capitalizeFirstLetter(carColor);
    const carOffset = data[`car${corrColor}OffsetY`];
    const carSpeed = data[`car${corrColor}Speed`];
    // return data.carTimeDeltaSec*carSpeed/2;
    return carOffset + carSpeed * data.timedeltaSec;
  }

  addMarkToRoad = (color) => {
    const data = this.data;
    const layer = this._getNode('roadMarks');

    let mark = new Konva.Image({
      x: 0,
      y: -data[`car${color}OffsetY`],
      rotation: 0,
      image: this.marks[`cars${color}Marks`].image,
      draggable: false,
      visible: true,
    })
    const marks = this.marks[`cars${color}Marks`];
    const group = marks.group;
    group.add(mark);
    layer.add(group)
  }

  addMarkToChart = (color) => {
    const data = this.data;
    const layerChart = this._getNode('chartMarks');
    const marks = this.marks[`cars${color}Marks`];

    const groupPathChart = marks.groupPathChart;
    const groupSpeedChart = marks.groupSpeedChart;
    const groupPathChartNode = this._getNode('chartPath');
    const groupSpeedChartNode = this._getNode('chartSpeed');

    const currTime = data.carTimeDeltaSec;

    const markPathX = currTime * 29.6;
    const markPathY = -data[`car${color}OffsetY`] * 3 / 2;

    const markPathCorrY = markPathY > -300 ? markPathY : null;
    if (markPathCorrY !== null && currTime <= 10) {
      let markPathChart = new Konva.Image({
        x: markPathX,
        y: markPathCorrY,
        rotation: 0,
        image: this.marks[`cars${ color }Marks`].image,
        draggable: false,
        visible: true,
        width: 8, height: 8
      })
      groupPathChart.add(markPathChart);
      groupPathChartNode.add(groupPathChart);
      layerChart.add(groupPathChartNode)
    }



    const markSpeedCorrX = markPathX;
    const markSpeedY = -data[`car${color}Speed`]*6;
    const markSpeedCorrY = markSpeedY > -300 ? markSpeedY : null;

    if (markSpeedCorrY !== null && currTime <= 10) {
      let markSpeedChart = new Konva.Image({
        x: markSpeedCorrX,
        y: markSpeedCorrY,
        rotation: 0,
        image: this.marks[`cars${ color }Marks`].image,
        draggable: false,
        visible: true,
        width: 8, height: 8
      })
      groupSpeedChart.add(markSpeedChart);
      groupSpeedChartNode.add(groupSpeedChart);
      layerChart.add(groupSpeedChartNode)
    }
  };


  marksController = () => {
    const data = this.data;

    const stageNode = this.stageRef?.current;
    const stageHeight = stageNode.height();
    const currTime = Math.round(data.carTimeDeltaSec);

    if (Math.abs(data.carTimeDeltaSec - currTime) < 0.01) {
      this.carsItems.forEach((k) => {
        this.addMarkToRoad(k);
        this.addMarkToChart(k);
      });
      data.timeCounter = currTime;
    }
  }

  move = (time) => {

    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    let timedeltaSec = timedelta / 1000;

    if (timedeltaSec > 0.5) {
      timedeltaSec = 0.1;
    }
    this.data.timedeltaSec = timedeltaSec;

    if (data.start) {
      if (!data.carsEnd) {
        data.carStartTime = data.carStartTime || time;
        data.carTimeDeltaSec += timedeltaSec;

        this.carsItems.forEach((key) => {
          data[`car${key}Speed`] = this.getCarSpeed(key);
          data[`car${key}OffsetY`] = this.getCarOffset(key);
        })

        this.marksController();
      }
    } else {
      data.carStartTime = 0;
      data.carTimeDeltaSec = 0;
    }

    data.carsEnd = this.carsItems.every((key) => data[`car${key}OffsetY`] > 562);

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    const sidePageWidthSingle = 470;
    const sidePageXSingle = 100;
    const sidePageWidthDouble = 600;
    const sidePageXDouble = 0;
    let correctSPWidth = sidePageWidthSingle;
    let correctSPX = sidePageXSingle;
    let correctChartPathX = 0;

    const isDoubleCharts = data.charPathVisible && data.charSpeedVisible;
    if (isDoubleCharts) {
      correctSPWidth = sidePageWidthDouble;
      correctSPX = sidePageXDouble;
      correctChartPathX = -30
    }

    // console.log(n['stage'].height());

    n['sidePage'].width(correctSPWidth);
    n['sidePage'].x(correctSPX);

    let scale = 1;
    n['chartSpeed'].visible(data.charSpeedVisible);
    n['chartPath'].visible(data.charPathVisible);
    n['chartSpeed'].scale({x: scale, y: scale});
    n['chartPath'].scale({x: scale, y: scale});
    n['chartSpeed'].position({x: 0, y: 20});
    n['chartPath'].position({x: 0, y: 20});

    if (isDoubleCharts) {
      scale = .65;
      n['chartSpeed'].scale({x: scale, y: scale});
      n['chartSpeed'].position({x: 180, y: 100});
      n['chartPath'].scale({x: scale, y: scale});
      n['chartPath'].position({x: -110, y: 100});
    }


    this.carsItems.forEach((key) => {
      n[`car${key}`].visible(data[`car${key}Visible`]);
      n[`car${key}Btn`].opacity(data[`car${key}Visible`] ? 1 : .6);
      n[`car${key}`].offsetY(data[`car${key}OffsetY`]);
      this.marks[`cars${key}Marks`].group.visible(data[`car${key}Visible`]);
      this.marks[`cars${key}Marks`].groupPathChart.visible(data[`car${key}Visible`] && data.charPathVisible);
      this.marks[`cars${key}Marks`].groupSpeedChart.visible(data[`car${key}Visible`] && data.charSpeedVisible);
    })


    n['charPathBtn'].opacity(data.charPathVisible ? 1 : .6);
    n['charPathBtn'].x(correctChartPathX);
    n['charSpeedBtn'].opacity(data.charSpeedVisible ? 1 : .6);
    n['charSpeedBtn'].x(correctChartPathX+190);

    n['playBtn'].visible(!data.start);
    n['resetBtn'].visible(data.start);

    n['scene'].draw();
    n['roadMarks'].draw();
    n['chartMarks'].draw();
  }

  ChartImg = React.forwardRef(({x, y, chartImg}, ref) => {
    const [chart] = useImage(chartImg);
    return (
      <Group ref={ref} x={x} y={y}>
        <KonvaImage image={chart}/>
      </Group>
    )
  })

  Scene = () => {
    const data = this.data;

    const [background] = useImage(backgroundImg);
    const [carRed] = useImage(carRedImg);
    const [carGreen] = useImage(carGreenImg);
    const [carBlue] = useImage(carBlueImg);

    const carsSettings = {
      'carBlue': {img: carBlue, text: 'Кусочно-равномерное'},
      'carGreen': {img: carGreen, text: 'Неравномерно'},
      'carRed': {img: carRed, text: 'Равномерно'},
    }

    return (
      <React.Fragment>
        <Layer>
          <KonvaImage image={background}/>
        </Layer>

        <Layer ref={this._ref('roadMarks')}/>

        <Layer ref={this._ref('scene')}>
          <Group x={370} y={10}>
            <Rect
              ref={this._ref('sidePage')}
              width={600}
              height={540}
              fill={'#edebe5'}
            />

            <Group x={150} y={490}>
              <CanvasButton
                btnRef={this._ref('charPathBtn')}
                text={'График пути'}
                onClick={() => this.data.charPathVisible = !this.data.charPathVisible}
                fontSize={18}
                btnFill={layout2.darkBlue}
                height={35}
                width={170}
                strokeWidth={.2}
              />
              <CanvasButton
                btnRef={this._ref('charSpeedBtn')}
                x={200}
                text={'График скорости'}
                onClick={() => this.data.charSpeedVisible = !this.data.charSpeedVisible}
                fontSize={18}
                btnFill={layout2.darkBlue}
                height={35}
                width={170}
                strokeWidth={.2}
              />
            </Group>
          </Group>

          <Group x={238} y={530}>
            {
              Object.keys(carsSettings).map((key, i) => (
                <KonvaImage key={key} ref={this._ref(key)} x={i*35} image={carsSettings[key].img}/>
              ))
            }
          </Group>

          <Group x={10} y={400}>
            {
              Object.keys(carsSettings).reverse().map((key, i) => {
                let visible = key !== 'carBlue'; // todo скрыта Кусочно-равномерное
                return (
                  <Group y={i*50} key={key+i} visible={visible}>
                    <Rect fill={'white'} height={40} width={195}/>
                    <CanvasButton
                      btnRef={this._ref(key+'Btn')}
                      text={carsSettings[key].text}
                      onClick={() => this.data[key+'Visible'] = !this.data[key+'Visible']}
                      fontSize={18}
                      btnFill={layout2.darkBlue}
                      height={40}
                      width={195}
                      strokeWidth={.2}
                      align={'left'}
                      prefixY={7}
                      textX={40}
                      prefixImg={carsSettings[key].img}
                    />
                  </Group>
                )
              })
            }
          </Group>

          <Group x={10} y={350}>
            <CanvasPlayBtn
              ref={this._ref('playBtn')}
              onClick={() => this.onClickStart()}
              x={0} y={0}
            />
            <CanvasResetBtn
              ref={this._ref('resetBtn')}
              onClick={() => this.onClickReset()}
              x={0} y={0}
            />
          </Group>
        </Layer>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <this.Scene />

          <Layer ref={this._ref('chartMarks')} x={500}>
            <this.ChartImg
              x={50} y={400}
              chartImg={chartPathBigImg}
              ref={this._ref('chartPath')}
            />
            <this.ChartImg
              x={50} y={400}
              chartImg={chartSpeedBigImg}
              ref={this._ref('chartSpeed')}
            />
          </Layer>
        </CanvasContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PathandspeedCars);

const styles = {
  mainContainer: {
    height: "auto",
    // background: "#EFEBE4",
  },
};
