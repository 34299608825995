import React from "react";
import Konva from "konva";
import { Layer, Line, Rect, Circle, Text, Image as KonvaImage, Group } from "react-konva";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import useImage from "use-image"
import ChemistryScene from "../../../../images/chemistry/scene.png";
import LiquidImage from "../../../../images/chemistry/liquid.png";
import PointerImage from "../../../../images/chemistry/pointer.png";
import Victor from 'victor';

class Chemistry extends React.Component {

	constructor(props) {
			super(props);	
			this.stageRef = React.createRef();
			this.mainLayerRef = React.createRef();
			this.hideFormulaRef = React.createRef();
			this.arrowRef = React.createRef();
			this.liquidRef = React.createRef();
			this.bubbleGroupRef = React.createRef();
			this.textRef = React.createRef();
			this.pointerRef = React.createRef();
			this.started = false;
			this.stop = false;
			this.delay = 0;
	}

	componentDidMount() {
		const stage = this.stageRef?.current;
		stage.on('click', (e) => {
			console.log("pos: ", stage.getPointerPosition());
		})
		
	}


	animation = (time) => {

		const stageNode = this.stageRef?.current;

		if (!this.prevTime) {
			this.prevTime = time;
		}

		let timeDelta = time - this.prevTime;
		this.prevTime = time;


		{
		const hideFormula = this.hideFormulaRef?.current;
		let currentOpacity = hideFormula.getAttr('opacity');
		let newOpacity = currentOpacity - timeDelta/5000;
		if (newOpacity < 0) { newOpacity = 0; }
		hideFormula.setAttr('opacity', newOpacity);
		}

		{
		const liquid = this.liquidRef?.current;
		let currentOpacity = liquid.getAttr('opacity');
		let newOpacity = currentOpacity - timeDelta/5000;
		if (newOpacity < 0) { newOpacity = 0; }
		liquid.setAttr('opacity', newOpacity);
		}

		const arrow = this.arrowRef?.current;
		let currentRotation = arrow.getAttr('rotation');
		let newRotation = currentRotation - timeDelta/5000*180;
		if (newRotation < -180) { newRotation = -180; this.stop = true;}
		arrow.setAttr('rotation', newRotation);


		const bubbleGroup = this.bubbleGroupRef?.current;
		if (this.delay > 0) { this.delay -= 1; }
		else if (!(this.stop)) {
			let bubble = new Konva.Circle({
				radius: 4,
				fill: 'lightblue',
				stroke: 'blue',
				strokeWidth: 1,
				x: 165,
				y: 431,
				opacity: 0.5,
				V: Victor((Math.random()-0.5)*40, Math.random()*(463-431)),
				iter: 1,
			});

			bubbleGroup.add(bubble);

			this.delay = 6;
		}

			let bubbles = bubbleGroup.getChildren();
			for (let i = 0; i < bubbles.length; i++) {
				
				let currentSpeed = bubbles[i].getAttr('V');
				let currentIter = bubbles[i].getAttr('iter');
				let currentPosition = bubbles[i].position();
				if (currentPosition.y < 339) {
					bubbles[i].destroy();
					continue;
				}
				if (currentPosition.x > 165+60) {
					currentPosition.x = 165+60;
					currentSpeed.x = -currentSpeed.x;
				}
				else if (currentPosition.x < 165-60) {
					currentPosition.x = 165-60;
					currentSpeed.x = -currentSpeed.x;
				}
				let newSpeed = currentSpeed.add(Victor(0, -(2**(currentIter/10))));
				let newPosition = Victor(currentPosition.x, currentPosition.y).add(newSpeed.multiply(Victor(timeDelta/50, timeDelta/50)));
				bubbles[i].position({x: newPosition.x, y: newPosition.y});
				bubbles[i].setAttr('iter', currentIter+1);
			}
		

			if (bubbles.length === 0) {
				bubbleGroup.destroy();
				window.cancelAnimationFrame(this.requestId);
			}
			else {
				this.requestId = window.requestAnimationFrame(this.animation);
			}


		stageNode.draw();
		
	}

	onClick = (e) => {
		console.log('target: ', e.target);
		if (this.started) { console.log('X'); return; }
		this.started = true;

		const text = this.textRef?.current;
		text.setAttr('opacity', 0);

		const pointer = this.pointerRef?.current;
		pointer.setAttr('opacity', 0);

		window.requestAnimationFrame(this.animation);
	}


	Scene = () => {
		const {code} = this.props;
		
		const [scene] = useImage(ChemistryScene);
		const [liquid] = useImage(LiquidImage);
		const [pointer] = useImage(PointerImage);

		return (
			<React.Fragment>
				<Layer
					ref={this.mainLayerRef}>
						<KonvaImage
							image={scene}
							width={1000}
							height={590}
						/>

						<KonvaImage
							image={liquid}
							ref={this.liquidRef}
							x={144}
							y={340}

						/>

						<Rect
							ref={this.hideFormulaRef}
							x={600}
							y={325}
							width={900-600}
							height={520-325}
							fill={'#efebe5'}
							opacity={1}
						/>

						

						<Line
							ref={this.arrowRef}
							x={297}
							y={186}
							points={[0, 0, 0, -9]}
							stroke={'red'}
							strokeWidth={1}
						/>

						<Text
							ref={this.textRef}
							x={320}
							y={76}
							text={'Press\nto open\na gas tank'}
							fontSize={24}
							fill={'blue'}
							fontFamily={'Calibri'}
						/>

						<KonvaImage
							ref={this.pointerRef}
							x={287}
							y={99}
							height={55}
							width={30}
							image={pointer}
						/>
						<Rect
							ref={this.craneRef}
							x={273}
							y={71}
							width={422-279}
							height={164-68}
							onClick={this.onClick}
							onTouchStart={this.onClick}
						/>

						<Group
							ref={this.bubbleGroupRef}>

						</Group>
					
				</Layer>
			</React.Fragment>
		)
	};
	
	render() {
		return (
			<div style={styles.mainContainer}>
				<CanvasContainer stageRef={this.stageRef} tabIndex={1}>
					<this.Scene/>
				</CanvasContainer>
			</div>
		)
	}
}

export default Chemistry;
	
const styles = {
	mainContainer: {
		background: '#f4f1de',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
	}
}