import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ArrangeByColumns from "./ArrangeByColumns";
import boxImg from "../images/arrangeByColumns/box.png";
import wheelImg from "../images/arrangeByColumns/wheel.png";
import rampImg from "../images/arrangeByColumns/ramp.png";

import task2Img1 from "../images/arrangeByColumns/task2Ramp1.png";
import task2Img2 from "../images/arrangeByColumns/task2Ramp2.png";
import task2Img3 from "../images/arrangeByColumns/task2Ramp3.png";


const Example2Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const columns1 = [
    {id: 1, successAnswerId: 2, title: 'Simple machine'},
    {id: 2, successAnswerId: 3, title: 'Ramp'},
    {id: 3, successAnswerId: 1, title: 'Not a simple machine'},
  ];
  const dragItems1 = [
    {id: 1, image: boxImg},
    {id: 2, image: wheelImg},
    {id: 3, image: rampImg},
  ];

  const columns2 = [
    {id: 1, successAnswerId: 3, title: 'The first ramp'},
    {id: 2, successAnswerId: 1, title: 'The second ramp'},
    {id: 3, successAnswerId: 2, title: 'The third ramp'},
  ];
  const dragItems2 = [
    {id: 1, image: task2Img1},
    {id: 2, image: task2Img2},
    {id: 3, image: task2Img3},
  ];

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={2}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      {
        completedTaskCount < 1 ? (
          <ArrangeByColumns
            key={'1'}
            title={'All titles are false. Move the pictures'}
            columns={columns1}
            dragItems={dragItems1}
            taskComplete={() => {
              setCompletedTaskCount(prevState => prevState + 1)
            }}
          />
        ) : completedTaskCount >= 1 ? (
          <ArrangeByColumns
            key={'2'}
            title={'Arrange ramps in the following order: one which helps to lift a weight to the highest height near one which is the easiest to lift a weight. One which is the easiest to lift a weight is the last.'}
            titleFontSize={'2.7em'}
            columns={columns2}
            dragItems={dragItems2}
            taskComplete={() => {
              setAllComplete(true);
              setCompletedTaskCount(prevState => prevState + 1)
            }}
          />
        ): null
      }
    </TaskMainContainer>
  )
}
export default Example2Task;
