import Victor from "victor";
import {scale, scaleToKm} from "./utils";

const ballSpeedCoeff = 5;

export const movePirates1 = ($this) =>
{
  const {resultV, cannonSpeedV, ballSpeedV} = $this.getDefaultData();
  const {shipTimeDelta, ballTimeDelta} = $this.data;
  const resultVX = -(resultV.x*10) * shipTimeDelta;
  const cannonSpeedVX = -(cannonSpeedV.x*10) * shipTimeDelta;
  const ballSpeedVY = -ballSpeedV.y * ballSpeedCoeff * ballTimeDelta;
  let shipPos = new Victor( resultVX,  resultV.y * shipTimeDelta);
  let cannonPos = new Victor( cannonSpeedVX,  cannonSpeedV.y * shipTimeDelta);
  let ballPos = new Victor( resultVX,  ballSpeedVY);

  $this.data = {
    ...$this.data,
    shipPos,
    cannonPos,
    ballPos,
  }
};


export const movePirates2 = ($this) =>
{
  const {shipTimeDelta, ballTimeDelta} = $this.data;
  const {resultV, ballSpeedV} = $this.getDefaultData();

  let shipPos = new Victor( +resultV.x * shipTimeDelta,  resultV.y * shipTimeDelta);
  let ballPos = new Victor( +ballSpeedV.x * ballTimeDelta,  ballSpeedV.y * ballSpeedCoeff * ballTimeDelta);


  $this.data = {
    ...$this.data,
    shipPos,
    ballPos
  }

};


export const movePirates3 = ($this) =>
{
  const {shipTimeDelta, ballTimeDelta} = $this.data;
  const {resultV, ballSpeedV} = $this.getDefaultData();

  let shipPos = new Victor( (+resultV.x) * shipTimeDelta,  resultV.y * shipTimeDelta);
  let ballPos = new Victor( +resultV.x * shipTimeDelta,  -ballSpeedV.y *  ballSpeedCoeff * ballTimeDelta);


  $this.data = {
    ...$this.data,
    shipPos,
    ballPos
  }
};

export const moveWater = ($this) => {
  const {shipTimeDelta} = $this.data;
  const {flowSpeedV} = $this.getDefaultData();

  const waterPatternOffsetScaled = scale(flowSpeedV.clone().multiply(new Victor(shipTimeDelta, shipTimeDelta)));

  $this.data = {
    ...$this.data,
    waterPatternOffsetScaled
  }
};