import levelImg1 from './images/level-1-anim.gif';
import levelImg2 from './images/level-2-anim.gif';
import levelImg3 from './images/level-3-anim.gif';
import levelImg4 from './images/level-4-anim.gif';
import levelImg5 from './images/level-5-anim.gif';
import levelImg6 from './images/level-6-anim.gif';


export const levels = [
  {id: 1, img: levelImg1, level: 1, color: '#C73D22', startPoints: 0, maxPoints: 1000},
  {id: 2, img: levelImg2, level: 2, color: '#F4C242', startPoints: 1000, maxPoints: 3000},
  {id: 3, img: levelImg3, level: 3, color: '#1C3169', startPoints: 3000, maxPoints: 6000},
  {id: 4, img: levelImg4, level: 4, color: '#6D8D42', startPoints: 6000, maxPoints: 10000},
  {id: 5, img: levelImg5, level: 5, color: '#A7C8F9', startPoints: 10000, maxPoints: 15000},
  {id: 6, img: levelImg6, level: 6, color: '#5840C5', startPoints: 15000, maxPoints: 20000},
]
