import React, {useState} from "react";
import cloneDeep from "lodash.clonedeep";
import useImage from "use-image";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {GIF} from "../../../canvas/components/GIF";
import {Layer, Group, Image, Rect, Text, Circle, Line, Arrow} from "react-konva";
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";

import btnTopImg from '../../../../images/space/spaceship_asteroid_1/btnTop.png';
import btnDownImg from '../../../../images/space/spaceship_asteroid_1/btnDown.png';

import spaceshipImg from '../../../../images/space/spaceship_asteroid_1/spaceship.png';
import engineImg from '../../../../images/space/spaceship_asteroid_1/engine.png';

import pointItemImg from '../../../../images/space/spaceship_asteroid_1/pointItem.png';
import asteroidImg from '../../../../images/space/spaceship_asteroid_1/asteroid.png';

import spaceBackgroundImg from '../../../../images/space/spaceBackground.png';

import planeta1Img from '../../../../images/space/spaceship_asteroid_1/planeta1.png';
import planeta2Img from '../../../../images/space/spaceship_asteroid_1/planeta2.png';
import planeta3Img from '../../../../images/space/spaceship_asteroid_1/planeta3.png';
import planeta4Img from '../../../../images/space/spaceship_asteroid_1/planeta4.png';
import planeta5Img from '../../../../images/space/spaceship_asteroid_1/planeta5.png';
import planeta6Img from '../../../../images/space/spaceship_asteroid_1/planeta6.png';
import planeta7Img from '../../../../images/space/spaceship_asteroid_1/planeta7.png';

import {getTimeDeltaSec} from "../../../../utils/common";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import {spaceshipAsteroid1ElementsUI, spaceshipAsteroid1Scenario} from "../utils/spaceshipAsteroid_1_Scenario";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {layout2} from "../../../../utils/styles";
import ArrowHint from "../../../canvas/components/ArrowHint";
import Victor from "victor";
import ForceController from "../components/ForceController";
import Asteroid from "../components/Asteroid";
import AsteroidPointItem from "../components/AsteroidPointItem";
import * as actions from "../../../../store/actions";






class SpaceshipAsteroid1 extends React.Component {
  constructor(props) {
    super(props);

    this.staticData = {
      maxForcePx: 300, // px
      maxForce: 5000, // H
      forceStep: 500, // H
    }

    this.managedComponents = [
      'stage',

      'forceText',
      'forceArrow',
      'forceArrowText',

      'arrowHintPoint',
      'arrowHintForce',

      'engine',

      'spaceship',
      'spaceshipWithAsteroid',
    ];

    this.elementsUI = spaceshipAsteroid1ElementsUI;
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timeDeltaSec: undefined,

      arrowHintPointVisible: false,
      arrowHintForceControllerVisible: false,

      force: 0,

      spaceshipWithAsteroidV: new Victor(0, 0),
      spaceshipWithAsteroidRotation: 0,
      spaceshipWithAsteroidRotationSpeed: 0,
      spaceshipWithAsteroidPos: new Victor(500, 290),

      ...this.elementsUI,
    }
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(spaceshipAsteroid1Scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
  }

  forceToPx = () => {
    return this.data.force / this.staticData.maxForce * this.staticData.maxForcePx;
  };

  resetData = () => {
    this.scenarioManager.selectStepByKey('spaceship bind left');
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };
  onClickReset = () => {
    this.resetData();
    this.scenarioManager.resetScenario();
  };

  getCorrectEngineStyle = () => {
    if (!this.data.force) {
      return {width: 0, height: 0}
    }
    const firstStep = { x: -135, y: -15, width: 100, height: 30 };
    const secondStep = { x: -160, y: -20, width: 100, height: 40 };
    const thirdStep = { x: -175, y: -25, width: 100, height: 50 };

    const percent =  this.data.force / this.staticData.maxForce * 100;
    return (
      percent > 70 ?
        thirdStep
        : percent > 30 ?
        secondStep
        :
        firstStep
    )
  };

  get spaceshipWithAsteroidPos() {
    const data = this.data;
    return data.spaceshipWithAsteroidPos.clone().add(data.spaceshipWithAsteroidV.clone().multiply(new Victor(data.timeDeltaSec, data.timeDeltaSec)));
  }

  get spaceshipWithAsteroidRotation() {
    const data = this.data;
    return data.spaceshipWithAsteroidRotation + data.spaceshipWithAsteroidRotationSpeed * data.timeDeltaSec;
    return 0;
  }

  applyForce = () => {
    const data = this.data;
    const coeff = 0.03;
    const val = data.force * coeff * data.timeDeltaSec;

    let forceVictor = new Victor(1,0);
    let rotationAcceleration = 0;
    if (data.spaceshipRight) {
      forceVictor = new Victor(-1,0);
      rotationAcceleration = 0;
    }
    if (data.spaceshipTop) {
      forceVictor = new Victor(0.5, 0);
      rotationAcceleration = 2;
    }

    // линейное ускорение
    const direction = forceVictor.rotateDeg(data.spaceshipWithAsteroidRotation);
    data.spaceshipWithAsteroidV = data.spaceshipWithAsteroidV.clone().add(direction.multiply(new Victor(val, val)));

    // вращение
    data.spaceshipWithAsteroidRotationSpeed += rotationAcceleration * data.timeDeltaSec;
  };

  onClickController = (direction) => {
    const data = this.data;
    const staticData = this.staticData;
    const forceStep = staticData.forceStep;
    let newVal = data.force;
    data.arrowHintPointVisible = false;
    data.arrowHintForceControllerVisible = false;
    switch (direction) {
      case 'top':
        newVal += forceStep;
        break;
      case 'down':
        newVal -= forceStep;
        break;
    }

    if (newVal >= staticData.maxForce) {
      newVal = staticData.maxForce;
    }
    if (newVal <= 0) {
      newVal = 0;
    }

    this.data.force = newVal;
  }

  setInitialPos = () => {
    const data = this.data;
    data.spaceshipWithAsteroidV = this.initialData.spaceshipWithAsteroidV;
    data.spaceshipWithAsteroidPos = this.initialData.spaceshipWithAsteroidPos;
    data.force = 0;
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timeDeltaSec = getTimeDeltaSec(timedelta);

    // data.spaceshipWithAsteroidV = this.spaceshipWithAsteroidV;
    this.applyForce();
    data.spaceshipWithAsteroidRotation = this.spaceshipWithAsteroidRotation;
    data.spaceshipWithAsteroidPos = this.spaceshipWithAsteroidPos;

    this.updateStage();
  };


  updateStage() {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    const left = data.spaceshipLeft;
    const top = data.spaceshipTop;
    const right = data.spaceshipRight;

    const forcePx = this.forceToPx();
    let forceArrowTextWidth = forcePx;
    let forceArrowTextHeight = 60;
    let forceArrowTextX = 0;
    let forceArrowTextY = -60;
    let arrowPoints = [0,0,forcePx,0];
    let shipRotation = 0;

    if (right) {
      arrowPoints = [0,0,-forcePx,0];
      shipRotation = 180;
      forceArrowTextX = -forcePx;
    }

    n['spaceshipWithAsteroid'].setAttrs({
      rotation: data.spaceshipWithAsteroidRotation,
      position: data.spaceshipWithAsteroidPos,
    });

    n['forceArrowText'].setAttrs({
      width: forcePx ? forceArrowTextWidth : 0,
      height: forcePx ? forceArrowTextHeight : 0,
      x: forceArrowTextX,
      y: forceArrowTextY,
    });

    n['forceArrow'].points(arrowPoints);
    n['spaceship'].rotation(shipRotation);



    n['arrowHintPoint'].visible(data.arrowHintPointVisible);
    n['arrowHintForce'].visible(data.arrowHintForceControllerVisible);

    n['forceText'].text(`F = ${data.force} H`);


    n['engine'].setAttrs(this.getCorrectEngineStyle());


    n['stage'].draw();
  }


  Env = (props) => {
    const data = this.data;

    return (
      <Group {...props}>

        <ArrowHint
          ref={this._ref('arrowHintPoint')}
          imgScaleX={-1}
          rotation={180}
          color={'white'}
          x={570}
          y={270}
          fontSize={16}
          textX={0} textY={-70}
          text={'Нажми для изменения \nточки приложения силы'}
        />

        <ArrowHint
          ref={this._ref('arrowHintForce')}
          imgScaleX={1}
          rotation={180}
          color={'white'}
          x={170}
          y={430}
          fontSize={16}
          textX={0} textY={-70}
          arrowX={170} arrowY={10}
          text={'Нажми для изменения \nчислового значения силы'}
        />




        <Group x={50} y={520}>
          <Arrow points={[0,0,150,0]} stroke={'white'} fill={'white'} strokeWidth={1}/>
          <Text text={'0'} align={'left'} fill={'white'}  width={150} height={35} fontSize={15} verticalAlign={'middle'}/>
          <Text text={'X'} align={'right'} fill={'white'} width={150} height={35} fontSize={15} verticalAlign={'middle'}/>
        </Group>
      </Group>
    )
  }

  Spaceship = (props) => {
    const data = this.data;
    const [spaceship] = useImage(spaceshipImg);
    const [engine] = useImage(engineImg);

    return (
      <Group {...props}>

        <Group ref={this._ref('spaceship')}>
          <Image
            ref={this._ref('engine')}
            image={engine}
            width={100} height={50}
          />
          <Image image={spaceship} scale={{x: .2,y: .2}} offset={{x: 248 / 2, y: 0}} rotation={90}/>
        </Group>

        <Group y={0} x={5}>
          <Text
            ref={this._ref('forceArrowText')}
            align={'center'}
            verticalAlign={'middle'}
            y={0} text={'F'} width={50} fontSize={30} stroke={'white'} fill={'white'} strokeWidth={2}
          />
          <Arrow ref={this._ref('forceArrow')} points={[0,0,50,0]} fill={'white'} stroke={'white'} strokeWidth={7}/>
        </Group>
      </Group>
    )
  }


  AsteroidWithPoints = (props) => {
    const data = this.data;
    const onClickPoint = (pos) => {
      // Arrow hint visible logic
      if (data.arrowHintPointVisible && !data.arrowHintForceControllerVisible) {
        data.arrowHintForceControllerVisible = true;
      }
      data.arrowHintPointVisible = false;

      this.setInitialPos();

      this.scenarioManager.selectStepByKey(`spaceship bind ${pos}`)
    };

    const points = [
      {direct: 'top', x:20, y:7},
      {direct: 'left', x:-7, y:52},
      {direct: 'right', x:110, y:52}
    ];
    return (
      <Asteroid {...props} offset={{x: 70, y:70}}>
        {
          points.map((item) => {
            return (
              <AsteroidPointItem
                key={item.direct}
                x={item.x} y={item.y}
                scale={.7}
                pointItemImg={pointItemImg}
                onCLick={() => onClickPoint(item.direct)}
              />
            )
          })
        }
      </Asteroid>
    )
  }

  Background = (props) => {
    const [planeta1] = useImage(planeta1Img);
    const [planeta2] = useImage(planeta2Img);
    const [planeta3] = useImage(planeta3Img);
    const [spaceBackground] = useImage(spaceBackgroundImg);
    return (
      <Group {...props}>
        <Image image={spaceBackground} opacity={.5}/>

        <Group x={650} y={20}>
          <Circle x={137} y={137} radius={137} fill={'#2A3E57'}/>
          <Image image={planeta1} />
        </Group>
        <Group x={80} y={280}>
          <Circle x={60} y={60} radius={60} fill={'#2A3E57'}/>
          <Image image={planeta2} />
        </Group>
        <Group x={820} y={400}>
          <Circle x={40} y={40} radius={40} fill={'#2A3E57'}/>
          <Image image={planeta3} />
        </Group>
      </Group>
    )
  }

  render() {
    const data = this.data;

    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer>

            <this.Background />

            <Group  ref={this._ref('spaceshipWithAsteroid')} >
              <this.AsteroidWithPoints />
              <this.Spaceship {...data.shipStg} />
            </Group>


            <ForceController
              forceTextRef={this._ref('forceText')}
              x={350} y={450}
              onClickController={this.onClickController}
            />


            <this.Env x={0} y={0} />


            <CanvasButton
              x={660} y={510}
              text={'Обнулить параметры'}
              height={30}
              fontSize={14}
              fontStyle={'bold'}
              btnFill={layout2.orange}
              strokeWidth={.2}
              width={180}
              onClick={() => this.resetData()}
            />
            <CanvasResetBtn
              y={510}
              onClick={() => this.onClickReset()}
            />
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpaceshipAsteroid1);

const styles = {
  mainContainer: {
    background: '#2A3E57'
  }
};
