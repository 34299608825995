import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ChooseExtraItemTask from "./ChooseExtraItemTask";
import imgTrue from "../images/сhooseExtraItemTask/scarecrow_true.png";
import imgFalse from "../images/сhooseExtraItemTask/scarecrow_false.png";
import {getCorrectFormatOptions, getCorrectTaskComponentByType} from "../utils/tasksUtils";
import {serverMediaUrl} from "../../../../utils/api";


const SchoolTaskAdmin = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const container = props.lessonData.school_task_container;
  const tasks = (container?.tasks || []).sort((a,b) => a.order-b.order);

  return (
    <TaskMainContainer
      health={container?.health}
      lessonCode={props.lessonData.code}
      tasksCount={tasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      {
        tasks.map((taskData, i) => {
          const CorrectComponent = getCorrectTaskComponentByType(taskData.type);
          const correctOptionsProps = getCorrectFormatOptions(taskData.options, taskData.type);
          return (
            <React.Fragment key={`step${i}`}>
              {
                (completedTaskCount === (i) || ((i + 1) === container?.tasks.length && completedTaskCount >= container?.tasks.length)) ? (
                  <CorrectComponent
                    {...correctOptionsProps}
                    lang={container?.lang}
                    title={taskData.title}
                    bgImg={taskData.background ? serverMediaUrl + taskData.background : undefined}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === container?.tasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ):null
              }
            </React.Fragment>
          )
        })
      }
    </TaskMainContainer>
  )
}
export default SchoolTaskAdmin;
