import useImage from "use-image";
import btnTopImg from "../../../../images/space/spaceship_asteroid_1/btnTop.png";
import btnDownImg from "../../../../images/space/spaceship_asteroid_1/btnDown.png";
import {Group, Image, Text} from "react-konva";
import CanvasButton from "../../../canvas/components/CanvasButton";
import React from "react";


const ForceController = (props) => {

  const [btnTop] = useImage(btnTopImg);
  const [btnDown] = useImage(btnDownImg);
  const btnSize = 50;
  return (
    <Group {...props}>
      <Group>
        <CanvasButton
          x={0} y={-btnSize+10}
          onClick={() => props.onClickController('top')}
        >
          <Image width={btnSize} height={btnSize} image={btnTop}/>
        </CanvasButton>

        <CanvasButton
          x={0} y={btnSize-25}
          onClick={() => props.onClickController('down')}
        >
          <Image width={btnSize} height={btnSize} image={btnDown}/>
        </CanvasButton>
      </Group>

      <Text x={70} y={-10} ref={props.forceTextRef} text={'F = 0 H'} fill={'white'} stroke={'white'} strokeWidth={1} fontSize={60}/>
    </Group>
  )
}
export default ForceController;