import React, {useEffect, useRef} from "react";
import {Circle, Group, Image, Rect} from "react-konva";
import astronautBodyImg from '../../../../images/space/repulsion_of_bodies/astronautBody.png';
import astronautArmImg from '../../../../images/space/repulsion_of_bodies/astronautArm.png';
import wholeAstronautImg from '../../../../images/space/repulsion_of_bodies/wholeAstronaut.png';
import useImage from "use-image";



const Astronaut = React.forwardRef( (props, ref) => {
  const {
    getResetArmRotation,
    getArmPushes,
    withMovableArm,
  } = props;

  const astronautRef = useRef(null);
  const armRef = useRef(null);

  const [astronautArm] = useImage(astronautArmImg);
  const [astronautBody] = useImage(astronautBodyImg);
  const [wholeAstronaut] = useImage(wholeAstronautImg);

  const pushes = () => {
    const armNode = armRef?.current;
    const astronautNode = astronautRef?.current;
    if (armNode && astronautNode) {
      astronautNode.x(-13);
    }
  }
  const reset = () => {
    const armNode = armRef?.current;
    const astronautNode = astronautRef?.current;
    if (armNode && astronautNode) {
      astronautNode.x(0);
    }
  }

  useEffect(() => {
    getArmPushes && getArmPushes(() => pushes());
    getResetArmRotation && getResetArmRotation(() => reset());
  }, [])

  return (
    <Group x={props.x} y={props.y} ref={ref}>
      {
        withMovableArm ? (
          <Group scale={{x:.8, y:.8}}>
            <Circle x={120} y={102} radius={11} fill={'#DEE2EB'}/>
            <Image
              x={110} y={115}
              image={astronautArm}
              width={85} height={85}
              offset={{x: 0, y: 50}}
              rotation={0}
              ref={armRef}
            />
            <Image image={astronautBody} ref={astronautRef}/>

          </Group>
        ) : (
          <Image scale={{x:.85, y:.85}} image={wholeAstronaut}/>
        )
      }
    </Group>
  )
})

export default Astronaut;