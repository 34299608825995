import {getBoolCode} from "./utils";
import {Text} from "react-konva";
import React from "react";
import defaultCargoImg from "../../../../images/liftingLoadMultiple/cargo.png";
import pianoImg from "../../../../images/allLifting/piano.png";
import bricksImg from "../../../../images/allLifting/briks.png";
import concreteMixerImg from "../../../../images/allLifting/concreteMixer.png";
import bowlImg from "../../../../images/allLifting/bowl.png";
import {lsnTitleTxtStyle} from "../../../../utils/styles";
import {showFailOrSuccessPopup} from "../../../../utils/common";
import {_t} from "../../../../utils/lang/common";

export const initialData = {
  startTime: undefined,
  prevTime: undefined,

  withoutSelectingMethods: false,
  method1Visible: false,
  method2Visible: false,
  method3Visible: false,
  method4Visible: false,
  method5Visible: false,
  method6Visible: false,
  method7Visible: false,
  method8Visible: false,

  liftingSingleBlock: false,
  liftingDualBlock: false,
  liftingLeverHandle: false,
  liftingInclinedSurface: false,

  blockNameTxt: '',

  cargoImg: defaultCargoImg,

  titleData: {},
  liftingTriangleTxt: {
    visible: false,
    height: 10,
    width: 40
  },

  inputVal: undefined,

  dragLineActive: true,

  cargoHeight: 0,
  cargoHeightVisible: false,
  windingArrowHintVisible: false,

  cargoGravityTxt: true,

  windingBlockVisible: false,
  windingCircleVisible: true,
  withWindingRope: false,

  dragLineVisible: false,
  connectorLineVisible: false,
  cargoLineVisible: false,
  cargoLineSecondVisible: false,
  cargoLineThirdVisible: false,
  supportStandVisible: false,
  leverHandleVisible: false,
  checkCargoWeightVisible: false,
  ropeForceVisible: true,
  completeLsnVisible: false,

  questionCardSettings: {
    visible: false,
    card: {}, label: {}, btn: {}
  },

  wheel1ContainerVisible: false,
  dragHintVisible: false,

  ropeCoeff: .8,

  buildingHeight: 10,

  cargoX: 0,
  cargoY: 0,
  maxCargoY: undefined,
  maxCargoX: undefined,

  windingRope1Len: 0,
  windingRope2Len: 0,
  windingRope3Len: 0,

  dragLineX: 0,
  dragLineY: 0,

  wheel1Factor: undefined,
  wheel2Factor: undefined,
  wheel3Factor: undefined,
  wheel4Factor: undefined,
  windingWheelFactor: undefined,

  wheel4Visible: false,
  formulaVisible: false,
  withCalc: false,

  cargoLineLength: 302,
  draggedOutDelta: 0,

  draggedLeverHandleOutDelta: 0,

  maxLeverHandleY: 60,
  minLeverHandleY: -30,
  leverHandleRotation: 0,
  leverWidth: 150,
  leverForce: 100,
  dragLeverHandleX: 0,
  dragLeverHandleY: 0,
  cargo2Rotation: 0,

  cargoLineX: 472,

  triangleYOffsetCoeff: 1,

  dragging: false,

  triangleGrayVisible: false,

  triangleAngle: 13,
  triangleActionBlockStg: {
    visible: false,
  },
  liftingTriangleStg: {
    scale: {x: 1, y: 1},
  },

  methodsImgsStep: 120,
  buildHeight: 102,

  imgDir: 'allLifting',

  cargoWeight: 1000,

  cargoImgStg: {},
  leverVal: undefined,
  liftingDragMomentum: 0,
  triangleHintX: 640,

  methodsContainerScale: {x: 1, y: 1},

  lsnMainContainerScale: {x: 1, y: 1},
  lsnMainContainerY: 0,

  cargoWeightLetter: false,

  cargoInitialHintText: 'Подними груз, используя \nпростые механизмы',

  successRange: {start: undefined, end: undefined},
  resetBtnPrev: false,

  cargoName: '',

  blocksDragLineSuccessCallback: undefined,
  btnCheckSuccessCallback: (lsnComp) => {},

  withTime: false,
  draggable: true,

  cargosList: [],
  timeTxt: 0
};


const methodSettings = {
  fixedBlock: {
    cargoX: 23,
    maxCargoY: 260,
    ropeCoeff: .8,
    buildingHeight: 10,

    method1Visible: true,
    liftingSingleBlock: true,
    dragLineVisible: true,
    connectorLineVisible: true,
    cargoLineVisible: true,
    dragHintVisible: true,
    triangleGrayVisible: true,
    supportStandVisible: true,
  },
  doubleBlock: {
    cargoX: 13,
    maxCargoY: 260,
    ropeCoeff: .4,
    wheel1Factor: -.4,
    buildingHeight: 20,
    cargoLineLength: 282,

    method2Visible: true,
    liftingDualBlock: true,
    wheel1ContainerVisible: true,
    dragLineVisible: true,
    connectorLineVisible: true,
    cargoLineVisible: true,
    cargoLineSecondVisible: true,
    dragHintVisible: true,
    triangleGrayVisible: true,
    supportStandVisible: true,
  },
  tripleBlock: {
    cargoX: 13,
    maxCargoY: 260,
    ropeCoeff: .2,
    wheel4Factor: -.2,
    buildingHeight: 30,
    cargoLineLength: 282,

    method3Visible: true,
    liftingDualBlock: true,
    wheel1ContainerVisible: true,
    dragLineVisible: true,
    connectorLineVisible: true,
    cargoLineVisible: true,
    cargoLineSecondVisible: true,
    cargoLineThirdVisible: true,
    dragHintVisible: true,
    wheel4Visible: true,
    triangleGrayVisible: true,
    supportStandVisible: true,
  },
  ['triangle4:1']: {
    triangleActionBlockStg: {
      visible: true,
      x: 200,
      y: 300,
      width: 440,
      height: 230,
      fill: 'green',
      opacity: 0,
    },
    liftingTriangleTxt: {
      visible: true,

      height: {
        x: 0,
        y: 50,
        fontSize: 13,
        fill: 'white',
        fontStyle: 'bold',
        align: 'center',
        width: 23,

        value: 10,
      },

      width: {
        x: 150,
        y: 37,
        fontSize: 13,
        fill: 'white',
        fontStyle: 'bold',

        value: 40,
      },
    },
    triangleYOffsetCoeff: 3.4,
    liftingDragMomentum: 250,
    cargoX: 250, cargoY: 8,
    maxCargoX: 280,

    method4Visible: true,
    liftingInclinedSurface: true,
  },
  ['triangle5:1']: {

    triangleActionBlockStg: {
      visible: true,
      x: 200,
      y: 300,
      width: 440,
      height: 230,
      fill: 'green',
      opacity: 0,
    },
    liftingTriangleTxt: {
      visible: true,

      height: {
        x: 0,
        y: 50,
        fontSize: 13,
        fill: 'white',
        fontStyle: 'bold',
        align: 'center',
        width: 23,

        value: 10,
      },

      width: {
        x: 150,
        y: 37,
        fontSize: 13,
        fill: 'white',
        fontStyle: 'bold',

        value: 50,
      },
    },
    triangleYOffsetCoeff: 3.4,
    liftingDragMomentum: 250,
    cargoX: 250, cargoY: 8,
    maxCargoX: 280,

    method5Visible: true,
    liftingInclinedSurface: true,
  },
  ['lever6:1']: {
    leverVal: 6,
    cargoX: 23,
    maxCargoY: 260,
    wheel2Factor: -.1,
    lsnMainContainerScale: {x: .9, y: .9},
    lsnMainContainerY: 60,
    maxLeverHandleY: 30,
    leverWidth: 100,
    leverForce: 250,

    method6Visible: true,
    liftingLeverHandle: true,
    cargoLineVisible: true,
    supportStandVisible: true,
    leverHandleVisible: true,
  },
  ['lever7:1']: {
    leverVal: 7,
    cargoX: 23,
    maxCargoY: 260,
    wheel2Factor: -.1,
    lsnMainContainerScale: {x: .9, y: .9},
    lsnMainContainerY: 60,
    maxLeverHandleY: 30,
    leverWidth: 120,
    leverForce: 250,

    resetBtnPrev: true,
    method7Visible: true,
    liftingLeverHandle: true,
    cargoLineVisible: true,
    supportStandVisible: true,
    leverHandleVisible: true,
  },
  ['lever8:1']: {
    leverVal: 8,
    cargoX: 23,
    maxCargoY: 260,
    wheel2Factor: -.1,
    lsnMainContainerScale: {x: .9, y: .9},
    lsnMainContainerY: 60,
    maxLeverHandleY: 30,
    leverForce: 250,

    resetBtnPrev: true,
    method8Visible: true,
    liftingLeverHandle: true,
    cargoLineVisible: true,
    supportStandVisible: true,
    leverHandleVisible: true,
  },
}




export const getScenario = (code) => {
  const codes = getBoolCode(code);
  if (codes.peryshkin5) return liftingMultipleScenario;
  if (codes.peryshkin6) return liftingSingleScenario;
  if (codes.peryshkin15) return liftingBlocksScenario;
  if (codes.peryshkin22) return liftingTasksScenario;
  if (codes.peryshkin23) return liftingBlocksWindingLengthScenario();
  if (codes.peryshkin24) return liftingBlocksWorkPerformedScenario();
  if (codes.blocksRopeLen1) return BlocksRopeLenScenario();

  const scenarioWithTime = liftingWithTimeScenarioFn();
  if (codes.peryshkin25) return scenarioWithTime;
};
export const getMethodsListByCode = (code) => {
  const codes = getBoolCode(code);
  let methodsCount = 0;
  if (codes.peryshkin5) methodsCount = 4;
  if (codes.peryshkin6) methodsCount = 2;
  if (codes.peryshkin15) methodsCount = 3;
  if (codes.peryshkin22 || codes.peryshkin25) methodsCount = 8;
  if (codes.peryshkin23 || codes.peryshkin24 || codes.blocksRopeLen1) methodsCount = 3;

  return Array(methodsCount).fill(1).map((e, i) => i+1);
};

export const liftingMultipleScenario = [
  // --------- STEP 1 ----------
  {
    key: 'step1 initial',
    patchData: {
      ...initialData,
      imgDir: 'liftingLoadMultiple',
    },
    next_success: 'success',
    next_btn_hidden: true,
  },
  {
    key: 'method-1',
    patchData: {
      ...initialData,
      imgDir: 'liftingLoadMultiple',
      method1Visible: true,
      liftingSingleBlock: true,


      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      dragHintVisible: true,

      triangleGrayVisible: true,
      supportStandVisible: true,

      ropeForce: 1000,

      cargoX: 23,
      maxCargoY: 275,
    },
    previous: 'step1',
    next_success: 'success',
  },
  {
    key: 'method-2',
    patchData: {
      ...initialData,
      imgDir: 'liftingLoadMultiple',
      method2Visible: true,
      liftingDualBlock: true,

      wheel1ContainerVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      cargoLineSecondVisible: true,
      dragHintVisible: true,

      triangleGrayVisible: true,
      supportStandVisible: true,

      ropeCoeff: .4,

      ropeForce: 500,

      wheel1Factor: -.4,
      cargoX: 13,
      cargoLineLength: 282,
      maxCargoY: 255,
    },
    previous: 'step1',
    next_success: 'success',
  },
  {
    key: 'method-3',
    patchData: {
      ...initialData,
      method3Visible: true,
      imgDir: 'liftingLoadMultiple',

      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,

      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
    },
    previous: 'step1',
    next_success: 'success',
  },
  {
    key: 'method-4',
    patchData: {
      ...initialData,
      imgDir: 'liftingLoadMultiple',
      method4Visible: true,
      liftingInclinedSurface: true,
      cargoX: 250,
      cargoY: 8,

      maxCargoX: 300,
      liftingDragMomentum: 250,

      triangleYOffsetCoeff: 3.4,

      triangleActionBlockStg: {
        visible: true,
        x: 200,
        y: 300,
        width: 440,
        height: 230,
        fill: 'green',
        opacity: 0,
      }
    },
    previous: 'step1',
    next_success: 'success',
  },
  {
    key: 'success',
  }
];
export const liftingSingleScenario = [
  // --------- STEP 1 ----------
  {
    key: 'method-1',
    patchData: {
      ...initialData,
      method1Visible: true,
      liftingInclinedSurface: true,

      imgDir: 'liftingInclinedSurface',

      methodsImgsStep: 170,

      method4Visible: true,

      cargoX: 250,
      cargoY: 8,

      liftingDragMomentum: 250,
      maxCargoX: 300,

      triangleYOffsetCoeff: 3.4,

      triangleActionBlockStg: {
        visible: true,
        x: 200,
        y: 300,
        width: 440,
        height: 230,
        fill: 'green',
        opacity: 0,
      },

      liftingTriangleTxt: {
        visible: true,

        height: {
          x: 0,
          y: 50,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',
          align: 'center',
          width: 23,

          value: 10,
        },

        width: {
          x: 150,
          y: 37,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',

          value: 40,
        },
      },

    },
    next_success: 'success',
  },
  {
    key: 'method-2',
    patchData: {
      ...initialData,
      method2Visible: true,
      liftingInclinedSurface: true,

      imgDir: 'liftingInclinedSurface',
      methodsImgsStep: 170,

      triangleAngle: 23,

      liftingDragMomentum: 500,
      cargoX: 50,
      cargoY: 8,

      triangleYOffsetCoeff: .1,
      liftingTriangleStg: {
        scale: {x: .6, y: 1},
      },
      maxCargoX: 300,

      triangleActionBlockStg: {
        visible: true,
        x: 210,
        y: 300,
        width: 250,
        height: 230,
        fill: 'green',
        opacity: 0,
      },
      liftingTriangleTxt: {
        visible: true,

        height: {
          x: 0,
          y: 50,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',
          align: 'center',
          width: 23,

          value: 10,
        },

        width: {
          x: 90,
          y: 37,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',

          value: 20,
        },
      },
      triangleHintX: 440
    },
    next_success: 'success',
  },
  {
    key: 'success',
  }
];
export const liftingBlocksScenario = [
  // --------- STEP 1 ----------
  {
    key: 'method-1',
    patchData: {
      ...initialData,
      imgDir: 'liftingBlocksMultiple',
      method1Visible: true,
      liftingSingleBlock: true,


      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      dragHintVisible: true,
      formulaVisible: true,

      triangleGrayVisible: true,
      supportStandVisible: true,

      ropeCoeff: .8,

      cargoWeight: 3000,
      ropeForce: 3000,

      buildingHeight: 10,

      cargoX: 23,
      maxCargoY: 260,
    },
    previous: 'step1',
    next_success: 'success',
    next_btn_hidden: true,
  },
  {
    key: 'method-2',
    patchData: {
      ...initialData,
      imgDir: 'liftingBlocksMultiple',
      method2Visible: true,
      liftingDualBlock: true,

      wheel1ContainerVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      cargoLineSecondVisible: true,
      dragHintVisible: true,
      formulaVisible: true,

      triangleGrayVisible: true,
      supportStandVisible: true,

      ropeCoeff: .4,

      buildingHeight: 20,

      cargoWeight: 3000,
      ropeForce: 1500,

      wheel1Factor: -.4,
      cargoX: 13,
      cargoLineLength: 282,
      maxCargoY: 260,
    },
    previous: 'step1',
    next_success: 'success',
  },
  {
    key: 'method-3',
    patchData: {
      ...initialData,
      imgDir: 'liftingBlocksMultiple',
      method3Visible: true,
      liftingDualBlock: true,

      wheel1ContainerVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      cargoLineSecondVisible: true,
      cargoLineThirdVisible: true,
      dragHintVisible: true,
      wheel4Visible: true,
      formulaVisible: true,

      triangleGrayVisible: true,
      supportStandVisible: true,

      ropeCoeff: .2,

      buildingHeight: 30,

      cargoWeight: 3000,
      ropeForce: 1000,

      wheel4Factor: -.2,
      cargoX: 13,
      cargoLineLength: 282,
      maxCargoY: 260,
    },
    previous: 'step1',
    next_success: 'success',
  },

  {
    key: 'success',
  }
];
export const liftingTasksScenario = [
  // --------- STEP 1 ----------
  {
    key: 'piano initial',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'piano',
      checkCargoWeightVisible: false,

      cargoImg: pianoImg,
      cargoImgStg: {
        width: 60, height: 45,
        x: 20, y: 5
      },

      resetBtnPrev: true,
      methodsContainerScale: {x: .8, y: .8},

      cargoInitialHintText: 'Определи вес груза, \nиспользуя простые механизмы. \nМаксимальная сила, с которой \nты можешь тянуть - 500 Н',
    },
    next_success: 'success',
  },

  {
    key: 'bricks initial',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',

      checkCargoWeightVisible: false,
      cargoName: 'bricks',
      cargoImg: bricksImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 17
      },

      resetBtnPrev: true,
      methodsContainerScale: {x: .8, y: .8},

      cargoInitialHintText: 'Определи вес груза, \nиспользуя простые механизмы. \nМаксимальная сила, с которой \nты можешь тянуть - 500 Н',
    },
    next_success: 'success',
  },

  {
    key: 'bigCargo initial',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bigCargo',
      checkCargoWeightVisible: false,

      cargoImgStg: {
        width: 60, height: 45,
        x: 20, y: 5
      },

      resetBtnPrev: true,
      methodsContainerScale: {x: .8, y: .8},

      cargoInitialHintText: 'Определи вес груза, \nиспользуя простые механизмы. \nМаксимальная сила, с которой \nты можешь тянуть - 500 Н',
    },
    next_success: 'success',
  },



  {
    key: 'piano-method-1',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'piano',
      cargoImg: pianoImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 20
      },
      resetBtnPrev: true,
      cargoWeightLetter: true,
      method1Visible: true,
      liftingSingleBlock: true,
      methodsContainerScale: {x: .8, y: .8},
      checkCargoWeightVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      dragHintVisible: true,
      withCalc: true,
      triangleGrayVisible: true,
      supportStandVisible: true,

      ropeCoeff: .8,

      cargoWeight: 1000,
      ropeForce: 500,

      buildingHeight: 10,

      cargoX: 23,
      maxCargoY: 260,
      successRange: {start: 501, end: 1000},
    },
    previous: 'piano initial',
    next_success: 'success',
  },
  {
    key: 'bricks-method-1',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bricks',
      cargoImg: bricksImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 17
      },
      resetBtnPrev: true,
      cargoWeightLetter: true,
      method1Visible: true,
      liftingSingleBlock: true,
      methodsContainerScale: {x: .8, y: .8},
      checkCargoWeightVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      dragHintVisible: true,
      withCalc: true,
      triangleGrayVisible: true,
      supportStandVisible: true,

      ropeCoeff: .8,

      cargoWeight: 2000,
      ropeForce: 500,

      buildingHeight: 10,

      cargoX: 23,
      maxCargoY: 260,
      successRange: {start: 2000, end: 2500},
    },
    previous: 'bricks initial',
    next_success: 'success',
  },

  {
    key: 'bigCargo-method-1',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bigCargo',
      method1Visible: true,
      liftingSingleBlock: true,
      methodsContainerScale: {x: .8, y: .8},
      checkCargoWeightVisible: true,
      cargoWeightLetter: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      dragHintVisible: true,
      resetBtnPrev: true,
      triangleGrayVisible: true,
      supportStandVisible: true,
      withCalc: true,
      ropeCoeff: .8,

      cargoWeight: 3000,
      ropeForce: 500,

      buildingHeight: 10,

      cargoX: 23,
      maxCargoY: 260,
      successRange: {start: 3000, end: 3500},
    },
    previous: 'bigCargo initial',
    next_success: 'success',
  },



  {
    key: 'piano-method-2',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'piano',
      cargoImg: pianoImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 20
      },
      checkCargoWeightVisible: true,
      cargoWeightLetter: true,
      method2Visible: true,
      liftingDualBlock: true,
      methodsContainerScale: {x: .8, y: .8},
      wheel1ContainerVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      cargoLineSecondVisible: true,
      dragHintVisible: true,
      resetBtnPrev: true,
      triangleGrayVisible: true,
      supportStandVisible: true,
      withCalc: true,
      ropeCoeff: .4,

      buildingHeight: 20,

      cargoWeight: 1000,
      ropeForce: 500,

      wheel1Factor: -.4,
      cargoX: 13,
      cargoLineLength: 282,
      maxCargoY: 260,
      successRange: {start: 501, end: 1000},
    },
    previous: 'piano initial',
    next_success: 'success',
  },
  {
    key: 'bricks-method-2',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bricks',
      cargoImg: bricksImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 17
      },
      checkCargoWeightVisible: true,
      cargoWeightLetter: true,
      method2Visible: true,
      liftingDualBlock: true,
      methodsContainerScale: {x: .8, y: .8},
      wheel1ContainerVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      cargoLineSecondVisible: true,
      dragHintVisible: true,
      resetBtnPrev: true,
      triangleGrayVisible: true,
      supportStandVisible: true,
      withCalc: true,
      ropeCoeff: .4,

      buildingHeight: 20,

      cargoWeight: 2000,
      ropeForce: 500,

      wheel1Factor: -.4,
      cargoX: 13,
      cargoLineLength: 282,
      maxCargoY: 260,
      successRange: {start: 2000, end: 2500},
    },
    previous: 'bricks initial',
    next_success: 'success',
  },
  {
    key: 'bigCargo-method-2',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bigCargo',
      checkCargoWeightVisible: true,
      cargoWeightLetter: true,
      method2Visible: true,
      liftingDualBlock: true,
      methodsContainerScale: {x: .8, y: .8},
      wheel1ContainerVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      cargoLineSecondVisible: true,
      dragHintVisible: true,
      resetBtnPrev: true,
      triangleGrayVisible: true,
      supportStandVisible: true,
      withCalc: true,
      ropeCoeff: .4,

      buildingHeight: 20,

      cargoWeight: 3000,
      ropeForce: 500,

      wheel1Factor: -.4,
      cargoX: 13,
      cargoLineLength: 282,
      maxCargoY: 260,
      successRange: {start: 3000, end: 3500},
    },
    previous: 'bigCargo initial',
    next_success: 'success',
  },




  {
    key: 'piano-method-3',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'piano',
      cargoImg: pianoImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 20
      },

      cargoWeightLetter: true,
      method3Visible: true,
      liftingDualBlock: true,
      checkCargoWeightVisible: true,
      wheel1ContainerVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      cargoLineSecondVisible: true,
      cargoLineThirdVisible: true,
      dragHintVisible: true,
      wheel4Visible: true,
      resetBtnPrev: true,
      methodsContainerScale: {x: .8, y: .8},
      triangleGrayVisible: true,
      supportStandVisible: true,
      withCalc: true,
      ropeCoeff: .2,

      buildingHeight: 30,

      cargoWeight: 1000,
      ropeForce: 500,

      wheel4Factor: -.2,
      cargoX: 13,
      cargoLineLength: 282,
      maxCargoY: 260,
      successRange: {start: 501, end: 1000},
    },
    previous: 'piano initial',
    next_success: 'success',
  },
  {
    key: 'bricks-method-3',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bricks',
      cargoImg: bricksImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 17
      },

      cargoWeightLetter: true,
      method3Visible: true,
      liftingDualBlock: true,
      checkCargoWeightVisible: true,
      wheel1ContainerVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      cargoLineSecondVisible: true,
      cargoLineThirdVisible: true,
      dragHintVisible: true,
      wheel4Visible: true,
      resetBtnPrev: true,
      methodsContainerScale: {x: .8, y: .8},
      triangleGrayVisible: true,
      supportStandVisible: true,
      withCalc: true,
      ropeCoeff: .2,

      buildingHeight: 30,

      cargoWeight: 2000,
      ropeForce: 500,

      wheel4Factor: -.2,
      cargoX: 13,
      cargoLineLength: 282,
      maxCargoY: 260,
      successRange: {start: 2000, end: 2500},
    },
    previous: 'bricks initial',
    next_success: 'success',
  },
  {
    key: 'bigCargo-method-3',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bigCargo',
      method3Visible: true,
      liftingDualBlock: true,
      checkCargoWeightVisible: true,
      cargoWeightLetter: true,
      wheel1ContainerVisible: true,
      dragLineVisible: true,
      connectorLineVisible: true,
      cargoLineVisible: true,
      cargoLineSecondVisible: true,
      cargoLineThirdVisible: true,
      dragHintVisible: true,
      wheel4Visible: true,
      resetBtnPrev: true,
      methodsContainerScale: {x: .8, y: .8},
      triangleGrayVisible: true,
      supportStandVisible: true,
      withCalc: true,
      ropeCoeff: .2,

      buildingHeight: 30,

      cargoWeight: 3000,
      ropeForce: 500,

      wheel4Factor: -.2,
      cargoX: 13,
      cargoLineLength: 282,
      maxCargoY: 260,
      successRange: {start: 3000, end: 3500},
    },
    previous: 'bigCargo initial',
    next_success: 'success',
  },



  {
    key: 'piano-method-4',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'piano',
      cargoImg: pianoImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 15, y: 15
      },
      cargoWeightLetter: true,
      method4Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingInclinedSurface: true,
      checkCargoWeightVisible: true,
      resetBtnPrev: true,
      cargoX: 250,
      cargoY: 8,
      cargoWeight: 1000,
      liftingDragMomentum: 500,
      maxCargoX: 300,
      withCalc: true,
      triangleYOffsetCoeff: 3.4,

      triangleActionBlockStg: {
        visible: true,
        x: 200,
        y: 300,
        width: 440,
        height: 230,
        fill: 'green',
        opacity: 0,
      },

      liftingTriangleTxt: {
        visible: true,

        height: {
          x: 0,
          y: 50,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',
          align: 'center',
          width: 23,

          value: 10,
        },

        width: {
          x: 150,
          y: 37,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',

          value: 40,
        },
      },
      successRange: {start: 501, end: 1000},
    },
    previous: 'piano initial',
    next_success: 'success',
  },
  {
    key: 'bricks-method-4',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bricks',
      cargoImg: bricksImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 15, y: 15
      },
      resetBtnPrev: true,
      method4Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingInclinedSurface: true,
      checkCargoWeightVisible: true,
      cargoWeightLetter: true,
      cargoX: 250,
      cargoY: 8,
      cargoWeight: 2000,
      liftingDragMomentum: 500,
      maxCargoX: 300,
      withCalc: true,
      triangleYOffsetCoeff: 3.4,

      triangleActionBlockStg: {
        visible: true,
        x: 200,
        y: 300,
        width: 440,
        height: 230,
        fill: 'green',
        opacity: 0,
      },

      liftingTriangleTxt: {
        visible: true,

        height: {
          x: 0,
          y: 50,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',
          align: 'center',
          width: 23,

          value: 10,
        },

        width: {
          x: 150,
          y: 37,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',

          value: 40,
        },
      },
      successRange: {start: 2000, end: 2500},
    },
    previous: 'bricks initial',
    next_success: 'success',
  },
  {
    key: 'bigCargo-method-4',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bigCargo',
      resetBtnPrev: true,
      method4Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingInclinedSurface: true,
      checkCargoWeightVisible: true,
      cargoWeightLetter: true,

      cargoX: 250,
      cargoY: 8,
      cargoWeight: 3000,

      liftingDragMomentum: 500,
      maxCargoX: 300,

      triangleYOffsetCoeff: 3.4,

      triangleActionBlockStg: {
        visible: true,
        x: 200,
        y: 300,
        width: 440,
        height: 230,
        fill: 'green',
        opacity: 0,
      },
      withCalc: true,
      liftingTriangleTxt: {
        visible: true,

        height: {
          x: 0,
          y: 50,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',
          align: 'center',
          width: 23,

          value: 10,
        },

        width: {
          x: 150,
          y: 37,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',

          value: 40,
        },
      },
      successRange: {start: 3000, end: 3500},
    },
    previous: 'bigCargo initial',
    next_success: 'success',
  },




  {
    key: 'piano-method-5',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'piano',
      cargoImg: pianoImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 15, y: 15
      },
      resetBtnPrev: true,
      method5Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingInclinedSurface: true,
      checkCargoWeightVisible: true,
      cargoWeightLetter: true,

      cargoX: 250,
      cargoY: 8,

      liftingDragMomentum: 500,
      maxCargoX: 300,

      triangleYOffsetCoeff: 3.4,
      cargoWeight: 1000,
      triangleActionBlockStg: {
        visible: true,
        x: 200,
        y: 300,
        width: 440,
        height: 230,
        fill: 'green',
        opacity: 0,
      },
      withCalc: true,
      liftingTriangleTxt: {
        visible: true,

        height: {
          x: 0,
          y: 50,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',
          align: 'center',
          width: 23,

          value: 10,
        },

        width: {
          x: 150,
          y: 37,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',

          value: 50,
        },
      },
      successRange: {start: 501, end: 1000},
    },
    previous: 'piano initial',
    next_success: 'success',
  },
  {
    key: 'bricks-method-5',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bricks',
      cargoImg: bricksImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 15, y: 15
      },
      resetBtnPrev: true,
      method5Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingInclinedSurface: true,
      checkCargoWeightVisible: true,
      cargoWeightLetter: true,
      cargoWeight: 2000,
      cargoX: 250,
      cargoY: 8,
      withCalc: true,
      liftingDragMomentum: 500,
      maxCargoX: 300,

      triangleYOffsetCoeff: 3.4,

      triangleActionBlockStg: {
        visible: true,
        x: 200,
        y: 300,
        width: 440,
        height: 230,
        fill: 'green',
        opacity: 0,
      },

      liftingTriangleTxt: {
        visible: true,

        height: {
          x: 0,
          y: 50,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',
          align: 'center',
          width: 23,

          value: 10,
        },

        width: {
          x: 150,
          y: 37,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',

          value: 50,
        },
      },
      successRange: {start: 2000, end: 2500},
    },
    previous: 'bricks initial',
    next_success: 'success',
  },
  {
    key: 'bigCargo-method-5',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bigCargo',
      method5Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingInclinedSurface: true,
      checkCargoWeightVisible: true,
      resetBtnPrev: true,
      cargoWeightLetter: true,
      cargoX: 250,
      cargoY: 8,
      withCalc: true,
      cargoWeight: 3000,
      liftingDragMomentum: 500,
      maxCargoX: 300,

      triangleYOffsetCoeff: 3.4,

      triangleActionBlockStg: {
        visible: true,
        x: 200,
        y: 300,
        width: 440,
        height: 230,
        fill: 'green',
        opacity: 0,
      },

      liftingTriangleTxt: {
        visible: true,

        height: {
          x: 0,
          y: 50,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',
          align: 'center',
          width: 23,

          value: 10,
        },

        width: {
          x: 150,
          y: 37,
          fontSize: 13,
          fill: 'white',
          fontStyle: 'bold',

          value: 50,
        },
      },
      successRange: {start: 3000, end: 3500},
    },
    previous: 'bigCargo initial',
    next_success: 'success',
  },




  {
    key: 'piano-method-6',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'piano',
      cargoImg: pianoImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 20
      },
      resetBtnPrev: true,
      method6Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,
      cargoWeightLetter: true,
      checkCargoWeightVisible: true,
      leverVal: 5,
      withCalc: true,
      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
      leverWidth: 100,
      leverForce: 500,
      cargoWeight: 1000,
      lsnMainContainerScale: {x: .9, y: .9},
      lsnMainContainerY: 60,

      successRange: {start: 501, end: 1000},
    },
    previous: 'piano initial',
    next_success: 'success',
  },
  {
    key: 'bricks-method-6',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bricks',
      cargoImg: bricksImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 17
      },
      leverVal: 5,
      method6Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,
      cargoWeightLetter: true,
      checkCargoWeightVisible: true,
      resetBtnPrev: true,
      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
      leverWidth: 100,
      leverForce: 500,
      cargoWeight: 2000,
      lsnMainContainerScale: {x: .9, y: .9},
      lsnMainContainerY: 60,
      withCalc: true,
      successRange: {start: 2000, end: 2500},
    },
    previous: 'bricks initial',
    next_success: 'success',
  },
  {
    key: 'bigCargo-method-6',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bigCargo',
      method6Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,
      cargoWeightLetter: true,
      checkCargoWeightVisible: true,
      resetBtnPrev: true,
      withCalc: true,
      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
      leverWidth: 100,
      leverForce: 500,
      cargoWeight: 3000,
      lsnMainContainerScale: {x: .9, y: .9},
      lsnMainContainerY: 60,
      leverVal: 5,
      successRange: {start: 3000, end: 3500},
    },
    previous: 'bigCargo initial',
    next_success: 'success',
  },




  {
    key: 'piano-method-7',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'piano',
      cargoImg: pianoImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 20
      },
      leverVal: 6,
      resetBtnPrev: true,
      method7Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,
      cargoWeightLetter: true,
      checkCargoWeightVisible: true,
      withCalc: true,
      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
      leverWidth: 120,
      leverForce: 500,
      cargoWeight: 1000,
      lsnMainContainerScale: {x: .9, y: .9},
      lsnMainContainerY: 60,

      successRange: {start: 501, end: 1000},
    },
    previous: 'piano initial',
    next_success: 'success',
  },
  {
    key: 'bricks-method-7',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bricks',
      cargoImg: bricksImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 17
      },
      leverVal: 6,
      method7Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,
      cargoWeightLetter: true,
      checkCargoWeightVisible: true,
      resetBtnPrev: true,
      withCalc: true,
      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
      leverWidth: 120,
      leverForce: 500,
      cargoWeight: 2000,
      lsnMainContainerScale: {x: .9, y: .9},
      lsnMainContainerY: 60,
      successRange: {start: 2000, end: 2500},
    },
    previous: 'bricks initial',
    next_success: 'success',
  },
  {
    key: 'bigCargo-method-7',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bigCargo',
      method7Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,
      cargoWeightLetter: true,
      checkCargoWeightVisible: true,
      resetBtnPrev: true,
      withCalc: true,
      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
      leverWidth: 120,
      leverForce: 500,
      cargoWeight: 3000,
      lsnMainContainerScale: {x: .9, y: .9},
      lsnMainContainerY: 60,
      leverVal: 6,
      successRange: {start: 3000, end: 3500},
    },
    previous: 'bigCargo initial',
    next_success: 'success',
  },




  {
    key: 'piano-method-8',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'piano',
      cargoImg: pianoImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 20
      },
      leverVal: 7,
      resetBtnPrev: true,
      method8Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,
      cargoWeightLetter: true,
      withCalc: true,
      checkCargoWeightVisible: true,
      cargoWeight: 1000,
      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
      leverForce: 500,

      lsnMainContainerScale: {x: .9, y: .9},
      lsnMainContainerY: 60,

      successRange: {start: 501, end: 1000},
    },
    previous: 'piano initial',
    next_success: 'success',
  },
  {
    key: 'bricks-method-8',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bricks',
      cargoImg: bricksImg,
      cargoImgStg: {
        width: 45, height: 30,
        x: 22, y: 17
      },
      leverVal: 7,
      method8Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,
      cargoWeightLetter: true,
      checkCargoWeightVisible: true,
      resetBtnPrev: true,
      withCalc: true,
      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
      leverForce: 500,
      cargoWeight: 2000,
      lsnMainContainerScale: {x: .9, y: .9},
      lsnMainContainerY: 60,
      successRange: {start: 2000, end: 2500},
    },
    previous: 'bricks initial',
    next_success: 'success',
  },
  {
    key: 'bigCargo-method-8',
    patchData: {
      ...initialData,
      imgDir: 'allLifting',
      cargoName: 'bigCargo',
      method8Visible: true,
      methodsContainerScale: {x: .8, y: .8},
      liftingLeverHandle: true,
      cargoLineVisible: true,
      supportStandVisible: true,
      leverHandleVisible: true,
      cargoWeightLetter: true,
      checkCargoWeightVisible: true,
      resetBtnPrev: true,
      withCalc: true,
      leverVal: 8,
      cargoX: 42,
      maxCargoY: 270,
      wheel2Factor: -.1,
      maxLeverHandleY: 30,
      cargoLineX: 492,
      leverForce: 500,
      cargoWeight: 3000,
      lsnMainContainerScale: {x: .9, y: .9},
      lsnMainContainerY: 60,
      successRange: {start: 3000, end: 3500},
    },
    previous: 'bigCargo initial',
    next_success: 'success',
  },
  {
    key: 'success',
  }
];
export const liftingBlocksWindingLengthScenario = () => {
  const staticPulley = _t('blocks_rope_len1.Tip_text1');
  const movingPulley2 = _t('blocks_rope_len1.Tip_text4');
  const movingPulley3 = _t('blocks_rope_len1.Tip_text5');


  return [
    // --------- STEP 1 ----------
    {
      key: 'single block step1',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method1Visible: true,
        liftingSingleBlock: true,
        withoutSelectingMethods: true,

        windingBlockVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        dragHintVisible: true,
        triangleGrayVisible: true,
        supportStandVisible: true,

        cargoGravityTxt: false,
        ropeForceVisible: false,

        blockNameTxt: staticPulley,
        ropeCoeff: .8,

        cargoHeight: 0,
        cargoHeightVisible: true,

        titleData: {
          text: _t('peryshkin23.title'),
        },

        cargoWeight: 3000,
        ropeForce: 3000,

        buildingHeight: 10,

        cargoX: 23,
        maxCargoY: 260,

        blocksDragLineSuccessCallback: (lsnComp) => {
          lsnComp.scenarioManager.next();
        },
      },
      previous: 'single block step1',
      next_success: 'single block step1.2',
      next_btn_hidden: true,
    },
    {
      key: 'single block step1.2',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method1Visible: true,
        liftingSingleBlock: true,
        withoutSelectingMethods: true,

        blockNameTxt: staticPulley,
        dragging: true,
        dragLineActive: false,
        dragHintVisible: false,

        windingArrowHintVisible: true,
        cargoGravityTxt: false,
        windingBlockVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,

        triangleGrayVisible: true,
        supportStandVisible: true,

        ropeForceVisible: false,

        ropeCoeff: .8,

        cargoHeight: 10,
        cargoHeightVisible: true,

        cargoWeight: 3000,
        ropeForce: 3000,

        buildingHeight: 10,

        cargoX: 23,
        maxCargoY: 260,
        draggedOutDelta: 260,

        btnCheckSuccessCallback: (lsnComp) => {
          const success = lsnComp.data.inputVal === lsnComp.data.buildingHeight;
          if (success) {
            lsnComp.scenarioManager.next();
          }
          showFailOrSuccessPopup(lsnComp, success);
        },
        questionCardSettings: {
          ...initialData.questionCardSettings,
          visible: true,
          label: {text: _t('peryshkin23.label')},
        },
        titleData: {
          text: _t('peryshkin23.title'),
        },

      },
      previous: 'single block step1',
      next_success: 'double block step1',
      next_btn_hidden: true,
    },



    {
      key: 'double block step1',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method2Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,
        ropeForceVisible: false,

        cargoGravityTxt: false,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        dragHintVisible: true,

        triangleGrayVisible: true,
        supportStandVisible: true,

        ropeCoeff: .4,

        cargoHeight: 10,
        cargoHeightVisible: true,

        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 1500,

        wheel1Factor: -.4,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,

        blockNameTxt: movingPulley2,
        titleData: {
          text: _t('peryshkin23.title'),
        },
        blocksDragLineSuccessCallback: (lsnComp) => {
          lsnComp.scenarioManager.next();
        },
      },
      previous: 'double block step1',
      next_success: 'double block step1.2',
    },
    {
      key: 'double block step1.2',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method2Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,
        ropeForceVisible: false,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        dragHintVisible: true,
        dragLineActive: false,
        triangleGrayVisible: true,
        supportStandVisible: true,
        cargoGravityTxt: false,
        windingArrowHintVisible: true,
        dragging: true,
        ropeCoeff: .4,

        buildingHeight: 10,

        cargoHeight: 10,
        cargoHeightVisible: true,

        cargoWeight: 3000,
        ropeForce: 1500,

        wheel1Factor: -.4,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,
        draggedOutDelta: 260,


        blockNameTxt: movingPulley2,
        titleData: {
          text: _t('peryshkin23.title'),
        },

        btnCheckSuccessCallback: (lsnComp) => {
          const corrHeight = lsnComp.data.buildingHeight*2;
          const success = lsnComp.data.inputVal === corrHeight;
          if (success) {
            lsnComp.scenarioManager.next();
          }
          showFailOrSuccessPopup(lsnComp, success);
        },
        questionCardSettings: {
          ...initialData.questionCardSettings,
          visible: true,
          label: {text: _t('peryshkin23.label')},
        },
      },
      previous: 'double block step1',
      next_success: 'triple block step1',
    },


    {
      key: 'triple block step1',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method3Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        cargoLineThirdVisible: true,
        dragHintVisible: true,
        wheel4Visible: true,
        ropeForceVisible: false,
        triangleGrayVisible: true,
        supportStandVisible: true,
        cargoGravityTxt: false,
        ropeCoeff: .2,

        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 1000,

        cargoHeight: 10,
        cargoHeightVisible: true,

        wheel4Factor: -.2,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,

        blockNameTxt: movingPulley3,
        titleData: {
          text: _t('peryshkin23.title'),
        },
        blocksDragLineSuccessCallback: (lsnComp) => {
          lsnComp.scenarioManager.next();
        },
      },
      previous: 'triple block step1',
      next_success: 'triple block step1.2',
    },
    {
      key: 'triple block step1.2',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method3Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        cargoLineThirdVisible: true,
        dragHintVisible: true,
        wheel4Visible: true,
        dragLineActive: false,
        ropeForceVisible: false,
        triangleGrayVisible: true,
        supportStandVisible: true,
        cargoGravityTxt: false,
        windingArrowHintVisible: true,
        ropeCoeff: .2,
        dragging: true,
        cargoHeight: 10,
        cargoHeightVisible: true,

        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 1000,

        blockNameTxt: movingPulley3,
        wheel4Factor: -.2,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,
        draggedOutDelta: 260,
        titleData: {
          text: _t('peryshkin23.title'),
        },


        btnCheckSuccessCallback: (lsnComp) => {
          const corrHeight = lsnComp.data.buildingHeight*3;
          const success = lsnComp.data.inputVal === corrHeight;
          if (success) {
            lsnComp.props.addLessonResult(lsnComp.props.code, true, {}, false);
            lsnComp.scenarioManager.next();
          }
          showFailOrSuccessPopup(lsnComp, success);
        },
        questionCardSettings: {
          ...initialData.questionCardSettings,
          visible: true,
          label: {text: _t('peryshkin23.label')},
        },
      },
      previous: 'triple block step1',
      next_success: 'complete block',
    },


    {
      key: 'complete block',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method3Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        cargoLineThirdVisible: true,
        wheel4Visible: true,
        ropeForceVisible: false,
        cargoGravityTxt: false,
        triangleGrayVisible: true,
        supportStandVisible: true,
        completeLsnVisible: true,

        blockNameTxt: '',

        ropeCoeff: 1,

        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 1000,

        wheel4Factor: -.2,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,

        titleData: {
          text: '',
        },
      },
      previous: 'triple block step1',
      next_success: 'success',
      switch_at: 2,
    },
    {
      key: 'success'
    }
  ];
}
export const liftingBlocksWorkPerformedScenario = () => {
  const staticPulley = _t('blocks_rope_len1.Tip_text1');
  const movingPulley2 = _t('blocks_rope_len1.Tip_text4');
  const movingPulley3 = _t('blocks_rope_len1.Tip_text5');

  return [
    // --------- STEP 1 ----------
    {
      key: 'single block step1',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method1Visible: true,
        liftingSingleBlock: true,
        withoutSelectingMethods: true,

        windingBlockVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        dragHintVisible: true,
        triangleGrayVisible: true,
        supportStandVisible: true,

        cargoGravityTxt: false,

        blockNameTxt: staticPulley,
        ropeCoeff: .8,

        cargoHeight: 0,
        cargoHeightVisible: true,

        titleData: {
          text: 'Подними груз на максимальную высоту и определи\n' +
            'величину выполненной работы, зная силу, \n' +
            'с которой тянут за канат\n',
        },

        cargoWeight: 3000,
        ropeForce: 300,

        buildingHeight: 10,

        cargoX: 23,
        maxCargoY: 260,

        blocksDragLineSuccessCallback: (lsnComp) => {
          lsnComp.scenarioManager.next();
        },
      },
      previous: 'single block step1',
      next_success: 'single block step1.2',
      next_btn_hidden: true,
    },
    {
      key: 'single block step1.2',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method1Visible: true,
        liftingSingleBlock: true,
        withoutSelectingMethods: true,

        blockNameTxt: staticPulley,
        dragging: true,
        dragLineActive: false,
        dragHintVisible: false,

        cargoGravityTxt: false,
        windingBlockVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,

        triangleGrayVisible: true,
        supportStandVisible: true,


        ropeCoeff: .8,

        cargoHeight: 10,
        cargoHeightVisible: true,

        cargoWeight: 3000,
        ropeForce: 300,

        buildingHeight: 10,

        cargoX: 23,
        maxCargoY: 260,
        draggedOutDelta: 260,

        btnCheckSuccessCallback: (lsnComp) => {
          const success = lsnComp.data.inputVal === 3000;
          if (success) {
            lsnComp.scenarioManager.next();
          }
          showFailOrSuccessPopup(lsnComp, success);
        },
        questionCardSettings: {
          ...initialData.questionCardSettings,
          visible: true,
          label: {text: 'Выполненная \nработа, Дж:'},
        },
        titleData: {
          text: 'Подними груз на максимальную высоту и определи\n' +
            'величину выполненной работы, зная силу, \n' +
            'с которой тянут за канат\n',
        },

      },
      previous: 'single block step1',
      next_success: 'double block step1',
      next_btn_hidden: true,
    },



    {
      key: 'double block step1',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method2Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,

        cargoGravityTxt: false,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        dragHintVisible: true,

        triangleGrayVisible: true,
        supportStandVisible: true,

        ropeCoeff: .4,

        cargoHeight: 10,
        cargoHeightVisible: true,

        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 150,

        wheel1Factor: -.4,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,

        blockNameTxt: movingPulley2,
        titleData: {
          text: 'Подними груз на максимальную высоту и определи\n' +
            'величину выполненной работы, зная силу, \n' +
            'с которой тянут за канат\n',
        },
        blocksDragLineSuccessCallback: (lsnComp) => {
          lsnComp.scenarioManager.next();
        },
      },
      previous: 'double block step1',
      next_success: 'double block step1.2',
    },
    {
      key: 'double block step1.2',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method2Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        dragHintVisible: true,
        dragLineActive: false,
        triangleGrayVisible: true,
        supportStandVisible: true,
        cargoGravityTxt: false,
        dragging: true,
        ropeCoeff: .4,

        buildingHeight: 10,

        cargoHeight: 10,
        cargoHeightVisible: true,

        cargoWeight: 3000,
        ropeForce: 150,

        wheel1Factor: -.4,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,
        draggedOutDelta: 260,


        blockNameTxt: movingPulley2,
        titleData: {
          text: 'Подними груз на максимальную высоту и определи\n' +
            'величину выполненной работы, зная силу, \n' +
            'с которой тянут за канат\n',
        },

        btnCheckSuccessCallback: (lsnComp) => {
          const success = lsnComp.data.inputVal === 3000;
          if (success) {
            lsnComp.scenarioManager.next();
          }
          showFailOrSuccessPopup(lsnComp, success);
        },
        questionCardSettings: {
          ...initialData.questionCardSettings,
          visible: true,
          label: {text: 'Выполненная \nработа, Дж:'},
        },
      },
      previous: 'double block step1',
      next_success: 'triple block step1',
    },


    {
      key: 'triple block step1',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method3Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        cargoLineThirdVisible: true,
        dragHintVisible: true,
        wheel4Visible: true,
        triangleGrayVisible: true,
        supportStandVisible: true,
        cargoGravityTxt: false,
        ropeCoeff: .2,

        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 100,

        cargoHeight: 10,
        cargoHeightVisible: true,

        wheel4Factor: -.2,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,

        blockNameTxt: movingPulley3,
        titleData: {
          text: 'Подними груз на максимальную высоту и определи\n' +
            'величину выполненной работы, зная силу, \n' +
            'с которой тянут за канат\n',
        },
        blocksDragLineSuccessCallback: (lsnComp) => {
          lsnComp.scenarioManager.next();
        },
      },
      previous: 'triple block step1',
      next_success: 'triple block step1.2',
    },
    {
      key: 'triple block step1.2',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method3Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        cargoLineThirdVisible: true,
        dragHintVisible: true,
        wheel4Visible: true,
        dragLineActive: false,

        triangleGrayVisible: true,
        supportStandVisible: true,
        cargoGravityTxt: false,
        ropeCoeff: .2,
        dragging: true,
        cargoHeight: 10,
        cargoHeightVisible: true,

        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 100,

        blockNameTxt: movingPulley3,
        wheel4Factor: -.2,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,
        draggedOutDelta: 260,
        titleData: {
          text: 'Подними груз на максимальную высоту и определи\n' +
            'величину выполненной работы, зная силу, \n' +
            'с которой тянут за канат\n',
        },


        btnCheckSuccessCallback: (lsnComp) => {
          const success = lsnComp.data.inputVal === 3000;
          if (success) {
            lsnComp.scenarioManager.next();
          }
          showFailOrSuccessPopup(lsnComp, success);
        },
        questionCardSettings: {
          ...initialData.questionCardSettings,
          visible: true,
          label: {text: 'Выполненная \nработа, Дж:'},
        },
      },
      previous: 'triple block step1',
      next_success: 'complete block',
    },


    {
      key: 'complete block',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method3Visible: true,
        liftingDualBlock: true,
        withoutSelectingMethods: true,
        windingBlockVisible: true,
        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        cargoLineThirdVisible: true,
        wheel4Visible: true,
        cargoGravityTxt: false,
        triangleGrayVisible: true,
        supportStandVisible: true,
        completeLsnVisible: true,

        blockNameTxt: '',

        ropeCoeff: 1,

        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 100,

        wheel4Factor: -.2,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,

        titleData: {
          text: 'Если обратить внимание, выполненная работа для каждого \nблока была одинакова. \n' +
            'Ни один из простых механизмов не дает выигрыша в работе. \n' +
            'Во сколько раз выигрываем в силе, во столько раз проигрываем \nв расстоянии.',
          lineHeight: '1.3',
          y: 25
        },
      },
      previous: 'triple block step1',
      next_success: '',
    },
  ];
}

export const BlocksRopeLenScenario = () => {
  const staticPulley = _t('blocks_rope_len1.Tip_text1');
  const movingPulley2 = _t('blocks_rope_len1.Tip_text4');
  const movingPulley3 = _t('blocks_rope_len1.Tip_text5');

  return [
    // --------- STEP 1 ----------
    {
      key: 'method-1',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method1Visible: true,
        liftingSingleBlock: true,

        windingBlockVisible: true,
        windingCircleVisible: false,
        withWindingRope: true,
        windingRopeCoeff: 1,

        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        dragHintVisible: true,
        triangleGrayVisible: true,
        supportStandVisible: true,
        ropeForceVisible: false,

        cargoGravityTxt: false,

        blockNameTxt: staticPulley,
        ropeCoeff: .8,

        cargoHeight: 0,
        cargoHeightVisible: true,

        cargoWeight: 3000,
        ropeForce: 300,

        buildingHeight: 10,

        cargoX: 23,
        maxCargoY: 260,

        blocksDragLineSuccessCallback: (lsnComp) => {},
      },
      next_success: 'method-1',
    },


    {
      key: 'method-2',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method2Visible: true,
        liftingDualBlock: true,

        cargoGravityTxt: false,

        windingBlockVisible: true,
        windingCircleVisible: false,
        withWindingRope: true,
        windingRopeCoeff: 2,

        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        dragHintVisible: true,
        ropeForceVisible: false,

        triangleGrayVisible: true,
        supportStandVisible: true,

        ropeCoeff: .4,

        cargoHeight: 10,
        cargoHeightVisible: true,

        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 150,

        wheel1Factor: -.4,
        windingWheelFactor: 1,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,

        blockNameTxt: movingPulley2,
        blocksDragLineSuccessCallback: (lsnComp) => {},
      },
      next_success: 'method-2',
    },


    {
      key: 'method-3',
      patchData: {
        ...initialData,
        imgDir: 'liftingBlocksMultiple',
        method3Visible: true,
        liftingDualBlock: true,

        windingBlockVisible: true,
        windingCircleVisible: false,
        withWindingRope: true,
        windingRopeCoeff: 3,

        wheel1ContainerVisible: true,
        dragLineVisible: true,
        connectorLineVisible: true,
        cargoLineVisible: true,
        cargoLineSecondVisible: true,
        cargoLineThirdVisible: true,
        dragHintVisible: true,
        wheel4Visible: true,
        triangleGrayVisible: true,
        supportStandVisible: true,
        cargoGravityTxt: false,
        ropeCoeff: .2,
        ropeForceVisible: false,
        buildingHeight: 10,

        cargoWeight: 3000,
        ropeForce: 100,

        cargoHeight: 10,
        cargoHeightVisible: true,

        wheel4Factor: -.2,
        cargoX: 13,
        cargoLineLength: 282,
        maxCargoY: 260,

        blockNameTxt: movingPulley3,
        blocksDragLineSuccessCallback: (lsnComp) => {},
      },
      next_success: 'method-3',
    },
  ];
}




export const liftingWithTimeScenarioFn = () => {
  // weight in newtons
  const cargos = [
    {key: 'piano', weight: 800, img: pianoImg,
      cargoImgStg: { width: 45, height: 30, x: 22, y: 20 },
      cargoTrangleStg: { width: 25, height: 15, x: 18, y: 20 }
    },
    {key: 'bowl', weight: 100, img: bowlImg,
      cargoImgStg: { width: 38, height: 28, x: 24, y: 19 },
      cargoTrangleStg: { width: 25, height: 15, x: 18, y: 20 }
    },
    {key: 'bricks', weight: 1500, img: bricksImg,
      cargoImgStg: { width: 45, height: 30, x: 22, y: 17 },
      cargoTrangleStg: { width: 25, height: 15, x: 18, y: 20 }
    },
    {key: 'bigCargo', weight: 1900, img: defaultCargoImg,
      cargoImgStg: { y: -2 },
      cargoTrangleStg: { width: 43, height: 30, x: 8, y: 13 }
    },
    {key: 'concreteMixer', weight: 400, img: concreteMixerImg,
      cargoImgStg: { width: 30, height: 30, x: 30, y: 20 },
      cargoTrangleStg: { width: 30, height: 30, x: 18, y: 10 }
    },
  ];
  const methods = [
    {key: 'fixedBlock', id: 1,},
    {key: 'doubleBlock', id: 2,},
    {key: 'tripleBlock', id: 3,},
    {key: 'triangle4:1', id: 4,},
    {key: 'triangle5:1', id: 5,},
    {key: 'lever6:1', id: 6,},
    {key: 'lever7:1', id: 7,},
    {key: 'lever8:1', id: 8,},
  ];

  let res = [];
  cargos.map((cargo) => {

    const defaultDataForAllSteps = {
      resetBtnPrev: true,
      methodsContainerScale: {x: .8, y: .8},
      imgDir: 'allLifting',
      cargoName: cargo.key,
      cargoWeight: cargo.weight,
      cargoImg: cargo.img,
      cargosList: cargos,
      cargoImgStg: cargo.cargoImgStg,
      cargoTrangleStg: cargo.cargoTrangleStg,
      withTime: true,
      withCalc: true,
    }

    res.push({
        key: `${cargo.key} initial`,
        patchData: {
          ...initialData,
          ...defaultDataForAllSteps,

          checkCargoWeightVisible: false,
          // start: false,
          cargoImg: cargo.img,

          cargoInitialHintText: `${cargo.weight/10} кг`,
        },
        next_success: 'success',
      });

    methods.forEach((method) => {
      res.push({
        key: `${cargo.key}-method-${method.id}`,
        patchData: {
          ...initialData,

          buildingHeight: 10,

          ...defaultDataForAllSteps,
          ...methodSettings[method.key],
          start: true,
          withCalc: true,
          // ropeCoeff: .8,
          ropeForce: 250,

          successRange: {start: 3000, end: 3500},

        },
        next_success: 'success',
      })
    })

    return res;
  })
  return [...res, {
    key: 'success', patchData: {
      start: false,
      dragging: false,
      draggable: false,
      timeTxt: 0,
      completeLsnVisible: true
    }}];
}
