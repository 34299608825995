import React, {useEffect} from "react";
import '../styles/farm-map.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import CatMapElement from "../components/CatMapElement";
import MapContainer from "./MapContainer";
import {checkIsTeacherMap, maps} from "../utils";
import FarmMapElement from "../components/FarmMapElement";
import MapElement from "../components/MapElement";
import videoImg from "../images/farm_1_map/video.png";
import presentationImg from "../images/farm_1_map/presentation.png";

export const getConfigByTaskCode = (key) => {
  let res = {pos: {left: 0, top: 0}};

  switch (key) {
    case 'farm_1_map_video1':
      res = { pos: {left: 260, top: 580, width: 200, height: 200}, itemImage: videoImg };
      break
    case 'find_in_the_picture1':
      res = { pos: {left: 220, top: 300, width: 270, height: 270} };
      break
    case 'lift_the_haystack1':
      res = { pos: {left: 486, top: 593, width: 220, height: 220} };
      break

    case 'farm_1_presentation1':
      res = { pos: {left: 400, top: 800, width: 250, height: 250}, itemImage: presentationImg };
      break


    // todo fix this is temporary
    case 'universal_task1':
      res = { pos: {left: 1280, top: 630, width: 220, height: 220} };
      break

    case 'school_task1':
      res = { pos: {left: 1217, top: 630, width: 220, height: 220} };
      break
    case 'school_task2':
      res = { pos: {left: 2, top: 500, width: 220, height: 220} };
      break
    case 'school_task3':
      res = { pos: {left: 117, top: 783, width: 220, height: 220} };
      break
    case 'school_task4':
      res = { pos: {left: 964, top: 309, width: 220, height: 220} };
      break
    case 'school_task5':
      res = { pos: {left: 1627, top: 620, width: 220, height: 220} };
      break
    case 'school_task6':
      res = { pos: {left: 708, top: 447, width: 220, height: 220} };
      break
    case 'lift_the_haystack2':
      res = { pos: {left: 691, top: 794, width: 220, height: 220} };
      break

    // case 'сatapult1':
    //   res = { pos: {left: 606, top: 12, width: 220, height: 220} };
    //   break
  }


  return res
}

const FarmMap = (props) => {
  const isTeacher = checkIsTeacherMap();

  useEffect(() => {
    const isTeacher = checkIsTeacherMap();
    if (isTeacher) {
      props.courseMapCreateStatistic();
    }
  }, [])

  const Content = ({tasksItems, isOpen, taskModalVisible, currentLessonCode, successScreenVisible, setTaskModalVisible}) => {

    useEffect(() => {
      // Initial redirect if already complete task
      const taskForRedirect = tasksItems.find(task => task.paymentRedirect);
      if (taskForRedirect?.complete && taskForRedirect?.paymentRedirect && !props.coursePaid && !isOpen) {
        window.location.href = taskForRedirect.paymentRedirect;
      }
    }, [tasksItems.length])

    useEffect(() => {
      if (!successScreenVisible) {
        const currentTask = tasksItems.find(task => task.lessonCode === currentLessonCode);
        if (currentTask?.complete && currentTask?.paymentRedirect && !props.coursePaid && !isOpen) {
          window.location.href = currentTask.paymentRedirect;
        }
      }
    }, [tasksItems, successScreenVisible]);
    return (
      <div className={'farm-map-background'}>
        {
          tasksItems.map((item, i) => {
            const prevItem = tasksItems.find(el => el.lessonCode === item.prevKey);
            return (
              <MapElement
                getConfigByTaskCode={getConfigByTaskCode}
                key={item.lessonCode}
                lessonCode={item.lessonCode}
                isOpen={isOpen}
                isTeacher={isTeacher}
                prevItem={prevItem}
                index={i}
                setTaskModalVisible={setTaskModalVisible} coursePaid={props.coursePaid} {...item}
                preOpen={() => props.getTaskAvailabilityStatus(item.lessonCode)}
              />
            )
          })
        }
      </div>
    )
  }
  return (
    <MapContainer {...props} lang={'en'} withoutFog={true} withoutSuccessScreen={true} mapKey={maps.farmMap.key}>
      <Content />
    </MapContainer>
  )
}

const mapStateToProps = state => {
  return ({
    coursePaid: state.mapReducer.coursePaid,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({
  getOrCreateMapProgress: (initial) => dispatch(actions.getOrCreateMapProgress(initial, maps.farmMap.key)),
  courseMapAuth: () => dispatch(actions.courseMapAuth()),

  getTaskAvailabilityStatus: (lessonCode) => dispatch(actions.getTaskAvailabilityStatus(maps.farmMap.key, lessonCode)),
  courseMapCreateStatistic: () => dispatch(actions.courseMapCreateStatistic(maps.farmMap.key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FarmMap);
