import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';
import bgNature from '../images/greece-flowers-bg.png';

import dragImg1 from "../images/arrangeByColumns/GreeceBlocks_3_1_1.png";
import dragImg2 from "../images/arrangeByColumns/GreeceBlocks_3_1_2.png";
import dragImg3 from "../images/arrangeByColumns/GreeceBlocks_3_1_3.png";


import dragImg21 from "../images/arrangeByColumns/GreeceBlocks_3_2_1.png";
import dragImg22 from "../images/arrangeByColumns/GreeceBlocks_3_2_2.png";
import dragImg23 from "../images/arrangeByColumns/GreeceBlocks_3_2_3.png";

import ArrangeByColumns from "./ArrangeByColumns";


const GreeceBlocks3 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'All titles are false. Move the pictures',
      columnsItems: [
        {id: 1, successAnswerId: 2, title: "Make the effort easier", height: '8em'},
        {id: 2, successAnswerId: 3, title: <>Doesn’t make the <br/>effort easier</>, height: '8em'},
        {id: 3, successAnswerId: 1, title: "Has no wheels", height: '8em'},
      ],
      dragItems: [
        {id: 1, image: dragImg2},
        {id: 2, image: dragImg3},
        {id: 3, image: dragImg1},
      ]
    },
    {
      title: 'The pulley is not the second. The wheel is behind the pulley.',
      columnsItems: [
        {id: 1, successAnswerId: 3, title: "The first"},
        {id: 2, successAnswerId: 1, title: "The second"},
        {id: 3, successAnswerId: 2, title: "The third"},
      ],
      dragItems: [
        {id: 1, image: dragImg21},
        {id: 2, image: dragImg22},
        {id: 3, image: dragImg23},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <ArrangeByColumns
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    columns={item.columnsItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default GreeceBlocks3;
