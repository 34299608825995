import React, {} from "react";
import teacherImg from '../../../images/teacher.jpg';
import starIcon from '../../../images/star.png';
import grayCross from '../../../images/grayCross.png';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";


const PaymentModal = (props) => {
  const {
    changeChangePaymentVisible
  } = props;

  return (
    <div style={styles.container}>
      <div style={styles.modalWrapper}>
        <img src={grayCross} style={styles.grayCrossStyle} alt="" onClick={() => changeChangePaymentVisible(false)}/>
        <div style={styles.teacherInfoWrapper}>
          <div>
            <img src={teacherImg} alt="" width={'100'}/>
          </div>
          <div style={styles.teacherInfo}>
            <div>
              <h2 style={{fontWeight: 'lighter', fontSize: '2.3em'}}>Ваш преподаватель:</h2>
              <h2 style={{ fontSize: '2.2em'}}>Роман Удовицкий</h2>
              <span style={{display: 'flex', alignItems: 'center'}}>
                <img src={starIcon} alt="" width={'20px'} /> <span>5,0</span>
              </span>
            </div>
            <div style={styles.teacherBio}>
              <p><b>Опыт преподавания школьной физики:</b> 6 лет </p>
              <p style={{lineHeight: '1.3', margin: '15px 0'}}>
                <b>Образование:</b> СПбПУ Петра Великого, <br/>
                магистр электроэнергетики и электротехники
              </p>
            </div>
          </div>
        </div>
        <div>
          <p>Для посещения следующего занятия необходимо произвести оплату:</p>
          <table style={styles.paymentInfo}>
            <tbody>
              <tr>
                <td> <p style={styles.priceItem}>1 занятие - 1'200 рублей</p></td>
                <td style={{textAlign: 'right'}}> <button style={{...styles.paymentBtn, background: 'white', color: blueColor}}>Оплатить 1 занятие</button> </td>
              </tr>
              <tr>
                <td> <p style={styles.priceItem}>4 занятие - <s>4'800</s> 4'500 рублей</p></td>
                <td style={{textAlign: 'right'}}> <button style={styles.paymentBtn}>Оплатить 4 занятие</button></td>
              </tr>
              <tr>
                <td> <p style={styles.priceItem}>8 занятие - <s>9'600</s> 8'400 рублей</p></td>
                <td style={{textAlign: 'right'}}> <button style={styles.paymentBtn}>Оплатить 8 занятие</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p style={styles.refund}>
            На все занятия действует <b>гарантия возврата средств:</b> если вас не устроил урок,
            его стоимость будет возвращена в полном объеме
          </p>
        </div>
      </div>
      <div style={styles.blur} onClick={() => changeChangePaymentVisible(false)}/>
    </div>
  )
};


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  changeChangePaymentVisible: (status) => dispatch(actions.changeChangePaymentVisible(status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentModal);

const blueColor = '#2972CC';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0, top: 0,
    width: '100%',
    height: '100%',
    zIndex: 1001,
    fontSize: '1.2em'
  },
  modalWrapper: {
    position: 'relative',
    zIndex: 1,
    border: '1px solid rgba(0,0,0,0.7)',
    borderRadius: '20px',
    width: '700px',
    background: 'white',
    padding: '30px 50px'
  },
  teacherInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    marginBottom: '35px'
  },
  textLeft: {
    textAlign: 'left'
  },
  teacherBio: {
    margin: '25px 0'
  },
  teacherInfo: {

  },
  paymentInfo: {
    alignItems: 'center',
    margin: '25px 0',
    width: '100%',
  },
  paymentBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%'
  },
  paymentBtn: {
    margin: '3px 0',
    width: '200px',
    padding: '5px 0',
    borderRadius: '7px',
    background: blueColor,
    border: `1px solid ${blueColor}`,
    fontWeight: 'bold',
    color: 'white'
  },
  priceList: {
    flexShrink: 0,
    height: '110px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  priceItem: {
    lineHeight: '34px',
    fontSize: '1.4em'
  },
  grayCrossStyle: {
    width: '40px',
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer'
  },
  blur: {
    cursor: 'pointer',
    position: 'absolute',
    left: 0, top: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 0
  },
  refund: {
    color: blueColor,
    textAlign: 'left'
  },
};