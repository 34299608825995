import React, {useEffect, useRef} from "react";
import grayCross from '../../../images/grayCross.png';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {userRoles} from "../../../utils/common";
import {serverMediaUrl} from "../../../utils/api";
import {Button, Select, Space} from 'antd';
import {FileImageOutlined, MinusOutlined} from '@ant-design/icons';


const PresentationModal = (props) => {
  const {
    user,
    presentation,
    changePresentationModalVisible,
    changePresentationFormModalVisible,
    changePresentationVisibleToStudents,
    changeActivePresentation,
    changePresentationImg,
    lessonSession
  } = props;

  const isLessonSessionActive = Boolean(lessonSession.token);

  const slidesBarRef = useRef(null);
  const isTeacher = user && user.role === userRoles.teacher;

  const activeImgId = presentation.activeImgId;
  const allImgs = presentation.imgs;
  const correctImgs = allImgs.filter((slide) => slide.presentationId === presentation.activePresentationId);
  const imgsLen = correctImgs ? correctImgs.length : 0;


  let currentImg = correctImgs[0];

  /**
   * Checking and getting img from current imgs list
   */
  const imgInCurrentImgsList = correctImgs.find((img) => (img.slideId === activeImgId));
  if (activeImgId && imgInCurrentImgsList)
    currentImg = imgInCurrentImgsList;

  useEffect(() => {
    const slidesBar = slidesBarRef.current;
    if (currentImg) {
      const imgEl = document.getElementById(currentImg.slideId);
      if (imgEl)
        slidesBar.scrollLeft = imgEl.offsetLeft - 300; // - 300 distance from panel edge
    }
  }, [presentation.activeImgId]);


  const handleHideModal = () => {
    if (isTeacher) {
      changePresentationModalVisible(false);
      changePresentationVisibleToStudents(false);
    }
  };

  const onChangePresentation = (presentId) => {
    const newImgs = allImgs.filter((slide) => slide.presentationId === Number(presentId));
    const newImgId = newImgs[0].slideId;
    changeActivePresentation(Number(presentId));
    changePresentationImg(newImgId);
  };

  const onClickSlideImg = (slideImgId) => {
    changePresentationImg(slideImgId);
  };

  const onClickSwitchImg = (action) => {
    const imgId = currentImg.slideId;
    let correctIndex = correctImgs.findIndex((img) => img.slideId === imgId);
    if (action === 'next')
      correctIndex += 1;
    if (action === 'prev')
      correctIndex -= 1;

    /**
     * Checking index border
     */
    if (correctIndex < 0)
      correctIndex = imgsLen-1;
    if (imgsLen-1 < correctIndex)
      correctIndex = 0;

    const newImgId = correctImgs[correctIndex].slideId;
    changePresentationImg(newImgId);
  };

  const onClickShareToStudents = () => {
    changePresentationVisibleToStudents(!presentation.visibleToStudents)
  };

  const handleClickOpenPresentationForm = () => changePresentationFormModalVisible(true);

  /**
   * Getting current img from server media
   */
  let mainImg = imgsLen && `${serverMediaUrl}${currentImg.img}`;
  if (!isTeacher && presentation && presentation.activeSlide)
    mainImg = imgsLen && `${serverMediaUrl}${presentation.activeSlideImg}`;

  const profilePresentations = presentation.allPresentations.filter(present => {
    return present.profile === user.profile
  });

  return (
    <div style={styles.container}>
      <div style={styles.modalWrapper}>
        {
          isTeacher && (
            <>
              <img src={grayCross} style={styles.grayCrossStyle} alt="" onClick={handleHideModal}/>
              <div style={{display: 'flex', margin: '0 0 10px', justifyContent: 'space-between'}}>
                <Space>
                  <Button
                    size={'large'}
                    type={'primary'}
                    style={{fontWeight: 'bold'}}
                    onClick={onClickShareToStudents}
                  >
                    {
                      (presentation.active) ? (
                        "Закрыть у учеников"
                      ) : (
                        "Открыть у учеников"
                      )
                    }
                  </Button>
                  <Select
                    size={'large'}
                    style={{minWidth: 100, maxWidth: 200}}
                    value={presentation.activePresentationId}
                    onChange={(val) => onChangePresentation(val)}
                  >
                    {
                      profilePresentations.length ? (
                        profilePresentations.map((presentation) => (
                          <Select.Option key={presentation.id} value={presentation.id}>{presentation.title}</Select.Option>
                        ))
                      ) : (
                        <Select.Option key={'empty-1'} value={0}><MinusOutlined /></Select.Option>
                      )
                    }
                  </Select>
                </Space>
                {
                  !isLessonSessionActive &&
                    <Button
                      type={'primary'}
                      size={'large'}
                      onClick={handleClickOpenPresentationForm}
                    >Добавить презентацию</Button>
                }
                {/* Добавить / Редактировать презентацию</Button>*/}
              </div>
            </>
          )
        }{
          mainImg ? (
            <div style={{...styles.slider, ...{maxHeight: isTeacher ? '775px' : '825px'}}}>
              <img src={mainImg} alt="" style={{...styles.img, ...{maxHeight: isTeacher ? '580px' : '825px'}}}/>
              <div style={styles.imgsBar} ref={slidesBarRef}>
                {
                  isTeacher && (
                    correctImgs.map((slide) => {
                      const imgSrc = `${serverMediaUrl}${slide.img}`;
                      const isActive = mainImg === imgSrc;
                      let style = styles.smallImg;
                      if (isActive)
                        style = {...style, ...styles.smallImgActive};
                      return (
                        <div key={slide.slideId} style={{textAlign: 'center'}}>
                          <img
                            alt=""
                            id={slide.slideId}
                            src={imgSrc}
                            style={style}
                            onClick={() => onClickSlideImg(slide.slideId)}
                          />
                          <p style={{fontSize: '.9em'}}>{slide.slideTitle}</p>
                        </div>
                      )
                    })
                  )
                }
              </div>
            </div>
          ) : (
            <div style={styles.noImgs}>
              <p style={{
                margin: '0 0 4px',
                color: 'rgba(0,0,0,.35)',
                fontSize: '4em'
              }}>
                <FileImageOutlined />
              </p>
              <p style={{
                margin: '0 0 4px',
                color: 'rgba(0,0,0,.85)',
                fontSize: '16px'
              }}> Вы ещё не добавили ни одной презентации!</p>
              {
                !isLessonSessionActive && <Button type={'link'} onClick={() => handleClickOpenPresentationForm()}>Создать презентацию</Button>
              }
            </div>
          )
        }{
        (isTeacher && correctImgs.length) ? (
            <div style={styles.controlsContainer}>
              <div style={styles.prev} onClick={() => onClickSwitchImg('prev')}>
                {'<'}
              </div>
              <div style={styles.next} onClick={() => onClickSwitchImg('next')}>
                {'>'}
              </div>
            </div>
          ) : (<></>)
        }
      </div>
      <div style={styles.blur} onClick={handleHideModal}/>
    </div>
  )
};


const mapStateToProps = (state) => {

  return ({
    user: state.lessonsReducer.user,
    lessonSession: state.lessonsReducer.lessonSession,
    presentation: state.lessonsReducer.presentation,
    presentationFormModalVisible: state.commonReducer.presentationFormModalVisible,
  });
}

const mapDispatchToProps = (dispatch) => ({
  changePresentationModalVisible: (visible) => dispatch(actions.changePresentationModalVisible(visible)),
  changePresentationFormModalVisible: (visible) => dispatch(actions.changePresentationFormModalVisible(visible)),
  changePresentationVisibleToStudents: (visible) => dispatch(actions.changePresentationVisibleToStudents(visible)),
  changePresentationImg: (index) => dispatch(actions.changePresentationImg(index)),
  changeActivePresentation: (presentId) => dispatch(actions.changeActivePresentation(presentId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PresentationModal);

const styles = {
  controlsContainer: {

  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    border: '1px solid rgba(0,0,0,0.2)',
    maxHeight: '600px'
  },
  imgsBar: {
    display: 'flex',
    padding: '15px 0',
    overflow: 'auto',
    width: '100%',
    flexShrink: '0',
    borderRadius: '10px',
  },
  smallImgActive: {
    border: '2px solid rgba(50,121,206,0.8)'
  },
  smallImg: {
    cursor: 'pointer',
    objectFit: 'contain',
    width: '130px',
    height: '100px',
    borderRadius: '10px',
    border: '1px solid rgba(0,0,0,0.2)',
    margin: '5px 10px'
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  prev: {
    position: 'absolute',
    top: '0', bottom: '0',
    left: '20px', margin: 'auto',
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: '15px 20px',
    background: 'rgba(0,0,0,0.3)',
    fontSize: '1.8em',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: '10px',
    cursor: 'pointer'
  },
  next: {
    position: 'absolute',
    top: '0', bottom: '0',
    right: '20px', margin: 'auto',
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: '15px 20px',
    background: 'rgba(0,0,0,0.3)',
    fontSize: '1.8em',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: '10px',
    cursor: 'pointer'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0, top: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    fontSize: '1.2em',
  },
  modalWrapper: {
    position: 'relative',
    zIndex: 1,
    border: '1px solid rgba(0,0,0,0.7)',
    borderRadius: '5px',
    width: '90vw',
    height: '90vh',
    background: 'white',
    padding: '30px 50px',
    overflow: 'hidden'
  },
  grayCrossStyle: {
    width: '40px',
    position: 'absolute',
    top: '2px',
    right: '2px',
    cursor: 'pointer'
  },
  blur: {
    cursor: 'pointer',
    position: 'absolute',
    left: 0, top: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 0
  },
  noImgs: {
    position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto', width: '600px', height: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  select: {
    margin: '10px',
    width: '140px',
    height: '44px'
  }
};
