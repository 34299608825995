import Lottie from "react-lottie";
import React, {useEffect} from "react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import successAnimationData from "../../../utils/success.json";
import {checkLessonStatus} from "../../../utils/common";


const successAnimOption = {
  loop: false,
  autoplay: true,
  animationData: successAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};



const SuccessModal = (props) => {
  const {
    changeSuccessVisible,
    successModalVisible
  } = props;

  const closeModal = () => {
    changeSuccessVisible(false)
  };

  return (
      <div style={!successModalVisible ? styles.hidden : styles.visible}>
        <div
            style={styles.successModal}
            onClick={() => closeModal()}
        >
          {
            successModalVisible && (
              <Lottie options={successAnimOption} height={100} width={100}/>
            )
          }
        </div>
        <div
            style={styles.modalStub}
            onClick={() => closeModal()}
        />
      </div>
  )
};



const mapStateToProps = (state) => ({
  successModalVisible: state.commonReducer.successModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuccessModal);

const styles = {
  hidden: {
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 1s, opacity 0.5s linear',
    zIndex: '1000',
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: '0',
    top: '0',
  },
  visible: {
    visibility: 'visible',
    transition: 'visibility 1s, opacity 0.5s linear',
    opacity: '1',
    zIndex: '1000',
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: '0',
    top: '0',
  },
  successModal: {
    zIndex: '1001',
    cursor: 'pointer',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto',
    width: '320px',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,.8)',
    borderRadius: '10px',
  },
  modalStub: {
    zIndex: '1000',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
  },
};
