import axios from "axios";
import {
  CAT_FINAL_TEST_SUCCESS, CLEAR_MAP_STATISTIC_HW,
  CREATE_MAP_STATISTIC_HW, HOMEWORK_EXECUTION_STATISTICS,
  MAP_AUTH,
  MAP_PAYMENT,
  MAP_PROGRESS,
  MAP_PROGRESS_STATUS, MAP_STATISTIC, MAP_TASK_AVAILABILITY_STATUS, MAP_TASK_RANGE_AVAILABILITY, MAP_TASK_RANGES
} from "../urls";
import {setUserWithMode} from "./lessonActions";
import {maps} from "../../views/pages/courseMap/utils";


export const catFinalTestSuccess = () => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(CAT_FINAL_TEST_SUCCESS);
  } catch (error) {
    console.log('debug error', error);
  }
  if (resp && resp.config) {
    const config = resp.config;
    dispatch(updateMapLessons(config.tasks));
  }
  return resp;
};
export const catMapPayment = () => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(MAP_PAYMENT, {mapKey: maps.catMap.key});
  } catch (error) {
    console.log('debug error', error);
  }
  if (resp && resp.status) {
    dispatch(setUserWithMode(resp.user, resp.profile));
    dispatch(updateCoursePaid(resp.coursePaid))
  }
  return resp;
};

export const courseMapAuth = (name, mapKey) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(MAP_AUTH, {name, mapKey});
  } catch (error) {
    console.log('debug error', error);
  }
  if (resp) {
    dispatch(setUserWithMode(resp.user, resp.profile));
  }
  return resp;
};

export const courseMapClearStatistic = (mapKey) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.delete(CLEAR_MAP_STATISTIC_HW+ '?mapKey='+ mapKey);
  } catch (error) {
    console.log('debug error', error);
  }
  return resp;
};

export const courseMapCreateStatistic = (mapKey) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(CREATE_MAP_STATISTIC_HW, {mapKey});
  } catch (error) {
    console.log('debug error', error);
  }
  return resp;
};

export const getOrCreateMapProgress = (initial, mapKey) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(MAP_PROGRESS, {mapKey});
  } catch (error) {
    console.log('debug error', error);
  }
  if (resp && resp.config) {
    const config = resp.config;
    dispatch(updateCourseTasksRangers(config.task_ranges));
    dispatch(updateCoursePaid(resp.coursePaid))
    if (initial) {
      dispatch(updateInitialPoints(resp.points));
    } else {
      dispatch(updatePoints(resp.points));
    }
    dispatch(updateMapLessons(config.tasks));
    dispatch(updateMapTimer(config.timer));
    dispatch(updateMapStatus(config.map_status));
  }
  return resp;
};


export const fetchMapExecutionStatistics = (mapKey) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${MAP_STATISTIC}${mapKey ? `?mapKey=${mapKey}` : ''}`);
  } catch (error) {
    console.log('debug classrooms list error', error);
  }
  return resp
};

export const updateStatusForMap = (mapKey, action) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.post(MAP_PROGRESS_STATUS, {mapKey, action});
  } catch (error) {
    console.log('debug error', error);
  }
  if (resp && resp.config) {
    const config = resp.config;
    dispatch(updateMapLessons(config.tasks));
    dispatch(updateMapTimer(config.timer));
    dispatch(updateMapStatus(config.map_status));
  }
  return resp;
};

export const getTaskAvailabilityStatus = (mapKey, lessonCode) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${MAP_TASK_AVAILABILITY_STATUS}?map_key=${mapKey}&lesson_code=${lessonCode}`);
  } catch (error) {
    console.error('debug task availability status error', error);
  }
  return resp
};

export const getTaskRanges = (mapKey) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.get(`${MAP_TASK_RANGES}?map_key=${mapKey}`);
  } catch (error) {
    console.error('debug task ranges error', error);
  }
  if (resp && resp.task_ranges) {
    dispatch(updateCourseTasksRangers(resp.task_ranges));
  }
  return resp
};

export const patchTaskRangeAvailability = (mapKey, rangeName, status=true) => async (dispatch, getState) => {
  let resp;
  try {
    resp = await axios.patch(`${MAP_TASK_RANGE_AVAILABILITY}${mapKey}/${rangeName}/`, {available: status});
  } catch (error) {
    console.error('debug task ranges error', error);
  }
  return resp
};

export const UPDATE_MAP_STATUS = 'UPDATE_MAP_STATUS';
export const updateMapStatus = (mapStatus) => {
  return {
    type: UPDATE_MAP_STATUS,
    payload: mapStatus || '',
  }
}

export const UPDATE_MAP_LESSONS = 'UPDATE_MAP_LESSONS';
export const updateMapLessons = (mapLessonsState) => {
  return {
    type: UPDATE_MAP_LESSONS,
    payload: mapLessonsState || {},
  }
}

export const UPDATE_MAP_TIMER = 'UPDATE_MAP_TIMER';
export const updateMapTimer = (mapTimerState) => {
  return {
    type: UPDATE_MAP_TIMER,
    payload: mapTimerState || {},
  }
}


export const UPDATE_POINTS = 'UPDATE_POINTS';
export const updatePoints = (points) => {
  return {
    type: UPDATE_POINTS,
    payload: points,
  }
}
export const UPDATE_INITIAL_POINTS = 'UPDATE_INITIAL_POINTS';
export const updateInitialPoints = (points) => {
  return {
    type: UPDATE_INITIAL_POINTS,
    payload: points,
  }
}

export const UPDATE_COURSE_PAID = 'UPDATE_COURSE_PAID';
export const updateCoursePaid = (coursePaid) => {
  return {
    type: UPDATE_COURSE_PAID,
    payload: coursePaid,
  }
}
export const UPDATE_COURSE_TASKS_RANGERS = 'UPDATE_COURSE_TASKS_RANGERS';
export const updateCourseTasksRangers = (rangers) => {
  return {
    type: UPDATE_COURSE_TASKS_RANGERS,
    payload: rangers,
  }
}

