import React, {PureComponent} from 'react';
import {Group} from 'react-konva';
import Measurer from './Measurer';

import {_t} from "../../../../utils/lang/common";

class MeasurerBlock extends PureComponent{
  state = {
    measurerSize: null,
  };

  move = (airBalloon) => {
    this.moveTemperature(this.calcTemperatureMeasurer(airBalloon));
    this.moveInnerDensity(this.calcInnerDensityMeasurer(airBalloon));
    this.moveOuterDensity(this.calcOuterDensityMeasurer(airBalloon));
    this.moveFuelDensity(this.calcFuelMeasurer(airBalloon));
  };

  calcTemperatureMeasurer = (airBalloon) => {
    const {physics, temperature} = airBalloon;
    return (physics.kelvin - temperature.min) / (temperature.max - temperature.min);
  }
  calcInnerDensityMeasurer = (airBalloon) => {
    const {physics, innerDensity} = airBalloon;
    return (physics.innerDensity - innerDensity.min) / (innerDensity.max - innerDensity.min);
  }
  calcOuterDensityMeasurer = (airBalloon) => {
    const {physics, outerDensity} = airBalloon;
    return (physics.outerDensity - outerDensity.min) / (outerDensity.max - outerDensity.min);
  }
  calcFuelMeasurer = (airBalloon) => {
    const {fuel: {min, max, current}} = airBalloon;
    return (current - min) / (max - min);
  }

  calcY = (index) => ((this.state.measurerSize?.height ?? 0) + MeasurerBlock.margin) * index;

  render() {
    const {airBalloon, ...props} = this.props;

    return (
      <Group {...props}>
        <Measurer
          y={this.calcY(0)}
          proportion={this.calcTemperatureMeasurer(airBalloon)}
          title={_t('balloon.temp_inside_balloon')}
          getMove={(move) => (this.moveTemperature = move)}
          getSize={(measurerSize) => this.setState({measurerSize})}
        />
        <Measurer
          y={this.calcY(1)}
          proportion={this.calcInnerDensityMeasurer(airBalloon)}
          title={_t('balloon.air_density_inside_balloon')}
          getMove={(move) => (this.moveInnerDensity = move)}
        />
        <Measurer
          y={this.calcY(2)}
          proportion={this.calcOuterDensityMeasurer(airBalloon)}
          title={_t('balloon.air_density_atmosphere')}
          getMove={(move) => (this.moveOuterDensity = move)}
        />
        <Measurer
          y={this.calcY(3)}
          proportion={this.calcFuelMeasurer(airBalloon)}
          title={_t('balloon.fuel')}
          getMove={(move) => (this.moveFuelDensity = move)}
        />
      </Group>
    );
  };

  static margin = 20;
}
export default MeasurerBlock;
