import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from "../../../../store/actions";
import MainContainer from "../../layout2/components/MainContainer";
import '../styles/index.css';
import Notifications from "../components/Notifications";
import {userRoles} from "../../../../utils/common";
import News from "../components/News";
import studentMainPageImg from '../../../../platformImages/layout2/studentMainPage.png'
import Achievements from "../components/Achievements";
import UpgradeSkill from "../components/UpgradeSkill";
import TeacherContent from "../components/TeacherContent";
import StudentContent from "../components/StudentContent";
import {Spin} from "antd";



class NewsFeed extends Component {

  constructor(props) {
    super(props);
    this.state = {
      initialLoading: false,
    }
  }

  componentDidMount() {
    this.setState({initialLoading: true}, () => {
      this.fetchNotifications();
    })
  }

  fetchNotifications = async () => {
    const props = this.props;
    await props.fetchHwTeacherNotifications();
    this.setState({initialLoading: false})
  }

  render() {
    const props = this.props;
    const state = this.state;

    const isAnonym = !props.user;
    const isTeacher = props.user?.role === userRoles.teacher;
    return (
      <MainContainer
        menuOnlyMobile
        pageKey={'news-feed'}
        containerClassname={'news-feed__container'}
      >
        <div className={'news-feed__wrapper'}>
          {
            !state.initialLoading ? (
              <>
                <Notifications />
                {
                  (isTeacher || isAnonym) ? (
                    <TeacherContent />
                  ) : (
                    <StudentContent />
                  )
                }
              </>
            ) :
              <Spin style={{width: '100%', height: '300px'}} spinning={state.initialLoading} size={'large'} >
                <div style={{height: '100px', width: '150px'}} />
              </Spin>
          }
        </div>
      </MainContainer>);
  }
}


const mapStateToProps = state => ({
  user: state.lessonsReducer.user,
});
const mapDispatchToProps = (dispatch, {match}) => ({
  setSidebarMenuVisible: (visible) => dispatch(actions.setSidebarMenuVisible(visible)),
  fetchHwTeacherNotifications: () => dispatch(actions.fetchHwTeacherNotifications()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsFeed);
