import React, {useState, useEffect} from "react";
import LessonsSwitcher from "../components/LessonsSwitcher";
import * as actions from '../../../store/actions';
import {connect} from "react-redux";
import {borderColor} from '../../../utils/styles';
import {userRoles} from '../../../utils/common';
import PaymentModal from "../components/PaymentModal";
import Header from "../components/Header";
import SuccessModal from "../components/SuccessModal";
import LessonsMenu from "../components/LessonsMenu";
import useMedia from "../../../utils/custom-hooks/useMedia";
import {_t} from '../../../utils/lang/common';
import Spaces from "../../lessons/space";
import FailureModal from "../components/FailureModal";
import { getHeight, width } from "../../canvas/containers/CanvasContainer";


const IFrame = (props) => {
  const {reduxLang, match, lessonData} = props;
  const {lang, code, id, withDescr} = match.params;

  useEffect(() => {
    props.setLanguage(lang);
  }, [reduxLang]);

  const lessonCode = `${code}${id}`;
  return (
    <div id={'frame-canvas-task-container'} data-scale={1} style={styles(lessonCode).container}>
      <LessonsSwitcher lessonCode={lessonCode} lessonData={lessonData} withDescr={Boolean(+withDescr)} isFrame={true}/>

      <SuccessModal/>
      <FailureModal/>
    </div>
  )
};

const mapStateToProps = (state) => ({
  reduxLang: state.lessonsReducer.lang,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => dispatch(actions.setLanguage(lang)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IFrame);


const styles = (lessonCode) => ({
  container: {
    width: `${width}px`,
    height: `${getHeight(lessonCode)}px`,
    margin: '0 auto',
    position: 'relative'}
});
