import React from "react";
import CanvasContainer, {height} from "../../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, RegularPolygon, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import backgroundImg from "../../../../../images/snowboard/background.png";
import indicatorImg from "../../../../../images/snowboard/indicator.png";
import manImg from "../../../../../images/snowboard/man.png";
import treeImg from "../../../../../images/snowboard/tree.png";
import mainInfoFormulaImg from "../../../../../images/snowboard/mainInfoFormula.png";
import speedFormulaImg from "../../../../../images/snowboard/speedFormula.png";

import CanvasResetBtn from "../../../../canvas/components/CanvasResetBtn";
import {getTimeDeltaSec, inputVal, sendSuccessForScenario} from "../../../../../utils/common";
import Card from "../../../../canvas/components/Card";
import {layout2, mainColor} from "../../../../../utils/styles";
import {Player} from "../../../../canvas/components/Player/Player";
import ScenarioManager from "../../../../../utils/ScenarioManager";
import {snowboardScenario, initialData} from "../scenario";
import ArrowHint from "../../../../canvas/components/ArrowHint";
import CanvasInput from "../../../../canvas/components/CanvasInput";
import CanvasButton from "../../../../canvas/components/CanvasButton";
import * as actions from "../../../../../store/actions";




class Snowboard extends React.Component {
  constructor(props) {
    super(props);

    this.staticData = {};
    this.initialData = initialData;
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(snowboardScenario, this);
    this.managedComponents = [
      'stage',

      'man',
      'title',

      'btnStart',
      'btnStart2',

      'speedTxt',
      'heightTxt',
      'kineticEnergyTxt',
      'potentialEnergyTxt',

      'heightInCard',

      'indicatorSpeed',

      'greenRectEnergy',
      'orangeRectEnergy',

      'hintArrow',
      'hintArrowText',

      'prevBtn',
      'playBtn',
      'stopBtn',
      'nextBtn',


      'heightArrow',
      'heightArrowTxt',
      'heightArrowHelperRect',

      'draggableContainer',
      'draggingArrows',
    ];

    this.descentR = 225;
    this.descentFunc = (x) => Math.sqrt(Math.pow(this.descentR, 2) - Math.pow(x-this.descentR, 2)) - 30;

    // на глаз подобранный кусок окружности, по которой должны двигаться сани
    const ys = new Array(225).fill(1).map((el, i) => this.descentFunc(i));
    this.points = [];
    for (let x in ys) {
      this.points.push(parseInt(x));
      this.points.push(ys[x]);
    }
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.setCorrectStep();

    this.timerId = setTimeout(() => {
      if (this.props.code === 'peryshkin18') {
        sendSuccessForScenario(this);
      }
    }, 8000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }
  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.setCorrectStep();
  };

  setCorrectStep = () => {
    let step = 'step1';
    switch (this.props.code) {
      case 'peryshkin19':
        step = 'step2';
        break;
      case 'peryshkin20':
        step = 'step3';
        break;
      case 'peryshkin21':
        step = 'step4';
        break;
      default:
        step = 'step1';
    }
    this.scenarioManager.selectStepByKey(step);
    // сначала фиксируем общую энергию, она не может быть больше, если физика задачи верна
    this.setFullEnergy();
  }


  onDragMan = (e) => {
    const data = this.data;
    const pos = e.target.getPosition();

    let correctY = pos.y;
    if (correctY < -data.maxTrackHeightPx) {
      correctY = -data.maxTrackHeightPx;
    }
    if (correctY > 0) {
      correctY = 0;
    }

    data.draggableContainerPos = {x: 0, y: correctY};
    data.passedPath = -Math.PI * 225 / 2 + correctY + 15;
    data.speed = 0.1;
    data.speedIndicator = 0;
    data.started = false;

    this.setFullEnergy();

    data.dragging = true;
    data.started = true;
  }

  onClickPrevBtn = () => {

  }
  onClickPlayBtn = () => {
    this.data.started = true;
    this.data.play = true;
  }
  onClickStopBtn = () => {
    this.data.play = false;
  }
  onClickNextBtn = () => {

  }
  onClickStart = () => {
    this.data.started = true;
    this.data.play = true;
  }

  get correctHeightM(){
    const data = this.data;
    return Number((
      Math.abs(data.draggableContainerPos.y / (data.maxTrackHeightPx / 100))
      * data.maxTrackHeightM/100
      + data.defaultTrackHeightM
    ).toFixed(1))
  }

  manPosition = () => {
    const data = this.data;
    // параметризуется пройденным путём
    const R = this.descentR;
    // console.log('debug on path', data.passedPath);
    const quarterCircle =  Math.PI * R / 2;
    const secondCircleStart = data.secondCircleStart;

    const extraHeight = Math.abs(data.draggableContainerPos.y);
    // console.log('debug on position', -extraHeight - quarterCircle, data.passedPath);

    if (-extraHeight - quarterCircle <= data.passedPath && data.passedPath < -quarterCircle ) {
      return {x: -R, y: data.passedPath + quarterCircle - R , rotation: Math.PI / 2}
    } else if (-quarterCircle <= data.passedPath && data.passedPath < 0) {
      const passedAngle = quarterCircle + data.passedPath / R;
      return {x: R * ( - Math.cos(passedAngle)), y: R * (-1 + Math.sin(passedAngle)), rotation: (Math.PI/2 - passedAngle)}
    } else if ( 0 <= data.passedPath && data.passedPath < secondCircleStart ) {
      return {x: data.passedPath, y: 0, rotation: 0}
    } else if ( secondCircleStart <= data.passedPath && data.passedPath < secondCircleStart + quarterCircle ) {
      const secondCirclePassedPath = data.passedPath - secondCircleStart;
      const passedAngle = secondCirclePassedPath / R;
      return {x: secondCircleStart + R * ( Math.sin(passedAngle)), y: R * (-1 + Math.cos(passedAngle)), rotation: (-passedAngle)}
    } else if (this.props.code === 'peryshkin21') {
      if (data.speed < 10) {
        console.log('Отлично!');
      } else {
        console.log('Потрачено!');
      }
      return {x: 0, y: 0, rotation: 0};
      // todo вынести reset data, вызывать здесь
    } else {
      // console.log('debug on passed path', data.passedPath, secondCircleStart + quarterCircle);
      return {x: 0, y: 0, rotation: 0};
    }

  };

  meterPerPx = 1/42;

  getHeight = () => (-this.manPosition()?.y * this.meterPerPx || 0);
  getSpeed = () => Math.abs(+this.data.speed * this.meterPerPx);

  potentialEnergy = () => {
    return 50 * 10 * this.getHeight();
  };

  kineticEnergy = () => {
    return 50 * Math.pow(this.getSpeed() , 2) / 2;
  };

  setFullEnergy = () => {
    this.data.fullEnergy = this.potentialEnergy() + this.kineticEnergy();
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timeDeltaSec = getTimeDeltaSec(timedelta);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    if (data.play) {
      data.passedPath += data.speed * data.timeDeltaSec;

      const absSpeed = Math.sqrt(Math.abs(data.fullEnergy - this.potentialEnergy()) / 50 * 2) / this.meterPerPx;
      if (absSpeed > 10) {
        data.speed = data.speedSign * absSpeed
      } else {
        // console.log('debug on man position', this.manPosition()?.x );
        if (this.manPosition()) data.speedSign = Math.sign(-this.manPosition()?.x + 250);
      }
    }

    // проставление значения индикатора
    if (data.passedPath >= 200 && !data.speedIndicator) {
      data.speedIndicator = this.getSpeed();
    }

    // чтобы не скрывать первый спуск в 4-й задаче, просто ограничиваем движение сноубордиста
    if (this.props.code === 'peryshkin21' && data.passedPath < 0) {
      this.onClickReset();
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const staticData = this.staticData;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['heightTxt'].text(`${this.getHeight().toFixed(1)}`);
    n['speedTxt'].text(`${this.getSpeed().toFixed(1)}`);
    n['kineticEnergyTxt'].text(`${(this.kineticEnergy()/1000).toFixed(1)} кДж`);
    n['potentialEnergyTxt'].text(`${(this.potentialEnergy()/1000).toFixed(1)} кДж`);

    n['indicatorSpeed'].text(`${data.speedIndicator.toFixed(1)}`);
    n['title'].text(data.title.value);

    // max width 200px
    n['orangeRectEnergy'].width(200 * this.kineticEnergy() / data.fullEnergy);
    // n['greenRectEnergy'].width(200);


    n['man'].position(this.manPosition());
    n['man'].rotation(this.manPosition().rotation * 180 / Math.PI - 5 * data.speedSign);
    n['man'].scaleX(data.speedSign);

    n['btnStart'].visible(!data.play);
    n['btnStart2'].visible(!data.play);

    // if (data.heightDragActive && data.dragging) {
      n['draggableContainer'].position(data.draggableContainerPos);
      // n['man'].position(data.draggableContainerPos);

      n['heightArrowHelperRect'].height(data.draggableContainerPos.y);
      n['heightArrow'].points([0,0,0,-220+data.draggableContainerPos.y]);
      n['heightArrowTxt'].y(230+data.draggableContainerPos.y);
      n['heightArrowTxt'].height(220-data.draggableContainerPos.y);
    // }

    n['draggingArrows'].visible(!data.started);

    n['heightInCard'].text(this.correctHeightM);

    n['hintArrow'].visible(!data.started);

    n['hintArrow'].visible(!data.started);
    const correctHintArrowStg = data.inputVal ? data.hintArrowStg2 : data.hintArrowStg
    if (correctHintArrowStg) {
      n['hintArrowText'].text(correctHintArrowStg.text);
      n['hintArrow'].position({
        y: correctHintArrowStg.y,
        x: correctHintArrowStg.x,
      });
    }


    n['playBtn'].visible(!data.play);
    n['stopBtn'].visible(data.play);


    n['stage'].draw();
  }

  Canvas = () => {
    const data = this.data;
    const staticData = this.staticData;

    const [background] = useImage(backgroundImg);
    const [indicator] = useImage(indicatorImg);
    const [man] = useImage(manImg);
    const [track] = useImage(data.trackImg);
    const [tree] = useImage(treeImg);

    const [mainInfoFormula] = useImage(mainInfoFormulaImg);
    const [speedFormula] = useImage(speedFormulaImg);



    return (
      <React.Fragment>
        <Group>
          <Image image={background}/>

          <Image x={0} y={228} image={track}/>

          <Text
            ref={this._ref('title')}
            text={data.title.value}
            fill={mainColor}
            fontSize={30}
            {...data.title.stg}
          />


          <Group x={90} y={30} visible={data.cardInfoVisible}>
            <Image image={mainInfoFormula}/>
            <Group x={80} y={30}>
              <Group y={12}>
                <Text width={40} align={'center'} ref={this._ref('speedTxt')} text={'0'} fontSize={20} fill={mainColor} fontStyle={'bold'}/>
                <Text width={40} align={'center'} y={52} ref={this._ref('heightTxt')} text={'0'} fontSize={20} fill={mainColor} fontStyle={'bold'}/>
              </Group>
              <Text x={42} y={63} text={'м'} fontSize={20} fill={mainColor} fontStyle={'italic'}/>

              <Group x={163}>
                <Text y={12} ref={this._ref('kineticEnergyTxt')} text={'0 Дж'} fontSize={20} fill={layout2.orange} fontStyle={'bold'}/>
                <Text y={63} ref={this._ref('potentialEnergyTxt')} text={'0 Дж'} fontSize={20} fill={layout2.green} fontStyle={'bold'}/>
              </Group>
            </Group>
          </Group>


          <Group x={800} y={180} visible={data.cardInputHeightVisible}>
            <Card width={120} height={100}>
              <Group x={20} y={20}>
                <Text text={'h'} fontSize={22} fill={mainColor} fontStyle={'italic'}/>
                <Text x={12} text={', м:'} fontSize={22} fill={mainColor}/>
                <Text
                  ref={this._ref('heightInCard')} x={0} y={30} text={'0'} fontSize={25}
                  fontStyle={'bold'} fill={mainColor} visible={data.withoutInput}/>

                <Group visible={!data.withoutInput}>
                  <CanvasInput
                    id={'1'}
                    y={25}
                    x={0}
                    width={80}
                    height={30}
                    stage={this.stageRef?.current}
                    onInput={(val) => {
                      this.data.inputVal = Number(inputVal(val));

                      if (this.data.inputVal > 10) {
                        this.data.inputVal = 10;
                      }

                      const heightDeltaMeter = this.data.inputVal - this.descentR * this.meterPerPx;
                      const correctY = - heightDeltaMeter / this.meterPerPx;

                      this.data.draggableContainerPos = {x: 0, y: correctY};
                      this.data.passedPath = -Math.PI * 225 / 2 + correctY + 15;
                      this.data.speed = 0.1;
                      this.data.speedIndicator = 0;
                      this.data.play = false;
                      this.data.started = false;
                      this.setFullEnergy();
                    }}
                    value={data.inputVal}
                  />
                </Group>
              </Group>
            </Card>
            <CanvasButton
              btnRef={this._ref('btnStart')}
              y={120}
              text={'Старт'}
              onClick={this.onClickStart}
              fontSize={20}
              height={40}
              width={120}
              strokeWidth={.2}
              btnCornerRadius={0}
            />
          </Group>


          <Group x={60} y={55} visible={data.cardInputSpeedVisible}>
            <Card width={160} height={230}>
              <Group x={20} y={20}>
                <Group y={5}>
                  <Text text={'h'} fontSize={22} fill={mainColor} fontStyle={'italic'}/>
                  <Text x={12} text={', м:'} fontSize={25} fill={mainColor}/>
                  <Text y={30} text={`${(this.descentR * this.meterPerPx).toFixed(1)}`} fontSize={35} fill={mainColor} fontStyle={'bold'}/>
                </Group>
                <Group y={85}>
                  <Image width={70} height={50} image={speedFormula}/>
                  <CanvasInput
                    id={'1'}
                    y={50}
                    x={0}
                    width={117}
                    height={50}
                    fontSize={40}
                    fontStyle={'bold'}
                    stage={this.stageRef?.current}
                    onInput={(val) => {
                      this.data.inputVal = Number(inputVal(val));
                      this.data.speed = this.data.inputVal/this.meterPerPx;
                      this.data.speedIndicator = 0;
                      this.data.play = false;
                      this.data.started = false;
                      this.setFullEnergy();
                    }}
                    value={data.inputVal}
                  />
                </Group>
              </Group>
            </Card>

            <CanvasButton
              btnRef={this._ref('btnStart2')}
              y={250}
              text={'Старт'}
              onClick={this.onClickStart}
              fontSize={25}
              height={50}
              width={160}
              strokeWidth={.2}
              btnCornerRadius={0}
            />
          </Group>


          <Group x={464} y={365} visible={data.speedIndicatorVisible}>
            <Image image={indicator}/>
            <Text
              ref={this._ref('indicatorSpeed')}
              width={75} align={'center'} x={0} y={12}
              fontStyle={'bold'} text={'0'} fontSize={20} fill={mainColor}/>
          </Group>



          <Group x={450} y={320} visible={data.energyColorIndicator}>
            <Group>
              <Group x={160}>
                <Text y={-5} text={'Е'} fill={layout2.green} fontSize={35} fontStyle={'bold'}/>
                <Text x={23} y={7} text={'п'} fill={layout2.green} fontSize={25} fontStyle={'bold'}/>
              </Group>
            </Group>
            <Group>
              <Group x={-85}>
                <Text x={-13} y={-5} text={'Е'} fill={layout2.orange} fontSize={35} fontStyle={'bold'}/>
                <Text x={10} y={7} text={'к'} fill={layout2.orange} fontSize={25} fontStyle={'bold'}/>
              </Group>
            </Group>
            <Rect ref={this._ref('greenRectEnergy')} x={-50} width={200} height={20} fill={layout2.green} />
            <Rect ref={this._ref('orangeRectEnergy')} x={-50} width={50} height={20} fill={layout2.orange}/>
          </Group>



          <Group x={450} y={500} visible={data.playerVisible}>
            <Player
              x={0} y={0}

              prevBtnRef={this._ref('prevBtn')}
              playBtnRef={this._ref('playBtn')}
              stopBtnRef={this._ref('stopBtn')}
              nextBtnRef={this._ref('nextBtn')}

              // onClickPrevBtn={this.onClickPrevBtn}
              onClickPlayBtn={this.onClickPlayBtn}
              onClickStopBtn={this.onClickStopBtn}
              // onClickNextBtn={this.onClickNextBtn}
            />
          </Group>

          <Image x={930} y={100} image={tree} visible={data.treeVisible}/>
        </Group>


        <Group x={70} y={0} visible={data.heightDragVisible}>

          <Rect ref={this._ref('heightArrowHelperRect')} height={-0} x={-70} y={230} width={79.5} fill={'#F9F2E5'} opacity={1}/>

          <Arrow ref={this._ref('heightArrow')} y={450} points={[0,0,0,-220]} fill={layout2.gray} stroke={layout2.gray}/>
          <Arrow y={449} points={[0,0,0,1]} stroke={layout2.gray} fill={layout2.gray}/>
          <Text ref={this._ref('heightArrowTxt')} x={-20} y={230} height={220} verticalAlign={'middle'} text={'h'} fill={layout2.gray} fontSize={20}/>

          <Line x={-70} y={455} points={[0,0,1000,0]} dash={[3,3]} stroke={layout2.gray}/>
        </Group>


        {/*Сноубордист*/}
        <Group x={78 + 225} y={457}>
          {/*<Line y={-225} x={-13} points={this.points} stroke={'black'} opacity={0.2}/>*/}
          <Image
            ref={this._ref('man')} offsetY={48} offsetX={25} width={50} height={50} image={man}/>
        </Group>


        <Group
          ref={this._ref('draggableContainer')}
          draggable={data.heightDragActive}
          onDragMove={this.onDragMan}
          onDragEnd={() => data.dragging = false}
        >
          <Group x={50} y={150} visible={data.heightDragActive}>
            <Rect x={-30} y={-30} width={130} height={130} fill={'red'} opacity={0}/>
            <Group y={50} ref={this._ref('draggingArrows')}>
              <Arrow points={[0,0,0,30]} stroke={mainColor} fill={mainColor}/>
              <Arrow points={[0,0,0,-30]} stroke={mainColor} fill={mainColor}/>
            </Group>
          </Group>

        </Group>



        <ArrowHint
          ref={this._ref('hintArrow')}
          textRef={this._ref('hintArrowText')}
          {...data.hintArrowStg}
        />

        <CanvasResetBtn onClick={() => this.onClickReset()}/>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Snowboard);

const styles = {
  mainContainer: {
    background: '#EDEBE5'
  }
};
