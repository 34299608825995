import React, {useState} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getTimeDeltaSec} from "../../../../utils/common";
import bgImg from '../../../../images/skiBond/background2.png';
import Card from "../../../canvas/components/Card";
import {scenario} from "../scenario";
import {layout2} from "../../../../utils/styles";
import CanvasButton from "../../../canvas/components/CanvasButton";
import Theory from "../../../components/theory/Theory";
import VideoBlock from "../../../canvas/components/CanvasVideoBlock";
import Lottie from "react-lottie";
import CanvasSuccessCard from "../../../canvas/components/CanvasSuccessCard";
import * as actions from "../../../../store/actions";



class SkiBond extends React.Component {
  constructor(props) {
    super(props);

    this.skiItems = [
      { id: 1, h: 1, a: 10 },
      { id: 2, h: 1.1, a: 12 },
      { id: 3, h: 1.1, a: 13 },
      { id: 4, h: 1.3, a: 15 },
    ];

    this.managedComponents = [
      'stage',
    ]

    this.staticData = {
      correctSkiIds: [3,4]
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      selectedSki: undefined,
      errorCounter: 0,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  onSelectSkiItem = (skiId) => {
    if (this.data.selectSki) {
      this.data.selectedSki = skiId;
      const success = this.staticData.correctSkiIds.includes(skiId);
      if (!success) {
        this.data.errorCounter++;
      }
      setTimeout(() => {
        this.scenarioManager.selectStepByKey(success ? 'success animation' : 'fail animation');
      }, 0);
    }
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);



    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    n['stage'].draw();
  }


  Env = () => {
    const data = this.data;
    const [bg] = useImage(bgImg);
    return (
      <>
        <Image image={bg}/>

        <Rect width={1000} height={90} fill={'rgba(0,0,0,0.5)'}/>
        <Text
          x={30} y={0}
          text={data.title}
          fill={'white'}
          fontSize={25}
          lineHeight={1.2}
          verticalAlign={'middle'}
          height={90}
        />

        <Card x={770} y={140} height={280} width={200}>
          <Group x={20} y={25}>
            <Group>
              <Text text={'Масса \nспецагента, кг:'} fontSize={18}/>
              <Text y={45} text={'70'} fontStyle={'bold'} fontSize={35}/>
            </Group>
            <Group y={110}>
              <Text text={'Предельное \nдавление \nна снег, Па:'} fontSize={18}/>
              <Text y={60} text={'2500'} fontStyle={'bold'} fontSize={35}/>
            </Group>
          </Group>
        </Card>

        <CanvasButton
          text={'Далее'}
          onClick={() => this.scenarioManager.next()}
          fontSize={28}
          strokeWidth={.1}
          btnCornerRadius={0}
          btnFill={'lightgray'}
          visible={data.nextBtnVisible}
          width={160}
          height={50}
          x={800}
          y={500}
        />

      </>
    )
  };

  Ski = (props) => {
    const Item = ({x, el}) => {
      const [visible, setVisible] = useState(false);
      return (
        <Group
          x={x}
          onClick={() => this.onSelectSkiItem(el.id)}
          onTap={() => this.onSelectSkiItem(el.id)}
          onMouseEnter={() => this.data.selectSki && setVisible(true)}
          onMouseLeave={() => this.data.selectSki && setVisible(false)}
        >
          <Rect x={-17} width={120} height={360} fill={'red'} opacity={0}/>
          <Rect visible={visible} width={90} height={310} stroke={'white'} cornerRadius={10} fill={'transparent'} strokeWidth={1.5}/>

          <Group x={35} y={320}>
            <Text text={`h = ${el.h} м`} fontSize={16}/>
            <Text y={20} text={`a = ${el.a} см`} fontSize={16}/>
          </Group>
        </Group>
      )
    }

    return (
      <Group {...props}>
        {
          this.skiItems.map((el, i) => {
            return <Item key={el.id} x={127*i} el={el}/>
          })
        }
      </Group>
    )
  }

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this._ref('stage')}
          lessonCode={this.props.code}

          component={this}
          withVideo={true}
          videoVisible={this.data.videoSrc}
          theoryVisible={this.data.theoryVisible}
          additionalTheoryStgs={{
            skipInitialStep: true,
            hideResetBtn: true,
            bgColor: 'white',
          }}
        >
          <Layer preventDefault={false}>
            <this.Env/>

            <this.Ski x={100} y={155} />


            <Card x={250} y={100} width={500} height={400} cornerRadius={20} visible={this.data.lastStep}>
              <Text y={50} width={500} align={'center'} text={'Задача выполнена'} fill={'#315259'} fontSize={40}/>

              <Group x={130} y={230}>
                <CanvasButton
                  text={'Посмотреть теорию'}
                  onClick={() => {
                    this.data.errorCounter = 0;
                    this.scenarioManager.selectStepByKey('theory')
                  }}
                  fontSize={20}
                  strokeWidth={.1}
                  btnCornerRadius={0}
                  btnFill={'#BCDDE0'}
                  textFill={'#315259'}
                  width={250}
                  height={45}
                />
                <CanvasButton
                  text={'Начать заново'}
                  onClick={() => this.onClickReset()}
                  fontSize={20}
                  strokeWidth={.1}
                  btnCornerRadius={0}
                  btnFill={'#BCDDE0'}
                  textFill={'#315259'}
                  width={250}
                  height={45}
                  y={70}
                />
              </Group>
            </Card>

            {
              this.data.lastStep && (
                <CanvasSuccessCard
                  goToTheory={() => this.scenarioManager.selectStepByKey('theory')}
                  onClickReset={() => this.onClickReset()}
                />
              )
            }

          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SkiBond);

const styles = {
  mainContainer: {
    background: '#515253'
  }
};
