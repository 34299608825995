import React from "react";
import {Spin} from 'antd';


const SectionWrapper = (props) => (
  <div style={props.wrapperStyles} className={'sectionWrapper ' + props.wrapperClassname||''}>
    <div style={{display: 'flex', alignItems: 'center', margin: '60px 0 20px', ...props.titleContainerStyle}}>
      <h3>{props.title}</h3>
      { props.btnAfterTitle ? props.btnAfterTitle : null }
      <Spin spinning={!!props.loading} style={{margin: '0 10px'}}/>
    </div>
    {props.children}
  </div>
);

export default SectionWrapper;
