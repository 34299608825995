import React from "react";
import {CaretLeftOutlined} from '@ant-design/icons';
import './dialog.css';


const DialogPopup = (props) => {

  return (
    <div className={'theory-dialog'} style={props.style} onClick={() => props.onClick?.()}>
      <div className={'theory-dialog__content'}>
        {props.children}
      </div>
      <CaretLeftOutlined className={'theory-dialog__arrow'}/>
    </div>
  )
}

export default DialogPopup;