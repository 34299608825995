import { serverBaseUrl } from "../../../utils/serverBaseUrl";

export const getCodeUrlByTaskCode = (code) => {
  const taskId = code.match(/[0-9]*$/)[0];
  const taskCodename = code.slice(0, code.length - taskId.length);
  return `${taskCodename}/${taskId}`;
}


export const goToTaskUrlGenerator = (code, selectedHwId, history) => {
  const taskCode = getCodeUrlByTaskCode(code);
  const currentPathname = history.location.pathname;
  const corrPathname = currentPathname.replace(/\//g, '');

  let lessonLink = `/task/ru/${taskCode}`;

  // Add homeworkId to link to set it in redux
  // And to get homeworkId from inside the task page after page reload (from redux)
  //
  // /1 - parameter (:withDescr) for description
  // todo it should probably be removed in the future or change to a get-parameter
  //
  if (selectedHwId) {
    lessonLink += `/1/${selectedHwId}`
  }

  // Set "withback" get-parameter to make the "GoBack" link visible
  lessonLink += `?withback=1`;

  // 'back' get-parameter, indicates the page from which went
  if (corrPathname) {
    lessonLink += `&back=${corrPathname}`;
  }

  return lessonLink;
}

export const editTaskUrlGenerator = (code, selectedHwId, history) => {
  const taskId = code.match(/[0-9]*$/)[0];
  //todo: change the link to normal data exchange
  let lessonLink = `${serverBaseUrl}container_test/container/${taskId}/edit/0`;

  return lessonLink;
}
