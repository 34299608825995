import React from "react";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {Image, Text} from "react-konva";
import animationStartMp4 from '../../../../images/space/theory/Animation_start.mp4';
import animationMiddleMp4 from '../../../../images/space/theory/Animation_middle.mp4';
import {createTheoryStepsList, theoryDefaultData} from "./theoryGenerator";


export const spaceshipLandingElementsUI = {
  title: '',
  secondTitle: '',
  centerBtnText: '',

  infoCardVisible: false,

  cyberpunkCardVisible: false,
  cyberpunkCardInputDisabled: false,
  cyberpunkCardLineVisible: false,
  cyberpunkCardBtnVisible: true,
  autopilotMotionStarted: false,
  cyberpunkBtnClick: (component) => {component.data.autopilotMotionStarted = true},
  cyberpunkCardPos: {x: 300, y: 150},

  resetSpeed: (comp) => {},
  resetSpeedBtnVisible: false,

  centerBtnPos: {x: 350, y: 250},

  shipVisible: false,
  shipEngineVisible: false,
  shipBreak: false,
  shipPathLines: false,

  alertIndicatorVisible: false,
  alertIndicatorText: '', // Тревога, Режим автопилота

  centerBtnVisible: false,

  controllerVisible: false,

  centerBtnClick: (component) => {console.log('center btn click')},


  // ANIMATIONS
  animationBlock: false,
  animationStg: {},


  //  THEORY
  ...theoryDefaultData,
}



const autopilot2PatchData = {
  title: 'Рассчитай необходимую скорость снижения относительно ветра \nдля точного приземления. ' +
    'Горизонтальная скорость корабля, \nвызванная сильным ветром, составляет 10 км/ч',

  shipPos: {x: 820, y: 375},
  shipVisible: true,
  alertIndicatorVisible: true,
  alertIndicatorText: 'Режим автопилота',

  infoCardVisible: true,

  shipPathLines: true,
  cyberpunkCardVisible: true,
  cyberpunkCardLineVisible: true,
  cyberpunkCardPos: {x: 400, y: 250},
}

const shipLandingFailure = {
  shipSpeed: {x: 0, y: 0},
  shipVisible: true,
  shipBreak: true,

  centerBtnVisible: true,
  centerBtnText: 'Повторить посадку',
  centerBtnClick: (component) => {
    component.scenarioManager.selectStepByKey('ship landing')
  }
}
const autopilotFailurePatchData = {
  title: 'Посадка совершена вне области приземления',
  shipVisible: true,

  alertIndicatorVisible: true,
  alertIndicatorText: 'Режим автопилота',

  centerBtnPos: {x: 500, y: 250},
  centerBtnVisible: true,

  cyberpunkCardInputDisabled: true,
  cyberpunkCardVisible: true,
  cyberpunkCardBtnVisible: false,
  cyberpunkCardPos: {x: 100, y: 200},
}
const questionTextStyles = {
  fill: '#4f558c', fontSize: 20,
  lineHeight: 1.2,
  y: -20
}


export const spaceshipLandingScenario = [

  {
    key: 'initial animation',
    patchData: {
      title: '',
      inputSinkRateVal: undefined,

      animationBlock: true,
      animationStg: {
        src: animationStartMp4,
      },
    },
    next_success: 'ship landing',
    next_failure: 'ship landing',
    previous: 'initial animation',
  },

  {
    key: 'ship landing',
    patchData: {
      title: 'Корабль сильно сносит. \nКорректируй курс, чтобы успешно приземлиться',
      secondTitle: 'Используй клавиши на клавиатуре / на экране.',
      shipSpeed: {x: -20, y: 0},
      shipRotation: 15,
      shipPos: {x: 800, y: 150},
      shipVisible: true,

      controllerVisible: true,
    },
    next_success: 'ship landing success',
    next_failure: 'ship landing failure',
    next_btn_hidden: true,
  },

  {
    key: 'ship landing failure',
    patchData: {
      ...shipLandingFailure,
      title: 'Посадка совершена вне области приземления',
    },
  },{
    key: 'ship landing angle failure',
    patchData: {
      ...shipLandingFailure,
      title: 'Превышен допустимый угол отклонения корабля при посадке',
    },
  },

  {
    key: 'ship landing success',
    patchData: {
      title: 'Посадка совершена успешно!',

      // shipPos: {x: 450, y: 552},
      shipRotation: 0,
      shipSpeed: {x: 0, y: 0},
      shipVisible: true,

      centerBtnVisible: true,
      centerBtnText: 'Продолжить',
      centerBtnClick: (component) => {
        component.scenarioManager.selectStepByKey('animation 2')
      }
    },
    next_failure: 'ship landing failure',
    next_success: 'animation2',
  },




  {
    key: 'animation 2',
    patchData: {
      title: '',
      animationBlock: true,
      animationStg: {
        src: animationMiddleMp4,
      },
    },
    next_success: 'initial autopilot',
    next_failure: 'animation 2',
  },





  {
    key: 'initial autopilot',
    patchData: {
      title: 'Система ручного управления посадкой вышла \n' +
        'из строя. Необходимо запрограммировать \nавтопилот на самостоятельную посадку.',

      shipPos: {x: 820, y: 375},
      shipVisible: true,

      alertIndicatorVisible: true,
      alertIndicatorText: 'Тревога',
    },
    switch_at: 5,
    next_success: 'autopilot 2',
    next_failure: 'initial autopilot',
  },{
    key: 'autopilot 2',
    patchData: {
      ...autopilot2PatchData,
      cyberpunkBtnClick: (component) => {
        if (!!component.data.inputSinkRateVal) {
          component.autopilotAttempts += 1;
          component.scenarioManager.selectStepByKey('autopilot start motion');
        }
      },
      shipEngineVisible: true,
    },
    next_success: 'autopilot success',
    next_failure: 'autopilot failure',
  },{
    key: 'autopilot 2 second attempt',
    patchData: {
      ...autopilot2PatchData,
      cyberpunkBtnClick: (component) => {
        if (!!component.data.inputSinkRateVal) {
          component.autopilotAttempts += 1;
          component.scenarioManager.selectStepByKey('autopilot start motion 2');
        }
      },
      shipEngineVisible: true,
    },
    next_success: 'autopilot success',
    next_failure: 'autopilot failure 2',
  },


  {
    key: 'autopilot start motion',
    patchData: {
      ...autopilot2PatchData,
      cyberpunkCardVisible: false,
      shipPathLines: false,
      autopilotMotionStarted: true,
      shipEngineVisible: true,
      resetSpeedBtnVisible: true,
      resetSpeed: (comp) => {
        comp.data.inputSinkRateVal = undefined;
        comp.scenarioManager.selectStepByKey('autopilot 2');
      }
    },
    next_success: 'autopilot success',
    next_failure: 'autopilot failure',
    next_btn_hidden: true,
  },{
    key: 'autopilot start motion 2',
    patchData: {
      ...autopilot2PatchData,
      cyberpunkCardVisible: false,
      shipPathLines: false,
      autopilotMotionStarted: true,
      shipEngineVisible: true,
      resetSpeedBtnVisible: true,
      resetSpeed: (comp) => {
        comp.data.inputSinkRateVal = undefined;
        comp.scenarioManager.selectStepByKey('autopilot 2 second attempt');
      }
    },
    next_success: 'autopilot success',
    next_failure: 'autopilot failure 2',
    next_btn_hidden: true,
  },


  {
    key: 'autopilot failure',
    patchData: {
      ...autopilotFailurePatchData,
      centerBtnText: 'Повторить посадку',
      centerBtnClick: (component) => {
        component.scenarioManager.selectStepByKey('autopilot 2 second attempt')
      },
    },
    next_success: 'autopilot success',
    next_failure: 'autopilot failure 2',
    next_btn_hidden: true,
  },
  {
    key: 'autopilot failure 2',
    patchData: {
      ...autopilotFailurePatchData,
      centerBtnText: 'Далее',
      centerBtnClick: (component) => {
        component.failureSecondTime = true; // Добавляет параметр для переадресации в блоке теории (при нажатии на "Пропустить теорию" ведет на форму вычисления)
        component.scenarioManager.selectStepByKey('initial theory')
      },
    },
    next_success: 'initial theory',
    next_failure: 'initial theory',
    next_btn_hidden: true,
  },
  {
    key: 'autopilot success',
    patchData: {
      title: 'Посадка совершена успешно!',

      shipPos: {x: 470, y: 545},
      shipVisible: true,

      inputSinkRateVal: 5,

      cyberpunkCardInputDisabled: true,
      cyberpunkCardVisible: true,
      cyberpunkCardBtnVisible: false,
      cyberpunkCardPos: {x: 100, y: 200},
    },
    next_success: 'initial theory',
    next_failure: 'initial theory',
    switch_at: 3
  },

  ...createTheoryStepsList(
    {
        theoryVisible: true,
        projectorVisible: true,
        questionsListVisible: true,
        theoryQuestions: [
          {id: 1,stepY: 0, text: 'Чему равна скорость корабля?', scenarioKey: 'theory Qst. 1'},
          {id: 2,stepY: 70, text: 'Что такое скорость снижения?', scenarioKey: 'theory Qst. 2'},
          {id: 3,stepY: 140, text: 'Чем отличается скорость снижения \nот скорости корабля относительно \nпланеты?', scenarioKey: 'theory Qst. 3'},
          {id: 4,stepY: 240, text: 'Зачем нужно знать расстояние \nдо космодрома?', scenarioKey: 'theory Qst. 4'},
        ],
        skipTheoryOnClick: (component) => {
          let skipTheoryKey = component.failureSecondTime ? 'autopilot 2' : 'success';
          component.scenarioManager.selectStepByKey(skipTheoryKey);
          component.failureSecondTime = false;
        }
      },
    [
      {
        key: 'theory Qst. 1',
        patchData: {
          theoryVisible: true,

          projectorVisible: true,
          projectorContent: (images) => (
            <>
              <Text text={
                'Невозможно ответить на вопрос. Движение тела -\n' +
                'это изменение положения тела в пространстве \n' +
                'относительно других тел.\n' +
                'Скорости корабля относительно планеты,\n' +
                'относительно ветра и относительно капитана корабля\n' +
                'отличаются друг от друга'
              } {...questionTextStyles}/>
              <images.TheoryQ1/>
            </>
          ),
        },
        next_success: 'theory questions',
        next_failure: 'theory questions',
      },

      {
        key: 'theory Qst. 2',
        patchData: {
          theoryVisible: true,

          multipleStepsQuestion: true,
          prevQstStepDisabled: true,

          projectorVisible: true,
          projectorContent: (images) => (
            <>
              <Text text={'Сперва, рассмотрим движение корабля относительно \nпланеты:'} {...questionTextStyles} y={0}/>
              <images.TheoryQ2/>
            </>
          ),
        },
        next_success: 'theory Qst. 2.1',
        next_failure: 'theory questions',
      },{
      key: 'theory Qst. 2.1',
      patchData: {
        theoryVisible: true,

        multipleStepsQuestion: true,
        nextQstStepDisabled: true,

        projectorVisible: true,
        projectorContent: (images) => (
          <>
            <Text text={
              'В данной задаче скорость снижения – это скорость \nкорабля относительно ветра. \n' +
              'Перейдём в систему отсчёта, связанную с ветром. \n' +
              'Тогда ветер будет неподвижен, космодром и земля \nвместе с ним будут двигаться вправо, а ракета - вниз:\n'
            }  {...questionTextStyles}/>
            <images.TheoryQ2_1/>
          </>
        ),
      },
      previous: 'theory Qst. 2',
      next_success: 'theory Qst. 2.1',
      next_failure: 'theory Qst. 2',
    },

      {
        key: 'theory Qst. 3',
        patchData: {
          theoryVisible: true,

          projectorVisible: true,
          projectorContent: (images) => (
            <>
              <Text text={
                'Скорость корабля относительно планеты складывается \nиз двух частей: горизонтальной и вертикальной. ' +
                '\nГоризонтальная составляющая равна скорости ветра \nи возникает из-за того, что ветер сносит корабль. ' +
                '\nВертикальная составляющая - это скорость снижения, \nона обеспечивается двигателями корабля:'
              } {...questionTextStyles}/>
              <images.TheoryQ3/>
            </>
          ),
        },
        next_success: 'theory questions',
        next_failure: 'theory questions',
      },

      {
        key: 'theory Qst. 4',
        patchData: {
          theoryVisible: true,

          projectorVisible: true,
          projectorContent: () => (
            <>
              <Text text={'Зная расстояние до космодрома и скорость ветра, \nможно рассчитать время, ' +
              'которое потребуется кораблю, \nчтобы оказаться над космодромом.'} {...questionTextStyles} y={10}/>
            </>
          ),
        },
        next_success: 'theory questions',
        next_failure: 'theory questions',
      },
    ]
  )
]
