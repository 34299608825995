import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Circle, Group, Image, Layer, Line, Rect, Text} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {GIF} from "../../../canvas/components/GIF";
import {sendSuccessForScenario} from '../../../../utils/common';
import {FieldBlock} from '../components/FieldBlock';
import Card from '../../../canvas/components/Card';
import * as actions from '../../../../store/actions';
import {changeSuccessVisible} from '../../../../store/actions';
import pirateShipImg from '../../../../images/gearRatios/pirateShip.png';
import personImg from '../../../../images/gearRatios/person.png';
import sheaveImg from '../../../../images/gearRatios/sheave.png';
import bridgeImg from '../../../../images/gearRatios/bridge.png';
import riverBackground from '../../../../images/gearRatios/river-background.png';
import dragGIF from '../../../../images/gearRatios/personDrag.gif';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";


class Bridge extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.bridgeRef = React.createRef();
    this.shipRef = React.createRef();
    this.ropeRef = React.createRef();
    this.sheaveRef = React.createRef();
    this.shadowRef = React.createRef();
    this.leverArmGroupRef = React.createRef();
    this.personRef = React.createRef();
    this.leverArmRef = React.createRef();
    this.state = {
      drag: false,
      running: false,
      inputLeverArmWidth: 1, // длинная плеча для инпута (может быть больше максимальной и меньше минимальной)
      leverArmWidth: 1, // длинная плеча, та которая используется в формулах и т.д. (НЕ может быть больше максимальной и меньше минимальной)
      bridgeSizeDiff: 100, // размер на который изменится длина моста
      defaultBridgeSize: 140, // размер моста до подъема
      defaultRopePoints: [0, 0, -570, 0], // точки троса/каната до подъема
      humanSpeed: 1, // м/с
      humanForce: 800, // Н
      bridgeLiftingForce: 5000, // Н
      coreRadius: 0.5, // м
      shipSpeed: 1.3, // м
      shipY: 0
    };
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      rotationAngle: 0, // угол поворота рычага
      bridgeSize: 140, // размер моста во время подъема
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
  };

  openModal = () => {
    sendSuccessForScenario(this);
    this.props.changeSuccessVisible(true)
  };

  onChangeInput = (e) => {
    const val = e.target.value;
    const min = Number(e.target.min);
    const max = Number(e.target.max);
    let correctVal = val;
    if (Number(val) < min)
      correctVal = min;
    if (Number(val) > max)
      correctVal = max;
    this.setState({leverArmWidth: correctVal, inputLeverArmWidth: val})
  };

  getNodes = (stageNode) => ({
    bridgeNode: this.bridgeRef.current,
    shipNode: this.shipRef.current,
    ropeNode: this.ropeRef.current,
    sheaveNode: this.sheaveRef.current,
    shadowNode: this.shadowRef.current,
    leverArmGroupNode: this.leverArmGroupRef.current,
    personNode: this.personRef.current,
    leverArmNode: this.leverArmRef.current,
  });

  move = (time) => {
    const {
      running,
      drag,
      bridgeSizeDiff,
      defaultBridgeSize,
      humanForce,
      leverArmWidth,
      bridgeLiftingForce,
      coreRadius,
      humanSpeed
    } = this.state;

    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    const {
      bridgeNode,
      ropeNode,
      leverArmGroupNode,
    } = this.getNodes(stageNode);

      data.startTime = running ? data.startTime || time : 0;
      const timedelta = data.prevTime ? time - data.prevTime : 0;
      data.prevTime = time;
      const timedeltaSec = timedelta / 1000;


    // const longWay = (((leverArmWidth)*2)*Math.PI)/2;
    // const maxAngle = radToDeg(longWay / leverArmWidth);

    const maxAngle = 270;

    const appliedMomentum = humanForce * leverArmWidth;
    const bridgeLiftMomentum = bridgeLiftingForce * coreRadius;

    if (drag && appliedMomentum >= bridgeLiftMomentum) {
      const angleDelta = humanSpeed / leverArmWidth / Math.PI * 180 * timedeltaSec;
      data.rotationAngle = data.rotationAngle < maxAngle ? data.rotationAngle+angleDelta: maxAngle;

      if (data.rotationAngle >= maxAngle)
        this.setState({drag: false});

      // пропорция для подъема моста
      data.bridgeSize = defaultBridgeSize - (data.rotationAngle * (bridgeSizeDiff/maxAngle))

    }

    this.updateStage();
  };

  updateStage() {
    const {activeLesson, changeFailureVisible} = this.props;
    const {defaultBridgeSize, defaultRopePoints, running, shipSpeed, shipY} = this.state;
    const {rotationAngle, bridgeSize, startTime, prevTime} = this.data;
    const timedelta = prevTime ? startTime - prevTime : 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const {
      shipNode,
      bridgeNode,
      ropeNode,
      shadowNode,
      leverArmGroupNode,
    } = this.getNodes(stageNode);

    const bridgeSizeDiff = defaultBridgeSize - bridgeSize;
    const ropePointsX1 = defaultRopePoints[2];
    ropeNode.points([0,0, bridgeSizeDiff+ropePointsX1,0]);
    leverArmGroupNode.rotation(-rotationAngle);
    bridgeNode.height(bridgeSize);

    // Shadow
    shadowNode.offset({x: -(bridgeSizeDiff*1.3), y: bridgeSizeDiff*.45});
    shadowNode.skew({x: 0, y: bridgeSizeDiff*.003});


    const shipYPos = shipSpeed * (timedelta/100);

    if (-shipYPos >= 360 && running) {
      this.setState(({running: false, drag: false}), () => {
        changeSuccessVisible(true);
        this.openModal();
        this.onClickReset();
      });
    }

    if (bridgeSizeDiff <= 70 && -shipYPos >= 280 && running) {
      this.setState(({running: false, drag: false}), () => {
        changeFailureVisible(true);
        this.onClickReset();
      });
    }

    if (running)
      shipNode.y(-shipSpeed * (timedelta/100));
    else
      shipNode.y(shipY);
    if (running && shipNode.y() >= 500){
      shipNode.y(500);
    }

    stageNode.draw();
  }


  CanvasLever = () => {
    const {leverArmWidth, defaultBridgeSize, defaultRopePoints, drag, shipY} = this.state;
    const [pirateShip] = useImage(pirateShipImg);
    const [person] = useImage(personImg);
    const [sheave] = useImage(sheaveImg);
    const [bridge] = useImage(bridgeImg);
    const [river] = useImage(riverBackground);

    return (
      <React.Fragment>
        <Layer preventDefault={false}>
          <Group y={-70}>
          <Group>
            <Image image={river} y={1.5} x={0} preventDefault={false}/>

            <Group y={-25}>
              <Image ref={this.shipRef} image={pirateShip} rotation={90} x={225} y={shipY} width={100} height={55}/>
            </Group>

            <Rect
              ref={this.shadowRef}
              id={'shadow'}
              rotation={0} y={303} x={135}
              width={defaultBridgeSize-10} height={100}
              fill={'rgba(0,0,0,.15)'}
            />
            <Image ref={this.bridgeRef} image={bridge} rotation={90} x={270} y={300} width={100} height={defaultBridgeSize} preventDefault={false}/>
          </Group>

          <Group x={705} y={350}>
            <Circle fill={'#15be01'} radius={270} preventDefault={false}/>
            <Line ref={this.ropeRef} id={'rope'} y={13} points={defaultRopePoints} rotation={1.5} stroke={'white'} />
            <Image ref={this.sheaveRef} id={'sheave'} image={sheave} rotation={0} x={0} y={0} width={28} height={28} offset={{x:28/2, y:28/2}}/>
          </Group>

          <Group ref={this.leverArmGroupRef} id={'leverArmGroup'} x={705} y={350}>
            <Line ref={this.leverArmRef} id={'leverArm'} stroke={'#7b6c29'} strokeWidth={3} y={13} points={[0, 0, 0, leverArmWidth*25]} rotation={0}/>
            {
              drag ? (
                <GIF src={dragGIF} x={-30} y={leverArmWidth*25-15} loop={true}/>
              ) : (
                <Image ref={this.personRef} id={'person'} image={person} rotation={0} x={-20} y={leverArmWidth*25-15} />
              )
            }
          </Group>
        </Group>
          <Group>
            <Card height={300} width={190} x={800} y={5}>
              <Text
                  text={'Определи длину рычага,\nнеобходимую для того,\nчтобы корабль успел\nпроплыть под мостом.'}
                  stroke={'#4D4D4D'}
                  fill={'#406C9C'}
                  lineHeight={1.3}
                  strokeWidth={.4}
                  fontSize={15}
                  x={10}
                  y={10}
              >
              </Text>
              <Text
                  text={'Сила натяжения троса\nпод весом моста,\n5000 Н\n\nМаксимальное усилие\nчеловека,\n800 Н\n\nРадиус ворота,\n0.5 м'}
                  stroke={'#4D4D4D'}
                  fill={'#406C9C'}
                  lineHeight={1.3}
                  strokeWidth={.4}
                  fontSize={15}
                  x={10}
                  y={100}
              >
              </Text>
            </Card>
          </Group>
        </Layer>
      </React.Fragment>
    )
  };

  render() {
    const {burning, withPiston} = this.state;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.CanvasLever/>
        </CanvasContainer>

        <div>
          <button
            style={{...styles.btn, right: '20%', opacity: burning ? .7 : 1}}
            onTouchStart={() => this.setState({drag: true, running: true})}
            onTouchEnd={() => this.setState({drag: false})}
            onMouseDown={() => this.setState({drag: true, running: true})}
            onMouseUp={() => this.setState({drag: false})}
          >
            Старт
          </button>
          <button
            style={{...styles.btn, right: '35%', opacity: burning ? .7 : 1}}
            onClick={() => this.setState({drag: false, running: false}, this.onClickReset)}
          >
            Сброс
          </button>
        </div>

        <div style={{...styles.mainContainer, top: '77%', right: '58%'}}>
        <FieldBlock
          min={1}
          max={10}
          label={'Длина рычага'}
          value={this.state.inputLeverArmWidth}
          onChangeInput={this.onChangeInput}
        >
          <div style={{width: '85px'}}>
            <p>макс: <b>10</b></p>
            <p>мин: <b>1</b></p>
          </div>
        </FieldBlock>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  activeLesson: state.lessonsReducer.activeLesson,
  failureModalVisible: state.commonReducer.failureModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Bridge);

const styles = {
  mainContainer: {
    background: '#1bae2c',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    position: 'absolute',
    background: '#E76F52',
    borderRadius: '7px',
    color: 'white',
    zIndex: 10,
    fontSize: '2vmin',
    padding: '1vmin 1.5vmin',
    fontWeight: 'bold',
    bottom: '2vmin',
  }
};
