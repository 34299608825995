import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import backgroundImg from "../../../../images/space/spaceBackground2.png";
import spaceshipImg from "../../../../images/space/spaceship.png";
import shadowAstronaut1Img from "../../../../images/space/repulsion_of_bodies/shadowAstronaut1.png";
import shadowSpaceshipImg from "../../../../images/space/repulsion_of_bodies/shadowSpaceship.png";
import Astronaut from "../components/Astronaut";
import DarkAnswerWindow from "../components/DarkAnswerWindow";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {
  defaultPatchData,
  RepulsionOfBodiesAstronautAndShipScenario
} from "../utils/RepulsionOfBodiesAstronautAndShipScenario";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import {getTimeDeltaSec} from "../../../../utils/common";




class RepulsionOfBodiesAstronautAndShip extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',

      'astronaut',
      'spaceship'
    ]

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timedeltaSec: 0,

      repulsion: false,

      spaceshipX: 0,
      astronautX: 0,
      astronautRotation: 0,


      ...defaultPatchData
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager(RepulsionOfBodiesAstronautAndShipScenario, this);
  }

  Spaceship = React.forwardRef((props, ref) => {
    const [spaceship] = useImage(spaceshipImg);

    return (
      <Group {...props} ref={ref}>
        <Group x={285}>
          <Image image={spaceship} scale={{x: -.5, y: .5}} rotation={10}/>
        </Group>
      </Group>
    )
  });

  _ref = (key) => this[`${key}Ref`] = React.createRef();

  _getNode = (key) => this[`${key}Ref`]?.current;

  getArmPushes = (cb) => this._armPushes = cb;

  _resetArmRotation = () => {}

  getResetArmRotation = (cb) => this._resetArmRotation = cb;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this._resetArmRotation();
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);


    if (data.repulsion) {
      this._armPushes();

      data.spaceshipX += 10/7.5 * (data.timedeltaSec);
      data.astronautX -= 10 * (data.timedeltaSec);
      data.astronautRotation -= .5 * (data.timedeltaSec);
    }

    this.updateStage();
  };

  Background = () => {

    const [background] = useImage(backgroundImg);

    return (
      <Image image={background}/>
    )
  };

  _armPushes = () => {}

  Env = () => {
    const data = this.data;

    const [shadowAstronaut1] = useImage(shadowAstronaut1Img);
    const [shadowSpaceship] = useImage(shadowSpaceshipImg);
    return (
      <Group>

        <Text text={data.title} x={80} y={30} fontSize={25} fill={'white'}/>

        <DarkAnswerWindow
          x={150} y={50}
          visible={data.answerWindowVisible}

          onCLickBack={() => this.scenarioManager.back()}
          backBtnVisible={data.answerWindowStep2Visible}

          onCLickNext={() => this.scenarioManager.next()}
          nextBtnVisible={data.answerWindowStep1Visible}
        >
          <Group x={80} y={80}>

            <Text text={data.answerWindowText} fontSize={17} fill={'white'} opacity={.5} lineHeight={1.4}/>

            <Group x={230} y={120}>
              <Group visible={data.answerWindowStep1Visible}>
                <Text x={-130} y={80} text={'m1 = 80 кг'} fill={'white'} opacity={.5} fontSize={18}/>

                <Image x={-15} y={50} image={shadowAstronaut1}/>
                <Image x={100} scaleX={-1} image={shadowSpaceship}/>

                <Text x={130} y={80} text={'m2 = 600 кг'} fill={'white'} opacity={.5} fontSize={18}/>
              </Group>

              <Group visible={data.answerWindowStep2Visible}>
                <Image x={-200} y={50} image={shadowAstronaut1}/>
                <Image x={130} scaleX={-1} image={shadowSpaceship}/>
              </Group>
            </Group>
          </Group>
        </DarkAnswerWindow>

        <CanvasButton
          text={'Оттолкнуться'}
          onClick={() => {
            this.scenarioManager.selectStepByKey('repulsion');
          }}
          fontSize={18}
          btnCornerRadius={0}
          textStroke={'transparent'}
          textFill={'#1E2531'}
          btnFill={'#EBFAFD'}
          btnStrokeWidth={1}
          width={160}
          height={35}
          x={420} y={450}
          visible={data.startBtnVisible}
        />
        <CanvasButton
          text={'Узнать'}
          onClick={() => {
            this.scenarioManager.selectStepByKey('answerWindow step 1');
          }}
          fontSize={18}
          btnCornerRadius={0}
          textStroke={'transparent'}
          textFill={'#1E2531'}
          btnFill={'#EBFAFD'}
          btnStrokeWidth={1}
          width={160}
          height={35}
          x={420} y={450}
          visible={data.openAnswerWindowBtnVisible}
        />
      </Group>
    )
  }

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    n['astronaut'].setAttrs({
      x: data.astronautX,
      rotation: data.astronautRotation
    });
    n['spaceship'].x(data.spaceshipX);


    n['stage'].draw();
  }

  render() {
    const data = this.data;
    const stageNode = this._getNode('stage');
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Background />

            <Group x={350} y={130}>
              <Astronaut
                y={0}
                getArmPushes={this.getArmPushes}
                getResetArmRotation={this.getResetArmRotation}
                ref={this._ref('astronaut')}
                withMovableArm={true}
              />
              <this.Spaceship ref={this._ref('spaceship')} y={0}/>
            </Group>

            <this.Env />

            <CanvasResetBtn onClick={() => this.onClickReset()}/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepulsionOfBodiesAstronautAndShip);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
