import React, {useEffect, useState} from "react";
import '../styles/levelProgressBar.css';
import AnimatedNumber from "animated-number-react";


const LevelProgressBar = ({progressData}) => {

  const [lineWidth, setLineWidth] = useState(0);

  const [currentPoints, setCurrentPoints] = useState(progressData.currentPoints);
  const [newPoints, setNewPoints] = useState(progressData.newPoints);

  const maxPoints = progressData.maxPoints;


  /** set new points value */
  useEffect(() => {
    if (progressData.newPoints) {
      setNewPoints(progressData.newPoints);
    }
  }, [progressData.newPoints])

  const points = currentPoints;

  /** set current points value */
  useEffect(() => {
    setCurrentPoints(progressData.currentPoints);
  }, [progressData.currentPoints])


  /** set line value */
  let valueLineWidth = points / progressData.maxPoints * 100;
  if (valueLineWidth > 100) {
    valueLineWidth = 100;
  }
  useEffect(() => {
    setLineWidth(valueLineWidth);
  }, [valueLineWidth])


  return (
    <div className={'level-bar'}>

      <div className={'level-bar__new-points'} style={{opacity: Number(progressData.newPoints || 0)}}>{newPoints || 0}</div>

      <div className={'level-bar__progress-container'}>
        <div className={'level-bar__progress-bg'}>
          <div className={'level-bar__progress-border'}>
            <div className={'level-bar__progress-value-line'} style={{width: lineWidth + '%'}}/>
          </div>
        </div>
      </div>
      <div className={'level-bar__points-wrapper'}>
        <div className={'level-bar__max-points'}>{maxPoints}</div>
        <div className={'level-bar__current-points'} style={{left: lineWidth + '%', marginLeft: currentPoints ? '-1em' : 0}}>
          <AnimatedNumber
            value={points}
            formatValue={(value) => value.toFixed(0)}
            // delay={500}
            duration={1400}
          />
        </div>
      </div>
    </div>
  )
}

export default LevelProgressBar;
