import weightQ1Img from '../../../../images/scales/weightQ1.png';
import weightQ2Img from '../../../../images/scales/weightQ2.png';




export const defaultPatchData = {
  title: '',
  weightQImgStg: {
    img: undefined
  },
  weightQMin: 0,
  weightQMax: 0,
  formVisible: false,
  endPopUpVisible: false,
  goToTheoryVisible: false,
  checkToSuccess: (comp) => {}
}


export const scenario = [
  {
    key: 'step1',
    patchData: {
      ...defaultPatchData,

      title: 'Определи массу груза',
      weightQImgStg: {
        pos: {x: 380, y: 30, width: 58, height: 50},
        img: weightQ1Img,
      },
      weightQMin: 135,
      weightQMax: 135,
      formVisible: true,
      checkToSuccess: (comp) => {
        const val = Number(comp.data.inputVal);
        const success = val >= comp.data.weightQMin && val <= comp.data.weightQMax;
        comp.showFailOrSuccessPopup(success);
        if (success) {
          comp.scenarioManager.selectStepByKey('step2');
          comp.data.inputVal = undefined;
        }
      },
      dragging: false,
      addedWeightQPlace: false,
      rightWeightPlaceStack: [],
    },
  },
  {
    key: 'step2',
    patchData: {
      ...defaultPatchData,

      title: 'Определи примерную массу груза',
      weightQImgStg: {
        pos: {x: 540, y: 30, width: 50, height: 50},
        img: weightQ2Img,
      },
      weightQMin: 81,
      weightQMax: 84,
      formVisible: true,
      checkToSuccess: (comp) => {
        const val = Number(comp.data.inputVal);
        const success = val >= comp.data.weightQMin && val <= comp.data.weightQMax;
        if (success) {
          comp.scenarioManager.success('step2');
          comp.data.inputVal = undefined;
        } else {
          comp.showFailOrSuccessPopup(false);
        }
      },
      dragging: false,
      addedWeightQPlace: false,
      rightWeightPlaceStack: [],
    },
    next_success: 'success',
  },
  {
    key: 'success',
    patchData: {
      ...defaultPatchData,

      endPopUpVisible: true,
    }
  }
]
