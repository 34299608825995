import React, {useEffect} from "react";
import '../styles/farm-map.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import MapContainer from "./MapContainer";
import {checkIsTeacherMap, maps} from "../utils";
import bg from "../images/bg-pattern.png";
import img1 from "../images/farm4map/1.png";
import img2 from "../images/farm4map/2.png";
import img3 from "../images/farm4map/3.png";
import img4 from "../images/farm4map/4.png";
import img5 from "../images/farm4map/5.png";
import img6 from "../images/farm4map/6.png";
import img7 from "../images/farm4map/7.png";
import img8 from "../images/farm4map/8.png";
import img9 from "../images/farm4map/9.png";
import img10 from "../images/farm4map/10.png";
import img11 from "../images/farm4map/11.png";
import img12 from "../images/farm4map/12.png";
import img13 from "../images/farm4map/13.png";
import img14 from "../images/farm4map/14.png";
import img15 from "../images/farm4map/15.png";
import MapElement from "../components/MapElement";



export const getConfigByTaskCode = (key) => {
  let res = {pos: {left: 0, top: 0}};

  const stage1 = 150;
  const stage2 = 450;
  const stage3 = 750;
  const stage4 = 1050;
  const stage5 = 1570;

  const row1 = 230;
  const row2 = 460;
  const row3 = 680;

  switch (key) {
    case 'save_the_cat1':
      res = { pos: {left: stage1, top: row1}, itemImage: img1 };
      break
    case 'pap_and_son_4':
      res = { pos: {left: stage1, top: row2}, itemImage: img2};
      break
    case 'farm_4_presentation1':
      res = { pos: {left: stage1, top: row3}};
      break

    case 'farm_4_lever1':
      res = { pos: {left: stage2, top: row1}, itemImage: img3 };
      break
    case 'choose_some_items_1':
      res = { pos: {left: stage2, top: row2}, itemImage: img4};
      break
    case 'find_fulcrums1':
      res = { pos: {left: stage2, top: row3}, itemImage: img5};
      break

    case 'lever_scales1':
      res = { pos: {left: stage3, top: row1}, itemImage: img6};
      break
    case 'archimedes_4':
      res = { pos: {left: stage3, top: row2}, itemImage: img7};
      break
    case 'farm_4_presentation2':
      res = { pos: {left: stage3, top: row3}};
      break

    case 'logic_lever_1':
      res = { pos: {left: stage4, top: row1}, itemImage: img8};
      break
    case 'logic_lever_2':
      res = { pos: {left: stage4, top: row2}, itemImage: img9};
      break
    case 'logic_lever_3':
      res = { pos: {left: stage4, top: row3}, itemImage: img10};
      break
    case 'logic_lever_4':
      res = { pos: {left: stage4 + 230, top: row1}, itemImage: img11};
      break
    case 'logic_lever_5':
      res = { pos: {left: stage4 + 230, top: row2}, itemImage: img12};
      break
    case 'logic_lever_6':
      res = { pos: {left: stage4 + 230, top: row3}, itemImage: img13};
      break

    case 'seesaw1':
      res = { pos: {left: stage5, top: row1}, itemImage: img14};
      break
    case 'lever_game1':
      res = { pos: {left: stage5, top: row2}, itemImage: img15};
      break
  }

  return res
}


const FarmMap4 = (props) => {
  const isTeacher = checkIsTeacherMap();

  useEffect(() => {
    if (isTeacher) {
      props.courseMapCreateStatistic();
    }
  }, [])

  const Content = ({tasksItems, isOpen, taskModalVisible, currentLessonCode, successScreenVisible, setTaskModalVisible}) => {

    useEffect(() => {
      // Initial redirect if already complete task
      const taskForRedirect = tasksItems.find(task => task.paymentRedirect);
      if (taskForRedirect?.complete && taskForRedirect?.paymentRedirect && !props.coursePaid && !isOpen) {
        window.location.href = taskForRedirect.paymentRedirect;
      }
    }, [tasksItems.length])

    useEffect(() => {
      if (!successScreenVisible) {
        const currentTask = tasksItems.find(task => task.lessonCode === currentLessonCode);
        if (currentTask?.complete && currentTask?.paymentRedirect && !props.coursePaid && !isOpen) {
          window.location.href = currentTask.paymentRedirect;
        }
      }
    }, [tasksItems, successScreenVisible]);

    const columns = [
      {id: 1, left: 170, background: '#37588D'},
      {id: 2, left: 470, background: '#8D4E04'},
      {id: 3, left: 770, background: '#8D3737'},
      {id: 4, left: 1190, background: '#7C8D37'},
      {id: 5, left: 1580, background: '#4D378D'}
    ];
    return (
      <div className={'farm-map-background'} style={{backgroundImage: `url(${bg})`}}>
        {
          columns.map((itm) => {
            return (
              <div
                key={itm.id}
                style={{
                position: 'absolute',
                top: '130px',
                padding: '10px 20px',
                borderRadius: '12px',
                color: 'white',
                fontFamily: 'Roboto-Bold',
                fontSize: '25px',
                ...itm
              }}>
                {itm.id} stage
              </div>
            )
          })
        }
        {
          tasksItems.map((item, i) => {
            const prevItem = tasksItems.find(el => el.lessonCode === item.prevKey);
            return (
              <MapElement
                getConfigByTaskCode={getConfigByTaskCode}
                key={item.lessonCode}
                lessonCode={item.lessonCode}
                isOpen={isOpen}
                isTeacher={isTeacher}
                prevItem={prevItem}
                index={i}
                setTaskModalVisible={setTaskModalVisible} coursePaid={props.coursePaid} {...item}
                preOpen={() => props.getTaskAvailabilityStatus(item.lessonCode)}
              />
            )
          })
        }
      </div>
    )
  }
  return (
    <MapContainer {...props} lang={'en'} withoutFog={true} withoutSuccessScreen={true} mapKey={maps.farm_4_map.key}>
      <Content />
    </MapContainer>
  )
}

const mapStateToProps = state => {
  return ({
    coursePaid: state.mapReducer.coursePaid,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({
  getOrCreateMapProgress: (initial) => dispatch(actions.getOrCreateMapProgress(initial, maps.farm_4_map.key)),
  courseMapAuth: () => dispatch(actions.courseMapAuth()),

  getTaskAvailabilityStatus: (lessonCode) => dispatch(actions.getTaskAvailabilityStatus(maps.farm_4_map.key, lessonCode)),
  courseMapCreateStatistic: () => dispatch(actions.courseMapCreateStatistic(maps.farm_4_map.key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FarmMap4);
