import Submarine_end_Mp4 from "../../../images/bondSubmarine/Submarine_end.mp4";
import Submarine_start_Mp4 from "../../../images/bondSubmarine/Submarine_start.mp4";
import submarine1Img from "../../../images/bondSubmarine/submarine.png";
import brokenSubmarineImg from "../../../images/bondSubmarine/breakSubmarine.png";
import cloneDeep from "lodash.clonedeep";


export const defaultPatchData = {
  title: '',

  videoSrc: undefined,
  onVideoEnd: (comp) => {},

  theoryVisible: false,
  onClickSkipTheory: (comp) => {},

  controllerVisible: false,
  controllerHintVisible: false,
  startBtnVisible: false,

  titleStgs: {},
  submarineImg: submarine1Img,

  finallyPopupText: 'Стекло субмарины \nне выдержало давления\nи треснуло',
  finallyPopupTextStgs: {
    finallyPopupTextPos: {y: 20},
    finallyPopupFontSize: 30,
    finallyPopupColor: '#000253',
    btnTopBg: '#0083D2',
    btnBottomBg: '#0083D2',
    btnTopTxtColor: 'white',
    btnBottomTxtColor: 'white',
  },
  finallyStepVisible: false,
  successFinallyPopup: false,
  stopMoving: false,
  nextBtnVisible: false,
  successStepPopupVisible: false,
  complete: false,
  directionsArrows: false,
}

export const scenario = [
  {
    key: 'initial video',
    patchData: {
      ...defaultPatchData,
      videoSrc: Submarine_start_Mp4,
      stopMoving: true,
      onVideoEnd: (comp) => {
        comp.scenarioManager.selectStepByKey('step 2')
      },
    }
  },
  {
    key: 'step 2',
    patchData: {
      ...defaultPatchData,
      title: 'Рассчитай максимальную глубину погружения\n' +
        'и отправляйся в путь.\n' +
        'Батискаф выдерживает давление 5 МПа.\n' +
        'Плотность солёной воды 1030 кг/м3.\ng принять за 10 Н/кг',

      startBtnVisible: true,
    }
  },
  {
    key: 'step 3',
    patchData: {
      ...defaultPatchData,
      title: 'Отправляйся на поиски подводной базы в восточном направлении.\n' +
        'Известно, что база скрыта на большой глубине.\n' +
        'Не забывай про увеличение давления с глубиной!',

      titleStgs: {fontSize: 30, lineHeight: 1.2},
      controllerVisible: true,
      controllerHintVisible: true,
      directionsArrows: true,
      start: true,
    },
    next_success: 'step 4'
  },
  {
    key: 'step 4',
    patchData: {
      ...defaultPatchData,
      title: 'Отправляйся на поиски подводной базы в восточном направлении.\n' +
        'Известно, что база скрыта на большой глубине.\n' +
        'Не забывай про увеличение давления с глубиной!',

      titleStgs: {fontSize: 30},
      controllerVisible: true,
      directionsArrows: true,
    },
    switch_at: 5,
  },
  {
    key: 'step 5',
    patchData: {
      ...defaultPatchData,
      controllerVisible: true,
      directionsArrows: true,
    },
    next_success: 'found base',
    next_failure: 'broken submarine',
  },

  {
    key: 'broken submarine',
    patchData: {
      ...defaultPatchData,
      submarineImg: brokenSubmarineImg,
      directionsArrows: true,
    },
    next_failure: 'failure',
  },{
    key: 'failure',
    patchData: {
      ...defaultPatchData,
      stopMoving: true,
      finallyStepVisible: true,
      directionsArrows: true,
      submarineImg: brokenSubmarineImg,
      failure: true,
    }
  },

  {
    key: 'found base',
    patchData: {
      ...defaultPatchData,
      controllerVisible: true,
      directionsArrows: true,
    },
    switch_at: 3,
  },

  {
    key: 'found base 2',
    patchData: {
      ...defaultPatchData,
      stopMoving: true,
      directionsArrows: true,
      successStepPopupVisible: true,
    }
  },

  {
    key: 'animation 2',
    patchData: {
      ...defaultPatchData,
      stopMoving: true,
      videoSrc: Submarine_end_Mp4,
      onVideoEnd: (comp) => {
        comp.scenarioManager.selectStepByKey('theory')
      },
    }
  },

  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        if (comp.data.failure) {
          comp.data = {
            ...cloneDeep(comp.initialData),
            ...defaultPatchData,
          };
          comp.scenarioManager.selectStepByKey('step 3');
        } else {
          comp.scenarioManager.selectStepByKey('success');
        }
      }
    }
  },

  {
    key: 'success',
    patchData: {
      ...defaultPatchData,
      complete: true
    }
  }
]