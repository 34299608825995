import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";


class BrownianMotion extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();

    this.state = {};

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
  };

  getNodes = (stageNode) => ({});

  move = (time) => {
    const {} = this.state;

    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    const {} = this.getNodes(stageNode);


    this.updateStage();
  };

  updateStage() {
    const {} = this.props;
    const {} = this.data;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    stageNode.draw();
  }


  Canvas = () => {

    const [] = useImage();

    return (
      <React.Fragment>

      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrownianMotion);

const styles = {
  mainContainer: {
    background: '#36a4d9'
  }
};
