
export const maps = {
  catMap: {key: "cat_map",  locationKey: "/course_map/"},
  catOpenMap: {key: "cat_map",  locationKey: "/open_cat_map/"},
  catFinalTestMap: {key: "cat_final_test_map",  locationKey: "/cat_final_test/"},

  farmMap: {key: "farm_map",  locationKey: "/farm_map/", teacherLocation: "/teacher_farm_map/"},
  schoolMap2: {key: "school_map2",  locationKey: "/school_map2/", teacherLocation: "/teacher_school_map2/"},
  greeceBlocks: {key: "greece_blocks",  locationKey: "/greece_blocks/", teacherLocation: "/teacher_greece_blocks/"},

  farm_4_map: {key: "farm_4_map",  locationKey: "/farm_4_map/", teacherLocation: "/teacher_farm_4_map/"},
  farm_5_map: {key: "farm_5_map",  locationKey: "/farm_5_map/", teacherLocation: "/teacher_farm_5_map/"},
}

export const getCorrectMapCodeByLocation = (locationPathname) => {
  const map = Object.values(maps).find(map => (
    locationPathname.includes(map?.locationKey?.replaceAll('/', '')) ||
    locationPathname.includes(map?.teacherLocation?.replaceAll('/', ''))
  ));
  let corrPathName = map?.locationKey;
  if (window.location.pathname.includes(map?.teacherLocation)) {
    corrPathName = map?.teacherLocation;
  }
  return corrPathName || '';
}

export const checkIsTeacherMap = () => (
  Object.values(maps)
    .filter(map => map.teacherLocation)
    .map(map => map.teacherLocation)
    .some(path => window.location.pathname.includes(path.replaceAll('/', '')))
)

export const checkIsOpen = () => (
  ['open_cat_map']
    .some(path => window.location.pathname.includes(path))
)

export const getProgressBarHeight = (maxItemsCount, itemHeight) => {
  const paddingTopBottom = 20;
  const itemsMargin = maxItemsCount * 10;
  const allItemsHeight = maxItemsCount * itemHeight;
  return allItemsHeight + itemsMargin + paddingTopBottom;
}
