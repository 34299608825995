import React from "react";
import CanvasContainer from "../../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line, Arrow } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import weightImg from '../../../../../images/movableFixedBLock/weight5.png';
import F1Img from '../../../../../images/movableFixedBLock/F1.png';
import F2Img from '../../../../../images/movableFixedBLock/F2.png';
import l1Img from '../../../../../images/movableFixedBLock/l1.png';
import l2Img from '../../../../../images/movableFixedBLock/l2.png';
import movableBlockImg from '../../../../../images/movableFixedBLock/movableBlock.png';
import topBlockImg from '../../../../../images/movableFixedBLock/topBlock.png';
import ScenarioManager from "../../../../../utils/ScenarioManager";
import {blocksScenario, getScenario} from "../scenario";
import { enslow } from "../../../../../utils/physics";
import * as actions from "../../../../../store/actions";
import {sendSuccessForScenario} from "../../../../../utils/common";


const VisibleCircles = ({a}) => (
  <>
    {[[a,a], [-a,-a], [-a, a], [a, -a]].map((i, indx) =>
      (<Circle
        key={indx}
        radius={5}
        offsetY={i[0]}
        offsetX={i[1]}
        fill={'rgba(0,0,0,0.1)'}
        stroke={'rgba(0,0,0,.1)'}
      />))
    }
  </>
);

class MovableFixedBlock extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',
      'arrow1',

      'rope1Left',
      'rope2Left',
      'rope1Right',
      'block1',

      'F1',
      'F2',

      'formula1',
      'formula2',

      'force2Left',
      'force2Right',

      'force1Left',
      'force1Right',
      'weight1Right',
    ];

    this.elementsUI = {
      centerFixedBLockVisible: false,
      movableBLockVisible: false,
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timedeltaSec: 0,
      arrow1Dragged: false,
      arrow1Pos: {x: -75, y: 80},
      forceRight: 1000,
      massRight: 5,
      ropeSpeed: 0,
      ropeLengthTaken: 0,
    };
    this.data = cloneDeep(this.initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  _movingCallbacks = {};
  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.scenarioManager.resetScenario();

    this.timerId = setTimeout(() => {
      sendSuccessForScenario(this);
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };

  arrow1DragMove = (e) => {
    let data = this.data;
    const position = e.target.getPosition();
    // data.arrow1Pos.x = position.x;
    if (position.y > data.arrow1Pos.y) data.arrow1Pos.y = position.y;
  };

  calcArrow1Force = () => {
    let data = this.data;
    return (data.arrow1Pos.y - data.ropeLengthTaken) * 10 || 0.001
  };

  calcArrow1Movement = () => {
    let d = this.data;

    const force = this.calcArrow1Force() - d.forceRight;

    const acceleration = force / d.massRight;
    d.ropeSpeed += acceleration * d.timedeltaSec;

    d.ropeLengthTaken += d.ropeSpeed * d.timedeltaSec;

    if (force > 0) {
      d.ropeSpeed = enslow(d.ropeSpeed, d.ropeSpeed * 1.5 + 30, d.timedeltaSec)
    }

    if (d.ropeLengthTaken < 0) {
      d.ropeLengthTaken = 0;
      d.ropeSpeed = 0;
    }

    if (d.ropeLengthTaken > d.rope1RightPos.maxHeightLift) {
      d.ropeLengthTaken = d.rope1RightPos.maxHeightLift;
      d.ropeSpeed = 0;
    }
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = timedelta / 1000;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    if (!data.arrow1Dragged && data.arrow1Pos.y !== this.initialData.arrow1Pos.y) {
      data.arrow1Pos.y =  data.arrow1Pos.y - 0.2 * (data.arrow1Pos.y - this.initialData.arrow1Pos.y)
    }

    // const force = this.calcArrow1Force();
    this.calcArrow1Movement()


    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['arrow1'].x(data.arrow1Pos.x);
    n['arrow1'].y(data.arrow1Pos.y);
    n['arrow1'].opacity(Number(data.ropeLengthTaken < 5));


    n['stage'].draw();

    // this would better be moved to move function
    let f2coef = 1;
    if (this.props.code === 'peryshkin10') {
      f2coef = 2;
    }


    n['force1Left'].points([0,0,0,this.calcArrow1Force()/30]);
    n['force1Left'].visible(data.arrow1Dragged);
    n['force1Left'].y(120 + data.ropeLengthTaken);

    n['force1Right'].points([0,0,0,-this.calcArrow1Force()/30 * f2coef]);
    n['force1Right'].visible(data.arrow1Dragged);


    n['force2Left'].points([0,0,0,-this.calcArrow1Force()/30]);
    n['force2Right'].points([0,0,0,-this.calcArrow1Force()/30]);
    n['force2Left'].visible(data.arrow1Dragged);
    n['force2Right'].visible(data.arrow1Dragged);


    n['F1'].visible(data.arrow1Dragged);
    n['F1'].y(115 + data.ropeLengthTaken);
    n['F2'].visible(data.arrow1Dragged);
    n['F2'].y(-this.calcArrow1Force()/30 * f2coef - 10);

    n['formula1'].visible(data.arrow1Dragged);



    n['rope1Left'].points([0,0,0,120 + data.ropeLengthTaken]);
    n['rope1Right'].points([0,0,0,data.rope1RightPos.lineHeight - data.ropeLengthTaken]);
    n['rope2Left'].points([0,0,0,data.rope2LeftPos ? data.rope2LeftPos.lineHeight - data.ropeLengthTaken : 0]);

    n['weight1Right'].y(data.weightPos.y - data.ropeLengthTaken);

    n['block1'].rotation( - data.ropeLengthTaken / 60 / Math.PI * 180);
  }


  Canvas = () => {
    const data = this.data;
    const [weight] = useImage(weightImg);
    const [F1] = useImage(F1Img);
    const [F2] = useImage(F2Img);
    const [l1] = useImage(l1Img);
    const [l2] = useImage(l2Img);
    const [formula] = useImage(data.formulaImg);
    // const [formula2] = useImage(formula2Img);

    const [topBlock] = useImage(topBlockImg);
    const [movableBlock] = useImage(movableBlockImg);

    return (
      <React.Fragment>
        <Group {...data.containerPos}>
          <Group
            x={-75}
            y={80}
            ref={this._ref('arrow1')}
            draggable={true}
            onDragMove={this.arrow1DragMove}
            onDragStart={() => this.data.arrow1Dragged = true}
            onDragEnd={() => this.data.arrow1Dragged = false}
          >
            <Group x={data.rope1LeftPos?.x-50} y={20}>
              <Text text={'Тяни'} fontSize={17}/>
            </Group>
            <Group x={data.rope1LeftPos?.x}>
              <Rect x={-20} y={-50} width={60} height={170}  fill={'red'} opacity={0}/>
              <Arrow
                points={[0,0,0,50]}
                stroke={'rgba(0,0,0,1)'}
                fill={'black'}
              />
            </Group>
          </Group>

          <Group x={-60}>
            <Line
              {...data.rope1LeftPos}
              ref={this._ref('rope1Left')}
              points={[0,0,0,200]} stroke={'rgba(0,0,0,1)'} fill={'black'}
              strokeWidth={1}
            />
            <Line
              {...data.rope2LeftPos}
              ref={this._ref('rope2Left')}
              points={[0,0,0,200]} stroke={'rgba(0,0,0,1)'} fill={'black'}
              strokeWidth={1}
              visible={data.movableBLockVisible}
            />
            <Group {...data.rope1LeftPos} >
              <Arrow
                y={150}
                ref={this._ref('force1Left')}
                points={[0,0,0,0.1]} stroke={'black'} fill={'black'}
              />
              <Image x={-70} image={F1} ref={this._ref('F1')}/>
            </Group>
          </Group>

          <Line
            {...data.rope1RightPos}
            ref={this._ref('rope1Right')}
            points={[0,0,0,200]}
            stroke={'black'} fill={'black'}
            strokeWidth={1}
          />

          <Image x={-0} y={-70} image={topBlock} visible={data.movableBLockVisible}/>

          <Group {...data.weightPos} ref={this._ref('weight1Right')}>

            <Group y={-125} visible={data.movableBLockVisible}>
              {/*<Rect width={10} height={10} fill={'red'}/>*/}
              <Arrow
                x={-37} y={30}
                ref={this._ref('force2Left')}
                points={[0,0,0,0]} stroke={'black'} fill={'black'}
              />
              <Arrow
                x={39} y={30}
                ref={this._ref('force2Right')}
                points={[0,0,0,0]} stroke={'black'} fill={'black'}
              />
              <Image x={-41} y={0} image={movableBlock}/>
              <Line x={0} y={40} points={[0,0,0,85]} stroke={'black'}/>
            </Group>

            <Image x={-29} y={-5} image={weight}/>
            <Arrow
              y={0}
              ref={this._ref('force1Right')}
              points={[0,0,0,-0.1]} stroke={'black'} fill={'black'}
            />
            <Image x={30} y={30} image={F2} ref={this._ref('F2')}/>
          </Group>


          <Image {...data.formulaStg} image={formula} ref={this._ref('formula1')} />
          <Group visible={data.centerFixedBLockVisible}>
            <Group>
              <Group
                ref={this._ref('block1')}
                rotation={0}
              >
                <Circle
                  radius={60}
                  fill={'#F9CF47'}
                  stroke={'rgba(0,0,0,.1)'}
                />
                <VisibleCircles a={35}/>
              </Group>
              <Image image={l1} x={-29} y={-30} width={15} height={30}/>
              <Image image={l2} x={23} y={-30} width={15} height={30}/>

              <Rect x={0} y={-5} width={1} height={10} fill={'black'} opacity={.3}/>
              <Rect x={-60} width={120} height={1} fill={'black'} opacity={.2}/>
              <Rect x={-60} y={-5} width={1} height={10} fill={'black'} opacity={.3}/>
              <Rect x={59} y={-5} width={1} height={10} fill={'black'} opacity={.3}/>
            </Group>
          </Group>
        </Group>


        <Group y={520}>
          <Rect
            width={1000}
            height={50}
            fill={'#E1A83D'}
          />
          <Rect
            width={1000}
            height={5}
            fill={'gray'}
          />
        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MovableFixedBlock);

const styles = {
  mainContainer: {
    background: '#f9f0d9'
  }
};
