import React from "react";
import axios from "axios";
import {typeset} from "../../../utils/mathJax";
import {Button, Space, Collapse, Empty} from "antd";
import '../styles.css';
import TasksIndicator from "../components/TasksIndicator";
import {defaultHtmlShadowStyles, layout2} from "../../../utils/styles";
import TextProblemHtmlContent from "../components/TextProblemHtmlContent";
import {CheckCircleFilled, ExclamationCircleFilled} from "@ant-design/icons";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import AnswerBlock from "../components/AnswerBlock";

const { Panel } = Collapse;



class TextProblemTasksList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textProblemKey: ''
    }
  }

  checkAnswerOnCorrect = (textProblem, textProblemKey) => {
    const answerActive = textProblemKey === 'answer-'+textProblem.id;
    const solutionActive = textProblemKey === 'solution-'+textProblem.id;
    const active = answerActive || solutionActive;
    const isOptions = textProblem.answer_type === 'options';

    const correctAnswers = textProblem?.correct_answers;
    const userAnswers = textProblem?.answers;
    const isUserAnswersCorrectLen = (correctAnswers?.length === userAnswers?.length);


    return userAnswers.every((answer) => {
      let res = answer?.is_correct;
      if (isOptions) {
        res = res && isUserAnswersCorrectLen
      }
      return res;
    });
  }

  render() {
    const {textProblems} = this.props;
    const {textProblemKey} = this.state;

    return (
      <div className={'text-problem-tasks__container'}>
        <Space size={20} direction="vertical" className={'text-problem-tasks__space'}>
          {
            (textProblems||[]).map((textProblem, i) => {
              const answerActive = textProblemKey === 'answer-'+textProblem.id;
              const solutionActive = textProblemKey === 'solution-'+textProblem.id;
              const active = answerActive || solutionActive;
              let resultSuccess = this.checkAnswerOnCorrect(textProblem, textProblemKey);
              return (
                <div
                  key={'collapse-key-'+textProblem.id}
                  className={ `tasks-item ${ active ? 'tasks-item--open' : '' }` }
                  style={{...defaultHtmlShadowStyles, borderColor: active ? layout2.blue : 'black'}}
                >
                  <div
                    className={'tasks-item__header'}
                    style={{ borderColor: active ? layout2.blue : 'black' }}
                  >
                    <h3 className={'tasks-item__title'}>Задача {i+1}. {textProblem.title}</h3>
                    <div style={{flexShrink: 0}}>
                      <Button
                        onClick={() => {this.setState({textProblemKey: !answerActive ? 'answer-' + textProblem.id : ''})}}
                        type={'link'}
                        style={{color: answerActive ? 'dimgray' : ''}}
                      >Ответ</Button>
                      <Button
                        onClick={() => {this.setState({textProblemKey: !solutionActive ? 'solution-' + textProblem.id : ''})}}
                        type={'link'}
                        style={{color: solutionActive ? 'dimgray' : ''}}
                      >Решение</Button>

                      <div className={'tasks-item__result'}>
                        {
                          resultSuccess ? (
                            <CheckCircleFilled className={'tasks-item__result-icon tasks-item__result-icon--success'}/>
                          ) : (
                            <ExclamationCircleFilled className={'tasks-item__result-icon tasks-item__result-icon--warning'}/>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className={'tasks-item__content'}>

                    {
                      answerActive && (
                        <>
                          <TextProblemHtmlContent htmlForRender={textProblem.task}/>
                          <AnswerBlock
                            editMode={false}
                            textProblem={textProblem}
                            // userAnswers={textProblem.answers}
                            // correctAnswers={correctAnswers}
                          />
                        </>
                      )
                    }
                    {
                      solutionActive && (
                        <>
                          <div className={'solution-section'}>
                            <h4>Пример решения:</h4>
                          </div>
                          <TextProblemHtmlContent htmlForRender={textProblem.zero_task}/>
                          <div className={'solution'}>
                            <div className={'solution__left'}>
                              <div className={'solution-section'}>
                                <h4>Дано:</h4>
                                <TextProblemHtmlContent htmlForRender={textProblem.solution_given}/>
                              </div>
                              <div className={'solution-section'}>
                                <h4>Найти:</h4>
                                <TextProblemHtmlContent htmlForRender={textProblem.solution_to_find}/>
                              </div>
                            </div>
                            <div className={'solution-section solution__right'}>
                              <h4>Решение:</h4>
                              <TextProblemHtmlContent htmlForRender={textProblem.solution_main}/>
                            </div>
                          </div>
                        </>
                      )
                    }

                  </div>
                </div>
              )
            })
          }
        </Space>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, props) => {
  return ({
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TextProblemTasksList);