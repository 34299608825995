import React, { useState, useRef, useMemo, useEffect } from "react";
import { render } from "react-dom";
import {Stage, Layer, Image, Rect, RegularPolygon, Group} from "react-konva";
import Konva from "konva";



const Video = (props) => {
  const {
    x, y, src,
    width, height,
    onEnded
  } = props;
  const imageRef = useRef(null);
  const [playBtnVisible, setPlayBtnVisible] = useState(false);
  const [size, setSize] = useState({ width: 1000, height: 562 });


  const videoElem = useMemo(() => {
    const element = document.createElement("video");
    element.src = src;
    return element;
  }, [src]);

  const play = () => {

    let playPromise = videoElem.play();

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          console.log("audio played auto");
          setPlayBtnVisible(false);
        })
        .catch(error => {
          if (!playBtnVisible) {
            setPlayBtnVisible(true);
          }
          console.log(error, "playback prevented");
        });
    }
  }

  const onload = function() {
    setSize({
      width: width || videoElem.videoWidth,
      height: height || videoElem.videoHeight
    });
  };
  const ended = function() {
    setPlayBtnVisible(true);
    if (onEnded) {
      onEnded();
    }
  };

  useEffect(() => {
    play();

    videoElem.addEventListener("loadeddata", onload);
    videoElem.addEventListener("ended", ended);
    return () => {
      videoElem.removeEventListener("loadeddata", onload);
      videoElem.removeEventListener("ended", ended);

      videoElem.pause();
      videoElem.currentTime = 0;
    };
  }, [videoElem]);


  const onClickPlay = () => {
    setPlayBtnVisible(false);
    videoElem.currentTime = 0;
    play();
  }

  const btnSize = 30;
  return (
    <>
      <Rect
        x={x}
        y={y}
        width={size.width}
        height={size.height}
        fill={'black'}
        opacity={1}
        visible={playBtnVisible}
      />

      <Image
        ref={imageRef}
        image={videoElem}
        x={x}
        y={y}
        stroke="transparent"
        width={size.width}
        height={size.height}
      />

      <Group
        x={x}
        y={y}
        onClick={onClickPlay}
        onTap={onClickPlay}
        visible={playBtnVisible}
      >
        <Rect
          width={size.width}
          height={size.height}
          fill={'white'}
          opacity={.8}
        />
        <RegularPolygon
          width={btnSize} height={btnSize}
          fill={'#3e3e3e'}
          x={(size.width-btnSize/2)/2} y={(size.height+btnSize/2)/2}
          sides={3}
          radius={btnSize}
          rotation={90}
        />
      </Group>
    </>
  );
};

export default Video;