import React from "react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {Select} from "antd";


const LangSelect = (props) => {
  const onChangeLanguage = (lng) => props.setLanguage(lng);
  const externalStyles = props.styles ? props.styles : {};
  return (
    <div style={{...styles.langSwitcher, ...externalStyles}}>
      <Select
        size={props.size}
        name="" id="language-switcher"
        onChange={(val) => onChangeLanguage(val)}
        value={props.lang}
      >
        <Select.Option key={1} value="ru">Ru</Select.Option>
        <Select.Option key={2} value="en">En</Select.Option>
      </Select>
    </div>
  )
};

const mapStateToProps = (state) => ({
  lang: state.lessonsReducer.lang,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: async (language) => dispatch(actions.setLanguage(language)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LangSelect);


const styles = {
  langSwitcher: {
    position: 'absolute',
    right: '30px', top: '30px'
  }
};