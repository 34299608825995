import React, {useEffect, useState} from "react";
import {LeftCircleFilled, RightCircleFilled} from "@ant-design/icons";
import {serverMediaUrl} from "../../../utils/api";
import {Spin, Carousel} from "antd";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";



const Presentation = (props) => {
  const { width, presentation} = props;
  const [loading, setLoading] = useState(false);
  const [presentationSlides, setPresentationSlides] = useState([]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      const resp = await props.fetchPresentationById(presentation);
      setPresentationSlides(resp.slides);
      setLoading(false);
    })()
  }, [])

  return (
    <>
      <Spin spinning={loading} size={'large'} style={{width: '1000px'}}>
        <div style={{minHeight: '500px'}}>
          <Carousel
            arrows
            draggable
            prevArrow={<LeftCircleFilled style={{position: 'absolute', background: "black", zIndex: 1000, left: 100}} />}
            nextArrow={<RightCircleFilled />}
            style={{width:width, height: 550}}

            afterChange={() => {}}
          >
            {
              presentationSlides.map((slide, i) => {
                const servUrl = serverMediaUrl.replace('/api/media/', '');
                return (
                  <div key={slide.img+i} style={{width: '100%', height: '100%'}}>
                    <img style={{width:width, height: 550, objectFit: 'contain'}} src={`${servUrl}${slide.img}`}/>
                  </div>
                )
              })
            }
          </Carousel>
        </div>
      </Spin>
    </>
  )
}


const mapStateToProps = (state, {match}) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPresentationById: (presentationId) => dispatch(actions.fetchPresentationById(presentationId)),
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Presentation);


