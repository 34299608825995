import { Image } from "react-konva";
import React from "react";

export const CanvasIsland = ({islandImage}) => {
  const width = 1100;
  const height = 500;

  return(
    <Image
      image={islandImage}
      width={width}
      height={height}
      offsetX={width/2}
      offsetY={height/2}
      preventDefault={false}
    />
  )
};
