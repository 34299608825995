import React from 'react';
import {Castle, Bridge, Scales, Engine} from '../../lessons/gearRatios';
import {Submarine, SubmarineScale, SubmarineWaterDisplacement, Crown} from '../../lessons/archimedes';
import Ship from '../../lessons/ship';
import Pirates from '../../lessons/pirates';
import {
  Spaces,
  SpaceshipLanding,
  SpaceshipTakeOff,
  SpaceshipAsteroid1,
  SpaceshipAsteroid2,
  JumpingOnPlanets,
  LossOfSatelliteDish,
  RepulsionOfBodiesAstronautAndShip,
  RepulsionOfBodiesTwoAstronaut,
} from '../../lessons/space';
import Collision from '../../lessons/examples/containers/Collision';
import {SteamFlask, SteamEngine} from '../../lessons/steamEngine';
import {IdealGasChart, Brownian, Diffusion, PhaseState} from '../../lessons/idealGas';
import Rally from '../../lessons/leverage-gears/containers/Rally';
import DragRacing from '../../lessons/leverage-gears/containers/DragRacing';
import {Balloon, HeliumBalloonScale, AirBalloonScale} from '../../lessons/balloon';
import {Step1, Step2, Step3, Step4} from '../../lessons/ICE';
import {Spring} from '../../lessons/oscillation';
import {Pendulum} from '../../lessons/pendulum';
import {TrackLoop} from '../../lessons/trackLoop';
import CityLighting from "../../lessons/cityLighting";
import BrickFactory from "../../lessons/brickFactory";
import Dynamometer from "../../lessons/dynamometer";
import {Crane, CraneScenario, NewCrane} from "../../lessons/crane";
import Efficiency from "../../lessons/efficiency";
import HydraulicPress from "../../lessons/hydraulicPress";
import Fountain from "../../lessons/fountain";
import ToricelliExperiment from "../../lessons/toricelliExperiment";
import BrownianMotion from "../../lessons/brownianMotion";
import {Electrostatic1, Electrostatic2, Electrostatic3} from "../../lessons/electrostatics";
import {tgCircle} from '../../lessons/circle';
import Chemistry from '../../lessons/chemistry';
import {
  Pathandspeed, Train, Train2, Train3, Space, SpaceWithScenario,
  PathandspeedLongLesson, PathAndSpeedCars
} from '../../lessons/pathandspeed';
import ExampleScenarioLongLesson from "../../lessons/examples/containers/ExampleScenarioLongLesson";
import Beaker from "../../lessons/beaker";
import {
  Work1J, Power1W, PositiveNegativeWork,
  TimeDependenceOfWork, LiftingLoadMultiple,
  MovableFixedBlock, MomentRules,
  PowerExample, Dam, KineticEnergy, Illustration,
  Snowboard, BrakingDistances, LiftingWithTime
} from "../../lessons/peryshkinLessons";


import {DegreesAndRadians, Power} from "../../lessons/testTasks";
import SkiBond from "../../lessons/skiBond/containers/SkiBond";
import BondGas from "../../lessons/bondGas/containers/BondGas";
import BondSubmarine from "../../lessons/bondSubmarine/containers/BondSubmarine";
import WaterTower from "../../lessons/waterTower/containers/WaterTower";
import Mountains from "../../lessons/mountains/containers/Mountains";
import ScalesWithBowls from "../../lessons/gearRatios/containers/ScalesWithBowls";
import ScaleForCubes from "../../lessons/gearRatios/containers/ScaleForCubes";
import Bus from "../../lessons/bus/containers/Bus";
import {RelativeMotion} from "../../lessons/RelativeMotion";
import PulleyMovableFixedBlock from "../../lessons/pulley/containers/PulleyMovableFixedBlock";



const LessonsSwitcher = ({lessonCode, match, withDescr, isFrame, lessonData}) => {
  const LessonComponent = getLessonComponent(lessonCode);

  if (LessonComponent !== undefined) return <LessonComponent lessonData={lessonData} match={match} code={lessonCode} withDescr={withDescr} isFrame={isFrame}/>;
  return <div style={styles.emptyBlock}/>;
};

export const isWideScreen = (lessonCode) => [
    exampleCodes,
    submarineCodes,
    electrostaticsCodes,
    ['idealGas12', 'idealGas13', 'idealGas14', 'idealGas15', 'idealGas16'],
    chemistryCodes,
    toricelliExperimentCodes,
    craneCodes,
    fountainCodes,
    gearRatiosCodes,
    crownCodes,
    pathandspeedCodes,
    inertiaCodes,
    piratesCodes,
    beakerCodes,
    peryshkinCodes,
    illustrationBlockCodes,
    illustrationVorotCodes,
    spaceshipLandingCodes,
    spaceshipTakeOffCodes,
    spaceshipAsteroidCodes,
    planetsCodes,
    lossSatelliteDishCodes,
    repulsionOfBodiesCodes,
    skiBondCodes,
    bondGasCodes,
    bondSubmarineCodes,
    waterTowerCodes,
    course7MountainsCodes,
    course7CrownCodes,
    blocksCodes,
    gearRatiosCodes,
    busCodes,
    RelativeMotionCodes,
    pulleyCodes,
  ].some(codes => codes.includes(lessonCode));

const generateCodes = (code, count) => Array(count).fill(1).map((_, i) => `${code}${code !== 'training' ? i+1 : i}`);

const iceCodes = generateCodes('ice', 6);
const submarineCodes = generateCodes('submarine', 7);
const exampleCodes = generateCodes('example', 2);
const rallyCodes = generateCodes('rally', 1);
const dragCodes = generateCodes('drag', 1);
const shipCodes = generateCodes('ship', 1);
const gearRatiosCodes = generateCodes('lever', 10);
const steamCodes = generateCodes('steam', 5);
const piratesCodes = generateCodes('pirates', 4);
const spaceCodes = generateCodes('space', 8);
const trainingCodes = generateCodes('training', 13);
const balloonCodes = generateCodes('balloon', 5);
const idealGasCodes = generateCodes('idealGas', 16);
const oscillationCodes = generateCodes('oscillation', 2);
const pendulumCodes = generateCodes('pendulum', 1);
const trackLoopCodes = generateCodes('loop', 1);
const electrostaticsCodes = generateCodes('electrostatic', 3);
const cityLightingCodes = generateCodes('cityLighting', 1);
const brickFactoryCodes = generateCodes('brickFactory', 1);
const dynamometerCodes = generateCodes('dynamometer', 1);

// New lessons
const craneCodes = generateCodes('crane', 12);
const efficiencyCodes = generateCodes('efficiency', 1);
const hydraulicPressCodes = generateCodes('hydraulicpress', 1);
const fountainCodes = generateCodes('fountain', 1);
const toricelliExperimentCodes = generateCodes('toricelli', 1);
const brownianMotionCodes = generateCodes('brownianmotion', 1);
const tgCircleCodes = generateCodes('tgcircle', 5);
const chemistryCodes = generateCodes('chemistry', 1);
const pathandspeedCodes = generateCodes('pathandspeed', 13);
const crownCodes = generateCodes('crown', 5);
const inertiaCodes = generateCodes('inertia', 3);
const testTasksCodes = generateCodes('testtask', 2);
const beakerCodes = generateCodes('beaker', 2);
const illustrationBlockCodes = generateCodes('illustration_block', 1);
const illustrationVorotCodes = generateCodes('illustration_vorot', 1);
const spaceshipLandingCodes = generateCodes('spaceship_landing', 1);
const spaceshipTakeOffCodes = generateCodes('spaceship_take_off', 1);
const spaceshipAsteroidCodes = generateCodes('spaceship_asteroid', 2);
const repulsionOfBodiesCodes = generateCodes('repulsion_of_bodies', 2);
const skiBondCodes = generateCodes('ski_bond', 1);

const peryshkinCodes = generateCodes('peryshkin', 25);

const planetsCodes = generateCodes('planets', 1);
const lossSatelliteDishCodes = generateCodes('loss_satellite_dish', 1);
const bondGasCodes = generateCodes('course7_gas', 3);
const bondSubmarineCodes = generateCodes('course7_submarine', 1);
const course7MountainsCodes = generateCodes('course7_mountains', 1);
const course7CrownCodes = generateCodes('course7_crown', 1);
const waterTowerCodes = generateCodes('water_tower', 2);
const blocksCodes = generateCodes('blocks_rope_len', 1);
const busCodes = generateCodes('bus', 3);
const RelativeMotionCodes = generateCodes('relative_motion', 3);
const pulleyCodes = generateCodes('pulley', 2);


const getLessonComponent = (lessonCode) => {
  if (exampleCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'example1':
        return Collision;
      case 'example2':
        return ExampleScenarioLongLesson;
      default:
        return <></>
    }
  }
  if (rallyCodes.includes(lessonCode)) return Rally;
  if (shipCodes.includes(lessonCode)) return Ship;
  if (piratesCodes.includes(lessonCode)) return Pirates;
  if (spaceCodes.includes(lessonCode)) return Spaces;
  if (trainingCodes.includes(lessonCode)) return Spaces;
  if (dragCodes.includes(lessonCode)) return DragRacing;
  if (oscillationCodes.includes(lessonCode)) return Spring;
  if (pendulumCodes.includes(lessonCode)) return Pendulum;
  if (trackLoopCodes.includes(lessonCode)) return TrackLoop;
  if (cityLightingCodes.includes(lessonCode)) return CityLighting;
  if (brickFactoryCodes.includes(lessonCode)) return BrickFactory;
  if (dynamometerCodes.includes(lessonCode)) return Dynamometer;

  // New lessons
  if (efficiencyCodes.includes(lessonCode)) return Efficiency;
  if (hydraulicPressCodes.includes(lessonCode)) return HydraulicPress;
  if (fountainCodes.includes(lessonCode)) return Fountain;
  if (toricelliExperimentCodes.includes(lessonCode)) return ToricelliExperiment;
  if (brownianMotionCodes.includes(lessonCode)) return BrownianMotion;
  if (tgCircleCodes.includes(lessonCode)) return tgCircle;
  if (chemistryCodes.includes(lessonCode)) { return Chemistry; }
  if (inertiaCodes.includes(lessonCode)) { return Spaces; }
  if (beakerCodes.includes(lessonCode)) { return Beaker; }

  if (spaceshipLandingCodes.includes(lessonCode)) { return SpaceshipLanding; }
  if (spaceshipTakeOffCodes.includes(lessonCode)) { return SpaceshipTakeOff; }
  if (planetsCodes.includes(lessonCode)) { return JumpingOnPlanets; }
  if (lossSatelliteDishCodes.includes(lessonCode)) { return LossOfSatelliteDish; }
  if (skiBondCodes.includes(lessonCode)) { return SkiBond; }
  if (bondGasCodes.includes(lessonCode)) { return BondGas; }
  if (bondSubmarineCodes.includes(lessonCode)) { return BondSubmarine; }
  if (waterTowerCodes.includes(lessonCode)) { return WaterTower; }
  if (course7MountainsCodes.includes(lessonCode)) { return Mountains; }
  if (course7CrownCodes.includes(lessonCode)) { return Crown; }
  if (blocksCodes.includes(lessonCode)) { return LiftingLoadMultiple; }
  if (busCodes.includes(lessonCode)) { return Bus; }
  if (RelativeMotionCodes.includes(lessonCode)) { return RelativeMotion; }

  if (pulleyCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'pulley1':
        return PulleyMovableFixedBlock;
      // case 'pulley2':
        // return SpaceshipAsteroid2;
    }
  }

  if (spaceshipAsteroidCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'spaceship_asteroid1':
        return SpaceshipAsteroid1;
      case 'spaceship_asteroid2':
        return SpaceshipAsteroid2;
    }
  }
  if (repulsionOfBodiesCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'repulsion_of_bodies1':
        return RepulsionOfBodiesAstronautAndShip;
      case 'repulsion_of_bodies2':
        return RepulsionOfBodiesTwoAstronaut;
    }
  }

  if (illustrationBlockCodes.includes(lessonCode)) { return Illustration; }
  if (illustrationVorotCodes.includes(lessonCode)) { return Illustration; }
  // -------------

  if (peryshkinCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'peryshkin1':
        return PositiveNegativeWork;
      case 'peryshkin2':
        return Work1J;
      case 'peryshkin3':
        return Power1W;
      case 'peryshkin4':
        return TimeDependenceOfWork;
      case 'peryshkin5':
      case 'peryshkin6':
        return LiftingLoadMultiple;
      case 'peryshkin7':
      case 'peryshkin8':
        return MomentRules;
      case 'peryshkin9':
      case 'peryshkin10':
        return MovableFixedBlock;
      case 'peryshkin11':
      case 'peryshkin12':
        return PowerExample;
      case 'peryshkin13':
      case 'peryshkin14':
        return Dam;
      case 'peryshkin15':
        return LiftingLoadMultiple;
      case 'peryshkin16':
        return KineticEnergy;
      case 'peryshkin17':
        return BrakingDistances;
      case 'peryshkin18':
      case 'peryshkin19':
      case 'peryshkin20':
      case 'peryshkin21':
        return Snowboard;
      case 'peryshkin22':
      case 'peryshkin23':
      case 'peryshkin24':
        return LiftingLoadMultiple;
      case 'peryshkin25':
        return LiftingWithTime;
      default:
        return <></>
    }
  }
  if (craneCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'crane1':
      case 'crane2':
      case 'crane3':
      case 'crane4':
      case 'crane5':
      case 'crane6':
      case 'crane7':
        return Crane;
      case 'crane8':
      case 'crane9':
        return CraneScenario;
      case 'crane10':
      case 'crane11':
      case 'crane12':
        return NewCrane;
      default:
        return <></>
    }
  }
  if (testTasksCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'testtask1':
        return Power;
      case 'testtask2':
        return DegreesAndRadians;
      default:
        return <></>
    }
  }
  if (pathandspeedCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'pathandspeed1':
        return Pathandspeed;
      case 'pathandspeed2':
        return Train;
      case 'pathandspeed3':
      case 'pathandspeed4':
        return Space;
      case 'pathandspeed5':
      case 'pathandspeed6':
        return PathandspeedLongLesson;
      case 'pathandspeed7':
        return PathAndSpeedCars;
      case 'pathandspeed8':
        return Train2;
      case 'pathandspeed9':
        return Train3;
      case 'pathandspeed10':
      case 'pathandspeed11':
        return SpaceWithScenario;
      case 'pathandspeed12':
      case 'pathandspeed13':
        return PathandspeedLongLesson;
      default:
        return <></>
    }
  }
  if (electrostaticsCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'electrostatic1':
        return Electrostatic1;
      case 'electrostatic2':
        return Electrostatic2;
      case 'electrostatic3':
        return Electrostatic3;
      default:
        return <></>
    }
  }

  if (balloonCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'balloon1':
        return HeliumBalloonScale;
      case 'balloon2':
      case 'balloon3':
        return AirBalloonScale;
      case 'balloon4':
        return Balloon;
      default:
        return <></>
    }
  }

  if (crownCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'crown1':
      case 'crown2':
      case 'crown3':
      case 'crown4':
      case 'crown5':
        return Crown;
      default:
        return <></>
    }
  }

  if (submarineCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'submarine1':
      case 'submarine2':
      case 'submarine5':
      case 'submarine6':
      case 'submarine7':
        return Submarine;
      case 'submarine3':
        return SubmarineWaterDisplacement;
      case 'submarine4':
        return SubmarineScale;
      default:
        return <></>
    }
  }

  if (idealGasCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'idealGas1':
      case 'idealGas2':
      case 'idealGas3':
      case 'idealGas4':
      case 'idealGas5':
      case 'idealGas6':
      case 'idealGas7':
      case 'idealGas8':
      case 'idealGas9':
      case 'idealGas10':
      case 'idealGas11':
        return IdealGasChart;
      case 'idealGas12':
      case 'idealGas13':
      case 'idealGas14':
        return Brownian;
      case 'idealGas15':
        return Diffusion;
      case 'idealGas16':
        return PhaseState;
      default:
        return <></>
    }
  }

  if (iceCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'ice1':
        return Step1;
      case 'ice2':
        return Step2;
      case 'ice3':
        return Step3;
      case 'ice4':
      case 'ice5':
      case 'ice6':
        return Step4;
      default:
        return <></>
    }
  }

  if (steamCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'steam1':
      case 'steam2':
        return SteamFlask;
      case 'steam3':
        return SteamEngine;
      default:
        return <></>

    }
  }

  if (gearRatiosCodes.includes(lessonCode)) {
    switch (lessonCode) {
      case 'lever1':
      case 'lever5':
      case 'lever6':
      case 'lever7':
        return Scales;
      case 'lever2':
        return Bridge;
      case 'lever3':
        return Castle;
      case 'lever4':
        return Engine;
      case 'lever8':
        return ScalesWithBowls;
      case 'lever9':
      case 'lever10':
        return ScaleForCubes;
      default:
        return <></>
    }
  }
};

export default LessonsSwitcher;

const styles = {
  emptyBlock: {
    background: 'transparent',
    width: '100%',
    height: '100%',
  }
};
