import {
  RESET_CATALOG_SELECTED_ITEMS,
  SET_CATALOG_CHAPTER,
  SET_CATALOG_GRADE,
  SET_CATALOG_LESSON,
  SET_CATALOG_PARAGRAPH, SET_SHOW_ALL_LESSONS
} from "../actions/catalogActions";


const initialState = {
  selectedGrade: undefined,
  selectedChapterId: undefined,
  selectedParagraphId: undefined,
  selectedLesson: undefined,
  showAllLessons: true,
}


export const catalogReducer = (state=initialState, {type, payload}) => {

  switch (type) {

    case SET_SHOW_ALL_LESSONS:
      return {
        ...state,
        showAllLessons: payload,
      }
    case SET_CATALOG_GRADE:
      return {
        ...state,
        selectedGrade: payload,
      }
    case SET_CATALOG_CHAPTER:
      return {
        ...state,
        selectedChapterId: payload,
      }
    case SET_CATALOG_PARAGRAPH:
      return {
        ...state,
        selectedParagraphId: payload,
      }
    case SET_CATALOG_LESSON:
      return {
        ...state,
        selectedLesson: payload,
      }

    case RESET_CATALOG_SELECTED_ITEMS:
      return {
        ...state,
        selectedGrade: undefined,
        selectedChapterId: undefined,
        selectedParagraphId: undefined,
        selectedLesson: undefined,
      }



    default:
      return state
  }
}
