import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import TimerComponent from "../components/TimerComponent";


class Power extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',
      'time',
    ]
    for (let el of this.managedComponents) {
      this[`${el}Ref`] = React.createRef();
    }

    this.state = {};
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      timeDeltaSec: 0,
    };
    this.data = cloneDeep(this.initialData);
  }

  _ref = (key) => this[`${key}Ref`];
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  componentWillUnmount() {
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId);
    }
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    timedeltaSec = timedelta / 1000;

    if (timedeltaSec > 0.5) {
      timedeltaSec = 0.1;
    }

    data.timeDeltaSec += timedeltaSec;





    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));


    let stageNode = n['stage'];
    if (!stageNode) return;


    n['time'].text(Math.round(data.timeDeltaSec))




    stageNode.draw();
  }


  Canvas = () => {

    // const [] = useImage();

    return (
      <React.Fragment>
        <Group>
          <TimerComponent
            taskName={'Мощность'}
            timeRef={this._ref('time')}
          />




        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Power);

const styles = {
  mainContainer: {
    background: '#EEEBE5'
  }
};
