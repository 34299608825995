import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';


import rowImg21 from "../images/continueRow/LogicWedge2_2_row_1.png";
import rowImg22 from "../images/continueRow/LogicWedge2_2_row_2.png";
import rowImg23 from "../images/continueRow/LogicWedge2_2_row_3.png";

import dragImg21 from "../images/continueRow/LogicWedge2_2_drag_1.png";
import dragImg22 from "../images/continueRow/LogicWedge2_2_drag_2.png";
import dragImg23 from "../images/continueRow/LogicWedge2_2_drag_3.png";
import dragImg24 from "../images/continueRow/LogicWedge2_2_drag_4.png";


import rowImg31 from "../images/continueRow/LogicWedge2_3_row_1.png";
import rowImg32 from "../images/continueRow/LogicWedge2_3_row_2.png";
import rowImg33 from "../images/continueRow/LogicWedge2_3_row_3.png";

import dragImg31 from "../images/continueRow/LogicWedge2_3_drag_1.png";
import dragImg32 from "../images/continueRow/LogicWedge2_3_drag_2.png";
import dragImg33 from "../images/continueRow/LogicWedge2_3_drag_3.png";
import dragImg34 from "../images/continueRow/LogicWedge2_3_drag_4.png";


import rowImg41 from "../images/continueRow/LogicWedge2_4_row_1.png";
import rowImg42 from "../images/continueRow/LogicWedge2_4_row_2.png";
import rowImg43 from "../images/continueRow/LogicWedge2_4_row_3.png";

import dragImg41 from "../images/continueRow/LogicWedge2_4_drag_1.png";
import dragImg42 from "../images/continueRow/LogicWedge2_4_drag_2.png";
import dragImg43 from "../images/continueRow/LogicWedge2_4_drag_3.png";
import dragImg44 from "../images/continueRow/LogicWedge2_4_drag_4.png";


import rowImg51 from "../images/continueRow/LogicWedge2_5_row_1.png";
import rowImg52 from "../images/continueRow/LogicWedge2_5_row_2.png";
import rowImg53 from "../images/continueRow/LogicWedge2_5_row_3.png";

import dragImg51 from "../images/continueRow/LogicWedge2_5_drag_1.png";
import dragImg52 from "../images/continueRow/LogicWedge2_5_drag_2.png";
import dragImg53 from "../images/continueRow/LogicWedge2_5_drag_3.png";
import dragImg54 from "../images/continueRow/LogicWedge2_5_drag_4.png";


import bgNature from '../images/bgNature.png';

import ContinueRowTask from "./ContinueRowTask";


const LogicWedge2 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'Continue the row: move the correct picture from below',
      rowItems: [
        {id: 1, mainText: 'Wedge'},
        {id: 2, mainText: 'is'},
        {id: 3, mainText: 'a simple'},
      ],
      dragItems: [
        {id: 1, mainText: 'ramp'},
        {id: 2, mainText: 'machine', success: true},
        {id: 3, mainText: 'screw'},
        {id: 4, mainText: 'thing'},
      ]
    },
    {
      title: 'Continue the row: move the correct picture from below',
      rowItems: [
        {id: 1, image: rowImg21},
        {id: 2, image: rowImg22},
        {id: 3, image: rowImg23},
      ],
      dragItems: [
        {id: 1, image: dragImg21},
        {id: 2, image: dragImg22},
        {id: 3, image: dragImg23, success: true},
        {id: 4, image: dragImg24},
      ]
    },
    {
      title: 'Continue the row: move the correct picture from below',
      rowItems: [
        {id: 1, image: rowImg31},
        {id: 2, image: rowImg32},
        {id: 3, image: rowImg33},
      ],
      dragItems: [
        {id: 1, image: dragImg31, success: true},
        {id: 2, image: dragImg32},
        {id: 3, image: dragImg33},
        {id: 4, image: dragImg34},
      ]
    },
    {
      title: 'Continue the row: move the correct picture from below',
      rowItems: [
        {id: 1, image: rowImg41},
        {id: 2, image: rowImg42},
        {id: 3, image: rowImg43},
      ],
      dragItems: [
        {id: 1, image: dragImg41},
        {id: 2, image: dragImg42},
        {id: 3, image: dragImg43, success: true},
        {id: 4, image: dragImg44},
      ]
    },
    {
      title: 'Continue the row: move the correct picture from below',
      rowItems: [
        {id: 1, image: rowImg51},
        {id: 2, image: rowImg52},
        {id: 3, image: rowImg53},
      ],
      dragItems: [
        {id: 1, image: dragImg51, success: true},
        {id: 2, image: dragImg52},
        {id: 3, image: dragImg53},
        {id: 4, image: dragImg54},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <ContinueRowTask
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    rowItems={item.rowItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default LogicWedge2;
