import {getRandomInt, hasIntersection} from "../../../../utils/common";

const getLineIntersection = ($this, columnI) => {
  const withScenario = $this.data.chartImgSetting?.withScenario;

  let nodes = $this.getNodes?.();
  if (withScenario)
    nodes = Object.fromEntries($this.managedComponents.map(key => [key, $this._getNode(key)]));

  let lineIndexActive = undefined;
  $this.data.itemsRowArr.forEach((el, rowI) => {
    const lineNode = nodes[`dragLine${columnI}${ withScenario ? '' : 'Node' }`];
    const boxNode = nodes[`actionBox${rowI}${columnI}${ withScenario ? '' : 'Node' }`];
    if (hasIntersection(boxNode, lineNode, $this)) {
      lineIndexActive = rowI;
    }
  })

  return lineIndexActive
}

const setLinePos = ($this, lineNumber, selectedPos) => {
  const withScenario = $this.data.chartImgSetting?.withScenario;

  let nodes = $this.getNodes?.();
  if (withScenario)
    nodes = Object.fromEntries($this.managedComponents.map(key => [key, $this._getNode(key)]));

  const line = nodes[`dragLineContainer${lineNumber}${ withScenario ? '' : 'Node' }`];
  const linePos = line?.getAbsolutePosition();
  const lineOffsetY = line?.offsetY();
  const lineScale = line?.getAbsoluteScale();

  const box = nodes[`actionBox${selectedPos}${lineNumber}${ withScenario ? '' : 'Node' }`];
  const boxPos = box?.getAbsolutePosition();

  line && line.setAbsolutePosition({ x: linePos.x, y: boxPos.y });
}
export const setInitialArrowHintPos = ($this) => {
  const withScenario = $this.data.chartImgSetting?.withScenario;

  let nodes = $this.getNodes?.();
  if (withScenario)
    nodes = Object.fromEntries($this.managedComponents.map(key => [key, $this._getNode(key)]));
  const line = nodes[`dragLineContainer${0}${ withScenario ? '' : 'Node' }`];
  const arrowHintNode = nodes[`arrowHint${ withScenario ? '' : 'Node' }`];

  const linePos = line.getAbsolutePosition();
  arrowHintNode.setAbsolutePosition({x: linePos.x, y: linePos.y+3})

}
export const setInitialPosLinePos = ($this) => {
  const withScenario = $this.data.chartImgSetting?.withScenario;

  let nodes = $this.getNodes?.();
  if (withScenario)
    nodes = Object.fromEntries($this.managedComponents.map(key => [key, $this._getNode(key)]));

  const selectedValues = $this.data.chartImgSetting.selectedValues;

  const defaultRowI = $this.data.itemsRowArr.length-1;
  const itemsColumnArr = $this.data.itemsColumnArr;
  itemsColumnArr.forEach((el, columnI) => {
    const line = nodes[`dragLineContainer${columnI}${ withScenario ? '' : 'Node' }`];
    const linePos = line.getAbsolutePosition();

    const box = nodes[`actionBox${defaultRowI}${columnI}${ withScenario ? '' : 'Node' }`];
    const boxPos = box.getAbsolutePosition();

    const corrVal = selectedValues ? selectedValues[defaultRowI] : (defaultRowI+1);
    $this.data.selectedData[`line${columnI+1}`] = corrVal;

    line.setAbsolutePosition({
      x: linePos.x, y: boxPos.y
    });
  });
}
export const setLineRandom = ($this) => {
  const withScenario = $this.data.chartImgSetting?.withScenario;
  const itemsColumnArr = $this.data.itemsColumnArr;
  let nodes = $this.getNodes?.();
  if (withScenario)
    nodes = Object.fromEntries($this.managedComponents.map(key => [key, $this._getNode(key)]));

  const selectedValues = $this.data.chartImgSetting.selectedValues;

  const arrowHintNode = nodes[`arrowHint${ withScenario ? '' : 'Node' }`];
  itemsColumnArr.forEach((el, columnI) => {
    const randRowI = getRandomInt(itemsColumnArr.length);
    const line = nodes[`dragLineContainer${columnI}${ withScenario ? '' : 'Node' }`];
    const linePos = line.getAbsolutePosition();

    const box = nodes[`actionBox${randRowI}${columnI}${ withScenario ? '' : 'Node' }`];
    const boxPos = box.getAbsolutePosition();

    const corrVal = selectedValues ? selectedValues[randRowI] : (randRowI+1);
    $this.data.selectedData[`line${columnI+1}`] = corrVal;

    if (columnI === 0) {
      arrowHintNode.setAbsolutePosition({
        x: linePos.x, y: boxPos.y
      })
    }

    line.setAbsolutePosition({
      x: linePos.x, y: boxPos.y
    });
  });
}

export const onDragBoundFunc = ($this, pos, cellI) => {
  const stageNode = $this.stageRef?.current;
  const stageWidth = stageNode.width();
  const stageHeight = stageNode.height();
  const withScenario = $this.data.chartImgSetting?.withScenario;

  let nodes = $this.getNodes?.();
  if (withScenario)
    nodes = Object.fromEntries($this.managedComponents.map(key => [key, $this._getNode(key)]));

  const lineNode = nodes[`dragLineContainer${cellI}${ withScenario ? '' : 'Node' }`];
  const linePos = lineNode.getAbsolutePosition() ;

  const chartImgSetting = $this.data.chartImgSetting?.boundFunc;

  let topK = chartImgSetting ? chartImgSetting.top : .35;
  let bottomK = chartImgSetting ? chartImgSetting.bottom : .7;

  let leftBorderX = linePos.x;
  let rightBorderX = leftBorderX;
  let topBorderY = stageHeight * topK;
  let bottomBorderY = stageHeight * bottomK;

  const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
  const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
  return {y: newY, x: newX};
}

export const onDragEnd = ($this, e, cellI) => {
  const selectedLineNumber = getLineIntersection($this, cellI);
  if (selectedLineNumber !== undefined) {
    const externalValues = $this.data.chartImgSetting.selectedValues;
    const corrVal = externalValues ? externalValues[selectedLineNumber] : (selectedLineNumber+1);
    $this.data.selectedData[`line${cellI+1}`] = corrVal;
    setLinePos($this, cellI, selectedLineNumber);
  }
}
export const onDragMove = ($this, e, cellI) => {

}
export const onDragStart = ($this, e, cellI) => {
  $this.data.isLineDragged = true;
}


