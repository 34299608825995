import animStartMp4 from '../../../images/mountains/Atmos_start.mp4';
import animSuccessMp4 from '../../../images/mountains/Atmos_end.mp4';



export const defaultPatchData = {
  titleFill: 'white',
  tablePopupVisible: false,

  theoryVisible: false,
  onClickSkipTheory: (comp) => {},

  videoSrc: undefined,
  onVideoEnd: (comp) => {},

  withSelectLab: false,

  experimentArrowHintVisible: false,
  experimentBtnVisible: false,

  tableBtnArrowHintVisible: false,
  tableBtnVisible: false,

  // ---- experiment -----
  experiment: false,
  toricelliStep: '', // initial, rotation, animation
  rotationHintVisible: false,
  dragHintVisible: false,
  placeForFlaskVisible: false,
  corkFlew: false,
  heightIndicatorVisible: false,
  cardFlaskVisible: false,
  cardInfoVisible: false,

  checkTheoryBtnVisible: false,
  returnToMapBtnVisible: false,


  endPopUpVisible: false,
  endPopUpSuccess: false,
  endPopUpTitle: '',
}
const calcLabTitle = 'Используй таблицу давлений, чтобы определить высоту,\nна которой находится агент Кварк, зная атмосферное\nдавление на этой высоте. (принять g за 10 Н/кг)';
export const scenario = [
  {
    key: 'initial animations',
    patchData: {
      ...defaultPatchData,
      videoSrc: animStartMp4,
      onVideoEnd: (comp) => {
        comp.scenarioManager.selectStepByKey('step 1');
      }
    },
  },
  {
    key: 'step 1',
    patchData: {
      ...defaultPatchData,
      title: 'Проведи эксперимент по определению атмосферного \nдавления в лаборатории, где находится агент Кварк',
      experimentArrowHintVisible: true,
      experimentBtnVisible: true,
      tableBtnVisible: true,
      prevStepHelper: 'step 1',
    }
  },
  {
    key: 'experiment initial',
    patchData: {
      ...defaultPatchData,
      title: '',
      experiment: true,
      dragHintVisible: true,
      placeForFlaskVisible: true,
      cardFlaskVisible: true,
      toricelliStep: 'initial',
    },
  },
  {
    key: 'experiment rotation',
    patchData: {
      ...defaultPatchData,
      title: '',
      experiment: true,
      rotationHintVisible: true,
      toricelliStep: 'rotation',
    }
  },
  {
    key: 'experiment animation',
    patchData: {
      ...defaultPatchData,
      title: '',
      experiment: true,
      corkFlew: true,
      toricelliStep: 'animation',
    },
    next_success: 'experiment complete'
  },
  {
    key: 'experiment complete',
    patchData: {
      ...defaultPatchData,
      title: 'Используй данные эксперимента, чтобы посчитать \nатмосферное давление',
      titleFill: 'black',
      corkFlew: true,
      experiment: true,
      heightIndicatorVisible: true,
      cardInfoVisible: true,
      checkTheoryBtnVisible: true,
      returnToMapBtnVisible: true,
    }
  },

  {
    key: 'table popup',
    patchData: {
      ...defaultPatchData,
      title: calcLabTitle,
      tablePopupVisible: true
    }
  },


  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        const key = comp.data.prevStepHelper || 'success';
        comp.scenarioManager.selectStepByKey(key);
        comp.data.prevStepHelper = '';
      }
    }
  },

  {
    key: 'select lab',
    patchData: {
      ...defaultPatchData,
      title: calcLabTitle,
      withSelectLab: true,
      experimentBtnVisible: true,
      tableBtnVisible: true,
      prevStepHelper: 'select lab',
    }
  },


  {
    key: 'success animation',
    patchData: {
      ...defaultPatchData,
      videoSrc: animSuccessMp4,
      onVideoEnd: (comp) => {
        comp.scenarioManager.selectStepByKey('success');
      }
    }
  },
  {
    key: 'success',
    patchData: {
      ...defaultPatchData,
      title: calcLabTitle,
      endPopUpVisible: true,
      endPopUpSuccess: true,
      endPopUpTitle: 'Миссия выполнена',
    }
  },
  {
    key: 'fail',
    patchData: {
      ...defaultPatchData,
      title: calcLabTitle,
      endPopUpVisible: true,
      endPopUpSuccess: false,
      endPopUpTitle: 'Миссия провалена',
    }
  },

]