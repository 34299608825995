

export const getScenario = (code) => {

  if (['ice4', 'ice5'].includes(code)) {
    return defaultScenario;
  }
  if (code === 'ice6') {
    return scenario
  }
}

const scenario = [
  {
    key: 'step1',
    patchData: {
      cardHintStg: {
        zIndex: 5,
        x: 270,
        y: -350
      },
      withBlurSwitch: true,
      cardHintRectStg: { height: 90 },
      arrowHintStg: { scaleX: -1, x: 260, y: 140 },
      blurStg: { zIndex: 4, },
      btnsContainerStg: { zIndex: 3, },
      answerStg: { zIndex: 5, },
      cardHintText: 'Перемести процессы \nна нужные такты работы \nДВС'
    },
    next_success: 'step2',
    switch_at: 5
  },
  {
    key: 'step2',
    patchData: {
      withBlurSwitch: false,
      cardHintStg: {visible: false},
      blurStg: {visible: false},
      btnsContainerStg: {zIndex: 5,},
      answerStg: {zIndex: 2,},
    },
    next_success: 'step3',
  },
  {
    key: 'step3',
    patchData: {
      withBlurSwitch: true,
      cardHintStg: { visible: true, zIndex: 5, x: 0, y: 0},
      cardHintRectStg: { height: 70 },
      blurStg: { visible: true, zIndex: 4, },
      btnsContainerStg: { zIndex: 5, },
      arrowHintStg: { visible: true, scaleX: 1, x: 160, y: 80 },
      answerStg: { visible: true, zIndex: 2, },
      cardHintText: 'Теперь жми Зажигание, \nа потом Газ'
    },
    next_success: 'step4',
  },
  {
    key: 'step4',
    patchData: {
      withBlurSwitch: false,
      cardHintStg: {visible: false},
      blurStg: {visible: false},
      btnsContainerStg: {zIndex: 5,},
      answerStg: {zIndex: 2,},
      hintForButtonsViewed: true
    },
    next_success: 'success',
  },
  {
    key: 'step5',
    patchData: {
      cardHintStg: {
        visible: true,
        zIndex: 5,
        x: 290,
        y: -480
      },
      withBlurSwitch: true,
      cardHintRectStg: { visible: true, height: 70, width: 300 },
      arrowHintStg: { visible: false },
      blurStg: { visible: false },
      btnsContainerStg: { zIndex: 3, },
      answerStg: { zIndex: 5, },
      cardHintText: 'Подумай как расставить \nпроцессы более правильно'
    },
    next_success: 'success',
  },
  {
    key: 'success',
    patchData: {
      withBlurSwitch: false,
      cardHintStg: { visible: false, zIndex: 5, },
      blurStg: { visible: false, zIndex: 4, },
      btnsContainerStg: { zIndex: 5, },
      answerStg: { zIndex: 2, },
    }
  }
]




const defaultScenario = [
  {
    key: 'initial',
    patchData: {

    }
  },
  {
    key: 'success',
    patchData: {

    }
  },
]