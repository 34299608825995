import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import dragImg11 from "../images/arrangeByColumns/LogicWheel3_1_1.png";
import dragImg12 from "../images/arrangeByColumns/LogicWheel3_1_2.png";
import dragImg13 from "../images/arrangeByColumns/LogicWheel3_1_3.png";

import dragImg21 from "../images/arrangeByColumns/LogicWheel3_2_1.png";
import dragImg22 from "../images/arrangeByColumns/LogicWheel3_2_2.png";
import dragImg23 from "../images/arrangeByColumns/LogicWheel3_2_3.png";
import ArrangeByColumns from "./ArrangeByColumns";
import bgNature from '../images/bgNature.png';
import { _t } from "../../../../utils/lang/common";


const LogicWheel3 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: _t("logic_wheel.all_titles_are_false"),
      columnsItems: [
        {id: 1, successAnswerId: 2, title: _t("logic_wheel.wheel")},
        {id: 2, successAnswerId: 3, title: _t("logic_wheel.axle")},
        {id: 3, successAnswerId: 1, title: _t("logic_wheel.ramp")},
      ],
      dragItems: [
        {id: 1, image: dragImg11},
        {id: 2, image: dragImg13},
        {id: 3, image: dragImg12},
      ]
    },
    {
      title: _t("logic_wheel.wheel_not_near_the_ramp"),
      columnsItems: [
        {id: 1, successAnswerId: 3, title: _t("logic_wheel.first")},
        {id: 2, successAnswerId: 1, title: _t("logic_wheel.second")},
        {id: 3, successAnswerId: 2, title: _t("logic_wheel.third")},
      ],
      dragItems: [
        {id: 1, image: dragImg23},
        {id: 2, image: dragImg22},
        {id: 3, image: dragImg21},
      ]
    },
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i+1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <ArrangeByColumns
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    columns={item.columnsItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default LogicWheel3;
