export const getMathJax = () => window.MathJax;

// todo add selector to typeset promise
// https://louisrli.github.io/blog/2020/06/04/react-mathjax/#.YKPEw5MzZSU

export const typeset = () => {
  const mathJax = getMathJax();
  // If MathJax script hasn't been loaded yet, then do nothing.
  if (!mathJax?.startup) {
    return null;
  }
  mathJax.startup.promise = mathJax.startup.promise
    .then(() => {
      return mathJax.typesetPromise();
    })
    .catch((err) => console.error(`Typeset failed: ${err.message}`));
  return mathJax.startup.promise;
};
