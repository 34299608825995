import {Button, Form, Input, Modal} from "antd";
import React, {useState} from "react";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {openNotificationWithIcon} from "../../../../utils/common";
import {ymClickSendFeedbackGoal} from "../../../../metriks/ym_metriks";


const PopupFeedback = (props) => {

  const defaultState = {textarea: '', url: ''};
  const [state, setState] = useState(defaultState);

  const submit = async () => {
    const resp = await props.sendFeedback(state);
    if (resp.status) {
      ymClickSendFeedbackGoal();

      props.setFeedbackPopupVisible(false);
      openNotificationWithIcon('success', 'Сообщение успешно отправлено');
      setState(defaultState)
    }
  }

  return (
    <Modal
      title={null}
      centered
      style={{margin: 0, padding: 0}}
      visible={props.feedbackPopupVisible}
      onCancel={() => props.setFeedbackPopupVisible(false)}
      width={700}
      wrapClassName={'popup-for-feedback'}
      footer={null}
    >
      <form>
        <h2>
          Обратная связь
        </h2>
        <p>Оставьте любой комментарий / вопрос / предложение / замечание:</p>
        <Form.Item>
          <Input.TextArea value={state.textarea} onChange={(e) => {
            const val = e.target.value;
            setState({textarea: val, url: window.location.origin + window.location.pathname})
          }} placeholder={'Ваш комментарий ...'} rows={6}/>
        </Form.Item>
        <span>Или напишите нам на электронную почту: <b>connect@inventic.tech</b></span>

        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button type={'primary'} onClick={submit}>Отправить</Button>
        </div>
      </form>
    </Modal>
  )
}


const mapStateToProps = state => ({
  feedbackPopupVisible: state.commonReducer.feedbackPopupVisible,
});
const mapDispatchToProps = (dispatch, {match}) => ({
  setFeedbackPopupVisible: (visible) => dispatch(actions.setFeedbackPopupVisible(visible)),
  sendFeedback: (data) => dispatch(actions.sendFeedback(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopupFeedback);
