import CanvasButton from "./CanvasButton";
import React from "react";
import {layout2} from "../../../utils/styles";
import {Group} from "react-konva";
import playImg from '../img/play.png'
import useImage from "use-image";


const CanvasPrevBtn = React.forwardRef((props, ref) => {
  const {
    x=850,
    y=500,
    width=95,
    height=30,
    fontSize=15,
    onClick,
    visible
  } = props;
  const [play] = useImage(playImg);
  return (
    <CanvasButton
      btnRef={ref}
      text={'Назад'}
      visible={visible}

      prefixImg={play}
      prefixY={15}
      prefixX={15}
      prefixWidth={15}
      prefixHeight={15}
      prefixRotation={180}

      onClick={() => onClick()}
      fontSize={fontSize}
      strokeWidth={.2}
      btnCornerRadius={0}
      btnFill={layout2.darkBlue}
      width={width}
      textWidth={width-20}
      height={height}
      align={'right'}
      x={x}
      y={y}
    />
  )
})

export default CanvasPrevBtn;