import React from "react";
import {Group, Image} from "react-konva";




const CargosList = (props) => {

  const stepX = props.stepX || 33;
  return (
    <Group>
      {
        props.cargosList.map((cargo, i) => {
          const img = props.cargosImgs[cargo.key];
          return (
            <React.Fragment key={'list-'+cargo.key}>
              <Image width={30} height={20} image={img} x={stepX*i}/>
            </React.Fragment>
          )
        })
      }
    </Group>
  )
}

export default CargosList;