import beaker1Img from "../../../../images/beaker/beaker1.png";
import beaker2Img from "../../../../images/beaker/beaker2.png";
import beaker3Img from "../../../../images/beaker/beaker3.png";


export const getCorrectScenario = (code) => {
  switch (code){
    case 'beaker1':
      return beakerScenario({radioCalcErr50: false, radioCalcErr1: true});
    case 'beaker2':
      return beakerScenario({radioCalcErr50: true, radioCalcErr1: false});
  }
}


export const beakerScenario = (calcErrProps) => [
  // --------- STEP 1 ----------
  {
    key: 'step1',
    text: { value: 'Определи объем воды и погрешность измерения'},
    patchData: {
      ...calcErrProps,
      beakerImg: beaker1Img,

      waterHeight: 141,
      waterWidth: 100,
      waterX: 100,

      inputSuccess: 7,
      inputCalcErrSuccess50: .25,
      inputCalcErrSuccess1: .5,


      measureTxt: 'мл'
    },
    next_success: 'step2',
    next_failure: 'step1',
  },
  {
    key: 'step2',
    text: { value: 'Определи объем воды и погрешность измерения'},
    patchData: {
      ...calcErrProps,
      beakerImg: beaker2Img,

      waterHeight: 226,
      waterWidth: 200,
      waterX: 205,

      inputSuccess: 22,
      inputCalcErrSuccess50: 1,
      inputCalcErrSuccess1: 2,

      measureTxt: 'мл'
    },
    next_success: 'step3',
    next_failure: 'step2',
  },
  {
    key: 'step3',
    text: { value: 'Определи объем воды и погрешность измерения \nв литрах'},
    patchData: {
      ...calcErrProps,
      beakerImg: beaker3Img,

      waterHeight: 145,
      waterWidth: 203,
      waterX: 205,

      inputSuccess: 1.5,
      inputCalcErrSuccess50: .25,
      inputCalcErrSuccess1: .5,

      measureTxt: 'л'
    },
    next_success: 'step4',
    next_failure: 'step3',
  },
  {
    key: 'step4',
    text: { value: 'Определи объем воды и погрешность измерения \nв литрах'},
    patchData: {
      ...calcErrProps,
      beakerImg: beaker3Img,

      waterHeight: 260,
      waterWidth: 203,
      waterX: 205,

      inputSuccess: 2.5,
      inputCalcErrSuccess50: 0.25,
      inputCalcErrSuccess1: 0.5,

      measureTxt: 'л'
    },
    next_success: 'success',
    next_failure: 'step4',
  },
  {
    key: 'success',
    text: { value: 'Определи объем воды и погрешность измерения \nв литрах'},
    patchData: {
      ...calcErrProps,
      beakerImg: beaker3Img,

      waterHeight: 260,
      waterWidth: 203,
      waterX: 205,

      inputSuccess: 2500,
      inputCalcErrSuccess50: 250,
      inputCalcErrSuccess1: 500,

      taskCompleteVisible: true,


      measureTxt: 'л'
    },
    next_success: 'success',
  }
]