import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getTimeDeltaSec, hasIntersection, inputVal, showFailOrSuccessPopup} from "../../../../utils/common";
import {defaultPatchData, getCorrectScenario, scenario} from "../scenaries/ScaleForCubesScenario";

import aluminumImg from '../../../../images/scaleForCubes/aluminum.png';
import brickImg from '../../../../images/scaleForCubes/brick.png';
import corkImg from '../../../../images/scaleForCubes/cork.png';
import goldImg from '../../../../images/scaleForCubes/gold.png';
import iceImg from '../../../../images/scaleForCubes/ice.png';
import oakImg from '../../../../images/scaleForCubes/oak.png';
import silverImg from '../../../../images/scaleForCubes/silver.png';
import steelImg from '../../../../images/scaleForCubes/steel.png';
import * as actions from "../../../../store/actions";
import CanvasScaleWithBowls from "../../../canvas/components/scales/CanvasScaleWithBowls";
import Card from "../../../canvas/components/Card";
import CanvasSuccessCard from "../../../canvas/components/CanvasSuccessCard";



class ScaleForCubes extends React.Component {
  _movingCallbacks = {};

  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',
      'defaultWeightNode',
    ]

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      draggingKey: '',


      leftWeightPlaceStack: [],
      rightWeightPlaceStack: [],
      defaultWeightPlaceStack: [], // adding from scenario
      weightItems: {}, // adding from scenario

      ...defaultPatchData
    };
    this.data = cloneDeep(this.initialData);
    const scenario = getCorrectScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }


  _ref = (key) => this[`${key}Ref`] = React.createRef();

  _getNode = (key) => this[`${key}Ref`]?.current;

  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
    Object.keys(this.data.weightItems).forEach((weightKey) => {
      const node = this._getNode('weightNode-'+weightKey);
      node.scale({x: 1, y: 1});
    })
  };

  showFailOrSuccessPopup = (status) => showFailOrSuccessPopup(this, status);

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);

    if (data.checkCorrect) {
      data.checkCorrect(this);
    }

    this.updateStage();
  };

  addWeightToBowls = (side) => {
    this.data[side+'WeightPlaceStack'].forEach((weightKey, i) => {
      const weightNode = this._getNode('weightNode-'+weightKey);
      const dropPlace = this._getNode(side+'DropPlace');
      const dropPlacePos = dropPlace.getAbsolutePosition();
      weightNode.scale({x: .7, y: .7})
      // const correctItem = this.data.weightItems[weightKey];
      weightNode.setAbsolutePosition({x: dropPlacePos.x + 45*i, y: dropPlacePos.y+90})
    });
  }

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    data.defaultWeightPlaceStack.forEach((weightKey, i) => {
      const weightNode = this._getNode('weightNode-'+weightKey);
      const defaultWeightNode = this._getNode('defaultWeightNode');
      const defaultWeightPos = defaultWeightNode.getAbsolutePosition();
      if (data.draggingKey !== weightKey) {
        const correctItem = data.weightItems[weightKey];
        weightNode.setAbsolutePosition({x: defaultWeightPos.x + 75 * i, y: defaultWeightPos.y});
      }
    })

    this.addWeightToBowls('left');
    this.addWeightToBowls('right');

    n['stage'].draw();
  }

  Env = () => {
    const data = this.data;

    return (
      <React.Fragment>
        <Text x={50} y={40} text={data.title} fontSize={30} fill={'#4A6C97'} stroke={'#4A6C97'} strokeWidth={.5} lineHeight={1.2}/>
      </React.Fragment>
    )
  };

  setNewWeightPosition = (weightKey, position) => {
    const data = this.data;
    data.defaultWeightPlaceStack = data.defaultWeightPlaceStack.filter((k) => k !== weightKey);
    data.defaultWeightPlaceStack.splice(position, 0, weightKey);
  }
  checkWeightPosIntersection = (action, weightKey, intersCallback) => {
    const currentNode = this._getNode('weightRectNode-'+weightKey);
    Object.keys(this.data.weightItems).forEach((key, i) => {
      const posNum = i+1;
      const posNode = this._getNode('weightPosRect'+posNum);
      const posHintNode = this._getNode('positionArrowHint'+posNum);
      const inters = hasIntersection(posNode, currentNode, this);
      posHintNode.visible(inters && action === 'start');
      if (inters && intersCallback) {
        intersCallback(weightKey, i);
      }
    })
  }

  Weights = (props) => {
    const data = this.data;

    const weightItemsImgs = {}
    Object.keys(data.weightItems).forEach((weightKey) => {
      const weighItem = data.weightItems[weightKey];
      const [weightImg] = useImage(weighItem.img);
      weightItemsImgs[weighItem.id] = weightImg;
    })

    const removeAddedItem = (weightId) => {
      data.leftWeightPlaceStack = data.leftWeightPlaceStack.filter(itm => itm !== weightId);
      data.rightWeightPlaceStack = data.rightWeightPlaceStack.filter(itm => itm !== weightId);
      data.defaultWeightPlaceStack = data.defaultWeightPlaceStack.filter(itm => itm !== weightId);
    }

    return (
      <Group {...props}>
        <Card height={data.weightCardHeight} width={data.weightCardWidth} cornerRadius={10}>
          <Group x={20} y={15}>
            <Rect width={70} height={42} fill={'red'} opacity={0} ref={this._ref('defaultWeightNode')}/>
            {
              data.defaultWeightPlaceStack.map((weightKey, i) => {
                const correctItem = data.weightItems[weightKey];
                return (
                  <Group x={75*i} key={'weight-'+(i+1)} ref={this._ref('weightContainerNode-'+weightKey)}>
                    <Group
                      y={0}
                      ref={this._ref('weightNode-'+weightKey)}
                      draggable={data.draggable}
                      dragBoundFunc={(pos) => {
                        const leftBorderX = 0;
                        const rightBorderX = 900;
                        const topBorderY = 0;
                        const bottomBorderY = 470;
                        const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
                        const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
                        return {y: newY, x: newX};
                      }}
                      onDragMove={() => {
                        this.checkWeightPosIntersection('start', weightKey);
                      }}
                      onDragStart={(e) => {
                        data.draggingKey = weightKey;
                        removeAddedItem(weightKey);
                        e.currentTarget.scale({x: 1.3, y: 1.3});
                        const currentContainer = this._getNode('weightContainerNode-'+weightKey);
                        currentContainer.moveToTop();
                      }}
                      onDragEnd={(e) => {
                        const currentNode = this._getNode('weightRectNode-'+weightKey);
                        const leftPlaceNode = this._getNode('leftDropPlace');
                        const rightPlaceNode = this._getNode('rightDropPlace');
                        const leftInters = hasIntersection(leftPlaceNode, currentNode, this)
                        const rightInters = hasIntersection(rightPlaceNode, currentNode, this)
                        e.currentTarget.scale({x: 1, y: 1});
                        if (leftInters || rightInters) {
                          if (leftInters) data.leftWeightPlaceStack.push(weightKey);
                          if (rightInters) data.rightWeightPlaceStack.push(weightKey);
                        } else {
                          removeAddedItem(weightKey);
                          this.setNewWeightPosition(data.draggingKey, data.defaultWeightPlaceStack.length);
                        }
                        this.checkWeightPosIntersection('end', weightKey, this.setNewWeightPosition);
                        data.draggingKey = '';
                      }}
                    >
                      <Image
                        x={correctItem.imgPosX} y={correctItem.imgPosY}
                        width={correctItem.ImgSize || undefined}
                        height={correctItem.ImgSize || undefined}
                        image={weightItemsImgs[weightKey]}
                        ref={this._ref('weightImgNode-'+weightKey)}
                      />
                      <Rect
                        x={0} y={0}
                        width={60} height={60}
                        fill={'black'} opacity={0}
                        ref={this._ref('weightRectNode-'+weightKey)}
                      />
                    </Group>
                  </Group>
                )
              })
            }
            {
              data.defaultWeightPlaceStack.map((weightKey, i) => {
                const indx = i+1;
                return (
                  <Group x={75*i} key={'weightAdditional-'+indx}>
                    <Group x={-25} y={-15}>
                      <Rect x={15} width={10} height={90} fill={'red'} opacity={0} ref={this._ref('weightPosRect'+indx)}/>
                      <Group x={20} ref={this._ref('positionArrowHint'+indx)} visible={false}>
                        <Arrow y={140} points={[0,0,0,-50]} fill={'black'}/>
                        <Arrow y={-60} points={[0,0,0,60]} fill={'black'}/>
                        <Line points={[0,0,0,90]} stroke={'dimgray'} dash={[3,3]} strokeWidth={1}/>
                      </Group>
                    </Group>
                </Group>
                )
              })
            }
          </Group>
        </Card>
      </Group>
    )
  }

  getWeights = () => {
    const data = this.data;
    const leftPlaceWeight = this.data.leftWeightPlaceStack.reduce((accum, key) => {
      return accum + data.weightItems[key].weight;
    }, 0);
    const rightPlaceWeight = this.data.rightWeightPlaceStack.reduce((accum, key) => {
      return accum + data.weightItems[key].weight;
    }, 0);
    return {leftPlace: leftPlaceWeight, rightPlace: rightPlaceWeight}
  }

  render() {
    const data = this.data;


    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this._ref('stage')}
          lessonCode={this.props.code}
        >
          <Layer preventDefault={false}>
            <this.Env/>

            <CanvasScaleWithBowls
              x={480} y={220}
              _ref={this._ref}
              _getNode={this._getNode}
              checkRightWeight={() => {
                const {leftPlace, rightPlace} = this.getWeights();
                return leftPlace > rightPlace;
              }}
              checkLeftWeight={() => {
                const {leftPlace, rightPlace} = this.getWeights();
                return leftPlace < rightPlace;
              }}
              getMovingCallback={this.getMovingCallback}
            />

            <this.Weights x={data.weightCardX} y={data.weightCardY}/>


            <CanvasSuccessCard
              visible={data.endPopUpVisible}
              goToTheoryVisible={data.goToTheoryVisible}
              onClickReset={() => this.onClickReset()}
              btnTopBg={'#0083D2'}
              btnTopTxtColor={'white'}
              btnBottomBg={'#0083D2'}
              btnBottomTxtColor={'white'}
            />
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScaleForCubes);

const styles = {
  mainContainer: {
    background: '#EEEBE5'
  }
};
