import React, {useEffect, useState} from "react";
import theoryBackgroundImg from "../../../images/space/theory/background.png";
import projectorTop from "../../../images/space/theory/Asset 86.png";
import './theory.css';
import {Button, Image} from "antd";
import {FastForwardOutlined} from "@ant-design/icons";
import StringHtmlRender from "../StringHtmlRender/StringHtmlRender";
import TheoryPage from "./TheoryPage";
import {convertTheoryQuestionToCorrectFormat} from "./converter";
import ResetBtn from "../ResetBtn/ResetBtn";
import DialogPopup from "../DialogPopup/DialogPopup";

import {_t} from '../../../utils/lang/common';

const Theory = (props) => {
  const {
    data,
    onClickSkipTheory,
    lessonComplete,
    skipInitialStep,
    bgImg,
    hideResetBtn,
    bgColor
  } = props;

  const {container, questions} = data || {};
  const [theoryQuestions, setTheoryQuestions] = useState(null);
  const [selectedQuestion, selectQuestion] = useState(null);
  const [projectOpen, setProjectOpen] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(!skipInitialStep);

  useEffect(() => {
    const questionsCorrectFormat = convertTheoryQuestionToCorrectFormat(questions);
    setTheoryQuestions(questionsCorrectFormat);
  }, [data])

  useEffect(() => {
    const timeoutMS = skipInitialStep ? 0 : 5000;
    const timeout = setTimeout(() => {
      setProjectOpen(true);
      setDialogVisible(false);
    }, timeoutMS)

    return () => {
      clearTimeout(timeout);
    }
  }, [])

  const methodErrConsoleLog = (methodName) => {
    console.error('' +
      '%c \n- '+ methodName +' function not found \n',
      'font-size: 1.2em; color: gold;',
      '\nAdd '+ methodName +' function to Theory component')
  }

  return (
    <div className={'theory-comp__container'} style={{background: bgColor ? bgColor : ''}}>
      {
        !bgColor && (
          <Image preview={false} src={bgImg || theoryBackgroundImg} className={'theory-comp__background'}/>
        )
      }
      {
        !hideResetBtn && (
          <ResetBtn
            style={{position: 'absolute', right: '50px', bottom: '25px', zIndex: 10}}
            onClick={() => {
              props.onClickReset ? props.onClickReset() : methodErrConsoleLog('onClickReset')
            }}
          />
        )
      }

      {
        dialogVisible && (
          <DialogPopup
            style={{position: 'absolute', left: '160px', top: '230px'}}
          >
            <p style={{width: '250px'}}>
              Привет! <br/><br/>
              Я помогу тебе ответить на вопросы по этой задаче. <br/><br/>
              Нажми на интересующий тебя вопрос из списка.
            </p>
          </DialogPopup>
        )
      }

      {
        lessonComplete ? (
          <DialogPopup style={{position: 'absolute', left: '160px', top: '350px'}}>
            <p> {_t('theory.tsk_complete')}</p>
          </DialogPopup>
        ) : (
          <>
            <Image preview={false} src={projectorTop} className={'theory-projector__top-img'}/>
            <div className={`theory-projector ${projectOpen ? 'theory-projector--open' : ''}`}>
              <div className={'theory-projector__content'}>
                {
                  !selectedQuestion ? (
                    <div className={'theory-projector__questions-list theory-questions'}>
                      <div className={'white-shadow-for-scroll theory-questions__top-shadow'}/>
                      {
                        theoryQuestions ?
                          theoryQuestions.map((qst) => {
                            return (
                              <React.Fragment key={qst.id}>
                                {
                                  !qst.next_step_for && (
                                    <div className={'theory-projector__question'} onClick={() => selectQuestion(qst)}>
                                      <p className={'theory-projector__question-title'}>
                                        <StringHtmlRender htmlForRender={qst?.title}/>
                                      </p>
                                    </div>
                                  )
                                }
                              </React.Fragment>
                            )
                          })
                        :
                          <h2 style={{textAlign: 'center', margin: '20px 0'}}>{_t('theory.theory_not_found')}</h2>
                      }
                      <div className={'white-shadow-for-scroll theory-questions__bottom-shadow'}/>
                      <Button
                        type={'ghost'}
                        className={'theory__btn theory__btn--skip-theory'}
                        onClick={() => {
                          onClickSkipTheory ?
                            onClickSkipTheory()
                            :
                            methodErrConsoleLog('onClickSkipTheory')
                        }}
                      >{_t('theory.skip_theory')} <FastForwardOutlined style={{position: 'relative', top: '3px', fontSize: '18px'}} /></Button>
                    </div>
                  ) : (
                    <TheoryPage
                      selectQuestion={selectQuestion}
                      selectedQuestion={selectedQuestion}
                    />
                  )
                }
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}


export default Theory;
