import React from "react";
import useImage from "use-image";
import submarineImg from "../../../../images/submarine/submarineRealBig.png";
import submarineWaterImg from "../../../../images/submarine/step4/submarineWaterBig2.png";
import triangleImg from "../../../../images/submarine/step4/triangle.png";
import stickImg from "../../../../images/submarine/step4/stick.png";
import platformImg from "../../../../images/submarine/step4/platform.png";
import {Group, Image, Layer, Rect} from "react-konva";
import cloneDeep from "lodash.clonedeep";
import boundariesImg from '../../../../images/submarine/boundariesBig.png';


/**
 * PROPS
 *
 *  externalData: f() => obj
 *  getResetCallback: f(callback)
 *  stageNode: konva node
 *  moveUp: bool
 *  moveDown: bool
 */

class CanvasSubmarineScale extends React.Component
{
  constructor(props)
  {
    super(props);

    // --------- REFS ---------
    this.submarineWaterRef = React.createRef();
    this.stickRef = React.createRef();
    this.platformLeftRef = React.createRef();
    this.platformRightRef = React.createRef();

    this.staticData = {
      maxAngle: 10,
      waterWeight: 14,
      submarineWeight: 10
    };
    this.initialData = {
      submarineWaterWeight: 0,
      rotationSpeed: 0,
      rotationAngle: 0,
    };
    this.data = cloneDeep(this.initialData);
  }

  reset = () => this.data = cloneDeep(this.initialData);

  componentDidMount()
  {
    const {getResetCallback} = this.props;
    if (getResetCallback) {
      getResetCallback(this.reset);
    }
    window.requestAnimationFrame(this.move);
  }

  getAllNodes = () => ({
    submarineWaterNode: this.submarineWaterRef?.current,
    stickNode: this.stickRef?.current,
    platformLeftNode: this.platformLeftRef?.current,
    platformRightNode: this.platformRightRef?.current,
  });

  move = (time) => {
    const {
      maxAngle,
      waterWeight,
      submarineWeight
    } = this.staticData;
    const { moveUp, moveDown, stageNode } = this.props;
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    if (moveDown) {
      data.submarineWaterWeight += 3.2 * timedeltaSec;
    }

    if (moveUp) {
      data.submarineWaterWeight -= 3.2 * timedeltaSec;
    }

    // EXTERNAL DATA
    const externalData = this.props.externalData && this.props.externalData();
    data.submarineWaterWeight = externalData && externalData.waterVolume !== undefined ? externalData.waterVolume * 1.6 : data.submarineWaterWeight;


    if (data.submarineWaterWeight >= 8) data.submarineWaterWeight = 8;
    if (data.submarineWaterWeight <= 0) data.submarineWaterWeight = 0;

    // get forces from external data
    let {leftMomentum, rightMomentum} = externalData || {};
    if (!leftMomentum || !rightMomentum) {
      leftMomentum = Math.round(submarineWeight + data.submarineWaterWeight);
      rightMomentum = waterWeight;
    }

    let correctAngle = 0;

    data.rotationSpeed += (rightMomentum - leftMomentum) * timedeltaSec;

    if (data.rotationAngle > maxAngle) {
      correctAngle = maxAngle;
      data.rotationSpeed = 0;
    } else if (data.rotationAngle < -maxAngle) {
      correctAngle = -maxAngle;
      data.rotationSpeed = 0;
    } else {
      correctAngle = data.rotationAngle + data.rotationSpeed * timedeltaSec;
    }


    data.rotationAngle = correctAngle;

    this.updateStage();
  };

  updateStage()
  {
    const data = this.data;
    const timedelta = data.prevTime ? data.startTime - data.prevTime : 0;

    const stageNode = this.props.stageNode;
    if (!stageNode) return;

    const nodes = this.getAllNodes();

    let waterMaxHeight = data.submarineWaterWeight * 3.8;
    if (Math.abs(waterMaxHeight) >= 30)
      waterMaxHeight = 30;

    const isNotNodes = Object.keys(nodes).some((key) => !nodes[key]);
    if (!isNotNodes) {
      nodes.submarineWaterNode.height(-waterMaxHeight);
      nodes.stickNode.rotation(data.rotationAngle);

      const left = data.rotationAngle * 4;
      const right = -data.rotationAngle * 4;

      const offsetX = Math.abs(data.rotationAngle/2);

      nodes.platformLeftNode.offsetY(left+2);
      nodes.platformLeftNode.offsetX(-offsetX);
      nodes.platformRightNode.offsetY(right+2);
      nodes.platformRightNode.offsetX(offsetX);
    }
    stageNode.draw();
  }

  CanvasComp = () => {
    const data = this.data;

    const [boundaries] = useImage(boundariesImg);
    const [submarine] = useImage(submarineImg);
    const [submarineWater] = useImage(submarineWaterImg);
    const [triangle] = useImage(triangleImg);
    const [stick] = useImage(stickImg);
    const [platform] = useImage(platformImg);

    return (
      <React.Fragment>
        <Group>
          <Image image={triangle} preventDefault={false} x={500} y={0}/>



          <Group x={536} y={60}>

            <Group x={167} ref={this.platformRightRef}>
              <Image image={platform} preventDefault={false} width={160} height={200} y={-7}/>
              <Image image={submarineWater} preventDefault={false} x={-100} y={122} height={70} width={330}/>
            </Group>

            <Group x={-325} ref={this.platformLeftRef}>
              <Image image={platform} preventDefault={false} width={160} height={200} y={-7}/>
              <Image image={submarine} preventDefault={false} x={-100} y={122}/>
              <Rect width={130} height={30} fill={'#C2E5ED'} x={20} y={160} cornerRadius={3}/>
              <Rect ref={this.submarineWaterRef} width={130} height={-data.submarineWaterWeight * 3.8} fill={'#0CB2D4'} x={20} y={190} cornerRadius={0}/>

              <Image image={boundaries} preventDefault={false} x={20} y={159} width={130} height={30}/>
              <Rect stroke={'#9caaad'} x={67} y={38} height={-32} width={130} x={20} y={190} strokeWidth={3}/>
            </Group>


            <Image ref={this.stickRef} image={stick} preventDefault={false} width={513} height={20} offset={{x: 256.5, y: 10}} rotation={0}/>
          </Group>
        </Group>
      </React.Fragment>
    )
  };

  render() {
    return (
      <this.CanvasComp />
    )
  }
}

export default CanvasSubmarineScale;
