

export const getBoolByLsnCode = (code) => ({
  isCrane1: code === 'crane1',
  isCrane2: code === 'crane2',
  isCrane3: code === 'crane3',
  isCrane4: code === 'crane4',
  isCrane5: code === 'crane5',
  isCrane6: code === 'crane6',
  isCrane7: code === 'crane7',
  isCrane10: code === 'crane10',
  isCrane11: code === 'crane11',
  isCrane12: code === 'crane12',
})


export const getCorrectKey = (code) => {
  const { isCrane10, isCrane11, isCrane12 } = getBoolByLsnCode(code);

  return (
    isCrane10 || isCrane11
      ? 'completedWork'
    : isCrane12
      ? 'enginePower'
      : 'weightCargo'
  );
}