import React from "react";
import {CanvasContainer} from "../../../canvas";
import { Image, Rect, Layer, Text, Group, Circle } from "react-konva";
// import Victor from 'victor';
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
// import {GIF} from "../../../canvas/components/GIF";
// import {degToRad, lessonStatus, radToDeg} from '../../../../utils/common';
// import {FieldBlock} from '../components/FieldBlock';
import * as actions from '../../../../store/actions';
import weightImg from '../../../../images/gearRatios/weight.png';
import weight5Img from '../../../../images/gearRatios/weight5.png';
import weight1Img from '../../../../images/gearRatios/weight1.png';
import weight3Img from '../../../../images/gearRatios/weight3.png';
import weightQImg from '../../../../images/gearRatios/weightQ.png';
import weightGruzImg from '../../../../images/gearRatios/weightGruz.png';
import arrowGreyImg from '../../../../images/gearRatios/arrowGrey.png';
import stickImg from '../../../../images/gearRatios/scaleStick.png';
import stick2Img from '../../../../images/gearRatios/scaleStick2.png';
import triangleImg from '../../../../images/gearRatios/triangle.png';
import triangle2Img from '../../../../images/gearRatios/triangle2.png';
import textWeightImg from '../../../../images/gearRatios/textWeight.png';
import marathonImg from '../../../../images/gearRatios/marathon.png';
import statusBar1Img from '../../../../images/gearRatios/statusBar1.png';
import statusBar11Img from '../../../../images/gearRatios/statusBar11.png';
import statusBar2Img from '../../../../images/gearRatios/statusBar2.png';
import statusBar22Img from '../../../../images/gearRatios/statusBar22.png';
import statusBar3Img from '../../../../images/gearRatios/statusBar3.png';
import statusBar33Img from '../../../../images/gearRatios/statusBar33.png';
import {connect} from "react-redux";
import {Input, Form, Space, Button} from 'antd';
import {_t} from "../../../../utils/lang/common";
import {getBoolByLsnCode} from "../utils";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {useQuery} from "../../../../utils/custom-hooks/useQuery";
import {sendSuccessForScenario, showFailOrSuccessPopup} from "../../../../utils/common";
import ScenarioManager from "../../../../utils/ScenarioManager";


class Scales extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();
    this.stickRef = React.createRef();
    this.vectorRopePullingForceTextRef = React.createRef();
    this.leftWeightRef = React.createRef();
    this.leftWeightQRef = React.createRef();
    this.leftWeightQestionRef = React.createRef();
    this.leftWeightfirstRef = React.createRef();
    this.rightWeightRef = React.createRef();
    this.arrowsGreyRef = React.createRef();
    this.textWeightRef = React.createRef();
    this.marathonRef = React.createRef();
    this.firstStatusRef = React.createRef();
    this.secondStatusRef = React.createRef();
    this.thirdStatusRef = React.createRef();
    this.rightWeight2Ref = React.createRef();
    this.canvasInputRef = React.createRef();

    Array(5).fill(1).forEach((el, i) => {
      this[`leftPointRef${i+1}`] = React.createRef();
      this[`rightPointRef${i+1}`] = React.createRef();
      this[`right2PointRef${i+1}`] = React.createRef();
    });


    this.staticData = {
      maxAngle: 7,
      minAngle: -7,
    };
    this.state = {
      leftDragging: false,
      rightDragging: false,
      right2Dragging: false,
      textPoint: 0,

      // POS
      leftPointActive: 0,
      rightPointActive: 0,
      right2PointActive: 0,

      // INPUT
      weightLeft: 2,
      weightLeftInput: 2,
      weightRight: 2,
      weightRight2: 3,
      weightRightInput: 2,
    };
    this.initialData = {
      completedWorkQuestion: null,
      answerIsCorrect:0,
      rotationAngle: 0,
      coordinateArrowGrey: 310,
      changeCoordinateArrowGrey: 1,
      successAnswer: 0,
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success', next_success: 'success'}], this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  openModal = () => showFailOrSuccessPopup(this, true);
  openFailureModal = () => showFailOrSuccessPopup(this, false);

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }
  firstTextInterval = () => {
    this.Interval = setInterval(() => {
      this.setState(prevState => ({
        textPoint: prevState.textPoint + 1
      }))
      if (this.state.textPoint === 300) {
        clearInterval(this.Interval);
      }
    }, 25)}

  startResetOnClick = () => {
    if (parseInt(this.data.completedWorkQuestion) === 7){
      const nodes = this.getAllNodes();
      this.openModal();
      this.firstTextInterval();
      nodes.thirdStatusNode.opacity(1);

      sendSuccessForScenario(this);
    } else {
      this.openFailureModal();
    }
  };


  getAllNodes = () => {
    const leftPointNodes = {};
    const rightPointNodes = {};
    const right2PointNodes = {};
    Array(5).fill(1).map((v, i) => {
      const numb = i+1;
      leftPointNodes[`leftPointNode${numb}`] = this[`leftPointRef${numb}`]?.current;
      rightPointNodes[`rightPointNode${numb}`] = this[`rightPointRef${numb}`]?.current;
      right2PointNodes[`right2PointNode${numb}`] = this[`right2PointRef${numb}`]?.current;
    });

    return ({
      stickNode: this.stickRef?.current,
      vectorRopePullingForceTextNode: this.vectorRopePullingForceTextRef?.current,
      leftWeightNode: this.leftWeightRef?.current,
      leftWeightQNode: this.leftWeightQRef?.current,
      leftWeightQestionNode: this.leftWeightQestionRef?.current,
      leftWeightfirstNode: this.leftWeightfirstRef?.current,
      rightWeightNode: this.rightWeightRef?.current,
      arrowsGreyNode: this.arrowsGreyRef?.current,
      textWeightNode: this.textWeightRef?.current,
      marathonNode: this.marathonRef?.current,
      firstStatusNode: this.firstStatusRef?.current,
      secondStatusNode: this.secondStatusRef?.current,
      thirdStatusNode: this.thirdStatusRef?.current,
      rightWeight2Node: this.rightWeight2Ref?.current,
      canvasInputNode: this.canvasInputRef?.current,

      // POINTS REFS
      ...leftPointNodes,
      ...rightPointNodes,
      ...right2PointNodes,
    });
  };

  move = (time) => {
    const data = this.data;
    const {maxAngle, minAngle} = this.staticData;
    // console.log(this.data.successAnswer);
    const nodes = this.getAllNodes();
    const {
      leftPointActive,
      rightPointActive,
      right2PointActive,
      weightLeft,
      weightRight,
      weightRight2,
    } = this.state;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const {isLever5, isLever1,} = getBoolByLsnCode(this.props.code);
    if(isLever5 && this.data.successAnswer === 0){
      this.state.leftPointActive = 1;
      this.state.weightLeft = 5;
      this.state.weightRight = 1;
    }
    if(isLever5 && this.data.successAnswer === 1){
      this.state.leftPointActive = 2;
      this.state.rightPointActive = 0;
      nodes.rightWeightNode.x(900);
      nodes.rightWeightNode.y(430);
      nodes.rightWeight2Node.opacity(1);
      this.state.weightLeft = 5;
      this.state.weightRight = 1;
      this.data.successAnswer = 2;
    }
    if(isLever5 && this.data.successAnswer === 3){
      this.state.leftPointActive = 2;
      this.state.weightLeft = 7;
      this.state.rightPointActive = 0;
      this.state.right2PointActive = 0;
      nodes.rightWeightNode.x(900);
      nodes.rightWeightNode.y(430);
      nodes.rightWeight2Node.x(800);
      nodes.rightWeight2Node.y(400);
      this.state.weightRight = 1;
      nodes.leftWeightQNode.opacity(1);
      nodes.canvasInputNode.opacity(1);
      nodes.leftWeightQestionNode.opacity(1);
      nodes.leftWeightfirstNode.opacity(0);
      nodes.vectorRopePullingForceTextNode.text('Определи вес груза');
      nodes.vectorRopePullingForceTextNode.x(-150);
      this.data.successAnswer = 4;
    }

    if(this.data.coordinateArrowGrey <= 320 && this.data.changeCoordinateArrowGrey === 1){
      this.data.coordinateArrowGrey = this.data.coordinateArrowGrey + 0.5;
      if(this.data.coordinateArrowGrey === 320) {
        this.data.changeCoordinateArrowGrey = 0;
      }
    }
    if(this.data.coordinateArrowGrey >= 300 && this.data.changeCoordinateArrowGrey === 0){
      this.data.coordinateArrowGrey = this.data.coordinateArrowGrey - 0.5;
      if(this.data.coordinateArrowGrey === 300) {
        this.data.changeCoordinateArrowGrey = 1;
      }
    }

    if(isLever5) {
      if (this.state.textPoint <= 150) {
        nodes.marathonNode && nodes.marathonNode.opacity(this.state.textPoint / 150);
      }
      if (this.state.textPoint > 150) {
        nodes.marathonNode && nodes.marathonNode.opacity(1 - (this.state.textPoint - 150) / 150);
      }
    }


    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;


    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;


    const leftMomentum = leftPointActive * weightLeft;
    const rightMomentum = isLever1 ? rightPointActive * weightRight : rightPointActive * weightRight + right2PointActive * weightRight2;

    let angleDelta = 0;
    let correctAngle = 0;



    const s = 30;

    if (leftMomentum > rightMomentum)
      angleDelta = (leftMomentum / s) / Math.PI * -180 * timedeltaSec;
    if (leftMomentum < rightMomentum)
      angleDelta = (rightMomentum / s) / Math.PI * 180 * timedeltaSec;



    // fix stick tremor if equal moment
    if (leftMomentum === rightMomentum && data.rotationAngle < 0)
      angleDelta = .1;
    if (leftMomentum === rightMomentum && data.rotationAngle > 0)
      angleDelta = -.1;

    if (data.rotationAngle >= maxAngle && leftMomentum < rightMomentum)
      correctAngle = maxAngle;
    else if (data.rotationAngle <= minAngle && leftMomentum > rightMomentum)
      correctAngle = minAngle;
    else if ((Math.round(data.rotationAngle) === 0) && (leftMomentum === rightMomentum)) {
      correctAngle = 0;
      if(isLever5 && nodes.arrowsGreyNode.opacity() === 0 && this.data.successAnswer === 0){
        this.openModal();
        nodes.firstStatusNode.opacity(1);
        this.state.leftPointActive = 0;
        nodes.leftWeightNode.x(325);
        nodes.leftWeightNode.y(273);
        this.data.successAnswer = 1;
      }
      if(isLever5 && nodes.arrowsGreyNode.opacity() === 0 && this.data.successAnswer === 2){
        this.openModal();
        nodes.secondStatusNode.opacity(1);
        this.state.leftPointActive = 0;
        this.data.successAnswer = 3;
      }

    } else
      correctAngle = data.rotationAngle + angleDelta;


    data.rotationAngle = correctAngle;
    this.updateStage();
  };

  updateStage() {
    const {
      leftDragging,
      rightDragging,
      right2Dragging,
      leftPointActive,
      rightPointActive,
      right2PointActive,
      // weightLeft,
      // weightRight,
    } = this.state;
    const {rotationAngle} = this.data;
    // const timedelta = prevTime ? startTime - prevTime : 0;
    const {
      isLever5,
    } = getBoolByLsnCode(this.props.code);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const nodes = this.getAllNodes();


    /**
     * Update weight blocks positions ( move weight blocks to active points )
     */
    if (leftPointActive && !leftDragging) {
      const leftPointNode = nodes[`leftPointNode${leftPointActive}`];
      const leftPos = leftPointNode.getAbsolutePosition();
      const scale = leftPointNode.getAbsoluteScale();
      const correctPosX = (leftPos.x-leftPointNode.offsetX()*scale.x) / scale.x;
      const correctPosY = (leftPos.y-5*scale.y) / scale.y;
      nodes.leftWeightNode.position({x: correctPosX, y: correctPosY});
    }
    if (rightPointActive && !rightDragging) {
      const rightPointNode = nodes[`rightPointNode${rightPointActive}`];
      const rightPos = rightPointNode.getAbsolutePosition();
      const scale = rightPointNode.getAbsoluteScale();
      const correctPosX = (rightPos.x-rightPointNode.offsetX()*scale.x) / scale.x;
      const correctPosY = (rightPos.y-5*scale.y) / scale.y;
      nodes.rightWeightNode.position({x: correctPosX, y: correctPosY});
    }
    if (isLever5 && right2PointActive && !right2Dragging) {
      const right2PointNode = nodes[`right2PointNode${right2PointActive}`];
      const right2Pos = right2PointNode.getAbsolutePosition();
      const scale = right2PointNode.getAbsoluteScale();
      const correctPosX = (right2Pos.x-right2PointNode.offsetX()*scale.x) / scale.x;
      const correctPosY = (right2Pos.y-5*scale.y) / scale.y;
      nodes.rightWeight2Node.position({x: correctPosX, y: correctPosY});
    }
    if(isLever5){
      nodes.arrowsGreyNode.y(this.data.coordinateArrowGrey);
    }


    nodes.stickNode.rotation(rotationAngle);

    stageNode.draw();
  }

  dragBoundFunc= (pos, code) => {
    /**
     *  Function to limit movement
     */
    const stageNode = this.stageRef?.current;
    const stageWidth = stageNode.width();
    const stageHeight = stageNode.height();
    const isRight = code === 'right';
    const isRight2 = code === 'right2';
    const leftBorderX = (isRight || isRight2) ? stageWidth * .55 : stageWidth * .05;
    const rightBorderX = (isRight || isRight2) ? stageWidth * .95 : stageWidth * .45;
    const topBorderY = stageHeight * .22;
    const bottomBorderY = stageHeight * .61;
    const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
    const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
    return {y: newY, x: newX};
  };

  onDragStart=(e, code) => {
    const {
      isLever5,
    } = getBoolByLsnCode(this.props.code);
    const nodes = this.getAllNodes();
    // const currNode = e.target;
    this.setState({leftDragging: code === 'left', rightDragging: code === 'right', right2Dragging: code === 'right2'});
    if(isLever5){
    nodes.textWeightNode.opacity(0);
    }
  };

  onDragEnd = (e, direction) => {
    const {
      leftPointActive,
      rightPointActive,
      right2PointActive,
    } = this.state;
    const {
      isLever5,
    } = getBoolByLsnCode(this.props.code);
    const stageNode = this.stageRef?.current;
    const scale = stageNode.getAbsoluteScale();

    const nodes = this.getAllNodes();
    const nodesKeys = Object.keys(nodes);
    const currNode = e.target;
    const currNodePos = currNode.getAbsolutePosition();
    const isLeft = direction === 'left';
    const isRight2 = direction === 'right2';

    /**
     * Left & right points absolute pos {x: ..., y: ...}
     */
    const leftArrPos = nodesKeys.filter(k => k.includes('leftPointNode')).map((k) => nodes[k].getAbsolutePosition());
    const rightArrPos = nodesKeys.filter(k => k.includes('rightPointNode')).map((k) => nodes[k].getAbsolutePosition());
    const right2ArrPos = isLever5 ? nodesKeys.filter(k => k.includes('right2PointNode')).map((k) => nodes[k].getAbsolutePosition()) : null;

    /**
     * Getting correct arr left or right
     */
    const correctArr = (isLeft && !isRight2) ? leftArrPos : rightArrPos;
    if(isRight2) {
      this.data.correctArr = right2ArrPos;
    }

    /**
     * Set 0 to the active point for the current direction
     * and set previous value for an another direction
     */
    let newLeftActivePoint = !isLeft ? leftPointActive : 0;
    let newRightActivePoint = (isLeft || isRight2)? rightPointActive : 0;
    let newRight2ActivePoint = (isLeft || !isRight2) ? right2PointActive : 0;

    if(isLever5){
      nodes.arrowsGreyNode.opacity(0);
    }

    correctArr.map((el, i) => {
      const elId = i+1;
      const elPosX = el.x;
      const elPosY = el.y;
      const currPosX = currNodePos.x;
      const currPosY = currNodePos.y;

      let isXCorrect = false;
      let isYCorrect = false;

      /**
       * Check the hit of the dragged weight on the point along X and Y
       */

      let coordsPaddingX = 50 * scale.x;
      let coordsPaddingY = 30 * scale.y;
      if ((elPosX + coordsPaddingX >= currPosX) && (elPosX-coordsPaddingX <= currPosX))
        isXCorrect = true;
      if ((elPosY + coordsPaddingY >= currPosY) && (elPosY-coordsPaddingY <= currPosY))
        isYCorrect = true;

      /**
       * Set new active point
       */
      if (isXCorrect && isYCorrect && !isRight2)
        if (isLeft)
          newLeftActivePoint = elId;
        else
          newRightActivePoint = elId;

      if (isXCorrect && isYCorrect && isRight2 && isLever5){
          newRight2ActivePoint = elId;}
    });

    this.setState((prev) => ({
      ...prev,
      leftDragging: false,
      rightDragging: false,
      right2Dragging: false,
      leftPointActive: newLeftActivePoint,
      rightPointActive: newRightActivePoint,
      right2PointActive: newRight2ActivePoint,
    }));
  };

  onChangeInput = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    const min = Number(e.target.min);
    const max = Number(e.target.max);
    let correctVal = val;
    if (Number(val) < min)
      correctVal = min;
    if (Number(val) > max)
      correctVal = max;
    this.setState({[name]: correctVal, [`${name}Input`]: val})
  };

  onDragMove = () => {};

  CanvasLever = () => {
    const {
      weightLeft,
      weightRight,
    } = this.state;
    const {
      isLever1,
      isLever5,
      isLever6,
    } = getBoolByLsnCode(this.props.code);

    const [stick] = isLever1 ? useImage(stickImg) : useImage(stick2Img);
    const [weight] = useImage(weightImg);
    const [weight5] = useImage(weight5Img);
    const [weightQ] = useImage(weightQImg);
    const [weightGruz] = useImage(weightGruzImg);
    const [weight1] = useImage(weight1Img);
    const [weight3] = useImage(weight3Img);
    const [arrowGrey] = useImage(arrowGreyImg);
    const [basis] = isLever1 ? useImage(triangleImg) : useImage(triangle2Img);
    const [textWeight] = useImage(textWeightImg);
    const [marathon] = useImage(marathonImg);
    const [statusBar1] = useImage(statusBar1Img);
    const [statusBar11] = useImage(statusBar11Img);
    const [statusBar2] = useImage(statusBar2Img);
    const [statusBar22] = useImage(statusBar22Img);
    const [statusBar3] = useImage(statusBar3Img);
    const [statusBar33] = useImage(statusBar33Img);

    const stickWidth = 900;

    const CirclesPoints = ({direction}) => {
      /**
       * Create 5 circles points
       */
      const isLeftSide = direction === 'left';
      return (
        <>
          {[1, 2, 3, 4, 5].map((v) => {
            let posY = 45;
            let posX = stickWidth / 10 * v;
            const offsetX = v * 2;
            let correctFill = 'white';

            const correctCirclePosX = isLeftSide ? -posX : posX;
            const correctOffsetX = isLeftSide ? -offsetX : offsetX;
            const refNamePos = isLeftSide ? `leftPointRef${v}` : `rightPointRef${v}`;
            return (
              <Circle
                ref={this[refNamePos]}
                key={`${direction}${v}`}
                radius={7}
                fill={correctFill}
                x={correctCirclePosX} y={posY}
                offsetX={correctOffsetX}
                preventDefault={false}
              />

            )
          })}
        </>
      )
    };

    const CirclesPoints2 = ({direction}) => {
      /**
       * Create 5 circles points
       */
      const isLeftSide = direction === 'left';
      return (
          <>
            {[1, 2, 3, 4, 5].map((v) => {
              let posY = 45;
              let posX = stickWidth / 10 * v;
              const offsetX = v * 2;
              let correctFill = 'white';

              const correctCirclePosX = isLeftSide ? -posX : posX;
              const correctOffsetX = isLeftSide ? -offsetX : offsetX;
              const refNamePos2 = isLeftSide ? `leftPointRef${v}` : `right2PointRef${v}`;
              return (
                  <Circle
                      ref={this[refNamePos2]}
                      key={`${direction}${v}`}
                      radius={7}
                      fill={correctFill}
                      x={correctCirclePosX} y={posY}
                      offsetX={correctOffsetX}
                      preventDefault={false}
                  />

              )
            })}
          </>
      )
    };




    return (
      <React.Fragment>
        <Layer>

          {/* -------------- SCALES -------------- */}
          <Group x={500} y={250}>
            <Group ref={this.stickRef} y={45} offsetY={90/2}>
              <Image image={stick} y={50} offsetY={90/2} offsetX={stickWidth/2} width={stickWidth} height={50} rotation={0} preventDefault={false}/>

              <CirclesPoints direction={'left'}/>
              <CirclesPoints direction={'right'}/>
              {isLever5 ? <CirclesPoints2 direction={'right2'}/> : null}
            </Group>

            <Image image={basis} y={!isLever1 ? 53 : 45} offsetX={110/2} width={isLever1 ? 110 : 70} x={!isLever1 ? 19: 0} preventDefault={false}/>
            <Circle radius={4} fill={'black'} y={47} preventDefault={false}/>
          </Group>


          {/* -------------- TEXT -------------- */}

          {isLever5 || isLever6 ?
          <Text
              ref={this.vectorRopePullingForceTextRef}
              x={15} y={80}
              text={'Уравновесь рычаг с помощью имеющихся грузов'}
              width={900} strokeWidth={.5}
              stroke={'#486a93'}
              fill={'#486a93'}
              fontSize={35}
              align={'center'}
          /> : null}

          {isLever5 ? <Group
              ref={this.textWeightRef}
              x={770}
              y={385}
              width={74}
          >
            <Image image={textWeight}/>
          </Group> : null}


          {isLever5 ? <Group x={150} y={140} opacity={0} ref={this.canvasInputRef}>
            <Text text={`P = `} stroke={'#486a93'} fill={'#486a93'} width={900} strokeWidth={.3} fontSize={35}/>
            <CanvasInput
                x={70}
                y={0}
                width={90}
                height={30}
                stage={this.stageRef?.current}
                textColor={'#486a93'}
                onInput={(val) => this.data.completedWorkQuestion = val}
                value={this.data.completedWorkQuestion}
            />
            <Text x={170} text={`H`} stroke={'#486a93'} fill={'#486a93'} width={900} strokeWidth={.3} fontSize={35}/>
            <Group
                x={230}
                y={-5}
                onClick={() => this.startResetOnClick()}
                onTap={() => this.startResetOnClick()}
            >
              <Rect width={135} height={40} fill={'#486a93'} cornerRadius={10} />
              <Text ref={this.startBtnRef} text={'Проверить'} stroke={'white'} fill={'white'} strokeWidth={1} fontSize={20} align={'center'} verticalAlign={'middle'} width={130} height={50} y={-4} x={2}/>
            </Group>
          </Group> : null}


          {/* -------------- WEIGHTS -------------- */}

          <Group
            ref={this.leftWeightRef}
            x={isLever1 ? 230 : 420}
            y={isLever1 ? 400 : 290}
            draggable={isLever1 ? true : false}
            dragBoundFunc={(pos) => this.dragBoundFunc(pos, 'left')}
            onDragMove={this.onDragMove}
            onDragStart={(e) => this.onDragStart(e, 'left')}
            onDragEnd={(e) => this.onDragEnd(e, 'left')}
            width={74}
            preventDefault={true}
          >
              <Group opacity={1} ref={this.leftWeightfirstRef}>
            <Image image={isLever1 ? weight : weight5} offsetX={isLever1 ? 74/2 : 60/2} />
              </Group>

              <Group ref={this.leftWeightQRef} opacity={0}>
            <Image image={isLever1 ? null : weightQ} offsetX={65/2}/>
             </Group>

            {isLever1 ? <Text text={weightLeft} x={-25} y={75} stroke={'white'} fill={'white'} fontSize={25} align={'center'} width={50}  height={50}/> : null}
          </Group>
          <Group ref={this.leftWeightQestionRef} opacity={0}>
            <Image image={isLever1 ? null : weightGruz} y={60} x={490}/>
          </Group>

            {isLever5 ?
              <Group>
                <Image image={statusBar1} x={868} y={30} height={20} width={32} opacity={1}/>
              </Group>: null}
          {isLever5 ?
              <Group>
                <Image image={statusBar11} ref={this.firstStatusRef} x={868} y={30} height={20} width={32} opacity={0}/>
              </Group>: null}
            {isLever5 ?
              <Group>
                <Image image={statusBar2} x={901} y={30} height={20} width={32} opacity={1}/>
              </Group>: null}
          {isLever5 ?
              <Group>
                <Image image={statusBar22} ref={this.secondStatusRef} x={901} y={30} height={20} width={32} opacity={0}/>
              </Group>: null}
            {isLever5 ?
              <Group>
                <Image image={statusBar3} x={934} y={30} height={20} width={32} opacity={1}/>
              </Group>: null}
          {isLever5 ?
              <Group>
                <Image image={statusBar33} ref={this.thirdStatusRef} x={934} y={30} height={20} width={32} opacity={0}/>
              </Group>: null}


            <Group
            ref={this.rightWeightRef}
            x={isLever1 ? 760 : 900}
            y={isLever1 ? 400 : 430}
            draggable={true}
            dragBoundFunc={(pos) => this.dragBoundFunc(pos, 'right')}
            onDragMove={this.onDragMove}
            onDragStart={(e) => this.onDragStart(e, 'right')}
            onDragEnd={(e) => this.onDragEnd(e, 'right')}
            width={74}
            preventDefault={true}
          >
            <Image image={isLever1 ? weight : weight1} offsetX={isLever1 ? 74/2 : 60/2}/>
            {isLever1 ? <Text text={weightRight} x={-25} y={75} stroke={'white'} fill={'white'} fontSize={25} align={'center'} width={50}  height={50}/> : null}
          </Group>

          {isLever5 ? <Group
              ref={this.rightWeight2Ref}
              x={840}
              y={401}
              draggable={true}
              opacity={0}
              dragBoundFunc={(pos) => this.dragBoundFunc(pos, 'right2')}
              onDragMove={this.onDragMove}
              onDragStart={(e) => this.onDragStart(e, 'right2')}
              onDragEnd={(e) => this.onDragEnd(e, 'right2')}
              width={74}
              preventDefault={true}
          >
            <Image image={weight3} offsetX={60/2}/>
          </Group> : null}

          {isLever5 ? <Group
            y={310}
            width={74}
            ref={this.arrowsGreyRef}
          >
            <Image image={arrowGrey} x={583}/>
            <Image image={arrowGrey} x={670} y={-10}/>
            <Image image={arrowGrey} x={760} y={-20}/>
            <Image image={arrowGrey} x={845} y={-30}/>
            <Image image={arrowGrey} x={935} y={-40}/>
          </Group> : null}
          {isLever5 ? <Group
              ref={this.marathonRef}
              x={580}
              y={5}
              opacity={1}
              width={0}
          >
            <Image image={marathon} height={200}
                   width={260}/>
          </Group> : null}
        </Layer>
      </React.Fragment>
    )
  };

  render() {
    const {weightLeftInput, weightRightInput} = this.state;
    const {
      isLever1,
    } = getBoolByLsnCode(this.props.code);
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.CanvasLever/>
        </CanvasContainer>



        {/* -------------- FIELDS  -------------- */}

        <Form layout="vertical"  style={styles.fieldsBlock}>
          <Space align={'enter'} size={20}>
            {isLever1 ?
            <label style={{...styles.label, ...styles.fontSize}}>
              {_t("left_weight")}
              <Input
                type={'number'}
                name="weightLeft"
                onChange={this.onChangeInput}
                defaultValue={weightLeftInput}
                min={1}
                max={100}
                style={styles.fontSize}
              />
            </label> : null }
            {isLever1 ?
                <label style={{...styles.label, ...styles.fontSize}}>
              {_t("right_weight")}
              <Input
                type={'number'}
                name={'weightRight'}
                onChange={this.onChangeInput}
                defaultValue={weightRightInput}
                min={1}
                max={100}
                style={styles.fontSize}
              />
            </label> : null }
          </Space>
        </Form>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  activeLesson: state.lessonsReducer.activeLesson,
  failureModalVisible: state.commonReducer.failureModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  changeVKShareVisible: (visible) => dispatch(actions.changeVKShareVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Scales);

const styles = {
  mainContainer: {
    background: 'rgba(230,230,230, 1)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  fieldsBlock: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    top: '2.5vw',
  },
  label: {
    display: 'block',
    maxWidth: '20vmin',
  },
  fontSize: {
    fontSize: '2vmin'
  }
  // input: {
  //   display: 'block',
  //   maxWidth: '90px',
  // }
};
