import {Group, Rect} from "react-konva";
import React from "react";
import ChartsContainer from "./ChartContainer";


const SidePage = (props) => {
  const { $this } = props;

  let sidePageStyles = style.sidePage;
  if ($this.state.step > 0) {
    sidePageStyles = {
      ...style.sidePage,
      right: 'auto',
      top: '0',
      left: '49%',
      transform: 'translate(-100%,0)',
    };
  }
  return (
    <div style={sidePageStyles}>
      <ChartsContainer
        lsnCode={$this.props.code}
        externalData={() => ({...$this.data, ...$this.state})}
        getResetCallback={$this.getResetBikerChartCallback}
      />
    </div>
  )
}

export default SidePage;

const style = {
  sidePage: {
    width: '49%',
    height: '100%',
    top: 0,
    right: 0,
    position: 'absolute',
    zIndex: 0,
    transition: 'all 1s ease',
  }
}