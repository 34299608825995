import pressureExpStartSrc from '../../../images/bondGas/Pressure_exp_start.mp4';
import pressureExpEndSrc from '../../../images/bondGas/Pressure_exp_end.mp4';
import gas2Src from '../../../images/bondGas/gas2.mp4';
import MovingArrow from "../../canvas/components/MovingArrow";
import React from "react";
import cloneDeep from 'lodash.clonedeep';


const nextBtnStg = {
  next: {text: "Далее", width: 150, textWidth: 150, x: 790},
  nextStep: {text: "Следующее задание", width: 280, textWidth: 280, x: 660},
}

export const defaultPatchData = {
  title: '',

  timerStart: false,
  timerVisible: false,

  videoSrc: undefined,
  onVideoEnd: (comp) => {},

  theoryVisible: false,
  onClickSkipTheory: (comp) => {},

  setWheelRotationDeg: undefined,

  nextBtnVisible: false,
  nextBtnStg: { ...nextBtnStg.next },


  pressureAnswerBtnsVisible: false,
  pressureAnswerCorrect: undefined, // rise, drop
  pressureAnswered: false,

  successIconSettings: {visible: false},

  rotateElementName: 'wheel',
  rotateArrowVisible: false,

  finallyStepVisible: false,
  successFinallyPopup: false,

  flaskChipVisible: false,

  movingArrowSettings: { visible: false },

  checkMarkStgs: {visible: false},
  warningMarkStgs: {visible: false},


  // Элементы которые надо скрыть для задачи 3
  hideVolumeHandle: false,
  hideGasController: false,
}

const checkMarkPos = {
  leftBtn: {x: 433, y: 305},
  wheel: {x: 100, y: 85},
  tempSlider: {x: 590, y: 460},
  cardPressure: {x: 880, y: 397, scale: {x: .7, y: .7}},
}

const titles = {
  block2_1: 'Увеличь число молекул газа и посмотри, \nкак изменится давление',
  block2_3: 'Исследуй, как зависит давления газа от \nколичества молекул',
  block2_4: 'Что будет с давлением при увеличении \nколичества молекул?',

  block3_1: 'Измени доступный для газа объем \nи посмотри, как изменится давление',
  block3_3: 'Исследуй, как зависит давления газа от \nрасполагаемого объема',
  block3_4: 'Что будет с давлением при увеличении \nрасполагаемого объема?',

  block4_1: 'Нагрей газ и посмотри, \nкак изменится давление',
  block4_3: 'Исследуй, как зависит давления газа от \nизменения температуры',
  block4_4: 'Что будет с давлением при увеличении \nтемпературы?',
}

const generatePressureAnswersBlock = (keys, titleKeys, pressureAnswerCorrect /* rise || drop */) => [
  {
    key: keys[0],
    patchData: {
      ...defaultPatchData,
      title: titles[titleKeys[0]],
      pressureAnswerBtnsVisible: true,
      pressureAnswerCorrect,
    },
    next_success: keys[1]
  },{
    key: keys[1],
    patchData: {
      ...defaultPatchData,
      title: titles[titleKeys[1]],
      pressureAnswerBtnsVisible: true,
      pressureAnswered: true,
      nextBtnVisible: true,
      nextBtnStg: { ...nextBtnStg.nextStep },
      pressureAnswerCorrect,
    },
  },
]

export const getScenario = (code) => {
  let res;
  if (code === 'course7_gas1') {
    res = scenario1;
  } else if (code === 'course7_gas2') {
    res = scenario2;
  } else if (code === 'course7_gas3') {
    res = scenario3;
  }
  return res
};

export const scenario1 = [
  {
    key: 'initial animation',
    patchData: {
      ...defaultPatchData,

      videoSrc: pressureExpStartSrc,
      onVideoEnd: (comp) => {
        comp.scenarioManager.next()
      },
    },

  },


  // --------- BLOCK 2 ---------
  {
    key: 'block 2-1',
    patchData: {
      ...defaultPatchData,
      title: titles['block2_1'],
      movingArrowSettings: {
        x: 460, y: 320,
        visible: true,
      },
    },
    next_success: 'block 2-2'
  },{
    key: 'block 2-2',
    patchData: {
      ...defaultPatchData,
      title: titles['block2_1'],
      checkMarkStgs: {...checkMarkPos.leftBtn, visible: true},
      nextBtnVisible: true,
      nextBtnStg: { ...nextBtnStg.nextStep },
    },
    next_success: 'block 2-3'
  },{
    key: 'block 2-3',
    patchData: {
      ...defaultPatchData,
      title: titles['block2_3'],
      nextBtnVisible: true
    },
    next_success: 'block 2-4',
    switch_at: 7,
  },
  ...generatePressureAnswersBlock(['block 2-4','block 2 success'], ['block2_4','block2_4'], 'rise'),



  // --------- BLOCK 3 ---------
  {
    key: 'block 3-1',
    patchData: {
      ...defaultPatchData,
      title: titles['block3_1'],
      rotateArrowVisible: true,
    },
    next_success: 'block 3-2'
  },{
    key: 'block 3-2',
    patchData: {
      ...defaultPatchData,
      title: titles['block3_1'],
      checkMarkStgs: {...checkMarkPos.wheel, visible: true},
      nextBtnVisible: true,
      nextBtnStg: { ...nextBtnStg.nextStep },
    },
    next_success: 'block 3-3'
  },{
    key: 'block 3-3',
    patchData: {
      ...defaultPatchData,
      title: titles['block3_3'],
      nextBtnVisible: true,
    },
    next_success: 'block 3-4',
    switch_at: 7
  },
  ...generatePressureAnswersBlock(['block 3-4','block 3 success'], ['block3_4','block3_4'], 'drop'),



  // --------- BLOCK 4 ---------
  {
    key: 'block 4-1',
    patchData: {
      ...defaultPatchData,
      title: titles['block4_1'],
      movingArrowSettings: {
        x: 500, y: 480,
        visible: true, rotation: -90
      },
    },
    next_success: 'block 4-2',
  },{
    key: 'block 4-2',
    patchData: {
      ...defaultPatchData,
      title: titles['block4_1'],
      checkMarkStgs: {...checkMarkPos.tempSlider, visible: true},
      nextBtnVisible: true,
      nextBtnStg: { ...nextBtnStg.nextStep },
    }
  },{
    key: 'block 4-3',
    patchData: {
      ...defaultPatchData,
      title: titles['block4_3'],
      nextBtnVisible: true,
    },
    next_success: 'block 4-4',
    switch_at: 7
  },
  ...generatePressureAnswersBlock(['block 4-4','block 4 success'], ['block4_4','block4_4'], 'rise'),


  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        if (comp.data.skipAnimation) {
          return comp.scenarioManager.selectStepByKey('success');
        }
        comp.scenarioManager.selectStepByKey('end animation');
      },
    }
  },

  {
    key: 'end animation',
    patchData: {
      ...defaultPatchData,
      videoSrc: pressureExpEndSrc,
      onVideoEnd: (comp) => {
        comp.scenarioManager.selectStepByKey('success')
      },
    },
  },


  {
    key: 'success',
    patchData: {
      ...defaultPatchData,

      skipAnimation: true,
      finallyPopupText: undefined,
      finallyStepVisible: true,
      successFinallyPopup: true,
      onClickReset: (comp) => {
        comp.data = {
          ...cloneDeep(comp.initialData),
          ...defaultPatchData
        };
        comp._setTransformRotation(0);
        comp.scenarioManager.selectStepByKey('block 2-1');
      }
    }
  },
];

export const scenario2 = [
  {
    key: 'step 1',
    patchData: {
      ...defaultPatchData,
      flaskChipVisible: true,
      title: 'Определи, выдержит ли чип давление\n5 МПа',

      temperature: 329,
    },
    next_success: 'step 1-2'
  },{
    key: 'step 1-2',
    patchData: {
      ...defaultPatchData,
      flaskChipVisible: true,
      title: 'Определи, выдержит ли чип давление\n5 МПа',

      checkMarkStgs: {...checkMarkPos.cardPressure, visible: true},
    },
    next_success: 'animation',
    switch_at: 3
  },

  {
    key: 'animation',
    patchData: {
      ...defaultPatchData,
      videoSrc: gas2Src,
      onVideoEnd: (comp) => {
        comp.scenarioManager.selectStepByKey('step 3')
      },
    },
  },

  {
    key: 'step 3',
    patchData: {
      ...defaultPatchData,
      flaskChipVisible: true,
      title: 'Чтобы чип остался целым, необходимо\nсрочно снизить давление до 5000 кПа',

      setWheelRotationDeg: (comp) => {
        if (!comp.data.rotationAdded) {
          comp.data.wheelRotationDeg = -2800;
          comp._setTransformRotation(-2800);
          comp.data.rotationAdded = true;
        }
      },
      temperatureSlider: 0,
      temperSlideControllerX: 0,
      temperature: 727,
      gasAmount: 337,

      timerValue: 10,
      warningMarkStgs: {...checkMarkPos.cardPressure, visible: true},
      timerVisible: true,
      timerStart: true
    },
    next_success: 'pressure success',
    next_failure: 'fail popup',
  },

  {
    key: 'pressure success',
    patchData: {
      ...defaultPatchData,
      flaskChipVisible: true,
      timerVisible: true,
    },
    next_success: 'success popup',
    switch_at: 3,
  },

  {
    key: 'fail popup',
    patchData: {
      ...defaultPatchData,
      flaskChipVisible: true,
      finallyStepVisible: true,
      successFinallyPopup: false,
      timerVisible: true,
      finallyPopupText: 'Чип испорчен',

      onClickReset: (comp) => {
        comp.data.rotationAdded = false;
        comp.scenarioManager.selectStepByKey('step 3');
      }
    }
  },{
    key: 'success popup',
    patchData: {
      ...defaultPatchData,
      flaskChipVisible: true,
      finallyStepVisible: true,
      successFinallyPopup: true,
      timerVisible: true,
      finallyPopupText: 'Задача выполнена',

      onClickReset: (comp) => {
        comp.data.rotationAdded = false;
        comp.scenarioManager.selectStepByKey('step 3');
      }
    }
  },

  {
    key: 'theory',
    patchData: {
      ...defaultPatchData,
      flaskChipVisible: true,
      theoryVisible: true,
      onClickSkipTheory: (comp) => {
        comp.scenarioManager.selectStepByKey('success');
      },
    }
  },

  {
    key: 'success',
    patchData: {
      ...defaultPatchData,
      flaskChipVisible: true,

      finallyPopupText: undefined,
      finallyStepVisible: true,
      successFinallyPopup: true,
      onClickReset: (comp) => {
        comp.data = {
          ...cloneDeep(comp.initialData),
          ...defaultPatchData
        };
        comp._setTransformRotation(0);
        comp.scenarioManager.selectStepByKey('step 1');
      }
    }
  },

];

export const scenario3 = [
  {
    key: 'initial',
    patchData: {
      ...defaultPatchData,

      title: 'Изменяй температуру газа и определи \nзависимость между скоростью движения \nмолекул, температурой и внутренней энергией',
      gasAmount: 100,
      hideVolumeHandle: true,
      hideGasController: true,
    }
  }
];
