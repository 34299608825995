import React from "react";
import Konva from "konva";
import Ball from "../components/Ball";

class Wall extends React.Component {
  constructor(props) {
    super(props);

    this.id = props.id;

    this.const = {
      orientation: props.orientation,
    };

    this.var = {
      T: 25,
      x: 1/2*(props.start.x + props.end.x),
      y: 1/2*(props.start.y + props.end.y),
      v: 0,
      prevx: 0,
    };

    this.line = new Konva.Line({
      id: this.id,
      index: props.index,
      x: 1/2*(props.start.x + props.end.x),
      y: 1/2*(props.start.y + props.end.y),
      points: (props.orientation === 'top' || props.orientation === 'bottom')
      ? [
        -(props.end.x-props.start.x)/2,
        0,
        (props.end.x-props.start.x)/2,
        0,
      ]
      : [
        0,
        -(props.end.y-props.start.y)/2,
        0,
        (props.end.y-props.start.y)/2,
      ],
      stroke: props.color,
      strokeWidth: props.strokeWidth,
      draggable: props.draggable,
    })

    // console.log('Wall debug: ', this);
    // console.log('wall debug on props: ', props);

  }

  checkCollision(ball) {

    if (!(ball instanceof Ball)) {console.log('not a ball'); return;}

    if (this.const.orientation === 'top') {
      if (ball.var.pos.y - ball.const.R <= this.var.y) {
        if (ball.var.V.y <= 0) {
          ball.var.V.y *= -1;
          ball.var.pos.y = this.var.y + ball.const.R;
        }
      }
      return;
    }

    if (this.const.orientation === 'bottom') {
      if (ball.var.pos.y + ball.const.R >= this.var.y) {
        if (ball.var.V.y >=0 ) {
          ball.var.V.y *= -1;
          ball.var.pos.y = this.var.y - ball.const.R;
        }
      }
      return;
    }

    if (this.const.orientation === 'left') {
      if (ball.var.pos.x - ball.const.R <= this.var.x) {
        if (ball.var.V.x <= 0) {
          ball.var.V.x *= -1;
          ball.var.pos.x = this.var.x + ball.const.R;
        }
        return;
      }
    }

    if (this.const.orientation === 'right') {
      if (ball.var.pos.x + ball.const.R >= this.var.x) {
        if (ball.var.V.x >= 0) {
          ball.var.V.x *= -1;
          ball.var.pos.x = this.var.x - ball.const.R;
        }
        return;
      }
    }

    if (this.const.orientation === 'piston') {
      if (ball.var.pos.x + ball.const.R >= this.var.x) {
        if (ball.var.V.x >= 0) {
          ball.var.V.x *= -1;//2*this.var.v - ball.var.V.x;
        }//{ball.var.V.x *= -1;}
        ball.var.pos.x = this.var.x - ball.const.R;
      }
      return;
    }

    ball.var.pos.add(ball.var.V);
    return;
  }

  getLine() {
    return this.line;
  }
}

export default Wall;