import React, {useEffect, useState} from "react";
import {Dropdown, Menu, Radio, Checkbox, DatePicker, ConfigProvider} from 'antd';
import '../styles/statistictablefilters.css';
import {layout2} from "../../../../utils/styles";
import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';
import {CaretDownOutlined} from "@ant-design/icons";
import moment from "moment";
import {string} from "prop-types";

const { RangePicker } = DatePicker;


export const StatisticTableFilter = (props) => {
  return (
    <div style={{marginBottom: '50px'}}>
      {
        props.configFilter.map((config) => {

          const periodFillerDate = props.stateFilter?.periodFillerDate;
          return (
            <div key={config.id} className={'statistic-filter'}>
              <p className={'statistic-filter__title'}>{config.title}:</p>

              {
                config.withPeriodDatePicker && (
                  <div style={{display: 'flex', alignItems: 'center', height: '42px', marginRight: '20px'}}>
                    <ConfigProvider locale={locale}>
                      <RangePicker
                        bordered={false}
                        value={periodFillerDate ? [moment(periodFillerDate.start), moment(periodFillerDate.end)] : undefined}
                        onChange={(value) => {
                          /**
                           * value: [startMoment, endMoment]
                           */
                          if (config?.setStateAction) {
                            config.setStateAction(prevState => ({
                              ...prevState,
                              periodFillerDate: {
                                start: value ? value[0].utc().format() : null,
                                end: value ? value[1].utc().format() : null
                              }
                            }));
                          }
                        }}
                      />
                    </ConfigProvider>
                  </div>
                )
              }

              <div>
                {
                  config.type === 'radio'
                    ?
                      <RadioGroup config={config} stateFilter={props.stateFilter}/>
                    : config.type === 'checkbox' ?
                      <CheckboxGroup config={config} stateFilter={props.stateFilter}/>
                    : config.type === 'select' ?
                      <DropdownFilterItems config={config} stateFilter={props.stateFilter}/>
                    : null
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

const setExternalState = (value, config) => {
  /**
   * set external state by key from config
   * */
  if (config?.setStateAction) {
    config.setStateAction(prevState => ({...prevState, [config.stateKey]: value}));
  }
}

export const DropdownFilterItems = ({config, stateFilter}) => {
  const defaultState = config.defaultValue ? config.defaultChecked : config.items[0]?.value;
  const [selected, setSelected] = useState(defaultState);

  useEffect(() => {
    setExternalState(defaultState, config);
    if (defaultState) {
      setSelected(defaultState);
    }
  }, [defaultState])

  const handleMenuClick = (e) => {
    setSelected(e.key);
    setExternalState(e.key, config);
  };

  const menu = (
    <Menu selectedKeys={[selected]} onClick={handleMenuClick}>
      {
        config.items.map((el) => (
          <Menu.Item key={ el.value } title={el.title}>{ el.title }</Menu.Item>
        ))
      }
    </Menu>
  );

  const correctStateValue = stateFilter ? stateFilter[config.stateKey] : selected;
  const corrItem = config.items.find((item) => item.value.toString() === (correctStateValue || '').toString());
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={'select-group'}>
        <div className={'select-btn select-btn--selected'} style={{borderColor: layout2.blue2, marginRight: '10px'}}>{corrItem?.title || ''}</div>
        <div className={'select-help-btn'} style={{padding: '0 3px', marginRight: 0}}>
          <CaretDownOutlined style={{fontSize: '20px', marginTop: '3px'}} />
        </div>
      </div>
    </Dropdown>
  )
}

export const RadioGroup = ({config, withoutInitialData, onChange, stateFilter}) => {

  const defaultState = !withoutInitialData ? config.defaultValue || config.items?.[0]?.value || '' : '';
  const [radioState, setRadioState] = useState(defaultState);

  useEffect(() => {
    setExternalState(defaultState, config);
  }, [])

  const onRadioChange = (e, sectionKey) => {
    const val = e.target.value;
    setRadioState(val);
    // onChange for external state (old version DON'T DELETED)
    if (onChange) {
      onChange(val);
    }
    setExternalState(val, config);
  }

  const correctStateValue = stateFilter ? stateFilter[config.stateKey] : radioState;
  return (
    <Radio.Group
      className={'radio-group'}
      value={correctStateValue}
      buttonStyle="solid"
      onChange={(e) => onRadioChange(e, config.id)}
    >
      {
        config.items.map((item, i) =>(
          <RadioButton
            key={item.value}
            value={item.value}
            title={item.title}
            onCLick={item.onClick}
          />
        ))
      }
    </Radio.Group>
  )
}

export const CheckboxGroup = ({config, stateFilter}) => {
  const defaultState = config.defaultValue ? config.defaultChecked : [config.items[0]?.value];
  const [checkboxState, setCheckboxState] = useState(defaultState);

  useEffect(() => {
    setExternalState(defaultState, config);
  }, [])

  const onCheckboxChange = (values, sectionKey) => {
    let correctValues = values;
    if (values.includes('all') && values.length > 1) {
      correctValues = values.filter(
        (k) => values[values.length - 1] !== 'all' ? k !== 'all' : k === 'all');
    }
    if (values.length !== 0) {
      setCheckboxState(correctValues);
      setExternalState(correctValues, config);
    }
  }

  const correctStateValue = stateFilter ? stateFilter[config.stateKey] : checkboxState;
  return (
    <Checkbox.Group
      value={correctStateValue}
      className={'checkbox-group'}
      onChange={(val) => onCheckboxChange(val, config.id)}
    >
      {
        config.items.map((item, i) =>(
          <CheckboxButton
            key={item.value}
            value={item.value}
            title={item.title}
            onCLick={item.onClick}
          />
        ))
      }
    </Checkbox.Group>
  )
}

export const RadioButton = (props) => (
  <Radio.Button className={'radio-btn'} style={{borderColor: layout2.blue2}} value={props.value}>{props.title}</Radio.Button>
)
export const CheckboxButton = (props) => (
  <Checkbox className={'checkbox-btn'} style={{borderColor: layout2.blue2}} value={props.value}>{props.title}</Checkbox>
)

