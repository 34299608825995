import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line, Arrow } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";

import weightImg from '../images/weight.png';
import movableBlockImg from '../images/movableBlock.png';
import topBlockImg from '../images/topBlock.png';
import bgImg from '../images/bg.png';
import achievementImg from '../images/achievement.png';

import ScenarioManager from "../../../../utils/ScenarioManager";
import {blocksScenario, getScenario} from "../scenario";
import { enslow } from "../../../../utils/physics";
import * as actions from "../../../../store/actions";
import {sendSuccessForScenario} from "../../../../utils/common";
import CustomButton from "../../../pages/schoolTasks/components/CustomButton";


const VisibleCircles = ({a}) => (
  <>
    {[[a,a], [-a,-a], [-a, a], [a, -a]].map((i, indx) =>
      (<Circle
        key={indx}
        radius={5}
        offsetY={i[0]}
        offsetX={i[1]}
        fill={'#F2A700'}
        stroke={'#F2A700'}
      />))
    }
  </>
);

class PulleyMovableFixedBlock extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',
      'arrow1',
      'arrow1Hint',

      'rope1Left',
      'rope2Left',
      'rope1Right',
      'block1',

      'formula1',
      'formula2',

      'force2Left',
      'force2Right',

      'achievement',

      'force1Left',
      'force1Right',
      'weight1Right',
    ];

    this.elementsUI = {
      centerFixedBLockVisible: false,
      movableBLockVisible: false,
    }
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timedeltaSec: 0,
      arrow1Dragged: false,
      arrow1Pos: {x: -75, y: 80},
      forceRight: 1000,
      massRight: 5,
      ropeSpeed: 0,
      ropeLengthTaken: 0,
    };
    this.data = cloneDeep(this.initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  _movingCallbacks = {};
  getMovingCallback = (callback) => {this._movingCallbacks = {...this._movingCallbacks, ...callback}};

  commonMove = (time) => {
    this.move(time);
    Object.keys(this._movingCallbacks).forEach((k) => this._movingCallbacks[k](time))
  }

  componentDidMount() {
    window.requestAnimationFrame(this.commonMove);
    this.scenarioManager.resetScenario();
    //
    // this.timerId = setTimeout(() => {
    //   sendSuccessForScenario(this);
    // }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };

  arrow1DragMove = (e) => {
    let data = this.data;
    const position = e.target.getPosition();
    // data.arrow1Pos.x = position.x;
    if (position.y > data.arrow1Pos.y) data.arrow1Pos.y = position.y;
  };

  calcArrow1Force = () => {
    let data = this.data;
    return (data.arrow1Pos.y - data.ropeLengthTaken) * 10 || 0.001
  };

  calcArrow1Movement = () => {
    let d = this.data;

    const force = this.calcArrow1Force() - d.forceRight;

    const acceleration = force / d.massRight;
    d.ropeSpeed += acceleration * d.timedeltaSec;

    d.ropeLengthTaken += d.ropeSpeed * d.timedeltaSec;

    if (force > 0) {
      d.ropeSpeed = enslow(d.ropeSpeed, d.ropeSpeed * 1.5 + 30, d.timedeltaSec)
    }

    if (d.ropeLengthTaken < 0) {
      d.ropeLengthTaken = 0;
      d.ropeSpeed = 0;
    }

    if (d.ropeLengthTaken > d.rope1RightPos.maxHeightLift) {
      d.ropeLengthTaken = d.rope1RightPos.maxHeightLift;
      d.ropeSpeed = 0;
    }
  };

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.commonMove);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = timedelta / 1000;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    if (!data.arrow1Dragged && data.arrow1Pos.y !== this.initialData.arrow1Pos.y) {
      data.arrow1Pos.y =  data.arrow1Pos.y - 0.2 * (data.arrow1Pos.y - this.initialData.arrow1Pos.y)
    }

    // const force = this.calcArrow1Force();
    this.calcArrow1Movement()


    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['arrow1'].x(data.arrow1Pos.x);
    n['arrow1'].y(data.arrow1Pos.y);
    n['arrow1Hint'].opacity(Number(data.ropeLengthTaken < .001));


    n['stage'].draw();

    // this would better be moved to move function
    let f2coef = 1;
    if (this.props.code === 'peryshkin10') {
      f2coef = 2;
    }


    n['force1Left'].points([0,0,0,this.calcArrow1Force()/30]);
    // n['force1Left'].visible(data.arrow1Dragged);
    n['force1Left'].y(120 + data.ropeLengthTaken);

    n['force1Right'].points([0,0,0,-this.calcArrow1Force()/30 * f2coef]);
    // n['force1Right'].visible(data.arrow1Dragged);


    n['force2Left'].points([0,0,0,-this.calcArrow1Force()/30]);
    n['force2Right'].points([0,0,0,-this.calcArrow1Force()/30]);
    // n['force2Left'].visible(data.arrow1Dragged);
    // n['force2Right'].visible(data.arrow1Dragged);


    n['rope1Left'].points([0,0,0,120 + data.ropeLengthTaken]);
    n['rope1Right'].points([0,0,0,data.rope1RightPos.lineHeight - data.ropeLengthTaken]);
    n['rope2Left'].points([0,0,0,data.rope2LeftPos ? data.rope2LeftPos.lineHeight - data.ropeLengthTaken : 0]);

    n['weight1Right'].y(data.weightPos.y - data.ropeLengthTaken);

    if ((n['weight1Right'].y() < 77) && data.step === 1) {
      this.scenarioManager.next();
    }
    if ((n['weight1Right'].y() < 157) && data.step === 3) {
      this.scenarioManager.next();
    }

    if (data.achievementVisible) {
      const scaleAch = n['achievement'].scaleX() ;
      const xAch = n['achievement'].x();
      let scale = scaleAch <= .5 ? .5 : scaleAch-0.008;
      n['achievement'].scale({x: scale, y: scale})
      n['achievement'].x(xAch <= 100 ? 100 : xAch - 3)
      if (scale <= .5) {
        window.postMessage('taskSuccess');
      }
    }

    n['block1'].rotation( - data.ropeLengthTaken / 60 / Math.PI * 180);
  }


  Canvas = () => {
    const data = this.data;
    const [weight] = useImage(weightImg);
    const [bg] = useImage(bgImg);

    const [topBlock] = useImage(topBlockImg);
    const [movableBlock] = useImage(movableBlockImg);
    const [achievement] = useImage(achievementImg);

    return (
      <React.Fragment>
        <Image image={bg} scale={{x: .53, y: .53}}/>

        <Group {...data.containerPos}>
          <Group
            x={-75}
            y={80}
            ref={this._ref('arrow1')}
            draggable={true}
            onDragMove={this.arrow1DragMove}
            onDragStart={() => this.data.arrow1Dragged = true}
            onDragEnd={() => this.data.arrow1Dragged = false}
          >
            <Rect x={-20} y={-50} width={60} height={170}  fill={'red'} opacity={0}/>
          </Group>
          <Group
            ref={this._ref('arrow1Hint')}
            x={-75}
            y={80}
          >
            <Group x={data.rope1LeftPos?.x-90} y={-20}>
              <Rect fill={'#37588D'} width={60} height={40} cornerRadius={10}/>
              <Text x={13} y={12} text={'Pull'} fontSize={20} fill={'white'} fontFamily={'Roboto-Bold'}/>
            </Group>
            <Group x={data.rope1LeftPos?.x}>

              <Arrow
                x={-10} y={-50}
                points={[0,0,0,100]}
                stroke={'#557DBD'}
                strokeWidth={8}
                fill={'#557DBD'}
              />
            </Group>
          </Group>


          <Group x={-60}>
            <Line
              {...data.rope1LeftPos}
              ref={this._ref('rope1Left')}
              points={[0,0,0,200]} stroke={'rgba(0,0,0,1)'} fill={'black'}
              strokeWidth={1}
            />
            <Line
              {...data.rope2LeftPos}
              ref={this._ref('rope2Left')}
              points={[0,0,0,200]} stroke={'rgba(0,0,0,1)'} fill={'black'}
              strokeWidth={1}
              visible={data.movableBLockVisible}
            />
            <Group {...data.rope1LeftPos} >
              <Arrow
                y={150}
                ref={this._ref('force1Left')}
                points={[0,0,0,0.1]} stroke={'black'} fill={'black'}
                visible={false}
              />
            </Group>
          </Group>

          <Line
            {...data.rope1RightPos}
            ref={this._ref('rope1Right')}
            points={[0,0,0,200]}
            stroke={'black'} fill={'black'}
            strokeWidth={1}
          />

          <Image x={-3} y={-52} image={topBlock} visible={data.movableBLockVisible} width={160} height={115}/>

          <Group {...data.weightPos} ref={this._ref('weight1Right')}>

            <Group x={8} y={-125} visible={data.movableBLockVisible}>
              {/*<Rect width={10} height={10} fill={'red'}/>*/}
              <Arrow
                x={-37} y={30}
                ref={this._ref('force2Left')}
                points={[0,0,0,0]} stroke={'black'} fill={'black'}
                visible={false}
              />
              <Arrow
                x={39} y={30}
                ref={this._ref('force2Right')}
                points={[0,0,0,0]} stroke={'black'} fill={'black'}
                visible={false}
              />
              <Line x={-8} y={40} points={[0,0,0,85]} stroke={'black'}/>
              <Image x={-40} y={0} image={movableBlock} width={65} height={65}/>
            </Group>

            <Image x={-36} y={-3} image={weight} width={60} height={120}/>
            <Arrow
              y={0}
              ref={this._ref('force1Right')}
              points={[0,0,0,-0.1]} stroke={'black'} fill={'black'}
              visible={false}
            />
          </Group>

          <Group visible={data.centerFixedBLockVisible}>
            <Group>
              <Group
                ref={this._ref('block1')}
                rotation={0}
              >
                <Circle
                  radius={60}
                  fill={'#FFD300'}
                  stroke={'#F2A700'}
                />
                <Circle
                  radius={20}
                  fill={'#F2A700'}
                  stroke={'#E17919'}
                />
                <VisibleCircles a={35}/>
              </Group>
            </Group>
          </Group>
        </Group>
        <Group>
          <Rect width={1200} height={100} fill={'white'}/>
          <Text
            x={60} y={35}
            fontSize={30}
            fontFamily={'Roboto-Bold'}
            text={"Try to lift the block using a single fixed pulley"}
            fill={'#37588D'}
          />
        </Group>

        <Image ref={this._ref('achievement')} image={achievement} x={300} y={100} width={450} height={450} visible={!!data.achievementVisible}/>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this._ref('stage')} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>

        <CustomButton
          style={{position: 'absolute', right: '40px', bottom: '20px', maxWidth: '250px', fontSize: '1.2em',
            visibility: this.data.btnVisible ? 'visible' : 'hidden'}}
          className={'continue-btn '}
          onClick={() => {
            if (this.data.btnVisible) {
              this.scenarioManager.next();
            }
          }}
          btnText={'Press to continue'}
        />
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PulleyMovableFixedBlock);

const styles = {
  mainContainer: {
    background: '#f9f0d9'
  }
};
