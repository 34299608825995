import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getTimeDeltaSec, hasIntersection} from "../../../../utils/common";
import {scenario, commonPatchData, getImgsForLsn, getScenario} from "../scenario";
import CanvasInput from "../../../canvas/components/CanvasInput";
import CanvasButton from "../../../canvas/components/CanvasButton";
import * as actions from "../../../../store/actions";
import {showFailOrSuccessPopup} from "../../../../utils/common";
import CanvasSuccessCard from "../../../canvas/components/CanvasSuccessCard";
import explosionGIF from "../../../../images/relativeMotion/task/explosion.gif";
import {GIF} from "../../../canvas/components/GIF";



class RelativeMotion extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',

      'meteor',
      'meteorRect',

      'spaceShip',
      'spaceShipRect',

      'rocket',
      'rocketRect',

      'planet',
      'planetRect',

      'meteorVectorSpeedValue',
      'spaceShipVectorSpeedValue',
      'rocketVectorSpeedValue',
      'planetVectorSpeedValue',

      'meteorVectorArrow',
      'spaceShipVectorArrow',
      'rocketVectorArrow',
      'planetVectorArrow',

      'centerRect',
      'explosionGif',

      'pathValueText',
    ]

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      errorCount: 0,

      meteorSpeed: 0,
      spaceShipSpeed: 0,
      rocketSpeed: 0,

      meteorPosX: 0,
      spaceShipPosX: 0,
      rocketPosX: 0,
      planetPosX: 0,

      shootVal: 0,

      meteorVectorSpeedValue: 0,
      spaceShipVectorSpeedValue: 0,
      rocketVectorSpeedValue: 0,
      planetVectorSpeedValue: 0,

      timer: 0,

      ...commonPatchData,
    };
    this.data = cloneDeep(this.initialData);
    const scenario = getScenario(this.props.code);
    this.scenarioManager = new ScenarioManager(scenario, this);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.scenarioManager.resetScenario();
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
    this.scenarioManager.resetScenario();
  };

  get pathValueText() {
    const data = this.data;
    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    const meteorX = n['meteor'].getAbsolutePosition()?.x;
    const shipX = n['spaceShip'].getAbsolutePosition()?.x;

    const val = (Math.abs(shipX - meteorX)-data.distanceCoeff) / 50;
    return val <= 0 ? 0 : Number(val.toFixed(1));
  }

  metersToPs = (meters) => {
    return meters / 40;
  }
  getPosXByKey(key) {
    const data = this.data;
    return data[key+'PosX'] + this.metersToPs(data[key+'VectorSpeedValue'] * data.timedeltaSec);
  }

  showFailPopup = () => showFailOrSuccessPopup(this, false);

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    let timedeltaSec = 0;

    const stageNode = this._getNode('stage');
    if (!stageNode) return;

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    data.timedeltaSec = getTimeDeltaSec(timedelta);

    if (data.start) {
      data.meteorPosX = this.getPosXByKey('meteor');
      data.spaceShipPosX = this.getPosXByKey('spaceShip');
      data.planetPosX = this.getPosXByKey('planet');

      if (data.shoot) {
        data.rocketPosX = this.getPosXByKey('rocket');
        data.timer += data.timedeltaSec;
      } else {
        if (!data.rocketVisible && !data.explosionVisible) {
          data.rocketPosX = data.spaceShipPosX;
        }
      }
      data.pathValueText = this.pathValueText;
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;

    const n = Object.fromEntries(this.managedComponents.map(key => [key, this._getNode(key)]));

    n['meteor'].setAttrs({x: data.meteorPosX, visible: data.meteorVisible});
    n['explosionGif'].setAttrs({x: data.rocketPosX});
    n['rocket'].setAttrs({x: data.rocketPosX, visible: data.rocketVisible});
    n['spaceShip'].setAttrs({x: data.spaceShipPosX, visible: data.spaceShipVisible});
    n['planet'].setAttrs({x: data.planetPosX, });
    n['centerRect'].setAttrs({x: data.planetPosX});

    n['meteorVectorSpeedValue'].text(`= ${Math.abs(data.meteorVectorSpeedValue)} м/с`);
    n['spaceShipVectorSpeedValue'].text(`= ${Math.abs(data.spaceShipVectorSpeedValue)} м/с`);
    n['rocketVectorSpeedValue'].text(`= ${Math.abs(data.rocketVectorSpeedValue)} м/с`);
    n['planetVectorSpeedValue'].text(`= ${Math.abs(data.planetVectorSpeedValue)} м/с`);

    const arrowCoeff = .2;
    n['meteorVectorArrow'].setAttrs({
      points: [0, 0, data.meteorVectorSpeedValue * arrowCoeff, 0],
      visible: Boolean(data.meteorVectorSpeedValue)
    });
    n['spaceShipVectorArrow'].setAttrs({
      points: [0, 0, data.spaceShipVectorSpeedValue * arrowCoeff, 0],
      visible: Boolean(data.spaceShipVectorSpeedValue)
    });
    n['rocketVectorArrow'].setAttrs({
      points: [0, 0, data.rocketVectorSpeedValue * arrowCoeff, 0],
      visible: Boolean(data.rocketVectorSpeedValue)
    });
    n['planetVectorArrow'].setAttrs({
      points: [0, 0, data.planetVectorSpeedValue *  arrowCoeff, 0],
      visible: Boolean(data.planetVectorSpeedValue)
    });

    n['pathValueText'].text(data.pathValueText);

    data.checkStepReset(this);

    n['stage'].draw();
  }


  Env = () => {
    const data = this.data;
    const { bgImg } = getImgsForLsn(this.props.code);
    const [bg] = useImage(bgImg);
    return (
      <Group>
        <Image image={bg} opacity={data.bgOpacity}/>
        <Text text={data.title} fill={data.titleColor} x={210} y={35} fontSize={25} lineHeight={1.3}/>

        <Group x={350} y={480} visible={data.inputCardVisible}>
          <Text x={-220} y={0} width={400} text={data.inputTextTitle} fontSize={27} fill={'white'} align={'right'}/>
          <Text x={-220} y={30} width={400} text={data.inputTextSubtitle} fontSize={13} fill={'white'} align={'right'}/>
          <CanvasInput
            id={'input'}
            x={200} y={0}
            textAlign={'center'}
            rectStyles={{stroke: 'white'}}
            background={'transparent'}
            width={150} height={50}
            stage={this._getNode('stage')}
            textColor={'white'}
            onInput={(val) => { this.data.inputVal = val }}
            value={this.data.inputVal}
            disabled={data.inputDisabled}
          />
          <Text x={370} y={8} text={'м/с'} fontSize={30} fill={'white'}/>

          <CanvasButton
            text={'Проверить'}
            onClick={() => data.onClickCheckData(this)}
            fontSize={25}
            textStroke={'transparent'}
            textFill={'black'}
            fontStyle={'normal'}
            btnFill={'#00E2DF'}
            width={150}
            height={50}
            x={450} y={0}
            visible={data.checkBtnVisible}
          />
        </Group>
        <Group>
          <CanvasButton
            text={'Понятно'}
            onClick={() => data.onClickOkBtnData(this)}
            fontSize={25}
            textStroke={'transparent'}
            textFill={'black'}
            fontStyle={'normal'}
            btnFill={'#00E2DF'}
            width={150}
            height={50}
            x={800} y={480}
            visible={data.okBtnVisible}
          />

          <CanvasButton
            text={data.changePointOfViewBtnText}
            onClick={() => data.onClickChangePointOfViewBtnData(this)}
            fontSize={28}
            textStroke={'transparent'}
            textFill={'black'}
            fontStyle={'normal'}
            btnFill={'#00E2DF'}
            width={600}
            height={50}
            x={210} y={135}
            visible={data.changePointOfViewBtnVisible}
          />
        </Group>

        <Group x={250} y={480} visible={data.pathInfoVisible}>
          <Text y={25} text={'Расстояние до астероида:'} fontSize={25} fill={'white'}/>
          <Text x={300} width={150} align={'center'} text={data.pathValueText} fontSize={60} fill={'white'} ref={this._ref('pathValueText')} />
          <Text x={450} y={25} text={'км'} fontSize={25} fill={'white'}/>
        </Group>
        <CanvasButton
          text={'Начать'}
          onClick={() => this.scenarioManager.next()}
          fontSize={28}
          textStroke={'transparent'}
          textFill={'white'}
          fontStyle={'normal'}
          btnFill={'#ED8011'}
          width={150} height={50}
          x={800} y={480}
          visible={data.startBtnVisible}
        />
        <CanvasButton
          text={'ВЫСТРЕЛ'}
          onClick={() => {
            this.data.shootVal = this.data.pathValueText;
            this.scenarioManager.next()
          }}
          fontSize={25}
          textStroke={'transparent'}
          textFill={'white'}
          fontStyle={'normal'}
          btnFill={'#BC4842'}
          width={150} height={50}
          x={800} y={480}
          visible={data.shootBtnVisible}
          disabled={data.shoot}
        />

        <Group x={500-1}>
          <Rect height={1000} width={3} fill={'red'} opacity={0} ref={this._ref('centerRect')}/>
        </Group>
      </Group>
    )
  }
  Objects = () => {
    const data = this.data;

    const { meteorImg, spaceShipImg, rocketImg, planetImg } = getImgsForLsn(this.props.code);
    const [meteor] = useImage(meteorImg);
    const [spaceShip] = useImage(spaceShipImg);
    const [rocket] = useImage(rocketImg);
    const [planet] = useImage(planetImg);

    const vectorVStg = {text: 'V', fill: 'white', fontSize: 40, fontStyle: 'italic'};
    const vectorTxtStg = {fill: 'white', fontSize: 11};
    return (
      <Group>
        <Group ref={this._ref('planet')}>
          <Group>
            <Image {...data.planetImgPos} image={planet}/>
            <Arrow
              {...data.planetArrowPos}
              visible={data.planetVectorVisible}
              points={[0,0,-50,0]} fill={'white'} stroke={'white'} ref={this._ref('planetVectorArrow')}/>
            <Rect ref={this._ref('planetRect')} width={data.planetWidth || 180} height={1000} fill={'lightBlue'} opacity={0}/>
          </Group>
          <Group x={100} y={210} visible={data.planetVectorVisible}>
            <Text {...vectorVStg}/>
            <Text x={23} y={25} text={data.planetVectorText} {...vectorTxtStg}/>
            <Text x={90} y={8} text={'= 0 м/с'} fill={'white'} fontSize={25} ref={this._ref('planetVectorSpeedValue')}/>
          </Group>
        </Group>

        <Group x={220} y={250}>
          <Group x={data.meteorContainerPosX}>
            <Group ref={this._ref('meteor')} >
              <Group>
                <Image image={meteor} scaleX={data.meteorScaleX}/>
                <Rect ref={this._ref('meteorRect')} width={data.meteorRectWidth} height={80} fill={'lightBlue'} opacity={0}/>
                <Arrow x={data.meteorScaleX === 1 ? data.meteorArrowPos?.x : -data.meteorArrowPos?.x}
                       y={data.meteorArrowPos?.y} points={[0,0,-50,0]} fill={'white'} stroke={'white'}
                       ref={this._ref('meteorVectorArrow')}
                />
              </Group>
              <Group x={-70} y={90} visible={data.meteorVectorVisible}>
                <Text {...vectorVStg}/>
                <Text x={23} y={25} text={data.meteorVectorText} {...vectorTxtStg}/>
                <Text x={90} y={8} text={'= 0 м/с'} fill={'white'} fontSize={25} ref={this._ref('meteorVectorSpeedValue')}/>
              </Group>
            </Group>
          </Group>

          <Group x={data.rocketContainerPosX}>
            <Group ref={this._ref('rocket')} y={35} >
              <Group>
                <Rect x={data.rocketScaleX === 1 ? 0 : -25} ref={this._ref('rocketRect')} width={25} height={10} fill={'lightBlue'} opacity={0}/>
                <Arrow x={data.rocketScaleX === 1 ? data.rocketArrowPos?.x : -data.rocketArrowPos?.x}  y={data.rocketArrowPos?.y} points={[0,0,20,0]} fill={'white'} stroke={'white'} ref={this._ref('rocketVectorArrow')}/>
                <Image image={rocket} scaleX={data.rocketScaleX} y={1}/>
              </Group>
              <Group x={data.rocketScaleX === 1 ? 0 : -100} y={data.rocketScaleX === 1 ? -60 : -80} visible={data.rocketVectorVisible}>
                <Text {...vectorVStg}/>
                <Text x={23} y={25} text={data.rocketVectorText} {...vectorTxtStg}/>
                <Text x={90} y={8} text={'= 0 м/с'} fill={'white'} fontSize={25} ref={this._ref('rocketVectorSpeedValue')}/>
              </Group>
            </Group>
            <Group offsetX={data.rocketScaleX === 1 ? 0 : 50} ref={this._ref('explosionGif')}>
              {data.explosionVisible && (
                <GIF x={-60} y={-80} src={explosionGIF} scale={.6}/>
              )}
            </Group>
          </Group>

          <Group x={data.spaceShipContainerPosX}>
            <Group ref={this._ref('spaceShip')} y={15} >
              <Group>
                <Image y={2} image={spaceShip} scaleX={data.spaceShipScaleX}/>
                <Rect x={data.spaceShipScaleX === 1 ? 0 : -73} ref={this._ref('spaceShipRect')} width={73} height={50} fill={'lightBlue'} opacity={0}/>
                <Arrow x={data.spaceShipScaleX === 1 ? data.spaceShipArrowPos?.x : -data.spaceShipArrowPos?.x} y={data.spaceShipArrowPos?.y}
                       points={[0,0,0,0]} fill={'white'} stroke={'white'}
                       ref={this._ref('spaceShipVectorArrow')}
                />
              </Group>
              <Group x={data.spaceShipScaleX === 1 ? 0 : -70} y={60} visible={data.spaceShipVectorVisible}>
                <Text {...vectorVStg}/>
                <Text x={23} y={25} text={data.spaceShipVectorText} {...vectorTxtStg}/>
                <Text x={90} y={8} text={'= 0 м/с'} fill={'white'} fontSize={25} ref={this._ref('spaceShipVectorSpeedValue')}/>
              </Group>
            </Group>
          </Group>
        </Group>
      </Group>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer
          stageRef={this._ref('stage')}
          lessonCode={this.props.code}

          component={this}
          withVideo={true}
          videoVisible={this.data.videoSrc}
          theoryVisible={this.data.theoryVisible}
          additionalTheoryStgs={{
            skipInitialStep: true,
            hideResetBtn: true,
            bgColor: 'white',
          }}
        >
          <Layer preventDefault={false}>
            <this.Env/>
            <this.Objects/>

            {
              this.data.successStepPopupVisible && (
                <CanvasSuccessCard success={true}>
                  <CanvasButton
                    text={'Далее'}
                    onClick={() => this.scenarioManager.next()}
                    fontSize={20}
                    strokeWidth={.1}
                    btnCornerRadius={0}
                    btnFill={'#00E2DF'}
                    textFill={'#333333'}
                    width={250}
                    height={45}
                  />
                  <CanvasButton
                    text={'Посмотреть теорию'}
                    onClick={() => {this.scenarioManager.selectStepByKey('theory')}}
                    fontSize={20}
                    strokeWidth={.1}
                    btnCornerRadius={0}
                    btnFill={'#00E2DF'}
                    textFill={'#333333'}
                    width={250}
                    height={45}
                    y={70}
                  />
                </CanvasSuccessCard>
              )
            }
            {
              this.data.failureStepPopupVisible && (
                <CanvasSuccessCard
                  success={false}
                  title={'Повтори расчет'}
                  goToTheory={() => {this.scenarioManager.selectStepByKey('theory')}}
                  onClickReset={() => this.scenarioManager.selectStepByKey(this.data.failureInitialStep)}
                  btnTopBg={'#00E2DF'}
                  btnTopTxtColor={'#333333'}
                  btnBottomBg={'#00E2DF'}
                  btnBottomTxtColor={'#333333'}
                />
              )
            }
            {
              this.data.finalPopupVisible && (
                <CanvasSuccessCard
                  success={true}
                  goToTheory={() => {this.scenarioManager.selectStepByKey('theory')}}
                  onClickReset={() => this.onClickReset()}
                  btnTopBg={'#00E2DF'}
                  btnTopTxtColor={'#333333'}
                  btnBottomBg={'#00E2DF'}
                  btnBottomTxtColor={'#333333'}
                />
              )
            }
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelativeMotion);

const styles = {
  mainContainer: {
    background: '#333333'
  }
};
