import React, {useEffect} from "react";
import './styles.css';
import {typeset} from "../../../utils/mathJax";


const HtmlContentRender = ({htmlForRender}) => {
  useEffect(() => {
    typeset();
  }, [htmlForRender])
  return (
    <div className={'HtmlContentRender'} dangerouslySetInnerHTML={{__html: htmlForRender}} />
  )
}

export default HtmlContentRender;
