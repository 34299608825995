import {
  CHANGE_ACTIVE_PRESENTATION,
  CHANGE_CREATE_LESSON_SPINNER_VISIBLE,
  CHANGE_LANGUAGE,
  CHANGE_LESSON_SESSION,
  CHANGE_PRESENTATION_IMG,
  CHANGE_PRESENTATION_VISIBLE_TO_STUDENTS,
  LOGGED_USER,
  RESET_LESSON_SESSION,
  RESET_TO_INIT,
  SET_ACTIVE_LESSON,
  SET_ACTIVE_LESSON_VARIANT,
  SET_CHAPTERS,
  SET_HOMEWORK_NOTIFICATIONS,
  SET_HOMEWORKS,
  SET_LESSONS,
  SET_LESSONS_ACCESS,
  SET_LESSONS_GROUPS,
  SET_PRESENTATION,
  SET_PRESENTATION_DATA,
  SET_SELECTED_HOMEWORK_ID,
  SET_TEACHER_PROFILE,
  SET_USER,
  SET_VIDEOS,
  UPDATE_ACTIVE_LESSON,
  SET_ADD_NEW_HOMEWORK_FLAG
} from "../actions";

const initialPresentation = {
  activePresentationId: 0,
  allPresentations: [],

  activeImgId: 0,
  imgs: [],

  visibleToStudents: false
};

const initialLessonSession = {
  token: '',
  link: '',
  zoomLink: '',
  startLessonDatetime: '',
  selectedLessons: {},
  students: [],
  studentsLessonsAccess: [],
};

const initialState = {
  isLogged: undefined,

  videos: [],
  presentation: {...initialPresentation},
  lessonSession: {...initialLessonSession},
  lang: 'ru',

  lessonsGroups: [],
  chapters: [],
  lessons: [],
  lessonsAccess: [],

  activeLesson: null,
  activeLessonVariant: undefined,

  // HOMEWORK
  selectedHomeworkId: undefined,
  homeworks: [],
  homeworkNotifications: [],
  addNewHomeworkFlag: false,

  user: null,
  teacherProfile: {},
  textProblems: [],
  // SPINNERS
  createLessonSpinnerVisible: false,

};

export const storeLessonsReducer = (state=initialState, {type, payload}) => {

  switch (type) {

    // Badge marker for homework link on header
    case SET_ADD_NEW_HOMEWORK_FLAG:
      return {
        ...state,
        addNewHomeworkFlag: payload
      };
    case SET_ACTIVE_LESSON_VARIANT:
      return {
        ...state,
        activeLessonVariant: payload
      };

    case CHANGE_CREATE_LESSON_SPINNER_VISIBLE:
      return {
        ...state,
        createLessonSpinnerVisible: payload
      };

    case CHANGE_LANGUAGE:
      return {
        ...state,
        lang: payload
      };

    case SET_USER:
      return {
        ...state,
        user: payload
      };
    case LOGGED_USER:
      return {
        ...state,
        isLogged: payload.status
      };

    case SET_TEACHER_PROFILE:
      return {
        ...state,
        teacherProfile: payload
      };

    case SET_LESSONS:
      return {
        ...state,
        lessons: payload
      };

    case SET_ACTIVE_LESSON:
      return {
        ...state,
        activeLesson: payload
      };

    case UPDATE_ACTIVE_LESSON:
      return {
        ...state,
        activeLesson: {
          ...state.activeLesson,
          ...payload
        }
      };

    case SET_LESSONS_GROUPS:
      return {
        ...state,
        lessonsGroups: payload
      };

    case SET_CHAPTERS:
      return {
        ...state,
        chapters: payload
      };


    case SET_LESSONS_ACCESS:
      return {
        ...state,
        lessonsAccess: payload
      };

    case SET_VIDEOS:
      return {
        ...state,
        videos: payload
      };


    //  --------- PRESENTATION ------------
    case CHANGE_PRESENTATION_IMG:
      return {
        ...state,
        presentation: {
          ...state.presentation,
          activeImgId: payload
        }
      };
    case SET_PRESENTATION_DATA:
      return {
        ...state,
        presentation: {
          ...state.presentation,
          imgs: payload.imgs,
          allPresentations: payload.allPresentations
        }
      };
    case SET_PRESENTATION:
      return {
        ...state,
        presentation: {
          ...state.presentation,
          ...payload
        }
      };
    case CHANGE_PRESENTATION_VISIBLE_TO_STUDENTS:
      return {
        ...state,
        presentation: {
          ...state.presentation,
          visibleToStudents: payload
        }
      };
    case CHANGE_ACTIVE_PRESENTATION:
      return {
        ...state,
        presentation: {
          ...state.presentation,
          activePresentationId: payload
        }
      };



    case RESET_TO_INIT:
      return {...initialState};


    case CHANGE_LESSON_SESSION:
      return {
        ...state,
        lessonSession: {
          ...state.lessonSession,
          ...payload
        }
      };
    case RESET_LESSON_SESSION:
      return {
        ...state,
        lessonSession: {...initialLessonSession}
      };




      // ---------------------- HOMEWORKS -----------------------
    case SET_SELECTED_HOMEWORK_ID:
      return {
        ...state,
        selectedHomeworkId: payload
      };
    case SET_HOMEWORKS:
      return {
        ...state,
        homeworks: payload.homeworks
      };
    case SET_HOMEWORK_NOTIFICATIONS:
      return {
        ...state,
        homeworkNotifications: payload
      };




    default:
      return state;
  }
};
