import aluminumImg from '../../../../images/scaleForCubes/aluminum.png';
import brickImg from '../../../../images/scaleForCubes/brick.png';
import corkImg from '../../../../images/scaleForCubes/cork.png';
import goldImg from '../../../../images/scaleForCubes/gold.png';
import iceImg from '../../../../images/scaleForCubes/ice.png';
import oakImg from '../../../../images/scaleForCubes/oak.png';
import silverImg from '../../../../images/scaleForCubes/silver.png';
import steelImg from '../../../../images/scaleForCubes/steel.png';
import brick2Img from '../../../../images/scaleForCubes/differentVolume/brick.png';
import gold2Img from '../../../../images/scaleForCubes/differentVolume/gold.png';
import oak2Img from '../../../../images/scaleForCubes/differentVolume/oak.png';
import silver2Img from '../../../../images/scaleForCubes/differentVolume/silver.png';

import React from "react";
import {logger} from "redux-logger/src";


export const getCorrectScenario = (code) => {
  if (code === 'lever9') {
    return scenarioEqualsVolume;
  }
  if (code === 'lever10') {
    return scenarioDifferentVolume;
  }
}

export const defaultPatchData = {
  title: '',

  draggable: false,

  endPopUpVisible: false,
  goToTheoryVisible: false,
}
const weightItemsForEqualsVolume = {
  'aluminum': {id: 'aluminum', weight: 2700, img: aluminumImg, successIndx: 4},
  'brick': {id: 'brick', weight: 1800, img: brickImg, successIndx: 3},
  'cork': {id: 'cork', weight: 240, img: corkImg, successIndx: 0},
  'gold': {id: 'gold', weight: 19300, img: goldImg, successIndx: 7},
  'ice': {id: 'ice', weight: 900, img: iceImg, successIndx: 2},
  'oak': {id: 'oak', weight: 700, img: oakImg, successIndx: 1},
  'silver': {id: 'silver', weight: 10500, img: silverImg, successIndx: 6},
  'steel': {id: 'steel', weight: 7800, img: steelImg, successIndx: 5}
};

export const scenarioEqualsVolume = [
  {
    key: 'step1',
    patchData: {
      ...defaultPatchData,

      draggable: true,

      title: 'Расставь кубики равного объема в порядке \nувеличения плотности',
      weightItems: weightItemsForEqualsVolume,
      defaultWeightPlaceStack: Object.keys(weightItemsForEqualsVolume),

      weightCardWidth: 620,
      weightCardHeight: 90,
      weightCardX: 180,
      weightCardY: 450,

      draggingKey: '',
      checkCorrect: (comp) => {
        const defaultWeightPlaceStack = comp.data.defaultWeightPlaceStack;
        const weightItemsLen = Object.keys(weightItemsForEqualsVolume).length;
        const allItemsAdded = defaultWeightPlaceStack.length === weightItemsLen;
        const correctlyPlaced = defaultWeightPlaceStack.every((weightKey, i) => {
          return weightItemsForEqualsVolume[weightKey].successIndx === i;
        });

        if (allItemsAdded && correctlyPlaced) {
          comp.scenarioManager.success('step1');
        }
      }
    },
    next_success: 'success',
  },
  {
    key: 'success',
    patchData: {
      ...defaultPatchData,

      weightItems: weightItemsForEqualsVolume,

      weightCardWidth: 620,
      weightCardHeight: 90,
      weightCardX: 180,
      weightCardY: 450,

      endPopUpVisible: true,
    }
  }
]


const weightItemsForEqualsMass = {
  'brick': {id: 'brick',
    ImgSize: 43, imgPosX: 5, imgPosY: 20, weight: 100, density: 1800, img: brick2Img, successIndx: 1},
  'gold': {id: 'gold',
    ImgSize: 23, imgPosX: 15, imgPosY: 40, weight: 100, density: 19300, img: gold2Img, successIndx: 3},
  'oak': {id: 'oak',
    ImgSize: 63, imgPosX: 0, imgPosY: 0, weight: 100, density: 700, img: oak2Img, successIndx: 0},
  'silver': {id: 'silver',
    ImgSize: 33, imgPosX: 10, imgPosY: 30, weight: 100, density: 10500, img: silver2Img, successIndx: 2},
};

export const scenarioDifferentVolume = [
  {
    key: 'step1',
    patchData: {
      ...defaultPatchData,

      title: 'Расставь кубики в порядке увеличения плотности',

      draggable: true,

      weightItems: weightItemsForEqualsMass,
      defaultWeightPlaceStack: Object.keys(weightItemsForEqualsMass),

      weightCardWidth: 340,
      weightCardHeight: 90,
      weightCardX: 300,
      weightCardY: 450,

      draggingKey: '',
      checkCorrect: (comp) => {
        const defaultWeightPlaceStack = comp.data.defaultWeightPlaceStack;
        const weightItemsLen = Object.keys(weightItemsForEqualsMass).length;
        const allItemsAdded = defaultWeightPlaceStack.length === weightItemsLen;
        const correctlyPlaced = defaultWeightPlaceStack.every((weightKey, i) => {
          return weightItemsForEqualsMass[weightKey].successIndx === i;
        });

        if (allItemsAdded && correctlyPlaced) {
          comp.scenarioManager.success('step1');
        }
      }
    },
    next_success: 'success',
  },
  {
    key: 'success',
    patchData: {
      ...defaultPatchData,

      weightItems: weightItemsForEqualsMass,


      weightCardWidth: 340,
      weightCardHeight: 90,
      weightCardX: 300,
      weightCardY: 450,

      endPopUpVisible: true,
    }
  }
]
