import CanvasButton from "./CanvasButton";
import React from "react";
import {layout2} from "../../../utils/styles";
import {Image, Rect} from "react-konva";
import resetImg from '../img/reset.png';
import resetImgEn from '../img/resetEn.jpg';
import useImage from "use-image";
import {_t, getCorrectLang} from '../../../utils/lang/common';


const CanvasResetBtn = React.forwardRef((props, ref) => {
  const {
    x=850,
    y=500,
    width=110,
    height=30,
    fontSize=15,
    onClick,
    visible,
  } = props;

  const lang = getCorrectLang();
  let resetBtnImg = resetImg;
  if (lang === 'en') {
    resetBtnImg = resetImgEn;
  }

  const [reset] = useImage(resetBtnImg);
  return (
    <CanvasButton
      btnRef={ref}
      visible={visible}
      onClick={() => onClick()}
      fontSize={fontSize}
      strokeWidth={.2}
      btnCornerRadius={0}
      btnFill={layout2.orange}
      width={width}
      textWidth={width-10}
      height={height}
      align={'right'}
      x={x}
      y={y}
    >
      <Rect
        width={width} height={height}
        shadowBlur={10}
        shadowColor={'rgba(0,0,0,0.5)'}
        shadowOffset={{x: 2, y:2}}
        fill={layout2.orange}
      />
      <Image width={width} height={height} image={reset}/>
    </CanvasButton>
  )
})

export default CanvasResetBtn;
