import {GIF} from "./GIF";
import React from "react";
import checkmarkGif from "../../../images/Checkmark.gif";
import useImage from "use-image";
import successArrowImg from "../../../images/successArrow2.png";
import {Group, Image} from "react-konva";


const CheckMarkGif = (props) => {
  const [successArrow] = useImage(successArrowImg);
  return (
    <Group x={props.x} y={props.y} visible={props.visible} scale={props.scale}>
      {
        props.withImageBg && (
          <Image
            x={2.5} y={2.5}
            image={successArrow}
            width={55}
            height={55}
          />
        )
      }
      <GIF
        width={60} height={60}
        src={checkmarkGif}
        loop={props.loop}
      />
    </Group>
  )
}

export default CheckMarkGif;