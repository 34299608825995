import React, {useEffect} from "react";
import '../styles.css';
import {typeset} from "../../../utils/mathJax";


const TextProblemHtmlContent = ({htmlForRender}) => {
  useEffect(() => {
    typeset();
  }, [htmlForRender])
  return (
    <div className={'text-problem-task__content'} dangerouslySetInnerHTML={{__html: htmlForRender}} />
  )
}

export default TextProblemHtmlContent;
