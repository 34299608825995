import { Image } from "react-konva";
import React from "react";
import {pxPerMetre} from "../settings";

export const CanvasAsteroid = ({asteroidImage, rotation, scale}) => {
  const width = 50 / 17.5; // In metres
  const height = 110 / 17.5;

  return (
    <Image
      image={asteroidImage}
      width={width * pxPerMetre * scale}
      height={height * pxPerMetre * scale}
      offsetX={width * pxPerMetre / 2 * scale}
      offsetY={height * pxPerMetre / 2 * scale}
      rotation={rotation}
    />
  )
};
