import {loginAction} from "../../../../store/actions";
import {hasIntersection} from "../../../../utils/common";


export const dragBoundFunc = (pos) => {
  /**
   *  Function to limit movement
   */
  const leftBorderX = 635;
  const rightBorderX = 840;
  const topBorderY = 50;
  const bottomBorderY = 480;
  const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
  const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
  return {y: newY, x: newX};
};

const getDropPlaceArr = (code) => {
  const nodesDropArr = [
    'dropEngineStroke1Node',
    'dropEngineStroke2Node',
    'dropEngineStroke3Node',
    'dropEngineStroke4Node',
  ];

  const nodesSparkPlugDropArr = [
    'dropEngineForSparkPlug1Node',
    'dropEngineForSparkPlug2Node',
    'dropEngineForSparkPlug3Node',
    'dropEngineForSparkPlug4Node',
  ];
  return code === 'SparkPlug' ? nodesSparkPlugDropArr : nodesDropArr;
}

// Check if the answer block fits into the drop place
const checkAnswerIntoDropPlace = (currNode, dropNode, $this) => {
  const currNodePos = currNode.getAbsolutePosition();
  const currNodeCenterY = currNodePos.y + currNode.height()/2;
  const currNodeCenterX = currNodePos.x + currNode.width()/2;

  const dropNodePos = dropNode.getAbsolutePosition();
  const dropNodeTop = dropNodePos.y;
  const dropNodeBottom = dropNodePos.y + dropNode.height();
  const dropNodeRight = dropNodePos.x + dropNode.width();

  return (currNodeCenterY >= dropNodeTop) && (currNodeCenterY < dropNodeBottom) && (currNodeCenterX <= dropNodeRight)
};

const setCurrentDropRectFill = (currNode, nodes, code, $this) => {
  const correctArr = getDropPlaceArr(code);
  correctArr.map((dropNodeName, i) => {
    const dropNode = nodes[dropNodeName];
    const isPlaceCorrect = checkAnswerIntoDropPlace(currNode, dropNode, $this);
    dropNode.attrs.fill = isPlaceCorrect ? 'rgba(0,200,0,0.1)' : 'transparent';
  })
};

const resetDropRectFill = (currNode, nodes, code) => {
  const correctArr = getDropPlaceArr(code);
  correctArr.map((dropNodeName, i) => {
    nodes[dropNodeName].attrs.fill = 'transparent';
  })
};

export const onDragMove = (e, code, nodesCallback, $this) => {
  const nodes = nodesCallback();
  const currNode = e.target;
  setCurrentDropRectFill(currNode, nodes, code, $this)
  $this.checkStepForMove();
};


export const onDragStart = (e, code, data, nodesCallback) => {
  const nodes = nodesCallback();
  const currNode = e.target;
  const isWasteGas = code === 'WasteGas';
  const isSparkPlug = code === 'SparkPlug';
  const isFuel = code === 'Fuel';


};

export const onDragEnd = (e, code, data, nodesCallback, $this) => {
  data.uiElements.startHint = false;
  const nodes = nodesCallback();
  const currNode = e.target;
  resetDropRectFill(currNode, nodes, code);

  let correctEngineStroke = 0;
  const correctArr = getDropPlaceArr(code);
  correctArr.map((dropNodeName, i) => {
    const dropNode = nodes[dropNodeName];
    const isPlaceCorrect = checkAnswerIntoDropPlace(currNode, dropNode, $this);
    if (isPlaceCorrect) {
      correctEngineStroke = i + 1;
      currNode.setAbsolutePosition(dropNode.getAbsolutePosition());
      currNode.rotation(0);
    }
  });
  data[`answer${code}`] = correctEngineStroke;

  // go to correct step
  if (data['answerFuel'] && data['answerSparkPlug'] && data['answerWasteGas'] && !data.hintForButtonsViewed) {
    $this.scenarioManager.selectStepByKey('step3')
  }
};
