import React from "react";
import {layout2} from "../../../../utils/styles";
import {Badge} from "antd";

const sortFn = (a, b, key) => {
  if(a[key] < b[key]) { return -1; }
  if(a[key] > b[key]) { return 1; }
  return 0;
}

export const hwColumnsConverter = (lessons, stateFilter, isMobileMode) => {
  let res = [];
  let student = {
    title: '',
    dataIndex: 'rowName',
    key: 'student',

    width: '100px',
  };
  if (!isMobileMode) {
    student = {
      ...student,
      fixed: 'left',
    }
  }

  if (stateFilter.rowsFiller === "by_students") {
    student = {
      ...student,
      sorter: (a, b) => sortFn(a, b, 'rowText'),
      showSorterTooltip: false,
    }
  }
  res.push(student)

  res.push({
    title: 'Average',
    dataIndex: 'average',
    key: 'average',
    width: '50px',
    render: (el) => el ? `${Number(el.toFixed(1))} %` : '0 %',
    sorter: (a, b) => sortFn(a, b, 'average'),
    showSorterTooltip: false,
    // sortOrder: 'descend',
  });
  lessons.forEach((lsn, i) => {
    res.push({
      title: lsn.title,
      dataIndex: `lesson-${lsn.id}`,
      key: `lesson-${lsn.id}`,
      width: '100px',
      sorter: (a, b) => {
        const key = `lesson-${lsn.id}`;
        const valA = parseFloat(a[key].replace('-', -1))
        const valB = parseFloat(b[key].replace('-', -1))
        if(valA < valB) { return -1; }
        if(valA > valB) { return 1; }
        return 0;
      },
      showSorterTooltip: false,
    });
  });
  return res;
}
export const hwRowsConverter = (tableDataRows) => {

  return tableDataRows?.sort((a, b) => b.average - a.average)?.map(rowData => {
    const rowHtml = (
      <div key={rowData.key} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <span style={{color: layout2.blue2}}>{`${rowData.rowName}`}</span>
        {
          rowData.notice ? (
            <Badge color={'#FF9737'} className={'badge-custom-size'}/>
          ) : null
        }
      </div>
    )
    return {
      ...rowData,
      rowText: rowData.rowName,
      rowName: rowHtml
    }
  })
}
