import React, {useEffect} from "react";
import './styles.css';
import {typeset} from "../../../utils/mathJax";


const StringHtmlRender = ({htmlForRender, style, className}) => {
  useEffect(() => {
    typeset();
  }, [htmlForRender])
  return (
    <div className={`html-render__content ${className}`} style={style} dangerouslySetInnerHTML={{__html: htmlForRender}} />
  )
}

export default StringHtmlRender;
