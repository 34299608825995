import {layout2} from "../../../../utils/styles";
import React from "react";


export const EmptyComp = (props) => {
  const style = {color: layout2.darkBlue, top: props.top };
  return (
    <div className={'emptyCompWrapp'}>
      <div className={'emptyComp'} style={{height: props.height, maxWidth: props.maxWidth}}>
        { props.icon({className:'emptyIcon', style}) }
        <h1 style={{color: layout2.darkBlue}}>{ props.text }</h1>
      </div>
    </div>
  )
}
