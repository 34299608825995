import React, {useEffect} from "react";
import '../styles/cat-map.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import CatMapElement from "../components/CatMapElement";
import MapContainer from "./MapContainer";
import {maps} from "../utils";


const CatMap = (props) => {

  const Content = ({tasksItems, isOpen, taskModalVisible, currentLessonCode, successScreenVisible, setTaskModalVisible}) => {

    useEffect(() => {
      // Initial redirect if already complete task
      const taskForRedirect = tasksItems.find(task => task.paymentRedirect);
      if (taskForRedirect?.complete && taskForRedirect?.paymentRedirect && !props.coursePaid && !isOpen) {
          window.location.href = taskForRedirect.paymentRedirect;
      }
    }, [tasksItems.length])

    useEffect(() => {
      if (!successScreenVisible) {
        const currentTask = tasksItems.find(task => task.lessonCode === currentLessonCode);
        if (currentTask?.complete && currentTask?.paymentRedirect && !props.coursePaid && !isOpen) {
          window.location.href = currentTask.paymentRedirect;
        }
      }
    }, [tasksItems, successScreenVisible]);

    return (
      <div className={'cat-map-background'}>
        {
          tasksItems.map((item, i) => {
            const prevItem = tasksItems.find(el => el.lessonCode === item.prevKey);
            return (
              <CatMapElement
                isOpen={isOpen}
                prevItem={prevItem}
                setTaskModalVisible={setTaskModalVisible} coursePaid={props.coursePaid} key={item.lessonCode} {...item}
              />
            )
          })
        }
      </div>
    )
  }
  return (
    <MapContainer {...props}>
      <Content />
    </MapContainer>
  )
}

const mapStateToProps = state => {
  return ({
    coursePaid: state.mapReducer.coursePaid,
  });
}
const mapDispatchToProps = (dispatch, {match}) => ({
  getOrCreateMapProgress: (initial) => dispatch(actions.getOrCreateMapProgress(initial, maps.catMap.key)),
  courseMapAuth: () => dispatch(actions.courseMapAuth())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatMap);
