import {hasIntersection, showFailOrSuccessPopup} from "../../../../utils/common";


export const getBoolCode = (code) => ({
  peryshkin5: code === 'peryshkin5',
  peryshkin6: code === 'peryshkin6',
  peryshkin15: code === 'peryshkin15',
  peryshkin22: code === 'peryshkin22',
  peryshkin23: code === 'peryshkin23',
  peryshkin24: code === 'peryshkin24',
  peryshkin25: code === 'peryshkin25',
  blocksRopeLen1: code === 'blocks_rope_len1',
  blocksRopeLen1en: code === 'blocks_rope_len1en',
})

