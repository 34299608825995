import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import '../styles/logicWheel2.css';

import dropWheelImg from "../images/matchItems/bicycle_3_wheel.png";
import dropBicycleImg from "../images/matchItems/bicycle_3_bicycle.png";
import dropPedalImg from "../images/matchItems/bicycle_3_pedal.png";

import dragArrow1Img from "../images/matchItems/bicycle_3_arrow1.png";
import dragArrow2Img from "../images/matchItems/bicycle_3_arrow2.png";
import dragArrow3Img from "../images/matchItems/bicycle_3_arrow3.png";

import completeWheelImg from "../images/matchItems/bicycle_3_complete_wheel.png";
import completeBicycleImg from "../images/matchItems/bicycle_3_complete_bicycle.png";
import completePedalImg from "../images/matchItems/bicycle_3_complete_pedal.png";

import bgNature from '../images/bgNature.png';
import MatchItems from "./MatchItems";


const Bicycle3 = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const chooseTasks = [
    {
      title: 'Match the items',
      dropItems: [
        {id: 1, successId: 3, completeImg: completeBicycleImg, img: dropBicycleImg},
        {id: 2, successId: 1, completeImg: completePedalImg, img: dropPedalImg},
        {id: 3, successId: 2, completeImg: completeWheelImg, img: dropWheelImg},
      ],
      dragItems: [
        {id: 1, img: dragArrow2Img},
        {id: 2, img: dragArrow3Img},
        {id: 3, img: dragArrow1Img},
      ]
    }
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={chooseTasks.length}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <div className={'logic-wheel-2'}>
        {
          chooseTasks.map((item, i) => (
            <React.Fragment key={`step${i}`}>
              {
                ((completedTaskCount === (i)) || ((i + 1) === chooseTasks.length && completedTaskCount >= chooseTasks.length)) ? (
                  <MatchItems
                    lang={'en'}
                    bgImg={bgNature}
                    title={item.title}
                    dropItems={item.dropItems}
                    dragItems={item.dragItems}
                    taskComplete={() => {
                      setCompletedTaskCount(prevState => prevState + 1);
                      if ((i + 1) === chooseTasks.length) {
                        setAllComplete(true);
                      }
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </TaskMainContainer>
  )
}
export default Bicycle3;
