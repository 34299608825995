import React from "react";
import {_t} from "../../../../utils/lang/common";
import {Input} from 'antd';
import useMedia from "../../../../utils/custom-hooks/useMedia";


export const FieldBlock = (props) => {
  const {
    drag,
    label,
    name,
    value,
    step,
    min,
    max,
    onChangeInput,
  } = props;

  const isMDView = useMedia(['(max-width: 768px)'],[true], false);
  const styles = getStyles(isMDView);
  return (
    <div style={styles.fieldsContainer}>
      <label style={styles.fieldsContainerLabel}>
        <span>{label}:</span>
        <Input
          type="number"
          step={step}
          name={name}
          onChange={ onChangeInput }
          value={value}
          disabled={drag}
          style={styles.fieldsContainerInput}
          min={min}
          max={max}
        />
      </label>
      {props.children}
    </div>
  )
};

const getStyles = (isMDView) => ({
  fieldsContainer: {
    padding: '10px',
    width: isMDView ? '170px' : '260px',
    position: 'absolute',
    zIndex: 100,
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '0 0 10px 10px',
    fontSize: isMDView ? '.7em' : '1em',
  },
  fieldsContainerLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  fieldsContainerInput: {
    width: '75px',
    borderRadius: '5px',
    border: '1px solid rgba(0,0,0,0.5)',
    padding: '10px',
    margin: '3px 10px',
    fontSize: isMDView ? '.7em' : '1em',
  },
  startButton: {
    width: '100px',
  },
});