import React, {useCallback} from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Layer, Group, Circle, Rect, Line, Text, Image, Arrow} from "react-konva";
import CanvasBalloonScale from '../components/CanvasBalloonScale'
import {connect} from "react-redux";
import cloneDeep from "lodash.clonedeep";
import useImage from "use-image";
import balloonImg from '../img/balloon.png';
import ballastImg from '../img/ballast.png';
import fireGif from "../../../../images/fireSmall.gif";
import noAirBalloonImg from "../../../../images/balloon/noAirBaloon.png";
import {GIF} from "../../../canvas/components/GIF";
import Victor from 'victor';
import { _t } from "../../../../utils/lang/common";


class AirBalloonScale extends React.Component {
  constructor(props) {
    super(props);

    // --------- REFS ---------
    this.stageRef = React.createRef();
    this.heightScaleRef = React.createRef();
    this.airTemperatureTextRef = React.createRef();
    this.forceOfArchimedesArrowRef = React.createRef();
    this.forceOfGravityArrowRef = React.createRef();
    this.forceOfArchimedesTextRef = React.createRef();
    this.forceOfGravityTextRef = React.createRef();
    this.ballastRef = React.createRef();

    this.balloonDensityIndicatorRef = React.createRef();
    this.airDensityIndicatorRef = React.createRef();


    this.staticData = {
      atmosphericPressureOfStart: 760, // mm Hg
      balloonWeight: 500, // kg
      balloonVolume: 1000, // m3
      ballastWeight: 20, // kg
      initialAirTemperature: 26, // deg C
      g: 9.8 // H/kg
    };
    this.state = {
      gas: false,
      ballastDropped: false
    };
    this.initialData = {
      startTime: null,
      prevTime: null,

      atmosphericPressure: 760,


      airTemperature: 26, // deg С
      airDensity: 0,

      balloonWarmAirTemperature: 0, // deg С
      balloonWarmAirDensity: 1.29,

      forceOfGravity: 0,
      forceOfArchimedes: 0,

      balloonAirVal: 0,
      airVal: 0,

      heightScaleOffsetY: 0,
      ballastOffsetY: 0,

      balloonVolume: 250,
      baseDensity: 1,
      tempCoef: 0.01,
      baseTemp: 273 + 20,
      tempVelocity: 2,
      balloonTemp: 273 + 55,
      pos: Victor(40,300),
      velocity: Victor(0,0),
    };
    this.data = cloneDeep(this.initialData);
  }

  _resetCallback = () => {console.log('Debug reset callback')};
  getResetCallback = (callback) => {this._resetCallback = callback};

  handleReset = () => {
    this.setState({gas: false, ballastDropped: false});
    this._resetCallback();
    this.data = cloneDeep(this.initialData);
  };

  componentDidMount() {
    const stageNode = this.stageRef?.current;
    if (stageNode) {
      this.handleReset()
    }
    window.requestAnimationFrame(this.move);
  }

  getAllNodes = () => ({
    heightScaleNode: this.heightScaleRef?.current,
    airTemperatureTextNode: this.airTemperatureTextRef?.current,
    forceOfArchimedesArrowNode: this.forceOfArchimedesArrowRef?.current,
    forceOfGravityArrowNode: this.forceOfGravityArrowRef?.current,
    forceOfArchimedesTextNode: this.forceOfArchimedesTextRef?.current,
    forceOfGravityTextNode: this.forceOfGravityTextRef?.current,
    ballastNode: this.ballastRef?.current,

    balloonDensityIndicatorNode: this.balloonDensityIndicatorRef?.current,
    airDensityIndicatorNode: this.airDensityIndicatorRef?.current,
  });

  // get atmosphericPressure() {
  //   const data = this.data;
  //   const heightPxToM = data.heightScaleOffsetY * 2; // 1 cell 50px === 100 m
  //   let resPressure = this.staticData.atmosphericPressureOfStart - (heightPxToM / 10.5); // 1 mm Hg in 11 meters
  //   if (resPressure <= 0) {
  //     resPressure = 0;
  //   }
  //   return resPressure;
  // }

  get ballastOffsetY() {
    const staticData = this.staticData;
    if (this.state.ballastDropped) {
      return staticData.ballastWeight * staticData.g;
    }
    return 0;
  }

  enslow (value, delta, timedeltaSec) {
    let absValue = Math.abs(value);
    let signValue = Math.sign(value);

    absValue -= delta * timedeltaSec;
    if (absValue < 0) absValue = 0;

    return signValue * absValue
  }

  get height() {
    return 300 - this.data.pos.y;
  }

  get insideDensity() {
    const data = this.data;
    return data.baseDensity * Math.exp(-this.height * 0.0003) / data.balloonTemp * data.baseTemp;
  }

  get outsideDensity() {
    const data = this.data;
    return data.baseDensity * Math.exp(-this.height * 0.0003);
  }

  get balloonWeight() {
    const data = this.data;
    let res = data.balloonVolume * this.insideDensity + 20;
    if (!this.state.ballastDropped && this.props.code === 'balloon3') {
      res += this.staticData.ballastWeight;
    }
    return res;
  }

  get archimedesForce() {
    const data = this.data;
    return data.balloonVolume * this.outsideDensity;
  }

  move = (time) => {
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta / 1000;

    const timedeltaSecV = Victor(timedeltaSec, timedeltaSec);
    data.balloonTemp -= data.balloonTemp > data.baseTemp ? data.tempVelocity * timedeltaSec : 0;
    if (this.state.gas) {
      data.balloonTemp += 7 * timedeltaSec;
    }
    if (data.balloonTemp - 273 > 200) {
      data.balloonTemp = 200 + 273;
    }

    const force = - this.balloonWeight + this.archimedesForce;
    data.velocity.add(Victor(0, -force).multiply(timedeltaSecV));

    data.pos.add(data.velocity.clone().multiply(timedeltaSecV));
    if (data.pos.y > 300) {
      data.pos.y = 300;
    }

    data.velocity.y = this.enslow(data.velocity.y, data.velocity.length(), timedeltaSec);

    // console.log('debug on pos', data.pos.y, this.height);
    if (this.height < -10) {
      data.velocity.y = 0;
    //   data.balloonTemp = data.baseTemp;
    }

    data.ballastOffsetY += this.ballastOffsetY/10;

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const timedelta = data.prevTime ? data.startTime - data.prevTime : 0;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const nodes = this.getAllNodes();

    nodes.forceOfArchimedesArrowNode.points([0, 0, 0, -this.archimedesForce/2]);
    nodes.forceOfGravityArrowNode.points([0, 0, 0, this.balloonWeight/2]);

    nodes.forceOfArchimedesTextNode.y(-this.archimedesForce/2);
    nodes.forceOfGravityTextNode.y( this.balloonWeight/2);


    nodes.ballastNode.offsetY(-data.ballastOffsetY);
    nodes.heightScaleNode.offsetY(-this.height);
    nodes.airTemperatureTextNode.text(`t = ${Math.round(data.balloonTemp) - 273} °C`);


    nodes.balloonDensityIndicatorNode.fill(`rgba(250,105,115, ${0.05 + (this.height/50) * 0.003})`);
    nodes.airDensityIndicatorNode.fill(`rgba(81,185,236,${0.5 - 0.0025 * (this.height/50)})`);

    stageNode.draw();
  }

  CanvasComp = () => {
    const stageNode = this.stageRef?.current;
    const isBalloon3 = this.props.code === 'balloon3';

    const [balloon] = useImage(balloonImg);
    const [ballast] = useImage(ballastImg);
    const [noAirBalloon] = useImage(noAirBalloonImg);
    return (
      <>
        <Group x={0} y={470} ref={this.heightScaleRef}>
          {
            Array(301).fill(1).map((el, i) => {
              const isFive = i % 5 === 0;
              const lineWidth = isFive ? 40 : 20;
              return (
                <Group y={-i*50} key={`scale-${i}`}>
                  <Rect width={520} height={50} fill={`rgba(81,185,236,${0.7 - 0.015 * i})`}/>
                  <Line points={[0, 0, lineWidth, 0]} stroke={'black'} strokeWidth={2}/>
                  <Text text={`${i > 0 ? i : i}`} fill={'black'} fontSize={isFive ? 20 : 13} y={isFive ? -10 : -5} x={lineWidth+10}/>
                </Group>
              )
            })
          }
        </Group>
        <Group x={170} y={200}>
          <Image
            image={balloon}
            width={230}
            height={400}
          />
          <Group scale={{x: .5, y: .5}}>
            <GIF
              src={fireGif}
              x={215}
              y={555}
              opacity={+this.state.gas}
              loop
            />
          </Group>
          <Group x={115} y={100}>
            <Group>
              <Arrow ref={this.forceOfArchimedesArrowRef} points={[0, 0, 0, -140]} stroke={'#273e53'} fill={'#273e53'} strokeWidth={5}/>
              <Text
                 ref={this.forceOfArchimedesTextRef}
                text={'Сила Архимеда'}
                x={40}
                y={-170}
                fontSize={25}
                strokeWidth={1}
                stroke={'#273e53'}
              />
            </Group>

            <Group y={20}>
              <Arrow ref={this.forceOfGravityArrowRef} points={[0, 0, 0, 140]} stroke={'#273e53'} fill={'#273e53'} strokeWidth={5}/>
              <Text
                ref={this.forceOfGravityTextRef}
                text={'Сила тяжести'}
                x={40}
                y={140}
                fontSize={25}
                strokeWidth={1}
                stroke={'#273e53'}
              />
            </Group>
          </Group>
          {/* Ballast */}

          <Group ref={this.ballastRef}>
            {
              isBalloon3 && (
                <Image image={ballast} width={15} height={30} x={110} y={370}/>
              )
            }
          </Group>
        </Group>
        <Group scale={{x: .8, y: .8}} x={350} y={50}>
          <CanvasBalloonScale
            getResetCallback={this.getResetCallback}
            stageNode={stageNode}
            start={this.state.gas}
            data={() => ({leftVal: this.balloonWeight/20, rightVal:  this.archimedesForce/20})}
            leftWeightNode={
              <Group offset={{x: 80, y: 100}}>
                <Rect ref={this.balloonDensityIndicatorRef} x={70} y={100} width={20} height={20} fill={`brown`}/>
                <Rect ref={this.balloonDensityIndicatorRef} x={10} y={2} width={140} height={127} fill={`rgba(250,105,115, 0.3)`}/>
                <Image
                  image={noAirBalloon}
                  width={160}
                  height={130}
                />
              </Group>
            }
            rightWeightNode={
              <Group offset={{x: 80, y: 100}}>
                <Rect ref={this.airDensityIndicatorRef} x={10} y={2} width={140} height={127} fill={`rgba(81,185,236,0.5)`}/>
                <Image
                  image={noAirBalloon}
                  width={160}
                  height={130}
                />
              </Group>
            }
          />
        </Group>

        {/* ------------ DENSITY ------------ */}
        {/*<Group x={600} y={480}>*/}
        {/*  <circle*/}
        {/*    radius={70}*/}
        {/*    stroke={'rgba(0,0,0,0.1)'}*/}
        {/*    fill={'rgba(200, 0, 0, 0.05)'}*/}
        {/*  />*/}
        {/*  <Group>*/}
        {/*  {*/}
        {/*   Array(100).fill(1).map((el, i) => {*/}
        {/*     const angle = Math.random()*Math.PI*2;*/}
        {/*     const r = Math.random()*1000 % 65;*/}
        {/*     const x = Math.cos(angle)*r;*/}
        {/*     const y = Math.sin(angle)*r;*/}

        {/*     return (*/}
        {/*       <circle*/}
        {/*         x={x} y={y}*/}
        {/*         radius={5}*/}
        {/*         stroke={'rgba(0,0,0,0.1)'}*/}
        {/*         fill={'rgba(171,92,135,0.7)'}*/}
        {/*         strokeWidth={1}*/}
        {/*       />*/}
        {/*     )*/}
        {/*   })*/}
        {/*  }*/}
        {/*  </Group>*/}
        {/*</Group>*/}

        <Group x={560} y={140}>
          <Text
            ref={this.airTemperatureTextRef}
            text={'t = 20 °C'}
            fontSize={20}
            stroke={'#58ACF9'}
            fill={'#58ACF9'}
            strokeWidth={1}
          />
        </Group>
      </>
    )
  };

  render() {
    const isBalloon3 = this.props.code === 'balloon3';
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <Layer>
            <this.CanvasComp />
          </Layer>
        </CanvasContainer>

        <div style={styles.fieldsBlock}>
          <button
            style={styles.btn}
            onTouchStart={() => this.setState({gas: true})}
            onTouchEnd={() => this.setState({gas: false})}
            onMouseDown={() => this.setState({gas: true})}
            onMouseUp={() => this.setState({gas: false})}
          >{_t('balloon.torch')}</button>
          {
            isBalloon3 && (
              <button
                style={styles.btn}
                onClick={() => {this.setState({ballastDropped: true})}}
              >Сбросить груз</button>
            )
          }
          <button
            style={styles.btn}
            onClick={this.handleReset}
          >Сбросить</button>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AirBalloonScale);

const styles = {
  mainContainer: {
    // background: '#EDF2FB',
    background: 'rgba(0,0,0,0.05)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    margin: '0 5px',
    background: '#9caaad',
    borderRadius: '7px',
    color: 'white',
    zIndex: 10,
    fontSize: '2vmin',
    padding: '1vmin 1.5vmin',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  fieldsBlock: {
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: '10%',
    right: '10%'
  }
};
