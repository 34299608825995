import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ArrangeByColumns from "./ArrangeByColumns";
import simpleMachinesImg from "../images/arrangeByColumns/simple_machines.png";
import barnImg from "../images/arrangeByColumns/barn.png";
import rampImg from "../images/arrangeByColumns/ramp2.png";
import workImg from "../images/arrangeByColumns/work.png";


const Example3Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const columns1 = [
    {id: 1, successAnswerId: 2, title: "Barn"},
    {id: 2, successAnswerId: 3, title: "Ramp"},
    {id: 3, successAnswerId: 4, title: "Simple machine"},
    {id: 4, successAnswerId: 1, title: "Work"},
  ]
  const dragItems1 = [
    {id: 1, image: workImg},
    {id: 2, image: barnImg},
    {id: 3, image: rampImg},
    {id: 4, image: simpleMachinesImg},
  ]

  const columns2 = [
    {id: 1, successAnswerId: 4, title: "Barn"},
    {id: 2, successAnswerId: 3, title: "Ramp"},
    {id: 3, successAnswerId: 1, title: "Simple machine"},
    {id: 4, successAnswerId: 2, title: "Work"},
  ]
  const dragItems2 = [
    {id: 1, mainText: "A mechanical device that changes how hard you push or pull a thing to move it and" +
        "the direction that you push or pull a thing to move it"},
    {id: 2, mainText: "A physical effort done in order to achieve a purpose or result"},
    {id: 3, mainText: "A flat surface located at an angle to the horizon"},
    {id: 4, mainText: "An agricultural building used for various purposes, usually on farms"},
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={2}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      {
        completedTaskCount < 1 ? (
          <ArrangeByColumns
            key={'1'}
            title={'All titles are false. Move the pictures'}
            columns={columns1}
            dragItems={dragItems1}
            taskComplete={() => {
              setCompletedTaskCount(prevState => prevState + 1);
            }}
          />
        ) : (
          <ArrangeByColumns
            key={'2'}
            title={'All titles are false. Move the definitions'}
            columns={columns2}
            dragItems={dragItems2}
            taskComplete={() => {
              setAllComplete(true);
              setCompletedTaskCount(prevState => prevState + 1);
            }}
          />
        )
      }
    </TaskMainContainer>
  )
}
export default Example3Task;
