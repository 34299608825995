import React, {useEffect, useState} from "react";
import MainContainer from "../../layout2/components/MainContainer";
import {Button} from "antd";
import {StatisticTableFilter} from "../../layout2/components/StatisticTableFilter";
import StatisticTable from "../../layout2/components/StatisticTable";
import SectionWrapper from "../../layout2/components/SectionWrapper";
import {
  getDynamicsColumns,
  getDynamicsConfigFilter,
  getDynamicsRows,
  getProgressConfigFilter,
  getProgressTableColumns, getProgressTableRows
} from "../utils";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import '../styles/styles.css';



const Statistics = (props) => {
  const {fetchHwTeacherNotifications} = props;

  const [classrooms, setClassrooms] = useState([]);
  const [homeworksByPeriod, setHomeworksByPeriod] = useState(null);

  const [dynamicsLoading, setDynamicsLoading] = useState(false);
  const [dynamicsTableData, setDynamicsTableData] = useState([]);
  const [stateFilterDynamics, setStateFilterDynamics] = useState(
    {
      studentsClassroomFilter: [],
      periodFillerFragmentation: null, // 'by_weeks' | 'by_months' | 'by_homeworks'
      periodFillerDate: {start: null, end: null}, // [startMoment, endMoment]
      rowsFiller: null,
      measureFilter: null,
    }
  );

  const [progressLoading, setProgressLoading] = useState(false);
  const [progressTableData, setProgressTableData] = useState([]);
  const [stateFilterProgress, setStateFilterProgress] = useState(
    {
      studentsClassroomFilter: [],
      rowsFiller: null,
      chapterFilter: null,
      measureFilter: null,
    }
  );

  useEffect(() => {
    (async function () {
      // Запрашиваем список домашних заданий для фрагментации по домашним заданиям
      const {start, end} = stateFilterDynamics.periodFillerDate;
      if (start && end && stateFilterDynamics.periodFillerFragmentation === 'by_homeworks') {
        const hwsResp = await props.fetchHomeworksByPeriod(stateFilterDynamics.periodFillerDate);
        if (hwsResp?.results) {
          setHomeworksByPeriod(hwsResp.results)
        }
      }
    })()
  }, [stateFilterDynamics.periodFillerFragmentation, stateFilterDynamics.periodFillerDate])

  useEffect(() => {
    (async function () {
      setDynamicsLoading(true);
      const resp = await props.fetchCommonDynamicsStatistics(stateFilterDynamics);
      if (resp?.results) {
        setDynamicsTableData(resp.results)
      }
      setDynamicsLoading(false);
    })()
  }, [stateFilterDynamics])

  useEffect(() => {
    (async function () {
      setProgressLoading(true);
      const resp = await props.fetchCommonProgressStatistics(stateFilterProgress);
      if (resp?.results) {
        setProgressTableData(resp.results)
      }
      setProgressLoading(false);
    })()
  }, [stateFilterProgress])


  useEffect(() => {
    (async function () {
      const lessonsParagraphsResp = await props.fetchLessonsParagraphsForCatalog();

      const resp = await props.fetchClassroomsData();
      if (resp) {
        setClassrooms(resp.classroomsList);
      }
    })();
    (async function () {
      setDynamicsLoading(true);
      setProgressLoading(true);
      await fetchHwTeacherNotifications();
      setDynamicsLoading(false);
      setProgressLoading(false);
    })()
  }, [])

  const pageKey = 'statistics-page';


  const classesWithoutEmpty = classrooms.filter( cls => !cls.for_new_students);

  const dynamicsColumns = getDynamicsColumns({...stateFilterDynamics, homeworksByPeriod});
  const dynamicsRows = getDynamicsRows(dynamicsTableData);
  const dynamicsConfigFilter = getDynamicsConfigFilter(classesWithoutEmpty, setStateFilterDynamics);

  const correctGroups = props.lsnGroups?.filter(group => group.chapter === Number(stateFilterProgress.chapterFilter))
  const progressTableColumns = getProgressTableColumns(correctGroups);
  const progressTableRows = getProgressTableRows(progressTableData);
  const progressConfigFilter = getProgressConfigFilter(classesWithoutEmpty, props.chapters, setStateFilterProgress);
  return (
    <div>
      <MainContainer
        menuOnlyMobile
        withoutMenuMargin
        pageKey={pageKey}
      >
        <div className={'statistics-wrapper'}>
          <SectionWrapper
            loading={dynamicsLoading}
            title={'Динамика успеваемости учеников'}
            wrapperStyles={{maxWidth: '1000px', width: '100%'}}
          >
            <StatisticTableFilter configFilter={dynamicsConfigFilter}/>
            <StatisticTable
              // loading={dynamicsLoading}
              columns={dynamicsColumns}
              rows={dynamicsRows}
            />
          </SectionWrapper>

          <SectionWrapper
            loading={progressLoading}
            title={'Успеваемость учеников по темам'}
            wrapperStyles={{maxWidth: '1500px', width: '100%'}}
          >
            <StatisticTableFilter configFilter={progressConfigFilter}/>
            <StatisticTable
              // loading={progressLoading}
              columns={progressTableColumns}
              rows={progressTableRows}
            />
          </SectionWrapper>
        </div>
      </MainContainer>
    </div>
  )
}


const mapStateToProps = (state, {match}) => ({
  lsnGroups: state.lessonsReducer.lessonsGroups,
  chapters: state.lessonsReducer.chapters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHwTeacherNotifications: () => dispatch(actions.fetchHwTeacherNotifications()),
    fetchLessonsParagraphsForCatalog: () => dispatch(actions.fetchLessonsParagraphsForCatalog()),
    fetchClassroomsData: () => dispatch(actions.fetchClassroomsData()),
    fetchCommonDynamicsStatistics: (filterState) => dispatch(actions.fetchCommonDynamicsStatistics(filterState)),
    fetchCommonProgressStatistics: (filterState) => dispatch(actions.fetchCommonProgressStatistics(filterState)),
    fetchHomeworksByPeriod: (period) => dispatch(actions.fetchHomeworksByPeriod(period)),
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Statistics);
