import React from "react";
import {Group, Image} from "react-konva";
import MovingArrow from "../../../../canvas/components/MovingArrow";




export const SelectingMethods = (props) => {
  const data = props.data;
  const _ref = props._ref;
  const _getNode = props._getNode;
  const scenarioManager = props.scenarioManager;

  const selectMethod = (num) => {
    if (!data.completeLsnVisible) {
      let key = `method-${num}`;
      if (data.cargoName) {
        key = `${data.cargoName}-${key}`;
      }
      scenarioManager.selectStepByKey(key);
    }
  };

  return (
    <Group
      x={950} y={0}
      offsetX={props.methodsList.length * data.methodsImgsStep}
      scale={data.methodsContainerScale}
      visible={!data.withoutSelectingMethods}
    >
      {
        props.methodsList.map((num, i) => {
          const refKey = `methodImg${num}`;
          const node = _getNode(refKey);

          if (!props.methodsImgs[`img${num}`]) {
            return <Group ref={_ref(refKey)} key={`methodImg-${num}`}/>}

          return (
            <Image
              ref={_ref(refKey)}
              key={`methodImg-${num}`}
              onMouseLeave={() => {
                if (!data[`method${num}Visible`]) {
                  node.scale({x: 1, y: 1})
                  node.offset({x: 0, y: 0});
                }
              }}
              onMouseEnter={() => {
                if (!data[`method${num}Visible`]) {
                  node.scale({x: 1.1, y: 1.1})
                  node.offset({x: 10, y: 10});
                }
              }}
              x={i * data.methodsImgsStep}
              width={180} height={180}
              scale={data[`method${num}Visible`] ? {x: 1.1, y: 1.1} : {x: 1, y: 1}}
              offset={data[`method${num}Visible`] ? {x: 10, y: 10} : {x: 0, y: 0}}
              image={props.methodsImgs[`img${num}`]}
              opacity={!props.isLiftingMethodSelected || data[`method${num}Visible`] ? 1 : .4}
              onClick={() => selectMethod(num)}
              onTap={() => selectMethod(num)}
            />
          )
        })
      }
      <MovingArrow
        id={'selectMethod'}
        ref={_ref('selectMethodArrowHint')}
        stageNode={_getNode('stage')}
        length={30}
        arrowsStep={10}
        arrowsCount={1}
        x={90} y={200}
        textColor={'black'}
        arrowColor={'black'}
        text={''}
        rotation={180}
        // visible={!props.isLiftingMethodSelected}
        visible={false}
        getMovingCallback={props.getMovingCallback}
      />
    </Group>
  )
}