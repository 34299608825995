import React from "react";
import {CanvasContainer} from "../../../canvas";
import {Rect, Layer, Text, Group, Line} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import Water from "../../../canvas/components/Water";
import water from "../../../../images/wave.png";
import Victor from "victor";
import pirateShipImg from "../images/pirateShip.png";
import islandImg from "../images/beach.png";
import cannonImg from "../images/Cannon.png";
import cannonballImg from "../images/ball.png";
import {CanvasPirateShip} from "../components/CanvasPirateShip";
import {getInitialDataByCode, getLsnBoolByCode, getTitle, scaleToPx} from "../utils";
import {CanvasIsland} from "../components/CanvasIsland";
import {CanvasCannonball} from "../components/CanvasCannonball";
import {CanvasCannon} from "../components/CanvasCannon";
import {GIF} from "../../../canvas/components/GIF";
import explosionGIF from "../images/explosionGIF.gif";
import {
  hasIntersection,
  sendSuccessForScenario,
  showFailOrSuccessPopup,
  toggleVisibleEl
} from "../../../../utils/common";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import Card from "../../../canvas/components/Card";
import CanvasButton from "../../../canvas/components/CanvasButton";
import {layout2, lsnLabelTxtStyle, lsnTitleTxtStyle} from "../../../../utils/styles";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {movePirates1, movePirates2, movePirates3, moveWater} from "../moveHelper";
import * as actions from "../../../../store/actions";
import ScenarioManager from "../../../../utils/ScenarioManager";


class Pirates extends React.Component {
  constructor(props) {
    super(props);

    this.stageRef = React.createRef();
    this.titleRef = React.createRef();
    this.shipRef = React.createRef();
    this.ballRef = React.createRef();
    this.cannonRef = React.createRef();
    this.timerRef = React.createRef();
    this.startBtnRef = React.createRef();
    this.cannonDescrRef = React.createRef();
    this.shipDescrRef = React.createRef();
    this.waterRef = React.createRef();
    this.explosionRef = React.createRef();

    this.cannonHitBoxRef = React.createRef();
    this.shipHitBoxRef = React.createRef();
    this.ballHitBoxRef = React.createRef();

    this.topBorderRef = React.createRef();
    this.rightBorderRef = React.createRef();
    this.bottomBorderRef = React.createRef();
    this.leftBorderRef = React.createRef();

    this.inputRef = React.createRef();
    this.inputViewRef = React.createRef();

    this.state = {
      explosion: false
    };

    this.initialData = getInitialDataByCode(this.props.code);
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidMount() {
    this.setState({code: this.props.code});
    window.requestAnimationFrame(this.move);
  }

  getNodes = () => ({
    titleNode: this.titleRef?.current,
    shipNode: this.shipRef?.current,
    ballNode: this.ballRef?.current,
    cannonNode: this.cannonRef?.current,
    timerNode: this.timerRef?.current,
    startBtnNode: this.startBtnRef?.current,
    cannonDescrNode: this.cannonDescrRef?.current,
    shipDescrNode: this.shipDescrRef?.current,
    waterNode: this.waterRef?.current,

    topBorderNode: this.topBorderRef?.current,
    rightBorderNode: this.rightBorderRef?.current,
    bottomBorderNode: this.bottomBorderRef?.current,
    leftBorderNode: this.leftBorderRef?.current,

    cannonHitBoxNode: this.cannonHitBoxRef?.current,
    shipHitBoxNode: this.shipHitBoxRef?.current,
    ballHitBoxNode: this.ballHitBoxRef?.current,

    explosionNode: this.explosionRef?.current,

    inputNode: this.inputRef?.current,
    inputViewNode: this.inputViewRef?.current,
  });

  get waterOffset() {
    const { pirates1, pirates2, pirates3, pirates4 } = getLsnBoolByCode(this.props.code);
    const data = this.data;
    const maxOffset = 111;
    const minOffset = -57;
    let condition = true;

    if (pirates3 || pirates2) {
      condition = maxOffset > Math.abs(data.waterOffset);
    }
    if (pirates2) {
      // выравнивание скорости течения без изменения значения в карточке информации
      return condition ? data.waterOffset + (-(Math.abs(data.flowRate)+18)/30) : 0;
    }
    if (pirates1 || pirates4) {
      condition = data.waterOffset > minOffset;
    }

    if (pirates4) {
      return condition ? data.waterOffset + (data.flowRate/3) : 0;
    }
    return condition ? data.waterOffset + (data.flowRate/30) : 0;
  }

  getDefaultData = () => {
    const data = this.data;
    const { pirates1, pirates4 } = getLsnBoolByCode(this.props.code);
    const correctBallSpeed = -data.ballSpeed;
    const correctBoatSpeed = data.boatSpeed;
    let correctFlowRate = pirates1 || pirates4 ? data.flowRate : -data.flowRate;
    const correctCannonSpeed = data.cannonSpeed;

    const cannonSpeedV = Victor(correctCannonSpeed, 0);
    const ballSpeedV = Victor(0, correctBallSpeed);
    const boatSpeedV = Victor(correctBoatSpeed, 0);
    const flowSpeedV = Victor(correctFlowRate, 0);
    const resultV = flowSpeedV.clone().add(boatSpeedV);

    return {
      cannonSpeedV,
      ballSpeedV,
      boatSpeedV,
      flowSpeedV,
      resultV,
    }
  }
  get timer() {
    const data = this.data;
    return (data.prevTime - data.shipStartTime) / 1000
  }

  stop = () => {
    this.data.start = false;
  };

  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
    this.setState({code: this.props.code, explosion: false});
  };

  checkStart = () => {
    this.data.start = true;
    this.data.shipStartTime = this.data.prevTime;
  }

  checkSuccess = () => {
    const data = this.data;
    const { pirates1, pirates2, pirates3, pirates4 } = getLsnBoolByCode(this.props.code);
    let success = false;
    if (pirates1)
      success = +data.boatSpeed === 6;
    else if (pirates2)
      success = +data.delay === 4;
    else if (pirates3)
      success = +data.delay === 3;
    else if (pirates4)
      success = +data.boatSpeed === 0.5;

    if (success) {
      sendSuccessForScenario(this);
    }
    return success;
  }
  checkResultValue = () => {
    const success = this.checkSuccess();
    showFailOrSuccessPopup(this, success);
  }

  move = (time) => {
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const { pirates1, pirates2, pirates3, pirates4 } = getLsnBoolByCode(this.props.code);
    const data = this.data;
    const nodes = this.getNodes();

    data.startTime = data.startTime || time;
    data.prevTime = time;
    data.waterOffset = this.waterOffset;

    if (data.start) {

      data.timer = this.timer;
      data.shipTimeDelta = data.timer;

      let ballStart = data.timer >= data.delay;
      if (pirates1 || pirates4) {
        ballStart = data.timer >= 5
      }

      if (ballStart) {
        data.ballStartTime = data.ballStartTime || data.prevTime;
        data.ballTimeDelta = (data.prevTime - data.ballStartTime) / 1000;
      }

      if (pirates1 || pirates4) movePirates1(this);
      else if (pirates2) movePirates2(this);
      else if (pirates3) movePirates3(this);

      const success = this.checkSuccess();
      const shipExplosion = success && hasIntersection(nodes.ballHitBoxNode , nodes.shipHitBoxNode, this);
      const cannonExplosion = success && hasIntersection(nodes.ballHitBoxNode , nodes.cannonHitBoxNode, this);

      const ballOutTop = hasIntersection(nodes.ballHitBoxNode, nodes.topBorderNode, this);
      const ballOutLeft = hasIntersection(nodes.ballHitBoxNode, nodes.leftBorderNode, this);
      const ballOutRight = hasIntersection(nodes.ballHitBoxNode, nodes.rightBorderNode, this);
      const ballOutBottom = hasIntersection(nodes.ballHitBoxNode, nodes.bottomBorderNode, this);

      const shipOutLeft = hasIntersection(nodes.shipHitBoxNode, nodes.leftBorderNode, this);
      const shipOutRight = hasIntersection(nodes.shipHitBoxNode, nodes.rightBorderNode, this);

      if (
        (pirates2 && shipExplosion) ||
        ((pirates3 || pirates1 || pirates4) && cannonExplosion)
      ) {
        this.stop();
        this.setState(() => ({
          explosion: true
        }), () => {
          setTimeout(this.checkResultValue, 1000)
        })
      }


      if (
        (
          shipOutLeft ||
          shipOutRight ||
          ballOutTop ||
          ballOutLeft ||
          ballOutRight ||
          ballOutBottom) &&
        !this.data.fail
      ) {
        this.data.fail = true;
        this.checkResultValue();
      }


      moveWater(this);
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const nodes = this.getNodes();
    const { pirates1, pirates4 } = getLsnBoolByCode(this.props.code);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    toggleVisibleEl(nodes.titleNode, data.start);
    toggleVisibleEl(nodes.startBtnNode, data.start);
    toggleVisibleEl(nodes.cannonDescrNode, data.start);
    toggleVisibleEl(nodes.shipDescrNode, data.start);

    nodes.shipNode.position(data.shipPos);
    nodes.ballNode.position(data.ballPos);
    nodes.cannonNode.position(data.cannonPos);
    nodes.waterNode.offsetX(data.waterOffset);

    nodes.inputNode[data.start ? 'hide' : 'show']();
    nodes.inputViewNode[!data.start ? 'hide' : 'show']();
    nodes.inputViewNode.text((pirates1 || pirates4 ? data.boatSpeed : data.delay)||0);

    nodes.timerNode.text(Math.round(data.timer));

    stageNode.draw();
  }

  Canvas = () => {
    const {
      shipPos,
      ballPos,
      shipCenter,
      ballCenter,
      cannonCenter,
      cannonPos,
      cannonRotation,
      shipRotation,
    } = this.data;

    const { pirates1, pirates2, pirates3, pirates4 } = getLsnBoolByCode(this.props.code);

    const [pirateShipImage] = useImage(pirateShipImg);
    const [islandImage] = useImage(islandImg);
    const [cannonImage] = useImage(cannonImg);
    const [cannonballImage] = useImage(cannonballImg);
    const [waterImage] = useImage(water);

    const shipPositionScaled = shipPos ? scaleToPx(shipPos) : null;
    const ballPositionScaled = ballPos ? scaleToPx(ballPos) : null;
    const cannonPositionScaled = cannonPos ? scaleToPx(cannonPos) : null;

    const title = getTitle(this.props.code);

    return (
      <React.Fragment>
        <Group ref={this.waterRef} x={-200}>
          <Water image={waterImage} canvasWidth={1500} canvasHeight={1500} targetHeight={700} />
        </Group>
        <Group x={550} y={500}>
          <CanvasIsland
            islandImage={islandImage}
          />
        </Group>


        { !this.state.explosion && (
          <Text ref={this.titleRef} text={title} {...lsnTitleTxtStyle} strokeWidth={0} opacity={0} y={20} fill={'white'}/>
        )}


        <Group {...ballPositionScaled} ref={this.ballRef}>
          <Group {...ballCenter}>
            {/* EXPLOSION GIF */}
            {
              this.state.explosion ? (
                <GIF reffer={this.explosionRef} src={explosionGIF} width={125}/>
              ) : (
                <>
                  <CanvasCannonball ballImage={cannonballImage} />
                  <Rect
                    ref={this.ballHitBoxRef}
                    height={20}
                    width={20}
                    x={-10} y={-10}
                    fill={'green'}
                    opacity={0}
                  />
                </>
              )
            }
          </Group>
        </Group>


        <>
          <Group {...cannonPositionScaled} ref={this.cannonRef}>
            <Group {...cannonCenter}>
              <CanvasCannon
                cannonImage={cannonImage}
                rotation={cannonRotation}
              />
              <Rect
                ref={this.cannonHitBoxRef}
                height={pirates1 || pirates4 ? 60 : 70}
                width={pirates1 || pirates4 ? 70 : 60}
                x={pirates1 || pirates4 ? -20 : -30} y={pirates1 || pirates4 ? -30 : -20}
                fill={'purple'}
                opacity={0}
              />
            </Group>
          </Group>
          {
            ((pirates2 || pirates3) && !this.state.explosion) && (
              <Group
                {...{ x: 500, y: 460}}
                ref={this.cannonDescrRef} opacity={0}
              >
                <Group y={-65} rotation={0}>
                  <Line points={[-10,0,10,0]} stroke={'white'} fill={'white'}/>
                  <Line y={-225 } points={[-10,0,10,0]} stroke={'white'} fill={'white'}/>
                  <Line points={[0,0,0,-225]} stroke={'white'} fill={'white'}/>
                </Group>
                <Text x={-60} y={-350} width={40} height={340}
                      align={'center'} verticalAlign={'middle'} text={'b'} fontSize={40} fill={'white'}/>
              </Group>
            )
          }
        </>



        <>
          <Group {...shipPositionScaled} ref={this.shipRef}>
            <Group {...shipCenter}>
              <CanvasPirateShip
                shipImage={pirateShipImage}
                rotation={shipRotation}
              />
              <Rect
                ref={this.shipHitBoxRef}
                height={50}
                width={100}
                x={pirates1 || pirates4 ? -35 : -65} y={-25}
                fill={'indigo'}
                opacity={0}
              />
            </Group>
          </Group>
          {
            ((pirates2 || pirates3) && !this.state.explosion) && (
              <Group x={200} y={150} ref={this.shipDescrRef} opacity={0}>
                <Group>
                  <Line points={[0,-10,0,10]} stroke={'white'} fill={'white'}/>
                  <Line x={320} points={[0,-10,0,10]} stroke={'white'} fill={'white'}/>
                  <Line points={[0,0,320,0]} stroke={'white'} fill={'white'}/>
                </Group>
                <Text y={20} width={340} height={40} align={'center'} verticalAlign={'middle'} text={'a'} fontSize={40} fill={'white'}/>
              </Group>
            )
          }
        </>

        <CanvasResetBtn onClick={() => this.onClickReset()} />

      </React.Fragment>
    )
  };

  CanvasCards = () => {
    const data = this.data;
    const { pirates1, pirates2, pirates3, pirates4 } = getLsnBoolByCode(this.props.code);
    let staticDataForCard = {

    }

    if (pirates1 || pirates4) {
      staticDataForCard = {
        rateSpeed: {txt: 'Скорость течения (м/с): ', val: Math.abs(data.flowRate)},
        cannonSpeed: {txt: 'Скорость пушки (м/с): ', val: Math.abs(data.cannonSpeed), y: 40},
        ballSpeed: {txt: "Скорость ядра при \nвылете из дула (м/с): ", val: data.ballSpeed, y: 0},
      }
    }
    if (pirates2) {
      staticDataForCard = {
        a: {txt: 'a (м): ', val: 100},
        b: {txt: 'b (м): ', val: 80},
        ballSpeed: {txt: 'Скорость ядра (м/с): ', val: data.ballSpeed, y: 41},
        rateSpeed: {txt: 'Скорость течения (м/с): ', val: Math.abs(data.flowRate), y: 42},
        shipSpeed: {txt: 'Скорость корабля \nотносительно воды (м/с): ', val: 0,},
      }
    }
    if (pirates3) {
      staticDataForCard = {
        a: {txt: 'a (м): ', val: 100},
        b: {txt: 'b (м): ', val: 80},
        ballSpeed: {txt: "Скорость ядра относительно корабля при вылете из дула (м/с): ", val: data.ballSpeed, height: 60, y: 32, fontSize: 14},
        rateSpeed: {txt: 'Скорость течения (м/с): ', val: 20, y: 43},
        shipSpeed: {txt: 'Скорость корабля \nотносительно воды (м/с): ', val: 5,},
      }
    }
    return (
      <>
        <Group x={pirates1 || pirates4 ? 570 : 560} y={325}>
          <Card height={170} width={150}>
            <Group x={15} y={15}>

              <Group y={0}>
                <Text text={'Таймер: '} {...lsnLabelTxtStyle} fill={layout2.orange}/>
                <Text ref={this.timerRef} y={20} text={'0'} {...lsnLabelTxtStyle} fontSize={30} fill={layout2.orange}/>
              </Group>

              <Group y={pirates1 || pirates4 ? 90 : 80}>
                <Text text={pirates1 || pirates4? 'Скорость корабля \nотносительно воды:' : 'Время до выстрела:'} {...lsnLabelTxtStyle} y={pirates1 || pirates4 ? -20 : 0} fontSize={14} fill={layout2.orange}/>

                <Group ref={this.inputRef}>
                  <CanvasInput
                    id={'1'}
                    y={20}
                    x={0}
                    width={120}
                    height={30}
                    stage={this.stageRef?.current}
                    textColor={layout2.orange}
                    onInput={(val) => {
                      this.data[pirates1 || pirates4 ? 'boatSpeed' : 'delay'] = val || val === '0' ? Number(val) : null;
                    }}
                    value={this.data[pirates1 || pirates4 ? 'boatSpeed' : 'delay']}
                  />
                </Group>
                <Text ref={this.inputViewRef} x={1} y={17} text={this.data[pirates1 || pirates4 ? 'boatSpeed' : 'delay']}
                      {...lsnLabelTxtStyle} fontSize={30} fill={layout2.orange}/>
              </Group>

            </Group>
          </Card>
          {
            !this.state.explosion && (
              <CanvasButton
                btnRef={this.startBtnRef}
                text={'Старт'}
                onClick={() => {this.checkStart()}}
                fontSize={23}
                strokeWidth={.2}
                btnCornerRadius={0}
                width={150}
                height={45}
                y={180}
              />
            )
          }
        </Group>

        <Rect ref={this.topBorderRef} x={0} y={-90} height={100} width={1000} fill={'black'} opacity={0}/>
        <Rect ref={this.rightBorderRef} x={990} y={0} height={600} width={100} fill={'black'} opacity={0}/>
        <Rect ref={this.bottomBorderRef} x={0} y={540} height={100} width={1000} fill={'black'} opacity={0}/>
        <Rect ref={this.leftBorderRef} x={-90} y={0} height={600} width={100} fill={'black'} opacity={0}/>

        <Group x={730} y={pirates1 || pirates4 ? 130 : 80}>
          <Card height={pirates1 || pirates4 ? 170 : 250} width={250}>
            <Group x={20} y={20}>
              {
                Object.keys(staticDataForCard).map((k, i) => {
                  const itemsLen = Object.keys(staticDataForCard).length-1;
                  const el = staticDataForCard[k];
                  return (
                    <Group y={(el.y||40)*i} key={el.txt}>
                      <Text text={el.txt} {...lsnLabelTxtStyle} width={170} align={'right'}
                            fontSize={el.fontSize || 15}
                      />
                      <Text x={180} y={i === itemsLen ? 20 : 0} height={el.height} verticalAlign={'middle'} text={el.val} {...lsnLabelTxtStyle} fontStyle={'bold'}/>
                    </Group>
                  )
                })
              }
            </Group>
          </Card>
        </Group>

      </>
    )
  }

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
            <this.CanvasCards />
          </Layer>
        </CanvasContainer>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pirates);

const styles = {
  mainContainer: {
    background: '#4AB5A1'
  }
};
