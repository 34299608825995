import React, {useState} from "react";
import SchoolTaskContainer from "../components/SchoolTaskContainer";
import bgImg from '../images/bg.png';
import wheelImg from '../images/arrangeByColumns/wheel.png';
import rampImg from '../images/arrangeByColumns/ramp.png';
import boxImg from '../images/arrangeByColumns/box.png';
import '../styles/arrangeByColumns.css';
import SchoolTaskDropColumn from "../components/SchoolTaskDropColumn";
import CustomButton from "../components/CustomButton";
import { isEng } from "../../../../utils/lang/common";

const columnsDefault = [
  {id: 1, successAnswerId: 2, title: 'Simple machine'},
  {id: 2, successAnswerId: 3, title: 'Ramp'},
  {id: 3, successAnswerId: 1, title: 'Not a simple machine'},
]
const dragItemsDefault = [
  {id: 1, image: boxImg},
  {id: 2, image: wheelImg},
  {id: 3, image: rampImg},
]

const ArrangeByColumns = (props) => {
  const columns = props.columns || columnsDefault;
  const dragItems = props.dragItems || dragItemsDefault;

  let initialColumnState = {};
  columns.forEach((el, i) => {
    initialColumnState[el.id] = dragItems[i].id
  })

  const [draggingEl, setDraggingEl] = useState(undefined);
  const [dragStart, setDragStart] = useState(false);
  const [taskCompleteFlag, setTaskCompleteFlag] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [fail, setFail] = useState(undefined);
  const [columnsState, setColumnsState] = useState(initialColumnState);

  const taskComplete = () => {
    props.taskComplete && props.taskComplete();
  }

  let btnColor = 'purple';
  if (success) {btnColor = 'green';}
  if (fail) {btnColor = 'red';}
  return (
    <SchoolTaskContainer
      failMessage={fail}
      lang={props.lang}
      bgImg={props.bgImg || bgImg}
      title={props.title || ''}
      titleFontSize={props.titleFontSize}
      success={taskCompleteFlag}
      continueClassName={'arrange-by-columns__btn-continue'}
      contentClassName={'arrange-by-columns__content'}
      onClickContinue={taskComplete}
    >
      <div className={'arrange-by-columns__columns'}>
        {
          columns.map((columnItem, i) => {
            const item = dragItems.find((dragItem) => dragItem.id === columnsState[columnItem.id]);
            return (
              <SchoolTaskDropColumn
                key={columnItem.id}
                id={columnItem.id}
                title={columnItem.title}
                titleFontSize={columnItem.titleFontSize}
                heightTitle={columnItem.height}

                success={success}
                fail={fail}

                dragging={!taskCompleteFlag && !(success || fail)}
                dragStart={dragStart}
                dragItem={item}
                onDragStart={(e) => {
                  setDraggingEl(e.target);
                }}
                onDrag={(e) => {
                  setDragStart(true);
                }}
                onDragEnd={(e) => {
                  setDragStart(false);
                  setDraggingEl(undefined);
                }}
                onDrop={(data) => {
                  const dragItemId = data.dragData.id;
                  const dropItemId = data.dropData.columnId;
                  setColumnsState(prevState => {
                    const oldDropPlaceId = Object.keys(prevState).find((key) => prevState[key] === dragItemId);
                    prevState[oldDropPlaceId] = prevState[dropItemId];
                    prevState[dropItemId] = dragItemId;
                    return prevState;
                  });
                }}
              />
            )
          })
        }
      </div>
      {
        !taskCompleteFlag ? (
          <CustomButton
            className={'arrange-by-columns__btn-check'}
            onClick={() => {
              const allCardsSuccess = columns.every(column => columnsState[column.id] === column.successAnswerId);
              if (allCardsSuccess) {
                setSuccess(true);
                setFail(undefined);
              } else {
                setSuccess(undefined);
                setFail(true);
              }
              setTimeout(() => {
                setFail(undefined);
                if (allCardsSuccess) {
                  setTaskCompleteFlag(true);
                }
              }, 1000);
            }}
            btnColor={btnColor}
            btnText={isEng() ? 'Press to check' : 'Проверить'}
          />
        ) : null
      }
    </SchoolTaskContainer>
  )
}
export default ArrangeByColumns;

