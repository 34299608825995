import useImage from "use-image";
import dialogArrowImg from "../../../../images/space/theory/dialogArrow.png";
import theoryBackgroundImg from "../../../../images/space/theory/background.png";
import {Group, Image, Rect, Text} from "react-konva";
import CanvasButton from "../../../canvas/components/CanvasButton";
import React from "react";


const TheoryCanvas = (props) => {
  const {data, containerComp, questionImages} = props;

  const [dialogArrow] = useImage(dialogArrowImg);
  const [theoryBackground] = useImage(theoryBackgroundImg);



  return (
    <Group {...props}>
      <Image image={theoryBackground}/>


      <Group x={160} y={400} visible={data.dialogVisible}>
        <Image image={dialogArrow} x={-30} y={20}/>
        <Rect width={260} height={110} cornerRadius={20} fill={'white'} {...data.dialogStgRect}/>
        <Text x={20} y={20} text={data.dialogText} fill={'#4f558c'} fontSize={data.dialogFontSize} lineHeight={1.2}/>
      </Group>



      <Group x={150} visible={data.projectorVisible}>
        <Rect x={25} width={650} height={520} fill={'white'} cornerRadius={4}/>
        <Rect width={700} height={18} fill={'#121527'} cornerRadius={10}/>

        <Group visible={data.questionsListVisible}>
          <Group x={80} y={60}>
            {
              data.theoryQuestions.map((el, i) => (
                <CanvasButton
                  key={'key-'+el.id}
                  text={'- '+el.text}
                  onClick={() => {containerComp.scenarioManager.selectStepByKey(el.scenarioKey)}}
                  fontSize={30}
                  strokeWidth={.2}
                  textFill={'#4f558c'}
                  btnFill={'transparent'}
                  fontStyle={'normal'}
                  align={'left'}
                  width={el.width || 600}
                  height={el.height || 100}
                  x={0} y={el.stepY}
                />
              ))
            }
          </Group>

          <CanvasButton
            text={'Пропустить теорию'}
            onClick={() => {data.skipTheoryOnClick(containerComp)}}
            fontSize={20}
            btnStroke={'#4f558c'}
            strokeWidth={.1}
            btnStrokeWidth={1}
            btnCornerRadius={10}
            textFill={'#4f558c'}
            btnFill={'white'}
            boxShadowColor={'transparent'}
            fontStyle={'normal'}
            width={200}
            height={40}
            x={450} y={450}
          />
        </Group>
        <Group x={80} y={60} visible={!!data.projectorContent}>

          {
            data.projectorContent &&
              data.projectorContent(questionImages)
          }

          <CanvasButton
            text={'К вопросам'}
            onClick={() => {containerComp.scenarioManager.selectStepByKey('theory questions')}}
            fontSize={20}
            btnStroke={'#4f558c'}
            strokeWidth={.1}
            btnStrokeWidth={1}
            btnCornerRadius={10}
            textFill={'#4f558c'}
            btnFill={'white'}
            boxShadowColor={'transparent'}
            fontStyle={'normal'}
            width={200}
            height={40}
            x={0} y={400}
          />
        </Group>

        <Group y={460} visible={data.multipleStepsQuestion}>
          <CanvasButton
            text={'Вперед'}
            onClick={() => {containerComp.scenarioManager.next()}}
            fontSize={17}
            btnStroke={'#4f558c'}
            strokeWidth={.1}
            btnStrokeWidth={1}
            btnCornerRadius={10}
            width={100}
            height={40}
            x={550} y={0}
            disabled={data.nextQstStepDisabled}
          />
          <CanvasButton
            text={'Назад'}
            onClick={() => {containerComp.scenarioManager.back()}}
            fontSize={17}
            btnStroke={'#4f558c'}
            strokeWidth={.1}
            btnStrokeWidth={1}
            btnCornerRadius={10}
            width={100}
            height={40}
            x={430} y={0}
            disabled={data.prevQstStepDisabled}
          />
        </Group>
      </Group>
    </Group>
  )
};

export default TheoryCanvas;