import React, {useEffect, useState} from "react";
import * as actions from '../../../store/actions';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {userRoles} from '../../../utils/common';
import Header from "../components/Header";
import Body from "../components/Body";
import LessonsMenu from "../components/LessonsMenu";
import useMedia from "../../../utils/custom-hooks/useMedia";
import PresentationModal from "../components/PresentationModal";
import PresentationFormModal from "../../modal/containers/PresentationFormModal";
import {Button, Layout} from 'antd';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';


const MainPage = (props) => {
  const {
    isDemo,
    checkLogin,
    fetchMainPageData,
    isTeacher,
    openLesson,
    changeChangePaymentVisible,
    presentationModalVisible,
    isPresentationActive,
  } = props;

  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  useEffect(() => {
    fetchMainPageData(isDemo);

    if (!isDemo) { checkLogin(); }
  }, []);

  useEffect(() => {
    const path = isDemo ? 'demo' : 'main';
    if (props.activeLessonId) {
      props.history.push(`/${path}/${props.activeLessonId}`);
    }
  }, [props.activeLessonId]);

  const isXLView = useMedia(['(max-width: 1200px)'],[true], false);

  useEffect(() => {
    setSidebarCollapse(isXLView);
  }, [isXLView]);

  const styles = getStyles(sidebarCollapse);


  const selectLesson = (lesson) => {
    // if ((isTeacher || (isTeacher && isLessonSession)) || isDemo) {
      changeChangePaymentVisible(false);
      openLesson(lesson);
    // }
  };

  const switchLessonMenuSideVisible = () => {
    setSidebarCollapse(!sidebarCollapse);
    // window.dispatchEvent(new Event('resize'));
  };

  return (
    <div style={styles.mainContainer}>
      <Header collapsed={sidebarCollapse} isDemo={isDemo}/>

      <Layout style={styles.bodyContainer}>
        <Layout.Sider
          width={250}
          theme={'light'}
          collapsed={sidebarCollapse}
          collapsedWidth={0}
        >
          <div style={styles.collapseTriggerWrapper}>
            <Button
              type={'primary'}
              onClick={switchLessonMenuSideVisible}
              style={styles.collapseTrigger}
            >
              { sidebarCollapse ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          </div>
          <LessonsMenu
            isDemo={isDemo}
            selectLesson={selectLesson}
          />
        </Layout.Sider>

        <Body
          isDemo={isDemo}
          sidebarCollapse={sidebarCollapse}
        />

      </Layout>
      {/*{*/}
      {/*  ((paymentModalVisible && !activeLessonAccess) && !isTeacher) &&*/}
      {/*    <PaymentModal />*/}
      {/*}*/}
      {
        (presentationModalVisible || (isPresentationActive && !isTeacher)) &&
          <PresentationModal />
      }

      <PresentationFormModal />
    </div>
  )
};

const mapStateToProps = (state, {match}) => {
  const user = state.lessonsReducer.user;
  const isTeacher = user && user.role === userRoles.teacher;
  const activeLesson = state.lessonsReducer.activeLesson;

  const description = activeLesson && activeLesson.description && Object.keys(activeLesson.description).length ? activeLesson.description.text : '';

  const lsnId = match.params.id;
  return {
    lang: state.lessonsReducer.lang,
    lessonSessionToken: state.lessonsReducer.lessonSession.token,
    isPresentationActive: state.lessonsReducer.presentation.active,
    isTeacher: isTeacher,
    activeLessonId: activeLesson ? activeLesson.id : lsnId,
    lessonDescription: description,
    paymentModalVisible: state.commonReducer.paymentModalVisible,
    successModalVisible: state.commonReducer.successModalVisible,
    failureModalVisible: state.commonReducer.failureModalVisible,
    presentationModalVisible: state.commonReducer.presentationModalVisible,
    presentationFormModalVisible: state.commonReducer.presentationFormModalVisible
  };
};

const mapDispatchToProps = (dispatch, {match}) => {
  const lsnId = Number(match.params.id);
  return ({
    logoutAction: () => dispatch(actions.logoutAction()),
    checkLogin: () => dispatch(actions.checkLogin()),
    fetchMainPageData: (isDemo) => dispatch(actions.fetchMainPageData(isDemo, lsnId)),
    openLesson: (lesson) => dispatch(actions.openLesson(lesson)),
    changeChangePaymentVisible: (status) => dispatch(actions.changeChangePaymentVisible(status)),
    getLessonActivityLog: () => dispatch(actions.getLessonActivityLog()),
  });
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainPage));


const getStyles = (sidebarCollapse) => ({
  leftBlock: {
    flexShrink: 0,
    width: '250px',
    height: '85vh',
    overflow: 'auto'
  },
  mainContainer: {
    height: '100vh',
    // overflow: 'hidden',
  },
  bodyContainer: {
    display: 'flex',
    height: '100%'
  },


  collapseTrigger: {
    height: 'auto',
    fontSize: '1.5em',
    padding: '0 7px'
  },
  collapseTriggerWrapper: {
    padding: '5px',
    position: 'absolute',
    zIndex: 110
  },

});
