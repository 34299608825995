import React from "react";
import { Image, Rect, Group, Circle } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import baseImg from "../../../../images/scales/base.png";
import leverImg from "../../../../images/scales/lever.png";
import platformImg from "../../../../images/scales/platform.png";


class CanvasScaleWithBowls extends React.Component {
  constructor(props) {
    super(props);

    this.staticData = {
      maxAngle: 10 || this.props.maxAngle,
    }

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      platformOffsetX: 0,
      leftPlatformOffsetY: 0,
      rightPlatformOffsetY: 0,
      leverLineRotation: 0,
    };
    this.data = cloneDeep(this.initialData);
  }

  get leverLineRotation() {
    const props = this.props;
    const {maxAngle} = this.staticData;

    const isRightWeightHeavier = props.checkRightWeight();
    const isLeftWeightHeavier = props.checkLeftWeight();
    let res = this.data.leverLineRotation;

    if (isRightWeightHeavier) {
      const newRes = res - .2;
      res = newRes <= -maxAngle ? -maxAngle : newRes;
    }
    if (isLeftWeightHeavier) {
      const newRes = res + .2;
      res = newRes >= maxAngle ? maxAngle : newRes;
    }
    if (!isRightWeightHeavier && !isLeftWeightHeavier) {
      res = res > 0.1 ? res-.1 : res < -0.1 ? res+.1 : 0;
    }
    return res;
  }

  _ref = (key) => this.props._ref(key);

  _getNode = (key) => this.props._getNode(key);

  componentDidMount() {
    this.props.getMovingCallback({[this.props.id]: this.move});
  }

  move = (time) => {
    const data = this.data;

    data.leverLineRotation = this.leverLineRotation;
    data.platformOffsetX = Math.abs(data.leverLineRotation/30);
    data.leftPlatformOffsetY = data.leverLineRotation * 4;
    data.rightPlatformOffsetY = -data.leverLineRotation * 4;

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const scaleNum = this.props.scaleNum || 1;
    let leftPlatformOffset = this._getNode('leftPlatform')?.offset({
      x: data.platformOffsetX*scaleNum,
      y: data.leftPlatformOffsetY*scaleNum
    });
    let rightPlatformOffset = this._getNode('rightPlatform')?.offset({
      x: data.platformOffsetX*scaleNum,
      y: data.rightPlatformOffsetY*scaleNum
    });
    let leverLineRotation = this._getNode('leverLine')?.rotation(data.leverLineRotation);

  }


  Scales = (props) => {
    const [base] = useImage(baseImg);
    const [lever] = useImage(leverImg);
    const [platform] = useImage(platformImg);
    const scaleNum = this.props.scaleNum || 1;
    return (
      <Group {...props}>
        <Group>
          <Image x={-16*scaleNum} image={base} scale={{x:scaleNum,y:scaleNum}}/>
          <Image ref={this._ref('leverLine')} image={lever} offset={{x: 221, y: 4}} rotation={0} scale={{x:scaleNum,y:scaleNum}}/>

          <Group ref={this._ref('leftPlatform')}>
            <Group x={-370*scaleNum} y={-2*scaleNum}>
              <Circle x={153*scaleNum} y={3*scaleNum} radius={9*scaleNum} fill={'#4a6c97'}/>
              <Rect ref={this._ref('leftDropPlace')} y={30*scaleNum} width={306*scaleNum} height={130*scaleNum} fill={'black'} opacity={0}/>
              <Image image={platform} scale={{x:scaleNum,y:scaleNum}}/>
            </Group>
          </Group>

          <Group ref={this._ref('rightPlatform')}>
            <Group x={65*scaleNum} y={-2*scaleNum}>
              <Circle x={153*scaleNum} y={3*scaleNum} radius={9*scaleNum} fill={'#4a6c97'}/>
              <Rect ref={this._ref('rightDropPlace')} y={30*scaleNum} width={306*scaleNum} height={130*scaleNum} fill={'black'} opacity={0}/>
              <Image image={platform} scale={{x:scaleNum,y:scaleNum}}/>
            </Group>
          </Group>
        </Group>
      </Group>
    )
  }

  render() {
    return (
      <Group x={this.props.x} y={this.props.y}>
        <this.Scales />
      </Group>
    )
  }
}


export default CanvasScaleWithBowls;
