import { Image } from "react-konva";
import React from "react";
import {pxPerMetre} from "../settings";

export const CanvasSpaceShip = (props) => {
  const {
    shipImage, rotation,
    width=50 / 17.5, // In metres
    height=110 / 17.5,
  } = props;

  return(
    <Image
      image={shipImage}
      width={width * pxPerMetre}
      height={height * pxPerMetre}
      offsetX={width * pxPerMetre/2}
      offsetY={height * pxPerMetre/2}
      rotation={rotation}
      preventDefault={false}
    />
  )
};
