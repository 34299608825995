import React, {useEffect, useState} from "react";
import HtmlContentRender from "../../components/HtmlContentRender/HtmlContentRender";
import {Button, Spin} from "antd";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import GoBack from "./GoBack";
import {InfoCircleOutlined} from "@ant-design/icons";


const TeacherCard = (props) => {
  const {cardId, fetchTeacherCard, lessonData, setLessonVariant} = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  useEffect(() => {
    (async function () {
      setLoading(true);
      const resp = await fetchTeacherCard(cardId);
      if (resp?.data) {
        setData(resp.data);
      }
      setLoading(false);
    })()
  }, [])

  const FeedbackBtn = () => (
    <Button
      className={'text-decoration'}
      target={'blank'}
      type="ghost"
      style={{fontSize: '15px', padding: 0, color: 'gray', margin: '10px 0', border: '0', boxShadow: 'none', ...props.styles}}
      shape="round"
      icon={<InfoCircleOutlined />}
      onClick={() => props.setFeedbackPopupVisible(true)}
    >
      Нашли ошибку? Напишите нам
    </Button>
  )
  return (
    <div>
      <GoBack
        top={'3.3%'} left={'3%'}
        lessonData={lessonData}
        setLessonVariant={setLessonVariant}
      />

      <Spin spinning={loading} size={'large'} style={{minHeight: '100px'}}>
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
          <div style={{margin: '5% 0', padding: '40px 20px 0', width: '100%', maxWidth: '900px'}}>
            <FeedbackBtn styles={{margin: '0 0 5px'}}/>
            <h1 style={{fontWeight: 'bold', fontSize: '25px'}}>{lessonData.title}</h1>
            {
              data && (
                <HtmlContentRender htmlForRender={data}/>
              )
            }
            <FeedbackBtn styles={{margin: '20px 0'}}/>
          </div>
        </div>
      </Spin>
    </div>
  )
}



const mapStateToProps = (state, {match}) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTeacherCard: (id) => dispatch(actions.fetchTeacherCard(id)),
    setFeedbackPopupVisible: (visible) => dispatch(actions.setFeedbackPopupVisible(visible))
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeacherCard);
