import React, {useRef, useState} from "react";
import SchoolTaskContainer from "../components/SchoolTaskContainer";
import bgImg from '../images/bg.png';
import SchoolTaskCard from "../components/SchoolTaskCard";
import img1 from '../images/continueRow/row1.png';
import img2 from '../images/continueRow/row2.png';
import img3 from '../images/continueRow/row3.png';
import dragItemImg1 from '../images/continueRow/dragItem1.png';
import dragItemImg2 from '../images/continueRow/dragItem2.png';
import dragItemImg3 from '../images/continueRow/dragItem3.png';
import dragItemImg4 from '../images/continueRow/dragItem4.png';
import '../styles/continueRowTask.css';
import {DropTarget} from "react-drag-drop-container";



const defaultRowItems = [
  {id: 1, image: img1},
  {id: 2, image: img2},
  {id: 3, image: img3},
]
const defaultDragItems = [
  {id: 1, image: dragItemImg1},
  {id: 2, image: dragItemImg2},
  {id: 3, image: dragItemImg3},
  {id: 4, image: dragItemImg4, success: true},
]


const ContinueRowTask = (props) => {
  const [answer, setAnswer] = useState(undefined);
  const [resetCardsCallback, setResetCardsCallback] = useState([]);

  const rowItems = props.rowItems || defaultRowItems
  const dragItems = props.dragItems  || defaultDragItems

  const answerFail = answer !== undefined && !answer.success;
  const answerSuccess = answer !== undefined && answer.success;

  let droppedEl;
  let draggedEl;

  const getCardReset = (callback) => setResetCardsCallback(prev=>[...prev, callback]);
  const reset = () => {
    draggedEl=undefined;
    droppedEl=undefined;
    setAnswer(undefined);
  }

  const taskComplete = () => {
    props.taskComplete && props.taskComplete();
  }

  return (
    <SchoolTaskContainer
      failMessage={answerFail}
      lang={props.lang}
      bgImg={props.bgImg || bgImg}
      title={props.title || ''}
      tryAgain={answerFail}
      success={answerSuccess}
      continueClassName={'continue-row-task__btn-continue'}
      onClickRestart={reset}
      onClickContinue={taskComplete}
    >
      <div className={'continue-row-task__items'}>
        {
          rowItems.map((el, i) => {
            return (
              <SchoolTaskCard
                index={i}
                key={el.id}
                id={el.id}
                img={el.image}
                mainText={el.mainText}
                className={`continue-row-task__item`}
              />
            )
          })
        }
        <DropTarget targetKey="targetKey">
          <div className={'continue-row-task__drop-item'}>
            {
              answer ? (
                <SchoolTaskCard
                  fail={answerFail}
                  success={answerSuccess}
                  img={answer.image}
                  mainText={answer.mainText}
                  className={`continue-row-task__item--added`}
                />
              ) : null
            }
          </div>
        </DropTarget>
      </div>
      <div className={'continue-row-task__drag-items'}>
        <div className={'continue-row-task__drag-items-container'}>
          {
            dragItems.map((el, i) => {
              const addedAnswer = answer?.id === el.id;
              return (
                <React.Fragment key={el.id}>
                  {
                    !addedAnswer ? (
                      <SchoolTaskCard
                        draggable={!answer}
                        smallSize={true}
                        getCardReset={getCardReset}
                        index={i}
                        id={el.id}
                        img={el.image}
                        mainText={el.mainText}
                        className={`continue-row-task__item continue-row-task__drag-item`}
                        onDrag={(e) => {}}
                        onDragStart={(e) => {
                          draggedEl = e.target;
                        }}
                        onDragEnd={(e) => {
                          droppedEl = e.target;
                          draggedEl = undefined;
                        }}
                        onDrop={(data) => {
                          const currDragItem = dragItems.find(el => el.id === data.dragData.id)
                          if (currDragItem) {
                            setAnswer(currDragItem);
                          }
                        }}
                      />
                    ) : null
                  }
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
    </SchoolTaskContainer>
  )
}
export default ContinueRowTask;
