import React from "react";
import '../styles/resultBar.css';


const ResultBar = (props) => {

  return (
    <div className={'result-bar'}>
      {
        Array(props.tasksCount).fill(1).map((el, i) => (
          <div key={i} className={`result-bar__item ${props.completeCount > i ? 'result-bar__item--complete' : ''}`}/>
        ))
      }
    </div>
  )
}
export default ResultBar;
