import React, {PureComponent, createRef} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions';
import moment from 'moment';
import {lessonStatus} from '../../../../utils/common';

import { Layer, Rect, Group, Text } from 'react-konva';
import CanvasContainer from '../../../canvas/containers/CanvasContainer';
import Distance from '../components/Distance';
import MeasurerBlock from '../components/MeasurerBlock';

import AirBalloon from '../data/AirBalloon';
import Track from '../data/Track';

import {_t} from "../../../../utils/lang/common";

class BalloonComponent extends PureComponent {
  fire = false;
  working = false;
  first = true;

  stageRef = createRef();
  airBalloonRef = createRef();
  fireRef = createRef();
  // airBalloon__DEV__rectRef = createRef();
  trackRef = createRef();
  distanceRef = createRef();
  measurerBlockRef = createRef();

  constructor(props) {
    super(props);
    this.refresh();
  }

  refresh = () => {
    this.airBalloon = new AirBalloon();
    this.track = new Track();
    this.horizontalVelocity = BalloonComponent.#options.initialHorizontalVelocity;
    this.lastMoveTime = null;
  };

  componentWillUnmount() {
    this.stop();
  }

  calcHorizontalVelocity = (timeDelta) => {
    const {horizontalAcceleration} = BalloonComponent.#options;
    return (this.horizontalVelocity += horizontalAcceleration * timeDelta);
  };

  move = (currentTime) => {
    this.requestId = window.requestAnimationFrame(this.move);
    if (currentTime === undefined) return;

    const {timeCoef, temperatureCoef, targetDistance} = BalloonComponent.#options;

    this.lastMoveTime = this.lastMoveTime ?? currentTime;
    const timeDelta = (currentTime - this.lastMoveTime) * timeCoef;
    this.lastMoveTime = currentTime;

    this.airBalloon.move({
      timeDelta,
      extraTemperatureVelocity: this.fire ? temperatureCoef : 0,
    });
    this.track.move(timeDelta * this.calcHorizontalVelocity(timeDelta));

    this.redraw();

    if (this.track.intersection(this.airBalloon.figure)) this.stop();
    if (this.calcMeters().meters >= targetDistance) this.stop({good: true});
  };
  redraw = () => {
    const stageNode = this.stageRef.current;
    const airBalloonNode = this.airBalloonRef.current;
    const fireNode = this.fireRef.current;
    // const airBalloon__DEV__rectNode = this.airBalloon__DEV__rectRef.current;
    const trackNode = this.trackRef.current;
    const distanceNode = this.distanceRef.current;
    const measurerBlockNode = this.measurerBlockRef.current;
    const ballastNodeExist = this.airBalloon.fallingBallastList.reduce((prev, {ref}) => prev || !(ref?.current), false);
    if (
      !stageNode
      || !airBalloonNode
      || !fireNode
      // || !airBalloon__DEV__rectNode
      || !trackNode
      || !distanceNode
      || !measurerBlockNode
      || ballastNodeExist
    ) return;

    airBalloonNode.y(this.airBalloon.y);
    fireNode.visible(this.fire);
    // airBalloon__DEV__rectNode.y(this.airBalloon.__DEV__rectY);
    trackNode.x(this.track.deltaX);
    distanceNode.text(_t('balloon.distance', {amount: this.distance}));
    measurerBlockNode.move(this.airBalloon);
    this.airBalloon.fallingBallastList.forEach((ballastPhysics) => {
      const ballastNode = ballastPhysics.ref.current;
      ballastNode.y(this.airBalloon.calcFallingBallastY(ballastPhysics));
    });

    stageNode.draw();
  };

  start = () => {
    if (!this.first) {
      this.refresh();
      this.forceUpdate();
    } else {
      this.first = false;
    }
    this.working = true;
    this.move();
  };
  stop = ({good = false} = {}) => {
    const {
      activeLesson,
      updateLessonStatus,
      updateActiveLesson,
      changeSuccessVisible,
      changeFailureVisible,
    } = this.props;

    this.working = false;
    window.cancelAnimationFrame(this.requestId);
    this.forceUpdate();

    if (activeLesson) updateLessonStatus(good ? lessonStatus.SUCCESS : lessonStatus.FAIL, activeLesson.id);
    (good ? changeSuccessVisible : changeFailureVisible)(true);
    updateActiveLesson({'finish_time': moment().utc()});
  }
  turnFireOn = () => {
    this.fire = true;
    if (!this.working) this.start();
  };
  turnFireOff = () => (this.fire = false);

  calcMeters = () => {
    const {metersAccuracy} = BalloonComponent.#options;
    const accuracy = Math.pow(10, metersAccuracy);

    return {
      accuracy: metersAccuracy,
      meters: (- Math.round((accuracy / 10) * this.track.deltaX) / accuracy),
    };
  }
  get distance() {
    let {meters, accuracy} = this.calcMeters();
    meters = meters.toString();

    const lettersAfterSeparator = meters.split('.')?.[1]?.length ?? 0;

    if (lettersAfterSeparator === 0) meters += '.';
    for (let i = accuracy; i > lettersAfterSeparator; i--) meters += '0';
    return meters;
  }

  dropBallast = () => {
    this.airBalloon.dropBallast();
    if (!this.airBalloon.physics.ballastAmount) this.forceUpdate();
    if (!this.working) this.start();
  };

  render() {
    const {styles} = BalloonComponent;

    const dropBallastDisabled = this.airBalloon.physics.ballastAmount <= 0;

    return (
      <div style={styles.Container}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code} component={this}>
          <Layer preventDefault={false}>
            <this.airBalloon.Component
              reffer={this.airBalloonRef}
              fireRef={this.fireRef}
              // __DEV__rectRef={this.airBalloon__DEV__rectRef}
            />
            <this.track.Component
              reffer={this.trackRef}
            />
            <MeasurerBlock
              x={25}
              y={25}
              ref={this.measurerBlockRef}
              airBalloon={this.airBalloon}
            />

            <Group x={100} y={420}>
              <Group
                onTouchStart={this.turnFireOn}
                onTouchEnd={this.turnFireOff}
                onMouseDown={this.turnFireOn}
                onMouseUp={this.turnFireOff}
              >
                <Rect width={140} height={25} fill={'#E97D62'} cornerRadius={5} />
                <Text width={140} height={25} align={'center'} verticalAlign={'middle'} fontSize={24} fill={'white'}
                      text={this.working || this.first ? _t('balloon.torch') : _t('balloon.repeat')}/>
              </Group>
              {!dropBallastDisabled ? <Group
                y={40}
                onClick={this.dropBallast}
                onTap={this.dropBallast}
              >
                <Rect width={140} height={25} fill={'#E97D62'} cornerRadius={5} />
                <Text width={140} height={25} align={'center'} verticalAlign={'middle'} fontSize={24} fill={'white'}
                      text={_t('balloon.drop_a_load')}/>
              </Group> : null}
            </Group>


            <Distance
              reffer={this.distanceRef}
              style={styles.Distance}
              amount={this.distance}
            />
          </Layer>
        </CanvasContainer>

        {/*<button*/}
        {/*  style={{...styles.Button, ...styles.Button__fire}}*/}
        {/*  onTouchStart={this.turnFireOn}*/}
        {/*  onTouchEnd={this.turnFireOff}*/}
        {/*  onMouseDown={this.turnFireOn}*/}
        {/*  onMouseUp={this.turnFireOff}*/}
        {/*>*/}
        {/*  {this.working || this.first ? _t('balloon.torch') : _t('balloon.repeat')}*/}
        {/*</button>*/}
        {/*{!dropBallastDisabled ? (*/}
        {/*  <button*/}
        {/*    style={{...styles.Button, ...styles.Button__ballast}}*/}
        {/*    onClick={this.dropBallast}*/}
        {/*  >*/}
        {/*    {_t('balloon.drop_a_load')}*/}
        {/*  </button>*/}
        {/*) : null}*/}
      </div>
    );
  }

  static #options = {
    initialHorizontalVelocity: -0.23,
    horizontalAcceleration: -0.001,
    timeCoef: 1 / 25,
    temperatureCoef: 2,
    metersAccuracy: 1,
    targetDistance: 1000,
  };

  static styles = {
    Container: {
      height: '100%',
      overflow: 'hidden',
      backgroundColor: '#f1faee',
    },

    Distance: {
      position: 'absolute',
      right: 25,
      top: 25,
    },

    Button: {
      position: 'absolute',
      width: '20%',
      left: '3%',
      zIndex: 10,
      borderWidth: 0,
      backgroundColor: 'rgba(255, 99, 71, 0.8)',
      color: 'white',
      fontSize: '2.3vmin',
      fontWeight: 'bold',
      cursor: 'pointer',
      borderRadius: 10,
    },

    Button__fire: {
      bottom: '37%',
    },
    Button__ballast: {
      bottom: '30%',
    },
  };
}

const mapStateToProps = (state) => ({
  activeLesson: state.lessonsReducer.activeLesson,
});

const mapDispatchToProps = (dispatch) => ({
  updateLessonStatus: (status, lessonId, method = 'status') => dispatch(actions.updateLessonStatus(status, lessonId, method)),
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  updateActiveLesson: (data) => dispatch(actions.updateActiveLesson(data)),
});

const Balloon = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BalloonComponent);

export default Balloon;
