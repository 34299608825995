import React from "react";
import {Rect} from "react-konva";
import cloneDeep from 'lodash.clonedeep';

/**
 *  PROPS
 *
 *  getExplosion: f() => bool
 *  getRectProps: f() => obj (x, y, height ...)
 *
 */

class Explosion extends React.Component {
  constructor(props) {
    super(props);
    this.explosionRef = React.createRef();

    this.staticData = {
      colors: [
        // '#FFEABD', '#FFD48F', '#ffc194', '#ffa277',
        'rgba(255,98,76,0.3)', 'rgba(255,63,58,0.3)', 'rgba(255,63,58,0.3)',
      ],
    };
    this.initialData = {
      colorIndex: 0,
      startTime: undefined,
      prevTime: undefined,
    };
    this.data = cloneDeep(this.initialData);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  move = (time) => {
    const props = this.props;
    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);
    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = timedelta/1000;

    const lastIndex = this.staticData.colors.length - 1;
    const explosion = props.getExplosion();


    if ((data.colorIndex < lastIndex) && explosion)
      data.colorIndex += 1;

    if (data.colorIndex >= lastIndex)
      data.colorIndex = 0;

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const staticData = this.staticData;
    const explosionNode = this.explosionRef?.current;
    const rectProps = this.props.getRectProps();
    if (!explosionNode) {
      return
    }
    explosionNode.height(rectProps.height);
    explosionNode.fill(staticData.colors[data.colorIndex]);
    explosionNode.opacity(Number(this.props.getExplosion()));
  }

  render() {
    return (
      <Rect ref={this.explosionRef} {...this.props.getRectProps()}/>
    )
  }
}

export default Explosion;
