import React from "react";
import {
  getFilterStudentsByChapter,
  getFilterStudentsByGrades,
  getFilterMeasure, getFilterStudentsByPeriod,
  getFilterRows
} from "../../../utils/tableFilters";
import {layout2} from "../../../utils/styles";
import moment from "moment";
import {Badge, Tooltip} from "antd";
import {ExclamationCircleFilled, ExclamationCircleOutlined} from '@ant-design/icons'

const defaultPeriod = {
  start: "2021-07-10T19:00:00.000Z",
  end: "2021-07-31T01:00:00.000Z",
};

/**
 * @period: {start: string, end: string}
 * @fragmentation: 'by_weeks' | 'by_months' | 'by_homeworks'
 */
export const getDateByPeriod = (period, fragmentation) => {
  if (!period.start && !period.end) {
    return [];
  }
  const startDate = moment(period.start).startOf('day');
  const endDate = moment(period.end).startOf('day');

  const fragmentationByWeeks = !fragmentation || fragmentation === 'by_weeks';
  const fragmentationByMonths = fragmentation === 'by_months';

  // Выбираем подходящий ключ в зависимости от выбранного пользователем разбиения (фрагментации)
  let fragmentationKey = 'week';
  if (fragmentationByMonths) {
    fragmentationKey = 'month'
  }
  // Проверяем, является ли дата начала периода, началом фрагмента (недели / месяца)
  const startDateIsFirstOfFragment = startDate.clone().startOf(fragmentationKey).isSame(startDate);

  const newDateArr = new Array(endDate.diff(startDate, 'days')+1).fill(1);
  return newDateArr
    .reduce((accum, v, index) => {
      const isFirstDay = !index;
      const currentDate = startDate.clone().add(index, 'days'); // День от индекса выбранного пользователем промежутка
      const prevDay = startDate.clone().add(index-1, 'days'); // Предыдущий день промежутка (для получения информации о фрагментах (неделе/месяце))

      let prevFragmentNumb;      // Предыдущий номер фрагмента (недели / месяца)
      let newFragmentOfDate;     // Флаг, показывающий начался ли новый фрагмент (недели / месяца)
      let resDate = currentDate; // Результирующая дата (текущая / начало недели / начало месяца)

      // Получаем номер фрагмента (недели / месяца) предыдущего дня
      prevFragmentNumb = prevDay[fragmentationKey]?.();

      // Определяем начался ли новый фрагмент (недели / месяца)
      // путем сравнения номера текущего фрагмента и номером прошлого
      newFragmentOfDate = prevFragmentNumb !== currentDate[fragmentationKey]?.();

      // Если начался новый фрагмент (неделя / месяц), то считаем дату результирующей, для текущей итерации
      if (newFragmentOfDate) {
        resDate = currentDate.clone().startOf(fragmentationKey);
      }

      if (
        isFirstDay // стартовая дата входит в любом случае
        || newFragmentOfDate // если начался новый фрагмент (неделя / месяц)
      ) {
        // Проверяем выбранный конец периода с последним днем фрагмента, если выбранный день
        // не является концом недели, значит выбрана не полная неделя
        const lasDayOfWeek = resDate.clone().endOf(fragmentationKey).startOf('day');
        const lastFragmentNotComplete = endDate.isBefore(lasDayOfWeek);

        const isNotCompleteFragment = (isFirstDay && !startDateIsFirstOfFragment) || lastFragmentNotComplete;
        accum.push({date: resDate, isNotCompleteFragment});
      }

      return accum;
    }, []);
}

export const getDynamicsColumns = ({periodFillerDate, periodFillerFragmentation, homeworksByPeriod}) => {
  let res = [];

  res.push({
    title: '',
    dataIndex: 'rowName',
    key: 'student',
    fixed: 'left',
    width: '200px',
    render: text => <span style={{fontSize: '18px'}}>{text}</span>
  });

  let dates = getDateByPeriod(periodFillerDate, periodFillerFragmentation);
  let fragmentation = 'Неполная неделя';
  if (periodFillerFragmentation === 'by_months')
    fragmentation = 'Неполный месяц';


  if (['by_months', 'by_weeks'].includes(periodFillerFragmentation)) {
    dates.forEach((el, i) => {
      const date = el.date.format('DD.MM');
      res.push({
        title: (
          <span>
          {date}
            {el.isNotCompleteFragment && (periodFillerFragmentation !== 'by_homeworks') ?
              <Tooltip title={fragmentation}>
                <ExclamationCircleFilled style={{color: '#FF9737', marginLeft: '5px'}}/>
              </Tooltip>
              : null}
        </span>
        ),
        dataIndex: `column-${date}`,
        key: `column-${date}`,
        width: '100px',
      });
    });
  } else {
    homeworksByPeriod &&
    homeworksByPeriod.forEach((hw, i) => {
      res.push({
        title: (
          <span>
            {hw.title}
          </span>
        ),
        dataIndex: `column-${hw.id}`,
        key: `column-${hw.id}`,
        width: '100px',
      });
    });
  }
  return res;
}
export const getDynamicsRows = (dynamicsTableData) => {
  return dynamicsTableData?.map(rowData => {
    const rowHtml = (
      <div key={rowData.key} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <span style={{color: layout2.blue2}}>{`${rowData.rowName}`}</span>
      </div>
    )
    const dateItems = Object.keys(rowData)
      .filter(k => k.includes('column-'))
      .reduce((accum, key, i) => {
        accum[key] = rowData[key] || rowData[key] === 0 ? `${rowData[key]} %` : '';
        return accum
      }, {})
    return {
      ...rowData,
      ...dateItems,
      rowName: rowHtml
    }
  })
}

export const getDynamicsConfigFilter = (gradesArr, setFilterStateFunc) => {
  return [
    getFilterStudentsByGrades(gradesArr, false, setFilterStateFunc),
    getFilterRows(setFilterStateFunc),
    getFilterStudentsByPeriod(setFilterStateFunc),
    getFilterMeasure(setFilterStateFunc),
  ];
}









export const getProgressTableColumns = (groups=[]) => {
  let res = [];
  res.push({
    title: '',
    dataIndex: 'rowName',
    key: 'student',
    fixed: 'left',
    width: 200,
    render: text => <span style={{fontSize: '18px'}}>{text}</span>
  });
  groups.forEach((group, i) => {
    res.push({
      title: group.title,
      dataIndex: `column-${group.id}`,
      key: `column-${group.id}`,
      width: '200px',
    });
  });
  return res;
}
export const getProgressTableRows = (progressTableData) => {
  return progressTableData?.map(rowData => {
    const rowHtml = (
      <div key={rowData.key} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <span style={{color: layout2.blue2}}>{`${rowData.rowName}`}</span>
      </div>
    )
    const groupItems = Object.keys(rowData)
      .filter(k => k.includes('column-'))
      .reduce((accum, key, i) => {
        accum[key] = rowData[key] || rowData[key] === 0 ? `${rowData[key]} %` : '';
        return accum
      }, {})
    return {
      ...rowData,
      ...groupItems,
      rowName: rowHtml
    }
  });
}
export const getProgressConfigFilter = (gradesArr, chapters, setFilterStateFunc) => {

  return [
    getFilterStudentsByGrades(gradesArr, false, setFilterStateFunc),
    getFilterRows(setFilterStateFunc),
    getFilterStudentsByChapter(setFilterStateFunc, chapters),
    getFilterMeasure(setFilterStateFunc),
  ];
}