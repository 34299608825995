import React from "react";



const FullscreenBtn = ({styles, visible, externalFullscreen, setFullScreen}) => (
  <>
    {
      visible
        ? (
          <button
            style={styles.fullscreenBtn}
            onClick={
              () => {
                let element = document.getElementById('task-container');
                if (!externalFullscreen) {
                  setFullScreen(!document.fullscreenElement);
                }
                if (!document.fullscreenElement) {
                  if (element.requestFullscreen) { // W3C API
                    element.requestFullscreen();
                  } else if (element.mozRequestFullScreen) { // Mozilla current API
                    element.mozRequestFullScreen();
                  } else if (element.webkitRequestFullScreen) { // Webkit current API
                    element.webkitRequestFullScreen();
                  } else if (element.webkitRequestFullscreen) { // Webkit current API
                    element.webkitRequestFullscreen();
                  }
                  // Maybe other prefixed APIs?
                  else {
                    alert('Open in safari,\n press the "Aa" symbol on the left of the website address' +
                      '\nand press "Hide toolbar" option to use interactive tasks in fullscreen mode on iOS');}
                } else {
                  if (document.exitFullscreen) {
                    document.exitFullscreen();
                  }
                }
              }
            }
          >
            &#x2921;
          </button>
        )
        : null
    }
  </>
)

export default FullscreenBtn;
