import React from "react";
import {layout2} from "../../../../utils/styles";
import {getLsnNameByCode} from "./common";


/** Initialize array with radius of n and queue values ahead of it */
export function pointRadiusLast(radius, length, initialArray) {
  var result = initialArray || [ radius ];
  while (result.length < length) result.unshift(0);
  return result;
}

export const getChartInitialData = (props, lsnCode) => {
  const {
    pathandspeed1,
    pathandspeed2,
    pathandspeed3,
    pathandspeed4,
    pathandspeed5,
    pathandspeed8,
    pathandspeed9,
    pathandspeed10,
    pathandspeed11,
  } = getLsnNameByCode(lsnCode);

  if (pathandspeed1) {
    return ({
      labels: [],
      datasets: [
        {
          label: props.bikerLabelA,
          align: 'right',
          data: [],
          borderColor: [layout2.orange],
          pointBorderColor: layout2.orange,
          borderWidth: 2,
          backgroundColor: ['rgba(200,0,0,0.0)'],
          pointBackgroundColor: layout2.orange,
          pointStyle: 'circle',
        },{
          label: props.bikerLabelB,
          align: 'right',
          data: [],
          borderColor: ['#2972CC'],
          pointBorderColor: '#2972CC',
          borderWidth: 2,
          backgroundColor: ['rgba(200,0,0,0.0)'],
          pointBackgroundColor: '#2972CC',
          pointStyle: 'circle',
        }
      ]
    });
  } else if (pathandspeed3 || pathandspeed4) {
    return ({
      labels: [],
      datasets: [
        {
          label: props.shipChartLabel,
          align: 'right',
          data: [],
          borderColor: ['#2972CC'],
          pointBorderColor: '#2972CC',
          borderWidth: 2,
          backgroundColor: ['rgba(200,0,0,0.0)'],
          pointBackgroundColor: '#2972CC',
          pointStyle: 'circle',
        }
      ]
    });
  } else if (pathandspeed5) {
    return ({
      labels: [],
      datasets: [
        {
          label: 'Мотоциклист',
          align: 'right',
          data: [],
          borderColor: [layout2.orange],
          pointBorderColor: layout2.orange,
          borderWidth: 2,
          backgroundColor: ['rgba(200,0,0,0.0)'],
          pointBackgroundColor: layout2.orange,
          pointStyle: 'circle',
        }
      ]
    });
  }
}
