import {
  doTraining1,
  doTraining2,
  doTraining3,
  doTraining4, doTraining5,
  moveSpace1,
  moveSpace2,
  moveSpace3,
  moveSpace6
} from "./move";
import {_t} from "../../../../utils/lang/common";
import {calcCollisionSpeeds} from "../helpers";
import Victor from "victor";

export const checkToSuccess = (shipSpeed, code, state) => {
  const step = 0.01;
  let res = false;
  if (code === 'space1')
    res = state.setShipSpeed * state.shipMass <= 1 && (state.setShipSpeed + step) * state.shipMass > 1;
  else if (code === 'space2') {
    const user_speed = calcCollisionSpeeds(Victor(state.setShipSpeed, 0), state.shipMass, state.stationMass,
      true).stationSpeed.x;
    const prev_speed = calcCollisionSpeeds(Victor(state.setShipSpeed - 0.01, 0), state.shipMass, state.stationMass,
      true).stationSpeed.x;
    res = user_speed >= 1 && prev_speed < 1;
  }
  else if (code === 'space3')
    res = state.boxCount === 13;
  else if (code === 'space4')
    res = (state.impulseIncreasePerSecond * state.engineTimeX) / state.shipMass >= 2
      && (state.impulseIncreasePerSecond * (state.engineTimeX - 0.01) / state.shipMass < 2);
  else if (code === 'space5') {
    res = (Math.abs(state.impulseIncreaseX * state.engineTimeX / state.shipMass - 2.) < 1e-6
      && Math.abs(state.impulseIncreaseY * state.engineTimeY / state.shipMass - 0.7) < 1e-4);
  }
  else if (['space6'].includes(code)) {
    res = (state.impulseIncreasePerSecond * state.engineTimeX / (state.shipMass + state.stationMass) >= 1
      && state.impulseIncreasePerSecond * (state.engineTimeX - .01) / (state.shipMass + state.stationMass) < 1);
  }
  else if (code === 'space7') {
    const setSpeed = state.impulseIncreaseX * state.engineTimeX / state.shipMass;
    const stationSpeed = calcCollisionSpeeds(Victor(setSpeed, 0), state.shipMass, state.stationMass, true).stationSpeed.x;
    res = Math.abs(stationSpeed - 1) < 5e-4
      && Math.abs(state.impulseIncreaseY * state.engineTimeY / state.shipMass - 0.6) < 3e-4;
  }
  else if (code === 'space8')
    res = Math.abs(state.impulseIncreaseY * state.engineTimeY / state.shipMass - 1) < 3e-4;

  // Training tasks
  else if (code === 'training1')
    res = Math.abs(Number(shipSpeed)) < 0.1;
  else if (code === 'training3')
    res = Number(state.setShipSpeed * state.shipMass) === 1;
  else if (code === 'training4')
    res = state.shipMass === 1430;
  else if (code === 'training5')
    res = true;
  else if (['training6', 'training7', 'training0', 'inertia1'].includes(code)) {
    const user_speed = calcCollisionSpeeds(Victor(state.setShipSpeed, 0), state.shipMass, state.stationMass,
      true).stationSpeed.x;
    const prev_speed = calcCollisionSpeeds(Victor(state.setShipSpeed - 0.01, 0), state.shipMass, state.stationMass,
      true).stationSpeed.x;
    res = user_speed >= 1 && prev_speed < 1;
  } else if (code === 'training8') {
    res = state.stationMass === 1.5;
  } else if (code === 'training9') {
    res = state.stationMass === 3.5;
  } else if (code === 'training10') {
    res = state.setStationSpeed === 2;
  } else if (code === 'training11') {
    res = state.setStationSpeed === -1;
  } else if (['training12'].includes(code)) {
    res = state.setShipSpeed === 5;
  } else if (code === 'inertia2') {
    res = state.setShipSpeed === 2;
  } else if (code === 'inertia3') {
    res = state.setShipSpeed === 3;
  }
  return res;
};

export const getNewState = (moveProps, code) => {
  let newState = {};

  if (
    ['training6', 'inertia1', 'training0',
    'training7', 'training8', 'training9',
    'training10', 'training11', 'training12',
    'inertia2', 'inertia3', 'space1', 'space2'
    ].includes(code)
  )
    newState = moveSpace1(this, moveProps);
  else if (code === 'space3')
    newState = moveSpace2(this, moveProps);
  else if (['space4','space6'].includes(code))
    newState = moveSpace3(this, moveProps);
  else if (['space5', 'space7', 'space8'].includes(code))
    newState = moveSpace6(this, moveProps);

  // Training tasks
  else if (code === 'training1')
    newState = doTraining1(this, moveProps);
  else if (code === 'training2')
    newState = doTraining2(this, moveProps);
  else if (code === 'training3')
    newState = doTraining3(this, moveProps);
  else if (code === 'training4')
    newState = doTraining4(this, moveProps);
  else if (code === 'training5')
    newState = doTraining5(this, moveProps);

  return newState;
}

export const getCorrectSettings = (code, state) => {
  let fieldSettings = '';
  let otherFieldSettings = '';
  let moreFieldSettings = '';

  if (code === 'space1') {
    fieldSettings = {
      min: 0, max: 10, step: .01,
      label: _t("spaces_ship_speed"),
      name: 'setShipSpeed',
      value: state.setShipSpeed,
    };
  } else if (code === 'space3') {
    fieldSettings = {
      min: 0, max: 20, step: 1,
      label: _t("spaces_box_count"),
      name: 'boxCount',
      value: state.boxCount,
    };
  } else if (['space6','space4'].includes(code)) {
    fieldSettings = {
      min: 0, max: 15, step: .01,
      label: _t("spaces_engine_working_time"),
      name: 'engineTimeX',
      value: state.engineTimeX,
    };
  } else if (code === 'space2') {
    fieldSettings = {
      min: 0, max: 15, step: .01,
      label: _t("spaces_ship_speed"),
      name: 'setShipSpeed',
      value: state.setShipSpeed,
    };
  } else if (code === 'space5' || code === 'space7') {
    fieldSettings = {
      min: 0, max: 15, step: .01,
      label: _t("spaces_general_thruster"),
      name: 'engineTimeX',
      value: state.engineTimeX,
    };
    otherFieldSettings = {
      min: 0, max: 15, step: .01,
      label: _t("spaces_side_thruster"),
      name: 'engineTimeY',
      value: state.engineTimeY,
    };
  } else if (code === 'space8') {
    fieldSettings = {
      min: 0, max: 15, step: .01,
      label: _t("spaces_side_thruster"),
      name: 'engineTimeY',
      value: state.engineTimeY,
    };
  }
  // Training tasks
  else if (code === 'training2') {
    fieldSettings = {
      min: 0, max: 10, step: .01,
      label: _t("spaces_ship_speed"),
      name: 'setShipSpeed',
      value: state.setShipSpeed,
    };
    otherFieldSettings = {
      min: 10, max: 3000, step: 10,
      label: _t("spaces_ship_mass"),
      name: 'shipMass',
      value: state.shipMass,
    };
  } else if (code === 'training3') {
    fieldSettings = {
      min: 0, max: 10, step: .01,
      label: _t("spaces_ship_speed"),
      name: 'setShipSpeed',
      value: state.setShipSpeed,
    };
  } else if (code === 'training4') {
    fieldSettings = {
      min: 10, max: 3000, step: 10,
      label: _t("spaces_ship_mass"),
      name: 'shipMass',
      value: state.shipMass,
    };
  } else if (code === 'training5') {
    fieldSettings = {
      min: 0, max: 10, step: .01,
      label: _t("spaces_ship_speed"),
      name: 'setShipSpeed',
      value: state.setShipSpeed,
    };
    otherFieldSettings = {
      min: 10, max: 3000, step: 10,
      label: _t("spaces_ship_mass"),
      name: 'shipMass',
      value: state.shipMass,
    };
    moreFieldSettings = {
      min: 10, max: 10000, step: 10,
      label: _t("spaces_station_mass"),
      name: 'stationMass',
      value: state.stationMass,
    };
  } else if (['training6', 'training7', 'training0', 'inertia1'].includes(code)) {
    fieldSettings = {
      min: 0.01, max: 15, step: .01,
      label: _t("spaces_ship_speed"),
      name: 'setShipSpeed',
      value: state.setShipSpeed,
    };
  } else if (code === 'training8') {
    fieldSettings = {
      min: 0.01, max: 15, step: .01,
      label: _t("spaces_station_mass"),
      name: 'stationMass',
      value: state.stationMass,
    };
  } else if (code === 'training9') {
    fieldSettings = {
      min: 0.01, max: 15, step: .01,
      label: _t("spaces_station_mass"),
      name: 'stationMass',
      value: state.stationMass,
    };
  } else if (code === 'training10') {
    fieldSettings = {
      min: 0.01, max: 15, step: .01,
      label: _t("spaces_station_speed"),
      name: 'setStationSpeed',
      value: state.setStationSpeed,
    };
  } else if (code === 'training11') {
    fieldSettings = {
      min: 0.01, max: 15, step: .01,
      label: _t("spaces_station_speed"),
      name: 'setStationSpeed',
      value: state.setStationSpeed,
    };
  } else if (['training12', 'inertia2', 'inertia3'].includes(code)) {
    fieldSettings = {
      min: 0.01, max: 15, step: .01,
      label: _t("spaces_ship_speed"),
      name: 'setShipSpeed',
      value: state.setShipSpeed,
    };
  }
  return { fieldSettings, otherFieldSettings, moreFieldSettings }
}

