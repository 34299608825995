import {nanoid} from 'nanoid';

class Key {
  constructor({key, size} = {}) {
    if (key instanceof Key) {
      this.string = key.string;
    } else if (key !== undefined) {
      this.string = key;
    } else {
      this.string = nanoid(Key.#checkSize(size) ? size : Key.#size);
    }

    this.#defineProperties();
  }

  #defineProperties = () => {
    const props = {
      equal: (another) => another instanceof Key && this.string === another.string,
      equalOneOf: (keyList) =>
        keyList instanceof Array && keyList.reduce((acc, item) => acc || this.equal(item), false),
      equalAllOf: (keyList) => keyList instanceof Array && keyList.reduce((acc, item) => acc && this.equal(item), true),

      toString: () => '' + this,
      valueOf: () => +this,
    };

    for (let key in props) {
      Object.defineProperty(this, key, {
        value: props[key],
        writable: true,
        enumerable: false,
        configurable: true,
      });
    }
  };

  [Symbol.toPrimitive] = (hint) => (hint === 'number' ? NaN : this.string);

  static #size = 32;
  static #checkSize = (size) => typeof size === 'number' && !isNaN(size) && size > 0 && size !== Infinity;
}

export default Key;
