import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ChooseExtraItemTask from "./ChooseExtraItemTask";
import imgTrue from "../images/сhooseExtraItemTask/scarecrow_true.png";
import imgFalse from "../images/сhooseExtraItemTask/scarecrow_false.png";


const Example10Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);


  const step1Items = [
    {id: 1, image: imgTrue},
    {id: 2, image: imgFalse, success: true},
  ]
  const step2Items = [
    {id: 1, image: imgTrue},
    {id: 2, image: imgFalse, success: true},
  ]
  const step3Items = [
    {id: 1, image: imgTrue, success: true},
    {id: 2, image: imgFalse},
  ]
  const step4Items = [
    {id: 1, image: imgTrue},
    {id: 2, image: imgFalse, success: true},
  ]


  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={4}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      {
        completedTaskCount < 1 ?
          <ChooseExtraItemTask
            key={'step1'}
            title={<>There are two scarecrows in front of you. Their names are True and False. <br/>
            True always tells the truth. False always lies. Press the scarecrow which lies.</>}
            titleFontSize={'2.8em'}
            items={step1Items}
            taskComplete={() => {
              setCompletedTaskCount(prevState => prevState + 1);
            }}
          />
        : completedTaskCount === 1 ?
          <ChooseExtraItemTask
            key={'step2'}
            title={<>“All machines have some moving parts” <br/>Who said that?</>}
            titleFontSize={'2.8em'}
            items={step2Items}
            taskComplete={() => {
              setCompletedTaskCount(prevState => prevState + 1);
            }}
          />
        : completedTaskCount === 2 ?
          <ChooseExtraItemTask
            key={'step3'}
            title={<>“Ramps help us to lift weights” <br/>Who said that?</>}
            titleFontSize={'2.8em'}
            items={step3Items}
            taskComplete={() => {
              setCompletedTaskCount(prevState => prevState + 1);
            }}
          />
        :completedTaskCount >= 3 ?
          <ChooseExtraItemTask
            key={'step4'}
            title={<>“It is easier to lift a weight using a shorter ramp” <br/>Who said that?</>}
            titleFontSize={'2.8em'}
            items={step4Items}
            taskComplete={() => {
              setAllComplete(true);
              setCompletedTaskCount(prevState => prevState + 1);
            }}
          />
        : null
      }
    </TaskMainContainer>
  )
}
export default Example10Task;
