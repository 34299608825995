import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import Card from '../../../canvas/components/Card';
import CanvasInput from '../../../canvas/components/CanvasInput';
import * as actions from "../../../../store/actions";
import anime from "animejs";
import {connect} from "react-redux";
import {_t} from "../../../../utils/lang/common";


import sheaveGrayImg from '../../../../images/gearRatios/sheaveGray.png';
import sheaveImg from '../../../../images/gearRatios/sheave.png';
import gatesImg from '../../../../images/gearRatios/gates.png';
import castleImg from '../../../../images/gearRatios/castle.png';
import wallImg from '../../../../images/gearRatios/wall.png';
import towerImg from '../../../../images/gearRatios/tower.png';
import handleImg from '../../../../images/gearRatios/handle.png';
import gearSmallImg from '../../../../images/gearRatios/gearSmall.png';
import gearHighImg from '../../../../images/gearRatios/gearHigh.png';
import oilBottleImg from '../../../../images/efficiency/oilBottle.png';
import hintArrowImg from '../../../../images/hintArrow.png';
import ArrowHint from "../../../canvas/components/ArrowHint";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getTimeDeltaSec, sendSuccessForScenario} from "../../../../utils/common";

const animation = anime.timeline({autoplay: false});

const finishAnimationOptions = {
  targets: '.castle',
  easing: 'linear',
  translateX: 0,
  translateY: 0,
  duration: 1000,
  delay: 100,
  rotate: 90,
};



class Castle extends React.Component {
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.state = {
      drag: false,
    };

    this.completedWorkTextRef = React.createRef();
    this.liftingHeightTextRef = React.createRef();

    this.hintArrowOneRef = React.createRef();
    this.hintArrowTwoRef = React.createRef();
    this.hintArrowThreeRef = React.createRef();
    this.hintArrowFourRef = React.createRef();
    this.hintArrowFiveRef = React.createRef();
    this.hintArrowSixRef = React.createRef();
    this.hintArrowShowRef = React.createRef();
    this.hintArrowShowTwoRef = React.createRef();

    this.oilBottleRef = React.createRef();
    this.efficiencyFactorRef = React.createRef();
    this.textForUsersRef = React.createRef();


    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      smallCogwheelAngle: 0,
      highCogwheelAngle: 0,
      cogwheelSpeed: 0,

      completedWork: 0,
      liftingHeight: 0,
      forceOne: 90,
      forceTwo: 90,
      forceThree: 90,
      forceFour: 90,
      forceFive: 90,
      opacitySix: 0,
      sumForce: 0,
      efficiencyFactor: null,
      povorot: false,
      fixCompletedWork: 0,
      falseAnswer: false,
      correctAnswer: false,
      lastAnswer: false,
      neprover: 1,
      step: 0
    };
    this.data = cloneDeep(this.initialData);
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    window.requestAnimationFrame(this.move);
  }

  openModal = () => {
    sendSuccessForScenario(this);
    this.props.changeSuccessVisible(true)
  };

  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
  };

  checkClick = () => {

    let success = false;
    if (this.data.step === 0) {
      success = (this.data.efficiencyFactor === 50)
    }
    if (this.data.step === 1) {
      success = (this.data.efficiencyFactor >= 66) && (this.data.efficiencyFactor <= 67) && (this.data.sumForce > 0)
    }

    if(success) {

      if (this.data.step >= 1) {
        this.openModal();
      }

      animation.add(finishAnimationOptions);
      animation.play();
      this.props.changeSuccessVisible(true);
      this.data.correctAnswer = true;
      this.data.falseAnswer = false;
      this.data.neprover = 0;
      this.setState({drag: false})
      this.data.efficiencyFactor = null;
      this.data.step += 1;
    } else {
      animation.add(finishAnimationOptions);
      animation.play();
      this.props.changeFailureVisible(true);
      this.data.falseAnswer = true;
      this.data.correctAnswer = false;
      this.data.efficiencyFactor = null;
    }

    this.forceUpdate();
  };


  getNodes = (stageNode) => ({
    cogwheelSmallNode: stageNode.findOne("#cogwheelSmall"),
    cogwheelHighNode: stageNode.findOne("#cogwheelHigh"),
    handleNode: stageNode.findOne("#handle"),
    gatesNode: stageNode.findOne("#gates"),
    sheaveNode: stageNode.find(".sheave"),
    sheaveInvertNode: stageNode.find(".sheaveInvert"),
    completedWorkTextNode: this.completedWorkTextRef?.current,
    liftingHeightTextNode: this.liftingHeightTextRef?.current,
    textForUsersNode: this.textForUsersRef?.current,

  });

  move = (time) => {
    const {drag} = this.state;

    const data = this.data;
    this.requestId = window.requestAnimationFrame(this.move);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    data.completedWork = 10;


    const {
      // handleNode,
      // gatesNode,
      // sheaveNode,
    } = this.getNodes(stageNode);


    data.startTime = data.startTime || time;
    const timedelta = data.prevTime ? time - data.prevTime : 0;
    data.prevTime = time;
    const timedeltaSec = getTimeDeltaSec(timedelta);

    data.sumForce = data.forceOne + data.forceTwo + data.forceThree + data.forceFour + data.forceFive;

    if (data.cogwheelSpeed > 0) {
      data.cogwheelSpeed = 0 // нет инерции на разгон
    }

    if (drag) {
      data.cogwheelSpeed = 60;
    } else if ( data.smallCogwheelAngle > 0 ) {
      data.cogwheelSpeed -= 100 * timedeltaSec;
    }

    if ( data.smallCogwheelAngle < 0 ) {
       data.cogwheelSpeed = 0;
      data.smallCogwheelAngle = 0;
    }

    data.smallCogwheelAngle = data.smallCogwheelAngle + data.cogwheelSpeed * timedeltaSec;
    data.highCogwheelAngle = data.smallCogwheelAngle * 0.41;

    const coeff = data.step >= 1 ? 20 : 19;
    const maxWork = data.step >= 1 ? 6000 : 8000;
    const completedWork = Number(((data.sumForce + 1000) * Math.floor(data.smallCogwheelAngle / 5)/coeff).toFixed(2));
    // const completedWork = Number(data.completedWork + timedeltaSec).toFixed(2));
    data.completedWork = completedWork > maxWork ? maxWork : completedWork;

    this.updateStage();
  };

  onDragMove = (e) => {
    if (e.target.y() >= 137 && e.target.y() <= 171 && e.target.x() <= 386 && e.target.x() >= 347) {
      this.data.forceOne = 15;
      this.data.povorot = true;
      this.updateStage();
    }
    else {this.data.povorot = false;}

    if (e.target.y() >= 138 && e.target.y() <= 168 && e.target.x() <= 649 && e.target.x() >= 618) {
      this.data.forceTwo = 15;
      this.data.povorot = true;
      this.updateStage();
    }
    if (e.target.y() >= 318 && e.target.y() <= 349 && e.target.x() <= 684 && e.target.x() >= 650) {
      this.data.forceThree = 15;
      this.data.povorot = true;
      this.updateStage();
    }
    if (e.target.y() >= 330 && e.target.y() <= 351 && e.target.x() <= 862 && e.target.x() >= 844) {
      this.data.forceFour = 15;
      this.data.povorot = true;
      this.updateStage();
    }
    if (e.target.y() >= 258 && e.target.y() <= 284 && e.target.x() <= 921 && e.target.x() >= 900) {
      this.data.forceFive = 15;
      this.data.povorot = true;
      this.updateStage();
    }
  };

  updateStage() {
    const {smallCogwheelAngle, highCogwheelAngle} = this.data;
    const hintArrowOneNode = this.hintArrowOneRef?.current;
    const hintArrowTwoNode = this.hintArrowTwoRef?.current;
    const hintArrowThreeNode = this.hintArrowThreeRef?.current;
    const hintArrowFourNode = this.hintArrowFourRef?.current;
    const hintArrowFiveNode = this.hintArrowFiveRef?.current;
    const hintArrowSixNode = this.hintArrowSixRef?.current;
    const hintArrowShowNode = this.hintArrowShowRef?.current;
    const hintArrowShowTwoNode = this.hintArrowShowTwoRef?.current;
    const oilBottleNode = this.oilBottleRef?.current;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const {
      handleNode,
      cogwheelSmallNode,
      cogwheelHighNode,
      gatesNode,
      sheaveNode,
      sheaveInvertNode,
      completedWorkTextNode,
      liftingHeightTextNode,
      textForUsersNode,
    } = this.getNodes(stageNode);

    const handleRotation = smallCogwheelAngle;
    const cogwheelSmallRotation = smallCogwheelAngle;
    const cogwheelHighRotation = -highCogwheelAngle;
    const sheaveRotation = smallCogwheelAngle;

    let gatesOffsetY = smallCogwheelAngle / 5;

    let titleText = 'Необходимо рассчитать КПД механизма.\nДля этого сперва нажми кнопку "Подъем ворот"\nи рассчитай КПД по показателям в правом боксе.\nОкругли КПД до целых значений процента';
    if (this.data.step >= 1) {
      titleText = ''
    }
    hintArrowOneNode.opacity(0);
    hintArrowTwoNode.opacity(0);
    hintArrowThreeNode.opacity(0);
    hintArrowFourNode.opacity(0);
    hintArrowFiveNode.opacity(0);
    oilBottleNode.opacity(0);
    hintArrowShowTwoNode.opacity(0);

    if (gatesOffsetY > 110) {
      return;
    }
    if (gatesOffsetY > 109.7) {
      this.data.fixCompletedWork = this.data.completedWork;
      this.data.neprover = 1;
    }

    if (gatesOffsetY > 109.7 && (this.data.step === 0)) {
      titleText = 'Введи значение КПД (g=10)';
      hintArrowShowNode.opacity(1);
    }
    if (this.data.falseAnswer) {
      titleText = 'Повтори расчет, ответ должен быть округлен до целых значений';
    }


    if (this.data.correctAnswer && (this.data.step === 1)) {
      titleText = 'Правильно! КПД значительно ниже единицы из-за того,\nчто часть энергии уходит на преодоление силы трения механизма.\nИспользуй масленку, чтобы снизить трение.';
      hintArrowShowNode.opacity(0);
      hintArrowOneNode.opacity(1);
      hintArrowTwoNode.opacity(1);
      hintArrowThreeNode.opacity(1);
      hintArrowFourNode.opacity(1);
      hintArrowFiveNode.opacity(1);
      oilBottleNode.opacity(1);
    }

    if (
      hintArrowOneNode.width() === 0 &&
      hintArrowTwoNode.width() === 0 &&
      hintArrowThreeNode.width() === 0 &&
      hintArrowFourNode.width() === 0 &&
      hintArrowFiveNode.width() === 0 &&
      this.data.falseAnswer === false &&
      this.data.lastAnswer === false
    ){
      titleText = 'Введи значение КПД (g=10)';
      hintArrowShowTwoNode.opacity(1);
      this.data.lastAnswer = true;
    }

    if (this.data.correctAnswer && this.data.lastAnswer && parseInt(this.data.efficiencyFactor) >= 66 && parseInt(this.data.efficiencyFactor) <= 67 && this.data.neprover === 0) {
      titleText = 'Правильно! КПД вырос за счет снижения трения в системе';
    }

    const someOil = ['forceOne', 'forceTwo', 'forceThree', 'forceFour', 'forceFive'].some((k) => this.data[k] === 15);
    if (someOil && (this.data.step === 1)) {
      titleText = 'Проведи расчет КПД повторно';
      hintArrowShowTwoNode.opacity(1);
    }


    textForUsersNode.text(titleText);

    handleNode.rotation(handleRotation);
    cogwheelSmallNode.rotation(cogwheelSmallRotation);
    cogwheelHighNode.rotation(cogwheelHighRotation);
    sheaveNode.rotation(sheaveRotation);
    sheaveInvertNode.rotation(-sheaveRotation);

    completedWorkTextNode.text(this.data.completedWork);
    const num = Number((Math.floor(gatesOffsetY)/27).toFixed(2));
    liftingHeightTextNode.text(num > 4 ? 4 : num);


    gatesNode.offsetY(gatesOffsetY);

    hintArrowOneNode.width(this.data.forceOne === 15 ? 0 : 20);
    hintArrowTwoNode.width(this.data.forceTwo === 15 ? 0 : 20);
    hintArrowThreeNode.width(this.data.forceThree === 15 ? 0 : 20);
    hintArrowFourNode.width(this.data.forceFour === 15 ? 0 : 20);
    hintArrowFiveNode.width(this.data.forceFive === 15 ? 0 : 20);
    hintArrowSixNode.width(this.data.opacitySix === 1 ? 0 : 25);

    oilBottleNode.rotation(this.data.povorot === true ? -30 : 0);

    stageNode.draw();
  }




  CanvasCastle = () => {

    const [sheaveGray] = useImage(sheaveGrayImg);
    const [sheave] = useImage(sheaveImg);
    const [gates] = useImage(gatesImg);
    const [castle] = useImage(castleImg);
    const [wall] = useImage(wallImg);
    const [tower] = useImage(towerImg);
    const [handle] = useImage(handleImg);
    const [gearSmall] = useImage(gearSmallImg);
    const [gearHigh] = useImage(gearHighImg);
    const [oilBottle] = useImage(oilBottleImg);
    const [hintArrow] = useImage(hintArrowImg);

    return (
      <React.Fragment>
        <Layer preventDefault={false}>
          <Group y={-138}>
          <Image image={castle} preventDefault={false}/>


          {/* GROUND */}
          <Rect fill={'#8cc63f'} x={0} y={610} height={90} width={1000} preventDefault={false}/>


          {/* TOWER */}
          <Group x={800} y={400}>
            <Image id={'cogwheelSmall'} image={gearSmall} x={80} y={37} width={60} height={60} offset={{x: 60/2, y: 60/2}} preventDefault={false}/>
            <Image id={'cogwheelHigh'} image={gearHigh} x={25} y={102} width={125} height={125} offset={{x: 125/2, y: 125/2}} preventDefault={false}/>
            <Image image={sheave} x={25} y={102} width={28} height={28} offset={{x: 28/2, y: 28/2}} preventDefault={false}/>
            <Image image={tower} x={0} y={40} preventDefault={false}/>
            <Image id={'handle'} image={handle} x={80} y={37} width={8} height={20} offset={{x: 4, y: 15}}/>
          </Group>

          {/* WALL */}
          <Group x={80} y={350}>
            <Image id={'gates'} image={gates} width={123} height={145} x={190} y={115} preventDefault={false}/>
            <Image image={wall} width={500} height={260} preventDefault={false}/>

            <Group x={260} y={-40}>
              <Line x={-12} y={40} points={[1, 0, 1, -40]} stroke={'black'} strokeWidth={.5}/>
              <Line x={-12} y={40} points={[10, -49, 270, -49]} stroke={'black'} strokeWidth={.5}/>
              <Line x={-12} y={40} points={[295, -30, 295, 150]} stroke={'black'} strokeWidth={.5}/>
              <Line x={-12} y={40} points={[310, 163, 490, 163]} stroke={'black'} strokeWidth={.5}/>

              <Group x={5} y={5}>
                <Circle name={'sheave'} radius={20} fill={'#d8d5d0'} stroke={'black'} strokeWidth={.5} preventDefault={false}/>
                <Circle radius={2} fill={'black'}/>
              </Group>
              <Group x={265} y={5}>
                <Circle name={'sheave'} radius={20} fill={'#d8d5d0'} stroke={'black'} strokeWidth={.5} preventDefault={false}/>
                <Circle radius={2} fill={'black'}/>
              </Group>
              <Group x={298} y={185}>
                <Circle name={'sheaveInvert'} radius={20} fill={'#d8d5d0'} stroke={'black'} strokeWidth={.5} preventDefault={false}/>
                <Circle radius={2} fill={'black'}/>
              </Group>
            </Group>
          </Group>

          <Group x={850} y={145}>
            <Card height={200} width={130}>
              <Group x={15} y={15}>
                <Text text={_t("work_made")}  stroke={'#E76F52'} strokeWidth={1} />
                <Text ref={this.completedWorkTextRef} y={27} text={`0`}  stroke={'#E76F52'} strokeWidth={1}/>
              </Group>

              <Group x={15} y={80}>
                <Text text={_t("height")}  stroke={'#E76F52'} strokeWidth={1}/>
                <Text ref={this.liftingHeightTextRef} y={27} text={`0`}  stroke={'#E76F52'} strokeWidth={1}/>
              </Group>

              <Group x={15} y={145}>
                <Text text={_t("weight_of_the_gate")}  stroke={'#E76F52'} strokeWidth={1}/>
              </Group>

              <Group x={0} y={210}>
                <Text text={_t("efficiency_coefficient")} />
                <CanvasInput
                    y={15}
                    width={70}
                    height={30}
                    stage={this.stageRef?.current}
                    textColor={'#E76F52'}
                    onInput={(val) => this.data.efficiencyFactor = val}
                    value={this.data.efficiencyFactor}
                />
              </Group>
              <Group
                  x={75} y={225}
                  onClick={() => this.checkClick()}
                  onTap={() => this.checkClick()}
              >
                <Rect width={65} height={30} fill={'#E76F52'} cornerRadius={5} />
                <Text ref={this.startBtnRef} text={_t("check")} stroke={'white'} fill={'white'} strokeWidth={1} fontSize={12} align={'center'} verticalAlign={'middle'} width={65} height={30}/>
              </Group>
            </Card>
          </Group>
          <Group>
            <Image image={hintArrow} rotation={-90} x={350} y={280} width={20} height={20} preventDefault={false} ref={this.hintArrowOneRef} opacity={0}/>
            <Image image={hintArrow} rotation={-90} x={620} y={280} width={20} height={20} preventDefault={false} ref={this.hintArrowTwoRef} opacity={0}/>
            <Image image={hintArrow} rotation={-90} x={650} y={470} width={20} height={20} preventDefault={false} ref={this.hintArrowThreeRef} opacity={0}/>
            <Image image={hintArrow} rotation={185} x={815} y={485} width={20} height={20} preventDefault={false} ref={this.hintArrowFourRef} opacity={0}/>
            <Image image={hintArrow} rotation={-90} x={900} y={410} width={20} height={20} preventDefault={false} ref={this.hintArrowFiveRef} opacity={0}/>
            <Image image={hintArrow} rotation={-70} x={920} y={500} width={25} height={40} preventDefault={false} ref={this.hintArrowSixRef} opacity={1}/>
          </Group>

          <Group x={790} y={50} draggable={true}
                 onDragMove={(e) => {this.onDragMove(e)}}>
            <Image id={'oilBottle'} image={oilBottle} x={0} y={190} width={60} height={60} offset={{x: 60/2, y: 60/2}} preventDefault={false} ref={this.oilBottleRef} opacity={0}/>
          </Group>
            <Group x={50} y={170}>
            <Text text={'Необходимо рассчитать КПД механизма.\nДля этого сперва нажми кнопку "Подъем ворот"\nи рассчитай КПД по показателям в правом боксе.\nОкругли КПД до целых значений процента'}
                  strokeWidth={1} fontSize={20} ref={this.textForUsersRef} background={'#4d4d4d'}/>
              <Image image={hintArrow} rotation={-190} x={780} y={210} width={20} height={20} preventDefault={false} ref={this.hintArrowShowRef} opacity={0}/>
              <Image image={hintArrow} rotation={-190} x={780} y={210} width={20} height={20} preventDefault={false} ref={this.hintArrowShowTwoRef} opacity={0}/>
            </Group>
         </Group>

        </Layer>
      </React.Fragment>
    )
  };

  render() {
    const {burning, withPiston} = this.state;
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef}>
          <this.CanvasCastle/>
        </CanvasContainer>
        <button
          style={{...styles.btn, right: '8.5%' ,opacity: burning ? .7 : 1, bottom: '180px', width:'70px' }}
          onTouchStart={() => this.setState({drag: true})}
          // onTouchEnd={() => this.setState({drag: false})}
          onMouseDown={() => this.setState({drag: true})}
          // onMouseUp={() => this.setState({drag: false})}
          onClick={() => this.data.opacitySix = 1}
        >
          {_t("lifting_the_gate")}
        </button>
        <button
          style={{...styles.btn, right: '5%', opacity: burning ? .7 : 1,padding: '1vmin 1.5vmin',fontSize: '2vmin',}}
          onClick={() => {
            this.data = cloneDeep(this.initialData);
            this.setState({drag: false}, this.onClickReset)
          }}
        >
          {_t("reset")}
        </button>
        {/*<FieldBlock*/}
        {/*  min={1}*/}
        {/*  max={10}*/}
        {/*  label={'Длина шарнира'}*/}
        {/*  value={this.state.inputLeverArmWidth}*/}
        {/*  onChangeInput={this.onChangeInput}*/}
        {/*>*/}
        {/*  <div style={{width: '85px'}}>*/}
        {/*    <p>макс: <b>10</b></p>*/}
        {/*    <p>мин: <b>1</b></p>*/}
        {/*  </div>*/}
        {/*</FieldBlock>*/}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  failureModalVisible: state.commonReducer.failureModalVisible,
});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Castle);


const styles = {
  mainContainer: {
    background: '#faefe3',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  btn: {
    position: 'absolute',
    background: '#E76F52',
    borderRadius: '7px',
    color: 'white',
    zIndex: 10,
    // fontSize: '2vmin',
    // padding: '1vmin 1.5vmin',
    fontWeight: 'bold',
    bottom: '2vmin'
  }
};
