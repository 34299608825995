import React, {useState} from "react";
import TaskMainContainer from "./TaskMainContainer";
import ArrangeByColumns from "./ArrangeByColumns";
import MatchItems from "./MatchItems";
import houseImg from "../images/matchItems/house.png";
import houseCompleteImg from "../images/matchItems/house_complete.png";
import rabbitHouseImg from "../images/matchItems/rabbit_house.png";
import rabbitHouseCompleteImg from "../images/matchItems/rabbit_house_complete.png";
import barnImg from "../images/matchItems/barn.png";
import barnCompleteImg from "../images/matchItems/barn_complete.png";
import rabbitRampImg from "../images/matchItems/rabbit_ramp.png";
import barnRampImg from "../images/matchItems/barn_ramp.png";
import houseRampImg from "../images/matchItems/house_ramp.png";


const Example3Task = (props) => {
  const [allComplete, setAllComplete] = useState(false);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);

  const dropItems = [
    {id: 1, img: houseImg, completeImg: houseCompleteImg, successId: 3},
    {id: 2, img: rabbitHouseImg, completeImg: rabbitHouseCompleteImg, successId: 1},
    {id: 3, img: barnImg, completeImg: barnCompleteImg, successId: 2},
  ]
  const dragItems = [
    {id: 1, img: rabbitRampImg},
    {id: 2, img: barnRampImg},
    {id: 3, img: houseRampImg},
  ]

  return (
    <TaskMainContainer
      lessonCode={props.lessonCode}
      tasksCount={1}
      completeCount={completedTaskCount}
      setCompletedTaskCount={setCompletedTaskCount}
      taskComplete={allComplete}
    >
      <MatchItems
        dropItems={dropItems}
        dragItems={dragItems}
        taskComplete={() => {
          setAllComplete(true);
          setCompletedTaskCount(prevState => prevState + 1)
        }}
      />
    </TaskMainContainer>
  )
}
export default Example3Task;
