import {layout2} from "../../../../utils/styles";
import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import {Image, Rect, Layer, Text, Group, Circle, Line, Arrow} from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import ScenarioManager from "../../../../utils/ScenarioManager";
import {getTimeDeltaSec, hasIntersection, inputVal, showFailOrSuccessPopup} from "../../../../utils/common";
import aluminumImg from '../../../../images/scaleForCubes/aluminum.png';
import CanvasScaleWithBowls from "../../../canvas/components/scales/CanvasScaleWithBowls";
import Card from "../../../canvas/components/Card";
import crownImg from '../../../../images/crown/crown.png';
import CanvasButton from "../../../canvas/components/CanvasButton";


class CrownScale extends React.Component {
  constructor(props) {
    super(props);

    this.managedComponents = [
      'stage',
      'defaultWeightNode',
    ]

    this.initialData = {
      startTime: undefined,
      prevTime: undefined,

      draggingKey: '',

      rightWeightItems: props.rightWeightItems || [],

      rightWeightPlaceStack: [],

    };
    this.data = cloneDeep(this.initialData);
  }

  _ref = (key) => this[`${key}Ref`] = React.createRef();
  _getNode = (key) => this[`${key}Ref`]?.current;

  componentDidMount() {
    this.props.getMovingCallback({['scaleComp']: this.move});
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.visible) {
      this.onClickReset();
    }
  }

  onClickReset = () => {
    this.data = cloneDeep(this.initialData);
    this.updateStage();
  };

  showFailOrSuccessPopup = (status) => showFailOrSuccessPopup(this, status);

  move = (time) => {
    const data = this.data;

    if (data.checkCorrect) {
      data.checkCorrect(this);
    }

    this.updateStage();
  };

  addWeightToBowls = (side) => {
    this.data[side+'WeightPlaceStack'].forEach((weightKey, i) => {
      const weightNode = this._getNode('weightNode-'+weightKey);
      const dropPlace = this._getNode(side+'DropPlace');
      const dropPlacePos = dropPlace?.getAbsolutePosition();
      const weightEl = this.data.rightWeightItems.find((el) => el.id === weightKey)
      const scale = .8;
      if (dropPlacePos) {
        weightNode.scale({x: scale, y: scale})
        weightNode.setAbsolutePosition({
          x: dropPlacePos.x + weightEl.x * scale,
          y: dropPlacePos.y + (163 - weightEl.height) * scale
        })
      }
    });
  }

  updateStage() {
    const data = this.data;

    const dropPlace = this._getNode('leftDropPlace');
    const crownNode = this._getNode('weightCrownForScale');
    const dropPlacePos = dropPlace?.getAbsolutePosition();
    if (dropPlacePos) {
      crownNode.setAbsolutePosition({x: dropPlacePos.x + 85, y: dropPlacePos.y + 65});

      data.rightWeightItems.forEach((weightEl, i) => {
        const weightKey = weightEl.id;
        const weightNode = this._getNode('weightNode-' + weightKey);
        const defaultWeightNode = this._getNode('defaultWeightNode');
        const defaultWeightPos = defaultWeightNode.getAbsolutePosition();
        if (data.draggingKey !== weightKey) {
          weightNode.scale({x: 1, y: 1});
          weightNode.setAbsolutePosition({
            x: defaultWeightPos.x + weightEl.x,
            y: defaultWeightPos.y + (111 - weightEl.height)
          });
        }
      })
    }

    this.addWeightToBowls('right');
  }

  Env = () => {
    const data = this.data;

    return (
      <React.Fragment>
        <Text
          x={70} y={40}
          text={'Определить плотность материала короны, используя \nдоступные инструменты'}
          fill={layout2.blue} fontSize={27} lineHeight={1.3}
        />

        <CanvasButton
          text={'Вернуться'}
          onClick={() => this.props.mainComp.scenarioManager.selectStepByKey('start')}
          fontSize={18}
          strokeWidth={.2}
          btnCornerRadius={0}
          y={500}
          x={50}
          height={40}
        />

      </React.Fragment>
    )
  };

  setNewWeightPosition = (weightKey, position) => {
    const data = this.data;
    data.defaultWeightPlaceStack = data.defaultWeightPlaceStack.filter((k) => k !== weightKey);
    data.defaultWeightPlaceStack.splice(position, 0, weightKey);
  }

  Weights = (props) => {
    const data = this.data;

    const [weightCrown] = useImage(crownImg);

    const weightItemsImgs = {}
    data.rightWeightItems.forEach((weightEl) => {
      const [weightImg] = useImage(weightEl.img);
      weightItemsImgs[weightEl.id] = weightImg;
    })

    const removeAddedItem = (weightId) => {
      data.rightWeightPlaceStack = data.rightWeightPlaceStack.filter(itm => itm !== weightId);
    }

    return (
      <Group {...props}>
        <Image image={weightCrown} ref={this._ref('weightCrownForScale')}/>

        <Group x={20} y={15}>
          <Rect width={70} height={42} fill={'red'} opacity={0} ref={this._ref('defaultWeightNode')}/>
          {
            data.rightWeightItems.map((weightEl, i) => {
              const correctItem = weightEl;
              const weightKey = weightEl.id;
              return (
                <Group
                  // x={75*i}
                  x={correctItem.x}
                       key={'weight-'+(i+1)} ref={this._ref('weightContainerNode-'+weightKey)}>
                  <Group
                    y={0}
                    ref={this._ref('weightNode-'+weightKey)}
                    draggable={true}
                    dragBoundFunc={(pos) => {
                      const leftBorderX = 0;
                      const rightBorderX = 900;
                      const topBorderY = 0;
                      const bottomBorderY = 470;
                      const newX = pos.x >= rightBorderX ? rightBorderX : pos.x <= leftBorderX ? leftBorderX : pos.x;
                      const newY = pos.y <= topBorderY ? topBorderY : pos.y >= bottomBorderY ? bottomBorderY : pos.y;
                      return {y: newY, x: newX};
                    }}
                    onDragStart={(e) => {
                      data.draggingKey = weightKey;
                      removeAddedItem(weightKey);
                      const currentContainer = this._getNode('weightContainerNode-'+weightKey);
                      currentContainer.moveToTop();
                    }}
                    onDragEnd={(e) => {
                      const currentNode = this._getNode('weightRectNode-'+weightKey);
                      const rightPlaceNode = this._getNode('rightDropPlace');
                      const rightInters = hasIntersection(rightPlaceNode, currentNode, this.props.mainComp);
                      if (rightInters) {
                        data.rightWeightPlaceStack.push(weightKey);
                      } else {
                        removeAddedItem(weightKey);
                      }
                      data.draggingKey = '';
                    }}
                  >
                    <Image
                      x={correctItem.imgPosX} y={correctItem.imgPosY}
                      width={correctItem.ImgSize || undefined}
                      height={correctItem.ImgSize || undefined}
                      image={weightItemsImgs[weightKey]}
                      ref={this._ref('weightImgNode-'+weightKey)}
                    />
                    <Rect
                      x={0} y={0}
                      width={correctItem.width || 60} height={correctItem.height || 60}
                      fill={'black'} opacity={0}
                      ref={this._ref('weightRectNode-'+weightKey)}
                    />
                  </Group>
                </Group>
              )
            })
          }
        </Group>
      </Group>
    )
  }

  getWeights = () => {
    const data = this.data;
    const leftPlaceWeight = this.props.crownWeight;
    const rightPlaceWeight = this.data.rightWeightPlaceStack.reduce((accum, key) => {
      const el = data.rightWeightItems.find((el) => el.id === key);
      return accum + el.weight;
    }, 0);
    return {leftPlace: leftPlaceWeight, rightPlace: rightPlaceWeight}
  }

  render() {
    const data = this.data;


    return (
      <Group visible={this.props.visible}>
        <this.Env/>

        <CanvasScaleWithBowls
          id={'crownScale'}
          x={480} y={220}
          _ref={this._ref}
          _getNode={this._getNode}
          checkRightWeight={() => {
            const {leftPlace, rightPlace} = this.getWeights();
            return leftPlace > rightPlace;
          }}
          checkLeftWeight={() => {
            const {leftPlace, rightPlace} = this.getWeights();
            return leftPlace < rightPlace;
          }}
          getMovingCallback={this.props.getMovingCallback}
        />

        <this.Weights x={500} y={420}/>
      </Group>
    )
  }
}

export default CrownScale;
