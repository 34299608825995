import {sortListByField} from "../../../utils/common";


export const convertClassroomsListData = (classroomsList) => {
  let notDistributedStudentsList = [];
  let distributedStudentsList = [];
  let allClassroomsList = [];

  classroomsList.forEach(
    (cr) => {
      const correctClassroom = convertStudentsByClassroom(cr);
      if (cr.for_new_students) {
        notDistributedStudentsList = correctClassroom;
      } else {
        distributedStudentsList.push(...correctClassroom)
        allClassroomsList.push({id: cr.id, name: cr.name});
      }
    }
  );

  notDistributedStudentsList = sortListByField(notDistributedStudentsList, 'classroomId');
  distributedStudentsList = sortListByField(distributedStudentsList, 'classroomId');

  return {
    notDistributedStudentsList,
    distributedStudentsList,
    allClassroomsList,
  }
}



export const convertStudentsByClassroom = (classroom) => {
  return classroom?.students.map((stdnt) => {
    return {id: stdnt.id, title: `${stdnt.user.first_name} ${stdnt.user.last_name}`, classroomId: classroom.id}
  });
}