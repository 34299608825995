import React from 'react';
import {Table, Space, Button} from "antd";
import {TeamOutlined  } from "@ant-design/icons";




const StatisticTableCard = (props) => {
  return (
    <div
      style={
        props.isMobileMode ? {
            border: '1px solid rgba(0,0,0,0.1)',
            padding: '0px',
          } :
        {
          boxShadow: '0px 2px 20px 1px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
          // padding: `20px ${props.isMobileMode ? '0' : '30'}px 15px`,
          padding: '20px 30px 15px',
        }
      }
    >
      {props.children}

      {/* todo добавить функционал для кнопок */}
      {/*<Space align={'end'} direction={'horizontal'} style={{margin: '30px 0 0', width: '100%', justifyContent: 'flex-end'}}>*/}
      {/*  <Button type={'link'} size={'large'} className={'withUnderline'}>Скопировать</Button>*/}
      {/*  <Button type={'link'} size={'large'} className={'withUnderline'}>Распечатать</Button>*/}
      {/*</Space>*/}
    </div>
  )
}
const StatisticTable = (props) => {
  return (
    <StatisticTableCard isMobileMode={props.isMobileMode}>
      <Table
        bordered
        loading={!!props.loading}
        className={'hw-table-custom-style'}
        dataSource={props.rows}
        columns={props.columns}
        scroll={{ x: 1000, y: 500 }}
        pagination={false}
        locale={{
          emptyText: <>
            <TeamOutlined style={{marginTop: '20px', fontSize: '50px'}} />
            <p style={{margin: '10px 0 20px'}}>Ни один ученик не приступил к выполнению</p>
          </>
        }}
      />
    </StatisticTableCard>
  );
};


export default StatisticTable;
