import {Group, Image, Rect} from "react-konva";
import React from "react";
import useImage from "use-image";
import playImg from "./imgs/play.png";
import pauseImg from "./imgs/pause.png";
import leftImg from "./imgs/left.png";
import rightImg from "./imgs/right.png";

export const Player = (props) => {
  const {
    x = 250,
    y = 50,

    prevBtnRef,
    playBtnRef,
    stopBtnRef,
    nextBtnRef,

    onClickPrevBtn,
    onClickPlayBtn,
    onClickStopBtn,
    onClickNextBtn,
  } = props;

  const [play] = useImage(playImg);
  const [pause] = useImage(pauseImg);
  const [left] = useImage(leftImg);
  const [right] = useImage(rightImg);

  const hitBoxStg = {
    opacity: 0,
    size: 70
  };
  return (
    <Group x={x} y={y}>
      {/* ------------------- PREV ---------------------- */}
      {onClickPrevBtn && <Group
        x={-50} ref={prevBtnRef}
        onClick={onClickPrevBtn}
        onTap={onClickPrevBtn}
      >
        <Rect x={-15} y={-25} fill={'purple'} width={hitBoxStg.size} height={hitBoxStg.size} opacity={hitBoxStg.opacity}/>
        <Image image={left} />
      </Group>}

      {/* ------------------- PLAY ---------------------- */}
      <Group
        y={-53} ref={playBtnRef}
        onClick={onClickPlayBtn}
        onTap={onClickPlayBtn}
      >
        <Rect x={20} y={28} fill={'purple'} width={hitBoxStg.size} height={hitBoxStg.size} opacity={hitBoxStg.opacity}/>
        <Image image={play}/>
      </Group>

      {/* ------------------- STOP ---------------------- */}
      <Group
        x={40} y={-10} ref={stopBtnRef}
        onClick={onClickStopBtn}
        onTap={onClickStopBtn}
      >
        <Rect x={-20} y={-15}  fill={'purple'} width={hitBoxStg.size} height={hitBoxStg.size} opacity={hitBoxStg.opacity}/>
        <Image image={pause}/>
      </Group>

      {/* ------------------- NEXT ---------------------- */}
      {onClickNextBtn && <Group
        x={120} ref={nextBtnRef}
        onClick={onClickNextBtn}
        onTap={onClickNextBtn}
      >
        <Rect x={-15} y={-25} fill={'purple'} width={hitBoxStg.size} height={hitBoxStg.size} opacity={hitBoxStg.opacity}/>
        <Image image={right}/>
      </Group>}
    </Group>
  )
}
