import React, {useEffect, useRef} from 'react';
import {Group, Image, Text} from 'react-konva';
import useImage from 'use-image';
import Victor from 'victor';
import measurerImage from '../img/measurer.png';
import pointerImage from '../img/pointer.png';

const Measurer = ({proportion = 0, title, getMove, getSize, ...props}) => {
  const {_options: {rotate}, canvasStyles} = Measurer;

  const hasTitle = typeof title === 'string';
  const titleMargin = hasTitle ? canvasStyles.Title.width + canvasStyles.Title.marginRight : 0;

  const [measurerNode] = useImage(measurerImage);
  const [pointerNode] = useImage(pointerImage);

  const {width: measurerWidth = 0, height: measurerHeight = 0} = measurerNode ?? {};
  const {width: pointerWidth = 0, height: pointerHeight = 0} = pointerNode ?? {};

  const calcDifVector = (prop) => {
    const rotation = rotate.min + Math.min(1, Math.max(0, prop)) * (rotate.max - rotate.min);
    const startVector = new Victor(pointerWidth / 2, 0);
    const rotatedVector = startVector.clone().rotateDeg(rotation);
    const difVector = startVector.clone().subtract(rotatedVector);
    return {rotation, difVector};
  };
  const calcX = (difVector) => (measurerWidth - pointerWidth) / 2 + difVector.x + titleMargin;
  const calcY = (difVector) => measurerHeight / 2 + difVector.y;

  const pointerRef = useRef();
  useEffect(() => {
    if (typeof getMove === 'function') {
      const move = (prop) => {
        const pointerNode = pointerRef.current;
        if (!pointerNode) return false;

        const {rotation: rot, difVector: dif} = calcDifVector(prop);
        pointerNode.x(calcX(dif));
        pointerNode.y(calcY(dif));
        pointerNode.rotation(rot);
      };
      getMove(move);
    }
  }, [pointerRef.current, measurerWidth, measurerHeight, pointerWidth, titleMargin]);

  useEffect(() => {
    if (typeof getSize === 'function') {
      getSize({width: measurerWidth, height: measurerHeight});
    }
  }, [measurerWidth, measurerHeight]);

  const {rotation, difVector} = calcDifVector(proportion);

  return (
    <Group {...props}>
      {hasTitle ? (
        <Text
          text={title}
          {...canvasStyles.Title}
          height={measurerHeight}
        />
      ) : null}

      <Image
        x={titleMargin}
        image={measurerNode}
      />
      <Image
        ref={pointerRef}
        image={pointerNode}
        x={calcX(difVector)}
        y={calcY(difVector)}
        rotation={rotation}
      />
    </Group>
  );
};

Measurer._options = {
  rotate: {
    min: 45,
    get max(){return 360 - this.min;},
  },
};

Measurer.canvasStyles = {
  Title: {
    fill: '#457b9d',
    fontSize: 14,
    width: 140,
    align: 'right',
    verticalAlign: 'middle',
    marginRight: 5,
  },
};

export default Measurer;
