import {mainColor} from "../../../utils/styles";
import React from "react";

export const initialData = {
  commonStartTime: undefined,
  commonPrevTime: undefined,
  startTime: undefined,
  prevTime: undefined,

  text: { value: '', settings: {}},

  wheelVel: 0,
  leftWheelVel: 0,
  rightWheelVel: 0,

  errorSettings: {
    text: 'Повтори расчет',
    fill: 'red', fontStyle: 'bold',
    x: 323, y: 40, fontSize: 13,
  },

  engineX: 260,
  boxX: 240,
  boxY: 470,
  verevka: 360,
  hookRefY: 0,
  hightCrane: 19.2,
  weightCargo: 1000,

  liftingHeight: 0,
  liftingTime: 0,

  liftingStartOffset: 0,
  heightY: 0,
  cargoReadyForLifted: false,
  hookVelocity: 0,
  leftSlingPoints: [0,0,0,0],
  rightSlingPoints: [0,0,0,0],

  cardDataSettings: {
    x: 113,
    y: 300,
    height: 170,
    width: 130,
  },

  checkBtnStg: {
    fontSize: 23,
    x: 150,
    y: 90,
    strokeWidth: .2,
    btnCornerRadius: 0,
    width: 150,
    height: 45,
  },

  liftingHeightTxtInCardSettings: {
    x: 15, y: 10, visible: true,
  },
  liftingWeightTxtInCardSettings: {
    x: 15, y: 90, visible: true,
  },
  liftingTimeTxtInCardSettings: {
    x: 15, y: 90, visible: false,
  },

  checkSuccessFn: undefined,
  inputWork: undefined,
  inputTime: undefined,
  inputWidth: undefined,
  inputHeight: undefined,
  inputPower: undefined,
  inputVal: undefined,


  btnTop: false,
  btnRight: false,
  btnBottom: false,
  btnLeft: false,

  arrowsVisible: false,

  completeTask: false,
  boxCompletePos: false,

  prevBtnArrowVisible: false,
  controllerArrowsVisible: false,
  controllerVisible: false,
  cardDataVisible: false,
  arrowCompleteVisible: false,
  completeBlockVisible: false,
  cardFormulaVisible: false,
  completeTaskVisible: false,
  inputErrVisible: false,
  formulaWithInputVisible: false,
  formulaTextVisible: false,
  autoCompleteLifting: false,
  customFormulaVisible: false,

  liftingTimeTxtInCardVisible: false,
  customFormulaCode: '',
  errorVisible: false,
};

export const getScenario = (code) => {
  if (code ==='crane8') {
    return craneWorkScenario
  }
  if (code ==='crane9') {
    return cranePowerScenario
  }
}


const defaultCardData = {
  cardDataSettings: {
    ...initialData.cardDataSettings,
    height: 270, y: 200
  },
  liftingTimeTxtInCardSettings: {
    ...initialData.liftingTimeTxtInCardSettings,
    y: 10, visible: true,
  },
  liftingHeightTxtInCardSettings: {
    ...initialData.liftingHeightTxtInCardSettings,
    y: 110, visible: true,
  },
  liftingWeightTxtInCardSettings: {
    ...initialData.liftingWeightTxtInCardSettings,
    y: 190, visible: true,
  },

  liftingTime: 10,
  liftingHeight: 10,
  cardDataVisible: true,
}

export const craneWorkScenario = [
  // --------- STEP 0 ----------
  {
    key: 'step0',
    patchData: {
      ...initialData,
      text: {
        value: 'Загрузи контейнер на судно',
        settings: { fontSize: 20, fontStyle: 'bold' }
      },

      boxCompletePos: false,
    },
    prev_btn_hidden: true,
    next_btn_hidden: true,
    previous: 'step0',
    next_success: 'step1',
    switch_at: 1
  },
  // --------- STEP 1 ----------
  {
    key: 'step1',
    patchData: {
      ...initialData,
      text: {
        value: 'Загрузи контейнер на судно',
        settings: { fontSize: 20, fontStyle: 'bold' }
      },

      boxCompletePos: false,
      controllerVisible: true,
      controllerArrowsVisible: true,
    },
    prev_btn_hidden: true,
    next_btn_hidden: true,
    previous: 'step1',
    next_success: 'step1.1',
  },
  {
    //блок для галочки о прохождении этапа
    key: 'step1.1',

    patchData: {
      ...initialData,
      text: {
        value: 'Загрузи контейнер на судно',
        settings: { fontSize: 20, fontStyle: 'bold' }
      },

      completeBlockVisible: true,
      controllerVisible: true,
      arrowCompleteVisible: true,
      boxCompletePos: true,
      completeTask: true,
      liftingHeight: 10,
      cardDataVisible: true,
    },
    next_success: 'step2',
    switch_at: 1
  },

  // --------- STEP 2 ----------

  {
    key: 'step2',

    patchData: {
      ...initialData,
      text: {
        value: 'Величина работы (A) измеряется \nкак произведение силы (F) на путь (S), \nпройденный по направлению \nэтой силы:',
        settings: { fontSize: 16, y: 30}
      },

      cardFormulaVisible: true,
      cardFormulaSettings: {
        card: {width: 200},
      },
      formulaTextVisible: true,
      formulaText: 'A = F x S',

      boxCompletePos: true,
      liftingHeight: 10,
      cardDataVisible: true,
    },
    previous: 'step1',
    next_success: 'step3',
    switch_at: 6
  },

  // --------- STEP 3 ----------

  {
    key: 'step3',

    patchData: {
      ...initialData,
      text: {
        value: 'В нашем случае была совершена работа \nпо подъему груза. А значит, в качестве \nпройденного пути будем использовать \nвысоту (h):',
        settings: { fontSize: 16, y: 30}
      },

      cardFormulaVisible: true,
      cardFormulaSettings: {
        card: {width: 200},
      },
      formulaTextVisible: true,
      formulaText: 'A = F x h',

      cardDataVisible: true,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    previous: 'step2',
    next_success: 'step4',
    switch_at: 6
  },

  // --------- STEP 4 ----------

  {
    key: 'step4',

    patchData: {
      ...initialData,
      text: {
        value: 'Определим работу, которая только \nчто была совершена краном.',
        settings: { fontSize: 18, y: 60}
      },

      cardFormulaVisible: true,
      cardFormulaSettings: {
        card: {width: 200},
      },
      formulaTextVisible: true,
      formulaText: 'A = F x h',

      cardDataVisible: true,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    previous: 'step3',
    next_success: 'step5',
    switch_at: 6
  },

  // --------- STEP 5 ----------


   {
    key: 'step5',

    patchData: {
      ...initialData,
      text: {
        value: 'Какая сила обеспечила равномерный \nподъем контейнера на судно? \nВведи значение в поле (в Ньютонах):',
        settings: { fontSize: 16, y: 50}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,


      cardFormulaSettings: {
        card: {width: 300},
        txtFirst: { text: 'A = ', x: 20 },
        input: {
          x: 100
        },
        txtSecond: { text: 'x h ', x: 220 },
      },
      boxCompletePos: true,
      cardDataVisible: true,
      prevBtnArrowVisible: true,

      inputSuccess: 1000,
      liftingHeight: 10,
    },
    next_btn_hidden: true,
    previous: 'step4',
    next_success: 'step5.2',
    next_failure: 'step5.1',
  },
  {
    key: 'step5.1',
    patchData: {
      ...initialData,
      text: {
        value: 'Какая сила обеспечила равномерный \nподъем контейнера на судно? \nВведи значение в поле (в Ньютонах):',
        settings: { fontSize: 16, y: 50}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,


      errorVisible: true,
      errorSettings: {
        ...initialData.errorSettings,
        x: 105, y: 40, fontSize: 13,
      },
      cardFormulaSettings: {
        card: {width: 300},
        txtFirst: { text: 'A = ', x: 20 },
        input: {
          x: 100
        },
        txtSecond: { text: 'x h ', x: 220 },
      },


      inputSuccess: 1000,
      cardDataVisible: true,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    next_btn_hidden: true,
    previous: 'step5',
    next_success: 'step5.2',
    next_failure: 'step5.1',
  },
  {
    key: 'step5.2',

    patchData: {
      ...initialData,
      text: {
        value: 'Какая сила обеспечила равномерный \nподъем контейнера на судно? \nВведи значение в поле (в Ньютонах):',
        settings: { fontSize: 16, y: 50}
      },


      completeBlockVisible: true,
      cardDataVisible: true,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    previous: 'step5',
    next_success: 'step6',
    switch_at: 1
  },

  // --------- STEP 6 ----------

  {
    key: 'step6',

    patchData: {
      ...initialData,
      text: {
        value: 'На какую высоту был поднят груз? \nВведи значение в поле (в метрах):',
        settings: { fontSize: 16, y: 60}
      },


      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {width: 400},
        txtFirst: { text: 'A = ', x: 20 },
        input: {
          x: 250
        },
        txtSecond: { text: '1000 H  x  ', x: 80 },
      },


      inputSuccess: 10,
      cardDataVisible: true,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    next_btn_hidden: true,
    previous: 'step5',
    next_success: 'step6.2',
    next_failure: 'step6.1',
  },
  {
    key: 'step6.1',
    patchData: {
      ...initialData,
      text: {value: 'На какую высоту был поднят груз? \nВведи значение в поле (в метрах):'},


      errorVisible: true,
      errorSettings: {
        ...initialData.errorSettings,
        x: 250, y: 40, fontSize: 13,
      },
      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {width: 400},
        txtFirst: { text: 'A = ', x: 20 },
        input: {
          x: 250
        },
        txtSecond: { text: '1000 H  x  ', x: 80 },
      },

      inputSuccess: 10,
      cardDataVisible: true,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    next_btn_hidden: true,
    previous: 'step6',
    next_success: 'step6.2',
    next_failure: 'step6.1',
  },
  {
    key: 'step6.2',

    patchData: {
      ...initialData,
      text: { value: 'На какую высоту был поднят груз? \nВведи значение в поле (в метрах):' },

      completeBlockVisible: true,
      cardDataVisible: true,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    previous: 'step6',
    next_success: 'step7',
    switch_at: 1
  },

    // --------- STEP 7 ----------

  {
    key: 'step7',

    patchData: {
      ...initialData,
      text: {
        value: 'Какая работа была совершена? \nВведи значение в поле (в Джоулях):',
        settings: { y: 40}
      },


      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {width: 430},
        txtFirst: { text: 'A = ', x: 20, fontSize: 30 },
        input: {
          x: 330, y:-8, width:80, fontSize: 30
        },
        txtSecond: { text: '1000 H  x  10 м = ', x: 80, fontSize: 30 },
      },
      cardDataVisible: true,
      inputSuccess: 10000,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    next_btn_hidden: true,
    previous: 'step6',
    next_success: 'step7.2',
    next_failure: 'step7.1',
  },
  {
    key: 'step7.1',

    patchData: {
      ...initialData,
      text: {
        value: 'Какая работа была совершена? \nВведи значение в поле (в Джоулях):',
        settings: { y: 40}
      },

      errorVisible: true,
      errorSettings: {
        ...initialData.errorSettings,
        x: 323, y: 40, fontSize: 13,
      },
      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {width: 430},
        txtFirst: { text: 'A = ', x: 20, fontSize: 30 },
        input: {
          x: 330, y: -8, width:80, fontSize: 30
        },
        txtSecond: { text: '1000 H  x  10 м = ', x: 80, fontSize: 30 },
      },

      inputSuccess: 10000,
      cardDataVisible: true,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    next_btn_hidden: true,
    previous: 'step7',
    next_success: 'step7.2',
    next_failure: 'step7.1',
  },
  {
    key: 'step7.2',

    patchData: {
      ...initialData,
      text: {
        value: 'Какая работа была совершена? \nВведи значение в поле (в Джоулях):',
        settings: { y: 40}
      },


      completeBlockVisible: true,
      cardDataVisible: true,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    previous: 'step7',
    next_success: 'step8',
    switch_at: 1
  },

    // --------- STEP 8 ----------

  {
    key: 'step8',

    patchData: {
      ...initialData,
      text: {
        value: 'Была совершена работа в \n10000 джоулей. Удобнее будет \nвыразить в килоджоулях:',
        settings: { fontSize: 22, y: 40}
      },
      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {width: 400},
        txtFirst: { text: 'A = 10000 Дж = ', x: 20, fontSize: 30 },
        input: {
          x: 245, y: -8, width:70, fontSize: 30
        },
        txtSecond: { text: ' кДж', x: 315, fontSize: 30 },
      },
      cardDataVisible: true,
      inputSuccess: 10,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    next_btn_hidden: true,
    previous: 'step7',
    next_success: 'step8.2',
    next_failure: 'step8.1',
  },
  {
    key: 'step8.1',

    patchData: {
      ...initialData,
      text: {
        value: 'Была совершена работа в \n10000 джоулей. Удобнее будет \nвыразить в килоджоулях:',
        settings: {fontSize: 22,  y: 40}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {width: 400},
        txtFirst: { text: 'A = 10000 Дж = ', x: 20, fontSize: 30 },
        input: {
          x: 245, y: -8, width:70, fontSize: 30
        },
        txtSecond: { text: ' кДж', x: 315, fontSize: 30 },
      },
      errorVisible: true,
      errorSettings: {
        ...initialData.errorSettings,
        x: 245, y: 40, fontSize: 13,
      },
      cardDataVisible: true,
      inputSuccess: 10,
      boxCompletePos: true,
      liftingHeight: 10,
    },
    next_btn_hidden: true,
    previous: 'step8',
    next_success: 'step8.2',
    next_failure: 'step8.1',
  },
  {
    // Галочка Урок пройден
    key: 'step8.2',
    patchData: {
      ...initialData,
      text: {
        value: 'Была совершена работа в \n10000 джоулей. Удобнее будет \nвыразить в килоджоулях:',
        settings: { fontSize: 22, y: 40}
      },


      boxCompletePos: true,
      completeTaskVisible: true,
      liftingHeight: 10,
    },
    previous: 'step8',
    next_btn_hidden: true,
  },
]

export const cranePowerScenario = [
  {
    key: 'step1',
    patchData: {
      ...initialData,
      ...defaultCardData,
      liftingTime: 0,
      liftingHeight: 0,
      text: { value: '',},
      start: true,
      autoCompleteLifting: true,
      cardDataVisible: true,

    },
    prev_btn_hidden: true,
    previous: 'step1',
    next_success: 'step2',
  },

  // --------- STEP 2 ----------

  {
    key: 'step2',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим работу, которая только \nчто была совершена краном.',
        settings: { fontSize: 20, y: 55}
      },

      cardFormulaVisible: true,
      formulaTextVisible: true,
      formulaText: 'A  =  F  x  h',
      cardFormulaSettings: {
        card: {width: 230},
      },

      boxCompletePos: true,
    },
    previous: 'step1',
    next_success: 'step3',
    switch_at: 6
  },

  // --------- STEP 3 ----------

  {
    key: 'step3',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим работу, которая только \nчто была совершена краном. \nЗаполни силу тяжести и высоту подъема:',
        settings: { fontSize: 20, y: 45}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      customFormulaCode: 'crane9/workFormulaWith2Input1',
      cardFormulaSettings: {
        card: {width: 360},
      },

      checkSuccessFn: (data) => data.inputWidth === 1000 && data.inputHeight === 10,

      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step2',
    next_success: 'step3.2',
    next_failure: 'step3.1',
  },
  {
    key: 'step3.1',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим работу, которая только \nчто была совершена краном. \nЗаполни силу тяжести и высоту подъема:',
        settings: { fontSize: 20, y: 45}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      customFormulaCode: 'crane9/workFormulaWith2Input1',
      cardFormulaSettings: {
        card: {width: 360},
      },

      checkSuccessFn: (data) => data.inputWidth === 1000 && data.inputHeight === 10,

      errorVisible: true,
      errorSettings: {
        text: 'Повтори расчет',
        fill: 'red',
        x: 17, y: 40, fontSize: 13, fontStyle: 'bold'
      },
      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step3',
    next_success: 'step3.2',
    next_failure: 'step3.1',
  },
  {
    key: 'step3.2',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим работу, которая только \nчто была совершена краном. \nЗаполни силу тяжести и высоту подъема:',
        settings: { fontSize: 20, y: 45}
      },


      completeBlockVisible: true,
      boxCompletePos: true,
    },
    previous: 'step3',
    next_success: 'step4',
    switch_at: 2
  },

  // --------- STEP 4 ----------

  {
    key: 'step4',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим работу, которая только \nчто была совершена краном:',
        settings: { fontSize: 18, y: 55}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: -130, width: 550},
      },
      customFormulaCode: 'crane9/workFormula2',

      checkSuccessFn: (data) => data.inputWork === 10000,

      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step3',
    next_success: 'step4.2',
    next_failure: 'step4.1',
  },{
    key: 'step4.1',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим работу, которая только \nчто была совершена краном:',
        settings: { fontSize: 18, y: 55}
      },

      checkSuccessFn: (data) => data.inputWork === 10000,

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: -130, width: 550},
      },
      customFormulaCode: 'crane9/workFormula2',

      errorVisible: true,
      errorSettings: {
        text: 'Повтори расчет',
        fill: 'red',
        x: 17, y: 40, fontSize: 13, fontStyle: 'bold'
      },
      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step3',
    next_success: 'step5',
  },{
    key: 'step4.2',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим работу, которая только \nчто была совершена краном:',
        settings: { fontSize: 18, y: 55}
      },


      completeBlockVisible: true,
      boxCompletePos: true,
    },
    previous: 'step3',
    next_success: 'step5',
    switch_at: 2
  },

  // --------- STEP 5 ----------


  {
    key: 'step5',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Была совершена работа 10 тысяч \nджоулей. Обрати внимание, что \nподъем занял 10 секунд.',
        settings: { fontSize: 18, y: 45}
      },

      boxCompletePos: true,

      inputSuccess: 1000,
      liftingHeight: 10,
    },
    previous: 'step4',
    next_success: 'step6',
    switch_at: 6
  },

  // --------- STEP 6 ----------

  {
    key: 'step6',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Мощность равна отношению работы \nко времени, за которое она была \nсовершена:',
        settings: { fontSize: 16, y: 60}
      },

      cardFormulaVisible: true,
      customFormulaVisible: true,
      cardFormulaSettings: {
        card: {x: 0, width: 150, height: 100},
      },
      customFormulaCode: 'crane9/powerFormula1',


      boxCompletePos: true,
    },
    previous: 'step5',
    next_success: 'step7',
    switch_at: 6
  },

    // --------- STEP 7 ----------

  {
    key: 'step7',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим мощность двигателя \nподъемного крана. \nЗаполни значения выполненной работы \nи затраченного времени:',
        settings: { fontSize: 16, y: 40}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: 100, width: 300, height: 130},
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 150
      },
      customFormulaCode: 'crane9/powerFormula2',

      checkSuccessFn: (data) => data.inputWork === 10000 && data.inputTime === 10,

      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step6',
    next_success: 'step7.2',
    next_failure: 'step7.1',
  },
  {
    key: 'step7.1',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим мощность двигателя \nподъемного крана. \nЗаполни значения выполненной работы \nи затраченного времени:',
        settings: { fontSize: 16, y: 40}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: 100, width: 300, height: 130},
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 150
      },
      customFormulaCode: 'crane9/powerFormula2',


      checkSuccessFn: (data) => data.inputWork === 10000 && data.inputTime === 10,

      errorVisible: true,
      errorSettings: {
        text: 'Повтори расчет',
        fill: 'red',
        x: 27, y: 90, fontSize: 13, fontStyle: 'bold'
      },
      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step7',
    next_success: 'step7.2',
    next_failure: 'step7.1',
  },
  {
    key: 'step7.2',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим мощность двигателя \nподъемного крана. \nЗаполни значения выполненной работы \nи затраченного времени:',
        settings: { fontSize: 16, y: 40}
      },

      completeBlockVisible: true,
      boxCompletePos: true,
    },
    previous: 'step7',
    next_success: 'step8',
    switch_at: 2
  },

    // --------- STEP 8 ----------

  {
    key: 'step8',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим мощность двигателя \nподъемного крана:',
        settings: { fontSize: 16, y: 60}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: -43, y: -20, width: 480, height: 130},
        time: 10
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 130
      },
      customFormulaCode: 'crane9/powerFormula3',
      checkSuccessFn: (data) => data.inputPower === 1000,

      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step7',
    next_success: 'step8.2',
    next_failure: 'step8.1',
  },
  {
    key: 'step8.1',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим мощность двигателя \nподъемного крана:',
        settings: { fontSize: 16, y: 60}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: -43, y: -20, width: 480, height: 130},
        time: 10
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 130
      },
      customFormulaCode: 'crane9/powerFormula3',
      checkSuccessFn: (data) => data.inputPower === 1000,

      errorVisible: true,
      errorSettings: {
        text: 'Повтори расчет',
        fill: 'red',
        x: 27, y: 90, fontSize: 13, fontStyle: 'bold'
      },
      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step8',
    next_success: 'step8.2',
    next_failure: 'step8.1',
  },
  {
    key: 'step8.2',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Определим мощность двигателя \nподъемного крана:',
        settings: { fontSize: 16, y: 60}
      },

      completeBlockVisible: true,
      boxCompletePos: true,
    },
    previous: 'step8',
    next_success: 'step9',
    switch_at: 2
  },

    // --------- STEP 9 ----------

  {
    key: 'step9',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Мощность двигателя крана - \n1000 ватт. Удобнее будет \nвыразить в киловаттах:',
        settings: { fontSize: 22, y: 40}
      },


      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: 0, y: 20, width: 400},
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 130
      },
      customFormulaCode: 'crane9/powerFormula4',
      checkSuccessFn: (data) => data.inputPower === 1,

      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step8',
    next_success: 'step9.2',
    next_failure: 'step9.1',
  },
  {
    key: 'step9.1',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Мощность двигателя крана - \n1000 ватт. Удобнее будет \nвыразить в киловаттах:',
        settings: { fontSize: 22, y: 40}
      },


      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: 0, y: 20, width: 400},
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 130
      },
      customFormulaCode: 'crane9/powerFormula4',
      checkSuccessFn: (data) => data.inputPower === 1,

      errorVisible: true,
      errorSettings: {
        text: 'Повтори расчет',
        fill: 'red',
        x: 23, y: 40, fontSize: 13, fontStyle: 'bold'
      },
      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step9',
    next_success: 'step9.2',
    next_failure: 'step9.1',
  },
  {
    key: 'step9.2',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Мощность двигателя крана - \n1000 ватт. Удобнее будет \nвыразить в киловаттах:',
        settings: { fontSize: 22, y: 40}
      },


      completeBlockVisible: true,
      boxCompletePos: true,
    },
    previous: 'step9',
    next_success: 'step10',
    switch_at: 2
  },

    // --------- STEP 10 ----------

  {
    key: 'step10',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Какой мощности требуется \nдвигатель, чтобы совершить \nпогрузку за 5 секунд?',
        settings: { fontSize: 22, y: 40}
      },


      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: 100, y: 20, width: 250, height: 90},
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 150, y: 130
      },
      customFormulaCode: 'crane9/powerFormula5',
      checkSuccessFn: (data) => data.inputPower === 2,

      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step9',
    next_success: 'step13',
    next_failure: 'step11',
  },

    // --------- STEP 11 ----------

  {
    key: 'step11',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Чтобы определить требуемую \nмощность, заполни необходимую работу, \nкоторую требуется совершить \nи время (5 секунд):',
        settings: { fontSize: 17, y: 30}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: 100, width: 300, height: 130},
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 150
      },
      customFormulaCode: 'crane9/powerFormula2',
      checkSuccessFn: (data) => data.inputWork === 10000 && data.inputTime === 5,

      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step10',
    next_success: 'step11.2',
    next_failure: 'step11.1',
  },
  {
    key: 'step11.1',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Чтобы определить требуемую \nмощность, заполни необходимую работу, \nкоторую требуется совершить \nи время (5 секунд):',
        settings: { fontSize: 17, y: 30}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: 100, width: 300, height: 130},
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 150
      },
      customFormulaCode: 'crane9/powerFormula2',
      checkSuccessFn: (data) => data.inputWork === 10000 && data.inputTime === 5,

      errorVisible: true,
      errorSettings: {
        text: 'Повтори расчет',
        fill: 'red',
        x: 27, y: 90, fontSize: 13, fontStyle: 'bold'
      },
      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step11',
    next_success: 'step11.2',
    next_failure: 'step11.1',
  },
  {
    // Галочка Урок пройден
    key: 'step11.2',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Чтобы определить требуемую \nмощность, заполни необходимую работу, \nкоторую требуется совершить \nи время (5 секунд):',
        settings: { fontSize: 17, y: 30}
      },


      boxCompletePos: true,
      completeBlockVisible: true,
    },
    previous: 'step11',
    next_success: 'step12',
    switch_at: 2
  },

    // --------- STEP 12 ----------

  {
    key: 'step12',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Какой мощности требуется \nдвигатель, чтобы совершить \nпогрузку за 5 секунд?:',
        settings: { fontSize: 18, y: 40}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: -43, y: -20, width: 480, height: 130},
        time: 5
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 130
      },
      customFormulaCode: 'crane9/powerFormula3',
      checkSuccessFn: (data) => data.inputPower === 2000,

      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step11',
    next_success: 'step10',
    next_failure: 'step12.1',
  },
  {
    key: 'step12.1',

    patchData: {
      ...initialData,
      ...defaultCardData,
      text: {
        value: 'Какой мощности требуется \nдвигатель, чтобы совершить \nпогрузку за 5 секунд?:',
        settings: { fontSize: 18, y: 40}
      },

      cardFormulaVisible: true,
      formulaWithInputVisible: true,
      cardFormulaSettings: {
        card: {x: -43, y: -20, width: 480, height: 130},
        time: 5
      },
      checkBtnStg: {
        ...initialData.checkBtnStg,
        x: 170, y: 130
      },
      customFormulaCode: 'crane9/powerFormula3',
      checkSuccessFn: (data) => data.inputPower === 2000,

      errorVisible: true,
      errorSettings: {
        text: 'Повтори расчет',
        fill: 'red',
        x: 27, y: 90, fontSize: 13, fontStyle: 'bold'
      },
      boxCompletePos: true,
    },
    next_btn_hidden: true,
    previous: 'step11',
    next_success: 'step10',
    next_failure: 'step12.1',
  },



  {
    // Галочка Урок пройден
    key: 'step13',
    patchData: {
      ...initialData,
      ...defaultCardData,
      text: { value: '',},

      boxCompletePos: true,
      completeTaskVisible: true,
    },
    prev_btn_hidden: true,
    next_btn_hidden: true,
    previous: 'step12',
  },
]