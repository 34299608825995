import {Group, Rect} from "react-konva";
import React from "react";


const StepsIndicator = (props) => {
  const {
    x,y,
    items,
    itemWidth = 33,
    itemHeight = 20,
    borderStroke = '#7ACDB5',
    activeFill = '#7ACDB5',
    emptyFill = 'white',
    activeVal,
  } = props;

  const width = itemWidth;
  const height = itemHeight;
  const itemRadius = 5;

  return (
    <Group x={x} y={y}>

      {
        items.map((item, indx) => {
          const itemsLen = items.length-1;
          let cornerRadius = [0,0,0,0];
          if (indx === 0) {
            cornerRadius = [itemRadius,0,0,itemRadius];
          }
          if (indx === itemsLen) {
            cornerRadius = [0,itemRadius,itemRadius,0];
          }
          const margin = 3;

          const correctFill = (indx+1 <= activeVal-1) ? activeFill : emptyFill

          return (
            <Rect
              key={`indicator-${indx}`}
              x={(width+margin) * indx}
              width={width} height={height}
              cornerRadius={cornerRadius}
              stroke={borderStroke}
              strokeWidth={1}
              fill={correctFill}
            />
          )
        })
      }

    </Group>
  )
}

export default StepsIndicator;