import React, {useEffect, useState} from "react";
import MainContainer from "../../layout2/components/MainContainer";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import '../styles/styles.css';
import GradesForm from "../components/GradesForm";
import DistributionByClass from "../components/DistributionByClass";
import {convertClassroomsListData} from "../utils";


const Grades = (props) => {
  const {fetchClassroomsData} = props;

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    notDistributedStudentsList: [],
    distributedStudentsList: [],
    allClassroomsList: [],
  });


  const fetchClassroomsList = async () => {
    const res = await fetchClassroomsData();
    if (res && res.status && res.classroomsList) {
      const classroomsList = res.classroomsList;
      const {
        notDistributedStudentsList,
        distributedStudentsList,
        allClassroomsList,
      } = convertClassroomsListData(classroomsList);

      setState({
        notDistributedStudentsList: notDistributedStudentsList,
        distributedStudentsList: distributedStudentsList,
        allClassroomsList: allClassroomsList,
      })
    }
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      await fetchClassroomsList();
      setLoading(false);
    })()
  }, [])

  const updateLocalState = (res) => {
    if (res && res.status) {
      const classroomsList = res.classroomsList;
      const {
        notDistributedStudentsList,
        distributedStudentsList,
        allClassroomsList,
      } = convertClassroomsListData(classroomsList);

      setState({
        notDistributedStudentsList: notDistributedStudentsList,
        distributedStudentsList: distributedStudentsList,
        allClassroomsList: allClassroomsList,
      })
    }
  }

  const removeClassroom = async (clsroomId) => {
    const res = await props.removeClassroomMethod(clsroomId);
    updateLocalState(res);
  };
  const removeStudent = async (studentId) => {
    const res = await props.removeClassroomMethod(undefined, studentId);
    updateLocalState(res);
  };
  const addNewClassroom = async (classroomTitle) => {
    const res = await props.addNewClassroom(classroomTitle);
    updateLocalState(res);
  };
  const updateClassroom = async ({clsroomId, newTitle, studentId}) => {
    const res = await props.updateClassroom({clsroomId, newName: newTitle, studentId});
    updateLocalState(res);
  };

  const pageKey = 'grades-page';

  return (
    <div>
      <MainContainer
        menuOnlyMobile
        pageKey={pageKey}
      >
        <div className={'grades-wrapper'}>
          <GradesForm
            loading={loading}
            grades={state.allClassroomsList}
            removeClassroom={removeClassroom}
            addNewClassroom={addNewClassroom}
            updateClassroom={updateClassroom}
          />
          <DistributionByClass
            loading={loading}
            removeStudent={removeStudent}
            updateClassroom={updateClassroom}
            allClassroomsList={state.allClassroomsList}
            distributedStudents={state.distributedStudentsList}
            notDistibutedStudents={state.notDistributedStudentsList}
          />
        </div>
      </MainContainer>
    </div>
  )
}



const mapStateToProps = (state, {match}) => {
  return {
    user: state.lessonsReducer.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewClassroom: (title) => dispatch(actions.addNewClassroom(title)),
    removeClassroomMethod: (clsroomId, studentId) => dispatch(actions.removeClassroomMethod(clsroomId, studentId)),
    updateClassroom: ({clsroomId, newName, studentId}) => dispatch(actions.updateClassroom({clsroomId, newName, studentId})),


    fetchClassroomsData: () => dispatch(actions.fetchClassroomsData()),
    logoutAction: () => dispatch(actions.logoutAction()),
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Grades);
