import React from "react";
import {Arrow, Group, Text} from 'react-konva';
import {mainColor} from "../../../utils/styles";
import {mergeRefs} from "../../../utils/common";

/**
 *  PROPS:
 *
 *  id: str - уникальный идентификатор для названия колбека
 *  ref - Ссылка на элемент контейнера
 *
 *  opacity: int - Прозрачность
 *  x: int , y: int - Координаты для изменения положения всей конструкции
 *  amplitude: int - Расстрояние, которое будет проходить стрелка (от 0 до amplitude, default 15)
 *  arrowsCount: int - Кол-во стрелок
 *  arrowsStep: int - Шаг между соседними стрелками (нужен когда стрелок больше 1, default 30)
 *  length: int - Длинна стрелки (default 50)
 *  rotation: int - Угол поворота стрелок
 *
 *  arrowColor: str - Цвет стрелки (заливка и бордеры)
 *  arrowFillColor: str - Цвет стрелки (только заливка)
 *  arrowStrokeColor: str - Цвет стрелки (только бордеры)
 *
 *  arrowsX: int, arrowsY: int - Координаты для изменения положения стрелок
 *  textX: int, textY: int - Координаты для изменения положения текста
 *
 *  text: str - Текст (default "Sample text")
 *  textWidth: int - Ширина текста (для расположения по центру)
 *  textColor: str - Цвет текста
 *  textAlign: center | left | right - Расположение текста (default "center")
 *  fontSize: int - Размер текста (default 15)
 *  fontStyle: 'italic'|'normal'|'bold' (default "italic")
 *
 **/
class MovingArrow extends React.Component {
  constructor(props) {
    super(props);

    this.arrowsRef = React.createRef();
    this.containerRef = React.createRef();

    this.arrowsItems = Array(this.props.arrowsCount || 1).fill(1);
    this.data = {
      startTime: undefined,
      prevTime: undefined,

      isArrowDown: false,

      arrowOffset: 0,
    }
  }

  componentDidMount() {
    this.props.getMovingCallback({[this.props.id]: this.move});
  }

  get arrowOffset() {
    const data = this.data;
    const amplitude = this.props.amplitude ?? 15;

    if (!data.isArrowDown) {
      if (data.arrowOffset >= amplitude) {
        this.data.isArrowDown = true;
        return amplitude
      } else {
        return data.arrowOffset + .5;
      }
    } else {
      if (data.arrowOffset <= 0) {
        this.data.isArrowDown = false;
        return 0
      } else {
        return data.arrowOffset - .4;
      }
    }
  }

  move = (time) => {
    // this.requestId = window.requestAnimationFrame(this.move);

    const data = this.data;
    data.startTime = data.startTime || time;
    data.prevTime = time;

    data.arrowOffset = this.arrowOffset;

    this.update();
  }

  update = () => {
    const containerNode = this.containerRef?.current;
    const data = this.data;
    const arrowsNode = this.arrowsRef?.current;

    if (!containerNode || !arrowsNode) return;


    arrowsNode.offsetY(-data.arrowOffset);

    // containerNode.draw();
  }

  render() {
    const {
      x, y,
      visible=true,
      opacity,
      arrowsStep=30,
      length=50,
      rotation=0,
      arrowColor,
      arrowFillColor,
      arrowStrokeColor,
      arrowsX=0, arrowsY=0,
      textX=0, textY=0,
      text="Sample text",
      textWidth,
      textAlign='center',
      fontSize=15,
      fontStyle='italic',
      textColor,
      textVisible,
      textRef,
      scale
    } = this.props;

    const correctArrowsFill = arrowColor ? arrowColor : arrowFillColor ? arrowFillColor : mainColor;
    const correctArrowsStroke = arrowColor ? arrowColor : arrowStrokeColor ? arrowStrokeColor : mainColor;
    const correctTextColor = textColor ? textColor : mainColor;
    return (
      <Group x={x} y={y} scale={scale} ref={ mergeRefs(this.props.externalRef, this.containerRef)} visible={visible} opacity={!visible ? 0 : opacity}>
        <Group ref={this.arrowsRef} rotation={rotation} x={arrowsX} y={arrowsY}>
          {
            this.arrowsItems.map((el, i) => (
              <Arrow key={`arrow-${i}`} points={[arrowsStep*i,0,arrowsStep*i,length]} stroke={correctArrowsStroke} fill={correctArrowsFill}/>
            ))
          }
        </Group>
        <Text
          x={textX} y={textY-20}
          text={text}
          ref={textRef}
          fill={correctTextColor}
          fontStyle={fontStyle}
          align={textAlign}
          width={textWidth}
          fontSize={fontSize}
          visible={textVisible}
        />
      </Group>
    )
  }
}

export default React.forwardRef((props, ref) => <MovingArrow
  externalRef={ref} {...props}
/>);