import React from "react";
import CanvasContainer from "../../../canvas/containers/CanvasContainer";
import { Image, Rect, Layer, Text, Group, Circle, Line } from "react-konva";
import useImage from 'use-image';
import cloneDeep from 'lodash.clonedeep';
import {connect} from "react-redux";
import completeImage from "../../../../images/pathandspeed/complete.png";
import backgroundImg from '../../../../images/pathandspeed/space/background.png';
import chart1Img from '../../../../images/pathandspeed/space/chart1.png';
import chart2Img from '../../../../images/pathandspeed/space/chart2.png';
import engineImg from '../../../../images/pathandspeed/space/engin.png';
import exclamationIconImg from '../../../../images/pathandspeed/space/exclamationIcon.png';
import chart1ForPointsImg from '../../../../images/pathandspeed/space/chart1ForPoints.png';
import chart2ForPointsImg from '../../../../images/pathandspeed/space/chart2ForPoints.png';
import shipImg from '../../../../images/pathandspeed/space/ship.png';
import {layout2, lsnLabelTxtStyle, lsnTitleTxtStyle, mainColor} from "../../../../utils/styles";
import {getCorrectTitle} from "../utils/trainUtils";
import Card from "../../../canvas/components/Card";
import CanvasButton from "../../../canvas/components/CanvasButton";
import CanvasResetBtn from "../../../canvas/components/CanvasResetBtn";
import CanvasInput from "../../../canvas/components/CanvasInput";
import {
  generateArray,
  getOpacityForNode, sendSuccessForScenario,
  showFailOrSuccessPopup,
  toFixed,
  toggleVisibleEl
} from "../../../../utils/common";
import * as actions from "../../../../store/actions";
import {getStaticDataByCode, getTitleByCode} from "../utils/spaceUtils";
import {getLsnNameByCode} from "../utils/common";
import {getChartImgSetting} from "../utils/utils";
import ChartsContainer from "../components/ChartContainer";
import ArrowHint from "../../../canvas/components/ArrowHint";
import CanvasChartImg from "../../../canvas/components/CanvasChartImg";
import ScenarioManager from "../../../../utils/ScenarioManager";


class Space extends React.Component {
  constructor(props) {
    super(props);

    const {pathandspeed3, pathandspeed4} = getLsnNameByCode(this.props.code);
    this.staticData = getStaticDataByCode(this.props.code);
    this.pointsItems = generateArray(this.staticData.pointsChats);


    this.stageRef = React.createRef();

    this.chartImgsRef = React.createRef();
    this.startBtnRef = React.createRef();

    this.lineStartRef = React.createRef();
    this.lineProgressRef = React.createRef();
    this.lineEndRef = React.createRef();
    this.progressTextRef = React.createRef();

    this.arrowHintRef = React.createRef();
    this.startArrowHintRef = React.createRef();
    this.cardWithInputRef = React.createRef();
    this.shipRef = React.createRef();
    this.engineRef = React.createRef();
    this.chartImg1Ref = React.createRef();
    this.chartImg2Ref = React.createRef();

    this.completeRef = React.createRef();

    this.pointsItems.forEach((el, i) => {
      this[`point${i}Ref`] = React.createRef();
    });

    this.gridChatsItems = pathandspeed3 ? {x: 6, y: 6} : {x: 6, y: 8};
    this.pointsChatsItems = this.pointsItems.map((el, i) => {
      const sec = i*5;
      let res = {x: 0, y: 0};
      if (pathandspeed3) {
        res = {x: i*54.5, y: -i*38.5};
      }
      if (pathandspeed4) {
        res = {x: i*54.7, y: -i*28.7};
        if (sec > 10 && sec < 20) {
          res = {x: i*54.5, y: -i*31.3};
        }
        if (sec === 20) {
          res = {x: i*54.5, y: -i*35.9};
        }
        if (sec > 20) {
          res = {x: i*54.5, y: -i*40.2};
        }
      }
      return res;
    });
    this.titleItems = generateArray(this.staticData.stepsCount);
    this.titleItems.forEach((el, i) => {
      this[`titleStep${i}Ref`] = React.createRef();
    })


    this.state = {};
    this.itemsRowArr = Array(5).fill(1);
    this.itemsColumnArr = Array(5).fill(1);
    this.initialData = {
      startTime: undefined,
      prevTime: undefined,
      timedeltaSec: undefined,

      shipStartTime: undefined,
      shipPrevTime: undefined,
      shipTimedeltaSec: undefined,

      shipChartLabel: 'Корабль',

      start: false,
      isLineDragged: false,

      step: 0,

      inputAverageSpeed: undefined,// км/ч

      shipSpeed: 20,// км/с
      shipPath: 0,

      itemsRowArr: this.itemsRowArr,
      itemsColumnArr: this.itemsColumnArr,

      selectedData: this.itemsColumnArr.reduce((accum, el, i) => {
        accum[`line${i+1}`] = undefined;
        return accum
      }, {}),

      chartImgSetting: getChartImgSetting(this.props.code, this.itemsRowArr),
    };
    this.data = cloneDeep(this.initialData);

    this.data.itemsColumnArr.forEach((el, columnI) => {
      this[`dragLine${columnI}Ref`] = React.createRef();
      this[`dragLineContainer${columnI}Ref`] = React.createRef();

      this.data.itemsRowArr.forEach((el, rowI) => {
        this[`actionBox${rowI}${columnI}Ref`] = React.createRef();
      });
    })
    this.scenarioManager = new ScenarioManager([{key: 'start'}, {key: 'success'}], this);
  }

  componentDidMount() {
    window.requestAnimationFrame(this.move);
    this.setState(() => ({
      code: this.props.code
    }))
  }

  _resetCallback = () => {}
  getResetCallback = (callback) => {
    this._resetCallback = callback
  }


  onClickReset = () => {
    this.data = {...this.initialData};
    this.updateStage();
    this.setState(() => ({ code: this.props.code }))
    this._resetCallback();
  };
  onClickStart = () => {
    this.data.start = true;
  };

  onCheckResult = () => {
    const data = this.data;
    const {pathandspeed3, pathandspeed4} = getLsnNameByCode(this.props.code);

    let success = false;
    if (pathandspeed3) {
      if (data.step === 0) {
        success = this.data.inputAverageSpeed === 72000;
      } else if (data.step === 1) {
        success = Object.keys(this.data.selectedData).every((k) => this.data.selectedData[k] === 72);

        if (success) {
          sendSuccessForScenario(this);
        }
      }
    }
    if (pathandspeed4) {
      if (data.step === 1) {
        success = (
          ['1','2'].every((k) => this.data.selectedData[`line${k}`] === 20) &&
          ['3'].every((k) => this.data.selectedData[`line${k}`] === 30) &&
          ['4','5'].every((k) => this.data.selectedData[`line${k}`] === 40)
        );
        if (success) {
          sendSuccessForScenario(this);
        }
      }
    }

    if (success) {
      if (this.data.step < this.staticData.maxStep) {
        this.data.step += 1;
      }
    }
    showFailOrSuccessPopup(this, success);
  };

  onChangeInput = (val) => {
    this.data.inputAverageSpeed = toFixed(val);
  };
  getCorrectSpeed = (newSpeed, koef) => {
    const data = this.data;
    return data.shipSpeed >= newSpeed ? data.shipSpeed : data.shipSpeed+koef;
  }
  get shipSpeed() {
    const { pathandspeed4 } = getLsnNameByCode(this.props.code);
    const data = this.data;
    let correctShipSpeed = data.shipSpeed;
    if (pathandspeed4) {
      if (data.shipTimedeltaSec >= 10 && !data.shipTimedeltaSec < 20) {
        correctShipSpeed = this.getCorrectSpeed(40, .03);
      }

      if (data.shipTimedeltaSec >= 20) {
        correctShipSpeed = 40;
      }
    }
    return correctShipSpeed;
  }
  get shipPath() {
    const { pathandspeed4 } = getLsnNameByCode(this.props.code);
    const data = this.data;
    const {maxShipPath} = this.staticData;
    let res = data.shipPath;
    if (data.shipTimedeltaSec && (data.shipPath <= maxShipPath)) {
      res = data.shipSpeed * data.shipTimedeltaSec

      if (pathandspeed4 && data.shipTimedeltaSec >= 20) {
        res -= 293;
      }
    }
    return res;
  }

  get shipTimedeltaSec() {
    const { maxShipSec } = this.staticData;
    const data = this.data;
    if (!data.shipTimedeltaSec || data.shipTimedeltaSec < maxShipSec ) {
      return Math.abs((data.shipStartTime - data.shipPrevTime) / 300);
    }
    return maxShipSec;
  }

  getNodes = (stageNode) => {
    const titles = this.titleItems.reduce((accum, el, i) => {
      accum[`titleStep${i}Node`] = this[`titleStep${i}Ref`]?.current;
      return accum
    }, {});
    const dragLineNodes = {};
    const actionBoxNodes = {};
    this.data.itemsColumnArr.forEach((el, cellI) => {
      dragLineNodes[`dragLine${cellI}Node`] = this[`dragLine${cellI}Ref`]?.current;
      dragLineNodes[`dragLineContainer${cellI}Node`] = this[`dragLineContainer${cellI}Ref`]?.current;

      this.data.itemsRowArr.forEach((el, rowI) => {
        actionBoxNodes[`actionBox${rowI}${cellI}Node`] = this[`actionBox${rowI}${cellI}Ref`]?.current;
      });
    })
    const points = {};
    this.pointsItems.forEach((el, i) => {
      points[`point${i}Node`] = this[`point${i}Ref`]?.current;
    });
    return ({
      ...dragLineNodes,
      ...actionBoxNodes,
      ...titles,
      ...points,
      chartImgsNode: this.chartImgsRef?.current,
      chartImg1Node: this.chartImg1Ref?.current,
      chartImg2Node: this.chartImg2Ref?.current,
      startBtnNode: this.startBtnRef?.current,
      arrowHintNode: this.arrowHintRef?.current,
      startArrowHintNode: this.startArrowHintRef?.current,
      cardWithInputNode: this.cardWithInputRef?.current,
      shipNode: this.shipRef?.current,
      engineNode: this.engineRef?.current,

      lineStartNode: this.lineStartRef?.current,
      lineProgressNode: this.lineProgressRef?.current,
      lineEndNode: this.lineEndRef?.current,
      progressTextNode: this.progressTextRef?.current,
    });
  }

  move = (time) => {
    const data = this.data;
    const {maxShipPath} = this.staticData;
    this.requestId = window.requestAnimationFrame(this.move);
    const { pathandspeed3, pathandspeed4 } = getLsnNameByCode(this.props.code);

    this.startTimeScenario = this.startTimeScenario || time;
    this.scenarioManager.passTimestampSec((time - this.startTimeScenario)/1000);

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    if (data.start) {
      data.shipStartTime = data.shipStartTime || time;
      data.shipPrevTime = time;

      data.shipPath = this.shipPath;
      data.shipTimedeltaSec = this.shipTimedeltaSec;
      if (pathandspeed4) {
        data.shipSpeed = this.shipSpeed;

        if (data.shipPath >= maxShipPath) {
          this.data.step = 1;
        }
      }
    }

    this.updateStage();
  };

  updateStage() {
    const data = this.data;
    const {maxShipPath} = this.staticData;

    const stageNode = this.stageRef?.current;
    if (!stageNode) return;

    const {
      chartImg1Node,
      chartImg2Node,
      startBtnNode,
      arrowHintNode,
      startArrowHintNode,
      cardWithInputNode,
      shipNode,
      engineNode,
      completeNode,

      lineStartNode,
      lineProgressNode,
      lineEndNode,
      progressTextNode,
    } = this.getNodes(stageNode);
    const nodes = this.getNodes(stageNode);
    const { pathandspeed3, pathandspeed4 } = getLsnNameByCode(this.props.code);

    toggleVisibleEl(startArrowHintNode, data.start);
    toggleVisibleEl(startBtnNode, data.start);

    const shipmentOffset = pathandspeed3 ? -data.shipPath*2.2 : -data.shipPath*1.6
    shipNode.offsetX(shipmentOffset);
    let engineIsActive = data.start && (data.shipPath < maxShipPath);
    if (pathandspeed4) {
      engineIsActive = data.shipTimedeltaSec >= 10 && data.shipTimedeltaSec < 20;
    }
    engineNode.opacity(Number(engineIsActive));

    if (
      pathandspeed3 &&
      (data.shipTimedeltaSec == null ||
      (data.start && data.shipTimedeltaSec >= 1))
    ) {
      toggleVisibleEl(cardWithInputNode, !data.start || this.data.step > 0);
    }

    toggleVisibleEl(chartImg1Node, this.data.step !== 1);
    toggleVisibleEl(arrowHintNode, this.data.isLineDragged);

    this.titleItems.forEach((el, i) => {
      const node = nodes[`titleStep${i}Node`];
      const titleText = getTitleByCode(this.props.code, i);
      node.text(titleText);
      toggleVisibleEl(node, data.step !== i);
    })

    this.pointsItems.forEach((el, i) => {
      toggleVisibleEl(nodes[`point${i}Node`], !data.start || data.shipTimedeltaSec <= (i*5)-1);
    })

    if (pathandspeed4) {
      const maxProgress = 110;
      toggleVisibleEl(lineStartNode, !data.shipTimedeltaSec || data.shipTimedeltaSec <= 10);
      toggleVisibleEl(progressTextNode, !data.shipTimedeltaSec || data.shipTimedeltaSec <= 10);
      toggleVisibleEl(lineProgressNode, !data.shipTimedeltaSec || data.shipTimedeltaSec <= 10);
      toggleVisibleEl(lineEndNode, !data.shipTimedeltaSec || data.shipTimedeltaSec <= 20);
      if (data.shipTimedeltaSec && data.shipTimedeltaSec >= 10) {
        const points = lineProgressNode.points();
        points[2] = points[2] < maxProgress ? points[2]+.58 : maxProgress;
        lineProgressNode && lineProgressNode.points(points);
      }
    }

    stageNode.draw();
  }

  Canvas = () => {
    const { pathandspeed3, pathandspeed4 } = getLsnNameByCode(this.props.code);

    const [background] = useImage(backgroundImg);
    const [chart1] = useImage(chart1Img);
    const [chart2] = useImage(chart2Img);
    const [engine] = useImage(engineImg);
    const [ship] = useImage(shipImg);
    const [exclamationIcon] = useImage(exclamationIconImg);
    const [chart1ForPoints] = useImage(chart1ForPointsImg);
    const [chart2ForPoints] = useImage(chart2ForPointsImg);
    const [complete] = useImage(completeImage);

    return (
      <React.Fragment>
        <Image image={background} />

        {
          this.titleItems.map((el, i) => (
              <Text
                ref={this[`titleStep${i}Ref`]}
                text={''}
                {...lsnTitleTxtStyle}
                fill={'white'}
                fontSize={25}
                x={40} y={30}
                opacity={Number(!i)}
              />
          ))
        }

        <Group x={70} y={230} ref={this.chartImgsRef}>
          <Image image={pathandspeed3 ? chart1ForPoints : chart2ForPoints} />
          <Group x={53} y={252}>
            {
              this.pointsChatsItems.map((el, i) => (
                <Circle key={'point-'+i} ref={this[`point${i}Ref`]} radius={3} fill={'rgba(0,0,0,0.9)'} strokeWidth={1} opacity={0} {...el}/>
              ))
            }
          </Group>
          <Group x={53} y={252}>
            <Line x={109} y={-20} ref={this.lineStartRef} points={[0,0,0,-38]} stroke={'purple'} strokeWidth={.5} dash={[7, 3]} opacity={0}/>
            <Line x={109} y={-27} ref={this.lineProgressRef} points={[0,0,0,0]} strokeWidth={15} stroke={'#9ea4d8'} opacity={0}/>
            <Line x={219} y={-20} ref={this.lineEndRef} points={[0,0,0,-123]} stroke={'purple'} strokeWidth={.5} dash={[7, 3]} opacity={0}/>

            <Text
              ref={this.progressTextRef}
              x={110} y={-15}
              text={'Двигатели работали'}
              fill={'#7a84e2'}
              stroke={'#7a84e2'}
              strokeWidth={.3}
              opacity={0}
            />
          </Group>
        </Group>

        <Group x={-80} y={160} ref={this.shipRef}>
          <Image ref={this.engineRef} image={engine} x={-30} y={15}/>
          <Image image={ship} width={80} height={40}/>
        </Group>


        <Group x={650} y={350}>
          <Group ref={this.startArrowHintRef}>
            <ArrowHint
              x={-150} y={-110}
              textY={50}
              fontStyle={'bold'}
              arrowX={120} arrowY={120}
              text={'Нажми на "Старт", чтобы \nзапустить корабль'}
              color={'white'}
              rotation={180}
            />
          </Group>

          <CanvasButton
            btnRef={this.startBtnRef}
            text={'Старт'}
            onClick={() => this.onClickStart()}
            fontSize={22}
            strokeWidth={.2}
            btnStroke={'white'}
            btnStrokeWidth={.7}
            width={150}
            height={40}
          />
        </Group>

        <Group ref={this.cardWithInputRef} x={630} y={270} opacity={0} visible={false}>
          <Card height={120} width={180}>
            <Group y={-40}>
              <Image image={exclamationIcon} width={25} height={30}/>
              <Text x={10} text={'Не забудь перевести в километры в час'} align={'center'} width={200} fontSize={14} fontStyle={'bold'} fill={'white'}/>
            </Group>
            <Text text={'Скорость, км/ч:'} {...lsnLabelTxtStyle} fontStyle={'bold'} x={20} y={20}/>
            <CanvasInput
              id={'1'}
              x={20} y={50}
              width={100}
              height={35}
              fontSize={30}
              stage={this.stageRef?.current}
              onInput={(val) => {
                this.onChangeInput(val);
              }}
              value={this.data.inputAverageSpeed}
            />
          </Card>
          <CanvasButton
            text={'Проверить'}
            onClick={() => this.onCheckResult()}
            fontSize={25}
            strokeWidth={.2}
            btnStroke={'white'}
            btnStrokeWidth={.7}
            width={200}
            height={50}
            x={-10} y={140}
          />
        </Group>




        {/* -------- CHARTS ----------- */}
        <Group x={470} y={205} ref={this.chartImg1Ref} opacity={0}>
          <CanvasButton
            text={'Проверить'}
            onClick={() => this.onCheckResult()}
            fontSize={25}
            strokeWidth={.2}
            btnStroke={'white'}
            btnStrokeWidth={.7}
            width={200}
            height={50}
            x={80} y={-50}
          />
          <Group>
            <Image image={ pathandspeed3 ? chart1 : chart2} width={370} height={370}/>
            <CanvasChartImg
              $this={this}
              x={81}
              y={225}
              getResetCallback={this.getResetCallback}
              onDragStart={() => this.data.isLineDragged = true}
            />
          </Group>
        </Group>

        <CanvasResetBtn
          x={853}
          onClick={() => this.onClickReset()}
        />

        {/*<Group ref={this.completeRef} opacity={0}>*/}
        {/*  <Image x={450} y={208} width={410} height={300} image={complete}/>*/}
        {/*</Group>*/}
      </React.Fragment>
    )
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        <CanvasContainer stageRef={this.stageRef} lessonCode={this.props.code}>
          <Layer preventDefault={false}>
            <this.Canvas/>
          </Layer>
        </CanvasContainer>

      </div>
    )
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeSuccessVisible: (visible) => dispatch(actions.changeSuccessVisible(visible)),
  changeFailureVisible: (visible) => dispatch(actions.changeFailureVisible(visible)),
  addLessonResult: (lesson_id, result, detailed, create_new) => dispatch(actions.addLessonResult(lesson_id, result, detailed, create_new)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Space);

const styles = {
  mainContainer: {
    background: 'black'
  }
};
