import React from "react";
import {useHistory} from "react-router-dom";
import {getCodeUrlByTaskCode} from "../../layout2/utils";
import completeImg from "../images/completeIcon.png";
import greeceBookImg from "../images/greeceBook.png";
import { openNotificationWithIcon } from "../../../../utils/common";


export const getConfigByTaskCode = (key) => {
  let res = {pos: {left: 0, top: 0}};

  switch (key) {
    case 'lift_block1':
      res = { pos: {left: 1540, top: 640, width: 180, height: 180} };
      break
    case 'lift_bigger_block1':
      res = { pos: {left: 1370, top: 850, width: 180, height: 180} };
      break
    case 'greece_map_video1':
      res = { pos: {left: 1580, top: 230, width: 280, height: 280} };
      break
    case 'pulley1':
      res = { pos: {left: 545, top: 165, width: 180, height: 180} };
      break
    case 'lift_load1':
      res = { pos: {left: 323, top: 190, width: 200, height: 200} };
      break
    case 'blocks_rope_len1':
      res = { pos: {left: 415, top: 855, width: 170, height: 170} };
      break
    case 'greece_map_video2':
      res = { pos: {left: 65, top: 600, width: 180, height: 180} };
      break
    case 'peryshkin23':
      res = { pos: {left: 620, top: 855, width: 170, height: 170} };
      break
    case 'greece_blocks1':
      res = { pos: {left: 295, top: 430, width: 180, height: 180} };
      break
    case 'greece_blocks2':
      res = { pos: {left: 1065, top: 50, width: 180, height: 180} };
      break
    case 'greece_blocks3':
      res = { pos: {left: 805, top: 50, width: 180, height: 180} };
      break
    case 'greece_blocks4':
      res = { pos: {left: 670, top: 510, width: 180, height: 180} };
      break
    case 'greece_blocks5':
      res = { pos: {left: 1345, top: 50, width: 180, height: 180} };
      break
    case 'greece_blocks6':
      res = { pos: {left: 818, top: 847, width: 170, height: 170} };
      break
    case 'fix_and_lift_load1':
      res = { pos: {left: 1030, top: 550, width: 170, height: 170} };
      break
    case 'pulley_game1':
      res = { pos: {left: 1208, top: 503, width: 170, height: 170} };
      break

    case 'fixed1':
      res = { pos: {left: 1700, top: 700, width: 130, height: 130} };
      break
    case 'movable1':
      res = { pos: {left: 1350, top: 700, width: 130, height: 130} };
      break
    case 'papason4':
      res = { pos: {left: 1600, top: 503, width: 130, height: 130} };
      break
    case 'ropewinding1':
      res = { pos: {left: 500, top: 730, width: 130, height: 130} };
      break
  }


  return res
}

const FarmMapElement2 = (props) => {
  const prevTaskDontComplete = props.prevItem ? props.prevItem.complete : true;
  const currentTaskNeedPayment = (Boolean(props.prevItem?.paymentRedirect) && !props.coursePaid) || false;
  const isStudentVideo = !props.isTeacher && props.teacher_video;
  const isStudentPresentation = !props.isTeacher && props.presentation;
  const disabled = !props.isOpen && (
    (props.disabled || !prevTaskDontComplete) || currentTaskNeedPayment || isStudentVideo || isStudentPresentation
  ) && !props.isTeacher;
  const {pos, size} = getConfigByTaskCode(props.lessonCode);

  const history = useHistory();

  const taskCode = getCodeUrlByTaskCode(props.lessonCode);
  let status = props.complete ? 'success' : 'active';
  if (disabled) {
    status = 'disabled';
  }

  let className = 'farm-map-item--'+status

  const isPresentation = props.presentation;
  let extraStyles = {}
  if (isPresentation) {
    extraStyles = {animation: 'none', background: 'none', border: '0', transform: 'scale(.7)'}
  }
  return (
    <div
      id={props.lessonCode}
      aria-disabled={disabled}
      className={'farm-map-item farm-map-item--greece '+className}
      onClick={async (e) => {
        if (!disabled) {
          if (!props.isTeacher) {
            let {available: isAvailable} = await props.preOpen();
            console.log('debug on is available', isAvailable);
            if (!isAvailable) {
              openNotificationWithIcon('info',  [
                'Slow down...',
                'Too fast!',
                'Relax for a second',
                'Take a breath.',
                'Wait a bit'
              ][Math.floor(Math.random() * 5)]);
              return
            }
          }
          const pathName = history.location.pathname.replaceAll('/', '');
          if (props.link) {
            window.location.pathname = props.link;
          } else {
            history.push(`/${pathName}/en/${taskCode}`);
          }
          props.setTaskModalVisible(true);
        }

        // if (!disabled) {
        //   props.setTaskSrc(`/task/ru/${taskCode}`);
        // }
      }}
      style={{...pos, ...extraStyles}}
    >
      {
        isPresentation ? (
          <img src={greeceBookImg} alt="" style={{position: 'relative', left: '60px', top: '30px', zIndex: 2}}/>
        ):null
      }
      {
        !disabled && props.isTeacher ? (
          <p className={'farm-map-item--number'}>
            {
              !isPresentation ? (
                <b>{props.index + 1}</b>
              ):null
            }
            {
              props.teacher_video
                ? '(Видео)'
                : props.presentation ?
                ' Презентация '
                : ''
            }
          </p>
        ) : null
      }
      {
        props.complete ? (
          <img className={'farm-map-item--success-img'} src={completeImg} alt=""/>
        ) : null
      }
    </div>
  )
}

export default FarmMapElement2;
