import Victor from "victor";
import { pxVictor } from "./index";

const getStateByCode = (code) =>
{
  let initialState = {
    timer: 0,

    start: null,

    docking: false,
    startAfterDocking: null,

    running: false,
    explosion: false,

    shipPos: Victor(0, 0).multiply(pxVictor),
    stationPos: Victor(0, 0).multiply(pxVictor),

    animationShown: false
  };
  if (code === 'space1') {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 4.00, // m/s; set by the client
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 0.7, // ton

      stationSpeed: Victor(0, 0), // m/s
      stationMass: 1.5, // ton

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1), // Stop secondary (Y) engine but keep main (X),

      asteroidTime: Math.random() * 5
    }
  }
  if (code === 'space2') {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 2, // m/s; set by the client
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 0.85, // ton

      stationSpeed: Victor(0, 0), // m/s
      stationMass: 2.8, // ton

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1) // Stop secondary (Y) engine but keep main (X)
    }
  }
  else if (code === 'space3')
  {
    initialState = {
      ...initialState,
      distance: 20, // m
      boxCount: 2,
      boxSpeed: 10, // m/s
      shipSpeed: Victor(0, 0), // m/s
      boxMass: 0.01, // ton

      shipMass: 0.65, // ton

      stationSpeed: Victor(0, 0), // m/s
      stationMass: 0.7, // ton

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      boxPos: Victor((150-60) / 17.5, 20).multiply(pxVictor),
      prevShipTimedelta: 0,

      stopEngine: Victor(0, 1)
    }
  }
  else if (code === 'space4') {
    initialState = {
      ...initialState,
      distance: 20, // m
      shipSpeed: Victor(0, 0), // m/s
      stationSpeed: Victor(0, 0), // m/s
      // Любые массы ставить нельзя, потому что секунды целочисленные.
      shipMass: 0.55, // ton
      stationMass: 1.5, // ton
      engineTimeX: 2, // sec

      impulseIncreasePerSecond: 0.18, // ton*m/sec

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1),
    }
  }
  else if (code === 'space5') {
    const shipSpeed = Victor(0, -0.7);
    const shipMass = 0.7;
    const impulseIncreaseX = 0.16;
    const impulseIncreaseY = 0.045;
    const engineTimeX = 2;
    const correctEngineTimeX = 8.75;
    const distance = Victor(20, 10);

    const accelerationX = impulseIncreaseX / shipMass;
    const flightTime = distance.x / (accelerationX * correctEngineTimeX) + correctEngineTimeX / 2;
    // Столько корабль пролетит по вертикали при правильном ответе
    const shipOffsetY = shipSpeed.y**2 * shipMass / (2 * impulseIncreaseY);

    initialState = {
      ...initialState,
      distance: distance, // m
      shipSpeed: shipSpeed, // m/s
      stationSpeed: Victor(0, (distance.y - shipOffsetY) / flightTime),
      shipMass: shipMass, // ton
      stationMass: 1.5, // ton
      engineTimeX: engineTimeX, // sec
      engineTimeY: 2, // sec

      impulseIncreaseX: impulseIncreaseX, // ton*m/sec
      impulseIncreaseY: impulseIncreaseY, // ton*m/sec

      initialShipSpeed: shipSpeed,

      // END POS
      endShipPos: Victor(500 / 17.5, 20 + distance.y/2).multiply(pxVictor), // in metres
      endStationPos: Victor(850 / 17.5, 20 + distance.y/2 - shipOffsetY).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20 + distance.y/2).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20 - distance.y/2).multiply(pxVictor),

      stopEngine: Victor(1, 0),
    }
  }
  else if (code === 'space6')
  {
    initialState = {
      ...initialState,
      distance: 20, // m
      shipSpeed: Victor(0, 0), // m/s
      stationSpeed: Victor(0, 0), // m/s
      // Любые массы ставить нельзя, потому что секунды целочисленные.
      shipMass: 0.65, // ton
      stationMass: 1.3, // ton
      engineTimeX: 2, // sec

      impulseIncreasePerSecond: 0.17, // ton*m/sec

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1),
    }
  }

  else if (code === 'space7') {
    const shipSpeed = Victor(0, -0.6);
    const shipMass = 0.7;
    const impulseIncreaseX = 0.17;
    const impulseIncreaseY = 0.055;
    const engineTimeX = 2;
    const correctEngineTimeX = 8.823;
    const distance = Victor(40, 10);

    const accelerationX = impulseIncreaseX / shipMass;
    const flightTime = distance.x / (accelerationX * correctEngineTimeX) + correctEngineTimeX / 2;
    // Столько корабль пролетит по вертикали при правильном ответе
    const shipOffsetY = shipSpeed.y**2 * shipMass / (2 * impulseIncreaseY);

    initialState = {
      ...initialState,
      distance: distance, // m
      shipSpeed: shipSpeed, // m/s
      stationSpeed: Victor(0, (distance.y - shipOffsetY) / flightTime),
      shipMass: shipMass, // ton
      stationMass: 0.8, // ton
      engineTimeX: engineTimeX, // sec
      engineTimeY: 2, // sec

      impulseIncreaseX: impulseIncreaseX, // ton*m/sec
      impulseIncreaseY: impulseIncreaseY, // ton*m/sec

      initialShipSpeed: shipSpeed,

      // END POS
      endShipPos: Victor(500 / 17.5 + 15, 20 + distance.y/2).multiply(pxVictor), // in metres
      endStationPos: Victor(850 / 17.5 + 15, 20 + distance.y/2 - shipOffsetY).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5 - 5, 20 + distance.y/2).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5 + 15, 20 - distance.y/2).multiply(pxVictor),

      stopEngine: Victor(1, 0),
    }
  }
  else if (code === 'space8') {
    const distance = Victor(40, 10);
    const angle = Math.PI * 30 / 180;
    const shipSpeed = Victor(2 * Math.cos(angle), -2 * Math.sin(angle));
    const shipMass = 0.65;
    const impulseIncreaseY = 0.066;
    const shipOffsetY = shipSpeed.y**2 * shipMass / (2 * impulseIncreaseY);

    const flightTime = distance.x / shipSpeed.x;

    initialState = {
      ...initialState,
      distance: distance, // m
      shipSpeed: shipSpeed, // m/s
      stationSpeed: Victor(0, (distance.y - shipOffsetY) / flightTime), // m/s
      shipMass: shipMass, // ton
      stationMass: 1.5, // ton
      engineTimeY: 2, // sec
      engineTimeX: 0,

      impulseIncreaseY: impulseIncreaseY, // ton*m/sec
      impulseIncreaseX: 0,

      initialShipSpeed: shipSpeed,

      // END POS
      endShipPos: Victor(500 / 17.5 + 15, 20 + distance.y/2).multiply(pxVictor), // in metres
      endStationPos: Victor(850 / 17.5 + 15, 20 + distance.y/2 - shipOffsetY).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5 - 5, 20 + distance.y/2).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5 + 15, 20 - distance.y/2).multiply(pxVictor),

      stopEngine: Victor(1, 0),
    }
  }

  // Training tasks
  else if (code === 'training1') {
    let shipSpeed = Victor(-5, 0);

    initialState = {
      ...initialState,
      distance: 40, // m, from one side of the screen to the other
      shipSpeed: shipSpeed, // m/s
      shipMass: 0.55, // ton
      engineTimeX: 10, // sec

      impulseIncreasePerSecond: 0.25, // ton*m/sec

      shipCenter: Victor(42, 20).multiply(pxVictor),

      stopEngine: Victor(1, 1),

      initialShipSpeed: shipSpeed,
      needButton: true,
      engineIsOn: false,
      prevShipTimedelta: 0,
      coeff: 50
    }
  }

  else if (code === 'training2') {
    initialState = {
      ...initialState,
      distance: 40, // m, from one side of the screen to the other
      shipSpeed: Victor(0, 0), // m/s
      shipMass: 1000, // kg

      setShipSpeed: 5,

      shipCenter: Victor(15, 20).multiply(pxVictor),

      stopEngine: Victor(1, 1),
      coeff: 23,
      useSetSpeed: true
    }
  }

  else if (code === 'training3') {
    initialState = {
      ...initialState,
      distance: 40, // m, from one side of the screen to the other
      shipSpeed: Victor(0, 0), // m/s
      shipMass: 0.5, // ton

      setShipSpeed: 0.2,

      shipCenter: Victor(15, 20).multiply(pxVictor),

      stopEngine: Victor(1, 1),
      coeff: 100,
      useSetSpeed: true
    }
  }

  else if (code === 'training4') {
    initialState = {
      ...initialState,
      distance: 40, // m, from one side of the screen to the other
      shipSpeed: Victor(0.7, 0), // m/s
      setShipSpeed: 0.7,
      shipMass: 500, // kg

      shipCenter: Victor(15, 20).multiply(pxVictor),

      stopEngine: Victor(1, 1),
      coeff: 100,
      useSetSpeed: true
    }
  }

  else if (code === 'training5') {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 1.00, // m/s; set by the client
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 500, // kg

      stationSpeed: Victor(0, 0), // m/s
      stationMass: 5000, // kg

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(1, 1),

      coeff: 25,
      useSetSpeed: true
    }
  }

  else if (['training6', 'training0', 'inertia1'].includes(code)) {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 1, // m/s; set by the client
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 0.5, // ton

      stationSpeed: Victor(0, 0), // m/s
      stationMass: 0.5, // ton

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1),

      coeff: 40,
      useSetSpeed: true
    }
    if (code === 'inertia1') {
      initialState = {
        ...initialState,
        endShipPos: Victor(500 / 17.5, 11).multiply(pxVictor), // in metres
        endStationPos: Victor(1150 / 17.5, 11).multiply(pxVictor),
        shipCenter: Victor(150 / 17.5, 13).multiply(pxVictor),
        stationCenter: Victor(650 / 17.5, 13).multiply(pxVictor),
      }
    }
  }

  else if (code === 'training7') {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 1, // m/s; set by the client
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 0.5, // ton

      stationSpeed: Victor(0, 0), // m/s
      stationMass: 4.5, // ton

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1),

      coeff: 40,
      useSetSpeed: true
    }
  } else if (code === 'training8') {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 4, // m/s;
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 0.5, // ton

      stationSpeed: Victor(0, 0), // m/s
      stationMass: 3, // ton  set by the client

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1),

      coeff: 40,
      useSetSpeed: true
    }
  } else if (code === 'training9') {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 10, // m/s;
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 0.5, // ton

      setStationSpeed: 2, // m/s
      stationSpeed: Victor(0, 0), // m/s
      stationMass: 1, // ton  set by the client

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1),

      coeff: 40,
      useSetSpeed: true
    }
  } else if (code === 'training10') {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 10, // m/s;
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 0.5, // ton

      setStationSpeed: 1, // m/s set by the client
      stationSpeed: Victor(0, 0), // m/s
      stationMass: 3.5, // ton

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1),

      coeff: 40,
      useSetSpeed: true
    }
  } else if (code === 'training11') {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 5, // m/s;
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 1, // ton

      setStationSpeed: 1, // m/s set by the client
      stationSpeed: Victor(0, 0), // m/s
      stationMass: 2, // ton

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1),

      coeff: 40,
      useSetSpeed: true
    }
  } else if (['training12', 'inertia2', 'inertia3'].includes(code)) {
    initialState = {
      ...initialState,
      distance: 20, // m
      setShipSpeed: 1, // m/s; set by the client
      shipSpeed: Victor(0, 0), // m/s; used by the app
      shipMass: 1, // ton

      setStationSpeed: -1, // m/s
      stationSpeed: Victor(-1, 0), // m/s
      stationMass: 2, // ton

      // END POS
      endShipPos: Victor(500 / 17.5, 20).multiply(pxVictor), // in metres
      endStationPos: Victor(1150 / 17.5, 20).multiply(pxVictor),

      // CENTER POS
      shipCenter: Victor(150 / 17.5, 20).multiply(pxVictor),
      stationCenter: Victor(650 / 17.5, 20).multiply(pxVictor),

      stopEngine: Victor(0, 1),

      coeff: 40,
      useSetSpeed: true
    }
    if (code === 'inertia2') {
      initialState = {
        ...initialState,
        endShipPos: Victor(500 / 17.5, 11).multiply(pxVictor), // in metres
        endStationPos: Victor(1150 / 17.5, 11).multiply(pxVictor),
        shipCenter: Victor(150 / 17.5, 13).multiply(pxVictor),
        stationCenter: Victor(650 / 17.5, 13).multiply(pxVictor),
      }
    }
    if (code === 'inertia3') {
      initialState = {
        ...initialState,
        endShipPos: Victor(500 / 17.5, 11).multiply(pxVictor), // in metres
        endStationPos: Victor(1150 / 17.5, 11).multiply(pxVictor),
        shipCenter: Victor(150 / 17.5, 13).multiply(pxVictor),
        stationCenter: Victor(650 / 17.5, 13).multiply(pxVictor),

        setShipSpeed: 1, // m/s; set by the client
        shipSpeed: Victor(0, 0), // m/s; used by the app
        shipMass: .5, // ton

        setStationSpeed: 1, // m/s
        stationSpeed: Victor(1, 0), // m/s
        stationMass: .5, // ton

      }
    }
  }

  return initialState;
};

export default getStateByCode;
